import React, { Component } from "react";

export default class CICDImg extends Component {
  render() {
    // const theme = this.props.theme;
    return (
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="100%"
        viewBox="0 0 1024 539"
        enable-background="new 0 0 1024 539"
        xmlSpace="preserve"
      >
        <path
          fill="#C7EFDF"
          opacity="1.000000"
          stroke="none"
          d="
M738.000000,540.000000
	C492.000000,540.000000 246.500000,540.000000 1.000000,540.000000
	C1.000000,360.333344 1.000000,180.666672 1.000000,1.000000
	C342.333344,1.000000 683.666687,1.000000 1025.000000,1.000000
	C1025.000000,180.666672 1025.000000,360.333344 1025.000000,540.000000
	C929.500000,540.000000 834.000000,540.000000 738.000000,540.000000
M184.187424,426.653931
	C199.824387,437.982361 216.928848,446.489410 235.152725,452.709290
	C286.068939,470.087128 336.431702,470.770477 385.757690,446.965149
	C404.301819,438.015533 421.290070,426.674835 436.768707,412.042999
	C438.019440,410.952332 439.335663,409.927612 440.511261,408.761230
	C463.397827,386.054413 486.257355,363.320404 509.168152,340.638092
	C510.523773,339.295990 512.264038,338.342407 514.131775,337.804749
	C535.091919,358.582764 556.052063,379.360748 577.279419,400.734802
	C582.845459,405.996643 588.225708,411.472778 594.007141,416.486176
	C637.474915,454.179230 688.400208,468.719330 745.060669,463.649078
	C782.315979,460.315308 815.745850,446.309692 845.046448,423.065094
	C853.319580,416.501892 860.489319,408.547791 868.702087,400.828949
	C869.789856,399.815491 870.979553,398.890839 871.950439,397.775665
	C903.401489,361.653503 920.775879,319.793762 921.614136,271.784546
	C922.302429,232.364395 911.228088,195.781509 891.128113,162.051071
	C874.951233,134.904068 852.940613,113.508957 824.518738,97.870102
	C809.832153,91.683754 795.533813,84.245293 780.385559,79.547523
	C736.222229,65.851585 692.244873,66.206284 648.989746,84.216988
	C614.311646,98.656364 587.526550,123.396759 561.503540,150.301132
	C546.559082,165.152557 531.629456,180.018936 516.645447,194.830322
	C515.275513,196.184509 513.557739,197.186874 511.613007,197.769302
	C489.339325,175.389679 467.374512,152.689743 444.650787,130.776855
	C434.554047,121.040375 422.925751,112.892105 411.596497,103.479721
	C369.816040,75.591049 323.588257,66.516617 274.380463,72.652214
	C223.037735,79.054016 181.725067,104.172165 149.074539,143.829147
	C133.829636,162.345444 122.056694,182.919281 114.392502,206.482239
	C107.795937,225.518326 104.185669,245.162979 104.361397,265.254456
	C104.757515,310.543365 118.519028,351.632812 145.109268,388.273712
	C155.814880,403.025848 168.545212,415.904053 184.187424,426.653931
z"
        />
        <path
          fill="#18786F"
          opacity="1.000000"
          stroke="none"
          d="
M114.867744,205.944199
	C122.056694,182.919281 133.829636,162.345444 149.074539,143.829147
	C181.725067,104.172165 223.037735,79.054016 274.380463,72.652214
	C323.588257,66.516617 369.816040,75.591049 411.523224,104.002083
	C392.082550,109.145996 372.723755,113.804092 353.340118,118.356483
	C349.716034,119.207634 347.370728,119.922058 348.638763,124.872993
	C352.920105,141.588791 356.698242,158.434860 360.550720,175.258148
	C361.544434,179.597687 362.105408,184.036331 362.538086,188.293182
	C354.162262,183.488998 346.469727,178.009811 338.003479,174.292892
	C300.375671,157.773132 255.051208,173.598679 229.773499,198.814316
	C217.887390,210.671219 210.759155,225.158356 205.792313,241.148941
	C205.403824,241.577133 205.225891,241.862900 205.055634,242.112488
	C205.063293,242.076340 205.126083,242.115402 204.734192,242.094482
	C201.983139,242.836868 199.550125,243.426147 197.276657,244.391266
	C180.590973,251.474686 163.930954,258.618805 147.279099,265.781525
	C144.273987,267.074158 142.175430,267.136566 140.660477,263.436737
	C134.930939,249.444016 129.048080,235.510666 122.974472,221.663925
	C120.610916,216.275452 117.589500,211.175552 114.867744,205.944199
M241.038467,121.205467
	C237.756927,122.634834 234.475403,124.064201 230.921555,125.612190
	C234.828674,133.997940 238.447083,141.764038 242.031662,149.457520
	C253.639084,145.654800 257.911255,140.839951 256.599792,133.513702
	C255.115387,125.221382 249.983856,120.885712 241.038467,121.205467
M220.669174,133.072525
	C212.878708,134.912613 208.387756,140.022003 210.442291,146.203659
	C211.837250,150.400757 214.697678,154.492493 217.900345,157.604782
	C221.434357,161.039062 226.161621,160.662704 230.383240,157.907089
	C234.693069,155.093918 236.245483,150.857910 234.871170,146.171814
	C232.911606,139.490173 229.202377,134.085556 220.669174,133.072525
M269.182495,115.581329
	C270.831573,114.959854 272.480652,114.338387 275.414703,113.232666
	C273.412811,111.761528 272.115814,110.103500 271.281158,110.310432
	C266.197052,111.570900 261.218079,113.255478 255.948257,114.877274
	C258.619934,123.669769 261.104645,131.846924 263.679871,140.322037
	C269.628601,138.522217 274.980469,136.902985 280.619904,135.196747
	C280.188263,133.629272 279.861023,132.440903 279.448792,130.943893
	C275.382324,132.081680 271.642151,133.128159 267.644653,134.246643
	C266.941498,131.794907 266.407349,129.932373 265.787933,127.772675
	C269.214905,126.594101 272.281799,125.539360 275.937408,124.282150
	C272.443878,117.704361 267.956268,124.423920 264.184418,122.590645
	C263.771637,121.171646 263.285095,119.499084 262.737762,117.617538
	C264.965271,116.916412 266.695221,116.371887 269.182495,115.581329
M213.871292,159.639282
	C210.270905,159.375626 209.604095,161.165268 210.036499,164.415283
	C210.633270,168.900650 206.412262,172.286484 202.951370,169.902283
	C199.803864,167.733948 196.939728,164.638153 195.032303,161.327942
	C193.038208,157.867264 196.661346,154.177078 200.717102,154.557953
	C202.686554,154.742874 204.744339,153.987183 207.904999,153.470490
	C202.324387,148.613525 198.787170,148.336288 194.505157,151.311798
	C189.953171,154.474869 187.955292,159.656403 190.285126,164.332077
	C191.793488,167.359192 194.163528,170.122864 196.661026,172.454483
	C201.019714,176.523697 206.044388,176.793365 210.290405,173.784073
	C215.064362,170.400665 216.275482,166.299454 213.871292,159.639282
z"
        />
        <path
          fill="#229759"
          opacity="1.000000"
          stroke="none"
          d="
M513.825317,337.208038
	C512.264038,338.342407 510.523773,339.295990 509.168152,340.638092
	C486.257355,363.320404 463.397827,386.054413 440.511261,408.761230
	C439.335663,409.927612 438.019440,410.952332 436.413513,411.671448
	C436.040771,391.137573 435.893433,370.973907 436.104370,350.813995
	C436.152161,346.245911 434.881531,344.848175 430.233398,344.890808
	C408.741058,345.088013 387.245331,344.917084 365.927429,344.588989
	C390.929657,319.891998 415.762177,295.491058 440.548035,271.042877
	C441.293488,270.307587 441.551819,269.078430 442.377502,268.020386
	C443.658203,267.300140 444.719727,266.760468 445.521515,265.960419
	C466.993256,244.534927 488.445404,223.089767 509.863129,201.610336
	C510.761444,200.709427 511.296997,199.446747 512.001526,198.352615
	C513.557739,197.186874 515.275513,196.184509 516.645447,194.830322
	C531.629456,180.018936 546.559082,165.152557 562.265259,150.151733
	C583.488525,149.995972 603.951172,150.102463 624.410583,149.892166
	C628.725342,149.847824 630.152283,150.972610 630.104309,155.460815
	C629.874817,176.918030 629.991333,198.378952 629.823120,220.139893
	C616.255554,233.752335 602.835449,247.049088 589.461365,260.392029
	C587.721741,262.127594 586.219482,264.101135 584.294800,266.044312
	C583.193665,266.716583 582.319763,267.223755 581.628662,267.913879
	C559.882385,289.627960 538.142639,311.348511 516.455261,333.121277
	C515.337524,334.243378 514.691101,335.834991 513.825317,337.208038
M521.504517,282.585876
	C524.475342,281.227570 526.569763,279.777466 524.617981,275.800598
	C524.041382,274.625671 525.335449,271.858429 526.577576,270.645386
	C527.955200,269.299896 530.806274,267.722687 532.048706,268.306030
	C535.749695,270.043884 537.215759,268.151855 539.067810,265.229523
	C531.086731,261.625946 523.262085,257.995636 515.322754,254.636292
	C514.372009,254.234024 512.398132,254.943665 511.628113,255.796616
	C510.892395,256.611603 510.561462,258.518433 510.996796,259.544830
	C513.593933,265.667908 516.522949,271.649323 519.197021,277.741333
	C519.776123,279.060760 519.768372,280.637817 519.407227,281.970367
	C518.299255,282.287018 516.852905,282.269135 516.147034,282.983215
	C514.049316,285.105347 512.243042,287.515472 509.827942,290.405182
	C507.575867,287.968903 506.147156,286.423340 505.257050,285.460449
	C505.127838,280.892578 513.376343,280.156952 509.046265,274.038666
	C506.116913,276.714996 503.723358,278.901794 501.307098,281.109344
	C499.587891,279.686188 498.221527,278.555115 495.659729,276.434509
	C499.543243,273.697205 502.673859,271.490570 505.494751,269.502258
	C503.664154,265.975891 502.472198,264.879883 500.028381,267.218262
	C496.560852,270.536194 492.792877,273.540222 489.503937,276.376007
	C496.501770,283.747711 502.430084,289.992798 509.014435,296.928925
	C513.113220,292.127045 517.024780,287.544495 521.504517,282.585876
M445.478088,326.020050
	C450.972656,331.709686 456.467224,337.399323 462.189392,343.324585
	C464.011139,341.313721 465.072113,340.142578 466.221832,338.873505
	C463.914764,336.461456 461.891602,334.346222 459.982056,332.349823
	C462.170929,327.629547 465.173645,329.919281 467.764587,330.802795
	C471.279846,332.001587 474.543854,332.985077 476.531097,327.966248
	C474.842926,327.383820 473.164368,326.786316 471.473450,326.226227
	C469.611084,325.609344 467.737274,325.026947 466.020569,324.478119
	C465.675323,317.601440 462.578461,313.376495 458.090881,313.248840
	C454.169464,313.137299 445.041260,320.771301 444.402771,324.744812
	C444.367279,324.965790 444.781525,325.259064 445.478088,326.020050
M526.724976,240.410736
	C524.759521,243.671661 524.054932,246.973999 527.002441,250.008835
	C530.086609,253.184372 533.651123,252.290085 537.170166,250.802383
	C539.005249,250.026581 540.747437,248.905212 542.652649,248.471863
	C543.671509,248.240112 545.269653,248.838852 545.998596,249.625748
	C546.521912,250.190781 546.475586,252.189880 545.920471,252.673218
	C544.403809,253.994003 542.575195,255.082733 540.697144,255.827103
	C538.806396,256.576538 536.707520,256.801117 533.623657,257.499329
	C539.934082,262.589325 546.856140,261.040741 550.187866,254.525146
	C551.388855,252.176498 551.594788,247.894135 550.200012,246.039612
	C548.775330,244.145370 544.859558,243.685593 541.974365,243.492126
	C540.217590,243.374298 538.406311,245.150604 536.510437,245.699005
	C534.881470,246.170181 533.074097,246.307327 531.402527,246.109940
	C531.067078,246.070312 530.397156,243.305496 530.908936,242.758408
	C532.129456,241.453720 533.890198,240.559708 535.569763,239.804810
	C537.387085,238.988007 539.360046,238.517441 541.992371,237.658539
	C536.096191,232.935837 532.645691,233.692307 526.724976,240.410736
M474.428223,295.896179
	C473.456818,295.937958 472.140656,295.585632 471.569641,296.085144
	C467.719971,299.452789 464.028198,303.000946 461.038940,305.788055
	C467.761841,312.777283 473.775574,319.029266 479.542175,325.024292
	C483.437714,321.231873 487.459015,317.317017 491.692902,313.195221
	C490.800934,312.413086 489.406525,310.919525 489.213318,311.061432
	C486.091858,313.354095 483.123016,315.854584 480.042419,318.371033
	C478.378265,317.041260 476.768677,315.755035 475.049408,314.381195
	C477.850372,311.309326 479.957123,308.998840 482.114044,306.633301
	C481.334839,305.414276 480.659698,304.358032 479.885345,303.146667
	C476.760040,306.108643 474.412201,308.333771 471.862976,310.749756
	C470.266205,309.026367 469.077942,307.743835 466.863586,305.353851
	C469.321320,303.436829 471.677094,301.849640 473.688934,299.906403
	C474.489838,299.132874 474.529694,297.571472 474.428223,295.896179
M569.468018,235.228851
	C568.793518,234.108871 568.118958,232.988892 566.687927,230.612946
	C563.817322,234.488586 561.541565,237.561066 558.749939,241.330093
	C556.371948,238.765137 554.957397,237.239319 554.252991,236.479492
	C553.750305,231.511993 562.561401,230.916367 557.834717,224.787292
	C555.028564,227.401398 552.641357,229.625244 550.366577,231.744339
	C548.624878,230.397522 547.207703,229.301682 544.635742,227.312851
	C548.410034,224.643021 551.530823,222.435471 555.077759,219.926453
	C552.914185,217.968307 551.923523,217.071701 552.040100,217.177185
	C547.326965,221.208771 543.070679,224.849579 539.400330,227.989136
	C545.625305,234.494370 551.575623,240.712555 558.042297,247.470367
	C561.784546,243.515427 565.439880,239.652359 569.468018,235.228851
M496.023315,302.863464
	C494.566284,302.103241 492.787140,301.651733 491.709808,300.527618
	C487.723816,296.368347 483.969513,291.987091 479.844330,287.371765
	C477.722168,289.850555 476.693298,291.052307 476.283203,291.531311
	C482.591644,298.032318 488.572235,304.195465 495.137787,310.961456
	C498.104370,307.742798 500.997559,304.301666 504.219543,301.202332
	C506.851196,298.670837 505.712402,297.442627 502.732483,295.908722
	C500.690186,298.059113 498.639984,300.217773 496.023315,302.863464
z"
        />
        <path
          fill="#137883"
          opacity="1.000000"
          stroke="none"
          d="
M825.063538,98.346840
	C852.940613,113.508957 874.951233,134.904068 891.128113,162.051071
	C911.228088,195.781509 922.302429,232.364395 921.614136,271.784546
	C920.775879,319.793762 903.401489,361.653503 871.950439,397.775665
	C870.979553,398.890839 869.789856,399.815491 868.129272,400.720490
	C867.085815,400.461487 866.621033,400.201508 866.143738,400.176178
	C845.112122,399.060333 824.081299,397.928558 803.045837,396.888733
	C796.265625,396.553589 795.785767,396.375854 796.156921,389.793610
	C797.228760,370.782745 798.477051,351.781830 799.802490,332.466156
	C800.863770,330.969452 801.778870,329.783752 802.693420,328.597656
	C828.398926,295.259216 830.322754,249.307541 807.565125,213.915894
	C797.969604,198.993408 784.887207,187.933853 770.082397,179.134766
	C774.931274,178.370743 779.342529,177.631439 783.766602,176.980225
	C799.826904,174.616226 815.901978,172.346893 831.932434,169.796707
	C833.058716,169.617508 834.811340,167.332123 834.677551,166.217712
	C833.453735,156.027191 831.866150,145.880600 830.405090,135.718262
	C828.614319,123.262596 826.843140,110.804115 825.063538,98.346840
M884.932983,271.453796
	C884.932983,269.708038 884.932983,267.962280 884.932983,266.115967
	C875.878418,265.526001 867.470093,264.978180 858.670776,264.404846
	C858.521240,266.426392 858.406799,267.972595 858.277588,269.719299
	C861.640747,270.050110 864.555908,270.336853 867.458557,270.622375
	C868.652527,276.470154 863.836792,276.150726 861.153687,277.533691
	C856.464050,279.950897 855.045471,288.515594 858.398010,292.337311
	C859.586487,293.692108 859.414551,296.748688 858.982788,298.847107
	C858.666931,300.382294 856.938599,302.760376 855.792664,302.787750
	C851.649902,302.886658 851.477173,305.393097 851.231506,308.789368
	C859.227905,308.034790 866.986206,307.476379 874.682434,306.452057
	C876.560852,306.202057 878.235962,304.424072 880.004517,303.348663
	C878.924927,301.716919 878.178406,299.645752 876.707153,298.530670
	C870.793884,294.048737 864.656311,289.862701 857.808716,285.004791
	C861.780273,283.224426 864.892334,281.829285 868.647705,280.145813
	C868.979553,280.781799 869.229370,281.414520 869.606750,281.958801
	C871.955139,285.345490 875.716370,286.275726 878.863892,284.738495
	C881.247986,283.574127 882.847107,280.360901 884.229919,277.758850
	C885.021606,276.269073 884.683044,274.178619 884.932983,271.453796
M855.839355,191.207870
	C851.974304,193.322876 847.644592,194.894211 844.366272,197.695801
	C840.593262,200.920227 840.577881,206.246582 843.124146,210.524658
	C845.642456,214.755692 850.253418,216.853470 855.086121,215.516266
	C857.604980,214.819305 860.081909,213.651825 862.301819,212.257568
	C867.322632,209.104126 869.294617,204.460480 868.057556,199.600937
	C866.712036,194.315262 863.067017,191.609207 855.839355,191.207870
M879.949646,255.439087
	C879.922729,258.911957 881.090332,260.489258 884.843689,258.376434
	C884.133545,252.802246 883.429504,247.276230 882.671753,241.328384
	C873.691589,242.420319 865.186401,243.454514 856.322510,244.532318
	C856.942810,249.922318 857.328430,254.871536 858.217590,259.728546
	C858.408752,260.772675 860.419434,261.483673 862.651978,263.129639
	C862.179993,257.455475 861.868164,253.706436 861.515686,249.468201
	C863.960876,249.242249 866.012329,249.052689 868.302429,248.841080
	C868.824890,252.416870 869.275635,255.501617 869.838684,259.355255
	C877.509155,257.330475 870.663818,251.123688 873.939331,248.069214
	C875.173279,247.947784 876.788879,247.788788 878.862854,247.584686
	C879.222961,250.071518 879.551514,252.340530 879.949646,255.439087
M860.023560,349.600708
	C861.427673,347.229095 862.831848,344.857483 864.371277,342.257416
	C856.510742,337.546722 849.154846,333.138489 841.492615,328.546631
	C838.339050,333.748444 835.438049,338.533600 832.372681,343.589996
	C833.674805,344.443390 834.691528,345.109741 835.900146,345.901855
	C838.195068,342.316498 840.290161,339.043304 842.497498,335.594757
	C844.761353,337.012695 846.528748,338.119720 847.688171,338.845917
	C848.323914,343.125305 841.138428,345.745422 846.593018,350.652557
	C848.795776,347.223267 850.550110,344.492249 852.447571,341.538239
	C854.367432,342.821747 855.822083,343.794281 857.431763,344.870453
	C855.363342,348.513794 853.508057,351.781616 851.634827,355.081177
	C853.724365,356.974670 855.347656,358.090759 856.861511,354.951538
	C857.652100,353.312012 858.720581,351.806427 860.023560,349.600708
M879.005859,229.159317
	C877.927551,224.372986 876.849243,219.586639 875.635376,214.198563
	C866.509033,217.104614 858.240479,219.737534 849.607544,222.486465
	C850.438232,224.546051 851.018555,225.985001 851.638306,227.521637
	C854.741455,226.639832 857.402222,225.883743 860.161072,225.099762
	C862.482544,230.357315 863.193176,236.749039 870.352905,236.880630
	C874.828186,236.962891 877.792419,234.415771 879.005859,229.159317
M848.745850,314.462036
	C847.850403,315.890533 846.954895,317.319061 845.890198,319.017548
	C852.980774,322.180634 859.465210,325.073303 865.897644,327.942780
	C863.531189,335.657959 863.531189,335.657959 867.431458,336.703766
	C870.171143,330.400452 872.928650,324.056213 875.672913,317.742523
	C872.286560,316.018097 872.286560,316.018097 868.087891,322.625122
	C861.913025,319.975616 855.671082,317.297333 848.745850,314.462036
z"
        />
        <path
          fill="#3BBCBC"
          opacity="1.000000"
          stroke="none"
          d="
M799.656067,332.777008
	C798.477051,351.781830 797.228760,370.782745 796.156921,389.793610
	C795.785767,396.375854 796.265625,396.553589 803.045837,396.888733
	C824.081299,397.928558 845.112122,399.060333 866.143738,400.176178
	C866.621033,400.201508 867.085815,400.461487 867.860840,400.921814
	C860.489319,408.547791 853.319580,416.501892 845.046448,423.065094
	C815.745850,446.309692 782.315979,460.315308 745.060669,463.649078
	C688.400208,468.719330 637.474915,454.179230 594.007141,416.486176
	C588.225708,411.472778 582.845459,405.996643 577.168945,399.957733
	C577.083679,377.869659 577.203186,356.558014 577.051758,335.248260
	C577.022705,331.164032 578.146606,329.793121 582.364807,329.839813
	C601.010132,330.046265 619.659058,329.954437 638.306458,329.890472
	C640.233154,329.883850 642.158081,329.345184 644.381470,329.179138
	C648.608215,333.133850 652.413208,337.100647 656.486755,340.769226
	C682.745911,364.418060 713.152527,372.325867 747.571655,364.309601
	C768.320129,359.477264 785.296936,348.247742 799.656067,332.777008
M615.876221,376.885132
	C615.876221,376.885132 615.558411,376.583160 615.593445,375.840240
	C617.009460,372.109131 618.091614,368.203979 619.922974,364.689301
	C622.417908,359.901031 620.509277,357.441132 615.880676,355.369598
	C610.240112,361.912354 604.641602,368.406281 598.779480,375.206055
	C600.448608,376.490234 601.682068,377.439270 603.170166,378.584198
	C606.381592,374.868530 609.276550,371.519012 612.171509,368.169495
	C612.042175,372.086823 610.696411,375.275330 609.802795,378.585907
	C609.310730,380.408844 609.578674,382.436981 609.503967,384.372589
	C611.455200,383.954102 613.536438,383.832825 615.328247,383.049683
	C618.251343,381.772095 620.985657,380.062622 623.799561,378.535370
	C624.083435,378.923096 624.367249,379.310791 624.651123,379.698517
	C621.817078,383.005371 618.983093,386.312225 615.907471,389.900970
	C617.579163,391.210938 618.817688,392.181427 620.283142,393.329712
	C623.368896,389.749817 626.184082,386.486359 628.996216,383.220306
	C631.776855,379.990967 634.554504,376.759064 637.192139,373.692444
	C634.309753,368.863861 631.261902,368.345215 627.077026,371.235168
	C623.689392,373.574615 619.832947,375.235138 615.876221,376.885132
M723.119141,406.520081
	C722.433411,407.448120 721.110474,408.413208 721.161987,409.298401
	C721.604309,416.906433 722.289551,424.500458 722.934387,432.095947
	C722.956482,432.355957 723.317078,432.587189 723.773987,433.138428
	C724.958862,433.022949 726.350647,432.887268 728.074646,432.719177
	C728.074646,429.465118 728.074646,426.547272 728.074646,423.637970
	C733.297485,421.844940 733.828735,426.303070 735.309692,428.762817
	C737.257812,431.998444 739.347656,433.106110 743.123413,431.174713
	C741.164612,427.854675 739.332275,424.749054 737.437134,421.537018
	C740.886658,418.266785 742.516235,414.513214 740.339600,410.237976
	C738.077881,405.795776 733.751953,405.785522 729.477539,406.075043
	C727.656006,406.198456 725.832520,406.292694 723.119141,406.520081
M690.856018,419.494324
	C688.720154,416.493652 686.542480,413.521484 684.465027,410.480896
	C683.328125,408.816956 682.360535,407.037323 681.316589,405.309845
	C682.263306,404.759216 683.507812,403.526733 684.101013,403.785736
	C685.882324,404.563568 687.461487,405.856873 689.020874,407.075317
	C690.468079,408.206116 691.788330,409.499359 693.578857,411.088959
	C695.259460,404.107941 690.888733,398.939117 683.925415,399.209656
	C681.252441,399.313507 677.549866,401.141022 676.387451,403.298798
	C675.391724,405.147369 676.809265,408.903961 678.163208,411.233276
	C679.427856,413.408936 682.223145,414.639191 683.920776,416.644409
	C684.672791,417.532745 684.855103,419.504852 684.392883,420.605743
	C684.090576,421.325806 681.880432,421.976929 681.030762,421.578918
	C679.385437,420.808136 678.043396,419.332153 676.675537,418.042419
	C675.208618,416.659241 673.856750,415.154053 671.653931,412.872955
	C670.807190,420.641357 673.723389,424.419159 680.077515,425.995392
	C685.825195,427.421173 688.677979,425.890717 690.856018,419.494324
M715.282471,426.457367
	C716.368958,419.973297 717.455505,413.489258 718.599182,406.663757
	C716.344727,406.445374 714.932922,406.308624 713.071716,406.128326
	C712.211914,411.658569 711.432617,416.895721 710.572205,422.119507
	C710.058960,425.235565 709.230896,428.304565 705.058228,427.606384
	C700.731812,426.882538 700.763550,423.689667 701.311401,420.300049
	C702.147583,415.126648 702.925049,409.943787 703.790222,404.359985
	C701.448364,404.359985 699.907166,404.359985 698.186096,404.359985
	C697.365356,409.766541 696.554382,414.853546 695.829102,419.952759
	C694.977783,425.937805 696.729492,429.338684 701.531250,431.234009
	C707.054382,433.414093 711.368103,432.114166 715.282471,426.457367
M747.641357,421.944275
	C748.462524,424.605713 749.283691,427.267181 750.280945,430.499481
	C756.055115,428.788483 761.405762,427.202972 767.125244,425.508179
	C766.677795,424.082367 766.235291,422.106201 766.038940,422.130341
	C762.205811,422.601959 758.401428,423.306671 754.618164,423.959045
	C751.774597,418.258972 751.885254,417.955261 757.368408,416.294159
	C758.324097,416.004639 759.482605,415.977173 760.192139,415.395386
	C760.923218,414.795929 761.710449,413.566925 761.554260,412.800751
	C761.425354,412.168335 759.899963,411.332550 759.090881,411.426514
	C757.298645,411.634613 755.565796,412.765137 753.812500,412.756409
	C752.588562,412.750275 750.685852,411.708740 750.349426,410.705963
	C750.033325,409.763611 751.062378,407.602173 752.046204,407.139465
	C754.708496,405.887360 757.661438,405.253082 762.000610,403.908447
	C760.080872,402.314484 758.753723,400.446381 757.919434,400.645325
	C752.832458,401.858276 747.857544,403.541046 742.626038,405.133820
	C744.355652,410.989655 745.861450,416.087891 747.641357,421.944275
M626.383118,397.289459
	C630.932556,400.579437 635.482056,403.869385 640.311584,407.361908
	C641.313721,405.941498 642.023987,404.934814 642.865540,403.742035
	C639.516296,401.340363 636.448669,399.140625 633.048401,396.702362
	C634.451294,394.709198 635.625732,393.040710 636.887634,391.247894
	C640.019836,393.134888 642.683411,394.739594 646.045959,396.765381
	C648.456482,390.162079 641.248047,390.538361 639.922058,386.541412
	C640.743225,385.404175 641.829529,383.899780 643.007202,382.268768
	C646.010437,384.228333 648.427917,386.014984 651.058777,387.394836
	C651.848389,387.808990 653.232666,387.089233 654.344543,386.888947
	C654.019165,385.817230 654.034424,384.277313 653.311829,383.751587
	C649.307190,380.838257 645.123108,378.171661 640.612671,375.161743
	C635.736145,382.515015 631.066406,389.556305 626.383118,397.289459
M661.746521,418.689728
	C662.750488,419.068298 663.754517,419.446869 665.260986,420.014923
	C666.371887,410.930298 667.543518,402.393433 668.388977,393.824432
	C668.657410,391.104156 664.792847,389.260834 662.690186,391.105438
	C656.238281,396.765564 649.976013,402.641846 643.640015,408.434113
	C643.709473,408.913757 643.778931,409.393433 643.848389,409.873077
	C645.733093,410.046814 648.607361,411.081024 649.332764,410.246735
	C652.631958,406.452301 655.166809,409.396729 658.118591,410.431946
	C661.715027,411.693268 660.275269,414.279236 660.246277,416.608002
	C660.239380,417.159119 660.815063,417.717499 661.746521,418.689728
z"
        />
        <path
          fill="#22BC67"
          opacity="1.000000"
          stroke="none"
          d="
M824.791138,98.108475
	C826.843140,110.804115 828.614319,123.262596 830.405090,135.718262
	C831.866150,145.880600 833.453735,156.027191 834.677551,166.217712
	C834.811340,167.332123 833.058716,169.617508 831.932434,169.796707
	C815.901978,172.346893 799.826904,174.616226 783.766602,176.980225
	C779.342529,177.631439 774.931274,178.370743 769.737183,179.074646
	C729.045898,160.288483 691.860901,164.386536 658.023193,192.978317
	C648.156372,201.315475 639.302856,210.851837 629.984863,219.838531
	C629.991333,198.378952 629.874817,176.918030 630.104309,155.460815
	C630.152283,150.972610 628.725342,149.847824 624.410583,149.892166
	C603.951172,150.102463 583.488525,149.995972 562.567383,150.001434
	C587.526550,123.396759 614.311646,98.656364 648.989746,84.216988
	C692.244873,66.206284 736.222229,65.851585 780.385559,79.547523
	C795.533813,84.245293 809.832153,91.683754 824.791138,98.108475
M662.652100,147.079269
	C672.523376,143.549194 675.517273,136.759628 671.222839,127.642403
	C667.386658,119.498161 659.410706,118.171654 648.782715,124.158562
	C651.389282,132.237030 654.019287,140.387970 656.733704,148.800659
	C658.782349,148.194458 660.351440,147.730164 662.652100,147.079269
M744.913818,117.548752
	C743.983215,121.370773 742.266418,125.197380 742.288696,129.013855
	C742.332520,136.547348 749.626770,141.513016 756.568909,139.255280
	C762.937805,137.183960 764.642029,131.879211 765.225159,125.971733
	C765.814941,119.995369 763.593384,115.553535 759.085571,113.472023
	C754.032532,111.138748 749.834534,112.189224 744.913818,117.548752
M675.426086,115.756844
	C675.083374,116.089706 674.399048,116.486214 674.447510,116.746185
	C676.010803,125.136169 677.644714,133.512985 679.311340,142.114944
	C685.608398,140.827560 691.086609,139.707581 696.564758,138.587601
	C696.655090,137.826843 696.745422,137.066086 696.835754,136.305344
	C693.442322,131.978790 688.133728,137.625046 683.827515,135.625549
	C683.512146,133.885925 683.148926,131.882263 682.763245,129.755066
	C686.432922,128.935287 689.461365,128.258759 693.190552,127.425667
	C690.345825,120.420639 685.153198,126.715927 681.464661,124.206657
	C681.293762,123.007858 681.052551,121.315979 680.824280,119.714470
	C692.114746,116.182999 692.114746,116.182999 691.218567,112.581390
	C686.227295,113.563522 681.207275,114.551323 675.426086,115.756844
M698.199585,111.608932
	C698.423584,120.346413 698.647522,129.083893 698.883484,138.288483
	C701.624023,137.923584 703.166809,137.718155 705.250061,137.440781
	C703.109314,130.853729 704.708923,127.605080 711.826111,127.257599
	C716.278809,127.040192 718.604431,123.332504 718.171570,118.624001
	C717.745178,113.984566 715.020264,111.219673 710.470947,110.932732
	C706.682434,110.693794 702.846069,111.212128 698.199585,111.608932
M787.751343,122.226898
	C784.860535,124.845253 781.969788,127.463615 778.998840,130.154572
	C775.876831,125.813293 781.165894,117.641884 771.788147,116.585922
	C771.764709,125.849670 776.236572,135.067734 770.370605,144.069733
	C772.660461,144.789993 774.159851,145.261627 776.189758,145.900116
	C777.497986,135.343918 786.666687,131.171783 792.870605,124.039314
	C791.113770,123.328697 789.781616,122.789864 787.751343,122.226898
M728.641174,128.416519
	C728.641174,122.470482 728.641174,116.524445 728.641174,110.273590
	C727.112061,110.273590 725.563232,110.273590 723.355225,110.273590
	C723.355225,119.069786 723.355225,127.637024 723.355225,136.726837
	C728.609985,136.726837 734.015442,136.726837 739.444397,136.726837
	C739.876160,132.958008 739.804749,133.314346 735.997498,132.551147
	C733.450745,132.040619 729.263733,134.553360 728.641174,128.416519
z"
        />
        <path
          fill="#13834A"
          opacity="1.000000"
          stroke="none"
          d="
M365.750824,344.878082
	C387.245331,344.917084 408.741058,345.088013 430.233398,344.890808
	C434.881531,344.848175 436.152161,346.245911 436.104370,350.813995
	C435.893433,370.973907 436.040771,391.137573 436.091370,411.786713
	C421.290070,426.674835 404.301819,438.015533 385.757690,446.965149
	C336.431702,470.770477 286.068939,470.087128 235.152725,452.709290
	C216.928848,446.489410 199.824387,437.982361 184.422638,426.335571
	C193.505478,424.674530 202.354401,423.340271 211.200409,421.987152
	C223.978485,420.032562 236.741547,417.972382 249.541290,416.172577
	C252.398895,415.770813 253.321396,414.565430 252.927460,411.946045
	C251.128342,399.982849 249.250031,388.031067 247.540939,376.055084
	C246.253922,367.036682 245.180283,357.987854 244.346741,349.039734
	C267.252686,364.513977 292.018341,370.314362 319.038666,365.989899
	C336.503326,363.194733 351.806122,355.513672 365.750824,344.878082
M337.615509,404.385010
	C339.862183,404.431915 342.109436,404.461212 344.355377,404.531189
	C346.204163,404.588745 348.051422,404.694519 350.478363,404.805359
	C346.991638,398.245789 341.434082,396.881256 335.621826,400.233765
	C332.526184,402.019409 329.787537,404.743073 331.282715,408.243256
	C332.325745,410.685059 335.295898,412.727783 337.877106,413.961121
	C340.153778,415.048950 343.140167,414.549652 345.697174,415.204346
	C346.666565,415.452515 347.750610,416.881195 347.957703,417.934052
	C348.107971,418.697937 347.012970,420.388733 346.278046,420.513123
	C344.301788,420.847656 341.435913,421.394470 340.339722,420.358337
	C337.804626,417.962158 336.058228,418.870422 333.852051,420.206818
	C336.758667,426.365143 343.793182,428.096924 349.806183,423.754547
	C351.772766,422.334381 353.815857,419.034668 353.493622,416.955780
	C353.128052,414.597382 350.497986,412.048279 348.197540,410.697296
	C346.243042,409.549500 343.336365,410.110107 340.890015,409.688599
	C338.386658,409.257233 335.587982,408.827820 337.615509,404.385010
M318.539215,403.236359
	C315.817444,403.637207 313.095673,404.038086 310.182404,404.467133
	C311.217499,413.519806 312.191315,422.036469 313.200409,430.861694
	C319.384399,430.151825 324.943848,429.513672 330.835266,428.837402
	C330.704834,427.538940 330.640167,425.795319 330.513184,425.790771
	C326.511871,425.647430 322.504547,425.673218 318.227417,425.673218
	C318.026764,423.270966 317.853668,421.198242 317.658234,418.858154
	C320.318176,418.522827 322.520660,418.509216 324.538269,417.891571
	C325.743805,417.522491 326.659576,416.206879 327.705597,415.316742
	C326.576233,414.746521 325.465393,413.746674 324.313599,413.697052
	C322.033752,413.598785 319.731262,414.025513 317.145905,414.261932
	C316.808502,412.195038 316.521729,410.438416 316.167999,408.271545
	C320.434448,407.687622 324.305115,407.157867 328.426666,406.593781
	C328.121307,404.948090 327.894989,403.728271 327.650696,402.411652
	C324.684570,402.669983 322.042572,402.900116 318.539215,403.236359
M308.550659,404.951477
	C302.302521,404.334900 296.061890,403.553497 289.798676,403.266602
	C288.774261,403.219696 287.650024,405.352905 285.694946,407.394165
	C289.921021,407.813019 292.182465,408.037170 294.865021,408.303040
	C294.429626,415.595886 294.008789,422.645294 293.560028,430.162079
	C295.511688,430.296844 297.171661,430.411469 298.930573,430.532898
	C299.542603,422.860046 300.101288,415.855591 300.569855,409.981293
	C303.611298,407.147308 308.726959,412.180389 308.550659,404.951477
M350.965424,395.525024
	C347.526306,397.233429 349.539642,398.625275 350.882141,400.217896
	C353.350067,399.256409 355.457550,398.435364 357.828583,397.511597
	C361.042572,404.271942 364.091980,410.686035 367.302002,417.438019
	C369.120392,416.518036 370.524994,415.807373 372.183228,414.968384
	C369.058014,408.176361 366.096252,401.739624 363.027130,395.069489
	C365.479431,393.752380 367.634369,392.594971 369.796722,391.433624
	C369.470337,388.163971 368.187805,387.313110 365.200775,388.834564
	C360.753632,391.099762 356.160522,393.078400 350.965424,395.525024
z"
        />
        <path
          fill="#1C6C62"
          opacity="1.000000"
          stroke="none"
          d="
M244.011673,348.952545
	C245.180283,357.987854 246.253922,367.036682 247.540939,376.055084
	C249.250031,388.031067 251.128342,399.982849 252.927460,411.946045
	C253.321396,414.565430 252.398895,415.770813 249.541290,416.172577
	C236.741547,417.972382 223.978485,420.032562 211.200409,421.987152
	C202.354401,423.340271 193.505478,424.674530 184.235962,426.050354
	C168.545212,415.904053 155.814880,403.025848 145.109268,388.273712
	C118.519028,351.632812 104.757515,310.543365 104.361397,265.254456
	C104.185669,245.162979 107.795937,225.518326 114.630127,206.213226
	C117.589500,211.175552 120.610916,216.275452 122.974472,221.663925
	C129.048080,235.510666 134.930939,249.444016 140.660477,263.436737
	C142.175430,267.136566 144.273987,267.074158 147.279099,265.781525
	C163.930954,258.618805 180.590973,251.474686 197.276657,244.391266
	C199.550125,243.426147 201.983139,242.836868 204.767273,242.434937
	C204.971970,244.923080 204.886475,247.074005 204.510742,249.172989
	C200.317932,272.596069 203.229980,294.885895 214.840286,315.876190
	C222.137024,329.067932 232.293121,339.686676 244.011673,348.952545
M149.146805,310.609375
	C154.584930,313.170166 156.942429,312.514526 159.724930,308.773865
	C163.876480,307.998291 167.809616,308.405243 169.361542,306.675629
	C171.174377,304.655243 171.491577,300.749908 171.384933,297.699890
	C171.274002,294.527069 169.882690,291.398987 168.965149,287.925415
	C160.180283,290.155670 151.905334,292.256439 142.547180,294.632233
	C144.697739,300.117065 146.647324,305.089325 149.146805,310.609375
M187.853607,385.922363
	C194.821289,385.833435 201.163940,381.718079 203.046082,375.933441
	C205.590408,368.113708 200.064087,363.753693 195.812393,358.459534
	C188.669312,363.955872 181.874863,369.183960 173.819153,375.382538
	C178.703659,379.226776 182.879883,382.513611 187.853607,385.922363
M162.172699,330.027374
	C158.900284,330.643707 156.477707,329.127869 156.726440,326.035583
	C156.875000,324.188690 158.987778,321.766663 160.822601,320.929962
	C165.749512,318.683380 171.001846,317.150452 176.498291,315.201721
	C175.783691,313.259338 175.239655,311.780548 174.597900,310.036194
	C168.582932,312.138367 162.881882,313.934387 157.337967,316.127563
	C152.482513,318.048370 150.731491,322.336182 152.143356,327.647919
	C153.535339,332.884857 157.683228,336.742188 162.034882,335.677734
	C168.554199,334.083008 174.859039,331.611481 181.641846,329.385956
	C180.695511,327.200439 180.076874,325.771729 179.391953,324.189972
	C173.644623,326.176025 168.310669,328.019226 162.172699,330.027374
M168.301743,363.303436
	C168.941772,364.467865 169.646149,365.602112 170.209229,366.802643
	C171.638306,369.849548 173.275711,369.758026 175.362061,367.173553
	C173.686737,363.953644 172.036255,360.781464 170.289078,357.423431
	C176.977463,353.753845 183.203415,350.337982 189.728256,346.758148
	C188.744370,344.971710 187.984619,343.592255 187.042770,341.882111
	C179.092941,346.216705 171.473373,350.371216 163.663361,354.629578
	C165.311081,357.686340 166.643219,360.157623 168.301743,363.303436
M179.070877,334.748108
	C172.428055,337.659180 165.785233,340.570221 158.769974,343.644470
	C159.644943,345.483765 160.314072,346.890350 161.168457,348.686401
	C169.487640,345.033936 177.411072,341.555206 185.210831,338.130798
	C184.286484,334.500366 183.312256,332.111145 179.070877,334.748108
z"
        />
        <path
          fill="#12847B"
          opacity="1.000000"
          stroke="none"
          d="
M511.807251,198.060959
	C511.296997,199.446747 510.761444,200.709427 509.863129,201.610336
	C488.445404,223.089767 466.993256,244.534927 445.521515,265.960419
	C444.719727,266.760468 443.658203,267.300140 442.070679,267.867706
	C415.237518,241.325974 389.051331,214.877716 362.865173,188.429459
	C362.105408,184.036331 361.544434,179.597687 360.550720,175.258148
	C356.698242,158.434860 352.920105,141.588791 348.638763,124.872993
	C347.370728,119.922058 349.716034,119.207634 353.340118,118.356483
	C372.723755,113.804092 392.082550,109.145996 411.721313,104.273689
	C422.925751,112.892105 434.554047,121.040375 444.650787,130.776855
	C467.374512,152.689743 489.339325,175.389679 511.807251,198.060959
M414.687286,192.397232
	C414.464325,191.032120 414.658691,189.364410 413.933136,188.363754
	C412.332214,186.155914 410.267700,184.284241 407.600159,181.426865
	C413.145355,176.239151 418.313202,171.404449 423.703674,166.361450
	C422.281433,165.016281 421.140472,163.937195 419.535583,162.419266
	C413.778503,168.326721 408.327515,173.907867 402.898041,179.509827
	C402.338745,180.086884 401.907684,180.788269 400.762543,182.290863
	C403.876953,185.056854 406.886932,187.925919 410.122620,190.512650
	C411.521088,191.630615 413.385803,192.165375 415.196716,193.765106
	C416.451233,198.626831 417.084534,198.830872 424.301514,195.948517
	C425.246094,197.162460 426.657959,198.624252 427.627991,200.336395
	C428.390625,201.682465 429.453369,203.810318 428.916260,204.770874
	C426.930939,208.321548 428.714996,209.970154 431.812836,211.750565
	C435.468719,203.876602 439.177826,196.099869 442.616241,188.205246
	C443.030487,187.254120 442.349365,185.254822 441.501007,184.488266
	C440.680695,183.747009 438.812592,183.473984 437.712311,183.863724
	C434.432220,185.025620 431.339783,186.704361 428.106140,188.013596
	C423.755768,189.774963 419.342072,191.379868 414.687286,192.397232
M452.917511,215.960098
	C452.734772,215.805573 452.552063,215.651031 452.373230,214.709473
	C453.325836,210.832443 454.040741,206.876053 455.300720,203.101639
	C456.459808,199.629578 455.611572,197.343887 452.775482,195.898697
	C446.611359,202.254929 440.547729,208.507553 434.307343,214.942429
	C435.012939,215.538696 436.198242,216.540421 437.391632,217.548935
	C441.061920,214.130142 444.375458,211.043625 447.954437,207.709885
	C449.851715,215.273193 441.050568,222.225143 448.458984,229.324493
	C454.768372,222.772873 460.741821,216.570114 466.969666,210.103149
	C466.202728,209.285141 465.118073,208.128265 463.896667,206.825531
	C460.241791,210.190369 456.841187,213.321106 452.917511,215.960098
M412.387054,155.338120
	C409.402435,152.074310 406.417816,148.810501 403.732941,145.874496
	C397.206512,152.449051 391.098602,158.602005 384.851440,164.895264
	C385.613373,165.593246 386.676910,166.567535 387.911621,167.698608
	C390.281372,165.645370 392.473114,163.746353 394.781616,161.746185
	C399.308655,165.676697 403.244568,171.216354 409.924957,166.902618
	C413.910431,164.329071 414.831116,160.410767 412.387054,155.338120
z"
        />
        <path
          fill="#11847A"
          opacity="1.000000"
          stroke="none"
          d="
M644.083801,329.053406
	C642.158081,329.345184 640.233154,329.883850 638.306458,329.890472
	C619.659058,329.954437 601.010132,330.046265 582.364807,329.839813
	C578.146606,329.793121 577.022705,331.164032 577.051758,335.248260
	C577.203186,356.558014 577.083679,377.869659 577.035278,399.659729
	C556.052063,379.360748 535.091919,358.582764 513.978516,337.506409
	C514.691101,335.834991 515.337524,334.243378 516.455261,333.121277
	C538.142639,311.348511 559.882385,289.627960 581.628662,267.913879
	C582.319763,267.223755 583.193665,266.716583 584.562134,266.278381
	C604.789246,287.306244 624.436523,308.179810 644.083801,329.053406
z"
        />
        <path
          fill="#C6EEDE"
          opacity="1.000000"
          stroke="none"
          d="
M362.538086,188.293182
	C389.051331,214.877716 415.237518,241.325974 441.730469,267.926880
	C441.551819,269.078430 441.293488,270.307587 440.548035,271.042877
	C415.762177,295.491058 390.929657,319.891998 365.927429,344.588989
	C351.806122,355.513672 336.503326,363.194733 319.038666,365.989899
	C292.018341,370.314362 267.252686,364.513977 244.346741,349.039734
	C232.293121,339.686676 222.137024,329.067932 214.840286,315.876190
	C203.229980,294.885895 200.317932,272.596069 204.510742,249.172989
	C204.886475,247.074005 204.971970,244.923080 205.159180,242.455841
	C205.126083,242.115402 205.063293,242.076340 205.317184,242.013977
	C205.715012,241.636566 205.858948,241.321533 206.002884,241.006500
	C210.759155,225.158356 217.887390,210.671219 229.773499,198.814316
	C255.051208,173.598679 300.375671,157.773132 338.003479,174.292892
	C346.469727,178.009811 354.162262,183.488998 362.538086,188.293182
M308.328766,282.865967
	C305.230499,282.865967 302.132233,282.865967 299.275543,282.865967
	C298.835876,283.975067 298.613373,284.423584 298.477142,284.896942
	C296.345001,292.305328 293.028351,294.940094 285.757080,294.984680
	C278.903809,295.026733 273.747345,291.869659 273.059723,284.842865
	C272.165466,275.704468 272.283508,266.297241 273.421600,257.187592
	C274.221710,250.783371 279.871460,247.776855 286.446442,248.013382
	C293.354614,248.261887 296.459503,250.806793 298.389252,257.748199
	C298.647278,258.676239 299.063629,259.560272 299.299835,260.183105
	C303.981506,260.183105 308.347351,260.183105 312.728699,260.183105
	C311.798126,247.851547 304.331940,239.235123 293.090607,237.091492
	C278.551056,234.318954 266.519928,239.476257 261.014740,251.165634
	C255.897537,262.031250 255.920914,273.534943 258.463928,284.904083
	C263.064880,305.473663 286.942841,313.863495 303.818115,301.074097
	C309.581116,296.706451 312.368774,290.699524 312.824738,283.270752
	C311.491333,283.120453 310.366699,282.993713 308.328766,282.865967
M322.927521,288.498413
	C322.927521,294.089600 322.927521,299.680786 322.927521,305.307312
	C328.025879,305.307312 332.401886,305.307312 336.724304,305.307312
	C336.724304,282.491669 336.724304,260.096832 336.724304,237.660873
	C331.982941,237.660873 327.605682,237.660873 322.927460,237.660873
	C322.927460,254.549545 322.927460,271.024384 322.927521,288.498413
z"
        />
        <path
          fill="#EDF6F5"
          opacity="1.000000"
          stroke="none"
          d="
M241.445251,121.129333
	C249.983856,120.885712 255.115387,125.221382 256.599792,133.513702
	C257.911255,140.839951 253.639084,145.654800 242.031662,149.457520
	C238.447083,141.764038 234.828674,133.997940 230.921555,125.612190
	C234.475403,124.064201 237.756927,122.634834 241.445251,121.129333
M238.948776,129.700012
	C241.034592,134.165039 243.120407,138.630066 245.180450,143.039963
	C250.435303,141.357513 251.828720,139.662506 251.409012,135.835281
	C250.847244,130.712616 247.064209,125.386711 243.562057,125.556992
	C241.618530,125.651489 238.109360,124.863182 238.948776,129.700012
z"
        />
        <path
          fill="#EDF6F5"
          opacity="1.000000"
          stroke="none"
          d="
M221.068161,133.070114
	C229.202377,134.085556 232.911606,139.490173 234.871170,146.171814
	C236.245483,150.857910 234.693069,155.093918 230.383240,157.907089
	C226.161621,160.662704 221.434357,161.039062 217.900345,157.604782
	C214.697678,154.492493 211.837250,150.400757 210.442291,146.203659
	C208.387756,140.022003 212.878708,134.912613 221.068161,133.070114
M221.956863,138.263657
	C216.138229,138.372513 213.789536,141.367096 216.086685,146.343842
	C217.299866,148.972183 219.068542,151.710953 221.341629,153.356842
	C222.910233,154.492630 226.259247,154.680298 227.903885,153.725876
	C229.237579,152.951874 230.297821,149.303665 229.583801,147.882843
	C227.869446,144.471542 225.040695,141.620285 221.956863,138.263657
z"
        />
        <path
          fill="#EDF6F5"
          opacity="1.000000"
          stroke="none"
          d="
M268.803833,115.704346
	C266.695221,116.371887 264.965271,116.916412 262.737762,117.617538
	C263.285095,119.499084 263.771637,121.171646 264.184418,122.590645
	C267.956268,124.423920 272.443878,117.704361 275.937408,124.282150
	C272.281799,125.539360 269.214905,126.594101 265.787933,127.772675
	C266.407349,129.932373 266.941498,131.794907 267.644653,134.246643
	C271.642151,133.128159 275.382324,132.081680 279.448792,130.943893
	C279.861023,132.440903 280.188263,133.629272 280.619904,135.196747
	C274.980469,136.902985 269.628601,138.522217 263.679871,140.322037
	C261.104645,131.846924 258.619934,123.669769 255.948257,114.877274
	C261.218079,113.255478 266.197052,111.570900 271.281158,110.310432
	C272.115814,110.103500 273.412811,111.761528 275.414703,113.232666
	C272.480652,114.338387 270.831573,114.959854 268.803833,115.704346
z"
        />
        <path
          fill="#ECF5F4"
          opacity="1.000000"
          stroke="none"
          d="
M214.074188,159.961487
	C216.275482,166.299454 215.064362,170.400665 210.290405,173.784073
	C206.044388,176.793365 201.019714,176.523697 196.661026,172.454483
	C194.163528,170.122864 191.793488,167.359192 190.285126,164.332077
	C187.955292,159.656403 189.953171,154.474869 194.505157,151.311798
	C198.787170,148.336288 202.324387,148.613525 207.904999,153.470490
	C204.744339,153.987183 202.686554,154.742874 200.717102,154.557953
	C196.661346,154.177078 193.038208,157.867264 195.032303,161.327942
	C196.939728,164.638153 199.803864,167.733948 202.951370,169.902283
	C206.412262,172.286484 210.633270,168.900650 210.036499,164.415283
	C209.604095,161.165268 210.270905,159.375626 214.074188,159.961487
z"
        />
        <path
          fill="#1C6C62"
          opacity="1.000000"
          stroke="none"
          d="
M205.792313,241.148941
	C205.858948,241.321533 205.715012,241.636566 205.309509,242.050125
	C205.225891,241.862900 205.403824,241.577133 205.792313,241.148941
z"
        />
        <path
          fill="#C6EEDE"
          opacity="1.000000"
          stroke="none"
          d="
M644.381470,329.179169
	C624.436523,308.179810 604.789246,287.306244 584.874634,266.198547
	C586.219482,264.101135 587.721741,262.127594 589.461365,260.392029
	C602.835449,247.049088 616.255554,233.752335 629.823120,220.139893
	C639.302856,210.851837 648.156372,201.315475 658.023193,192.978317
	C691.860901,164.386536 729.045898,160.288483 769.305481,179.139771
	C784.887207,187.933853 797.969604,198.993408 807.565125,213.915894
	C830.322754,249.307541 828.398926,295.259216 802.693420,328.597656
	C801.778870,329.783752 800.863770,330.969452 799.802490,332.466156
	C785.296936,348.247742 768.320129,359.477264 747.571655,364.309601
	C713.152527,372.325867 682.745911,364.418060 656.486755,340.769226
	C652.413208,337.100647 648.608215,333.133850 644.381470,329.179169
M726.164490,237.944412
	C726.017090,238.920380 725.742310,239.896194 725.741150,240.872345
	C725.715942,261.190521 725.715698,281.508728 725.737915,301.826904
	C725.739136,302.947021 725.977417,304.066833 726.114075,305.262970
	C734.951599,305.262970 743.644897,306.045959 752.140076,305.082245
	C765.379639,303.580292 774.784729,294.413696 776.936462,281.279480
	C777.940491,275.150909 778.015442,268.592255 777.023560,262.469574
	C774.573730,247.347717 763.223572,237.821884 747.905884,237.298370
	C740.924744,237.059784 733.925232,237.357910 726.164490,237.944412
M697.947266,237.361328
	C677.950806,233.849976 664.463562,242.183670 661.599609,260.878937
	C660.460083,268.317963 660.564636,276.307007 661.942017,283.703156
	C665.707275,303.922089 686.331116,313.030884 704.396851,303.094269
	C712.062256,298.878113 716.772217,290.515015 715.542358,283.096436
	C711.291260,283.096436 707.014771,283.096436 702.586365,283.096436
	C702.366760,283.876770 702.187805,284.484833 702.024536,285.097015
	C700.129395,292.203796 696.875000,294.799683 689.636414,294.975037
	C682.810059,295.140442 678.372742,292.213318 676.678528,285.613464
	C674.238098,276.106476 674.112305,266.449860 676.911560,256.993042
	C678.812439,250.571426 683.424866,247.755020 690.221680,248.027725
	C696.851685,248.293732 699.950806,250.862228 702.004089,257.429688
	C702.376465,258.620758 703.500977,260.384888 704.401184,260.462738
	C708.288879,260.798920 712.222351,260.606598 716.138306,260.606598
	C715.891846,249.219620 709.826050,241.466248 697.947266,237.361328
z"
        />
        <path
          fill="#EEF8F4"
          opacity="1.000000"
          stroke="none"
          d="
M445.232239,325.769348
	C444.781525,325.259064 444.367279,324.965790 444.402771,324.744812
	C445.041260,320.771301 454.169464,313.137299 458.090881,313.248840
	C462.578461,313.376495 465.675323,317.601440 466.020569,324.478119
	C467.737274,325.026947 469.611084,325.609344 471.473450,326.226227
	C473.164368,326.786316 474.842926,327.383820 476.531097,327.966248
	C474.543854,332.985077 471.279846,332.001587 467.764587,330.802795
	C465.173645,329.919281 462.170929,327.629547 459.982056,332.349823
	C461.891602,334.346222 463.914764,336.461456 466.221832,338.873505
	C465.072113,340.142578 464.011139,341.313721 462.189392,343.324585
	C456.467224,337.399323 450.972656,331.709686 445.232239,325.769348
M454.598297,327.248962
	C458.253571,329.964874 459.255157,326.154907 460.224121,324.386841
	C460.821533,323.296814 459.886169,320.183807 458.753540,319.542236
	C454.768433,317.284943 453.028290,320.749023 450.858002,323.338684
	C452.026001,324.583221 453.035065,325.658447 454.598297,327.248962
z"
        />
        <path
          fill="#EDF7F3"
          opacity="1.000000"
          stroke="none"
          d="
M526.920593,240.091980
	C532.645691,233.692307 536.096191,232.935837 541.992371,237.658539
	C539.360046,238.517441 537.387085,238.988007 535.569763,239.804810
	C533.890198,240.559708 532.129456,241.453720 530.908936,242.758408
	C530.397156,243.305496 531.067078,246.070312 531.402527,246.109940
	C533.074097,246.307327 534.881470,246.170181 536.510437,245.699005
	C538.406311,245.150604 540.217590,243.374298 541.974365,243.492126
	C544.859558,243.685593 548.775330,244.145370 550.200012,246.039612
	C551.594788,247.894135 551.388855,252.176498 550.187866,254.525146
	C546.856140,261.040741 539.934082,262.589325 533.623657,257.499329
	C536.707520,256.801117 538.806396,256.576538 540.697144,255.827103
	C542.575195,255.082733 544.403809,253.994003 545.920471,252.673218
	C546.475586,252.189880 546.521912,250.190781 545.998596,249.625748
	C545.269653,248.838852 543.671509,248.240112 542.652649,248.471863
	C540.747437,248.905212 539.005249,250.026581 537.170166,250.802383
	C533.651123,252.290085 530.086609,253.184372 527.002441,250.008835
	C524.054932,246.973999 524.759521,243.671661 526.920593,240.091980
z"
        />
        <path
          fill="#EDF7F3"
          opacity="1.000000"
          stroke="none"
          d="
M474.672119,296.132446
	C474.529694,297.571472 474.489838,299.132874 473.688934,299.906403
	C471.677094,301.849640 469.321320,303.436829 466.863586,305.353851
	C469.077942,307.743835 470.266205,309.026367 471.862976,310.749756
	C474.412201,308.333771 476.760040,306.108643 479.885345,303.146667
	C480.659698,304.358032 481.334839,305.414276 482.114044,306.633301
	C479.957123,308.998840 477.850372,311.309326 475.049408,314.381195
	C476.768677,315.755035 478.378265,317.041260 480.042419,318.371033
	C483.123016,315.854584 486.091858,313.354095 489.213318,311.061432
	C489.406525,310.919525 490.800934,312.413086 491.692902,313.195221
	C487.459015,317.317017 483.437714,321.231873 479.542175,325.024292
	C473.775574,319.029266 467.761841,312.777283 461.038940,305.788055
	C464.028198,303.000946 467.719971,299.452789 471.569641,296.085144
	C472.140656,295.585632 473.456818,295.937958 474.672119,296.132446
z"
        />
        <path
          fill="#EEF8F4"
          opacity="1.000000"
          stroke="none"
          d="
M569.281616,235.509079
	C565.439880,239.652359 561.784546,243.515427 558.042297,247.470367
	C551.575623,240.712555 545.625305,234.494370 539.400330,227.989136
	C543.070679,224.849579 547.326965,221.208771 552.040100,217.177185
	C551.923523,217.071701 552.914185,217.968307 555.077759,219.926453
	C551.530823,222.435471 548.410034,224.643021 544.635742,227.312851
	C547.207703,229.301682 548.624878,230.397522 550.366577,231.744339
	C552.641357,229.625244 555.028564,227.401398 557.834717,224.787292
	C562.561401,230.916367 553.750305,231.511993 554.252991,236.479492
	C554.957397,237.239319 556.371948,238.765137 558.749939,241.330093
	C561.541565,237.561066 563.817322,234.488586 566.687927,230.612946
	C568.118958,232.988892 568.793518,234.108871 569.281616,235.509079
z"
        />
        <path
          fill="#F0F8F5"
          opacity="1.000000"
          stroke="none"
          d="
M520.030945,282.096191
	C519.768372,280.637817 519.776123,279.060760 519.197021,277.741333
	C516.522949,271.649323 513.593933,265.667908 510.996796,259.544830
	C510.561462,258.518433 510.892395,256.611603 511.628113,255.796616
	C512.398132,254.943665 514.372009,254.234024 515.322754,254.636292
	C523.262085,257.995636 531.086731,261.625946 539.067810,265.229523
	C537.215759,268.151855 535.749695,270.043884 532.048706,268.306030
	C530.806274,267.722687 527.955200,269.299896 526.577576,270.645386
	C525.335449,271.858429 524.041382,274.625671 524.617981,275.800598
	C526.569763,279.777466 524.475342,281.227570 521.243835,282.804565
	C520.983154,283.023285 521.051025,283.060059 521.082153,282.659851
	C520.722168,282.160889 520.330994,282.062195 519.956787,282.000671
	C519.973755,282.037842 520.030945,282.096191 520.030945,282.096191
M518.070068,261.289551
	C517.814270,261.366577 517.558472,261.443604 517.302673,261.520660
	C517.473450,261.738434 517.644165,261.956207 517.860901,262.881744
	C518.968079,264.959442 520.075195,267.037140 521.402771,269.528473
	C523.038696,267.696747 524.171753,266.428101 525.698669,264.718414
	C522.866150,263.494324 520.641174,262.532837 518.070068,261.289551
z"
        />
        <path
          fill="#ECF7F3"
          opacity="1.000000"
          stroke="none"
          d="
M520.959717,282.992615
	C517.024780,287.544495 513.113220,292.127045 509.014435,296.928925
	C502.430084,289.992798 496.501770,283.747711 489.503937,276.376007
	C492.792877,273.540222 496.560852,270.536194 500.028381,267.218262
	C502.472198,264.879883 503.664154,265.975891 505.494751,269.502258
	C502.673859,271.490570 499.543243,273.697205 495.659729,276.434509
	C498.221527,278.555115 499.587891,279.686188 501.307098,281.109344
	C503.723358,278.901794 506.116913,276.714996 509.046265,274.038666
	C513.376343,280.156952 505.127838,280.892578 505.257050,285.460449
	C506.147156,286.423340 507.575867,287.968903 509.827942,290.405182
	C512.243042,287.515472 514.049316,285.105347 516.147034,282.983215
	C516.852905,282.269135 518.299255,282.287018 519.719116,282.033264
	C520.030945,282.096191 519.973755,282.037842 520.007202,282.293243
	C520.377441,282.719116 520.714233,282.889587 521.051025,283.060059
	C521.051025,283.060059 520.983154,283.023285 520.959717,282.992615
z"
        />
        <path
          fill="#ECF7F3"
          opacity="1.000000"
          stroke="none"
          d="
M496.306580,302.619965
	C498.639984,300.217773 500.690186,298.059113 502.732483,295.908722
	C505.712402,297.442627 506.851196,298.670837 504.219543,301.202332
	C500.997559,304.301666 498.104370,307.742798 495.137787,310.961456
	C488.572235,304.195465 482.591644,298.032318 476.283203,291.531311
	C476.693298,291.052307 477.722168,289.850555 479.844330,287.371765
	C483.969513,291.987091 487.723816,296.368347 491.709808,300.527618
	C492.787140,301.651733 494.566284,302.103241 496.306580,302.619965
z"
        />
        <path
          fill="#EFF7F7"
          opacity="1.000000"
          stroke="none"
          d="
M884.893188,271.906189
	C884.683044,274.178619 885.021606,276.269073 884.229919,277.758850
	C882.847107,280.360901 881.247986,283.574127 878.863892,284.738495
	C875.716370,286.275726 871.955139,285.345490 869.606750,281.958801
	C869.229370,281.414520 868.979553,280.781799 868.647705,280.145813
	C864.892334,281.829285 861.780273,283.224426 857.808716,285.004791
	C864.656311,289.862701 870.793884,294.048737 876.707153,298.530670
	C878.178406,299.645752 878.924927,301.716919 880.004517,303.348663
	C878.235962,304.424072 876.560852,306.202057 874.682434,306.452057
	C866.986206,307.476379 859.227905,308.034790 851.231506,308.789368
	C851.477173,305.393097 851.649902,302.886658 855.792664,302.787750
	C856.938599,302.760376 858.666931,300.382294 858.982788,298.847107
	C859.414551,296.748688 859.586487,293.692108 858.398010,292.337311
	C855.045471,288.515594 856.464050,279.950897 861.153687,277.533691
	C863.836792,276.150726 868.652527,276.470154 867.458557,270.622375
	C864.555908,270.336853 861.640747,270.050110 858.277588,269.719299
	C858.406799,267.972595 858.521240,266.426392 858.670776,264.404846
	C867.470093,264.978180 875.878418,265.526001 884.932983,266.115967
	C884.932983,267.962280 884.932983,269.708038 884.893188,271.906189
M872.178406,273.523315
	C872.942200,275.507629 873.436523,277.682983 874.628174,279.363953
	C875.002991,279.892639 878.113586,279.534576 878.477905,278.820404
	C879.566040,276.686737 879.914001,274.175568 880.550964,271.811829
	C880.143372,271.372314 879.735779,270.932770 879.328125,270.493256
	C876.963684,271.219330 874.599243,271.945404 872.178406,273.523315
M865.530457,302.329315
	C867.667603,302.193756 869.804810,302.058228 871.941956,301.922699
	C871.955750,301.586090 871.969543,301.249481 871.983337,300.912872
	C869.661560,299.463776 867.339844,298.014679 865.018066,296.565582
	C864.533630,296.775299 864.049194,296.985046 863.564758,297.194763
	C863.972046,298.932373 864.379395,300.669952 865.530457,302.329315
z"
        />
        <path
          fill="#EFF7F7"
          opacity="1.000000"
          stroke="none"
          d="
M856.243530,191.169083
	C863.067017,191.609207 866.712036,194.315262 868.057556,199.600937
	C869.294617,204.460480 867.322632,209.104126 862.301819,212.257568
	C860.081909,213.651825 857.604980,214.819305 855.086121,215.516266
	C850.253418,216.853470 845.642456,214.755692 843.124146,210.524658
	C840.577881,206.246582 840.593262,200.920227 844.366272,197.695801
	C847.644592,194.894211 851.974304,193.322876 856.243530,191.169083
M861.802185,197.683228
	C856.880859,195.550247 848.345337,198.527145 846.869019,203.462753
	C846.326843,205.275177 847.346924,208.891220 848.782959,209.713440
	C853.466736,212.395294 857.277832,208.936996 860.856140,206.404266
	C863.771667,204.340637 864.647278,201.446976 861.802185,197.683228
z"
        />
        <path
          fill="#ECF6F5"
          opacity="1.000000"
          stroke="none"
          d="
M879.914856,255.024323
	C879.551514,252.340530 879.222961,250.071518 878.862854,247.584686
	C876.788879,247.788788 875.173279,247.947784 873.939331,248.069214
	C870.663818,251.123688 877.509155,257.330475 869.838684,259.355255
	C869.275635,255.501617 868.824890,252.416870 868.302429,248.841080
	C866.012329,249.052689 863.960876,249.242249 861.515686,249.468201
	C861.868164,253.706436 862.179993,257.455475 862.651978,263.129639
	C860.419434,261.483673 858.408752,260.772675 858.217590,259.728546
	C857.328430,254.871536 856.942810,249.922318 856.322510,244.532318
	C865.186401,243.454514 873.691589,242.420319 882.671753,241.328384
	C883.429504,247.276230 884.133545,252.802246 884.843689,258.376434
	C881.090332,260.489258 879.922729,258.911957 879.914856,255.024323
z"
        />
        <path
          fill="#ECF6F5"
          opacity="1.000000"
          stroke="none"
          d="
M859.843567,349.920532
	C858.720581,351.806427 857.652100,353.312012 856.861511,354.951538
	C855.347656,358.090759 853.724365,356.974670 851.634827,355.081177
	C853.508057,351.781616 855.363342,348.513794 857.431763,344.870453
	C855.822083,343.794281 854.367432,342.821747 852.447571,341.538239
	C850.550110,344.492249 848.795776,347.223267 846.593018,350.652557
	C841.138428,345.745422 848.323914,343.125305 847.688171,338.845917
	C846.528748,338.119720 844.761353,337.012695 842.497498,335.594757
	C840.290161,339.043304 838.195068,342.316498 835.900146,345.901855
	C834.691528,345.109741 833.674805,344.443390 832.372681,343.589996
	C835.438049,338.533600 838.339050,333.748444 841.492615,328.546631
	C849.154846,333.138489 856.510742,337.546722 864.371277,342.257416
	C862.831848,344.857483 861.427673,347.229095 859.843567,349.920532
z"
        />
        <path
          fill="#EFF7F6"
          opacity="1.000000"
          stroke="none"
          d="
M878.970947,229.567810
	C877.792419,234.415771 874.828186,236.962891 870.352905,236.880630
	C863.193176,236.749039 862.482544,230.357315 860.161072,225.099762
	C857.402222,225.883743 854.741455,226.639832 851.638306,227.521637
	C851.018555,225.985001 850.438232,224.546051 849.607544,222.486465
	C858.240479,219.737534 866.509033,217.104614 875.635376,214.198563
	C876.849243,219.586639 877.927551,224.372986 878.970947,229.567810
M872.120728,230.810455
	C876.163879,227.677292 873.580261,224.535889 872.346252,221.080444
	C869.757507,221.905334 867.455505,222.638855 864.823181,223.477631
	C866.341797,226.942062 865.389465,231.852036 872.120728,230.810455
z"
        />
        <path
          fill="#ECF6F5"
          opacity="1.000000"
          stroke="none"
          d="
M849.087524,314.540527
	C855.671082,317.297333 861.913025,319.975616 868.087891,322.625122
	C872.286560,316.018097 872.286560,316.018097 875.672913,317.742523
	C872.928650,324.056213 870.171143,330.400452 867.431458,336.703766
	C863.531189,335.657959 863.531189,335.657959 865.897644,327.942780
	C859.465210,325.073303 852.980774,322.180634 845.890198,319.017548
	C846.954895,317.319061 847.850403,315.890533 849.087524,314.540527
z"
        />
        <path
          fill="#F0FAF9"
          opacity="1.000000"
          stroke="none"
          d="
M616.184448,377.196838
	C619.832947,375.235138 623.689392,373.574615 627.077026,371.235168
	C631.261902,368.345215 634.309753,368.863861 637.192139,373.692444
	C634.554504,376.759064 631.776855,379.990967 628.996216,383.220306
	C626.184082,386.486359 623.368896,389.749817 620.283142,393.329712
	C618.817688,392.181427 617.579163,391.210938 615.907471,389.900970
	C618.983093,386.312225 621.817078,383.005371 624.651123,379.698517
	C624.367249,379.310791 624.083435,378.923096 623.799561,378.535370
	C620.985657,380.062622 618.251343,381.772095 615.328247,383.049683
	C613.536438,383.832825 611.455200,383.954102 609.503967,384.372589
	C609.578674,382.436981 609.310730,380.408844 609.802795,378.585907
	C610.696411,375.275330 612.042175,372.086823 612.171509,368.169495
	C609.276550,371.519012 606.381592,374.868530 603.170166,378.584198
	C601.682068,377.439270 600.448608,376.490234 598.779480,375.206055
	C604.641602,368.406281 610.240112,361.912354 615.880676,355.369598
	C620.509277,357.441132 622.417908,359.901031 619.922974,364.689301
	C618.091614,368.203979 617.009460,372.109131 615.443481,376.386017
	C615.590454,377.020142 615.887451,377.108490 616.184448,377.196838
M625.367615,378.765442
	C625.481201,378.663239 625.594788,378.561035 625.708374,378.458832
	C625.602295,378.348999 625.498779,378.156006 625.389404,378.152679
	C625.278687,378.149261 625.162231,378.333405 625.367615,378.765442
z"
        />
        <path
          fill="#F2FBFA"
          opacity="1.000000"
          stroke="none"
          d="
M723.564514,406.460144
	C725.832520,406.292694 727.656006,406.198456 729.477539,406.075043
	C733.751953,405.785522 738.077881,405.795776 740.339600,410.237976
	C742.516235,414.513214 740.886658,418.266785 737.437134,421.537018
	C739.332275,424.749054 741.164612,427.854675 743.123413,431.174713
	C739.347656,433.106110 737.257812,431.998444 735.309692,428.762817
	C733.828735,426.303070 733.297485,421.844940 728.074646,423.637970
	C728.074646,426.547272 728.074646,429.465118 728.074646,432.719177
	C726.350647,432.887268 724.958862,433.022949 723.773987,433.138428
	C723.317078,432.587189 722.956482,432.355957 722.934387,432.095947
	C722.289551,424.500458 721.604309,416.906433 721.161987,409.298401
	C721.110474,408.413208 722.433411,407.448120 723.564514,406.460144
M727.070129,416.130188
	C727.527344,420.637512 730.795715,418.773773 732.632812,418.049927
	C734.007874,417.508179 734.717712,415.278015 735.723694,413.799591
	C733.916321,412.775635 732.213379,411.227234 730.278809,410.842041
	C726.764526,410.142334 726.757874,412.770569 727.070129,416.130188
z"
        />
        <path
          fill="#F0FAF9"
          opacity="1.000000"
          stroke="none"
          d="
M690.820312,419.872681
	C688.677979,425.890717 685.825195,427.421173 680.077515,425.995392
	C673.723389,424.419159 670.807190,420.641357 671.653931,412.872955
	C673.856750,415.154053 675.208618,416.659241 676.675537,418.042419
	C678.043396,419.332153 679.385437,420.808136 681.030762,421.578918
	C681.880432,421.976929 684.090576,421.325806 684.392883,420.605743
	C684.855103,419.504852 684.672791,417.532745 683.920776,416.644409
	C682.223145,414.639191 679.427856,413.408936 678.163208,411.233276
	C676.809265,408.903961 675.391724,405.147369 676.387451,403.298798
	C677.549866,401.141022 681.252441,399.313507 683.925415,399.209656
	C690.888733,398.939117 695.259460,404.107941 693.578857,411.088959
	C691.788330,409.499359 690.468079,408.206116 689.020874,407.075317
	C687.461487,405.856873 685.882324,404.563568 684.101013,403.785736
	C683.507812,403.526733 682.263306,404.759216 681.316589,405.309845
	C682.360535,407.037323 683.328125,408.816956 684.465027,410.480896
	C686.542480,413.521484 688.720154,416.493652 690.820312,419.872681
z"
        />
        <path
          fill="#F2FBFA"
          opacity="1.000000"
          stroke="none"
          d="
M715.129272,426.819946
	C711.368103,432.114166 707.054382,433.414093 701.531250,431.234009
	C696.729492,429.338684 694.977783,425.937805 695.829102,419.952759
	C696.554382,414.853546 697.365356,409.766541 698.186096,404.359985
	C699.907166,404.359985 701.448364,404.359985 703.790222,404.359985
	C702.925049,409.943787 702.147583,415.126648 701.311401,420.300049
	C700.763550,423.689667 700.731812,426.882538 705.058228,427.606384
	C709.230896,428.304565 710.058960,425.235565 710.572205,422.119507
	C711.432617,416.895721 712.211914,411.658569 713.071716,406.128326
	C714.932922,406.308624 716.344727,406.445374 718.599182,406.663757
	C717.455505,413.489258 716.368958,419.973297 715.129272,426.819946
z"
        />
        <path
          fill="#F0FAF9"
          opacity="1.000000"
          stroke="none"
          d="
M747.504333,421.565186
	C745.861450,416.087891 744.355652,410.989655 742.626038,405.133820
	C747.857544,403.541046 752.832458,401.858276 757.919434,400.645325
	C758.753723,400.446381 760.080872,402.314484 762.000610,403.908447
	C757.661438,405.253082 754.708496,405.887360 752.046204,407.139465
	C751.062378,407.602173 750.033325,409.763611 750.349426,410.705963
	C750.685852,411.708740 752.588562,412.750275 753.812500,412.756409
	C755.565796,412.765137 757.298645,411.634613 759.090881,411.426514
	C759.899963,411.332550 761.425354,412.168335 761.554260,412.800751
	C761.710449,413.566925 760.923218,414.795929 760.192139,415.395386
	C759.482605,415.977173 758.324097,416.004639 757.368408,416.294159
	C751.885254,417.955261 751.774597,418.258972 754.618164,423.959045
	C758.401428,423.306671 762.205811,422.601959 766.038940,422.130341
	C766.235291,422.106201 766.677795,424.082367 767.125244,425.508179
	C761.405762,427.202972 756.055115,428.788483 750.280945,430.499481
	C749.283691,427.267181 748.462524,424.605713 747.504333,421.565186
z"
        />
        <path
          fill="#EEF9F8"
          opacity="1.000000"
          stroke="none"
          d="
M626.389893,396.943542
	C631.066406,389.556305 635.736145,382.515015 640.612671,375.161743
	C645.123108,378.171661 649.307190,380.838257 653.311829,383.751587
	C654.034424,384.277313 654.019165,385.817230 654.344543,386.888947
	C653.232666,387.089233 651.848389,387.808990 651.058777,387.394836
	C648.427917,386.014984 646.010437,384.228333 643.007202,382.268768
	C641.829529,383.899780 640.743225,385.404175 639.922058,386.541412
	C641.248047,390.538361 648.456482,390.162079 646.045959,396.765381
	C642.683411,394.739594 640.019836,393.134888 636.887634,391.247894
	C635.625732,393.040710 634.451294,394.709198 633.048401,396.702362
	C636.448669,399.140625 639.516296,401.340363 642.865540,403.742035
	C642.023987,404.934814 641.313721,405.941498 640.311584,407.361908
	C635.482056,403.869385 630.932556,400.579437 626.389893,396.943542
z"
        />
        <path
          fill="#F2FBFA"
          opacity="1.000000"
          stroke="none"
          d="
M661.434631,418.481140
	C660.815063,417.717499 660.239380,417.159119 660.246277,416.608002
	C660.275269,414.279236 661.715027,411.693268 658.118591,410.431946
	C655.166809,409.396729 652.631958,406.452301 649.332764,410.246735
	C648.607361,411.081024 645.733093,410.046814 643.848389,409.873077
	C643.778931,409.393433 643.709473,408.913757 643.640015,408.434113
	C649.976013,402.641846 656.238281,396.765564 662.690186,391.105438
	C664.792847,389.260834 668.657410,391.104156 668.388977,393.824432
	C667.543518,402.393433 666.371887,410.930298 665.260986,420.014923
	C663.754517,419.446869 662.750488,419.068298 661.434631,418.481140
M662.693909,401.379578
	C662.693909,400.386322 662.693909,399.393066 662.693909,398.569000
	C660.302368,400.469940 658.175232,402.160767 655.522400,404.269409
	C657.979553,405.426849 659.642212,406.210052 661.587463,407.126343
	C661.970520,405.007843 662.232666,403.558075 662.693909,401.379578
z"
        />
        <path
          fill="#EFFAF5"
          opacity="1.000000"
          stroke="none"
          d="
M662.286316,147.172577
	C660.351440,147.730164 658.782349,148.194458 656.733704,148.800659
	C654.019287,140.387970 651.389282,132.237030 648.782715,124.158562
	C659.410706,118.171654 667.386658,119.498161 671.222839,127.642403
	C675.517273,136.759628 672.523376,143.549194 662.286316,147.172577
M665.715454,128.914062
	C663.266602,124.577454 659.956970,123.864365 655.427795,126.615952
	C657.161011,131.990341 658.868469,137.284897 660.557373,142.522141
	C668.011292,140.854172 669.015503,138.220016 665.715454,128.914062
z"
        />
        <path
          fill="#EFFAF5"
          opacity="1.000000"
          stroke="none"
          d="
M745.113892,117.233467
	C749.834534,112.189224 754.032532,111.138748 759.085571,113.472023
	C763.593384,115.553535 765.814941,119.995369 765.225159,125.971733
	C764.642029,131.879211 762.937805,137.183960 756.568909,139.255280
	C749.626770,141.513016 742.332520,136.547348 742.288696,129.013855
	C742.266418,125.197380 743.983215,121.370773 745.113892,117.233467
M757.374573,132.858582
	C758.186157,130.020752 759.335144,127.227875 759.720947,124.333290
	C760.167908,120.980339 759.177002,117.839188 755.260803,117.165535
	C751.606995,116.537033 749.774719,118.996460 748.879700,122.095551
	C748.289185,124.140030 747.875671,126.308235 747.844421,128.427841
	C747.754578,134.528732 751.423584,136.519379 757.374573,132.858582
z"
        />
        <path
          fill="#EDF9F4"
          opacity="1.000000"
          stroke="none"
          d="
M675.806641,115.647980
	C681.207275,114.551323 686.227295,113.563522 691.218567,112.581390
	C692.114746,116.182999 692.114746,116.182999 680.824280,119.714470
	C681.052551,121.315979 681.293762,123.007858 681.464661,124.206657
	C685.153198,126.715927 690.345825,120.420639 693.190552,127.425667
	C689.461365,128.258759 686.432922,128.935287 682.763245,129.755066
	C683.148926,131.882263 683.512146,133.885925 683.827515,135.625549
	C688.133728,137.625046 693.442322,131.978790 696.835754,136.305344
	C696.745422,137.066086 696.655090,137.826843 696.564758,138.587601
	C691.086609,139.707581 685.608398,140.827560 679.311340,142.114944
	C677.644714,133.512985 676.010803,125.136169 674.447510,116.746185
	C674.399048,116.486214 675.083374,116.089706 675.806641,115.647980
z"
        />
        <path
          fill="#F0FAF6"
          opacity="1.000000"
          stroke="none"
          d="
M698.615234,111.501984
	C702.846069,111.212128 706.682434,110.693794 710.470947,110.932732
	C715.020264,111.219673 717.745178,113.984566 718.171570,118.624001
	C718.604431,123.332504 716.278809,127.040192 711.826111,127.257599
	C704.708923,127.605080 703.109314,130.853729 705.250061,137.440781
	C703.166809,137.718155 701.624023,137.923584 698.883484,138.288483
	C698.647522,129.083893 698.423584,120.346413 698.615234,111.501984
M707.247192,123.746330
	C710.361084,123.306572 713.759216,122.632027 712.468018,118.446304
	C710.915527,113.413872 706.915527,115.181122 702.501038,116.055748
	C704.695435,118.552849 700.848877,123.013290 707.247192,123.746330
z"
        />
        <path
          fill="#EDF9F4"
          opacity="1.000000"
          stroke="none"
          d="
M788.100403,122.238960
	C789.781616,122.789864 791.113770,123.328697 792.870605,124.039314
	C786.666687,131.171783 777.497986,135.343918 776.189758,145.900116
	C774.159851,145.261627 772.660461,144.789993 770.370605,144.069733
	C776.236572,135.067734 771.764709,125.849670 771.788147,116.585922
	C781.165894,117.641884 775.876831,125.813293 778.998840,130.154572
	C781.969788,127.463615 784.860535,124.845253 788.100403,122.238960
z"
        />
        <path
          fill="#ECF8F3"
          opacity="1.000000"
          stroke="none"
          d="
M728.633423,128.908417
	C729.263733,134.553360 733.450745,132.040619 735.997498,132.551147
	C739.804749,133.314346 739.876160,132.958008 739.444397,136.726837
	C734.015442,136.726837 728.609985,136.726837 723.355225,136.726837
	C723.355225,127.637024 723.355225,119.069786 723.355225,110.273590
	C725.563232,110.273590 727.112061,110.273590 728.641174,110.273590
	C728.641174,116.524445 728.641174,122.470482 728.633423,128.908417
z"
        />
        <path
          fill="#EAF6F1"
          opacity="1.000000"
          stroke="none"
          d="
M337.351807,404.706024
	C335.587982,408.827820 338.386658,409.257233 340.890015,409.688599
	C343.336365,410.110107 346.243042,409.549500 348.197540,410.697296
	C350.497986,412.048279 353.128052,414.597382 353.493622,416.955780
	C353.815857,419.034668 351.772766,422.334381 349.806183,423.754547
	C343.793182,428.096924 336.758667,426.365143 333.852051,420.206818
	C336.058228,418.870422 337.804626,417.962158 340.339722,420.358337
	C341.435913,421.394470 344.301788,420.847656 346.278046,420.513123
	C347.012970,420.388733 348.107971,418.697937 347.957703,417.934052
	C347.750610,416.881195 346.666565,415.452515 345.697174,415.204346
	C343.140167,414.549652 340.153778,415.048950 337.877106,413.961121
	C335.295898,412.727783 332.325745,410.685059 331.282715,408.243256
	C329.787537,404.743073 332.526184,402.019409 335.621826,400.233765
	C341.434082,396.881256 346.991638,398.245789 350.478363,404.805359
	C348.051422,404.694519 346.204163,404.588745 344.355377,404.531189
	C342.109436,404.461212 339.862183,404.431915 337.351807,404.706024
z"
        />
        <path
          fill="#EAF6F1"
          opacity="1.000000"
          stroke="none"
          d="
M318.969879,403.183289
	C322.042572,402.900116 324.684570,402.669983 327.650696,402.411652
	C327.894989,403.728271 328.121307,404.948090 328.426666,406.593781
	C324.305115,407.157867 320.434448,407.687622 316.167999,408.271545
	C316.521729,410.438416 316.808502,412.195038 317.145905,414.261932
	C319.731262,414.025513 322.033752,413.598785 324.313599,413.697052
	C325.465393,413.746674 326.576233,414.746521 327.705627,415.316772
	C326.659576,416.206879 325.743805,417.522491 324.538269,417.891571
	C322.520660,418.509216 320.318176,418.522827 317.658234,418.858154
	C317.853668,421.198242 318.026764,423.270966 318.227417,425.673218
	C322.504547,425.673218 326.511871,425.647430 330.513184,425.790771
	C330.640167,425.795319 330.704834,427.538940 330.835266,428.837402
	C324.943848,429.513672 319.384399,430.151825 313.200409,430.861694
	C312.191315,422.036469 311.217499,413.519806 310.182404,404.467133
	C313.095673,404.038086 315.817444,403.637207 318.969879,403.183289
z"
        />
        <path
          fill="#EAF6F1"
          opacity="1.000000"
          stroke="none"
          d="
M308.721619,405.341217
	C308.726959,412.180389 303.611298,407.147308 300.569855,409.981293
	C300.101288,415.855591 299.542603,422.860046 298.930573,430.532898
	C297.171661,430.411469 295.511688,430.296844 293.560028,430.162079
	C294.008789,422.645294 294.429626,415.595886 294.865021,408.303040
	C292.182465,408.037170 289.921021,407.813019 285.694946,407.394165
	C287.650024,405.352905 288.774261,403.219696 289.798676,403.266602
	C296.061890,403.553497 302.302521,404.334900 308.721619,405.341217
z"
        />
        <path
          fill="#EAF6F1"
          opacity="1.000000"
          stroke="none"
          d="
M351.297485,395.352020
	C356.160522,393.078400 360.753632,391.099762 365.200775,388.834564
	C368.187805,387.313110 369.470337,388.163971 369.796722,391.433624
	C367.634369,392.594971 365.479431,393.752380 363.027130,395.069489
	C366.096252,401.739624 369.058014,408.176361 372.183228,414.968384
	C370.524994,415.807373 369.120392,416.518036 367.302002,417.438019
	C364.091980,410.686035 361.042572,404.271942 357.828583,397.511597
	C355.457550,398.435364 353.350067,399.256409 350.882141,400.217896
	C349.539642,398.625275 347.526306,397.233429 351.297485,395.352020
z"
        />
        <path
          fill="#EEF5F4"
          opacity="1.000000"
          stroke="none"
          d="
M148.871857,310.335449
	C146.647324,305.089325 144.697739,300.117065 142.547180,294.632233
	C151.905334,292.256439 160.180283,290.155670 168.965149,287.925415
	C169.882690,291.398987 171.274002,294.527069 171.384933,297.699890
	C171.491577,300.749908 171.174377,304.655243 169.361542,306.675629
	C167.809616,308.405243 163.876480,307.998291 159.724930,308.773865
	C156.942429,312.514526 154.584930,313.170166 148.871857,310.335449
M149.397675,301.234039
	C150.271469,304.069183 150.974487,307.923431 155.050812,305.931732
	C159.401169,303.806122 156.227417,300.326294 155.447861,296.170807
	C153.000061,297.857971 151.110535,299.160339 149.397675,301.234039
M162.191345,302.573761
	C163.685257,302.364288 166.359909,302.408691 166.468933,301.901520
	C166.944046,299.691528 166.673080,297.321136 166.673080,294.332977
	C163.784821,295.077972 161.958542,295.549042 159.625305,296.150848
	C160.389618,298.364929 161.012070,300.168060 162.191345,302.573761
z"
        />
        <path
          fill="#EEF6F5"
          opacity="1.000000"
          stroke="none"
          d="
M187.454865,385.861389
	C182.879883,382.513611 178.703659,379.226776 173.819153,375.382538
	C181.874863,369.183960 188.669312,363.955872 195.812393,358.459534
	C200.064087,363.753693 205.590408,368.113708 203.046082,375.933441
	C201.163940,381.718079 194.821289,385.833435 187.454865,385.861389
M185.400452,380.195740
	C190.126282,381.394257 195.217026,379.112549 197.971985,374.591736
	C200.172775,370.980286 198.444061,368.393677 195.759766,365.473846
	C190.975937,369.168518 186.444305,372.668365 181.699493,376.332886
	C182.983124,377.785645 183.854004,378.771301 185.400452,380.195740
z"
        />
        <path
          fill="#EEF5F4"
          opacity="1.000000"
          stroke="none"
          d="
M162.574707,329.944916
	C168.310669,328.019226 173.644623,326.176025 179.391953,324.189972
	C180.076874,325.771729 180.695511,327.200439 181.641846,329.385956
	C174.859039,331.611481 168.554199,334.083008 162.034882,335.677734
	C157.683228,336.742188 153.535339,332.884857 152.143356,327.647919
	C150.731491,322.336182 152.482513,318.048370 157.337967,316.127563
	C162.881882,313.934387 168.582932,312.138367 174.597900,310.036194
	C175.239655,311.780548 175.783691,313.259338 176.498291,315.201721
	C171.001846,317.150452 165.749512,318.683380 160.822601,320.929962
	C158.987778,321.766663 156.875000,324.188690 156.726440,326.035583
	C156.477707,329.127869 158.900284,330.643707 162.574707,329.944916
z"
        />
        <path
          fill="#ECF4F3"
          opacity="1.000000"
          stroke="none"
          d="
M168.138550,362.966187
	C166.643219,360.157623 165.311081,357.686340 163.663361,354.629578
	C171.473373,350.371216 179.092941,346.216705 187.042770,341.882111
	C187.984619,343.592255 188.744370,344.971710 189.728256,346.758148
	C183.203415,350.337982 176.977463,353.753845 170.289078,357.423431
	C172.036255,360.781464 173.686737,363.953644 175.362061,367.173553
	C173.275711,369.758026 171.638306,369.849548 170.209229,366.802643
	C169.646149,365.602112 168.941772,364.467865 168.138550,362.966187
z"
        />
        <path
          fill="#ECF4F3"
          opacity="1.000000"
          stroke="none"
          d="
M179.399872,334.582764
	C183.312256,332.111145 184.286484,334.500366 185.210831,338.130798
	C177.411072,341.555206 169.487640,345.033936 161.168457,348.686401
	C160.314072,346.890350 159.644943,345.483765 158.769974,343.644470
	C165.785233,340.570221 172.428055,337.659180 179.399872,334.582764
z"
        />
        <path
          fill="#EEF6F5"
          opacity="1.000000"
          stroke="none"
          d="
M453.440582,216.451843
	C456.841187,213.321106 460.241791,210.190369 463.896667,206.825531
	C465.118073,208.128265 466.202728,209.285141 466.969666,210.103149
	C460.741821,216.570114 454.768372,222.772873 448.458984,229.324493
	C441.050568,222.225143 449.851715,215.273193 447.954437,207.709885
	C444.375458,211.043625 441.061920,214.130142 437.391632,217.548935
	C436.198242,216.540421 435.012939,215.538696 434.307343,214.942429
	C440.547729,208.507553 446.611359,202.254929 452.775482,195.898697
	C455.611572,197.343887 456.459808,199.629578 455.300720,203.101639
	C454.040741,206.876053 453.325836,210.832443 452.243195,215.387039
	C452.555634,216.193695 452.998108,216.322769 453.440582,216.451843
M448.464569,207.928268
	C448.525055,207.746429 448.585541,207.564590 448.646027,207.382751
	C448.474243,207.440323 448.302490,207.497879 448.464569,207.928268
z"
        />
        <path
          fill="#F0F8F7"
          opacity="1.000000"
          stroke="none"
          d="
M412.578735,155.661713
	C414.831116,160.410767 413.910431,164.329071 409.924957,166.902618
	C403.244568,171.216354 399.308655,165.676697 394.781616,161.746185
	C392.473114,163.746353 390.281372,165.645370 387.911621,167.698608
	C386.676910,166.567535 385.613373,165.593246 384.851440,164.895264
	C391.098602,158.602005 397.206512,152.449051 403.732941,145.874496
	C406.417816,148.810501 409.402435,152.074310 412.578735,155.661713
M405.948547,154.480469
	C400.829803,150.972549 400.733032,156.909714 397.741669,158.292709
	C400.861206,160.625153 402.478699,165.473053 406.961487,162.096802
	C409.890961,159.890442 408.850189,157.223358 405.948547,154.480469
z"
        />
        <path
          fill="#EFF7F6"
          opacity="1.000000"
          stroke="none"
          d="
M414.955536,193.051910
	C419.342072,191.379868 423.755768,189.774963 428.106140,188.013596
	C431.339783,186.704361 434.432220,185.025620 437.712311,183.863724
	C438.812592,183.473984 440.680695,183.747009 441.501007,184.488266
	C442.349365,185.254822 443.030487,187.254120 442.616241,188.205246
	C439.177826,196.099869 435.468719,203.876602 431.812836,211.750565
	C428.714996,209.970154 426.930939,208.321548 428.916260,204.770874
	C429.453369,203.810318 428.390625,201.682465 427.627991,200.336395
	C426.657959,198.624252 425.246094,197.162460 424.301514,195.948517
	C417.084534,198.830872 416.451233,198.626831 415.090088,193.380234
	C414.983429,192.995361 414.955536,193.051910 414.955536,193.051910
M434.030457,190.712967
	C432.031677,191.705017 430.032898,192.697052 427.462128,193.973007
	C429.366547,195.749832 430.703247,196.996994 433.187347,199.314636
	C433.839844,195.434402 434.261871,192.924667 434.030457,190.712967
z"
        />
        <path
          fill="#ECF6F5"
          opacity="1.000000"
          stroke="none"
          d="
M415.011047,192.980225
	C413.385803,192.165375 411.521088,191.630615 410.122620,190.512650
	C406.886932,187.925919 403.876953,185.056854 400.762543,182.290863
	C401.907684,180.788269 402.338745,180.086884 402.898041,179.509827
	C408.327515,173.907867 413.778503,168.326721 419.535583,162.419266
	C421.140472,163.937195 422.281433,165.016281 423.703674,166.361450
	C418.313202,171.404449 413.145355,176.239151 407.600159,181.426865
	C410.267700,184.284241 412.332214,186.155914 413.933136,188.363754
	C414.658691,189.364410 414.464325,191.032120 414.821411,192.724579
	C414.955536,193.051910 414.983429,192.995361 415.011047,192.980225
z"
        />
        <path
          fill="#206F65"
          opacity="1.000000"
          stroke="none"
          d="
M308.785400,282.866455
	C310.366699,282.993713 311.491333,283.120453 312.824738,283.270752
	C312.368774,290.699524 309.581116,296.706451 303.818115,301.074097
	C286.942841,313.863495 263.064880,305.473663 258.463928,284.904083
	C255.920914,273.534943 255.897537,262.031250 261.014740,251.165634
	C266.519928,239.476257 278.551056,234.318954 293.090607,237.091492
	C304.331940,239.235123 311.798126,247.851547 312.728699,260.183105
	C308.347351,260.183105 303.981506,260.183105 299.299835,260.183105
	C299.063629,259.560272 298.647278,258.676239 298.389252,257.748199
	C296.459503,250.806793 293.354614,248.261887 286.446442,248.013382
	C279.871460,247.776855 274.221710,250.783371 273.421600,257.187592
	C272.283508,266.297241 272.165466,275.704468 273.059723,284.842865
	C273.747345,291.869659 278.903809,295.026733 285.757080,294.984680
	C293.028351,294.940094 296.345001,292.305328 298.477142,284.896942
	C298.613373,284.423584 298.835876,283.975067 299.275543,282.865967
	C302.132233,282.865967 305.230499,282.865967 308.785400,282.866455
z"
        />
        <path
          fill="#1E6E64"
          opacity="1.000000"
          stroke="none"
          d="
M322.927490,287.998810
	C322.927460,271.024384 322.927460,254.549545 322.927460,237.660873
	C327.605682,237.660873 331.982941,237.660873 336.724304,237.660873
	C336.724304,260.096832 336.724304,282.491669 336.724304,305.307312
	C332.401886,305.307312 328.025879,305.307312 322.927521,305.307312
	C322.927521,299.680786 322.927521,294.089600 322.927490,287.998810
z"
        />
        <path
          fill="#248077"
          opacity="1.000000"
          stroke="none"
          d="
M238.805054,129.364868
	C238.109360,124.863182 241.618530,125.651489 243.562057,125.556992
	C247.064209,125.386711 250.847244,130.712616 251.409012,135.835281
	C251.828720,139.662506 250.435303,141.357513 245.180450,143.039963
	C243.120407,138.630066 241.034592,134.165039 238.805054,129.364868
z"
        />
        <path
          fill="#227E75"
          opacity="1.000000"
          stroke="none"
          d="
M222.309235,138.403351
	C225.040695,141.620285 227.869446,144.471542 229.583801,147.882843
	C230.297821,149.303665 229.237579,152.951874 227.903885,153.725876
	C226.259247,154.680298 222.910233,154.492630 221.341629,153.356842
	C219.068542,151.710953 217.299866,148.972183 216.086685,146.343842
	C213.789536,141.367096 216.138229,138.372513 222.309235,138.403351
z"
        />
        <path
          fill="#177A85"
          opacity="1.000000"
          stroke="none"
          d="
M726.549316,237.678497
	C733.925232,237.357910 740.924744,237.059784 747.905884,237.298370
	C763.223572,237.821884 774.573730,247.347717 777.023560,262.469574
	C778.015442,268.592255 777.940491,275.150909 776.936462,281.279480
	C774.784729,294.413696 765.379639,303.580292 752.140076,305.082245
	C743.644897,306.045959 734.951599,305.262970 726.114075,305.262970
	C725.977417,304.066833 725.739136,302.947021 725.737915,301.826904
	C725.715698,281.508728 725.715942,261.190521 725.741150,240.872345
	C725.742310,239.896194 726.017090,238.920380 726.549316,237.678497
M748.160889,294.116577
	C756.714905,292.983398 761.714722,288.323669 762.856323,279.732574
	C763.421326,275.480835 763.551453,271.087158 763.201172,266.813232
	C762.015808,252.349182 754.768066,246.945190 740.099304,249.493774
	C740.099304,264.162720 740.099304,278.882874 740.099304,294.145447
	C742.674805,294.145447 744.965698,294.145447 748.160889,294.116577
z"
        />
        <path
          fill="#167A84"
          opacity="1.000000"
          stroke="none"
          d="
M698.330811,237.491837
	C709.826050,241.466248 715.891846,249.219620 716.138306,260.606598
	C712.222351,260.606598 708.288879,260.798920 704.401184,260.462738
	C703.500977,260.384888 702.376465,258.620758 702.004089,257.429688
	C699.950806,250.862228 696.851685,248.293732 690.221680,248.027725
	C683.424866,247.755020 678.812439,250.571426 676.911560,256.993042
	C674.112305,266.449860 674.238098,276.106476 676.678528,285.613464
	C678.372742,292.213318 682.810059,295.140442 689.636414,294.975037
	C696.875000,294.799683 700.129395,292.203796 702.024536,285.097015
	C702.187805,284.484833 702.366760,283.876770 702.586365,283.096436
	C707.014771,283.096436 711.291260,283.096436 715.542358,283.096436
	C716.772217,290.515015 712.062256,298.878113 704.396851,303.094269
	C686.331116,313.030884 665.707275,303.922089 661.942017,283.703156
	C660.564636,276.307007 660.460083,268.317963 661.599609,260.878937
	C664.463562,242.183670 677.950806,233.849976 698.330811,237.491837
z"
        />
        <path
          fill="#309E66"
          opacity="1.000000"
          stroke="none"
          d="
M454.321228,326.991302
	C453.035065,325.658447 452.026001,324.583221 450.858002,323.338684
	C453.028290,320.749023 454.768433,317.284943 458.753540,319.542236
	C459.886169,320.183807 460.821533,323.296814 460.224121,324.386841
	C459.255157,326.154907 458.253571,329.964874 454.321228,326.991302
z"
        />
        <path
          fill="#309E66"
          opacity="1.000000"
          stroke="none"
          d="
M518.416260,261.571320
	C520.641174,262.532837 522.866150,263.494324 525.698669,264.718414
	C524.171753,266.428101 523.038696,267.696747 521.402771,269.528473
	C520.075195,267.037140 518.968079,264.959442 517.989746,262.378723
	C518.118591,261.875671 518.416260,261.571320 518.416260,261.571320
z"
        />
        <path
          fill="#309E66"
          opacity="1.000000"
          stroke="none"
          d="
M517.966797,262.024841
	C517.644165,261.956207 517.473450,261.738434 517.302673,261.520630
	C517.558472,261.443604 517.814270,261.366577 518.243164,261.430420
	C518.416260,261.571320 518.118591,261.875671 517.966797,262.024841
z"
        />
        <path
          fill="#229759"
          opacity="1.000000"
          stroke="none"
          d="
M521.082153,282.659851
	C520.714233,282.889587 520.377441,282.719116 519.990234,282.256073
	C520.330994,282.062195 520.722168,282.160889 521.082153,282.659851
z"
        />
        <path
          fill="#26848D"
          opacity="1.000000"
          stroke="none"
          d="
M872.206604,273.097382
	C874.599243,271.945404 876.963684,271.219330 879.328125,270.493256
	C879.735779,270.932770 880.143372,271.372314 880.550964,271.811829
	C879.914001,274.175568 879.566040,276.686737 878.477905,278.820404
	C878.113586,279.534576 875.002991,279.892639 874.628174,279.363953
	C873.436523,277.682983 872.942200,275.507629 872.206604,273.097382
z"
        />
        <path
          fill="#26848D"
          opacity="1.000000"
          stroke="none"
          d="
M865.158569,302.368408
	C864.379395,300.669952 863.972046,298.932373 863.564758,297.194763
	C864.049194,296.985046 864.533630,296.775299 865.018066,296.565582
	C867.339844,298.014679 869.661560,299.463776 871.983337,300.912872
	C871.969543,301.249481 871.955750,301.586090 871.941956,301.922699
	C869.804810,302.058228 867.667603,302.193756 865.158569,302.368408
z"
        />
        <path
          fill="#1D7E88"
          opacity="1.000000"
          stroke="none"
          d="
M862.053101,197.951324
	C864.647278,201.446976 863.771667,204.340637 860.856140,206.404266
	C857.277832,208.936996 853.466736,212.395294 848.782959,209.713440
	C847.346924,208.891220 846.326843,205.275177 846.869019,203.462753
	C848.345337,198.527145 856.880859,195.550247 862.053101,197.951324
z"
        />
        <path
          fill="#1D7E88"
          opacity="1.000000"
          stroke="none"
          d="
M871.727295,230.953888
	C865.389465,231.852036 866.341797,226.942062 864.823181,223.477631
	C867.455505,222.638855 869.757507,221.905334 872.346252,221.080444
	C873.580261,224.535889 876.163879,227.677292 871.727295,230.953888
z"
        />
        <path
          fill="#3BBCBC"
          opacity="1.000000"
          stroke="none"
          d="
M625.207886,378.600433
	C625.162231,378.333405 625.278687,378.149261 625.389404,378.152679
	C625.498779,378.156006 625.602295,378.348999 625.708374,378.458832
	C625.594788,378.561035 625.481201,378.663239 625.207886,378.600433
z"
        />
        <path
          fill="#3BBCBC"
          opacity="1.000000"
          stroke="none"
          d="
M616.030334,377.040985
	C615.887451,377.108490 615.590454,377.020142 615.425903,376.757477
	C615.558411,376.583160 615.876221,376.885132 616.030334,377.040985
z"
        />
        <path
          fill="#49C1C0"
          opacity="1.000000"
          stroke="none"
          d="
M727.045654,415.696960
	C726.757874,412.770569 726.764526,410.142334 730.278809,410.842041
	C732.213379,411.227234 733.916321,412.775635 735.723694,413.799591
	C734.717712,415.278015 734.007874,417.508179 732.632812,418.049927
	C730.795715,418.773773 727.527344,420.637512 727.045654,415.696960
z"
        />
        <path
          fill="#59C6C5"
          opacity="1.000000"
          stroke="none"
          d="
M662.594360,401.743958
	C662.232666,403.558075 661.970520,405.007843 661.587463,407.126343
	C659.642212,406.210052 657.979553,405.426849 655.522400,404.269409
	C658.175232,402.160767 660.302368,400.469940 662.693909,398.569000
	C662.693909,399.393066 662.693909,400.386322 662.594360,401.743958
z"
        />
        <path
          fill="#2DC070"
          opacity="1.000000"
          stroke="none"
          d="
M665.870483,129.269440
	C669.015503,138.220016 668.011292,140.854172 660.557373,142.522141
	C658.868469,137.284897 657.161011,131.990341 655.427795,126.615952
	C659.956970,123.864365 663.266602,124.577454 665.870483,129.269440
z"
        />
        <path
          fill="#2BBF6E"
          opacity="1.000000"
          stroke="none"
          d="
M757.141724,133.177368
	C751.423584,136.519379 747.754578,134.528732 747.844421,128.427841
	C747.875671,126.308235 748.289185,124.140030 748.879700,122.095551
	C749.774719,118.996460 751.606995,116.537033 755.260803,117.165535
	C759.177002,117.839188 760.167908,120.980339 759.720947,124.333290
	C759.335144,127.227875 758.186157,130.020752 757.141724,133.177368
z"
        />
        <path
          fill="#36C276"
          opacity="1.000000"
          stroke="none"
          d="
M706.847473,123.775909
	C700.848877,123.013290 704.695435,118.552849 702.501038,116.055748
	C706.915527,115.181122 710.915527,113.413872 712.468018,118.446304
	C713.759216,122.632027 710.361084,123.306572 706.847473,123.775909
z"
        />
        <path
          fill="#2C786E"
          opacity="1.000000"
          stroke="none"
          d="
M149.309357,300.848389
	C151.110535,299.160339 153.000061,297.857971 155.447861,296.170807
	C156.227417,300.326294 159.401169,303.806122 155.050812,305.931732
	C150.974487,307.923431 150.271469,304.069183 149.309357,300.848389
z"
        />
        <path
          fill="#27746A"
          opacity="1.000000"
          stroke="none"
          d="
M161.912933,302.272461
	C161.012070,300.168060 160.389618,298.364929 159.625305,296.150848
	C161.958542,295.549042 163.784821,295.077972 166.673080,294.332977
	C166.673080,297.321136 166.944046,299.691528 166.468933,301.901520
	C166.359909,302.408691 163.685257,302.364288 161.912933,302.272461
z"
        />
        <path
          fill="#27746A"
          opacity="1.000000"
          stroke="none"
          d="
M185.062668,379.976318
	C183.854004,378.771301 182.983124,377.785645 181.699493,376.332886
	C186.444305,372.668365 190.975937,369.168518 195.759766,365.473846
	C198.444061,368.393677 200.172775,370.980286 197.971985,374.591736
	C195.217026,379.112549 190.126282,381.394257 185.062668,379.976318
z"
        />
        <path
          fill="#12847B"
          opacity="1.000000"
          stroke="none"
          d="
M453.179047,216.205963
	C452.998108,216.322769 452.555634,216.193695 452.241272,215.780548
	C452.552063,215.651031 452.734772,215.805573 453.179047,216.205963
z"
        />
        <path
          fill="#12847B"
          opacity="1.000000"
          stroke="none"
          d="
M448.297638,207.741852
	C448.302490,207.497879 448.474243,207.440323 448.646027,207.382751
	C448.585541,207.564590 448.525055,207.746429 448.297638,207.741852
z"
        />
        <path
          fill="#218C83"
          opacity="1.000000"
          stroke="none"
          d="
M406.202881,154.744217
	C408.850189,157.223358 409.890961,159.890442 406.961487,162.096802
	C402.478699,165.473053 400.861206,160.625153 397.741669,158.292709
	C400.733032,156.909714 400.829803,150.972549 406.202881,154.744217
z"
        />
        <path
          fill="#30948C"
          opacity="1.000000"
          stroke="none"
          d="
M434.357178,190.563965
	C434.261871,192.924667 433.839844,195.434402 433.187347,199.314636
	C430.703247,196.996994 429.366547,195.749832 427.462128,193.973007
	C430.032898,192.697052 432.031677,191.705017 434.357178,190.563965
z"
        />
        <path
          fill="#C4EDDE"
          opacity="1.000000"
          stroke="none"
          d="
M747.708740,294.131012
	C744.965698,294.145447 742.674805,294.145447 740.099304,294.145447
	C740.099304,278.882874 740.099304,264.162720 740.099304,249.493774
	C754.768066,246.945190 762.015808,252.349182 763.201172,266.813232
	C763.551453,271.087158 763.421326,275.480835 762.856323,279.732574
	C761.714722,288.323669 756.714905,292.983398 747.708740,294.131012
z"
        />
      </svg>
    );
  }
}
