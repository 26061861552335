import React, { Component } from "react";

export default class CloudDevImg extends Component {
  render() {
    // const theme = this.props.theme;
    return (
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="100%"
        viewBox="0 0 1200 800"
        enableBackground="new 0 0 1200 800"
        xmlSpace="preserve"
      >
        <path
          fill="#181C20"
          opacity="1.000000"
          stroke="none"
          d="
M1200.683472,53.736023
	C1201.000000,70.687561 1201.000000,87.375122 1201.000000,104.531342
	C1198.103760,106.672188 1196.598511,109.757072 1194.436646,111.855309
	C1190.169434,115.996902 1187.869385,118.493507 1193.553345,123.272652
	C1195.672607,125.054657 1193.338745,128.964783 1190.002197,130.133591
	C1187.922363,130.862213 1185.721436,130.686523 1183.569214,130.855804
	C1181.687134,131.003845 1179.838013,131.268509 1178.974365,133.253189
	C1177.127197,137.498489 1173.484863,138.745148 1169.320801,139.235474
	C1166.142334,139.609726 1163.268921,140.207733 1161.446411,143.535507
	C1159.716187,146.694626 1156.216309,146.786377 1153.004517,146.729813
	C1149.781250,146.673065 1147.519043,147.423874 1145.836548,150.734512
	C1143.485107,155.361649 1139.500977,155.195068 1136.576660,150.864014
	C1133.486450,146.287155 1131.741943,146.062195 1127.550903,149.796844
	C1125.935791,151.236115 1124.313110,152.683365 1122.904907,154.331955
	C1120.953003,156.617126 1118.570435,158.754883 1122.619507,161.521881
	C1124.523071,162.822632 1123.387207,164.860596 1122.378662,166.441788
	C1119.677124,170.677689 1115.139893,171.666367 1110.747437,172.423813
	C1105.203247,173.379837 1099.373657,173.181274 1095.039551,177.675354
	C1094.820923,177.902100 1094.444946,178.064514 1094.131958,178.063126
	C1083.587158,178.016586 1073.590210,184.887878 1062.438721,179.543930
	C1057.271484,177.067764 1050.946777,178.778717 1045.205811,180.015198
	C1044.181030,180.235886 1043.326416,180.764175 1042.853027,181.707169
	C1040.516968,186.361053 1036.335205,187.000198 1031.805786,186.743378
	C1023.621399,186.279312 1015.530823,187.769119 1007.372559,187.839249
	C1005.733032,187.853333 1004.022705,187.894180 1002.910645,189.529770
	C998.493164,196.026978 991.671265,197.389328 984.573425,197.690979
	C978.449341,197.951218 972.417114,197.887390 966.413147,195.461426
	C962.331543,193.812195 957.624023,194.371445 953.235229,196.135132
	C947.748657,198.339935 945.442444,197.134506 943.353516,191.523468
	C941.872253,187.544769 940.903503,187.369644 938.233337,190.598007
	C934.202271,195.471695 930.851685,195.515854 927.735413,190.265320
	C926.078003,187.472794 924.073730,186.653610 921.160889,187.111938
	C917.826843,187.636536 914.523193,187.908127 911.131348,185.811020
	C908.131348,180.928146 908.896057,175.577454 908.163208,170.617996
	C906.992676,162.696518 902.748535,157.384979 896.610474,152.469513
	C895.276550,149.994690 895.743774,147.834732 896.351685,145.272491
	C896.515381,139.427963 892.352905,135.880585 890.339294,131.389725
	C887.482239,125.017723 887.575989,123.595734 893.409729,119.540085
	C895.647644,117.984215 896.084717,115.864456 896.158875,113.479347
	C896.303894,108.815781 896.239624,104.149925 896.214661,99.485458
	C896.171326,91.384857 893.334229,88.156830 885.309509,88.067337
	C880.112122,88.009377 874.253357,87.626274 872.952148,95.012169
	C872.747986,96.171150 871.628540,96.951073 870.540649,97.414169
	C868.112305,98.447914 866.422546,100.139351 865.392578,102.565674
	C864.929749,103.655983 864.173401,104.706932 863.065125,105.084900
	C858.062622,106.790985 857.598633,110.753647 857.758728,115.161781
	C857.891602,118.822357 857.676025,122.490067 857.934998,126.152710
	C858.171875,129.503052 859.654602,131.438660 863.278442,131.202957
	C869.673706,130.787018 876.155701,132.268082 882.420837,129.184769
	C886.316284,127.267662 888.469543,129.177856 888.621521,133.693115
	C888.643982,134.359177 888.655823,135.014954 888.773743,135.674149
	C889.190491,138.003647 889.653259,140.395126 887.260010,142.010162
	C884.965332,143.558701 882.714355,142.686172 880.694580,141.438812
	C877.163391,139.258026 873.230591,139.459381 869.509155,139.873322
	C861.618835,140.750946 855.180908,137.613678 849.173584,133.253128
	C846.864136,131.576813 844.570618,130.779694 841.815125,130.782501
	C832.485291,130.791977 823.153870,130.657516 813.826477,130.850800
	C809.657532,130.937149 805.568970,131.640671 801.649231,133.273178
	C796.792542,135.295868 791.561584,135.295578 786.425476,135.555405
	C784.316345,135.662109 782.466797,135.641510 781.405579,137.868317
	C780.895752,138.938034 779.986023,139.789398 778.820557,140.104752
	C771.726624,142.024200 767.498657,147.344650 763.732971,153.049347
	C760.741577,157.581009 756.544006,156.246857 752.084961,155.945755
	C749.444519,155.880295 748.378662,153.676849 746.151978,152.825668
	C745.695129,152.422333 745.536194,152.238953 745.202698,151.742218
	C744.396179,146.336487 742.676575,141.906509 737.442871,139.257233
	C736.990540,138.918625 736.823853,138.765579 736.447876,138.347107
	C735.564087,136.978073 735.368103,135.598907 734.029053,134.561127
	C733.568481,134.303299 733.381775,134.204651 732.930908,133.933502
	C732.499695,133.634903 732.332703,133.508804 731.924316,133.181992
	C731.525146,132.842712 731.367432,132.704163 730.978760,132.352753
	C730.131958,131.478989 729.248108,131.323441 728.177917,130.763931
	C727.050049,129.969086 726.490112,129.179626 726.269287,127.767578
	C726.938232,123.996147 729.635437,122.937576 732.455688,122.521484
	C739.597595,121.467796 746.757874,120.297737 754.026550,121.244926
	C754.929993,121.362663 755.639526,121.103447 756.355347,120.014603
	C756.862610,118.959473 757.299927,118.317719 758.000671,117.390678
	C762.399719,110.134972 768.354919,107.583633 776.462280,108.686844
	C782.337463,109.486328 788.341980,108.214432 794.329590,108.655579
	C796.485046,108.814377 797.551025,105.368401 800.520020,105.385971
	C802.053528,109.365822 805.317383,108.972298 808.352600,109.102943
	C811.049805,109.219055 812.341248,108.012886 812.150085,105.147148
	C810.561401,106.238350 809.406738,108.345703 807.119812,107.365791
	C804.747131,106.349106 804.471619,104.089432 804.438782,101.913902
	C804.363647,96.935677 803.703308,92.170052 802.334229,87.280869
	C800.749451,81.621361 796.794922,80.380219 792.241455,79.557098
	C787.185913,78.643219 782.035217,78.449524 776.891052,78.650597
	C773.107117,78.798492 769.676208,77.874466 766.323120,75.441544
	C764.100647,74.791290 762.851562,73.270981 760.987549,72.056618
	C759.488953,70.508606 757.993408,69.580894 756.025757,68.768723
	C754.942261,67.898743 754.138855,67.203018 752.627563,67.714714
	C752.087463,67.753151 751.870178,67.749626 751.333801,67.686424
	C749.596619,66.713074 748.183167,67.197624 746.415527,67.653931
	C745.821228,67.619423 745.580994,67.571663 745.000671,67.362915
	C744.089905,66.850510 743.465576,66.462250 742.809326,66.580254
	C734.257935,68.117996 725.721741,66.058029 717.177612,66.500069
	C715.994812,66.561272 714.877441,66.782082 713.561035,67.718323
	C711.158386,68.776451 709.012695,68.455193 706.520142,68.360260
	C705.006409,68.013680 703.980835,67.284027 702.378052,67.454605
	C699.774292,67.703239 697.595703,68.463280 694.910278,68.273575
	C693.984070,68.083893 693.497253,67.854156 692.779907,67.224014
	C691.393127,63.781723 693.360474,62.254398 695.680786,61.022778
	C698.074036,59.752411 700.730713,59.320400 703.390686,59.072319
	C715.673279,57.926792 727.863892,57.646034 740.197571,59.575748
	C748.058411,60.805649 756.362427,61.904625 764.002991,57.237499
	C767.045837,55.378819 770.851685,56.253700 774.333008,56.197601
	C777.997864,56.138546 781.667053,56.095924 785.329041,56.205570
	C788.886841,56.312084 791.680908,55.743839 793.323730,51.883080
	C794.744629,48.543671 798.242126,48.215633 801.498352,48.228779
	C804.804443,48.242123 807.723572,47.936993 809.214417,44.218410
	C809.941589,42.404724 811.808655,41.638664 813.846985,42.192776
	C815.746399,42.709118 816.299622,44.334637 816.543457,46.031849
	C817.090637,49.840206 816.638489,53.689804 816.929382,57.512421
	C817.090027,59.624580 817.443909,61.658745 820.137390,61.726910
	C823.094482,61.801746 823.117981,59.467850 823.095703,57.499287
	C823.039978,52.574482 824.418213,48.634689 829.474426,46.783157
	C830.073853,46.563663 829.637390,47.080853 829.872375,46.530407
	C832.972351,39.267921 840.731445,39.090523 846.154724,35.416248
	C852.114136,31.378815 858.149414,31.133530 864.648376,31.179886
	C868.081665,31.204376 871.257080,31.710594 873.843262,34.271027
	C875.820923,36.229034 877.973999,36.231289 880.051270,34.352890
	C882.964478,31.718437 886.805725,30.209812 888.698425,26.294748
	C889.859558,23.893082 892.629211,23.415564 895.215210,23.278498
	C901.981750,22.919846 908.914734,24.011040 915.345032,20.742064
	C916.500854,20.154476 918.007996,20.360115 919.290405,20.738949
	C927.963196,23.300875 936.847534,21.739305 945.627563,22.176891
	C948.947083,22.342337 950.895691,21.212776 951.600647,17.709970
	C952.317993,14.145766 955.031067,12.272731 958.532166,12.376580
	C971.271851,12.754455 984.150879,10.320282 996.723083,14.555954
	C1000.336853,15.773467 1004.351074,15.762463 1007.978882,14.054841
	C1013.000366,11.691254 1017.495361,12.739924 1021.388855,16.199287
	C1023.272461,17.872862 1024.595459,18.057636 1026.510498,16.558765
	C1027.853760,15.507350 1029.597412,15.263611 1031.270752,15.294658
	C1035.241089,15.368316 1038.526611,14.776202 1041.091431,10.937119
	C1043.237061,7.725347 1047.142578,8.031293 1049.462891,11.484381
	C1051.755005,14.895533 1053.943115,14.907345 1057.420898,13.577646
	C1066.725220,10.020213 1076.451172,13.047708 1083.026733,21.157873
	C1084.700562,23.222349 1085.845337,22.757910 1087.222534,20.770166
	C1091.489746,14.611354 1092.297241,14.483503 1098.143433,18.588604
	C1098.973633,19.171476 1099.780884,19.913616 1100.789795,19.872726
	C1109.988403,19.499889 1118.469238,24.563499 1127.836914,23.266268
	C1132.334961,22.643375 1135.870605,24.260746 1139.070923,28.140388
	C1142.070190,31.776274 1147.097046,31.653303 1151.562378,30.205744
	C1159.031982,27.784311 1160.447021,28.306393 1162.903198,35.670643
	C1163.661377,37.943665 1164.923950,38.912975 1167.186157,39.061787
	C1170.720459,39.294273 1174.071533,38.929867 1177.440796,37.573532
	C1181.876587,35.787766 1185.321777,37.062958 1186.123779,41.248077
	C1187.407837,47.948162 1192.949463,49.520245 1197.695435,52.181839
	C1198.545288,52.658436 1199.591309,52.794727 1200.683472,53.736023
z"
        />
        <path
          fill="#121E24"
          opacity="1.000000"
          stroke="none"
          d="
M1.335055,58.271851
	C1.000000,40.521961 1.113546,23.042578 0.906953,5.566980
	C0.864156,1.946819 1.514258,0.888536 5.422976,0.894080
	C100.969398,1.029602 196.516068,1.000000 292.531342,1.000000
	C297.923950,8.593325 305.323486,10.213426 313.774200,10.233045
	C325.232513,10.259646 336.559235,12.928017 348.080444,12.341699
	C348.902618,12.299858 349.778595,12.447775 350.523102,12.795556
	C360.068787,17.254635 370.504242,16.750202 380.520691,18.568110
	C387.612122,19.855150 394.644684,18.690540 401.464294,20.034540
	C412.697632,22.248390 424.344269,22.000256 435.288971,25.831224
	C436.377563,26.212259 437.614105,26.194344 438.760498,26.088543
	C449.208710,25.124262 459.432770,27.368319 469.344299,29.725046
	C476.401306,31.403027 483.794922,31.556229 490.626740,34.381016
	C494.369507,35.928543 498.531250,35.053177 502.507050,35.128319
	C506.999207,35.213219 511.523010,34.911366 515.443237,37.817699
	C516.786926,38.813869 518.300049,38.408413 520.139160,37.654137
	C520.842163,37.513271 521.132507,37.510277 521.824951,37.675476
	C523.308105,39.024105 523.372742,40.410923 522.895752,42.257942
	C518.864197,46.330235 515.952148,50.732948 513.098145,55.679321
	C505.861420,61.780312 497.434784,60.813198 489.447235,60.409248
	C481.836090,60.024330 474.250061,58.585514 466.672424,57.468300
	C463.196228,56.955784 459.777924,56.283646 456.811035,54.196758
	C454.296417,52.427990 451.354919,52.823227 448.571228,52.902309
	C441.341736,53.107689 434.282806,52.881966 427.829651,48.899151
	C425.877045,47.694023 423.465057,46.968788 421.139648,47.062840
	C413.806519,47.359417 406.954346,45.562958 400.273010,42.801968
	C398.385010,42.021759 396.363190,41.716240 394.387207,41.932613
	C385.445526,42.911766 376.472504,42.071095 367.526459,42.532455
	C366.190155,42.601372 364.739288,42.893795 363.571014,42.309292
	C359.191620,40.118217 354.772583,38.600384 349.774597,39.872162
	C348.835815,40.111038 347.658905,39.660877 347.039001,38.884354
	C342.920044,33.724895 336.904724,34.381653 331.415466,34.001980
	C319.760345,33.195827 308.155121,34.886997 296.519867,35.314171
	C295.194489,35.362835 293.811737,35.865780 292.546692,35.531311
	C274.923370,30.871956 256.222565,35.588570 238.783295,29.337549
	C237.357010,28.826307 235.594269,28.659473 234.409241,29.570415
	C228.504242,34.109596 222.530807,31.195215 216.771515,29.612726
	C213.028519,28.584253 209.377075,27.548660 205.525711,27.887728
	C191.331924,29.137316 177.372833,25.737215 163.243134,25.866377
	C156.186356,25.930885 149.717209,27.234911 143.396530,31.158072
	C134.258270,36.830074 123.798866,35.361687 113.701401,33.888950
	C110.568558,33.432022 107.479210,32.922516 104.304344,32.847679
	C100.583641,32.759975 97.573006,33.248486 95.967171,37.440285
	C94.712212,40.716198 91.227165,41.250988 88.247520,40.626331
	C79.310455,38.752750 70.771729,42.372044 61.948868,42.166008
	C58.108604,42.076336 54.644806,44.733982 51.632717,46.871315
	C47.099350,50.088108 42.374737,51.117290 37.073391,50.889812
	C32.544056,50.695465 28.527454,51.549091 25.974957,55.970947
	C24.828966,57.956219 22.566652,58.780048 20.333967,58.645737
	C15.166497,58.334892 10.056660,58.462517 4.962156,59.452480
	C3.820587,59.674309 2.647177,59.286522 1.335055,58.271851
z"
        />
        <path
          fill="#15191C"
          opacity="1.000000"
          stroke="none"
          d="
M910.662109,185.293777
	C914.581787,185.633270 918.040649,186.224304 921.793152,185.202896
	C925.209656,184.272919 928.479614,185.011780 929.165039,189.613693
	C929.459656,191.592087 930.898682,192.880096 933.007446,192.875519
	C935.116455,192.870941 936.552429,191.572876 936.830322,189.596313
	C937.261597,186.528351 939.051575,185.839142 941.739136,186.026688
	C944.761169,186.237534 944.855591,188.298828 945.039429,190.613571
	C945.463135,195.949081 947.811462,196.971146 952.732971,194.606979
	C958.208862,191.976517 963.685486,192.521301 969.440369,194.509476
	C977.091492,197.152725 985.077698,197.133804 992.777405,194.429596
	C996.581726,193.093536 1001.328247,192.561020 1002.000000,186.956039
	C1002.120300,185.952362 1003.981018,185.986832 1005.150696,186.014435
	C1014.961914,186.246155 1024.695557,184.283295 1034.524414,184.993164
	C1037.823853,185.231461 1040.549927,184.565475 1041.086792,180.599243
	C1041.256714,179.343369 1041.983032,178.357071 1043.281860,178.190948
	C1049.705322,177.369370 1056.349365,175.681442 1062.559082,177.416260
	C1066.686890,178.569443 1070.517090,180.338272 1074.955933,180.044357
	C1076.990723,179.909622 1079.016235,179.856873 1080.640381,178.558029
	C1082.491455,177.077744 1084.562988,176.977661 1086.761841,176.981384
	C1090.819946,176.988251 1094.812378,177.679245 1097.748413,173.177872
	C1099.312988,170.779343 1103.409058,171.243774 1106.180664,171.256836
	C1110.165771,171.275635 1113.540283,169.575195 1117.238159,168.934586
	C1120.126465,168.434235 1120.943848,165.700790 1122.248657,163.753998
	C1123.293823,162.194427 1121.341187,162.179779 1120.268555,161.984131
	C1116.543945,161.304764 1116.604736,159.723389 1118.540283,156.817612
	C1121.077026,153.009338 1124.541992,150.156357 1127.698364,147.024353
	C1131.411621,143.339828 1136.358887,144.640060 1137.848511,149.461914
	C1138.444336,151.390533 1138.894409,153.087128 1141.276733,152.870392
	C1143.343140,152.682388 1144.823242,151.336945 1144.855957,149.328110
	C1144.922241,145.254242 1147.376709,144.817612 1150.541138,144.982681
	C1152.368408,145.078003 1154.205078,144.977570 1156.037231,144.997421
	C1157.613037,145.014496 1159.659058,144.744919 1159.910889,143.315857
	C1161.128052,136.406830 1166.981934,138.030533 1171.190063,137.188416
	C1174.287964,136.568497 1176.558472,136.037552 1177.146484,132.481369
	C1177.558960,129.986237 1179.464966,128.996399 1181.919556,129.004761
	C1184.085083,129.012146 1186.250732,129.007156 1188.415894,128.974350
	C1190.669434,128.940201 1192.346680,127.925179 1192.798828,125.670784
	C1193.270142,123.321411 1191.928589,121.727264 1189.777588,121.215965
	C1187.615967,120.702133 1185.992432,120.075165 1186.020508,117.462967
	C1186.052856,114.457626 1188.049561,114.132256 1190.382324,113.869980
	C1192.416260,113.641319 1193.959961,112.220039 1193.855591,110.222931
	C1193.604736,105.419037 1196.276489,104.592728 1200.575562,105.000031
	C1201.000000,131.687561 1201.000000,158.375122 1201.000000,185.531342
	C1197.700073,187.936493 1194.015625,189.111572 1193.257690,193.995590
	C1192.848022,196.635757 1189.368408,196.798782 1186.855225,196.745575
	C1182.712158,196.657822 1179.671509,198.890701 1176.096191,200.477798
	C1167.759644,204.178421 1159.981934,210.061050 1150.565552,210.562531
	C1142.822998,210.974838 1135.344727,211.739685 1128.189575,214.867050
	C1125.795776,215.913376 1123.313843,215.459961 1120.871216,214.599091
	C1113.571777,212.026474 1106.385864,214.680008 1099.438354,216.028473
	C1095.915283,216.712296 1092.323608,217.938095 1088.636475,218.745209
	C1082.244385,220.144424 1076.085571,218.588852 1069.833374,218.783203
	C1065.782837,218.909119 1061.857788,219.184952 1058.233521,221.199219
	C1057.361816,221.683685 1056.389526,222.221008 1055.426392,222.200089
	C1047.333618,222.024231 1039.877197,225.404373 1031.985229,226.310410
	C1031.322510,226.386490 1030.650757,226.498138 1029.991943,226.432297
	C1012.592163,224.693665 995.236084,228.177643 977.837402,226.867004
	C974.966125,226.650726 972.185242,227.102493 969.803345,228.967621
	C969.283447,229.374741 968.642212,229.838623 968.025391,229.840088
	C959.353394,229.860596 951.366577,234.218521 942.617432,233.791534
	C938.878174,233.609039 936.149353,232.265869 935.258301,228.376907
	C934.554871,225.306595 932.602539,224.916367 929.958923,225.685455
	C927.713440,226.338684 925.405029,226.606842 923.069580,226.815094
	C917.185059,227.339813 912.522339,225.847260 910.186279,219.345795
	C908.315125,210.795563 910.184448,202.465820 908.780151,194.252640
	C908.290466,191.388580 908.731567,188.283417 910.662109,185.293777
z"
        />
        <path
          fill="#1C2024"
          opacity="1.000000"
          stroke="none"
          d="
M1200.669922,589.722168
	C1201.000000,658.911377 1200.967285,727.822876 1201.093872,796.734009
	C1201.100342,800.278625 1200.439087,801.119751 1196.776367,801.105164
	C1155.163818,800.939453 1113.550415,801.000000 1071.468628,801.000000
	C1068.119507,796.691101 1068.697510,791.969666 1070.210205,787.590820
	C1071.556396,783.694153 1075.503906,782.658813 1078.995117,783.840576
	C1086.293457,786.311157 1093.112549,782.336731 1100.212036,782.961304
	C1101.953857,783.114624 1102.832031,781.798767 1103.147339,780.133728
	C1104.239746,774.365479 1107.495483,769.394226 1109.149536,763.768677
	C1110.370361,759.617310 1113.602661,755.994446 1117.559814,753.561646
	C1120.663940,751.653381 1122.392212,749.059937 1122.219727,745.345154
	C1121.957520,739.695557 1125.859619,735.843140 1128.346191,731.413147
	C1129.415161,729.508789 1131.690186,729.398254 1133.711426,729.306885
	C1137.374756,729.141296 1141.042236,729.261414 1144.707886,729.224854
	C1152.452515,729.147583 1153.130615,728.455444 1153.222778,720.526550
	C1153.253784,717.860718 1153.129883,715.190186 1153.306030,712.529602
	C1153.650024,707.330994 1155.206543,705.748596 1160.302979,705.301697
	C1161.630371,705.185242 1162.965332,705.180847 1164.296265,705.098450
	C1167.265991,704.914551 1168.947021,703.406677 1169.057739,700.408142
	C1169.328613,693.080933 1169.382690,685.751648 1169.014771,678.424927
	C1168.963989,677.412354 1168.656494,676.469849 1168.098022,675.607422
	C1160.870972,664.445679 1162.887939,652.696106 1168.816406,642.557434
	C1172.016602,637.084778 1172.960083,635.248413 1167.439697,632.047729
	C1165.381592,630.854553 1165.534546,627.929993 1165.778809,625.555420
	C1166.121948,622.220459 1165.067749,621.038391 1161.483154,621.830139
	C1155.312256,623.192871 1154.387817,622.507019 1151.987061,616.284973
	C1150.922729,613.526367 1149.325439,611.715637 1146.062988,612.096680
	C1143.341919,612.414551 1141.355591,611.166382 1139.492310,609.239014
	C1133.611694,603.156250 1126.024780,599.541687 1118.817871,595.414490
	C1117.166870,594.468994 1115.285889,593.406677 1112.737183,594.360962
	C1104.963623,594.340515 1097.647339,593.737427 1090.088379,592.232971
	C1088.938232,589.598145 1091.002075,588.892944 1092.449097,587.413208
	C1096.100708,585.595642 1099.560303,585.045532 1103.549927,585.679016
	C1106.084839,587.391663 1108.197144,588.098022 1110.439209,585.250732
	C1111.570190,584.981445 1112.335571,585.055725 1113.453735,585.500732
	C1117.632690,588.263794 1121.914307,587.186035 1125.911255,586.818909
	C1130.900757,586.360657 1135.869141,586.131775 1140.863770,586.138916
	C1145.686890,586.145874 1150.444580,586.318665 1154.827393,583.460876
	C1157.391479,581.789062 1160.589722,582.171692 1163.612549,582.407898
	C1171.384033,583.015198 1179.109619,584.569153 1186.969238,583.486023
	C1188.824585,583.230347 1190.781006,583.583862 1192.153442,584.864563
	C1194.536499,587.088257 1197.598999,587.947754 1200.669922,589.722168
z"
        />
        <path
          fill="#15191C"
          opacity="1.000000"
          stroke="none"
          d="
M1201.000000,53.531342
	C1197.104614,54.205250 1193.256348,54.398922 1191.161377,50.003845
	C1190.900757,49.457394 1189.727051,49.081753 1188.975098,49.079327
	C1185.503052,49.068119 1185.046753,46.963379 1184.952393,44.110561
	C1184.751953,38.056259 1182.268311,36.640678 1177.072021,39.619984
	C1172.803955,42.066978 1168.309814,41.067093 1163.949341,40.610096
	C1161.483032,40.351616 1161.112915,37.853542 1160.951904,35.509926
	C1160.582153,30.128380 1157.916870,28.546766 1153.445190,31.453638
	C1148.295776,34.801144 1143.464478,31.446003 1138.500488,31.487244
	C1138.157227,31.490093 1137.548950,30.868738 1137.500854,30.483179
	C1136.712646,24.162313 1132.012329,25.072165 1127.686646,24.962906
	C1121.809937,24.814474 1115.753540,26.130234 1110.267456,22.427977
	C1108.967163,21.550537 1106.701416,22.024635 1104.879028,22.016899
	C1101.230103,22.001406 1097.771240,22.387335 1095.330322,18.338444
	C1093.720459,15.667996 1089.897949,17.039625 1089.191895,20.566818
	C1088.680054,23.123972 1088.281738,25.017071 1085.094727,25.024918
	C1082.039185,25.032440 1080.955322,23.549686 1080.910400,20.734011
	C1080.887451,19.293165 1080.010620,17.991493 1078.745850,17.596502
	C1071.169067,15.230126 1063.789795,11.030820 1055.583496,16.370995
	C1053.104248,17.984379 1049.633301,17.933010 1048.850464,13.413368
	C1048.507935,11.436591 1047.126221,10.137286 1045.017822,10.124706
	C1042.904175,10.112097 1041.454590,11.408326 1041.177856,13.380478
	C1040.748047,16.443970 1038.908936,16.866440 1036.281250,17.068928
	C1031.907471,17.405960 1026.279175,14.759016 1024.421387,21.967209
	C1024.049927,23.408094 1022.655334,21.838184 1022.150452,20.776815
	C1019.187195,14.547980 1014.619446,13.169062 1008.297729,16.029673
	C1004.071899,17.941866 999.363464,17.738871 995.182983,16.068399
	C990.531860,14.209845 985.884094,13.902851 981.035828,13.972604
	C973.706909,14.078047 966.374451,14.070836 959.045349,13.972113
	C955.254761,13.921051 952.807251,15.168293 953.024780,19.356808
	C953.205261,22.831593 951.629639,24.093653 948.249512,24.036732
	C940.754639,23.910522 933.255981,23.970699 925.759277,24.016869
	C923.871094,24.028498 922.135559,23.640081 920.480347,22.767712
	C918.774170,21.868452 916.554626,21.315279 915.187195,22.281395
	C908.949219,26.688604 901.966736,24.581059 895.299438,24.929956
	C891.935364,25.105999 889.404358,25.664778 888.860107,29.565361
	C888.609070,31.364323 887.338257,32.593395 885.542297,32.861591
	C883.721741,33.133465 882.705566,34.147392 881.599670,35.527996
	C878.787720,39.038109 875.156555,39.140244 872.477966,35.576065
	C870.952271,33.545883 869.322693,32.919659 867.000854,32.976913
	C863.337891,33.067245 859.670349,33.045948 856.006104,32.985786
	C853.380981,32.942684 851.337585,33.631844 849.433105,35.744942
	C846.463318,39.040264 841.499817,38.522324 837.946472,40.929123
	C837.455505,41.261650 836.648621,41.121292 835.989990,41.213966
	C831.467834,41.850281 834.899841,48.852257 829.250977,49.169102
	C823.391235,49.497776 825.029785,54.641903 825.095337,58.148392
	C825.173279,62.313713 824.048706,64.549164 819.456970,64.002968
	C816.411499,63.640709 815.029541,62.002399 815.013123,59.056053
	C814.990784,55.057762 815.048096,51.058422 814.974548,47.061314
	C814.945923,45.507557 815.500671,43.408344 813.003967,43.198139
	C810.876404,43.019028 810.074158,44.485462 810.024475,46.373112
	C809.939148,49.617435 807.845886,50.078762 805.249634,50.009121
	C803.251953,49.955532 801.251221,50.026283 799.252380,50.000294
	C796.371643,49.962841 794.234314,51.056015 794.078064,54.145916
	C793.899658,57.672424 791.758728,58.044037 788.980530,58.019012
	C782.150452,57.957497 775.318787,58.055294 768.489197,57.970795
	C766.044617,57.940544 763.876282,58.248505 762.199829,60.256115
	C761.324463,61.304302 760.163696,62.042229 758.726929,61.959412
	C751.133179,61.521698 743.597839,62.260193 735.904053,60.759117
	C726.155640,58.857166 716.023743,59.558975 706.087219,60.560452
	C702.996399,60.871971 699.759460,60.783092 696.807007,62.186169
	C694.978821,63.054955 693.006653,63.692883 693.008423,66.617439
	C692.753235,67.665382 692.261963,68.113312 691.356323,68.603546
	C690.605164,68.847786 690.158875,68.901466 689.372314,68.865364
	C688.383057,68.702621 687.763550,68.450165 686.805054,68.169388
	C685.001892,67.354179 683.276978,68.276993 681.528076,67.154343
	C680.533875,66.118393 680.147034,65.176224 680.014160,63.717781
	C680.652771,60.252796 683.036255,59.478500 685.624878,59.349564
	C688.962158,59.183338 691.439941,57.554802 694.145447,55.763294
	C700.076782,51.835663 706.293579,48.213108 713.781677,48.217308
	C717.021301,48.219128 719.747864,47.614468 720.726196,43.984970
	C720.853394,43.513012 721.120972,42.991791 721.503723,42.722462
	C728.821472,37.573502 734.350647,28.882763 745.224670,30.745441
	C747.480408,31.131842 748.993774,29.573189 750.430969,28.245811
	C753.538269,25.375795 757.101318,24.040972 761.332397,24.200357
	C767.380615,24.428194 772.771729,22.660521 777.441040,18.698965
	C780.002808,16.525515 783.199158,16.287243 786.399536,16.232525
	C792.198364,16.133381 797.910217,15.812079 803.710205,14.836989
	C809.836304,13.807058 816.449951,14.461440 822.488831,16.040373
	C829.101013,17.769226 834.452087,15.338071 839.329346,12.214285
	C844.202271,9.093300 849.247192,7.398059 854.739380,7.340948
	C864.252197,7.242029 873.574707,5.119578 883.069275,5.098633
	C885.875488,5.092442 887.583496,3.248560 889.715820,1.293310
	C992.043762,1.000000 1094.087646,1.042264 1196.130981,0.861247
	C1200.697510,0.853147 1201.148926,2.218237 1201.092896,6.120564
	C1200.868408,21.765162 1201.000000,37.414867 1201.000000,53.531342
z"
        />
        <path
          fill="#1C2D32"
          opacity="1.000000"
          stroke="none"
          d="
M1.335784,193.278503
	C1.000000,179.979111 1.000000,166.958206 1.000000,153.468658
	C1.793523,152.119019 2.445859,151.174713 2.487913,149.924820
	C2.610071,146.294022 5.011469,144.685242 8.085454,143.785004
	C10.800375,142.989899 13.501420,141.998138 16.243876,141.475555
	C22.700048,140.245300 26.651873,135.914612 30.082956,130.957642
	C32.441578,127.550087 35.385628,125.240578 39.521904,125.357857
	C43.993584,125.484650 46.402340,123.342430 47.389637,119.338844
	C48.742493,113.852859 52.264145,111.932632 57.680527,112.113464
	C63.226730,112.298630 68.162498,110.690765 71.708611,105.965950
	C73.512154,103.562920 76.163368,102.568771 79.109818,102.325218
	C81.770561,102.105270 84.445663,102.059761 87.099731,102.199089
	C90.619835,102.383881 93.122215,101.299782 94.699821,97.914581
	C96.692436,93.638855 100.482506,92.283447 104.986374,92.195518
	C110.167542,92.094368 115.234962,93.337723 120.427460,93.226944
	C123.817650,93.154617 125.866737,92.349236 126.940681,88.660637
	C128.856461,82.080673 131.377869,80.454758 138.181152,80.224594
	C147.303558,79.915970 156.400009,80.363243 165.542084,78.778183
	C170.259064,77.960350 175.313736,78.931160 180.297852,77.828018
	C185.106323,76.763748 190.223480,77.130516 194.098663,73.230988
	C194.883102,72.441620 196.271713,72.493393 197.416275,72.260513
	C205.393585,70.637337 213.341492,72.443329 221.303986,72.472549
	C222.814056,72.478088 224.336227,73.090080 225.359589,74.216904
	C228.365936,77.527184 232.502365,78.341278 236.352386,77.850571
	C245.442596,76.691963 254.284088,78.304771 263.196625,79.208214
	C272.353241,80.136414 281.483063,79.003105 290.629333,79.066902
	C298.366180,79.120888 305.925354,79.694931 313.268158,82.163658
	C315.940063,83.061989 318.409241,82.906883 321.017883,81.818748
	C330.042297,78.054443 338.237122,80.277092 346.150452,85.919594
	C343.758545,91.657417 341.339142,97.210770 334.082947,98.461708
	C331.171936,98.612640 328.753601,99.344803 326.253845,99.496773
	C322.828644,99.705009 319.736664,98.802307 318.266174,95.552155
	C315.981079,90.501495 311.820557,89.352074 307.120361,90.107063
	C297.898346,91.588409 288.432343,89.658501 279.303894,92.401649
	C276.850159,93.139008 274.130402,92.530487 272.446594,90.381599
	C269.099457,86.109917 264.234772,85.501038 259.512390,84.957855
	C253.055145,84.215134 246.541611,84.142502 240.218964,86.237762
	C239.271393,86.551781 238.162872,86.875435 237.267303,86.573074
	C230.668716,84.345291 224.153656,86.549622 217.604904,86.785469
	C214.267761,86.905663 210.983154,86.605324 207.710648,85.727592
	C201.872833,84.161819 197.295898,86.651237 195.480942,92.279488
	C194.575592,95.086983 192.837585,96.851112 190.004730,97.524391
	C187.409058,98.141296 184.787354,98.609184 182.106720,98.684090
	C178.244476,98.792007 175.434601,97.415443 173.756332,93.664314
	C171.896545,89.507507 167.367004,88.943474 165.125046,92.947418
	C162.427719,97.764641 158.385910,99.090332 153.563278,99.369301
	C147.623093,99.712906 141.849503,102.197235 135.750473,100.624725
	C133.322006,99.998596 132.557968,102.006058 131.963348,103.725403
	C130.711288,107.345695 129.809830,111.075195 128.941391,114.804871
	C127.717056,120.063042 120.785431,126.878334 115.524460,128.037842
	C111.574974,128.908295 107.570366,128.776337 103.579315,128.865250
	C100.469788,128.934555 98.533524,130.281479 96.768204,132.981552
	C93.703674,137.668793 89.478622,141.394226 84.722374,144.422653
	C83.752861,145.039963 82.063873,145.164124 81.862846,146.253464
	C80.917542,151.376038 76.095207,153.308670 73.413902,156.970291
	C72.613892,158.062775 71.722397,159.186752 70.437225,159.553802
	C66.867554,160.573257 64.977692,162.949936 63.941479,166.402847
	C63.396103,168.220154 61.578510,169.099335 60.051323,170.135178
	C58.041847,171.498154 57.301907,174.056168 59.291088,175.098938
	C65.929939,178.579132 64.548752,185.353546 66.465225,190.752640
	C66.794327,191.679794 66.704178,192.730515 66.766312,193.728760
	C67.285591,202.071533 69.025406,203.824890 77.325630,204.144043
	C85.940460,204.475296 94.176414,205.821625 100.765877,212.113266
	C102.394394,213.668213 104.530739,213.203110 106.486488,213.198792
	C112.807152,213.184845 119.185181,212.651047 124.911034,216.289505
	C125.872269,216.900330 127.115601,217.082474 128.296631,217.038422
	C141.626633,216.541306 154.798721,218.909271 168.064865,219.611572
	C181.975403,220.347977 195.941895,220.684586 209.843002,221.823822
	C212.529556,222.044006 215.143051,221.873703 217.800568,221.581146
	C223.632523,220.939133 229.356476,220.732956 234.812851,223.820999
	C238.737411,226.042099 243.307083,224.946686 247.595337,225.277100
	C254.309631,225.794403 257.677399,230.070145 256.311829,237.003479
	C255.382889,237.557159 254.665039,237.746857 254.089920,237.391159
	C244.207916,231.279037 232.887650,232.142670 222.043030,230.985657
	C216.121658,230.353928 210.077530,230.843185 204.089142,230.881760
	C199.247360,230.912933 194.443466,230.934708 189.972733,228.570602
	C188.323471,227.698486 186.389420,227.933670 184.567307,227.905746
	C179.411011,227.826691 174.253372,227.837509 169.097000,227.881134
	C151.799576,228.027405 134.744751,226.153915 117.639954,223.438171
	C107.060051,221.758408 96.608475,219.340652 86.331230,216.853561
	C77.504639,214.717529 69.489014,210.361862 59.728195,211.010956
	C54.992664,211.325836 49.877018,208.612427 44.664158,208.573059
	C42.610767,208.557556 40.773624,207.502365 39.413315,205.976547
	C36.595448,202.815903 32.992260,201.377991 28.936008,200.764847
	C27.782497,200.590485 26.463375,200.459534 25.653013,199.675598
	C20.293543,194.490875 13.578975,194.804260 6.901942,194.829041
	C5.074713,194.835815 3.223417,194.764969 1.335784,193.278503
z"
        />
        <path
          fill="#212226"
          opacity="1.000000"
          stroke="none"
          d="
M1112.780762,593.322083
	C1115.402832,591.013184 1117.641235,592.809204 1119.378418,593.840454
	C1127.428833,598.619385 1136.525757,601.983215 1142.302368,609.994995
	C1143.228271,611.279053 1144.322388,610.552002 1145.445557,610.377380
	C1150.579956,609.578918 1153.023804,611.257690 1153.963257,616.114502
	C1154.975586,621.347900 1156.525146,622.267090 1161.276611,620.076416
	C1163.028809,619.268616 1164.718140,618.380432 1166.377075,619.657959
	C1168.139038,621.014893 1168.451538,623.204346 1167.724243,625.136658
	C1165.847778,630.122620 1168.585938,632.316650 1172.645996,633.658142
	C1174.473267,634.261963 1176.100342,634.541443 1176.023315,637.083191
	C1175.956787,639.279541 1174.792358,639.869629 1172.958984,640.222961
	C1171.642334,640.476807 1170.431396,641.190674 1169.833862,642.500427
	C1165.880737,651.165710 1165.352173,660.307129 1166.128662,669.622375
	C1166.142212,669.784180 1166.170532,669.996094 1166.276001,670.091492
	C1174.295044,677.346497 1169.891724,686.912170 1170.994751,695.450256
	C1171.037109,695.777832 1171.000000,696.115601 1171.000000,696.448608
	C1170.999268,706.537231 1170.676147,707.041565 1160.755615,706.919312
	C1156.462402,706.866455 1154.639893,708.211670 1154.939453,712.646606
	C1155.219238,716.789856 1155.027100,720.967651 1154.979126,725.129578
	C1154.934814,728.988464 1152.903320,730.955994 1149.063599,730.983215
	C1143.902344,731.019836 1138.738892,731.084290 1133.580322,730.964478
	C1130.982910,730.904114 1129.903076,731.745728 1128.481567,734.256165
	C1125.750244,739.079407 1123.061157,743.531189 1123.818848,749.392395
	C1124.067627,751.316040 1122.208374,753.043884 1120.693970,753.606079
	C1116.400635,755.200012 1114.948486,759.592041 1111.595093,762.028442
	C1110.618408,762.738037 1109.952881,763.807617 1110.067749,765.033752
	C1110.436523,768.971619 1108.300659,771.989441 1106.519165,775.144653
	C1105.778564,776.456360 1104.881958,777.776367 1105.057739,779.320435
	C1105.613525,784.204407 1103.087036,785.320557 1098.788086,785.062561
	C1095.009155,784.835876 1091.204346,784.652161 1087.561035,786.531189
	C1085.228027,787.734314 1082.775024,786.708374 1080.365112,785.773926
	C1074.485474,783.493958 1071.039551,785.903503 1071.002686,792.079163
	C1070.986816,794.743042 1071.000000,797.407104 1071.000000,800.535522
	C1044.645752,801.000000 1018.291565,801.000000 991.468628,801.000000
	C989.285095,797.343872 990.569153,787.508179 993.139099,785.098083
	C995.278259,783.092102 998.035278,783.354492 1000.668945,783.231567
	C1007.357849,782.919312 1008.057129,782.329590 1008.191345,775.634766
	C1008.271606,771.630249 1009.997559,768.940552 1012.464539,765.692566
	C1015.878845,761.197327 1021.286133,758.770508 1023.787415,753.580322
	C1024.522217,752.055725 1026.127441,751.253479 1027.616455,750.515747
	C1029.071411,749.794861 1029.980103,748.738403 1030.164062,747.112427
	C1030.660034,742.729248 1033.322632,740.375549 1037.751221,739.163879
	C1041.828735,736.599792 1046.130005,735.309204 1049.798462,732.748535
	C1052.036865,731.186096 1054.524658,731.537720 1056.637329,733.310547
	C1058.525513,734.894958 1060.460205,736.405212 1062.824951,737.983887
	C1063.294800,738.406250 1063.464233,738.594849 1063.833984,739.105347
	C1064.410156,740.623474 1064.940918,741.737854 1065.962769,743.023926
	C1067.403564,747.141113 1068.717163,750.744995 1072.231079,753.315735
	C1074.665039,755.096375 1074.692383,758.304749 1074.726440,761.153198
	C1074.768921,764.719971 1076.533081,766.182983 1080.004150,766.253723
	C1083.444702,766.323853 1086.681885,766.026489 1086.856689,761.715820
	C1087.119141,755.248718 1090.391479,752.713928 1096.639160,751.474243
	C1103.202515,750.172058 1105.931763,744.165283 1107.021240,738.155640
	C1107.834595,733.669678 1109.321411,730.153198 1112.649292,726.919983
	C1116.321289,723.352417 1119.286987,719.094666 1119.587891,713.516174
	C1119.819702,709.217834 1121.224976,705.341736 1125.234985,702.963440
	C1125.522095,702.793213 1125.873291,702.587097 1125.960815,702.294434
	C1128.131226,695.034668 1133.080078,688.746399 1134.106201,681.200928
	C1134.907349,675.308899 1135.571655,669.311707 1135.379395,663.292908
	C1135.275879,660.045776 1136.538696,657.343506 1139.697510,656.062561
	C1142.456299,654.943787 1143.244263,652.951050 1143.286621,650.187500
	C1143.416138,641.716431 1142.336182,633.569519 1137.466675,626.338318
	C1136.125488,624.346680 1135.528076,622.156738 1135.288086,619.786682
	C1134.610596,613.093506 1131.415039,607.885071 1125.829468,604.129395
	C1122.518555,601.903320 1119.417236,599.371704 1115.783325,597.620972
	C1114.167725,596.842651 1112.428467,595.939209 1112.780762,593.322083
z"
        />
        <path
          fill="#0A0D13"
          opacity="1.000000"
          stroke="none"
          d="
M1200.683350,237.740662
	C1201.000000,244.687637 1201.000000,251.375290 1201.000000,258.531464
	C1193.896362,262.368286 1187.471191,266.824860 1178.986572,267.379272
	C1165.047119,268.290192 1151.714233,273.583374 1137.751221,274.549255
	C1128.553589,275.185455 1119.410889,277.308289 1110.098755,276.060822
	C1107.614136,275.727966 1105.119019,275.914917 1102.644897,276.385986
	C1097.396973,277.385223 1092.140259,278.205597 1086.794067,277.038452
	C1085.990845,276.863098 1085.105713,276.825226 1084.306396,276.997131
	C1068.942871,280.301147 1053.462891,276.622955 1038.064209,277.776794
	C1035.911987,277.938080 1033.721313,277.690765 1031.587769,278.073547
	C1029.583374,278.433136 1027.337280,279.066406 1027.157349,281.409393
	C1026.975952,283.771454 1028.549561,285.491791 1030.909668,286.040955
	C1034.029663,286.766907 1036.732056,288.613068 1039.737427,289.422760
	C1046.658203,291.287384 1050.286987,296.591461 1053.947388,301.982727
	C1054.759521,303.178955 1054.910156,305.161011 1056.683594,305.263763
	C1060.845337,305.504822 1063.693115,308.225250 1066.756714,310.408295
	C1071.942871,314.103668 1077.962646,315.131409 1083.857544,314.803802
	C1091.298950,314.390228 1097.777710,317.414764 1104.692383,318.842072
	C1105.175659,318.941864 1105.627319,319.297516 1106.105835,319.318878
	C1122.833252,320.063995 1138.578857,327.203857 1155.780273,326.022003
	C1164.682007,325.410400 1173.942505,327.566925 1182.852539,329.721375
	C1184.164185,330.038574 1185.326782,330.759003 1186.313477,331.657257
	C1188.495728,333.643860 1190.822998,334.127563 1193.664062,333.086823
	C1195.856689,332.283600 1198.215576,331.938904 1200.681641,333.739014
	C1201.000000,339.688049 1201.000000,345.376068 1201.000000,351.532043
	C1198.728760,354.148743 1196.080200,353.709167 1193.468140,352.985138
	C1176.427246,348.261658 1158.894287,345.910797 1141.642578,342.206238
	C1139.022461,341.643555 1136.318115,341.651367 1133.672974,341.852448
	C1126.062500,342.431183 1118.802368,340.379791 1111.436768,339.117218
	C1109.787842,338.834595 1108.118408,338.324585 1106.862061,337.211639
	C1099.836548,330.988098 1090.957275,330.137390 1081.911499,328.513550
	C1081.264404,328.350647 1081.014648,328.267883 1080.417725,328.015442
	C1078.000732,325.924042 1076.176392,323.638916 1072.784424,323.053986
	C1064.674927,319.431824 1057.755859,314.474640 1050.498901,310.097229
	C1049.041016,309.217896 1048.257080,307.699066 1047.318481,306.313354
	C1042.531616,299.245697 1035.135864,295.711334 1027.783325,292.180634
	C1026.258911,291.448547 1024.502441,291.924530 1022.851685,292.170380
	C1020.705017,292.490021 1018.553650,292.805725 1016.176270,291.610291
	C1014.472229,289.441071 1015.839233,287.739990 1016.277222,285.899078
	C1016.733276,283.982422 1018.500793,282.073151 1016.479370,279.692322
	C1015.540527,277.427063 1015.085876,275.341400 1013.628296,273.671936
	C1010.654846,270.266541 1006.446960,270.080109 1001.708618,273.474670
	C996.468140,277.760315 995.711060,280.644806 998.553406,287.736603
	C1000.052002,291.475739 999.153931,293.072205 994.854370,293.168549
	C992.932678,293.261108 991.488525,292.856659 989.793701,291.949860
	C977.646973,284.403259 965.300232,281.505432 951.923828,287.322815
	C949.733948,288.275208 947.948975,289.680664 946.692017,292.169342
	C946.391602,292.922150 946.221252,293.347992 945.875122,294.058258
	C945.484924,294.956879 945.329041,295.592163 944.882080,296.448761
	C944.832397,297.179108 943.569092,296.778839 944.367981,297.814484
	C944.680847,298.462769 944.748474,298.866638 944.663940,299.582611
	C944.734985,300.310699 943.416626,300.440582 944.535767,301.284912
	C944.947937,302.133789 944.981812,302.749634 944.785645,303.677521
	C944.314148,304.733856 943.693420,305.260437 942.792480,305.910156
	C942.266296,306.305420 941.982544,306.544739 941.541870,307.013977
	C941.160339,307.534546 940.945862,307.837921 940.560547,308.372864
	C939.747314,309.359985 938.917175,309.880371 937.860474,310.594360
	C937.118774,311.111176 936.605896,311.436340 935.935486,312.088837
	C935.527344,312.457367 935.355164,312.601685 934.897339,312.941193
	C933.976807,313.494659 933.303467,313.753235 932.210876,313.972626
	C927.050903,313.844788 922.483521,311.823669 917.205688,312.346924
	C916.242004,312.178406 915.728210,311.967529 914.934570,311.386719
	C913.375610,309.403778 913.315063,307.527374 913.959351,305.229065
	C914.406494,304.541595 914.720520,304.214508 915.063232,303.914062
	C915.405090,303.614441 915.734802,303.301056 916.080200,303.005280
	C915.729187,303.127045 915.563416,303.468292 915.007568,303.910126
	C911.075806,303.919342 910.248413,301.088898 909.372437,297.913330
	C910.335205,294.934296 908.498596,294.323944 906.691956,293.744080
	C897.835449,290.901550 895.413086,283.053192 901.134338,275.424530
	C901.944519,274.344238 902.674866,273.459015 902.798340,271.990051
	C903.087952,268.545044 905.516907,266.786316 908.504150,265.741638
	C915.479797,263.302246 922.523071,262.191498 930.060303,262.656372
	C937.618835,263.122559 945.364624,262.616150 952.336487,258.784149
	C954.770874,257.446075 957.411377,257.133331 960.088501,257.245483
	C972.453308,257.763397 984.704529,256.736450 996.889282,254.704300
	C1000.768799,254.057266 1004.256348,254.412750 1006.336365,258.375702
	C1007.503845,260.600250 1009.464111,261.230316 1011.788086,261.083679
	C1016.464478,260.788666 1021.019958,260.033051 1025.397827,258.189911
	C1031.858765,255.469757 1038.066406,256.140228 1043.766602,260.283325
	C1047.316650,262.863617 1051.054688,262.979370 1054.385498,260.471497
	C1057.831421,257.877014 1061.500366,257.149902 1065.612183,257.183411
	C1071.076660,257.227966 1076.610962,257.761230 1081.729614,254.919098
	C1083.212158,254.095871 1104.393311,255.167816 1105.929932,255.939331
	C1108.047119,257.002350 1110.055786,257.092743 1112.112549,255.901108
	C1112.399780,255.734726 1112.730835,255.480774 1113.021118,255.509552
	C1121.999268,256.399353 1130.569580,251.780365 1139.623657,253.581055
	C1144.453369,254.541626 1149.061646,255.218719 1152.295166,249.813324
	C1154.063110,246.858047 1157.509888,247.117905 1160.482910,248.156647
	C1163.675781,249.272263 1166.641235,249.019699 1169.704346,247.742996
	C1175.613403,245.280167 1182.328979,246.791809 1188.226440,244.050919
	C1190.031738,243.211868 1191.992554,242.919388 1192.651978,240.542465
	C1193.840210,236.258972 1196.665161,235.658096 1200.683350,237.740662
z"
        />
        <path
          fill="#1B292E"
          opacity="1.000000"
          stroke="none"
          d="
M346.360596,87.259521
	C339.957886,84.146469 334.117828,80.549194 326.939636,81.984848
	C326.132660,82.146248 325.009583,81.752960 324.492065,82.177025
	C319.300140,86.431381 313.851105,84.568733 308.497437,82.870270
	C297.226990,79.294655 285.656067,80.996124 274.276123,81.277863
	C266.087006,81.480621 258.171661,80.254723 250.159332,79.464714
	C246.985031,79.151741 243.942108,79.220726 240.775528,79.460945
	C234.896896,79.906876 228.517700,81.424355 224.295746,75.130455
	C223.969940,74.644775 223.185638,74.173302 222.623291,74.183479
	C213.537888,74.347992 204.453003,72.462029 195.372147,74.153870
	C194.897736,74.242264 194.372818,74.430191 194.018890,74.740166
	C186.274200,81.522919 176.944000,80.534683 167.732452,80.143738
	C166.736313,80.101456 165.605270,79.768906 164.762527,80.117188
	C156.228729,83.644089 147.312012,81.546730 138.581940,81.937729
	C131.302795,82.263748 129.061630,83.483406 128.250504,90.570045
	C127.805733,94.455826 126.246124,94.946243 122.905174,95.105324
	C116.869843,95.392723 110.994835,94.109756 105.016747,93.918716
	C100.168060,93.763779 96.802673,95.410271 95.547325,100.120659
	C94.668114,103.419662 92.685791,104.184372 89.650162,104.036461
	C86.325119,103.874443 82.985657,103.971931 79.653107,104.010475
	C76.078308,104.051804 73.641243,105.286964 71.808144,108.868996
	C69.925140,112.548546 65.276573,112.639252 61.672943,113.949371
	C61.228424,114.110985 60.659496,114.087418 60.184280,113.983978
	C53.230202,112.470261 49.342712,115.588142 48.140030,122.249435
	C47.366028,126.536446 44.827427,127.434937 40.998665,127.034073
	C36.114346,126.522682 33.593479,129.100006 30.728613,133.111389
	C28.061354,136.846085 25.491190,141.735001 19.244425,142.410049
	C15.319079,142.834229 11.388236,144.862671 7.335168,145.755615
	C5.334626,146.196365 3.998923,147.184753 3.998881,149.441895
	C3.998850,151.062592 3.515298,152.408310 1.368261,152.939499
	C1.000000,143.312439 1.000000,133.624878 1.000000,123.468658
	C2.993905,120.999741 4.898365,119.003006 6.002676,116.264206
	C7.226499,113.228996 9.681967,111.215378 12.584105,109.694115
	C17.311499,107.216064 21.794029,104.407043 25.086248,100.032440
	C27.347010,97.028412 30.903543,97.043312 34.143223,96.519981
	C37.024822,96.054497 39.637131,95.643967 40.816601,92.313820
	C41.718330,89.767860 44.302925,89.574532 46.626091,89.118172
	C53.194733,87.827835 59.946667,88.627266 66.508865,87.289963
	C68.143127,86.956917 70.137276,87.048401 71.142708,85.712349
	C74.250816,81.582237 78.621651,81.494133 83.173782,80.950516
	C88.377831,80.329033 93.733955,79.614738 97.719765,75.321625
	C99.805191,73.075432 102.607307,73.426491 105.148972,74.711670
	C107.557060,75.929291 109.316628,75.490753 111.437943,73.757027
	C115.077156,70.782730 120.313622,70.343605 123.442413,66.260529
	C124.383232,65.032745 126.589417,65.188683 128.226685,65.512939
	C134.079254,66.672043 139.486237,64.970100 144.751938,62.872288
	C148.182617,61.505539 151.456238,61.322952 154.984818,62.220768
	C162.781021,64.204453 170.188416,62.579784 177.204239,59.130711
	C179.942200,57.784687 182.458542,57.673904 185.374664,58.167263
	C197.446091,60.209576 209.611832,59.070210 221.742371,58.865452
	C223.721481,58.832047 225.451401,57.405945 227.390259,56.928314
	C231.863647,55.826324 236.296234,55.855366 240.623932,57.655048
	C242.472641,58.423847 244.571991,59.685123 246.362152,58.656113
	C252.473160,55.143391 258.097382,57.872311 263.589264,59.906620
	C267.719025,61.436382 270.733093,61.218868 274.597168,58.716282
	C278.999329,55.865204 283.908417,55.910614 289.133972,58.965683
	C295.488586,62.680893 303.042664,63.454006 310.319550,62.502312
	C314.771942,61.920017 318.823944,61.277081 322.745972,63.985542
	C323.685760,64.634521 324.865570,65.052254 326.057495,64.988045
	C333.279724,64.598991 340.126465,67.642433 347.348114,67.284714
	C348.846283,67.210503 350.496277,67.300598 351.718750,68.134552
	C356.602814,71.466354 362.106140,71.015526 367.598602,71.279839
	C375.029480,71.637428 382.359406,72.727257 389.903046,72.502113
	C396.286102,72.311630 402.458313,71.744530 408.588165,70.217087
	C411.455109,69.502708 414.133636,69.496155 416.807098,70.952332
	C422.493530,74.049629 428.637146,75.267006 435.081879,75.202629
	C439.913147,75.154358 444.745453,75.210594 449.577148,75.189713
	C455.538452,75.163948 461.377167,75.330536 467.195343,72.863441
	C472.074890,70.794357 477.617859,70.960960 483.359802,71.834610
	C484.597260,71.599098 485.432556,71.618263 486.682861,71.745407
	C489.027618,72.553589 490.961273,72.662056 492.931122,71.917503
	C497.698883,70.115440 502.920898,69.834282 507.511902,67.328941
	C509.730682,66.118149 512.306641,66.003098 515.000366,67.653793
	C515.515259,68.520386 515.540100,69.089439 515.152893,70.046402
	C506.329041,76.523277 497.275269,81.468124 486.735565,83.581345
	C482.933838,84.343597 479.477539,82.911110 475.894806,83.371964
	C468.457855,84.328598 460.994232,83.892937 453.561432,84.144859
	C421.791016,85.221703 390.010834,84.477547 358.243988,85.203110
	C354.161957,85.296349 350.953064,88.796616 346.360596,87.259521
z"
        />
        <path
          fill="#06090F"
          opacity="1.000000"
          stroke="none"
          d="
M1201.000000,333.531342
	C1198.034546,334.044495 1195.093384,333.708893 1192.336914,335.558899
	C1190.766602,336.612854 1188.439209,336.322327 1187.200806,334.904572
	C1181.796265,328.717041 1174.131104,330.478577 1167.414307,329.047089
	C1155.601929,326.529602 1143.312378,328.875977 1131.567993,325.992188
	C1123.862305,324.100098 1116.259277,322.431946 1108.311890,321.498322
	C1103.045532,320.879639 1096.876221,320.251160 1091.992432,316.767059
	C1091.273804,316.254395 1090.077881,316.076843 1089.172119,316.211151
	C1078.474365,317.797333 1068.814575,315.911316 1060.866943,308.030609
	C1060.633301,307.798950 1060.294556,307.364197 1060.129639,307.416626
	C1051.424194,310.185486 1051.842529,301.232727 1048.956055,297.698090
	C1044.028687,291.664276 1037.411621,289.989441 1031.073853,288.373047
	C1026.631470,287.240021 1026.479370,283.363983 1023.607788,281.671387
	C1025.373169,279.233856 1027.086792,276.263123 1030.354980,276.205780
	C1042.499756,275.992523 1054.656372,275.506653 1066.795776,276.445557
	C1068.444946,276.573120 1070.226807,277.324982 1071.707275,276.916962
	C1082.366577,273.979218 1093.505493,277.220734 1104.160767,274.241241
	C1106.529175,273.578918 1109.127563,273.763214 1111.593018,274.375793
	C1115.399170,275.321381 1119.130371,275.499420 1122.787109,273.684906
	C1123.648315,273.257538 1124.765991,272.900696 1125.665283,273.064056
	C1136.442017,275.021637 1146.776978,271.598694 1157.001465,269.610779
	C1166.592407,267.745972 1176.084473,265.638092 1185.839600,264.772369
	C1187.742065,264.603546 1189.778809,263.946503 1191.178955,262.308014
	C1193.556885,259.525269 1196.643799,258.670227 1200.583496,259.000000
	C1201.000000,283.687561 1201.000000,308.375122 1201.000000,333.531342
z"
        />
        <path
          fill="#385661"
          opacity="1.000000"
          stroke="none"
          d="
M1.336926,677.278320
	C1.000000,651.979126 1.000000,626.958191 1.000000,601.468628
	C4.892327,597.900513 6.673769,592.252197 12.692902,591.642273
	C14.264688,591.482910 14.672817,589.497925 14.946795,588.044739
	C15.929342,582.833618 17.947031,578.997803 22.137218,574.833679
	C26.608488,570.390259 31.648291,569.222717 36.329636,566.394470
	C44.506905,561.454102 52.862926,562.505432 61.360050,562.237366
	C63.181004,562.179871 65.014191,562.373291 66.917603,561.957581
	C67.636948,560.980042 66.493515,560.632202 66.163101,560.047729
	C63.942986,556.120972 65.176254,553.009155 69.685875,552.571167
	C75.781357,551.979187 81.976273,551.548523 88.003731,553.214417
	C91.735382,554.245911 95.343323,555.475891 98.861671,552.645020
	C99.506561,552.126160 100.457367,552.346619 101.550110,552.969482
	C103.255234,555.251709 101.873756,557.124390 101.419571,559.421997
	C101.279694,560.020325 101.215622,560.255798 101.020218,560.828125
	C98.868515,564.213074 100.823662,567.462708 100.219284,570.893555
	C99.657806,571.502014 99.239700,571.716858 98.429062,571.895508
	C96.454430,572.100525 94.337784,570.325928 93.308762,572.740356
	C92.320137,575.059937 93.802460,576.783447 95.474548,578.227539
	C97.550407,580.020386 100.278114,581.509338 98.015503,585.182495
	C93.785789,584.751892 91.298546,585.753052 91.409920,590.398010
	C91.454765,592.268188 90.484756,594.005798 88.488869,594.662598
	C86.134186,595.437500 83.874123,594.603577 82.723404,592.725952
	C79.755966,587.883972 74.294823,587.556702 69.915421,585.710571
	C66.444878,584.247559 63.019474,587.686584 60.466049,590.120300
	C57.050873,593.375549 53.503212,595.163147 48.802155,594.193848
	C46.424759,593.703613 44.931236,594.515808 44.421314,596.974792
	C43.695892,600.472961 41.492409,602.690063 38.533081,604.628723
	C35.367340,606.702576 34.374653,611.026611 30.047478,612.890503
	C35.225113,615.816345 37.058243,619.972717 36.722389,625.202515
	C36.529251,628.210022 38.168610,629.411194 41.010960,629.298645
	C46.062599,629.098694 50.195362,630.582336 52.567711,635.456848
	C53.335655,637.034790 54.925533,637.165466 56.441662,637.248962
	C59.104832,637.395569 61.774174,637.365967 64.437599,637.315613
	C69.275581,637.224243 73.117676,638.738342 75.876831,643.036499
	C78.283844,646.786072 82.683487,648.657471 85.039726,652.758423
	C86.930099,656.048584 89.168266,659.147522 90.762459,662.647217
	C92.231949,665.873108 93.134727,669.748047 98.187302,669.192017
	C99.663651,669.029602 100.722023,670.609680 101.562485,671.896301
	C104.754570,676.782837 106.863647,682.268799 110.039848,687.171021
	C110.868370,688.449768 111.095795,689.989258 109.742287,691.661072
	C105.728928,693.434937 101.929764,692.975769 97.691864,692.517395
	C91.244911,692.235413 85.448883,691.131470 79.802055,688.913574
	C72.685448,686.118347 65.305481,684.525391 57.623116,683.686951
	C49.089775,682.755615 40.538246,681.551086 32.014687,680.624756
	C23.865520,679.739075 15.731120,677.730164 7.413119,678.561584
	C5.419493,678.760803 3.416756,678.860413 1.336926,677.278320
z"
        />
        <path
          fill="#101417"
          opacity="1.000000"
          stroke="none"
          d="
M910.647339,219.282486
	C911.314636,219.099014 911.724182,219.107544 911.928955,219.316620
	C913.578918,221.001266 913.148132,224.551422 916.285828,224.788483
	C920.235535,225.086823 924.273193,225.643967 928.158142,224.351883
	C935.007019,222.074112 935.573120,222.313126 937.043274,229.041367
	C937.311035,230.266464 937.701904,231.535751 938.963623,231.665726
	C943.242188,232.106491 948.047180,233.342682 951.664490,231.166122
	C955.180725,229.050354 958.492554,229.004608 962.076965,229.031769
	C965.128540,229.054886 968.046814,229.110519 970.217957,226.373627
	C971.505310,224.750839 973.492493,224.999710 975.322754,224.999908
	C984.975586,225.000946 994.628723,225.041229 1004.281189,224.982147
	C1008.894165,224.953918 1013.523132,225.493927 1017.995789,223.336594
	C1019.614075,222.555984 1022.122742,222.625809 1023.865112,223.274490
	C1030.528076,225.755188 1036.359619,222.932190 1042.740479,221.910690
	C1050.683716,220.639038 1058.329712,218.072784 1066.232056,217.210358
	C1071.142578,216.674454 1076.502563,215.635696 1081.358398,218.448425
	C1082.585083,219.158966 1083.469360,217.867111 1084.451660,217.332672
	C1085.768677,216.616180 1087.043335,215.880127 1088.679810,216.365585
	C1092.188477,217.406387 1095.527954,217.841278 1097.859497,213.982086
	C1098.491333,212.936249 1099.901245,213.026932 1101.079346,213.004959
	C1105.863037,212.915756 1110.677002,213.504395 1115.263916,211.234222
	C1117.136108,210.307602 1119.232666,211.335754 1120.858887,212.574219
	C1122.432861,213.772949 1124.457886,214.734085 1125.948608,213.857269
	C1135.417480,208.288101 1146.135864,209.658630 1156.299683,208.009995
	C1157.109253,207.878662 1158.375977,207.891830 1158.629883,207.413239
	C1161.825928,201.388809 1168.332520,203.000458 1173.219971,200.934540
	C1173.964233,200.619934 1175.130371,200.370529 1175.320801,199.820709
	C1177.527100,193.448288 1182.852539,195.142639 1187.457153,195.007751
	C1190.579468,194.916275 1193.139771,195.342682 1192.505737,190.211746
	C1191.845825,184.872299 1196.910156,186.288315 1200.571899,186.000000
	C1201.000000,196.020889 1201.000000,206.041794 1201.000000,216.531342
	C1197.512573,219.071915 1193.769897,220.571640 1191.272095,224.262711
	C1189.809082,226.424637 1186.451294,226.195786 1183.844238,226.660217
	C1181.153198,227.139603 1178.709106,227.828445 1177.192993,230.457443
	C1175.816162,232.844635 1173.436279,233.588623 1170.807129,233.707672
	C1167.644653,233.850845 1164.480591,233.783600 1161.317627,233.796524
	C1158.584595,233.807678 1156.309082,234.527100 1153.989502,236.262177
	C1149.314697,239.758804 1143.630127,240.539642 1137.929443,240.758987
	C1131.619385,241.001785 1125.212402,240.108307 1119.026855,241.749451
	C1113.258789,243.279846 1107.697510,245.575806 1101.510132,243.807510
	C1099.831177,243.327667 1098.422485,244.555756 1097.406372,246.076385
	C1095.577148,248.814362 1092.973511,249.401428 1089.948242,248.389084
	C1086.641968,247.282684 1083.163208,246.673004 1080.101685,244.857910
	C1077.433350,243.276016 1075.339844,243.512253 1073.268555,246.269165
	C1070.368774,250.128571 1067.928101,250.014816 1064.772827,246.501404
	C1061.594360,242.962143 1060.515869,242.865814 1057.114258,245.817108
	C1053.259277,249.161713 1050.907471,248.952087 1048.480957,244.411880
	C1046.759399,241.190826 1044.402344,240.420654 1041.507202,241.815979
	C1036.258423,244.345673 1030.636963,244.793518 1025.026855,245.441254
	C1021.872925,245.805374 1018.840942,246.281906 1016.053101,248.102936
	C1013.224182,249.950851 1010.772461,249.068954 1008.714172,246.382614
	C1005.468689,242.146759 1004.348022,242.084274 1001.452759,246.157532
	C999.348755,249.117676 996.833374,249.808792 993.669800,248.413818
	C988.522766,246.144318 983.292175,246.489090 978.014648,247.726334
	C976.229248,248.144897 974.451843,248.651688 972.612305,248.667572
	C955.172424,248.818253 937.850708,252.171722 920.367188,250.723557
	C915.765259,250.342377 911.635254,252.396729 906.826904,252.176636
	C904.727539,249.327332 904.914490,246.422775 905.862244,243.777802
	C907.745728,238.521820 908.197876,233.151413 908.240173,227.648773
	C908.261719,224.842484 908.605713,222.004166 910.647339,219.282486
z"
        />
        <path
          fill="#272228"
          opacity="1.000000"
          stroke="none"
          d="
M1039.046143,739.614868
	C1034.589478,741.645630 1030.807129,743.760620 1031.862061,749.512329
	C1031.889648,749.663086 1031.833130,749.961243 1031.762451,749.979126
	C1026.255981,751.370056 1024.884033,757.387268 1020.535461,759.981934
	C1019.128479,760.821472 1017.212585,761.065491 1016.716431,762.813354
	C1015.919739,765.620361 1015.162903,768.081726 1011.592712,768.518677
	C1010.151062,768.695129 1010.027222,770.479431 1010.015991,771.845886
	C1009.996887,774.171082 1009.992371,776.496338 1009.995178,778.821594
	C1010.000061,782.896790 1007.987976,784.814392 1003.898987,785.025513
	C994.103455,785.531555 994.088074,785.595276 991.766357,795.279297
	C991.383911,796.874512 991.249573,798.529175 991.000305,800.578186
	C952.312439,801.000000 913.624878,801.000000 874.468628,801.000000
	C871.477722,797.746887 871.882812,794.277222 875.046082,792.308838
	C878.636414,790.074646 881.167908,786.595947 884.564209,784.555847
	C891.733032,780.249634 898.508362,775.649170 904.283020,769.556335
	C906.057922,767.683594 908.737183,767.446045 911.279053,767.345398
	C913.608704,767.253296 915.943665,767.365173 918.269714,767.189453
	C924.431030,766.724121 925.848755,765.770508 926.888123,759.615234
	C927.765259,754.420715 930.237061,751.563721 935.563416,751.029724
	C939.383301,750.646729 941.949707,748.551880 942.955383,744.649414
	C943.672668,741.866211 945.591187,740.176025 948.359985,739.272827
	C953.579285,737.570435 959.456299,735.749451 963.218506,732.599060
	C968.238220,728.395569 973.208740,726.086121 979.315430,725.174194
	C980.380432,725.015198 980.986206,724.114380 981.410034,723.183594
	C983.196106,719.260681 986.664795,717.315430 990.691528,715.523682
	C994.313843,718.822449 998.348267,719.358215 1002.801575,719.470825
	C1008.325073,719.610596 1010.363586,722.088928 1010.685608,727.724792
	C1010.799683,729.721130 1010.797668,731.724670 1010.674377,733.720703
	C1010.316711,739.508301 1009.078979,740.836975 1003.277832,741.754028
	C1001.979553,741.959290 1000.290405,741.717773 999.651001,743.009216
	C998.630310,745.070679 997.524780,747.964905 999.446106,749.413757
	C1000.857056,750.477783 1003.263000,748.535217 1004.046082,746.670105
	C1006.759827,740.206421 1014.128479,739.409180 1018.270264,734.714722
	C1018.896240,734.005249 1020.201172,733.791382 1021.116760,734.150879
	C1026.583252,736.296936 1032.538696,736.328003 1038.061768,738.178528
	C1038.531250,738.335693 1038.823853,738.803650 1039.046143,739.614868
z"
        />
        <path
          fill="#18252B"
          opacity="1.000000"
          stroke="none"
          d="
M484.054138,72.577271
	C478.828064,73.296516 473.232330,72.110199 468.565552,74.191032
	C456.678284,79.491356 444.377045,76.194878 432.305756,76.829636
	C426.449341,77.137589 420.410553,75.918495 415.462494,72.022697
	C414.161255,70.998184 412.506012,70.507629 411.252594,71.229393
	C407.368347,73.466095 402.706573,72.352386 398.809235,74.480865
	C396.232117,75.888306 393.660614,73.521629 390.997009,73.996193
	C380.394684,75.885162 369.973938,72.139427 359.390656,72.930183
	C357.810944,73.048210 355.591095,72.847252 354.655823,71.858711
	C351.291138,68.302238 346.887604,69.915688 343.121277,68.823425
	C334.991089,66.465614 325.749115,69.385284 318.410767,63.445190
	C317.234009,62.492668 315.469147,62.864132 314.072479,63.504063
	C309.385742,65.651474 304.655365,65.291496 299.944641,63.763084
	C297.828430,63.076473 295.707642,62.950447 293.539551,63.002472
	C291.833862,63.043404 290.221588,62.802883 288.817932,61.730282
	C282.586273,56.968384 279.379791,57.132942 272.835480,62.397095
	C271.533142,63.444672 269.565765,64.746834 268.294159,63.724789
	C264.734070,60.863403 259.929169,61.789410 256.341797,59.244385
	C254.262268,57.769089 252.166916,57.692989 249.911743,59.366993
	C246.776779,61.694057 243.519547,61.355377 240.085846,59.504452
	C235.442291,57.001354 230.548355,57.075359 225.866760,59.519291
	C223.866379,60.563549 221.883911,61.029190 219.662186,61.018940
	C209.830185,60.973564 199.997818,60.984943 190.165680,61.011536
	C188.303925,61.016571 186.543823,60.794025 184.841904,59.950283
	C182.248077,58.664391 180.353012,59.381969 177.666351,60.911060
	C171.767487,64.268349 164.434662,66.682510 158.198349,64.576668
	C150.397858,61.942646 144.394257,65.505318 137.697037,66.995476
	C135.415497,67.503120 133.233902,68.342293 130.850449,67.771828
	C126.935455,66.834816 122.882362,65.355759 121.406853,71.278633
	C121.136108,72.365433 119.624657,72.914978 118.465889,72.852486
	C115.174622,72.674980 113.101646,73.829376 111.197739,76.778313
	C109.614021,79.231316 106.662308,78.115677 104.536781,76.490105
	C101.744583,74.354660 99.441231,74.065826 97.901932,77.812004
	C97.498116,78.794769 96.919632,79.649864 95.875130,79.841133
	C91.327965,80.673813 87.148659,83.014473 82.394043,83.017868
	C78.708969,83.020500 75.374123,82.441200 72.787605,87.001724
	C70.691704,90.697189 65.092354,89.356995 61.128578,89.780449
	C56.048222,90.323181 50.883991,90.450645 45.759113,90.935722
	C43.436810,91.155548 41.892975,91.538063 41.966827,94.281548
	C42.036350,96.864441 40.205418,97.641380 38.065231,97.938080
	C35.276260,98.324707 32.472778,98.610840 29.689659,99.033249
	C28.740370,99.177322 27.090075,99.483727 27.020737,99.917488
	C26.167425,105.255577 20.317501,105.448441 17.832056,109.045441
	C16.929480,110.351677 15.408178,110.621674 13.941358,111.049828
	C10.541486,112.042213 7.788464,114.365715 7.230184,117.709770
	C6.637403,121.260475 4.904734,122.589066 1.381679,122.989777
	C1.000000,113.312439 1.000000,103.624878 1.000000,93.468658
	C8.471916,88.069221 16.827185,85.410110 25.504387,83.710030
	C28.968805,83.031258 30.976389,81.355980 32.182613,77.953781
	C34.074760,72.616920 36.181046,72.023132 41.175056,75.109764
	C42.765923,76.093018 44.336475,76.050354 45.950283,76.027931
	C52.292122,75.939812 58.052563,73.147301 61.769932,68.718590
	C65.747101,63.980373 70.248619,61.671139 75.821320,61.447166
	C83.927788,61.121365 91.418968,59.491112 98.835670,55.929085
	C106.168694,52.407246 114.324684,52.284401 122.350716,54.087826
	C124.465149,54.562935 126.550903,55.059204 128.746841,55.081326
	C131.616776,55.110229 133.994492,54.488224 135.293213,51.571712
	C136.895065,47.974461 139.964828,47.096786 143.568512,47.389481
	C157.886673,48.552395 172.116272,47.701706 186.289093,45.532700
	C186.423111,45.512196 186.604279,45.432972 186.782761,45.479214
	C200.842300,49.121658 215.616028,42.341499 229.548752,48.468781
	C232.747009,49.875298 236.169556,49.080318 239.177124,47.378532
	C242.537933,45.476868 246.137619,45.821598 249.776443,45.448689
	C263.918518,43.999393 277.543457,47.233318 291.329956,49.155918
	C293.973297,49.524544 296.587708,49.943127 299.195801,50.500946
	C303.314850,51.381920 307.401611,51.510319 311.481964,50.012173
	C318.230713,47.534290 324.716553,46.603752 331.538025,50.766140
	C335.927155,53.444347 341.495209,53.224449 346.665558,53.129177
	C357.007202,52.938618 367.362823,52.865589 377.418640,55.599991
	C396.675140,60.836266 416.919373,60.487431 436.116364,66.517395
	C442.335205,68.470787 449.553833,68.161438 456.300079,69.062035
	C459.113434,69.437599 461.871887,68.864510 464.517059,67.518097
	C470.831177,64.304169 479.586456,66.459312 484.054138,72.577271
z"
        />
        <path
          fill="#152228"
          opacity="1.000000"
          stroke="none"
          d="
M1.000000,58.468624
	C7.718851,57.791588 14.298136,55.256660 21.150446,56.936958
	C22.903250,57.366776 24.656431,56.457565 25.094791,54.622429
	C26.395451,49.177376 30.937067,48.568272 34.845123,49.179932
	C43.578857,50.546879 49.849819,45.751053 56.628815,41.940826
	C57.496452,41.453159 58.391674,40.480183 59.218845,40.536983
	C68.173271,41.151886 76.753281,37.146812 85.831223,38.639503
	C89.679871,39.272343 94.958122,41.174206 95.346336,33.864742
	C95.425003,32.383530 96.948311,31.197399 98.569901,31.154305
	C103.046814,31.035332 107.567001,30.430485 111.992897,31.531069
	C121.705009,33.946167 131.654526,34.642513 140.774368,30.550077
	C145.457565,28.448540 149.665848,25.026646 154.960434,24.594767
	C163.352554,23.910223 171.807739,22.744934 180.124542,25.452465
	C181.510574,25.903681 183.066071,25.961880 184.545456,25.976267
	C190.376480,26.032974 196.259247,26.487347 202.027969,25.885262
	C210.606323,24.989927 218.246918,28.433058 226.225540,30.242311
	C228.528595,30.764557 230.076065,30.713512 231.498718,28.985567
	C233.366760,26.716677 236.058289,26.349442 238.347229,27.182468
	C247.840714,30.637506 257.817566,30.918278 267.654022,31.760937
	C273.170441,32.233513 278.778412,30.151991 284.412262,31.959074
	C294.356995,35.148903 304.385132,32.784584 314.391510,32.165440
	C323.867615,31.579111 333.335022,31.924179 342.744934,33.201679
	C343.968292,33.367756 345.848602,34.141727 346.110962,35.024544
	C347.460815,39.566246 350.508667,38.675400 353.537292,37.623302
	C356.363678,36.641441 358.747406,36.846859 360.746033,39.229897
	C362.051117,40.786049 363.894379,41.455414 365.697632,40.936302
	C373.367798,38.728233 381.107330,42.143543 388.911102,40.200085
	C394.750580,38.745819 400.817047,40.218418 406.325134,42.966400
	C410.016846,44.808208 413.909912,45.384903 417.951111,44.981239
	C422.097351,44.567081 426.355438,45.317905 429.465454,47.526344
	C435.212921,51.607689 441.404449,50.927467 447.662964,51.004650
	C452.068329,51.058975 455.984375,51.283684 460.279236,53.953987
	C464.435089,56.537853 470.461212,56.168461 475.685699,56.966629
	C485.155548,58.413380 494.664062,59.165314 504.264435,58.607803
	C507.481445,58.420982 509.073486,55.025288 512.628662,55.008362
	C514.544739,56.266422 514.520142,57.945610 514.100952,60.094910
	C512.708679,62.714081 510.284363,63.667839 508.244141,65.644501
	C507.129578,66.237312 506.323456,66.427223 505.057190,66.453552
	C501.454285,66.419533 498.407440,66.605675 495.582428,68.542603
	C493.530823,69.949249 491.128082,69.403732 488.852600,68.544945
	C480.901154,65.543945 472.720215,64.839928 464.355164,66.276695
	C461.490936,66.768654 458.694824,66.618179 456.031281,65.427902
	C451.352173,63.336918 446.644623,63.413006 441.943115,65.214485
	C438.674072,66.467079 435.590240,66.213516 432.217682,65.262077
	C422.997925,62.661091 414.197845,58.816399 404.288940,58.266041
	C397.641937,57.896858 391.417206,53.934082 384.587769,53.059601
	C383.601776,52.933353 382.540192,52.207855 381.679993,52.470840
	C370.815247,55.792362 360.599274,47.891075 349.793213,50.688782
	C341.228821,47.521198 331.655701,49.538158 323.417114,46.628345
	C314.458344,43.464169 305.659088,42.861183 296.528076,42.840328
	C294.011292,42.834579 291.646240,43.279026 289.282990,44.341637
	C284.528259,46.479534 279.335327,46.705566 274.654510,44.797794
	C266.305084,41.394798 257.691589,42.629063 249.166092,42.409325
	C246.192398,42.332680 243.157394,42.912079 240.226639,42.196850
	C229.787766,39.649315 219.105209,40.568272 208.540573,39.857361
	C203.371506,39.509521 198.247467,39.886093 193.320465,41.878544
	C190.417648,43.052437 187.385452,42.781075 184.575668,41.414051
	C181.123306,39.734390 177.353806,39.154366 173.855438,40.109802
	C163.881088,42.833893 153.119827,40.641880 143.474258,45.176933
	C142.587509,45.593861 141.369186,45.828499 140.534576,45.430809
	C133.613541,42.132942 128.104630,46.166813 122.410828,48.898769
	C119.043579,50.514420 115.752640,50.915642 112.286720,49.586838
	C109.872978,48.661427 107.858749,48.850613 105.994484,50.914001
	C103.684555,53.470657 100.472313,53.809906 97.248962,53.790066
	C94.178528,53.771172 91.479088,54.329655 88.982910,56.485710
	C85.066788,59.868225 80.099174,59.127819 75.558525,58.542095
	C68.689796,57.656055 62.736267,58.644386 56.585880,62.360477
	C49.729519,66.503136 41.583088,66.512291 33.711830,65.385681
	C29.854885,64.833641 27.297335,65.338493 26.272692,69.668900
	C25.504684,72.914696 22.518957,73.642242 19.623045,74.035782
	C15.405620,74.608910 11.761449,75.576263 9.512981,80.015862
	C8.004604,82.994164 4.722943,83.290092 1.324862,81.265877
	C1.000000,73.645752 1.000000,66.291496 1.000000,58.468624
z"
        />
        <path
          fill="#17272E"
          opacity="1.000000"
          stroke="none"
          d="
M1.000000,193.468658
	C5.326355,193.000549 9.662612,192.819794 13.976130,193.055389
	C17.864748,193.267776 21.162979,194.636856 24.781324,196.721741
	C31.051546,200.334610 38.002052,202.671753 44.439949,206.426239
	C48.478905,208.781708 55.099064,208.216034 60.566509,209.633224
	C65.153709,210.822281 70.310600,208.024139 75.256981,209.923752
	C76.302803,210.325409 77.603729,210.159210 78.463814,211.062546
	C83.638145,216.497116 90.429695,216.831619 97.243568,216.973694
	C100.434868,217.040222 103.605400,216.778107 105.734589,219.929260
	C106.435669,220.966827 107.755592,220.895706 108.930252,221.003555
	C121.860786,222.190598 134.675705,224.515640 147.574936,225.671860
	C159.461685,226.737305 171.513245,225.969910 183.492249,225.996918
	C186.966309,226.004745 190.462845,225.623566 193.460312,228.137238
	C194.781357,229.245071 196.615967,229.002686 198.283417,229.001572
	C204.606552,228.997345 210.953629,229.348129 217.246933,228.914993
	C225.633881,228.337799 233.660156,230.360031 241.822021,231.454163
	C242.475479,231.541763 243.237366,232.072983 243.735962,231.871399
	C249.331131,229.609512 252.154892,233.627609 255.666412,236.934479
	C258.210999,236.842316 258.308472,238.673889 258.444794,239.995361
	C258.747742,242.932495 260.167572,245.292542 261.910248,247.963654
	C263.298950,250.589661 262.650665,253.691635 265.373688,255.733063
	C266.316986,258.019836 265.760284,260.012177 265.600342,262.367310
	C265.473389,264.227142 265.359161,265.720551 265.831879,267.564728
	C265.879517,268.465088 265.776367,268.965973 265.348450,269.762817
	C264.194519,270.791351 263.189850,270.700806 261.814575,270.212158
	C258.316925,267.959930 254.726410,266.825348 251.280777,265.371307
	C249.490173,264.615692 247.585770,263.990204 246.476181,261.363708
	C246.450134,260.404541 246.531006,260.173340 246.851166,259.667450
	C248.111969,258.315125 250.664703,258.504089 250.052277,256.108765
	C249.601822,254.346939 248.044479,253.363693 246.425354,253.109207
	C241.789627,252.380569 237.231644,252.493271 233.473541,255.928314
	C231.901428,257.365295 230.527954,259.055328 227.957977,259.637573
	C226.780655,259.528900 226.088181,259.173370 225.163040,258.398468
	C222.666718,253.205078 219.407669,251.400375 213.890640,253.242844
	C210.430679,254.398346 206.512527,253.422058 203.194550,251.923141
	C194.975494,248.210159 186.369537,248.444122 177.780228,249.126877
	C169.212189,249.807953 160.956436,248.627426 152.841690,246.149796
	C151.026016,245.595413 149.277054,245.575424 147.045074,245.501984
	C133.791718,244.087982 121.041504,245.244431 108.298988,246.638626
	C104.418884,247.063171 100.786461,246.375214 97.057396,245.103821
	C90.515823,242.873520 83.895599,241.158310 76.887268,240.284943
	C69.676826,239.386414 62.371506,238.513443 55.170200,237.735779
	C44.412766,236.574081 33.897732,234.201294 23.217596,232.742996
	C16.036890,231.762543 8.718173,231.552338 1.340418,229.282867
	C1.000000,217.312439 1.000000,205.624878 1.000000,193.468658
z"
        />
        <path
          fill="#0D161D"
          opacity="1.000000"
          stroke="none"
          d="
M680.726013,63.775501
	C681.309570,64.786942 681.621582,65.575073 681.968018,66.680351
	C683.613586,70.111588 680.645996,70.697083 678.897705,72.349426
	C678.412537,72.718613 678.208191,72.849396 677.677368,73.130310
	C675.289001,73.823524 673.445801,74.921059 671.407349,75.557182
	C667.620544,76.738876 665.269958,79.166725 665.317017,83.760414
	C665.021851,85.326775 664.589783,86.462997 663.639648,87.758827
	C660.990295,88.933060 659.915222,87.248184 658.653931,85.389664
	C658.341003,84.588341 658.256958,84.016251 657.452698,83.623428
	C656.155457,83.315910 655.318115,82.698746 654.141663,82.020744
	C648.303406,76.878036 642.252075,72.709076 634.832520,70.068573
	C631.596985,68.007568 627.905212,68.029846 624.743408,65.848236
	C619.380920,61.436661 615.934509,54.858696 608.236450,54.495495
	C605.956299,54.387913 604.251099,53.239582 602.619995,51.700031
	C599.853027,49.088364 596.973206,46.553711 592.576599,46.092415
	C592.012817,45.995956 591.790161,45.944389 591.246948,45.775330
	C590.118652,45.289478 589.460815,44.616268 588.194824,44.436348
	C582.640808,42.867222 580.815796,37.461201 576.480103,34.398293
	C574.713928,30.085669 570.922363,29.398115 567.456726,28.548857
	C564.827454,27.904549 562.315796,27.251472 560.182922,25.527117
	C556.961609,22.922876 552.996155,23.217844 549.238220,22.807995
	C546.718506,22.533184 546.496460,24.741568 545.640503,26.692533
	C545.258240,27.135807 545.070251,27.279869 544.541138,27.517817
	C542.088135,27.593361 540.997498,26.032742 539.841675,24.643229
	C538.269348,22.752979 536.306641,21.725397 533.621948,21.506462
	C535.635010,23.071770 536.730164,24.742178 535.224915,27.392904
	C533.603210,28.731478 532.041687,29.109554 529.920410,28.927263
	C527.170898,27.593626 524.961487,27.625174 522.373657,28.552879
	C515.900818,30.873287 509.518188,29.764097 503.551056,26.708496
	C501.392853,25.603333 499.285004,25.144691 496.950562,24.834448
	C487.721252,23.607927 478.505676,22.446613 469.157318,22.642614
	C465.709290,22.714909 462.784943,20.839909 459.913818,19.146029
	C456.582275,17.180511 453.177582,16.453985 449.406830,17.779228
	C447.184357,18.560337 444.789307,18.946934 442.515747,18.225893
	C435.621246,16.039349 428.344757,16.219961 421.320282,15.388732
	C411.972107,14.282532 402.631989,11.939711 393.056366,12.793267
	C389.852417,13.078860 386.760284,12.327240 383.970886,10.589530
	C382.980011,9.972256 381.856445,9.050396 380.775330,9.335023
	C372.905334,11.406998 365.952057,6.172771 358.247742,6.640678
	C355.327850,6.818012 351.951721,6.208787 352.758301,1.354212
	C400.020905,1.000000 447.041779,1.000000 494.531342,1.000000
	C498.273987,4.231438 502.227631,5.088390 506.776123,5.357585
	C514.902527,5.838532 522.728760,8.723288 530.895386,9.151799
	C546.002625,9.944491 560.763611,13.543670 575.845581,14.555640
	C579.812683,14.821825 583.651428,15.741719 587.337646,17.607283
	C593.590332,20.771654 600.584717,22.335369 607.258118,23.851788
	C611.712219,24.863903 616.740112,24.329798 621.062988,26.592413
	C625.898071,29.123127 630.768311,30.801346 636.303040,29.456549
	C637.247498,29.227066 638.363037,29.399433 639.245605,29.838169
	C646.020142,33.205929 652.739258,32.646221 659.506653,29.801619
	C660.880432,29.224163 662.450500,29.211710 663.948181,29.238052
	C667.276978,29.296604 669.884766,28.218889 672.346130,25.737980
	C676.508118,21.542902 684.727112,20.663807 689.613342,23.702633
	C691.526245,31.047958 689.346497,34.420120 682.358215,34.742214
	C675.133606,35.075191 674.977417,34.997139 674.860352,43.027843
	C674.770569,49.189789 675.118347,55.318607 675.690186,61.828583
	C675.807068,62.964161 674.687622,64.251823 676.838379,64.101852
	C678.192505,63.449760 679.273560,63.077511 680.726013,63.775501
z"
        />
        <path
          fill="#314651"
          opacity="1.000000"
          stroke="none"
          d="
M166.219513,800.672791
	C158.645752,801.000000 151.291504,801.000000 143.468628,801.000000
	C135.902008,793.509033 127.904808,786.904480 121.348160,778.874573
	C115.980972,772.301392 108.568604,770.126404 100.491211,769.815979
	C95.660507,769.630310 90.793396,770.000549 86.429970,767.312134
	C86.152672,767.141235 85.791145,767.028870 85.470993,767.061035
	C70.863762,768.529541 57.182507,761.010620 42.520149,762.688354
	C37.792027,763.229248 33.636475,762.501587 30.363501,757.783997
	C28.023579,754.411255 22.873489,753.851807 19.273624,755.387512
	C12.739479,758.174988 7.344738,755.479492 1.311441,753.258240
	C1.000000,736.645752 1.000000,720.291565 1.000000,703.468628
	C3.575193,700.680847 6.288182,701.576172 8.916123,702.783569
	C14.874847,705.521240 21.000879,707.482300 27.652319,707.366333
	C28.818798,707.346069 30.268953,707.222473 31.044668,707.949829
	C35.760838,712.371582 41.668903,711.156982 47.193039,711.473511
	C56.485138,712.006104 65.592873,714.157837 74.905502,714.435425
	C76.587204,714.485535 78.396385,714.715576 79.621002,715.840942
	C83.893608,719.767456 89.037895,719.599060 94.211029,719.150818
	C97.265533,718.886169 99.621574,719.575623 101.695282,722.025330
	C104.274086,725.071777 108.573341,725.867493 111.197311,729.125916
	C117.981583,737.550659 124.087730,746.441040 129.779221,755.608826
	C139.041489,770.528320 149.785431,784.182556 163.021103,795.799072
	C164.467545,797.068604 166.564758,797.881226 166.219513,800.672791
z"
        />
        <path
          fill="#2E252C"
          opacity="1.000000"
          stroke="none"
          d="
M991.339722,715.776855
	C989.889465,717.344788 989.291138,719.117004 987.007751,719.282532
	C985.500977,719.391785 983.922119,720.802979 983.755371,722.099121
	C982.915588,728.629150 977.808228,727.615173 973.664490,727.923157
	C970.316040,728.171997 967.738770,728.967712 967.058044,732.623535
	C966.767029,734.185974 965.993286,735.008545 964.396240,734.971375
	C957.563538,734.812317 953.049988,740.935364 946.449829,741.590698
	C944.896057,741.744934 944.582092,743.994263 944.456726,745.356506
	C943.913879,751.256287 939.607239,752.599121 935.017822,752.876892
	C930.848450,753.129333 929.279846,754.672607 928.851624,758.854797
	C927.977600,767.390442 925.896851,769.607117 917.358093,768.791260
	C909.589661,768.048950 903.667419,770.583130 899.456665,776.352356
	C896.040588,781.032898 890.774963,782.028748 886.507568,784.915161
	C885.253906,785.763062 883.707214,786.304565 883.281616,787.800110
	C882.487915,790.589172 880.715759,791.748169 877.950989,792.250610
	C873.352905,793.086304 873.939392,796.810913 874.000000,800.570496
	C860.312439,801.000000 846.624878,801.000000 832.468628,801.000000
	C829.900024,798.027588 831.254761,794.978516 831.963013,791.559998
	C834.814636,788.834106 836.033630,785.574707 837.543762,782.502441
	C839.733826,778.046875 842.978394,775.569580 848.581909,776.808899
	C851.140076,778.352478 853.105652,778.110168 854.536865,775.904053
	C857.147156,771.880371 860.999817,771.259155 865.395508,771.274597
	C872.262451,771.298706 873.922546,769.334534 873.280212,762.542480
	C873.094727,760.580994 873.082031,758.757141 875.274780,758.011353
	C878.289612,756.985962 880.833801,757.535950 882.270813,760.844604
	C884.883484,750.537048 891.991760,745.436035 901.651245,743.208496
	C903.786255,742.716125 905.338806,741.864258 905.642700,739.456299
	C906.455261,733.018860 911.519531,729.377869 915.714783,725.537903
	C919.108032,722.431946 922.788940,719.318176 927.880432,718.284485
	C931.001038,717.651001 932.927490,714.132080 935.303345,711.817688
	C937.923767,709.264954 940.513794,706.679382 943.055481,704.047546
	C944.448792,702.604919 945.823547,701.132568 945.715393,698.899536
	C945.547852,695.440552 947.386414,693.931946 950.628235,693.168823
	C955.344421,692.058716 960.034546,690.908325 964.903442,691.363281
	C969.788086,691.819702 973.568298,690.114197 976.774902,686.443054
	C979.176025,683.693970 981.723450,681.085510 984.036377,678.237488
	C987.285828,674.236206 991.849182,675.153076 993.736206,679.955322
	C994.907043,682.935181 994.633545,686.178833 995.476196,689.248169
	C996.466064,692.853516 998.449646,694.540405 1002.233459,694.366150
	C1004.525635,694.260559 1006.921570,694.106689 1009.256592,695.812622
	C1010.574341,703.453796 1008.629761,706.331299 1001.650085,706.631409
	C997.516663,706.809082 994.597656,707.780029 993.789673,712.285583
	C993.558411,713.574646 992.807129,714.737366 991.339722,715.776855
z"
        />
        <path
          fill="#2C4653"
          opacity="1.000000"
          stroke="none"
          d="
M1.280550,513.245361
	C1.000000,501.645782 1.000000,490.291534 1.000000,478.468658
	C3.150188,475.865570 5.932494,476.169220 8.548697,476.241058
	C11.884618,476.332703 14.369477,475.028595 14.669433,471.744690
	C15.396979,463.779480 21.172564,461.454651 27.474257,459.823151
	C32.339901,458.563416 37.224087,457.638733 40.605145,453.387512
	C41.319405,452.489410 42.526508,451.904327 43.671574,451.814484
	C51.039085,451.236267 57.736591,447.874908 64.992096,446.805115
	C71.161484,445.895538 77.600235,445.939545 82.569405,440.920044
	C84.378990,439.092133 87.507248,439.151611 90.095886,438.516571
	C108.726723,433.946228 127.829002,432.655701 146.782944,430.359253
	C154.411453,429.434998 161.647964,426.700226 169.287201,425.866058
	C172.268570,425.540497 172.971191,422.164825 175.639587,420.667297
	C176.266556,420.437744 176.528488,420.385132 177.185822,420.371429
	C178.350815,420.671600 178.888748,421.214813 179.530762,422.173218
	C179.810089,422.706787 179.902527,422.925903 180.092575,423.480469
	C180.330383,424.475616 180.370132,425.147003 180.459961,426.132507
	C180.602631,427.340515 181.183502,427.974243 181.894531,428.923157
	C182.571152,430.036774 183.043991,430.875153 183.450211,432.175049
	C184.707077,436.102631 186.857498,439.352478 186.668121,443.304169
	C186.564575,445.464691 186.399185,447.567780 184.442764,449.493530
	C182.498581,450.604401 180.819000,450.635071 178.715637,450.083832
	C175.123718,446.643799 171.563736,446.223969 167.048630,447.383362
	C156.610916,450.063568 146.079971,452.458679 135.372086,453.888824
	C133.357437,454.157867 132.167389,455.519348 130.887650,456.810516
	C129.349152,458.362732 127.710541,460.283051 125.521629,460.176697
	C118.258713,459.823761 111.651550,462.983429 104.623039,463.659790
	C101.521248,463.958252 98.812920,465.012695 96.244438,466.584442
	C92.695038,468.756439 88.890823,470.447357 85.263046,472.426971
	C80.509377,475.020996 76.625488,479.047394 73.206146,483.189850
	C70.577133,486.374878 67.965492,488.713074 63.743542,488.624908
	C59.532059,488.536987 56.943916,489.840302 56.323479,494.628937
	C55.781265,498.813782 53.619572,499.579376 49.411449,498.097687
	C44.050934,496.210297 40.770977,497.161438 37.472824,501.899048
	C35.790340,504.315887 34.147915,505.280182 31.256933,503.697357
	C28.495678,502.185547 24.284161,504.487366 23.360146,507.683319
	C21.988209,512.428650 14.603312,517.358276 10.005460,516.157593
	C7.171935,515.417603 4.247380,514.778381 1.280550,513.245361
z"
        />
        <path
          fill="#081018"
          opacity="1.000000"
          stroke="none"
          d="
M1.333222,293.276581
	C1.000000,287.977905 1.000000,282.955811 1.000000,277.466858
	C3.224032,274.881073 5.853109,275.218292 8.516366,275.759613
	C9.982838,276.057678 11.345958,276.983643 12.842240,276.888855
	C24.687489,276.138489 36.270084,279.408875 48.080532,279.131317
	C51.402699,279.053223 54.715382,279.141632 57.923668,277.949097
	C59.652988,277.306305 61.579292,277.166870 63.339153,277.729584
	C69.019119,279.545776 74.854752,279.117859 80.656975,279.112152
	C94.761475,279.098236 108.860481,279.827606 122.947777,279.818085
	C131.296646,279.812439 139.492111,279.462402 147.556458,282.025757
	C153.360748,283.870697 158.926529,286.324646 164.901703,288.756348
	C165.851288,290.748810 164.797562,291.622131 163.287201,292.620911
	C160.062347,293.458862 157.825851,295.096527 155.821960,297.681915
	C153.675125,305.079681 148.843765,308.211426 141.654663,308.269104
	C140.342621,308.279633 139.136734,309.029785 137.989975,310.335846
	C133.415482,313.206726 132.558929,317.816833 131.006271,322.411499
	C130.667328,323.216217 129.945526,323.620728 130.708496,324.608337
	C130.998749,325.714478 130.904099,326.465607 130.422119,327.560669
	C122.268501,334.341522 113.773094,339.069305 102.968025,337.961700
	C99.169830,337.572357 95.365463,338.738098 91.618973,339.533722
	C84.920113,340.956360 78.186493,342.011353 71.308975,341.854095
	C68.461609,341.788940 65.623993,342.056183 62.883656,342.777588
	C57.528324,344.187500 52.186623,345.162354 46.627819,344.038300
	C44.986691,343.706451 43.335430,344.249725 41.719059,344.713348
	C37.536766,345.913025 33.193851,346.940216 28.920795,346.645142
	C22.072506,346.172180 15.736017,347.800629 9.359422,349.771606
	C6.809747,350.559692 4.179718,351.253815 1.332517,349.272705
	C1.000000,343.311951 1.000000,337.623932 1.000000,331.467957
	C4.895693,328.153687 9.538301,328.512390 13.943283,328.237579
	C18.116791,327.977173 22.238188,327.893280 26.138466,326.129547
	C27.503349,325.512390 29.029327,325.240662 30.528957,325.264557
	C45.907047,325.509338 60.910225,322.260193 76.080605,320.539917
	C86.124474,319.401031 96.428864,320.829102 106.277832,317.549042
	C107.380966,317.181610 108.686958,317.267242 109.631630,316.630371
	C111.774055,315.186066 115.653473,315.258240 115.181664,311.349487
	C114.669846,307.109253 111.682922,304.425415 107.587875,304.018219
	C105.931892,303.853546 104.261070,303.881866 102.595535,303.886719
	C94.307198,303.911011 86.010254,304.450226 78.127625,300.771332
	C69.098083,296.557190 59.162426,296.957001 49.685043,297.147888
	C34.979443,297.444061 20.557831,294.177826 5.899169,294.789520
	C4.401711,294.852020 2.906267,294.549194 1.333222,293.276581
z"
        />
        <path
          fill="#2B3D45"
          opacity="1.000000"
          stroke="none"
          d="
M1.000000,753.468628
	C4.584099,753.096863 8.304494,752.301147 11.285747,755.404968
	C12.373463,756.537354 13.719964,755.467590 14.885409,754.763611
	C19.994917,751.677429 25.169195,753.357361 30.285259,754.748413
	C31.146547,754.982544 32.408669,755.867126 32.477802,756.552856
	C33.014500,761.876343 36.911461,760.861938 40.320286,761.020813
	C47.102634,761.336731 54.191235,759.506714 60.609455,762.068848
	C68.618279,765.265808 77.392181,763.096863 84.851921,765.500488
	C94.325577,768.553101 104.256607,766.505066 113.332596,770.271790
	C116.804863,771.713013 119.883896,774.088074 121.957420,776.821289
	C127.224434,783.763672 134.063507,789.061035 139.883636,795.387451
	C141.208633,796.827637 143.045578,797.949646 142.992035,800.636230
	C96.939880,801.000000 50.879704,800.972473 4.819764,801.064636
	C1.892276,801.070496 0.846908,800.656128 0.900234,797.335327
	C1.132478,782.872498 1.000000,768.403870 1.000000,753.468628
z"
        />
        <path
          fill="#0D1114"
          opacity="1.000000"
          stroke="none"
          d="
M906.632263,252.151123
	C911.545776,248.440369 916.879883,248.935181 922.142212,248.990250
	C926.638611,249.037323 931.136658,249.054123 935.632629,248.990128
	C950.676819,248.776016 965.601807,247.278641 980.508362,245.248413
	C985.285706,244.597778 990.404358,243.865082 994.976196,247.197113
	C997.341370,248.920807 999.414673,248.032990 1000.023193,244.871964
	C1000.548157,242.144699 1002.296265,241.000916 1005.005737,241.001816
	C1007.718811,241.002716 1009.472229,242.155975 1009.974548,244.884354
	C1010.491882,247.694366 1012.929260,249.203812 1014.483276,247.330185
	C1018.372314,242.641266 1023.721008,244.072784 1028.349365,243.254349
	C1032.439819,242.531036 1036.380981,242.120346 1040.225952,240.203644
	C1045.531982,237.558701 1048.081299,238.893585 1050.104980,244.581223
	C1050.539185,245.801605 1051.153076,246.650162 1052.448608,246.843201
	C1053.918091,247.062164 1055.455444,246.612152 1055.646606,245.155136
	C1056.107666,241.637680 1058.838989,241.476898 1061.166748,241.473480
	C1063.740967,241.469711 1065.958862,242.602936 1066.244751,245.842499
	C1066.379272,247.366714 1067.729370,247.962860 1069.223022,247.882141
	C1070.767456,247.798676 1071.399902,246.801437 1071.969849,245.433243
	C1073.682007,241.322937 1077.458984,239.889694 1080.413818,242.720413
	C1083.840088,246.002533 1088.517456,245.207092 1092.245972,247.371475
	C1094.079956,248.436111 1095.684814,246.882416 1096.031616,244.695999
	C1096.443970,242.095261 1099.070923,241.044403 1100.445312,241.574081
	C1106.710449,243.988403 1112.264526,242.004486 1118.094482,240.079803
	C1123.523560,238.287460 1129.337769,238.617096 1134.928101,239.123291
	C1140.433960,239.621841 1145.328735,237.317581 1150.576294,236.856903
	C1150.896729,236.828751 1151.361694,236.751984 1151.494995,236.535187
	C1156.063599,229.104935 1163.414673,232.769547 1169.621582,232.064255
	C1172.589600,231.726990 1175.612671,232.476517 1176.324097,228.064331
	C1176.729858,225.548111 1179.640381,225.614975 1181.656250,225.131668
	C1184.064331,224.554321 1186.553223,224.302185 1189.014893,223.965363
	C1190.264526,223.794373 1191.585327,223.326141 1191.678345,222.094604
	C1192.117920,216.283081 1196.465942,217.251434 1200.573486,217.000092
	C1201.000000,223.687637 1201.000000,230.375290 1201.000000,237.531464
	C1197.743652,238.180817 1193.147949,236.440414 1194.537720,242.971405
	C1194.783203,244.124954 1192.613159,244.470978 1191.434692,245.049500
	C1189.169922,246.161270 1186.605347,246.484879 1184.281616,246.929138
	C1177.537476,248.218597 1170.695801,248.971298 1164.069214,250.931229
	C1163.228638,251.179810 1162.015381,250.850952 1161.200928,250.389984
	C1156.891479,247.951050 1154.164673,248.424683 1151.814819,253.447632
	C1149.393799,258.622681 1143.784424,256.404541 1140.235474,255.517365
	C1135.568115,254.350632 1132.224121,254.986832 1127.711548,256.482666
	C1121.473877,258.550323 1114.101196,255.542816 1107.556519,258.811249
	C1107.050903,259.063751 1105.729858,258.130096 1105.041870,257.479340
	C1103.551025,256.069275 1101.385010,255.271133 1100.005249,256.122253
	C1095.079468,259.160706 1090.486572,257.071228 1085.707397,256.063904
	C1083.599121,255.619507 1081.385986,255.914749 1079.708496,257.724060
	C1078.446655,259.085236 1076.722900,259.004700 1075.045532,259.001831
	C1070.714355,258.994415 1066.383057,259.011108 1062.051880,258.995728
	C1060.197998,258.989166 1058.515747,259.128937 1056.990356,260.546387
	C1051.577515,265.576080 1047.179321,265.422028 1041.413086,260.202606
	C1039.820679,258.761230 1029.619385,257.666534 1027.613892,258.989868
	C1022.798096,262.167389 1017.257629,262.222412 1011.868225,263.005676
	C1008.079102,263.556335 1005.286133,262.484680 1004.306091,258.455261
	C1003.734863,256.106293 1002.003174,255.695267 1000.081543,256.040985
	C985.647034,258.637909 971.105896,259.545227 956.459045,259.062164
	C955.513062,259.030945 954.405701,259.307343 953.616455,259.816925
	C945.555481,265.021790 936.640320,264.849731 927.604858,264.147156
	C920.351501,263.583130 913.613342,265.878815 906.884644,268.115814
	C906.288269,268.314117 905.424133,268.825470 905.347351,269.298950
	C904.318787,275.640930 898.069702,280.336273 899.934875,287.686523
	C901.023743,291.977570 904.242615,291.599823 907.223450,292.253265
	C912.548523,293.420654 912.844238,294.045471 909.767944,298.136047
	C909.210144,298.321381 909.010193,298.319305 908.531738,298.202789
	C908.085999,297.976990 907.918762,297.865662 907.522461,297.557892
	C907.152588,297.218536 907.011719,297.075592 906.681946,296.703094
	C905.871338,295.843658 905.099609,295.802155 903.966919,295.928284
	C903.203613,295.923065 902.770752,295.852448 902.033325,295.605469
	C900.321716,293.737000 898.642212,294.209167 896.575562,294.912903
	C895.132996,294.917297 894.216614,294.589020 893.681946,293.146454
	C894.512695,289.885956 895.212952,287.008942 895.639099,284.027924
	C896.289490,279.477753 896.579834,274.804596 899.951538,271.030365
	C901.701416,269.071625 901.278442,266.277527 901.244812,263.796112
	C901.182251,259.178558 902.739014,255.329987 906.632263,252.151123
z"
        />
        <path
          fill="#364F5A"
          opacity="1.000000"
          stroke="none"
          d="
M166.468643,801.000000
	C160.566956,795.733582 154.855759,790.721130 149.763763,785.143250
	C141.388611,775.968994 133.644226,766.221313 127.746536,755.236877
	C125.432289,750.926453 122.400375,747.163879 119.431938,743.476257
	C116.309990,739.597961 115.116989,734.508118 110.953156,731.321594
	C108.259964,729.260498 106.640770,725.734924 102.603081,725.623047
	C102.315666,725.615112 101.876259,725.362793 101.777374,725.116028
	C99.208534,718.705933 93.710823,721.164856 89.170593,721.054749
	C85.605858,720.968140 82.231621,721.072266 79.798729,717.690857
	C79.140320,716.775757 77.459122,716.145081 76.252373,716.152954
	C70.571739,716.190002 65.090309,715.141296 59.509869,714.216919
	C52.681290,713.085815 45.622849,713.341187 38.663563,713.001770
	C37.666473,712.953125 36.148575,713.340027 35.749634,712.833679
	C31.084276,706.912048 24.070656,709.581482 18.257154,708.158508
	C12.630907,706.781311 7.996999,702.421936 1.418831,703.000000
	C1.000000,694.645752 1.000000,686.291504 1.000000,677.468628
	C4.149664,676.964966 7.321332,677.262085 10.381681,676.141907
	C14.080253,674.788269 16.944008,678.029785 20.435202,678.012390
	C31.535337,677.957031 42.353783,680.507996 53.306347,681.589233
	C61.617554,682.409790 69.929489,682.987610 77.596252,686.116699
	C83.664421,688.593384 89.905487,689.683960 96.592491,690.985962
	C102.011497,695.206848 107.300507,699.111145 111.647522,704.567017
	C117.251740,713.594971 124.040115,721.481018 127.706032,731.287415
	C128.930252,734.562256 131.417419,737.283569 133.766357,739.774414
	C139.413803,745.762878 140.440186,754.252563 145.470291,760.456848
	C150.776367,767.001587 154.747162,774.707214 160.948471,780.365234
	C166.264359,785.215393 170.447205,791.633240 178.653885,793.208130
	C183.122543,794.065674 186.973312,797.795044 191.967758,798.128052
	C192.949493,798.193420 193.587692,799.223938 193.216583,800.702026
	C184.312439,801.000000 175.624863,801.000000 166.468643,801.000000
z"
        />
        <path
          fill="#181C20"
          opacity="1.000000"
          stroke="none"
          d="
M1200.706787,415.751373
	C1201.000000,435.687561 1201.000000,455.375122 1201.000000,475.531342
	C1197.887085,478.779510 1194.061279,477.789093 1190.489380,477.877411
	C1185.377441,478.003906 1180.330322,477.442169 1176.263794,481.942566
	C1174.007446,484.439728 1170.657959,483.649872 1167.960815,481.888184
	C1165.860596,480.516418 1163.817749,480.208923 1161.358521,480.744934
	C1152.310913,482.717041 1143.159424,482.103912 1134.038330,481.414764
	C1121.743042,480.485809 1109.445068,480.963074 1097.146240,481.119904
	C1096.141602,481.132690 1095.221924,481.627167 1093.796143,481.888153
	C1092.418579,481.965240 1091.566162,481.736389 1090.530273,480.838501
	C1087.038696,471.212036 1087.013916,470.932220 1076.966919,471.822052
	C1068.924194,472.534332 1061.574219,469.778290 1053.984863,468.232635
	C1051.004395,467.625641 1050.826050,465.606750 1053.235107,463.683136
	C1054.128662,462.969604 1055.404907,462.806671 1056.434570,461.518311
	C1064.258789,459.219086 1071.344604,461.443542 1078.839600,463.409149
	C1081.872925,465.233765 1084.552612,466.819427 1088.180786,464.948059
	C1096.643311,465.526733 1104.698486,466.612183 1112.880859,466.364227
	C1119.295410,466.169800 1125.592163,464.995667 1132.180908,465.685150
	C1136.713135,466.159424 1142.011475,464.376190 1144.941772,459.496704
	C1146.674072,456.612305 1149.448364,456.119110 1152.360474,457.201416
	C1156.567871,458.765045 1159.906006,457.860382 1163.369629,455.019470
	C1166.695312,452.291840 1171.831177,451.964111 1173.656128,447.095428
	C1174.119019,445.860382 1175.167114,444.735046 1174.594971,443.398132
	C1171.775269,436.810883 1170.999023,429.588318 1168.047119,423.019623
	C1166.374878,419.298431 1163.847656,417.691376 1159.934448,417.730957
	C1157.608032,417.754486 1155.278931,417.710480 1152.951172,417.685913
	C1149.046265,417.644653 1145.840088,416.711426 1144.163940,412.569458
	C1142.870728,409.373871 1141.248413,410.629730 1140.071045,412.845184
	C1139.298096,414.299683 1138.964355,416.057587 1137.403564,416.947662
	C1134.295776,418.719635 1132.312988,417.119751 1130.957397,414.473541
	C1129.233765,411.108826 1127.028320,408.065674 1124.719238,404.632385
	C1123.891846,403.476318 1123.633057,402.503296 1123.520020,401.121033
	C1123.328491,398.795868 1124.146240,396.668060 1122.284180,394.756989
	C1121.623901,394.331787 1121.274048,394.049927 1120.714355,393.493530
	C1119.482910,391.489044 1118.985229,389.545837 1118.366455,387.324951
	C1118.225098,386.569336 1119.083130,386.027313 1117.961426,385.474487
	C1117.595581,385.104431 1117.476440,384.926880 1117.253418,384.440857
	C1118.073486,380.330627 1114.847778,379.668549 1112.480469,377.907776
	C1112.026489,376.881073 1112.003540,376.146912 1112.322754,375.045685
	C1117.225830,371.296539 1126.354980,372.851410 1129.477539,378.256805
	C1131.647705,382.013550 1134.024048,382.327606 1137.690918,381.382599
	C1143.203613,379.961914 1145.468994,381.358307 1146.585449,386.249573
	C1147.763306,391.409790 1154.352173,395.001221 1159.571289,393.327820
	C1166.332031,391.160156 1166.121826,391.363739 1170.962646,396.361481
	C1173.933960,399.429199 1178.184570,400.928528 1181.886841,403.061829
	C1185.126587,404.928833 1186.946411,406.824951 1184.762695,410.555969
	C1183.102539,413.392395 1184.793945,413.958405 1187.191040,414.226044
	C1191.592896,414.717499 1196.187866,413.224976 1200.706787,415.751373
z"
        />
        <path
          fill="#091017"
          opacity="1.000000"
          stroke="none"
          d="
M689.348389,24.265789
	C683.380432,22.179420 675.948425,24.332150 672.434692,28.653395
	C671.743774,29.503174 670.507202,30.596830 669.635803,30.504683
	C662.013672,29.698746 655.333496,34.426369 647.795593,34.046806
	C645.253540,33.918804 642.857239,33.761425 640.704407,32.208149
	C639.929626,31.649134 638.797180,30.938047 638.026367,31.123108
	C631.462280,32.699051 625.565552,31.252634 619.719360,28.104792
	C615.978027,26.090233 612.082275,25.768230 607.412292,26.137243
	C603.808289,26.422028 598.496399,23.791990 594.206543,21.946983
	C582.963074,17.111374 571.258118,15.758580 559.197937,14.669552
	C551.104675,13.938734 543.049316,11.701495 534.797668,11.450242
	C523.701416,11.112372 513.169800,6.728566 501.992035,6.972516
	C498.398193,7.050950 496.000854,5.308280 495.048584,1.363346
	C580.354248,1.000000 665.708435,1.000000 751.531372,1.000000
	C754.104370,5.507108 753.171631,7.911078 748.696106,9.195145
	C745.301270,10.169150 741.785583,9.852682 738.317200,9.861612
	C735.068542,9.869976 732.144897,10.399669 729.679504,12.831913
	C726.877014,15.596620 723.182312,15.775808 719.476379,15.873964
	C714.201843,16.013666 708.876892,15.313502 703.678284,16.910845
	C701.058411,17.715847 698.924622,18.801325 697.544861,21.447201
	C695.936768,24.530966 693.315857,26.061121 689.348389,24.265789
z"
        />
        <path
          fill="#0F1B23"
          opacity="1.000000"
          stroke="none"
          d="
M227.776535,259.174896
	C232.111862,254.083878 236.925186,250.653412 243.766571,250.948761
	C246.000458,251.045212 247.904510,251.490555 249.733643,252.802231
	C251.443420,254.028305 252.921021,255.382294 252.508499,257.588654
	C252.023285,260.183838 249.810318,260.048676 247.395554,260.000122
	C246.809692,259.809692 246.612259,259.627899 246.405777,259.457031
	C246.444016,259.480377 246.479324,259.508026 246.759308,259.765259
	C246.971085,260.363647 246.940048,260.729767 246.855545,261.644348
	C245.367783,263.262543 243.724060,263.240112 242.053589,263.121918
	C229.626129,262.242554 217.348114,264.541290 204.596954,265.004761
	C194.016602,267.014893 188.816544,273.850128 186.190231,283.268494
	C185.535065,285.618011 185.447021,288.206482 183.155518,290.256165
	C181.317429,290.771393 180.018112,290.358154 178.584869,289.096313
	C178.189896,288.444763 178.072784,288.133789 177.981369,287.815704
	C177.246429,285.258759 179.752472,282.363251 177.588303,280.128510
	C175.688095,278.166321 172.784958,279.856720 170.431061,279.020203
	C166.751938,277.712708 162.897812,277.812103 159.071655,277.881256
	C148.792007,278.067047 138.617035,275.582703 128.289017,276.744141
	C126.143723,276.985382 124.011032,276.240509 121.966606,275.479492
	C120.719604,275.015350 119.438271,274.141296 118.191841,274.260986
	C109.203102,275.123871 100.368393,271.848969 91.374886,273.341919
	C87.707748,273.950684 84.080750,273.921143 80.532379,272.662262
	C79.745293,272.382996 78.938553,272.025757 78.117844,272.082642
	C67.261810,272.834717 56.653992,270.093903 45.863583,269.841339
	C31.542128,269.506165 17.506725,266.750061 3.422616,264.455750
	C2.762436,264.348236 2.192463,263.965515 1.331651,263.275879
	C1.000000,257.645081 1.000000,252.290146 1.000000,246.467606
	C2.681382,244.268158 4.877280,244.233459 7.045368,244.247818
	C14.181684,244.295105 21.319626,243.812622 28.373415,245.759171
	C32.216873,246.819778 36.322655,246.200134 40.310993,246.202255
	C43.837830,246.204147 47.301636,246.353287 50.468330,248.174118
	C52.177258,249.156723 53.989677,249.067352 55.785328,248.469574
	C61.924084,246.425995 67.524620,246.973969 72.545624,251.474716
	C74.907753,253.592102 77.637802,253.941833 80.901505,253.069473
	C86.431656,251.591339 92.076477,251.899124 97.678490,253.315475
	C103.171097,254.704178 108.572884,254.886185 114.381302,254.045517
	C122.344086,252.893066 130.657425,253.107376 138.319687,256.478912
	C141.380280,257.825623 143.167328,257.808350 144.296021,254.244476
	C145.434860,250.648544 147.717041,248.258621 151.330154,248.718887
	C149.772263,248.097778 146.504333,248.420609 146.781799,244.333054
	C154.619507,244.287933 161.808243,247.139938 169.471146,247.579346
	C172.875092,247.774536 176.014481,247.113205 179.279617,247.007584
	C185.764236,246.797836 192.205811,247.200897 198.653946,247.951050
	C201.060760,248.231049 202.166916,250.599640 204.325577,250.812241
	C208.706543,251.243759 213.036758,252.926575 217.524261,250.761124
	C220.909103,249.127808 223.701385,249.621857 224.324173,254.194839
	C224.470779,255.271423 225.345978,256.248810 225.938736,257.637512
	C226.123810,258.902313 226.776886,259.196381 227.776535,259.174896
z"
        />
        <path
          fill="#0F141A"
          opacity="1.000000"
          stroke="none"
          d="
M689.000000,24.433329
	C692.718323,24.038374 695.186951,23.159349 696.839355,18.952463
	C698.693176,14.232916 704.249329,14.643799 708.497070,14.124982
	C712.925293,13.584113 717.470825,13.964836 721.964417,14.011964
	C724.947937,14.043255 727.496643,13.429796 729.259399,10.664726
	C730.634521,8.507688 732.935181,7.988623 735.382446,7.998345
	C739.044128,8.012893 742.707336,8.049637 746.367188,7.962057
	C750.288757,7.868216 752.734924,6.289321 752.000000,1.422937
	C797.687561,1.000000 843.375122,1.000000 889.531372,1.000000
	C889.963440,5.912053 886.914673,6.747460 882.712097,7.085503
	C870.190369,8.092698 857.544678,7.999798 845.214600,10.921796
	C844.102173,11.185421 843.044983,11.198621 842.051270,12.082665
	C836.377991,17.130247 830.252991,19.213427 822.207214,17.883976
	C814.803223,16.660593 807.163940,14.746041 799.272400,17.642927
	C795.297424,19.102083 790.368958,17.972425 785.871582,17.997368
	C782.313843,18.017099 779.584778,18.895250 776.685913,21.694498
	C773.525574,24.746225 768.650879,26.351852 763.892883,25.928423
	C758.194458,25.421297 753.388489,26.636663 749.834656,31.350132
	C748.808350,32.711323 747.279175,33.504768 745.917358,32.859047
	C740.681396,30.376478 736.692993,34.373783 732.118469,35.211655
	C731.054565,35.406513 730.422363,36.205593 730.161438,37.256634
	C730.041199,37.740860 730.079102,38.591099 729.840881,38.671726
	C723.111755,40.949593 722.250977,51.414581 712.615051,50.216061
	C706.142822,49.411049 700.359436,53.528492 695.342346,57.182846
	C692.035339,59.591564 689.179382,61.384323 685.101440,61.028942
	C683.366699,60.877758 681.729492,61.199669 681.064819,63.617599
	C679.894409,64.304436 678.791199,64.610054 677.345337,64.957458
	C676.085449,65.177872 675.208801,65.152763 674.229370,64.244789
	C673.111572,55.401165 672.932922,46.783859 672.928162,38.144642
	C672.926025,34.202408 674.417053,32.604946 678.252563,32.988987
	C679.076538,33.071495 679.915833,33.001068 680.748108,32.999722
	C688.597107,32.987007 688.956360,32.632702 689.000000,24.433329
z"
        />
        <path
          fill="#11181F"
          opacity="1.000000"
          stroke="none"
          d="
M1201.000000,589.531372
	C1197.300171,590.192566 1193.721191,590.316284 1191.579590,586.273926
	C1190.826538,584.852600 1188.954346,584.745544 1187.485840,585.062317
	C1179.172485,586.855835 1171.044434,584.742859 1162.846436,584.063721
	C1159.145264,583.757141 1155.484619,583.754089 1152.635254,586.786621
	C1151.651733,587.833252 1150.320557,588.050354 1148.953247,587.989075
	C1138.808838,587.534851 1128.716309,588.617493 1118.598999,588.966064
	C1116.540649,589.036987 1114.097778,589.602051 1113.068604,586.372131
	C1113.386230,582.798645 1116.224365,582.819641 1118.262939,582.459900
	C1125.798950,581.129883 1133.249512,579.282349 1140.832764,578.303162
	C1146.478760,577.574280 1151.923584,576.069031 1157.443481,574.851685
	C1158.814209,574.549316 1160.433838,574.242371 1160.984863,572.597595
	C1160.925781,571.861633 1160.420654,571.622253 1159.939209,571.768860
	C1144.280640,576.536438 1127.955933,577.817505 1111.977783,580.906311
	C1107.947632,581.685425 1103.929565,583.307800 1099.328369,581.847656
	C1094.535034,573.189880 1086.062500,570.718567 1077.964111,568.084961
	C1069.325317,565.275635 1060.785278,561.513733 1051.308838,562.564880
	C1046.378296,558.577637 1039.553833,558.088135 1034.763672,553.255493
	C1034.460449,551.701538 1034.579712,550.585327 1035.224365,549.124634
	C1038.575439,545.487488 1042.944336,546.446716 1046.731689,545.735229
	C1053.241333,544.512329 1059.815918,543.574402 1066.695312,542.209961
	C1070.443237,540.515320 1074.713867,541.761475 1077.968018,538.545898
	C1087.454346,538.143799 1095.536133,542.502686 1104.167725,544.526367
	C1109.455078,545.766052 1114.595215,547.652344 1120.350342,548.717285
	C1130.173096,552.792297 1140.135376,555.174438 1149.904297,558.129883
	C1153.614502,559.252319 1156.989136,560.747681 1160.370605,563.198853
	C1163.596924,562.156067 1168.232788,565.954041 1169.399658,561.844971
	C1170.607666,557.591614 1169.119873,552.813110 1165.931396,549.103577
	C1164.343994,547.256775 1162.286621,545.545227 1164.289551,542.505615
	C1167.184570,541.687744 1169.539551,542.774353 1171.946045,543.275513
	C1176.394287,544.201904 1180.781494,544.603210 1185.205078,543.107788
	C1188.201294,542.094788 1191.196899,542.218201 1193.646362,544.423645
	C1195.610840,546.192383 1198.420776,545.842285 1200.686768,547.738831
	C1201.000000,561.687561 1201.000000,575.375122 1201.000000,589.531372
z"
        />
        <path
          fill="#11161D"
          opacity="1.000000"
          stroke="none"
          d="
M1092.375000,481.254974
	C1094.456055,479.201782 1096.647461,479.037384 1099.054688,478.952148
	C1117.132446,478.312164 1135.159058,480.046173 1153.221313,480.015015
	C1155.725098,480.010681 1158.201172,479.997314 1160.549072,478.985840
	C1163.719360,477.620117 1166.434570,477.688080 1169.104370,480.452789
	C1171.688965,483.129364 1174.742676,482.670563 1176.592163,479.493591
	C1178.413818,476.364624 1180.941284,475.879181 1184.129395,475.962280
	C1189.437378,476.100708 1194.751343,476.000031 1200.531494,476.000000
	C1201.000000,486.687561 1201.000000,497.375122 1201.000000,508.531342
	C1199.035767,510.965088 1196.496338,510.965393 1194.009766,510.923584
	C1176.918945,510.636414 1159.816528,511.466400 1142.743042,510.796600
	C1133.956665,510.451874 1125.248169,509.691467 1116.447021,510.791260
	C1112.484741,511.286407 1108.546753,511.179016 1105.107300,513.694580
	C1103.430176,514.921204 1101.212524,514.253662 1098.890381,514.896118
	C1096.415894,515.700012 1094.281128,516.135803 1091.832764,516.954346
	C1091.180786,517.141174 1090.914917,517.200256 1090.241455,517.301819
	C1088.723267,517.384277 1087.624878,517.337585 1086.220703,516.674683
	C1085.668701,516.253662 1085.478760,516.046509 1085.090332,515.468872
	C1084.815796,514.830750 1084.739624,514.563049 1084.594360,513.886108
	C1083.103394,504.945160 1082.488647,504.897430 1073.692749,502.340210
	C1065.188843,499.867981 1056.712402,497.838562 1047.852295,497.331360
	C1042.131958,497.003906 1039.094604,492.295532 1035.375732,488.849823
	C1033.823242,487.411407 1034.483154,485.743866 1036.127075,484.676849
	C1038.949829,482.844635 1041.988892,481.570587 1045.442993,481.546631
	C1048.934326,481.522430 1052.425781,481.546021 1056.295044,481.508423
	C1064.354736,480.563660 1071.865601,483.067261 1078.550781,482.529602
	C1076.132080,482.379944 1072.471558,482.703766 1071.004761,478.233704
	C1074.690430,475.689178 1078.190308,477.660461 1081.618530,478.246582
	C1085.171631,478.854034 1088.616699,480.139679 1092.375000,481.254974
z"
        />
        <path
          fill="#17232A"
          opacity="1.000000"
          stroke="none"
          d="
M1.000000,81.460632
	C5.046156,81.171028 9.157638,81.688751 9.776571,75.782127
	C9.957278,74.057602 11.913433,73.362419 13.639617,73.090759
	C16.093204,72.704620 18.544008,72.178177 21.013597,72.049561
	C23.673244,71.911041 24.999689,70.851616 25.010794,68.113480
	C25.029570,63.482307 27.981821,62.353683 31.755888,63.217567
	C39.460175,64.981087 46.905804,63.831585 54.364796,61.946415
	C55.569695,61.641895 56.493858,61.030384 57.299740,60.179062
	C61.569530,55.668533 67.065704,56.085556 72.531883,56.346256
	C78.710289,56.640915 85.199562,59.579491 90.301636,52.829449
	C91.933334,50.670708 95.699501,52.152847 98.484818,52.034954
	C101.375000,51.912621 103.994469,51.953159 105.481857,48.580593
	C106.417145,46.459885 108.732376,45.496700 110.957893,47.032665
	C117.647522,51.649574 123.044983,47.019600 128.064041,44.049171
	C131.483826,42.025227 133.852768,40.821495 137.380005,43.102753
	C139.598770,44.537758 142.201096,44.410934 144.557480,43.175591
	C149.570786,40.547337 154.993362,40.087662 160.489288,40.013885
	C164.304596,39.962669 168.084961,40.218937 171.719971,38.403996
	C173.086105,37.721893 175.208374,37.536373 176.602798,38.089783
	C187.740097,42.509850 198.793900,37.398975 209.856461,38.041668
	C220.645737,38.668480 231.569138,37.827358 242.170395,40.790985
	C243.263000,41.096424 244.562881,41.227814 245.634796,40.943359
	C250.751663,39.585499 255.791992,40.489761 260.917847,40.885239
	C265.892853,41.269073 270.561096,40.583050 275.608429,43.235794
	C279.454651,45.257275 285.333313,45.616211 289.732971,41.902489
	C289.859283,41.795868 290.003937,41.649925 290.150330,41.636780
	C300.702789,40.688972 311.252808,39.877811 321.408936,44.012417
	C322.456146,44.438747 323.484283,45.918598 324.297729,45.760368
	C335.147125,43.649956 345.228363,49.831680 356.140564,48.460255
	C360.369659,47.928749 365.499481,47.916500 369.508057,50.998833
	C370.960388,52.115608 372.753387,52.398525 374.265930,51.921707
	C384.376038,48.734531 393.193726,53.104218 402.214203,56.416210
	C402.980072,56.697422 403.835968,57.023956 404.611176,56.945503
	C415.311249,55.862671 424.412018,61.367512 434.150757,64.172096
	C436.426147,64.827385 438.230255,64.830460 440.332336,63.888615
	C445.902069,61.393097 451.526703,60.865841 457.085541,64.030647
	C458.914978,65.072197 460.872772,65.103867 462.903259,64.824219
	C472.328979,63.526016 481.696716,62.773079 490.536499,67.571999
	C491.906097,68.315521 493.758026,68.436607 494.801270,67.343056
	C497.500275,64.513847 500.839630,65.033211 504.563354,65.000641
	C506.243835,67.010384 504.976257,68.536438 503.434998,68.892418
	C498.183563,70.105339 493.489777,73.452171 487.408844,73.000969
	C486.250153,73.001732 485.500488,73.001549 484.376038,73.001755
	C480.458191,68.988930 474.987518,69.379166 470.628632,67.204498
	C469.481903,66.632378 467.960449,66.863388 466.883514,67.890144
	C461.781036,72.754723 455.863159,71.256866 450.041382,69.954971
	C448.599762,69.632591 447.236481,68.889809 445.702759,69.013000
	C436.581329,69.745590 428.063446,66.715500 419.469757,64.530029
	C411.393646,62.476192 403.160034,61.115646 394.990295,61.281204
	C389.170868,61.399136 384.487396,58.732437 379.220856,57.682262
	C369.064545,55.657032 358.917847,54.414383 348.591492,55.038017
	C343.555115,55.342178 338.674774,54.232277 333.714783,53.882881
	C332.585052,53.803303 331.188446,53.560017 330.425781,52.846088
	C324.179443,46.998943 317.868835,49.685043 311.309174,52.204998
	C307.216095,53.777393 302.867188,52.880959 298.538086,52.411652
	C286.047119,51.057552 273.976471,46.728912 261.282776,47.055435
	C256.185760,47.186550 251.127060,48.229973 246.003830,47.055721
	C244.210159,46.644611 242.495880,47.232731 240.916397,48.505959
	C237.406433,51.335361 233.145386,51.947632 229.119400,50.423275
	C220.835678,47.286819 212.629059,45.741478 204.018387,48.906326
	C203.132446,49.231956 201.930176,49.272614 201.051804,48.955288
	C190.717361,45.221779 180.408707,49.680164 170.110580,48.954391
	C164.406876,48.552414 158.904984,50.351067 153.223663,50.040836
	C150.236023,49.877697 147.297394,49.715607 144.322128,49.187363
	C140.909027,48.581390 137.288208,48.174759 136.547928,53.454453
	C136.142639,56.345039 133.383408,56.817318 130.696228,57.039669
	C124.468643,57.554970 118.779930,54.215305 112.468460,54.907059
	C106.364433,55.576069 100.702980,56.486366 95.156654,59.814476
	C91.478027,62.021858 86.599602,63.075134 81.869179,63.029591
	C78.397774,62.996162 74.930687,62.738483 71.490616,63.932262
	C68.197899,65.074905 65.335030,65.844757 63.808109,69.855827
	C62.173046,74.150963 46.197067,79.335999 42.009647,77.763481
	C39.749752,76.914818 38.761475,72.778969 35.387737,74.965492
	C32.995640,76.515808 34.156593,79.608871 32.955910,81.791534
	C32.000397,83.528519 30.888145,84.574730 28.876999,85.007759
	C23.231226,86.223381 17.575150,87.459465 12.034700,89.071030
	C8.555632,90.083000 6.255592,94.273949 1.419470,92.999969
	C1.000000,89.307091 1.000000,85.614182 1.000000,81.460632
z"
        />
        <path
          fill="#314D58"
          opacity="1.000000"
          stroke="none"
          d="
M1.000000,513.375000
	C3.672339,512.938354 6.236141,513.085205 8.783175,514.276794
	C11.709738,515.645935 14.684211,515.589355 16.725962,512.485291
	C18.050381,510.471802 20.197046,510.089386 22.443760,509.665802
	C19.545172,506.539581 19.548746,506.546814 23.553476,504.563751
	C23.993063,504.346069 24.466505,504.063599 24.748358,503.681458
	C27.138069,500.441437 29.284527,497.279388 32.752964,502.941956
	C33.815746,504.677002 35.199539,503.264557 35.917953,501.609863
	C38.566311,495.509888 44.841515,493.389221 50.307713,496.821594
	C53.297016,498.698608 54.537098,497.675964 54.975895,494.577423
	C56.031105,487.126038 56.036491,487.118744 63.513363,487.005432
	C65.347336,486.977631 67.813515,487.461121 68.702019,485.919647
	C71.399910,481.238800 75.981018,478.467224 79.334564,474.505554
	C80.419350,473.223999 81.327240,471.690033 82.965965,471.212372
	C87.332253,469.939606 91.207916,467.865540 94.943031,465.258728
	C98.509834,462.769318 102.971420,462.718994 107.045525,461.646545
	C113.251114,460.013031 119.262093,458.563049 125.675484,458.827393
	C126.248779,458.851044 127.054428,458.419220 127.403046,457.943207
	C133.877502,449.102722 144.378296,451.792847 152.965027,449.074615
	C158.310730,447.382355 164.072968,446.961670 169.354752,445.125763
	C173.568542,443.661163 176.032806,444.583801 177.950623,448.648102
	C180.630295,454.465088 180.703232,454.585510 175.776230,457.569580
	C173.805084,458.763428 174.135071,459.705261 175.286194,461.056305
	C176.543243,462.531616 178.309341,463.036163 180.047211,463.632935
	C181.547607,464.148102 182.831879,464.986908 182.591187,466.839691
	C182.386429,468.415924 181.117950,469.207886 179.749161,469.161926
	C175.067352,469.004761 170.977081,472.302155 166.868942,472.309479
	C160.804321,472.320312 155.445557,472.969604 150.377701,476.306946
	C149.531738,476.864014 148.054962,476.605469 146.933838,476.336426
	C133.989029,473.230011 121.228531,477.817444 108.327911,477.266754
	C104.215767,477.091248 100.431717,478.735748 96.690567,481.045898
	C93.017632,483.313995 88.324623,483.890594 84.386597,486.059235
	C82.317345,487.198761 80.434105,488.477142 78.839737,490.233307
	C76.995575,492.264679 76.670769,494.266907 78.781006,496.746216
	C79.556023,500.011902 80.443115,502.852539 81.265793,505.717682
	C82.778488,510.985901 80.875633,514.619263 75.607651,514.121277
	C68.975235,513.494324 64.674805,518.494812 58.797108,519.217529
	C56.755856,519.468506 56.929413,521.738586 56.606323,523.298706
	C56.000500,526.223999 54.372978,528.730286 51.400414,528.406860
	C41.919403,527.375671 34.799160,533.568237 26.496656,536.059082
	C23.069672,537.087219 19.804956,538.024536 16.283258,537.325012
	C13.185616,536.709656 10.807150,537.527161 8.847484,540.070374
	C7.048888,542.404480 4.901749,544.270630 1.315859,542.251465
	C1.000000,534.312439 1.000000,526.624817 1.000000,518.468628
	C2.129952,516.708252 3.667027,517.083984 5.091093,517.001526
	C3.877024,516.921936 2.608331,517.199585 1.246348,516.229736
	C1.000000,515.250000 1.000000,514.500000 1.000000,513.375000
z"
        />
        <path
          fill="#35535F"
          opacity="1.000000"
          stroke="none"
          d="
M102.353256,554.257568
	C101.129036,553.693237 99.771683,552.729431 99.308502,553.647583
	C96.449867,559.314392 92.115509,556.258484 88.696526,555.161499
	C82.099442,553.044739 75.489212,554.448425 68.903259,554.106262
	C67.427475,554.029602 66.362724,554.957397 66.157074,556.490417
	C65.929810,558.184570 66.602341,559.404419 68.357178,559.867859
	C69.740257,560.233215 71.824394,560.158875 71.408180,562.269348
	C70.984764,564.416260 68.837303,563.966431 67.241692,563.980164
	C61.746223,564.027466 56.249634,564.043640 50.754387,563.985535
	C46.593098,563.941589 42.689041,563.734131 39.426556,567.507080
	C37.198154,570.084229 32.769753,568.299011 30.034571,570.058472
	C25.285858,573.113159 21.754328,577.648621 18.095871,581.886292
	C16.984377,583.173767 17.274445,585.694336 16.979534,587.658691
	C16.427277,591.337280 15.629210,594.655762 10.658612,594.052002
	C8.830268,593.829895 7.348341,594.643005 7.071080,596.697083
	C6.647014,599.838623 4.369023,600.593689 1.380148,600.989868
	C1.000000,588.645752 1.000000,576.291565 1.000000,563.468628
	C2.807950,561.137817 5.255594,561.393005 7.519723,560.995667
	C11.718552,560.258728 15.034095,558.650757 15.705720,553.725037
	C16.542788,547.585876 20.074554,543.743286 26.373140,543.068726
	C31.978609,542.468445 35.382774,537.571594 40.546227,536.175293
	C41.490135,535.920166 42.334900,535.038879 43.286156,535.028320
	C50.971886,534.942932 57.996254,530.954895 65.719955,531.247070
	C69.622566,531.394653 73.276276,530.349487 76.981041,529.380554
	C79.055252,528.838135 80.616974,527.991699 81.351524,525.815002
	C82.151360,523.444824 83.846405,521.402100 86.371132,521.614502
	C92.957214,522.168762 95.439774,518.431396 96.852074,512.570312
	C101.398979,510.237366 105.795586,511.243164 110.555038,511.694702
	C111.256081,511.942902 111.728699,512.052551 111.792328,512.289856
	C113.623093,519.116882 119.251877,521.964233 124.787415,525.102966
	C132.908691,529.707825 137.284012,538.068665 143.705475,544.904175
	C143.938980,545.830872 143.932526,546.376709 143.669708,547.316772
	C141.588806,550.064514 138.954895,549.671448 135.986786,549.495972
	C126.392952,549.708191 116.953049,547.783875 108.038788,552.871033
	C107.600815,553.353271 107.403320,553.526978 106.864311,553.901611
	C105.265350,554.506775 104.020432,555.032288 102.353256,554.257568
z"
        />
        <path
          fill="#394148"
          opacity="1.000000"
          stroke="none"
          d="
M452.259277,800.751465
	C428.312439,801.000000 404.624878,801.000000 380.468658,801.000000
	C367.984436,788.802002 356.903778,775.913940 348.891571,760.598083
	C346.833588,756.664124 343.717255,753.305054 340.974976,749.769531
	C339.230957,747.520996 337.613556,745.199036 336.632874,742.547729
	C333.818787,734.939758 329.515778,728.770691 321.204529,725.719971
	C318.024170,718.409546 319.799042,710.941895 318.783081,703.337646
	C318.845245,702.641113 318.896454,702.366089 319.101135,701.707092
	C319.412323,701.103027 319.569977,700.883057 320.044647,700.419556
	C322.933594,699.209106 325.818542,699.206116 328.536194,697.463013
	C329.366333,697.126587 329.867279,697.025208 330.766449,696.991821
	C334.066437,697.905396 336.565857,696.132507 339.275909,695.718811
	C341.048401,695.448242 342.780121,694.788391 344.928650,695.609497
	C345.741272,696.486816 345.879150,697.233032 345.652771,698.400757
	C344.853638,699.993042 343.621674,700.601868 342.548859,701.367371
	C337.132263,705.231995 336.677216,706.729675 339.372162,712.949524
	C342.563873,720.315857 347.606262,726.620911 351.286804,733.744507
	C355.477112,741.854675 360.062195,749.802795 365.453369,757.240967
	C374.045135,769.094971 382.238434,781.150574 395.642487,788.549194
	C400.217163,791.074280 404.793793,791.275635 409.492279,791.903870
	C428.096893,794.391785 442.522491,787.333435 453.474579,772.421143
	C460.229797,763.223206 464.245819,752.517029 469.982269,742.775269
	C474.263885,735.504089 477.745422,727.673218 484.277679,721.827576
	C488.277039,718.248718 492.458893,715.034668 497.408173,712.223877
	C501.178406,711.856628 504.764160,713.246338 508.301666,710.922729
	C509.888611,710.302490 510.900879,710.913269 512.169067,711.796692
	C513.237671,712.766907 514.004700,713.583374 515.596985,713.492554
	C517.668884,713.572998 519.299744,713.960388 520.990112,715.252441
	C522.951721,718.817566 521.001831,720.723022 518.348999,721.718689
	C506.747681,726.072571 498.658997,734.302856 492.160889,744.514648
	C488.338257,750.521912 483.156769,755.656067 480.792480,762.604980
	C480.075714,764.711670 479.391785,766.580444 480.787994,768.737061
	C482.685303,771.667664 481.202301,773.606445 478.303497,774.297302
	C474.840240,775.122681 473.227844,777.456482 471.557465,780.324158
	C466.800262,788.491272 459.572021,794.438904 452.259277,800.751465
z"
        />
        <path
          fill="#213642"
          opacity="1.000000"
          stroke="none"
          d="
M1.326256,448.252106
	C1.000000,440.979034 1.000000,433.958099 1.000000,426.468567
	C5.251421,421.304810 10.317700,418.534576 16.871716,419.301361
	C19.803375,419.644318 22.180275,418.267883 24.849979,417.316711
	C34.675571,413.815887 44.410599,409.639832 55.062843,409.268372
	C59.258034,409.122101 63.470306,408.969727 67.375587,407.511963
	C77.849854,403.602234 89.013626,404.040924 99.723701,401.958801
	C112.191017,399.535034 124.585846,401.047089 136.984726,400.340149
	C143.108231,399.990936 149.152954,399.040222 155.039383,396.847137
	C159.044937,395.354767 163.495956,395.015625 167.871124,395.468475
	C171.354172,395.829010 174.126328,395.025543 175.395004,391.307068
	C176.106430,389.221924 177.526321,388.615631 179.900345,388.322266
	C184.959305,387.697205 189.863724,385.985596 195.095215,387.179413
	C197.206909,387.661316 199.535934,387.666748 201.276047,388.980133
	C208.350967,394.319946 216.176453,392.436676 223.831375,391.349365
	C226.974518,390.902893 230.049255,390.079987 233.214996,390.705902
	C236.698105,391.394562 238.653412,390.015778 239.458405,386.228424
	C239.769424,385.324982 239.999146,384.832062 240.495239,384.021759
	C241.062592,383.269012 241.460068,382.914612 242.269348,382.458862
	C242.877838,382.241455 243.130432,382.188232 243.765778,382.128967
	C245.609634,382.398193 247.106003,382.473511 248.891510,383.012939
	C252.162460,384.979675 255.703674,385.163971 259.090698,386.832458
	C260.066345,387.402740 260.673096,387.849609 261.424622,388.697205
	C262.317749,390.463287 262.165009,391.847168 261.050507,393.419617
	C260.437744,394.154480 260.060791,394.646576 259.901093,395.599091
	C259.809296,396.365021 259.696167,396.798584 259.372681,397.537659
	C253.492401,404.514191 245.820068,405.400604 237.862961,404.618408
	C234.496521,404.287537 231.638428,404.502319 228.303894,405.492371
	C220.357193,407.851776 212.187393,407.354187 203.933472,404.354858
	C200.556213,403.769226 199.634888,400.774811 197.263306,398.948975
	C193.768906,395.099915 189.728134,393.454926 185.078857,394.884186
	C180.398422,396.322998 178.918915,400.385681 178.378830,404.850494
	C178.279221,405.673828 178.066910,406.456848 177.509918,407.534485
	C174.335159,411.965332 170.713028,414.437286 165.377914,412.644226
	C163.994324,412.179199 162.816254,412.830688 161.554413,413.274719
	C147.521820,418.212341 133.279709,421.670044 118.189209,419.920013
	C113.394402,419.363983 108.701912,421.119385 104.057426,421.587097
	C93.200890,422.680389 82.963036,428.063782 71.742027,426.172852
	C70.029625,425.884277 68.378868,426.117279 67.120804,427.430511
	C64.489517,430.177216 61.150181,430.622620 57.563892,430.883942
	C51.772743,431.305939 46.097012,432.683868 40.339169,433.463623
	C37.570255,433.838623 34.641430,434.535950 32.462143,436.389435
	C25.009773,442.727661 15.059696,443.675262 6.897437,448.459991
	C5.320637,449.384338 3.409458,449.589020 1.326256,448.252106
z"
        />
        <path
          fill="#412C2E"
          opacity="1.000000"
          stroke="none"
          d="
M692.235840,800.681641
	C658.645752,801.000000 625.291565,801.000000 591.468628,801.000000
	C588.456055,795.541199 590.409912,790.267151 591.852539,784.597290
	C593.834290,781.824036 596.485901,781.384766 599.168823,781.370300
	C602.484436,781.352417 604.868896,780.157166 606.407227,777.117859
	C607.479248,774.999756 609.138794,773.297241 611.511292,772.614380
	C615.782104,771.384888 618.124146,768.388245 619.363220,764.330627
	C620.042542,762.105713 621.364990,760.054749 623.654358,759.956787
	C630.839600,759.648987 632.905579,753.585388 635.970642,749.015686
	C638.021606,745.958069 639.927490,743.315063 643.569824,742.421326
	C646.019958,741.820129 646.961487,740.067322 647.677490,737.899963
	C648.544922,735.273926 649.875305,732.847778 653.135193,732.964478
	C656.352173,733.079590 657.778381,735.494080 658.304932,738.206604
	C659.040894,741.997986 661.173401,743.076050 664.730164,742.528442
	C666.697266,742.225525 668.717285,742.249634 670.924133,743.377502
	C674.034607,750.312744 676.148621,757.330017 678.062134,764.247864
	C680.134216,771.739014 685.256836,777.118958 688.404114,783.746582
	C690.892639,788.986877 692.458130,794.496033 692.235840,800.681641
z"
        />
        <path
          fill="#273E4A"
          opacity="1.000000"
          stroke="none"
          d="
M1.000000,448.468658
	C7.340643,448.283813 11.592990,442.800446 17.521877,441.996185
	C25.193125,440.955566 29.965433,433.983032 37.331127,432.386322
	C44.589767,430.812775 51.897903,429.451019 59.325462,429.053680
	C61.926952,428.914490 64.158943,428.839813 65.621765,426.140533
	C66.932678,423.721558 69.507919,423.526978 71.802330,424.229553
	C75.638046,425.404083 79.311966,424.979004 83.073341,424.057220
	C89.319283,422.526520 96.402222,422.558990 101.862801,420.421844
	C113.410095,415.902496 124.791580,420.029877 136.087402,418.124542
	C144.893494,416.639130 153.370285,414.370178 161.565430,410.946411
	C162.660812,410.488800 163.856216,409.571350 164.800583,410.237518
	C169.910782,413.842102 173.019989,410.623077 176.442795,407.073364
	C178.654556,408.064240 178.326477,409.952698 178.472687,412.101501
	C178.062500,415.067657 179.411896,417.543274 178.494507,420.437134
	C177.932877,420.874054 177.485992,420.854889 177.309082,421.064087
	C167.601196,432.541138 153.317184,430.409027 140.789978,432.706360
	C130.478592,434.597290 119.747871,434.888611 109.334106,436.359467
	C101.108612,437.521332 92.808609,439.458282 84.676155,441.640320
	C84.035347,441.812256 83.139320,441.858002 82.824081,442.295471
	C77.517075,449.660278 68.990143,446.918030 62.030529,449.067657
	C55.751911,451.006927 49.519493,452.930237 42.943371,453.629822
	C39.602592,453.985168 40.070553,458.662079 36.104969,459.425079
	C31.021824,460.403046 25.871805,461.823975 20.966774,463.886963
	C18.792761,464.801331 17.717760,466.093201 17.198645,468.054199
	C14.567682,477.992828 14.594743,478.000000 4.425769,478.000000
	C3.593832,478.000000 2.761896,478.000000 1.464980,478.000000
	C1.000000,468.312439 1.000000,458.624878 1.000000,448.468658
z"
        />
        <path
          fill="#060B13"
          opacity="1.000000"
          stroke="none"
          d="
M1.000000,293.468658
	C18.792671,291.952545 36.359936,296.043549 54.193226,295.255798
	C59.183025,295.035400 64.551163,295.242798 69.521057,296.202667
	C74.330040,297.131470 79.360260,298.238953 83.637611,300.973328
	C85.201080,301.972809 86.751221,301.984589 88.392975,301.990479
	C94.555054,302.012665 100.717331,302.020142 106.879372,301.994293
	C111.420670,301.975250 115.193901,302.984344 115.905556,308.364502
	C115.927361,308.529327 116.007011,308.696289 115.990341,308.854950
	C115.715134,311.475250 121.066704,311.427429 119.226700,313.390015
	C116.807014,315.970947 113.048409,317.785248 109.540573,318.761261
	C100.734161,321.211609 91.728920,322.536011 82.474113,322.076141
	C75.661896,321.737671 69.007736,324.145599 62.360107,324.589813
	C52.454346,325.251709 42.732735,328.293243 32.682335,327.025513
	C29.822233,326.664734 27.079996,327.195221 24.512171,328.693726
	C23.397297,329.344360 21.997196,329.978821 20.776066,329.895538
	C14.423987,329.462524 8.211265,331.049622 1.449454,331.000000
	C1.000000,318.645782 1.000000,306.291534 1.000000,293.468658
z"
        />
        <path
          fill="#533230"
          opacity="1.000000"
          stroke="none"
          d="
M774.278137,800.755981
	C756.645752,801.000000 739.291565,801.000000 721.468628,801.000000
	C717.815796,796.985291 714.443481,793.306885 710.043640,790.396362
	C705.904724,787.658386 705.445740,782.100525 702.766541,778.116577
	C701.756348,776.614441 701.249634,774.587891 699.676147,773.673950
	C692.453613,769.478943 690.252869,762.057800 687.429688,755.040100
	C686.359314,752.379395 684.811646,749.977295 683.142639,747.758484
	C677.352966,740.061523 675.397949,730.641724 670.999756,721.933044
	C667.017944,716.250916 666.754089,709.719788 664.971313,703.696350
	C664.250183,701.259888 666.060059,699.317566 667.638184,697.533203
	C669.074829,695.908813 670.812500,694.584045 672.471619,692.615356
	C675.466980,691.061462 678.067078,689.409668 681.631836,690.401978
	C683.188477,691.966431 682.648987,693.429871 682.081360,694.807495
	C680.444092,698.780762 681.682922,702.065186 684.697998,704.656006
	C688.601318,708.010132 690.156372,712.171326 690.963928,717.203979
	C692.213379,724.989929 695.226013,732.181702 701.330872,737.667664
	C703.472412,739.592041 705.455261,741.905273 705.810852,744.713562
	C706.459839,749.837952 709.726685,753.830688 711.117126,758.593567
	C711.649109,760.415894 712.862793,761.835754 714.433899,762.843811
	C721.563477,767.418091 727.151733,773.390808 731.093567,780.900635
	C731.966125,782.562988 733.572754,783.174622 735.272522,783.161133
	C744.053406,783.091614 752.736389,785.096130 761.633850,783.611755
	C767.003601,782.715942 771.450623,781.032593 775.163879,777.051331
	C777.398499,774.655396 779.473389,771.904175 783.300659,770.978027
	C785.334595,770.990906 787.068298,771.229431 787.351196,768.813965
	C788.013611,763.158630 793.233704,760.726562 796.199341,756.715881
	C798.138245,754.093750 800.939148,755.122131 802.210632,758.374573
	C802.808594,759.904114 803.029541,761.569885 803.777283,763.448486
	C803.269409,772.648682 799.065491,779.642517 792.155579,784.855164
	C788.246399,787.804077 784.552795,790.769531 782.036560,795.025879
	C780.332214,797.908752 777.611694,799.449097 774.278137,800.755981
z"
        />
        <path
          fill="#12232B"
          opacity="1.000000"
          stroke="none"
          d="
M1.329443,384.270447
	C1.000000,377.645599 1.000000,371.291199 1.000000,364.468384
	C3.811061,361.579498 7.473985,361.250580 10.792088,360.768555
	C21.777000,359.172852 32.581951,356.647461 43.749435,355.838043
	C53.923084,355.100739 64.068977,353.069641 74.247604,351.889435
	C82.504684,350.931976 90.600845,348.998993 98.790962,348.094421
	C109.606506,346.899933 119.753525,342.417236 131.117081,342.263794
	C131.800415,342.237640 132.072021,342.258942 132.728958,342.401672
	C133.571991,342.777222 133.998093,343.076111 134.666153,343.670776
	C137.630020,346.504669 139.114365,350.136749 142.833527,352.765778
	C143.591858,353.372467 143.769226,353.545319 144.177185,354.012024
	C148.050674,357.502594 152.648285,358.404785 157.138779,359.465820
	C161.181183,360.420959 165.286530,361.131378 169.081757,362.901550
	C170.857834,363.729980 172.926376,364.393524 172.587799,367.381378
	C170.971237,369.417511 169.104767,368.899414 167.306000,368.724487
	C165.323990,368.531708 163.324951,368.129578 161.357117,368.636932
	C158.198853,369.451111 155.449814,368.893188 153.088043,366.574524
	C151.191879,364.713013 148.651764,364.334564 146.611069,365.495941
	C138.810272,369.935455 130.143295,368.951630 121.804169,369.736908
	C114.536217,370.421387 107.329865,371.567474 100.148567,372.662384
	C91.436501,373.990631 82.597198,374.478943 73.997963,376.079010
	C64.302826,377.882965 54.696468,378.299591 44.953384,378.019928
	C44.289650,378.000885 43.609695,378.119354 42.999825,378.378601
	C31.784521,383.146667 19.469269,382.320221 7.894154,385.272583
	C5.801292,385.806366 3.598368,385.953674 1.329443,384.270447
z"
        />
        <path
          fill="#0C1119"
          opacity="1.000000"
          stroke="none"
          d="
M1097.172607,514.339111
	C1110.629395,508.600250 1124.578003,507.431396 1139.244507,508.684601
	C1152.617920,509.827301 1166.183594,509.272583 1179.670044,508.700623
	C1186.451294,508.412994 1193.264526,508.876282 1200.531616,509.000000
	C1201.000000,521.687561 1201.000000,534.375122 1201.000000,547.531372
	C1197.558350,547.988098 1194.016235,548.631348 1191.645386,544.916443
	C1190.651978,543.359802 1188.414917,543.614990 1187.007690,544.273926
	C1179.333374,547.867126 1172.078125,545.279602 1164.381226,543.061768
	C1162.059326,544.606750 1160.084595,543.836182 1158.561768,542.595520
	C1154.153931,539.004150 1148.767578,537.911072 1143.531738,536.752747
	C1137.751709,535.474060 1132.192749,533.348572 1125.949219,532.481873
	C1124.624390,532.473083 1123.665283,532.568054 1122.428833,532.179749
	C1121.661011,531.963745 1121.364746,531.186462 1120.512939,531.915527
	C1119.769165,532.482971 1119.184570,532.659546 1118.224609,532.647583
	C1116.845703,532.230774 1115.761108,532.147339 1114.292969,532.302673
	C1105.696777,530.496704 1097.839111,527.364136 1089.267578,525.271973
	C1087.441284,524.454102 1085.665161,524.349915 1084.451172,522.510437
	C1084.170288,521.580200 1084.187012,521.034119 1084.525879,520.131836
	C1089.523438,516.966797 1093.720947,520.184692 1097.867432,521.543823
	C1101.117920,522.609314 1104.062012,523.473694 1107.757690,522.493103
	C1108.859131,522.216492 1109.602783,522.088196 1110.699463,521.856934
	C1112.243286,521.583557 1113.441040,521.591675 1114.980713,521.547119
	C1116.630005,521.428040 1117.884644,521.821777 1119.168945,521.915649
	C1118.120239,521.829956 1117.104248,521.514587 1115.707520,521.455811
	C1114.670410,521.391113 1113.982788,521.293823 1112.969238,521.006958
	C1109.246338,519.869995 1106.222290,517.846863 1102.307617,517.200928
	C1100.360229,516.404846 1098.544189,516.122925 1097.172607,514.339111
z"
        />
        <path
          fill="#101920"
          opacity="1.000000"
          stroke="none"
          d="
M352.531342,1.000000
	C352.845642,4.074644 354.520020,5.008492 357.344574,5.029410
	C362.059906,5.064331 367.051483,3.953164 371.019257,7.780107
	C378.950134,5.214135 385.716431,10.868128 393.229675,10.952623
	C404.655457,11.081122 415.833466,13.700754 427.090607,14.389108
	C435.069427,14.876996 443.082733,19.076349 451.393402,15.269093
	C454.062683,14.046254 457.352539,14.983990 459.879730,16.925447
	C464.312744,20.330935 469.275421,21.779135 474.790405,20.979542
	C483.666351,19.692657 492.085205,23.320126 500.908844,23.322147
	C504.346924,23.322935 507.203918,27.325205 511.549530,27.536137
	C515.913147,27.747944 520.005310,28.586058 523.945557,25.796928
	C526.163330,24.227085 528.375244,24.564623 529.746033,27.757401
	C531.335632,31.955982 528.401855,33.031620 525.388550,34.388412
	C523.370667,34.692074 521.721252,34.911751 519.768188,34.534698
	C519.186890,34.499874 518.821899,34.437408 518.644836,34.605816
	C518.424072,34.815887 518.618042,35.063396 519.275940,35.232838
	C520.324768,35.790249 520.828308,36.443207 521.010864,37.663395
	C519.037720,41.045120 516.523438,40.127537 513.996582,38.956902
	C512.657593,38.336571 511.595551,36.878960 510.003906,37.083260
	C498.972076,38.499344 488.234711,35.852905 477.703003,33.666691
	C470.406097,32.151981 462.979675,30.957661 455.750793,29.027817
	C453.480804,28.421822 451.262848,27.838554 448.900482,27.999142
	C435.551971,28.906544 422.896210,24.262430 409.799011,23.092319
	C403.491852,22.528839 397.302307,19.417370 390.878174,22.747322
	C390.012054,23.196301 388.184814,23.110886 387.566711,22.499908
	C384.105560,19.078867 379.829865,19.941130 375.780609,20.051334
	C369.284088,20.228146 362.334839,19.378597 356.823212,17.431194
	C341.090088,11.872293 324.898865,14.122535 309.049683,11.997092
	C303.791687,11.291970 298.518707,10.697630 294.707916,6.255921
	C293.542877,4.897964 292.870209,3.529411 293.001160,1.382257
	C312.687561,1.000000 332.375122,1.000000 352.531342,1.000000
z"
        />
        <path
          fill="#15191C"
          opacity="1.000000"
          stroke="none"
          d="
M1201.000000,415.531342
	C1196.006104,415.999573 1191.012085,416.002838 1186.018188,415.996704
	C1184.357666,415.994659 1182.615601,416.181610 1181.712158,414.269196
	C1180.838135,412.418976 1181.723755,410.903168 1182.965454,409.812103
	C1185.952637,407.187164 1185.302856,405.711609 1181.694580,404.971375
	C1177.453491,404.101288 1175.246948,399.181213 1170.391113,399.397827
	C1169.476807,399.438599 1169.238525,397.959015 1168.984619,396.969818
	C1167.906250,392.767395 1165.170044,392.007843 1161.850952,394.192993
	C1158.492310,396.404175 1155.515991,396.667023 1152.247437,394.216034
	C1151.366821,393.555786 1150.106201,393.126282 1149.003906,393.090912
	C1145.994507,392.994293 1145.074463,391.477081 1144.954590,388.641541
	C1144.665283,381.791443 1143.621948,381.260071 1137.230957,383.287231
	C1133.503784,384.469543 1129.816284,385.453979 1128.533691,379.577209
	C1127.700684,375.760406 1119.467285,373.397705 1113.356567,374.842957
	C1108.779907,376.220459 1106.084473,373.335419 1103.610596,370.953888
	C1099.853027,367.336456 1099.874634,356.854889 1103.526367,352.217407
	C1106.114624,351.251129 1108.266479,351.688538 1110.092529,352.921021
	C1113.833130,355.445648 1117.942017,356.313995 1122.356201,356.399078
	C1127.017456,356.488861 1130.625732,359.688690 1134.956299,360.876740
	C1138.432251,361.830322 1141.683472,362.422424 1145.119629,360.829041
	C1147.674927,359.644073 1150.449219,359.528168 1152.647217,361.486420
	C1155.226562,363.784363 1158.233032,363.912262 1161.394165,364.343079
	C1168.079102,365.254242 1174.488770,367.799530 1181.355347,367.811096
	C1182.320068,367.812744 1183.363403,368.320068 1184.050537,369.016388
	C1187.166504,372.174988 1191.080322,372.360046 1195.136475,372.261780
	C1196.953735,372.217743 1198.816162,372.244720 1200.682861,373.738068
	C1201.000000,387.687561 1201.000000,401.375122 1201.000000,415.531342
z"
        />
        <path
          fill="#101417"
          opacity="1.000000"
          stroke="none"
          d="
M1201.000000,373.531433
	C1197.844971,374.000000 1194.689819,373.978882 1191.535034,374.006287
	C1188.699341,374.030914 1185.878418,374.315277 1184.223877,371.082886
	C1183.783081,370.221466 1182.232056,369.273834 1181.338257,369.401154
	C1173.399902,370.532501 1166.403320,365.664246 1158.627075,365.997040
	C1156.259399,366.098358 1154.115845,366.026581 1152.615967,363.535217
	C1151.076172,360.977600 1148.408081,360.731689 1146.074829,362.212433
	C1139.761719,366.219025 1134.438232,362.401581 1128.872925,360.360504
	C1128.116333,360.083069 1127.487915,359.189392 1126.761230,359.138611
	C1119.005249,358.596405 1111.801270,356.143585 1104.390503,353.003113
	C1101.250732,350.289490 1099.493042,346.801239 1097.526245,343.612152
	C1094.675781,338.990662 1088.873291,338.175720 1086.150635,333.474091
	C1085.196655,331.826630 1082.412231,333.333130 1080.106445,333.623047
	C1074.509766,330.333374 1069.347900,326.884155 1062.955200,325.135651
	C1062.218506,321.821045 1064.532837,321.859406 1066.397461,321.479309
	C1068.380371,321.075134 1070.247925,322.154785 1072.609619,322.016785
	C1076.403564,321.662292 1078.644531,323.188110 1079.951172,326.648193
	C1080.000000,327.000000 1079.715088,327.271698 1079.857544,327.135864
	C1087.900024,326.179871 1094.916138,329.947754 1102.391235,331.335968
	C1103.958618,331.627045 1104.828979,332.943848 1105.774780,334.132446
	C1106.808105,335.431000 1107.917725,336.752289 1109.737793,336.954956
	C1116.310669,337.686798 1122.586426,340.409058 1129.322144,339.987549
	C1142.467896,339.164825 1154.873535,343.514587 1167.630127,345.399445
	C1176.971558,346.779694 1186.084717,349.194031 1195.159058,351.777008
	C1196.712036,352.219055 1198.469360,351.943848 1200.566162,352.000000
	C1201.000000,359.020966 1201.000000,366.041901 1201.000000,373.531433
z"
        />
        <path
          fill="#2D3037"
          opacity="1.000000"
          stroke="none"
          d="
M529.255005,800.692383
	C510.979095,801.000000 492.958221,801.000000 474.468658,801.000000
	C472.892914,796.044922 476.744415,793.094421 479.031097,789.794373
	C482.988953,784.082458 486.226410,777.925842 490.370544,772.337524
	C493.891907,767.589050 497.701416,763.015137 500.074432,757.470520
	C500.770325,755.844482 502.262970,754.345520 503.882385,753.615540
	C508.495636,751.536011 512.088745,748.974426 512.038025,743.191467
	C512.024170,741.617493 513.846985,740.504639 515.341614,740.704468
	C521.162659,741.482849 526.196228,739.923340 531.078979,736.289307
	C535.000061,737.722351 535.501587,740.749817 535.575256,744.394531
	C535.631958,747.833008 535.345886,750.656921 533.319092,753.236328
	C529.703613,757.837524 530.055725,762.774780 532.876343,767.640991
	C537.502136,775.621643 537.415588,779.590088 532.104126,786.606201
	C530.464233,788.772400 530.249207,791.118164 530.428040,793.657166
	C530.589722,795.952759 531.055542,798.312744 529.255005,800.692383
z"
        />
        <path
          fill="#1F323D"
          opacity="1.000000"
          stroke="none"
          d="
M241.189896,384.617493
	C240.783020,392.719482 239.713226,393.520660 232.027481,392.253357
	C230.267838,391.963257 228.090302,391.683319 226.647812,392.437164
	C220.831863,395.476593 214.404114,392.822296 208.505829,394.728363
	C207.756226,394.970612 206.518661,394.361115 205.756668,393.804596
	C198.323517,388.375916 190.793427,387.245209 181.555328,389.918610
	C177.953583,390.960846 177.509583,391.644409 176.357574,394.428925
	C175.389771,396.768219 172.425476,398.132385 169.602097,397.637360
	C163.203308,396.515442 157.430893,398.123810 151.327316,400.024597
	C144.824356,402.049774 137.798233,402.620178 130.834427,402.472137
	C121.809242,402.280334 112.764397,400.501587 103.792343,403.686798
	C100.448807,404.873810 96.464607,404.802551 92.984444,404.707245
	C84.154015,404.465332 75.880203,406.561523 67.806000,409.364807
	C60.108944,412.037170 51.838871,409.579224 44.297508,412.887756
	C37.361473,415.930786 29.067787,415.407288 23.024652,420.907623
	C22.569525,421.321838 21.683237,421.748749 21.206360,421.579834
	C13.787327,418.951843 7.851139,422.828552 1.362875,425.950836
	C1.000000,421.975464 1.000000,417.950897 1.000000,413.463196
	C7.279333,408.349030 14.839335,407.563843 22.155701,406.272247
	C25.996893,405.594147 29.644861,405.077301 32.574814,402.162994
	C34.286442,400.460571 36.697010,399.957672 39.015347,399.699097
	C52.741669,398.168121 66.472191,396.659546 80.699715,396.277985
	C82.960243,396.627136 84.764420,396.653534 86.700043,395.216919
	C87.815002,394.711517 88.606201,394.542267 89.841560,394.502502
	C95.254356,393.281616 100.289970,392.234100 105.286560,391.018066
	C106.585144,390.702026 107.893425,390.352478 109.226189,390.465393
	C130.479248,392.266998 151.275269,388.627808 172.197479,385.742920
	C180.154739,384.645721 187.996262,381.327545 196.314285,383.173157
	C197.762634,383.494568 199.380722,383.293121 200.713974,383.961700
	C206.644348,386.935730 212.052933,384.833160 217.306519,382.213928
	C219.726318,381.007477 222.243347,380.078583 224.319901,381.829407
	C227.839111,384.796661 231.140564,384.493378 235.116455,382.185425
	C237.278091,381.511261 238.953995,381.611145 240.750854,383.099060
	C241.144821,383.693634 241.256927,383.958954 241.189896,384.617493
z"
        />
        <path
          fill="#3B3036"
          opacity="1.000000"
          stroke="none"
          d="
M534.255737,745.279663
	C534.092407,742.181396 534.033386,739.403625 531.310669,737.180908
	C530.561279,736.494873 530.104858,735.846252 530.326172,735.246765
	C532.607788,729.067871 532.518066,722.522339 533.637512,715.801025
	C536.749573,713.681763 539.875793,714.245239 543.347534,714.417969
	C545.240479,714.825378 546.720886,715.031067 548.468140,713.923828
	C554.118042,713.122864 559.313293,711.956970 564.572449,711.195862
	C566.669067,710.892334 568.871643,710.321655 571.094177,712.061523
	C571.730042,714.782043 569.942322,715.556091 568.305054,716.357971
	C564.545471,718.199463 560.666809,719.660767 556.392517,719.688660
	C555.727539,719.692993 555.059692,719.710632 554.398743,719.782532
	C550.542908,720.201965 546.217957,720.693115 544.417908,724.351562
	C542.254456,728.748474 545.882812,732.553589 547.567261,736.414795
	C548.167725,737.791260 549.999634,738.033752 551.469971,738.359680
	C555.749023,739.308228 558.804565,741.931274 559.917419,746.019165
	C561.116943,750.425598 564.201538,751.585022 568.002258,752.101990
	C571.112732,752.525146 574.365662,752.328918 577.353516,754.646606
	C578.754211,756.713440 578.695862,758.723755 578.804688,760.704346
	C579.152100,767.024963 579.277954,767.174377 585.320618,768.717957
	C592.431152,770.534363 595.500793,776.015076 593.258301,783.676941
	C592.335144,789.385193 591.670288,794.767883 591.002686,800.575317
	C586.643127,801.000000 582.286194,801.000000 577.464661,801.000000
	C573.760498,798.218933 569.476440,797.722534 565.740295,796.037170
	C562.186829,794.434326 559.947327,792.287781 559.233215,788.590454
	C558.178040,783.126831 555.271423,777.925537 551.246399,775.208984
	C546.480103,771.992371 542.807312,769.268738 542.851379,763.223877
	C535.976013,759.267456 538.527222,750.859131 534.255737,745.279663
z"
        />
        <path
          fill="#182932"
          opacity="1.000000"
          stroke="none"
          d="
M1.000000,384.464661
	C14.474298,381.636169 28.340664,381.617004 41.431271,376.885925
	C46.517483,375.047668 51.921940,375.717529 57.173103,376.594849
	C60.029240,377.071991 62.542740,377.023132 65.303696,375.606232
	C71.430038,372.462219 78.358871,374.356903 84.885704,373.135406
	C94.739723,371.291199 104.721634,370.114380 114.625885,368.460968
	C121.285912,367.349213 128.142960,367.341583 134.920822,367.045013
	C138.132172,366.904480 141.129807,366.565491 143.880554,364.655762
	C148.003448,361.793457 151.525055,362.306580 155.009552,365.942108
	C155.899216,366.870361 156.817581,368.400787 158.288025,367.655609
	C162.573288,365.484009 166.753723,366.449768 171.007874,367.570435
	C171.289261,367.644592 171.759232,367.002960 172.332367,366.359833
	C173.629456,365.623138 174.687988,365.766998 176.031891,366.568726
	C176.480759,367.077545 176.609940,367.321503 176.816223,367.971558
	C176.472382,372.088684 173.602341,372.761108 170.804642,372.612488
	C165.866562,372.350128 162.663071,374.025696 161.339844,379.383728
	C158.658401,381.487427 155.869110,381.823090 153.011520,381.867645
	C137.407761,382.111145 121.829712,383.451477 106.207336,382.937286
	C102.703056,382.821930 99.256142,382.979401 96.206833,385.180054
	C94.998276,386.052277 93.380577,386.465820 91.912460,386.218506
	C86.477760,385.303040 81.332947,386.773376 76.208672,388.065399
	C69.851952,389.668213 63.436111,390.076019 56.930569,389.935822
	C54.113621,389.875092 51.269939,389.797333 48.518894,390.630646
	C39.904110,393.240112 30.982826,394.319672 22.156515,395.855591
	C20.131159,396.208038 18.406748,397.096710 16.582127,397.882599
	C11.724049,399.975067 6.775852,400.959412 1.345839,398.288055
	C1.000000,393.643097 1.000000,389.286194 1.000000,384.464661
z"
        />
        <path
          fill="#3C545F"
          opacity="1.000000"
          stroke="none"
          d="
M193.468658,801.000000
	C192.664764,800.457275 192.250183,799.407166 192.005814,799.445374
	C184.495636,800.619934 179.838638,793.166199 172.762543,793.127441
	C170.440567,793.114746 169.186127,791.706787 168.344788,789.725281
	C167.679245,788.157837 166.580872,786.981018 165.113205,786.057312
	C159.420090,782.474609 155.202911,777.609375 152.193787,771.561829
	C150.125183,767.404480 145.853302,764.938110 143.479401,760.868469
	C142.502167,759.193115 140.912903,757.645264 140.524673,755.789795
	C138.888702,747.971008 133.729111,742.304138 128.924423,736.492554
	C125.538666,732.397278 124.748100,727.163574 121.971512,722.826843
	C118.325989,717.132935 113.855583,711.956360 111.335831,705.120361
	C113.890892,703.984985 115.813072,705.484680 118.326218,705.881104
	C124.231346,708.949158 126.464012,714.450806 129.078186,719.629700
	C130.579086,722.603210 131.714767,725.743530 132.698563,728.927124
	C133.566086,731.734558 135.070312,734.023621 137.617218,735.595032
	C140.556351,737.408447 142.537048,740.073242 143.872131,743.189331
	C150.314209,758.225281 161.452118,769.663330 172.879578,780.823853
	C177.128937,784.973816 182.376541,786.908447 188.536407,787.599304
	C199.978226,788.882568 211.391266,789.574402 222.888184,789.380249
	C226.678772,789.316162 229.860229,788.366699 232.986160,785.947815
	C236.907898,782.913208 241.731201,781.224915 246.194824,779.027344
	C250.407181,776.953491 253.631714,774.084900 255.578247,769.787170
	C257.786530,764.911499 260.505890,760.414062 264.340851,756.556396
	C267.280823,753.598999 269.530945,750.010376 271.047272,746.103638
	C273.707764,739.248962 277.079529,732.763367 280.873840,726.494873
	C283.791229,721.675171 285.339600,716.834473 282.318054,711.466980
	C281.832214,710.603882 281.785553,709.550781 281.983734,708.144714
	C282.196930,707.508423 282.319183,707.264221 282.726776,706.718628
	C284.515717,705.433411 286.181946,705.359253 288.301941,705.633484
	C288.967987,705.814209 289.226562,705.905884 289.835083,706.205322
	C295.391266,711.998535 295.583740,718.106384 292.536957,724.855408
	C290.217499,729.993347 288.944641,735.531128 286.568298,740.710327
	C281.221710,752.363098 272.753693,762.171021 267.508575,773.884888
	C265.604004,778.138428 260.927185,780.418823 258.054596,784.583496
	C250.188782,788.566223 244.094452,794.749512 236.016953,797.743225
	C232.895721,798.900024 229.739899,799.703674 226.277298,800.759949
	C215.312439,801.000000 204.624878,801.000000 193.468658,801.000000
z"
        />
        <path
          fill="#121E24"
          opacity="1.000000"
          stroke="none"
          d="
M146.579941,243.988937
	C147.169998,246.037354 148.479218,246.638870 150.293671,247.106842
	C152.987900,247.801727 155.891647,247.958588 158.567352,249.807968
	C156.849594,251.734909 154.940475,250.895187 153.146637,250.540085
	C148.418839,249.604202 145.408569,251.389175 145.470963,256.163208
	C145.546631,261.953217 142.090485,260.334259 139.629181,259.163940
	C130.274109,254.715683 120.778870,254.321747 110.852272,256.747894
	C106.594185,257.788635 102.200630,256.386200 98.106018,255.343826
	C91.674599,253.706604 85.563728,253.039917 79.224289,255.638016
	C75.525307,257.153992 72.552505,254.389130 70.454918,252.170502
	C66.510948,247.998962 62.106915,248.416824 57.616837,249.840195
	C54.249805,250.907547 51.273922,251.875870 48.399471,249.017990
	C47.519676,248.143265 46.328793,247.942459 45.142292,248.021317
	C30.663080,248.983810 16.437164,244.555786 1.468632,246.000000
	C1.000000,240.645065 1.000000,235.290146 1.000000,229.467606
	C11.960118,228.640778 22.590776,230.821609 33.325779,232.656311
	C39.599613,233.728561 46.148067,234.361496 52.342709,235.452652
	C59.788296,236.764175 67.636383,235.464661 74.747940,238.710739
	C75.184059,238.909805 75.738235,239.000397 76.213066,238.942215
	C84.862724,237.882141 92.455444,241.293594 100.136040,244.458893
	C102.819458,245.564758 105.759781,245.204849 108.532265,244.826828
	C119.241074,243.366776 129.985382,242.707062 140.782562,243.043274
	C142.582169,243.099319 144.366425,243.648972 146.579941,243.988937
z"
        />
        <path
          fill="#0D161D"
          opacity="1.000000"
          stroke="none"
          d="
M1.000000,263.464661
	C7.688941,262.911377 14.143388,265.209686 20.617420,265.586151
	C30.213409,266.144135 39.581871,268.344177 49.303974,268.256042
	C59.147999,268.166779 69.056297,269.697479 78.909996,270.502045
	C84.107155,270.926453 89.150970,271.694794 94.416008,271.088623
	C97.678558,270.713013 101.005524,270.901550 104.315849,271.770508
	C107.130264,272.509308 110.029266,272.902954 113.112938,272.170837
	C115.606979,271.578705 118.460480,271.511688 120.906349,272.975647
	C126.172424,276.127594 131.855560,274.966309 137.457901,274.648041
	C137.942200,274.620575 138.475357,274.148071 138.882553,274.259521
	C149.220627,277.088989 160.050400,274.656006 170.390091,277.233856
	C171.865860,277.601807 173.187469,278.115387 174.731964,277.598755
	C176.182556,277.113495 178.028015,275.165619 179.127228,277.142670
	C180.330994,279.307709 181.817917,281.952515 180.247025,284.599640
	C179.538101,285.794250 178.943359,286.877258 179.002960,288.658905
	C177.786591,290.604797 175.988632,290.435425 173.971283,290.678772
	C173.171173,290.752136 172.718552,290.786011 171.938354,290.853943
	C171.180359,290.929535 170.749100,290.940338 170.007233,290.879456
	C169.504486,290.776245 169.311691,290.712341 168.875717,290.489197
	C168.424454,289.867188 168.225616,289.958984 167.788086,290.513977
	C166.962723,290.927948 166.365707,290.965881 165.481964,290.677765
	C162.437866,290.775299 160.420914,285.418030 156.999695,289.530273
	C156.314285,290.354095 155.270691,289.219727 155.023514,288.112640
	C154.394806,285.296753 151.906433,284.554901 149.746735,284.233154
	C141.661072,283.028442 133.797592,281.474792 125.330032,281.548126
	C106.509705,281.711121 87.604446,281.679535 68.806664,280.608185
	C63.264229,280.292328 58.052834,280.270538 52.570171,280.874237
	C39.141487,282.352844 25.937273,277.998840 12.427928,278.750732
	C9.107065,278.935547 5.635237,276.404785 1.441288,277.000000
	C1.000000,272.643097 1.000000,268.286194 1.000000,263.464661
z"
        />
        <path
          fill="#31383F"
          opacity="1.000000"
          stroke="none"
          d="
M533.761963,715.904907
	C535.267700,723.257812 534.536682,729.958496 531.049866,736.635071
	C527.247620,742.344849 522.178589,743.525391 516.031921,742.089783
	C513.652283,741.533936 512.850586,743.356812 513.016235,745.496887
	C513.351074,749.824219 512.335876,752.299377 507.220154,753.459900
	C503.235168,754.363831 500.131897,758.243164 499.062866,762.771912
	C498.672455,764.425903 498.403290,766.274170 497.012207,767.185669
	C491.518768,770.784790 489.207153,776.919128 486.037170,782.029785
	C482.196503,788.221741 477.504395,793.852783 474.047638,800.651855
	C466.979034,801.000000 459.958099,801.000000 452.468567,801.000000
	C452.530884,797.692444 455.452759,796.230103 457.455750,794.108582
	C462.731293,788.520935 469.121796,783.909973 471.739441,776.119629
	C472.431030,774.061462 474.880402,773.292847 477.138550,773.023926
	C480.436859,772.631226 482.703796,771.769653 478.646637,768.821899
	C476.403412,767.192139 477.892944,765.703979 478.455170,764.038879
	C482.053680,753.380859 490.124695,745.595398 496.082306,736.455078
	C501.287506,728.469177 508.992828,723.889954 517.649902,720.595642
	C519.429565,719.918396 521.011169,719.023987 520.989380,716.377075
	C523.346558,714.171875 526.281006,716.453613 529.070312,714.800293
	C530.924500,714.507996 532.307983,714.641235 533.761963,715.904907
z"
        />
        <path
          fill="#0D1920"
          opacity="1.000000"
          stroke="none"
          d="
M133.295837,342.705444
	C128.204575,346.137085 122.454033,344.604004 117.243454,345.965118
	C116.306587,346.209839 114.942856,345.608673 114.306099,346.093719
	C106.965668,351.685394 97.676086,348.487122 89.866478,351.620026
	C84.121048,353.924866 78.005661,352.372253 72.184189,353.922729
	C63.924397,356.122650 55.222580,355.448181 46.879349,357.679901
	C41.984055,358.989349 36.655128,357.626556 31.557177,358.920074
	C23.575710,360.945251 15.217301,361.089722 7.307550,363.636322
	C5.629345,364.176666 3.688425,363.901001 1.434273,364.000671
	C1.000000,359.310760 1.000000,354.621490 1.000000,349.466125
	C4.935794,349.612122 8.689192,348.550385 12.096450,346.950378
	C19.787519,343.338745 28.035194,345.485596 35.960457,344.136810
	C56.991028,340.557709 78.522835,340.882477 99.455437,336.215546
	C104.431198,335.106171 109.696564,336.500885 114.814560,335.172699
	C117.491302,334.478027 119.785522,333.901794 121.458817,331.425232
	C123.252914,328.769867 126.861740,329.069824 129.594727,327.154297
	C130.574966,327.424377 130.843597,328.086151 131.001617,329.184631
	C130.626617,331.581116 130.116348,333.633392 131.931610,335.731628
	C132.832672,338.109497 134.099579,340.052063 133.295837,342.705444
z"
        />
        <path
          fill="#33444C"
          opacity="1.000000"
          stroke="none"
          d="
M319.699280,701.782959
	C322.128662,709.598389 319.969940,717.546570 321.999634,725.564026
	C323.236664,727.365784 322.751526,728.888489 322.104187,730.202087
	C319.568085,735.348328 317.932983,740.820984 313.273895,744.977234
	C307.527954,750.103027 303.804932,756.943787 301.477661,764.346619
	C300.354919,767.918030 298.432953,770.993774 296.386444,774.008850
	C289.932953,783.516663 282.879486,792.542114 274.252441,800.733765
	C268.311951,801.000000 262.623932,801.000000 256.467957,801.000000
	C254.371109,798.708496 255.623871,796.592529 257.046509,795.164612
	C260.773560,791.423950 263.818878,786.944519 268.451141,784.152832
	C269.892731,783.284058 271.263916,782.402344 272.028778,780.817078
	C279.029480,766.307739 288.220947,753.057312 296.026154,739.018982
	C300.119995,731.655823 302.617340,723.455688 308.030792,716.733459
	C310.297791,713.918335 308.148865,711.844360 305.666656,710.318054
	C303.058960,708.714600 299.823517,709.070801 296.906494,707.333923
	C296.421875,706.591125 296.344635,706.096313 296.547485,705.220032
	C297.393951,704.031738 298.370575,703.726501 299.742798,703.397095
	C301.166443,703.322876 301.967896,702.693542 303.183899,702.068481
	C306.555206,701.143616 309.622070,700.711487 313.126160,700.703003
	C315.384003,701.683350 317.496033,700.883301 319.699280,701.782959
z"
        />
        <path
          fill="#33505C"
          opacity="1.000000"
          stroke="none"
          d="
M96.764290,512.704224
	C96.996178,514.335266 97.031303,515.669434 96.984512,517.000671
	C96.847542,520.896790 95.346054,523.310791 90.908241,523.035645
	C86.930664,522.788879 82.539528,522.126282 82.539436,528.408142
	C82.539421,529.501587 81.256325,530.663147 80.122765,530.667419
	C75.592949,530.684509 71.685486,533.545898 66.988823,533.097107
	C62.746223,532.691711 58.409958,532.478760 54.532322,535.411987
	C53.305294,536.340210 51.000626,535.980347 49.189819,535.975037
	C43.199516,535.957397 38.085224,537.969971 34.028042,542.400696
	C31.610601,545.040771 27.978390,544.691895 25.214720,545.038025
	C19.487783,545.755127 18.536617,549.663574 17.337542,553.674500
	C15.106387,561.137695 12.849393,562.945129 4.892172,562.999084
	C3.892590,563.005859 2.892923,563.000000 1.446648,563.000000
	C1.000000,556.312378 1.000000,549.624695 1.000000,542.468506
	C4.328605,542.423645 6.711218,541.470642 8.093613,538.058350
	C9.059843,535.673340 11.634780,534.453308 13.905171,535.159668
	C22.485603,537.829163 29.461290,533.451538 36.241676,529.828186
	C40.646526,527.474304 44.836117,526.733398 49.458103,527.040771
	C53.485542,527.308533 55.463551,525.882080 55.016102,521.653870
	C54.652069,518.213867 56.335712,516.705505 59.724304,517.003784
	C62.221592,517.223511 64.095566,516.557739 65.624062,514.262695
	C66.857185,512.411255 68.950195,511.766449 71.390556,512.555420
	C72.753036,512.995972 74.364952,513.007568 75.804688,512.799255
	C79.410057,512.277588 81.671806,510.863892 79.999603,506.503387
	C78.946091,503.756256 77.671295,500.949188 78.000679,497.419312
	C80.039368,495.760345 81.485977,497.042053 81.823921,498.636322
	C83.540520,506.734558 89.863777,509.710876 96.764290,512.704224
z"
        />
        <path
          fill="#313D44"
          opacity="1.000000"
          stroke="none"
          d="
M274.437500,801.000000
	C273.727722,798.722900 275.108948,797.294006 276.592499,795.903931
	C285.128998,787.905334 291.617340,778.310791 297.959808,768.589600
	C300.596710,764.547974 300.826385,759.618896 303.552948,755.471497
	C306.926880,750.339355 310.542938,745.668335 314.741730,741.143250
	C318.242493,737.370422 319.597046,731.606140 321.951080,726.360718
	C330.098083,725.142090 332.901733,731.569824 335.786041,736.866760
	C344.241669,752.395264 354.203186,766.878662 364.515442,781.190186
	C368.529175,786.760498 373.151489,791.648987 377.952911,796.449646
	C379.025818,797.522339 380.049927,798.603027 379.992126,800.636230
	C376.638702,801.000000 373.277435,801.000000 369.458069,801.000000
	C364.336975,796.739746 359.871918,792.277161 355.868195,787.392578
	C351.551178,782.125610 348.096100,776.140259 342.584106,771.876343
	C341.113586,770.738953 340.142456,769.035828 339.889435,767.190430
	C339.192749,762.109497 336.392456,757.851440 334.459717,753.283569
	C332.988403,749.806152 330.850586,746.339905 326.038483,746.719727
	C321.557495,747.073425 318.590576,749.263367 317.303833,753.650879
	C316.189575,757.450195 312.579651,759.458923 310.830597,763.002502
	C303.681671,777.486084 295.213593,791.076050 281.259583,800.751465
	C278.958344,801.000000 276.916656,801.000000 274.437500,801.000000
z"
        />
        <path
          fill="#1C2D35"
          opacity="1.000000"
          stroke="none"
          d="
M1.000000,398.466125
	C7.242881,397.925140 13.241858,398.252441 19.183363,394.547119
	C22.394974,392.544250 27.545147,393.193481 31.700550,392.059265
	C46.640953,387.981323 62.253181,388.922638 77.302895,385.880554
	C82.513985,384.827209 88.357803,385.748413 92.707794,384.341919
	C113.208023,377.713531 134.222015,382.638062 154.783981,379.982269
	C156.585678,379.749542 158.366211,379.352966 160.578598,379.017456
	C162.782761,383.220520 161.917526,385.374786 157.921738,386.463715
	C155.810394,387.039124 153.622208,386.934998 151.457428,386.938660
	C136.457489,386.964020 121.457352,386.976501 106.457703,386.924866
	C102.750771,386.912109 99.615479,387.269165 97.854698,391.270996
	C96.589325,394.146851 93.303001,394.365448 90.369049,395.727051
	C89.250801,395.999329 88.500183,395.999908 87.374268,396.000854
	C85.832214,397.476044 83.862495,396.589661 82.257126,397.762207
	C66.986977,398.537354 52.041306,399.856232 37.160664,401.926788
	C35.851868,402.108887 34.305180,401.939575 33.582005,403.251617
	C31.057667,407.831329 26.637327,407.686523 22.347641,408.010712
	C17.565603,408.372131 13.046880,409.931091 8.491701,411.269379
	C6.277479,411.919891 4.201789,413.062225 1.402183,412.998169
	C1.000000,408.310760 1.000000,403.621490 1.000000,398.466125
z"
        />
        <path
          fill="#2A363C"
          opacity="1.000000"
          stroke="none"
          d="
M361.234741,800.707275
	C338.979095,801.000000 316.958221,801.000000 294.468658,801.000000
	C292.646729,799.086304 294.004944,797.817688 295.218781,796.758423
	C302.007874,790.833252 307.013702,783.382019 312.489166,776.369141
	C315.198944,772.898560 316.561401,768.461426 319.176178,764.794189
	C322.005920,760.825439 324.137787,760.112122 328.357544,762.511780
	C330.096375,763.500549 331.803955,764.504211 333.708954,765.131165
	C336.272644,765.974915 337.931427,767.644653 338.598297,770.306152
	C339.478424,773.818726 341.527100,776.743652 344.166901,779.006287
	C349.493225,783.571716 353.608276,789.265442 358.709869,794.029236
	C360.463440,795.666687 362.585236,797.415283 361.234741,800.707275
z"
        />
        <path
          fill="#492E2E"
          opacity="1.000000"
          stroke="none"
          d="
M671.727234,721.243896
	C675.154663,724.325745 676.595154,728.953247 677.389099,732.858398
	C678.769226,739.647034 683.911011,743.852234 686.351379,749.741333
	C688.641724,755.268188 691.524109,760.547363 693.975586,766.011963
	C694.447754,767.064392 694.548218,768.513672 695.606567,768.935669
	C703.438232,772.057922 705.304565,779.460876 708.276978,786.129700
	C709.310608,788.448608 711.160583,790.428772 713.556824,791.161316
	C718.204590,792.582153 719.534363,796.396851 720.990967,800.629028
	C711.645752,801.000000 702.291565,801.000000 692.468628,801.000000
	C689.742676,794.024902 688.713928,786.627686 684.852417,780.203125
	C684.515442,779.642578 684.356384,778.758240 683.876587,778.534363
	C679.747192,776.606689 678.884216,772.716309 677.960938,768.885620
	C676.004150,760.767212 671.076050,753.511047 671.000000,744.425415
	C670.642273,737.604309 667.829102,734.620178 660.978882,735.964966
	C658.604248,736.431091 657.704102,733.988953 658.010132,731.772217
	C658.357971,729.252380 657.726868,726.212341 660.636902,724.740784
	C661.841370,724.131775 662.778442,725.269653 663.555969,726.142517
	C664.744568,727.476807 665.654785,729.382324 667.929260,728.707031
	C670.335266,727.992554 670.262329,725.739014 670.660339,723.827026
	C670.830444,723.009766 671.007874,722.204468 671.727234,721.243896
z"
        />
        <path
          fill="#2D3A41"
          opacity="1.000000"
          stroke="none"
          d="
M361.444153,801.000000
	C361.311646,798.478821 359.547394,797.102173 358.052460,795.536499
	C351.888824,789.081299 345.755951,782.596741 339.615509,776.119507
	C339.272522,775.757690 338.690582,775.373230 338.677948,774.984497
	C338.435669,767.540649 331.729034,766.464783 327.147247,763.426636
	C324.763245,761.845703 322.749023,761.904846 320.651520,765.094055
	C317.005219,770.638123 314.056122,776.581421 310.132172,782.042114
	C305.306610,788.757507 299.243896,794.073547 294.048553,800.657959
	C289.975464,801.000000 285.950897,801.000000 281.463196,801.000000
	C281.655762,797.557861 284.424377,795.555908 286.897034,793.893738
	C292.983124,789.802368 295.784302,782.826721 301.356781,778.367737
	C303.872009,776.355042 301.925903,772.557007 304.045319,770.601562
	C308.691833,766.314453 309.061737,759.055786 314.775146,755.559021
	C315.367889,755.196289 315.663086,754.188660 315.894684,753.419556
	C317.842010,746.952454 322.506409,743.667114 328.883270,745.138977
	C333.513702,746.207825 335.432800,750.492615 336.841614,754.518311
	C338.459412,759.141174 341.707092,763.179077 341.800873,768.379517
	C341.815826,769.208496 342.659790,770.310059 343.429504,770.792358
	C349.430939,774.552490 352.647034,780.703613 356.676086,786.125732
	C360.416870,791.159973 366.292267,794.327515 368.953156,800.654541
	C366.629425,801.000000 364.258881,801.000000 361.444153,801.000000
z"
        />
        <path
          fill="#342C32"
          opacity="1.000000"
          stroke="none"
          d="
M534.003479,745.419067
	C537.703552,746.817383 539.734436,749.690613 539.167358,753.886719
	C538.797119,756.625977 538.951721,758.725769 542.130371,759.643860
	C545.251648,760.545471 545.116394,763.239990 544.991211,765.770142
	C544.810364,769.427429 545.865234,772.007385 550.115906,772.014404
	C551.114990,772.016052 552.432007,772.002075 552.744934,773.015137
	C554.189453,777.691650 559.563354,780.308777 559.894531,785.214661
	C560.458069,793.562256 566.826355,794.893311 572.698242,796.954285
	C574.590271,797.618408 576.702209,797.619629 576.984802,800.621155
	C561.312439,801.000000 545.624878,801.000000 529.468628,801.000000
	C528.847107,798.197693 529.004272,795.417114 528.488831,792.583923
	C527.998230,789.887329 528.478516,787.081543 530.957581,785.000122
	C535.347229,781.314636 535.833374,774.270447 532.182617,769.692017
	C527.489563,763.806580 527.647156,757.178528 532.793518,751.479309
	C534.374634,749.728333 533.834534,747.760132 534.003479,745.419067
z"
        />
        <path
          fill="#3A4D56"
          opacity="1.000000"
          stroke="none"
          d="
M296.652283,706.697021
	C301.201385,706.814941 305.272858,707.429688 308.657837,710.235352
	C311.364319,712.478699 312.051422,715.133850 309.487213,717.974304
	C305.082703,722.853271 302.519379,728.708923 299.830536,734.629761
	C296.020782,743.018860 290.790955,750.683533 286.137604,758.640381
	C281.890717,765.902100 276.806976,772.758911 273.917725,780.786255
	C273.234406,782.684692 272.187439,783.996338 270.295776,785.181519
	C264.301056,788.937317 259.005524,793.546936 256.046570,800.645203
	C254.285645,801.000000 252.571289,801.000000 250.428467,801.000000
	C249.773849,800.742065 249.433960,800.339050 249.548813,800.163879
	C252.713470,795.338806 252.910706,789.200500 256.732849,784.279297
	C259.567505,778.160339 266.376556,775.636047 268.404724,769.350159
	C271.902313,758.510193 280.630066,750.691223 285.095062,740.291016
	C287.911560,733.730652 289.452484,726.769165 292.568726,720.324707
	C294.705719,715.905396 292.067291,711.761169 289.987549,707.449402
	C291.891663,704.808594 294.037323,704.776611 296.652283,706.697021
z"
        />
        <path
          fill="#3D292C"
          opacity="1.000000"
          stroke="none"
          d="
M833.284424,790.642212
	C833.204529,794.112976 832.858704,797.170837 832.014221,800.588623
	C821.979126,801.000000 811.958191,801.000000 801.468628,801.000000
	C800.659607,800.620361 800.242432,800.071045 800.428589,799.687256
	C803.300476,793.768616 804.701477,787.015564 809.745789,782.314209
	C814.727722,777.671082 818.619690,772.779785 815.759521,765.389343
	C815.529846,764.795837 815.810120,764.074585 816.074585,763.057251
	C819.112366,756.840088 822.473694,751.213989 824.944885,745.137451
	C826.968384,740.161682 829.258972,738.545715 833.676758,739.296875
	C835.437805,745.698120 836.281860,751.823669 835.576355,758.534058
	C836.163147,761.947754 835.784546,764.893005 835.457886,767.850403
	C834.790588,773.891174 833.301086,779.883545 834.476990,786.038940
	C834.757141,787.505493 834.506714,789.007935 833.284424,790.642212
z"
        />
        <path
          fill="#492E2E"
          opacity="1.000000"
          stroke="none"
          d="
M816.778259,762.696899
	C817.053101,764.164673 816.667419,765.640930 817.235229,766.438477
	C821.147095,771.934143 817.710449,776.443970 814.427734,779.698120
	C809.801025,784.284485 806.258667,789.136597 804.242249,795.289429
	C803.646362,797.107849 802.169800,798.637634 801.048401,800.649841
	C792.312439,801.000000 783.624817,801.000000 774.468628,801.000000
	C780.770142,796.080688 783.893921,787.742188 791.574463,783.378906
	C798.335510,779.537964 800.573120,771.848022 802.986572,764.379150
	C803.505798,762.218445 804.883850,761.173767 806.597717,759.958618
	C810.851440,758.015381 813.520752,760.884094 816.778259,762.696899
z"
        />
        <path
          fill="#364F5A"
          opacity="1.000000"
          stroke="none"
          d="
M256.646759,784.047424
	C256.323486,787.227478 254.794479,790.237854 254.843262,793.657349
	C254.890015,796.936157 251.143005,797.691101 250.051300,800.650940
	C242.312424,801.000000 234.624832,801.000000 226.468628,801.000000
	C227.267746,797.684937 230.731659,798.421143 233.126923,797.418884
	C240.076340,794.510864 246.210495,790.366577 252.348251,786.149780
	C253.561783,785.316101 254.973297,784.770752 256.646759,784.047424
z"
        />
        <path
          fill="#2C4653"
          opacity="1.000000"
          stroke="none"
          d="
M1.000000,516.333313
	C3.372354,516.204895 5.818207,515.525879 8.523859,516.664185
	C6.467044,518.983765 3.952344,517.543457 1.432356,518.000000
	C1.000000,517.555542 1.000000,517.111084 1.000000,516.333313
z"
        />
        <path
          fill="#1B1E22"
          opacity="1.000000"
          stroke="none"
          d="
M910.137573,106.644470
	C905.043701,106.459816 903.420959,104.026329 903.941040,99.464706
	C904.259766,96.669518 904.179871,93.794205 903.958252,90.980133
	C903.616516,86.640648 904.435303,83.375557 909.578735,82.841484
	C911.370056,82.655479 911.952515,81.187691 911.964539,79.539902
	C911.990112,76.042458 911.891724,72.541588 912.015991,69.048233
	C912.105896,66.519981 910.470825,66.054649 908.574280,65.686279
	C905.705750,65.129120 904.190308,63.384983 904.441101,60.447392
	C904.707092,57.331226 907.081360,56.448540 909.680054,56.121929
	C911.322205,55.915535 913.022583,55.861176 914.665283,56.035511
	C917.914612,56.380322 920.049500,55.593628 920.603333,51.910072
	C921.115723,48.501667 924.082825,48.123066 926.740173,48.065533
	C933.565857,47.917751 940.397583,47.958656 947.225464,48.039833
	C950.445984,48.078117 953.230591,49.089165 953.740540,52.814587
	C954.084961,55.331013 955.410034,56.009892 957.796997,56.049549
	C962.658508,56.130314 967.773804,54.875656 972.042969,58.711239
	C972.920593,59.499802 974.601868,59.055988 975.865723,58.421284
	C985.526672,53.569420 995.730042,56.637726 1005.703369,56.478668
	C1007.614075,56.448196 1009.027649,57.790825 1009.463135,59.879032
	C1010.193909,63.383129 1012.733582,64.104584 1015.858276,64.015770
	C1018.687500,63.935356 1021.527405,63.877796 1024.350830,64.028839
	C1028.477661,64.249603 1031.987427,64.374870 1033.143188,58.830479
	C1033.835205,55.510632 1044.563354,54.111393 1046.962769,57.377464
	C1050.480591,62.165760 1055.868530,61.842049 1060.289795,64.023331
	C1060.875977,64.312538 1061.710327,64.225113 1062.174438,64.615646
	C1066.018433,67.849899 1068.426270,72.368141 1072.898438,75.277611
	C1075.157104,76.747002 1073.415771,80.518227 1069.946167,81.495804
	C1067.852295,82.085777 1065.596191,82.333862 1066.022949,85.486130
	C1066.486816,88.909828 1069.085571,87.801613 1071.041626,87.973587
	C1072.529053,88.104378 1074.049316,87.879112 1075.533936,88.024994
	C1078.257446,88.292595 1079.992310,87.533112 1080.371216,84.499786
	C1080.705322,81.824081 1082.460693,80.355301 1085.143311,80.402550
	C1088.203491,80.456459 1089.723755,82.583778 1089.789062,85.264099
	C1089.979614,93.082985 1089.870239,100.909592 1089.829224,108.732918
	C1089.825928,109.367012 1089.629028,110.375679 1089.215820,110.574966
	C1084.030029,113.075600 1082.060303,119.113495 1077.104248,121.650108
	C1075.312622,122.567085 1074.021606,123.110939 1073.731567,125.238083
	C1073.260742,128.692215 1070.721558,129.798096 1067.671509,129.939713
	C1064.679565,130.078629 1061.677002,129.996704 1058.679077,129.998123
	C1054.917603,129.999893 1051.152344,129.426407 1048.555786,133.482559
	C1047.145996,135.684906 1043.949341,135.215302 1041.632080,135.742325
	C1036.155273,136.987991 1033.264771,142.648163 1027.496460,143.309555
	C1025.336304,143.557220 1026.091064,146.004837 1026.020996,147.560089
	C1025.703491,154.590363 1024.373169,155.439148 1017.430969,154.410675
	C1011.683411,153.559174 1005.810730,153.493851 1000.047485,154.525787
	C993.921204,155.622742 992.161072,154.311554 992.001099,148.017532
	C991.748718,138.090759 991.748840,138.083450 981.863586,138.005661
	C979.698120,137.988617 977.539185,138.167374 975.815918,136.370605
	C974.558228,135.059296 972.947205,134.514328 971.313843,135.625351
	C969.482178,136.871246 970.129211,138.911682 970.010681,140.684906
	C969.881348,142.618179 970.577454,143.745819 972.666321,144.167007
	C974.208313,144.477936 975.941101,144.697311 976.914612,146.396652
	C978.785217,149.662003 976.861084,153.405075 972.591187,153.793655
	C968.129150,154.199768 963.603333,154.102814 959.114868,153.949249
	C956.140259,153.847488 953.840820,154.256226 953.200745,157.685028
	C953.023193,158.636627 952.678589,159.794937 951.383484,159.654633
	C950.099609,159.515533 950.194214,158.319977 949.979431,157.354233
	C949.077026,153.296295 947.216003,151.616806 943.520081,154.913345
	C942.125671,156.157059 940.136292,156.575989 938.848633,155.826843
	C934.806641,153.475235 930.472534,154.273071 926.225464,153.931412
	C923.482483,153.710754 920.817688,153.002151 920.452942,149.600708
	C920.100830,146.317642 922.036194,144.631317 925.007446,144.218765
	C927.695251,143.845566 928.067139,142.239731 928.021118,140.003281
	C927.938904,136.007523 927.893311,132.005295 928.027832,128.012375
	C928.169250,123.814568 926.380249,121.619118 922.110596,122.031891
	C917.574280,122.470459 915.608826,120.629654 914.788269,116.064682
	C914.207092,112.832001 911.981812,109.894867 910.137573,106.644470
z"
        />
        <path
          fill="#1B1E22"
          opacity="1.000000"
          stroke="none"
          d="
M750.724060,155.321198
	C752.187378,153.671448 753.191467,153.231003 755.174988,154.278748
	C758.366150,155.964355 760.642761,154.918106 762.876648,151.521774
	C766.526062,145.973465 770.085144,139.814438 777.903442,138.912964
	C782.062988,138.433365 779.849915,134.791733 780.434082,132.644028
	C788.054932,134.738876 795.143066,133.893600 802.151978,131.009476
	C805.685974,129.555267 809.529236,129.079758 813.330994,129.038513
	C823.481506,128.928391 833.634338,129.040451 843.785645,128.972153
	C846.263184,128.955505 847.899719,130.083160 849.924133,131.449966
	C854.508789,134.545380 858.805359,138.702850 865.174744,138.055176
	C868.304382,137.736969 871.495300,138.072403 874.654480,137.978073
	C877.385437,137.896530 879.861328,138.105316 881.981262,140.255264
	C883.258606,141.550735 885.079712,142.405548 886.904114,140.884644
	C888.593567,139.476273 887.973633,137.948883 886.970032,136.472427
	C886.389465,135.618286 886.039307,134.771011 886.297607,133.683914
	C886.562195,132.570358 888.646423,131.488342 886.777344,130.348770
	C885.520691,129.582565 883.436218,129.481277 882.524963,130.563324
	C879.780640,133.821655 876.224670,132.909103 872.843994,132.983948
	C869.517212,133.057587 866.141541,132.652237 862.870056,133.083878
	C858.299011,133.686966 856.316650,131.529068 856.169861,127.467621
	C855.953613,121.487755 856.086426,115.494034 856.145020,109.506935
	C856.152832,108.710945 856.391724,107.392525 856.870300,107.206062
	C862.366333,105.064919 863.811646,98.378029 869.108154,96.275444
	C871.188904,95.449455 871.978027,94.610901 872.060974,92.470703
	C872.206116,88.724182 874.132324,87.268402 878.082275,86.811348
	C883.098145,86.230942 887.663391,85.986168 892.590637,87.892136
	C896.368774,89.353592 897.972595,91.353027 897.985352,95.074295
	C898.008057,101.731087 898.052795,108.388832 897.963745,115.044518
	C897.921570,118.199135 897.067200,120.789154 893.387207,121.781349
	C889.674805,122.782265 888.697937,125.567223 890.976990,128.799957
	C893.260681,132.039291 893.863586,136.222137 897.143127,138.920364
	C898.651306,140.161240 897.880737,142.379684 897.998291,144.589188
	C896.030640,146.970032 893.490540,146.938812 890.994019,146.948257
	C880.014343,146.989807 869.034363,146.937866 858.054749,146.977386
	C853.751160,146.992874 849.801819,146.251205 846.589783,143.077209
	C845.244751,141.748138 843.469543,141.102051 841.596008,141.084030
	C829.455383,140.967331 817.313477,140.745056 805.174927,141.204865
	C804.514343,141.229904 803.879700,141.496277 802.833252,141.790009
	C800.490173,142.449554 798.503174,142.705154 796.528564,142.376984
	C791.765137,141.585403 787.698364,142.460251 784.654541,146.585938
	C783.110291,148.679047 780.074219,148.329849 778.271423,150.166687
	C775.283691,153.210800 773.273438,156.870697 772.604309,160.972168
	C771.598145,167.139236 768.819519,171.796448 763.180237,174.656952
	C761.516663,175.500763 760.511536,177.092041 758.951782,178.665405
	C756.913757,179.637146 755.222351,179.634460 753.158325,178.668884
	C751.246094,176.766495 751.613953,174.676270 751.506409,172.291565
	C751.470642,170.073441 751.945129,168.382294 752.534912,166.666870
	C753.559448,163.687164 753.230652,160.891235 750.573120,158.284607
	C750.130798,157.162643 750.154541,156.390671 750.724060,155.321198
z"
        />
        <path
          fill="#241312"
          opacity="1.000000"
          stroke="none"
          d="
M907.004272,251.555359
	C903.846924,257.323029 902.030212,262.963104 902.895508,269.256104
	C902.958008,269.710327 902.840088,270.388184 902.535767,270.664734
	C896.220764,276.404358 897.850830,284.660309 895.781982,291.737793
	C895.660950,292.151794 895.147156,292.450989 894.461243,292.917328
	C891.596313,290.769958 892.505005,287.887665 892.921753,285.245178
	C893.176758,283.628723 893.632080,282.079071 893.158630,280.041321
	C892.860229,279.105347 892.788208,278.550293 892.839172,277.561310
	C892.961548,276.864838 893.040771,276.591827 893.315857,275.939697
	C893.691650,275.341034 893.871460,275.121552 894.343994,274.596680
	C896.112549,272.161652 896.598450,269.800842 896.515747,267.225647
	C896.025269,251.950714 899.697693,236.961472 899.800232,221.753098
	C899.880737,209.823807 901.514893,197.973083 901.334961,186.082809
	C901.247192,180.286316 902.666504,174.705215 902.289185,168.820465
	C901.920715,163.073883 899.683777,158.765549 896.512817,154.019501
	C896.452454,153.302231 896.488708,153.012009 896.764893,152.354553
	C903.166626,155.692978 908.404602,159.767578 909.228210,167.942673
	C909.768982,173.310776 911.445618,178.575867 910.998901,184.541443
	C910.142456,196.026993 912.049316,207.053528 910.999023,218.538727
	C909.037048,227.294876 911.531799,236.167160 907.549866,244.275620
	C906.612427,246.184464 907.144897,248.815155 907.004272,251.555359
z"
        />
        <path
          fill="#2A1B1B"
          opacity="1.000000"
          stroke="none"
          d="
M897.004761,151.590698
	C897.001587,152.157791 896.998291,152.328262 896.990417,152.754486
	C895.965454,154.394165 894.416016,154.601242 892.983765,154.333893
	C883.858215,152.630630 874.634583,153.990616 865.037231,153.260010
	C862.262756,151.761551 859.736389,151.292267 857.024353,151.464539
	C851.770386,151.798218 846.511292,151.574402 841.254211,151.481888
	C839.267212,151.446915 837.274780,151.419724 835.182190,152.912247
	C830.953613,153.755386 827.108215,153.494949 822.836426,153.202805
	C819.117554,151.066437 815.564575,150.274750 811.678223,150.454117
	C808.028809,150.622543 804.492920,150.022476 801.407654,147.008850
	C800.559509,145.058502 799.937683,143.457336 800.757324,141.351196
	C802.438477,139.345291 804.340088,138.993134 806.411011,138.996094
	C818.056213,139.012695 829.701477,138.978073 841.346497,139.022369
	C844.403625,139.033997 847.223572,139.516525 848.963501,142.659225
	C850.390137,145.236145 853.122803,145.005142 855.637207,145.004013
	C869.445068,144.997818 883.252930,145.001694 897.529175,145.001984
	C897.666565,147.065918 897.335571,149.129990 897.004761,151.590698
z"
        />
        <path
          fill="#182932"
          opacity="1.000000"
          stroke="none"
          d="
M801.331543,105.672798
	C796.860291,111.359810 791.108521,110.128319 785.467407,110.095276
	C780.888245,110.068466 776.292786,111.456871 771.796326,109.447472
	C770.562683,108.896172 769.523682,109.194855 769.004089,110.598824
	C768.650513,111.554115 768.059937,112.871002 767.059265,112.528259
	C761.965088,110.783302 761.519653,115.088188 759.514038,117.924149
	C757.709656,117.153000 757.582397,115.698219 757.489380,114.196739
	C757.126404,108.343246 756.767395,102.493019 758.453369,96.309608
	C758.479065,95.579865 758.483215,95.290680 758.479248,94.573364
	C757.820679,89.705887 758.465942,85.332527 759.225708,80.573288
	C759.351501,79.929245 759.394104,79.672668 759.465637,79.032692
	C759.501770,78.396263 759.508850,78.143242 759.521729,77.514015
	C759.536682,76.393822 759.551819,75.649925 759.574829,74.537415
	C759.611572,73.675827 759.674316,73.187302 759.893555,72.353546
	C760.156433,71.798492 760.293518,71.592484 760.734741,71.173904
	C763.025208,71.032768 763.977478,72.757256 765.709167,73.853180
	C767.658203,78.352776 770.888550,79.504128 775.331299,79.212082
	C781.080322,78.834160 786.873352,79.027084 792.616028,79.570892
	C795.037231,79.800163 798.258484,79.365120 798.188721,83.685463
	C795.753479,86.167030 792.975403,85.370918 790.406250,85.451920
	C786.776917,85.566338 783.140991,85.442650 779.508728,85.492096
	C774.163025,85.564865 773.599060,86.147858 773.483459,91.502602
	C773.454956,92.823151 773.473633,94.144676 773.381348,95.890900
	C773.262207,97.170013 772.918274,97.956810 772.657837,99.195663
	C772.635498,105.237373 772.782410,105.416718 777.982361,105.497375
	C782.481140,105.567169 787.000122,105.229980 791.473938,105.573486
	C795.669373,105.895630 796.707764,103.955093 796.550110,100.285889
	C796.400024,96.793579 796.480530,93.288208 796.539124,89.789688
	C796.569397,87.986961 796.237366,86.087448 797.853821,84.198853
	C800.869873,83.017868 801.839111,84.751999 802.063477,86.873062
	C802.716187,93.040932 803.046753,99.226685 801.331543,105.672798
z"
        />
        <path
          fill="#15313E"
          opacity="1.000000"
          stroke="none"
          d="
M760.942261,70.629593
	C761.039001,70.961327 761.028198,71.443184 761.022339,71.684097
	C758.564941,73.582596 755.880554,72.368027 753.331055,72.432053
	C745.920776,72.618156 738.502563,72.513077 730.662720,72.486435
	C723.620117,72.895531 717.066589,72.162605 710.491699,71.536201
	C700.469849,70.581406 692.135315,74.455460 684.650513,80.839569
	C679.362244,85.350227 673.992126,89.821846 666.999207,92.478531
	C664.439636,92.199310 663.348999,90.814407 662.959595,88.380157
	C663.247620,86.880081 663.494507,85.781433 663.890869,84.374146
	C664.360474,83.712364 664.735413,83.435181 665.518921,83.175949
	C666.655212,82.996399 667.615540,83.582214 668.225952,82.969704
	C672.469299,78.711891 677.786560,75.707207 682.071777,70.984573
	C682.472656,70.580139 682.648071,70.434937 683.114380,70.114944
	C685.061401,69.350243 686.684265,68.665985 688.694153,68.096474
	C689.450439,68.008926 689.891052,68.008980 690.662231,68.006821
	C692.205933,67.924431 693.480591,68.232780 694.806152,67.226837
	C697.410828,66.686752 699.790466,66.358116 702.579224,66.026489
	C703.586426,66.026520 704.126648,66.196632 704.797729,66.784683
	C707.371277,67.000008 709.740967,67.008865 712.554565,67.013397
	C716.650818,64.867386 720.510498,66.216476 724.218079,66.570366
	C731.200134,67.236794 738.166504,66.222572 745.569214,66.900940
	C746.009460,67.048294 746.501709,67.054115 746.747681,67.044495
	C748.003906,66.306816 749.075073,65.965431 750.641357,66.777374
	C750.995911,67.038116 751.461304,67.063408 751.694397,67.063934
	C752.990479,68.045509 754.376221,67.064095 755.749451,67.844818
	C757.940002,68.086708 759.803101,68.311943 760.942261,70.629593
z"
        />
        <path
          fill="#337484"
          opacity="1.000000"
          stroke="none"
          d="
M760.441528,78.804771
	C760.496216,83.729309 760.204163,88.423622 759.848145,93.570343
	C759.715942,94.180183 759.647827,94.337601 759.670044,94.735909
	C759.355042,102.345627 758.639282,109.703918 759.098022,117.563370
	C758.841858,118.434067 758.498291,118.840454 758.000610,119.465012
	C756.869385,121.143188 755.483154,121.326401 753.528809,120.755028
	C752.469849,120.000359 752.180908,119.251831 752.139160,118.030464
	C753.202576,115.096428 751.167480,116.183510 750.222717,116.067116
	C748.803345,115.892242 747.589661,115.443970 747.377502,113.847649
	C747.202332,112.529312 747.743408,111.601944 749.216614,111.463699
	C749.300720,111.455811 749.687866,111.537971 749.494324,111.370018
	C747.016052,109.220055 748.280457,105.935074 747.003174,103.270775
	C745.630615,100.407700 747.296082,98.030769 750.741028,98.480247
	C750.317200,98.591751 749.549622,98.458572 748.448181,98.411606
	C747.974548,98.156342 747.840881,97.967758 747.723755,97.446960
	C749.264709,93.610245 752.386536,94.603264 754.868225,94.515602
	C752.476379,93.963509 748.927673,96.026299 747.414551,91.527573
	C747.132385,90.749321 747.043762,90.285751 747.007996,89.459747
	C747.482849,86.513191 747.879700,83.912331 747.414673,81.135956
	C746.293335,80.811195 745.455811,80.530014 744.189087,80.493698
	C740.799133,80.279343 737.730652,80.940781 734.432739,79.627090
	C733.961609,79.111534 733.834167,78.854668 733.820557,78.182953
	C736.683594,75.622932 739.906433,76.882858 743.296692,76.517899
	C744.684875,76.501350 745.686157,76.545067 746.966309,76.044090
	C748.182617,75.543770 748.933533,74.953094 750.071594,74.301109
	C751.141846,73.954300 751.880432,73.949684 752.984192,74.193733
	C755.322815,76.127228 758.272156,74.880859 760.583618,76.764038
	C760.853333,77.023010 760.903564,77.499588 760.862488,77.735657
	C758.504211,79.027550 756.150208,78.350540 754.340149,78.377960
	C755.856201,78.907333 758.010376,78.257523 760.441528,78.804771
z"
        />
        <path
          fill="#1B1E22"
          opacity="1.000000"
          stroke="none"
          d="
M798.337891,84.098450
	C797.705688,81.018196 795.498108,81.001289 793.267151,81.002151
	C785.848450,81.005028 778.429260,81.051399 771.011230,80.983253
	C765.787415,80.935265 765.081726,79.986404 765.989502,74.376282
	C773.727234,79.246925 782.748108,76.081688 791.067627,77.791092
	C801.295166,79.892540 805.622192,84.079659 805.982971,94.594696
	C806.080017,97.422569 805.992981,100.256439 806.014465,103.087372
	C806.024719,104.434921 806.023376,106.027657 807.563110,106.427200
	C809.376099,106.897644 810.186279,105.385284 810.789124,103.977463
	C811.326233,102.723167 810.868408,100.349289 812.947205,100.761002
	C814.787598,101.125488 813.927063,103.307152 813.963013,104.677750
	C814.121765,110.741409 812.801819,112.135452 806.991821,110.646515
	C803.897583,109.853546 801.981384,111.133018 799.823730,112.705704
	C798.019836,109.851799 803.387817,109.212906 801.121826,106.286003
	C801.038025,100.317032 799.731812,94.676208 800.923462,88.970078
	C801.309021,87.123962 801.296082,84.904274 798.337891,84.098450
z"
        />
        <path
          fill="#77878D"
          opacity="1.000000"
          stroke="none"
          d="
M752.785217,171.214035
	C751.695923,173.530182 752.955627,175.467163 752.905884,177.832901
	C752.972290,178.751328 752.758545,179.111954 751.993042,179.392532
	C750.930542,179.241776 750.446838,178.767212 749.920776,177.842896
	C749.521790,176.887238 749.350464,176.218628 749.002197,175.244446
	C748.823059,174.696365 748.760925,174.473679 748.643799,173.908905
	C746.093323,163.220917 743.144287,153.064041 736.261230,144.191528
	C735.914429,143.752731 735.762939,143.588242 735.366821,143.194794
	C734.801025,142.651886 734.472534,142.345779 733.893188,141.807220
	C733.503723,141.389389 733.357971,141.211807 733.029846,140.740692
	C730.059753,135.288010 724.961792,132.565704 720.709595,128.953384
	C719.217957,127.686203 717.261475,126.881340 716.642334,124.406082
	C716.822021,123.582161 717.106934,123.192963 717.811401,122.698669
	C721.972168,122.634575 724.733521,124.455872 726.657166,128.116898
	C727.207092,128.960388 727.681396,129.440170 728.376221,130.044769
	C729.681030,130.402618 730.660095,130.817856 731.322632,132.076294
	C731.305237,132.400772 731.422607,132.576767 731.528076,132.585190
	C732.039490,132.284622 732.387573,132.356262 732.554810,133.055511
	C732.423584,133.357224 732.420715,133.597534 732.520081,133.665146
	C734.974915,133.948715 735.717163,135.836807 736.663940,137.895660
	C736.724731,138.215363 736.834656,138.227570 736.785034,138.251968
	C742.245117,140.683762 743.823853,145.894089 745.709717,151.242981
	C745.698853,151.608322 745.445923,151.828079 745.520752,151.977966
	C749.832458,154.420914 749.475037,159.083130 751.212036,163.093460
	C752.525452,165.780655 751.973389,168.431808 752.785217,171.214035
z"
        />
        <path
          fill="#142732"
          opacity="1.000000"
          stroke="none"
          d="
M726.904175,128.117508
	C724.034729,126.743637 721.336548,125.006668 718.307373,123.184303
	C716.497803,122.986168 714.961975,122.971085 714.581604,120.625626
	C714.817383,119.925102 714.998779,119.676384 715.570923,119.172073
	C727.981323,118.221756 740.001282,118.250153 752.447266,119.120285
	C754.253113,119.475380 755.632996,119.616966 757.429688,119.720863
	C757.825989,122.738144 756.053406,123.011543 753.511108,122.721817
	C745.776062,121.840332 738.188171,123.076103 730.640442,124.646683
	C728.848999,125.019455 727.769653,126.151894 726.904175,128.117508
z"
        />
        <path
          fill="#1B1E22"
          opacity="1.000000"
          stroke="none"
          d="
M745.809448,66.711914
	C737.982666,69.008461 729.851074,68.028923 721.786804,67.607033
	C719.185974,67.470985 716.540100,67.097099 713.448120,67.012604
	C713.250122,65.660782 714.092041,65.088806 715.432312,65.022308
	C724.806702,64.557144 734.169922,66.443581 743.558716,65.167557
	C744.176147,65.083649 744.922791,65.950775 745.809448,66.711914
z"
        />
        <path
          fill="#15191C"
          opacity="1.000000"
          stroke="none"
          d="
M1092.996582,162.019867
	C1096.212769,162.036423 1097.191650,163.623199 1096.912354,166.192474
	C1096.657349,168.536819 1094.994629,168.986252 1093.003540,168.986511
	C1091.014526,168.986755 1089.346680,168.544617 1089.088501,166.199738
	C1088.806274,163.633514 1089.778809,162.040695 1092.996582,162.019867
z"
        />
        <path
          fill="#1B1E22"
          opacity="1.000000"
          stroke="none"
          d="
M905.717163,148.539673
	C905.186340,145.688339 905.960999,144.501053 908.425354,146.071686
	C910.316956,147.277237 911.379395,149.126083 910.893738,151.523361
	C910.667358,152.641434 910.439575,154.019485 909.227905,154.283173
	C907.732849,154.608551 907.574646,152.982132 907.072876,152.063477
	C906.531372,151.072113 906.238647,149.944809 905.717163,148.539673
z"
        />
        <path
          fill="#142732"
          opacity="1.000000"
          stroke="none"
          d="
M745.773865,151.158752
	C742.157227,147.804703 740.684082,142.577393 736.844849,138.589844
	C742.390137,139.161346 745.698730,143.020798 745.773865,151.158752
z"
        />
        <path
          fill="#142732"
          opacity="1.000000"
          stroke="none"
          d="
M750.369385,164.078476
	C748.591553,160.301010 747.185852,156.573746 745.687866,152.487152
	C747.848083,151.670273 749.389038,152.631592 750.711975,154.786163
	C750.982544,155.758667 750.986389,156.504700 750.991455,157.623901
	C752.117188,160.090424 752.901428,162.169815 750.369385,164.078476
z"
        />
        <path
          fill="#1B1E22"
          opacity="1.000000"
          stroke="none"
          d="
M755.985962,67.684448
	C754.642456,68.486511 753.206421,69.889313 752.006226,67.379791
	C753.344360,66.136963 754.915039,62.879673 755.985962,67.684448
z"
        />
        <path
          fill="#15191C"
          opacity="1.000000"
          stroke="none"
          d="
M1172.515381,51.576588
	C1171.112305,50.294579 1171.168213,49.399166 1172.831909,49.102055
	C1173.736694,48.940475 1175.124634,48.759083 1175.326172,49.869213
	C1175.655518,51.681072 1174.235840,51.867958 1172.515381,51.576588
z"
        />
        <path
          fill="#142732"
          opacity="1.000000"
          stroke="none"
          d="
M736.792114,137.817871
	C735.418701,136.898361 734.112671,135.581360 732.713013,133.998566
	C736.466125,132.164093 736.507629,134.970657 736.792114,137.817871
z"
        />
        <path
          fill="#0F141A"
          opacity="1.000000"
          stroke="none"
          d="
M768.698059,67.114594
	C768.632874,68.397118 768.091736,68.819481 767.169373,68.314301
	C766.587341,67.995499 766.205139,67.291901 766.622253,66.724724
	C767.209473,65.926163 767.977356,66.157013 768.698059,67.114594
z"
        />
        <path
          fill="#1B1E22"
          opacity="1.000000"
          stroke="none"
          d="
M694.692200,67.009941
	C694.548706,69.211197 693.184387,69.283379 691.228088,68.250641
	C691.465759,67.689766 691.938782,67.374954 692.704712,67.027382
	C693.449402,66.997925 693.901306,67.001213 694.692200,67.009941
z"
        />
        <path
          fill="#1B1E22"
          opacity="1.000000"
          stroke="none"
          d="
M750.937134,66.713409
	C749.928833,67.038261 748.861816,67.038414 747.394165,67.036713
	C748.070435,65.508682 749.136658,63.918320 750.937134,66.713409
z"
        />
        <path
          fill="#1B1E22"
          opacity="1.000000"
          stroke="none"
          d="
M764.435974,67.013977
	C763.909424,67.893074 763.309753,67.962227 762.753052,67.402756
	C762.610413,67.259377 762.620300,66.714912 762.768005,66.576241
	C763.339844,66.039543 763.938293,66.112595 764.435974,67.013977
z"
        />
        <path
          fill="#142732"
          opacity="1.000000"
          stroke="none"
          d="
M731.453247,132.032135
	C730.516907,131.830933 729.728516,131.261108 728.768494,130.430435
	C729.824341,130.212967 731.373169,129.610199 731.453247,132.032135
z"
        />
        <path
          fill="#1B1E22"
          opacity="1.000000"
          stroke="none"
          d="
M704.960632,66.712112
	C704.454468,66.819145 703.907227,66.647125 703.174255,66.249306
	C703.866760,65.054062 704.500549,65.237190 704.960632,66.712112
z"
        />
        <path
          fill="#142732"
          opacity="1.000000"
          stroke="none"
          d="
M732.468933,133.101288
	C732.111816,133.381973 731.875488,133.260406 731.674072,132.793060
	C731.927063,132.677536 732.220703,132.761459 732.468933,133.101288
z"
        />
        <path
          fill="#18252B"
          opacity="1.000000"
          stroke="none"
          d="
M487.283752,73.262558
	C491.856964,71.354935 496.689789,69.629631 501.586182,68.109001
	C503.042633,67.656700 504.452087,67.459213 504.941650,65.363724
	C505.750000,65.000450 506.500000,64.999985 507.625000,64.999298
	C509.464233,63.755520 511.488312,63.230408 512.769653,61.229290
	C512.998230,59.284641 512.998901,57.570469 512.998596,55.427841
	C512.985596,49.107742 517.362427,46.232140 521.872559,43.315258
	C522.130371,50.322659 520.430664,56.878437 520.150879,63.978523
	C519.792603,64.810829 519.497620,65.222588 518.845703,65.805603
	C517.531494,66.528023 516.551147,67.109261 515.317139,67.901001
	C508.688629,67.283646 503.541382,71.329742 497.609497,72.234818
	C494.267761,72.744682 491.150513,74.858459 487.283752,73.262558
z"
        />
        <path
          fill="#0D161D"
          opacity="1.000000"
          stroke="none"
          d="
M521.248901,37.999859
	C520.486877,37.444817 519.975708,36.889385 519.233521,36.166367
	C518.356689,35.328365 518.247253,34.548248 518.767334,33.345001
	C520.474731,33.074604 521.944641,33.147171 523.709717,33.110920
	C524.541992,33.605331 524.874573,34.296135 524.863953,35.507698
	C524.483521,36.966652 523.856140,37.768085 522.388672,38.080597
	C521.834534,38.000961 521.667175,38.000214 521.248901,37.999859
z"
        />
        <path
          fill="#18252B"
          opacity="1.000000"
          stroke="none"
          d="
M522.001099,38.417305
	C522.511292,37.443184 523.020691,36.884666 523.761414,36.157860
	C524.818054,35.613720 525.481812,35.946320 526.309875,36.800766
	C526.741638,40.076843 525.434082,42.075340 522.376709,43.112190
	C522.004578,43.003136 521.997559,42.997726 521.991455,42.991695
	C521.990356,41.601414 521.995300,40.217159 522.001099,38.417305
z"
        />
        <path
          fill="#282429"
          opacity="1.000000"
          stroke="none"
          d="
M1063.216553,740.214417
	C1063.000000,740.000000 1063.000000,739.500000 1063.000000,739.250000
	C1060.242065,736.230286 1059.097290,732.930847 1059.842285,729.065002
	C1060.434692,725.991272 1059.438110,723.629822 1057.026611,721.663269
	C1054.234985,719.386841 1053.300415,716.404175 1053.867920,712.810852
	C1054.435547,709.216736 1054.109131,705.560242 1054.266846,701.934570
	C1054.345947,700.116150 1054.461792,698.283569 1056.306396,696.723145
	C1058.377686,696.392212 1059.318604,697.378845 1060.154541,699.109985
	C1060.355835,699.992371 1060.502930,700.503296 1060.417847,700.985291
	C1059.134888,708.248596 1062.661255,712.618896 1069.031372,715.203857
	C1072.620361,716.660339 1073.399780,719.178955 1072.772095,722.825073
	C1071.995605,727.333374 1073.346802,728.807190 1078.017212,729.183228
	C1082.450928,729.540283 1086.741821,729.674194 1088.577759,724.177246
	C1089.519409,721.357849 1092.280884,720.533508 1094.915649,719.875610
	C1100.120361,718.575989 1104.218628,716.253540 1104.606201,710.118958
	C1104.702515,708.595154 1105.473145,706.992798 1106.854004,706.392822
	C1112.100098,704.113525 1112.926025,699.267517 1113.993530,694.578674
	C1115.012085,690.105408 1116.509399,686.255249 1121.932495,685.703064
	C1124.000854,685.492554 1123.957642,683.745483 1123.729370,682.225098
	C1123.207031,678.745239 1124.561890,675.774902 1125.780029,672.632324
	C1128.517700,665.570496 1128.976685,658.054749 1129.250000,650.575134
	C1129.396240,646.566467 1129.400757,642.601257 1131.065308,638.831787
	C1132.577759,635.407043 1131.859009,631.774963 1130.874268,628.539429
	C1129.472412,623.933411 1128.432373,619.209534 1126.788330,614.688904
	C1123.117554,604.594543 1108.106689,595.625732 1096.577759,596.333801
	C1093.356934,596.531616 1089.810791,597.463440 1087.320435,593.634277
	C1087.096436,592.708984 1087.136475,592.170532 1087.511108,591.283203
	C1088.200928,590.593506 1088.726807,590.466064 1089.651123,590.773560
	C1097.279907,592.601562 1104.721558,592.409729 1112.546143,593.001831
	C1113.500000,595.678955 1116.067261,595.726868 1117.825317,596.816956
	C1124.533325,600.976440 1131.753052,604.571289 1135.183838,612.389771
	C1135.651978,613.456360 1136.708130,614.575439 1136.574951,615.544128
	C1135.791382,621.243103 1138.908813,625.715271 1141.380981,630.163574
	C1145.422119,637.435425 1144.901245,645.171143 1144.999634,652.929443
	C1145.028931,655.239014 1144.244385,656.724121 1141.710815,657.058777
	C1137.719971,657.585815 1136.941406,660.207764 1136.916504,663.869812
	C1136.870239,670.665222 1136.313721,677.456848 1135.997314,684.251282
	C1135.941528,685.449280 1135.869507,686.711548 1134.901855,687.462524
	C1130.542358,690.846191 1130.415405,696.088074 1129.050049,700.748657
	C1128.603271,702.273865 1128.233398,703.988098 1126.787964,704.361389
	C1122.743408,705.405823 1121.733521,708.368713 1121.578247,711.863953
	C1121.225830,719.796814 1117.442505,725.696411 1111.260986,730.394592
	C1109.642212,731.624817 1108.779785,733.687256 1109.052979,735.757874
	C1109.957153,742.607971 1105.039673,746.780212 1101.689697,751.529602
	C1100.050171,753.854004 1096.616577,753.914734 1093.838135,753.917175
	C1090.310425,753.920288 1089.081299,755.660950 1089.010864,758.809326
	C1088.996094,759.475464 1089.027588,760.147400 1088.951416,760.806702
	C1088.199219,767.319092 1080.678101,770.837769 1075.079590,767.315308
	C1072.870728,765.925659 1072.940186,763.728760 1073.004761,761.541687
	C1073.122314,757.564453 1072.851074,754.166016 1068.200195,752.263611
	C1064.851807,750.894043 1066.192627,746.623718 1065.012695,743.398804
	C1064.122314,742.359497 1064.053467,741.226074 1063.216553,740.214417
z"
        />
        <path
          fill="#06090F"
          opacity="1.000000"
          stroke="none"
          d="
M1098.635742,580.747681
	C1106.345703,580.887939 1113.335693,578.434937 1120.572998,577.686646
	C1130.610840,576.648621 1140.260864,573.254395 1150.413452,572.945374
	C1153.305786,572.857361 1155.764282,571.265137 1158.309448,570.096619
	C1160.308838,569.178528 1162.570068,567.730408 1163.728516,570.605530
	C1164.744019,573.125977 1163.204346,575.260803 1160.593872,575.913391
	C1154.678467,577.392273 1148.749634,578.920349 1142.740112,579.897400
	C1132.949463,581.489014 1123.152954,582.990295 1113.269043,585.725708
	C1112.254395,585.993774 1111.506226,586.002808 1110.384033,586.016846
	C1108.792603,587.148804 1107.419312,587.707642 1105.375366,587.096191
	C1101.230347,586.717590 1097.544922,587.339294 1093.445068,588.050171
	C1091.295166,589.797424 1088.724487,589.558105 1086.416016,590.908081
	C1084.858032,591.521484 1083.591187,591.484436 1081.961914,591.486816
	C1080.634644,591.489014 1079.673706,591.489685 1078.431641,592.010498
	C1077.393188,592.300537 1076.682617,592.403381 1075.609985,592.503540
	C1074.519897,592.598328 1073.850830,592.889832 1072.803955,593.227661
	C1068.833984,594.570801 1064.760010,593.692932 1060.955322,595.938599
	C1055.791992,597.009155 1051.236572,596.535095 1046.746582,593.515747
	C1045.795776,592.045105 1045.729980,590.855103 1046.269653,589.209351
	C1047.804077,587.107300 1049.694214,586.453491 1051.709717,586.362793
	C1065.316406,585.750183 1078.527832,582.493347 1091.919678,580.448364
	C1094.027466,580.126465 1096.161987,579.563171 1098.635742,580.747681
z"
        />
        <path
          fill="#11181F"
          opacity="1.000000"
          stroke="none"
          d="
M1105.044800,587.333313
	C1106.171875,585.645569 1107.768433,586.093323 1109.624512,586.013428
	C1109.019043,588.513916 1108.304688,591.835083 1105.044800,587.333313
z"
        />
        <path
          fill="#11181F"
          opacity="1.000000"
          stroke="none"
          d="
M1085.984863,590.402161
	C1087.794189,588.386536 1090.320923,589.221741 1092.646973,588.097290
	C1092.959351,589.730530 1091.453125,589.873596 1090.213135,590.764038
	C1089.555664,591.004333 1089.111328,591.003784 1088.333740,591.004028
	C1087.252686,591.613647 1086.582764,591.462708 1085.984863,590.402161
z"
        />
        <path
          fill="#181C20"
          opacity="1.000000"
          stroke="none"
          d="
M684.240417,70.765694
	C684.002075,71.008408 683.512024,71.017174 683.266968,71.014221
	C681.785461,72.180359 680.558716,73.377312 678.309631,72.269569
	C678.080688,69.455673 681.766663,70.185303 681.950806,67.344009
	C683.543030,67.085175 684.902771,65.467064 686.773804,66.756042
	C687.651245,69.237961 685.119080,69.203590 684.240417,70.765694
z"
        />
        <path
          fill="#1B1E22"
          opacity="1.000000"
          stroke="none"
          d="
M684.357178,70.964485
	C683.976318,69.181267 685.344849,68.386986 686.799438,67.251129
	C687.549377,67.180054 688.096313,67.363998 688.826538,67.778404
	C688.614197,70.509956 686.272278,70.138229 684.357178,70.964485
z"
        />
        <path
          fill="#1C2D35"
          opacity="1.000000"
          stroke="none"
          d="
M261.438599,249.086365
	C257.400513,246.027191 256.788452,241.871323 256.261322,237.289017
	C255.378998,229.086823 253.218613,227.006393 245.620224,226.999741
	C240.323685,226.995102 235.219223,227.223663 230.422882,223.635971
	C227.775604,221.655807 223.524094,222.641464 220.151001,223.144196
	C215.316910,223.864670 210.562057,223.012665 205.873291,223.229004
	C187.560516,224.073944 169.493774,220.787537 151.230103,220.584198
	C143.144821,220.494171 134.298721,220.812393 126.722809,218.440231
	C118.266586,215.792419 110.244598,214.422119 101.616234,215.342514
	C101.034233,215.404587 100.129166,215.107315 99.820343,214.665451
	C94.060623,206.424698 85.274078,206.578445 76.611687,205.939789
	C65.458099,205.117493 64.243156,201.854630 65.016617,194.447800
	C65.531021,189.521683 62.253674,185.261017 62.739830,180.294540
	C62.911060,178.545258 60.953560,178.239212 59.449261,177.870438
	C56.991100,177.267853 56.010818,175.526367 55.998672,173.054199
	C55.986610,170.601059 56.889755,168.809753 59.344238,168.154526
	C60.813358,167.762360 62.820927,167.586472 62.719883,165.813477
	C62.419056,160.535110 65.622673,158.925385 69.916405,158.130661
	C71.216423,157.890045 71.835289,157.012146 72.050377,155.704544
	C72.345474,153.910446 73.414360,152.470032 75.189293,152.224182
	C78.116524,151.818726 79.136169,150.267639 79.069962,147.483719
	C79.048073,146.563156 79.456711,144.935486 79.965691,144.806839
	C85.083961,143.513077 87.623741,138.868546 91.625587,136.262665
	C93.421799,135.093002 94.750298,134.427704 95.195786,132.193054
	C95.921921,128.550629 98.528999,127.023567 102.117828,127.007607
	C104.949875,126.995018 107.860008,127.410454 110.595276,126.893730
	C114.428131,126.169655 119.561836,127.692009 120.987236,121.749008
	C121.243431,120.680885 122.627899,120.145691 123.855217,120.020828
	C126.460129,119.755814 126.786079,118.000221 127.052124,115.797401
	C127.688705,110.526573 129.450531,105.510010 131.024048,100.459846
	C131.625488,98.529556 133.184860,97.390785 134.812790,98.255547
	C140.989120,101.536415 146.850967,97.773247 152.843552,97.627151
	C157.220001,97.520454 162.292999,97.619041 163.792892,90.855576
	C164.396973,88.131577 169.475403,88.045738 172.776550,88.141426
	C175.880722,88.231400 174.672577,91.079063 175.143250,92.801399
	C175.881561,95.503151 177.280151,97.321869 180.393112,96.990593
	C183.851074,96.622597 187.329636,96.361862 190.751877,95.777321
	C192.738449,95.438004 193.663467,93.872879 193.986832,91.834831
	C195.048782,85.142288 202.083893,81.515343 208.441208,83.874458
	C213.998093,85.936562 219.852722,85.777832 225.440140,83.917114
	C227.985123,83.069588 230.212997,82.534622 232.521439,84.034050
	C234.675308,85.433075 237.329681,85.635605 239.258942,84.779037
	C246.134064,81.726608 253.317001,82.335373 260.220703,83.251282
	C264.928864,83.875916 270.943512,83.196365 273.597809,89.511841
	C274.669495,92.061752 277.957672,91.465950 279.928009,90.693146
	C285.429657,88.535309 291.068420,89.082764 296.698242,89.005562
	C300.019226,88.960014 303.284607,88.844543 306.602570,88.191994
	C312.784332,86.976219 316.763489,89.267273 319.500122,94.893059
	C321.037048,98.052521 323.825653,98.381874 326.732666,97.786377
	C328.864471,97.349686 330.951080,96.882881 333.567413,97.000465
	C335.612091,100.249695 334.058655,103.509438 333.602997,106.597023
	C332.694244,112.754715 331.987793,118.932442 331.030396,125.486862
	C327.616119,129.928528 323.162262,130.810822 318.319214,130.574478
	C315.442932,130.434143 312.999817,129.213394 311.955261,126.489616
	C310.772247,123.404732 308.563721,122.854874 305.674286,122.753250
	C295.715363,122.403015 285.801025,121.078735 275.787811,122.608444
	C270.566895,123.406036 265.417786,123.008835 262.875763,116.812706
	C261.945923,114.546249 259.930389,115.384758 259.099243,117.134972
	C256.389832,122.840485 251.705261,123.122444 246.413910,122.222145
	C241.788559,121.435165 237.107635,121.841354 232.451340,121.755905
	C228.618988,121.685562 225.129852,121.106773 223.238129,117.143677
	C222.278244,115.132721 220.839111,115.210495 219.155975,116.385124
	C212.974716,120.698914 206.285553,122.877716 198.580582,122.043930
	C193.844376,121.531395 190.105072,124.851196 186.297867,126.991173
	C182.635849,129.049545 179.372208,129.739426 175.433487,128.444305
	C168.548203,126.180298 161.630600,128.228745 156.418228,131.810196
	C152.756592,134.326141 149.421585,137.381088 145.252090,139.411804
	C142.167236,140.914246 139.386887,141.531204 136.241730,139.993729
	C133.890335,138.844269 132.060776,138.801834 130.472137,141.613556
	C127.655174,146.599304 125.996506,147.190002 120.715599,145.100571
	C117.023979,143.639954 115.350975,144.324539 114.318764,148.310394
	C113.189262,152.671982 110.485016,153.897049 105.850906,153.026352
	C103.582687,152.600189 101.429840,151.729462 98.999046,152.459274
	C91.511765,154.707184 91.120720,155.032974 90.758446,163.049911
	C90.593155,166.707703 89.571564,169.572495 85.708427,170.718140
	C84.592743,171.048996 83.403648,171.464188 83.444130,172.968750
	C83.462646,173.657043 83.782936,174.252304 84.532074,174.458755
	C85.966240,174.853943 86.656509,173.936630 87.290634,172.896530
	C89.477974,169.308884 91.364578,170.328781 93.120834,173.285950
	C93.460968,173.858673 93.833778,174.414917 94.129349,175.010559
	C97.136696,181.071106 100.870605,185.837189 108.788872,184.594894
	C110.737328,184.289200 112.966629,185.454239 113.764763,187.393951
	C115.345863,191.236450 118.663284,191.557327 121.945099,191.977264
	C125.710602,192.459137 129.121613,193.285736 131.092194,197.054306
	C132.296722,199.357880 134.731812,199.152908 136.863266,199.177643
	C140.726151,199.222488 144.367142,199.779175 148.023254,201.321762
	C152.114059,203.047760 156.528000,202.436951 160.765839,201.217499
	C164.617752,200.109100 168.079544,200.406036 170.515533,204.022217
	C171.901154,206.079132 173.785522,206.171661 175.803482,205.545303
	C179.657455,204.349060 183.331604,204.680786 186.981262,206.337173
	C189.156815,207.324539 191.540909,207.255814 193.868271,207.214035
	C202.516800,207.058823 211.165359,207.551971 219.818695,206.621902
	C224.283112,206.142075 228.824265,206.960175 233.013733,208.755844
	C235.606125,209.866974 238.036896,209.952744 240.761856,209.286575
	C246.933990,207.777710 250.096924,210.156479 254.405075,214.868744
	C259.087036,219.989899 258.499481,226.510208 261.059662,232.087875
	C261.852417,233.815002 262.678772,235.321945 264.515686,236.703979
	C266.029785,239.814514 265.407379,242.720093 265.220947,246.007736
	C264.435974,247.834778 263.392365,248.784256 261.438599,249.086365
z"
        />
        <path
          fill="#262C32"
          opacity="1.000000"
          stroke="none"
          d="
M329.749939,126.351105
	C328.733795,117.769974 332.187897,109.953094 332.153595,101.814316
	C332.147888,100.466476 333.275604,99.113853 333.940369,97.382027
	C338.283783,97.216278 340.167023,94.000504 341.701630,90.982559
	C342.643890,89.129448 343.826263,88.192932 345.763855,87.249260
	C355.506287,82.061546 365.803650,83.018753 375.964172,83.005058
	C391.785187,82.983727 407.606598,82.938957 423.427185,83.024780
	C431.572357,83.068962 439.619232,81.643471 447.799500,81.908531
	C457.506348,82.223053 467.248077,81.963501 476.949493,81.697975
	C484.308228,81.496574 490.908051,80.345619 497.670654,78.255486
	C501.013275,77.222359 504.105530,76.050827 506.688782,73.359238
	C508.561127,71.408356 511.085785,69.709396 514.835571,70.304619
	C515.849426,73.548462 513.697876,75.004250 511.823456,76.466454
	C509.524719,78.259605 507.219177,80.047638 504.548737,82.013107
	C502.564209,82.527664 500.789886,82.535301 499.502563,83.717049
	C495.155334,87.707680 489.612946,87.880577 484.230408,88.862457
	C466.584259,92.081444 448.712433,90.125740 430.979584,91.373940
	C424.896332,91.802139 418.719635,91.028122 412.579773,91.034958
	C408.615967,91.039368 404.637878,90.386124 400.756378,92.010696
	C398.913422,92.782043 396.839111,92.544823 394.856140,92.036789
	C391.579620,91.197342 388.257324,91.076454 385.023987,92.104774
	C380.919342,93.410210 376.571533,92.527252 372.805176,91.915398
	C366.336914,90.864616 361.235687,93.728333 355.194153,95.260010
	C348.276733,95.174492 346.960846,101.094093 343.827942,104.804031
	C337.394318,112.422615 339.010406,122.344177 336.524628,131.099869
	C335.808105,133.623764 336.298126,137.042847 331.962708,137.340698
	C328.839294,134.095047 328.702271,130.492142 329.749939,126.351105
z"
        />
        <path
          fill="#25262A"
          opacity="1.000000"
          stroke="none"
          d="
M1057.307373,696.709656
	C1055.242920,699.024414 1056.163208,701.459412 1056.031494,703.711609
	C1055.857300,706.695496 1056.536255,709.879883 1055.712891,712.639282
	C1054.526733,716.614380 1055.618408,719.117737 1058.951538,720.946289
	C1061.518433,722.354309 1062.658569,724.614868 1061.894653,727.180664
	C1060.720825,731.123047 1061.693726,734.636719 1062.989014,738.611145
	C1060.346313,738.423462 1058.015015,737.381348 1056.367310,735.040161
	C1054.934448,733.004089 1052.513550,732.024902 1050.814819,733.805420
	C1047.673218,737.098206 1043.086548,737.314087 1039.356689,739.948975
	C1035.627563,738.490540 1031.944092,738.628296 1028.404175,738.007202
	C1027.427124,737.835754 1026.132568,738.110779 1025.496460,737.573669
	C1020.277710,733.166565 1017.214722,736.605652 1014.253113,740.507446
	C1013.608887,741.356201 1012.869873,741.919983 1011.786072,741.967712
	C1008.240967,742.123840 1006.235840,743.889832 1005.985413,747.503723
	C1005.913452,748.541260 1005.648804,749.843811 1004.509033,749.773621
	C1001.495239,749.588013 999.095337,755.282471 996.940063,752.002625
	C995.390930,749.645325 996.762634,744.975464 997.648987,741.548645
	C998.242310,739.254761 1001.083496,739.878601 1002.961670,740.058838
	C1007.705200,740.513977 1009.207825,738.214355 1009.020752,733.874023
	C1008.576904,723.577454 1012.178955,720.341553 996.793396,721.051208
	C993.320374,721.211365 992.011292,719.517151 991.259888,716.268677
	C991.097839,715.514526 991.148132,715.012512 991.297791,714.542053
	C994.091431,705.763794 994.081726,705.502502 1003.247375,705.077759
	C1009.369568,704.794067 1007.360291,699.766724 1008.939453,696.393799
	C1006.022522,686.637695 1011.156677,678.627197 1019.408020,675.952271
	C1024.132446,674.420776 1029.037598,673.290039 1033.993652,672.592957
	C1036.698608,672.212463 1039.763062,672.038879 1041.322876,674.548645
	C1044.062866,678.957581 1048.660156,680.644470 1052.634521,683.231628
	C1057.582031,686.452087 1059.111206,690.176758 1057.307373,696.709656
z"
        />
        <path
          fill="#25262A"
          opacity="1.000000"
          stroke="none"
          d="
M1063.009521,740.347534
	C1064.387939,740.142883 1064.981079,740.968994 1064.990479,742.652466
	C1063.612061,742.857056 1063.018677,742.031250 1063.009521,740.347534
z"
        />
        <path
          fill="#8AB7C6"
          opacity="1.000000"
          stroke="none"
          d="
M936.618774,310.183960
	C937.591431,309.244263 938.370361,308.533691 939.431274,307.677826
	C942.802612,311.993164 946.818115,314.213837 952.351257,315.499207
	C956.773010,316.526428 961.043579,317.316284 965.490601,317.245056
	C970.731750,317.161102 975.674194,317.633148 980.301208,320.605316
	C983.220032,322.480255 986.727539,323.398468 990.317322,323.246063
	C995.054321,323.044922 999.587036,324.075989 1004.058960,325.413300
	C1007.241333,326.365021 1010.215881,327.345703 1013.699463,326.048676
	C1016.149109,325.136658 1018.634338,326.517609 1020.341553,328.550446
	C1022.098389,330.642334 1024.242188,332.137909 1026.963867,333.565613
	C1028.180908,334.374268 1028.755493,335.280548 1029.766357,336.302673
	C1031.656372,339.169891 1034.513184,338.261108 1036.799805,338.907623
	C1042.823364,340.610718 1049.351685,341.137268 1053.725952,347.056335
	C1054.148804,350.639648 1051.759033,351.077637 1049.510986,351.899261
	C1043.011719,354.274628 1036.620972,351.927734 1030.257568,351.392365
	C1014.388184,350.057251 998.757446,346.258728 982.700745,346.711975
	C982.038147,346.730682 981.344421,346.691650 980.721008,346.480438
	C970.384033,342.977722 959.784241,344.642426 949.233093,344.644287
	C938.763733,344.646149 928.775757,342.601196 918.722717,338.817627
	C910.519836,335.730377 905.890869,329.068176 899.144226,324.755615
	C897.743103,323.860046 896.897461,322.315338 896.568237,320.180420
	C897.304810,317.495819 899.049683,318.358612 900.942383,318.533752
	C901.566467,318.600677 901.813232,318.630463 902.420532,318.732300
	C903.469543,318.994568 904.138489,319.243835 905.148804,319.557404
	C906.588623,319.999115 907.514954,320.615936 908.421021,321.796021
	C909.055908,322.750946 909.528870,323.419250 910.603638,323.920471
	C912.826355,325.233093 914.699219,326.469391 915.857483,328.861969
	C916.017517,329.667297 915.824036,330.208374 916.338745,330.609985
	C917.112305,330.399506 917.473511,330.015961 918.050293,329.378357
	C925.780518,326.615082 928.546875,319.510651 933.156311,313.707794
	C933.617004,313.042328 933.924438,312.705109 934.540222,312.185669
	C934.781921,311.941345 935.076416,311.793610 935.135132,311.639709
	C935.287903,310.765137 935.816223,310.542877 936.618774,310.183960
z"
        />
        <path
          fill="#92BFCE"
          opacity="1.000000"
          stroke="none"
          d="
M1027.256348,334.754578
	C1023.799744,333.726135 1020.745239,332.676270 1018.706360,329.222748
	C1017.555542,327.273315 1014.297119,325.264099 1011.825317,328.865601
	C1011.125977,329.884583 1010.228882,330.013733 1009.255920,329.458282
	C1002.565796,325.639069 995.281250,324.915924 987.747986,324.873749
	C985.211670,324.859558 982.063965,324.268524 980.262939,322.708771
	C974.196411,317.455048 967.117065,319.270966 960.329468,319.041748
	C957.152649,318.934479 954.071716,319.134247 951.622375,316.464142
	C950.781738,315.547760 949.312988,314.791962 947.985229,315.598969
	C943.588318,318.271362 942.096741,316.083557 941.444153,311.981354
	C941.240601,310.701721 940.541016,309.501007 939.890869,307.898376
	C939.727783,307.238190 939.830017,306.978363 940.082642,306.550140
	C940.224609,305.937531 940.429749,305.601624 941.030457,305.230957
	C941.976990,304.642395 942.635620,304.151672 943.557007,303.493713
	C946.072021,303.248566 948.620117,303.573761 948.382324,299.656128
	C950.515686,295.397308 954.311035,294.631714 958.536133,293.943970
	C961.771362,295.004059 964.472229,293.502441 967.275635,293.445648
	C990.271851,292.979858 1008.027649,302.593658 1021.425293,321.438416
	C1021.747009,321.940948 1021.886902,322.131439 1022.259888,322.582397
	C1022.643982,323.016907 1022.794922,323.191040 1023.149475,323.640198
	C1023.478516,324.103790 1023.603882,324.292328 1023.905762,324.768738
	C1024.407593,325.647644 1024.740845,326.234161 1025.229370,327.126648
	C1026.523315,329.662445 1028.762817,331.560913 1027.256348,334.754578
z"
        />
        <path
          fill="#241312"
          opacity="1.000000"
          stroke="none"
          d="
M1072.768799,321.757568
	C1072.239624,324.625214 1070.329224,322.607819 1069.001831,322.957306
	C1067.102539,323.457367 1064.822388,322.130737 1062.836426,324.852905
	C1055.180176,327.749084 1051.632202,326.925598 1048.206299,321.934265
	C1047.643066,320.419312 1047.326294,319.226624 1046.403076,317.920349
	C1045.615601,316.426758 1045.036865,315.215424 1044.130859,313.806885
	C1042.581299,311.517365 1041.221069,309.501343 1038.913818,307.855927
	C1031.816040,301.954010 1023.874939,298.795013 1015.225952,297.199982
	C1013.597778,296.899689 1011.940430,296.646698 1010.164673,295.566315
	C1008.526733,292.324646 1011.186401,291.901093 1013.140869,290.626770
	C1014.018433,290.326447 1014.538330,290.327606 1015.380737,290.689819
	C1018.512695,291.007050 1021.297241,291.160278 1024.062256,290.249847
	C1025.628052,289.734314 1027.419067,289.720886 1028.917114,290.564484
	C1035.496826,294.269714 1043.255859,297.134094 1047.376465,303.385712
	C1053.656616,312.913879 1064.268433,315.365326 1072.768799,321.757568
z"
        />
        <path
          fill="#CFECEF"
          opacity="1.000000"
          stroke="none"
          d="
M1022.342285,323.706970
	C1018.008362,319.923676 1015.048706,314.907959 1010.685364,311.044312
	C996.626770,298.596130 980.464478,293.189697 961.751953,295.753754
	C960.977356,295.859894 960.150452,295.584534 959.120483,295.163940
	C959.590210,291.529266 962.480591,291.328552 964.957275,290.703339
	C971.267212,289.110535 977.717712,289.395508 984.598816,289.452362
	C986.538269,289.661957 988.003601,290.018799 989.917358,290.413300
	C991.677185,290.994141 992.660645,292.363251 994.641846,292.028687
	C996.410034,292.117828 997.789612,292.318756 999.252075,293.259705
	C1000.035400,298.614258 1005.168701,299.263031 1008.113647,302.492737
	C1008.839600,303.392273 1009.263062,304.115082 1010.042358,304.945587
	C1010.388855,305.312042 1010.525452,305.460419 1010.856995,305.839600
	C1011.507935,306.730255 1011.953674,307.399719 1012.931763,307.991852
	C1013.333313,308.327606 1013.487305,308.472076 1013.851135,308.856750
	C1014.635315,310.002686 1015.260193,310.864227 1016.429504,311.666992
	C1016.875305,311.982483 1017.043518,312.123932 1017.437622,312.506714
	C1017.937317,313.094208 1018.194885,313.451996 1018.623779,314.096130
	C1018.927368,314.561401 1019.043457,314.751892 1019.305420,315.243835
	C1020.618652,318.130035 1023.585571,320.005005 1022.342285,323.706970
z"
        />
        <path
          fill="#F0F0EC"
          opacity="1.000000"
          stroke="none"
          d="
M914.436768,310.847229
	C915.422241,311.108551 915.833679,311.169586 916.553223,311.285950
	C919.879578,312.664551 923.355957,311.581360 926.365112,313.452606
	C930.375427,315.946411 930.872925,317.533020 927.904358,321.098846
	C925.441711,324.057037 923.821716,328.011627 918.909363,328.527161
	C918.317932,328.432770 918.087830,328.372131 917.536011,328.173981
	C917.018860,327.917358 916.823425,327.798309 916.368042,327.465881
	C915.955872,327.093750 915.803711,326.934998 915.449890,326.522217
	C915.000000,325.922913 914.756958,325.574066 914.315552,324.981018
	C913.972961,324.581177 913.833435,324.422180 913.477905,324.023804
	C913.114502,323.622284 912.967041,323.460175 912.597168,323.049957
	C909.924805,319.649109 906.260620,318.422913 902.860229,316.760223
	C901.152954,315.925415 898.938416,315.563477 899.002808,312.712402
	C902.281555,310.353851 905.791992,310.408783 909.619324,310.232422
	C910.200195,310.110931 910.429932,310.058441 911.005249,309.980225
	C911.576233,309.969818 911.801575,309.985107 912.352783,310.067810
	C913.089600,310.268158 913.485718,310.435486 914.436768,310.847229
z"
        />
        <path
          fill="#5E9BAB"
          opacity="1.000000"
          stroke="none"
          d="
M990.138062,290.297485
	C988.879639,290.776520 987.414490,290.991547 985.516235,291.094910
	C977.536743,289.303009 970.070923,287.035645 962.191772,287.712189
	C953.474854,288.460632 949.501587,291.649902 947.809082,299.610657
	C949.460449,300.684174 950.599487,302.381683 949.530884,303.442261
	C948.210571,304.752686 945.960144,305.425232 943.890991,303.622925
	C944.033997,302.730621 944.248047,302.134796 944.361816,301.236542
	C944.057190,300.827698 943.852966,300.721313 943.799438,300.304108
	C943.957581,299.551453 943.964966,299.109589 943.926758,298.339325
	C943.789490,297.837250 943.697937,297.663605 943.761963,297.240021
	C944.043335,296.372162 944.169128,295.754272 944.290771,294.820618
	C944.216492,294.121887 944.290222,293.772461 944.682678,293.225586
	C948.634033,285.272583 955.819214,283.560944 963.344971,283.047516
	C972.906250,282.395264 981.521973,285.968750 990.138062,290.297485
z"
        />
        <path
          fill="#9FA2B2"
          opacity="1.000000"
          stroke="none"
          d="
M916.781372,327.288513
	C919.655701,328.213898 921.650085,327.216766 923.300903,325.091217
	C923.705872,324.569763 924.155579,324.065460 924.461609,323.488037
	C925.716431,321.119904 930.135437,319.785919 928.534668,316.875671
	C927.036438,314.151825 923.239746,313.184967 919.834656,312.886719
	C918.853638,312.800781 917.832764,312.857697 916.972961,311.685791
	C922.122070,310.584717 927.153198,311.799469 932.571289,313.109131
	C933.180725,312.905090 933.390198,312.717194 933.788208,312.906982
	C930.696045,320.526093 927.350952,327.708435 918.274658,329.980957
	C916.819641,329.541351 916.354431,328.758911 916.781372,327.288513
z"
        />
        <path
          fill="#617179"
          opacity="1.000000"
          stroke="none"
          d="
M999.292358,294.221069
	C998.246826,292.554565 996.691284,292.938873 995.276489,292.208954
	C996.028625,291.029388 997.254333,292.133209 998.714111,291.565460
	C995.119629,285.105469 993.644897,278.645477 1000.234070,272.713196
	C1000.784973,272.640442 1001.018555,272.689514 1001.427246,273.048615
	C1002.061279,275.317963 1000.906738,276.839203 1000.147827,278.433289
	C998.460632,281.977234 999.057129,285.219055 1001.610718,287.976959
	C1003.944580,290.497498 1006.790100,291.131012 1009.962158,289.257263
	C1010.810486,288.756195 1011.864807,288.841431 1013.178711,289.223511
	C1013.911255,289.660736 1014.200378,290.042725 1014.314941,290.858551
	C1012.929260,292.180695 1011.624573,293.142181 1010.243164,294.404480
	C1009.160583,296.547363 1007.386536,296.429932 1005.256104,296.427673
	C1003.757141,296.213562 1002.734436,295.830902 1001.306458,295.414398
	C1000.404541,295.158081 999.952148,294.885284 999.292358,294.221069
z"
        />
        <path
          fill="#557E8B"
          opacity="1.000000"
          stroke="none"
          d="
M913.211914,311.350098
	C912.841248,311.154419 912.432861,311.108734 912.256714,311.002991
	C911.713562,310.571045 911.194031,310.265045 911.084778,309.835663
	C909.126160,302.141235 903.712219,298.721100 895.959839,297.532715
	C894.720703,296.027740 894.131592,294.849945 896.849731,294.410156
	C898.636292,293.969910 900.014648,293.555908 901.674316,294.754791
	C902.384888,295.171021 902.760010,295.322418 903.440063,295.473572
	C905.298523,294.793243 906.481323,295.369110 907.685242,296.860840
	C907.892395,297.146454 907.950745,297.100006 907.924377,297.073608
	C908.181641,297.230927 908.447449,297.437378 908.860046,297.859802
	C909.027527,298.049377 909.171570,298.254669 909.290894,298.289062
	C911.037476,300.239227 912.664795,302.154968 914.628540,304.036774
	C914.091370,306.049438 914.783569,308.109650 914.961060,310.606262
	C915.010803,311.047546 915.368896,311.221619 915.302490,311.409302
	C914.680481,311.724030 914.129272,311.706879 913.211914,311.350098
z"
        />
        <path
          fill="#617179"
          opacity="1.000000"
          stroke="none"
          d="
M1000.732544,272.763153
	C1007.251465,266.876434 1014.310608,269.232391 1017.408020,278.565674
	C1017.298096,279.081085 1017.109314,279.221619 1016.567322,279.385834
	C1015.740540,279.261108 1015.208862,279.081848 1014.968872,278.677521
	C1011.922913,273.545990 1007.298462,272.573669 1001.577148,273.460114
	C1001.274597,273.279572 1000.913574,272.934906 1000.732544,272.763153
z"
        />
        <path
          fill="#48505A"
          opacity="1.000000"
          stroke="none"
          d="
M1015.803711,279.160339
	C1016.353699,278.937775 1016.579468,278.941010 1017.146057,278.942383
	C1020.570068,283.194702 1016.943604,286.517395 1015.813538,290.559204
	C1015.377625,290.984894 1015.051636,291.053314 1014.479858,291.170502
	C1013.967712,290.891266 1013.701416,290.563263 1013.166138,290.067078
	C1012.947388,286.195557 1014.216003,282.791473 1015.803711,279.160339
z"
        />
        <path
          fill="#4C707C"
          opacity="1.000000"
          stroke="none"
          d="
M915.003357,304.014435
	C915.038879,303.876556 915.112305,303.717255 915.112366,303.557892
	C915.113220,301.604736 915.309937,299.796661 917.993958,300.152924
	C919.045898,300.292542 920.040161,300.848694 919.780212,302.033203
	C919.275879,304.330933 917.205017,303.449432 915.349487,303.885864
	C914.964844,304.002838 915.007202,303.992981 915.003357,304.014435
z"
        />
        <path
          fill="#142732"
          opacity="1.000000"
          stroke="none"
          d="
M936.508911,310.013489
	C936.455139,310.395477 936.097778,310.836151 935.467102,311.381348
	C935.530945,311.014587 935.868103,310.543396 936.508911,310.013489
z"
        />
        <path
          fill="#142732"
          opacity="1.000000"
          stroke="none"
          d="
M934.133423,313.069702
	C933.804260,313.279449 933.631714,313.274200 933.215210,313.180969
	C933.386902,312.731964 933.802490,312.370972 934.500000,311.975647
	C934.617981,312.245789 934.454041,312.550232 934.133423,313.069702
z"
        />
        <path
          fill="#142732"
          opacity="1.000000"
          stroke="none"
          d="
M943.810425,300.114990
	C944.042969,300.136536 944.135864,300.279785 944.244995,300.678589
	C944.064514,300.701630 943.867615,300.469177 943.810425,300.114990
z"
        />
        <path
          fill="#142732"
          opacity="1.000000"
          stroke="none"
          d="
M943.775879,297.084167
	C943.956177,297.161743 943.994751,297.333466 943.957214,297.758057
	C943.752930,297.836334 943.602661,297.672607 943.509033,297.481171
	C943.477234,297.416168 943.588318,297.281250 943.775879,297.084167
z"
        />
        <path
          fill="#142732"
          opacity="1.000000"
          stroke="none"
          d="
M944.641846,293.196075
	C944.781433,293.356384 944.727173,293.723236 944.479797,294.297485
	C944.340515,294.137451 944.394287,293.770020 944.641846,293.196075
z"
        />
        <path
          fill="#142732"
          opacity="1.000000"
          stroke="none"
          d="
M941.033081,305.233856
	C941.114258,305.467529 940.910095,305.801971 940.425537,306.241791
	C940.346008,306.009674 940.546936,305.672150 941.033081,305.233856
z"
        />
        <path
          fill="#262C32"
          opacity="1.000000"
          stroke="none"
          d="
M514.995605,68.329193
	C515.060974,66.864647 515.462341,65.919792 516.779053,65.192215
	C518.465942,67.218994 518.836121,69.157501 515.489868,70.220901
	C515.046326,70.044266 515.021362,70.020515 515.008972,70.008392
	C514.997070,69.551735 514.997559,69.107201 514.995605,68.329193
z"
        />
        <path
          fill="#3C5965"
          opacity="1.000000"
          stroke="none"
          d="
M282.707306,707.822021
	C288.445557,717.158020 284.344635,724.737366 279.382996,732.307556
	C274.471375,739.801392 273.121796,749.256775 266.913849,755.996399
	C262.133942,761.185730 258.631927,767.000183 255.634354,773.336243
	C254.002243,776.785950 250.429520,778.682190 246.999573,780.591125
	C241.904068,783.427124 235.859177,784.534180 231.737854,789.275818
	C230.418869,790.793274 227.857346,790.912109 225.640991,791.017395
	C211.661728,791.681030 197.768143,790.430725 183.927338,788.825928
	C179.100876,788.266418 175.041107,785.194580 171.442886,781.911377
	C161.738724,773.056946 153.922302,762.558289 145.910233,752.241455
	C145.127350,751.233337 144.810303,749.844421 144.356384,748.603271
	C142.456253,743.407410 140.550278,738.357666 134.882477,735.795349
	C132.334366,734.643311 131.855759,731.632141 130.989716,729.107056
	C128.233353,721.070435 125.374268,713.070190 118.296425,707.157227
	C115.416374,704.728333 114.165382,701.690491 113.072914,698.541992
	C112.377663,696.538269 112.034103,694.329285 109.949692,692.632507
	C109.700180,692.089478 109.650955,691.851013 109.800720,691.306274
	C106.550293,684.497437 103.134689,677.976562 99.627861,671.505127
	C98.743462,669.872986 97.250816,670.076233 95.678413,670.581482
	C93.204170,671.376648 91.961655,670.606934 90.923340,667.905701
	C87.780869,659.730530 84.708122,651.347107 76.237411,646.821533
	C76.091537,646.743530 75.849075,646.697937 75.812424,646.585022
	C72.452988,636.243042 63.763420,639.356750 56.526043,639.060425
	C53.533962,638.937866 51.249447,638.995056 50.361919,635.132080
	C49.447811,631.153320 45.728168,630.958130 42.235535,630.984314
	C34.350674,631.043396 34.919674,631.511292 35.031723,623.664490
	C35.092522,619.407043 34.122974,616.023499 29.372549,615.046753
	C28.126925,614.790649 27.101934,614.417603 27.163723,612.883789
	C27.213797,611.640747 28.041809,611.200562 29.112404,611.015564
	C32.462849,610.436707 33.266941,607.168945 35.254887,605.394226
	C39.393360,601.699585 43.295731,598.558044 44.083923,592.565796
	C44.230057,591.454773 47.381222,592.078918 49.178085,592.542114
	C53.293926,593.603027 56.502449,592.713623 59.013081,588.839111
	C62.064205,584.130432 69.253059,582.982849 74.067482,585.871094
	C75.028488,586.447632 76.257446,586.969360 77.324226,586.902161
	C80.213593,586.720520 81.948494,587.656616 82.900925,590.563782
	C83.627136,592.780457 85.665443,594.167419 88.009727,593.619324
	C90.636116,593.005249 89.911079,590.473877 89.985497,588.571472
	C90.219406,582.592346 91.984436,581.636047 97.677238,584.823853
	C100.146545,583.519165 101.596954,585.027649 102.595276,586.455261
	C106.406891,591.906250 111.792236,595.496643 117.337585,599.514160
	C117.699844,600.046082 117.815109,600.280579 118.027374,600.898682
	C118.208885,602.091614 118.075470,602.863037 117.482925,603.906799
	C116.652405,604.557739 116.027130,604.748657 115.339668,604.634216
	C108.583344,603.509766 106.910385,604.848633 105.781570,611.924622
	C103.367073,627.059998 106.460976,641.568542 110.440636,656.041382
	C114.292435,670.049316 119.647842,683.472229 125.782921,696.588257
	C133.452713,712.985474 141.056061,729.405823 151.731400,744.214417
	C156.175461,750.379211 160.466400,756.555298 165.276993,762.497009
	C171.719254,770.453979 180.200714,773.156128 188.870132,776.111755
	C193.070740,777.543945 197.679016,777.849609 202.026993,778.976624
	C212.812363,781.772400 223.368698,779.730408 233.440811,776.379028
	C239.608795,774.326721 245.242188,769.925842 249.744781,765.179932
	C260.425659,753.922058 268.708344,740.867920 275.542969,726.919312
	C277.294983,723.343689 277.433167,719.556030 277.280396,715.698853
	C277.201782,713.714905 276.606903,711.740967 277.299683,709.382690
	C278.092590,708.077515 279.090149,707.717773 280.492859,707.297363
	C281.423126,707.191406 281.933594,707.306274 282.707306,707.822021
z"
        />
        <path
          fill="#33616C"
          opacity="1.000000"
          stroke="none"
          d="
M100.125145,571.567749
	C98.400047,567.766663 97.126312,564.536560 99.825439,561.063171
	C104.247627,561.130005 106.044540,564.563904 108.276695,567.018921
	C117.644020,577.321777 127.858261,586.797119 137.319183,596.992493
	C147.821457,608.309998 159.070251,618.905029 169.310074,630.476624
	C172.979767,634.623657 176.399033,639.548279 183.376495,639.227295
	C184.365097,639.410156 183.475784,639.320435 184.130692,639.435425
	C184.607559,639.519165 185.097488,639.393860 185.560669,639.568298
	C185.138794,639.428772 184.695511,639.495911 183.923615,639.340149
	C182.403732,638.850891 181.059464,639.188232 179.709915,638.117676
	C179.119980,637.025696 179.120956,636.236694 179.678284,635.114868
	C187.313034,635.621155 194.651123,634.760254 202.376282,634.369751
	C204.692535,639.239441 199.962601,638.600342 197.813568,640.523682
	C196.108368,641.539612 194.605713,641.976379 192.690186,642.354004
	C188.933426,642.268127 186.298355,644.087158 183.248795,645.872314
	C180.617828,646.639893 178.426483,646.040588 175.882355,645.440735
	C173.137207,644.290161 170.031082,645.825928 167.826569,643.086975
	C167.289993,642.376343 166.975250,641.885315 166.403793,641.251465
	C166.088669,640.932495 165.965988,640.803955 165.673584,640.473999
	C165.300812,639.995911 165.107193,639.712769 164.780930,639.219482
	C164.637802,639.016541 164.349304,638.618591 164.200256,638.425415
	C164.051224,638.232239 163.743973,637.856140 163.585876,637.672485
	C163.427795,637.488770 163.101074,637.130432 162.934143,636.953613
	C162.767212,636.776733 162.438797,636.416687 162.252777,636.255859
	C162.066772,636.094971 161.698349,635.763245 161.513351,635.594910
	C161.204208,635.312256 161.080063,635.197998 160.581009,634.729492
	C160.079544,634.253174 159.952988,634.130981 159.445404,633.625610
	C158.939301,633.107910 158.814194,632.973267 158.505737,632.629150
	C158.204391,632.275208 158.086380,632.130737 157.805908,631.759033
	C157.447296,631.216370 157.215881,630.929504 156.716461,630.516968
	C156.331192,630.256470 156.178177,630.150391 155.809952,629.868774
	C155.460693,629.567688 155.326630,629.442200 155.007721,629.117676
	C154.711243,628.779663 154.599609,628.640808 154.337128,628.288208
	C154.093506,627.931335 154.000732,627.788208 153.774445,627.433716
	C153.640930,627.222473 153.372055,626.814392 153.229568,626.621460
	C153.087082,626.428467 152.775864,626.069580 152.418762,625.757263
	C151.800339,625.257141 151.537140,625.071960 151.063370,624.752869
	C150.720367,624.515137 150.586090,624.414124 150.259445,624.147949
	C149.818558,623.751282 149.576569,623.513123 149.160095,623.087036
	C148.866135,622.778076 148.753128,622.650391 148.485565,622.320190
	C148.143707,621.839539 147.964279,621.556641 147.659546,621.061768
	C147.524643,620.855957 147.251968,620.450317 147.108887,620.254272
	C146.769424,619.799072 146.560715,619.550171 146.152344,619.144653
	C145.836243,618.865723 145.707489,618.753784 145.380524,618.470520
	C145.047836,618.180115 144.913376,618.060974 144.372955,617.552856
	C143.833084,617.021423 143.699203,616.878967 143.368347,616.506836
	C143.047058,616.111145 142.922760,615.945007 142.640594,615.504883
	C141.175354,613.446716 139.610397,611.915649 137.599380,610.385010
	C137.148239,610.095032 136.978745,609.966125 136.580917,609.620361
	C135.680267,608.680725 135.255478,607.702515 133.965088,607.207886
	C133.366074,606.859375 133.049225,606.623474 132.537796,606.161133
	C132.196091,605.814331 132.067307,605.669434 131.759827,605.299072
	C131.467514,604.921997 131.353851,604.770264 131.072662,604.392456
	C130.696930,603.859375 130.468216,603.568542 130.020996,603.100952
	C129.694550,602.776855 129.565536,602.645935 129.244598,602.317993
	C128.799026,601.859253 128.545868,601.596985 128.092529,601.149536
	C127.758163,600.841919 127.624573,600.718872 127.290497,600.411865
	C126.695282,599.859985 126.335823,599.450012 125.626663,599.031738
	C125.119225,598.645874 124.859459,598.393311 124.461792,597.903809
	C124.119041,597.394897 123.946785,597.093994 123.655785,596.575073
	C123.525940,596.363403 123.232979,595.971436 123.069473,595.791260
	C122.905960,595.611023 122.544029,595.283325 122.351227,595.129639
	C122.158432,594.976013 121.761368,594.674133 121.562134,594.519653
	C121.232079,594.257202 121.101265,594.149231 120.590958,593.695496
	C120.088295,593.229675 119.965126,593.109619 119.657478,592.807617
	C119.348198,592.504944 119.223396,592.384277 118.911621,592.081909
	C118.489693,591.636169 118.214752,591.422363 117.680893,591.099243
	C117.160896,590.730713 116.883873,590.492737 116.427238,590.041504
	C116.115486,589.704346 115.996933,589.564453 115.711632,589.208618
	C115.437462,588.847961 115.330055,588.703308 115.062637,588.343872
	C114.700623,587.839783 114.482895,587.562988 114.060028,587.116821
	C113.752350,586.807983 113.631058,586.683472 113.330849,586.370728
	C112.917740,585.932007 112.690887,585.674927 112.291595,585.232544
	C111.999893,584.928894 111.888382,584.803650 111.623901,584.481445
	C111.282776,584.018311 111.106552,583.744446 110.809799,583.267456
	C110.677322,583.071960 110.409554,582.690002 110.269852,582.505981
	C110.130157,582.321899 109.836922,581.967834 109.525558,581.630493
	C109.214195,581.293213 108.889771,580.965088 108.558846,580.637939
	C108.227921,580.310730 107.890831,579.980774 107.547379,579.645813
	C107.203918,579.310852 106.850800,578.969604 106.486496,578.620728
	C105.996895,578.151794 105.871590,578.031799 105.366341,577.533447
	C104.861748,577.020569 104.737099,576.886047 104.430389,576.539795
	C104.132492,576.179688 104.016663,576.031250 103.746307,575.644897
	C103.204025,574.739990 102.716644,574.161316 101.836845,573.549438
	C101.421944,573.276245 101.256477,573.163208 100.866913,572.844727
	C100.518166,572.477661 100.393517,572.316040 100.125145,571.567749
z"
        />
        <path
          fill="#1E4552"
          opacity="1.000000"
          stroke="none"
          d="
M180.226471,634.368286
	C180.125671,635.476196 180.069092,636.233826 179.982086,637.370056
	C177.533447,639.089722 176.266525,637.149292 174.949051,635.832458
	C167.774719,628.661804 160.582581,621.486938 153.716736,614.037598
	C140.192566,599.364014 125.849998,585.514099 111.854172,571.315186
	C108.258385,567.667236 105.358406,563.299805 100.445023,560.535583
	C99.847237,558.243286 100.020256,556.255676 101.849464,554.336243
	C103.178825,553.709473 104.317436,553.404907 105.810867,553.072754
	C107.206985,554.050171 107.866180,555.282166 108.405571,556.979309
	C109.026848,558.939697 110.025322,560.286316 111.192978,561.465454
	C121.247772,571.618958 131.274597,581.800903 141.797058,591.968506
	C142.785187,592.742371 143.323853,593.485168 144.232300,594.332397
	C144.901398,595.082458 145.279861,595.632751 145.849136,596.453918
	C147.136597,598.110291 148.208435,599.516846 149.776215,600.937683
	C159.203766,610.771179 168.861618,619.912292 177.720016,629.824646
	C178.827469,631.063904 179.979599,632.280212 180.226471,634.368286
z"
        />
        <path
          fill="#B3D5DF"
          opacity="1.000000"
          stroke="none"
          d="
M100.210785,560.514648
	C102.459320,558.726318 103.461952,560.921387 104.604591,562.067200
	C127.331078,584.856506 150.021652,607.681274 172.099792,631.105713
	C174.319107,633.460327 176.884506,635.488647 179.620468,637.708618
	C181.138107,637.908386 182.324539,638.067993 183.835236,638.380249
	C184.311768,638.797180 184.464050,639.061523 184.292084,639.648682
	C178.192825,641.195435 174.530670,638.314941 170.718887,634.263733
	C153.769913,616.249878 136.507019,598.531067 119.333336,580.729065
	C113.116402,574.284729 106.831581,567.905823 100.373245,561.250366
	C100.168755,561.003662 100.280731,560.674011 100.210785,560.514648
z"
        />
        <path
          fill="#35535F"
          opacity="1.000000"
          stroke="none"
          d="
M117.248810,599.699707
	C110.875931,597.646057 106.252953,593.316895 102.216019,588.302307
	C101.183304,587.019470 100.445282,585.532288 98.344131,585.047119
	C98.213921,582.832764 97.709839,581.105835 95.448128,580.188843
	C93.121490,579.245483 91.814690,577.313477 91.205086,574.973938
	C90.721146,573.116821 91.010017,571.373779 92.590240,569.991882
	C94.505547,568.316895 95.929932,569.556946 97.713303,570.845825
	C99.344734,573.170715 98.071503,575.985291 99.978531,578.336426
	C104.189728,584.463074 109.005035,589.585693 114.286736,594.291321
	C115.842522,595.677368 117.884674,596.787109 117.248810,599.699707
z"
        />
        <path
          fill="#3C545F"
          opacity="1.000000"
          stroke="none"
          d="
M109.536545,691.000000
	C109.999893,691.166687 110.000092,691.333313 110.000443,691.750000
	C112.497513,695.838135 112.761307,699.960205 111.281136,704.633240
	C111.001526,704.997925 111.000000,705.000000 111.000153,705.000000
	C106.394783,700.529846 100.598534,697.303467 97.046982,691.342651
	C101.025688,691.000000 105.049545,691.000000 109.536545,691.000000
z"
        />
        <path
          fill="#33616C"
          opacity="1.000000"
          stroke="none"
          d="
M99.336166,579.058594
	C96.885277,576.814819 96.464447,574.387634 97.868477,571.346069
	C98.457504,571.005676 98.893021,571.001648 99.654984,570.989380
	C99.981430,570.981140 100.051186,571.050232 100.086113,571.084717
	C100.270607,571.271118 100.420166,571.423035 100.797028,571.799805
	C101.024345,572.024658 101.002037,572.001892 100.991180,571.990234
	C101.345886,572.396667 101.659943,572.850403 102.030609,573.743713
	C102.178261,576.215210 101.704918,578.036377 99.336166,579.058594
z"
        />
        <path
          fill="#17232A"
          opacity="1.000000"
          stroke="none"
          d="
M512.655334,61.045502
	C513.144226,63.992481 511.166077,64.719604 508.375305,64.989677
	C508.509399,62.671951 510.498993,61.978947 512.655334,61.045502
z"
        />
        <path
          fill="#F0F0EC"
          opacity="1.000000"
          stroke="none"
          d="
M202.981598,264.343323
	C217.373505,262.344666 231.631744,259.915588 246.441925,262.216492
	C251.788574,264.127136 256.957092,265.651001 261.694489,268.938599
	C261.266235,271.453827 261.217346,271.667908 259.224457,271.212769
	C256.435730,270.575867 253.640167,270.700745 250.844315,270.653320
	C243.107742,270.522034 243.107239,270.551331 242.750580,278.330750
	C241.975754,279.617462 241.029724,279.499084 239.742813,279.305359
	C238.737778,279.047882 238.057556,278.971985 237.005936,278.989319
	C232.420792,278.344452 229.203384,275.497986 225.656006,273.547729
	C219.769775,270.311676 213.647217,268.161072 207.139297,266.842041
	C205.549759,266.519897 203.816299,266.384552 202.981598,264.343323
z"
        />
        <path
          fill="#0E505B"
          opacity="1.000000"
          stroke="none"
          d="
M263.909973,247.407379
	C264.009094,243.967209 263.987152,240.940536 263.948120,237.460693
	C260.679657,234.189072 261.110077,230.441589 261.395752,226.289597
	C261.897186,219.884827 260.754913,213.965775 261.105896,208.016495
	C261.222961,206.032242 261.332977,204.053635 262.310669,201.775238
	C262.870270,200.204269 263.293304,199.070587 264.258484,197.700531
	C268.185486,192.583084 273.269897,191.325623 278.947693,191.254776
	C283.895477,191.193024 288.842438,191.105911 293.788147,191.235489
	C295.754150,191.287003 297.794495,191.349640 299.186523,193.638092
	C298.097809,196.522675 295.855438,196.428787 293.690613,196.457001
	C289.210052,196.515442 284.727570,196.402390 280.248322,196.508438
	C271.508789,196.715378 266.118561,201.854462 266.594177,210.571259
	C267.412384,225.565750 267.855408,240.585144 269.297943,255.550629
	C269.738617,260.122498 270.141357,264.791626 269.460480,269.850342
	C269.398010,270.837860 269.303986,271.389160 268.967285,272.295715
	C268.457733,273.103333 268.035706,273.409576 267.127136,273.594086
	C266.237579,273.526703 265.802979,273.299042 265.241089,272.628540
	C264.948486,272.107178 264.859192,271.885406 264.756775,270.957275
	C264.844177,269.770203 264.944672,269.289520 265.056335,268.439545
	C264.850159,266.540253 265.329041,264.922363 264.278748,263.208374
	C263.966888,260.835724 264.121887,258.760498 264.940338,256.382812
	C262.920624,253.514999 263.042603,250.713547 263.909973,247.407379
z"
        />
        <path
          fill="#F4E1D8"
          opacity="1.000000"
          stroke="none"
          d="
M265.836487,271.110840
	C265.916748,271.704803 265.967987,271.997833 266.129150,272.500000
	C267.084625,274.444397 266.963928,276.185974 265.124695,276.933838
	C257.889252,279.875580 250.532562,282.285126 242.304504,279.259186
	C241.892593,278.072113 241.844391,277.235413 241.658371,276.430573
	C240.233810,270.266724 241.171692,268.979614 247.635300,269.043701
	C252.367706,269.090637 257.096649,269.485352 261.881500,269.455505
	C262.667908,269.513733 263.399963,269.841461 264.437836,270.210022
	C264.743683,270.250885 264.816040,270.180725 264.795410,270.134766
	C265.156586,270.251160 265.474915,270.496063 265.836487,271.110840
z"
        />
        <path
          fill="#213642"
          opacity="1.000000"
          stroke="none"
          d="
M263.720520,247.041260
	C265.902527,249.516663 264.656433,252.415985 264.998047,255.559540
	C260.589691,255.885208 262.161133,251.976761 261.020691,249.396759
	C261.810120,248.366898 262.610077,247.727768 263.720520,247.041260
z"
        />
        <path
          fill="#213642"
          opacity="1.000000"
          stroke="none"
          d="
M264.044830,263.340393
	C266.771942,263.763000 266.140137,265.655151 265.308594,267.802124
	C263.607208,266.893890 264.080841,265.249878 264.044830,263.340393
z"
        />
        <path
          fill="#121E24"
          opacity="1.000000"
          stroke="none"
          d="
M255.014984,259.145447
	C256.209778,258.138336 256.714844,258.535339 256.501740,259.676605
	C256.360291,260.434082 255.976440,261.590027 255.034058,261.354309
	C253.830093,261.053223 254.601120,260.092194 255.014984,259.145447
z"
        />
        <path
          fill="#121E24"
          opacity="1.000000"
          stroke="none"
          d="
M227.744080,258.887177
	C226.955139,259.742828 225.755402,261.089111 225.791199,258.194855
	C226.490372,258.259460 226.989273,258.515686 227.744080,258.887177
z"
        />
        <path
          fill="#CFECEF"
          opacity="1.000000"
          stroke="none"
          d="
M624.709839,64.776077
	C628.135315,66.242722 631.286560,67.413567 634.726257,68.778397
	C635.110291,69.226463 635.205994,69.480553 635.077393,70.074020
	C632.955505,71.698112 631.023682,71.061516 629.098572,70.506973
	C608.162781,64.476181 587.983398,66.786827 568.416992,75.977440
	C565.991455,77.116753 564.011169,78.933266 561.459961,80.524551
	C561.051880,80.990990 560.879395,81.164284 560.412231,81.542953
	C558.851013,82.532913 556.937256,82.258629 556.136230,84.371117
	C555.828125,84.839973 555.687561,85.016960 555.291138,85.418442
	C550.415588,87.617020 546.626892,90.710670 543.042542,94.639893
	C538.567078,99.181557 535.172058,104.097992 532.002319,109.624527
	C526.653931,117.367126 522.833740,125.470627 520.154358,134.080139
	C518.590698,139.104462 515.273987,141.403702 510.233978,141.507645
	C489.260956,141.940247 472.704102,151.357758 458.758911,166.880005
	C457.897522,167.849228 457.124084,168.410126 456.521240,169.561493
	C456.224762,170.316315 456.024170,170.733826 455.604614,171.430481
	C449.202545,179.082855 445.789764,187.767731 443.376587,197.285049
	C443.202667,197.795639 443.117737,197.989304 442.869446,198.426727
	C442.446045,198.849243 442.379944,199.015930 442.493256,199.180588
	C442.598969,199.334183 442.684784,199.416321 442.985596,199.649918
	C443.581848,200.483215 443.673920,201.131638 443.393433,202.143936
	C441.646454,204.486801 439.340302,205.545212 437.169342,206.715103
	C420.330994,215.788925 408.423157,229.437469 400.087067,246.434433
	C399.133331,248.379059 398.233246,250.372192 395.585144,251.164444
	C394.494080,250.870468 394.008087,250.327484 393.530334,249.300690
	C393.175629,246.676788 394.626495,244.799622 395.013824,242.312164
	C396.992523,239.374969 398.799805,236.743591 400.054199,233.406860
	C401.078857,231.865158 402.164368,230.767761 402.905029,229.046570
	C403.389221,228.196838 403.745026,227.650940 404.339539,226.875732
	C405.218781,225.813965 406.274292,225.359985 406.854156,224.033569
	C407.346802,223.193176 407.694336,222.641602 408.240295,221.820358
	C408.710175,221.195099 409.012054,220.864746 409.586945,220.328979
	C412.150269,218.693176 413.994934,216.793106 415.751526,214.337143
	C416.173370,213.690094 416.446350,213.345322 416.963440,212.778839
	C417.497437,212.244217 417.824738,211.966858 418.422119,211.522430
	C419.192108,210.996277 419.739899,210.710617 420.528931,210.241913
	C421.469025,209.688400 422.175903,209.326904 422.964172,208.556030
	C423.477631,208.072739 423.781769,207.805908 424.326233,207.344452
	C424.886383,206.888748 425.225464,206.653442 425.841858,206.274521
	C427.009583,205.647171 428.035187,205.428314 429.087250,204.584198
	C429.854401,204.086655 430.387024,203.791458 431.157288,203.300751
	C431.734802,202.873917 432.077362,202.645828 432.688965,202.258301
	C433.488159,201.753586 434.074341,201.504608 434.878571,200.976135
	C437.471527,199.454590 437.508179,197.147797 437.681213,194.552429
	C437.758881,194.007156 437.801788,193.793152 437.931488,193.268372
	C438.203979,192.357803 438.506073,191.800430 438.750092,190.896729
	C438.900970,190.409821 438.965057,190.216400 439.131531,189.735458
	C439.468872,188.882767 439.661896,188.302994 439.923981,187.416397
	C440.082001,186.917770 440.151276,186.719162 440.344543,186.229065
	C440.957520,184.765686 441.603790,183.647507 441.772614,182.027069
	C441.918915,181.511993 441.988861,181.310165 442.189728,180.818085
	C442.703033,179.797440 443.287170,179.184250 443.658478,178.105270
	C443.837372,177.636902 443.919403,177.454453 444.143951,177.010773
	C444.391022,176.583054 444.495575,176.416519 444.926758,175.767151
	C445.465912,174.962204 445.675140,174.637939 446.020935,174.055206
	C446.255798,173.627243 446.351257,173.455887 446.598022,173.030792
	C447.091461,172.292099 447.425507,171.802673 447.713440,170.942657
	C447.903625,170.469345 447.990723,170.283615 448.231750,169.830261
	C448.501373,169.392838 448.617096,169.223038 449.116760,168.577072
	C449.633972,167.950806 449.767273,167.800690 450.303833,167.218750
	C451.251373,166.255936 451.822235,165.750168 452.418427,164.897629
	C452.651642,164.505081 452.740631,164.346725 452.973450,163.960266
	C453.309631,163.434647 453.501251,163.136627 453.818542,162.607727
	C454.030518,162.224167 454.116150,162.071030 454.334137,161.689911
	C454.650146,161.159012 454.843506,160.862457 455.202332,160.352356
	C455.589111,159.853577 455.839508,159.593201 456.312195,159.169250
	C456.661713,158.878525 456.805573,158.767166 457.170654,158.496094
	C457.541077,158.231873 457.690186,158.127411 458.066040,157.871429
	C458.784424,157.467148 459.196075,157.119415 459.678162,156.439545
	C459.941833,156.049301 460.056152,155.897629 460.359833,155.529846
	C460.821228,155.040497 461.106232,154.781616 461.632935,154.357849
	C462.171753,153.948730 462.488129,153.727234 463.040527,153.337616
	C463.428406,153.048950 463.584229,152.934052 463.976318,152.649200
	C464.372437,152.368530 464.532288,152.257812 464.936523,151.987610
	C465.692352,151.548691 466.185608,151.241440 466.869080,150.689819
	C467.255859,150.386963 467.413574,150.268951 467.815369,149.983826
	C468.766602,149.449768 469.397949,148.994125 470.027344,148.072754
	C470.374725,147.700256 470.523956,147.559235 470.919769,147.227951
	C471.342255,146.923141 471.518097,146.808594 471.970215,146.539337
	C472.436493,146.292023 472.626556,146.199402 473.108093,145.982239
	C473.978668,145.633499 474.600403,145.520752 475.392273,145.025085
	C475.813934,144.752090 475.982819,144.645950 476.410339,144.396332
	C477.013092,144.075745 477.360321,143.905029 477.960968,143.608643
	C478.377533,143.390091 478.543976,143.304001 478.959351,143.090576
	C479.373901,142.879486 479.539490,142.795715 479.953247,142.587830
	C480.366577,142.381393 480.531738,142.299057 480.944244,142.092926
	C481.356537,141.886627 481.521515,141.804169 481.935120,141.598236
	C482.350677,141.392456 482.517548,141.310150 482.936310,141.104858
	C483.529205,140.818893 483.872986,140.661835 484.484131,140.388336
	C484.932617,140.194077 485.116333,140.121933 485.584778,139.957611
	C486.645874,139.631073 487.475739,139.592163 488.517883,139.106262
	C489.031494,138.921661 489.242371,138.853790 489.782928,138.712982
	C492.413208,138.253876 494.636414,137.569061 497.212158,136.800598
	C502.175720,136.562622 506.673218,135.266632 511.617096,135.221176
	C512.682129,134.765640 513.429626,134.412857 513.833740,133.240326
	C514.033997,132.744003 514.117493,132.543777 514.346680,132.047363
	C516.250427,127.792244 517.368286,123.570908 519.505920,119.415039
	C519.650818,118.890396 519.708801,118.682648 519.876953,118.175758
	C520.211670,117.298195 520.525330,116.756638 520.795715,115.867447
	C520.965576,115.386925 521.038574,115.196037 521.234985,114.723595
	C521.539307,114.070877 521.735718,113.708084 522.105591,113.083115
	C522.386169,112.642342 522.506531,112.470879 522.828552,112.059395
	C523.763245,111.062157 524.280579,110.172951 524.752869,108.897530
	C524.958862,108.425003 525.052734,108.242554 525.309814,107.803207
	C525.810669,107.063057 526.241577,106.650925 526.663330,105.882736
	C526.873352,105.460068 526.963440,105.295273 527.199585,104.893394
	C527.448120,104.504761 527.550659,104.353210 527.967896,103.765289
	C528.487915,103.043968 528.695068,102.760330 529.050293,102.265167
	C529.299866,101.916618 529.403381,101.781151 529.672424,101.453995
	C530.059753,101.015808 530.291687,100.779160 530.683350,100.361305
	C530.833069,100.170265 531.127380,99.787033 531.266357,99.590294
	C531.582642,99.123566 531.754883,98.850410 532.041016,98.357178
	C532.160034,98.140251 532.418579,97.713318 532.561035,97.506683
	C532.806335,97.167450 532.909119,97.034851 533.341431,96.522537
	C533.784241,96.020531 533.897522,95.898247 534.355469,95.419556
	C534.930115,94.832306 535.163452,94.605019 535.564331,94.204819
	C535.728271,94.028351 536.050354,93.672844 536.204224,93.490311
	C536.556396,93.056229 536.745117,92.797676 537.051025,92.320168
	C537.268311,91.967834 537.358887,91.827454 537.600220,91.482422
	C537.861755,91.146805 537.972473,91.015839 538.262207,90.694702
	C538.566345,90.379791 538.691467,90.255051 539.010437,89.945564
	C539.482788,89.525909 539.743042,89.272003 540.184937,88.797302
	C540.520447,88.459732 540.659485,88.327698 541.020203,88.009659
	C541.732300,87.511581 542.174377,87.144417 542.699097,86.445541
	C542.984802,86.057426 543.106995,85.909172 543.424683,85.552155
	C543.885681,85.080391 544.161438,84.828621 544.637207,84.395660
	C544.957703,84.083755 545.089233,83.965073 545.426636,83.684372
	C545.911682,83.329216 546.196411,83.144661 546.690613,82.835831
	C546.892883,82.700478 547.287537,82.427368 547.477539,82.284355
	C547.667542,82.141342 548.033936,81.839607 548.390259,81.521881
	C548.746521,81.204147 549.109619,80.885986 549.298828,80.732506
	C549.487976,80.579025 549.882935,80.287308 550.087341,80.146675
	C550.430603,79.912994 550.569519,79.819946 550.919312,79.583488
	C551.271667,79.339951 551.413025,79.239822 551.769043,78.983017
	C552.130493,78.719788 552.277344,78.613220 552.888000,78.192566
	C553.514648,77.779770 553.677368,77.681084 554.087219,77.435745
	C554.830261,76.998482 555.340515,76.734154 556.070435,76.285385
	C556.437195,75.984344 556.580139,75.860107 556.943298,75.560486
	C557.311951,75.273163 557.460388,75.161209 557.833008,74.888687
	C558.208130,74.626526 558.359009,74.524948 558.737793,74.278259
	C559.117493,74.039192 559.269409,73.945244 559.648193,73.710358
	C560.025146,73.473282 560.175171,73.377136 560.549927,73.132629
	C560.926880,72.885658 561.079041,72.787056 561.468628,72.547043
	C561.872498,72.319763 562.039001,72.233902 562.465088,72.032829
	C563.261047,71.695068 563.819885,71.529182 564.624512,71.145004
	C565.101257,70.933548 565.297363,70.852692 565.802979,70.677734
	C571.026855,68.221512 576.262390,66.686699 581.624756,64.527359
	C582.146912,64.282051 582.359192,64.190178 582.900085,63.985260
	C584.345642,63.612156 585.504883,63.499985 586.858521,62.787048
	C590.947388,61.111481 595.503113,63.085087 599.284119,59.879700
	C603.168030,59.141159 606.571106,60.347340 610.445557,60.457123
	C613.828918,61.879978 617.231750,61.171616 620.635986,62.479092
	C622.476868,62.644714 623.584717,63.337738 624.709839,64.776077
z"
        />
        <path
          fill="#15313E"
          opacity="1.000000"
          stroke="none"
          d="
M521.948059,43.387886
	C524.196289,41.891628 524.230591,39.200115 525.963074,37.370316
	C529.214050,32.215401 534.705200,30.577564 539.488647,27.353264
	C541.014709,26.691761 542.191406,26.944605 543.698181,27.173111
	C544.031982,27.037996 544.519836,27.037157 544.763550,27.027874
	C548.107422,24.265408 551.963379,24.968109 555.397217,25.369253
	C563.022217,26.259987 570.600891,27.726257 576.630615,33.902176
	C580.388977,34.826107 582.083130,37.587032 583.955017,40.061466
	C585.090393,41.562237 586.140381,42.993000 588.583862,43.102371
	C589.486877,43.560196 589.998230,44.021412 590.711548,44.770638
	C590.944763,45.024334 590.983032,45.023731 590.975464,45.041321
	C592.340881,48.786324 596.339111,51.625542 594.570557,56.640106
	C591.483276,57.667995 590.790039,55.195560 589.613037,53.595787
	C584.892090,47.179317 579.687012,41.313034 572.845520,37.003578
	C570.283386,35.389721 567.646606,34.202759 564.366211,33.468853
	C553.639221,30.020679 543.814880,30.748648 534.637878,37.030220
	C527.299255,42.053394 523.128357,48.608681 523.413696,57.854881
	C523.561035,62.630856 524.166138,67.456879 523.113586,72.626144
	C522.683167,73.509300 522.291077,73.895828 521.371643,74.218872
	C517.493042,72.164001 518.537476,68.903786 518.893677,65.443802
	C518.923645,57.806271 519.545166,50.672611 521.948059,43.387886
z"
        />
        <path
          fill="#17272E"
          opacity="1.000000"
          stroke="none"
          d="
M593.730957,57.501785
	C594.255737,54.475670 594.024231,51.948761 591.633972,50.122734
	C590.018127,48.888309 590.237000,47.349422 590.924866,45.395859
	C596.815735,43.047653 600.285706,47.433510 603.702759,50.538315
	C605.496948,52.168518 606.867065,52.963940 609.083740,53.032249
	C613.047424,53.154388 616.384949,54.197853 618.522400,58.152779
	C619.237183,59.475300 620.291870,61.652824 622.988586,61.331924
	C624.410522,61.162727 624.932556,62.871288 624.970947,64.691193
	C623.846619,64.883835 622.709290,64.695793 621.294800,64.294846
	C618.221680,61.206379 614.540222,62.243584 610.992676,62.176266
	C607.049072,62.776192 603.520081,61.640011 599.531128,60.807560
	C598.407898,60.935234 597.701233,60.970394 596.641846,60.849472
	C594.986572,60.148277 593.514099,59.684761 593.730957,57.501785
z"
        />
        <path
          fill="#101920"
          opacity="1.000000"
          stroke="none"
          d="
M577.006226,33.662498
	C576.406494,34.715965 575.486511,35.288715 574.864929,34.626244
	C567.093689,26.343826 556.965759,26.877218 546.928223,27.027771
	C546.598938,27.032711 546.269470,27.027029 545.473755,27.022446
	C544.573914,20.705023 545.256104,19.892683 551.560547,21.221630
	C555.381470,22.027065 559.374207,21.725033 562.770142,25.336639
	C565.031006,27.741133 569.734924,28.044296 573.442688,28.827091
	C576.129700,29.394390 577.161438,30.698027 577.006226,33.662498
z"
        />
        <path
          fill="#1B1E22"
          opacity="1.000000"
          stroke="none"
          d="
M677.746155,71.995438
	C679.426697,71.695038 680.858215,71.397079 682.655884,71.055199
	C681.687744,75.075218 677.985413,76.930061 674.871216,79.073654
	C672.146729,80.948944 670.968628,85.377609 666.235229,84.271645
	C665.340576,80.179680 667.457275,77.764397 670.373840,75.891098
	C672.324219,74.638435 674.593262,73.924049 676.732056,72.254150
	C676.999329,72.002907 677.497192,71.997894 677.746155,71.995438
z"
        />
        <path
          fill="#121E24"
          opacity="1.000000"
          stroke="none"
          d="
M539.997314,27.567028
	C536.164490,31.859129 531.082947,33.851112 526.380981,36.983910
	C525.529480,36.770920 525.022339,36.499584 524.253906,36.108910
	C523.995972,35.242764 523.999207,34.495960 524.003662,33.375629
	C527.083252,33.091732 528.785278,31.294106 529.953979,28.351412
	C531.421753,27.673046 532.841003,27.342075 534.627686,27.000786
	C536.684753,25.672182 538.351562,25.391199 539.997314,27.567028
z"
        />
        <path
          fill="#101920"
          opacity="1.000000"
          stroke="none"
          d="
M540.381592,28.030163
	C538.857910,26.643538 537.246399,27.093683 535.381836,26.993343
	C535.057251,24.889374 535.002563,22.921246 532.047302,22.980265
	C530.939270,23.002394 529.335144,23.075315 529.421753,21.505360
	C529.524292,19.647240 531.309875,20.147678 532.540039,20.005135
	C537.887939,19.385445 540.985229,22.503689 543.760620,26.821239
	C543.157288,28.104988 541.931885,28.004877 540.381592,28.030163
z"
        />
        <path
          fill="#9FA2B2"
          opacity="1.000000"
          stroke="none"
          d="
M634.990845,70.903061
	C634.966248,70.276299 634.980103,70.015686 635.004333,69.363724
	C642.427063,71.323227 648.577576,75.788391 654.758972,80.840622
	C655.320190,81.467781 655.500061,81.917320 655.370789,82.768433
	C654.493286,83.636543 653.578552,83.856804 652.911804,83.337105
	C647.224670,78.904434 640.641479,75.805267 634.990845,70.903061
z"
        />
        <path
          fill="#181C20"
          opacity="1.000000"
          stroke="none"
          d="
M676.635498,72.050720
	C676.879150,73.067513 676.334045,73.818703 675.364929,74.283943
	C671.266479,76.251495 667.995422,79.043854 666.146545,83.717422
	C665.549377,84.030998 665.117798,84.036453 664.363342,84.053711
	C663.043884,78.866364 665.561279,75.960884 670.261597,74.397232
	C672.294861,73.720840 674.270081,72.870094 676.635498,72.050720
z"
        />
        <path
          fill="#688FA1"
          opacity="1.000000"
          stroke="none"
          d="
M654.857300,83.420433
	C654.994995,82.680275 654.994751,82.220139 654.998779,81.415260
	C655.811584,81.707527 656.620056,82.344536 657.685913,83.160568
	C658.134583,83.396187 658.325867,83.452782 658.601196,83.796577
	C659.908264,85.341553 661.131348,86.599335 662.677612,87.917908
	C663.859131,89.145142 664.717407,90.311577 665.786499,91.713654
	C666.580566,92.488403 666.807617,93.162186 666.638611,94.291519
	C661.997803,96.328796 661.343872,91.733124 658.729370,89.706795
	C657.857422,88.859543 657.337708,88.152527 656.579712,87.234436
	C656.116150,86.664032 655.864258,86.329414 655.443054,85.730560
	C654.937378,84.927292 654.732178,84.343391 654.857300,83.420433
z"
        />
        <path
          fill="#15191C"
          opacity="1.000000"
          stroke="none"
          d="
M673.875977,64.294556
	C674.851624,64.169579 675.703308,64.341904 676.778809,64.756744
	C676.771545,66.529640 675.866882,67.181969 674.388306,66.801483
	C673.063293,66.460495 673.393860,65.495941 673.875977,64.294556
z"
        />
        <path
          fill="#15313E"
          opacity="1.000000"
          stroke="none"
          d="
M658.941101,83.897705
	C658.420959,84.045174 658.214722,83.914803 658.004883,83.516129
	C658.147095,82.596489 658.181030,81.366867 659.210449,81.711327
	C660.158752,82.028656 659.768066,83.028038 658.941101,83.897705
z"
        />
        <path
          fill="#17272E"
          opacity="1.000000"
          stroke="none"
          d="
M590.908691,44.707542
	C590.095642,44.914711 589.381531,44.613186 589.017700,43.422081
	C589.875671,43.114834 590.617554,43.382366 590.908691,44.707542
z"
        />
        <path
          fill="#101920"
          opacity="1.000000"
          stroke="none"
          d="
M544.336792,18.408642
	C543.547791,18.535007 543.211853,18.122505 543.661804,17.696018
	C544.109680,17.271509 544.507629,17.624512 544.336792,18.408642
z"
        />
        <path
          fill="#15313E"
          opacity="1.000000"
          stroke="none"
          d="
M661.514526,81.524643
	C661.482361,81.540009 661.546753,81.509270 661.514526,81.524643
z"
        />
        <path
          fill="#342A31"
          opacity="1.000000"
          stroke="none"
          d="
M833.352539,790.948730
	C830.689026,780.549377 833.960999,770.281006 834.000000,759.455688
	C836.566162,754.981506 840.487122,752.373535 844.113708,749.497498
	C846.622864,747.507446 849.098694,747.380493 850.302612,751.052856
	C851.182983,753.738220 852.587219,752.925537 853.941895,751.663818
	C858.554321,747.367798 862.181641,742.092834 867.271545,738.256836
	C868.154053,737.591675 867.879272,736.419983 867.868286,735.436829
	C867.850769,733.872986 868.144043,732.221680 869.341248,731.327881
	C876.130493,726.259705 879.802612,718.060425 887.250183,713.668274
	C891.627136,711.087036 894.925781,707.379333 897.874634,703.288696
	C900.105347,700.194275 902.343811,697.054932 905.829590,695.159363
	C907.215515,694.405762 907.925842,693.163818 908.524780,691.721741
	C912.396423,682.399780 918.656860,674.671875 925.186401,667.148071
	C926.820190,665.265564 928.719788,663.640259 931.136047,662.724854
	C932.168945,662.333618 933.599182,662.157593 933.621460,660.660461
	C933.644958,659.075623 932.222778,658.544739 931.130066,657.825439
	C928.150635,655.864014 924.110352,655.323608 922.537598,651.058838
	C922.574341,650.439453 922.645386,650.194214 922.984375,649.668335
	C924.427307,648.635803 925.716736,648.584961 927.418396,648.850098
	C934.271545,652.178223 942.142151,651.294312 946.638794,646.648804
	C947.447571,646.270020 947.950317,646.191895 948.849426,646.280518
	C952.563782,648.349670 956.209595,646.886414 959.208557,646.174133
	C966.645325,644.408081 974.199707,643.175171 981.588806,641.221252
	C982.395264,641.008057 983.089233,640.556946 984.040894,639.811890
	C984.790710,639.367249 985.253723,639.201233 986.094727,639.070312
	C989.079346,639.513306 991.727112,640.107361 994.245239,637.751831
	C994.908997,637.362061 995.318909,637.208130 996.059265,637.021973
	C997.213623,636.814941 998.045898,636.655029 999.125244,636.162598
	C1000.292297,635.782532 1001.175110,635.742004 1002.398193,635.812622
	C1005.220459,636.570862 1007.421936,635.783447 1009.856995,634.419434
	C1010.632874,634.195984 1011.094360,634.166199 1011.907166,634.255249
	C1018.945862,635.092834 1025.007446,633.369507 1030.919434,629.554077
	C1035.629517,630.904053 1036.563721,634.243469 1032.974609,636.782593
	C1027.892212,640.378113 1023.409729,645.712402 1015.809082,643.263611
	C1013.037415,642.370483 1011.473938,644.288757 1011.590454,647.238586
	C1011.643188,648.573792 1011.996460,649.870605 1012.158691,651.578979
	C1011.743408,656.180786 1009.140259,658.484741 1005.278320,659.508545
	C999.951355,660.920532 995.765259,663.253235 993.025879,668.644653
	C990.686768,673.248108 985.744690,675.204590 980.503601,675.756165
	C976.212341,676.207825 971.953918,676.480957 969.375732,681.003418
	C968.186157,683.090027 965.467712,683.351501 963.109558,683.551147
	C960.121216,683.804260 957.119751,683.762756 954.129822,683.670410
	C950.756775,683.566162 947.755615,683.882812 946.737610,687.863831
	C946.617310,688.334106 946.268982,688.897217 945.859131,689.040833
	C940.268311,690.999451 937.508972,696.391479 933.589600,700.113647
	C929.820312,703.693176 925.014954,706.707397 922.492554,711.630859
	C921.601746,713.369446 920.174072,714.661011 918.319458,714.919067
	C911.769775,715.830566 910.382874,721.506287 908.448059,726.141113
	C907.234741,729.047607 906.139038,731.123962 903.032715,731.833557
	C902.393311,731.979675 902.952026,731.522522 902.584412,731.990906
	C899.298279,736.177307 894.174255,738.845093 891.427673,743.073608
	C887.200989,749.580994 881.548889,753.682617 874.783081,756.326111
	C870.485535,758.005127 866.817932,759.431274 866.197205,764.653687
	C865.993835,766.364868 864.672607,767.711426 863.006165,768.226196
	C858.214600,769.706116 854.328857,772.241760 851.895996,776.777039
	C851.514709,777.487915 850.591797,777.771912 849.379150,777.975342
	C841.296326,778.007202 840.848572,778.312073 837.927490,785.782104
	C837.075439,787.961121 836.246582,790.130981 833.352539,790.948730
z"
        />
        <path
          fill="#2E282E"
          opacity="1.000000"
          stroke="none"
          d="
M849.179443,778.305786
	C852.374573,772.465759 856.112000,767.489197 863.285461,766.569580
	C864.998413,766.349915 864.883545,764.324402 865.021179,762.812866
	C865.370667,758.973694 866.897583,756.644226 871.243713,755.754944
	C875.229431,754.939514 879.877441,754.310120 882.006287,749.654541
	C882.489197,748.598389 883.716309,748.114929 884.945129,748.006714
	C886.168579,747.898926 887.578918,747.480896 887.888428,746.417297
	C889.279114,741.638794 891.019836,737.456787 896.985718,737.088074
	C897.856018,737.034302 897.923828,735.464722 898.001587,734.462463
	C898.236450,731.433716 899.947937,730.047119 902.909485,729.998108
	C905.374573,729.957275 907.278076,729.523865 906.286194,726.209229
	C905.757935,724.443787 906.812744,722.986572 908.229431,721.941467
	C909.651611,720.892334 910.625610,719.473816 910.807556,717.819580
	C911.133850,714.852600 912.731323,713.948608 915.500244,713.941345
	C918.801392,713.932678 921.269165,712.566711 921.836914,708.942627
	C921.888367,708.614502 921.904175,708.043213 922.052185,708.002136
	C927.583740,706.467468 928.845032,700.228455 933.446350,697.692444
	C933.590149,697.613220 933.849426,697.622559 933.901611,697.515686
	C936.630981,691.933655 944.070068,690.629333 945.956421,684.423584
	C946.620605,682.238586 947.838989,682.002380 949.594177,682.004822
	C953.419495,682.010132 957.244812,681.987183 961.070068,682.004028
	C965.020935,682.021362 968.857727,681.922974 969.947205,676.908752
	C970.366394,674.979126 972.021240,675.211548 973.510803,674.963135
	C978.789673,674.082764 984.372070,674.456665 989.023804,670.909973
	C990.391846,669.866882 991.941345,668.939575 992.249878,667.312256
	C993.487915,660.781921 998.909607,659.364685 1003.943726,658.215820
	C1007.488892,657.406738 1009.588074,655.825195 1010.949951,652.354736
	C1012.479065,650.244324 1014.485168,650.398926 1016.873779,650.539673
	C1019.199463,651.142334 1021.099915,651.997314 1023.491638,650.962646
	C1026.715820,650.166260 1029.868530,650.754639 1032.742676,648.745850
	C1035.031250,646.814941 1032.798096,644.885803 1033.342407,642.761597
	C1034.435303,640.124878 1035.616821,637.926697 1037.531982,636.248108
	C1038.864868,635.080017 1040.444214,634.590759 1042.157959,635.293030
	C1043.822754,635.975220 1044.136719,637.518616 1043.983032,639.043030
	C1043.711548,641.734802 1044.818481,643.846497 1046.287842,645.944580
	C1048.012207,648.406982 1049.259766,651.146790 1050.185303,654.000793
	C1051.204468,657.143860 1052.949585,659.473755 1055.961548,661.119507
	C1059.947876,663.297363 1061.749634,666.594299 1062.421875,671.493835
	C1063.260010,677.602295 1067.558105,682.339294 1073.066162,686.128174
	C1075.124268,687.970581 1077.199219,689.346924 1077.578125,692.380493
	C1075.789917,696.196045 1074.126709,699.558777 1073.607422,703.487732
	C1073.048828,707.713928 1070.863037,708.953918 1066.050415,708.696655
	C1062.168823,708.489197 1059.637939,706.995789 1059.961426,702.599792
	C1060.010742,701.931274 1059.817261,701.281311 1059.253662,700.338989
	C1058.951172,698.871521 1058.535400,697.938904 1057.280762,697.152100
	C1056.765503,693.218750 1057.326294,688.670044 1054.300537,686.301819
	C1051.878662,684.406372 1049.084839,682.222473 1046.029541,681.335022
	C1043.463623,680.589661 1041.774170,679.518005 1041.178223,677.031616
	C1040.514404,674.261169 1038.510986,673.560059 1036.250244,674.065918
	C1029.089844,675.667847 1021.521240,675.706360 1015.118469,680.273560
	C1012.594482,682.073792 1010.999268,683.733887 1010.742859,686.919373
	C1010.518860,689.700806 1009.610046,692.427124 1009.000671,695.588867
	C1007.165222,696.000000 1005.332886,696.000122 1003.500549,695.999939
	C995.288269,695.999084 993.562866,694.405457 993.043335,685.992371
	C992.909302,683.821960 992.823975,681.704102 992.058411,679.625732
	C991.471375,678.031982 990.563293,677.095337 988.821594,677.043518
	C987.214417,676.995789 985.930725,677.357910 985.162354,679.005310
	C982.688721,684.308472 977.591797,687.289856 973.918274,691.537415
	C972.705566,692.939575 971.037537,693.063904 969.331787,692.998291
	C962.833435,692.748169 956.430542,693.086914 950.093689,694.895691
	C947.940857,695.510315 946.432007,696.015198 947.360413,698.352478
	C948.383057,700.926941 947.147949,702.399902 945.525879,703.998352
	C940.661499,708.791931 935.856995,713.646240 931.022522,718.470276
	C930.433777,719.057800 929.755676,720.140564 929.203003,720.089172
	C923.466858,719.556458 920.977600,725.144409 916.411194,726.702698
	C914.857544,727.232910 913.543457,728.037109 913.351318,729.632019
	C913.008545,732.478333 911.581421,734.180969 908.877747,734.931152
	C907.260315,735.379944 906.799011,736.560669 907.220276,738.024963
	C908.675537,743.083313 905.870483,744.942444 901.583679,744.926331
	C894.058105,744.898132 890.925537,751.674255 885.526733,754.901611
	C884.833069,755.316223 885.127808,757.391113 884.972107,758.697754
	C884.606384,761.767822 883.355652,764.074768 879.878723,764.626038
	C878.236267,763.095886 883.521545,758.439941 877.365234,758.975159
	C874.932495,759.186584 873.527649,759.377869 874.907837,762.513123
	C877.225220,767.776978 873.629883,772.964111 867.858643,772.969910
	C862.728394,772.975037 857.557068,772.142639 854.818726,778.408020
	C853.879272,780.557617 851.279724,780.692139 849.179443,778.305786
z"
        />
        <path
          fill="#3E505A"
          opacity="1.000000"
          stroke="none"
          d="
M97.464760,513.001587
	C89.119453,512.947449 82.278740,507.440704 80.980911,500.031555
	C80.731354,498.606873 80.229370,497.534119 78.376030,497.066284
	C74.172432,495.707001 73.775703,493.869324 76.193390,490.460510
	C80.070709,484.993683 86.139221,483.470856 91.716148,481.272552
	C93.989403,480.376465 95.932297,479.749481 97.955856,478.082947
	C101.813179,474.906250 107.091072,475.466034 111.779480,475.125854
	C119.850151,474.540314 127.998169,475.593048 135.993057,473.176910
	C138.748810,472.344116 141.797852,473.063568 144.734390,474.208038
	C148.549500,475.694916 152.336166,476.032135 155.134613,471.593811
	C156.577759,469.305054 158.765472,466.119202 161.727997,470.845459
	C163.041931,472.941681 165.296646,471.808411 167.140564,470.933746
	C170.982986,469.111084 174.592575,466.448669 179.297409,467.907501
	C180.184830,468.182648 181.456528,467.962128 181.610550,466.661591
	C181.771164,465.305481 180.624680,464.995148 179.626236,465.070068
	C177.008652,465.266418 175.222733,463.956940 173.629349,462.129395
	C172.531387,460.870056 171.148987,459.585815 171.489182,457.837952
	C171.916733,455.641418 173.992905,456.127136 175.584274,455.982452
	C178.644302,455.704254 179.464294,454.417145 177.538452,451.794220
	C177.005142,451.067902 177.042938,450.243011 177.758392,449.229462
	C179.715561,449.000214 181.431107,449.001617 183.575439,449.003448
	C186.400162,448.878845 188.528473,449.956360 190.761276,450.543396
	C192.462158,450.990570 193.955093,451.875885 193.700012,453.949921
	C193.434967,456.105164 191.839706,456.932617 189.857605,457.185699
	C189.196457,457.270111 190.021133,457.219971 189.361206,457.194977
	C187.573013,457.127228 185.242233,456.510925 185.071014,459.123962
	C184.891144,461.868774 186.991486,462.671204 189.819427,462.556824
	C190.428833,462.588806 190.674484,462.607544 191.287964,462.691223
	C197.399445,463.734497 203.136917,464.583832 209.024673,461.723755
	C209.801620,461.285004 210.281128,461.120056 211.154663,460.961670
	C213.990768,460.922821 216.418793,460.632629 219.197937,460.013123
	C222.180191,460.365692 224.917450,460.368073 227.110931,462.835632
	C226.801071,465.657318 224.718933,466.642609 222.854431,466.306458
	C217.341110,465.312408 212.430511,467.333252 207.452988,468.853790
	C201.634384,470.631195 195.471725,471.100128 189.515381,473.826172
	C184.264801,476.127502 178.970291,475.733337 173.455872,476.437592
	C171.534775,476.039612 170.072021,476.125183 168.340332,477.148346
	C160.798416,478.473846 153.512817,478.264801 145.921265,479.242584
	C142.059814,480.269379 138.086136,477.988800 134.655334,481.282043
	C132.337494,482.658661 130.187729,482.671478 127.638382,482.153748
	C121.907509,479.258484 116.489838,480.205048 110.718796,481.928619
	C104.775169,482.555817 99.187729,483.158325 93.743912,484.590485
	C92.026421,485.042389 90.274605,485.474915 88.814140,486.771057
	C84.804123,490.329987 84.868515,493.871155 89.223007,497.009277
	C92.646309,499.476257 96.802795,500.307068 100.911064,502.166992
	C103.815544,504.980347 106.488899,507.367676 110.161484,508.525757
	C112.071815,509.128143 114.615112,510.016876 112.381912,513.033569
	C107.311714,512.998718 102.621384,512.999695 97.464760,513.001587
z"
        />
        <path
          fill="#2E515F"
          opacity="1.000000"
          stroke="none"
          d="
M190.255234,463.696655
	C188.148987,463.855316 186.079285,464.533417 184.675888,462.433655
	C183.647919,460.895691 182.355377,459.410736 183.475555,457.522736
	C184.459579,455.864258 186.043884,455.060028 188.092133,455.543457
	C188.880585,455.729523 189.717422,455.920593 190.506775,455.844727
	C191.759613,455.724274 192.710068,455.021942 192.717789,453.644409
	C192.725021,452.352844 191.691559,452.174805 190.673111,452.016998
	C188.407623,451.666077 186.104874,451.387299 184.187225,449.315125
	C186.762878,443.197845 183.871964,438.047302 182.070297,432.391815
	C182.190338,431.578613 182.432144,431.183319 183.095917,430.751587
	C185.412521,431.103424 186.242554,432.793854 187.209320,434.263580
	C189.161423,437.231293 191.758347,439.314484 195.467072,440.476013
	C204.556366,440.575073 206.238449,439.174347 207.642349,430.542755
	C207.828888,429.395721 208.287933,428.360748 208.773346,426.940277
	C209.615158,425.301758 210.295456,423.970154 211.228424,422.824371
	C212.969666,420.686066 214.865936,419.581360 217.499405,421.727936
	C220.432602,424.118835 222.619186,422.971497 224.196442,419.921417
	C225.053894,418.263306 225.317642,415.824158 228.492813,417.105164
	C230.459106,425.197968 229.940506,433.273834 231.770660,441.442841
	C232.570251,447.013062 237.495514,446.892609 241.038269,449.063049
	C243.556412,451.839661 246.343079,452.710175 249.996140,451.878235
	C251.543762,451.585236 252.753387,451.598999 254.332275,451.798798
	C255.426834,452.050751 256.121979,452.328918 257.174011,452.725006
	C259.306549,453.778137 261.295074,454.247498 263.235992,455.653625
	C263.846893,456.631805 263.941589,457.363220 263.670502,458.467560
	C262.358490,460.258820 260.851135,459.821289 259.423126,459.441559
	C252.529526,457.608582 245.457016,456.857330 238.420624,455.873535
	C232.846085,455.094116 227.898987,453.261627 225.287613,446.977295
	C225.992523,453.103607 225.992523,453.103607 217.527283,452.415131
	C217.365738,456.082062 222.190125,455.923157 222.516815,459.361206
	C221.785477,461.077606 220.538712,460.392303 219.266510,460.719543
	C217.034012,462.460785 214.700226,462.349518 212.161240,462.078125
	C211.455170,460.964691 211.012924,460.077209 209.970612,459.236603
	C208.208755,457.430267 206.345078,457.342468 204.325089,457.696259
	C200.966980,458.284393 197.569229,458.590576 193.846039,459.071289
	C192.594452,459.323669 191.669144,459.398987 190.744827,459.455566
	C188.526688,459.591309 189.638763,460.550751 190.402695,461.709839
	C190.643280,462.495636 190.607300,462.956879 190.255234,463.696655
z"
        />
        <path
          fill="#6494A3"
          opacity="1.000000"
          stroke="none"
          d="
M195.290482,441.590698
	C189.385452,440.402802 186.549301,435.791748 183.285919,431.152740
	C181.786972,430.664551 181.690048,429.422150 180.811523,428.436829
	C180.220169,427.977661 179.957733,427.647003 179.650940,426.963745
	C179.449020,425.814392 179.628754,424.935974 179.164398,423.812683
	C179.085388,423.500092 179.220154,423.302399 179.169922,423.193817
	C178.599945,422.668549 178.518845,422.058014 178.237671,421.206512
	C178.072540,420.950104 178.199493,420.911163 178.226639,420.848907
	C177.852661,418.440582 177.451523,416.094482 177.094025,413.343658
	C177.348557,412.808685 177.559433,412.678497 178.141327,412.568909
	C181.221329,414.144440 180.737167,417.197968 181.557892,419.551575
	C183.031464,423.777405 184.921616,427.714905 187.694153,431.637695
	C189.817261,435.264252 194.045609,436.346588 195.726196,440.266083
	C195.655457,440.850281 195.579163,441.076416 195.290482,441.590698
z"
        />
        <path
          fill="#273E4A"
          opacity="1.000000"
          stroke="none"
          d="
M180.878265,427.965088
	C181.659103,428.551453 182.218872,429.451660 182.903992,430.644714
	C182.835617,431.209503 182.641876,431.481476 182.234314,431.886230
	C181.694916,431.221893 181.369370,430.424744 180.999634,429.307068
	C180.856049,428.750671 180.756622,428.514832 180.878265,427.965088
z"
        />
        <path
          fill="#2E515F"
          opacity="1.000000"
          stroke="none"
          d="
M181.062973,427.496674
	C181.075882,427.873871 181.052429,428.096527 180.992218,428.652863
	C180.087036,428.877808 179.372742,428.560822 179.062592,427.318512
	C179.259094,426.820801 179.509003,426.630005 180.161606,426.684692
	C180.790161,426.987061 180.944260,427.124390 181.062973,427.496674
z"
        />
        <path
          fill="#273E4A"
          opacity="1.000000"
          stroke="none"
          d="
M180.493164,426.559448
	C180.305084,426.943756 180.045868,426.957367 179.397919,426.991272
	C179.024826,426.136230 179.040436,425.260864 179.070724,423.942810
	C179.530930,424.396301 179.976471,425.292511 180.493164,426.559448
z"
        />
        <path
          fill="#273E4A"
          opacity="1.000000"
          stroke="none"
          d="
M178.088516,421.263489
	C178.403336,421.447540 178.734131,421.945007 179.092316,422.763855
	C178.781296,422.582458 178.442902,422.079681 178.088516,421.263489
z"
        />
        <path
          fill="#B2E7F4"
          opacity="1.000000"
          stroke="none"
          d="
M176.207565,367.002533
	C175.381699,365.303589 174.231323,365.971283 172.900879,365.968964
	C166.470673,362.817841 159.722198,361.797058 153.254700,359.893341
	C149.454086,358.774567 145.575592,357.828979 142.309357,354.766846
	C142.914978,352.505554 144.615540,352.930664 145.864456,353.459900
	C154.396271,357.075287 163.640259,356.623901 171.980774,358.212738
	C162.755127,356.744781 160.382095,354.445312 159.358551,344.637848
	C158.994049,341.145142 157.226974,339.475769 154.100525,339.093536
	C148.739548,338.438110 145.905243,335.291931 144.481445,330.328461
	C143.796707,327.941406 142.800354,325.631592 141.754150,323.360657
	C140.850632,321.399414 140.714508,319.377747 142.336975,317.784515
	C145.730209,314.452454 143.818161,313.082153 140.431885,312.101044
	C139.156540,311.731537 137.855408,311.392578 136.677917,310.122345
	C139.150101,306.491547 142.976547,305.588165 146.749741,305.868408
	C150.844666,306.172577 152.069000,304.326080 152.157150,300.878967
	C152.198135,299.277100 152.806427,298.113678 154.415405,297.079407
	C157.123322,295.857971 158.816971,293.597626 161.879059,292.956848
	C164.522400,292.178040 166.618851,292.598816 168.956894,293.939117
	C172.397125,297.808960 174.782806,301.725006 173.500977,306.806458
	C173.046799,308.606934 173.896027,309.674164 175.204651,310.554749
	C179.027328,313.127075 179.929749,316.733673 179.063446,321.034424
	C178.768066,322.500732 178.728928,324.004395 178.709473,325.501923
	C178.615143,332.760101 179.818100,333.428986 185.700073,335.334869
	C193.063416,337.720764 199.963425,341.016632 204.647491,348.663818
	C207.241699,352.899109 211.205521,358.006226 217.601044,359.394104
	C221.639221,360.270477 226.071991,361.950836 226.643814,367.392670
	C226.746811,368.372803 227.673431,368.814056 228.521713,368.448181
	C231.966873,366.962189 236.316208,367.161255 238.727371,363.776337
	C241.258499,360.223022 244.643158,359.235077 248.678864,359.310547
	C251.169968,359.357117 253.670563,359.194336 256.154388,359.337158
	C259.906281,359.552887 262.093994,358.237823 264.627716,354.998230
	C267.367279,351.495483 270.417206,347.418365 275.439087,346.082275
	C277.522675,351.816956 275.742493,357.219208 275.359131,362.600800
	C274.757324,371.048706 274.877625,379.501343 274.575684,388.343689
	C273.955139,391.644287 271.904694,391.072296 270.071625,390.403595
	C268.209473,389.724274 266.466980,388.935364 264.292938,390.491455
	C262.868286,390.781281 261.982208,390.501312 261.205048,389.196594
	C260.407349,388.782684 259.808075,388.686249 259.117493,388.293701
	C256.469025,384.596039 252.173767,385.824493 248.614014,384.261322
	C246.719269,385.274872 245.449341,383.887085 243.704773,383.575867
	C243.621277,383.117706 243.662079,382.958344 243.512268,382.896973
	C243.487289,382.886780 243.362991,382.881409 243.017578,383.124329
	C242.735779,383.581329 242.526703,383.723694 242.127319,383.924561
	C241.827011,384.173218 241.668198,384.291687 241.255280,384.256104
	C239.913071,382.777802 238.469894,382.574585 236.511475,382.752167
	C233.617706,383.385956 231.137833,382.912598 228.797867,382.113770
	C212.613617,376.588684 195.910599,373.005280 178.990311,368.645081
	C177.777863,368.313354 177.043472,367.949371 176.207565,367.002533
z"
        />
        <path
          fill="#A9DFED"
          opacity="1.000000"
          stroke="none"
          d="
M136.198029,309.891235
	C138.460358,310.168854 140.396545,310.670197 142.362869,310.860962
	C144.688934,311.086639 145.972809,312.364136 146.611084,314.442322
	C147.369522,316.911743 145.652603,317.537781 143.834778,318.262421
	C142.166611,318.927368 141.610565,320.360199 142.632858,321.908020
	C144.941864,325.404022 145.914322,329.414520 147.255280,333.284515
	C148.223572,336.079041 150.413971,336.669189 153.056854,337.108154
	C160.597580,338.360657 160.745560,338.662292 160.998749,346.276550
	C161.196304,352.217712 165.284348,356.495758 171.233139,357.003052
	C173.900711,357.230469 177.075165,356.862640 176.661209,361.077942
	C165.579590,357.207275 153.478226,359.548401 142.524063,354.387909
	C142.105026,354.467346 142.105316,354.443817 142.093842,354.440125
	C142.082352,354.436401 142.292633,354.049805 142.107941,354.169403
	C138.684769,352.114960 137.129562,348.639618 134.852692,345.397278
	C134.072678,345.087250 133.673843,344.761902 133.248474,343.940735
	C133.120987,343.568665 133.256058,343.273865 133.165558,343.139313
	C133.564026,340.598694 130.712311,339.184448 131.160202,336.394958
	C129.827835,334.414642 129.835495,332.455963 129.859436,330.266724
	C130.140625,329.213562 130.142715,328.464081 130.026184,327.355133
	C129.955750,326.241272 130.003906,325.486877 129.964737,324.362732
	C129.787216,323.823639 129.697006,323.654266 129.764832,323.244385
	C130.821381,318.163300 131.881042,313.396545 136.198029,309.891235
z"
        />
        <path
          fill="#CFECEF"
          opacity="1.000000"
          stroke="none"
          d="
M169.376495,295.079834
	C166.937210,294.628235 164.874069,294.299957 162.409698,293.968079
	C161.622879,293.561462 161.466660,293.083466 161.740845,292.191528
	C162.882080,291.548645 163.822281,291.244720 164.985657,290.737061
	C165.917847,290.507202 166.626846,290.481110 167.661865,290.311798
	C168.132980,289.994293 168.278061,289.819977 168.698486,289.845367
	C168.973862,290.045044 168.707748,290.212250 168.769989,290.356537
	C172.809296,291.489197 177.174835,290.760681 181.095078,293.498627
	C182.382278,296.783844 181.020691,297.796173 178.249268,297.264465
	C175.389740,296.715851 172.446869,296.452637 169.376495,295.079834
z"
        />
        <path
          fill="#BAEEFA"
          opacity="1.000000"
          stroke="none"
          d="
M161.584366,291.851868
	C161.958450,292.322144 161.975006,292.791718 162.000000,293.612915
	C159.753204,294.754944 158.038834,296.759979 155.106995,296.891357
	C156.074966,294.169189 158.689575,293.059814 161.584366,291.851868
z"
        />
        <path
          fill="#0D1920"
          opacity="1.000000"
          stroke="none"
          d="
M129.781845,323.094849
	C129.970001,323.171204 130.017151,323.338562 129.970856,323.749451
	C129.519547,323.788422 129.440689,323.519379 129.781845,323.094849
z"
        />
        <path
          fill="#674541"
          opacity="1.000000"
          stroke="none"
          d="
M897.095215,294.649963
	C896.155396,295.124237 895.118591,295.206421 895.602234,296.968201
	C894.697510,297.842010 893.921082,297.857208 892.781250,297.382233
	C891.835327,296.585785 891.370178,295.909698 890.878723,294.758667
	C889.739563,289.971008 890.555542,285.515442 890.286621,280.669373
	C890.336487,279.965790 890.399292,279.687653 890.703064,279.054871
	C891.726196,278.204926 892.545044,278.147552 893.764648,278.716309
	C895.985046,283.347260 893.136292,287.731232 894.082397,292.583557
	C894.377502,294.133118 895.288452,294.135803 896.478699,294.228821
	C896.970276,294.313995 897.134399,294.391449 897.095215,294.649963
z"
        />
        <path
          fill="#0A0D13"
          opacity="1.000000"
          stroke="none"
          d="
M897.323608,294.857788
	C896.891968,294.831085 896.859375,294.531311 896.832764,294.383942
	C898.545532,292.599487 900.290466,290.715179 901.961670,294.684509
	C900.591553,294.974579 899.173401,294.929535 897.323608,294.857788
z"
        />
        <path
          fill="#0A0D13"
          opacity="1.000000"
          stroke="none"
          d="
M907.878540,296.781555
	C906.689392,296.706543 905.486450,296.266663 904.014221,295.650024
	C905.721558,293.153168 906.969177,294.016174 907.878540,296.781555
z"
        />
        <path
          fill="#0A0D13"
          opacity="1.000000"
          stroke="none"
          d="
M908.888367,297.831604
	C908.709106,297.888092 908.390686,297.726807 907.985107,297.306396
	C908.181763,297.236115 908.465454,297.424988 908.888367,297.831604
z"
        />
        <path
          fill="#1A2026"
          opacity="1.000000"
          stroke="none"
          d="
M1080.335449,465.031738
	C1072.650757,463.861847 1065.427734,461.796936 1057.448608,461.995789
	C1055.849609,462.303131 1054.706177,462.273529 1053.626953,461.767181
	C1051.356079,460.701874 1048.705078,459.449860 1048.979980,456.657257
	C1049.264038,453.771881 1052.157959,453.883850 1054.450684,453.736298
	C1055.251099,453.684753 1056.108032,453.879944 1056.798218,452.846436
	C1056.742554,451.679413 1056.428833,450.863129 1056.217773,449.665558
	C1056.201416,447.185547 1055.291870,445.939606 1053.055176,445.616943
	C1050.113892,445.192566 1047.254150,444.282013 1044.207520,444.546814
	C1041.898926,444.747437 1039.542847,444.735291 1037.295410,444.083557
	C1034.739380,443.342407 1031.792969,442.590149 1031.575317,439.381317
	C1031.373413,436.404846 1034.540771,436.386444 1036.299438,435.065735
	C1041.625366,431.065979 1046.543823,431.382111 1051.555420,435.559326
	C1052.815918,436.609955 1054.588501,437.295105 1056.214600,436.366516
	C1059.953491,434.231293 1064.194702,434.422272 1068.566162,433.438965
	C1069.987793,433.287933 1070.936279,433.603577 1072.128662,434.340118
	C1073.494995,437.242645 1076.099731,436.775574 1078.198120,437.512360
	C1087.727417,440.858154 1096.301392,438.584015 1104.382080,433.315247
	C1106.114502,432.185638 1107.906006,431.500854 1109.912842,431.360687
	C1113.371216,431.119171 1115.453247,429.473328 1115.862793,425.563232
	C1118.051636,420.495300 1122.074585,416.732483 1122.916138,411.027954
	C1122.909058,409.246918 1123.245239,407.866486 1123.629395,406.127014
	C1123.842163,405.303436 1124.058716,404.871338 1124.630615,404.244446
	C1128.478394,405.871155 1130.551758,409.063995 1131.906494,412.604736
	C1132.588013,414.385925 1132.870361,416.390289 1135.004639,416.530518
	C1137.276489,416.679779 1137.908569,414.924805 1138.088257,412.852264
	C1138.312622,410.263245 1139.739014,408.318787 1142.452148,408.248688
	C1145.129150,408.179565 1146.040527,410.029846 1146.072998,412.581482
	C1146.106201,415.189514 1148.137695,415.804352 1150.224243,415.929932
	C1152.710327,416.079590 1155.210938,415.996826 1157.705322,415.998291
	C1167.121826,416.003815 1170.264648,418.840088 1172.031860,428.051025
	C1172.947632,432.824524 1173.558228,437.753632 1176.297363,442.047211
	C1177.584351,444.064331 1177.408203,446.358612 1175.439819,447.889618
	C1173.755127,449.199921 1172.805542,450.797272 1172.203125,452.732605
	C1171.905762,453.687714 1171.443726,455.136841 1170.402954,454.707062
	C1166.194336,452.968903 1164.473022,456.294037 1162.065430,458.384949
	C1159.179077,460.891754 1155.892822,460.526215 1152.755859,459.102234
	C1149.349121,457.555817 1146.683716,457.022400 1145.778320,461.805969
	C1145.613892,462.675354 1144.781616,463.998535 1144.109253,464.096741
	C1139.988281,464.698547 1136.453369,468.719788 1132.570679,467.785583
	C1123.751831,465.663757 1115.237427,468.800354 1106.533447,468.157898
	C1100.570435,467.717743 1094.667114,467.245117 1088.410889,466.012512
	C1085.606689,465.295837 1083.056885,465.824982 1080.335449,465.031738
z"
        />
        <path
          fill="#151E25"
          opacity="1.000000"
          stroke="none"
          d="
M1068.871216,433.700317
	C1068.807373,434.939514 1068.534546,435.595062 1067.428589,435.616882
	C1063.671631,435.691040 1059.926025,435.540588 1056.763672,438.374420
	C1055.206787,439.769531 1053.250854,438.745209 1051.662720,437.771484
	C1049.683350,436.557770 1047.817627,435.156891 1045.824097,433.968597
	C1044.545532,433.206390 1042.687622,432.251373 1041.740356,433.668915
	C1040.029419,436.229218 1037.562500,436.622437 1034.983276,437.215271
	C1033.914551,437.460876 1032.492310,437.795929 1032.536499,439.265656
	C1032.574219,440.526489 1033.657715,441.276947 1034.758057,441.710999
	C1036.768066,442.503876 1038.846191,443.369324 1041.037842,442.966614
	C1045.832031,442.085571 1050.394897,443.754578 1055.078613,444.044708
	C1059.217529,444.301117 1058.035522,447.072968 1057.176758,449.775208
	C1054.647339,451.961456 1052.371704,450.174286 1050.305786,449.763885
	C1046.943848,449.096069 1043.534302,448.378448 1039.922607,447.230042
	C1038.586792,447.774536 1037.215210,446.744537 1036.390869,448.206635
	C1036.738892,449.111877 1037.043091,448.657410 1037.572266,448.173889
	C1038.162231,447.658661 1038.585083,447.509369 1039.409912,447.373016
	C1045.948364,448.657471 1052.046387,450.346649 1058.606201,451.749603
	C1059.107544,454.423370 1057.908081,455.911835 1055.458862,455.520660
	C1053.420532,455.195099 1050.577393,454.479492 1050.157715,456.407104
	C1049.626831,458.845337 1052.643188,459.796692 1054.697388,460.764343
	C1055.292603,461.044708 1055.920654,461.255737 1056.766846,461.746521
	C1057.353638,465.218811 1054.620605,464.932190 1052.616089,465.761261
	C1061.631348,467.929199 1070.272583,471.384521 1079.688354,469.850494
	C1088.879517,468.353027 1090.894043,470.045441 1090.957031,479.747345
	C1089.543945,481.332001 1088.135254,480.574371 1086.701538,480.241547
	C1064.351929,475.053925 1041.935303,470.143311 1019.804260,464.054565
	C1017.414307,463.397034 1014.891357,463.296326 1012.206055,462.147919
	C1010.701660,461.324036 1009.615234,460.389221 1008.227966,460.553711
	C1003.332336,461.134094 998.882568,458.959351 993.788818,458.347412
	C990.767578,457.370117 988.234314,456.168365 985.194458,455.254578
	C984.652954,454.945221 984.452637,454.798553 984.027588,454.360352
	C984.529785,450.716919 987.329407,451.802765 989.434814,451.487213
	C990.752686,451.289703 992.389465,451.445343 992.756531,449.882690
	C993.204468,447.976105 991.400696,447.446899 990.113953,446.801453
	C988.296448,445.889740 986.294739,445.655426 984.287781,445.646271
	C981.386230,445.633026 978.701904,444.999908 976.279663,443.319458
	C974.422729,442.031281 972.322693,441.596497 970.095703,442.007172
	C966.430420,442.683105 962.878906,442.786255 959.383423,441.042267
	C957.524170,440.114624 955.840027,440.954041 954.272766,442.786255
	C952.200012,444.110107 950.300781,444.613251 948.246582,444.115601
	C935.031616,440.914246 921.865295,437.514740 908.631775,434.388977
	C907.632324,434.152893 906.760742,433.722626 905.837158,432.626953
	C905.084167,428.826935 907.559387,428.710480 909.687805,429.109314
	C913.468872,429.817749 916.474182,428.626251 919.414062,426.445190
	C922.658936,424.037933 926.196777,423.698944 930.271362,424.477509
	C944.179321,427.135193 958.255310,426.825653 972.324890,426.112823
	C974.061951,426.024780 975.520203,425.260193 976.951782,424.410767
	C979.198181,423.077850 981.712891,422.230408 984.089050,423.328339
	C987.374451,424.846344 990.162170,422.376129 993.686401,423.084106
	C1004.579468,425.272430 1015.066833,429.014069 1026.075562,430.764069
	C1019.063416,430.307343 1013.174622,426.798309 1007.016174,424.044098
	C1005.387329,423.315643 1003.429138,422.825317 1003.728882,420.034515
	C1006.690857,417.657623 1009.608398,418.818329 1012.420471,419.456512
	C1015.284363,420.106476 1018.076904,421.073212 1021.406982,421.554413
	C1025.071045,421.993317 1028.244751,422.785309 1031.837158,423.556458
	C1038.864746,425.045837 1045.410156,426.866119 1052.559814,427.766205
	C1056.269409,429.540527 1059.978638,429.807404 1063.476685,430.824860
	C1065.369385,431.375427 1067.461304,431.490479 1068.871216,433.700317
z"
        />
        <path
          fill="#241A19"
          opacity="1.000000"
          stroke="none"
          d="
M1079.639038,333.905426
	C1084.354248,329.583221 1086.195801,329.919189 1089.310547,335.162903
	C1089.701294,335.820587 1090.384644,336.523865 1091.079956,336.747620
	C1098.684814,339.194855 1101.321045,345.724365 1103.989746,352.627930
	C1099.727661,358.163391 1102.991211,363.620087 1104.010254,368.638947
	C1104.697510,372.024323 1109.221191,373.145355 1112.650391,374.952576
	C1113.000366,375.748474 1113.000732,376.495697 1113.002930,377.616577
	C1113.376465,378.400696 1113.533813,378.881042 1113.311768,379.788208
	C1112.558228,380.476257 1111.741455,380.826599 1111.310791,380.360413
	C1106.614624,375.277405 1099.883545,374.308380 1093.700684,371.396790
	C1091.947510,368.500427 1092.103027,365.525879 1091.522461,362.320190
	C1091.175781,360.413971 1090.083618,359.210266 1089.690796,357.321411
	C1089.488281,355.954315 1089.305176,354.964325 1089.461182,353.596741
	C1090.212158,348.600128 1087.059570,344.779175 1086.753540,340.293823
	C1086.623047,338.382690 1084.050537,338.832153 1082.204102,338.151215
	C1080.300781,337.259338 1079.435059,336.055878 1079.639038,333.905426
z"
        />
        <path
          fill="#8B9698"
          opacity="1.000000"
          stroke="none"
          d="
M1118.586670,385.818695
	C1119.389038,388.231689 1120.278564,390.223846 1121.260864,392.580383
	C1115.416016,397.728271 1108.668823,400.130829 1100.680542,399.739868
	C1100.141724,399.521790 1099.935425,399.425720 1099.451660,399.149750
	C1098.697021,398.576477 1098.306885,398.086853 1097.273193,397.420898
	C1096.645142,396.928314 1096.542847,396.742401 1096.389771,396.235291
	C1096.374634,395.463104 1096.493164,395.033691 1096.863037,394.323975
	C1102.088013,388.793976 1109.228760,387.390228 1115.659668,384.008118
	C1116.537109,384.079865 1116.993042,384.271149 1117.653809,384.855164
	C1117.906250,385.134216 1117.969849,385.046875 1117.920410,385.068695
	C1118.138550,385.192932 1118.406128,385.295380 1118.586670,385.818695
z"
        />
        <path
          fill="#5D696E"
          opacity="1.000000"
          stroke="none"
          d="
M1098.590210,398.292053
	C1104.870850,399.687164 1110.186890,396.979462 1115.557129,394.846405
	C1117.206787,394.191162 1118.631958,392.972076 1120.942871,392.950867
	C1121.609009,392.866943 1121.854492,392.890900 1122.277832,393.045837
	C1124.970825,394.746338 1124.069946,397.153717 1123.676758,399.767944
	C1118.564697,404.114899 1112.616333,405.910004 1106.332886,407.744110
	C1104.238892,407.547485 1103.632324,405.980957 1102.371094,404.729309
	C1101.968506,404.437744 1101.794922,404.340271 1101.378174,404.073975
	C1100.985840,403.778381 1100.836670,403.651611 1100.487061,403.313477
	C1100.043823,402.803741 1099.812744,402.496613 1099.423828,401.950928
	C1099.146973,401.563354 1099.039551,401.405518 1098.785889,400.999878
	C1098.561157,400.576172 1098.482544,400.400330 1098.334961,399.943115
	C1098.242432,399.272522 1098.292969,398.893250 1098.590210,398.292053
z"
        />
        <path
          fill="#465962"
          opacity="1.000000"
          stroke="none"
          d="
M1011.660095,460.758118
	C1036.452148,466.985962 1060.883423,472.983276 1085.316284,478.973969
	C1086.920166,479.367218 1088.536743,479.709381 1090.544678,480.126404
	C1091.368774,480.456604 1091.795410,480.735657 1092.479980,481.044800
	C1091.344482,482.707428 1089.755127,481.783142 1088.199097,481.436096
	C1082.769409,480.225159 1077.336426,479.028351 1071.182129,477.917480
	C1069.428955,478.410370 1068.368286,478.298553 1066.989258,478.151184
	C1065.618530,477.905640 1064.589966,477.596802 1063.213501,477.336090
	C1062.677734,477.213226 1062.464111,477.156006 1061.934448,476.992554
	C1060.364624,476.416199 1059.181885,475.754303 1057.499512,475.459717
	C1055.638672,475.428497 1054.315308,474.623810 1052.461304,474.397644
	C1039.437378,471.185974 1026.867554,467.755188 1014.198120,464.715576
	C1012.433716,464.292328 1010.640869,463.933746 1009.016479,462.281342
	C1009.027527,460.392273 1010.061523,460.276459 1011.660095,460.758118
z"
        />
        <path
          fill="#3A4D56"
          opacity="1.000000"
          stroke="none"
          d="
M1104.890625,407.108765
	C1110.919678,404.557281 1116.705444,402.267761 1122.885498,399.970062
	C1123.646484,399.639191 1124.071411,399.538055 1124.736450,399.920410
	C1124.917725,401.225525 1124.909424,402.239410 1124.908447,403.633911
	C1124.884521,404.428101 1124.853271,404.841614 1124.796143,405.565247
	C1122.162476,408.541229 1118.335693,408.869598 1115.327759,410.795807
	C1113.680054,411.851105 1111.940552,412.750916 1110.308472,414.423645
	C1109.876953,414.829865 1109.688721,414.977203 1109.166992,415.261719
	C1108.592651,415.394257 1108.351929,415.389679 1107.782715,415.234070
	C1106.042114,413.727997 1104.997314,412.162323 1104.604248,409.842346
	C1104.472168,408.777954 1104.398193,408.067413 1104.890625,407.108765
z"
        />
        <path
          fill="#15191C"
          opacity="1.000000"
          stroke="none"
          d="
M1117.938721,384.787048
	C1117.559448,385.062317 1117.212524,384.990417 1116.599854,384.927490
	C1114.745239,384.330200 1113.183228,383.691559 1112.533447,381.506348
	C1112.495972,380.916290 1112.531616,380.679230 1112.799316,380.167542
	C1113.023438,379.470184 1113.015381,379.047485 1113.006104,378.307495
	C1119.216187,378.481995 1119.794800,379.233429 1117.938721,384.787048
z"
        />
        <path
          fill="#151E25"
          opacity="1.000000"
          stroke="none"
          d="
M1080.164795,465.289917
	C1082.618164,463.424286 1085.163330,463.380798 1087.805908,465.679382
	C1085.322632,468.084625 1082.771851,467.837006 1080.164795,465.289917
z"
        />
        <path
          fill="#15191C"
          opacity="1.000000"
          stroke="none"
          d="
M1124.948242,399.777832
	C1124.561401,400.132080 1124.196655,400.052521 1123.555908,399.967407
	C1123.053833,397.900299 1122.827881,395.838745 1122.533691,393.426147
	C1125.996338,394.087494 1124.741821,397.012115 1124.948242,399.777832
z"
        />
        <path
          fill="#15191C"
          opacity="1.000000"
          stroke="none"
          d="
M1118.706055,386.109985
	C1118.248535,386.057800 1118.074829,385.819641 1117.924805,385.307800
	C1118.317383,385.271088 1119.396484,384.711151 1118.706055,386.109985
z"
        />
        <path
          fill="#BAEEFA"
          opacity="1.000000"
          stroke="none"
          d="
M169.049637,295.318909
	C172.274750,293.040863 175.234558,295.267273 178.312454,295.731964
	C179.580292,295.923370 180.907562,297.027069 180.953079,294.288513
	C180.400040,292.334564 181.390457,291.340302 182.684250,290.133606
	C184.534378,283.495728 185.587540,276.900360 190.011658,271.561554
	C193.278976,267.618683 197.075790,264.620178 202.692352,263.910217
	C208.289261,265.996552 213.951370,266.251984 219.167725,268.431335
	C225.525955,271.087738 231.099915,275.135284 237.922729,278.108948
	C238.911835,278.630798 239.367813,278.908722 240.162476,279.137939
	C240.914261,279.485992 241.393524,279.769714 242.180176,280.073242
	C242.792770,280.348022 243.126190,280.542572 243.686432,280.934631
	C244.072861,281.247406 244.220718,281.382782 244.568390,281.745850
	C245.014572,282.299408 245.249039,282.633728 245.650940,283.234741
	C245.944351,283.672852 246.058853,283.852356 246.321899,284.317230
	C247.518051,286.322418 249.219864,287.468597 250.601135,289.343536
	C251.623398,290.941803 252.287140,292.374573 253.597275,293.788879
	C256.777771,299.671844 258.945526,305.688324 262.729370,311.161377
	C263.712280,311.527740 264.323792,311.866058 265.159912,312.511597
	C266.277679,315.066193 266.576385,317.891205 270.447876,316.346222
	C271.789520,315.810791 272.620026,317.478821 273.178802,318.737823
	C274.740845,322.257263 277.717682,321.617157 280.415741,320.865601
	C282.318146,320.335693 283.183350,318.357391 283.260468,316.539917
	C283.355438,314.301392 281.213898,314.894135 279.898163,314.587524
	C278.005157,314.146454 275.760681,314.314117 275.478790,311.229340
	C275.901550,310.160187 276.449524,309.645203 277.467163,309.078735
	C278.840057,308.620850 279.866608,308.646667 281.260376,308.926331
	C282.923553,309.613739 284.003204,310.702362 285.886108,310.856934
	C287.333374,311.461273 288.462677,311.910095 289.667297,312.958221
	C290.651611,314.488159 292.191956,315.160828 292.514984,317.101624
	C291.143280,319.923553 289.724335,322.268372 290.105713,325.356140
	C290.391144,327.666992 288.806122,329.114990 286.594330,329.492371
	C282.943756,330.115265 281.795410,332.571930 281.398376,335.835052
	C280.900940,339.923065 279.738403,343.693970 275.357941,345.904694
	C273.242859,346.225861 273.476318,347.747070 272.888672,348.876526
	C270.465668,353.533722 264.209534,355.049225 262.954895,360.758728
	C258.633240,360.839569 254.311279,360.996277 249.989990,360.980011
	C245.613083,360.963501 241.110138,360.408905 239.569366,366.176270
	C239.299515,367.186401 226.782318,371.184784 225.759232,370.907410
	C224.876282,370.667969 224.007248,370.144165 224.289490,369.241760
	C226.866821,361.001160 219.087860,362.069214 215.529755,360.452301
	C212.763962,359.195465 209.985046,359.087433 209.193909,355.533234
	C208.938934,354.387665 208.088608,353.343658 206.917099,353.036377
	C203.438797,352.124023 202.331329,349.851776 201.399841,346.436401
	C200.207657,342.065186 194.708740,342.221100 191.593430,339.496216
	C189.045685,337.267761 185.496765,336.041016 181.884308,336.024353
	C178.637894,336.009399 177.353531,333.937958 177.160294,331.295441
	C176.906784,327.828918 176.533432,324.147888 177.402267,320.875214
	C178.682983,316.051025 177.221313,313.158173 173.018951,311.157166
	C170.766144,310.084534 169.305832,309.095978 171.712067,306.436676
	C174.601593,303.243286 170.731583,300.748169 170.214203,297.876007
	C170.074875,297.102539 169.483047,296.410614 169.049637,295.318909
z"
        />
        <path
          fill="#92BFCE"
          opacity="1.000000"
          stroke="none"
          d="
M182.604797,289.896240
	C183.301468,291.467682 182.657303,292.584961 181.163269,293.688995
	C177.096344,292.660797 172.775604,293.303162 168.980652,290.815308
	C169.309616,290.332611 169.787033,290.164368 170.642319,289.905823
	C171.370697,289.579926 171.733261,289.364655 172.409515,289.266296
	C174.563080,290.146515 176.383438,289.332581 178.608765,289.153198
	C180.088318,289.331573 181.177460,289.610565 182.604797,289.896240
z"
        />
        <path
          fill="#121E24"
          opacity="1.000000"
          stroke="none"
          d="
M173.474854,257.341248
	C174.958038,258.108582 176.466858,258.708191 175.401779,260.348938
	C174.557678,261.649261 173.039612,262.111755 171.500931,261.861481
	C169.832275,261.590057 168.618546,260.513123 167.350800,258.938721
	C169.108093,257.545258 170.938904,256.882019 173.474854,257.341248
z"
        />
        <path
          fill="#121E24"
          opacity="1.000000"
          stroke="none"
          d="
M193.488388,262.178589
	C193.253998,260.913666 193.044189,259.891479 193.280365,258.985596
	C193.663773,257.515076 194.891144,257.006287 196.341583,257.109436
	C197.394028,257.184296 198.282318,257.673584 198.642487,258.685608
	C199.240067,260.364807 197.842865,260.966400 196.820297,261.741974
	C195.892746,262.445435 194.970169,263.169342 193.488388,262.178589
z"
        />
        <path
          fill="#17272E"
          opacity="1.000000"
          stroke="none"
          d="
M209.753815,260.484192
	C208.977936,258.471008 209.458740,257.278595 211.505341,257.532959
	C212.470413,257.652924 213.500076,258.574005 213.391556,259.508698
	C213.122360,261.827576 211.336685,260.789032 209.753815,260.484192
z"
        />
        <path
          fill="#17272E"
          opacity="1.000000"
          stroke="none"
          d="
M247.001053,259.998779
	C246.138184,259.826447 244.992889,260.301819 244.620209,259.024017
	C244.567429,258.843048 244.856186,258.403107 245.070190,258.331268
	C246.015732,258.013824 246.557419,258.529053 246.938232,259.686035
	C246.998047,260.002441 247.000000,260.000000 247.001053,259.998779
z"
        />
        <path
          fill="#1D2229"
          opacity="1.000000"
          stroke="none"
          d="
M1046.772583,592.754883
	C1051.324219,593.907837 1055.562134,595.245483 1060.479370,594.945496
	C1061.349731,594.603638 1061.845215,594.455750 1062.762451,594.665344
	C1064.377930,598.163818 1061.839233,598.839966 1059.651733,599.566467
	C1053.541382,601.595764 1047.559082,599.762390 1042.213501,597.447327
	C1036.602417,595.017151 1030.796509,593.631958 1024.991089,592.825256
	C1016.606873,591.660278 1009.604248,587.806641 1002.597534,583.699707
	C999.151428,581.679871 995.747375,579.719360 991.899841,578.470764
	C985.981201,576.550110 985.211426,574.133850 988.705200,568.894287
	C989.632385,567.503906 989.170898,566.830872 988.207275,566.013184
	C987.065369,565.044250 985.895691,564.100342 985.329346,562.200073
	C985.375977,557.348877 985.795288,552.938416 987.218262,548.287964
	C988.120361,547.166199 989.029053,546.649231 990.018066,546.612854
	C994.333557,546.454041 998.543945,546.206116 1000.623047,541.435852
	C1000.760864,541.119568 1001.538208,541.174072 1002.412476,541.232788
	C1007.860535,544.959534 1013.659241,547.191345 1019.178406,549.986450
	C1023.141846,551.993713 1026.507080,553.107910 1030.711182,551.799866
	C1032.293335,551.307617 1033.985840,551.396484 1035.709473,552.756104
	C1037.642212,556.444946 1041.189453,554.956482 1043.786621,555.923950
	C1044.969482,556.364502 1046.990967,555.914917 1047.273560,557.253784
	C1048.299072,562.115356 1051.966309,561.151794 1055.065186,560.942871
	C1061.383545,560.517090 1067.057739,562.421082 1072.761475,564.799744
	C1076.827271,566.495239 1081.316650,566.926880 1085.412354,568.812683
	C1089.394287,570.646240 1092.851685,573.438721 1097.159424,574.700195
	C1099.989380,575.529053 1097.465576,578.682617 1098.957764,580.657715
	C1083.428345,583.491089 1068.080688,587.456421 1052.221436,588.073120
	C1050.764526,588.129822 1049.324585,588.622131 1047.468140,588.973938
	C1046.050293,590.281799 1044.497314,590.208801 1043.063354,590.604919
	C1044.126465,591.540833 1045.625732,591.491272 1046.772583,592.754883
z"
        />
        <path
          fill="#122430"
          opacity="1.000000"
          stroke="none"
          d="
M1035.995728,552.605347
	C1031.282959,552.514893 1025.857422,556.370178 1022.543152,554.302551
	C1018.754883,551.939209 1014.931946,550.464294 1011.021667,548.656311
	C1008.262024,547.380371 1005.048767,545.603271 1003.049072,542.356079
	C1002.271667,537.631226 1004.364929,534.352844 1007.968262,534.207825
	C1016.635193,533.858826 1016.635193,533.858887 1017.494629,524.790039
	C1019.346436,522.192017 1021.435425,521.595215 1024.207764,522.330750
	C1029.615967,523.765625 1035.182007,524.574829 1040.901611,526.432251
	C1051.603516,528.756897 1061.480225,532.626282 1072.242432,534.726501
	C1074.587036,535.656250 1076.642334,536.358826 1077.938477,538.707214
	C1077.965698,541.090942 1078.327881,543.128601 1074.917236,542.288208
	C1073.171021,541.857971 1071.103027,542.734863 1068.801758,543.030396
	C1068.194214,543.006104 1067.965210,542.972534 1067.464233,542.724731
	C1066.070068,539.786743 1063.966553,540.830688 1061.689453,541.242371
	C1057.985107,541.678406 1054.582886,541.696350 1051.494995,540.363037
	C1046.153076,538.056458 1040.395142,538.798828 1034.554199,537.562622
	C1033.958618,537.413086 1033.721069,537.359985 1033.144897,537.184692
	C1030.943115,536.549744 1029.812378,534.238464 1027.276367,535.116089
	C1026.360840,537.212952 1024.942749,538.460266 1023.034790,539.695679
	C1021.843018,540.193542 1020.932007,540.231384 1019.686768,539.922974
	C1018.891968,539.816589 1018.716125,538.652832 1017.767578,539.662354
	C1015.000610,541.126465 1011.977234,539.749146 1008.776184,541.481140
	C1012.651184,543.086853 1015.758911,544.704224 1019.194824,545.604980
	C1024.582031,547.017395 1029.834351,549.158508 1035.801636,548.760498
	C1036.010376,550.091003 1036.003418,551.149658 1035.995728,552.605347
z"
        />
        <path
          fill="#1D1A1E"
          opacity="1.000000"
          stroke="none"
          d="
M1124.765503,531.377808
	C1132.296021,530.686462 1138.584473,534.911499 1145.615356,535.696594
	C1152.247314,536.437256 1157.450195,540.397095 1163.631226,542.998474
	C1164.389282,544.090942 1164.463867,545.747559 1165.218628,546.160583
	C1170.385498,548.987610 1169.732056,554.514099 1171.478027,558.917053
	C1172.387939,561.211121 1171.796631,563.223145 1170.284424,564.900818
	C1168.791504,566.557007 1166.921631,566.241211 1165.411743,564.887085
	C1163.727051,563.376160 1162.862061,566.111816 1161.221436,565.255737
	C1157.321167,559.535339 1150.941406,559.086060 1145.471802,557.274292
	C1139.255737,555.215393 1132.954956,553.344910 1126.399414,551.133789
	C1123.899048,548.498962 1121.137573,547.111267 1117.972656,546.600525
	C1112.018555,545.639709 1106.431396,543.483948 1101.682129,539.956421
	C1098.494507,537.588806 1093.768555,538.284302 1091.592529,533.964783
	C1093.897827,530.355835 1097.138428,531.253784 1100.171387,531.730286
	C1102.631714,532.116821 1104.849609,533.331848 1107.402588,534.653381
	C1111.087036,538.736450 1115.214478,541.222290 1120.326782,542.065125
	C1125.451538,542.910034 1129.929321,545.280273 1134.473145,547.729980
	C1140.682373,551.077637 1147.581299,551.939758 1154.609863,551.548157
	C1155.608154,551.492554 1156.667603,551.399353 1157.051270,550.249512
	C1157.410522,549.172302 1156.864990,548.320618 1156.096069,547.627563
	C1155.228394,546.845642 1154.123779,546.423218 1152.864746,545.553345
	C1152.421753,545.111755 1152.267700,544.910583 1151.947021,544.364929
	C1150.913940,538.834839 1146.397827,539.221008 1142.902344,538.605164
	C1139.662109,538.034302 1136.255737,538.482361 1132.530762,538.460571
	C1131.343384,538.449768 1130.556274,538.383301 1129.430664,537.963379
	C1127.136963,536.083740 1124.712158,534.731934 1124.765503,531.377808
z"
        />
        <path
          fill="#43626E"
          opacity="1.000000"
          stroke="none"
          d="
M1036.405640,548.919006
	C1025.159790,551.685364 1016.323425,545.497070 1006.799866,542.329529
	C1006.200317,542.130066 1005.610840,541.716187 1005.802612,540.559570
	C1009.277588,538.739990 1013.275940,539.266235 1017.564087,538.846680
	C1018.167480,538.734253 1018.324585,538.670837 1018.727783,538.670776
	C1020.039001,538.759399 1021.104187,538.784607 1022.567139,538.770020
	C1023.759521,538.359558 1024.454834,538.497803 1025.132935,539.514343
	C1025.069092,540.100342 1024.924805,540.309204 1024.472534,540.735535
	C1021.858032,542.015442 1019.285156,541.467529 1017.107361,542.066040
	C1026.120605,547.213013 1035.652344,546.423035 1045.430542,544.118958
	C1052.642944,542.419556 1060.009521,541.399597 1067.769775,541.595093
	C1068.124268,541.996948 1068.175659,542.207520 1068.325195,542.728943
	C1059.539795,545.791382 1050.257812,546.354065 1041.173462,548.001404
	C1039.713013,548.266235 1038.253662,548.537415 1036.405640,548.919006
z"
        />
        <path
          fill="#465962"
          opacity="1.000000"
          stroke="none"
          d="
M1078.419312,539.085754
	C1076.478149,538.318237 1074.931519,537.537170 1073.151001,536.535156
	C1072.796021,536.068481 1072.674927,535.822876 1072.697510,535.192200
	C1074.501709,533.473389 1076.319702,533.949768 1078.085571,534.431885
	C1091.729980,538.156921 1105.179199,542.525024 1118.655640,546.802490
	C1119.453857,547.055908 1120.126343,547.571838 1120.989990,548.477783
	C1121.291382,549.079468 1121.341187,549.351440 1121.242920,550.010986
	C1111.256592,548.607971 1101.920288,544.969849 1092.249756,542.559204
	C1087.760254,541.440063 1083.291748,540.237183 1078.419312,539.085754
z"
        />
        <path
          fill="#2D3A41"
          opacity="1.000000"
          stroke="none"
          d="
M1121.336426,550.611938
	C1121.071289,550.167542 1121.047607,549.936584 1121.007324,549.357788
	C1122.937012,547.565186 1124.593262,549.138245 1126.674438,549.793640
	C1135.853271,553.025635 1144.754761,555.895020 1153.569824,559.008789
	C1156.460571,560.029968 1160.306030,560.047546 1161.007446,564.675415
	C1148.901123,557.449768 1134.490601,556.212341 1121.336426,550.611938
z"
        />
        <path
          fill="#1D2229"
          opacity="1.000000"
          stroke="none"
          d="
M1042.614258,550.354126
	C1045.413330,549.264465 1045.413330,549.264465 1048.233887,550.612366
	C1045.954346,551.381531 1044.044800,552.817017 1041.731689,551.352905
	C1041.642822,551.296570 1041.583130,551.006104 1041.641479,550.938721
	C1041.836304,550.713562 1042.091064,550.540344 1042.614258,550.354126
z"
        />
        <path
          fill="#271B1F"
          opacity="1.000000"
          stroke="none"
          d="
M1085.697754,513.736572
	C1085.985352,514.007324 1085.985718,514.500000 1085.983154,514.746338
	C1085.302124,515.679382 1084.600586,516.335388 1083.339111,516.829102
	C1082.740723,516.980530 1082.494385,517.018005 1081.871338,517.049866
	C1079.880981,516.902832 1078.783447,515.743164 1077.126709,514.825562
	C1073.521362,512.601318 1070.102661,511.165131 1066.154297,510.930023
	C1059.929443,510.559418 1054.104858,508.203033 1048.422974,506.038849
	C1040.875854,503.164154 1033.163330,503.123993 1025.404785,502.806244
	C1021.394165,502.641968 1017.415710,502.697601 1013.755005,500.702576
	C1012.880798,500.226166 1011.861572,499.753510 1010.888916,500.152039
	C1003.623169,503.128632 997.378235,499.460480 991.535095,496.406494
	C981.010681,490.905853 970.049500,489.976257 958.606689,490.999390
	C957.341187,491.112579 956.429382,491.700378 955.164307,492.667664
	C951.471375,493.569397 950.247253,492.126862 950.484924,488.894653
	C950.652771,486.612610 950.484863,484.310760 950.665405,481.581512
	C952.206177,474.700073 952.135925,468.025818 953.607361,461.582886
	C954.200684,458.984680 954.723328,456.260162 958.581421,455.976074
	C961.848694,457.020752 962.941040,460.119781 965.849792,461.663635
	C967.163696,462.491425 967.731934,463.388794 967.937622,464.945251
	C968.247375,470.056396 972.589233,474.301392 977.792664,474.438141
	C986.408203,474.664551 995.036804,474.736359 1003.648621,474.279602
	C1014.000488,473.730591 1024.128662,475.412018 1034.622070,477.300842
	C1035.353149,477.983673 1035.566284,478.492615 1035.543213,479.481018
	C1029.797729,484.852905 1023.179260,482.922333 1017.014587,481.810425
	C1012.917969,481.071533 1009.102661,481.090332 1005.212402,482.002228
	C1000.979248,482.994507 1000.488586,485.007782 1003.505737,488.216461
	C1005.045959,489.854462 1006.933777,491.183990 1009.165466,491.004242
	C1018.390869,490.261169 1026.508911,495.239471 1035.470093,495.870880
	C1041.753418,496.313629 1048.090454,497.715668 1053.859253,499.743927
	C1061.067749,502.278381 1068.334473,504.085693 1075.712158,505.822449
	C1079.921143,506.813232 1082.204468,510.862793 1085.697754,513.736572
z"
        />
        <path
          fill="#1D1A1E"
          opacity="1.000000"
          stroke="none"
          d="
M1085.989624,513.585205
	C1083.947754,514.059631 1082.149292,513.936768 1080.850830,511.829803
	C1078.141113,507.432617 1073.744263,505.978912 1068.879028,506.051880
	C1064.558472,506.116638 1060.577881,505.426056 1057.377197,502.355255
	C1056.002075,501.035828 1054.444458,500.943207 1052.776855,501.011322
	C1049.738770,501.135406 1046.952637,500.755432 1044.262451,498.958588
	C1041.551392,497.147827 1037.794678,497.832703 1034.989868,497.692291
	C1026.086304,497.246552 1018.349854,491.277191 1009.188354,492.938904
	C1006.575684,493.412750 1004.400330,491.932617 1002.574646,490.076721
	C1000.691956,488.162842 998.448303,486.154053 999.276733,483.262726
	C1000.169678,480.146210 1003.514709,480.819031 1005.900146,479.938263
	C1013.241577,477.227753 1020.077393,482.359070 1027.327637,481.070007
	C1029.576294,480.670166 1031.969116,481.060455 1034.586914,480.063660
	C1040.569946,479.038971 1046.188843,478.809937 1051.813232,479.143951
	C1054.023071,479.275208 1056.643433,479.086487 1057.203613,482.561462
	C1049.681519,483.215790 1042.023682,481.594543 1035.884277,486.687744
	C1041.221436,495.658875 1050.266479,496.339081 1059.283691,496.811646
	C1065.987061,497.162964 1071.800415,500.704254 1078.441040,501.540527
	C1084.182007,502.263550 1085.849121,505.781921 1085.989624,513.585205
z"
        />
        <path
          fill="#1A2026"
          opacity="1.000000"
          stroke="none"
          d="
M1057.459961,482.995056
	C1056.394775,480.363831 1054.252075,481.103577 1052.541992,481.013153
	C1046.909180,480.715363 1041.244385,481.551331 1035.314941,480.271423
	C1034.999878,479.557983 1034.999756,479.115997 1034.997314,478.342468
	C1035.459473,476.903290 1036.995605,476.869263 1037.534668,475.165314
	C1028.197021,473.036774 1018.691650,471.821198 1009.644287,468.746063
	C1005.261536,467.256409 1000.849121,465.803986 995.695679,466.486084
	C992.161743,466.374939 989.058411,465.927338 985.984070,465.353912
	C984.198669,465.020905 982.438110,464.405151 982.011963,461.960693
	C982.751831,461.134705 983.340698,460.726471 984.002869,460.732666
	C991.271973,460.800385 998.503479,459.664795 1005.780579,460.062408
	C1006.791016,460.117584 1007.744019,460.377655 1008.832153,461.430725
	C1023.540588,465.406738 1037.934082,469.041992 1052.665771,472.851562
	C1054.242188,474.244873 1055.431030,475.607849 1057.706421,474.347656
	C1059.161133,474.477417 1060.358032,474.801147 1061.812622,475.304138
	C1062.126343,475.914398 1062.375244,476.171539 1062.975586,476.047607
	C1064.204224,476.015320 1065.246094,476.215912 1066.530762,476.629944
	C1067.811523,477.899170 1069.175049,477.332550 1070.708984,477.753662
	C1071.040039,477.940491 1071.048218,477.898468 1071.062012,477.881165
	C1073.636108,481.999481 1078.293579,480.371277 1081.843506,481.785614
	C1082.937378,482.221405 1085.028198,481.788330 1084.514893,483.807861
	C1084.067383,485.568024 1082.408691,484.668823 1081.138550,484.575958
	C1073.400879,484.010193 1065.658081,483.514954 1057.459961,482.995056
z"
        />
        <path
          fill="#1C2D32"
          opacity="1.000000"
          stroke="none"
          d="
M1101.889648,515.810791
	C1106.161377,515.670227 1109.274170,517.994324 1112.841919,519.769287
	C1114.073730,521.836304 1112.924683,522.274841 1111.270752,522.830811
	C1110.484375,523.361572 1109.981567,523.645447 1109.177002,523.927856
	C1102.387817,523.024231 1095.903687,522.118958 1089.914185,519.204285
	C1088.340088,518.438293 1086.194580,517.833252 1089.784912,516.189453
	C1090.055664,515.975769 1090.533081,515.971924 1090.771484,515.985962
	C1094.491455,514.492859 1098.106323,516.834656 1101.889648,515.810791
z"
        />
        <path
          fill="#374651"
          opacity="1.000000"
          stroke="none"
          d="
M1089.649902,515.962891
	C1089.918945,516.666504 1088.291626,517.327148 1089.325562,517.819519
	C1093.231323,519.679504 1097.044067,521.642761 1101.741455,521.458862
	C1103.771851,521.379456 1105.989014,523.230408 1108.623291,523.686890
	C1102.887573,526.140076 1097.817261,522.653442 1092.415771,521.257446
	C1090.199097,520.684509 1088.048218,519.821899 1085.275513,519.912598
	C1082.300781,520.359741 1081.084351,519.432739 1081.839111,516.425049
	C1082.115601,516.109375 1082.562622,516.073914 1082.783691,516.036987
	C1083.678589,515.393250 1084.496826,515.518127 1085.651123,515.740723
	C1087.085327,515.939453 1088.164673,515.944702 1089.649902,515.962891
z"
        />
        <path
          fill="#374651"
          opacity="1.000000"
          stroke="none"
          d="
M1101.940918,515.724609
	C1098.700928,520.229858 1095.252197,515.657837 1091.398071,515.975769
	C1092.771851,515.393494 1094.533936,514.786926 1096.647949,514.090576
	C1098.567139,514.487610 1100.134277,514.974426 1101.940918,515.724609
z"
        />
        <path
          fill="#1D1A1E"
          opacity="1.000000"
          stroke="none"
          d="
M1086.000732,515.698853
	C1085.255859,515.965576 1084.505737,515.996887 1083.380249,516.014099
	C1083.570557,515.191650 1084.407959,515.013550 1085.648926,514.997681
	C1085.980469,514.992676 1085.995483,515.463440 1086.000732,515.698853
z"
        />
        <path
          fill="#18252B"
          opacity="1.000000"
          stroke="none"
          d="
M41.271149,72.108627
	C42.878830,67.760994 45.906593,68.707382 48.752811,69.702133
	C49.815304,70.073471 49.973740,71.450081 49.003338,72.148285
	C46.553272,73.911095 43.956974,75.391724 41.271149,72.108627
z"
        />
        <path
          fill="#43626E"
          opacity="1.000000"
          stroke="none"
          d="
M143.504059,547.576538
	C143.125504,546.772034 143.081451,546.261353 143.021240,545.366638
	C141.212830,536.174561 133.833115,531.013245 129.215469,524.052734
	C127.516937,521.492432 125.383675,519.477844 127.064102,516.104248
	C127.892891,514.440430 126.691177,513.280029 125.608047,512.234741
	C122.274193,509.017395 119.174408,505.549805 115.286369,502.930420
	C113.357826,501.631104 111.456818,500.180359 111.930481,497.095673
	C114.503479,495.241516 116.248062,496.798126 117.866592,498.154510
	C126.112320,505.064575 134.810760,511.412720 143.005417,518.375671
	C145.779022,520.732422 147.297195,520.979065 149.539948,517.607788
	C152.449448,513.234436 155.790115,508.962067 161.021591,506.994476
	C163.127914,506.202271 165.405121,505.034637 167.490814,507.734467
	C167.574600,508.343506 167.557175,508.593506 167.392792,509.191223
	C165.108658,511.422302 164.467804,513.699829 164.554276,516.597412
	C164.730591,522.503906 165.714767,528.448120 163.871658,534.332703
	C162.906891,537.412842 165.392746,538.676819 167.628311,539.905029
	C169.370407,540.862061 172.133789,541.028809 170.923691,544.522217
	C161.895950,547.496399 152.981155,548.685852 143.504059,547.576538
z"
        />
        <path
          fill="#465962"
          opacity="1.000000"
          stroke="none"
          d="
M111.770538,496.421448
	C112.934113,500.236633 115.951942,501.285156 118.193520,503.182098
	C121.857414,506.282715 125.228996,509.726166 128.808701,512.930237
	C130.117722,514.101929 130.175674,515.330078 129.029449,516.317200
	C126.090691,518.847961 128.089920,520.364868 129.787003,522.373535
	C134.043060,527.411072 138.184280,532.557861 142.115311,537.851074
	C143.433792,539.626465 144.570358,541.875549 143.151215,544.658203
	C138.747208,541.355652 134.839752,537.529602 131.662384,532.765442
	C128.773972,528.434631 123.971184,524.758484 119.294975,523.682556
	C113.009048,522.236328 112.619469,518.408325 112.004181,513.403809
	C113.509598,509.981995 110.945297,510.301819 109.410332,509.932587
	C105.721642,509.045380 102.677116,507.364563 101.045349,503.353210
	C102.088028,501.119507 104.122475,499.590668 102.620628,496.876801
	C101.810112,495.412201 102.751839,494.040710 104.256737,492.821655
	C105.227463,492.367523 105.921051,492.343353 106.968689,492.538727
	C107.756805,492.735291 108.187073,492.899384 108.887619,493.290894
	C110.098602,494.110687 110.591980,495.166565 111.770538,496.421448
z"
        />
        <path
          fill="#5E9BAB"
          opacity="1.000000"
          stroke="none"
          d="
M142.813873,547.463257
	C152.150757,546.164185 161.131943,545.045776 170.533142,543.878235
	C172.121948,542.586853 173.431534,541.609863 175.646866,541.538330
	C178.012085,541.256470 179.954819,541.226562 182.287109,541.850525
	C183.224854,542.226196 183.704269,542.544739 184.421204,543.270569
	C185.186096,544.367188 185.459702,545.190979 185.301819,546.519104
	C183.563309,548.919922 181.200882,549.179077 178.953171,549.455261
	C173.259247,550.154907 167.585510,551.030762 161.435181,551.523438
	C144.543518,554.489380 127.774437,555.350830 111.387138,558.816101
	C110.241814,559.058228 109.083145,559.107544 107.609245,558.328003
	C106.847794,556.678955 106.468536,555.235474 106.127487,553.418579
	C106.165688,553.045166 106.619881,552.988281 106.835663,552.911987
	C111.506302,549.429199 116.981392,549.830261 122.059097,549.196228
	C126.080826,548.694153 130.199860,548.698730 134.665039,548.074707
	C137.546005,547.949890 140.002075,547.796875 142.813873,547.463257
z"
        />
        <path
          fill="#1E4552"
          opacity="1.000000"
          stroke="none"
          d="
M134.804367,547.834961
	C126.011292,549.688293 116.932663,551.273621 107.452744,552.847290
	C106.841171,549.661133 109.460495,549.643433 111.397705,549.191956
	C115.468475,548.243286 119.548897,547.559021 123.829521,547.924927
	C127.356659,548.226440 130.953094,547.716980 134.804367,547.834961
z"
        />
        <path
          fill="#414A51"
          opacity="1.000000"
          stroke="none"
          d="
M498.334991,712.696472
	C494.568939,715.596313 490.959686,717.983215 487.750427,720.822327
	C477.543518,729.852295 471.820923,741.882080 466.182281,753.914307
	C459.455841,768.267822 451.047302,781.850586 437.129578,789.603821
	C429.292542,793.969604 419.319733,794.635864 409.572479,793.885498
	C394.034119,792.689148 383.156464,783.715942 374.690430,772.590454
	C366.666412,762.045776 358.093323,751.597900 352.674500,739.083923
	C349.686066,732.182556 344.949585,726.041321 341.042236,719.532715
	C339.931122,717.681824 338.851562,715.796082 337.950684,713.837463
	C334.143890,705.560852 335.502869,702.418213 344.912415,699.233398
	C350.411713,715.911194 360.093170,729.746887 369.623535,743.635437
	C373.843018,749.784485 377.872772,755.995789 383.064819,761.556946
	C395.351349,774.716919 410.728912,776.361023 427.162964,775.050293
	C430.988007,774.745239 433.747559,772.133362 436.546143,769.693176
	C446.911407,760.655151 454.084503,749.255920 460.477081,737.323608
	C466.228882,726.587219 473.376190,716.723572 479.472870,706.210266
	C481.278412,703.096680 484.576813,702.072693 487.818237,700.322388
	C490.717865,699.864075 492.726868,701.979919 495.656158,701.818176
	C499.887146,704.695068 500.355927,708.274597 498.334991,712.696472
z"
        />
        <path
          fill="#0D1920"
          opacity="1.000000"
          stroke="none"
          d="
M301.161987,704.678955
	C299.845612,704.896240 298.804840,704.913208 297.373657,704.935181
	C294.567230,705.837280 291.909149,705.547974 289.214233,706.671387
	C287.240936,706.935547 285.550964,706.995239 283.438843,707.078125
	C282.118225,706.970886 281.325928,707.313721 280.217712,707.766724
	C278.521881,707.953064 277.174103,708.263062 275.451843,708.448120
	C274.618103,708.467407 274.140045,708.470520 273.299500,708.473633
	C271.965546,708.512146 270.994934,708.528564 269.677368,708.280457
	C267.963409,707.753296 266.602142,707.375916 264.831024,708.122314
	C263.432129,708.344910 262.414398,708.257507 261.032440,707.852905
	C258.801666,706.101501 254.979233,706.093262 255.554764,702.515991
	C256.105072,699.095581 259.498199,698.985352 262.482117,697.956909
	C263.671783,697.574646 264.493134,697.482178 265.744873,697.505554
	C267.267517,697.376465 268.373199,697.179443 269.904541,697.451538
	C273.105408,697.271423 275.886810,696.970093 279.092133,697.312500
	C283.787384,696.640564 288.077240,695.939453 292.698303,694.775085
	C297.519989,694.141479 301.878021,693.226501 306.698975,692.524048
	C313.182678,691.707825 319.142975,690.321350 325.195679,689.493469
	C327.173401,689.222961 329.147980,689.182373 331.488556,689.988892
	C335.976868,694.414368 335.963593,695.931641 331.388733,698.074097
	C330.559052,698.135193 330.167938,698.139771 329.483643,698.132812
	C326.795563,699.344910 324.093628,699.562805 321.293396,700.648254
	C320.828461,700.889282 320.663239,700.922607 320.248749,700.998169
	C318.360321,701.875793 316.584869,701.981262 314.411743,702.024109
	C310.658478,702.409180 307.289032,702.782288 303.488342,703.194336
	C302.502960,703.629822 301.960846,704.042053 301.161987,704.678955
z"
        />
        <path
          fill="#2C4653"
          opacity="1.000000"
          stroke="none"
          d="
M498.366333,713.164307
	C497.800812,709.597778 498.784912,705.921936 495.375549,703.087280
	C489.461517,700.503296 485.529327,695.943237 481.082428,692.037109
	C476.119781,687.678101 471.157684,683.318298 465.342590,679.504517
	C464.819183,679.296265 464.613190,679.207397 464.121399,678.945984
	C463.112915,678.295410 462.592804,677.548767 461.464661,677.071655
	C460.344910,676.539490 459.535919,676.125610 458.463470,675.443481
	C456.871002,673.968933 455.811768,672.609314 456.442322,670.282227
	C459.920349,667.764832 462.589844,669.679077 465.098419,671.480530
	C471.075928,675.772705 476.944672,680.216492 483.204712,684.743774
	C490.645813,690.661072 497.623871,696.588379 505.524475,701.275696
	C506.548157,701.883057 507.415802,702.648743 507.535095,704.291077
	C505.305328,707.243103 508.674744,708.403076 508.991394,710.707153
	C506.606598,716.438904 502.372009,713.525208 498.366333,713.164307
z"
        />
        <path
          fill="#1B1E22"
          opacity="1.000000"
          stroke="none"
          d="
M331.238953,698.355225
	C334.740540,696.512817 333.634460,694.062866 332.022064,691.171875
	C333.296936,686.430481 337.352600,687.801270 340.708618,686.741516
	C344.383240,685.837646 347.636597,684.821350 351.433014,686.028748
	C354.345947,688.709412 357.599335,691.044617 353.669525,695.319275
	C351.710297,696.614380 349.825073,696.590210 347.596008,696.834351
	C346.672058,696.896118 346.163635,696.782532 345.354980,696.334229
	C340.446808,696.487976 336.284607,699.162231 331.238953,698.355225
z"
        />
        <path
          fill="#17272E"
          opacity="1.000000"
          stroke="none"
          d="
M509.281250,711.174438
	C507.553101,709.750732 506.062195,708.483521 504.661652,707.123291
	C504.013306,706.493591 502.888702,705.742615 503.697540,704.725769
	C504.441742,703.790222 505.490936,704.924316 506.754456,704.870728
	C507.504456,704.556824 507.975281,704.380127 508.875671,704.386841
	C512.965210,705.765930 516.509888,707.715637 521.013550,707.481201
	C522.927063,707.184570 524.424133,707.364197 526.224304,708.017822
	C528.506775,710.224060 525.949280,713.978149 529.183350,715.804138
	C526.611389,717.812805 524.189087,716.799744 521.382935,716.048828
	C519.273254,715.655273 517.546326,715.311035 515.436890,714.960327
	C514.358032,714.143860 513.247314,714.047729 512.221924,713.217346
	C511.190033,712.430969 510.372742,711.861877 509.281250,711.174438
z"
        />
        <path
          fill="#1B1E22"
          opacity="1.000000"
          stroke="none"
          d="
M321.393250,700.889893
	C322.699554,697.964050 325.719177,698.514160 328.770691,698.084717
	C327.438446,700.939941 324.428802,700.438660 321.393250,700.889893
z"
        />
        <path
          fill="#193A47"
          opacity="1.000000"
          stroke="none"
          d="
M314.272675,702.279419
	C315.501068,700.443970 317.440613,701.179932 319.598114,701.020508
	C319.999481,701.040466 320.000275,701.532043 319.995056,701.778015
	C318.153137,702.052490 316.463562,703.695129 314.272675,702.279419
z"
        />
        <path
          fill="#414A51"
          opacity="1.000000"
          stroke="none"
          d="
M345.023560,696.472412
	C345.480438,696.096924 345.931824,696.079651 346.720825,696.098511
	C347.983521,696.563965 348.607697,697.255554 348.321808,698.298340
	C347.958588,699.623169 346.902130,699.750183 345.372620,699.245728
	C345.011627,699.011780 345.001617,699.001648 344.995422,698.995605
	C344.998810,698.269958 345.008423,697.550293 345.023560,696.472412
z"
        />
        <path
          fill="#31383F"
          opacity="1.000000"
          stroke="none"
          d="
M512.076050,713.337646
	C513.143921,713.037964 514.342346,712.938843 514.968323,714.626038
	C513.896423,714.955811 512.674988,715.102051 512.076050,713.337646
z"
        />
        <path
          fill="#214A59"
          opacity="1.000000"
          stroke="none"
          d="
M261.013977,389.259644
	C261.785919,389.218262 262.565216,389.557404 263.676575,389.859497
	C267.107758,388.240814 269.896851,390.714996 273.300171,390.495483
	C283.049591,392.646912 292.349854,394.937439 301.389709,399.139343
	C303.324524,407.450806 303.047333,415.667847 304.382019,424.052917
	C303.798553,427.452759 304.073029,430.395233 305.486633,433.560242
	C305.589325,434.148682 305.591949,434.390961 305.494415,434.984985
	C303.933258,438.206116 300.141418,437.834503 298.097656,440.553406
	C296.754822,441.191193 295.716339,441.219177 294.283264,440.776337
	C291.852814,438.512238 289.188599,436.760498 289.881958,432.916260
	C290.601807,432.405304 291.080139,432.176117 291.576721,432.240845
	C297.729370,433.043182 298.305634,432.789795 297.391724,426.387939
	C296.586212,420.745544 296.560669,415.135803 296.491974,409.492737
	C296.451355,406.157532 295.379059,403.803833 291.561188,403.201416
	C288.797119,402.765259 286.090698,401.832947 283.384766,401.019592
	C281.180328,400.356903 279.453796,401.078094 279.853058,403.574646
	C281.202972,412.015167 279.922913,420.520599 280.577240,429.304993
	C276.045044,430.972717 273.566528,428.690613 274.290680,423.668121
	C274.697632,420.845825 275.426239,418.132050 272.698181,415.318848
	C269.469238,423.160309 270.877319,431.189514 269.968170,439.241089
	C269.799561,439.988007 269.261902,440.258484 269.367462,440.715729
	C269.449951,441.072845 269.711182,440.973480 270.116760,440.304626
	C273.589233,439.798004 276.094635,440.811127 277.535034,444.190002
	C276.709808,446.579193 273.415497,446.246857 273.076599,449.195068
	C269.184937,449.517578 269.975952,453.113007 268.233704,455.159485
	C265.061493,455.851349 264.946716,453.768677 264.568665,451.805847
	C263.996490,448.835327 265.201721,446.095459 265.522034,442.864624
	C264.038452,431.290558 264.761658,419.964752 263.520691,408.755432
	C263.175568,405.637817 264.433838,402.560486 263.771179,399.846619
	C263.752441,399.410156 263.742584,399.397644 263.723907,399.397125
	C263.608673,399.393982 263.522583,399.526978 263.596802,399.752960
	C263.862427,400.561768 263.939850,401.376282 263.583252,402.575867
	C262.992462,403.354950 262.497101,403.592407 261.481323,403.632812
	C258.484467,402.397552 257.376617,400.560852 258.740387,397.388611
	C259.007172,396.567322 259.009460,396.112671 259.010742,395.317017
	C258.653931,394.595245 258.505890,394.145905 258.789368,393.328674
	C261.049438,392.709259 261.017944,391.164368 261.013977,389.259644
z"
        />
        <path
          fill="#28424F"
          opacity="1.000000"
          stroke="none"
          d="
M258.660889,397.083435
	C258.815033,399.057068 259.237122,400.899780 260.875244,402.730499
	C263.603180,406.540497 262.127045,410.493469 262.233643,414.665405
	C258.862091,418.508911 257.567169,418.550903 254.901215,415.094421
	C254.099411,414.054901 253.565216,412.828705 251.727875,412.526062
	C250.870499,412.433136 250.382645,412.382416 249.541199,412.253174
	C248.496979,412.010437 247.860291,411.680054 246.824921,411.453766
	C242.348236,410.644562 238.666000,408.414398 234.488647,407.777222
	C233.347000,407.603058 232.278107,407.223877 231.098755,408.437927
	C229.908508,409.414368 229.416443,410.364960 229.481873,411.949249
	C229.405365,413.854858 229.526230,415.445648 227.958618,416.828186
	C225.677826,417.609222 226.242599,419.302917 225.934097,420.681366
	C225.144363,424.209930 219.449600,426.911957 217.465515,424.332642
	C213.407379,419.056915 212.730667,423.851257 210.982758,425.901886
	C210.770370,426.151031 210.563400,426.404785 210.067413,426.844543
	C208.130966,425.450836 208.600693,423.104919 207.643860,420.876190
	C207.287445,414.985809 204.350571,410.215485 203.296204,404.579437
	C203.749557,403.407379 204.092148,403.012054 205.227097,403.308716
	C209.007065,404.296661 212.833069,404.846985 216.778931,405.159119
	C223.010086,405.651947 228.575058,403.612946 234.321426,402.084106
	C235.084152,401.881195 235.651443,401.265717 236.591736,401.719086
	C244.993744,405.770508 251.751602,401.890961 258.660889,397.083435
z"
        />
        <path
          fill="#83AEBF"
          opacity="1.000000"
          stroke="none"
          d="
M178.632507,412.743958
	C178.182419,412.997620 177.973587,412.983582 177.451202,412.954224
	C177.052246,411.236115 176.966843,409.533295 176.821045,407.407501
	C177.385681,404.952362 176.736069,402.797913 177.667542,400.728546
	C181.786499,391.577728 188.855850,390.368866 197.809021,397.716644
	C198.178986,398.532532 198.045456,398.972382 197.242737,399.441223
	C195.317398,399.697723 194.071106,398.820831 192.726089,398.224976
	C186.429352,395.435608 182.263550,397.547028 180.653885,404.302979
	C180.007385,407.016327 180.000061,409.868256 178.632507,412.743958
z"
        />
        <path
          fill="#759EB0"
          opacity="1.000000"
          stroke="none"
          d="
M196.831284,399.291870
	C197.306213,398.886078 197.565002,398.693390 197.885010,398.210876
	C200.295929,399.312653 201.245560,401.803528 203.162766,403.932037
	C206.031693,408.926392 207.893448,413.920593 208.866562,419.625000
	C208.654816,420.157562 208.459869,420.299042 207.903931,420.441498
	C203.547028,417.231384 204.191635,411.820709 201.470795,407.632446
	C200.839905,406.597382 200.365845,405.857147 199.510269,404.999420
	C199.001785,404.431458 198.729874,404.090942 198.276840,403.475403
	C197.399933,402.083130 196.673828,400.972137 196.831284,399.291870
z"
        />
        <path
          fill="#BAEEFA"
          opacity="1.000000"
          stroke="none"
          d="
M248.517090,384.616760
	C252.071976,383.282379 255.633545,385.154694 259.358368,385.239777
	C261.067352,385.278809 261.370850,386.693085 259.366394,387.908081
	C255.601013,386.973053 252.175888,385.948486 248.517090,384.616760
z"
        />
        <path
          fill="#28424F"
          opacity="1.000000"
          stroke="none"
          d="
M258.728424,393.067719
	C259.012482,393.461884 259.011566,393.894623 259.010193,394.651672
	C258.377167,394.889191 257.631531,394.828156 257.587402,394.041870
	C257.570892,393.746918 258.140839,393.419098 258.728424,393.067719
z"
        />
        <path
          fill="#BAEEFA"
          opacity="1.000000"
          stroke="none"
          d="
M243.018219,383.446686
	C243.206543,383.053070 243.517075,382.738922 243.827621,382.424774
	C243.813431,382.657288 243.799225,382.889832 243.587418,383.248779
	C243.389832,383.375214 243.140427,383.526123 243.018219,383.446686
z"
        />
        <path
          fill="#28424F"
          opacity="1.000000"
          stroke="none"
          d="
M225.518372,403.530762
	C225.480942,403.549927 225.555817,403.511627 225.518372,403.530762
z"
        />
        <path
          fill="#1B2E3A"
          opacity="1.000000"
          stroke="none"
          d="
M241.001801,384.329224
	C241.001144,384.102051 241.490494,384.008606 241.738159,384.031677
	C241.865173,384.350922 241.675812,384.593445 241.214233,384.896484
	C241.010773,385.010712 241.002472,384.556427 241.001801,384.329224
z"
        />
        <path
          fill="#473235"
          opacity="1.000000"
          stroke="none"
          d="
M671.826660,720.682495
	C672.122864,722.320068 672.461792,723.660950 672.323425,724.950562
	C672.037292,727.619446 671.671448,730.369812 668.213989,730.938660
	C665.029236,731.462646 663.116150,730.208984 662.406677,727.020020
	C662.284607,726.471375 661.663330,726.033752 660.977966,725.175964
	C659.283875,727.825623 659.838806,730.623596 659.448364,733.052673
	C659.025452,735.683899 661.451721,734.778381 663.024353,734.499329
	C671.218933,733.045166 673.271484,735.262024 671.058228,743.608032
	C669.666931,744.000427 668.278442,743.755737 667.010315,744.042969
	C661.008606,745.402283 656.411621,745.000977 656.383850,737.079712
	C656.378357,735.500916 654.989258,734.058777 652.929382,734.100220
	C650.874084,734.141541 649.642029,735.628723 649.552124,737.212402
	C649.273621,742.119324 646.748779,744.276428 642.052185,744.591492
	C641.172302,744.650452 639.820923,745.389160 639.587646,746.106812
	C638.254822,750.208374 634.689209,752.891357 633.022339,756.647644
	C631.760498,759.491516 630.136169,761.037720 627.106384,760.975769
	C623.561218,760.903320 621.489807,761.738403 620.590637,765.961731
	C619.754517,769.888672 617.224548,773.720276 612.221924,773.989624
	C609.472595,774.137634 608.342773,776.308411 607.724915,778.124023
	C606.322327,782.245728 603.676941,783.475830 599.691467,783.018250
	C597.681580,782.787537 595.706665,783.128723 593.407410,783.938843
	C593.522949,780.205933 591.477844,776.925720 590.849976,773.359436
	C590.625366,772.083618 589.740601,771.452087 588.501038,771.102905
	C584.942993,770.100281 580.420654,771.188721 578.054626,767.622131
	C575.739807,764.132751 577.379578,759.838867 577.000000,755.460571
	C575.034485,753.034119 575.177979,750.495972 575.148743,748.026001
	C575.068481,741.253601 575.171143,734.480530 575.088318,727.707458
	C575.056885,725.135803 574.453430,723.249695 571.525879,722.657837
	C569.566650,722.261719 567.569397,721.385986 567.518494,718.934631
	C567.467896,716.497864 569.532471,715.727356 571.332092,714.908142
	C572.538086,714.359192 573.767700,713.884338 575.063965,712.671814
	C586.781311,710.810547 597.656372,706.702271 609.126099,705.509460
	C610.138306,705.404175 611.103088,705.307617 612.004333,704.054932
	C612.526306,703.596436 612.759705,703.440491 613.386963,703.130249
	C614.329590,702.843811 614.886108,702.786499 615.861816,702.861206
	C620.276306,704.549683 624.216797,702.890320 627.986572,702.198914
	C639.763367,700.038879 651.563538,697.945801 663.249390,695.300293
	C663.934998,695.145081 664.333069,694.580933 665.033447,693.762817
	C667.215454,692.012085 669.290710,691.328796 672.334290,692.507935
	C672.561523,696.130920 669.805420,697.467468 667.967163,699.467285
	C666.210754,701.378174 665.622375,703.024597 666.953552,705.578735
	C669.360413,710.196594 668.563599,715.905334 671.826660,720.682495
z"
        />
        <path
          fill="#5D3732"
          opacity="1.000000"
          stroke="none"
          d="
M784.308899,771.766174
	C781.533508,772.162170 780.095215,774.031799 778.907593,775.699463
	C773.176331,783.747375 764.779480,785.789246 755.752808,785.901611
	C748.470947,785.992249 741.174866,785.359375 733.894775,784.895264
	C731.891785,784.767578 729.732666,784.081421 729.225220,781.795837
	C727.843384,775.571899 723.234802,771.183777 718.676880,768.211975
	C710.369446,762.795349 706.736938,755.366638 704.840027,746.341553
	C704.359558,744.055481 703.481934,741.623291 701.616821,740.257446
	C694.985535,735.401001 692.417236,728.243164 689.923523,720.941406
	C689.374695,719.334473 688.939270,717.785706 688.998291,716.096619
	C689.129578,712.346619 687.643372,709.520691 684.720825,707.052734
	C678.259705,701.596558 677.883423,698.851746 681.947327,691.347046
	C682.739807,689.977661 683.817505,689.657043 685.419678,689.559875
	C686.682739,689.605103 687.495483,689.750488 688.568054,690.378662
	C689.452515,691.317688 689.809692,692.278076 689.247803,692.910706
	C686.010376,696.555237 688.061707,699.239502 690.683411,701.911072
	C692.329102,703.588135 693.444336,705.649231 694.710205,707.610291
	C699.200134,714.566589 702.662720,722.308594 709.869385,727.203186
	C711.515686,728.321228 712.837036,730.060120 713.254333,732.045532
	C714.417603,737.579102 718.444275,741.695007 720.348877,746.849487
	C720.808838,748.094482 721.597473,749.525818 722.765137,749.948608
	C727.206787,751.556946 730.012817,754.255249 731.942993,758.798462
	C733.075012,761.463074 737.304016,760.912231 739.761108,759.886414
	C748.032532,756.432800 756.401855,757.126099 764.841797,758.440674
	C768.301270,758.979553 770.348389,758.132324 771.640564,754.538818
	C773.177124,750.265869 776.727600,747.206360 779.750977,743.917358
	C781.116333,742.432068 783.062134,741.681152 785.565674,742.296997
	C787.714600,743.679016 787.126038,746.121826 788.616638,747.792603
	C790.915161,747.296692 791.020020,745.051819 791.910583,743.476501
	C793.455261,740.743896 794.805542,737.764099 799.130920,738.777710
	C800.228088,743.959290 798.310791,748.471313 795.201721,751.647095
	C791.398987,755.531372 789.178467,759.846375 787.757690,764.855530
	C787.080383,767.243713 786.425781,769.667053 784.308899,771.766174
z"
        />
        <path
          fill="#553635"
          opacity="1.000000"
          stroke="none"
          d="
M784.300171,772.157593
	C786.882263,764.510010 786.788269,755.852966 794.472473,750.272644
	C797.123596,748.347351 797.541809,743.347290 798.989990,739.374756
	C797.886658,735.580872 799.951050,733.029297 801.382812,730.315674
	C803.732910,725.861389 806.043640,721.378906 809.866882,717.579224
	C814.488831,720.795593 814.209106,726.043213 816.064514,730.783264
	C816.272644,739.161316 813.605957,746.474548 810.330200,753.536194
	C809.284302,755.790955 809.099121,758.373901 807.256592,760.687500
	C805.769836,761.843628 805.437012,763.714905 803.322510,763.956970
	C801.200195,763.134644 800.973206,761.540283 800.983093,759.794800
	C800.990723,758.445923 800.938599,756.939331 799.364502,756.507996
	C797.813049,756.082764 796.498779,757.407471 796.230225,758.447205
	C794.900696,763.592834 787.293091,764.872864 788.561584,771.906799
	C789.027832,774.492004 786.148315,773.483093 784.300171,772.157593
z"
        />
        <path
          fill="#321616"
          opacity="1.000000"
          stroke="none"
          d="
M686.249756,690.529053
	C684.887634,690.834839 683.836609,690.901062 682.390381,690.978760
	C679.252380,691.649414 676.509583,692.308533 673.379761,692.995239
	C672.992676,693.022949 673.005249,692.921143 673.004272,692.869873
	C671.531677,689.932190 672.720032,686.826904 672.421631,683.507324
	C674.601685,680.859253 677.466431,681.418945 680.426758,680.865356
	C681.898865,680.564148 682.982666,680.547791 684.469666,680.739258
	C687.079346,681.547852 689.222656,682.461853 691.319702,683.471924
	C692.372375,683.979065 693.433533,684.647278 693.388672,686.023804
	C693.343384,687.409241 692.265503,687.914795 691.140076,688.371948
	C689.607361,688.994446 687.938232,689.296692 686.249756,690.529053
z"
        />
        <path
          fill="#0D1920"
          opacity="1.000000"
          stroke="none"
          d="
M133.275421,343.918884
	C133.557632,343.869934 133.994263,344.171204 134.501633,344.880768
	C134.191528,344.949066 133.810699,344.609100 133.275421,343.918884
z"
        />
        <path
          fill="#B2E7F4"
          opacity="1.000000"
          stroke="none"
          d="
M141.946289,354.343903
	C142.070511,354.245331 142.093857,353.561768 142.227951,354.319092
	C142.082352,354.436401 141.969345,354.398804 141.946289,354.343903
z"
        />
        <path
          fill="#321616"
          opacity="1.000000"
          stroke="none"
          d="
M1124.582031,531.040649
	C1125.356689,533.561218 1128.731079,533.875671 1129.015381,536.843384
	C1129.273438,537.959106 1129.020874,538.604736 1128.289307,539.421204
	C1124.012695,541.672974 1121.248291,537.277222 1117.523193,537.768311
	C1117.479614,537.913818 1117.907837,537.820618 1118.170898,537.821533
	C1119.481689,538.015808 1120.572388,538.076233 1121.712036,539.026672
	C1129.975708,542.547974 1137.808105,546.282532 1145.998657,549.068359
	C1148.877563,550.047485 1150.984009,547.916016 1152.677734,545.196045
	C1155.242798,544.843628 1157.313965,545.180115 1158.600098,547.363647
	C1159.368408,548.668213 1160.168945,549.963196 1159.169189,551.526611
	C1158.183960,553.067444 1156.924316,553.682983 1154.996338,553.523804
	C1145.549927,552.744202 1136.307251,551.830322 1128.112061,546.003357
	C1125.203003,543.934875 1120.865601,543.909241 1117.211060,542.845337
	C1113.536621,541.775696 1109.723877,540.835510 1108.048706,536.349976
	C1107.904663,534.749023 1108.521729,533.795288 1109.561523,532.689575
	C1111.622437,531.191833 1113.770508,532.737366 1115.905884,531.615051
	C1116.641479,531.358948 1117.070801,531.389282 1117.743164,531.761963
	C1118.543335,531.807922 1119.093628,531.636475 1119.831787,531.239380
	C1120.179443,530.888000 1120.339355,530.762207 1120.740479,530.823059
	C1122.048706,531.016785 1123.115479,531.023804 1124.582031,531.040649
z"
        />
        <path
          fill="#48505A"
          opacity="1.000000"
          stroke="none"
          d="
M1115.706299,531.677979
	C1114.655762,535.257507 1112.398560,532.167236 1110.414795,532.865051
	C1102.323486,533.057190 1095.435425,530.249268 1088.512695,526.574707
	C1088.241333,525.646973 1088.288574,525.106750 1088.746582,524.260864
	C1097.345703,526.331421 1105.681519,528.720520 1114.395020,531.153320
	C1115.034546,531.234802 1115.296265,531.272522 1115.706299,531.677979
z"
        />
        <path
          fill="#73676A"
          opacity="1.000000"
          stroke="none"
          d="
M1088.741211,523.753906
	C1088.995850,524.356445 1088.981812,524.770569 1088.960205,525.495056
	C1087.400513,527.607361 1085.510132,526.587891 1083.330933,526.342651
	C1081.498169,525.495911 1079.796021,525.538086 1078.057495,524.479248
	C1077.003296,522.664368 1077.615967,521.546082 1079.239014,520.450806
	C1081.356812,519.972046 1082.889526,520.429993 1084.531494,521.820190
	C1086.029663,522.604614 1087.250977,523.085022 1088.741211,523.753906
z"
        />
        <path
          fill="#3E2423"
          opacity="1.000000"
          stroke="none"
          d="
M1084.861084,521.755615
	C1083.437378,521.837708 1082.066528,521.551208 1080.372314,521.116211
	C1078.272461,519.461975 1077.046753,517.683472 1077.601685,514.608765
	C1079.149292,514.645020 1080.297974,515.293091 1081.781250,516.025269
	C1081.686401,517.991150 1082.777222,518.924072 1084.570557,519.745544
	C1084.871826,520.394165 1084.892822,520.890564 1084.861084,521.755615
z"
        />
        <path
          fill="#1C2D32"
          opacity="1.000000"
          stroke="none"
          d="
M1115.635620,520.258667
	C1117.191406,520.135681 1118.861938,520.289368 1119.074951,521.564087
	C1119.307617,522.956238 1117.669189,522.847961 1116.273682,522.956482
	C1114.985840,522.284424 1114.134766,521.598145 1115.635620,520.258667
z"
        />
        <path
          fill="#374651"
          opacity="1.000000"
          stroke="none"
          d="
M1115.540527,520.056641
	C1115.548584,520.855164 1115.141113,521.630554 1115.855957,522.704590
	C1114.562622,522.977600 1113.188721,523.006897 1111.401001,523.056946
	C1111.647095,522.291870 1112.307007,521.506165 1112.987183,520.410889
	C1113.731567,520.079651 1114.455811,520.058044 1115.540527,520.056641
z"
        />
        <path
          fill="#1D1A1E"
          opacity="1.000000"
          stroke="none"
          d="
M1116.211670,532.032227
	C1115.558716,531.937622 1115.296997,531.774536 1114.921143,531.376770
	C1115.834106,530.846863 1116.958130,529.397339 1117.939453,531.677368
	C1117.518066,531.992554 1117.043335,532.005676 1116.211670,532.032227
z"
        />
        <path
          fill="#1D1A1E"
          opacity="1.000000"
          stroke="none"
          d="
M1120.891357,530.863037
	C1120.981812,531.009766 1120.500488,531.012207 1120.260010,531.012939
	C1120.280029,530.914612 1120.540405,530.815430 1120.891357,530.863037
z"
        />
        <path
          fill="#121E24"
          opacity="1.000000"
          stroke="none"
          d="
M518.628174,33.003216
	C519.004028,33.751225 519.003235,34.500412 519.002441,35.624184
	C517.447144,36.777042 515.715698,37.273853 515.310425,34.974556
	C514.928650,32.808262 516.891235,33.126465 518.628174,33.003216
z"
        />
        <path
          fill="#847876"
          opacity="1.000000"
          stroke="none"
          d="
M1079.514648,333.315887
	C1080.271606,334.623779 1080.753296,335.712585 1081.391235,337.064819
	C1083.265137,337.970245 1083.190918,339.357361 1082.827026,341.181396
	C1082.109863,342.386292 1081.330811,342.401855 1080.119141,342.001587
	C1078.858765,341.216156 1078.278076,340.351166 1077.661865,339.057861
	C1075.467529,333.879272 1071.736206,331.038086 1066.760376,329.700409
	C1065.358643,329.323578 1063.849487,329.237152 1062.614014,327.734863
	C1062.342163,326.928772 1062.373047,326.449493 1062.756592,325.699219
	C1062.999023,325.422699 1062.938232,325.148346 1062.948242,325.008118
	C1069.349487,325.702911 1075.075928,327.853363 1079.514648,333.315887
z"
        />
        <path
          fill="#0A0D13"
          opacity="1.000000"
          stroke="none"
          d="
M1079.713013,327.047638
	C1079.822510,327.722351 1079.645020,328.444702 1079.467651,329.167053
	C1079.194336,329.054230 1078.796021,329.019012 1078.669312,328.815063
	C1078.117798,327.927002 1078.540283,327.391785 1079.713013,327.047638
z"
        />
        <path
          fill="#1B2E3A"
          opacity="1.000000"
          stroke="none"
          d="
M178.744507,367.203491
	C195.719803,371.049011 212.561737,374.654083 228.806366,380.492493
	C231.029633,381.291565 233.466156,381.497345 235.967987,382.341034
	C232.090790,386.605530 228.005310,387.431610 223.603180,383.150177
	C222.143555,381.730591 220.358582,381.452454 218.843750,383.348267
	C217.261124,385.328979 214.638855,385.147522 212.757187,386.168213
	C205.275558,390.226379 198.843109,383.426331 191.735062,384.657440
	C190.717804,384.833618 189.567215,384.029053 188.346680,384.382782
	C173.826599,388.590881 158.751877,389.852051 143.876144,391.474823
	C132.980087,392.663452 121.877289,393.902985 110.636681,392.457031
	C105.053108,391.738800 99.043175,394.326355 93.227921,395.427887
	C92.415001,395.581879 91.612389,395.790314 90.403206,395.986145
	C93.542412,393.348297 96.955452,390.752991 98.601601,386.231262
	C99.304413,384.300812 102.162750,385.017059 104.110374,385.014282
	C121.347481,384.989685 138.585220,384.924194 155.821335,385.056854
	C159.681915,385.086517 161.438797,383.737885 161.000290,379.423645
	C161.054443,371.459961 161.485703,371.125061 168.880066,370.944366
	C171.316650,370.884827 174.396240,372.242828 175.936249,368.408569
	C176.684235,367.206451 177.667297,367.640808 178.744507,367.203491
z"
        />
        <path
          fill="#1B2E3A"
          opacity="1.000000"
          stroke="none"
          d="
M82.392708,397.998138
	C82.490723,395.399261 84.573410,396.164276 86.607605,396.001038
	C86.510071,398.600067 84.427536,397.836151 82.392708,397.998138
z"
        />
        <path
          fill="#151117"
          opacity="1.000000"
          stroke="none"
          d="
M614.250854,703.706055
	C613.838867,703.935852 613.673340,703.961975 613.259460,704.027466
	C612.053772,705.095215 610.832886,705.587769 609.469238,705.698608
	C600.496399,706.428223 592.000427,709.473938 583.213379,711.117249
	C580.160339,711.688232 576.799194,710.947693 573.711487,713.278503
	C572.789429,713.628906 572.239319,713.617920 571.376831,713.167114
	C567.661255,711.811096 564.328186,712.906921 561.107300,713.578857
	C557.076477,714.419739 553.100830,715.250610 548.523193,715.108276
	C547.374451,715.694458 546.581482,716.021423 545.337524,716.036499
	C541.640991,716.081970 538.272217,716.105530 534.447876,716.197998
	C532.616638,716.206299 531.241028,716.145630 529.454224,716.104248
	C528.929016,716.002563 528.836670,715.816833 528.697571,715.770142
	C523.338623,713.968201 523.338074,713.969849 526.984863,709.409180
	C528.581604,707.095825 530.715027,707.301270 533.276733,707.473511
	C546.201782,705.783386 558.504761,703.050781 570.895264,700.814453
	C574.636719,700.139160 578.415833,699.745605 582.538330,698.851562
	C603.493347,694.546387 624.096069,690.624817 644.793945,687.238281
	C648.381348,686.651306 651.950073,685.576111 656.077637,685.980042
	C657.288757,686.329834 657.897217,686.883911 658.570251,687.357178
	C664.099915,691.245972 664.033325,692.450806 657.716919,695.685913
	C656.875122,696.104248 656.366028,696.260315 655.450928,696.388489
	C650.495239,696.371277 646.169556,697.414490 641.716675,699.503601
	C640.501953,700.120361 639.544861,700.236328 638.223206,700.218628
	C636.822815,700.138550 635.822998,699.501160 634.417603,699.960938
	C633.710327,700.135681 633.304077,700.222046 632.591187,700.350647
	C631.674561,700.469604 631.082886,700.620422 630.214233,700.925537
	C629.334045,701.177185 628.734314,701.282837 627.839539,701.443665
	C626.710999,701.580933 626.076538,702.092346 625.113281,702.632202
	C624.234619,702.947327 623.613525,702.975037 622.686768,703.022034
	C621.394836,702.920898 620.425415,702.471069 619.122559,702.982788
	C618.205872,703.195679 617.581360,703.249878 616.636841,703.172363
	C615.674438,703.053772 615.056458,703.139038 614.250854,703.706055
z"
        />
        <path
          fill="#433437"
          opacity="1.000000"
          stroke="none"
          d="
M570.836243,713.174927
	C571.461121,712.893738 571.860901,712.897461 572.561157,712.910278
	C573.612976,712.409546 574.414612,712.182739 575.655029,712.744751
	C575.520264,715.795166 572.909668,715.573364 571.098328,716.500000
	C569.967163,717.078735 568.361267,717.413086 568.504028,719.084778
	C568.650513,720.801880 570.186279,721.088867 571.498596,720.942017
	C576.520020,720.380127 577.148193,723.213928 577.059692,727.303894
	C576.866638,736.219788 577.000000,745.142639 577.000000,754.531372
	C572.095581,753.541870 566.788269,754.623047 561.934021,752.308228
	C560.006958,751.389282 559.205200,750.685547 558.905518,748.551147
	C558.221375,743.679199 555.738525,740.326538 550.295410,739.934875
	C547.474976,739.731873 544.956604,738.751465 545.004517,735.241943
	C545.039978,732.650757 542.396606,731.526123 542.002441,729.096741
	C540.970764,722.737488 545.495605,717.964905 553.582520,718.114197
	C559.851562,718.229919 565.267822,716.218933 570.836243,713.174927
z"
        />
        <path
          fill="#17272E"
          opacity="1.000000"
          stroke="none"
          d="
M545.225220,716.275513
	C545.581543,715.196655 546.445862,715.033875 547.733826,715.093079
	C547.714233,716.573547 546.968506,717.286499 545.225220,716.275513
z"
        />
        <path
          fill="#12232B"
          opacity="1.000000"
          stroke="none"
          d="
M178.621170,366.925079
	C178.439575,367.817200 177.593750,367.993774 176.341705,368.016388
	C176.003983,367.795898 176.001038,367.566589 175.991394,366.992920
	C176.737671,366.719818 177.490662,366.791077 178.621170,366.925079
z"
        />
        <path
          fill="#385661"
          opacity="1.000000"
          stroke="none"
          d="
M111.363937,705.052368
	C110.774742,700.956238 109.466331,697.004028 110.000412,692.449707
	C111.832977,692.160889 113.410347,693.149719 113.654747,694.829590
	C114.261047,698.997131 116.741669,702.374817 117.990601,706.625488
	C115.571632,707.486145 113.727699,706.035645 111.363937,705.052368
z"
        />
        <path
          fill="#193A47"
          opacity="1.000000"
          stroke="none"
          d="
M289.189270,706.932983
	C290.729156,703.832825 293.828491,704.885437 296.724335,704.711182
	C296.980347,705.390320 296.977417,705.840576 296.963287,706.627075
	C294.862671,706.978699 292.773285,706.993958 290.327148,707.040771
	C289.796143,707.044922 289.621887,707.017456 289.189270,706.932983
z"
        />
        <path
          fill="#193A47"
          opacity="1.000000"
          stroke="none"
          d="
M280.825073,707.411987
	C281.401642,706.493835 281.916382,706.146240 282.855865,706.885681
	C283.016663,707.101379 283.016632,707.593384 283.008911,707.840210
	C282.544312,708.081482 282.087372,708.075806 281.288391,708.094849
	C280.850464,707.958984 280.754578,707.798462 280.825073,707.411987
z"
        />
        <path
          fill="#0F1B23"
          opacity="1.000000"
          stroke="none"
          d="
M172.406311,289.327393
	C172.354996,289.544037 171.998901,289.725250 171.331512,289.861023
	C171.380646,289.640991 171.741104,289.466461 172.406311,289.327393
z"
        />
        <path
          fill="#0F1B23"
          opacity="1.000000"
          stroke="none"
          d="
M168.872131,289.894470
	C168.820206,290.150604 168.666550,290.256134 168.250397,290.265137
	C168.248749,290.027008 168.509567,289.885468 168.872131,289.894470
z"
        />
        <path
          fill="#081018"
          opacity="1.000000"
          stroke="none"
          d="
M129.866425,330.203308
	C130.506302,331.726227 130.874039,333.489410 131.168900,335.635498
	C128.664841,334.680176 128.556763,332.716156 129.866425,330.203308
z"
        />
        <path
          fill="#193A47"
          opacity="1.000000"
          stroke="none"
          d="
M301.197266,704.872986
	C301.104980,704.044250 301.475433,703.349365 302.746643,703.216064
	C302.854523,704.075256 302.439301,704.715637 301.197266,704.872986
z"
        />
        <path
          fill="#372F35"
          opacity="1.000000"
          stroke="none"
          d="
M922.668945,650.730225
	C925.747742,654.128845 929.737305,655.188049 933.223267,657.072388
	C934.982483,658.023315 936.288574,659.030945 935.929993,661.279907
	C935.598999,663.355530 934.147522,663.663025 932.379761,664.061890
	C927.263428,665.216248 924.118347,669.473389 921.769531,673.308655
	C918.808838,678.142944 914.710632,682.118530 912.156372,687.195618
	C911.258484,688.980408 910.011047,690.559753 909.983093,692.670227
	C909.963928,694.117432 909.444397,695.417542 908.047363,695.887390
	C903.599548,697.383301 901.168518,701.036865 898.907166,704.652771
	C894.693787,711.390076 887.604004,714.820679 881.996887,719.939758
	C880.269043,721.517334 878.245361,722.673462 877.932556,725.308594
	C877.716125,727.130981 876.426147,728.083191 874.852539,728.919678
	C868.845398,732.112671 868.347900,733.375305 870.383850,739.303650
	C864.059448,740.528687 861.756592,746.527100 857.582397,750.285461
	C856.267273,751.469604 855.013916,752.786072 853.643860,753.975403
	C850.848450,756.402100 848.970154,756.966797 848.983521,752.199402
	C848.986694,751.058838 849.155518,749.675537 847.676392,749.300293
	C846.379211,748.971252 845.592957,750.062439 844.695984,750.797791
	C841.375183,753.520325 838.025757,756.207886 834.343567,758.955566
	C834.660034,752.587280 832.687378,746.310608 832.994080,739.460632
	C833.093506,738.782532 833.194397,738.557678 833.538818,738.056519
	C834.623413,737.145203 835.047241,736.314331 835.165039,735.252014
	C836.027649,727.472229 841.803406,724.340149 848.428101,721.660278
	C852.806458,722.857422 856.315491,721.052307 859.786011,719.593201
	C868.965027,715.733948 877.879822,711.344971 883.975159,703.032776
	C891.305359,693.036743 898.544434,682.952576 907.302979,674.150330
	C911.930786,669.499451 913.684021,663.350281 916.942871,658.002869
	C918.108215,656.090454 917.115967,654.131348 916.563416,651.842407
	C916.666077,651.208862 916.772034,650.964172 917.196655,650.463806
	C919.168335,649.422974 920.781189,649.405884 922.668945,650.730225
z"
        />
        <path
          fill="#473235"
          opacity="1.000000"
          stroke="none"
          d="
M834.261963,738.611572
	C833.832153,738.997192 833.664307,738.998718 833.244568,739.003784
	C829.775635,739.687988 827.293518,742.029663 826.860107,744.758545
	C825.977905,750.313049 820.959412,753.740173 820.276794,759.254395
	C820.097656,760.701843 818.866638,761.812073 817.304260,762.836792
	C815.105591,762.866394 813.001953,763.532776 811.659607,761.427429
	C810.578125,759.731079 809.092529,760.535461 807.348267,760.956421
	C806.087219,756.549255 809.377563,753.496216 810.511841,749.810303
	C812.310486,743.965759 814.560913,738.068237 814.983765,731.407288
	C814.515381,727.147156 815.587463,723.802246 818.185791,720.905273
	C823.653076,714.809692 823.381592,706.736389 825.492249,699.486267
	C826.313232,696.666016 826.252014,693.461731 829.565552,691.550049
	C832.199036,692.088684 832.776733,693.988159 832.713013,695.756653
	C832.217163,709.516052 835.790710,723.044922 834.959290,736.812683
	C834.928650,737.319519 834.703674,737.766479 834.261963,738.611572
z"
        />
        <path
          fill="#282429"
          opacity="1.000000"
          stroke="none"
          d="
M800.570251,141.000549
	C801.329041,142.426178 801.658142,143.851730 801.996643,145.646240
	C801.112915,146.814804 799.976624,146.817291 798.544312,146.994476
	C794.625610,147.009491 790.800659,145.726624 787.621643,149.288467
	C785.856140,150.703751 784.122986,151.455948 782.045166,152.287506
	C777.822510,153.872421 776.582031,156.849594 777.044006,161.109177
	C777.160950,162.202744 777.074036,162.929611 776.753784,164.002655
	C775.198181,166.071716 774.818848,168.149841 774.564697,170.268097
	C774.096375,174.172043 771.909363,177.208466 769.258179,180.422760
	C765.423340,181.823456 762.330688,180.844360 759.227661,178.443878
	C758.865295,175.619461 759.961731,174.063202 762.235352,173.053665
	C766.866089,170.997513 769.979370,167.942551 770.500916,162.366959
	C770.936218,157.712662 773.269104,153.609390 776.317200,149.923828
	C777.652222,148.309662 778.718689,146.818695 781.052856,146.934402
	C782.294739,146.995956 783.474609,146.385910 783.994446,145.337448
	C786.799438,139.679794 791.521301,139.825211 796.660583,140.889008
	C797.780273,141.120773 798.978516,140.973282 800.570251,141.000549
z"
        />
        <path
          fill="#A8A6A9"
          opacity="1.000000"
          stroke="none"
          d="
M758.582153,178.149048
	C762.072510,178.750656 765.134705,179.423111 768.603760,180.090378
	C773.884644,178.296707 777.816162,181.637726 782.557129,182.634552
	C786.114258,184.602829 789.832031,185.341980 793.228577,187.637909
	C794.700012,188.773041 795.956970,189.632446 796.108887,191.629013
	C794.312317,193.646057 792.410278,192.768448 790.212402,192.314392
	C789.659363,192.125244 789.443726,192.037598 788.921997,191.788513
	C787.728271,190.906021 786.758179,190.359909 785.262085,190.113785
	C782.187134,188.944885 779.266968,188.363754 776.344971,186.709061
	C771.376343,183.269302 766.001648,184.491852 760.929443,184.219788
	C758.372925,184.082642 755.755249,184.743500 752.783203,183.899246
	C751.081238,182.605103 750.830139,181.271790 751.811401,179.392502
	C752.383179,178.991089 752.613281,178.852081 752.870605,178.447601
	C754.680115,178.244705 756.416992,178.232285 758.582153,178.149048
z"
        />
        <path
          fill="#181C20"
          opacity="1.000000"
          stroke="none"
          d="
M750.026855,164.395325
	C752.107605,162.471527 749.326172,160.253967 750.861450,158.299438
	C754.516174,159.319916 756.022095,162.841919 754.485718,166.601776
	C753.928833,167.964523 752.913391,169.139969 752.891052,171.153229
	C750.509827,169.887299 751.194397,166.951828 750.026855,164.395325
z"
        />
        <path
          fill="#46170C"
          opacity="1.000000"
          stroke="none"
          d="
M863.590881,151.697098
	C874.711121,152.159866 885.448792,151.408661 896.548218,153.006332
	C904.355835,160.101715 904.888916,168.870972 903.181641,178.197815
	C903.062744,178.847214 902.977234,179.526794 903.029785,180.179962
	C904.028503,192.583328 901.601929,204.857071 901.762207,217.275955
	C901.919006,229.421036 900.377747,241.589447 898.409302,253.654633
	C897.668274,258.196106 898.108521,262.927185 897.965210,267.570435
	C897.874512,270.509857 897.969116,273.524384 895.212158,275.874695
	C894.644104,275.694824 894.452454,275.453247 894.049316,274.799103
	C893.768616,274.084717 893.699402,273.782928 893.583679,273.018433
	C894.141846,255.001205 894.628784,237.441711 896.410706,219.949509
	C896.947815,214.677261 896.912720,209.349258 897.189026,204.069046
	C897.812622,192.150848 899.313477,180.285629 899.461548,168.351212
	C899.579163,158.877975 896.033386,155.425583 886.504150,155.519653
	C867.536499,155.706894 848.570251,155.302734 829.596313,155.775925
	C822.317871,155.957443 814.991028,156.279556 807.685303,155.946793
	C805.687256,155.855789 803.679932,155.891678 801.472412,154.600266
	C800.690552,153.758209 800.526245,153.151169 800.825684,152.044388
	C807.747253,148.893661 814.478638,150.697983 821.610962,151.708008
	C826.033142,151.977554 830.045410,151.979645 834.519531,151.980560
	C839.301819,150.066849 843.829773,151.158920 848.262207,151.150787
	C853.232178,151.141663 858.224609,150.454834 863.590881,151.697098
z"
        />
        <path
          fill="#A19796"
          opacity="1.000000"
          stroke="none"
          d="
M894.134766,278.632996
	C893.323242,278.994934 892.514893,279.052551 891.316345,279.217651
	C890.067566,280.019623 889.095459,280.200073 887.672852,279.821594
	C887.116394,279.514343 886.918396,279.350006 886.563354,278.833496
	C886.395203,278.224609 886.384155,277.967834 886.493530,277.344727
	C886.787109,276.788422 886.960205,276.598450 887.465088,276.212219
	C888.274902,275.825836 888.769836,275.705139 889.660278,275.629242
	C890.542969,275.593597 891.046387,275.611420 891.919189,275.723969
	C892.768372,275.958313 893.189209,276.219238 893.810181,276.843109
	C894.091858,277.517548 894.114929,277.923096 894.134766,278.632996
z"
        />
        <path
          fill="#A5654C"
          opacity="1.000000"
          stroke="none"
          d="
M894.144897,276.953491
	C893.547119,277.036316 893.025391,276.960602 892.116577,276.805298
	C891.595825,276.547028 891.462036,276.368378 891.243530,275.864685
	C891.153259,275.085693 891.236938,274.648804 891.617249,273.963013
	C892.433228,273.312622 893.076904,273.252167 894.012939,273.695068
	C894.338501,274.145599 894.404236,274.383759 894.447388,274.991821
	C894.387268,275.557281 894.349792,275.752838 894.182495,276.217346
	C894.052612,276.486298 894.220947,276.794983 894.144897,276.953491
z"
        />
        <path
          fill="#CD7D4C"
          opacity="1.000000"
          stroke="none"
          d="
M894.244141,276.342773
	C894.122253,276.229340 894.196594,275.973724 894.350098,275.540588
	C894.424683,275.361725 894.634338,275.646179 894.735046,275.791290
	C894.835754,275.936401 894.435730,276.199280 894.244141,276.342773
z"
        />
        <path
          fill="#3E1E1C"
          opacity="1.000000"
          stroke="none"
          d="
M821.919067,151.615753
	C815.354248,151.989243 808.687683,152.003006 801.558594,152.019882
	C798.227600,151.238602 797.464172,149.414581 798.734863,146.365814
	C799.727295,144.966064 800.593567,145.304947 801.739502,145.896545
	C805.108154,148.552673 808.837708,148.892258 812.614075,148.983871
	C815.860168,149.062622 819.137329,148.923264 821.919067,151.615753
z"
        />
        <path
          fill="#3E1E1C"
          opacity="1.000000"
          stroke="none"
          d="
M863.886597,151.585022
	C854.643677,151.973434 845.283081,151.984177 835.451904,151.987152
	C835.301147,150.319443 836.497131,150.005249 837.942139,150.008621
	C845.872620,150.027084 853.803162,150.026230 861.733643,150.048294
	C862.601685,150.050705 863.336243,150.389694 863.886597,151.585022
z"
        />
        <path
          fill="#557E8B"
          opacity="1.000000"
          stroke="none"
          d="
M797.973633,84.023430
	C797.890259,90.157997 797.677002,96.274033 797.832947,102.380638
	C797.931702,106.246368 796.078430,107.183952 792.671387,107.075691
	C787.718201,106.918289 782.756226,107.063065 777.798706,107.011581
	C771.659546,106.947823 771.247925,106.470673 771.404053,99.588089
	C771.668396,98.645226 771.904846,98.113625 771.898071,97.236801
	C772.074402,83.860191 772.074402,83.846184 785.158569,83.862152
	C789.114136,83.866982 793.069519,83.972939 797.489563,84.043221
	C797.954102,84.053871 798.000000,84.000908 797.973633,84.023430
z"
        />
        <path
          fill="#478D9B"
          opacity="1.000000"
          stroke="none"
          d="
M728.735596,71.607574
	C739.355591,70.898582 749.791870,70.727966 760.611938,71.826141
	C761.008545,72.380684 761.000671,72.836365 760.927368,73.627403
	C760.632324,74.173248 760.402588,74.383720 759.768860,74.829239
	C749.629333,74.038391 739.764587,77.302742 729.690918,73.938278
	C728.795410,73.253922 728.550049,72.703011 728.735596,71.607574
z"
        />
        <path
          fill="#246876"
          opacity="1.000000"
          stroke="none"
          d="
M759.411926,74.333038
	C759.960144,74.157707 760.139954,74.115036 760.590942,74.017570
	C760.885254,74.728294 760.908508,75.493813 760.892517,76.641174
	C758.327759,76.451462 755.443970,78.140060 753.077454,75.445862
	C754.890137,73.927284 757.027283,74.793213 759.411926,74.333038
z"
        />
        <path
          fill="#246876"
          opacity="1.000000"
          stroke="none"
          d="
M760.816650,78.769806
	C758.606140,79.034996 756.424072,79.034996 754.241943,79.034996
	C754.249084,78.688721 754.256226,78.342445 754.263367,77.996170
	C756.178589,77.996170 758.093811,77.996170 760.415222,77.983948
	C760.829285,78.149353 760.837219,78.326981 760.816650,78.769806
z"
        />
        <path
          fill="#246876"
          opacity="1.000000"
          stroke="none"
          d="
M771.675415,97.181679
	C772.763000,97.594643 772.890442,98.263474 771.712891,99.078278
	C771.483459,98.599022 771.479004,98.006714 771.675415,97.181679
z"
        />
        <path
          fill="#246876"
          opacity="1.000000"
          stroke="none"
          d="
M759.795044,94.739014
	C759.473328,94.768860 759.407104,94.515961 759.672852,94.120430
	C759.784058,94.022758 759.829773,94.501213 759.795044,94.739014
z"
        />
        <path
          fill="#67BFC6"
          opacity="1.000000"
          stroke="none"
          d="
M728.503662,71.125107
	C728.932739,71.577003 728.948120,71.980042 728.982056,72.685379
	C726.414246,75.665955 723.109924,74.158012 720.129578,74.453674
	C718.182678,74.646812 716.198914,74.481293 713.819580,74.484451
	C710.419250,74.727188 707.430664,74.374275 704.442749,74.487495
	C699.224609,74.685234 695.006592,77.151581 690.946594,80.191154
	C683.915222,85.455238 677.190247,91.165512 669.162109,95.661209
	C668.691528,96.073318 668.475830,96.205673 667.885498,96.402672
	C666.989136,96.394386 666.557190,96.157631 666.059326,95.411530
	C665.922729,94.287148 665.942261,93.507996 665.979492,92.339066
	C672.413757,88.772278 678.210571,84.629784 683.487061,79.869133
	C692.026367,72.164627 701.853821,69.121925 713.220764,70.312767
	C718.149719,70.829140 723.131897,70.837273 728.503662,71.125107
z"
        />
        <path
          fill="#3C7D8E"
          opacity="1.000000"
          stroke="none"
          d="
M734.208008,77.648987
	C734.000000,78.010323 734.017273,78.464066 734.028442,78.690826
	C733.886597,79.350716 733.438232,79.558739 733.232178,79.925644
	C732.907532,80.503792 733.316711,80.785492 733.120667,80.148300
	C732.924561,79.511124 734.177002,79.818596 733.838623,79.999634
	C737.192261,79.972809 740.244873,80.068550 743.619995,79.864067
	C744.136536,79.680809 744.329041,79.570961 744.763184,79.232803
	C745.863586,79.176582 746.722351,79.348656 747.804077,79.768280
	C748.965942,82.711189 748.322327,85.485435 748.215210,88.627563
	C747.987976,89.458008 747.981812,89.882805 747.974121,90.626244
	C748.213135,92.803024 748.829651,94.629089 748.210938,96.801636
	C747.971863,97.063156 747.965881,97.500534 747.965942,97.719246
	C746.702393,104.157288 749.222351,110.658546 746.244995,117.066444
	C745.361084,117.556976 744.790405,117.568161 743.859497,117.133659
	C742.676819,115.758995 742.386230,114.458870 742.569885,113.150719
	C743.412231,107.149002 743.223145,101.120033 743.220947,94.705215
	C744.107117,91.129890 739.752563,89.258736 741.143921,85.931992
	C741.205261,85.785515 741.538513,85.857910 741.442810,85.574135
	C739.678406,86.706017 737.223572,86.324806 735.600464,88.471039
	C733.451660,93.071762 735.509644,97.639374 734.487854,102.007454
	C733.240540,107.340004 734.593811,112.208595 737.152344,117.127014
	C735.158264,118.820717 733.189941,118.595184 730.839966,118.062691
	C727.118164,114.733536 728.572815,110.762733 728.958923,107.122414
	C729.185120,104.990730 729.556702,102.882690 729.488525,100.262947
	C726.899719,101.984749 723.941711,101.001900 720.964539,102.206024
	C718.555298,102.469017 716.652405,102.225800 714.912354,100.307388
	C714.320251,95.209801 714.559448,90.462990 714.602295,85.352852
	C715.078735,82.635010 716.150574,80.278343 715.410950,78.873322
	C716.313477,80.016846 715.017151,82.190521 714.757080,84.685638
	C714.516113,90.442421 714.352539,95.808548 714.949463,101.227928
	C715.390381,105.231239 714.109009,109.303223 714.015686,113.394135
	C713.983643,114.800270 712.816406,115.693291 710.827332,115.119476
	C709.012329,112.495613 709.056946,109.750305 709.093628,107.181412
	C709.235291,97.269753 710.491882,87.402840 710.470154,77.476486
	C710.468689,76.814316 710.769592,76.189911 711.299927,75.221428
	C714.896057,73.244179 718.329224,74.263412 721.759094,74.430580
	C724.893616,74.583336 728.055359,74.738266 731.613342,74.234657
	C732.889038,74.479599 733.572021,74.946243 734.224121,76.068184
	C734.394531,76.732201 734.405273,77.009926 734.208008,77.648987
z"
        />
        <path
          fill="#478D9B"
          opacity="1.000000"
          stroke="none"
          d="
M743.538696,116.594337
	C744.377014,116.915451 744.854553,116.935875 745.690552,116.955276
	C748.003235,115.421875 750.132080,115.951210 752.595642,116.746872
	C752.932312,117.636688 752.929932,118.207878 752.900391,119.056427
	C740.608521,119.596169 728.343750,119.858551 715.623169,120.167305
	C713.574219,120.136734 713.273560,119.115974 713.554688,117.833366
	C713.893799,116.285995 715.194275,115.736778 716.529785,115.713837
	C720.771973,115.640968 725.068359,114.791466 729.624146,116.691238
	C732.084045,116.987900 734.145630,116.971603 736.648682,116.924973
	C738.974915,115.231552 741.009399,115.084328 743.538696,116.594337
z"
        />
        <path
          fill="#0E505B"
          opacity="1.000000"
          stroke="none"
          d="
M733.342896,75.358253
	C726.319641,77.199242 719.725647,74.670410 712.699829,75.125107
	C711.772705,74.876335 711.533875,74.394730 711.771729,73.408890
	C717.342773,73.023766 722.703735,73.010597 728.532715,72.992554
	C738.910339,74.765152 748.936707,73.499939 759.335327,74.167000
	C757.936951,75.606209 755.778137,74.732330 753.369629,75.056183
	C752.232361,75.082024 751.506958,75.081902 750.419067,75.107475
	C744.680664,77.221581 739.233337,76.925842 733.342896,75.358253
z"
        />
        <path
          fill="#246876"
          opacity="1.000000"
          stroke="none"
          d="
M753.314270,117.025673
	C750.925781,117.044388 748.916870,117.023285 746.478516,116.978210
	C746.514465,110.830856 746.979919,104.707474 747.705688,98.261024
	C750.410950,98.338356 753.088684,97.357086 755.548706,98.843422
	C754.667786,99.998932 753.882446,99.767113 753.080994,99.519630
	C751.487915,99.027718 749.540588,98.298782 748.540466,99.957413
	C747.500305,101.682213 747.571045,103.789124 749.497009,105.284592
	C750.588501,106.132149 750.763672,106.983635 749.833618,108.209106
	C747.952271,110.687889 750.676636,110.626358 751.831726,111.431267
	C750.950012,112.693558 747.727783,111.274818 748.501526,113.757133
	C749.185364,115.950691 751.166687,114.791504 752.584900,113.916023
	C753.627625,113.272362 754.881897,112.617432 755.587952,113.960274
	C756.487122,115.670502 755.642151,116.795578 753.314270,117.025673
z"
        />
        <path
          fill="#246876"
          opacity="1.000000"
          stroke="none"
          d="
M732.985657,75.370499
	C738.324097,75.081100 743.718262,75.067940 749.584412,75.093971
	C749.884521,75.995529 749.553955,76.727898 748.269104,76.984932
	C747.185852,77.625771 746.278503,77.459824 744.964600,77.495277
	C741.267029,77.464256 738.011230,77.561974 734.373779,77.213158
	C733.674316,76.540672 733.357788,76.093704 732.985657,75.370499
z"
        />
        <path
          fill="#246876"
          opacity="1.000000"
          stroke="none"
          d="
M748.257690,97.041672
	C748.365662,95.151329 746.551453,93.421448 747.741577,91.203964
	C749.921936,94.515556 753.494324,94.130669 756.838501,94.504387
	C754.011658,95.381454 750.632202,94.066032 748.257690,97.041672
z"
        />
        <path
          fill="#478D9B"
          opacity="1.000000"
          stroke="none"
          d="
M744.546631,78.999207
	C745.002747,79.174408 745.000671,79.344315 744.986877,79.768845
	C741.338318,81.019363 737.635498,80.291100 733.542297,80.258545
	C732.347290,79.320084 732.910034,79.130989 733.808594,78.951897
	C737.389221,78.943039 740.738770,78.968475 744.546631,78.999207
z"
        />
        <path
          fill="#246876"
          opacity="1.000000"
          stroke="none"
          d="
M748.259766,88.745285
	C747.997742,86.326530 748.001282,83.619858 748.015991,80.464508
	C748.761902,80.117760 750.339050,80.138893 749.890808,80.782730
	C748.194336,83.219368 749.776917,86.072441 748.259766,88.745285
z"
        />
        <path
          fill="#478D9B"
          opacity="1.000000"
          stroke="none"
          d="
M733.992493,77.243317
	C737.344543,77.003883 740.698181,77.020126 744.507507,77.049896
	C745.163208,77.212212 745.363220,77.361000 745.276733,77.750122
	C741.632080,77.996170 738.273865,78.001900 734.457886,78.008972
	C733.998047,77.839882 733.996094,77.669441 733.992493,77.243317
z"
        />
        <path
          fill="#246876"
          opacity="1.000000"
          stroke="none"
          d="
M754.229614,106.265785
	C754.811646,106.507233 755.121338,106.809906 755.431091,107.112579
	C755.048645,107.323517 754.614746,107.769539 754.293823,107.699310
	C753.478149,107.520752 753.254089,106.986649 754.229614,106.265785
z"
        />
        <path
          fill="#246876"
          opacity="1.000000"
          stroke="none"
          d="
M756.751099,96.352890
	C756.044128,97.216743 755.142151,97.061356 754.137939,96.630951
	C754.835205,95.604942 755.761047,96.128922 756.751099,96.352890
z"
        />
        <path
          fill="#3C7D8E"
          opacity="1.000000"
          stroke="none"
          d="
M745.321533,77.992310
	C744.990234,77.990448 744.989624,77.525986 744.976379,77.294701
	C745.712585,77.046799 746.462036,77.030182 747.584473,77.025024
	C747.396179,77.853500 746.551331,77.988304 745.321533,77.992310
z"
        />
        <path
          fill="#5D413D"
          opacity="1.000000"
          stroke="none"
          d="
M776.414795,185.371582
	C779.585144,186.450851 782.397949,187.317230 785.408691,188.469116
	C786.480652,189.538956 787.651672,189.805450 788.895142,190.640854
	C789.195068,190.813034 789.269226,190.601715 789.379517,190.621155
	C788.885437,198.780396 786.940186,200.371994 779.263977,198.866104
	C776.936646,198.409546 774.656738,198.355301 771.954956,199.036591
	C764.077637,197.789658 756.715210,198.959824 749.356445,200.078293
	C745.926270,200.599625 743.944702,199.214432 743.304016,195.813522
	C742.627625,192.222595 742.043030,188.606598 741.117920,185.072617
	C737.545227,171.424561 732.008423,158.793686 721.969421,148.519119
	C718.448242,144.915375 713.934814,142.653854 709.758301,139.302612
	C705.123352,135.923050 702.097839,131.127808 695.526184,131.005173
	C691.827332,130.936157 688.461548,127.265213 684.109375,127.404198
	C683.016602,127.439095 682.364502,126.030716 682.494263,124.379044
	C683.760010,122.270058 686.069397,122.140602 687.744568,120.448410
	C688.832581,120.001640 689.602051,119.952217 690.766541,120.142181
	C692.200256,120.683083 693.160583,121.663895 694.343994,121.436287
	C703.397095,119.695114 710.748291,124.735184 718.206360,128.129349
	C724.054932,130.791046 728.927917,135.653931 733.812012,140.657349
	C734.098633,140.915100 734.075500,140.977646 734.055908,140.950638
	C734.411987,141.443054 735.009583,141.490845 735.807739,141.790146
	C736.056152,141.939957 736.041382,141.999588 736.058228,141.971832
	C735.938477,145.377472 738.843689,147.320023 740.298279,149.889160
	C744.528076,157.360168 746.379944,165.651398 749.680176,173.771637
	C749.891541,174.043045 749.961670,174.483231 749.976379,174.705612
	C750.418762,175.370148 750.484131,175.947922 750.744202,176.752441
	C751.146179,177.551071 751.388245,178.109924 751.869019,178.861923
	C752.143005,180.195877 752.178284,181.336685 752.403320,182.765610
	C754.709106,184.843826 757.079468,184.294357 759.576782,184.147278
	C765.083679,183.822983 770.669006,183.184738 776.414795,185.371582
z"
        />
        <path
          fill="#403D45"
          opacity="1.000000"
          stroke="none"
          d="
M734.186157,140.557343
	C729.155884,140.327347 726.253296,136.246979 722.670288,133.641663
	C714.919678,128.005890 706.963074,123.600540 697.221619,122.967072
	C695.079651,122.827782 692.442932,124.089417 690.883667,120.841385
	C699.546265,119.232895 708.373901,117.591911 716.532532,123.856926
	C721.654968,127.795906 726.751221,131.085297 730.986938,135.457733
	C732.334839,136.849091 734.008362,138.045258 734.186157,140.557343
z"
        />
        <path
          fill="#81A2AC"
          opacity="1.000000"
          stroke="none"
          d="
M716.994385,124.103180
	C708.877075,120.047867 700.237366,120.765984 691.268677,120.410126
	C690.098572,120.395599 689.424011,120.375610 688.410034,120.400246
	C682.935669,122.585098 679.687439,122.019104 677.284180,118.143982
	C678.583313,115.155327 680.944580,116.401184 683.236389,116.128082
	C685.196350,116.081909 686.808411,115.165047 688.727600,116.287338
	C689.544434,116.439667 690.023499,116.463745 690.878052,116.395920
	C693.874939,116.169456 696.498169,116.140884 699.520142,116.399628
	C702.924988,116.494835 705.907043,116.781670 709.264771,117.417084
	C711.864502,117.547462 712.948303,119.312477 714.694275,120.804100
	C715.851379,121.604866 716.687134,122.150085 717.749634,122.897102
	C717.728333,123.381546 717.480347,123.664192 716.994385,124.103180
z"
        />
        <path
          fill="#403D45"
          opacity="1.000000"
          stroke="none"
          d="
M749.940674,173.643311
	C747.918823,173.634216 747.652161,171.962860 747.018616,170.424164
	C744.514648,164.342178 743.867737,157.589432 739.207092,152.209412
	C737.685547,150.453079 737.750122,147.181091 735.017273,145.528793
	C733.828369,144.809998 735.798706,143.644608 735.998108,142.248108
	C743.255554,149.367386 746.227722,158.836441 748.920593,168.433212
	C749.363708,170.012314 749.637634,171.638916 749.940674,173.643311
z"
        />
        <path
          fill="#403D45"
          opacity="1.000000"
          stroke="none"
          d="
M735.922607,141.644287
	C735.493896,141.770065 734.931702,141.600189 734.202881,141.176971
	C734.620544,141.065292 735.204834,141.206955 735.922607,141.644287
z"
        />
        <path
          fill="#403D45"
          opacity="1.000000"
          stroke="none"
          d="
M750.910645,176.690002
	C750.614868,176.501648 750.325562,176.011063 750.013672,175.224228
	C750.299805,175.414581 750.608521,175.901184 750.910645,176.690002
z"
        />
        <path
          fill="#246876"
          opacity="1.000000"
          stroke="none"
          d="
M715.069336,120.851013
	C713.592407,120.474144 712.169250,119.888634 710.426880,119.222519
	C708.899414,117.907715 709.324951,116.726692 710.553833,115.328903
	C712.562195,114.885323 712.960632,113.755280 712.933533,112.285507
	C712.771240,103.497406 715.066223,94.780655 713.708191,85.951805
	C713.639954,85.508492 713.947876,85.007309 714.196594,84.166458
	C714.537354,83.722038 714.762207,83.644234 715.326172,83.677948
	C714.570801,88.892975 715.067017,94.048935 715.220520,99.661804
	C715.627258,102.434189 716.465820,103.861885 719.297424,103.585617
	C721.557678,103.365112 723.940857,103.118690 725.140991,103.486633
	C722.851013,103.309242 719.386169,103.739487 716.004883,102.807663
	C719.268738,100.544792 722.935608,102.029671 725.943665,101.515686
	C724.809265,101.370575 723.233215,101.611656 721.362549,101.181808
	C721.796448,100.645866 722.577393,100.014763 723.281555,100.091217
	C726.743652,100.467125 729.478394,99.925385 729.937439,95.429703
	C732.356506,102.535118 729.245239,109.320419 730.030884,116.546730
	C725.767395,117.003922 721.512085,116.972694 717.257629,117.018944
	C715.602722,117.036942 713.652039,116.937447 714.910217,119.935150
	C715.167358,120.213684 715.123169,120.642365 715.069336,120.851013
z"
        />
        <path
          fill="#33444C"
          opacity="1.000000"
          stroke="none"
          d="
M518.687134,65.038155
	C521.076050,67.596947 519.038635,71.025513 520.976807,74.041672
	C521.926147,76.315750 520.535583,77.257790 519.378296,78.111687
	C512.511902,83.178032 506.233704,88.853127 500.851349,95.499428
	C500.003571,96.546234 498.899323,97.319916 497.107056,97.227158
	C495.258270,95.186646 495.986938,93.355713 497.007050,91.563225
	C497.907532,89.980995 498.964325,88.473030 499.164551,86.220680
	C499.817444,83.007477 502.621674,82.806625 504.750427,81.237762
	C505.125671,78.320679 507.573853,77.763924 509.201630,76.575073
	C511.461456,74.924591 513.799927,73.438637 514.992859,70.384102
	C517.583191,69.527824 516.873352,67.470673 517.058655,65.446976
	C517.502563,65.054802 517.933044,65.047585 518.687134,65.038155
z"
        />
        <path
          fill="#2F252B"
          opacity="1.000000"
          stroke="none"
          d="
M1059.000000,700.460205
	C1060.360962,700.217041 1061.046997,701.066711 1060.954712,702.390442
	C1060.655029,706.690369 1063.325562,706.923523 1066.652832,707.085632
	C1070.598633,707.277893 1072.234375,706.171753 1072.138550,701.907471
	C1072.058350,698.326538 1073.101807,694.716431 1076.781250,692.268677
	C1077.335571,691.653198 1077.732300,691.404541 1078.555176,691.250305
	C1082.237427,692.029968 1085.558960,695.387207 1089.228271,691.078735
	C1093.220093,689.772339 1096.933594,688.763672 1100.742065,688.389832
	C1104.723633,687.999146 1106.996338,686.197021 1108.081543,682.216614
	C1109.959229,675.327942 1112.046997,668.545410 1117.053711,662.967712
	C1119.915161,659.779907 1120.274658,655.375366 1120.249878,651.145447
	C1120.227173,647.274902 1120.618896,643.501831 1122.262329,639.938171
	C1122.540527,639.334900 1122.852051,638.704346 1122.900391,638.051819
	C1124.260986,619.662292 1116.516235,599.506226 1092.321289,599.498657
	C1089.824463,599.497864 1087.327515,599.517029 1084.427856,599.464844
	C1083.199341,599.401306 1082.391235,599.276306 1081.298828,598.660583
	C1079.355225,595.530579 1082.086182,594.459290 1083.673584,592.529419
	C1085.159424,591.823364 1086.252808,591.908997 1087.658569,592.743896
	C1089.583862,595.880981 1092.386353,595.186646 1094.702515,594.895935
	C1104.637085,593.649048 1112.571655,597.420654 1120.183594,603.463867
	C1126.217163,608.254089 1129.479126,613.944153 1130.144897,621.467834
	C1130.419067,624.565918 1133.267334,626.738892 1133.098389,630.283936
	C1132.544434,641.898132 1130.465576,653.337280 1129.524902,664.899475
	C1129.200439,668.886719 1128.362793,672.694397 1126.007446,676.090637
	C1124.565308,678.169922 1125.119995,680.445740 1125.692261,682.809692
	C1126.496582,686.132385 1125.698730,688.650879 1121.401489,687.632751
	C1117.829224,686.786377 1117.112915,689.549561 1116.323975,691.789490
	C1115.337402,694.589844 1114.416260,697.527100 1114.206177,700.458130
	C1113.946411,704.082031 1112.056274,705.882385 1108.947876,706.859009
	C1107.387695,707.349182 1105.957153,708.098145 1106.105713,709.845337
	C1106.839966,718.481934 1100.610718,720.505371 1094.302734,721.772217
	C1091.235718,722.388184 1089.960449,723.740112 1089.372803,726.361938
	C1088.579102,729.903564 1086.379517,731.301086 1082.841797,731.024170
	C1081.020874,730.881592 1079.177124,731.061218 1077.348267,730.983765
	C1071.472900,730.734924 1069.418701,727.868530 1071.112305,722.187500
	C1071.761108,720.011169 1072.554932,718.207397 1069.455933,717.018738
	C1058.965820,712.995300 1059.000000,712.906372 1059.000000,700.460205
z"
        />
        <path
          fill="#1C2024"
          opacity="1.000000"
          stroke="none"
          d="
M1088.000000,592.665405
	C1086.933350,592.999634 1085.866577,593.001709 1084.399902,593.006775
	C1082.998413,593.804932 1081.976807,593.877197 1080.702393,592.740723
	C1080.322144,591.830200 1080.341064,591.279358 1080.779297,590.395142
	C1082.409790,590.039062 1083.791992,590.022339 1085.588989,590.006958
	C1086.475342,590.318359 1086.946899,590.628357 1087.709473,590.971619
	C1088.000366,591.447693 1088.000244,591.890503 1088.000000,592.665405
z"
        />
        <path
          fill="#2E252C"
          opacity="1.000000"
          stroke="none"
          d="
M1080.763672,591.785522
	C1081.577637,592.820129 1082.424561,592.981323 1083.666748,593.002136
	C1084.158813,595.194153 1081.745361,595.672729 1081.063477,597.669678
	C1079.920532,599.742310 1078.060791,599.949890 1076.312378,600.438843
	C1070.255981,602.132080 1064.352539,604.165161 1058.510742,606.592957
	C1050.231812,610.033691 1041.586548,612.201904 1034.148682,604.253967
	C1033.123535,603.158569 1031.704712,602.329041 1030.240601,602.184448
	C1024.843506,601.651428 1021.236877,596.985962 1015.056885,596.964539
	C1010.512390,596.948792 1005.148071,595.034668 999.917603,593.361450
	C990.338562,590.060730 984.373474,582.106628 976.234009,577.323120
	C974.045471,576.036926 973.380066,573.645691 973.445618,571.231384
	C973.550842,567.355835 972.886719,563.653931 971.604187,559.647339
	C976.936523,555.820129 982.245667,556.529724 985.899170,561.560120
	C987.020935,564.188660 989.565247,564.315186 991.111145,565.765076
	C992.937561,567.478149 994.287598,569.216614 990.394592,570.114746
	C988.977417,570.441650 987.208496,570.783813 987.280884,572.722961
	C987.348999,574.551086 988.886108,576.180237 990.283081,576.364441
	C998.252747,577.415894 1003.949097,582.950867 1010.611877,586.470703
	C1017.852539,590.295898 1025.672852,591.357361 1033.420410,592.776489
	C1039.770386,593.939514 1045.213501,597.683350 1051.667847,598.420898
	C1055.810669,598.894287 1059.762817,599.265686 1062.945312,595.378906
	C1065.562744,593.057434 1068.785034,594.526367 1071.805908,593.177002
	C1072.935913,592.530151 1073.813477,592.158508 1074.810669,591.315918
	C1075.813965,591.091919 1076.540405,591.082092 1077.630127,591.075562
	C1078.877075,591.030151 1079.760620,590.981201 1080.763672,591.785522
z"
        />
        <path
          fill="#1C2024"
          opacity="1.000000"
          stroke="none"
          d="
M1047.016846,592.590576
	C1046.195679,592.985657 1045.358398,593.064514 1044.541260,592.970093
	C1042.158569,592.694641 1039.219482,591.810120 1039.556274,589.357605
	C1039.858765,587.155090 1042.601074,589.576904 1044.240234,589.028625
	C1044.834473,588.829773 1045.554077,589.006104 1046.638062,589.023438
	C1047.042969,590.088867 1047.026489,591.142029 1047.016846,592.590576
z"
        />
        <path
          fill="#1C2024"
          opacity="1.000000"
          stroke="none"
          d="
M1073.194458,592.761353
	C1070.666748,596.872009 1066.899902,594.177979 1063.456909,595.006958
	C1062.544189,594.999268 1062.078491,594.991272 1061.262451,594.965088
	C1063.946533,591.237183 1068.600464,593.719666 1072.578369,592.001587
	C1073.129639,592.167297 1073.259277,592.336975 1073.194458,592.761353
z"
        />
        <path
          fill="#11181F"
          opacity="1.000000"
          stroke="none"
          d="
M1081.001953,591.689087
	C1080.151611,591.856995 1079.303345,591.697632 1078.224243,591.308594
	C1078.547852,590.213928 1079.405029,590.031067 1080.686401,590.045349
	C1081.019775,590.491150 1081.011841,590.926453 1081.001953,591.689087
z"
        />
        <path
          fill="#11181F"
          opacity="1.000000"
          stroke="none"
          d="
M1073.308838,592.960571
	C1073.000000,592.846191 1073.000000,592.676514 1073.000000,592.252136
	C1073.493408,591.696045 1073.986816,591.394531 1074.783936,591.097412
	C1074.910156,591.957703 1074.544312,592.660095 1073.308838,592.960571
z"
        />
        <path
          fill="#20323A"
          opacity="1.000000"
          stroke="none"
          d="
M262.768494,225.737961
	C263.174744,229.403275 262.572205,232.876862 263.895874,236.599640
	C260.033112,235.614182 256.469452,229.485718 257.470367,225.307098
	C258.007233,223.065735 258.061249,221.411850 255.736374,220.337173
	C254.260361,219.654861 253.684265,218.285355 253.085709,216.872299
	C250.529434,210.837494 246.471542,209.021637 240.479584,211.255814
	C237.821945,212.246750 235.488251,212.196762 232.912643,210.658249
	C228.803131,208.203415 223.889618,207.708801 219.444824,208.299927
	C209.459152,209.627945 199.503677,208.596832 189.546677,208.922592
	C188.294022,208.963577 186.862793,208.493820 185.779999,207.828354
	C182.839966,206.021484 180.014618,205.893158 176.773438,207.133530
	C173.433823,208.411591 170.153748,209.350449 168.549545,204.155426
	C167.705856,201.423187 164.571976,201.715393 162.017029,202.531738
	C156.058899,204.435440 150.343491,205.353745 144.465057,201.592621
	C141.723846,199.838730 137.934006,201.086700 134.623657,201.035629
	C131.823181,200.992416 129.646851,200.264771 129.041122,197.236603
	C128.540451,194.733688 126.710007,194.270370 124.625259,194.022659
	C121.830521,193.690552 119.045509,193.270660 116.246803,192.979980
	C114.562767,192.805084 113.197632,192.147141 113.130165,190.374969
	C112.976952,186.349884 110.277824,186.013107 107.248161,186.002243
	C105.082909,185.994476 102.917496,185.972488 100.752434,185.990387
	C98.718491,186.007187 96.549538,185.179398 96.469170,183.312683
	C96.248589,178.188843 90.985001,176.169189 90.463364,171.410995
	C88.529671,172.341324 89.184662,173.727020 88.844994,174.748169
	C87.982529,177.340912 85.862381,177.055847 83.887321,176.907211
	C81.515877,176.728699 81.015579,175.038925 81.013573,173.004181
	C81.011559,170.968628 81.537247,169.026505 83.874664,169.135590
	C88.765457,169.363876 89.299690,166.527206 89.026199,162.734390
	C88.882866,160.746475 88.922287,158.732880 89.057030,156.742905
	C89.120827,155.800827 89.525337,154.115005 89.905937,154.077118
	C94.992348,153.570938 98.861259,148.141907 104.817650,150.820862
	C110.404259,153.333496 112.327194,151.977646 113.009010,146.025925
	C113.472084,141.983551 116.097801,140.804779 120.183144,142.805252
	C126.777687,146.034393 126.685593,145.999435 129.157028,139.490128
	C130.594467,135.704178 133.779907,136.371368 136.150070,137.932983
	C139.499893,140.140091 142.108688,140.361145 144.888657,137.256516
	C145.907318,136.118881 147.555634,136.031296 149.083008,135.935135
	C150.449066,135.849136 151.795105,135.423859 152.510956,134.189957
	C153.503815,132.478577 152.494843,129.546722 155.578705,129.120270
	C162.564560,128.154236 169.189438,123.516762 176.589188,126.853973
	C179.826614,128.314011 181.909103,127.327576 185.057358,125.623703
	C190.336609,122.766525 195.333405,118.622314 202.803726,120.367302
	C207.558151,121.477898 213.324677,119.023537 217.314957,115.297363
	C221.005737,111.850868 223.512726,112.039391 224.997375,116.889801
	C225.834305,119.624039 227.806671,119.920799 230.010818,119.973824
	C236.811707,120.137367 243.619980,119.675964 250.397003,120.924561
	C253.557175,121.506783 256.410309,120.754478 257.066772,116.744812
	C257.470673,114.277733 259.102081,113.030495 261.633972,113.038567
	C264.283722,113.047012 264.850281,114.735298 265.078217,116.981789
	C265.392914,120.083633 267.544678,120.890259 270.377350,121.037270
	C275.063995,121.280495 279.637451,120.420906 284.260162,120.017891
	C292.586670,119.291977 300.802307,121.557419 309.132263,121.030724
	C310.690521,120.932198 312.022919,121.345230 312.777740,123.022697
	C315.613739,129.325699 318.663544,130.545761 325.252960,128.047638
	C326.636658,127.523064 327.930450,126.761436 329.631622,126.053772
	C330.625488,129.400864 331.253387,132.803238 331.941284,136.601273
	C335.925385,144.433289 335.219788,152.293732 334.057037,160.173035
	C333.842316,161.628159 333.076965,162.983444 332.916443,164.900620
	C333.135071,169.020538 332.307495,171.920654 327.910004,172.417374
	C323.772766,172.884689 321.714966,170.878494 321.024536,166.979721
	C320.609253,164.634674 319.409912,162.636490 317.917816,160.801788
	C316.370178,158.898727 315.538910,156.761703 316.242096,154.305603
	C317.060059,151.448639 315.723175,150.170685 313.091736,149.993195
	C310.464447,149.816010 307.741241,149.321381 306.916687,153.181961
	C304.872467,162.753387 304.777405,162.849594 295.043396,162.720917
	C286.752228,162.611298 278.487885,162.463623 270.174896,161.719299
	C263.782166,161.146896 256.995483,161.680954 250.420547,162.088852
	C244.938690,162.428925 239.471451,162.735275 233.981949,162.764709
	C230.850723,162.781479 227.836044,162.546921 225.472580,165.644547
	C223.812851,167.819824 221.524353,166.411713 219.810776,164.896500
	C212.459000,158.395767 203.616104,157.147583 194.668961,161.390350
	C193.917282,161.746811 193.143326,162.077911 192.442108,162.514908
	C190.979340,163.426468 188.285263,163.396744 188.518997,165.699966
	C188.760605,168.080917 191.202744,166.847855 192.665619,167.188110
	C196.207794,168.012024 198.193314,171.246689 196.476822,174.875854
	C194.882568,178.246613 194.783386,181.510117 195.031647,185.006958
	C195.330032,189.209579 196.880432,190.902176 200.895020,190.355774
	C204.147461,189.913086 207.188538,190.258698 210.212143,191.319016
	C211.650620,191.823486 213.166443,192.274841 214.614594,191.889847
	C221.420486,190.080475 228.458206,191.205185 235.400009,189.926056
	C241.817841,188.743484 248.505325,191.265366 255.092087,192.002274
	C257.050110,192.221313 258.818970,192.678497 260.883392,192.272491
	C264.634430,191.534805 265.819519,193.041977 265.269012,197.744019
	C264.859161,198.902405 264.679840,199.744522 264.258423,200.806763
	C260.567596,203.640060 258.886200,206.704086 260.176331,211.304153
	C261.466888,215.905655 261.545258,220.784454 262.768494,225.737961
z"
        />
        <path
          fill="#3B2C30"
          opacity="1.000000"
          stroke="none"
          d="
M504.589905,80.994659
	C505.119232,84.207855 501.566162,83.560631 500.192383,85.684036
	C498.426422,89.066490 495.581726,90.418945 492.430542,91.066422
	C483.332123,92.935867 474.265808,94.972511 464.875702,94.939835
	C442.106079,94.860596 419.334747,95.043785 396.566803,94.834946
	C388.932861,94.764923 381.294220,94.541771 373.655396,94.494720
	C365.064575,94.441818 357.927734,97.795380 351.879150,103.715065
	C349.834137,105.716507 348.297546,108.051453 347.373016,111.245422
	C345.888885,113.864769 344.215729,115.890053 344.275970,118.751495
	C344.304901,120.126663 343.510071,121.237099 341.556580,121.440453
	C339.287201,119.990417 339.017639,117.831451 339.479065,116.003731
	C341.673553,107.311378 345.157074,99.346657 353.662079,94.222916
	C357.379028,92.252396 361.420532,92.183632 364.566986,89.803139
	C365.037933,89.446854 365.906586,89.239952 366.449951,89.411476
	C376.738342,92.659172 387.309052,88.917641 397.743713,90.727692
	C401.641479,91.403809 405.808380,88.604256 410.255066,88.932167
	C429.891174,90.380188 449.561951,89.097000 469.185974,88.652496
	C477.231293,88.470261 485.678497,87.731316 493.666718,85.296936
	C497.316864,84.184578 499.610291,79.811409 504.589905,80.994659
z"
        />
        <path
          fill="#2D3037"
          opacity="1.000000"
          stroke="none"
          d="
M353.617493,93.999107
	C348.234894,98.607140 345.607788,105.355148 342.009247,111.456337
	C340.351929,114.266205 341.309875,117.221268 340.997406,120.543671
	C342.955048,122.957130 342.997375,125.482826 342.956421,127.993431
	C342.804321,137.317017 343.712646,146.618942 343.727875,155.914581
	C343.738678,162.517975 343.191895,169.192596 342.394409,176.260239
	C341.274750,178.663666 339.720917,179.259003 337.292755,178.463745
	C334.598938,174.594269 335.331573,169.530396 331.270691,166.273987
	C331.226166,160.492752 334.016357,155.344620 333.059052,149.622116
	C332.412872,145.759521 332.333221,141.802170 332.000671,137.442078
	C333.748322,136.798996 333.893158,135.442032 334.167358,134.106522
	C335.066467,129.727539 337.054169,125.283249 336.742035,121.001183
	C335.958405,110.249809 343.187683,103.738510 348.444031,96.112938
	C349.552612,94.504715 351.241730,93.805779 353.617493,93.999107
z"
        />
        <path
          fill="#83AEBF"
          opacity="1.000000"
          stroke="none"
          d="
M897.019165,318.570679
	C898.329041,323.220245 902.715332,324.355316 905.290833,327.197357
	C913.181763,335.904999 923.119873,339.325684 934.360718,341.360962
	C941.706543,342.691010 948.861816,341.829315 955.961975,342.430359
	C963.834229,343.096771 972.234436,339.766724 979.726624,344.646881
	C980.466492,345.128754 981.692566,345.080658 982.656799,344.968201
	C993.360657,343.719666 1003.974609,345.808014 1014.312439,347.443146
	C1023.562073,348.906158 1032.996704,349.000641 1042.204468,351.156311
	C1045.371826,351.897888 1050.360962,351.794159 1052.965942,347.245728
	C1058.701904,347.491669 1061.196167,351.648071 1063.039429,356.651520
	C1056.770996,361.805847 1049.369507,361.440613 1042.390747,361.669952
	C1034.141968,361.941040 1025.825439,361.179535 1017.609253,360.233093
	C1007.243408,359.039093 997.431091,360.359497 988.175903,365.156952
	C984.992737,366.806946 981.591064,367.282684 977.780457,368.025269
	C970.265930,369.370544 963.089417,368.592377 955.940247,368.724731
	C951.912842,368.799286 948.033691,369.183624 944.161987,370.487213
	C938.653259,372.341980 933.035278,372.418732 927.543396,370.119202
	C926.775879,369.797791 925.975159,369.166565 925.213257,369.282440
	C913.698425,371.034058 910.157837,360.931763 903.474121,355.193146
	C896.799194,349.462067 896.397400,341.316132 893.357422,334.207733
	C892.218384,331.544250 890.946960,329.754150 888.323364,328.528717
	C884.942017,326.949341 881.019958,325.917694 879.425415,321.488251
	C883.229065,316.659729 891.772644,315.239014 897.019165,318.570679
z"
        />
        <path
          fill="#88A6B8"
          opacity="1.000000"
          stroke="none"
          d="
M897.407837,319.058044
	C891.326782,317.418030 885.896545,318.140350 880.326233,320.851501
	C874.640930,324.236847 875.201599,323.865234 878.631592,329.039764
	C882.430725,334.771240 888.577332,337.738312 892.789856,342.838409
	C894.166443,344.505005 896.134277,345.947174 896.587646,348.023224
	C898.244995,355.612976 903.841858,360.533600 908.393677,366.164032
	C910.390564,368.634125 912.343018,371.106506 913.419556,374.134644
	C914.778259,377.956177 917.464478,379.270721 921.479126,379.340240
	C938.719177,379.638824 955.750305,383.574493 973.089722,382.391968
	C974.247620,382.312988 975.403076,382.620331 976.770630,383.426300
	C977.208679,383.906677 977.331604,384.142151 977.474365,384.774933
	C976.470093,387.750671 974.109375,387.654388 972.014648,387.670776
	C964.366150,387.730560 956.774475,387.849365 949.207764,389.481567
	C945.024170,390.384033 940.584167,389.982178 936.343567,388.801239
	C935.702515,388.622742 935.011597,388.305359 934.397095,388.414673
	C927.848572,389.579987 921.336487,389.816254 914.837341,386.902496
	C914.688721,389.888641 916.805603,390.398132 917.577881,392.239899
	C917.865601,393.133148 917.878662,393.662476 917.632507,394.563904
	C915.842407,396.664612 913.903137,396.560211 912.069031,395.499542
	C904.632751,391.199158 897.393066,386.664886 894.281006,377.554077
	C892.261780,375.760773 891.161011,373.838715 890.580200,371.226959
	C890.558899,369.176666 889.769287,367.860901 888.375854,366.458069
	C883.161499,355.587128 875.797913,347.216370 864.942749,341.654175
	C864.363220,341.064972 864.102478,340.673492 863.761047,339.926758
	C861.777283,336.473511 861.073303,333.116150 861.543823,329.175995
	C861.947144,326.238373 861.591370,324.300385 858.267456,324.268341
	C856.803101,324.254242 855.368835,323.733368 853.540527,323.491882
	C850.495300,323.795197 848.044312,322.435669 844.992798,322.382874
	C843.379150,322.116089 842.413574,321.503418 841.352661,320.244568
	C839.927856,316.976562 839.896729,314.057495 841.142700,310.754456
	C841.719421,310.046204 842.325867,309.554718 842.610352,309.919983
	C846.149658,314.463776 851.419800,313.853424 856.626221,314.665985
	C861.713318,315.537567 866.468689,315.844421 871.579712,316.473633
	C872.976685,316.677246 873.979126,316.924072 875.399719,316.650208
	C877.132935,316.324341 878.484619,316.368805 880.265869,316.497681
	C885.591614,315.640076 890.538330,315.354980 895.498840,315.384094
	C898.365601,315.400909 901.176270,315.733490 902.684631,319.220856
	C901.002869,320.181122 899.412170,319.641144 897.407837,319.058044
z"
        />
        <path
          fill="#F0F0EC"
          opacity="1.000000"
          stroke="none"
          d="
M1063.482910,356.091980
	C1059.772583,353.210571 1057.455200,349.328461 1053.306885,346.968872
	C1046.982788,342.403748 1039.982300,340.886566 1032.796509,339.880524
	C1031.324219,339.674377 1029.278564,340.393677 1029.070068,337.457916
	C1029.337158,336.270935 1029.853149,335.672485 1030.812744,334.928375
	C1037.866089,331.466522 1044.834473,329.073517 1052.747314,328.552734
	C1059.036133,329.358795 1065.109009,329.532166 1070.217773,333.911316
	C1070.683472,335.306610 1070.383667,336.319794 1069.592407,336.905151
	C1065.474121,339.951691 1064.582764,344.210083 1064.682129,349.268677
	C1062.966064,350.990173 1061.949829,349.771484 1060.633301,348.624573
	C1059.943481,347.942841 1059.495605,347.490967 1058.836304,346.848633
	C1058.313232,346.702148 1058.536133,346.096313 1058.344727,346.415894
	C1058.967651,346.972351 1059.369019,347.372772 1059.985596,347.987701
	C1061.423706,349.357422 1062.468872,350.718384 1064.568481,351.883118
	C1065.256958,352.456573 1065.394409,352.642609 1065.662720,353.154114
	C1065.944214,354.195068 1065.868774,354.884369 1065.324463,355.816925
	C1064.672241,356.326904 1064.221436,356.408630 1063.482910,356.091980
z"
        />
        <path
          fill="#CFECEF"
          opacity="1.000000"
          stroke="none"
          d="
M902.726440,319.707764
	C897.544739,315.186127 891.425476,316.974060 885.606262,317.246521
	C883.835876,317.329407 882.053772,317.960876 879.733398,317.794250
	C879.294678,317.587555 879.334473,317.362640 879.553223,316.843994
	C880.545288,315.731598 881.671204,315.341675 882.794922,314.353668
	C883.528320,313.972198 883.975464,313.816437 884.791626,313.642578
	C886.813110,313.302185 888.466431,312.947540 890.500732,312.616333
	C891.372192,312.552490 891.873718,312.525055 892.756714,312.451965
	C894.930298,311.915863 896.723389,311.394196 898.712585,312.850403
	C902.448914,314.857880 905.928345,316.592194 909.460327,318.212158
	C911.313599,319.062164 912.776917,320.229797 913.783142,322.390015
	C914.137024,323.010986 914.360474,323.328827 914.826538,323.826721
	C915.036987,324.029480 914.981750,324.014893 914.983032,323.986359
	C915.414978,324.409821 915.482788,325.012634 915.841187,325.802063
	C916.027222,326.032074 915.969543,326.024689 915.971130,325.995605
	C916.206787,326.200684 916.435120,326.440308 916.844910,326.848511
	C917.032104,327.011627 916.995422,327.013062 916.995789,326.995544
	C917.209167,327.786377 917.422241,328.594727 917.765320,329.693359
	C917.853943,330.869080 917.835815,331.783173 916.293335,330.978760
	C915.621643,330.822876 915.341736,330.596558 915.178406,329.854919
	C914.351624,327.513763 912.248291,326.754974 910.709229,325.051697
	C909.550842,324.674164 908.719360,324.354828 908.269470,323.111938
	C907.522339,322.164001 906.780212,321.555634 905.743286,320.925232
	C904.748657,320.671082 904.030212,320.503845 903.041382,320.058258
	C902.759338,319.821075 902.741211,319.744324 902.726440,319.707764
z"
        />
        <path
          fill="#77878D"
          opacity="1.000000"
          stroke="none"
          d="
M1030.728271,335.450928
	C1030.224976,336.129547 1029.904541,336.501038 1029.338867,336.970215
	C1028.492310,336.554321 1027.890869,336.040802 1027.144775,335.263611
	C1027.217896,332.192322 1025.364502,330.122437 1024.244019,327.432251
	C1023.603882,326.671082 1023.519531,326.046600 1023.192993,325.194733
	C1023.014954,324.940491 1023.001282,325.001923 1023.032715,325.001831
	C1022.798706,324.801941 1022.560974,324.573273 1022.185120,324.131592
	C1022.019226,323.947479 1022.060303,323.999023 1022.087646,323.981018
	C1022.078796,320.573761 1019.350403,318.486176 1018.030396,315.337402
	C1018.818115,314.181824 1019.750366,314.370178 1021.003906,315.023102
	C1022.212463,316.035553 1022.833069,317.073883 1023.796143,318.289948
	C1024.898193,319.956848 1026.028564,321.203888 1026.523438,323.200562
	C1027.836182,327.486206 1032.509155,330.150513 1030.728271,335.450928
z"
        />
        <path
          fill="#83AEBF"
          opacity="1.000000"
          stroke="none"
          d="
M908.015320,323.001892
	C908.869019,323.310638 909.473572,323.848877 910.264038,324.639160
	C909.447510,324.512268 907.259338,326.050598 908.015320,323.001892
z"
        />
        <path
          fill="#83AEBF"
          opacity="1.000000"
          stroke="none"
          d="
M915.084595,329.873718
	C915.349304,329.827606 915.593384,330.097687 915.951416,330.614380
	C915.835571,331.206085 915.605774,331.551239 915.375916,331.896393
	C915.271912,331.327576 915.167908,330.758759 915.084595,329.873718
z"
        />
        <path
          fill="#83AEBF"
          opacity="1.000000"
          stroke="none"
          d="
M903.026855,320.088440
	C903.477539,319.997803 904.195740,320.174530 905.181274,320.627197
	C904.730530,320.720703 904.012390,320.538269 903.026855,320.088440
z"
        />
        <path
          fill="#142732"
          opacity="1.000000"
          stroke="none"
          d="
M948.191406,299.656586
	C945.389832,300.753479 945.956665,298.861664 946.138123,297.724091
	C947.021057,292.189850 950.638123,288.949860 955.669617,287.266510
	C965.770874,283.887024 975.313171,286.381561 984.869507,290.690125
	C976.362427,291.053802 967.623169,290.975464 959.095093,294.522797
	C954.794006,294.908173 951.755554,297.414856 948.191406,299.656586
z"
        />
        <path
          fill="#CFECEF"
          opacity="1.000000"
          stroke="none"
          d="
M1023.030396,325.260284
	C1023.328552,325.460907 1023.642151,325.981323 1024.016357,326.817139
	C1023.733337,326.615082 1023.389587,326.097565 1023.030396,325.260284
z"
        />
        <path
          fill="#CFECEF"
          opacity="1.000000"
          stroke="none"
          d="
M1022.109619,324.195801
	C1022.321594,324.113037 1022.624023,324.278564 1022.995300,324.722961
	C1022.776123,324.815887 1022.488037,324.630005 1022.109619,324.195801
z"
        />
        <path
          fill="#F4E1D8"
          opacity="1.000000"
          stroke="none"
          d="
M1021.239441,316.534729
	C1020.144653,315.978149 1019.396606,315.394409 1018.299683,314.903107
	C1017.950867,314.995575 1017.981079,315.026245 1018.000000,315.036499
	C1017.595154,314.588898 1017.515747,313.987427 1017.157593,313.192810
	C1016.972290,312.960754 1017.000000,313.000000 1016.985046,312.980316
	C1015.816040,311.807159 1014.653503,310.662201 1013.265503,309.248230
	C1013.020325,308.999023 1013.004883,308.995117 1013.002686,308.987366
	C1012.130066,308.136505 1011.277710,307.275299 1010.217102,306.194153
	C1010.008545,305.974457 1009.998901,305.998383 1010.011169,305.995056
	C1009.464111,305.594208 1008.891907,305.211731 1008.298828,304.357513
	C1005.242676,301.435608 1001.351013,300.203949 998.775879,297.066284
	C997.821228,295.903046 997.508118,295.335907 998.868774,294.162537
	C999.464050,293.904663 999.900269,293.885132 1000.665405,293.884827
	C1001.739136,294.886444 1002.933716,294.283356 1004.217407,294.758392
	C1006.193054,294.837036 1007.787476,294.867523 1009.774170,294.801636
	C1021.001099,296.109100 1031.209351,299.072784 1039.790283,306.818970
	C1040.525757,308.275940 1040.400635,309.423798 1039.668091,310.863525
	C1039.300659,311.332367 1039.126221,311.496552 1038.646362,311.847473
	C1032.837524,313.830261 1027.501099,316.252411 1021.239441,316.534729
z"
        />
        <path
          fill="#A19796"
          opacity="1.000000"
          stroke="none"
          d="
M1030.953491,335.808929
	C1030.539062,331.230621 1026.882446,328.402710 1025.244019,324.252502
	C1028.466187,321.685455 1031.868896,319.671112 1036.467529,319.964111
	C1038.846802,321.204041 1040.861816,321.220245 1043.016968,320.369202
	C1044.818848,319.657684 1046.642944,318.581451 1048.984619,319.839569
	C1051.205811,327.598297 1057.498901,324.320496 1062.549316,325.333984
	C1063.008057,325.830078 1063.017212,326.237488 1063.009521,326.950073
	C1060.284180,329.855621 1056.461670,327.804413 1053.253174,329.769470
	C1045.389404,330.580170 1038.415039,333.366455 1030.953491,335.808929
z"
        />
        <path
          fill="#D0C3BE"
          opacity="1.000000"
          stroke="none"
          d="
M1038.587891,310.990967
	C1039.210571,309.903595 1039.424194,308.804596 1039.802979,307.406311
	C1042.214233,308.214691 1043.455078,310.312012 1045.038330,312.478516
	C1045.675415,314.227753 1044.725830,315.179016 1043.750488,316.490143
	C1043.060913,317.104248 1042.605957,317.369476 1041.719604,317.651184
	C1037.224365,317.208130 1033.812866,320.063385 1029.758911,320.054382
	C1027.610962,320.049622 1025.477783,320.555634 1023.056213,319.407898
	C1022.128601,318.517151 1021.561584,317.798706 1020.943604,316.821075
	C1021.217651,315.842316 1021.662109,314.464447 1021.850342,314.498627
	C1027.799194,315.578217 1032.496704,310.952118 1038.587891,310.990967
z"
        />
        <path
          fill="#BEAAA0"
          opacity="1.000000"
          stroke="none"
          d="
M1041.594604,317.050110
	C1042.065063,316.591400 1042.270874,316.336060 1042.757202,316.011902
	C1044.388916,315.798248 1045.786987,315.793274 1047.364258,316.721191
	C1048.212891,317.674408 1048.718384,318.562378 1049.243042,319.763000
	C1046.944214,320.274567 1044.930908,321.234375 1042.877808,322.289032
	C1041.016968,323.244904 1038.869629,323.865814 1037.098633,321.325592
	C1036.714966,317.771149 1039.248047,317.777985 1041.594604,317.050110
z"
        />
        <path
          fill="#C1ACB2"
          opacity="1.000000"
          stroke="none"
          d="
M1047.638062,316.443970
	C1046.194824,316.433014 1044.558716,318.865845 1043.052979,316.237640
	C1043.031860,314.663208 1044.002808,314.071594 1044.945679,313.062256
	C1045.943604,313.896149 1046.756226,314.998810 1047.638062,316.443970
z"
        />
        <path
          fill="#D0C3BE"
          opacity="1.000000"
          stroke="none"
          d="
M1013.055054,309.335815
	C1015.115356,309.154144 1016.290100,310.422119 1016.975708,312.617676
	C1014.846313,312.844574 1013.857849,311.388672 1013.055054,309.335815
z"
        />
        <path
          fill="#D0C3BE"
          opacity="1.000000"
          stroke="none"
          d="
M1010.051392,306.308228
	C1011.438965,306.187531 1012.497803,306.866211 1012.971069,308.647461
	C1011.470520,308.898102 1010.530457,308.083221 1010.051392,306.308228
z"
        />
        <path
          fill="#D0C3BE"
          opacity="1.000000"
          stroke="none"
          d="
M1008.149292,304.366730
	C1008.683960,304.533386 1009.238647,305.007935 1009.908386,305.737061
	C1009.133362,305.935638 1008.426941,305.621002 1008.149292,304.366730
z"
        />
        <path
          fill="#77878D"
          opacity="1.000000"
          stroke="none"
          d="
M1016.976440,313.266876
	C1017.722290,313.143188 1018.027100,313.693024 1018.085754,314.718872
	C1017.672852,314.555542 1017.326721,314.064270 1016.976440,313.266876
z"
        />
        <path
          fill="#3E1E1C"
          opacity="1.000000"
          stroke="none"
          d="
M899.351440,313.037842
	C897.296753,313.298492 895.645874,313.425903 893.641846,313.545227
	C890.625488,311.367096 891.617310,308.563721 892.176086,305.476440
	C894.619812,302.962677 897.018860,304.068329 899.408630,304.684235
	C901.506531,305.225006 903.701538,305.080139 906.249390,305.558411
	C909.431213,305.904602 910.635315,307.723663 911.104492,310.620178
	C907.461914,312.794128 903.427429,311.749725 899.351440,313.037842
z"
        />
        <path
          fill="#0F1B23"
          opacity="1.000000"
          stroke="none"
          d="
M911.299438,311.022644
	C910.005981,309.641113 909.311096,307.946899 907.315979,307.027222
	C903.968445,304.754639 901.311890,302.016541 897.550049,300.893219
	C896.266663,300.510010 895.407776,299.382782 895.739014,297.526855
	C895.937012,297.219055 895.976135,297.059723 895.984436,296.978088
	C906.607544,297.474182 910.611938,300.762360 912.086060,310.506653
	C911.907837,310.931061 911.735107,310.964935 911.299438,311.022644
z"
        />
        <path
          fill="#9FA2B2"
          opacity="1.000000"
          stroke="none"
          d="
M912.982605,311.359802
	C913.390381,311.178864 913.939575,311.203308 914.862427,311.412384
	C914.515503,312.681854 913.808899,312.842499 912.982605,311.359802
z"
        />
        <path
          fill="#9FA2B2"
          opacity="1.000000"
          stroke="none"
          d="
M916.052063,325.718231
	C915.683777,325.545776 915.340271,325.059509 914.990479,324.265533
	C915.348450,324.440002 915.712708,324.922180 916.052063,325.718231
z"
        />
        <path
          fill="#9FA2B2"
          opacity="1.000000"
          stroke="none"
          d="
M916.984619,326.732117
	C916.755005,326.818756 916.477966,326.625885 916.086792,326.199768
	C916.294189,326.128571 916.615601,326.290588 916.984619,326.732117
z"
        />
        <path
          fill="#9FA2B2"
          opacity="1.000000"
          stroke="none"
          d="
M914.903076,323.769775
	C914.741638,323.769836 914.446350,323.510193 914.048340,322.960571
	C914.220154,322.950439 914.494629,323.230255 914.903076,323.769775
z"
        />
        <path
          fill="#0F1B23"
          opacity="1.000000"
          stroke="none"
          d="
M1016.028076,278.596161
	C1016.163269,282.658478 1016.410583,286.440704 1013.088135,289.596313
	C1004.825073,293.322754 999.716797,291.470490 997.675537,284.335175
	C996.478760,280.151978 998.619812,276.984589 1001.130493,273.594696
	C1009.350037,270.044983 1011.819580,270.883972 1016.028076,278.596161
z"
        />
        <path
          fill="#CFECEF"
          opacity="1.000000"
          stroke="none"
          d="
M1004.331909,294.516510
	C1003.578491,294.690674 1002.558228,294.574799 1001.266174,294.181488
	C1002.017944,294.011536 1003.041504,294.119019 1004.331909,294.516510
z"
        />
        <path
          fill="#43626E"
          opacity="1.000000"
          stroke="none"
          d="
M268.634430,707.334961
	C270.077301,707.321106 271.125916,707.373474 272.548889,707.550537
	C273.650574,708.014160 274.410309,708.152344 275.514954,708.041138
	C276.451904,708.057617 277.056091,708.173401 277.774902,708.798340
	C281.815948,722.791626 275.457886,733.613098 267.561493,743.711975
	C265.130493,746.821167 264.189667,750.492432 261.954163,753.613098
	C255.309052,762.889099 246.841431,770.158813 237.590057,776.617126
	C233.071854,779.771179 227.485413,779.469055 222.431793,780.860840
	C210.206482,784.227722 198.234863,780.315735 186.717010,777.309265
	C178.256592,775.100708 170.636795,770.617676 163.807770,763.407166
	C155.646896,754.790466 150.446655,744.537964 143.705429,735.276672
	C138.139252,727.629639 134.321564,718.383545 130.090179,709.641541
	C124.873817,698.864624 120.005638,687.945007 115.576569,676.861694
	C111.413773,666.444824 108.870697,655.443665 105.698532,644.677856
	C103.495811,637.202209 104.209892,629.620789 102.834534,622.160950
	C101.863289,616.892944 103.071648,609.934265 106.361595,604.498352
	C108.640465,600.733093 111.194527,600.681946 116.653610,603.946472
	C117.611198,603.409668 118.285156,603.446899 119.267654,604.001221
	C120.422997,606.099426 121.306374,607.967712 123.694153,609.021973
	C128.641586,612.080566 132.529205,615.826782 136.208481,619.804260
	C137.514847,621.216492 139.236786,622.514893 137.983246,625.142334
	C135.933273,626.322998 134.512268,625.437927 133.117416,624.468384
	C132.023911,623.708313 131.034500,622.789001 129.869003,622.145447
	C127.702904,620.949280 125.312958,618.881897 122.994637,620.074951
	C120.701317,621.255188 121.743515,624.300842 121.707649,626.540100
	C121.499565,639.529724 120.957680,652.675903 124.880257,665.191833
	C129.134323,678.765381 132.698380,692.547485 140.103210,705.128357
	C145.905746,714.986816 151.470917,725.030945 157.124832,735.004333
	C163.884277,746.927979 173.483185,755.400940 185.621964,761.176697
	C189.191849,762.875244 193.293076,763.674011 197.192230,764.033569
	C208.110611,765.040283 219.265167,766.335327 229.756012,762.137390
	C237.838898,758.903076 245.928360,755.453125 250.886322,747.221069
	C254.383072,741.415222 258.745392,736.116394 262.554382,730.453735
	C267.036682,723.789917 269.031952,717.121155 267.111755,708.951538
	C267.423370,708.066345 267.768799,707.677917 268.634430,707.334961
z"
        />
        <path
          fill="#15313E"
          opacity="1.000000"
          stroke="none"
          d="
M99.011360,579.315552
	C100.216377,577.603210 100.331779,575.754456 100.997269,573.715332
	C102.731384,573.255127 103.467262,574.616150 104.728485,575.786499
	C104.963928,576.047546 105.004768,576.008728 104.979614,576.020142
	C105.213676,576.223083 105.451584,576.438232 105.822350,576.861938
	C105.976524,577.046997 106.001259,577.000854 105.977386,577.011597
	C106.210022,577.218506 106.447258,577.435791 106.819244,577.860229
	C106.973274,578.046265 106.999619,578.000000 106.976601,578.013367
	C107.211418,578.220459 107.448921,578.436584 107.819786,578.860718
	C107.973480,579.046509 108.000389,579.000122 107.977066,579.013306
	C108.212036,579.221130 108.450226,579.437866 108.824158,579.861694
	C108.980019,580.046570 108.999977,579.999817 108.976540,580.009766
	C109.208862,580.216980 109.445747,580.434753 109.816811,580.859863
	C109.969872,581.046753 109.998817,580.999939 109.975723,581.014954
	C110.212074,581.221375 110.450020,581.436523 110.819359,581.861694
	C110.972275,582.047791 110.998299,581.998962 110.972626,582.009277
	C111.435646,582.421143 111.480827,583.022705 111.812813,583.808228
	C111.979744,584.046265 111.995964,583.996216 111.972366,584.007935
	C112.594688,584.447693 113.288841,584.822449 113.816284,585.803894
	C113.980812,586.044983 113.997108,585.996826 113.974396,586.008362
	C114.530075,586.521484 115.059814,587.073181 115.788292,587.830811
	C115.979599,588.044373 116.007225,588.003723 115.982712,588.002563
	C116.283310,588.275696 116.565369,588.588806 116.901291,589.243103
	C116.998024,589.545654 117.224976,589.947571 117.339798,589.980347
	C118.288132,590.342590 118.901123,590.968811 119.760361,591.802490
	C119.981918,592.043152 120.001259,591.998535 119.978516,592.007202
	C120.209000,592.216797 120.445366,592.435974 120.820961,592.858154
	C120.977051,593.043030 121.002213,593.001160 120.979820,593.010864
	C121.212128,593.220581 121.449432,593.439453 121.827774,593.860596
	C121.986206,594.043945 122.000023,593.998474 121.980042,594.011353
	C122.417641,594.428955 123.010933,594.493347 123.779861,594.857239
	C123.997589,595.051208 124.007843,594.995361 123.979553,594.997681
	C124.518150,595.497559 124.481499,596.257690 124.876968,597.208130
	C124.986282,597.538818 125.229225,597.942505 125.345718,597.976807
	C126.293472,598.343811 126.904564,598.971252 127.762970,599.804626
	C127.984428,600.045166 127.997536,599.996582 127.975555,600.008911
	C128.595535,600.449768 129.288620,600.822021 129.812378,601.801331
	C129.975525,602.042114 129.999039,601.998962 129.977631,602.011169
	C130.532181,602.524231 131.060669,603.073975 131.785919,603.830444
	C131.976120,604.043884 132.005997,604.005188 131.981552,604.004822
	C132.284195,604.277527 132.567184,604.590454 132.902679,605.246216
	C132.999115,605.549377 133.218506,605.954895 133.334839,605.983704
	C134.719986,606.562927 135.563843,607.628479 136.752441,608.797119
	C136.978104,609.044495 136.999969,609.000000 136.977966,609.011597
	C139.201187,611.145630 141.352264,613.362915 143.750580,615.800232
	C143.972244,616.046021 144.002426,616.002869 143.977844,616.012207
	C144.210526,616.219299 144.448929,616.437683 144.825897,616.861328
	C144.983292,617.046082 144.998322,616.998840 144.979370,617.014893
	C145.214828,617.223633 145.449600,617.437866 145.817261,617.857117
	C145.969803,618.040649 145.994324,617.997192 145.976593,618.015991
	C146.535461,618.527405 147.059387,619.074402 147.776093,619.828979
	C147.962769,620.042969 148.003510,620.006958 147.977997,620.015503
	C148.441925,620.426758 148.480667,621.031982 148.814331,621.819092
	C148.977814,622.060120 148.996613,622.002014 148.967224,622.010315
	C149.590805,622.440491 150.283493,622.818359 150.810394,623.803650
	C150.972656,624.047119 151.003235,624.004028 150.980072,624.016479
	C151.415878,624.425293 152.004059,624.493530 152.769196,624.853027
	C152.984024,625.048035 153.002716,625.001343 152.980042,625.012329
	C153.212540,625.221008 153.449432,625.438599 153.825043,625.859619
	C153.982025,626.043030 153.999802,625.995911 153.975754,626.003418
	C154.428772,626.417358 154.481537,627.011414 154.801270,627.798096
	C154.965836,628.036011 155.003555,628.012512 154.982651,628.015808
	C155.213654,628.221558 155.449707,628.441162 155.828033,628.860840
	C155.986176,629.043945 155.985138,628.993042 155.967133,629.011108
	C156.865829,629.832825 157.705688,630.714966 158.760559,631.811646
	C158.962952,632.039062 159.001907,632.005615 158.981049,632.017944
	C159.213486,632.222107 159.447128,632.435425 159.811737,632.854004
	C159.962387,633.037720 160.000000,633.000000 159.980957,633.018799
	C160.216568,633.225769 160.450195,633.437256 160.812759,633.855042
	C160.962708,634.038086 161.000000,634.000000 160.980865,634.018616
	C161.216843,634.226074 161.450958,634.438110 161.814331,634.856812
	C161.964569,635.040344 161.995789,635.000000 161.977325,635.019897
	C162.538712,635.530945 163.063660,636.078979 163.782532,636.834229
	C163.969681,637.048523 164.007446,637.018616 163.983459,637.018066
	C164.211807,637.219666 164.447968,637.439148 164.826279,637.858948
	C164.984558,638.041931 164.999283,637.994568 164.975739,638.002258
	C165.434296,638.420593 165.481293,639.024658 165.811981,639.817749
	C165.975723,640.059998 165.999725,640.005066 165.970825,640.013062
	C166.481216,640.520874 167.227570,640.491821 168.173386,640.906128
	C168.842499,646.002930 172.939392,641.576416 174.766861,643.688171
	C176.875763,645.716003 176.834045,648.146545 176.932083,650.399597
	C177.077911,653.751160 178.593735,655.886108 181.546921,657.363464
	C183.518082,658.349609 186.042267,659.103760 185.587692,662.527039
	C184.476990,663.996826 183.270401,663.919556 181.654419,663.469543
	C181.075424,663.320740 180.848587,663.253113 180.306427,663.034363
	C179.051636,662.319214 178.319305,661.400391 176.836823,661.128357
	C175.496674,660.522461 174.390991,660.455139 172.931442,660.410583
	C171.622498,660.290649 170.761093,659.955811 169.830063,659.013306
	C169.362030,654.540894 165.821457,652.304260 163.673996,648.864075
	C162.779709,647.842163 162.525009,646.689514 161.108856,646.175110
	C160.235657,645.740295 159.683456,645.390198 158.840347,644.872192
	C158.207260,644.404602 157.878601,644.097351 157.358429,643.503357
	C155.981125,641.384277 155.297821,639.140503 152.759277,637.974609
	C151.007584,636.641663 149.737946,635.296814 148.298843,633.628479
	C142.873016,626.647766 136.858841,620.670532 130.791626,614.781128
	C126.279144,610.400879 122.157417,605.641296 117.288666,601.140015
	C117.084755,600.859009 117.079361,600.422668 117.059914,600.205200
	C116.875366,597.935364 115.318184,597.031616 113.902702,595.863892
	C108.210754,591.168213 103.275154,585.751160 99.011360,579.315552
z"
        />
        <path
          fill="#35535F"
          opacity="1.000000"
          stroke="none"
          d="
M117.057175,601.251709
	C119.790298,601.514954 121.923218,603.404480 123.563896,605.259949
	C131.459488,614.189453 140.186264,622.297607 148.346756,630.956909
	C148.664520,631.294067 148.751556,631.848694 148.979004,632.647217
	C147.901215,634.046326 146.736649,633.641724 145.258011,632.929443
	C142.857956,630.556580 141.006912,628.143005 138.330414,625.603149
	C134.698730,618.578491 129.008713,614.589172 123.171303,610.286987
	C122.082787,608.276428 120.524788,607.057556 119.226219,605.229492
	C118.527443,604.688538 118.057281,604.374634 117.296158,604.026733
	C117.013321,603.209900 117.021454,602.427185 117.057175,601.251709
z"
        />
        <path
          fill="#3C545F"
          opacity="1.000000"
          stroke="none"
          d="
M278.290680,709.035278
	C277.222473,709.050598 276.483459,709.046509 275.374390,709.048828
	C274.719086,708.766602 274.602814,708.417236 274.805206,707.783203
	C276.694489,707.409973 278.409912,707.269531 280.558350,707.157593
	C280.991394,707.186157 280.963043,707.651367 280.954681,707.885437
	C280.170837,708.418335 279.395386,708.717102 278.290680,709.035278
z"
        />
        <path
          fill="#95D1E2"
          opacity="1.000000"
          stroke="none"
          d="
M180.532501,634.883789
	C169.872971,624.027954 159.563690,613.337280 149.116577,602.343750
	C148.107407,600.926819 148.338089,599.762329 149.149918,598.846191
	C151.718140,595.948059 154.789352,593.636169 158.377579,592.201172
	C160.542923,591.335205 162.776825,591.810120 164.186691,593.820557
	C165.653091,595.911499 164.535278,597.701782 162.819916,598.981445
	C160.037491,601.057251 161.460861,602.003052 163.635361,603.056946
	C165.975830,604.191101 168.442947,604.281189 170.946625,604.300476
	C177.240036,604.348877 183.543869,603.832520 189.508911,606.889465
	C191.792892,608.059814 194.624039,608.209961 197.336533,608.027344
	C199.316360,607.894104 201.340988,607.867126 203.003342,609.316772
	C201.606857,607.833252 199.746674,607.834717 198.020264,608.045410
	C193.883331,608.550110 190.606552,606.785767 187.419357,604.537659
	C182.953934,601.388000 183.123276,599.313049 188.080338,596.781494
	C188.839386,596.393921 189.601105,596.128113 190.786835,596.166626
	C192.232819,596.633606 192.390076,597.404541 192.365891,598.552612
	C192.342392,599.668091 192.824265,598.620422 193.348221,598.358032
	C194.474762,597.802002 195.375885,597.811401 196.643768,597.983337
	C206.464218,599.772278 215.150497,596.529907 224.245789,593.605103
	C232.185989,590.529053 240.243576,589.903992 248.610947,588.713684
	C252.168503,589.998901 255.245834,588.595032 258.350281,588.533997
	C264.340851,588.416260 267.239838,590.350403 268.933746,596.104309
	C270.105743,600.085388 270.832642,604.200684 270.764404,608.359375
	C270.684387,613.240051 271.357269,617.920410 273.361359,622.804443
	C271.442780,625.732178 268.612030,626.255981 265.838776,626.703552
	C246.867569,629.765076 227.970932,633.263794 209.004730,636.352722
	C207.203186,636.646179 205.399643,636.992432 203.178528,636.218445
	C197.880356,636.538513 193.009323,637.012573 188.129959,637.374023
	C185.437912,637.573425 182.871750,637.151306 180.532501,634.883789
z"
        />
        <path
          fill="#1C2D35"
          opacity="1.000000"
          stroke="none"
          d="
M191.704620,641.295288
	C193.447220,640.650085 194.881454,640.312378 196.681000,639.911743
	C206.871887,636.041504 217.315048,635.044373 227.582947,633.421570
	C246.241226,630.472717 264.771027,626.761719 283.481720,624.144287
	C285.141449,623.912170 286.665588,623.225708 288.708191,622.794800
	C302.685242,621.977295 315.956665,619.037842 329.829712,617.501221
	C334.841309,618.515442 338.597198,615.354553 343.324402,615.020386
	C344.295319,615.234192 344.779877,615.515259 345.429535,616.276062
	C345.855408,617.193604 345.926941,617.763916 345.775757,618.776489
	C345.183136,620.317932 344.365753,621.145142 342.995422,622.032715
	C337.421417,624.066833 332.132935,625.556885 326.701111,626.411133
	C285.569183,632.880310 244.449997,639.425720 203.403473,646.421875
	C199.790039,647.037720 196.107224,647.067444 192.012909,646.850891
	C191.336502,646.588623 191.084671,646.438477 190.586639,645.923584
	C189.822998,644.008118 190.164749,642.686707 191.704620,641.295288
z"
        />
        <path
          fill="#3E6978"
          opacity="1.000000"
          stroke="none"
          d="
M289.303375,623.856506
	C281.837952,627.552185 273.839539,627.003723 266.269562,628.637695
	C255.611786,630.938171 244.760742,632.349060 233.985306,634.096985
	C221.909042,636.056030 209.824890,637.966675 197.395233,639.873535
	C197.728668,636.898376 201.153641,638.456116 202.612747,636.330200
	C215.601791,633.781250 228.444397,631.426941 241.296967,629.128235
	C251.721985,627.263672 262.158966,625.465881 272.963928,623.491577
	C274.275055,621.322571 276.315918,621.257080 278.459869,620.634460
	C279.878601,620.527649 280.906738,620.539734 282.299622,620.480896
	C285.026947,620.373657 285.586456,618.970276 285.529083,616.860168
	C285.462921,614.426208 285.355499,611.979736 285.726257,609.218384
	C286.118103,607.881165 286.433807,606.872253 285.926453,605.490967
	C285.427032,603.567749 285.442078,601.944580 285.655151,599.966919
	C285.899719,598.513977 286.402130,597.427856 285.822205,595.942139
	C285.276367,592.597290 284.364532,589.677002 283.984650,586.311035
	C284.054535,584.687622 284.274780,583.380066 283.141113,581.981934
	C282.199310,579.834106 281.695801,577.986755 283.206055,575.850769
	C284.269470,574.882080 285.259125,574.583069 286.635742,574.200317
	C287.296783,574.130798 287.565460,574.133240 288.229584,574.227722
	C289.685242,574.779358 290.389587,575.575562 290.915375,577.032227
	C292.228149,589.767517 294.392975,602.025513 294.033661,614.498413
	C293.981598,616.305420 294.242462,618.128357 293.967102,620.343567
	C293.423950,623.152954 291.065460,622.697205 289.303375,623.856506
z"
        />
        <path
          fill="#0D1920"
          opacity="1.000000"
          stroke="none"
          d="
M190.694275,644.787048
	C190.985001,645.031189 190.997437,645.496033 191.001923,645.728516
	C192.884003,648.062439 192.420746,650.318970 191.189163,652.425659
	C189.502823,655.310242 190.986374,656.294983 193.520294,656.818970
	C194.998169,657.124573 196.530670,657.239746 197.984161,656.978394
	C208.258392,655.131531 218.658356,654.164734 228.931625,652.225830
	C239.019485,650.321899 249.192154,648.779968 259.314484,647.036377
	C267.644897,645.601501 276.061218,644.808411 284.366852,643.170715
	C289.786072,642.102234 295.379211,640.641052 301.157715,643.174683
	C301.555023,645.857666 299.664520,646.100830 297.800415,646.898865
	C291.215057,648.273865 284.795929,648.492737 278.490753,651.053101
	C277.954315,651.395447 277.738678,651.532532 277.173248,651.830200
	C276.094482,652.254150 275.333862,652.346619 274.189270,652.438354
	C272.808411,652.605469 271.804657,652.673645 270.424042,652.504639
	C269.307983,652.486084 268.565155,652.492859 267.455078,652.485413
	C266.118225,652.472229 265.148621,652.460266 263.823517,652.475830
	C263.233612,652.486023 262.999115,652.490601 262.415985,652.505859
	C259.401794,652.944641 256.687012,653.139587 253.868286,654.374451
	C253.380981,654.658630 253.177826,654.756287 252.654663,654.957642
	C251.688675,655.204590 251.032333,655.234131 250.060333,655.244385
	C249.137314,655.260925 248.524551,655.245422 247.649109,655.586487
	C247.158600,655.788452 246.958725,655.868774 246.446320,656.052979
	C245.695389,656.269348 245.250229,656.341736 244.449127,656.405945
	C241.716690,656.513855 239.235336,656.106262 236.826996,657.980835
	C234.350098,658.642578 232.190399,658.408203 229.640106,658.484558
	C225.379333,658.202759 221.717255,659.418030 217.574387,660.060425
	C216.644211,660.318481 216.098068,660.396667 215.132172,660.461670
	C207.315262,660.883545 200.058929,662.697876 192.236877,662.919922
	C191.080322,663.370605 190.269348,663.564392 189.105560,664.016724
	C187.537064,664.275940 186.444809,664.131348 185.297699,663.011780
	C185.203156,660.754639 183.968903,660.174561 182.441772,659.632751
	C177.597733,657.914062 174.237473,655.089783 175.025696,649.255554
	C175.222794,647.796631 175.021591,646.283997 175.007828,644.361572
	C177.397324,644.228516 179.782440,644.529785 182.569397,644.826965
	C185.470703,645.314941 187.872314,643.208008 190.694275,644.787048
z"
        />
        <path
          fill="#15313E"
          opacity="1.000000"
          stroke="none"
          d="
M191.030502,644.684021
	C188.498322,645.450562 186.055389,646.756470 183.226181,645.112183
	C185.220947,642.474670 187.621704,640.449280 191.612640,640.977295
	C191.700653,642.104126 191.388321,643.220520 191.030502,644.684021
z"
        />
        <path
          fill="#63879A"
          opacity="1.000000"
          stroke="none"
          d="
M143.936600,615.703308
	C140.732895,614.945557 139.026657,612.172424 137.001221,609.360657
	C140.315933,609.990479 142.153198,612.626465 143.936600,615.703308
z"
        />
        <path
          fill="#63879A"
          opacity="1.000000"
          stroke="none"
          d="
M104.937027,575.709351
	C103.182961,576.340210 102.580696,574.785461 101.319122,573.604980
	C101.078323,573.029175 101.050613,572.730286 100.985291,572.206543
	C102.962555,572.330688 104.101234,573.659912 104.937027,575.709351
z"
        />
        <path
          fill="#5B7D8C"
          opacity="1.000000"
          stroke="none"
          d="
M158.943680,631.696777
	C157.409882,631.973450 156.692978,630.866943 155.992065,629.358093
	C157.555969,629.017273 158.472870,629.888184 158.943680,631.696777
z"
        />
        <path
          fill="#63879A"
          opacity="1.000000"
          stroke="none"
          d="
M136.955643,608.673218
	C135.402771,608.907898 134.669083,607.691223 133.645020,606.305420
	C135.231094,605.833862 136.316315,606.711792 136.955643,608.673218
z"
        />
        <path
          fill="#5B7D8C"
          opacity="1.000000"
          stroke="none"
          d="
M147.924347,619.724609
	C146.853867,620.260559 146.295044,619.639282 145.947266,618.374268
	C146.892426,618.082764 147.592834,618.458313 147.924347,619.724609
z"
        />
        <path
          fill="#63879A"
          opacity="1.000000"
          stroke="none"
          d="
M119.939346,591.667114
	C118.995613,591.950562 118.359467,591.349487 117.649796,590.292480
	C118.535034,589.930054 119.494469,590.078125 119.939346,591.667114
z"
        />
        <path
          fill="#63879A"
          opacity="1.000000"
          stroke="none"
          d="
M127.931244,599.676208
	C127.280594,599.546814 126.576736,599.048462 125.667557,598.280640
	C126.523994,597.965088 127.470818,598.133301 127.931244,599.676208
z"
        />
        <path
          fill="#5B7D8C"
          opacity="1.000000"
          stroke="none"
          d="
M163.931824,636.727905
	C162.876099,637.234558 162.287704,636.637207 161.932846,635.378967
	C162.896713,635.081299 163.615295,635.433105 163.931824,636.727905
z"
        />
        <path
          fill="#63879A"
          opacity="1.000000"
          stroke="none"
          d="
M115.963867,587.710815
	C115.085197,587.922607 114.485016,587.405090 114.014099,586.324829
	C114.969345,585.954346 115.652489,586.380859 115.963867,587.710815
z"
        />
        <path
          fill="#63879A"
          opacity="1.000000"
          stroke="none"
          d="
M131.967499,603.710083
	C131.042984,603.977966 130.469727,603.417664 130.004211,602.333374
	C130.959915,601.976746 131.670227,602.364380 131.967499,603.710083
z"
        />
        <path
          fill="#5B7D8C"
          opacity="1.000000"
          stroke="none"
          d="
M150.964340,623.709595
	C150.375854,623.552734 149.779068,623.058350 149.060043,622.291260
	C149.910370,622.022705 150.717422,622.273560 150.964340,623.709595
z"
        />
        <path
          fill="#95D1E2"
          opacity="1.000000"
          stroke="none"
          d="
M184.301453,640.018921
	C183.996902,639.731812 184.025986,639.491943 184.107269,638.892456
	C184.969193,638.745422 185.987213,638.530823 186.373444,639.612122
	C185.793976,639.763489 185.214523,639.914856 184.301453,640.018921
z"
        />
        <path
          fill="#63879A"
          opacity="1.000000"
          stroke="none"
          d="
M113.977325,585.704468
	C113.366531,585.559265 112.752266,585.073486 112.043388,584.303711
	C112.996315,583.906555 113.692795,584.322327 113.977325,585.704468
z"
        />
        <path
          fill="#63879A"
          opacity="1.000000"
          stroke="none"
          d="
M129.977692,601.703979
	C129.344376,601.566895 128.713242,601.091675 128.017838,600.318787
	C128.969925,599.955627 129.701340,600.319458 129.977692,601.703979
z"
        />
        <path
          fill="#63879A"
          opacity="1.000000"
          stroke="none"
          d="
M125.007927,597.170166
	C124.566750,596.931519 124.147217,596.324280 123.839478,595.358459
	C124.310707,595.600464 124.670143,596.201050 125.007927,597.170166
z"
        />
        <path
          fill="#63879A"
          opacity="1.000000"
          stroke="none"
          d="
M123.832382,594.791992
	C123.272720,595.500305 122.728462,595.141357 122.086655,594.338379
	C122.529091,594.193787 123.098129,594.363220 123.832382,594.791992
z"
        />
        <path
          fill="#5B7D8C"
          opacity="1.000000"
          stroke="none"
          d="
M168.222809,640.808533
	C167.791138,640.903564 167.089279,640.764587 166.164673,640.323364
	C166.612167,640.205566 167.282410,640.390015 168.222809,640.808533
z"
        />
        <path
          fill="#63879A"
          opacity="1.000000"
          stroke="none"
          d="
M112.002029,583.732605
	C111.568840,583.575806 111.157936,583.105347 110.847000,582.327209
	C111.306091,582.486023 111.665207,582.952454 112.002029,583.732605
z"
        />
        <path
          fill="#5B7D8C"
          opacity="1.000000"
          stroke="none"
          d="
M165.989197,639.748291
	C165.600418,639.584534 165.225082,639.109070 164.900970,638.321777
	C165.302322,638.485474 165.652481,638.960999 165.989197,639.748291
z"
        />
        <path
          fill="#5B7D8C"
          opacity="1.000000"
          stroke="none"
          d="
M148.985962,621.747803
	C148.582245,621.588135 148.186676,621.116089 147.871796,620.334106
	C148.299713,620.494568 148.646912,620.965027 148.985962,621.747803
z"
        />
        <path
          fill="#5B7D8C"
          opacity="1.000000"
          stroke="none"
          d="
M154.981781,627.729370
	C154.562943,627.571777 154.160065,627.107544 153.854462,626.327026
	C154.300400,626.481445 154.649063,626.952087 154.981781,627.729370
z"
        />
        <path
          fill="#5B7D8C"
          opacity="1.000000"
          stroke="none"
          d="
M152.828918,624.781860
	C152.289017,625.361633 151.742661,625.082825 151.100769,624.320190
	C151.529221,624.191101 152.101517,624.353394 152.828918,624.781860
z"
        />
        <path
          fill="#63879A"
          opacity="1.000000"
          stroke="none"
          d="
M132.936646,605.221680
	C132.699539,605.243713 132.399963,604.938110 132.028748,604.318481
	C132.262787,604.300964 132.568481,604.597412 132.936646,605.221680
z"
        />
        <path
          fill="#5B7D8C"
          opacity="1.000000"
          stroke="none"
          d="
M145.899384,617.790894
	C145.641586,617.898071 145.313385,617.755432 144.972778,617.321899
	C145.249939,617.201050 145.539474,617.371094 145.899384,617.790894
z"
        />
        <path
          fill="#63879A"
          opacity="1.000000"
          stroke="none"
          d="
M144.909988,616.790283
	C144.641342,616.901611 144.299393,616.757202 143.955353,616.317139
	C144.247742,616.192444 144.542206,616.363464 144.909988,616.790283
z"
        />
        <path
          fill="#63879A"
          opacity="1.000000"
          stroke="none"
          d="
M100.919792,571.830444
	C100.745193,571.912170 100.511955,571.738220 100.222839,571.310913
	C100.405113,571.220703 100.636513,571.393066 100.919792,571.830444
z"
        />
        <path
          fill="#63879A"
          opacity="1.000000"
          stroke="none"
          d="
M116.941963,589.215698
	C116.703445,589.237854 116.408867,588.929993 116.036247,588.311768
	C116.267433,588.296143 116.576660,588.590942 116.941963,589.215698
z"
        />
        <path
          fill="#63879A"
          opacity="1.000000"
          stroke="none"
          d="
M110.890846,581.801880
	C110.612122,581.919067 110.251984,581.790344 109.922226,581.345825
	C110.238220,581.205322 110.523827,581.380615 110.890846,581.801880
z"
        />
        <path
          fill="#63879A"
          opacity="1.000000"
          stroke="none"
          d="
M109.890274,580.799927
	C109.626991,580.909912 109.284126,580.773071 108.947182,580.328003
	C109.238960,580.197571 109.524826,580.375305 109.890274,580.799927
z"
        />
        <path
          fill="#63879A"
          opacity="1.000000"
          stroke="none"
          d="
M105.905411,576.792847
	C105.627541,576.910889 105.278557,576.774841 104.942017,576.335144
	C105.247742,576.200562 105.541023,576.369629 105.905411,576.792847
z"
        />
        <path
          fill="#63879A"
          opacity="1.000000"
          stroke="none"
          d="
M108.897430,579.799927
	C108.633553,579.907776 108.287079,579.768982 107.947174,579.328308
	C108.240776,579.202026 108.527809,579.377625 108.897430,579.799927
z"
        />
        <path
          fill="#63879A"
          opacity="1.000000"
          stroke="none"
          d="
M107.896301,578.797485
	C107.628410,578.909241 107.283356,578.771912 106.945938,578.330688
	C107.242096,578.200684 107.530609,578.374634 107.896301,578.797485
z"
        />
        <path
          fill="#63879A"
          opacity="1.000000"
          stroke="none"
          d="
M121.926834,593.778320
	C121.687744,593.877563 121.389282,593.711182 121.024117,593.282654
	C121.260765,593.184631 121.564110,593.348694 121.926834,593.778320
z"
        />
        <path
          fill="#63879A"
          opacity="1.000000"
          stroke="none"
          d="
M120.946457,592.764160
	C120.665848,592.881042 120.354652,592.718994 119.999611,592.286377
	C120.275795,592.172363 120.595833,592.328857 120.946457,592.764160
z"
        />
        <path
          fill="#5B7D8C"
          opacity="1.000000"
          stroke="none"
          d="
M164.880508,637.811707
	C164.658844,637.890991 164.333130,637.740112 163.983459,637.303345
	C164.231827,637.205505 164.504150,637.393494 164.880508,637.811707
z"
        />
        <path
          fill="#5B7D8C"
          opacity="1.000000"
          stroke="none"
          d="
M153.880478,625.811401
	C153.631271,625.908386 153.280518,625.773743 152.943573,625.331177
	C153.231216,625.208740 153.505066,625.394226 153.880478,625.811401
z"
        />
        <path
          fill="#5B7D8C"
          opacity="1.000000"
          stroke="none"
          d="
M159.891876,632.789124
	C159.636536,632.893799 159.310669,632.749817 158.972473,632.318115
	C159.247238,632.200439 159.534317,632.370483 159.891876,632.789124
z"
        />
        <path
          fill="#5B7D8C"
          opacity="1.000000"
          stroke="none"
          d="
M161.893066,634.792480
	C161.634308,634.902649 161.304031,634.765015 160.967773,634.332275
	C161.248352,634.206299 161.534958,634.375427 161.893066,634.792480
z"
        />
        <path
          fill="#63879A"
          opacity="1.000000"
          stroke="none"
          d="
M106.896820,577.797363
	C106.634422,577.905334 106.295570,577.764404 105.955124,577.322876
	C106.242470,577.197632 106.531418,577.373047 106.896820,577.797363
z"
        />
        <path
          fill="#5B7D8C"
          opacity="1.000000"
          stroke="none"
          d="
M160.892487,633.790161
	C160.633652,633.897217 160.304596,633.756287 159.968750,633.326477
	C160.248718,633.205688 160.535507,633.373901 160.892487,633.790161
z"
        />
        <path
          fill="#5B7D8C"
          opacity="1.000000"
          stroke="none"
          d="
M155.898331,628.800232
	C155.685303,628.876648 155.384445,628.709351 155.022675,628.280579
	C155.244675,628.198242 155.527588,628.377380 155.898331,628.800232
z"
        />
        <path
          fill="#99D7E8"
          opacity="1.000000"
          stroke="none"
          d="
M192.345337,596.809814
	C191.662796,597.008362 191.275436,596.927429 191.009140,597.070984
	C189.057663,598.123352 185.822571,598.455322 185.504181,600.451965
	C185.122849,602.843567 188.770905,602.676025 190.161377,604.166138
	C193.167908,607.387939 196.970749,607.020569 200.781509,607.016418
	C206.292587,607.010437 206.449448,607.264038 205.226273,614.484070
	C201.741821,607.473999 195.416214,610.524963 190.816849,608.697937
	C182.445450,605.372620 174.013077,606.589722 165.608368,605.820740
	C163.019623,605.583862 160.679153,603.801208 158.466476,602.598511
	C155.893051,601.199585 158.962051,599.841614 159.972214,598.756042
	C161.214447,597.421082 165.252869,597.684387 163.237640,594.636292
	C161.473129,591.967407 158.633667,592.673767 156.455460,595.031311
	C154.247726,597.420715 150.375900,597.877075 149.031799,601.693359
	C147.590332,600.703857 146.201904,599.366699 144.672592,597.738892
	C144.549835,597.225037 144.567978,597.001892 144.839767,596.558716
	C146.161057,595.926758 147.399307,595.563721 145.404816,594.165649
	C144.994217,593.847046 144.843094,593.710876 144.487915,593.377563
	C143.777893,592.777832 143.338898,592.906799 142.715561,593.573486
	C142.306198,593.903870 142.120743,594.010681 141.618774,594.170654
	C130.770477,583.656372 120.220589,573.106689 109.735504,562.492981
	C108.712357,561.457336 108.088577,560.027161 107.253235,558.451233
	C119.638268,554.165161 132.656982,553.980835 145.385132,551.993713
	C150.286591,551.228516 155.212097,550.617371 160.560486,549.939636
	C163.727722,553.395935 164.694534,557.241760 163.765594,561.592773
	C162.676392,566.694519 163.293900,567.226868 168.731857,566.848022
	C175.988037,566.342590 182.318298,568.799988 188.230713,573.488647
	C189.230164,574.742676 190.025208,575.668457 191.483871,576.427490
	C192.036087,576.872009 192.275040,577.100830 192.534164,577.304504
	C199.373276,582.680237 199.421890,582.704895 195.439590,590.398560
	C194.387863,592.430420 194.691467,595.038757 192.345337,596.809814
z"
        />
        <path
          fill="#99D7E8"
          opacity="1.000000"
          stroke="none"
          d="
M143.620880,596.082336
	C142.674789,595.669739 142.085617,595.199341 141.399353,594.476440
	C141.406052,594.002869 141.509842,593.781799 141.826004,593.262329
	C142.723694,592.411316 143.485260,592.260742 144.662308,592.763672
	C144.998169,592.997559 145.000000,593.000000 145.000610,593.001221
	C145.446686,593.679382 145.305603,594.355469 144.786407,595.323730
	C144.375610,595.752197 144.176712,595.888367 143.620880,596.082336
z"
        />
        <path
          fill="#99D7E8"
          opacity="1.000000"
          stroke="none"
          d="
M144.653442,597.333252
	C144.147476,597.162903 143.763229,596.877747 143.321472,596.366333
	C143.376923,595.937012 143.489868,595.733887 143.819366,595.257690
	C145.059113,594.853699 145.367325,595.463501 145.221725,596.656860
	C145.014786,596.984741 144.775162,597.218445 144.653442,597.333252
z"
        />
        <path
          fill="#D0C3BE"
          opacity="1.000000"
          stroke="none"
          d="
M242.373444,281.013855
	C241.614517,280.654663 241.111740,280.402466 240.356628,280.020966
	C239.702927,279.712860 239.340363,279.474854 239.012924,278.582520
	C239.809265,278.220306 240.609253,278.453339 241.706940,278.790222
	C249.273911,279.680054 256.306274,278.752258 263.177979,276.224121
	C264.655792,275.680420 266.010101,275.406647 266.121887,273.124939
	C266.565582,272.762146 266.969788,272.801514 267.678589,272.830658
	C269.047638,272.656952 270.032104,272.840820 271.177399,273.774780
	C271.871765,274.732330 272.036743,275.473846 271.912720,276.637756
	C266.191620,280.970490 259.775787,282.761505 253.048004,283.546356
	C251.251144,283.755981 249.431229,283.667358 247.387024,284.566162
	C246.524109,284.701324 246.037186,284.635956 245.269333,284.235413
	C244.973679,284.009552 244.999084,283.995667 244.995834,284.009613
	C244.571136,283.574371 244.518097,282.974365 244.146271,282.206482
	C243.953995,281.986816 244.013550,281.987915 244.010193,282.017517
	C243.607193,281.649994 243.090851,281.426331 242.373444,281.013855
z"
        />
        <path
          fill="#D0C3BE"
          opacity="1.000000"
          stroke="none"
          d="
M238.059128,277.942261
	C238.335663,277.848022 238.592743,277.760773 238.929535,277.830383
	C239.009262,277.987274 238.999817,278.000336 238.995621,278.006104
	C238.821655,278.182983 238.651886,278.354126 238.267654,278.234131
	C238.053192,277.943024 238.039673,277.949249 238.059128,277.942261
z"
        />
        <path
          fill="#213642"
          opacity="1.000000"
          stroke="none"
          d="
M331.043213,166.355194
	C336.157593,167.511398 337.481537,171.290146 337.000153,176.562027
	C336.509613,178.373245 337.525574,180.093796 335.731506,181.472260
	C334.127106,182.185654 332.901794,181.872757 331.330872,181.315796
	C330.294495,180.930725 329.693451,180.500000 328.703735,180.048218
	C325.159851,178.123047 321.729950,178.689636 319.121033,180.537262
	C316.217407,182.593628 317.633667,185.922913 318.416595,188.830353
	C319.997528,194.701462 322.579041,196.620010 328.435120,196.286514
	C330.157196,196.188446 331.740295,196.240616 332.341064,194.100830
	C332.821289,192.390533 334.175598,191.867538 336.207214,192.244873
	C338.646606,193.936325 338.039001,195.962692 337.194305,197.761536
	C335.874603,200.571960 336.365540,202.933350 337.538483,205.767120
	C339.582306,210.704895 338.222961,215.892014 337.196594,220.920334
	C336.670624,223.497360 335.407196,225.946152 336.048309,229.091888
	C335.805359,232.137543 334.452820,232.888016 331.696045,231.556427
	C328.695343,227.354706 324.501953,226.866150 320.371582,226.938065
	C311.764557,227.087936 303.243805,225.931122 294.299194,225.610718
	C292.802490,225.458328 291.669403,225.094894 290.266296,225.874237
	C289.743195,226.149902 289.525909,226.253754 288.969940,226.501480
	C285.391693,229.843246 285.820251,233.794159 286.485992,237.712708
	C288.147064,247.489746 288.429138,257.446198 290.390350,267.186066
	C290.710571,268.776337 290.690765,270.486694 289.834351,272.422546
	C288.634644,273.795776 287.595184,273.707397 286.564972,272.838959
	C285.781525,272.178558 285.171509,271.296844 284.991882,270.343445
	C283.429962,262.053040 278.075989,256.636200 271.197327,251.830139
	C270.310059,237.253357 268.966400,223.052521 269.555573,208.799026
	C269.793518,203.043060 274.575378,198.875626 281.043030,198.567230
	C285.685516,198.345901 290.341553,198.583130 294.988281,198.468872
	C296.693909,198.426926 298.737183,199.157486 299.793274,196.682678
	C299.470734,195.923630 299.256378,195.493958 298.990234,194.692902
	C297.816467,192.517548 295.994476,193.006683 294.406525,193.024689
	C287.130920,193.107162 279.856323,193.279327 272.581238,193.411575
	C269.330200,193.470657 268.576141,197.295761 265.378693,198.001129
	C264.898285,196.282608 265.368164,194.193649 263.171967,193.310562
	C261.039246,192.453033 260.289429,193.973816 259.500763,195.584656
	C259.011902,196.583221 257.709381,197.109070 257.239624,195.786743
	C255.971970,192.218231 253.077988,193.079315 250.536560,193.022461
	C247.872467,192.962845 245.241730,192.996185 242.642136,192.143768
	C238.936829,190.928757 235.180084,192.213531 231.587723,192.214386
	C223.683273,192.216278 215.746506,195.550949 207.759644,192.308456
	C205.924454,191.563416 203.636063,191.019333 201.138596,192.416931
	C198.354294,193.975067 195.753159,191.660461 193.694397,189.764206
	C192.415588,188.586365 192.868729,177.997009 193.983398,176.479858
	C195.418823,174.526123 196.481094,172.652390 195.073318,170.097351
	C193.575256,167.378510 191.882492,168.732620 190.021301,169.393677
	C188.138474,170.062393 186.570221,169.285950 186.206375,167.366272
	C185.887894,165.685852 185.210693,162.725983 187.188095,162.524170
	C191.864304,162.046982 195.025665,158.488861 199.610245,157.641449
	C205.129745,156.621262 210.143600,157.776169 215.330032,158.667023
	C217.578018,159.053131 219.396454,160.165894 220.027359,162.588043
	C220.458221,164.242264 221.685120,165.145218 223.208267,165.415176
	C225.444229,165.811401 224.799179,163.818024 225.126282,162.718674
	C225.724869,160.706818 227.398880,160.886765 228.806320,161.040329
	C239.404037,162.196701 250.032547,160.702316 260.566376,160.463638
	C273.762512,160.164612 286.853027,162.223831 300.027588,161.120621
	C304.072235,160.781921 305.221497,159.259659 305.050385,155.577118
	C304.930542,152.998062 304.174469,149.538971 307.169647,148.456390
	C310.397736,147.289642 314.125458,147.466751 317.404327,149.080627
	C319.895081,150.306564 319.299744,152.465607 318.134094,154.164688
	C316.664978,156.306198 317.128754,157.890808 318.889435,159.366562
	C321.623352,161.658142 322.876160,164.627579 323.006653,168.112213
	C323.150543,171.955338 326.011169,171.005951 328.139099,170.887466
	C330.614807,170.749588 332.379700,169.699768 331.043213,166.355194
z"
        />
        <path
          fill="#6CDAE1"
          opacity="1.000000"
          stroke="none"
          d="
M298.513672,194.398590
	C299.127289,194.571609 299.384857,194.746292 299.908447,194.929703
	C301.573395,199.146515 298.836029,199.041443 296.160400,198.812683
	C290.672485,198.343460 285.187897,198.175507 279.728149,199.002472
	C272.670532,200.071472 269.567322,203.732620 269.491241,210.705017
	C269.296814,228.523422 271.533569,246.246964 271.535736,264.056763
	C271.535919,265.569702 272.264313,266.967377 272.242126,268.923645
	C272.124634,269.648224 272.041992,269.931183 271.725708,270.588501
	C270.785828,271.629974 269.964325,271.754547 268.725037,271.200195
	C267.261139,250.999939 266.002838,231.085602 265.051788,211.156601
	C264.560944,200.871628 269.863831,195.333954 280.186615,194.842712
	C286.155487,194.558655 292.138824,194.578873 298.513672,194.398590
z"
        />
        <path
          fill="#213642"
          opacity="1.000000"
          stroke="none"
          d="
M263.011780,225.526474
	C259.393463,222.500427 260.050995,217.646530 259.029266,213.342194
	C258.167419,209.711349 256.179779,206.033981 259.906769,202.550949
	C261.014252,201.515976 261.894257,201.022018 263.631714,201.019806
	C261.966187,208.966095 263.473236,217.052979 263.011780,225.526474
z"
        />
        <path
          fill="#67BFC6"
          opacity="1.000000"
          stroke="none"
          d="
M268.300110,271.231689
	C268.954407,270.928192 269.516663,270.936035 270.360565,270.934631
	C271.488373,271.035614 271.673370,271.663971 271.722839,272.699280
	C271.568909,273.234497 271.460663,273.433228 271.047058,273.780090
	C269.945923,273.726593 269.150177,273.524963 268.168762,273.071838
	C268.058136,272.394623 268.133118,271.968842 268.300110,271.231689
z"
        />
        <path
          fill="#213642"
          opacity="1.000000"
          stroke="none"
          d="
M265.836456,271.109100
	C265.595795,271.172058 265.248383,270.945923 264.837891,270.404266
	C265.093109,270.332520 265.411407,270.576233 265.836456,271.109100
z"
        />
        <path
          fill="#AAF9FA"
          opacity="1.000000"
          stroke="none"
          d="
M496.753601,96.734131
	C504.343567,88.698975 511.812592,80.991722 520.981201,74.719299
	C521.442505,74.197220 521.762390,73.955284 522.215332,73.436882
	C523.169067,71.169960 525.231201,70.490211 526.938049,68.867676
	C546.122559,56.560333 566.532898,52.924107 588.821289,57.539330
	C591.741516,57.855709 593.784058,59.543545 596.607727,60.078777
	C597.544128,60.241741 598.116455,60.343513 598.900757,60.648155
	C596.341492,65.507904 591.497681,62.535282 587.471069,63.959328
	C586.030396,64.863899 584.727356,64.306915 583.262146,64.794113
	C582.967773,65.014442 582.996521,64.992897 583.013550,64.987274
	C577.127014,66.910034 571.091797,68.490410 565.316406,71.782898
	C565.010132,71.998322 565.002441,72.003181 564.998291,72.005615
	C564.279358,72.707344 563.324768,72.389450 562.235229,72.801849
	C561.976196,73.006058 561.997253,72.996590 562.004883,72.987991
	C561.787109,73.208115 561.560669,73.435913 561.165039,73.835182
	C560.996765,74.007576 561.008972,73.987282 561.020569,73.984680
	C560.587524,74.418175 559.987244,74.482529 559.202271,74.836998
	C558.973450,75.021690 558.991455,74.989502 559.009399,74.985519
	C558.788818,75.207664 558.554260,75.437981 558.152222,75.849556
	C557.980713,76.026642 557.997437,75.995781 558.014526,75.991699
	C557.183228,76.733765 556.019531,76.877411 554.857666,77.720459
	C554.267578,78.213341 553.897278,78.441788 553.238525,78.822906
	C552.981812,79.026031 552.989319,78.989426 553.007812,78.986572
	C552.787659,79.208733 552.553589,79.438339 552.153687,79.849899
	C551.983276,80.027260 551.993652,79.989693 552.013062,79.989502
	C551.585815,80.418602 550.988037,80.482651 550.206421,80.836433
	C549.979492,81.021851 549.988464,80.984932 550.007446,80.984985
	C549.788269,81.208229 549.555176,81.436523 549.159058,81.847931
	C548.991089,82.025948 548.988525,81.991905 549.005554,81.992287
	C548.783569,82.213188 548.550659,82.439972 548.155884,82.849792
	C547.987976,83.026573 547.991760,82.987076 548.011353,82.990097
	C547.583374,83.420891 546.984802,83.482574 546.203979,83.838058
	C545.976929,84.023201 545.984375,83.981575 546.005493,83.981186
	C545.537170,84.578163 545.178040,85.298721 544.211670,85.844261
	C543.984192,86.028160 543.985840,85.985222 544.007324,85.986259
	C543.147827,86.843620 542.283325,87.716408 541.202393,88.807022
	C540.987915,89.026848 540.988831,88.988869 541.007812,88.988144
	C540.476624,89.521614 539.935852,90.065239 539.195984,90.816345
	C538.995972,91.022926 538.992310,90.993042 539.007385,90.993523
	C538.782227,91.214859 538.548157,91.442146 538.149902,91.851334
	C537.979370,92.026817 537.985962,91.988747 538.003906,91.981628
	C537.572571,92.409187 537.506958,93.007362 537.167786,93.798882
	C536.991028,94.033508 536.995667,93.996315 537.013672,93.991310
	C536.416626,94.456055 535.692261,94.808502 535.164551,95.795845
	C534.988464,96.030556 534.988281,95.991425 535.007568,95.988396
	C534.788208,96.208466 534.554626,96.436783 534.156250,96.846848
	C533.986389,97.023376 533.991150,96.989693 534.007996,96.987503
	C533.787720,97.208855 533.555054,97.436935 533.157227,97.845482
	C532.987610,98.021423 532.990295,97.990829 533.004944,97.986382
	C532.570374,98.411758 532.507019,99.005760 532.173401,99.792480
	C531.998291,100.025681 531.991943,99.993744 532.008240,99.994308
	C531.473145,100.524452 530.934692,101.067108 530.198242,101.817139
	C529.999695,102.023895 529.992310,101.994080 530.007690,101.993446
	C529.566101,102.414696 529.507385,103.007767 529.176880,103.791565
	C529.003845,104.024757 528.994629,103.996223 529.009521,103.997139
	C528.783813,104.213310 528.551514,104.437256 528.163269,104.847656
	C527.999023,105.025429 527.992798,104.993370 528.009155,104.994446
	C527.298767,105.741165 527.120422,106.826744 526.204834,107.807617
	C525.991028,108.027397 525.985962,107.989410 526.004761,107.985992
	C524.956482,109.387688 524.657410,111.225983 523.219482,112.788742
	C522.991455,113.026901 522.984680,112.989136 523.003540,112.985680
	C522.568115,113.412323 522.507507,114.011528 522.163940,114.798508
	C521.985596,115.031235 521.983643,114.990936 522.001221,114.981018
	C521.272522,115.674263 521.598816,116.642876 521.202759,117.752411
	C521.007996,118.023911 520.995117,117.996300 521.010376,117.996902
	C518.461182,122.536873 516.546814,127.329193 515.203247,132.731262
	C514.994019,133.037598 514.980957,132.989105 515.005371,132.983185
	C514.375000,133.624802 514.567932,134.646286 513.663269,135.624832
	C506.500183,136.935272 499.654755,137.068069 492.760620,136.531754
	C488.004700,136.161758 483.205566,136.784531 478.022217,136.027435
	C477.089722,135.546188 476.666626,135.137268 476.159729,134.234985
	C475.842224,133.290436 475.775085,132.735001 475.828369,131.774231
	C477.850891,125.314751 480.376007,119.487167 483.598846,113.581230
	C484.640137,111.719360 485.613770,110.218285 486.516327,108.293381
	C486.790497,107.807594 486.907562,107.617317 487.215698,107.152618
	C487.543427,106.703125 487.680023,106.528046 488.035248,106.103256
	C489.429108,104.582298 490.925751,103.628860 492.106720,101.912827
	C493.164917,100.746063 494.204926,99.994789 494.985626,98.587051
	C495.506592,97.758583 495.933228,97.269348 496.753601,96.734131
z"
        />
        <path
          fill="#A8FBFB"
          opacity="1.000000"
          stroke="none"
          d="
M456.767212,159.914047
	C456.470917,160.022369 456.189728,160.340042 456.105347,160.404633
	C455.896179,160.878418 455.697144,161.247543 455.200195,161.802521
	C454.976532,162.028397 454.989807,161.993240 455.004883,161.982025
	C454.574005,162.408432 454.506805,163.006729 454.166077,163.798676
	C453.988220,164.032944 453.996216,163.998047 454.012512,163.990601
	C452.926880,165.000534 451.930695,166.097015 451.184143,167.778671
	C450.989624,168.031586 450.994293,167.997101 451.010437,167.990631
	C450.788483,168.207993 450.555084,168.436646 450.157043,168.847214
	C449.987640,169.024292 449.995026,168.990921 450.011169,168.985352
	C449.788666,169.207535 449.553680,169.438965 449.150635,169.852203
	C448.978943,170.030304 448.984070,169.988144 449.003418,169.979416
	C448.123810,170.987640 447.990234,172.356750 447.195465,173.773346
	C446.997772,174.034088 446.985260,173.990189 447.007874,173.987274
	C446.569672,174.410172 446.507294,175.008850 446.169983,175.799515
	C445.994843,176.034775 445.986420,175.989227 446.009583,175.988693
	C445.789093,176.208130 445.553131,176.436081 445.156067,176.851059
	C444.987305,177.030136 444.983948,176.987488 445.005066,176.983917
	C444.114380,177.988754 443.993225,179.363007 443.190979,180.773407
	C442.991241,181.032303 442.981628,180.989624 443.002197,180.982147
	C442.124390,182.689529 441.852905,184.589569 441.204590,186.735779
	C441.000427,187.032974 440.984680,186.991592 441.006317,186.986847
	C440.260376,187.678680 440.605042,188.656250 440.196594,189.764771
	C439.999603,190.036240 439.981812,189.990692 440.005432,189.984436
	C439.262268,190.676361 439.604218,191.654755 439.195618,192.764893
	C438.997833,193.036346 439.000000,193.001221 438.998474,193.017441
	C437.569885,195.229080 438.804779,199.718170 433.558960,198.788666
	C431.006287,196.870438 431.817200,194.437653 431.798981,192.190048
	C431.754669,186.720444 430.910278,185.812790 425.600159,185.625595
	C424.114502,185.573212 422.621857,185.627701 421.149811,185.380615
	C410.651886,183.618546 406.284943,185.848892 401.929382,195.407074
	C401.137329,196.284256 400.452209,196.577621 399.259155,196.403107
	C396.806671,194.109146 398.269714,191.755600 398.549500,189.081451
	C398.614807,188.441040 398.646606,188.187042 398.765137,187.561966
	C399.358582,185.798981 400.213684,184.569275 400.858154,182.841919
	C406.502838,170.430756 414.061218,159.940414 424.964355,151.601578
	C426.707458,150.317032 428.322754,149.473740 430.091614,148.238846
	C431.181793,147.601837 431.877106,147.010254 432.817444,146.146637
	C437.100525,143.388138 441.540009,141.771500 446.204712,139.706741
	C456.212646,136.293335 466.229126,135.561859 476.682983,135.280273
	C482.436920,136.882599 487.981934,135.205093 493.669739,136.698944
	C493.710541,139.801620 491.100830,138.695343 489.319122,139.787781
	C488.978638,140.021851 488.995056,139.990036 489.012756,139.992371
	C487.989410,140.867615 486.709015,140.306366 485.283691,140.794876
	C485.000641,141.017014 485.000000,140.998093 485.007507,141.004089
	C484.572357,141.429596 483.981262,141.482285 483.213654,141.832153
	C482.991028,142.015396 482.996826,141.995255 483.007141,141.996826
	C482.583099,142.417267 482.005615,142.483017 481.243286,142.813187
	C481.025757,142.996948 481.011200,143.016907 480.999176,143.019684
	C480.555389,143.445801 479.966064,143.481216 479.215393,143.837708
	C478.998291,144.021393 478.997620,143.995834 479.009155,144.001526
	C478.575348,144.426849 477.982544,144.482468 477.210754,144.833008
	C476.986603,145.016357 476.996155,144.994553 477.008057,144.994537
	C476.003326,145.858444 474.652771,145.987900 473.266235,146.791687
	C473.016541,146.995972 473.003265,147.006439 472.996094,147.010956
	C472.708069,147.310394 472.398712,147.571991 471.778503,147.909286
	C471.492371,148.013901 471.495758,147.996567 471.504150,147.993988
	C470.279602,148.587036 469.423065,149.633636 468.216766,150.780334
	C467.980103,151.015930 468.000183,150.999603 468.010468,150.991760
	C467.252380,151.676392 466.176727,151.867752 465.203064,152.795715
	C464.986267,153.012421 465.003204,152.990417 465.014343,152.982178
	C464.711487,153.265625 464.431061,153.586700 464.078613,154.239838
	C463.618774,154.682510 463.264709,154.822495 462.718597,154.973785
	C462.117004,155.102509 461.746552,155.293808 461.199158,155.795868
	C460.977142,156.021484 460.993225,155.993942 461.008118,155.988312
	C460.151337,156.845078 459.284515,157.712357 458.210327,158.805679
	C457.997955,159.026764 457.991455,158.992523 458.008850,158.993134
	C457.728851,159.292267 457.407959,159.562958 456.767212,159.914047
z"
        />
        <path
          fill="#5D696E"
          opacity="1.000000"
          stroke="none"
          d="
M454.163879,170.997864
	C454.214661,170.229370 454.368042,169.829498 454.767517,169.219971
	C455.797607,168.681549 456.512024,168.283310 456.771179,167.041412
	C469.399689,151.085281 485.570435,141.597778 505.581665,140.095917
	C513.436584,139.506409 518.263916,137.945435 520.148499,129.429794
	C521.755432,122.168701 525.887329,115.660576 530.185242,109.123650
	C534.228333,107.345367 537.059692,107.993515 538.270569,112.750252
	C538.247925,113.406425 538.215698,113.663063 538.072266,114.281845
	C537.618042,115.547913 536.870911,116.190720 536.282104,117.326378
	C536.036621,117.818016 535.930908,118.009438 535.660034,118.478867
	C534.739807,119.681488 534.947205,120.790565 534.866882,122.211075
	C531.132446,130.884903 527.391602,139.182739 524.122192,147.678375
	C522.542175,151.784088 519.334778,152.738693 515.376160,152.712006
	C507.565308,152.659348 499.835785,153.265274 492.363037,155.836639
	C487.786011,157.411575 483.454132,159.404907 479.345703,162.674774
	C475.207153,163.965942 473.134613,168.179932 468.557037,168.488876
	C467.172180,168.294739 466.446747,166.651779 465.403290,167.391418
	C464.274292,168.191666 465.753235,168.893250 466.160461,169.985809
	C466.117188,174.282654 463.933746,176.850067 460.118896,178.531723
	C456.921967,176.893600 456.100342,173.743546 454.163879,170.997864
z"
        />
        <path
          fill="#64777D"
          opacity="1.000000"
          stroke="none"
          d="
M395.310211,251.306534
	C402.806793,236.904465 411.229950,223.199493 424.490479,213.007339
	C430.070099,208.718811 436.261353,205.593857 442.673340,202.096344
	C444.712463,200.180389 446.730347,199.074005 449.668396,199.227936
	C451.994049,200.760590 454.508392,200.241714 456.910004,201.419342
	C457.727692,202.271988 457.932617,202.997742 457.902222,204.159912
	C455.829102,209.090073 450.835571,208.971008 447.306152,211.154190
	C446.083954,211.910217 444.572845,212.274811 442.867249,212.919067
	C442.382446,213.231415 442.195862,213.366333 441.718933,213.685455
	C441.029816,214.087189 440.621582,214.285309 439.906189,214.618027
	C439.404877,214.860657 439.202179,214.951172 438.690063,215.158478
	C436.721161,215.777405 435.064758,216.284164 434.064514,218.325287
	C433.425476,219.128098 432.868439,219.500656 431.898987,219.837494
	C430.525177,220.096130 429.387512,220.027847 428.471863,221.286469
	C428.160645,221.705704 428.028564,221.866013 427.678345,222.239807
	C426.787018,222.969482 426.473419,223.693878 426.153687,224.810516
	C420.573059,231.615601 414.778473,237.768951 410.570831,245.286087
	C404.157013,256.744751 400.420471,268.773346 400.665497,281.964966
	C400.727264,285.290894 400.803497,288.630676 399.831665,292.278137
	C398.021851,294.928894 396.288635,294.483643 394.796906,292.620361
	C392.935547,290.295471 391.419464,287.890228 391.459900,284.587555
	C391.514343,280.140808 392.097443,275.636597 390.831024,270.818115
	C390.247101,265.136261 391.872986,260.238525 393.462219,254.928070
	C393.569061,253.731659 393.662048,252.939453 394.174255,251.851913
	C394.597504,251.357605 394.807892,251.198486 395.310211,251.306534
z"
        />
        <path
          fill="#11161D"
          opacity="1.000000"
          stroke="none"
          d="
M654.657349,83.043152
	C655.290100,83.587341 655.585083,84.034286 656.010010,84.722305
	C654.859619,86.725296 653.090454,86.833199 651.223816,86.298630
	C645.162476,84.562790 639.085815,83.022980 632.690552,83.245636
	C631.038086,83.303169 629.306396,82.911751 627.845520,82.166412
	C620.158081,78.244202 612.079895,77.526985 603.606750,78.408005
	C599.345215,78.851112 594.992004,78.617203 590.671326,78.058189
	C585.432434,77.380371 581.332947,81.183525 576.467102,82.159584
	C575.023132,82.449234 573.670532,83.192940 571.754944,82.862503
	C569.735962,81.574486 568.490356,79.723251 565.926819,81.782890
	C563.534302,83.705162 560.922729,83.050705 558.781311,80.213142
	C580.285889,65.529480 603.802185,61.888588 628.980591,68.565918
	C630.745972,69.034103 632.459961,69.696053 634.575134,70.402390
	C642.018250,73.721069 648.523926,77.779289 654.657349,83.043152
z"
        />
        <path
          fill="#617179"
          opacity="1.000000"
          stroke="none"
          d="
M453.795532,170.776917
	C457.326996,171.809708 458.856628,174.342453 459.715210,177.854187
	C460.902924,180.034103 459.911438,181.678635 459.114990,183.259918
	C456.819427,187.817810 454.712067,192.462570 452.266541,197.364670
	C448.868500,198.300659 446.024078,200.359558 442.271362,199.212616
	C442.856110,188.415268 447.908722,179.552460 453.795532,170.776917
z"
        />
        <path
          fill="#4B555C"
          opacity="1.000000"
          stroke="none"
          d="
M537.461121,115.194305
	C537.892700,110.488312 534.606934,109.735954 530.852661,108.965485
	C533.375183,103.547180 537.114380,98.765656 541.482056,94.086128
	C544.782227,92.586754 546.978943,96.451660 550.296997,95.293724
	C551.295471,95.691208 551.660156,96.243431 551.913513,97.252136
	C551.951416,97.797340 551.939575,98.014687 551.849854,98.542801
	C551.686035,99.043655 551.600220,99.233749 551.164062,99.913742
	C550.683472,100.546410 550.553223,100.689171 550.025024,101.226280
	C549.496155,101.746498 549.365295,101.872368 548.850586,102.370697
	C548.341492,102.867668 548.216248,102.992195 547.721313,103.495613
	C547.230286,104.004623 547.108887,104.134735 546.634583,104.676712
	C546.169922,105.237007 546.058105,105.385429 545.629150,106.018372
	C545.207458,106.676651 545.102844,106.850418 544.837219,107.291290
	C542.481323,110.218117 541.594177,114.046501 537.461121,115.194305
z"
        />
        <path
          fill="#2A363C"
          opacity="1.000000"
          stroke="none"
          d="
M551.394043,96.120087
	C547.648865,97.749535 544.826477,96.976692 542.127930,94.013512
	C545.073853,89.839737 549.282654,86.726524 553.615723,83.285141
	C555.499573,85.263680 557.298584,83.650230 558.920532,83.458855
	C561.039246,83.208885 563.200500,82.153740 564.718262,85.103638
	C562.455017,91.500046 556.592102,93.257782 551.394043,96.120087
z"
        />
        <path
          fill="#85C1D1"
          opacity="1.000000"
          stroke="none"
          d="
M432.744629,197.552368
	C437.099243,198.995621 437.020294,195.340149 438.669617,193.179535
	C438.913391,196.389648 439.711243,200.062576 435.059875,201.860580
	C429.495911,200.142639 424.588196,200.688187 419.644440,201.218765
	C416.254639,201.582550 412.873077,203.004242 409.125977,201.335266
	C409.152527,197.708908 411.910004,197.291992 414.342224,196.679886
	C416.029938,196.255157 417.898621,196.576218 419.822815,195.306213
	C424.410858,195.139084 428.751831,194.279373 432.744629,197.552368
z"
        />
        <path
          fill="#76D4DF"
          opacity="1.000000"
          stroke="none"
          d="
M494.275513,138.160660
	C490.498138,136.028488 486.789429,137.416245 483.223907,137.035583
	C481.274689,136.827515 479.232635,137.485931 477.268372,135.504181
	C489.149139,134.353500 501.073456,137.182068 513.442322,135.159470
	C514.012817,135.111221 514.237793,135.282761 514.226624,135.806885
	C508.631195,136.780273 503.271820,137.401062 497.471283,138.017731
	C496.308563,138.655197 495.447937,138.467117 494.275513,138.160660
z"
        />
        <path
          fill="#1A2026"
          opacity="1.000000"
          stroke="none"
          d="
M564.308960,86.338760
	C563.515259,83.769081 562.170837,83.881142 560.332825,84.637878
	C558.290161,85.478859 556.068420,86.691376 554.136780,83.627586
	C553.968323,83.204811 553.998230,83.232765 554.016846,83.229355
	C556.216797,83.514900 557.427124,81.900787 558.686279,80.378647
	C558.495728,80.001526 558.514587,79.992188 558.519653,79.985138
	C561.784729,81.296478 564.825867,82.758186 567.258728,78.286171
	C568.458435,76.080894 569.585266,76.830910 571.021973,81.643616
	C571.401184,82.802429 571.152405,83.500526 570.494019,84.467987
	C568.568115,85.989738 566.824707,87.009544 564.308960,86.338760
z"
        />
        <path
          fill="#8AB7C6"
          opacity="1.000000"
          stroke="none"
          d="
M565.388672,72.026810
	C569.793945,67.330597 576.001404,66.154053 582.621094,64.982819
	C577.692627,68.355972 571.415833,69.439072 565.388672,72.026810
z"
        />
        <path
          fill="#D0C3BE"
          opacity="1.000000"
          stroke="none"
          d="
M441.961487,198.991577
	C444.916504,198.225189 447.899872,197.465591 451.263306,196.624268
	C452.623077,197.039108 452.783936,197.913422 452.569519,199.324417
	C451.991425,200.257278 451.454376,200.505478 450.364685,200.355835
	C447.860352,200.779434 445.793518,201.351395 443.364563,201.948715
	C442.798126,201.402679 442.593781,200.831284 442.142822,200.068344
	C441.832916,199.713486 441.769623,199.550186 441.844727,199.163589
	C441.983124,198.940308 441.989868,198.998367 441.961487,198.991577
z"
        />
        <path
          fill="#759EB0"
          opacity="1.000000"
          stroke="none"
          d="
M515.299133,132.850464
	C515.361694,127.550919 517.469055,122.682594 520.668823,118.036636
	C519.218506,122.886116 517.411377,127.774727 515.299133,132.850464
z"
        />
        <path
          fill="#83AEBF"
          opacity="1.000000"
          stroke="none"
          d="
M417.144897,213.996475
	C415.846008,217.226868 413.288025,219.076706 410.245667,220.906250
	C409.363159,216.492020 412.338013,214.814301 415.930115,213.351715
	C416.527161,213.468048 416.753662,213.566376 417.144897,213.996475
z"
        />
        <path
          fill="#0D161D"
          opacity="1.000000"
          stroke="none"
          d="
M611.178894,62.631966
	C614.415161,59.718304 617.702759,60.767628 620.939331,63.714302
	C617.863037,63.643715 614.708374,63.205479 611.178894,62.631966
z"
        />
        <path
          fill="#83AEBF"
          opacity="1.000000"
          stroke="none"
          d="
M401.226898,233.078629
	C400.530182,236.569473 398.806702,239.274109 396.742096,242.166840
	C396.262482,242.410690 396.010345,242.407394 395.531372,242.100266
	C394.506439,237.739761 396.719238,235.123764 399.959473,232.725830
	C400.569122,232.664261 400.816193,232.702713 401.226898,233.078629
z"
        />
        <path
          fill="#83AEBF"
          opacity="1.000000"
          stroke="none"
          d="
M396.350372,242.280182
	C395.790009,244.384933 395.065460,246.355881 394.257782,248.668793
	C393.910583,249.068695 393.646484,249.126648 393.039551,248.964615
	C391.355194,245.728897 392.183563,243.447556 395.440674,241.868378
	C395.850891,241.887390 396.186157,242.146378 396.350372,242.280182
z"
        />
        <path
          fill="#83AEBF"
          opacity="1.000000"
          stroke="none"
          d="
M493.586548,137.937195
	C494.712311,137.939392 495.484375,137.976578 496.643250,138.013672
	C494.785645,139.311981 492.277161,139.671692 489.376892,140.038116
	C489.938843,138.388245 491.666656,138.347900 493.586548,137.937195
z"
        />
        <path
          fill="#759EB0"
          opacity="1.000000"
          stroke="none"
          d="
M523.354004,113.062698
	C523.203918,111.131294 524.266479,109.659187 525.705811,108.099487
	C526.382141,110.196365 525.179321,111.706047 523.354004,113.062698
z"
        />
        <path
          fill="#11161D"
          opacity="1.000000"
          stroke="none"
          d="
M558.147949,80.089073
	C559.192505,83.096924 557.295532,83.641266 554.458252,83.451324
	C555.290344,82.209511 556.545227,81.193062 558.147949,80.089073
z"
        />
        <path
          fill="#83AEBF"
          opacity="1.000000"
          stroke="none"
          d="
M404.068329,228.952026
	C403.497284,230.531586 402.712646,231.806976 401.659241,233.249237
	C401.390533,233.416092 401.100525,233.127747 400.953979,232.985153
	C399.770172,230.823883 400.697601,229.531189 402.772308,228.481018
	C403.379639,228.506821 403.617188,228.577362 404.068329,228.952026
z"
        />
        <path
          fill="#81A2AC"
          opacity="1.000000"
          stroke="none"
          d="
M451.292175,167.899124
	C450.723328,166.081818 451.686279,164.833450 453.670044,163.973022
	C453.901459,165.684387 452.681885,166.682938 451.292175,167.899124
z"
        />
        <path
          fill="#759EB0"
          opacity="1.000000"
          stroke="none"
          d="
M441.314697,186.846512
	C440.495178,184.903152 441.513672,183.156754 442.722473,181.178680
	C443.630890,183.132767 442.478638,184.859314 441.314697,186.846512
z"
        />
        <path
          fill="#83AEBF"
          opacity="1.000000"
          stroke="none"
          d="
M468.330139,150.977600
	C467.971222,149.153336 469.238159,148.470413 471.132874,148.004135
	C471.121338,149.505295 470.089478,150.403610 468.330139,150.977600
z"
        />
        <path
          fill="#759EB0"
          opacity="1.000000"
          stroke="none"
          d="
M541.339722,89.053612
	C541.077637,87.479630 542.039917,86.637001 543.695801,86.036491
	C544.272034,87.790848 542.943665,88.406830 541.339722,89.053612
z"
        />
        <path
          fill="#81A2AC"
          opacity="1.000000"
          stroke="none"
          d="
M458.348633,159.055084
	C457.991455,157.408096 459.179901,156.740051 460.689636,156.022537
	C461.211578,157.738052 459.965759,158.418472 458.348633,159.055084
z"
        />
        <path
          fill="#83AEBF"
          opacity="1.000000"
          stroke="none"
          d="
M407.982971,223.912872
	C407.801208,225.515686 407.045013,226.535095 405.151886,226.759567
	C404.257996,224.971329 404.998077,224.085251 406.648438,223.460861
	C407.262268,223.485443 407.502869,223.554596 407.982971,223.912872
z"
        />
        <path
          fill="#81A2AC"
          opacity="1.000000"
          stroke="none"
          d="
M553.778503,78.194473
	C554.773621,76.714836 555.939697,76.094086 557.691406,75.932625
	C557.352539,77.483727 555.779541,77.866531 554.300415,78.852509
	C553.862122,78.795067 553.725281,78.626793 553.778503,78.194473
z"
        />
        <path
          fill="#759EB0"
          opacity="1.000000"
          stroke="none"
          d="
M447.317841,173.894836
	C446.639832,172.474030 447.505676,171.381454 448.721985,170.139267
	C449.447052,171.556458 448.547363,172.657806 447.317841,173.894836
z"
        />
        <path
          fill="#83AEBF"
          opacity="1.000000"
          stroke="none"
          d="
M473.362152,147.082153
	C473.614075,145.496521 474.935211,145.157455 476.673462,144.963043
	C476.486420,146.588364 475.167450,146.985580 473.362152,147.082153
z"
        />
        <path
          fill="#85C1D1"
          opacity="1.000000"
          stroke="none"
          d="
M430.065063,204.960144
	C429.342651,206.290344 428.252075,206.943970 426.332245,207.012512
	C425.753754,204.970856 426.883423,204.279495 428.903717,204.209900
	C429.475739,204.394897 429.687958,204.514496 430.065063,204.960144
z"
        />
        <path
          fill="#759EB0"
          opacity="1.000000"
          stroke="none"
          d="
M443.351440,180.979126
	C442.838806,179.540695 443.515015,178.354752 444.707764,177.111115
	C445.414948,178.571045 444.823792,179.835266 443.351440,180.979126
z"
        />
        <path
          fill="#617179"
          opacity="1.000000"
          stroke="none"
          d="
M456.608612,166.902374
	C457.319916,168.056122 458.639282,169.840012 455.266907,169.105133
	C455.460785,168.360901 455.907959,167.711456 456.608612,166.902374
z"
        />
        <path
          fill="#759EB0"
          opacity="1.000000"
          stroke="none"
          d="
M526.348206,108.042305
	C525.904602,106.802483 526.611633,105.961105 527.703613,105.072853
	C528.282043,106.316422 527.840332,107.336266 526.348206,108.042305
z"
        />
        <path
          fill="#83AEBF"
          opacity="1.000000"
          stroke="none"
          d="
M465.312256,153.017685
	C465.052826,151.524277 466.084351,151.094467 467.669373,150.935150
	C467.576111,152.071991 466.692474,152.647110 465.312256,153.017685
z"
        />
        <path
          fill="#83AEBF"
          opacity="1.000000"
          stroke="none"
          d="
M423.480225,208.708679
	C423.333588,209.754959 422.552155,210.392029 421.206970,210.838501
	C420.347565,208.899460 421.287842,208.310043 423.480225,208.708679
z"
        />
        <path
          fill="#8AB7C6"
          opacity="1.000000"
          stroke="none"
          d="
M583.341309,65.050140
	C583.849426,63.867382 585.095642,63.915615 586.727051,63.993877
	C586.242249,65.200455 584.951233,65.057510 583.341309,65.050140
z"
        />
        <path
          fill="#759EB0"
          opacity="1.000000"
          stroke="none"
          d="
M539.340759,91.079361
	C538.931885,89.996231 539.569092,89.458130 540.702271,89.013496
	C541.212402,90.098549 540.608704,90.716881 539.340759,91.079361
z"
        />
        <path
          fill="#759EB0"
          opacity="1.000000"
          stroke="none"
          d="
M530.331604,102.064728
	C529.965088,100.998253 530.478149,100.315186 531.689209,99.926949
	C532.033081,100.996498 531.509888,101.655212 530.331604,102.064728
z"
        />
        <path
          fill="#AFEAF5"
          opacity="1.000000"
          stroke="none"
          d="
M392.737427,249.669006
	C393.192383,249.021988 393.387817,249.057877 393.878967,249.052261
	C394.550751,249.638107 394.926819,250.265457 395.452515,251.233276
	C395.602112,251.573715 395.569611,251.568344 395.557129,251.577637
	C395.044312,251.620514 394.545105,251.666611 393.681641,251.630035
	C392.853546,251.270447 392.574127,250.876099 392.737427,249.669006
z"
        />
        <path
          fill="#83AEBF"
          opacity="1.000000"
          stroke="none"
          d="
M485.360413,141.112549
	C485.776672,139.707214 487.087830,139.948349 488.661560,139.937958
	C488.202881,141.151672 486.932922,141.101807 485.360413,141.112549
z"
        />
        <path
          fill="#759EB0"
          opacity="1.000000"
          stroke="none"
          d="
M535.287781,95.966293
	C534.815125,94.920616 535.238281,94.171890 536.678101,93.905510
	C536.550171,94.624878 536.068665,95.263458 535.287781,95.966293
z"
        />
        <path
          fill="#759EB0"
          opacity="1.000000"
          stroke="none"
          d="
M439.302887,192.867096
	C439.125580,192.117294 439.253326,191.198227 439.705078,190.128677
	C439.888672,190.884735 439.748291,191.791275 439.302887,192.867096
z"
        />
        <path
          fill="#759EB0"
          opacity="1.000000"
          stroke="none"
          d="
M544.344177,86.092636
	C544.469971,85.408897 544.955811,84.789627 545.734131,84.075584
	C546.405762,85.204491 545.705566,85.772316 544.344177,86.092636
z"
        />
        <path
          fill="#759EB0"
          opacity="1.000000"
          stroke="none"
          d="
M440.292938,189.848907
	C439.730377,189.056702 439.818848,188.122513 440.690735,187.113495
	C440.880707,187.875244 440.733490,188.768402 440.292938,189.848907
z"
        />
        <path
          fill="#8AB7C6"
          opacity="1.000000"
          stroke="none"
          d="
M562.319458,73.071014
	C562.564758,72.224838 563.370544,71.892853 564.659912,71.939056
	C564.525452,73.021721 563.608337,73.108429 562.319458,73.071014
z"
        />
        <path
          fill="#759EB0"
          opacity="1.000000"
          stroke="none"
          d="
M521.320557,117.850235
	C520.631531,117.052620 520.907532,116.184669 521.724670,115.163475
	C522.490601,115.958496 522.149475,116.830017 521.320557,117.850235
z"
        />
        <path
          fill="#759EB0"
          opacity="1.000000"
          stroke="none"
          d="
M514.253052,135.922577
	C513.972961,135.953461 513.955444,135.747437 513.862915,135.240555
	C514.006531,134.358734 514.225159,133.777802 514.736755,133.087067
	C514.858398,133.880081 514.687012,134.782883 514.253052,135.922577
z"
        />
        <path
          fill="#759EB0"
          opacity="1.000000"
          stroke="none"
          d="
M522.325500,114.952118
	C522.143311,114.440582 522.301025,113.849937 522.740601,113.120758
	C523.430786,113.712067 523.138367,114.309586 522.325500,114.952118
z"
        />
        <path
          fill="#85C1D1"
          opacity="1.000000"
          stroke="none"
          d="
M434.993408,201.991013
	C434.588928,202.359573 434.190155,202.735992 433.291229,202.887543
	C433.013092,201.830643 433.594757,201.523895 434.700989,201.772934
	C434.993866,201.990372 434.999146,201.998871 434.993408,201.991013
z"
        />
        <path
          fill="#759EB0"
          opacity="1.000000"
          stroke="none"
          d="
M546.295532,84.174110
	C546.449890,83.647224 546.922791,83.271248 547.713379,82.944199
	C547.887878,83.787270 547.346802,84.158081 546.295532,84.174110
z"
        />
        <path
          fill="#83AEBF"
          opacity="1.000000"
          stroke="none"
          d="
M405.041382,227.034668
	C404.951080,227.640945 404.842377,228.232071 404.507812,229.039688
	C404.281952,229.256180 403.966064,228.963226 403.808411,228.816437
	C403.422241,227.910461 403.617767,227.302307 404.639954,226.927383
	C404.986511,226.989624 405.023010,227.019516 405.041382,227.034668
z"
        />
        <path
          fill="#759EB0"
          opacity="1.000000"
          stroke="none"
          d="
M532.295288,99.897812
	C531.654541,99.316689 531.912292,98.733109 532.699951,98.087669
	C532.877197,98.577934 532.734802,99.173935 532.295288,99.897812
z"
        />
        <path
          fill="#759EB0"
          opacity="1.000000"
          stroke="none"
          d="
M537.278015,93.884399
	C536.573120,93.308655 536.924988,92.755821 537.708008,92.101860
	C537.869568,92.561440 537.717285,93.148369 537.278015,93.884399
z"
        />
        <path
          fill="#83AEBF"
          opacity="1.000000"
          stroke="none"
          d="
M477.306152,145.054138
	C477.137085,144.256912 477.684998,143.986450 478.697144,143.948151
	C478.555695,144.368790 478.090698,144.730347 477.306152,145.054138
z"
        />
        <path
          fill="#83AEBF"
          opacity="1.000000"
          stroke="none"
          d="
M479.314880,144.047485
	C479.155090,143.289871 479.671356,142.985016 480.670959,142.950485
	C480.849854,143.778580 480.284241,143.982315 479.314880,144.047485
z"
        />
        <path
          fill="#83AEBF"
          opacity="1.000000"
          stroke="none"
          d="
M481.338531,143.041428
	C481.103577,142.177536 481.695770,141.993881 482.693176,141.954330
	C482.562073,142.360901 482.106689,142.723404 481.338531,143.041428
z"
        />
        <path
          fill="#85C1D1"
          opacity="1.000000"
          stroke="none"
          d="
M431.985718,203.925354
	C431.713226,204.495728 431.240051,204.798767 430.498413,205.194000
	C430.229950,205.286194 430.008728,204.993423 429.898804,204.846527
	C429.864929,204.241486 430.103546,203.888580 430.810364,203.559021
	C431.353455,203.546799 431.569244,203.602417 431.985718,203.925354
z"
        />
        <path
          fill="#83AEBF"
          opacity="1.000000"
          stroke="none"
          d="
M483.316895,142.059052
	C483.143707,141.260895 483.705872,141.033371 484.694153,140.975739
	C484.912994,141.820648 484.312592,142.005295 483.316895,142.059052
z"
        />
        <path
          fill="#759EB0"
          opacity="1.000000"
          stroke="none"
          d="
M550.299133,81.112350
	C550.451782,80.641846 550.924133,80.261841 551.714478,79.935562
	C551.561279,80.393814 551.090027,80.798332 550.299133,81.112350
z"
        />
        <path
          fill="#81A2AC"
          opacity="1.000000"
          stroke="none"
          d="
M454.275818,163.885818
	C454.148102,163.448959 454.307953,162.864960 454.743896,162.125885
	C454.867767,162.560104 454.715607,163.149399 454.275818,163.885818
z"
        />
        <path
          fill="#759EB0"
          opacity="1.000000"
          stroke="none"
          d="
M446.281128,175.899323
	C446.123718,175.403870 446.252594,174.772949 446.705994,174.063202
	C446.876160,174.577530 446.721802,175.170715 446.281128,175.899323
z"
        />
        <path
          fill="#759EB0"
          opacity="1.000000"
          stroke="none"
          d="
M529.317932,103.912079
	C529.136230,103.419662 529.268616,102.814560 529.712036,102.101135
	C530.363953,102.697006 530.112183,103.272911 529.317932,103.912079
z"
        />
        <path
          fill="#83AEBF"
          opacity="1.000000"
          stroke="none"
          d="
M421.057922,211.068146
	C420.720306,211.529678 420.256714,211.855743 419.281677,211.957977
	C418.872589,210.988892 419.334595,210.614075 420.362366,210.579376
	C420.678925,210.662750 420.931976,210.932663 421.057922,211.068146
z"
        />
        <path
          fill="#81A2AC"
          opacity="1.000000"
          stroke="none"
          d="
M559.295288,75.062958
	C559.449158,74.665512 559.924866,74.309326 560.716370,73.967606
	C560.560486,74.356125 560.088745,74.730179 559.295288,75.062958
z"
        />
        <path
          fill="#83AEBF"
          opacity="1.000000"
          stroke="none"
          d="
M409.031036,222.007507
	C409.058441,222.781967 408.860382,223.309036 408.442444,224.019073
	C408.222534,224.202011 407.939423,223.936096 407.798462,223.802536
	C407.509949,223.233582 407.517700,222.800964 407.915039,222.131821
	C408.368103,221.848373 408.586853,221.804245 409.031036,222.007507
z"
        />
        <path
          fill="#83AEBF"
          opacity="1.000000"
          stroke="none"
          d="
M417.623230,213.198669
	C418.151764,213.558365 417.976318,213.829666 417.507599,214.183807
	C417.309601,214.328247 417.106628,214.107803 417.005737,213.997070
	C416.941376,213.648193 416.977936,213.410049 417.623230,213.198669
z"
        />
        <path
          fill="#8AB7C6"
          opacity="1.000000"
          stroke="none"
          d="
M561.275757,73.961136
	C561.158875,73.707169 561.321045,73.406761 561.747864,73.042870
	C561.859985,73.291161 561.707397,73.602928 561.275757,73.961136
z"
        />
        <path
          fill="#85C1D1"
          opacity="1.000000"
          stroke="none"
          d="
M433.137146,203.146362
	C433.090179,203.545685 432.883728,203.785446 432.397278,204.079178
	C432.186401,204.192688 431.977722,203.958298 431.874878,203.839783
	C431.810974,203.335327 431.991486,203.034210 432.591187,202.830765
	C432.868927,202.843704 433.046753,203.046356 433.137146,203.146362
z"
        />
        <path
          fill="#81A2AC"
          opacity="1.000000"
          stroke="none"
          d="
M558.262939,75.980576
	C558.138062,75.704536 558.295349,75.382416 558.739990,75.020912
	C558.866638,75.299187 558.705933,75.616844 558.262939,75.980576
z"
        />
        <path
          fill="#83AEBF"
          opacity="1.000000"
          stroke="none"
          d="
M410.145508,221.149689
	C410.102234,221.527344 409.922638,221.792099 409.463654,222.131378
	C409.256500,222.254883 409.115143,222.112396 409.042847,222.042816
	C408.817169,221.523209 408.943542,221.159241 409.631287,220.913940
	C409.952454,220.958755 410.081421,221.085785 410.145508,221.149689
z"
        />
        <path
          fill="#759EB0"
          opacity="1.000000"
          stroke="none"
          d="
M548.272705,82.992096
	C548.141296,82.701378 548.294617,82.376175 548.735229,82.021820
	C548.867554,82.314323 548.712463,82.635963 548.272705,82.992096
z"
        />
        <path
          fill="#759EB0"
          opacity="1.000000"
          stroke="none"
          d="
M549.298218,82.017860
	C549.149719,81.709908 549.308350,81.393883 549.746704,81.031448
	C549.886047,81.326630 549.745667,81.668205 549.298218,82.017860
z"
        />
        <path
          fill="#83AEBF"
          opacity="1.000000"
          stroke="none"
          d="
M419.154480,212.156616
	C419.122467,212.541946 418.936798,212.800919 418.444031,213.108627
	C418.231964,213.225464 418.096924,213.092270 418.028931,213.026154
	C417.834106,212.505249 417.960571,212.137558 418.660858,211.917923
	C418.981384,211.978851 419.095764,212.098358 419.154480,212.156616
z"
        />
        <path
          fill="#85C1D1"
          opacity="1.000000"
          stroke="none"
          d="
M426.182312,207.184937
	C426.138672,207.583878 425.929382,207.819794 425.234619,207.824890
	C424.926056,207.435242 425.042938,207.223114 425.514343,206.931885
	C425.868866,206.852753 426.076721,207.075256 426.182312,207.184937
z"
        />
        <path
          fill="#83AEBF"
          opacity="1.000000"
          stroke="none"
          d="
M425.147034,208.091537
	C425.095306,208.540039 424.875183,208.780548 424.347900,209.035461
	C424.115051,209.117889 423.934021,208.914322 423.844452,208.811707
	C423.806061,208.305099 423.994049,207.980255 424.610107,207.692230
	C424.901489,207.649994 425.052917,207.951523 425.147034,208.091537
z"
        />
        <path
          fill="#759EB0"
          opacity="1.000000"
          stroke="none"
          d="
M553.706543,78.014336
	C553.976807,78.102684 553.984985,78.274940 553.996094,78.705276
	C553.831177,79.001381 553.663330,79.039406 553.238708,79.051743
	C553.136047,78.716782 553.290283,78.407509 553.706543,78.014336
z"
        />
        <path
          fill="#759EB0"
          opacity="1.000000"
          stroke="none"
          d="
M552.265015,79.984573
	C552.136230,79.696945 552.289124,79.366623 552.734131,79.010010
	C552.866394,79.303101 552.706604,79.622490 552.265015,79.984573
z"
        />
        <path
          fill="#759EB0"
          opacity="1.000000"
          stroke="none"
          d="
M449.274109,170.021942
	C449.140289,169.704391 449.301636,169.378479 449.745148,169.016174
	C449.874634,169.324387 449.721954,169.668991 449.274109,170.021942
z"
        />
        <path
          fill="#81A2AC"
          opacity="1.000000"
          stroke="none"
          d="
M450.270721,168.990753
	C450.137817,168.686478 450.287994,168.348648 450.732391,167.997498
	C450.868988,168.308517 450.711395,168.632858 450.270721,168.990753
z"
        />
        <path
          fill="#759EB0"
          opacity="1.000000"
          stroke="none"
          d="
M445.279510,177.017090
	C445.140106,176.689529 445.292938,176.348938 445.739258,175.998260
	C445.879089,176.326797 445.725433,176.665436 445.279510,177.017090
z"
        />
        <path
          fill="#759EB0"
          opacity="1.000000"
          stroke="none"
          d="
M528.288513,105.014145
	C528.142212,104.678848 528.285400,104.332260 528.726562,103.991867
	C528.875671,104.332993 528.726868,104.667923 528.288513,105.014145
z"
        />
        <path
          fill="#83AEBF"
          opacity="1.000000"
          stroke="none"
          d="
M471.845123,148.024780
	C471.778625,147.708603 472.064850,147.403290 472.670044,147.056732
	C472.725281,147.355530 472.461578,147.695587 471.845123,148.024780
z"
        />
        <path
          fill="#81A2AC"
          opacity="1.000000"
          stroke="none"
          d="
M455.330933,161.929535
	C455.170929,161.656387 455.365326,161.284378 455.790344,160.690796
	C455.909088,160.923050 455.797211,161.376862 455.330933,161.929535
z"
        />
        <path
          fill="#81A2AC"
          opacity="1.000000"
          stroke="none"
          d="
M456.837952,160.057037
	C456.778351,159.722488 457.085815,159.422623 457.709778,159.058258
	C457.752502,159.359741 457.478729,159.725723 456.837952,160.057037
z"
        />
        <path
          fill="#81A2AC"
          opacity="1.000000"
          stroke="none"
          d="
M461.315674,155.999451
	C461.270996,155.705627 461.564850,155.389786 462.192627,155.029510
	C462.235779,155.315872 461.944977,155.646652 461.315674,155.999451
z"
        />
        <path
          fill="#81A2AC"
          opacity="1.000000"
          stroke="none"
          d="
M464.214905,154.324158
	C464.096619,154.098083 464.220398,153.653625 464.684814,153.091553
	C464.835999,153.351227 464.646484,153.728516 464.214905,154.324158
z"
        />
        <path
          fill="#617179"
          opacity="1.000000"
          stroke="none"
          d="
M441.741211,198.840836
	C441.983124,198.940308 442.020050,199.416306 441.958130,199.646545
	C441.276367,199.668716 441.137726,199.292465 441.741211,198.840836
z"
        />
        <path
          fill="#759EB0"
          opacity="1.000000"
          stroke="none"
          d="
M533.279175,98.004623
	C533.142334,97.693108 533.297058,97.364784 533.738281,97.010880
	C533.873413,97.319473 533.722046,97.653648 533.279175,98.004623
z"
        />
        <path
          fill="#759EB0"
          opacity="1.000000"
          stroke="none"
          d="
M538.279053,92.017654
	C538.144958,91.712524 538.310547,91.398239 538.749268,91.038979
	C538.874573,91.332169 538.726685,91.670326 538.279053,92.017654
z"
        />
        <path
          fill="#759EB0"
          opacity="1.000000"
          stroke="none"
          d="
M534.269348,96.990631
	C534.140625,96.693176 534.294800,96.362976 534.737915,96.009064
	C534.868652,96.309532 534.710449,96.633713 534.269348,96.990631
z"
        />
        <path
          fill="#6CDAE1"
          opacity="1.000000"
          stroke="none"
          d="
M527.075806,70.120758
	C526.527283,72.726212 524.183594,71.945244 522.661316,73.089722
	C521.607178,66.199661 521.353149,59.134285 522.324097,52.282326
	C523.974487,40.636261 532.557251,34.869392 542.779968,31.365993
	C549.862000,28.938923 557.122986,29.369785 564.622437,31.886250
	C565.247437,32.949051 564.680420,33.489159 563.592041,34.099869
	C559.634399,35.039108 555.988525,34.448853 552.382690,34.712158
	C542.024841,35.468521 533.757141,39.763031 528.319336,49.390614
	C525.762268,54.618641 526.932007,59.764275 527.020386,64.818367
	C527.048889,66.449829 527.686218,68.069305 527.075806,70.120758
z"
        />
        <path
          fill="#59B1BB"
          opacity="1.000000"
          stroke="none"
          d="
M562.515747,33.706490
	C563.240540,33.082207 563.899902,32.807217 564.787720,32.256889
	C576.668884,35.299400 584.217224,43.673271 590.937622,53.053699
	C591.801086,54.258900 592.538147,55.554653 593.620789,56.997360
	C594.015991,58.795246 595.175537,59.332382 596.729004,59.927711
	C594.705566,60.998924 592.635925,59.684071 590.179443,59.335564
	C588.389343,57.669712 587.240356,55.954586 586.031128,53.851357
	C580.558899,46.203060 574.096497,40.299366 565.559570,36.902126
	C564.190186,36.357182 562.763428,35.792641 562.515747,33.706490
z"
        />
        <path
          fill="#321616"
          opacity="1.000000"
          stroke="none"
          d="
M569.896240,84.300430
	C570.270142,83.487717 570.535339,83.003311 570.920410,82.269760
	C573.836304,82.252296 576.539062,80.946686 578.525024,79.711601
	C584.966003,75.705971 591.622742,75.889542 598.593079,76.980316
	C605.475952,78.057426 612.394897,74.439522 619.277161,77.054749
	C622.360535,78.226379 625.374695,79.595894 628.498657,80.641296
	C633.892395,82.446266 639.650391,81.716888 645.223694,82.654549
	C648.705750,83.240364 651.570862,86.542831 655.777954,84.982346
	C656.475891,85.001534 656.723633,85.169685 656.953735,85.689224
	C657.155334,86.112083 657.286255,86.313560 657.353394,86.868912
	C653.497192,92.572845 647.991150,90.554710 643.033264,90.322655
	C640.806641,90.218437 638.639160,88.916489 635.950928,89.974915
	C635.342590,90.018829 635.097229,90.013733 634.488281,89.944069
	C633.655945,89.737114 633.198730,89.564407 632.427979,89.201736
	C626.567749,86.209465 620.462158,85.183662 614.185059,83.491745
	C605.773071,80.239906 597.601196,82.229248 589.090576,82.877220
	C588.234192,82.967308 587.753235,83.020607 586.935364,83.150421
	C585.513000,83.454483 584.437683,83.733505 583.023376,83.908707
	C582.112549,83.951729 581.513916,83.907303 580.637451,83.992386
	C580.177063,84.080620 579.995056,84.123375 579.546265,84.249390
	C578.736755,84.511978 578.232910,84.772697 577.485229,85.224548
	C576.658447,85.678177 576.051575,85.847733 575.092285,85.983124
	C573.045410,85.757172 570.998169,86.695610 569.896240,84.300430
z"
        />
        <path
          fill="#412C2E"
          opacity="1.000000"
          stroke="none"
          d="
M635.084839,89.409584
	C637.454102,87.492882 639.186218,87.181328 642.191467,88.471451
	C646.646790,90.384117 651.747559,89.422813 656.637634,87.246597
	C657.465332,87.352417 657.719055,88.040817 658.659912,88.215477
	C661.796387,89.662704 663.038818,92.711655 665.610413,94.919266
	C666.056580,95.352104 666.215576,95.634697 666.522888,96.110771
	C667.987122,96.783333 668.719788,97.724075 668.882812,99.495453
	C668.714233,100.872833 668.342163,101.789162 667.521301,102.877502
	C666.416687,103.452003 665.630676,103.217400 665.011230,102.675484
	C660.925232,99.101128 656.867493,100.989761 652.463135,102.717369
	C651.904236,102.720016 651.682434,102.689545 651.152466,102.531113
	C649.843384,101.636681 649.201782,100.379044 647.480225,100.043312
	C643.156555,96.425446 638.051636,94.249565 635.084839,89.409584
z"
        />
        <path
          fill="#11161D"
          opacity="1.000000"
          stroke="none"
          d="
M658.715210,87.955902
	C657.987549,89.129135 657.427612,88.617592 656.956543,87.295265
	C656.921021,86.834480 656.946289,86.650284 656.997986,86.188354
	C657.501709,86.537460 657.979126,87.164307 658.715210,87.955902
z"
        />
        <path
          fill="#342C32"
          opacity="1.000000"
          stroke="none"
          d="
M999.747437,592.295959
	C1005.342041,593.519226 1011.237915,592.677734 1016.140625,596.344116
	C1017.917419,597.672913 1019.767822,595.508789 1021.278931,594.240845
	C1022.081421,593.567444 1022.910034,592.981323 1023.924622,593.723450
	C1024.680664,594.276428 1024.929565,595.152039 1024.357910,595.910767
	C1022.386353,598.528076 1024.183350,598.884705 1026.225342,599.244202
	C1031.091553,600.100769 1034.867676,602.602478 1038.700439,605.831604
	C1044.451416,610.676758 1051.551758,607.984314 1057.111206,605.455688
	C1064.614868,602.042542 1072.289795,599.843201 1080.597168,598.011719
	C1081.750000,597.998535 1082.500000,597.999451 1083.625000,598.001221
	C1084.450073,599.240723 1083.597412,599.952759 1082.595215,600.972290
	C1076.737061,603.153198 1072.052734,606.724792 1066.890869,609.395874
	C1063.365723,611.219849 1061.226196,613.907104 1060.705200,617.886047
	C1060.335083,620.710815 1059.805176,623.588440 1058.071533,625.885071
	C1054.951172,630.018860 1055.648926,634.130371 1058.173706,637.948181
	C1060.726196,641.807739 1061.988647,645.930542 1062.964478,650.396973
	C1064.344360,656.712830 1068.232910,662.260254 1069.138672,668.802307
	C1069.277588,669.804871 1070.212158,670.490784 1071.005127,671.080750
	C1074.000366,673.308899 1074.933960,676.321838 1074.544678,680.293335
	C1074.913574,682.712097 1074.774048,684.650879 1073.325562,686.714844
	C1071.415161,687.115479 1069.533813,686.816406 1068.986938,685.493286
	C1066.680176,679.911377 1058.905762,677.380432 1060.695923,669.321045
	C1061.467896,665.845703 1057.666992,663.669250 1054.728516,662.169128
	C1052.903564,661.237427 1051.110718,660.568665 1050.262329,658.531494
	C1048.108032,653.358582 1046.622437,647.859985 1042.539185,643.648376
	C1041.607422,642.687378 1041.950317,641.112061 1042.236328,639.789429
	C1042.499756,638.571655 1043.602173,637.120789 1041.798218,636.334656
	C1039.936890,635.523560 1038.604858,636.715820 1037.583130,638.120605
	C1036.517822,639.585144 1035.585571,641.146423 1034.297607,642.832764
	C1033.062988,644.333618 1031.357666,644.030640 1030.053589,644.808472
	C1031.123779,646.285767 1033.344116,646.433533 1033.957764,648.667847
	C1032.142456,653.604614 1028.062378,651.447632 1024.436035,652.000000
	C1022.221863,651.999146 1020.444580,651.999451 1018.333984,651.999878
	C1015.958801,652.000000 1013.916931,652.000000 1011.437500,652.000000
	C1010.535828,650.412598 1009.905151,648.852478 1009.639832,647.232483
	C1008.942627,642.975525 1011.400330,640.443237 1015.671509,641.197754
	C1017.801941,641.574036 1020.246277,642.809265 1021.992981,641.862427
	C1026.119507,639.625549 1030.069336,636.925232 1033.644165,633.887146
	C1034.802246,632.902893 1033.725586,630.727234 1031.363403,630.055298
	C1030.295410,629.484741 1029.951660,628.753479 1029.737549,627.517456
	C1029.718262,626.567139 1029.799683,626.036926 1030.100342,625.154663
	C1034.602905,618.232849 1032.216553,611.720032 1025.067871,608.629395
	C1018.089172,605.612366 1011.263428,602.251160 1004.115417,599.604431
	C1001.910645,598.788147 1000.154907,597.176758 999.205200,594.464539
	C999.131165,593.558533 999.251953,593.061279 999.747437,592.295959
z"
        />
        <path
          fill="#0D1920"
          opacity="1.000000"
          stroke="none"
          d="
M923.000122,650.747375
	C921.290833,651.010559 919.581604,651.021179 917.445557,651.045105
	C916.212524,651.778870 915.319336,652.319397 913.820557,652.470154
	C911.651428,653.033142 909.903748,653.639709 907.740906,654.289185
	C892.180664,657.439941 876.833557,659.455994 861.688965,662.668518
	C853.588318,664.386719 845.613647,667.141479 836.749451,666.861816
	C835.258484,666.773743 834.274902,667.063477 832.998901,667.822510
	C831.545898,668.330017 830.442505,668.385376 828.903198,668.169128
	C826.093811,667.203125 823.604126,667.600403 821.153564,667.482849
	C816.672546,667.267761 816.007446,666.352051 816.977234,661.199463
	C818.578857,658.898254 820.713867,658.521973 822.830688,658.040649
	C831.082214,656.164673 839.449829,654.924622 848.194336,653.440674
	C856.413330,651.398132 864.547607,651.137085 872.718872,648.830078
	C883.099670,647.054260 893.114441,645.631104 903.551392,644.262207
	C904.774719,644.319275 905.567322,644.253967 906.693970,643.768066
	C910.365295,642.467651 913.860352,642.342896 917.655884,641.635193
	C920.783813,641.838074 923.378845,640.054626 926.518250,641.264221
	C929.464294,645.644714 925.499512,647.103760 923.282410,649.699585
	C923.000000,650.157898 923.000122,650.326355 923.000122,650.747375
z"
        />
        <path
          fill="#0D1114"
          opacity="1.000000"
          stroke="none"
          d="
M923.415894,649.994324
	C922.145874,646.384399 926.647217,645.744080 926.997986,642.439575
	C927.312622,641.264526 927.900757,640.699219 928.989990,640.062927
	C930.960327,639.346191 932.608032,639.250305 934.685059,639.298462
	C958.726440,634.992798 982.322754,630.509277 1005.992371,626.421631
	C1010.045593,625.721558 1014.195068,625.603638 1018.537109,624.266968
	C1020.841919,623.197083 1022.817566,622.437622 1025.390869,622.433350
	C1026.846680,622.848267 1027.580933,623.642029 1028.568481,624.744751
	C1028.904785,625.338623 1029.010986,625.592590 1029.199097,626.252686
	C1029.754272,630.713318 1026.924805,632.499512 1023.866394,633.190247
	C1020.204468,634.017212 1016.593872,635.345520 1012.380066,635.190674
	C1011.556030,635.025146 1011.111572,635.026367 1010.334045,635.041443
	C1008.067200,636.198486 1005.875916,636.505310 1003.367554,636.987610
	C1001.932129,637.006470 1000.866699,637.021484 999.402222,637.057495
	C998.582581,637.465637 998.138245,637.813538 997.281982,637.970642
	C996.554138,637.997559 996.109680,638.002991 995.332214,638.022949
	C992.991394,640.163940 990.384827,640.085266 987.388245,639.998291
	C986.555664,639.998657 986.110962,639.999512 985.333008,640.013550
	C981.261292,642.948547 976.471558,642.721375 972.258362,643.495178
	C964.822144,644.861145 957.540405,647.531738 949.408936,647.105347
	C948.555603,646.998962 948.111389,647.003418 947.334351,647.022461
	C941.510132,651.369141 935.078857,650.155762 928.419434,650.049072
	C926.605774,649.988892 925.218933,649.994080 923.415894,649.994324
z"
        />
        <path
          fill="#2E282E"
          opacity="1.000000"
          stroke="none"
          d="
M949.091919,647.355225
	C958.784912,644.184082 968.927979,643.157959 978.847046,641.013672
	C980.613708,640.631775 982.402954,640.354126 984.590759,640.027588
	C984.823425,641.598572 983.707458,642.384583 982.376099,642.656006
	C971.980713,644.776001 961.572510,646.834045 951.161072,648.874146
	C950.278381,649.047058 949.572632,648.565308 949.091919,647.355225
z"
        />
        <path
          fill="#2E282E"
          opacity="1.000000"
          stroke="none"
          d="
M928.197937,650.317322
	C932.180054,648.608093 936.499084,648.137695 940.883118,647.955566
	C942.657227,647.881836 944.414978,647.414490 946.590820,647.081665
	C945.390869,651.247681 942.807983,653.679077 937.922546,652.188293
	C934.829041,651.244263 931.235474,653.299500 928.197937,650.317322
z"
        />
        <path
          fill="#2E282E"
          opacity="1.000000"
          stroke="none"
          d="
M1012.082764,635.367676
	C1015.762451,634.005310 1019.516113,632.954529 1023.289246,631.978760
	C1026.019897,631.272705 1028.275024,630.213745 1027.962891,626.406433
	C1028.957642,625.369690 1029.738647,625.813660 1030.733398,626.729248
	C1030.994141,627.727478 1030.997925,628.484192 1031.003418,629.619263
	C1031.308594,634.318848 1027.242554,633.316101 1025.378784,633.815491
	C1021.127808,634.954529 1016.901733,637.630676 1012.082764,635.367676
z"
        />
        <path
          fill="#2E282E"
          opacity="1.000000"
          stroke="none"
          d="
M987.272583,640.255127
	C989.094421,638.159058 992.012939,639.482056 994.614258,638.075195
	C993.484070,641.783203 990.537354,641.216919 987.272583,640.255127
z"
        />
        <path
          fill="#2E282E"
          opacity="1.000000"
          stroke="none"
          d="
M1003.242371,637.239441
	C1004.816772,635.518066 1006.959167,635.068787 1009.615234,635.040039
	C1008.590271,637.903076 1006.029846,637.671448 1003.242371,637.239441
z"
        />
        <path
          fill="#2E282E"
          opacity="1.000000"
          stroke="none"
          d="
M997.176453,638.194702
	C997.472168,637.691711 997.945801,637.391174 998.711304,637.084595
	C998.798767,637.949768 998.718018,638.975525 997.176453,638.194702
z"
        />
        <path
          fill="#3A292B"
          opacity="1.000000"
          stroke="none"
          d="
M1082.187012,600.351562
	C1082.555054,599.488159 1083.110107,598.976318 1083.832520,598.233276
	C1091.723389,598.331482 1099.627441,596.756348 1106.961914,600.801331
	C1114.328735,604.864197 1120.563843,609.752747 1122.101807,618.665100
	C1122.936157,623.499939 1125.614990,627.896545 1125.081787,633.094482
	C1124.761353,636.217834 1124.945312,639.262329 1123.201294,642.171753
	C1121.428223,645.129700 1121.977417,648.634338 1122.009033,651.948425
	C1122.056641,656.918213 1121.201660,661.853088 1117.655396,665.345398
	C1112.175049,670.742310 1111.229126,677.927612 1109.268799,684.631104
	C1108.403442,687.590332 1106.916504,689.034241 1104.505615,689.557800
	C1099.669678,690.608032 1094.736816,691.212158 1089.421997,692.001404
	C1085.956177,694.463196 1082.881226,693.217285 1079.403564,692.041138
	C1078.555542,692.001953 1078.111084,692.001465 1077.333252,692.001709
	C1076.315430,689.998779 1073.435791,689.973450 1073.045166,687.330933
	C1073.000000,684.956665 1073.000000,682.915771 1073.000000,680.437439
	C1076.917358,677.240967 1081.580688,677.825073 1085.945435,677.260010
	C1092.259277,676.442688 1098.475708,675.866394 1102.865845,670.274170
	C1104.956665,667.610901 1106.653931,664.864197 1107.558960,661.209717
	C1107.715698,659.718445 1108.192505,658.766113 1109.025391,657.540894
	C1113.461060,652.540344 1114.088745,646.646729 1115.014160,640.794373
	C1116.191528,633.347778 1115.484741,626.042175 1113.849976,618.780518
	C1113.495483,617.206055 1112.971924,615.567871 1111.079834,614.442749
	C1107.002319,609.818787 1102.408813,606.622864 1096.551514,604.595459
	C1094.732910,602.004089 1092.335449,602.570496 1090.123413,602.494751
	C1087.426880,602.402527 1084.555908,603.032349 1082.187012,600.351562
z"
        />
        <path
          fill="#342A31"
          opacity="1.000000"
          stroke="none"
          d="
M1034.324463,648.956055
	C1032.708618,648.344788 1031.467773,647.298889 1030.114258,647.123352
	C1027.918091,646.838562 1026.604736,646.097961 1027.145630,643.768555
	C1027.716187,641.311707 1029.492188,643.019592 1030.740601,642.953186
	C1031.553345,642.909912 1032.371948,642.980164 1033.593994,642.999756
	C1034.063843,644.236633 1034.726929,645.078369 1035.640869,645.878906
	C1037.555542,647.556091 1036.233032,648.306946 1034.324463,648.956055
z"
        />
        <path
          fill="#342A31"
          opacity="1.000000"
          stroke="none"
          d="
M1018.263672,652.252686
	C1019.701843,650.498108 1021.572205,650.828491 1023.736328,651.747314
	C1022.298157,653.501831 1020.427856,653.171570 1018.263672,652.252686
z"
        />
        <path
          fill="#5B555B"
          opacity="1.000000"
          stroke="none"
          d="
M171.671814,475.013794
	C177.388168,474.349792 182.776474,473.688843 188.582199,473.010895
	C189.402802,472.704895 189.797729,472.272308 190.282913,472.274048
	C200.433777,472.310303 209.951584,468.079010 220.100159,467.923737
	C224.168503,467.861481 228.038895,465.408539 232.733704,466.605774
	C234.355225,466.832977 235.352188,467.452850 236.697876,468.295044
	C237.704742,469.695221 237.388214,470.746521 236.557831,472.094482
	C235.182144,473.271851 233.881577,473.368011 232.181580,473.300964
	C229.862335,472.901245 227.903061,471.679779 225.662308,473.515442
	C225.139496,473.828857 224.918152,473.941833 224.336868,474.176819
	C221.647049,474.755646 219.395020,475.680756 216.532150,475.472717
	C208.980621,475.465454 202.000519,476.957977 194.956161,477.740356
	C186.108490,478.723022 177.224091,479.272552 168.379715,480.327515
	C161.190842,481.184998 153.944336,481.738495 146.275681,482.342499
	C145.544464,482.217010 145.257477,482.137360 144.584747,481.832184
	C143.202820,480.553223 143.226135,479.521545 144.629272,478.261353
	C145.855057,477.076111 146.998520,476.925781 148.147690,477.031036
	C154.854111,477.645355 161.438995,475.990082 168.560822,475.999146
	C169.617462,475.262360 170.430481,475.020233 171.671814,475.013794
z"
        />
        <path
          fill="#5B555B"
          opacity="1.000000"
          stroke="none"
          d="
M105.015724,492.452942
	C104.318588,494.460388 102.737091,495.478760 104.820038,497.875214
	C107.142967,500.547699 104.109131,502.007660 101.348732,502.957092
	C95.341011,502.638123 90.421577,500.279327 86.107353,496.855988
	C81.641266,493.312103 84.312485,486.165375 91.025330,483.916107
	C96.855988,481.962402 102.899239,480.881195 109.547699,481.013733
	C110.354485,481.446838 110.513786,481.941681 110.320618,482.871887
	C106.543770,485.557312 101.895477,483.643127 97.982239,486.311340
	C97.206383,486.730377 96.734245,486.891174 95.884750,487.056000
	C92.877014,486.936493 90.733353,487.943848 90.600784,490.691925
	C90.488930,493.010651 92.795914,493.663086 94.669930,494.347687
	C97.637581,495.431824 99.367737,494.256042 100.430992,491.101440
	C101.061058,490.525330 101.511917,490.343964 102.376923,490.260559
	C103.801903,490.507477 104.588638,491.078156 105.015724,492.452942
z"
        />
        <path
          fill="#4B555C"
          opacity="1.000000"
          stroke="none"
          d="
M234.522278,468.245483
	C224.901352,468.676117 215.666779,469.385742 206.725784,472.202179
	C201.230438,473.933228 195.529892,472.639771 189.467407,472.997864
	C189.187195,472.152344 189.492004,471.236298 190.444168,471.191620
	C196.805817,470.893005 203.355179,469.136444 208.492828,466.485596
	C214.683090,463.291534 220.981689,466.944000 226.743454,463.372131
	C230.825821,461.658264 233.327026,463.904083 235.662231,466.334412
	C236.480682,467.186188 236.331039,468.168854 234.522278,468.245483
z"
        />
        <path
          fill="#77878D"
          opacity="1.000000"
          stroke="none"
          d="
M234.371124,468.286102
	C235.306915,467.902100 235.619247,467.464386 234.595459,466.654297
	C232.538330,465.026581 230.117050,464.137573 227.341217,463.199585
	C224.592865,462.408661 222.181244,461.707947 219.394836,460.967926
	C218.733078,460.796997 218.446106,460.665405 218.159119,460.533813
	C218.399994,460.386292 218.635101,460.124695 218.882797,460.112183
	C219.704422,460.070618 220.530579,460.118683 221.692749,460.134613
	C224.613754,458.587433 227.260391,459.330139 229.720032,460.255310
	C233.601044,461.715179 237.524551,463.074188 241.668121,464.933380
	C242.162689,465.383636 242.336639,465.590027 242.677597,466.166748
	C242.977539,467.068115 243.010925,467.605347 242.835815,468.538910
	C242.485779,469.416962 242.157501,469.836517 241.397095,470.387817
	C239.664322,471.019684 238.471222,470.681396 237.217926,469.358215
	C236.184418,468.866089 235.382370,468.686554 234.371124,468.286102
z"
        />
        <path
          fill="#2E515F"
          opacity="1.000000"
          stroke="none"
          d="
M209.695770,461.585693
	C209.682358,464.337067 207.501923,464.613495 205.915741,464.722321
	C201.199280,465.045959 196.427582,465.651459 191.357147,464.157227
	C190.608444,463.469421 190.490952,462.897675 190.867065,461.891998
	C191.608307,461.124939 192.157425,460.863068 193.222870,460.658142
	C198.909683,460.829590 204.194534,459.709991 209.695770,461.585693
z"
        />
        <path
          fill="#557E8B"
          opacity="1.000000"
          stroke="none"
          d="
M210.342804,461.908386
	C204.605209,461.929138 199.186752,461.938782 193.303009,461.830688
	C192.858063,461.421875 192.878372,461.130859 193.211411,460.533264
	C199.094116,459.124390 204.504013,456.633362 210.787354,458.451599
	C211.983231,458.974731 213.089371,459.120789 213.506393,459.734894
	C214.052399,460.538788 212.926773,460.927032 212.161102,461.612335
	C211.511414,461.848480 211.086685,461.872894 210.342804,461.908386
z"
        />
        <path
          fill="#4B555C"
          opacity="1.000000"
          stroke="none"
          d="
M110.302689,483.254120
	C110.003494,482.534729 110.000877,482.100677 109.996521,481.341370
	C115.803658,478.020233 121.745468,478.161560 127.873001,480.650391
	C127.632072,481.456573 127.220551,481.791962 126.363434,482.173096
	C125.724503,482.343628 125.463333,482.385193 124.808075,482.445740
	C120.856857,482.837341 117.141235,481.584625 113.359512,483.653107
	C112.159775,483.980774 111.363556,483.906128 110.302689,483.254120
z"
        />
        <path
          fill="#4B555C"
          opacity="1.000000"
          stroke="none"
          d="
M144.537582,478.007507
	C144.681900,478.812347 144.370453,479.613556 144.026520,480.737000
	C143.413132,481.840851 142.591583,482.183838 141.213837,482.381165
	C138.666092,482.378265 136.506424,482.813690 134.275314,481.256653
	C133.890320,478.518555 135.279388,477.924469 137.357864,477.990540
	C139.597076,478.061737 141.840332,478.004913 144.537582,478.007507
z"
        />
        <path
          fill="#79675E"
          opacity="1.000000"
          stroke="none"
          d="
M133.625122,480.935730
	C136.142929,480.984589 138.298004,481.065186 140.904739,481.251007
	C137.834076,484.403076 130.078568,485.194763 125.556717,482.540100
	C125.937714,481.847290 126.500008,481.565002 127.518982,481.160309
	C129.737900,481.014435 131.500153,480.990967 133.625122,480.935730
z"
        />
        <path
          fill="#4B555C"
          opacity="1.000000"
          stroke="none"
          d="
M171.789276,474.776794
	C171.425003,475.810913 170.586670,475.988831 169.343231,475.995544
	C169.260681,474.440948 169.955811,473.672424 171.789276,474.776794
z"
        />
        <path
          fill="#557E8B"
          opacity="1.000000"
          stroke="none"
          d="
M190.812561,461.947083
	C191.083374,462.546539 191.049850,462.958862 190.994873,463.680420
	C190.973419,463.989655 190.508911,463.981293 190.277527,463.981689
	C190.013428,463.567810 189.980698,463.153534 189.918945,462.428711
	C190.096024,461.998749 190.302109,461.879364 190.812561,461.947083
z"
        />
        <path
          fill="#3E505A"
          opacity="1.000000"
          stroke="none"
          d="
M242.250153,466.183777
	C235.218613,465.267822 229.566116,460.832916 222.371582,460.307312
	C221.074585,456.973053 216.832993,457.391510 215.712875,454.305267
	C215.349930,453.305237 213.858582,452.653900 214.626068,451.302887
	C215.418228,449.908508 216.807907,449.891541 218.150162,450.245453
	C219.754028,450.668304 221.289352,451.405304 222.909988,451.694244
	C224.351669,451.951263 225.619492,450.719147 224.352997,449.759369
	C219.581131,446.143188 225.440231,445.648987 225.929825,443.287689
	C227.313385,453.104614 234.283646,453.571350 241.892593,454.593048
	C248.755920,455.514648 255.931427,455.854187 262.799072,458.949615
	C266.254608,460.155426 264.713440,462.530487 264.434937,464.830078
	C259.123413,462.705261 254.608475,465.551208 249.572403,466.877808
	C248.779434,467.039520 248.319473,467.073486 247.514587,467.042603
	C245.678329,466.540771 244.112564,466.479736 242.250153,466.183777
z"
        />
        <path
          fill="#285362"
          opacity="1.000000"
          stroke="none"
          d="
M257.232941,454.202637
	C256.333282,453.736572 255.768585,453.492523 255.029449,452.985474
	C252.693314,450.319305 252.484100,447.369598 252.528793,444.356232
	C252.656235,435.764130 251.381699,427.259521 251.025269,418.314972
	C253.682068,414.725983 254.671997,417.265656 255.365662,419.285828
	C256.423981,422.368073 257.101746,425.580505 258.471802,428.892212
	C261.002136,426.910553 259.307251,422.386108 263.513855,421.691956
	C265.875946,423.897369 264.823364,426.491302 264.956573,428.856201
	C265.149994,432.290253 264.802612,435.753479 265.594086,439.574890
	C265.816345,440.521759 265.907928,441.065643 265.929077,442.028809
	C265.880676,442.976013 265.779236,443.503754 265.548126,444.411255
	C263.524841,448.066528 266.654663,452.136414 263.435516,455.361511
	C261.208557,455.195496 259.475098,454.351196 257.232941,454.202637
z"
        />
        <path
          fill="#67BFC6"
          opacity="1.000000"
          stroke="none"
          d="
M252.466156,416.661224
	C253.131332,428.634521 253.985519,440.276611 254.847351,452.320557
	C253.510208,452.810913 252.165451,452.899323 250.412231,452.997070
	C246.929138,453.171143 244.146500,452.293945 241.290359,450.406555
	C237.529434,448.148193 232.451553,448.661682 230.795822,443.349731
	C230.978958,442.841492 231.200912,442.702454 231.810516,442.512573
	C236.715500,443.530365 240.846130,445.684509 245.239746,447.104858
	C250.086105,448.671600 250.565552,448.529755 250.213135,443.559570
	C249.594330,434.832581 248.923691,426.121429 249.471863,416.988617
	C249.617447,416.098633 249.748901,415.601013 250.139053,414.785950
	C250.537842,414.284302 250.733414,414.114655 251.303833,413.849915
	C251.900253,413.884674 252.121872,414.014496 252.525360,414.482635
	C252.825897,415.328674 252.808807,415.831696 252.466156,416.661224
z"
        />
        <path
          fill="#7DA7B8"
          opacity="1.000000"
          stroke="none"
          d="
M264.724731,443.078247
	C264.872650,442.476898 264.936066,442.105255 265.080780,441.462494
	C266.489990,440.572235 266.342834,441.662598 266.553436,442.675049
	C266.753815,444.449585 268.722168,445.476349 266.359406,447.547974
	C264.968811,448.767273 265.197601,452.699951 267.560486,455.207825
	C269.102722,455.515472 269.503143,456.270233 269.339905,457.687378
	C267.428070,459.814087 267.301849,461.897644 268.242249,464.519958
	C268.472748,466.261292 268.357483,467.586060 267.327209,469.113556
	C266.200104,469.864502 265.342468,469.906860 264.075195,469.387787
	C262.899017,468.045013 262.866852,466.891235 263.711487,465.316833
	C263.828461,463.118317 263.684723,461.297455 263.359009,459.231628
	C263.185486,458.237427 263.193909,457.488251 263.132446,456.370850
	C262.867554,455.835785 262.672485,455.668976 262.790405,455.277924
	C264.810883,451.543213 263.551117,447.967590 263.607727,444.161804
	C264.186401,443.776917 264.440338,443.576660 264.724731,443.078247
z"
        />
        <path
          fill="#3E6978"
          opacity="1.000000"
          stroke="none"
          d="
M211.029755,458.300659
	C205.324768,458.838745 199.811340,461.588165 193.393097,461.018372
	C192.344238,460.364075 192.246567,459.584900 192.749130,458.394836
	C198.943863,457.069916 204.895309,453.867126 211.029755,458.300659
z"
        />
        <path
          fill="#5E9BAB"
          opacity="1.000000"
          stroke="none"
          d="
M232.167816,442.763245
	C231.666687,443.069092 231.484833,443.055786 231.029999,443.011505
	C228.303391,434.777588 228.651382,426.247345 227.702774,417.408386
	C227.675720,415.235992 227.767471,413.509949 227.979401,411.367249
	C228.399429,410.904663 228.699280,410.858704 229.341431,411.113220
	C232.204437,421.613892 233.271332,431.945343 232.167816,442.763245
z"
        />
        <path
          fill="#85C1D1"
          opacity="1.000000"
          stroke="none"
          d="
M207.447998,420.250916
	C207.905899,419.986206 208.094559,419.996185 208.566498,420.011169
	C209.117355,422.094147 209.384949,424.172150 209.716721,426.641449
	C209.558334,428.320221 209.266785,429.600189 209.124313,430.896484
	C208.176880,439.516479 204.444000,442.703094 195.497528,442.001984
	C195.079254,441.582550 195.080383,441.286285 195.092316,440.545715
	C205.115585,436.643585 205.730484,435.810303 206.310501,424.856415
	C206.389297,423.368225 206.404205,421.872070 207.447998,420.250916
z"
        />
        <path
          fill="#759EB0"
          opacity="1.000000"
          stroke="none"
          d="
M192.555344,458.047119
	C192.963699,458.800415 192.943817,459.548737 192.934723,460.673218
	C192.945496,461.049408 192.889740,461.383209 192.863739,461.548035
	C192.453644,461.800201 192.069550,461.887512 191.401184,462.054504
	C190.912857,462.114929 190.708786,462.095642 190.197327,462.097260
	C188.347626,461.288452 186.805359,460.458710 184.865417,459.415039
	C187.360657,457.261627 189.890076,458.415436 192.555344,458.047119
z"
        />
        <path
          fill="#285362"
          opacity="1.000000"
          stroke="none"
          d="
M241.067841,450.516693
	C243.794037,450.989594 246.587357,451.811676 249.692230,452.820068
	C246.483536,454.629578 243.589020,453.660187 241.067841,450.516693
z"
        />
        <path
          fill="#7DA7B8"
          opacity="1.000000"
          stroke="none"
          d="
M216.612091,443.151154
	C218.084885,443.759064 218.949890,444.479706 218.097717,445.838318
	C217.575882,446.670258 216.680099,446.596191 215.920609,446.207245
	C214.956345,445.713440 213.503052,445.394531 213.817902,443.940674
	C214.086273,442.701385 215.355713,443.096405 216.612091,443.151154
z"
        />
        <path
          fill="#3C7D8E"
          opacity="1.000000"
          stroke="none"
          d="
M262.742676,454.888977
	C263.103424,455.053711 263.094482,455.528473 263.078552,455.765564
	C261.016205,456.108704 259.233093,455.222656 257.152100,454.241028
	C258.752655,454.031555 260.653198,453.744537 262.742676,454.888977
z"
        />
        <path
          fill="#285362"
          opacity="1.000000"
          stroke="none"
          d="
M207.501495,419.703308
	C207.741516,423.636444 208.083115,427.328278 207.722183,430.950073
	C207.027573,437.920044 202.793411,441.140900 195.481613,440.274536
	C192.333206,438.128510 189.238266,436.545410 187.147919,433.282104
	C186.380951,427.842133 182.118561,424.173126 181.559006,418.879486
	C181.220291,415.675110 180.554703,412.724213 184.191605,411.420135
	C187.675354,410.170929 188.560852,413.436768 190.280212,415.769043
	C193.176102,420.327820 193.176102,420.327820 198.159866,418.463715
	C196.230392,414.859497 192.798431,412.367096 191.023529,408.336182
	C190.689224,402.098450 195.573563,403.521576 199.064178,402.684967
	C199.638016,402.939636 199.835861,403.112274 200.020340,403.560913
	C200.422638,404.508759 200.675995,405.323578 201.745880,405.857971
	C205.052322,409.934418 205.863800,414.800568 207.501495,419.703308
z"
        />
        <path
          fill="#214A59"
          opacity="1.000000"
          stroke="none"
          d="
M199.278458,402.534424
	C197.526917,403.463287 196.685867,406.508362 193.694702,404.673187
	C192.636261,404.023804 192.126740,406.257019 191.949097,407.906433
	C191.479919,408.738800 190.916168,408.884583 190.023285,408.787231
	C189.423248,408.492706 189.031769,408.580475 189.096970,408.846161
	C189.168167,409.136322 189.743210,409.071899 190.132294,409.692383
	C191.294601,412.108154 191.829681,414.262756 190.305359,416.746643
	C188.317093,416.893005 188.104630,415.481781 187.586411,414.293213
	C186.958633,412.853394 186.106186,411.492889 184.268066,412.559662
	C182.798569,413.412567 181.553146,414.657257 182.455551,416.586395
	C183.931168,419.740967 183.070999,423.771332 186.839691,426.171082
	C188.768143,427.399048 186.407349,430.143982 187.003159,432.584717
	C182.269318,427.375366 179.790482,420.810303 178.572205,413.321045
	C177.808273,409.692932 178.357773,406.432861 179.035538,403.210968
	C180.911453,394.293427 187.997482,392.450562 196.690063,398.827057
	C197.724319,400.075134 198.457092,401.146545 199.278458,402.534424
z"
        />
        <path
          fill="#AFEAF5"
          opacity="1.000000"
          stroke="none"
          d="
M301.232422,400.365356
	C292.137543,397.388580 283.279083,394.762634 274.124268,391.968506
	C273.724548,391.489777 273.621155,391.179169 273.755798,390.439087
	C272.347595,387.937195 271.654968,385.833160 272.664734,383.092987
	C273.266815,381.459076 273.052734,379.470184 272.978119,377.650177
	C272.554596,367.319305 274.189362,357.129395 274.998779,346.445374
	C278.693909,341.714142 279.804535,336.503967 280.636230,331.085571
	C280.967072,328.929962 283.159851,328.043335 285.294617,328.059906
	C288.479675,328.084595 289.642975,326.820648 288.413544,323.766449
	C287.090088,320.478760 288.945190,318.827850 291.772308,317.262085
	C296.201874,314.624908 298.628510,319.195923 302.480835,319.677826
	C303.055115,319.872345 303.274902,319.974396 303.790680,320.283173
	C305.799469,322.598572 306.771210,325.397888 309.965973,326.515808
	C310.751862,326.696136 311.187469,326.842407 311.912292,327.195923
	C313.185547,327.998108 313.860138,328.918213 314.769135,330.074677
	C315.615326,331.543793 316.939697,332.115448 317.845520,333.529968
	C318.392731,334.655670 318.682068,335.523987 319.192810,336.680115
	C321.993408,342.337036 324.071991,347.911316 326.219543,353.833862
	C326.654724,355.570312 327.674103,356.594452 328.827850,357.064514
	C334.834320,359.511627 339.549713,363.873535 345.078796,367.613983
	C346.502533,368.141510 347.449005,368.796112 348.529175,369.857025
	C349.279114,370.854462 349.688416,371.671722 350.508209,372.618561
	C351.443390,374.828766 352.850220,376.345367 354.089966,378.382385
	C354.545746,379.916382 355.390472,380.832733 356.157043,382.234558
	C358.867126,390.374878 361.361969,398.163757 357.426575,406.661255
	C356.337555,408.450500 354.933746,409.438416 353.683990,411.108948
	C348.956787,413.659973 344.248718,413.184418 339.786255,412.131317
	C328.039062,409.359283 316.475525,405.857178 304.818817,402.719299
	C303.530548,402.372467 302.331787,401.807556 301.232422,400.365356
z"
        />
        <path
          fill="#BAEEFA"
          opacity="1.000000"
          stroke="none"
          d="
M273.627258,389.998047
	C273.977295,390.308380 273.960754,390.607147 273.886078,391.353149
	C270.830109,391.177094 267.832275,390.553833 264.421539,389.876526
	C265.467773,387.244415 267.293335,386.633087 269.719940,388.664978
	C270.719055,389.501587 271.919708,389.976685 273.627258,389.998047
z"
        />
        <path
          fill="#1F0F0F"
          opacity="1.000000"
          stroke="none"
          d="
M891.027832,313.424500
	C889.019348,314.163727 887.378357,314.621063 885.371094,315.020813
	C881.855164,309.444824 884.653992,304.009277 885.619995,298.701904
	C886.157349,295.749603 887.045349,293.034027 886.250977,290.010803
	C885.142517,285.792480 886.618042,282.536560 890.673584,280.091675
	C893.541016,284.541840 891.413574,289.437500 892.046997,294.624329
	C894.633484,301.217560 891.398804,307.117035 891.027832,313.424500
z"
        />
        <path
          fill="#0F141A"
          opacity="1.000000"
          stroke="none"
          d="
M891.007141,279.796997
	C887.279724,284.135803 887.988953,288.707794 888.589539,293.955536
	C889.089355,298.322998 885.547241,302.734192 885.468872,307.662872
	C885.435608,309.754913 885.048096,311.928406 885.004150,314.522522
	C884.669617,315.225311 884.334351,315.487396 883.692139,315.850891
	C882.112000,315.662689 881.223572,314.867767 881.267822,313.574280
	C881.632935,302.901703 881.818359,292.213562 883.524109,281.635345
	C883.775452,280.076569 884.310730,278.746277 886.592163,278.871948
	C888.039978,279.190430 889.085693,279.250305 890.528809,279.317627
	C890.926086,279.325073 890.950867,279.646149 891.007141,279.796997
z"
        />
        <path
          fill="#1F0F0F"
          opacity="1.000000"
          stroke="none"
          d="
M895.604797,297.153503
	C896.281616,299.491943 898.040283,299.711609 899.854004,300.195404
	C903.093567,301.059540 905.992737,302.524933 906.852173,306.637573
	C902.504089,307.530518 898.246033,306.588135 893.707275,304.948242
	C891.021912,302.777466 893.089539,300.182068 892.920288,297.515381
	C893.922668,297.148682 894.597595,297.118317 895.604797,297.153503
z"
        />
        <path
          fill="#916E67"
          opacity="1.000000"
          stroke="none"
          d="
M893.141296,296.889069
	C893.240723,299.473938 893.233765,301.768799 893.275146,304.492493
	C893.258789,307.516022 893.194153,310.110687 893.209106,313.121246
	C892.707886,313.650543 892.127075,313.763977 891.103271,313.791870
	C891.119202,307.791260 891.578003,301.876099 892.068298,295.526978
	C892.411438,295.594971 892.723145,296.097015 893.141296,296.889069
z"
        />
        <path
          fill="#5B7D8C"
          opacity="1.000000"
          stroke="none"
          d="
M1069.302124,434.105103
	C1063.766357,432.765228 1058.541626,431.443329 1053.038086,429.896362
	C1051.927246,427.138702 1053.867798,426.008270 1055.390381,424.852509
	C1063.584229,418.632172 1072.422363,413.894379 1082.995850,413.362976
	C1086.554810,413.184082 1089.797363,412.203705 1092.813110,410.177704
	C1099.012207,406.012970 1101.253540,406.522278 1105.656982,412.962738
	C1108.709351,417.650513 1106.983887,422.031128 1105.797974,426.787384
	C1105.561523,427.407227 1105.455078,427.650330 1105.160767,428.243225
	C1104.004517,430.170807 1102.884277,431.643555 1101.552856,433.469299
	C1094.509399,439.979675 1086.672729,439.633331 1078.611694,437.300842
	C1076.599731,436.718750 1074.242432,437.180603 1072.288086,435.148621
	C1071.200928,434.601410 1070.406982,434.362213 1069.302124,434.105103
z"
        />
        <path
          fill="#151E25"
          opacity="1.000000"
          stroke="none"
          d="
M1072.002930,435.217163
	C1074.896973,435.341064 1077.828613,435.712250 1080.695435,436.367432
	C1087.934814,438.021881 1094.719727,437.590088 1100.883301,432.273621
	C1102.416138,431.184753 1103.614624,430.277039 1103.787842,428.250854
	C1103.970825,427.982910 1103.995361,427.978577 1103.985840,427.969055
	C1106.353638,427.384674 1108.632690,426.700012 1110.627808,425.103729
	C1112.359985,423.717834 1114.432983,423.217957 1116.784912,424.732971
	C1116.949463,431.793884 1116.409058,432.587433 1109.822510,432.934692
	C1105.005249,433.188629 1102.291626,437.396820 1097.793823,438.569672
	C1093.977417,439.564850 1090.616089,440.757965 1086.415161,440.616058
	C1082.560547,440.485870 1079.055054,438.331696 1075.006592,439.364960
	C1072.992554,439.878967 1072.151611,437.575043 1072.002930,435.217163
z"
        />
        <path
          fill="#1C2D35"
          opacity="1.000000"
          stroke="none"
          d="
M1117.296753,424.834198
	C1114.209229,424.718689 1111.876465,425.351837 1109.815308,427.531464
	C1108.490234,428.932587 1106.702393,431.071655 1104.230103,428.158478
	C1104.701538,423.564850 1106.994629,419.311340 1105.028564,414.315643
	C1104.428467,412.647156 1103.947388,411.354309 1104.373291,409.563965
	C1104.779053,409.026367 1105.000488,408.860718 1105.605225,408.853699
	C1105.669312,411.117584 1107.306641,412.509583 1107.961670,414.630188
	C1108.846069,416.089691 1108.471191,417.293152 1108.329224,418.390045
	C1108.649292,417.549255 1108.433228,416.564880 1108.778564,415.307373
	C1109.004883,415.003662 1109.000000,415.000000 1109.002441,415.002441
	C1110.077759,414.760986 1110.478149,415.369049 1110.631836,416.289520
	C1111.165039,415.818146 1109.317017,418.058289 1111.193481,416.703613
	C1114.865234,414.052704 1118.277832,410.586823 1123.688843,410.828522
	C1124.723755,416.889496 1120.180420,420.316956 1117.296753,424.834198
z"
        />
        <path
          fill="#2D3A41"
          opacity="1.000000"
          stroke="none"
          d="
M1124.068359,410.627258
	C1120.138062,413.039093 1116.134399,414.891693 1112.405762,417.190552
	C1109.745728,418.830597 1110.135010,416.492737 1109.232178,415.214661
	C1110.094116,409.348907 1115.822876,409.687164 1119.318848,407.160400
	C1120.541504,406.276703 1122.437134,406.324493 1124.397705,405.911133
	C1125.216431,407.436340 1124.488647,408.821259 1124.068359,410.627258
z"
        />
        <path
          fill="#3C545F"
          opacity="1.000000"
          stroke="none"
          d="
M1038.772461,447.278320
	C1043.189697,446.004639 1046.838013,448.470093 1050.793579,448.982361
	C1052.578491,449.213501 1054.331909,449.687653 1056.499756,450.054596
	C1057.479248,450.531494 1058.058472,451.005310 1058.798096,451.708008
	C1052.289062,451.807190 1046.028442,449.652008 1039.271484,447.945740
	C1038.777222,447.713531 1038.669800,447.575012 1038.772461,447.278320
z"
        />
        <path
          fill="#212226"
          opacity="1.000000"
          stroke="none"
          d="
M1003.969116,419.110352
	C1003.969421,423.164246 1007.571533,422.265472 1009.236389,422.958954
	C1014.239990,425.043182 1018.716187,429.119293 1024.768677,428.039886
	C1024.926758,428.011688 1025.112427,428.027985 1025.262939,428.083954
	C1027.354126,428.862091 1030.838867,429.035614 1030.428467,431.662201
	C1030.031372,434.203339 1026.601685,433.232269 1024.778442,432.810150
	C1019.165161,431.510559 1013.641968,429.784790 1008.144897,428.032623
	C1004.480408,426.864532 1000.724915,426.402039 996.945190,426.024017
	C995.298645,425.859375 993.583557,426.134338 992.360901,424.614624
	C992.060852,424.241699 991.634888,423.970184 991.266785,423.652039
	C987.130981,429.288330 979.523438,420.571045 975.519043,427.434601
	C975.115601,428.126129 973.029907,427.929352 971.718323,427.982330
	C964.924072,428.256561 958.136230,428.244568 951.326416,428.713226
	C944.394653,429.190247 937.288208,427.354095 930.385132,426.226318
	C926.119873,425.529480 922.789673,425.568329 919.895752,428.177734
	C916.403137,431.326935 912.924683,432.336761 908.609680,430.383270
	C907.612915,429.931976 906.645020,430.142059 906.074707,431.657867
	C904.451172,433.674347 902.656555,432.822662 900.928528,432.481750
	C897.073547,431.721222 893.323364,430.444733 888.964600,429.972473
	C887.174500,429.044769 885.812744,427.917053 886.049011,426.363678
	C887.078552,419.593994 885.866394,412.742218 886.967285,405.995453
	C887.072571,405.350372 887.458496,404.764069 887.898438,403.752319
	C889.179565,402.054260 890.542725,402.013214 892.411865,402.563721
	C893.049927,402.743958 893.295776,402.830994 893.870850,403.111023
	C897.218079,407.284241 900.747070,405.235504 904.518005,404.033875
	C905.496216,403.731659 906.166443,403.637878 907.165955,403.561249
	C908.886353,403.419525 910.413513,403.938416 911.777954,402.466614
	C912.511047,401.507660 912.837952,400.666199 913.644348,399.711548
	C914.110291,399.329468 914.320679,399.202515 914.882141,398.946594
	C915.981812,398.654144 916.741089,398.638672 917.889771,398.676147
	C920.088440,398.872894 921.810852,399.534149 924.018127,399.743042
	C935.455444,402.679474 946.698792,404.720917 958.052368,407.860382
	C961.009460,410.786652 956.504822,410.733978 956.605225,412.609650
	C959.527161,414.046753 960.314331,412.222687 961.055786,409.652069
	C964.882751,408.006683 968.096313,409.382477 971.618713,410.841064
	C972.789124,411.578156 973.660522,412.151581 975.080078,412.121155
	C976.027405,412.165314 976.634888,412.324554 977.510864,412.695312
	C979.044434,413.645386 980.382141,414.144287 982.231445,414.015106
	C983.007507,414.073151 983.442627,414.176971 984.176025,414.480469
	C989.122437,418.719940 994.424194,419.814240 1000.691162,418.079498
	C1002.060852,417.916840 1003.009277,418.070007 1003.969116,419.110352
z"
        />
        <path
          fill="#63879A"
          opacity="1.000000"
          stroke="none"
          d="
M1104.499268,408.688446
	C1104.611084,410.384735 1104.809692,411.752777 1104.947021,413.532471
	C1100.006226,407.538727 1099.993286,407.520020 1092.874268,412.462952
	C1088.369019,415.591034 1082.983154,415.139557 1077.999634,415.784454
	C1070.239502,416.788635 1064.325806,421.278015 1057.911987,424.878479
	C1056.109497,425.890289 1054.680298,427.566803 1052.919556,429.304871
	C1045.951904,428.793152 1039.298584,427.177246 1032.336548,425.280273
	C1030.935181,423.926147 1030.508789,422.576599 1030.638794,420.985840
	C1031.190063,414.241608 1037.060303,407.939606 1043.614014,405.798889
	C1056.976318,401.434204 1070.877808,400.829651 1084.644287,399.259766
	C1087.397461,398.945831 1089.839355,398.380646 1092.124634,396.073181
	C1093.928833,395.053894 1095.238403,395.243164 1096.691895,396.745972
	C1096.997070,397.050903 1097.004272,396.995148 1096.976807,397.000916
	C1097.883911,397.624908 1097.948364,398.798309 1098.829590,399.807800
	C1099.057861,400.019379 1098.980713,400.017426 1098.979370,399.978851
	C1099.215088,400.217834 1099.473755,400.473541 1099.981689,400.859497
	C1100.464478,401.550415 1100.722168,402.086060 1100.897705,402.863831
	C1101.001099,403.074036 1101.186646,403.042114 1101.654297,402.998047
	C1103.465820,404.458771 1104.230591,406.308594 1104.499268,408.688446
z"
        />
        <path
          fill="#688FA1"
          opacity="1.000000"
          stroke="none"
          d="
M1093.596191,395.323425
	C1092.317383,399.652283 1089.412964,400.480011 1085.349609,401.202057
	C1073.712402,403.270050 1061.353760,400.790985 1050.326416,406.661499
	C1049.645386,407.024109 1048.705078,406.920776 1047.881592,406.991608
	C1037.303467,407.901855 1032.207642,413.435638 1032.007568,424.665009
	C1028.887085,424.485779 1025.764771,423.884674 1022.307495,423.085083
	C1020.082886,421.854614 1018.068359,421.212677 1015.931946,420.949585
	C1014.479675,420.770752 1013.435852,420.147278 1013.347290,418.125977
	C1015.881165,411.584076 1021.148376,407.029938 1023.051147,400.486847
	C1023.697449,398.264465 1026.046753,397.109192 1028.342651,396.307800
	C1032.440308,394.877533 1036.656128,393.819702 1040.948608,393.640076
	C1054.330322,393.080078 1067.431885,390.367859 1081.025757,388.618958
	C1085.581909,387.059814 1088.505981,389.580109 1091.785645,392.022278
	C1092.471924,392.754944 1092.807861,393.316742 1093.240845,394.174500
	C1093.426025,394.642639 1093.483398,394.835052 1093.596191,395.323425
z"
        />
        <path
          fill="#8396A6"
          opacity="1.000000"
          stroke="none"
          d="
M1013.875183,417.652222
	C1015.314514,421.756744 1020.138794,419.037170 1021.883545,422.545593
	C1016.303528,421.777405 1010.634460,420.668243 1004.551270,419.500793
	C1003.337280,419.223602 1002.537292,419.004700 1001.421082,418.785522
	C995.319946,419.062988 989.673584,418.647614 984.278748,415.198883
	C983.591858,414.801483 983.202820,414.687988 982.509033,414.598572
	C980.539490,414.976074 978.948059,414.566071 977.212402,413.770325
	C976.565308,413.214447 976.062256,413.045166 975.288940,412.910645
	C974.200806,413.659363 973.302551,413.523376 972.252747,412.746368
	C968.596863,411.423859 965.074280,410.549774 961.160034,409.509644
	C960.315918,409.540924 959.851929,409.583954 959.108032,409.203979
	C947.405212,406.527710 935.968872,404.094940 924.234985,401.438873
	C922.067505,398.713226 918.593689,398.275696 916.892700,395.329407
	C916.986206,394.542694 916.988586,394.099548 916.992065,393.324097
	C919.813660,390.602783 922.518127,392.237823 924.800293,393.679626
	C929.352417,396.555634 934.224304,397.718597 939.501099,397.693756
	C940.835022,397.687500 942.442322,397.540375 943.370789,398.409485
	C947.349731,402.133881 951.880310,400.522766 956.132019,399.702850
	C964.239197,398.139343 971.639343,400.338837 978.906982,403.336182
	C981.651184,404.467957 984.195007,405.309753 987.396362,403.843445
	C991.557373,403.281708 995.159180,400.639587 999.500916,402.933228
	C1003.990845,408.327332 1008.247437,413.440643 1013.875183,417.652222
z"
        />
        <path
          fill="#212226"
          opacity="1.000000"
          stroke="none"
          d="
M953.792114,442.774963
	C955.043518,438.742584 958.041016,437.160431 960.482300,439.398987
	C963.054626,441.757721 965.706909,441.059387 968.374023,440.622101
	C970.177673,440.326385 972.427307,439.244202 973.669922,440.254028
	C978.521423,444.196716 984.489014,443.503082 989.938416,444.925751
	C992.379944,445.563141 995.939819,446.633667 995.539490,449.547760
	C995.093140,452.796783 991.821411,453.555786 988.535889,453.052765
	C987.278931,452.860352 985.930847,453.262817 984.321045,453.628601
	C983.186523,454.817871 982.221252,454.578094 980.920166,453.990601
	C975.686951,448.246399 968.688721,448.797852 961.970459,447.464661
	C961.009155,447.118317 960.409485,446.804352 959.529175,446.314575
	C959.053711,446.045197 958.869507,445.930054 958.422974,445.628662
	C957.257751,444.427307 955.900513,444.426239 954.494385,443.754822
	C954.081421,443.423676 953.942566,443.260101 953.792114,442.774963
z"
        />
        <path
          fill="#48505A"
          opacity="1.000000"
          stroke="none"
          d="
M953.423828,442.437073
	C953.780457,442.453369 953.805664,442.692566 953.849182,442.804688
	C955.238342,443.974274 957.241638,443.181396 958.721069,444.807800
	C958.964172,445.069275 958.986267,445.001434 958.957153,445.022034
	C959.348999,445.465454 959.515198,445.971680 959.228882,446.930908
	C957.134033,448.812408 955.110596,447.808899 953.286865,447.333069
	C933.653259,442.210571 913.815979,437.909058 894.216553,432.651215
	C892.439148,432.174408 890.624756,431.877014 889.165649,429.693451
	C894.678833,428.644104 899.796143,431.063660 905.596802,431.988342
	C920.385315,435.614014 934.767944,439.258850 949.179749,442.784332
	C950.374451,443.076599 951.766785,442.561005 953.423828,442.437073
z"
        />
        <path
          fill="#3C545F"
          opacity="1.000000"
          stroke="none"
          d="
M980.640076,452.920166
	C981.785461,453.380432 982.585449,453.633362 983.701538,453.874634
	C984.017700,453.862976 984.462830,453.972260 984.687683,454.016724
	C985.544373,455.880585 982.649658,455.984436 983.307922,458.242096
	C985.927612,457.485229 988.400085,458.965729 991.100830,459.240784
	C992.365540,459.369568 993.534058,459.396118 992.812378,457.221741
	C999.177795,458.027039 1005.448730,459.151276 1011.870056,460.632812
	C1011.212341,461.114929 1010.404297,461.239716 1009.371704,461.567993
	C1002.181946,459.689209 995.288086,462.919250 988.330933,462.055664
	C986.520142,461.830902 984.676331,461.873016 982.376099,461.743988
	C980.091736,462.558258 978.483154,462.101257 976.735535,460.725220
	C976.013184,460.083923 975.595886,459.588959 974.780029,459.045105
	C974.319519,458.726044 974.134888,458.592255 973.682739,458.232483
	C972.468872,457.085663 972.039490,456.002625 972.547302,454.333496
	C974.951782,451.809662 977.650940,452.425751 980.640076,452.920166
z"
        />
        <path
          fill="#243A45"
          opacity="1.000000"
          stroke="none"
          d="
M992.641418,456.687164
	C993.521973,458.381836 995.432922,457.503754 996.638855,459.347168
	C989.891174,462.716705 984.658875,459.869080 979.369934,456.282379
	C980.705078,454.069366 982.979614,455.655334 984.665161,454.321533
	C987.558289,454.375671 990.260254,454.516022 992.641418,456.687164
z"
        />
        <path
          fill="#617179"
          opacity="1.000000"
          stroke="none"
          d="
M1039.515503,458.753815
	C1037.073364,456.738831 1039.094116,456.320404 1040.312622,455.778442
	C1041.310547,455.334564 1042.409790,455.669617 1042.610962,456.780060
	C1043.024170,459.060638 1041.325439,458.890900 1039.515503,458.753815
z"
        />
        <path
          fill="#688FA1"
          opacity="1.000000"
          stroke="none"
          d="
M1038.709961,446.979797
	C1038.982422,447.120178 1038.931152,447.485992 1038.907837,447.669006
	C1038.604126,447.939667 1038.335693,448.055420 1037.911865,448.341125
	C1037.712280,449.874908 1036.800415,449.431885 1035.824951,448.943817
	C1035.110962,447.812958 1034.119751,447.004791 1035.162842,446.099670
	C1036.455688,444.977905 1037.547119,445.684326 1038.709961,446.979797
z"
        />
        <path
          fill="#1A2026"
          opacity="1.000000"
          stroke="none"
          d="
M1035.325684,448.788818
	C1036.143555,448.561829 1036.675293,448.536957 1037.475708,448.497528
	C1037.873535,449.515594 1039.678101,450.892700 1037.565552,451.408051
	C1036.838745,451.585388 1033.906250,451.830933 1035.325684,448.788818
z"
        />
        <path
          fill="#212226"
          opacity="1.000000"
          stroke="none"
          d="
M948.351868,434.026184
	C947.524292,435.059967 946.658142,435.160431 945.824585,434.401062
	C945.686646,434.275330 945.698059,433.718048 945.841736,433.594666
	C946.690308,432.866333 947.554626,432.952515 948.351868,434.026184
z"
        />
        <path
          fill="#D0C3BE"
          opacity="1.000000"
          stroke="none"
          d="
M1112.691162,379.839233
	C1113.031494,379.892883 1113.099487,380.314026 1113.133179,380.524658
	C1111.917358,384.855133 1107.916626,386.063416 1104.907715,388.177765
	C1102.823853,389.642120 1100.401978,390.712463 1098.115356,392.560394
	C1097.580444,393.192413 1097.191406,393.451630 1096.403564,393.706787
	C1095.817871,393.766113 1095.581055,393.756653 1094.999512,393.660889
	C1094.437500,393.482056 1094.219971,393.389557 1093.414795,392.908875
	C1092.631104,392.389099 1092.435303,392.257507 1091.929565,391.951782
	C1090.081665,390.020660 1086.297241,389.601807 1088.411255,385.697571
	C1090.898804,385.443268 1093.130981,385.441864 1094.613037,383.478455
	C1096.024170,381.608795 1100.101807,380.776215 1098.724731,378.122345
	C1097.632446,376.017151 1094.140015,377.638977 1091.715698,377.841797
	C1090.891357,377.910736 1090.138184,378.349854 1089.026855,378.790100
	C1083.833252,378.282471 1079.489746,381.387024 1074.301270,380.628326
	C1073.782593,380.229919 1073.610474,380.025330 1073.296143,379.442200
	C1073.067627,378.523987 1073.090942,377.988953 1073.386963,377.089661
	C1076.394897,373.818634 1080.037354,372.831757 1083.911987,372.475830
	C1086.404175,372.246948 1088.792847,371.680847 1091.452637,370.533997
	C1092.355469,370.287933 1092.878296,370.325714 1093.733032,370.704590
	C1101.197510,371.745728 1107.165527,374.960022 1112.691162,379.839233
z"
        />
        <path
          fill="#847876"
          opacity="1.000000"
          stroke="none"
          d="
M1092.317627,371.011658
	C1090.328491,374.012817 1087.087646,373.404480 1084.344482,373.931213
	C1081.083496,374.557373 1077.966431,375.304932 1074.825195,377.229492
	C1071.259888,374.315460 1072.429443,369.951324 1071.246094,365.915405
	C1074.849609,361.492157 1080.475830,362.399323 1084.683472,359.842346
	C1086.090454,358.987335 1087.518433,358.379944 1088.760742,356.739380
	C1089.636353,356.380310 1090.163086,356.403259 1090.951416,356.897552
	C1091.715820,358.257477 1092.211060,359.315704 1092.720215,360.666290
	C1091.846680,364.096069 1089.229614,365.934387 1086.894531,368.143158
	C1088.394531,370.111389 1090.904297,369.170532 1092.317627,371.011658
z"
        />
        <path
          fill="#A8A6A9"
          opacity="1.000000"
          stroke="none"
          d="
M1091.177490,356.830078
	C1090.729614,357.130280 1090.238647,357.061249 1089.376343,356.993683
	C1084.455444,361.042297 1078.708130,362.087830 1072.718506,363.476257
	C1072.158447,363.637695 1071.931885,363.684692 1071.368530,363.737183
	C1070.819702,363.696747 1070.607788,363.650848 1070.116821,363.456238
	C1069.405762,362.900818 1069.087891,362.393707 1068.192627,361.659546
	C1067.567871,361.214569 1067.417969,361.086700 1067.070435,360.733093
	C1066.158447,359.495117 1065.610596,358.393921 1065.074219,356.633362
	C1064.941772,355.262634 1064.870117,354.512665 1064.876221,353.392822
	C1070.127075,355.664093 1074.660767,353.358215 1079.265625,351.253174
	C1081.952759,350.024780 1084.672729,348.621948 1088.133301,349.892883
	C1089.658691,350.611206 1089.995972,351.828979 1090.607544,353.224976
	C1090.804443,354.542816 1090.969482,355.501862 1091.177490,356.830078
z"
        />
        <path
          fill="#532C24"
          opacity="1.000000"
          stroke="none"
          d="
M1090.744141,353.178833
	C1090.026245,352.824585 1089.413208,352.065369 1088.559937,351.130798
	C1085.386353,348.771545 1082.482178,346.564331 1081.811279,342.330383
	C1081.863525,340.688385 1081.785889,339.387634 1081.627930,337.707581
	C1088.487549,336.753113 1088.698242,336.760376 1088.872803,343.389099
	C1088.960205,346.710693 1093.227905,349.037628 1090.744141,353.178833
z"
        />
        <path
          fill="#73676A"
          opacity="1.000000"
          stroke="none"
          d="
M1092.713135,371.336548
	C1091.113892,371.226715 1089.795776,371.175781 1088.498779,370.983032
	C1087.010742,370.761841 1084.824707,371.814667 1084.347412,369.516541
	C1083.964233,367.671326 1085.675903,366.465332 1087.024780,365.705933
	C1089.106934,364.533661 1090.364014,362.586151 1092.428101,361.049438
	C1093.179688,363.991455 1093.625488,367.024231 1094.073242,370.482361
	C1093.717896,371.049377 1093.360840,371.191040 1092.713135,371.336548
z"
        />
        <path
          fill="#F0F0EC"
          opacity="1.000000"
          stroke="none"
          d="
M1089.382202,385.708496
	C1087.116943,389.422028 1091.192627,388.986694 1092.189819,390.829712
	C1092.864990,391.103363 1093.240234,391.297150 1093.791992,391.808105
	C1094.011108,392.042542 1094.007080,391.986786 1093.979980,391.993225
	C1094.204468,392.211517 1094.443115,392.437073 1094.831299,392.861603
	C1094.993652,393.046844 1094.996704,392.996277 1094.976562,393.011597
	C1095.453247,393.388977 1096.006836,393.663635 1096.682617,394.595032
	C1096.914795,395.576660 1096.963623,395.995239 1097.004883,396.732361
	C1095.599487,397.833069 1095.162476,396.257416 1093.936279,395.769653
	C1093.651611,395.619415 1093.413818,395.638092 1093.353516,395.741028
	C1092.591187,395.626556 1092.526978,394.996002 1092.197510,394.175964
	C1089.604736,391.169556 1086.322388,389.903381 1082.259521,388.805908
	C1081.258057,388.905396 1081.000488,388.899261 1080.365479,388.804993
	C1078.041260,387.908783 1076.087891,387.124115 1074.088135,386.445038
	C1071.475952,385.558014 1070.378906,383.915741 1072.062744,380.922455
	C1072.736694,380.293701 1073.209839,380.088470 1074.129639,380.028168
	C1079.141479,379.718811 1083.164551,375.745483 1088.557617,377.810211
	C1091.267822,380.102448 1091.074097,382.664917 1089.382202,385.708496
z"
        />
        <path
          fill="#C1ACB2"
          opacity="1.000000"
          stroke="none"
          d="
M1096.777832,393.186432
	C1097.211182,392.088837 1097.162720,390.931732 1097.653931,390.617188
	C1102.622192,387.435242 1107.678833,384.391052 1112.938721,381.021729
	C1114.115601,381.937897 1115.064331,383.140472 1116.173584,384.639771
	C1110.284424,388.660217 1103.337402,390.676941 1097.189453,394.993835
	C1096.865845,395.158051 1096.888184,395.091217 1096.912720,395.066040
	C1096.613281,394.582916 1096.483032,394.075317 1096.777832,393.186432
z"
        />
        <path
          fill="#F0F0EC"
          opacity="1.000000"
          stroke="none"
          d="
M1099.008301,399.693115
	C1097.540405,399.977356 1097.081177,398.843353 1096.798340,397.344971
	C1096.949341,397.006653 1097.446777,396.963318 1097.689453,397.022339
	C1098.313232,397.081146 1098.560425,397.262878 1098.809570,397.849243
	C1098.945801,398.071320 1098.858276,398.001862 1098.810303,398.031006
	C1098.827881,398.495728 1098.893311,398.931305 1099.008301,399.693115
z"
        />
        <path
          fill="#5D696E"
          opacity="1.000000"
          stroke="none"
          d="
M1098.998291,397.817810
	C1098.588379,398.025879 1098.320312,397.846680 1098.036865,397.307526
	C1098.771362,396.161743 1098.960571,396.748260 1098.998291,397.817810
z"
        />
        <path
          fill="#2D3A41"
          opacity="1.000000"
          stroke="none"
          d="
M1104.806396,409.020142
	C1103.646118,407.226959 1102.879639,405.437286 1102.024780,403.316772
	C1104.031616,403.025024 1104.278809,404.822479 1105.057617,406.516632
	C1105.342896,407.389343 1105.552124,407.931915 1105.874878,408.743408
	C1105.725830,409.016083 1105.463135,409.019867 1104.806396,409.020142
z"
        />
        <path
          fill="#F0F0EC"
          opacity="1.000000"
          stroke="none"
          d="
M1100.065063,400.769409
	C1099.822266,400.873535 1099.437988,400.732269 1099.015869,400.265686
	C1099.293213,400.134888 1099.608276,400.329468 1100.065063,400.769409
z"
        />
        <path
          fill="#11161D"
          opacity="1.000000"
          stroke="none"
          d="
M1057.607422,474.079346
	C1056.050659,477.354401 1054.525269,476.154388 1053.056274,473.375488
	C1054.419678,473.352264 1055.835083,473.678650 1057.607422,474.079346
z"
        />
        <path
          fill="#11161D"
          opacity="1.000000"
          stroke="none"
          d="
M1070.793457,477.658630
	C1069.775391,477.958771 1068.476807,478.202332 1067.056274,477.112274
	C1068.031372,477.021179 1069.289062,477.198975 1070.793457,477.658630
z"
        />
        <path
          fill="#11161D"
          opacity="1.000000"
          stroke="none"
          d="
M1062.890503,475.728546
	C1062.861572,476.124603 1062.580688,476.601624 1062.116699,475.708679
	C1062.253174,475.536377 1062.435791,475.589386 1062.890503,475.728546
z"
        />
        <path
          fill="#2D3A41"
          opacity="1.000000"
          stroke="none"
          d="
M1108.757324,414.998169
	C1108.774658,415.940216 1109.553223,417.093231 1108.398071,417.831299
	C1108.264404,417.100922 1108.130859,416.370544 1108.005981,415.310760
	C1108.014648,414.981384 1108.509766,414.992676 1108.757324,414.998169
z"
        />
        <path
          fill="#63879A"
          opacity="1.000000"
          stroke="none"
          d="
M278.021912,309.975708
	C277.370209,310.468292 276.935211,310.692078 276.264008,310.999542
	C275.223633,311.757446 274.332977,312.214813 272.884033,312.149048
	C270.083893,311.956543 270.908508,308.427124 268.627075,308.260529
	C265.488220,308.031433 262.784088,308.645660 262.227661,312.819824
	C257.909393,307.641418 256.257294,300.912933 252.517517,294.889099
	C252.451553,294.293762 252.493805,294.033234 252.908325,293.644470
	C261.515045,296.879211 269.886536,298.773468 278.721497,295.966644
	C279.516296,295.714142 280.364746,295.860504 281.495544,296.276062
	C283.567780,298.592316 281.892853,299.772278 280.444672,300.844299
	C278.579926,302.224701 277.471405,303.068848 280.123596,305.145111
	C283.784912,308.011414 279.882172,308.571533 278.021912,309.975708
z"
        />
        <path
          fill="#AFEAF5"
          opacity="1.000000"
          stroke="none"
          d="
M271.877441,311.213867
	C273.157715,311.028229 274.203003,311.005310 275.638031,311.032837
	C276.649963,313.731567 278.818542,312.868652 280.490448,312.981323
	C282.624146,313.125122 286.383179,313.816071 285.993805,314.822632
	C284.941650,317.542419 284.624908,321.326965 281.602051,322.640411
	C279.642639,323.491760 277.017212,323.119873 274.742523,322.851990
	C272.294434,322.563751 271.200165,321.144318 271.467987,318.342407
	C271.633636,316.609467 269.933105,317.050446 268.966888,317.805023
	C267.850830,318.676544 267.108490,320.593719 265.352234,319.675964
	C263.213928,318.558533 265.490570,316.718658 265.015228,315.232880
	C264.871429,314.783478 264.999939,314.246948 265.004150,313.345459
	C265.359222,311.672394 266.332214,311.213501 267.832458,311.008850
	C268.981476,311.021912 269.750153,311.301331 270.810913,311.553772
	C271.279236,311.491882 271.460876,311.434204 271.877441,311.213867
z"
        />
        <path
          fill="#8AB7C6"
          opacity="1.000000"
          stroke="none"
          d="
M286.428833,311.939209
	C284.697632,311.675476 283.135437,312.258545 282.067993,310.470276
	C282.195282,307.039368 284.790588,307.443542 286.714111,307.027313
	C292.240936,305.831421 297.898743,307.040833 303.861816,306.097534
	C305.338257,305.599518 306.431427,305.601654 307.908386,305.842285
	C309.784485,306.426880 311.106079,307.808228 313.254761,306.790924
	C315.069977,306.459259 316.525848,306.448517 318.307068,307.001007
	C320.016754,307.669220 320.335846,309.533508 322.297058,309.896423
	C323.687073,310.724243 324.966187,311.172058 325.105438,312.998230
	C323.982452,314.411652 322.653107,314.739014 320.955078,315.241211
	C316.383362,315.774017 312.243195,316.568695 307.613342,316.276306
	C300.779877,313.021179 293.693542,313.035278 286.428833,311.939209
z"
        />
        <path
          fill="#457483"
          opacity="1.000000"
          stroke="none"
          d="
M305.209351,306.903748
	C298.452850,308.872253 291.549438,307.209106 284.949585,309.034515
	C284.160004,309.252869 283.348938,309.393402 282.263977,309.853699
	C280.869781,310.201294 279.759369,310.265259 278.227081,310.286835
	C277.813629,308.495087 279.728851,308.260162 280.405548,307.109131
	C280.147369,305.928650 279.176178,306.151642 278.461426,305.911682
	C277.257935,305.507690 275.635376,305.392059 275.518890,303.807770
	C275.399750,302.187317 276.678070,301.273315 278.008423,300.499451
	C279.375000,299.704590 281.159668,299.445404 281.894928,297.363068
	C282.529694,296.276459 283.333588,296.024109 284.665741,296.046356
	C286.254883,296.467224 287.040710,297.279144 287.883301,298.623322
	C288.470612,301.689758 289.993103,303.049194 292.756805,303.196442
	C295.415680,303.338104 298.041595,303.850708 300.676636,304.231323
	C302.457306,304.488525 304.403839,304.528046 305.209351,306.903748
z"
        />
        <path
          fill="#A8A6A9"
          opacity="1.000000"
          stroke="none"
          d="
M245.024139,284.332703
	C245.428345,284.000671 245.882996,283.991791 246.678467,284.005310
	C252.952637,284.204987 258.792328,283.716461 264.594727,282.285522
	C267.588623,281.547180 270.240723,280.568146 272.551605,277.932129
	C275.872589,277.789062 277.192047,279.606018 277.621033,282.689728
	C275.527100,286.559052 272.120819,287.669037 268.500488,288.612976
	C263.689789,289.867340 258.726501,290.448029 253.631302,292.162689
	C251.678574,292.543396 250.987900,291.324768 249.789581,290.182220
	C247.826904,288.353149 246.067719,286.816650 245.024139,284.332703
z"
        />
        <path
          fill="#B3D5DF"
          opacity="1.000000"
          stroke="none"
          d="
M286.200775,312.166870
	C293.156403,310.475037 300.053070,310.911957 306.796021,314.747681
	C307.243958,317.120605 305.954376,318.695587 304.584045,320.759094
	C304.015503,321.078369 303.569122,321.022308 303.345398,320.998901
	C298.659851,318.665314 294.727203,315.259644 289.313965,314.148590
	C288.174164,313.392029 287.274689,312.908173 286.200775,312.166870
z"
        />
        <path
          fill="#67BFC6"
          opacity="1.000000"
          stroke="none"
          d="
M269.400970,312.252319
	C267.904449,312.256165 266.770447,312.450134 265.320526,312.792389
	C264.255859,312.992523 263.507141,313.044403 262.382141,313.091309
	C262.717499,311.243286 259.750488,308.769714 262.003784,307.769135
	C264.592438,306.619598 267.815033,306.388428 270.710602,306.572357
	C274.090759,306.787048 271.014679,309.333191 271.891541,310.853882
	C272.112427,311.051117 272.026154,311.436584 272.000244,311.632385
	C271.406311,312.640900 270.564667,312.468750 269.400970,312.252319
z"
        />
        <path
          fill="#CFECEF"
          opacity="1.000000"
          stroke="none"
          d="
M289.103943,314.208008
	C295.018127,313.143768 298.839020,316.856415 302.930054,320.690063
	C299.044922,321.320648 296.524719,317.497375 292.362854,317.042542
	C291.326019,315.844025 289.405914,316.155212 289.103943,314.208008
z"
        />
        <path
          fill="#63879A"
          opacity="1.000000"
          stroke="none"
          d="
M249.592896,290.332062
	C250.425552,290.560028 251.412964,291.109894 252.701294,291.824402
	C253.141388,292.133209 253.280579,292.277374 253.491913,292.713318
	C253.522263,293.201050 253.480453,293.396973 253.218292,293.797180
	C252.997910,294.001465 252.724808,294.295074 252.567062,294.424683
	C250.880219,293.692169 250.534225,292.022736 249.592896,290.332062
z"
        />
        <path
          fill="#A8A6A9"
          opacity="1.000000"
          stroke="none"
          d="
M242.248260,281.176727
	C243.064423,280.235443 243.376190,281.074829 243.909363,281.833313
	C243.464294,281.846954 242.921738,281.646759 242.248260,281.176727
z"
        />
        <path
          fill="#F0F0EC"
          opacity="1.000000"
          stroke="none"
          d="
M238.088165,278.269592
	C238.053192,277.943024 238.521820,277.984467 238.756622,277.998169
	C239.268417,278.483032 239.545395,278.954163 239.963348,279.658508
	C239.159973,279.894073 238.376205,279.650940 238.088165,278.269592
z"
        />
        <path
          fill="#A8A6A9"
          opacity="1.000000"
          stroke="none"
          d="
M243.937103,282.297852
	C244.297272,282.461792 244.640549,282.936768 244.988190,283.717651
	C244.635132,283.552002 244.277679,283.080444 243.937103,282.297852
z"
        />
        <path
          fill="#282025"
          opacity="1.000000"
          stroke="none"
          d="
M986.261414,561.711426
	C982.117615,558.076172 977.388611,558.642639 972.306580,559.735596
	C971.174744,560.829895 970.219116,560.860535 968.881714,560.255554
	C967.283508,557.874329 965.357361,556.700134 962.487610,556.389343
	C955.418640,554.428223 949.415100,550.513428 942.303955,549.679260
	C940.211487,549.433899 938.298889,548.019043 937.484192,545.340210
	C938.020325,542.970032 939.659302,542.758728 941.356384,542.498901
	C946.752319,541.673035 952.104492,540.584900 957.481201,539.637146
	C952.842041,540.007935 948.374207,541.396423 943.779297,542.043884
	C941.621460,542.347961 939.508728,542.793884 937.116211,541.572266
	C935.026550,538.331421 936.951233,536.359558 938.869873,534.523560
	C941.551086,531.957825 944.911011,530.348328 948.449585,529.688965
	C953.865234,528.679749 957.952271,526.456543 959.786804,520.542236
	C962.518250,519.083496 965.105896,519.681580 968.042480,519.557922
	C969.014648,519.602478 969.560669,519.675903 970.479126,519.931641
	C973.260559,521.802551 975.399536,523.867065 977.839600,525.560364
	C979.654419,526.819702 981.090698,528.528503 981.297119,530.770203
	C981.798584,536.216064 983.707214,540.981750 987.440918,545.010559
	C988.001099,545.614990 988.227661,546.447510 988.142212,547.648254
	C988.322388,552.566895 987.426514,556.995178 986.261414,561.711426
z"
        />
        <path
          fill="#1B1E22"
          opacity="1.000000"
          stroke="none"
          d="
M988.463013,548.003418
	C983.337952,543.141968 979.568848,537.942383 979.817932,530.661865
	C979.895996,528.380676 977.801147,527.018921 975.776001,526.063660
	C973.781067,525.122620 972.583374,523.318298 971.075500,521.442749
	C973.301758,519.237427 975.600830,520.742737 977.857361,521.196350
	C979.775940,521.581970 981.713928,521.409729 984.000183,521.776245
	C988.127441,524.608154 991.427124,523.810669 994.736084,520.386230
	C996.001404,519.762878 997.009033,519.644287 998.420532,519.617493
	C1005.330566,518.103088 1010.823242,522.237488 1017.289734,523.123291
	C1017.814514,523.518005 1017.992249,523.716248 1018.156433,524.313721
	C1019.595398,532.574402 1018.754883,534.528992 1013.037537,536.421875
	C1012.272217,536.675232 1011.171326,537.129944 1010.678223,536.804993
	C1004.977844,533.048279 1004.802795,538.443787 1003.041138,541.643921
	C1001.627625,542.061829 999.744934,542.202026 1000.199951,543.939941
	C1001.526489,549.005859 998.059204,547.932495 995.422180,547.990479
	C993.257690,548.038086 991.091431,548.001343 988.463013,548.003418
z"
        />
        <path
          fill="#15313E"
          opacity="1.000000"
          stroke="none"
          d="
M1068.031982,541.421143
	C1056.468018,543.804382 1044.897827,546.061279 1033.245850,547.756653
	C1025.477783,548.887085 1019.015015,544.056396 1011.829163,542.066162
	C1015.792786,539.972412 1019.979736,540.113464 1024.557129,540.033325
	C1024.509277,539.674133 1024.034424,539.308044 1023.262329,538.836060
	C1023.247192,536.904297 1026.044067,536.773621 1025.874268,534.319214
	C1028.578735,532.589661 1030.079224,535.775513 1032.702881,535.786865
	C1033.028076,536.000000 1032.993042,536.012085 1032.989746,535.993774
	C1033.519409,536.365479 1034.104126,536.869324 1034.724731,536.843689
	C1043.772095,536.471558 1052.088745,541.437927 1061.583496,540.265625
	C1064.243164,538.695984 1066.469482,537.691956 1068.031982,541.421143
z"
        />
        <path
          fill="#43626E"
          opacity="1.000000"
          stroke="none"
          d="
M999.141968,520.624634
	C997.832520,520.867126 996.808899,520.921265 995.405640,521.037231
	C992.426880,522.515564 989.848938,524.014832 986.329163,523.080688
	C985.766663,522.788513 985.562439,522.642761 985.079102,522.254333
	C985.776062,518.050537 990.314514,519.908997 992.378723,517.460449
	C990.397156,514.707214 986.146118,515.749146 984.515991,512.226318
	C984.513794,511.617401 984.571167,511.372253 984.876343,510.832886
	C988.142822,509.096649 990.782776,510.947327 993.952637,511.626526
	C1004.443848,514.355591 1013.965637,519.258606 1025.065674,519.542419
	C1025.968872,519.580566 1026.480103,519.630310 1027.354370,519.804504
	C1029.087036,520.393127 1030.122070,521.660706 1032.081299,521.679810
	C1035.307861,523.078003 1038.771484,523.088074 1041.232056,526.001099
	C1041.402832,526.588074 1041.412842,526.837341 1041.228760,527.406372
	C1035.404785,526.385254 1029.727539,525.209656 1024.168457,523.621094
	C1021.981018,522.995972 1020.382874,523.224609 1018.477783,524.696899
	C1018.142822,524.712891 1018.111389,524.362427 1018.075806,524.190186
	C1013.159363,522.630432 1008.481506,520.558472 1003.449341,519.682556
	C1002.020569,519.433899 1000.506409,518.790222 999.141968,520.624634
z"
        />
        <path
          fill="#496A76"
          opacity="1.000000"
          stroke="none"
          d="
M1041.403076,527.857605
	C1041.034668,527.726257 1041.035767,527.238525 1041.035156,526.994690
	C1043.236816,524.787415 1045.727661,525.501343 1048.113525,526.128479
	C1053.409058,527.520386 1058.659424,529.081848 1064.350708,530.573730
	C1067.535889,531.573059 1070.696899,531.651062 1072.871216,534.610962
	C1072.991089,535.215576 1072.981812,535.434937 1072.944824,535.984192
	C1062.276245,534.508240 1052.219727,530.516357 1041.403076,527.857605
z"
        />
        <path
          fill="#43626E"
          opacity="1.000000"
          stroke="none"
          d="
M1061.516479,540.061218
	C1060.378540,541.317078 1058.468384,541.066162 1056.716919,541.017761
	C1048.708130,540.796326 1041.179932,537.879578 1033.162842,536.325317
	C1042.535400,536.050110 1051.577026,539.631226 1061.516479,540.061218
z"
        />
        <path
          fill="#43626E"
          opacity="1.000000"
          stroke="none"
          d="
M1032.843506,535.707581
	C1030.548218,536.631287 1028.775391,534.891357 1026.347168,534.092163
	C1028.845825,531.348633 1030.467163,534.794434 1032.843506,535.707581
z"
        />
        <path
          fill="#15313E"
          opacity="1.000000"
          stroke="none"
          d="
M1018.864380,538.636475
	C1018.973816,538.734192 1018.496399,538.835388 1018.253418,538.816528
	C1018.201233,538.511597 1018.449402,538.425293 1018.864380,538.636475
z"
        />
        <path
          fill="#272228"
          opacity="1.000000"
          stroke="none"
          d="
M1028.161987,521.198730
	C1027.421997,521.200378 1027.027588,521.175476 1026.338867,521.113037
	C1022.119751,518.227478 1017.029968,520.156738 1012.988892,517.912720
	C1011.269287,516.957703 1008.512268,517.866211 1007.939941,514.701233
	C1008.215454,514.119324 1008.366943,513.908386 1008.826477,513.461365
	C1012.806396,512.398254 1016.531128,511.924408 1020.650391,511.554443
	C1021.795776,511.513733 1022.541809,511.632996 1023.629944,511.977386
	C1025.628418,513.373169 1027.551270,513.657593 1029.921387,513.765381
	C1030.536865,513.935669 1030.778198,514.025513 1031.359375,514.302673
	C1036.273926,517.800232 1041.532959,516.943909 1046.601074,516.726868
	C1049.756836,516.591614 1052.903564,516.222900 1056.073486,516.362610
	C1058.312500,516.461243 1060.518066,516.941223 1061.907837,518.765869
	C1067.349609,525.910522 1074.704590,525.991211 1082.896240,524.679932
	C1084.932007,525.085388 1086.587036,525.408936 1088.597412,525.768921
	C1095.545898,528.793823 1102.762817,529.828796 1109.773071,532.600098
	C1109.975098,533.997742 1109.599609,535.006348 1108.289551,535.842590
	C1102.844116,535.283447 1098.248535,531.174194 1092.294189,533.718567
	C1091.516724,534.562561 1090.885254,534.816895 1089.805542,534.826660
	C1088.543335,534.618896 1087.850342,533.965759 1086.668823,533.594482
	C1085.653442,533.482361 1084.983887,533.339783 1083.968750,533.126221
	C1082.497681,532.577332 1081.318115,532.426331 1079.729370,532.470764
	C1074.342651,532.377136 1069.870605,529.731934 1064.513672,529.401733
	C1059.058105,528.377686 1054.968506,524.395996 1049.126709,524.543701
	C1046.002197,523.735596 1043.232544,523.231262 1039.995239,523.489746
	C1037.645386,523.521362 1035.671753,523.555176 1033.453003,522.707214
	C1031.761719,521.516541 1030.109741,521.426941 1028.161987,521.198730
z"
        />
        <path
          fill="#0D1920"
          opacity="1.000000"
          stroke="none"
          d="
M1088.816162,533.780762
	C1089.764038,533.978821 1090.506836,533.982666 1091.620605,533.983459
	C1101.944824,536.393127 1109.405151,544.882385 1120.228760,545.406189
	C1122.650024,545.523376 1124.967407,547.480469 1126.919922,549.711914
	C1125.240234,549.734131 1123.494995,549.407166 1121.370239,549.045105
	C1106.537354,544.696594 1092.084717,540.380615 1077.629272,536.074280
	C1076.356079,535.694946 1075.064331,535.377991 1073.390747,535.014160
	C1070.465454,534.231018 1067.930664,533.465759 1065.080566,532.519775
	C1064.211060,531.181946 1063.273682,530.144836 1063.815918,528.359131
	C1069.443115,527.075500 1074.013794,530.597595 1079.598511,530.984619
	C1080.891968,532.133484 1082.166504,531.980835 1083.697144,532.003662
	C1084.750000,532.007751 1085.495117,532.009949 1086.612793,532.013916
	C1087.524658,532.542053 1088.067139,533.064880 1088.816162,533.780762
z"
        />
        <path
          fill="#632B1D"
          opacity="1.000000"
          stroke="none"
          d="
M1128.005859,539.657776
	C1128.222046,538.764038 1128.495850,538.235840 1128.917725,537.452271
	C1129.757568,537.172058 1130.449341,537.147217 1131.488403,537.118530
	C1134.577148,538.763794 1137.595093,537.742554 1140.479858,537.992126
	C1145.134399,538.394714 1149.597290,539.143738 1152.676514,543.802063
	C1152.930542,544.114502 1152.937012,544.553833 1152.953857,544.772705
	C1153.740234,549.986267 1150.601562,550.880798 1146.695435,550.980713
	C1145.179321,551.019470 1143.689697,550.908875 1142.336792,550.144897
	C1136.173218,546.664124 1130.084106,543.028748 1122.815063,542.221313
	C1122.215210,542.154724 1121.722778,541.121399 1121.122070,540.166748
	C1126.368652,538.981567 1130.856812,541.903137 1136.089722,543.104675
	C1138.364990,543.646790 1140.323242,543.950317 1141.784058,544.353943
	C1140.679321,544.125366 1139.155640,543.628601 1137.215332,543.403809
	C1134.008301,542.037109 1130.670410,542.001038 1128.005859,539.657776
z"
        />
        <path
          fill="#321616"
          opacity="1.000000"
          stroke="none"
          d="
M1152.954102,543.709656
	C1147.234985,540.420837 1141.141113,538.213745 1134.211670,538.993530
	C1133.358887,539.089539 1132.615845,538.627014 1131.990967,537.481445
	C1137.782715,537.146301 1143.759766,536.248779 1149.575073,538.631226
	C1151.935913,539.598389 1153.250854,540.712646 1152.954102,543.709656
z"
        />
        <path
          fill="#3D292C"
          opacity="1.000000"
          stroke="none"
          d="
M960.334473,456.847351
	C956.268982,456.397858 955.484436,458.990356 955.079163,461.744171
	C954.164551,467.957550 953.378967,474.189880 952.279175,480.719788
	C951.741516,481.102661 951.465637,481.180237 950.858337,480.984161
	C949.494873,474.818970 949.943542,468.988678 951.333618,462.809479
	C951.635132,460.175018 951.683167,458.317566 948.722107,457.485840
	C934.477966,453.484619 920.146545,450.010803 905.366455,448.632538
	C901.812317,448.301117 898.401611,448.591400 894.702393,449.740051
	C892.079712,454.498291 888.437134,454.926361 883.709778,452.837982
	C874.074524,447.518066 864.647827,444.392853 853.539001,446.455688
	C845.476685,447.952759 836.995850,446.553558 828.716614,447.651550
	C824.920715,448.154968 821.174316,448.519287 817.536438,449.835052
	C814.043701,451.098297 810.429932,451.603790 806.832092,450.089722
	C804.999512,449.318573 803.321960,449.416138 801.688477,450.642334
	C800.058289,451.866089 798.119446,452.365112 795.761597,452.853180
	C792.119995,453.307587 789.178406,451.818207 785.679138,451.287567
	C783.176880,450.485687 781.077393,449.684418 778.562378,448.981995
	C778.033691,448.681824 777.837830,448.539093 777.401428,448.129395
	C776.293762,445.880402 775.227417,444.158264 772.280823,444.358093
	C770.854614,444.037354 769.972168,443.513489 769.237427,442.172058
	C768.892578,440.210388 769.313599,438.970703 771.336548,438.305603
	C778.797729,440.421387 785.919006,437.997559 793.411255,438.555176
	C807.356140,439.295197 820.536438,436.328125 833.824036,434.720612
	C848.074341,432.996643 862.236816,430.507019 876.676025,431.070343
	C878.831482,431.154449 880.998047,431.032562 883.500549,431.645874
	C884.792053,432.140839 885.505920,432.740204 885.839478,433.691925
	C886.378845,435.231171 886.497070,433.611237 887.113159,433.355042
	C890.929688,431.648712 894.306458,432.598755 897.533020,434.005035
	C902.006836,435.954956 906.580750,437.148712 911.487976,437.357086
	C915.673401,437.534790 919.424011,439.690918 923.412903,440.652649
	C935.592163,443.589233 947.713318,446.930573 960.298035,447.855469
	C962.778564,448.037781 964.788269,449.055969 965.061646,452.253784
	C963.939575,453.976837 962.699585,454.423584 961.129883,453.623383
	C961.110596,453.741241 961.091370,453.859070 961.072083,453.976929
	C961.719910,453.819611 960.943115,454.851959 961.464722,454.658875
	C961.531921,455.689728 961.208862,456.258118 960.334473,456.847351
z"
        />
        <path
          fill="#3E2423"
          opacity="1.000000"
          stroke="none"
          d="
M1021.276855,512.617188
	C1017.300354,512.884399 1013.728821,515.189209 1009.423218,513.978821
	C1005.451721,511.732666 1001.418274,511.167694 997.341064,510.767670
	C995.551758,510.592072 993.727295,510.576691 991.851379,509.303345
	C990.686951,507.619568 989.847595,506.150055 988.270386,504.782227
	C985.368225,501.376831 981.402832,501.375305 977.888672,500.528503
	C971.186340,498.913361 964.496704,497.289581 957.979675,495.032562
	C956.769714,494.613464 955.457703,494.152954 954.950073,492.402771
	C955.265747,489.360413 957.117432,489.065552 959.290894,488.987305
	C969.587830,488.616699 979.964233,488.279388 989.323425,493.417938
	C993.089294,495.485596 996.993347,496.891418 1000.957581,498.308807
	C1003.962280,499.383179 1006.923157,501.632782 1009.897278,497.953339
	C1011.125305,496.434143 1012.746765,497.753906 1013.872986,498.597961
	C1016.094666,500.262939 1018.441895,501.136230 1021.254333,501.023682
	C1033.748169,500.523773 1045.833130,502.409637 1057.438599,507.226349
	C1059.554077,508.104370 1061.885254,508.686401 1064.164307,508.900787
	C1069.029663,509.358368 1073.578125,510.521210 1077.781006,513.729980
	C1078.000488,513.996948 1077.895020,514.105835 1077.842285,514.160278
	C1075.252197,516.623840 1072.138916,513.390991 1069.140869,515.169800
	C1060.367188,514.832214 1052.751587,511.275757 1044.387695,509.174561
	C1038.332520,504.669525 1031.965454,506.806366 1025.809204,507.291565
	C1023.860779,507.445160 1021.995544,508.409302 1019.618530,508.640076
	C1019.803528,510.185730 1021.921997,510.741241 1021.276855,512.617188
z"
        />
        <path
          fill="#372624"
          opacity="1.000000"
          stroke="none"
          d="
M995.773743,465.340210
	C1004.801514,464.309631 1012.765015,467.865173 1020.918884,470.253632
	C1025.903687,471.713806 1031.163208,471.071625 1036.070557,472.927917
	C1038.012207,473.662415 1040.944702,473.218109 1040.691895,476.152344
	C1040.439331,479.083466 1037.573730,477.602509 1035.414795,478.007324
	C1019.993835,477.136047 1005.045837,475.189758 989.961975,475.964203
	C985.542236,476.191132 981.090698,476.174408 976.671387,475.938751
	C971.219482,475.648132 966.269165,470.347870 966.800659,465.227844
	C969.415710,463.663116 970.382874,465.751801 971.485779,466.982941
	C974.111389,469.913818 977.311523,471.298706 981.193787,471.244690
	C987.613098,471.155396 994.056152,471.628632 1000.737000,470.894135
	C999.693787,467.943512 995.484985,468.966217 995.773743,465.340210
z"
        />
        <path
          fill="#492E2E"
          opacity="1.000000"
          stroke="none"
          d="
M995.567139,465.015076
	C996.688477,468.112823 999.644836,466.537384 1001.355774,467.600098
	C1002.459534,468.285614 1003.970581,468.413513 1003.854553,470.201508
	C1003.735840,472.031799 1002.584290,472.914062 1000.909912,472.921265
	C993.109924,472.954865 985.295227,473.182312 977.514771,472.779236
	C972.818420,472.535950 970.433594,468.413330 967.389526,465.062195
	C966.907471,464.123230 966.619019,463.358368 965.353699,463.016632
	C963.288269,460.530701 964.224182,458.579803 966.481201,456.545227
	C969.658569,454.684174 971.806763,456.955841 974.604187,457.803070
	C974.967712,458.039673 974.988159,458.003357 974.986206,458.023041
	C975.444580,458.463135 976.078186,458.488342 976.785767,458.993073
	C977.966980,460.983765 979.886780,460.751068 981.673889,461.499634
	C985.663147,465.441498 990.925415,463.140228 995.567139,465.015076
z"
        />
        <path
          fill="#473235"
          opacity="1.000000"
          stroke="none"
          d="
M954.650635,492.020752
	C962.379822,495.629944 970.440979,496.898376 978.226501,499.116211
	C982.044067,500.203735 986.714905,499.533417 989.204346,503.775024
	C986.906189,506.048645 984.631897,504.883636 982.421875,504.352325
	C972.560303,501.981598 963.222900,497.672455 952.534973,496.384399
	C953.703064,498.493652 954.562500,500.601624 952.154602,502.629456
	C949.032166,503.830902 946.592896,502.815948 943.979675,501.142700
	C943.885864,497.414734 942.788330,493.788788 945.729370,490.573639
	C947.476929,489.272675 948.521484,487.933838 948.732056,485.694489
	C949.136719,483.948578 949.493408,482.548218 950.779175,481.217407
	C951.089722,481.023193 951.553345,481.018158 951.785278,481.021606
	C951.992493,483.842285 952.206116,486.688507 951.867432,489.467438
	C951.598145,491.676392 952.516357,492.080872 954.650635,492.020752
z"
        />
        <path
          fill="#372624"
          opacity="1.000000"
          stroke="none"
          d="
M967.077332,456.360901
	C966.880188,458.754700 964.503662,459.908936 965.039185,462.574707
	C963.163452,461.394989 960.487915,460.596558 960.013611,457.334534
	C960.267334,456.470856 960.529724,455.961884 960.902466,455.188141
	C963.325562,453.412842 965.251343,454.175293 967.077332,456.360901
z"
        />
        <path
          fill="#11161D"
          opacity="1.000000"
          stroke="none"
          d="
M1104.526123,517.536682
	C1104.482056,517.559937 1104.570312,517.513428 1104.526123,517.536682
z"
        />
        <path
          fill="#385661"
          opacity="1.000000"
          stroke="none"
          d="
M167.252518,508.306335
	C164.199142,505.963806 161.807861,508.070099 159.763763,509.256409
	C155.742371,511.590332 152.583084,515.162415 150.265244,519.129211
	C147.730637,523.467102 146.830673,523.777832 143.104736,520.662964
	C133.851990,512.927795 124.460594,505.358032 115.084656,497.771545
	C114.494400,497.293945 113.558502,497.243469 112.424553,496.995972
	C112.065704,496.997681 112.024185,496.968750 112.002670,496.955444
	C111.601753,496.465149 111.470016,495.927673 111.806396,494.958374
	C122.296364,492.145172 132.683853,489.114075 143.222641,490.792664
	C149.480774,491.789459 155.746033,491.772583 161.952423,492.695801
	C163.833786,492.975677 165.585464,492.363739 167.279236,491.721100
	C170.627701,490.450592 173.999619,490.258942 177.546631,490.698669
	C184.422531,491.551117 190.859573,488.151459 197.706680,488.388367
	C200.947510,488.500488 204.378601,487.388519 207.292221,490.450836
	C206.708633,493.946655 204.022598,493.554749 201.869553,493.809418
	C193.199692,494.834778 184.634979,496.625214 175.907135,497.255676
	C173.485901,497.430573 170.954102,497.673126 168.577530,499.376709
	C167.330170,502.755737 169.880081,504.094757 171.564789,505.635803
	C174.456985,508.281372 177.591415,510.663452 180.511566,513.794189
	C180.928238,514.827209 180.942825,515.546204 180.539749,516.590942
	C174.356506,516.775085 170.945465,512.648315 167.252518,508.306335
z"
        />
        <path
          fill="#457483"
          opacity="1.000000"
          stroke="none"
          d="
M167.008789,508.278931
	C171.564072,510.302521 175.160629,513.943542 179.682220,516.843445
	C180.706635,516.876160 181.430283,516.813049 182.496918,517.162598
	C183.043716,517.464233 183.245255,517.618042 183.679962,518.076050
	C184.159744,518.839966 184.338745,519.324768 184.575821,520.207825
	C186.548660,523.703186 189.652863,523.665527 192.618042,523.457703
	C199.913437,522.946472 207.207993,523.260010 214.501770,523.232117
	C220.242004,523.210205 225.396164,524.611816 228.592697,529.953979
	C229.745895,531.881226 232.215149,532.322571 233.357452,534.721924
	C232.479034,537.886902 229.929138,538.050903 227.738907,538.344971
	C213.992630,540.190613 200.249985,542.058960 186.525589,544.061584
	C185.680344,544.184875 184.893402,543.924622 183.773712,543.290710
	C181.230881,543.065613 179.077179,543.046814 176.464172,543.018494
	C173.197952,540.584167 172.607285,537.047668 171.348007,533.830994
	C169.416779,528.897949 167.200806,524.073547 165.593246,519.017761
	C164.564514,515.782349 163.977448,512.535522 166.744583,509.305542
	C167.009171,509.002899 167.012177,508.520233 167.008789,508.278931
z"
        />
        <path
          fill="#3E6978"
          opacity="1.000000"
          stroke="none"
          d="
M166.640594,509.058411
	C164.491516,517.545654 169.859955,524.243835 172.313934,531.619751
	C173.494888,535.169373 174.767090,538.688721 176.001434,542.615356
	C174.566849,543.285583 173.128799,543.562256 171.321960,543.834045
	C169.510254,540.710754 165.522888,542.277527 163.571350,539.701965
	C162.116135,537.781494 160.637146,536.522461 162.028488,533.739075
	C164.835876,528.122864 162.477707,522.057251 162.967224,516.208679
	C163.204102,513.378479 163.047165,510.495789 166.640594,509.058411
z"
        />
        <path
          fill="#465962"
          opacity="1.000000"
          stroke="none"
          d="
M133.150955,516.436951
	C134.186630,516.786072 134.812134,517.285767 134.399734,518.196899
	C134.309967,518.395264 133.490295,518.505371 133.214691,518.329834
	C132.525406,517.890686 132.033691,517.250977 133.150955,516.436951
z"
        />
        <path
          fill="#465962"
          opacity="1.000000"
          stroke="none"
          d="
M139.042694,521.471069
	C139.687592,521.826355 139.698807,522.182922 139.234695,522.497620
	C139.137924,522.563232 138.914001,522.559631 138.819778,522.490723
	C138.370560,522.162354 138.383530,521.806091 139.042694,521.471069
z"
        />
        <path
          fill="#79675E"
          opacity="1.000000"
          stroke="none"
          d="
M105.238075,492.972412
	C104.393097,492.302643 103.794029,491.773682 103.049782,490.978210
	C100.856720,486.917389 101.288109,486.229370 105.631401,485.537506
	C115.746658,483.926117 125.989227,483.927917 136.164352,483.091370
	C138.819733,482.873047 141.328537,481.879089 144.451813,482.004333
	C157.194260,480.425262 169.559464,479.433655 181.888245,478.346863
	C193.105774,477.358002 204.212372,475.327057 215.897888,474.882965
	C218.399353,474.327179 220.574905,474.575958 223.025391,474.036316
	C223.636917,474.042328 223.877151,474.052521 224.466522,474.108276
	C225.844299,474.576782 226.877731,474.896362 228.288757,474.199158
	C229.103226,474.012512 229.586441,473.983337 230.450562,474.057770
	C234.497589,474.993134 238.241211,474.210388 242.340881,474.683685
	C244.709991,475.299561 246.488159,476.123077 247.780914,478.345581
	C248.181061,479.623138 248.121765,480.481720 247.541183,481.690796
	C246.537674,482.631256 245.658859,482.623779 244.334473,482.598450
	C224.074280,478.929810 204.273056,481.568542 184.073349,482.990234
	C181.849899,483.864014 179.953949,484.301910 177.593201,484.488647
	C174.582581,484.628540 171.838776,483.894867 169.129333,485.881805
	C166.608841,486.817688 164.385223,486.850433 161.737335,486.660736
	C158.871384,486.486725 156.409134,486.236359 153.577759,486.965637
	C152.629105,487.178009 152.075958,487.210907 151.108368,487.128357
	C143.149033,486.812103 135.674408,487.500824 127.886185,488.953613
	C121.314636,490.021027 114.900925,489.629272 108.668320,492.222992
	C108.114929,492.834869 107.790070,493.176453 107.192398,493.745667
	C106.448730,493.693909 105.966354,493.403534 105.238075,492.972412
z"
        />
        <path
          fill="#43626E"
          opacity="1.000000"
          stroke="none"
          d="
M111.902351,494.710510
	C112.008270,495.458557 112.012451,495.882965 111.998901,496.624756
	C110.458603,496.923676 109.458557,496.216064 109.239807,494.158630
	C110.419876,493.251099 111.181961,493.604095 111.902351,494.710510
z"
        />
        <path
          fill="#43626E"
          opacity="1.000000"
          stroke="none"
          d="
M107.266136,493.997314
	C106.967003,493.529999 107.002907,493.075714 107.062607,492.280396
	C108.244431,491.628326 108.808441,492.286163 109.127869,493.576752
	C109.060181,493.920990 109.000061,493.992126 108.968460,494.027588
	C108.491631,494.045502 108.046402,494.027954 107.266136,493.997314
z"
        />
        <path
          fill="#9FDAEC"
          opacity="1.000000"
          stroke="none"
          d="
M188.370605,575.267456
	C181.582565,570.488708 174.857452,567.030823 166.459366,568.914551
	C162.735687,569.749817 159.711929,567.103699 161.326996,564.057739
	C163.938950,559.131775 161.203629,555.147034 160.996994,550.353394
	C168.847137,548.896301 176.699966,547.849365 184.894470,546.600708
	C186.814102,543.428772 190.056961,545.230652 192.632996,543.803528
	C198.977798,542.612488 204.919800,541.605347 211.382263,542.213501
	C212.823639,543.064392 213.384903,544.136475 213.420990,545.292847
	C213.570831,550.095337 215.449142,554.070007 218.183105,558.015320
	C221.297134,562.508911 219.573334,567.996094 214.316788,568.997681
	C207.510635,570.294556 201.166870,572.780273 194.239075,574.828735
	C192.914978,575.384705 191.873520,575.435913 190.500122,575.731201
	C189.615280,575.826904 189.129181,575.724365 188.370605,575.267456
z"
        />
        <path
          fill="#122430"
          opacity="1.000000"
          stroke="none"
          d="
M212.320038,543.367798
	C205.959656,543.767273 199.925186,544.421814 193.455505,545.086060
	C190.245514,544.735718 187.401642,545.450623 184.306625,544.331055
	C183.714951,543.946533 183.524887,543.745178 183.383331,543.291870
	C195.688477,541.228455 207.990967,539.363220 220.296997,537.521667
	C224.400558,536.907654 228.513214,536.354492 232.985977,535.605103
	C233.763962,534.172913 234.820648,533.803467 236.384888,533.545288
	C240.303665,533.724243 244.010239,532.561829 247.444412,535.385498
	C247.932999,535.832642 248.123672,536.013916 248.542725,536.512451
	C249.149673,537.504395 249.343475,538.242310 249.587967,539.350464
	C249.663330,539.955505 249.660370,540.199768 249.557343,540.794189
	C249.207382,541.556396 248.895752,541.912903 248.188416,542.340393
	C246.456955,542.701111 245.214890,542.129639 243.637909,541.570496
	C241.425385,541.186523 239.527740,540.939331 237.283539,541.371338
	C235.839630,541.496277 234.696960,541.421387 233.315353,542.051147
	C232.511276,542.262207 232.039154,542.333313 231.198288,542.401611
	C230.342911,542.447083 229.851547,542.460022 228.986359,542.473145
	C225.574188,542.646912 222.690216,543.672607 219.314789,544.177002
	C217.878067,544.431519 216.812225,544.420959 215.339386,544.469543
	C214.087280,544.411560 213.321671,544.136536 212.320038,543.367798
z"
        />
        <path
          fill="#457483"
          opacity="1.000000"
          stroke="none"
          d="
M183.994797,544.440186
	C186.827209,543.778076 189.702026,543.456238 192.774445,544.760132
	C190.903214,546.684387 188.369888,545.877869 185.643707,546.352966
	C184.836533,545.858337 184.436935,545.317749 183.994797,544.440186
z"
        />
        <path
          fill="#4B4D54"
          opacity="1.000000"
          stroke="none"
          d="
M345.007751,699.460083
	C346.418793,698.874268 348.290161,699.195190 347.180359,696.413513
	C348.448975,694.478333 350.414948,695.217957 352.526001,694.880310
	C358.634766,692.060364 364.540955,690.014282 371.377045,690.293457
	C373.679596,693.402649 371.180939,694.201843 369.347931,694.868958
	C366.108795,696.047852 363.228058,697.765869 360.138123,699.949951
	C355.895752,701.154175 356.335999,703.833374 357.085938,706.832825
	C360.544586,720.666138 367.949158,732.329712 376.302948,743.676758
	C383.088867,752.894165 392.320099,758.913208 401.486450,765.067261
	C405.084808,767.483093 409.729370,767.380798 414.085358,767.143311
	C415.577515,767.062012 417.093262,766.337341 418.525513,766.585632
	C427.953094,768.219727 433.186035,762.061829 439.297119,756.480835
	C451.095337,745.705994 456.503662,730.970337 464.715454,717.981323
	C467.640045,713.355408 469.954987,708.384155 473.759735,704.275085
	C477.035004,700.737793 480.748596,698.763367 485.908051,698.776245
	C487.065582,699.072083 487.703186,699.491150 488.047241,700.617676
	C475.817474,710.177917 470.396790,724.203613 462.885284,736.618225
	C455.391571,749.003418 448.301849,761.497131 437.313660,771.286377
	C433.132477,775.011292 428.763336,777.190918 423.011505,777.081726
	C414.858826,776.926758 406.853943,776.509216 398.889008,774.311157
	C393.949341,772.947937 390.225708,769.876709 386.710052,766.865784
	C380.699615,761.718201 375.115204,756.102478 371.200378,748.833435
	C367.536469,742.030273 362.433105,736.010925 358.079193,729.568298
	C353.550873,722.867493 351.815887,714.585815 345.723419,708.651794
	C343.680573,706.662109 345.244781,702.867676 345.007751,699.460083
z"
        />
        <path
          fill="#3C5965"
          opacity="1.000000"
          stroke="none"
          d="
M488.358887,701.060425
	C487.522919,700.694275 487.050751,700.377502 486.284973,700.029175
	C482.460205,698.180542 478.660492,696.803162 475.616882,693.539185
	C473.064423,689.164673 470.064240,685.728882 465.506165,683.300659
	C464.225220,681.691528 463.730347,680.258057 464.768677,678.302002
	C472.538391,681.664001 478.191162,687.779541 484.487000,693.044556
	C488.025085,696.003418 491.299774,699.277222 494.855164,702.704590
	C492.584167,703.484802 490.732147,702.035156 488.358887,701.060425
z"
        />
        <path
          fill="#496A76"
          opacity="1.000000"
          stroke="none"
          d="
M267.807312,707.898438
	C273.354675,719.736755 265.862762,727.987061 260.663208,736.810608
	C257.465607,742.236938 252.353867,746.309265 249.821716,752.231995
	C248.675262,754.913635 245.650055,755.381592 243.550797,756.952209
	C234.917984,763.410583 224.885040,766.586182 214.418396,766.741333
	C204.961029,766.881592 194.961624,767.054016 186.171066,763.245911
	C177.001846,759.273743 168.169495,754.046936 162.230240,745.388428
	C153.829941,733.142090 146.271667,720.463562 139.476517,707.190125
	C136.008972,700.416809 132.698471,693.652771 129.806015,686.629456
	C121.102196,665.495178 118.836266,643.425659 120.006607,620.830627
	C120.188049,617.327393 121.643463,616.918823 124.942604,617.832703
	C129.776566,619.171631 132.860107,623.161499 137.644989,624.983643
	C138.006851,624.992676 138.000320,624.999451 138.003815,624.996460
	C139.291290,626.811035 142.089172,627.715149 141.210724,631.003906
	C140.026749,631.850342 139.068054,631.667603 138.078156,631.419678
	C132.759262,630.087280 131.040985,631.046387 130.860367,636.537292
	C130.445251,649.156250 130.178055,661.690430 134.196808,674.066528
	C140.095200,692.231018 148.019882,709.214417 159.123367,724.816406
	C163.153992,730.479980 166.138824,737.112793 171.863235,741.170959
	C184.960938,750.455994 199.453415,754.759094 215.865631,753.888550
	C229.079056,753.187683 238.628052,747.488403 247.050522,737.914917
	C252.446594,731.781494 256.807434,725.372253 259.040161,717.521973
	C259.974091,714.238098 259.073975,710.948853 259.473999,707.330322
	C259.891388,706.853577 260.113098,706.715332 260.721283,706.642151
	C262.143250,706.815247 263.178741,706.923218 264.604736,707.055298
	C265.918152,707.044067 266.851501,706.955078 267.807312,707.898438
z"
        />
        <path
          fill="#414A51"
          opacity="1.000000"
          stroke="none"
          d="
M341.255615,687.792542
	C338.466492,689.926453 334.996826,688.233459 332.241730,690.573853
	C323.784515,690.722534 315.989197,693.528748 307.457031,694.076782
	C305.082397,692.071533 304.896149,689.540771 305.608063,687.164673
	C307.397736,681.191772 308.031891,674.968689 310.004089,668.633667
	C310.759674,667.578674 311.582672,666.929443 312.323181,667.139954
	C318.503906,668.897522 325.290771,667.630249 331.148468,670.992859
	C337.532440,674.657532 340.973511,680.120972 341.255615,687.792542
z"
        />
        <path
          fill="#465962"
          opacity="1.000000"
          stroke="none"
          d="
M310.946014,667.616821
	C310.495087,673.047607 310.371033,678.186340 308.476440,682.987000
	C307.171234,686.294250 306.803284,689.675842 307.018494,693.619629
	C302.710999,695.009277 298.416290,696.073853 293.538879,696.226624
	C290.225250,693.807251 290.606110,690.680115 291.305389,687.538818
	C292.353149,682.832031 293.236603,678.106506 293.152191,673.262268
	C292.983521,663.581848 288.343475,659.018188 278.786652,658.761719
	C275.623657,658.676819 272.031769,659.752686 269.634979,655.996277
	C271.124115,652.663635 273.866608,653.677551 276.197540,653.517029
	C281.732086,653.136108 287.269318,652.786011 293.225281,652.576538
	C294.121796,652.745789 294.705566,652.790039 295.025757,653.137024
	C299.846405,658.361938 305.341705,662.824097 310.946014,667.616821
z"
        />
        <path
          fill="#496A76"
          opacity="1.000000"
          stroke="none"
          d="
M269.655823,655.719971
	C273.732361,658.006592 277.756744,656.781311 281.646545,656.959351
	C286.954681,657.202332 292.593079,661.586792 294.153503,666.731384
	C296.164581,673.361633 294.827789,679.953613 293.431946,686.293396
	C292.729462,689.484131 292.620667,692.327698 293.071045,695.807556
	C289.178528,697.940186 284.890198,697.923645 280.385193,698.758911
	C277.293152,695.760315 278.670959,692.588074 280.067627,689.882568
	C283.269714,683.679932 282.787872,677.418335 281.511292,670.929993
	C280.548920,666.038513 277.779083,663.376953 272.944183,662.504517
	C269.495361,661.882263 266.063324,661.403442 262.544464,661.451477
	C260.436035,661.480164 258.132324,661.829102 256.433136,659.342773
	C256.202515,658.352539 256.271454,657.779053 256.743835,656.855225
	C261.011017,654.935791 265.023468,653.502380 269.655823,655.719971
z"
        />
        <path
          fill="#557E8B"
          opacity="1.000000"
          stroke="none"
          d="
M266.317627,698.584412
	C265.238434,698.942322 264.487823,698.978882 263.361694,699.039612
	C260.854828,698.681763 258.491394,699.775940 256.313477,697.732849
	C253.362411,693.195801 253.424133,688.676758 254.491241,684.008179
	C254.821609,682.562744 255.000519,681.073242 254.894989,679.561890
	C254.473495,673.525208 253.417221,672.210205 247.452682,670.722595
	C241.605057,669.264221 235.579330,669.781006 229.646332,669.250427
	C228.000534,669.103271 226.308167,669.393799 224.775146,667.725220
	C224.420929,666.005920 225.212585,665.182495 226.555344,664.221436
	C227.519135,663.783936 228.108704,663.655396 229.172119,663.582886
	C238.099396,663.290710 246.572662,663.508606 255.043594,663.569214
	C265.045288,663.640747 270.799408,669.458984 270.901550,679.324707
	C270.970306,685.965820 271.531372,692.729736 266.317627,698.584412
z"
        />
        <path
          fill="#4C707C"
          opacity="1.000000"
          stroke="none"
          d="
M256.690491,658.775208
	C258.522400,660.496521 260.432922,659.907532 262.199036,659.985413
	C265.166565,660.116333 268.198242,659.803284 271.097076,660.297729
	C280.816345,661.955566 282.549194,664.615173 284.154541,674.617249
	C285.137207,680.739868 283.816498,685.984314 281.316803,691.220886
	C280.279114,693.394653 279.861053,695.579224 279.986023,698.324585
	C276.957062,698.867981 273.930939,699.014709 270.453674,699.101929
	C268.541138,697.000427 269.834229,695.179138 270.618591,693.446228
	C272.795746,688.635620 273.288544,683.629028 273.273132,678.400208
	C273.239502,667.016968 267.140686,663.933960 256.953278,663.874695
	C252.146637,663.846802 247.337769,664.209717 242.178375,663.519653
	C241.242310,662.601624 241.099762,661.794556 241.378296,660.523193
	C242.054153,659.396729 242.820724,659.100281 244.065140,658.912354
	C247.210083,659.424133 249.971390,660.243958 252.928345,658.219360
	C254.384567,657.797852 255.424210,657.860718 256.690491,658.775208
z"
        />
        <path
          fill="#517581"
          opacity="1.000000"
          stroke="none"
          d="
M241.704712,662.724060
	C249.383026,663.785767 256.759094,661.703979 264.173492,662.929810
	C269.969238,663.888123 274.398224,668.155945 274.926788,674.085693
	C275.685913,682.602417 275.021515,690.902283 270.051208,698.680664
	C268.928406,699.051941 267.854279,699.061462 266.384583,698.988403
	C267.919098,692.459839 269.507202,686.059204 269.117645,679.137695
	C268.626953,670.419189 263.800995,665.123169 254.876221,665.020508
	C246.562271,664.924866 238.246368,664.999634 229.462250,664.996033
	C227.592804,661.828003 230.864166,661.977905 232.410431,660.464355
	C233.498672,659.965271 234.277466,659.851562 235.468903,659.881958
	C237.844040,660.480469 239.855591,660.986633 241.704712,662.724060
z"
        />
        <path
          fill="#193A47"
          opacity="1.000000"
          stroke="none"
          d="
M256.722412,696.793335
	C258.517181,698.624207 260.766724,697.578857 262.722931,698.817383
	C262.588348,699.325928 262.219482,699.743347 261.787811,699.823669
	C259.715057,700.209534 257.057159,700.364624 256.559662,702.601868
	C255.870193,705.702454 259.936554,704.223694 260.982574,706.403442
	C260.925720,706.782837 260.743713,706.858459 260.274841,707.005737
	C259.223114,707.739685 258.380707,708.228516 256.971008,708.257874
	C256.371124,708.210205 256.135468,708.167969 255.564697,708.008911
	C254.379318,707.541199 253.764130,706.813293 252.604691,706.348877
	C251.260086,705.840332 250.239960,705.414551 248.953476,704.763794
	C246.748169,703.051880 244.870407,701.462036 242.691528,699.696045
	C241.398743,698.201355 240.720093,696.678955 239.363098,695.198120
	C238.960556,694.430420 238.806610,693.957275 238.656448,693.087585
	C238.474075,691.657776 238.647827,690.655029 239.282547,689.368896
	C241.662445,687.425354 243.322296,688.870911 244.782684,690.151611
	C247.091385,692.176270 249.782364,693.381226 252.954941,694.507690
	C254.653809,694.764404 255.660919,695.476746 256.722412,696.793335
z"
        />
        <path
          fill="#193A47"
          opacity="1.000000"
          stroke="none"
          d="
M268.166077,708.068604
	C267.123657,707.992737 266.277191,707.780273 265.212952,707.323608
	C266.086884,705.814209 267.312988,705.824829 268.829224,706.982544
	C268.856537,707.540161 268.634338,707.761292 268.166077,708.068604
z"
        />
        <path
          fill="#3C5965"
          opacity="1.000000"
          stroke="none"
          d="
M274.637085,707.440186
	C274.980133,707.882690 274.984955,708.214783 274.998932,708.801025
	C273.673798,710.255188 273.242554,709.409363 272.954224,707.921448
	C273.380493,707.560059 273.837769,707.444946 274.637085,707.440186
z"
        />
        <path
          fill="#59646C"
          opacity="1.000000"
          stroke="none"
          d="
M464.776428,677.962036
	C465.315491,679.410034 465.617676,680.835510 465.971741,682.623779
	C466.645508,685.033020 464.992523,685.949768 463.936707,687.147644
	C460.170990,691.420105 456.812347,696.015442 454.907349,701.392761
	C451.157196,711.978271 445.786987,721.688354 439.699097,731.056030
	C434.433014,739.159119 416.086639,746.507751 407.101562,743.132996
	C394.720001,738.482361 383.214325,732.271667 376.116302,720.193726
	C373.254913,715.324768 370.458588,710.410278 367.258820,705.728210
	C363.803375,700.671936 365.456757,696.446960 371.047333,693.877563
	C373.589203,692.709412 376.117340,691.603699 379.093994,690.570984
	C380.863129,689.422668 382.886597,689.824524 384.436035,688.152344
	C385.650909,687.433228 386.613678,687.083374 387.981079,686.708496
	C392.004730,686.632874 395.336334,684.480469 399.406158,685.244690
	C401.585327,687.602478 399.946716,688.786011 398.316620,689.691345
	C395.985138,690.986450 393.456879,691.877747 390.841949,692.467529
	C389.373322,692.798889 387.950653,693.192993 386.668701,694.052612
	C379.347260,698.962158 378.755341,702.033997 383.991211,708.949768
	C385.898346,711.468872 387.428345,714.233704 389.599518,716.593323
	C397.584076,725.270813 407.822296,726.923279 418.846252,726.305115
	C420.186127,726.230042 421.490967,725.997620 422.562378,725.056763
	C431.020508,717.628052 438.351196,709.287720 442.678833,698.757629
	C444.522186,694.272583 446.885895,690.063293 448.809357,685.641174
	C450.316864,682.175598 449.560059,681.247253 445.762329,681.049927
	C443.473114,680.930908 440.327240,682.057861 440.937561,677.464966
	C446.590424,674.302368 452.360870,673.731506 458.696045,674.703491
	C459.893311,675.124084 460.730743,675.318909 461.791229,675.750977
	C462.537811,676.526367 463.066223,677.059448 463.843353,677.738525
	C464.064545,677.912659 464.539581,677.939636 464.776428,677.962036
z"
        />
        <path
          fill="#4C707C"
          opacity="1.000000"
          stroke="none"
          d="
M483.378967,686.076172
	C476.479797,682.439087 470.971283,677.427612 464.925934,673.181763
	C462.694489,671.614441 460.493530,670.406555 457.436157,670.080688
	C452.024078,674.420410 445.652557,675.495544 439.518005,676.327576
	C433.278229,677.173767 427.184937,678.755920 420.927277,679.420654
	C414.531952,680.099976 408.121490,679.673523 401.361847,679.666443
	C399.558319,675.054260 402.706482,674.746826 405.523132,674.598083
	C409.116699,674.408325 412.743530,674.677612 416.280701,672.284302
	C426.612701,671.832520 435.760681,667.656738 445.820770,666.008911
	C449.084534,664.705811 452.106323,666.889832 455.179169,665.298584
	C455.584503,664.320374 455.916229,663.691345 456.623322,662.860352
	C459.077728,661.665649 460.841064,662.721802 462.891205,664.024658
	C466.549988,669.900208 472.337982,672.797119 477.558807,676.878296
	C479.963318,679.635803 483.882477,680.476562 484.627380,684.667419
	C484.449341,685.517822 484.158264,685.897705 483.378967,686.076172
z"
        />
        <path
          fill="#3C5965"
          opacity="1.000000"
          stroke="none"
          d="
M400.651184,678.708313
	C411.201385,677.418396 421.508270,677.544861 431.775208,675.601990
	C440.036499,674.038696 448.136414,672.044617 456.664307,670.068909
	C456.617371,671.944153 458.203125,672.913208 458.973389,674.602051
	C453.299408,675.916992 447.542938,676.904785 441.388763,677.964233
	C440.112488,678.999695 439.226501,679.952881 437.489624,680.311279
	C435.824554,680.463745 434.535309,680.513733 432.966309,681.198242
	C427.623291,682.175293 422.647736,682.992920 417.381897,684.393555
	C414.548340,684.304016 412.143188,684.851074 409.356262,684.542480
	C406.750916,681.399353 403.564087,683.218811 400.819733,682.793091
	C397.931030,682.345032 394.482574,683.691589 392.671326,679.708984
	C394.992340,676.765015 397.561279,676.916931 400.651184,678.708313
z"
        />
        <path
          fill="#43626E"
          opacity="1.000000"
          stroke="none"
          d="
M483.214172,686.250793
	C483.330780,685.839417 483.589142,685.639038 483.925659,685.162720
	C485.993622,683.124390 487.856995,684.043762 490.027283,685.307739
	C493.881226,688.165039 497.262939,691.035828 501.163940,693.780640
	C502.037292,694.614990 502.463440,695.431702 503.616333,695.937561
	C505.144653,696.719177 506.393036,697.321594 507.692017,698.513123
	C508.796478,700.836914 512.087158,702.237366 509.312988,705.142944
	C508.526978,705.057495 508.113342,705.013000 507.392212,704.919189
	C505.022278,701.456116 501.056122,700.553894 498.121063,698.267273
	C493.170105,694.410156 488.012085,690.821350 483.214172,686.250793
z"
        />
        <path
          fill="#3C5965"
          opacity="1.000000"
          stroke="none"
          d="
M463.991638,677.610962
	C463.140656,677.896118 462.390503,677.617493 462.065247,676.304688
	C462.882965,676.091431 463.608154,676.401550 463.991638,677.610962
z"
        />
        <path
          fill="#594E52"
          opacity="1.000000"
          stroke="none"
          d="
M311.390015,668.003723
	C304.586670,664.925476 299.010803,660.861694 295.159088,654.297363
	C293.407806,650.877686 297.112305,650.873840 298.126068,648.878174
	C299.512024,647.659973 301.090088,647.635986 302.615601,646.644165
	C303.217926,646.514099 303.469086,646.507568 304.080627,646.622803
	C305.105743,647.168457 305.580200,647.758850 306.073425,648.811340
	C307.108246,651.867004 304.980896,655.445557 308.338898,657.379700
	C312.583588,659.824402 316.457672,663.454590 321.882874,662.814880
	C332.511963,661.561707 340.780579,666.494202 348.276672,673.023132
	C351.755890,676.053406 352.902588,680.580017 353.612244,685.457397
	C353.476288,686.369141 353.197205,686.786316 352.368652,687.065430
	C348.541229,686.716797 345.227173,687.632446 341.449097,688.101929
	C338.408478,680.024475 334.972351,672.317261 325.566376,670.539795
	C320.985565,669.674194 316.126282,670.264160 311.390015,668.003723
z"
        />
        <path
          fill="#342A31"
          opacity="1.000000"
          stroke="none"
          d="
M351.999084,687.386841
	C352.277863,686.883972 352.537872,686.684753 352.893982,686.230469
	C354.836670,683.498169 357.925079,684.073669 360.720306,682.870972
	C364.822754,682.104370 368.627686,682.021240 372.676636,680.841431
	C374.756714,680.320129 376.454254,680.354431 378.582153,680.634216
	C380.501740,681.381775 382.162445,681.343933 383.692413,681.774780
	C386.381195,682.531982 389.044769,683.519226 388.222351,687.576050
	C387.186920,688.204895 386.405609,688.483093 385.311890,688.774658
	C382.778534,690.050110 379.888550,689.434998 377.586731,691.507385
	C375.703827,691.951843 374.105988,692.989929 372.300598,691.343140
	C365.941315,692.379822 359.847412,693.654907 353.337341,694.887085
	C355.965546,692.049500 352.667450,690.062195 351.999084,687.386841
z"
        />
        <path
          fill="#3A3D45"
          opacity="1.000000"
          stroke="none"
          d="
M534.257812,708.470581
	C531.977356,708.901062 529.945862,708.966736 527.481445,709.051636
	C525.318237,709.046021 523.587952,709.021240 521.426514,708.988037
	C520.000488,708.037231 518.713684,707.718689 517.448547,707.446960
	C513.213623,706.537476 511.056915,703.878479 510.632690,699.241821
	C507.234741,688.805115 508.370667,679.080383 511.887238,669.248474
	C513.707031,664.160583 517.378479,660.700623 520.783875,656.399170
	C523.150513,655.133118 525.317566,655.109680 527.482849,655.257629
	C531.467468,655.529724 535.241638,656.465027 536.825562,660.656738
	C538.441895,664.934448 538.487305,669.410095 535.068176,672.798523
	C532.392700,675.449951 531.809814,678.363037 531.863220,681.795044
	C531.938232,686.618713 532.327942,691.499512 531.753296,696.253784
	C531.213074,700.722595 534.546936,703.957947 534.257812,708.470581
z"
        />
        <path
          fill="#2C4653"
          opacity="1.000000"
          stroke="none"
          d="
M511.681824,698.719849
	C511.169006,705.701294 518.511536,704.610718 520.923584,708.634888
	C516.709717,709.171509 512.834534,707.842590 509.048370,705.443176
	C511.100616,702.322815 506.931030,701.789429 506.979126,699.408020
	C507.962158,695.798584 509.729645,697.564758 511.681824,698.719849
z"
        />
        <path
          fill="#28424F"
          opacity="1.000000"
          stroke="none"
          d="
M300.850159,400.338470
	C312.712067,403.168518 324.424835,406.334717 336.145599,409.471008
	C341.457916,410.892517 346.772522,412.526581 352.669006,410.145386
	C354.614563,408.968597 356.292389,408.476654 358.587341,408.965515
	C366.646820,415.205170 376.061249,414.951263 385.004211,416.049835
	C389.305267,416.578247 393.577362,416.997589 397.714355,418.283234
	C402.084076,419.641235 404.577026,423.097626 404.049286,427.198181
	C403.511200,431.379272 400.519135,433.193085 396.765778,433.428741
	C381.897736,434.362335 367.184479,436.809021 352.298950,437.676514
	C344.380951,438.137909 336.475891,439.576233 328.843109,442.076782
	C325.354218,443.219818 320.949860,442.278717 318.726471,446.988251
	C318.332397,447.913513 318.074524,448.428894 317.446777,449.229401
	C315.587250,450.642365 313.788452,450.387146 311.621216,450.472717
	C309.779510,450.454712 308.363464,450.495178 306.585693,450.039886
	C303.343811,447.115692 300.046082,445.699554 295.957611,446.247803
	C294.331543,446.465820 292.475647,446.355103 291.680237,444.553314
	C290.896393,442.777649 291.926270,441.411926 293.642517,440.146027
	C295.069427,439.997772 296.131561,439.998230 297.591949,439.998138
	C300.235107,438.570404 303.959961,439.326935 304.793732,435.252777
	C304.993866,434.983246 304.991943,434.496948 304.991211,434.253784
	C303.765503,431.351379 304.933411,428.176758 303.090942,425.306274
	C302.973999,421.723541 303.092926,418.598389 301.347809,415.449036
	C300.326935,413.606689 299.672211,410.906464 301.532867,408.463043
	C303.544922,405.820831 298.618805,403.548065 300.850159,400.338470
z"
        />
        <path
          fill="#33505C"
          opacity="1.000000"
          stroke="none"
          d="
M293.960144,439.652039
	C294.310516,441.692383 291.560577,442.915070 292.865936,444.408234
	C294.027344,445.736694 295.913757,444.614777 297.521790,444.370728
	C301.048706,443.835388 303.900391,445.122406 305.931458,448.513123
	C305.732452,449.291016 305.425140,449.851440 304.965698,449.888367
	C297.527863,450.486206 291.256287,455.717163 283.416656,455.072632
	C280.578552,454.839294 277.396606,455.328003 274.761292,457.745575
	C274.504150,458.290070 274.425171,458.516510 274.187958,459.062500
	C273.457672,460.257111 272.637024,460.553223 271.305542,460.337158
	C270.780487,460.175659 270.578827,460.089935 270.104828,459.825562
	C269.683014,459.491486 269.533569,459.336151 269.207703,458.575317
	C269.413544,457.001892 268.511993,456.557465 268.057190,455.611084
	C268.362976,452.431915 266.670563,448.010193 272.856689,448.938232
	C274.085327,447.451843 276.186737,447.331787 276.925476,445.324554
	C275.348236,442.349243 272.861938,441.161438 269.383118,440.999817
	C268.376617,432.126984 268.060455,423.304016 270.955414,414.703796
	C271.328094,413.596558 271.248413,412.299927 272.798767,412.187927
	C274.659668,412.053467 274.521423,413.594482 275.111298,414.762482
	C276.669891,417.848755 277.337524,420.558960 275.737152,424.080170
	C274.546844,426.699188 276.090179,429.246887 280.101349,429.986786
	C282.899933,432.716614 286.002777,430.897736 288.811615,431.998718
	C289.322784,432.287048 289.509064,432.433716 289.848572,432.901367
	C290.290161,435.903168 293.804932,436.509979 293.960144,439.652039
z"
        />
        <path
          fill="#5E9BAB"
          opacity="1.000000"
          stroke="none"
          d="
M290.367004,433.232605
	C290.001801,433.222321 289.542847,433.042511 289.297607,433.018097
	C286.372681,431.656250 282.949768,432.803711 280.321167,430.173706
	C280.070404,429.890167 280.131348,429.885956 280.147644,429.858521
	C278.900452,426.820648 279.223633,423.633148 279.127533,420.493683
	C278.945007,414.529724 279.262970,408.546021 278.191467,402.620117
	C277.656677,399.662354 278.703278,398.127563 281.996521,398.989471
	C286.164368,400.080261 290.334229,401.172180 294.534088,402.127991
	C296.804291,402.644623 297.336182,404.279694 297.453735,406.161896
	C298.011902,415.098297 298.451416,424.042389 299.055145,432.975494
	C299.211395,435.287964 298.229919,435.536713 296.424255,434.986877
	C294.525940,434.408844 292.629486,433.824493 290.367004,433.232605
z"
        />
        <path
          fill="#5E9BAB"
          opacity="1.000000"
          stroke="none"
          d="
M266.692871,442.558411
	C266.219299,442.483398 265.858704,441.985748 265.330078,441.339722
	C263.003021,437.147522 264.522278,432.760315 263.998047,428.561310
	C263.752838,426.597107 263.715393,424.607025 263.376801,422.267944
	C261.825653,420.808441 261.340027,419.387512 261.673798,417.230865
	C262.420959,412.469879 261.544861,408.105499 262.493134,403.387817
	C262.687927,400.001648 262.687927,397.036499 262.687927,394.071381
	C263.160156,394.054688 263.632355,394.037994 264.104584,394.021301
	C265.005005,410.059448 265.905426,426.097595 266.692871,442.558411
z"
        />
        <path
          fill="#33505C"
          opacity="1.000000"
          stroke="none"
          d="
M304.666779,435.140747
	C304.935883,441.558594 304.747284,441.714111 298.381134,440.055481
	C297.971893,435.546722 302.597321,437.370148 304.666779,435.140747
z"
        />
        <path
          fill="#285362"
          opacity="1.000000"
          stroke="none"
          d="
M262.414917,402.979309
	C262.848114,407.330017 263.008301,411.693298 263.122681,416.518646
	C262.316132,417.609711 261.621429,417.469879 260.984314,416.394470
	C261.005768,411.979797 261.015228,407.970581 261.037354,403.503632
	C261.414001,403.027893 261.777954,403.009888 262.414917,402.979309
z"
        />
        <path
          fill="#33505C"
          opacity="1.000000"
          stroke="none"
          d="
M302.750366,425.237122
	C308.454956,426.492493 304.221863,430.812592 305.234161,433.775909
	C302.098694,431.773102 302.783264,428.491882 302.750366,425.237122
z"
        />
        <path
          fill="#5E9BAB"
          opacity="1.000000"
          stroke="none"
          d="
M276.613831,445.029663
	C279.329193,448.930817 276.700623,448.955200 273.594360,448.913086
	C272.572876,447.918762 270.865814,447.138275 271.778595,445.739716
	C272.831268,444.126740 274.728119,445.328064 276.613831,445.029663
z"
        />
        <path
          fill="#33505C"
          opacity="1.000000"
          stroke="none"
          d="
M269.010681,441.015076
	C269.021881,441.028961 268.750061,441.415680 268.750061,441.415680
	C268.750061,441.415680 268.752075,441.093994 268.861877,441.036438
	C268.971649,440.978851 268.999451,441.001190 269.010681,441.015076
z"
        />
        <path
          fill="#214A59"
          opacity="1.000000"
          stroke="none"
          d="
M260.655518,416.034485
	C261.488037,416.302124 261.979736,416.615204 262.774170,416.954529
	C263.113739,418.350800 263.150604,419.720886 263.178650,421.499084
	C261.396454,424.399658 261.692841,427.499268 260.721191,430.223175
	C260.344482,431.279236 260.010681,432.600891 258.618317,432.652496
	C256.983154,432.713013 257.174438,431.211792 256.862244,430.175232
	C255.563431,425.863190 254.253769,421.554443 252.612610,417.118469
	C252.209595,416.548309 252.142029,416.104156 252.076736,415.322021
	C252.284378,414.791962 252.382919,414.578094 252.375381,414.342377
	C252.367538,414.095978 252.337753,413.972778 252.061554,414.151123
	C251.837830,414.329468 251.384277,414.223389 250.912125,413.945312
	C250.145538,412.960907 250.164337,412.266815 250.746368,411.304016
	C253.697235,410.568420 255.305359,411.870789 256.160004,414.337433
	C256.899384,416.471466 258.278809,417.051208 260.655518,416.034485
z"
        />
        <path
          fill="#76D4DF"
          opacity="1.000000"
          stroke="none"
          d="
M229.083893,411.144714
	C228.774597,410.864685 228.430252,410.855896 228.264923,410.903259
	C227.880829,409.706909 227.370636,408.356354 229.597473,407.772827
	C237.363068,407.524292 244.144409,409.589569 250.739059,413.645050
	C251.026138,413.929291 250.947983,414.059875 250.933350,414.134521
	C250.921234,414.684387 250.923767,415.159607 250.914093,415.990997
	C248.482956,417.697906 246.045868,416.831329 243.767822,416.115021
	C238.961578,414.603607 233.797775,414.160431 229.083893,411.144714
z"
        />
        <path
          fill="#285362"
          opacity="1.000000"
          stroke="none"
          d="
M251.018295,413.565704
	C244.430283,411.293549 237.549225,409.662323 230.302155,407.906189
	C232.967026,402.736572 236.340561,407.508636 239.409531,407.663239
	C241.463791,407.766724 243.972198,409.027985 246.607635,409.953857
	C247.413422,410.463501 248.008698,410.490753 248.790237,410.803345
	C249.453125,410.988495 249.887390,410.996674 250.646729,411.013489
	C250.984695,411.748810 250.997574,412.475464 251.018295,413.565704
z"
        />
        <path
          fill="#2E515F"
          opacity="1.000000"
          stroke="none"
          d="
M215.665436,417.595581
	C216.449188,417.469971 216.782654,417.879364 216.335541,418.303101
	C215.890503,418.724854 215.495621,418.374237 215.665436,417.595581
z"
        />
        <path
          fill="#214A59"
          opacity="1.000000"
          stroke="none"
          d="
M248.829712,410.754395
	C248.466431,410.808319 247.913986,410.636353 247.173218,410.237091
	C247.536774,410.182709 248.088669,410.355591 248.829712,410.754395
z"
        />
        <path
          fill="#214A59"
          opacity="1.000000"
          stroke="none"
          d="
M201.945892,405.634155
	C199.951920,407.367889 200.196289,405.631622 200.061554,404.094482
	C200.666107,404.286499 201.252121,404.819946 201.945892,405.634155
z"
        />
        <path
          fill="#3B3036"
          opacity="1.000000"
          stroke="none"
          d="
M631.908142,699.270752
	C632.563110,699.091797 632.969543,699.083069 633.680542,699.061157
	C634.785034,699.007629 635.585693,699.001953 636.686401,698.984436
	C638.054688,698.967346 639.122803,698.956604 640.590698,698.919678
	C645.281433,696.958130 649.918030,696.402405 654.789673,695.288635
	C655.526733,695.060669 655.945740,695.059814 656.678467,695.037903
	C659.729614,694.184814 662.310242,692.525269 665.689331,693.740479
	C663.538757,698.821960 658.456604,698.094055 654.530090,698.793762
	C646.590027,700.208557 638.752197,702.154846 630.803650,703.381653
	C626.149109,704.100037 621.452515,705.884766 616.289307,704.274658
	C615.612610,703.619080 615.486084,703.157654 615.891663,702.369263
	C616.875061,702.095459 617.579285,702.079224 618.635864,702.057495
	C619.784851,702.008118 620.582703,702.002380 621.679260,701.976318
	C622.731689,701.960144 623.485107,701.953491 624.614380,701.919922
	C625.574341,701.418640 626.113403,700.893127 626.918457,700.247437
	C627.862610,700.082214 628.570312,700.070740 629.631836,700.046753
	C630.600098,699.996033 631.162170,699.809753 631.908142,699.270752
z"
        />
        <path
          fill="#241312"
          opacity="1.000000"
          stroke="none"
          d="
M666.386963,693.977661
	C663.278137,694.374939 660.554138,694.696228 657.411133,695.017212
	C662.242249,692.672607 661.411255,690.213928 657.058838,687.354614
	C658.385071,684.864380 660.622314,684.543213 662.878296,684.487366
	C664.514954,684.446838 666.065430,684.335571 667.819519,683.166992
	C669.663879,682.572388 671.066101,682.600281 672.685547,683.776306
	C674.403564,686.759827 675.306763,689.442688 673.012939,692.476501
	C670.926147,693.179626 668.848877,693.540649 666.386963,693.977661
z"
        />
        <path
          fill="#3B3036"
          opacity="1.000000"
          stroke="none"
          d="
M576.441101,713.003296
	C575.216675,712.994690 574.433411,712.984497 573.255859,712.946777
	C573.338135,710.718506 575.327087,710.809753 576.821594,710.628296
	C588.747559,709.179810 600.199097,705.220154 612.588013,704.042786
	C613.015442,705.784729 612.447327,706.938477 610.461609,706.979553
	C601.086487,707.173462 592.425293,710.954529 583.305237,712.473877
	C581.147644,712.833252 579.031189,712.929810 576.441101,713.003296
z"
        />
        <path
          fill="#241312"
          opacity="1.000000"
          stroke="none"
          d="
M615.844971,702.121826
	C616.119690,702.532288 616.068665,702.952942 616.005127,703.692261
	C615.550232,703.997559 615.107849,703.984192 614.334961,703.940308
	C614.137817,703.000610 614.497559,702.284363 615.844971,702.121826
z"
        />
        <path
          fill="#623B37"
          opacity="1.000000"
          stroke="none"
          d="
M786.356445,742.775391
	C782.302979,743.081665 780.005432,745.095947 778.045227,748.109985
	C775.681213,751.744873 772.345337,754.704773 771.549072,759.345093
	C771.247925,761.099548 769.378113,760.669312 767.777466,760.496826
	C762.353333,759.912231 756.989563,758.767456 751.471558,758.946045
	C748.255127,759.050293 745.240234,759.419067 742.378296,761.033691
	C739.130127,762.866150 735.532410,762.122925 732.132935,761.710205
	C729.945251,761.444702 728.977173,759.469727 729.005493,757.213989
	C729.033264,755.005737 728.497986,752.916077 725.851135,753.119385
	C722.555420,753.372437 720.564453,751.475037 719.702759,749.050842
	C717.876709,743.914124 713.355286,740.156494 712.465637,734.692383
	C711.966125,731.624512 710.507874,729.205627 708.116333,727.741516
	C700.567261,723.120056 697.838623,714.925720 693.172058,708.188049
	C691.854431,706.285767 690.780945,704.254883 689.113708,702.573730
	C683.821472,697.237061 683.838013,696.428406 689.155212,691.011047
	C689.909363,690.097168 690.385803,689.906494 691.285339,689.771179
	C692.464966,689.760986 693.203491,689.974243 694.196045,690.590698
	C697.730286,696.623230 700.653503,702.641846 706.708679,706.239807
	C707.979370,706.994812 708.942993,708.337646 709.416992,709.757141
	C710.844421,714.031433 713.478333,716.724854 717.988220,717.923706
	C721.544250,718.869019 722.151978,722.333923 722.900452,725.443298
	C723.746338,728.957764 724.075256,732.918152 729.629639,731.213440
	C731.239258,730.719360 732.710144,732.175171 733.828125,733.481812
	C734.594299,734.377258 735.066284,735.443237 735.597229,736.480530
	C741.680481,748.364258 741.684204,748.359131 754.583496,749.766663
	C755.583801,749.875854 756.906372,750.241272 757.441833,749.450806
	C760.040344,745.614868 765.207275,744.230652 766.827393,739.514099
	C767.682129,737.025757 769.306458,735.211548 771.652466,734.052734
	C776.127625,731.842224 779.522461,728.799622 781.329224,723.867371
	C782.514404,720.631958 784.604797,717.633850 787.137939,715.217163
	C790.546143,711.965759 792.815247,708.132935 793.215454,703.526489
	C793.978333,694.746765 797.753723,686.067749 794.302795,677.105835
	C794.063416,676.484131 794.303162,675.790588 794.774048,674.846252
	C795.458374,674.222046 795.947571,674.010803 796.892944,673.862061
	C800.929382,674.308716 802.029602,677.006470 802.887573,680.022888
	C804.406006,685.360962 802.986023,690.604553 802.431335,695.859680
	C802.115112,698.855957 802.035156,701.779663 802.382629,704.786987
	C803.183716,711.720032 803.307373,718.520874 796.676636,723.233826
	C795.581421,724.012268 795.160889,725.402222 794.853455,726.710999
	C793.454041,732.668335 790.397705,737.768066 786.356445,742.775391
z"
        />
        <path
          fill="#543937"
          opacity="1.000000"
          stroke="none"
          d="
M786.002441,743.423889
	C785.432800,737.761841 790.864136,735.418640 791.833862,730.712646
	C792.759338,726.221252 794.019348,722.021484 798.798645,718.867432
	C802.258789,716.583862 801.789673,710.437378 800.787415,706.150146
	C799.351807,700.009277 800.875427,694.275146 801.771545,688.445740
	C802.355957,684.643555 801.222534,681.200500 800.589539,677.643066
	C800.355835,676.329468 799.116211,675.471863 797.365601,675.049988
	C796.609009,674.053833 796.997375,673.291565 797.889832,672.274902
	C800.981323,670.452087 803.825806,669.949097 807.193359,671.501404
	C808.163330,672.359192 808.718994,673.084839 808.866028,673.858948
	C810.547180,682.710327 814.583313,691.189331 813.305359,700.507751
	C812.804993,704.156433 813.048584,707.815857 812.765869,711.460083
	C812.597595,713.630066 812.337280,715.781799 810.362061,717.731934
	C808.241455,723.375610 804.192810,727.469727 801.833069,732.541687
	C800.921082,734.501953 800.235229,736.494690 799.251099,738.746704
	C795.733459,739.358093 794.234375,741.659241 793.111877,744.368713
	C793.049744,744.518494 793.065552,744.698975 793.015259,744.855591
	C792.206299,747.377258 791.314697,750.883423 788.485840,750.533508
	C785.157043,750.121704 786.225342,746.366089 786.002441,743.423889
z"
        />
        <path
          fill="#471B15"
          opacity="1.000000"
          stroke="none"
          d="
M686.313538,690.827759
	C686.008667,686.484680 690.636414,688.206238 692.528381,685.848999
	C690.067871,684.687256 687.769531,683.601990 685.231934,682.261292
	C685.056458,679.427490 687.256226,679.590515 688.835632,679.176575
	C691.655823,678.437378 694.596924,678.160095 697.701782,676.809204
	C701.888672,676.165771 705.653381,675.420349 709.870239,676.458435
	C711.782715,678.493774 711.809814,680.594788 711.364380,683.206360
	C710.629822,685.496948 709.220947,686.499084 707.065186,687.240234
	C705.843384,687.442017 705.030212,687.522705 703.837463,687.621094
	C700.078125,688.129150 696.918945,689.580566 693.152527,689.952881
	C691.679382,690.059387 690.508911,689.844482 689.219482,690.725464
	C688.958557,690.938232 688.955811,690.956116 688.954529,690.952515
	C688.198303,690.928284 687.443359,690.907593 686.313538,690.827759
z"
        />
        <path
          fill="#4C393B"
          opacity="1.000000"
          stroke="none"
          d="
M810.256531,718.258667
	C813.022583,702.646973 811.230591,687.613159 806.994995,672.339233
	C806.918091,670.390747 808.060730,670.297485 809.550781,670.185791
	C812.414307,672.209839 815.534302,671.001648 818.568848,672.093872
	C823.062195,678.288208 828.098755,683.824463 829.231873,691.694641
	C827.680420,698.590027 825.581726,704.998108 824.794617,711.719421
	C824.343384,715.572754 822.196533,718.851013 819.682007,721.744751
	C817.458130,724.304077 815.995605,727.135742 815.251099,730.739380
	C813.501892,726.839539 812.006287,722.679016 810.256531,718.258667
z"
        />
        <path
          fill="#5D413D"
          opacity="1.000000"
          stroke="none"
          d="
M673.277954,683.808105
	C671.650696,684.083923 670.301392,684.089478 668.547058,684.091431
	C665.235901,686.100952 662.254639,684.190857 659.433960,683.937256
	C651.906982,683.260315 643.993774,683.502441 637.652405,678.142578
	C637.024658,677.611877 636.188293,677.218750 635.388123,677.129700
	C622.934937,675.745178 616.927368,665.327332 609.140381,657.762573
	C605.269104,654.001770 607.084473,648.375427 610.364502,643.597839
	C614.928101,639.389221 619.282349,635.579346 625.357971,634.810852
	C628.554932,634.406433 631.945129,633.242798 634.479126,636.956970
	C633.612915,639.834656 631.259460,639.845703 629.236145,640.427551
	C627.173828,641.020630 625.006165,641.373596 623.217346,643.260376
	C622.730896,646.702820 625.421570,647.502380 627.228088,649.060059
	C629.761292,651.244385 633.714111,651.938110 634.487183,656.272583
	C632.801697,659.409424 629.815857,657.480652 627.217957,658.675354
	C633.575134,665.386841 641.183533,668.262146 649.951050,669.673401
	C658.065063,670.979492 666.384033,671.445312 674.256348,674.013489
	C677.796631,675.168396 681.767639,676.370056 681.226440,681.746582
	C678.800720,683.924683 675.887390,682.292419 673.277954,683.808105
z"
        />
        <path
          fill="#72443E"
          opacity="1.000000"
          stroke="none"
          d="
M681.400879,682.027588
	C680.598694,678.537720 678.142090,676.830811 675.177246,675.971252
	C670.785522,674.698059 665.982849,674.626831 661.745972,673.602539
	C649.645508,670.677002 636.218323,670.910767 626.401794,661.199829
	C624.786377,659.601807 624.610901,657.880188 625.327332,656.268005
	C626.139282,654.440735 627.615417,655.951965 628.729858,656.346985
	C630.521912,656.982422 632.237183,657.420105 634.115601,655.700195
	C635.645142,654.893616 636.812439,655.426575 638.349609,655.967651
	C640.202576,657.103333 642.016418,657.756104 642.595703,660.150024
	C641.918030,663.430237 643.136902,664.775146 645.993164,665.328552
	C651.747131,666.443298 657.490295,666.421997 663.282898,665.828918
	C675.138855,664.614990 686.172852,667.286072 696.504456,673.077515
	C698.230957,674.045349 699.325195,675.408997 698.149414,677.854858
	C694.352173,681.378601 689.569580,679.991211 685.279907,681.744812
	C683.928528,682.011353 682.864319,682.016907 681.400879,682.027588
z"
        />
        <path
          fill="#AA8367"
          opacity="1.000000"
          stroke="none"
          d="
M1127.562500,539.208618
	C1130.807861,540.253174 1133.667603,541.214111 1136.782837,542.408752
	C1137.214966,542.853149 1137.391724,543.063965 1137.279785,543.569458
	C1131.942871,542.524475 1126.894775,541.184692 1121.455078,539.820557
	C1119.967896,539.465332 1118.872070,539.134399 1117.436523,538.719849
	C1116.512573,538.829163 1115.948975,538.813782 1115.183350,538.292603
	C1114.406128,537.835205 1113.415527,537.586304 1113.393677,537.270508
	C1113.256714,535.287903 1114.915039,535.817200 1115.787476,536.027283
	C1119.610962,536.947754 1123.384888,538.073669 1127.562500,539.208618
z"
        />
        <path
          fill="#632B1D"
          opacity="1.000000"
          stroke="none"
          d="
M1115.022705,538.395264
	C1115.509155,538.180725 1116.069336,538.246399 1116.863281,538.474243
	C1116.451294,539.733276 1115.788940,539.971619 1115.022705,538.395264
z"
        />
        <path
          fill="#3E2423"
          opacity="1.000000"
          stroke="none"
          d="
M1082.977295,524.550537
	C1073.758545,528.576721 1067.882690,526.972107 1060.707275,519.817383
	C1056.920288,516.041138 1051.703003,518.288269 1047.059326,518.461304
	C1041.582764,518.665283 1035.995850,519.371033 1031.085938,515.371216
	C1032.547119,513.436218 1034.363892,514.576111 1036.059937,514.468384
	C1039.379517,514.257446 1043.001099,514.680359 1045.099243,510.585327
	C1052.490234,510.224426 1058.903809,512.972168 1065.732422,515.423950
	C1067.881348,521.306641 1071.948975,523.653564 1078.035889,523.558105
	C1079.796509,523.888184 1081.236938,524.113647 1082.977295,524.550537
z"
        />
        <path
          fill="#6D3E2F"
          opacity="1.000000"
          stroke="none"
          d="
M1078.320190,523.383179
	C1071.577271,525.834229 1066.731567,523.302551 1066.305054,517.069946
	C1067.274292,516.077698 1068.181885,515.945557 1069.518555,516.280029
	C1071.716675,516.558472 1073.022339,517.748596 1074.515259,519.801208
	C1074.162720,516.799377 1072.300049,517.238403 1070.760254,516.587769
	C1069.713257,516.279053 1069.216431,515.837158 1068.977295,514.775757
	C1071.546631,511.292114 1074.348999,514.530701 1077.401978,514.168945
	C1077.606812,516.556519 1079.970825,517.966797 1080.020142,520.600952
	C1079.211304,521.473694 1078.335571,521.947998 1078.320190,523.383179
z"
        />
        <path
          fill="#D0C3BE"
          opacity="1.000000"
          stroke="none"
          d="
M1081.698242,341.890991
	C1084.220825,344.584869 1086.475708,347.198822 1088.207031,350.620117
	C1083.323975,349.771698 1079.596802,352.992615 1075.449463,354.704163
	C1071.815674,356.203674 1068.361450,357.048920 1065.242554,353.145660
	C1064.954224,353.022919 1064.996338,353.006592 1064.991943,353.029053
	C1064.814941,352.913391 1064.642578,352.775208 1064.197510,352.825256
	C1062.324097,351.852692 1060.834595,350.592529 1060.053589,348.321594
	C1061.489868,347.915070 1062.371948,348.969696 1063.995361,349.754517
	C1064.838745,349.533813 1065.148071,349.277954 1065.754150,348.875244
	C1068.803101,347.502289 1071.280518,345.907135 1073.417725,343.717224
	C1075.051880,342.042908 1076.927246,340.602173 1079.877075,340.847717
	C1080.635010,341.144531 1081.045044,341.468689 1081.698242,341.890991
z"
        />
        <path
          fill="#F4E1D8"
          opacity="1.000000"
          stroke="none"
          d="
M1080.058960,340.604187
	C1074.814575,342.958801 1072.141235,349.416077 1065.367188,350.000427
	C1064.828247,350.123871 1064.666382,350.244110 1064.265625,350.178467
	C1064.026855,349.992645 1064.005005,350.000793 1064.006348,349.985840
	C1061.934326,343.432220 1063.820679,338.434570 1070.009277,334.721069
	C1071.091064,333.932373 1071.992188,334.051849 1073.212891,334.501923
	C1074.134277,334.958008 1074.712402,335.324860 1075.646240,335.756195
	C1076.921143,336.530304 1077.762817,337.321289 1078.863037,338.273560
	C1078.726196,339.470367 1079.421265,339.874329 1080.058960,340.604187
z"
        />
        <path
          fill="#3A292B"
          opacity="1.000000"
          stroke="none"
          d="
M1073.241455,336.047333
	C1072.325928,335.351593 1071.596924,334.899353 1070.533447,334.425873
	C1065.061279,331.678589 1059.345581,331.161041 1053.367920,330.063049
	C1054.989014,325.670105 1059.001221,327.960175 1062.557861,327.266388
	C1069.960815,328.255157 1076.083130,330.515747 1078.902100,338.222321
	C1077.949097,338.069824 1076.889648,337.553101 1075.477661,336.997742
	C1074.623901,336.572388 1073.993896,336.513092 1073.241455,336.047333
z"
        />
        <path
          fill="#213642"
          opacity="1.000000"
          stroke="none"
          d="
M227.563721,382.988037
	C228.090820,382.110535 228.690887,382.045105 229.247253,382.600861
	C229.389496,382.742950 229.379333,383.287567 229.231796,383.425568
	C228.660080,383.960419 228.061768,383.887390 227.563721,382.988037
z"
        />
        <path
          fill="#4C393B"
          opacity="1.000000"
          stroke="none"
          d="
M611.225342,643.600342
	C610.625122,645.100220 610.204956,646.180115 609.881226,647.288208
	C608.533630,651.900818 608.096375,655.759094 612.724609,659.593384
	C617.371765,663.443298 622.264893,667.298706 625.917297,672.298096
	C626.110840,672.563049 626.364807,672.954346 626.620178,672.978882
	C634.472595,673.730713 640.516418,679.365356 648.020813,680.942322
	C654.400513,682.282959 661.090149,681.773193 667.742188,684.046631
	C665.478699,687.884644 660.966553,684.931763 657.397583,686.968506
	C632.608521,691.363892 608.229309,695.709045 583.431885,700.054443
	C578.424316,699.752258 575.165771,698.121582 574.725098,692.837463
	C574.520142,690.380127 572.189575,690.207825 570.137390,689.962280
	C562.339539,689.029297 561.227539,687.785767 561.096863,679.957947
	C560.942505,670.713074 561.671631,661.433228 559.348633,652.297302
	C558.705811,649.769104 558.901489,647.015015 560.332275,644.126709
	C564.931580,641.217773 569.826477,641.356628 574.555542,641.174316
	C583.660400,640.823120 592.710510,640.127502 602.145325,638.538940
	C606.259216,638.750122 610.342590,638.143066 611.225342,643.600342
z"
        />
        <path
          fill="#443A42"
          opacity="1.000000"
          stroke="none"
          d="
M561.051025,643.579956
	C561.704285,657.364441 564.026733,670.639160 563.033081,684.089172
	C562.805054,687.175720 564.715393,688.202148 567.468750,687.982544
	C573.205811,687.524963 577.840210,688.005371 577.188904,695.833008
	C576.885620,699.478455 581.788147,696.685181 582.913452,699.682007
	C571.358276,703.141113 559.407166,704.726746 547.590637,706.992249
	C543.382568,707.799011 539.234253,709.119873 534.438232,708.880249
	C526.672119,699.085449 531.228455,687.825317 530.020630,677.297729
	C529.793823,675.321228 530.451660,673.739868 532.406616,672.797974
	C537.445923,670.370056 536.249939,665.815674 535.645325,661.854431
	C535.013184,657.712952 531.303772,657.294373 527.912720,657.035706
	C525.924194,656.884033 523.914856,657.004089 521.462341,657.003296
	C519.631287,655.657043 519.947388,654.137085 520.695679,652.231201
	C522.454712,649.288818 525.238098,648.652710 527.845947,647.773193
	C533.802856,645.764038 533.840332,645.745789 532.722290,639.506958
	C532.602112,638.836060 532.676392,638.218140 533.372925,637.417847
	C536.258362,636.146118 538.612244,637.382874 540.929321,638.053650
	C545.432190,639.357056 550.068237,639.579529 554.656982,640.197754
	C557.109802,640.528259 559.710083,640.510925 561.051025,643.579956
z"
        />
        <path
          fill="#241312"
          opacity="1.000000"
          stroke="none"
          d="
M654.667969,695.041260
	C651.267273,698.379517 646.244568,697.652832 641.401611,698.920654
	C644.733276,695.313171 649.538025,695.362915 654.667969,695.041260
z"
        />
        <path
          fill="#241312"
          opacity="1.000000"
          stroke="none"
          d="
M636.786865,698.751831
	C636.273254,699.914673 635.415100,700.108459 634.191162,699.265564
	C634.693970,698.083984 635.547852,697.842773 636.786865,698.751831
z"
        />
        <path
          fill="#241312"
          opacity="1.000000"
          stroke="none"
          d="
M621.778809,701.742249
	C621.273743,702.911499 620.416565,703.096619 619.193726,702.268555
	C619.689270,701.085510 620.539978,700.843811 621.778809,701.742249
z"
        />
        <path
          fill="#241312"
          opacity="1.000000"
          stroke="none"
          d="
M626.831421,700.108948
	C626.915222,700.919861 626.554688,701.639587 625.301208,701.870239
	C625.136841,700.995056 625.497437,700.280762 626.831421,700.108948
z"
        />
        <path
          fill="#241312"
          opacity="1.000000"
          stroke="none"
          d="
M631.841003,699.097168
	C631.842224,699.926758 631.676819,700.955933 630.173401,700.227051
	C630.498779,699.720825 631.012024,699.407349 631.841003,699.097168
z"
        />
        <path
          fill="#433437"
          opacity="1.000000"
          stroke="none"
          d="
M829.388367,692.117188
	C826.680054,685.377625 821.551331,680.271057 819.049194,673.360352
	C822.034241,670.470337 824.541931,671.948181 826.864014,674.124512
	C828.082520,675.266418 828.889404,676.777954 829.593872,678.270264
	C832.486328,684.396667 836.856018,687.992004 844.039246,687.427002
	C846.433411,687.238647 848.509155,688.255310 850.122314,690.241577
	C854.548828,695.692078 857.227417,696.144958 863.443054,692.595154
	C867.404541,690.332642 871.572144,688.921387 876.544250,689.321899
	C878.837463,691.626343 877.657410,693.505798 876.296143,695.250671
	C874.556946,697.480042 872.540466,699.487610 870.361572,701.272339
	C867.710327,703.443909 866.068604,705.931824 865.156677,709.391479
	C863.778687,714.619141 858.147034,716.016357 854.564026,719.293152
	C853.160767,720.576538 851.228333,721.423584 849.274048,722.742188
	C842.868835,724.373169 837.711182,729.656616 837.011719,735.429199
	C836.817749,737.029602 836.511475,738.380493 834.375916,738.933228
	C831.829895,731.609436 833.923645,723.870056 832.043579,716.425110
	C830.370483,709.799561 831.102539,702.975647 830.981934,696.227478
	C830.956543,694.810608 831.433044,693.178650 829.388367,692.117188
z"
        />
        <path
          fill="#37343B"
          opacity="1.000000"
          stroke="none"
          d="
M849.377869,723.053345
	C849.328186,720.162109 851.961365,719.490906 853.506165,717.829041
	C857.123169,713.938110 865.013062,713.639893 864.130066,705.755676
	C863.929199,703.961609 865.717896,703.085388 866.962524,701.965149
	C870.704468,698.597168 875.258911,695.910950 876.989258,690.368286
	C876.308350,687.420044 878.388184,686.568848 879.917419,685.418335
	C889.707153,678.052734 897.557861,668.774475 905.507385,659.586792
	C907.047607,657.806641 906.709900,655.484253 907.766785,653.245728
	C909.151855,651.730469 910.820190,651.689880 912.623535,651.131958
	C914.010376,652.151978 915.241943,651.981323 916.761780,651.820190
	C921.628479,654.448486 918.907227,657.536499 917.719055,660.439453
	C912.845154,672.346619 902.685669,680.289124 895.367126,690.347961
	C890.622620,696.868774 885.602478,703.109680 880.495972,709.322510
	C878.623840,711.600403 876.636353,713.310608 873.784241,714.041443
	C871.300659,714.677795 869.619019,716.706482 867.477722,717.867615
	C861.977051,720.850525 856.522034,724.296753 849.377869,723.053345
z"
        />
        <path
          fill="#2E282E"
          opacity="1.000000"
          stroke="none"
          d="
M917.020874,651.762695
	C915.685486,652.433472 914.275757,653.864807 913.042847,651.411194
	C914.046204,651.074463 915.127075,651.046387 916.613281,651.038391
	C917.018616,651.058472 917.017334,651.527954 917.020874,651.762695
z"
        />
        <path
          fill="#412C2E"
          opacity="1.000000"
          stroke="none"
          d="
M783.129578,184.269089
	C778.381836,183.078918 773.990784,181.814560 769.305176,180.317688
	C771.962585,175.120285 772.255249,169.104523 775.661743,164.100769
	C777.102417,164.266724 777.707214,165.026611 778.263733,166.305206
	C779.212891,170.459732 780.834473,173.590057 785.228149,174.066467
	C789.865845,174.569336 792.736572,172.614609 795.407043,167.851151
	C796.499756,165.901855 797.002502,163.873245 797.536377,161.420731
	C797.647827,160.568985 797.761230,160.087799 798.077515,159.268280
	C798.530884,158.485199 798.888245,158.115265 800.432617,157.588562
	C803.961609,158.757355 807.135742,157.899277 809.478271,160.334808
	C809.870850,161.498932 809.929443,162.408966 809.375977,163.051392
	C803.899353,169.408585 800.067688,177.067017 793.217102,182.391556
	C789.981384,184.906464 787.193237,186.032578 783.129578,184.269089
z"
        />
        <path
          fill="#314D58"
          opacity="1.000000"
          stroke="none"
          d="
M777.395081,167.232819
	C776.823364,166.209549 776.560547,165.405624 776.161804,164.313950
	C776.045166,163.274551 776.064453,162.522903 776.090698,161.395782
	C775.964905,157.110519 777.767578,154.102814 780.787964,151.295074
	C782.805664,150.374268 784.553406,149.695267 786.661926,148.995590
	C792.461914,146.125336 795.691650,146.825424 798.564819,151.927078
	C798.291565,153.350403 797.521851,153.716232 796.244812,154.026550
	C795.029968,154.111420 794.241272,153.976852 793.081238,153.783905
	C786.320618,153.320419 782.775818,155.934723 781.420593,162.167389
	C781.064453,163.805206 780.658508,165.386841 779.450073,167.048019
	C778.699890,167.512772 778.210327,167.563156 777.395081,167.232819
z"
        />
        <path
          fill="#412C2E"
          opacity="1.000000"
          stroke="none"
          d="
M797.788940,153.387329
	C796.547913,146.866745 791.514771,149.691986 787.463745,148.990265
	C788.540649,145.163940 791.989746,144.212601 798.600586,145.953690
	C798.810120,148.054001 799.224426,149.899780 800.904602,151.712936
	C801.094299,152.505325 801.092468,152.987671 800.850342,154.051636
	C799.937256,154.917328 799.255310,154.929871 798.270996,154.514130
	C797.869141,154.120621 797.761658,153.914658 797.788940,153.387329
z"
        />
        <path
          fill="#412C2E"
          opacity="1.000000"
          stroke="none"
          d="
M780.698853,151.084259
	C779.686096,154.267288 777.322571,157.012207 776.337952,160.771729
	C774.190918,156.296417 776.975830,153.581833 780.698853,151.084259
z"
        />
        <path
          fill="#15191F"
          opacity="1.000000"
          stroke="none"
          d="
M796.456665,192.506409
	C795.737793,190.451508 794.411621,190.116058 793.050415,189.319855
	C793.830383,187.721985 795.243835,187.526169 797.116455,187.464752
	C800.146362,187.777710 801.888794,189.820465 804.163391,190.810318
	C807.291382,192.171524 809.991821,191.803680 810.801208,188.195007
	C813.173340,177.619186 819.813171,173.141174 830.389954,173.121506
	C834.136414,173.114532 837.358521,170.149170 841.302124,169.892639
	C844.013977,169.716232 846.284424,169.987885 847.667725,172.622787
	C848.563538,174.329102 850.058899,175.280289 851.717346,176.158707
	C856.992676,178.952637 857.022522,180.749283 851.720093,184.834412
	C850.193420,186.793243 848.226196,188.055908 847.928406,190.762390
	C847.879700,191.324203 847.849915,191.550064 847.747559,192.114166
	C847.611328,192.677597 847.547607,192.902786 847.262024,193.807220
	C846.311646,197.851501 846.026306,201.283051 845.548096,205.069916
	C845.831787,206.533051 845.811218,207.618546 845.681274,209.129272
	C843.985352,218.148773 844.940247,226.989105 843.028809,235.948059
	C839.148254,238.312012 836.845032,237.093094 835.712158,232.509857
	C834.872009,229.110886 835.839966,225.535248 834.516968,221.208008
	C833.333374,224.195343 833.692627,226.703278 832.890625,228.896057
	C832.322449,230.449356 831.942993,232.327072 829.886230,232.412216
	C827.943787,232.492615 827.038879,230.909302 826.341309,229.359955
	C825.385620,227.237350 824.594604,225.046738 823.889404,222.826599
	C822.105042,217.209030 821.083008,216.513336 814.496399,216.671997
	C810.532532,214.923889 808.595032,212.195328 809.253052,207.844452
	C813.040344,207.485291 817.258057,210.403427 819.698242,204.929626
	C820.938965,201.769943 821.247009,198.907959 818.251160,197.158295
	C815.263062,195.413071 812.895142,197.063065 810.977844,199.434692
	C810.454468,200.082062 809.930664,200.710373 808.887085,201.267197
	C804.346985,200.517181 802.840088,195.852539 798.648010,194.391068
	C797.759460,193.941544 797.235718,193.542480 796.456665,192.506409
z"
        />
        <path
          fill="#2C1E22"
          opacity="1.000000"
          stroke="none"
          d="
M798.333740,188.773102
	C796.577148,189.003372 795.154724,189.011169 793.305359,189.025238
	C789.693054,187.615784 786.507629,186.200073 783.047485,184.563812
	C782.873047,184.210602 782.987854,183.953186 783.071411,183.962936
	C792.539429,185.070740 796.359375,177.192276 801.125671,171.724167
	C803.822876,168.629730 805.896240,164.735657 808.949402,161.330246
	C811.542847,158.548248 815.056335,158.797928 817.930237,158.752823
	C835.897766,158.470825 853.876160,158.078735 872.263611,159.106842
	C874.794739,164.757904 870.508667,163.902313 867.236206,164.478607
	C859.011475,164.901962 851.318542,166.218857 843.564392,166.969116
	C839.786560,167.334641 835.970398,167.724808 832.531311,169.650879
	C831.368896,170.301926 829.976929,170.711380 828.685364,170.490555
	C821.205750,169.211761 814.777771,172.882324 807.981567,174.776443
	C804.018799,175.880905 801.860352,178.359055 802.035583,182.761490
	C802.133179,185.213364 801.987427,187.908722 798.333740,188.773102
z"
        />
        <path
          fill="#532C24"
          opacity="1.000000"
          stroke="none"
          d="
M771.080444,197.965302
	C773.857971,196.566940 776.793396,196.758865 779.669312,197.425430
	C783.622253,198.341614 787.318359,199.181503 787.336792,193.102524
	C787.338623,192.498230 788.349426,191.896973 789.190857,190.967453
	C791.738892,190.192795 793.250244,192.377243 795.774719,192.115479
	C796.152893,191.934662 796.104614,191.982269 796.076416,191.963379
	C796.407227,192.395050 796.807556,192.809326 797.379883,193.455948
	C797.698364,193.816513 797.824097,193.962875 798.114990,194.346512
	C798.605347,195.340164 799.288757,195.742737 800.127197,196.434143
	C800.485474,196.821136 800.612793,196.994339 800.884644,197.461945
	C801.408142,200.770233 802.227783,203.444473 804.912354,205.415024
	C807.183716,207.082245 807.723938,209.808060 807.924316,212.875351
	C807.330383,216.597504 805.804016,218.216614 801.979675,216.156769
	C800.716309,215.618301 799.773315,215.173630 799.109436,214.459183
	C791.904297,206.706116 783.100159,201.777206 772.821045,199.401199
	C772.165283,199.249634 771.652222,198.798737 771.080444,197.965302
z"
        />
        <path
          fill="#F0F0EC"
          opacity="1.000000"
          stroke="none"
          d="
M776.697266,185.248383
	C770.116028,184.765869 763.494629,185.039810 756.859314,185.965790
	C755.245544,186.190979 752.572754,187.606201 752.562500,183.510162
	C758.819885,182.506592 765.052063,182.472366 771.282654,182.941376
	C773.227173,183.087753 775.166687,183.379364 776.697266,185.248383
z"
        />
        <path
          fill="#532C24"
          opacity="1.000000"
          stroke="none"
          d="
M789.237427,190.420837
	C787.832092,190.998520 786.844666,190.398849 785.845337,189.039749
	C787.034058,188.593750 788.299744,188.899246 789.237427,190.420837
z"
        />
        <path
          fill="#CD7D4C"
          opacity="1.000000"
          stroke="none"
          d="
M894.416321,273.464783
	C893.813171,273.916870 893.353577,273.926300 892.661316,273.937866
	C892.148621,273.792908 891.868469,273.645782 891.319214,273.108521
	C891.565369,264.951599 891.163147,257.143616 891.907715,249.403305
	C892.128906,247.104111 892.428589,244.805923 892.503235,242.485870
	C893.165588,221.890472 894.445923,201.323135 895.689087,180.760239
	C896.040161,174.952911 897.013733,169.224777 895.677246,163.027786
	C893.082886,158.749756 889.036865,158.570709 885.297485,158.587769
	C867.355896,158.669601 849.414062,158.504868 831.469299,158.745453
	C821.598083,158.877823 811.621460,160.712814 801.400085,158.125946
	C801.044983,157.835526 801.196411,157.780075 801.174866,157.702377
	C800.120117,156.884567 800.093018,155.922302 800.821777,154.580292
	C801.101807,154.305634 801.167236,154.265701 801.159302,154.228668
	C829.306824,154.174927 857.462219,154.132584 885.617615,154.153625
	C897.542664,154.162552 901.549927,158.308014 900.842590,170.325546
	C898.827698,204.562424 896.664612,238.790573 894.416321,273.464783
z"
        />
        <path
          fill="#101417"
          opacity="1.000000"
          stroke="none"
          d="
M813.730652,216.309540
	C819.530945,213.192169 823.852478,215.249435 825.108398,220.985031
	C825.774414,224.026733 827.095642,226.925919 828.137085,229.883789
	C828.419739,230.686569 828.871887,231.472626 829.823547,231.415268
	C830.901733,231.350296 830.813599,230.311615 831.076599,229.560471
	C831.911682,227.175476 832.074646,224.723618 832.025391,222.221222
	C831.992615,220.557144 831.975586,218.480453 834.159241,218.371429
	C836.618042,218.248703 836.815857,220.525101 836.925354,222.294113
	C837.099060,225.100906 836.999573,227.925079 837.001831,230.741898
	C837.004150,233.633530 838.126648,235.816727 841.242920,237.014526
	C841.817688,237.071655 842.041382,237.095001 842.500183,237.357086
	C843.599670,239.014648 843.843445,240.850372 845.779297,241.885712
	C846.751953,242.470474 847.499146,242.755646 848.664612,242.688660
	C850.115417,242.603058 851.231567,242.657196 852.626953,243.153412
	C854.540833,244.195724 855.898621,248.459076 858.231812,243.477844
	C861.051575,243.059219 863.538879,243.781601 866.417236,243.448837
	C870.253296,243.220093 871.385559,240.979980 871.510315,237.913651
	C871.575623,236.308090 871.557434,234.699371 871.658630,232.696320
	C873.123840,219.287659 874.137329,206.239548 875.121643,192.773926
	C875.343323,191.282104 874.979919,190.225937 875.064636,188.766098
	C876.512451,184.492432 879.305603,185.747879 882.241699,186.773285
	C883.081177,187.876099 883.188110,188.817368 882.968262,190.134415
	C882.527649,191.576981 881.134644,192.363251 881.929871,194.120422
	C882.819092,198.914169 880.715515,203.069992 880.531555,207.778885
	C880.274902,209.274490 880.688843,210.259872 881.542114,211.495209
	C882.847473,214.153580 882.952759,216.658249 882.634521,219.134476
	C881.653931,226.764557 882.350220,234.306747 883.403625,241.869431
	C884.162292,247.316025 883.845093,252.774338 883.193542,258.246857
	C882.433533,264.630493 881.626404,271.148499 887.127075,276.637665
	C887.143982,277.049500 887.024536,277.416656 886.930542,277.585297
	C885.309814,279.212799 883.554138,279.074982 881.357422,278.371216
	C879.766602,277.813599 878.621643,277.196869 877.012939,276.685028
	C867.663391,273.249420 858.521973,274.100128 849.353455,275.465881
	C848.127808,275.648468 847.225769,276.323547 845.993408,277.279053
	C842.658081,277.660553 842.298584,279.895050 841.884949,282.191071
	C840.329712,290.822296 837.417969,293.520477 828.339172,294.547607
	C820.653381,293.450470 813.958618,291.404144 811.235046,283.082520
	C817.240784,276.255646 816.721252,267.819366 818.093506,260.062134
	C819.127930,254.214615 818.832214,248.176437 818.730957,241.829132
	C818.600281,240.685760 818.501160,239.926086 818.478149,238.783081
	C818.519897,237.389481 818.347046,236.418823 817.667053,235.180038
	C815.427795,228.920715 813.538147,222.967239 813.730652,216.309540
z"
        />
        <path
          fill="#241312"
          opacity="1.000000"
          stroke="none"
          d="
M879.029785,209.288879
	C878.877930,204.159058 880.533875,199.568237 881.000000,194.405060
	C880.368835,193.005371 880.830017,192.231583 881.750183,191.248077
	C881.995911,189.930435 881.996704,188.865768 881.997192,187.401840
	C880.829895,182.538498 882.751160,177.848801 880.457825,173.319855
	C878.722351,169.892471 876.925903,167.723740 872.869141,167.246475
	C870.231323,166.936142 866.567383,167.661957 865.778320,163.315552
	C868.388672,162.570694 871.476685,164.248734 872.927490,160.371475
	C877.071594,157.161072 881.521851,158.717667 886.229370,159.126694
	C888.047729,160.526810 888.225952,162.651123 890.060425,164.078033
	C891.575134,169.788544 891.643616,175.364929 891.305115,180.721512
	C890.544617,192.757629 889.914124,204.723923 890.323120,216.823044
	C890.777100,230.255341 888.460327,243.659775 888.488403,257.146271
	C888.497070,261.285736 888.710693,265.436310 888.493652,269.573059
	C888.362549,272.070007 890.110779,273.442505 891.537415,275.534393
	C891.708130,275.928467 891.717957,276.327332 891.723755,276.526489
	C891.313171,276.726654 890.896790,276.727631 890.178894,276.647980
	C889.449829,276.520966 889.021667,276.512726 888.304688,276.691986
	C888.016541,276.835358 887.610107,277.065857 887.377075,277.057678
	C877.556885,272.998566 881.091003,264.672729 881.428650,257.869019
	C881.732361,251.751175 881.997437,245.777924 881.261047,239.648911
	C880.505676,233.361328 879.326538,227.021011 880.782471,220.565430
	C881.419250,217.742050 881.505432,214.632843 880.163025,211.373077
	C879.558716,210.606812 879.208679,210.157104 879.029785,209.288879
z"
        />
        <path
          fill="#4C707C"
          opacity="1.000000"
          stroke="none"
          d="
M880.775635,277.009460
	C882.817078,277.073669 884.149292,278.829041 886.464478,277.823944
	C886.870239,277.982361 886.903931,278.210754 886.965881,278.784882
	C885.349426,279.193176 884.696045,280.172913 884.584900,281.692505
	C883.766663,292.878479 882.344421,304.032501 883.101746,315.619385
	C882.703552,316.979218 881.603577,316.825897 880.286438,316.937988
	C877.460449,314.472565 878.223755,311.407379 878.540344,308.497253
	C879.291321,301.592468 879.352356,294.630493 880.413635,287.746155
	C880.732849,285.675323 880.848633,283.443909 879.777954,280.971313
	C879.220764,279.307953 879.579224,278.220581 880.775635,277.009460
z"
        />
        <path
          fill="#101417"
          opacity="1.000000"
          stroke="none"
          d="
M888.333984,276.870178
	C888.402222,276.332397 888.856506,276.031219 889.695435,276.446838
	C889.468750,276.679901 889.060059,276.792419 888.333984,276.870178
z"
        />
        <path
          fill="#46170C"
          opacity="1.000000"
          stroke="none"
          d="
M887.155457,161.275604
	C882.789124,158.854004 878.269165,160.507523 873.452515,159.994507
	C851.960815,160.391098 830.906006,159.016449 809.461548,160.995773
	C806.870117,159.039261 803.456604,160.847061 801.248291,158.147095
	C830.418701,157.576218 859.792603,157.334641 889.165894,157.033829
	C892.617493,156.998474 895.353516,157.972351 897.042969,161.564987
	C895.807129,184.931046 893.618835,207.897369 893.059326,230.958084
	C892.748962,243.749069 891.713745,256.500519 891.082886,269.273804
	C891.032898,270.285370 891.428772,271.234924 891.807068,272.611816
	C892.115356,273.158112 892.174927,273.315521 892.331604,273.706482
	C892.290955,274.390442 892.153259,274.840912 891.861816,275.609924
	C887.754272,275.025177 887.017700,272.466461 886.890869,268.619446
	C886.328674,251.567810 887.634521,234.626572 888.894653,217.655380
	C889.282776,212.428329 888.629211,207.060654 887.897400,201.835114
	C887.376343,198.113739 887.927673,195.071732 889.200684,191.456543
	C890.440247,187.936249 889.909546,183.322067 889.654541,179.391586
	C889.335327,174.470413 889.273438,169.581680 889.151001,164.330414
	C888.271912,163.290344 887.438721,162.659851 887.155457,161.275604
z"
        />
        <path
          fill="#246876"
          opacity="1.000000"
          stroke="none"
          d="
M774.019409,92.123169
	C773.406860,87.460266 774.807312,85.541962 779.421570,85.946434
	C784.033630,86.350723 788.707520,86.035011 793.354065,86.066208
	C795.806274,86.082664 797.219543,86.983833 796.317810,89.749557
	C796.166260,90.214401 796.182861,90.732071 796.100220,91.221703
	C795.402283,95.356514 798.096924,101.950371 794.560547,103.184860
	C789.546631,104.935066 783.434448,105.633476 777.542847,104.240753
	C774.468506,103.514008 773.829041,102.230103 773.979309,99.566475
	C774.109985,97.250244 774.012878,94.921173 774.019409,92.123169
z"
        />
        <path
          fill="#15313E"
          opacity="1.000000"
          stroke="none"
          d="
M710.862305,114.690186
	C710.634460,116.212357 710.385559,117.328011 710.122192,118.792786
	C707.013550,118.857620 703.919373,118.573311 700.417786,118.184204
	C698.769043,115.223457 701.226685,114.705086 702.841309,113.899162
	C705.032166,112.805580 706.135010,111.224815 706.079651,108.800568
	C705.901611,100.990387 707.770264,93.261131 707.105835,85.449341
	C706.866882,82.639687 708.399475,78.579239 703.664734,77.966003
	C699.363892,77.408958 693.968323,80.937614 693.429321,84.832558
	C692.566772,91.065010 692.121704,97.358330 691.787964,103.655418
	C691.545654,108.227280 692.899719,112.966316 690.264160,117.653984
	C689.592041,117.992943 689.181946,118.022003 688.466064,117.996216
	C686.032043,114.850166 685.957825,111.221870 686.684387,107.931755
	C687.668213,103.477043 687.334351,99.067932 687.504517,94.250900
	C687.428284,92.688324 687.805054,91.492363 687.400574,90.264053
	C687.081116,90.927406 687.378113,91.525551 687.414368,92.507721
	C687.735229,101.076653 687.115051,109.186874 685.144104,117.557480
	C682.331787,118.590874 680.108276,117.948471 677.675903,116.429932
	C677.063416,116.392982 676.902710,116.218147 676.689087,115.689560
	C676.674988,114.345070 677.031555,113.475906 677.905457,112.465988
	C679.066772,111.532310 680.685303,111.283569 680.801025,110.142349
	C681.345276,104.773743 682.764343,99.435265 681.759583,93.984833
	C681.394836,92.006088 680.204529,91.662033 678.507507,92.649223
	C675.916321,94.156548 673.543945,95.898376 671.662964,98.749069
	C669.235413,99.809349 668.989197,97.893181 668.131348,96.378220
	C667.803284,94.666321 668.450928,93.680962 669.539673,93.155876
	C677.069641,89.523972 683.150757,83.857330 689.673950,78.828583
	C696.048584,73.914444 703.201660,72.200081 711.529297,72.968231
	C712.024963,73.525986 712.068176,74.015038 712.194824,74.864899
	C711.748901,88.243172 710.167725,101.221947 710.862305,114.690186
z"
        />
        <path
          fill="#688FA1"
          opacity="1.000000"
          stroke="none"
          d="
M677.988586,112.876297
	C677.798401,114.099098 677.913879,115.147270 677.684082,116.410233
	C675.763367,116.800621 674.769470,115.804260 674.232239,114.625145
	C672.132874,110.017746 669.041565,105.842911 668.124146,100.347496
	C667.700928,98.958939 667.244263,97.953033 666.726562,96.627296
	C666.894348,96.237694 667.123169,96.167931 667.707886,96.051941
	C668.837158,96.963737 669.610474,97.921745 670.610291,99.098679
	C671.053040,99.369949 671.269226,99.422302 671.778076,99.639175
	C672.787170,100.375198 673.335938,101.091858 674.025391,102.139877
	C675.544495,105.866516 678.587524,108.620789 677.988586,112.876297
z"
        />
        <path
          fill="#246876"
          opacity="1.000000"
          stroke="none"
          d="
M743.927307,116.472549
	C741.912598,116.903023 739.925659,116.911018 737.514404,116.906830
	C730.901794,113.950005 732.054626,108.932686 732.825989,103.561356
	C733.574707,98.347649 733.255554,92.980591 733.667297,87.363663
	C736.031860,91.550522 740.818298,90.844482 744.706299,92.678223
	C744.881042,100.632805 745.406738,108.385979 743.927307,116.472549
z"
        />
        <path
          fill="#478D9B"
          opacity="1.000000"
          stroke="none"
          d="
M744.988281,92.544861
	C743.023376,92.938934 741.037537,92.844009 739.077454,93.026222
	C735.205017,93.386215 733.528381,91.726227 733.952393,87.463753
	C736.496948,83.355972 740.308716,85.745415 743.796814,84.858261
	C740.721680,88.135925 745.518372,89.601143 744.988281,92.544861
z"
        />
        <path
          fill="#478D9B"
          opacity="1.000000"
          stroke="none"
          d="
M720.670532,100.969543
	C723.301697,101.197311 725.635498,100.478661 729.282410,102.008965
	C724.292725,102.008965 720.756042,102.008965 717.219421,102.008965
	C717.214966,102.344490 717.210571,102.680023 717.206177,103.015549
	C720.737854,103.015549 724.269592,103.015549 727.801331,103.015549
	C727.794373,103.336662 727.787415,103.657784 727.780457,103.978897
	C724.501282,103.978897 721.221558,103.940063 717.943237,103.992653
	C715.782104,104.027313 714.926453,103.000511 715.216492,100.529434
	C716.952576,100.417496 718.621338,100.702896 720.670532,100.969543
z"
        />
        <path
          fill="#246876"
          opacity="1.000000"
          stroke="none"
          d="
M729.850037,83.251114
	C729.957703,82.580307 729.943359,82.235390 730.044556,81.928528
	C730.409424,80.821617 730.870605,81.005638 730.945496,81.991920
	C731.416077,88.188370 726.972717,85.304893 723.613647,86.113327
	C725.786194,84.082954 728.653015,86.267288 729.850037,83.251114
z"
        />
        <path
          fill="#246876"
          opacity="1.000000"
          stroke="none"
          d="
M733.409424,80.339371
	C736.765869,80.058990 740.413574,80.045059 744.518188,80.027298
	C741.300049,81.825333 737.485840,80.934219 733.409424,80.339371
z"
        />
        <path
          fill="#0E505B"
          opacity="1.000000"
          stroke="none"
          d="
M715.737305,83.393311
	C715.365967,83.851883 715.063721,83.874603 714.535522,83.828720
	C714.519409,81.386284 714.726379,78.972725 715.048340,75.217049
	C716.305908,78.580193 715.852905,80.814423 715.737305,83.393311
z"
        />
        <path
          fill="#246876"
          opacity="1.000000"
          stroke="none"
          d="
M720.840332,77.969215
	C720.311890,77.949989 720.129578,77.974373 719.984314,77.912125
	C719.618652,77.755424 718.946106,77.243309 719.710815,77.185577
	C722.324036,76.988289 724.961365,77.109993 727.620911,77.973381
	C725.481689,77.973381 723.342468,77.973381 720.840332,77.969215
z"
        />
        <path
          fill="#246876"
          opacity="1.000000"
          stroke="none"
          d="
M730.347046,89.205421
	C731.220032,89.928444 731.067322,90.854370 730.629333,91.885872
	C729.567993,91.171890 730.126343,90.221138 730.347046,89.205421
z"
        />
        <path
          fill="#246876"
          opacity="1.000000"
          stroke="none"
          d="
M730.483032,77.473709
	C730.519287,77.455284 730.446838,77.492134 730.483032,77.473709
z"
        />
        <path
          fill="#CD7D4C"
          opacity="1.000000"
          stroke="none"
          d="
M770.578247,197.650925
	C783.229797,199.443954 793.075928,205.568146 801.571045,214.758942
	C804.987122,217.004349 807.075073,219.898697 808.627197,223.301010
	C817.093445,241.858673 816.687134,260.590057 809.722412,279.852539
	C809.303894,280.965790 808.965698,281.674774 808.507629,282.742554
	C808.291992,283.331635 808.201660,283.564301 807.940674,284.126892
	C803.611511,290.164307 800.062012,296.336731 794.737549,301.149963
	C792.908386,302.803497 791.372437,304.796326 788.452576,305.420135
	C787.612427,305.249542 787.202942,304.985474 786.650269,304.322754
	C785.764648,300.660095 788.463684,299.128479 790.217041,297.135406
	C791.607178,295.555267 792.896545,293.882446 794.676453,292.173340
	C797.261963,290.156128 798.196655,287.404144 799.960632,284.782471
	C805.924805,276.886475 807.331848,267.729492 808.318176,258.786682
	C809.683105,246.410263 807.798401,234.385223 801.120300,223.384766
	C798.064575,218.351105 794.983459,213.551025 789.345703,210.440216
	C779.558533,204.233582 769.564392,200.549393 758.066406,202.224594
	C754.631836,202.724976 751.129028,202.728470 747.419800,204.041504
	C746.209595,204.333145 745.387695,204.318939 744.164185,204.069000
	C741.607788,202.904648 740.894348,200.997711 740.437744,198.785568
	C739.175171,192.669388 738.170959,186.493195 736.218750,180.134674
	C735.855896,179.300858 735.708801,178.809982 735.580322,177.936172
	C730.007080,161.965469 722.021362,148.364182 705.616943,141.282257
	C704.651306,140.957153 704.048218,140.653671 703.131958,140.250336
	C701.308411,139.346542 699.700500,138.803497 697.737305,138.305023
	C697.213867,138.184845 697.005554,138.129242 696.488770,137.975418
	C694.956665,137.290863 693.618958,137.456436 691.993530,137.253540
	C690.737244,137.098572 689.896973,136.668045 688.622803,136.531586
	C685.352234,136.808960 682.513062,137.744690 679.176575,137.599243
	C677.875610,138.026001 676.890503,138.191147 675.537964,138.445648
	C673.859314,138.402100 672.661255,138.856262 671.056458,139.266754
	C668.320557,139.520798 666.548279,138.649811 665.294373,136.172974
	C664.150391,130.382233 661.149841,125.789970 658.636597,120.612160
	C658.351868,120.105103 658.229187,119.907814 657.952515,119.399979
	C656.935486,117.005005 656.077637,114.917725 654.371582,112.922913
	C653.982544,112.526711 653.824951,112.370644 653.443237,111.970596
	C653.077271,111.558685 652.935425,111.390762 652.590454,110.965378
	C652.260071,110.531860 652.132812,110.355804 651.837769,109.903389
	C650.766846,107.887627 649.289368,106.677391 647.562500,105.274551
	C646.319885,104.277939 645.578369,103.239395 644.493164,102.093811
	C643.596069,101.095009 642.946472,100.292137 641.881226,99.461899
	C641.454163,99.158852 641.281006,99.038933 640.841553,98.739853
	C640.391357,98.443298 640.207520,98.325912 639.740662,98.035385
	C634.166321,95.094109 629.551331,91.078941 623.365723,89.239914
	C622.554993,89.031029 622.099121,88.876572 621.290222,88.612526
	C618.663452,88.033264 616.420715,87.489716 614.556274,85.346283
	C614.096558,84.217087 614.137634,83.447220 614.741760,82.379227
	C621.120850,83.327438 627.115173,85.065376 633.327637,88.069641
	C634.061035,88.570877 634.316406,88.638351 634.917175,88.904022
	C639.453247,92.270218 643.643982,95.438217 648.161926,98.736359
	C649.019165,99.786186 650.103638,100.186905 650.892395,101.259506
	C651.176758,101.388474 651.365906,101.607185 651.483643,101.691086
	C659.361633,105.897202 661.626648,114.235252 666.287598,121.142624
	C667.096252,124.590172 668.941162,127.244606 670.050354,130.538757
	C670.530823,131.592575 670.969543,132.229355 672.291016,132.100067
	C685.613464,128.717926 698.026306,129.340042 709.710999,137.548706
	C730.622070,149.206528 739.358093,168.327316 743.833984,190.129089
	C745.610168,198.781128 745.463745,198.957550 754.334961,197.864319
	C759.636108,197.211029 764.878479,196.504486 770.578247,197.650925
z"
        />
        <path
          fill="#72443E"
          opacity="1.000000"
          stroke="none"
          d="
M709.944641,137.444412
	C705.961853,137.155579 702.601929,134.669037 698.733765,133.466049
	C689.747559,130.671417 680.864502,131.672485 671.580933,133.864319
	C670.470764,133.507858 669.950134,132.896515 669.280151,132.033844
	C667.873962,128.734955 666.601196,125.705170 665.383545,122.326508
	C666.816711,119.494453 664.096130,118.580971 663.445129,116.874550
	C662.912354,115.478127 661.898315,114.044624 663.260376,112.792091
	C664.710632,111.458443 666.330811,112.004326 667.726135,113.176056
	C668.706360,113.999161 669.416321,115.290321 671.373169,115.040947
	C672.725037,115.277832 673.670349,115.703468 674.231750,116.496376
	C676.129883,119.177139 678.155518,121.500244 681.859924,121.488579
	C682.597290,121.486244 683.050354,122.501122 683.123901,123.684975
	C683.239563,125.661903 684.250488,127.591110 685.715088,126.630371
	C689.335388,124.255547 691.056580,126.793343 693.240051,128.668579
	C693.845886,129.188858 694.741760,129.926682 695.350342,129.803375
	C702.154175,128.424576 705.346924,134.053345 709.944641,137.444412
z"
        />
        <path
          fill="#4B4D54"
          opacity="1.000000"
          stroke="none"
          d="
M683.393738,124.027275
	C682.574646,123.550415 682.109436,122.600792 681.736389,122.635704
	C676.648743,123.111755 674.591003,118.959709 671.300415,116.124023
	C668.721252,112.241753 667.193909,108.163002 666.794067,103.392059
	C667.176758,102.154533 667.390991,101.271896 667.881409,100.177048
	C671.997009,104.806740 673.429688,110.944901 677.075439,116.328972
	C677.338867,116.625023 677.241638,116.616684 677.232849,116.592255
	C678.942871,122.425377 683.899597,119.158318 687.706421,120.301590
	C688.027161,123.293686 686.282043,124.100777 683.393738,124.027275
z"
        />
        <path
          fill="#1B292E"
          opacity="1.000000"
          stroke="none"
          d="
M690.452515,118.028160
	C689.521179,107.489357 690.431885,97.058174 691.036621,86.620857
	C691.396118,80.417023 699.989624,74.122787 706.286682,75.223030
	C707.850586,75.496269 707.853333,76.673958 708.146301,77.778130
	C709.800659,84.012146 709.234558,90.383270 708.674866,96.617493
	C708.348511,100.252525 707.812500,103.896027 708.070618,107.509872
	C708.425049,112.472473 706.881165,115.573677 701.590332,116.157845
	C701.077637,116.214447 700.639343,116.944962 700.088379,117.721024
	C696.974609,118.083748 693.938782,118.088097 690.452515,118.028160
z"
        />
        <path
          fill="#478D9B"
          opacity="1.000000"
          stroke="none"
          d="
M688.613892,91.673737
	C688.603271,100.299217 688.332886,108.666389 688.111328,117.487457
	C687.244568,117.630043 686.449768,115.876450 685.113708,117.807175
	C685.232544,109.289940 685.592285,100.671783 685.864685,91.605370
	C686.731079,90.297073 687.551697,90.764801 688.613892,91.673737
z"
        />
        <path
          fill="#3C7D8E"
          opacity="1.000000"
          stroke="none"
          d="
M724.645874,113.981766
	C726.142395,114.140053 727.385010,113.613739 728.666626,115.441101
	C724.135498,116.499176 719.972656,115.939911 714.587708,115.843124
	C718.281860,112.966599 721.408142,114.422737 724.645874,113.981766
z"
        />
        <path
          fill="#478D9B"
          opacity="1.000000"
          stroke="none"
          d="
M727.127808,112.913788
	C723.079163,112.975922 719.428833,112.975922 715.778564,112.975922
	C715.777039,112.528931 715.775574,112.081940 715.774048,111.634949
	C719.716614,110.504234 723.777832,110.856331 727.798889,111.495697
	C728.658569,111.632385 728.102173,112.470421 727.127808,112.913788
z"
        />
        <path
          fill="#3C7D8E"
          opacity="1.000000"
          stroke="none"
          d="
M727.108765,106.025841
	C723.951294,107.972679 720.381165,106.774261 715.651184,106.615601
	C720.046753,103.634010 723.444946,105.826889 727.108765,106.025841
z"
        />
        <path
          fill="#478D9B"
          opacity="1.000000"
          stroke="none"
          d="
M716.523438,108.247780
	C720.658142,108.072754 724.498169,108.072754 728.338196,108.072754
	C728.316101,108.505714 728.294006,108.938667 728.271912,109.371628
	C724.280151,108.448494 720.204773,109.768333 716.523438,108.247780
z"
        />
        <path
          fill="#5D3732"
          opacity="1.000000"
          stroke="none"
          d="
M497.049927,96.253296
	C496.785767,97.180664 496.514160,97.753860 496.008850,98.543991
	C493.879578,100.182411 491.633820,100.091011 489.520569,99.865852
	C483.697388,99.245453 477.868011,99.480751 472.046051,99.526016
	C445.919067,99.729156 419.825409,97.968361 393.687317,98.367104
	C385.395966,98.493591 377.070190,97.456383 368.771637,98.416267
	C354.676453,100.046661 350.078705,108.807495 349.464417,119.718071
	C349.106567,126.073837 350.279785,132.311676 350.395569,139.042557
	C350.154816,140.268463 349.936249,141.064682 349.977570,142.314850
	C349.978790,143.610229 349.761139,144.421829 348.781677,145.281509
	C345.528442,145.178284 345.702332,142.966492 345.559692,141.007065
	C345.271088,137.044098 345.985352,133.037567 344.893250,128.683472
	C343.735168,123.028534 342.918304,117.800270 345.768433,112.374458
	C346.856659,104.559334 352.231689,100.780342 357.891418,97.154358
	C364.697510,92.793892 372.227905,92.918556 379.906158,92.955704
	C409.537689,93.099075 439.171326,93.150322 468.801880,92.929230
	C477.415619,92.864960 485.814453,90.494209 494.268036,88.908501
	C496.273834,88.532257 496.850311,85.701935 499.636261,85.997070
	C501.949158,88.039383 500.252502,89.663933 499.192566,91.153969
	C498.141449,92.631584 496.827698,93.886246 497.049927,96.253296
z"
        />
        <path
          fill="#2E282E"
          opacity="1.000000"
          stroke="none"
          d="
M1079.224609,692.269531
	C1082.045410,692.001831 1085.090698,692.001282 1088.568115,692.001587
	C1086.106934,697.375244 1082.737427,694.229614 1079.224609,692.269531
z"
        />
        <path
          fill="#372624"
          opacity="1.000000"
          stroke="none"
          d="
M968.754639,558.767944
	C969.572510,559.808716 970.418091,559.982788 971.667725,559.998901
	C975.580139,562.035034 975.190186,565.491333 974.958130,568.744568
	C974.647400,573.102722 975.242493,576.699280 980.368652,577.755432
	C981.124817,577.911194 981.951050,578.444824 982.443787,579.049072
	C987.041565,584.686707 993.204651,588.195923 999.697693,591.838257
	C1000.000000,592.443726 1000.000000,592.889099 1000.000000,593.668457
	C997.376587,595.742981 994.763123,594.685486 992.445862,593.558228
	C978.825012,586.931885 965.207703,580.308472 955.507690,567.938477
	C952.939148,564.662903 949.467407,562.234253 945.015991,560.817505
	C939.462769,555.644592 932.684143,554.105225 925.675293,552.106812
	C923.323059,550.928528 920.978210,550.177673 921.876587,546.789490
	C927.071167,544.611633 931.911804,542.099792 937.699829,544.694824
	C939.260071,547.248169 941.659424,548.565735 943.707397,548.562073
	C950.722473,548.549500 956.028931,553.007996 962.636414,554.953125
	C964.255249,557.088623 966.668579,557.368042 968.754639,558.767944
z"
        />
        <path
          fill="#433437"
          opacity="1.000000"
          stroke="none"
          d="
M345.681458,112.175262
	C344.129913,117.031540 345.788330,122.068504 345.982422,127.536835
	C346.075012,136.265884 345.694305,144.578842 347.057922,153.256165
	C347.437408,154.553345 347.483307,155.445877 347.479034,156.788147
	C347.319794,163.083832 347.707031,168.929031 347.631165,175.190033
	C347.452454,176.871689 346.681305,178.053741 347.144226,179.760071
	C347.438049,181.358032 347.425751,182.595306 347.270325,184.198822
	C346.919434,185.736496 346.706787,186.911346 347.268616,188.469604
	C347.571503,190.175537 347.601318,191.507721 347.305664,193.257477
	C345.436310,197.811752 347.580475,202.392883 345.490875,206.864594
	C340.839325,207.790665 341.416473,204.349091 341.239990,201.987946
	C340.639587,193.954834 339.872589,185.901764 341.114929,177.434280
	C342.527283,158.647339 340.919312,140.287292 340.996521,121.457291
	C343.272980,120.606262 343.030884,118.818680 342.973267,117.258347
	C342.895569,115.154984 343.589539,113.512657 345.681458,112.175262
z"
        />
        <path
          fill="#31383F"
          opacity="1.000000"
          stroke="none"
          d="
M340.764404,177.246124
	C344.216705,179.196838 342.721985,182.204666 342.510162,185.084564
	C341.972900,192.389236 342.500580,199.637421 344.591858,206.899826
	C346.923828,208.760910 346.366943,210.815338 345.691040,212.873505
	C344.369354,216.898148 343.332794,221.003143 342.067902,225.475464
	C340.442719,228.064117 338.630371,229.655304 335.367249,229.176544
	C333.458832,227.408875 333.778168,225.533173 334.596771,223.885620
	C336.733887,219.584595 336.139679,214.857498 336.939301,210.355286
	C337.135986,209.247665 337.284027,207.526062 336.664459,206.975128
	C331.964355,202.795883 334.754150,198.891205 336.770538,194.875168
	C336.940216,194.537186 336.600098,193.943222 336.252869,193.228088
	C335.682098,192.646957 335.423523,192.261215 335.187408,191.479706
	C335.406067,188.486954 336.106140,185.826340 334.771240,182.911484
	C334.560333,182.388489 334.506256,182.167358 334.500183,181.608139
	C336.135895,180.501526 335.755798,178.716141 336.746735,177.246674
	C338.036377,178.172333 339.234070,178.208191 340.764404,177.246124
z"
        />
        <path
          fill="#83A7BB"
          opacity="1.000000"
          stroke="none"
          d="
M977.349243,383.808594
	C971.179138,384.000000 965.341858,383.700958 959.546509,384.066467
	C949.560059,384.696259 939.977905,381.708862 930.133667,381.245514
	C926.817810,381.089447 923.460327,380.741699 920.184021,381.081390
	C915.662964,381.550079 913.254944,379.344604 912.047058,375.495361
	C910.182861,369.554749 905.836792,365.457855 901.723145,361.222809
	C899.359070,358.788910 897.550049,356.368164 896.687134,352.906616
	C894.959045,345.973999 890.193176,341.225403 884.013245,337.896698
	C882.672241,337.174377 881.339417,336.682831 880.862915,335.011444
	C879.895813,331.618378 876.986267,329.709686 874.780518,327.259766
	C873.611633,325.961578 871.558899,324.944275 872.386902,322.748932
	C873.218506,320.544098 875.371948,321.095093 877.144043,321.006683
	C877.807373,320.973602 878.473694,321.002106 879.566895,321.004303
	C882.458313,323.962708 885.638794,325.954376 889.257568,326.964050
	C892.772522,327.944763 894.898010,329.864258 894.939148,333.520538
	C894.971191,336.363159 896.143738,338.711029 897.622375,340.909912
	C898.968628,342.911652 900.195374,344.744263 899.173889,347.312836
	C898.804382,348.242096 899.006287,349.818542 899.889160,349.995392
	C906.449036,351.309113 905.962341,360.394073 912.669250,361.443085
	C912.955688,361.487854 913.376404,361.794861 913.429993,362.044983
	C914.917053,368.980438 920.037354,366.640442 924.720642,367.467407
	C929.808350,368.365845 935.104858,371.554230 940.016479,369.730927
	C951.913513,365.314545 964.053528,367.737000 976.531372,367.000702
	C978.803223,366.996124 980.606873,367.029327 982.409485,366.991150
	C984.781738,366.940887 986.602783,367.930939 987.542175,370.080048
	C988.554688,372.396332 987.443054,374.358826 985.694519,375.716888
	C982.700500,378.042297 979.939392,380.551422 977.349243,383.808594
z"
        />
        <path
          fill="#7DA7B8"
          opacity="1.000000"
          stroke="none"
          d="
M976.998291,384.250000
	C977.214905,380.591705 978.991760,378.228485 981.881104,376.549805
	C984.120056,375.249054 987.290405,374.082031 986.576111,371.000458
	C985.875916,367.980103 982.638855,368.062714 979.963867,367.975403
	C979.162537,367.949249 978.369141,367.680115 977.286072,367.261749
	C984.973694,365.478821 991.795654,360.769196 999.669495,358.890686
	C1005.609619,357.473511 1011.515808,357.974548 1017.454224,358.341675
	C1029.797729,359.104797 1042.132446,361.252350 1054.496582,358.835327
	C1057.235962,358.299805 1059.843750,357.091370 1062.867920,356.022278
	C1063.622070,355.970154 1064.020508,356.088562 1064.716309,356.109802
	C1065.013672,356.012604 1065.006348,355.980316 1064.989258,355.981445
	C1066.028076,357.400055 1066.322021,359.249878 1067.804688,360.791138
	C1068.040894,361.022949 1068.023315,360.973450 1067.999023,360.963501
	C1068.247070,361.230377 1068.520264,361.506195 1068.998047,361.989197
	C1069.339233,362.334412 1069.476440,362.471802 1069.821289,362.813293
	C1070.028931,363.017395 1069.983154,363.017426 1069.983643,362.994507
	C1071.104858,364.109039 1071.000244,365.538727 1070.805908,367.351349
	C1068.500610,370.213745 1065.992310,371.559967 1062.519287,370.745972
	C1061.107056,370.415039 1059.556763,370.966980 1058.073853,371.140747
	C1050.663208,372.009186 1043.305542,372.580963 1035.781006,371.864990
	C1025.932617,370.927887 1016.456726,374.431244 1006.755676,375.564514
	C1001.047852,376.231262 995.711060,379.149109 992.033936,384.721252
	C987.024902,385.956940 982.408447,387.645691 977.302307,385.338776
	C976.999756,384.833344 976.999451,384.666656 976.998291,384.250000
z"
        />
        <path
          fill="#151117"
          opacity="1.000000"
          stroke="none"
          d="
M841.794434,319.693817
	C842.904846,320.118530 843.646240,320.506226 844.693848,320.980225
	C846.504761,322.983490 844.766113,324.330719 843.955322,326.183899
	C841.256836,329.696167 837.136597,330.773712 834.049377,333.736633
	C829.649414,333.559937 827.717590,335.815247 826.844055,339.837585
	C826.533875,340.362488 826.381775,340.559509 825.936890,340.999207
	C822.899536,342.670319 820.372375,341.590515 818.153381,340.182861
	C811.619995,336.038177 804.292786,338.145660 797.353394,337.413910
	C794.170349,337.078186 793.310608,341.640533 789.810791,342.396423
	C786.214844,343.218079 782.956299,343.032684 780.085876,341.731567
	C768.086731,336.292480 755.199280,336.370209 742.505554,335.579773
	C726.247498,334.567383 709.939392,335.152039 693.661377,334.789398
	C686.896484,334.638702 680.966431,336.814758 674.532837,339.241943
	C670.920959,340.646057 668.732178,343.404694 665.373047,345.143616
	C661.701233,345.207214 660.593933,343.253265 660.568481,340.379730
	C660.530273,336.064117 660.372314,331.746643 660.821472,326.990845
	C661.367737,325.677063 662.020752,325.042755 663.216064,324.323792
	C664.966125,323.443024 666.430420,323.518890 668.300781,323.509399
	C670.616516,323.458557 672.547791,323.761322 674.784119,322.875122
	C676.040771,322.626770 676.891541,322.639374 678.165894,322.842102
	C682.186096,324.255920 685.813904,323.008362 689.425964,323.039154
	C702.056641,323.146881 714.688049,323.105560 727.319336,323.084747
	C730.712219,323.079163 733.587219,322.348114 735.530273,318.714478
	C740.880066,316.643036 746.069946,317.880920 751.593628,317.622894
	C757.142151,320.057983 762.283997,320.728699 767.713013,317.521423
	C768.574097,316.730682 769.258179,316.413849 770.418213,316.362427
	C774.537170,318.921967 778.355164,320.082428 782.950684,318.361023
	C785.453125,317.423706 787.076538,316.147736 788.543152,313.937073
	C791.655701,309.245453 795.966309,306.092834 802.103149,305.832001
	C802.566833,310.436584 800.950073,314.642853 801.868591,319.188202
	C802.400024,321.818054 799.460938,322.312866 797.125366,321.950012
	C795.159241,321.644623 793.210449,320.982483 791.242737,321.829346
	C789.438293,322.606018 787.194153,323.119141 787.333374,325.701263
	C787.449158,327.848480 789.168579,328.700806 790.995239,329.273285
	C794.973145,330.519958 799.036743,331.934570 803.160156,331.853699
	C812.216736,331.676056 821.425781,332.190063 829.116455,325.458954
	C832.479370,322.515717 836.238159,319.259003 841.794434,319.693817
z"
        />
        <path
          fill="#0A0D13"
          opacity="1.000000"
          stroke="none"
          d="
M842.196655,319.311188
	C835.757935,322.480377 830.134521,326.465118 824.533081,330.550201
	C822.743469,331.855347 820.278381,332.666351 818.048706,332.861176
	C808.512024,333.694397 798.955872,334.197388 789.667480,330.939911
	C787.109192,330.042725 785.269043,328.569885 785.063477,325.734283
	C784.860168,322.930054 786.671509,321.794250 789.032959,320.764862
	C791.912964,319.509521 794.399658,318.875824 797.469116,320.513550
	C799.823364,321.769653 802.018005,320.094421 800.554810,317.752838
	C797.818726,313.374084 800.417969,310.245117 801.946899,306.354309
	C802.407898,302.715027 805.204529,303.231781 807.244995,302.914917
	C812.739441,302.061676 813.649292,300.942993 812.464417,295.555939
	C812.111023,293.949371 811.565002,292.383362 812.500671,290.464203
	C815.731995,289.000427 817.652771,291.341492 819.896484,292.511078
	C825.162903,295.256256 830.693481,296.698425 836.650085,296.320862
	C837.311951,296.278900 836.502869,296.381378 837.080383,296.086426
	C837.454346,295.895447 837.271240,295.563904 836.466064,295.556580
	C834.900513,295.473541 833.658264,295.440948 832.006226,295.613953
	C830.114014,295.634399 828.743530,295.293762 827.361511,293.906158
	C829.649414,292.994202 832.089233,292.227692 834.556152,291.323639
	C838.563354,289.855072 839.788940,287.288910 840.039490,283.509460
	C840.168884,281.557770 840.654114,279.620117 841.088257,277.700928
	C841.510498,275.834290 842.737610,275.942230 844.415833,276.828461
	C844.927490,277.175018 845.138489,277.348755 845.472656,277.912842
	C845.928833,282.635162 845.731384,286.941162 844.906372,291.620850
	C844.400146,296.313660 844.006775,300.610657 843.558716,305.316284
	C843.815857,307.210052 843.636719,308.613708 842.583130,310.256012
	C842.290771,313.367645 842.260315,316.129578 842.196655,319.311188
z"
        />
        <path
          fill="#829FB1"
          opacity="1.000000"
          stroke="none"
          d="
M976.685425,385.271973
	C981.700867,384.666626 986.401672,384.333221 991.550049,383.999939
	C994.992432,387.494385 995.085571,392.491119 992.018555,397.966919
	C990.822937,400.101532 989.623535,402.232269 988.243042,404.700439
	C985.249512,407.751282 982.075928,407.197601 979.301453,405.498108
	C974.344971,402.462036 968.865845,401.700073 963.325623,400.982727
	C959.121094,400.438324 955.082703,401.463928 951.051086,402.436829
	C948.622986,403.022766 946.271179,403.952271 944.592041,400.868805
	C943.753967,399.330017 942.029846,399.327637 940.214539,399.307922
	C935.148560,399.253021 930.037231,399.409668 925.578552,395.937103
	C923.438232,394.270294 920.814575,392.809570 917.386169,392.996643
	C915.892517,392.268433 914.770447,391.575226 913.696533,390.814117
	C912.346680,389.857544 910.733826,388.972565 911.642151,386.862061
	C912.487915,384.897003 914.023193,384.863007 915.811951,385.417053
	C917.871948,386.055084 919.959167,386.609833 922.048218,387.148010
	C923.325684,387.477142 924.911133,388.322357 925.873840,387.880432
	C931.589111,385.256989 937.185791,388.089874 942.769043,387.856354
	C953.978149,387.387512 965.111084,385.526062 976.685425,385.271973
z"
        />
        <path
          fill="#9FA2B2"
          opacity="1.000000"
          stroke="none"
          d="
M891.781555,369.772766
	C891.984070,372.227753 892.681274,374.158875 894.706909,375.825562
	C896.139038,377.392670 895.758606,379.146667 896.146362,380.716003
	C897.775574,387.308258 901.726868,391.992188 908.005371,394.623474
	C909.657654,395.315979 911.570129,395.546448 912.083069,397.981018
	C911.089783,399.155396 910.060974,399.460480 908.505249,399.462219
	C902.438843,398.338623 896.572632,398.548645 890.652222,396.614624
	C887.937195,396.315125 885.718018,395.733917 883.370544,394.392090
	C882.381226,393.685455 881.750793,393.048950 880.468506,393.033386
	C879.429810,393.009277 878.760498,392.849426 877.875000,392.259766
	C877.513245,389.403564 876.708252,387.783722 874.214905,385.954956
	C865.186218,379.332855 855.023560,376.750153 844.246765,375.210144
	C840.845703,374.724091 836.183472,374.915894 835.525024,369.319397
	C837.766418,366.976379 840.025208,367.992981 842.187561,368.777069
	C846.607605,370.379822 851.138733,371.149597 855.832520,371.521576
	C863.928162,372.163147 871.548950,374.290863 877.636658,380.165955
	C879.975586,382.423279 882.948425,383.526550 886.698608,384.744598
	C880.103516,375.417450 877.321167,364.829468 871.897034,355.521271
	C870.376404,352.911621 868.589966,350.544434 866.371948,348.480743
	C864.304626,346.557190 862.893066,344.381775 864.739807,341.207214
	C874.693481,344.114532 881.182007,350.928436 886.168518,359.415863
	C887.256714,361.268036 888.009277,363.317352 888.965088,365.632843
	C890.099548,367.007843 890.598999,368.444427 891.781555,369.772766
z"
        />
        <path
          fill="#B1A5B1"
          opacity="1.000000"
          stroke="none"
          d="
M865.018311,340.648041
	C864.585938,344.049683 865.922180,345.903717 868.260864,348.073395
	C874.483643,353.846375 877.473816,361.899689 880.389038,369.544189
	C882.491943,375.058441 886.077515,379.499420 888.529053,384.667877
	C888.936523,385.526947 890.044617,386.095917 889.070496,387.118713
	C888.085327,388.153229 887.215759,387.458557 886.043457,387.063599
	C878.555664,384.540680 873.803955,377.538544 866.118958,375.212097
	C863.896973,374.539429 861.676331,373.855988 859.460327,373.734955
	C851.643616,373.308044 844.194214,371.321289 836.394043,369.015533
	C835.387024,369.547333 834.686340,369.806793 833.512817,369.639221
	C830.592773,364.184052 824.978394,361.512604 821.652100,356.338318
	C821.007202,355.038300 821.041992,354.049072 821.633423,352.736145
	C823.486267,350.464935 825.136047,350.290466 826.647400,352.545929
	C831.011963,359.059448 837.043274,362.170319 844.675842,364.471344
	C850.600281,366.257355 856.593201,366.867737 862.462769,368.376770
	C864.308167,368.851227 866.240540,369.058746 867.459045,367.291351
	C868.568115,365.682587 867.437195,364.115295 866.748169,362.621002
	C863.811768,356.253204 859.974854,350.443451 855.521484,344.565186
	C853.767334,340.599579 853.913757,337.431732 858.238281,335.230103
	C861.491943,334.600922 862.719421,336.962738 864.725098,338.725525
	C865.006470,339.438629 865.010559,339.878479 865.018311,340.648041
z"
        />
        <path
          fill="#28424F"
          opacity="1.000000"
          stroke="none"
          d="
M842.370300,310.930573
	C842.252441,308.966644 842.183594,307.327576 842.246704,305.218719
	C843.517456,302.009094 843.326416,299.093079 843.520813,295.878418
	C845.598206,292.778137 846.824768,296.155426 848.830444,296.233032
	C851.035034,296.763214 852.590271,296.046753 854.350464,294.801086
	C860.677612,291.004944 864.610535,285.546387 868.178772,279.275116
	C870.336060,278.200928 872.271423,278.589111 874.603027,278.621887
	C876.528870,278.832123 877.851501,279.423126 879.339478,280.662964
	C881.506775,286.154083 880.101990,291.160767 878.659119,296.492126
	C877.375793,297.676208 876.442139,297.580566 875.469543,296.593201
	C871.947754,293.017700 871.933228,293.032043 870.405518,298.076538
	C868.501892,300.432312 866.803711,302.609375 863.351013,301.584045
	C862.770081,301.373138 862.637329,301.506439 862.713928,301.906464
	C862.762085,302.157867 862.811340,302.285492 862.997559,302.567322
	C863.514648,304.184662 863.513672,305.524231 863.034180,307.190399
	C861.510559,309.841675 857.744873,310.466614 858.109131,314.449707
	C857.929138,315.248322 857.718628,315.663300 857.144165,316.240417
	C853.232178,316.047516 849.624023,315.478394 845.998413,315.327881
	C843.129395,315.208801 842.248474,313.811096 842.370300,310.930573
z"
        />
        <path
          fill="#3D292C"
          opacity="1.000000"
          stroke="none"
          d="
M832.812683,333.451019
	C835.043518,329.305115 839.606079,328.990326 842.668518,326.102478
	C845.406555,321.769165 849.750793,323.425232 853.606995,322.423737
	C856.015442,322.483185 858.095276,322.788422 860.185059,322.990417
	C861.792603,323.145874 863.918518,322.814178 864.573547,324.409058
	C865.289734,326.153107 862.775696,326.831207 862.051758,328.614166
	C862.250427,331.244110 860.920227,332.626923 858.807983,333.968079
	C850.552124,334.772583 842.779419,336.165955 834.538696,336.937012
	C833.037109,336.100952 832.441772,335.160889 832.812683,333.451019
z"
        />
        <path
          fill="#213642"
          opacity="1.000000"
          stroke="none"
          d="
M857.216675,316.590820
	C856.890564,315.924438 856.933228,315.412872 856.989746,314.517944
	C858.368530,312.039215 861.039612,311.075562 861.958496,308.184967
	C862.028259,306.169861 862.009949,304.487183 861.985352,302.383514
	C861.602417,301.539185 861.506714,301.059875 862.000732,300.283936
	C865.465332,302.258453 866.979919,298.794067 869.634888,298.017212
	C874.283569,296.259949 875.576904,296.698425 875.368164,300.757568
	C875.076599,306.428040 877.210144,312.396179 873.339722,318.024048
	C867.852966,317.779816 862.719177,317.262726 857.216675,316.590820
z"
        />
        <path
          fill="#929EAC"
          opacity="1.000000"
          stroke="none"
          d="
M912.001343,398.346039
	C911.170227,397.583435 910.273438,397.192017 909.453064,396.678528
	C907.334534,395.352417 904.317261,397.009583 902.558289,394.643097
	C898.615723,389.338745 893.232361,384.631226 895.005371,376.443420
	C897.580017,379.646942 898.693176,384.285828 902.526672,387.089355
	C902.767761,387.265686 902.778503,387.790680 903.022217,387.924835
	C907.384338,390.325989 911.260437,393.637177 916.584106,394.987122
	C919.502747,396.547119 923.146851,396.694366 923.915161,400.849579
	C922.180603,400.939606 920.423645,400.663666 918.336304,400.202515
	C917.352966,399.287781 916.583557,398.971313 915.322205,399.497253
	C914.859802,399.746033 914.694946,399.841278 914.274048,400.062775
	C913.829285,400.282440 913.640686,400.375824 913.147949,400.380096
	C912.325562,399.869629 912.058777,399.313446 912.001343,398.346039
z"
        />
        <path
          fill="#12232B"
          opacity="1.000000"
          stroke="none"
          d="
M876.593994,297.292633
	C878.434387,292.089020 879.032104,287.074554 879.032349,281.534058
	C879.080688,280.716522 879.263550,280.424164 879.837280,280.182922
	C883.166992,280.444214 882.076172,282.876373 881.991699,284.360809
	C881.390137,294.927917 880.600464,305.484253 879.922607,316.489929
	C879.854370,317.081696 879.734253,317.227600 879.434570,317.593018
	C878.063721,318.010803 876.872498,318.209106 875.313599,318.459412
	C874.451172,311.485260 874.972900,304.522583 876.593994,297.292633
z"
        />
        <path
          fill="#385661"
          opacity="1.000000"
          stroke="none"
          d="
M876.686523,296.697449
	C877.175232,303.932526 875.669739,310.719482 874.952576,318.047058
	C874.386169,318.554718 873.826416,318.598083 873.126709,318.469177
	C874.741333,312.278503 873.763611,306.105042 874.045898,300.002075
	C874.230652,296.006470 871.098022,300.203949 870.205688,298.234314
	C867.679993,294.253479 869.954102,292.022400 873.055847,291.313995
	C877.471008,290.305603 875.142212,294.771729 876.686523,296.697449
z"
        />
        <path
          fill="#9FA2B2"
          opacity="1.000000"
          stroke="none"
          d="
M858.752808,333.318024
	C859.098999,331.319611 860.444031,330.419495 861.646851,329.090881
	C863.217041,329.146484 864.524475,330.143555 863.657532,331.131592
	C860.908264,334.264740 864.028442,336.024719 864.959717,338.654083
	C862.563477,339.025269 861.607605,336.234772 858.937012,335.948792
	C858.167908,335.151917 858.167419,334.397064 858.752808,333.318024
z"
        />
        <path
          fill="#15191F"
          opacity="1.000000"
          stroke="none"
          d="
M853.533081,322.121368
	C853.543518,324.230621 851.930542,324.136841 850.538757,323.976501
	C847.970093,323.680603 845.883423,324.775391 843.392944,325.965515
	C843.645386,324.593201 844.274475,323.165619 844.951904,321.402283
	C847.793335,320.688385 850.435669,321.596985 853.533081,322.121368
z"
        />
        <path
          fill="#929EAC"
          opacity="1.000000"
          stroke="none"
          d="
M891.962646,369.650024
	C890.021606,369.778046 889.556335,368.227692 889.052124,366.333862
	C890.976807,366.225067 891.447327,367.767242 891.962646,369.650024
z"
        />
        <path
          fill="#D0C3BE"
          opacity="1.000000"
          stroke="none"
          d="
M1058.057861,346.310181
	C1058.852173,346.135254 1059.618286,346.398834 1059.988403,347.642334
	C1060.049683,347.948761 1060.002319,347.996735 1059.977539,348.020569
	C1059.078003,347.901093 1058.395386,347.514771 1058.057861,346.310181
z"
        />
        <path
          fill="#D0C3BE"
          opacity="1.000000"
          stroke="none"
          d="
M1058.009521,345.992676
	C1057.901001,345.864471 1057.783203,345.743408 1057.665405,345.622345
	C1057.741943,345.668518 1057.818481,345.714691 1057.940674,345.889618
	C1057.986450,346.018372 1058.000244,345.999786 1058.009521,345.992676
z"
        />
        <path
          fill="#D0C3BE"
          opacity="1.000000"
          stroke="none"
          d="
M1055.495239,344.474762
	C1055.522705,344.464691 1055.467773,344.484833 1055.495239,344.474762
z"
        />
        <path
          fill="#A8A6A9"
          opacity="1.000000"
          stroke="none"
          d="
M1064.187378,353.106323
	C1064.217285,352.915009 1064.509766,352.816589 1064.894897,352.884857
	C1064.808350,353.100769 1064.629150,353.150024 1064.187378,353.106323
z"
        />
        <path
          fill="#C1ACB2"
          opacity="1.000000"
          stroke="none"
          d="
M1041.583252,316.764832
	C1041.337769,319.304382 1038.381592,318.591614 1037.169922,320.675507
	C1032.799561,320.082306 1030.111084,324.791290 1025.532471,323.946594
	C1025.006836,322.243286 1023.719238,321.188202 1022.827209,319.542755
	C1027.622437,318.306946 1032.778931,318.586639 1037.462402,316.352814
	C1038.711060,315.757233 1040.022095,316.000824 1041.583252,316.764832
z"
        />
        <path
          fill="#F4E1D8"
          opacity="1.000000"
          stroke="none"
          d="
M1038.998413,311.001282
	C1038.996826,311.002563 1039.359375,311.226288 1039.359375,311.226288
	C1039.359375,311.226288 1039.101318,311.198608 1039.051758,311.097534
	C1039.002075,310.996460 1039.000000,311.000000 1038.998413,311.001282
z"
        />
        <path
          fill="#496A76"
          opacity="1.000000"
          stroke="none"
          d="
M1005.674805,278.238770
	C1006.356689,277.871246 1006.827393,277.810486 1007.640625,277.850647
	C1009.317261,278.641754 1008.981628,280.190887 1009.916748,281.403259
	C1011.578918,282.526764 1010.741821,283.951385 1010.951294,285.538086
	C1008.869751,285.487915 1007.154968,287.349487 1004.725098,286.752197
	C1003.752625,286.174713 1003.410339,285.547943 1003.260437,284.428589
	C1003.952820,282.200592 1004.961609,280.474304 1005.674805,278.238770
z"
        />
        <path
          fill="#5E6B74"
          opacity="1.000000"
          stroke="none"
          d="
M1004.230103,283.605286
	C1004.123474,284.544067 1004.250488,285.059387 1004.555664,285.775177
	C1006.098877,287.105652 1007.934204,286.665222 1009.708984,287.680115
	C1007.862000,289.464661 1005.582214,289.908844 1003.741150,287.805298
	C1001.678711,285.448669 1001.390625,282.732056 1003.719788,279.819763
	C1005.056396,280.667389 1004.927551,281.903870 1004.230103,283.605286
z"
        />
        <path
          fill="#243A45"
          opacity="1.000000"
          stroke="none"
          d="
M1010.207458,287.877106
	C1008.305481,287.883148 1006.480469,288.275696 1004.974854,286.313873
	C1005.581116,285.666718 1006.778870,286.357300 1007.287354,285.083099
	C1008.874390,281.105865 1009.633118,283.679077 1010.723877,285.706543
	C1010.779480,286.541809 1010.599976,287.157898 1010.207458,287.877106
z"
        />
        <path
          fill="#243A45"
          opacity="1.000000"
          stroke="none"
          d="
M1004.327454,283.862061
	C1004.007507,282.846466 1004.018616,281.664185 1004.023315,280.028809
	C1004.124023,278.774078 1004.584595,278.277496 1005.614807,277.985168
	C1006.050354,279.975281 1006.312561,282.039429 1004.327454,283.862061
z"
        />
        <path
          fill="#243A45"
          opacity="1.000000"
          stroke="none"
          d="
M1010.133423,281.462463
	C1007.601257,282.050873 1008.069641,280.160614 1007.967651,278.337708
	C1007.983215,277.951569 1008.000671,277.989197 1007.984619,277.979553
	C1008.734863,277.467651 1009.175659,277.984802 1009.833557,278.753448
	C1010.048950,279.010254 1009.991638,279.008423 1009.998047,278.980469
	C1010.376160,279.376068 1010.519409,279.866486 1010.214966,280.756958
	C1009.995117,281.090302 1010.096313,281.335236 1010.133423,281.462463
z"
        />
        <path
          fill="#496A76"
          opacity="1.000000"
          stroke="none"
          d="
M1010.084229,278.659729
	C1009.534729,278.738647 1009.020508,278.467010 1008.237427,278.082642
	C1008.983215,276.195770 1009.611145,276.873413 1010.084229,278.659729
z"
        />
        <path
          fill="#496A76"
          opacity="1.000000"
          stroke="none"
          d="
M1010.290649,280.995056
	C1010.013428,280.614777 1010.031738,280.139221 1010.027222,279.308105
	C1010.980652,279.367859 1011.088318,280.042725 1010.290649,280.995056
z"
        />
        <path
          fill="#3C5965"
          opacity="1.000000"
          stroke="none"
          d="
M119.005638,605.359985
	C121.458939,605.365662 122.802826,606.774475 122.997116,609.629761
	C121.165031,609.043945 118.946228,608.448486 119.005638,605.359985
z"
        />
        <path
          fill="#35535F"
          opacity="1.000000"
          stroke="none"
          d="
M163.735397,647.764038
	C166.952698,650.652832 170.338470,653.033325 170.519638,657.781677
	C169.226105,658.970642 167.984085,658.591064 166.404297,658.419189
	C164.773346,658.212830 163.744507,657.640259 162.841492,656.282227
	C162.648087,655.749573 162.608414,655.527405 162.611176,654.977783
	C162.923538,653.857971 163.166626,653.065186 162.547058,651.971497
	C162.325760,651.474792 162.248886,651.267639 162.100159,650.731018
	C161.955978,649.701538 162.060684,649.029724 162.564392,648.110352
	C162.997849,647.750000 163.216324,647.666626 163.735397,647.764038
z"
        />
        <path
          fill="#43626E"
          opacity="1.000000"
          stroke="none"
          d="
M144.713196,632.616516
	C146.120789,632.295654 147.221985,632.611816 148.667572,632.960205
	C150.244705,634.139038 151.477463,635.285645 152.856232,636.715088
	C153.548019,637.593018 153.820511,638.284973 153.625122,639.467163
	C153.030060,640.523499 152.335800,640.805664 151.157349,640.880005
	C150.533356,640.786865 150.291412,640.713135 149.729095,640.441589
	C147.917084,637.788025 144.889679,636.431152 144.713196,632.616516
z"
        />
        <path
          fill="#43626E"
          opacity="1.000000"
          stroke="none"
          d="
M163.159790,647.785645
	C162.972977,648.794373 162.984238,649.531677 162.993439,650.637634
	C158.600189,651.295593 155.770203,648.581238 155.513306,643.268311
	C156.207947,642.583313 156.734344,642.461121 157.664200,642.758606
	C158.324615,643.103394 158.578445,643.299011 158.903839,643.769653
	C159.590912,644.630798 160.258545,645.141113 161.380707,645.539795
	C162.609070,645.936279 163.147354,646.581421 163.159790,647.785645
z"
        />
        <path
          fill="#4C707C"
          opacity="1.000000"
          stroke="none"
          d="
M165.779755,657.294189
	C167.203674,657.256897 168.396713,657.531006 169.954926,657.927795
	C171.002075,658.359497 171.684052,658.668457 172.666931,659.008850
	C173.550659,659.651733 173.839218,660.376404 173.709686,661.618774
	C173.071518,663.048645 172.196045,663.632812 170.677521,663.923218
	C170.008972,663.906189 169.744415,663.861633 169.112564,663.657593
	C167.301132,661.739319 165.101746,660.390259 165.779755,657.294189
z"
        />
        <path
          fill="#43626E"
          opacity="1.000000"
          stroke="none"
          d="
M179.667664,661.717163
	C180.018005,661.961853 180.482407,662.051147 180.715790,662.089111
	C181.524948,662.621216 182.046646,663.157959 182.315948,664.267700
	C182.163071,664.880493 182.027725,665.105835 181.580093,665.580444
	C179.997757,666.491577 178.635330,666.412781 176.857178,666.250732
	C174.918945,665.614868 174.111282,664.580933 174.416138,662.496216
	C174.926239,661.620056 175.391312,661.297058 176.385315,661.075867
	C177.672318,661.040222 178.531570,661.047424 179.667664,661.717163
z"
        />
        <path
          fill="#35535F"
          opacity="1.000000"
          stroke="none"
          d="
M157.981812,642.622925
	C157.566910,642.989441 157.113571,643.005615 156.320282,643.030396
	C154.059631,643.450928 153.592484,642.010132 153.004425,640.356995
	C152.992859,639.251282 152.990585,638.500122 152.995270,637.373413
	C156.109390,637.388306 157.061707,639.797363 157.981812,642.622925
z"
        />
        <path
          fill="#35535F"
          opacity="1.000000"
          stroke="none"
          d="
M182.325867,664.451050
	C181.746490,663.650940 181.399841,663.181580 181.001175,662.419678
	C182.102341,662.339905 183.255524,662.552673 184.757370,662.700134
	C186.147690,662.692688 187.189331,662.750549 188.623016,662.794800
	C189.155106,663.013672 189.295197,663.246216 189.449951,663.887146
	C188.719269,666.240234 187.070526,666.299133 184.968185,666.186707
	C183.762573,665.871643 183.088150,665.435425 182.325867,664.451050
z"
        />
        <path
          fill="#35535F"
          opacity="1.000000"
          stroke="none"
          d="
M177.221069,661.687988
	C176.528748,662.021057 176.055267,662.044556 175.227539,662.098694
	C174.474075,662.419189 174.029678,662.511841 173.249924,662.236755
	C172.954681,661.289551 172.955612,660.539124 172.962189,659.414551
	C174.137497,659.197937 175.307159,659.355530 176.729568,659.762878
	C177.372238,660.388306 177.524780,660.843567 177.221069,661.687988
z"
        />
        <path
          fill="#273E4A"
          opacity="1.000000"
          stroke="none"
          d="
M177.379150,662.002441
	C176.997787,661.556396 176.993347,661.115173 176.985596,660.343323
	C178.121689,660.044678 179.336594,659.906738 179.945114,661.629517
	C179.264023,661.976990 178.510025,661.992126 177.379150,662.002441
z"
        />
        <path
          fill="#273E4A"
          opacity="1.000000"
          stroke="none"
          d="
M163.230438,648.030701
	C162.470230,647.467712 161.978790,646.878296 161.228241,646.153076
	C160.855637,645.834473 160.742142,645.651611 160.838745,645.223511
	C162.622681,644.955200 163.937714,645.270264 164.014618,647.588074
	C163.857040,647.964661 163.678101,647.984497 163.230438,648.030701
z"
        />
        <path
          fill="#35535F"
          opacity="1.000000"
          stroke="none"
          d="
M160.856293,644.754883
	C161.035507,645.151428 161.022186,645.324524 160.988983,645.757507
	C160.081741,645.900330 159.337418,645.593628 159.064865,644.294556
	C159.571915,644.163147 160.117859,644.347290 160.856293,644.754883
z"
        />
        <path
          fill="#43626E"
          opacity="1.000000"
          stroke="none"
          d="
M141.174103,631.370483
	C141.059464,628.659119 138.474960,627.772583 138.049286,625.335571
	C141.383987,625.919678 143.285843,628.460388 144.966248,631.640686
	C145.019592,631.979492 145.000107,632.000000 144.991119,632.009766
	C143.686935,633.015442 142.469971,632.958923 141.174103,631.370483
z"
        />
        <path
          fill="#95F8FC"
          opacity="1.000000"
          stroke="none"
          d="
M197.446686,599.249878
	C195.975693,599.036743 194.896255,599.012939 193.412689,598.964417
	C193.002090,593.392639 196.252808,588.954041 198.176804,584.079102
	C198.942383,582.139282 200.944794,581.928223 202.919235,582.398132
	C205.022263,582.898621 207.377747,583.070496 209.133362,584.137207
	C215.512131,588.012878 221.969025,585.934998 228.530319,585.162903
	C233.669479,584.558044 234.849274,580.358276 237.422760,576.769287
	C238.465668,576.083618 239.263428,575.918335 240.510223,575.939331
	C247.058044,578.456665 253.057343,581.231750 258.736877,584.644409
	C259.959930,585.379333 261.551727,586.227966 260.959839,588.057861
	C260.463409,589.592651 258.922760,589.582336 257.590057,589.478088
	C254.908890,589.268188 252.477921,590.966980 249.415054,590.253662
	C241.222824,591.512512 233.385284,592.959534 225.263489,594.657593
	C216.011154,596.891602 206.958984,598.370728 197.446686,599.249878
z"
        />
        <path
          fill="#85C1D1"
          opacity="1.000000"
          stroke="none"
          d="
M249.342926,590.326477
	C252.758942,589.524170 256.457520,588.982910 261.243011,588.282532
	C254.528778,583.292053 247.492798,581.259521 241.187866,577.278198
	C240.656448,572.186523 243.516174,569.113220 247.444061,570.929871
	C264.717102,578.918884 279.064880,588.562805 276.630859,610.154419
	C276.349152,612.653503 275.648438,615.430969 277.753845,618.074707
	C276.894867,620.086731 278.456085,620.644714 279.274536,621.835266
	C277.477905,622.575928 275.768250,622.970825 273.697906,623.355164
	C269.562042,620.808289 268.964539,616.863831 269.047394,612.847534
	C269.143738,608.176697 268.634399,603.592407 267.945343,599.003845
	C266.810059,591.443542 263.041229,588.793640 255.467712,590.403992
	C253.495728,590.823364 251.601944,591.357849 249.342926,590.326477
z"
        />
        <path
          fill="#76D4DF"
          opacity="1.000000"
          stroke="none"
          d="
M238.262589,576.783508
	C235.955734,587.523376 227.151245,586.571472 219.713974,587.965210
	C214.055115,589.025696 209.448593,585.703369 204.488068,584.122009
	C200.403351,582.819885 198.768295,583.980835 197.571777,587.709656
	C196.412140,591.323547 194.733612,594.770874 193.145782,598.615540
	C193.325714,599.465881 193.642853,599.992065 193.959991,600.518250
	C193.404572,600.620972 192.756393,600.960022 192.309998,600.784851
	C190.528488,600.085754 191.240097,598.806152 191.866394,597.302002
	C192.888519,593.847839 193.451736,590.672668 195.357117,587.806030
	C197.456451,584.647583 197.937775,581.389954 193.220352,579.671509
	C192.395386,579.370911 191.826233,578.368103 191.328979,577.052734
	C192.465988,575.818481 193.509094,575.734070 194.971405,575.911682
	C199.696747,577.256592 203.663879,579.436768 207.620224,581.507507
	C214.767746,585.248474 221.840088,583.711487 229.000519,581.996887
	C230.979355,581.523071 231.847412,579.867310 232.823196,578.298523
	C233.941177,576.501160 234.819733,574.321106 237.897430,574.742798
	C238.489563,575.414612 238.566544,575.916687 238.262589,576.783508
z"
        />
        <path
          fill="#76D4DF"
          opacity="1.000000"
          stroke="none"
          d="
M197.113266,599.357727
	C205.992020,597.206482 214.898315,595.151123 224.533020,594.930664
	C221.941803,598.162048 217.607620,597.155457 213.908478,598.055847
	C208.472565,599.379089 202.981155,601.682861 197.113266,599.357727
z"
        />
        <path
          fill="#060B13"
          opacity="1.000000"
          stroke="none"
          d="
M301.350037,644.199402
	C296.116577,642.827576 291.224762,643.791809 286.490265,644.510742
	C280.457703,645.426941 274.286163,645.988831 268.427368,647.292603
	C258.838898,649.426270 248.921890,649.544678 239.514832,652.573364
	C235.625961,653.825439 231.453766,653.464783 227.364838,654.398315
	C217.464523,656.658386 207.260727,657.615906 197.158295,658.938293
	C195.412170,659.166931 193.523483,658.783020 191.768921,658.382812
	C186.204926,657.113342 185.978836,656.513794 189.641693,651.896606
	C190.922363,650.282227 191.001724,648.596558 191.001801,646.365356
	C206.668488,644.429810 222.079956,641.228149 237.625595,638.918030
	C252.202011,636.752014 266.704254,634.091003 281.251862,631.725891
	C297.421326,629.096985 313.616333,626.623779 329.774994,623.931274
	C333.967438,623.232727 338.065582,621.968323 342.614838,620.934937
	C343.503510,621.260376 343.867035,621.709595 344.221863,622.676086
	C344.873627,632.261108 343.623962,634.056152 334.964783,637.427307
	C328.105438,640.097778 320.949005,641.421021 313.691559,641.327942
	C309.235565,641.270752 305.475098,642.541382 301.350037,644.199402
z"
        />
        <path
          fill="#623B37"
          opacity="1.000000"
          stroke="none"
          d="
M343.366699,623.261658
	C343.118469,622.496216 343.082489,622.043396 343.034546,621.248291
	C343.510071,620.342834 343.997528,619.779724 344.739838,619.091553
	C349.269226,618.628357 351.620148,621.315430 353.722717,624.869385
	C353.185303,628.279114 352.674164,631.315002 352.016266,634.712280
	C344.610809,641.888855 336.346161,646.546814 326.118530,646.904968
	C324.632965,646.956970 323.140350,646.848145 321.416138,647.775146
	C317.184631,648.987854 314.288635,646.830505 311.027649,644.694275
	C310.660522,644.146362 310.561646,643.899658 310.457764,643.258179
	C311.275818,640.445496 313.359314,641.068237 315.087891,641.020508
	C322.758240,640.808899 330.197540,639.765442 337.345520,636.628357
	C344.398743,633.532837 345.872955,630.908386 343.366699,623.261658
z"
        />
        <path
          fill="#31383F"
          opacity="1.000000"
          stroke="none"
          d="
M344.144348,615.767944
	C339.777802,616.674988 336.261230,619.695557 331.394592,618.991943
	C329.811707,616.961853 331.300690,616.330139 332.725647,615.789551
	C333.790924,615.385498 335.068359,615.412109 335.642212,613.757812
	C336.290100,611.733948 336.853790,610.031189 337.516357,608.002380
	C338.824463,606.260010 338.580658,604.154358 340.274689,602.677734
	C342.094849,602.256226 343.044678,603.219177 344.241913,604.338379
	C344.591034,604.711365 344.722748,604.863098 345.210876,605.467590
	C345.681824,606.064758 345.796326,606.209045 346.274292,606.748352
	C346.901703,607.380249 347.175140,607.605652 347.684875,607.969177
	C348.064941,608.220886 348.216736,608.324890 348.589722,608.600098
	C348.951630,608.896423 349.092377,609.021545 349.433990,609.348755
	C349.761719,609.693542 349.888611,609.836243 350.189148,610.206116
	C350.790741,611.110962 351.433105,611.498108 352.428528,611.897522
	C352.856079,612.139709 353.020599,612.247620 353.410950,612.544495
	C354.196686,613.259888 354.562286,613.962708 355.430969,614.572021
	C355.843811,614.806824 356.006958,614.905212 356.413696,615.154785
	C356.984894,615.508850 357.310699,615.714661 357.887573,616.074951
	C358.305939,616.334656 358.471344,616.442932 358.876312,616.725830
	C359.559906,617.311035 360.094635,617.554382 360.936340,617.860962
	C361.557098,618.178833 361.883240,618.402649 362.392090,618.864197
	C362.964111,619.497375 363.268311,619.969543 363.759644,620.631104
	C364.092957,620.932373 364.224304,621.054626 364.555054,621.358154
	C364.892181,621.657837 365.029999,621.776367 365.604797,622.243774
	C366.502991,622.950806 366.941589,623.335571 367.597534,623.946289
	C367.953796,624.333374 368.088013,624.499939 368.393280,624.943237
	C370.793457,627.670349 372.704010,630.407288 375.450623,632.388550
	C376.653503,633.256165 377.677643,634.453552 376.850861,636.465515
	C375.442963,637.464172 374.154938,637.442139 372.476440,637.447510
	C367.765717,636.519531 364.216003,634.471741 361.863770,630.149536
	C361.603455,627.955872 361.259399,626.207275 359.745636,624.924011
	C355.727081,621.517395 351.578857,618.268372 347.552063,614.871155
	C346.296753,613.812073 344.970673,613.078430 344.144348,615.767944
z"
        />
        <path
          fill="#496A76"
          opacity="1.000000"
          stroke="none"
          d="
M336.455688,613.391479
	C338.741211,615.572815 336.716736,615.891663 335.541870,616.625854
	C334.186646,617.472656 332.102448,616.361572 331.069946,618.630615
	C321.069122,620.455688 311.140381,621.788879 301.273468,623.482300
	C297.483337,624.132751 293.716309,623.764832 289.497498,624.139282
	C289.032257,621.725342 291.309631,622.301025 292.766327,621.236816
	C295.857147,617.154175 300.415222,617.928406 304.674988,616.723877
	C311.250885,615.326050 317.448639,614.163513 324.151672,613.668701
	C328.496735,615.659668 332.208954,612.294861 336.455688,613.391479
z"
        />
        <path
          fill="#15191F"
          opacity="1.000000"
          stroke="none"
          d="
M344.109192,616.095093
	C344.296478,614.422607 341.038757,613.383118 343.122101,611.700378
	C345.158112,610.055908 346.420410,612.052063 347.757935,613.126770
	C352.001953,616.536865 356.227661,619.970764 360.411163,623.454590
	C361.952850,624.738403 363.345947,626.192688 362.972015,628.886353
	C361.907990,629.553284 361.115753,629.012939 360.102417,628.145508
	C355.349579,624.006409 349.802002,621.571716 345.206055,617.242432
	C344.776031,616.713318 344.558197,616.458435 344.109192,616.095093
z"
        />
        <path
          fill="#3B2C30"
          opacity="1.000000"
          stroke="none"
          d="
M344.992188,617.301636
	C350.255249,620.090088 355.779297,622.783142 360.743530,626.889954
	C357.534882,630.618347 355.554657,627.729919 353.191284,625.335510
	C350.928101,622.543823 348.340057,620.797302 345.213989,619.225830
	C344.993256,618.522644 344.991852,618.078857 344.992188,617.301636
z"
        />
        <path
          fill="#2C4653"
          opacity="1.000000"
          stroke="none"
          d="
M305.248901,617.741699
	C301.264679,618.992432 297.517731,619.923523 293.388428,620.921265
	C292.655304,617.583008 291.853577,614.153625 292.038177,610.778137
	C292.591888,600.653015 290.575470,590.797974 289.367340,580.858215
	C289.218842,579.636414 289.142517,578.580811 289.955139,577.203125
	C295.937256,576.620850 298.294434,581.428406 301.808868,585.110229
	C301.451385,594.406006 304.036835,602.954590 305.148651,611.681152
	C305.392822,613.597595 306.514343,615.436584 305.248901,617.741699
z"
        />
        <path
          fill="#0F1B23"
          opacity="1.000000"
          stroke="none"
          d="
M301.199890,585.297241
	C297.585876,582.354126 294.190918,579.680664 290.463928,576.942139
	C289.613708,576.295166 289.095581,575.713257 288.329224,574.974731
	C286.895294,573.614685 287.459869,572.431885 288.385223,570.964111
	C291.389557,569.131287 293.302399,570.976624 295.346802,572.308289
	C296.863770,573.296448 297.958466,574.831848 299.967224,575.766357
	C305.693054,579.722961 310.826935,583.873779 316.467957,587.939026
	C322.311707,593.061157 328.261627,597.485413 334.026550,602.138428
	C335.799408,603.569336 337.929413,604.715759 338.215057,607.637329
	C339.479828,610.028870 337.962036,611.453552 336.909729,613.287781
	C333.184875,615.109497 329.485992,616.198059 325.158142,615.344727
	C324.670959,614.822388 324.572906,614.439331 324.777618,614.223267
	C329.175201,609.584106 329.205872,609.558289 324.283661,604.987183
	C318.228027,599.363525 311.048889,595.156799 304.867767,589.684631
	C303.505005,588.478149 302.052551,587.372559 301.199890,585.297241
z"
        />
        <path
          fill="#557E8B"
          opacity="1.000000"
          stroke="none"
          d="
M286.994873,598.703735
	C287.000153,601.040527 287.006897,603.076172 287.016479,605.547974
	C287.388794,606.380676 287.532166,606.846741 287.235657,607.699463
	C287.014771,611.325012 286.989563,614.633545 287.006744,617.941711
	C287.018463,620.198303 286.368164,621.869690 283.335876,621.951172
	C282.791992,621.701355 282.653931,621.460510 282.423767,620.814087
	C282.504974,614.666321 282.866638,608.921936 281.988525,602.807129
	C281.596924,601.647217 281.485870,600.849365 281.502686,599.641113
	C281.706848,590.183899 278.931000,582.364197 273.414307,574.959473
	C270.259064,570.724426 267.169220,566.017029 265.422180,560.382812
	C265.600769,559.167297 266.090240,558.586914 267.199524,558.081665
	C274.335815,559.704651 278.579010,564.681519 282.883362,570.110718
	C283.412537,572.052429 283.783875,573.660156 283.977783,575.653748
	C282.679626,577.766357 283.914490,579.526245 284.007599,581.639404
	C284.732513,582.614136 284.982788,583.427979 284.996094,584.671204
	C285.620697,588.395508 287.136108,591.616760 286.973389,595.578613
	C286.994965,596.796570 286.999451,597.599548 286.994873,598.703735
z"
        />
        <path
          fill="#15313E"
          opacity="1.000000"
          stroke="none"
          d="
M282.316284,570.987854
	C278.313568,566.257996 273.541595,562.850098 268.795837,558.967468
	C267.848236,558.846985 267.514252,558.516235 267.567932,557.478638
	C267.968811,556.630676 268.380402,556.297913 269.270020,555.983276
	C273.940918,556.076172 276.968170,559.117737 280.932739,560.928345
	C282.757416,562.481995 284.669373,563.465576 285.518921,565.814941
	C285.421844,566.498535 285.221191,566.794983 284.970245,567.124390
	C285.092133,567.159607 285.071777,566.987610 285.288574,566.665649
	C285.710388,566.435364 285.895081,566.354919 286.398285,566.252686
	C289.018372,566.789917 289.641693,568.387695 289.199646,570.848633
	C288.722107,572.174316 288.424500,573.184875 288.103943,574.506775
	C287.899963,574.869751 287.718933,574.921387 287.257019,575.001221
	C284.661407,574.763245 283.562439,573.019226 282.316284,570.987854
z"
        />
        <path
          fill="#7DA7B8"
          opacity="1.000000"
          stroke="none"
          d="
M282.634583,598.736755
	C282.987244,599.762268 282.995544,600.509583 283.008484,601.630493
	C281.986481,608.103455 283.454498,614.220459 282.987610,620.648926
	C283.092499,620.974792 283.049835,621.465210 282.989929,621.703735
	C281.930023,622.026245 280.930023,622.110291 279.558777,622.187744
	C279.443848,620.863220 275.537201,621.805115 277.846497,619.230835
	C277.055603,618.013184 276.465637,616.872131 276.523804,615.465088
	C276.747009,610.066528 276.802155,604.657104 277.848022,598.950134
	C278.621643,598.248535 279.160980,598.007141 280.198120,597.862305
	C281.241974,597.914795 281.790955,598.120239 282.634583,598.736755
z"
        />
        <path
          fill="#4C707C"
          opacity="1.000000"
          stroke="none"
          d="
M282.133362,571.093933
	C284.163239,571.361328 285.480316,572.835144 286.836182,574.729858
	C286.395447,575.818604 285.552094,575.972534 284.320984,575.996887
	C281.281189,575.388184 282.162048,573.244629 282.133362,571.093933
z"
        />
        <path
          fill="#4C707C"
          opacity="1.000000"
          stroke="none"
          d="
M287.223816,598.806396
	C286.065674,598.273987 285.852905,597.415039 286.749237,596.195618
	C287.931488,596.717712 288.156494,597.577209 287.223816,598.806396
z"
        />
        <path
          fill="#4C707C"
          opacity="1.000000"
          stroke="none"
          d="
M285.233704,584.787964
	C284.189056,584.425293 284.017975,583.578918 284.017029,582.326416
	C285.554443,582.263855 286.319580,582.966980 285.233704,584.787964
z"
        />
        <path
          fill="#4C707C"
          opacity="1.000000"
          stroke="none"
          d="
M287.301392,607.963135
	C287.016174,607.565063 287.010376,607.113403 287.011963,606.322998
	C287.639099,606.101990 288.381195,606.184692 288.413940,606.956665
	C288.426849,607.261963 287.875336,607.591248 287.301392,607.963135
z"
        />
        <path
          fill="#3B2C30"
          opacity="1.000000"
          stroke="none"
          d="
M343.095428,623.385010
	C348.188995,627.126221 348.206146,631.308350 343.137268,634.915955
	C335.912842,640.057556 327.854645,642.370239 318.986023,642.044373
	C316.532806,641.954224 314.064667,642.270081 311.277771,642.661133
	C310.802094,643.199768 310.595520,643.425110 310.148926,643.697388
	C309.682983,643.970581 309.591278,644.219360 309.699799,644.813171
	C309.654388,645.482422 309.511902,645.849976 309.076904,646.435669
	C307.573486,647.543396 306.180603,647.575684 304.394012,647.192871
	C303.877563,647.080994 303.707336,647.071106 303.282135,647.040955
	C301.038422,648.214050 299.057556,648.597961 297.058899,646.503479
	C297.845490,644.721252 299.510986,645.284607 300.776794,644.211365
	C306.972351,637.075745 315.412689,640.981262 322.571838,639.186951
	C328.011017,637.823669 333.435455,636.389465 338.612915,634.392395
	C343.182617,632.629700 343.371948,628.121338 343.095428,623.385010
z"
        />
        <path
          fill="#3E505A"
          opacity="1.000000"
          stroke="none"
          d="
M298.703308,648.771362
	C299.207306,651.835876 295.956512,651.494324 295.071350,653.671143
	C286.868042,654.495728 278.736053,654.989014 270.292297,655.763550
	C265.879364,655.467651 261.939209,656.853638 257.473389,657.041626
	C256.218018,657.806458 255.293182,657.714478 254.011917,657.192200
	C252.857269,656.478577 252.178146,655.808350 252.018341,654.464478
	C252.127121,654.119446 252.567871,654.067139 252.787750,654.037231
	C254.215637,652.770447 255.782410,653.061096 257.626587,653.007568
	C259.761322,653.117615 261.583801,653.552612 263.491302,652.185730
	C264.267242,652.050781 264.716248,652.024902 265.500488,652.023682
	C267.102539,652.088379 268.448212,652.770935 269.820587,651.364380
	C271.120361,651.126648 272.157166,651.086426 273.583221,651.053406
	C274.755646,651.815308 275.599670,652.125183 276.789856,651.208130
	C276.996765,650.998352 276.976624,650.985596 276.973938,650.973999
	C278.892365,649.441895 281.316498,650.288269 283.818481,649.602051
	C288.088196,650.769958 291.744080,649.863831 295.722412,648.346191
	C296.901337,648.027039 297.679108,648.111023 298.703308,648.771362
z"
        />
        <path
          fill="#2A363C"
          opacity="1.000000"
          stroke="none"
          d="
M285.397888,650.964111
	C282.620026,651.024658 280.242065,651.022522 277.417664,650.991394
	C278.945770,647.809265 282.622467,648.249878 285.494965,647.306885
	C288.830170,646.212097 292.584503,646.394104 296.589172,646.032959
	C298.535187,647.546570 300.464020,646.813538 302.623779,647.015015
	C302.721100,648.998718 301.208282,648.981384 299.355652,649.038086
	C298.237305,649.048584 297.490479,649.037598 296.369873,649.031067
	C292.824402,650.902466 289.033905,649.386719 285.397888,650.964111
z"
        />
        <path
          fill="#15313E"
          opacity="1.000000"
          stroke="none"
          d="
M190.840027,662.332031
	C198.718658,660.972351 206.441833,660.004883 214.603363,659.059692
	C215.800735,659.908997 216.149048,660.861694 215.797913,662.354370
	C215.507584,662.924805 215.350876,663.128540 214.867447,663.529663
	C214.066254,663.876221 213.581314,663.958435 212.732300,663.911377
	C211.317566,663.578735 210.295853,663.032043 208.793030,663.372925
	C205.831528,663.589905 203.285324,664.374878 200.261566,664.425476
	C197.162872,664.703979 194.433746,665.071411 191.465042,663.689453
	C190.975571,663.205505 190.830063,662.964844 190.840027,662.332031
z"
        />
        <path
          fill="#15313E"
          opacity="1.000000"
          stroke="none"
          d="
M216.868134,659.433716
	C220.572998,657.568848 224.302444,656.960327 228.586075,657.028076
	C229.386917,657.467468 229.542969,657.959839 229.330109,658.889099
	C228.374527,660.055725 227.371323,660.355652 225.893707,660.460205
	C224.470047,660.471741 223.454529,660.580322 222.114319,661.096191
	C220.412842,661.440430 219.107681,661.425598 217.505798,660.689209
	C217.024200,660.251038 216.877502,660.029480 216.868134,659.433716
z"
        />
        <path
          fill="#273E4A"
          opacity="1.000000"
          stroke="none"
          d="
M229.000687,659.430664
	C229.005997,658.579468 229.008621,658.139832 229.015015,657.371277
	C231.353043,657.005676 233.687302,656.968994 236.458496,656.930847
	C237.361847,656.588257 237.882004,656.452942 238.827423,656.711304
	C239.492691,657.397461 239.600998,657.932129 239.350021,658.886169
	C238.534821,659.882812 237.757462,660.142273 236.473816,660.108276
	C233.733551,659.121033 231.292206,661.900024 229.000687,659.430664
z"
        />
        <path
          fill="#15313E"
          opacity="1.000000"
          stroke="none"
          d="
M259.349365,654.042725
	C257.285004,654.071472 255.573380,654.058838 253.434692,654.026794
	C254.776199,650.311340 258.689301,652.651672 261.618500,651.105957
	C262.414215,653.020020 261.235199,653.653198 259.349365,654.042725
z"
        />
        <path
          fill="#273E4A"
          opacity="1.000000"
          stroke="none"
          d="
M251.735474,654.073975
	C252.666351,654.609192 253.205566,655.098938 253.902802,655.818237
	C253.560837,657.105835 252.636185,657.639282 251.204971,658.137024
	C249.400345,658.378967 248.064270,658.258850 246.783005,656.845520
	C246.572830,656.217102 246.560684,655.945740 246.752472,655.321655
	C246.956375,654.968933 246.983047,654.954651 246.970795,654.945312
	C247.392441,654.556091 247.840576,654.200073 248.706360,654.044189
	C249.776779,654.026672 250.560287,654.027649 251.735474,654.073975
z"
        />
        <path
          fill="#2A363C"
          opacity="1.000000"
          stroke="none"
          d="
M239.355347,657.273865
	C238.585770,657.029236 238.103470,657.007141 237.258301,656.957275
	C238.559860,653.496521 241.719193,655.577637 244.580200,654.966919
	C245.154999,655.166077 245.297348,655.361511 245.449554,655.920288
	C243.934509,658.673767 241.793167,658.107178 239.355347,657.273865
z"
        />
        <path
          fill="#273E4A"
          opacity="1.000000"
          stroke="none"
          d="
M259.235779,654.308472
	C259.898621,653.268555 260.800629,652.453003 261.861572,651.379211
	C262.020538,651.120972 262.498688,651.103088 262.737915,651.103516
	C263.569641,651.408508 264.106567,651.767395 264.138397,652.746216
	C262.719788,654.264771 261.531830,655.701660 259.235779,654.308472
z"
        />
        <path
          fill="#273E4A"
          opacity="1.000000"
          stroke="none"
          d="
M269.700806,651.108276
	C270.023376,653.632812 268.948151,654.464294 266.324890,653.162231
	C265.522461,652.298950 265.949005,651.841064 266.737000,651.222168
	C267.775391,651.039368 268.546722,651.044556 269.700806,651.108276
z"
        />
        <path
          fill="#273E4A"
          opacity="1.000000"
          stroke="none"
          d="
M216.677826,659.050720
	C217.007629,659.242554 217.009796,659.425476 217.022644,659.882568
	C217.573807,660.725464 217.797943,661.399536 217.570358,662.523438
	C217.251144,663.034241 217.068161,663.200256 216.523499,663.445801
	C215.921524,663.469543 215.681198,663.413757 215.195801,663.080750
	C214.972885,661.809509 214.995026,660.815491 215.029449,659.451782
	C215.477859,659.068665 215.914017,659.055298 216.677826,659.050720
z"
        />
        <path
          fill="#273E4A"
          opacity="1.000000"
          stroke="none"
          d="
M276.618958,650.975098
	C276.458008,652.998535 275.609589,653.708252 274.039734,651.376221
	C274.728699,651.024353 275.484894,650.988098 276.618958,650.975098
z"
        />
        <path
          fill="#15313E"
          opacity="1.000000"
          stroke="none"
          d="
M266.601562,651.017944
	C266.744446,651.524109 266.484802,652.013916 266.118591,652.760742
	C265.559692,653.021179 265.107300,653.024658 264.315369,653.030029
	C263.725403,652.549255 263.475067,652.066589 263.100952,651.343872
	C264.051117,651.069763 265.125061,651.035706 266.601562,651.017944
z"
        />
        <path
          fill="#273E4A"
          opacity="1.000000"
          stroke="none"
          d="
M190.715607,661.970093
	C190.999268,662.146667 191.003021,662.353455 191.009018,662.870483
	C191.024689,664.131409 190.433624,664.418823 189.298706,664.261230
	C188.988434,663.853882 188.992706,663.640137 189.006012,663.103760
	C189.488602,662.520874 189.962158,662.260620 190.715607,661.970093
z"
        />
        <path
          fill="#15313E"
          opacity="1.000000"
          stroke="none"
          d="
M248.818176,653.820129
	C248.512497,654.325256 248.031708,654.624756 247.254730,654.930115
	C247.180298,654.062439 247.286728,653.041870 248.818176,653.820129
z"
        />
        <path
          fill="#15313E"
          opacity="1.000000"
          stroke="none"
          d="
M245.314575,656.267761
	C245.000641,655.879211 245.002426,655.697754 245.008423,655.243408
	C245.445084,654.969421 245.877548,654.968140 246.633179,654.967896
	C246.966919,655.146790 246.977478,655.324646 246.996796,655.769592
	C246.618454,656.406555 246.159958,656.552429 245.314575,656.267761
z"
        />
        <path
          fill="#95D1E2"
          opacity="1.000000"
          stroke="none"
          d="
M139.798370,586.663757
	C137.540192,585.888428 136.279480,584.570740 135.878952,582.439758
	C140.384750,582.686523 141.351593,583.680298 139.798370,586.663757
z"
        />
        <path
          fill="#95D1E2"
          opacity="1.000000"
          stroke="none"
          d="
M145.359436,596.995605
	C144.834900,596.443909 144.654999,595.903015 144.255524,595.173340
	C144.283310,594.492249 144.530716,594.000000 144.889679,593.255066
	C148.101959,592.667480 148.177933,595.489075 149.575790,597.064697
	C148.090057,598.098022 146.932785,596.747681 145.359436,596.995605
z"
        />
        <path
          fill="#95D1E2"
          opacity="1.000000"
          stroke="none"
          d="
M144.997269,592.645813
	C144.257629,592.994690 143.517105,592.991760 142.407471,592.976318
	C142.285751,591.556946 142.355545,589.741882 144.197052,589.786621
	C145.250168,589.812195 144.883972,591.374756 144.997269,592.645813
z"
        />
        <path
          fill="#76D4DF"
          opacity="1.000000"
          stroke="none"
          d="
M188.125198,575.370789
	C188.493927,575.041626 188.916840,575.042114 189.657654,575.042480
	C190.691010,575.213989 191.090698,575.691956 191.140778,576.684814
	C191.007843,576.989380 191.000183,576.999512 190.994568,577.005127
	C189.886734,576.929260 188.662888,577.108521 188.125198,575.370789
z"
        />
        <path
          fill="#95D1E2"
          opacity="1.000000"
          stroke="none"
          d="
M187.044342,595.472534
	C187.693039,595.933655 187.650955,596.462708 187.028061,596.452576
	C186.403397,596.442444 186.383530,595.912170 187.044342,595.472534
z"
        />
        <path
          fill="#C1ACB2"
          opacity="1.000000"
          stroke="none"
          d="
M273.183533,277.662628
	C272.611786,281.091736 271.052582,282.958618 267.684113,283.312469
	C260.989410,284.015869 254.506699,287.953369 247.288147,284.290283
	C247.076553,283.328705 247.309372,282.582764 248.075745,282.601379
	C256.192291,282.798492 263.304596,278.909363 271.107880,276.798218
	C271.712738,276.387268 272.132050,276.354340 272.835754,276.616394
	C273.189545,276.985718 273.268829,277.185547 273.183533,277.662628
z"
        />
        <path
          fill="#0E505B"
          opacity="1.000000"
          stroke="none"
          d="
M273.147217,276.776550
	C272.511414,276.995758 272.167969,276.864380 271.563965,276.639526
	C271.162323,275.892609 271.021240,275.239136 270.810944,274.256958
	C270.741699,273.928253 270.952911,273.999329 271.060974,274.026611
	C273.703918,273.142609 273.633087,274.725525 273.147217,276.776550
z"
        />
        <path
          fill="#1B2E3A"
          opacity="1.000000"
          stroke="none"
          d="
M272.903259,277.328857
	C272.825165,276.045837 272.985077,274.894592 271.479919,274.148407
	C271.077515,273.262543 270.986023,272.471161 270.768341,271.302551
	C270.642181,270.925354 270.831879,270.949677 270.922974,270.978760
	C272.140991,266.650330 270.602631,262.297455 270.990570,257.937866
	C271.148895,256.158234 270.748505,254.334274 271.702637,252.284103
	C278.028870,254.298309 282.849701,257.736664 284.749115,264.434265
	C285.569916,267.328522 285.821411,270.591766 288.649536,272.911804
	C292.001343,277.448364 290.646057,282.941040 292.481171,288.097290
	C292.658539,291.093994 292.318176,293.655212 292.645813,296.267517
	C293.138275,300.194153 291.398010,301.155365 287.030273,299.345947
	C286.283875,298.525024 285.894348,297.908997 285.272095,297.148254
	C282.645050,295.330750 281.777466,292.601624 280.184021,289.957336
	C279.076080,287.631226 276.868652,286.218536 277.126648,283.295837
	C275.980652,281.402161 274.783112,279.847076 273.302277,278.115936
	C273.018951,277.939911 272.951691,277.530548 272.903259,277.328857
z"
        />
        <path
          fill="#457483"
          opacity="1.000000"
          stroke="none"
          d="
M212.905106,165.796600
	C216.906311,168.521851 220.738098,170.998795 221.058502,176.555359
	C220.883743,177.303406 220.569672,177.603210 219.864319,177.917694
	C218.840103,178.303680 217.910583,178.069824 217.298233,179.188614
	C217.094498,179.664062 217.010330,179.855865 216.803436,180.341492
	C216.036194,181.782486 216.160370,183.392120 214.499252,184.158752
	C215.713623,184.274124 216.547012,183.373199 217.928345,183.457855
	C218.749359,183.657684 219.135941,183.938675 219.613983,184.650452
	C219.844406,185.489151 219.809189,185.981705 219.540131,186.816895
	C219.136246,187.452469 218.872086,187.739731 218.325378,188.115479
	C217.521103,188.424469 216.904861,188.259079 216.237122,188.827667
	C209.767227,190.035019 204.180832,183.953720 202.062317,176.722107
	C200.827408,172.506729 200.392014,167.890747 205.727264,165.143921
	C206.511002,165.552292 206.999420,165.689804 207.779480,165.274872
	C208.453033,164.508469 208.984695,164.222397 209.848358,164.911087
	C210.744415,165.856277 211.647949,165.837585 212.905106,165.796600
z"
        />
        <path
          fill="#0E505B"
          opacity="1.000000"
          stroke="none"
          d="
M271.985382,251.509796
	C271.434570,258.119232 273.257751,264.324432 271.265442,270.722656
	C269.993500,250.449066 268.880737,229.894226 268.010864,209.329102
	C267.762878,203.466110 271.664124,199.199051 277.893097,198.090851
	C284.116608,196.983643 290.426086,196.713196 296.693237,197.797409
	C298.603271,198.127838 300.030548,198.320343 300.141052,195.415237
	C301.552704,195.031418 302.957245,195.233261 302.799622,197.098846
	C302.621704,199.204666 300.862183,199.795135 299.213501,199.847397
	C294.072876,200.010300 288.925232,199.958893 283.780243,199.975830
	C274.356628,200.006851 271.222015,203.403015 270.911560,212.804352
	C270.489502,225.584717 272.534241,238.274704 271.985382,251.509796
z"
        />
        <path
          fill="#1E4552"
          opacity="1.000000"
          stroke="none"
          d="
M291.256317,289.344360
	C290.472443,283.937561 288.047852,279.142975 289.001740,273.442932
	C287.466492,260.890564 286.079193,248.771439 284.342010,236.702667
	C283.509216,230.917252 285.063171,226.431427 289.579712,224.988800
	C291.153534,226.521927 290.664795,228.247375 290.505524,230.367188
	C290.602722,240.154907 291.713440,249.438126 292.553009,259.179688
	C293.954773,267.941315 293.825439,276.400757 294.978088,285.176758
	C295.375946,286.770996 295.382080,287.933807 294.733704,289.458435
	C293.463867,290.779419 292.456024,290.747925 291.256317,289.344360
z"
        />
        <path
          fill="#B2E7F4"
          opacity="1.000000"
          stroke="none"
          d="
M289.241852,230.334717
	C288.557709,228.500488 288.749268,227.023727 289.799255,225.324066
	C289.966705,224.982300 289.995422,225.000244 289.990540,224.985260
	C290.406189,224.555069 290.865082,224.208862 291.768677,224.167023
	C303.321869,224.756454 314.583435,225.339874 325.847717,225.866180
	C328.933533,226.010361 331.385956,226.921036 332.408447,230.589188
	C332.855652,231.683304 332.988647,232.448166 332.555450,233.588715
	C331.678558,234.508331 330.891174,234.529099 329.674866,234.287323
	C329.026917,234.081299 328.775726,233.974091 328.177002,233.653366
	C325.108398,231.803665 322.104523,232.621536 319.222504,232.502274
	C316.138397,232.374664 313.029633,232.638016 309.605652,231.587982
	C303.212219,231.314407 297.237457,231.714752 290.836060,231.183350
	C290.135406,230.906693 289.872040,230.755676 289.241852,230.334717
z"
        />
        <path
          fill="#557E8B"
          opacity="1.000000"
          stroke="none"
          d="
M334.249146,181.194824
	C334.548126,181.270050 334.641052,181.599014 334.693787,181.761597
	C334.954437,182.020935 335.162415,182.117706 335.364929,182.561127
	C334.776093,184.012344 333.387268,184.796249 334.101440,186.669510
	C334.620056,188.188889 335.658325,189.083923 335.992096,190.674438
	C336.043976,191.432022 336.044983,191.876984 336.027466,192.654968
	C335.514038,193.029800 335.019135,193.071594 334.524261,193.113419
	C334.524261,193.113419 325.938965,198.440536 325.938965,198.440536
	C324.533264,198.754639 317.402252,194.058365 317.405518,192.424286
	C317.411621,189.359039 316.005676,186.627441 315.942810,183.519730
	C315.877838,180.305893 317.598724,177.954514 319.700897,178.091537
	C322.816071,178.294571 326.007233,175.452194 329.205658,178.488983
	C328.364349,184.653076 328.364349,184.653076 323.489563,183.876312
	C327.316895,186.764755 329.125061,184.097534 330.676727,180.080933
	C331.990784,180.355682 332.970459,180.737656 334.249146,181.194824
z"
        />
        <path
          fill="#314D58"
          opacity="1.000000"
          stroke="none"
          d="
M332.189819,234.363739
	C332.358521,233.213638 332.367645,232.443115 332.479614,231.300690
	C333.878754,231.356018 335.184174,231.799500 334.994751,229.291351
	C336.762817,228.047226 338.535858,227.089355 340.637299,226.061554
	C343.667633,223.345642 344.179230,218.272308 349.723541,218.553436
	C351.545776,220.759979 350.311615,222.504761 349.351593,224.202011
	C347.887299,226.790695 346.201202,229.259644 345.277161,232.524063
	C344.953491,233.599731 344.644073,234.282227 344.195770,235.314529
	C342.410828,238.810028 342.506012,242.681824 339.660614,245.719162
	C334.602753,248.796890 334.090759,244.490234 332.339691,241.554474
	C331.465118,239.056244 331.215515,236.908310 332.189819,234.363739
z"
        />
        <path
          fill="#33616C"
          opacity="1.000000"
          stroke="none"
          d="
M330.861115,179.766708
	C330.683197,180.903824 330.200684,181.810196 330.053650,182.768051
	C329.344788,187.385773 328.244659,188.132690 324.286072,185.743362
	C323.127472,185.044037 323.194885,185.301712 322.754059,186.031570
	C322.073151,187.159042 321.098541,186.995743 320.196716,186.413910
	C319.462067,185.939911 319.084351,185.160309 319.323059,184.260284
	C319.679749,182.915451 320.854126,182.406204 321.991760,182.436996
	C323.950653,182.489990 325.872070,183.356186 327.903503,182.743713
	C327.523193,181.473785 324.780182,182.508469 325.480286,180.364136
	C326.054718,178.604752 327.838379,179.786530 329.181885,179.007935
	C329.847961,179.042725 330.279572,179.301208 330.861115,179.766708
z"
        />
        <path
          fill="#5C8997"
          opacity="1.000000"
          stroke="none"
          d="
M218.876526,177.224213
	C219.595184,177.004593 220.032288,176.989960 220.797470,176.950165
	C222.480347,179.360260 220.016159,181.767639 221.047668,184.609833
	C220.547852,185.397720 220.016876,185.508514 219.226456,184.975815
	C218.910324,184.388168 218.754791,184.214310 218.398285,184.000702
	C215.037781,181.576721 218.725891,179.607269 218.876526,177.224213
z"
        />
        <path
          fill="#5C8997"
          opacity="1.000000"
          stroke="none"
          d="
M213.003876,165.643494
	C212.171432,166.175400 211.029068,167.544968 210.072510,165.399231
	C210.958755,164.872467 211.903610,164.741531 213.003876,165.643494
z"
        />
        <path
          fill="#1E4552"
          opacity="1.000000"
          stroke="none"
          d="
M291.898560,223.961548
	C291.558807,224.443161 291.058319,224.691772 290.271790,224.955322
	C290.203400,224.039413 290.321991,222.968674 291.898560,223.961548
z"
        />
        <path
          fill="#285362"
          opacity="1.000000"
          stroke="none"
          d="
M219.013214,185.036957
	C219.460968,184.730499 219.899033,184.860550 220.679993,185.004791
	C220.649521,185.649277 220.276138,186.279602 219.651566,187.037567
	C219.268097,186.601288 219.135818,186.037369 219.013214,185.036957
z"
        />
        <path
          fill="#6494A3"
          opacity="1.000000"
          stroke="none"
          d="
M207.672302,165.065720
	C207.908600,165.716309 207.900269,166.473083 207.107056,166.523727
	C206.811417,166.542587 206.478806,165.982147 206.123352,165.408752
	C206.507233,165.098953 206.930817,165.066238 207.672302,165.065720
z"
        />
        <path
          fill="#285362"
          opacity="1.000000"
          stroke="none"
          d="
M216.331848,189.003021
	C216.171127,188.130280 216.291046,187.221039 217.821869,188.049316
	C217.562836,188.453842 217.082809,188.703751 216.331848,189.003021
z"
        />
        <path
          fill="#E1B582"
          opacity="1.000000"
          stroke="none"
          d="
M348.653259,141.279221
	C348.515656,134.571411 348.267517,128.092697 348.158936,121.611641
	C347.891022,105.614990 356.669312,96.245575 372.795898,96.305664
	C395.083588,96.388702 417.368622,97.095009 439.655640,97.486916
	C455.289276,97.761826 470.924530,97.944290 486.558502,98.200676
	C489.376770,98.246887 492.192413,98.451576 495.392212,98.672012
	C495.851837,100.313637 495.061951,101.309486 493.389954,102.016861
	C488.662903,105.185204 484.080475,102.369362 479.126282,102.504196
	C469.225739,102.475266 459.791626,102.476570 449.889038,102.515823
	C447.566437,102.841087 445.707672,103.039558 443.376099,102.694305
	C439.881989,101.685974 436.776703,101.447441 433.597870,101.455269
	C412.700562,101.506706 391.802643,101.408630 370.905853,101.525253
	C364.517792,101.560898 358.827545,103.445457 355.170349,109.852325
	C354.750549,110.800888 354.557922,111.361687 354.255768,112.356766
	C352.283142,119.043663 352.120544,125.301025 353.193939,131.788376
	C354.293365,138.433197 352.865967,145.268066 354.042145,152.420776
	C355.351318,167.076416 355.464233,181.321960 355.497223,196.027649
	C355.039673,202.171585 357.100769,207.858719 355.374023,213.990585
	C354.735046,215.684570 353.970490,216.573608 352.040283,216.087402
	C349.928650,213.993332 349.643311,211.539444 349.687653,209.327896
	C349.945953,196.446594 348.087494,183.660233 348.198853,170.351868
	C348.594147,167.895370 348.701996,165.891525 347.899658,163.506012
	C347.627594,162.529999 347.555847,161.956223 347.536438,160.939056
	C347.504974,156.709854 347.506744,152.916550 347.542969,148.680145
	C347.561401,147.327515 347.730316,146.463516 348.531738,145.340973
	C348.820221,144.219070 348.847900,143.461044 348.803192,142.329468
	C348.730835,141.955917 348.606232,141.507248 348.653259,141.279221
z"
        />
        <path
          fill="#1D3540"
          opacity="1.000000"
          stroke="none"
          d="
M586.803040,52.697693
	C588.556396,54.453598 590.009338,56.127911 590.087402,58.782753
	C567.791504,55.087063 546.895386,57.884861 527.364258,70.342804
	C525.479675,70.473465 525.222961,69.397797 525.222839,68.151398
	C525.222168,61.908855 524.268799,55.625904 526.223511,49.133148
	C529.030518,47.993980 529.195129,50.219509 529.534302,51.608158
	C530.421814,55.241493 532.583191,55.561176 535.548401,54.367367
	C544.368896,50.816166 553.690674,51.066437 562.922302,50.932953
	C568.359558,50.854336 573.813965,52.208893 579.094788,49.551720
	C582.451965,47.862450 583.933960,51.691833 586.803040,52.697693
z"
        />
        <path
          fill="#85EAF4"
          opacity="1.000000"
          stroke="none"
          d="
M476.734924,132.878021
	C477.098267,133.518753 477.121307,133.833237 477.142883,134.380157
	C477.158875,134.611313 476.956116,134.929489 476.877533,135.100983
	C467.399078,135.963943 457.944763,138.161285 448.662445,137.480713
	C438.577240,136.741302 428.531006,136.567642 418.471771,136.609375
	C412.863525,136.632629 407.293549,136.730347 401.351013,135.617249
	C400.601196,134.750061 400.472717,134.128937 400.813232,133.028870
	C403.314880,130.751358 406.038422,131.020721 408.669617,131.014008
	C421.277039,130.981812 433.884521,130.982361 446.968994,131.022873
	C450.192200,131.614166 452.966980,131.600815 456.200470,131.349701
	C457.875671,131.078354 459.094269,131.106979 460.759613,131.402222
	C465.748077,132.056854 470.308746,130.536652 475.255554,131.807388
	C475.918121,132.186874 476.165070,132.368698 476.734924,132.878021
z"
        />
        <path
          fill="#33616C"
          opacity="1.000000"
          stroke="none"
          d="
M476.193237,132.833801
	C471.275482,133.019897 466.556946,132.961166 461.376282,132.804077
	C460.623260,128.404465 464.262604,130.078033 466.555847,129.066376
	C470.002319,127.110420 471.480072,123.966621 473.487549,120.800079
	C477.011505,119.141479 479.980042,117.386009 481.546478,113.493637
	C482.677155,112.521477 483.629944,112.210289 485.007629,112.906525
	C482.947235,119.059776 479.375580,124.393921 477.569702,130.852295
	C476.627167,131.349548 476.511963,131.970642 476.193237,132.833801
z"
        />
        <path
          fill="#705250"
          opacity="1.000000"
          stroke="none"
          d="
M485.554260,112.995193
	C484.416260,113.389137 483.545624,113.583420 482.367493,113.932709
	C480.172668,114.026009 478.971527,112.741425 477.429108,111.343445
	C476.579742,109.694290 475.759277,108.581001 474.495209,107.655357
	C473.016815,106.572784 471.871185,105.096039 472.931702,102.720894
	C474.690186,101.759537 476.267609,102.081757 478.197510,102.053986
	C481.975922,103.289230 484.643341,105.747475 487.966095,107.740921
	C487.380035,109.647186 486.600800,111.221352 485.554260,112.995193
z"
        />
        <path
          fill="#A5654C"
          opacity="1.000000"
          stroke="none"
          d="
M488.117554,108.044724
	C484.574219,107.888817 481.649261,106.421432 479.191528,103.340622
	C478.642914,102.814301 478.492706,102.461617 478.646545,101.774582
	C483.295380,100.467590 487.711212,101.987030 492.618103,102.026642
	C492.548340,103.995811 490.987396,105.158089 489.529968,106.711258
	C488.917847,107.053802 488.626709,107.319313 488.188904,107.856339
	C488.009766,108.092674 488.075806,108.016411 488.117554,108.044724
z"
        />
        <path
          fill="#246876"
          opacity="1.000000"
          stroke="none"
          d="
M476.245331,133.233124
	C476.249359,132.512177 476.504730,131.945724 477.052551,131.249115
	C477.279205,131.581802 477.213409,132.044617 477.102722,132.856506
	C476.870789,133.266235 476.683716,133.326935 476.245331,133.233124
z"
        />
        <path
          fill="#705250"
          opacity="1.000000"
          stroke="none"
          d="
M488.322876,108.009811
	C488.245972,107.775452 488.482178,107.458229 488.977722,106.979904
	C489.036743,107.188179 488.836395,107.557556 488.322876,108.009811
z"
        />
        <path
          fill="#385661"
          opacity="1.000000"
          stroke="none"
          d="
M431.190338,148.635315
	C429.514984,149.834412 428.089905,150.859192 426.352203,152.001419
	C423.364502,152.327469 421.510498,154.136368 419.691132,155.725082
	C413.853455,160.822601 408.456329,166.259552 404.273590,172.961014
	C401.603363,177.239075 397.853668,180.732300 395.525024,185.456711
	C393.584167,189.394379 391.249573,193.086990 390.036407,197.678925
	C385.084686,199.540909 380.517395,201.468552 375.182831,202.176697
	C373.627136,201.911545 372.765747,201.308151 372.201416,199.801605
	C372.229065,197.540024 372.438171,195.689346 373.115967,193.543884
	C373.732208,192.683868 374.266815,192.131485 374.928009,192.172272
	C381.611023,192.584473 384.618439,187.672073 387.309937,183.040039
	C396.090363,167.929169 407.053650,154.882568 421.649658,145.096786
	C424.981171,142.863190 429.732941,142.594070 431.603027,137.734253
	C432.784424,136.897369 433.799347,136.748871 435.237671,136.718353
	C436.159241,136.738205 436.686707,136.770935 437.613220,136.857910
	C441.271423,137.080460 445.533112,134.899323 446.298492,140.868896
	C442.130310,143.200668 438.152405,145.164032 433.836182,147.172089
	C432.648773,147.361465 432.096771,147.997299 431.190338,148.635315
z"
        />
        <path
          fill="#246876"
          opacity="1.000000"
          stroke="none"
          d="
M436.018921,138.142029
	C434.825134,137.974396 433.867920,137.973129 432.552002,137.985321
	C430.716522,139.321671 428.988403,139.884491 426.565277,139.645447
	C415.968597,140.054153 405.832428,140.191772 395.718964,138.964020
	C390.515350,138.332306 385.445068,139.642609 380.377106,140.401062
	C375.841522,141.079880 372.174438,146.070938 371.962006,150.779770
	C371.353882,164.258377 372.841675,177.709793 372.469940,191.184219
	C372.411774,193.292709 372.955109,195.601044 370.314514,197.137100
	C367.951111,195.938965 367.425812,194.065170 367.520050,191.974915
	C368.112427,178.836151 366.361328,165.761841 366.480225,152.633636
	C366.580994,141.507233 370.296753,136.238068 381.132233,134.106720
	C387.399689,132.873886 393.931091,132.647842 400.634460,134.779709
	C425.962799,135.460358 450.935852,135.770477 476.354065,135.188904
	C476.798950,135.272461 476.706757,135.500610 476.772797,135.604263
	C466.838135,137.424774 456.501434,137.300354 446.501038,141.204544
	C444.738007,135.687469 440.944672,138.731659 437.565063,138.971573
	C436.867920,138.777069 436.560272,138.544617 436.018921,138.142029
z"
        />
        <path
          fill="#95F8FC"
          opacity="1.000000"
          stroke="none"
          d="
M433.045227,197.431229
	C429.012787,197.297577 424.961792,196.762512 420.517761,196.156570
	C413.954773,196.710022 407.772247,197.986145 401.342773,195.376617
	C401.699066,191.421921 402.465393,187.955597 406.013916,185.812119
	C407.440826,184.950195 408.500031,183.628922 410.382385,184.607285
	C410.786377,184.817261 411.593109,184.966141 411.792847,184.751282
	C417.465881,178.650101 423.478088,185.553619 429.535706,184.363297
	C434.603180,183.367584 432.709229,189.304565 433.621857,192.202484
	C434.065002,193.609558 433.264282,195.408356 433.045227,197.431229
z"
        />
        <path
          fill="#3E6978"
          opacity="1.000000"
          stroke="none"
          d="
M390.185242,197.060303
	C388.917236,194.603546 388.738892,192.876846 390.505707,190.800110
	C395.163788,185.324936 397.695343,178.501282 402.305817,172.736465
	C405.955872,168.172562 409.984344,163.482239 413.692139,158.886414
	C416.320099,155.629028 419.764923,150.871353 425.821228,151.854279
	C416.174652,160.689590 407.608093,170.265411 402.270996,182.768433
	C400.813538,184.009094 400.577942,185.454559 400.247864,187.230896
	C400.218536,187.591522 400.221680,187.847992 400.119385,187.925354
	C397.639099,190.160812 397.722290,193.237991 396.838318,196.374420
	C396.319946,197.169983 395.912720,197.533112 395.040649,197.990768
	C393.146393,198.434830 391.697052,198.433762 390.185242,197.060303
z"
        />
        <path
          fill="#85EAF4"
          opacity="1.000000"
          stroke="none"
          d="
M395.148926,196.950943
	C395.505707,196.348709 395.544434,196.007401 395.717773,195.429199
	C396.563904,195.024536 397.267120,195.000305 398.218964,195.552002
	C399.163269,195.691086 399.839264,195.592300 400.771606,195.258011
	C407.109741,195.245255 413.255554,194.265549 419.690979,196.039490
	C419.551025,198.268723 417.756439,198.018280 416.156128,198.001205
	C413.560638,197.973495 411.446655,199.141220 409.193542,200.818176
	C409.081055,201.624924 408.909058,202.027222 408.184326,202.469696
	C404.660065,202.568069 401.987732,204.673569 398.446960,204.728699
	C397.097046,204.472504 396.124451,204.178329 395.145020,204.196533
	C392.454010,204.246460 390.041016,203.895416 389.893463,200.498718
	C389.733368,196.812820 392.858154,197.825317 395.148926,196.950943
z"
        />
        <path
          fill="#457483"
          opacity="1.000000"
          stroke="none"
          d="
M398.616150,195.413040
	C397.815704,195.686340 397.144165,195.582809 396.162476,195.335800
	C396.336945,192.492706 395.622559,189.220978 399.643494,188.016632
	C399.593109,190.347214 399.169098,192.691711 398.616150,195.413040
z"
        />
        <path
          fill="#457483"
          opacity="1.000000"
          stroke="none"
          d="
M400.476044,187.331955
	C400.189514,186.037338 399.391357,184.297409 401.657715,183.110458
	C401.602997,184.367523 401.168274,185.719955 400.476044,187.331955
z"
        />
        <path
          fill="#3E6978"
          opacity="1.000000"
          stroke="none"
          d="
M431.250427,148.769653
	C431.162018,147.807816 431.723816,147.189743 433.151398,147.187469
	C432.852173,147.721085 432.206482,148.225388 431.250427,148.769653
z"
        />
        <path
          fill="#E1B582"
          opacity="1.000000"
          stroke="none"
          d="
M537.109558,115.111618
	C540.302734,113.146873 541.363647,109.461464 543.921692,106.591469
	C544.194702,105.944977 544.396545,105.633217 544.861206,105.171707
	C545.073242,104.988541 544.991150,104.993713 544.992737,105.034821
	C545.209534,104.812569 545.438293,104.561607 545.874634,104.157242
	C546.066589,103.989532 545.990662,103.990639 545.990051,104.028610
	C546.210266,103.807793 546.441956,103.559204 546.880737,103.157486
	C547.075378,102.992691 546.993530,102.994263 546.993530,103.035179
	C547.209839,102.811440 547.440674,102.560173 547.882202,102.157562
	C548.078247,101.992722 547.993530,101.993866 547.993286,102.036194
	C548.208435,101.813042 548.438965,101.561516 548.880005,101.158180
	C549.075500,100.992607 548.993408,100.994705 548.993713,101.035797
	C549.208496,100.812683 549.438171,100.562378 549.877808,100.161530
	C550.072876,99.997292 549.997803,99.998146 549.997925,100.035698
	C550.211731,99.810547 550.439392,99.560616 550.876587,99.161285
	C551.070190,98.997253 551.003723,99.015984 551.017517,99.047630
	C557.036499,92.467949 563.772949,87.121979 573.475220,86.462898
	C574.451416,87.197815 574.456177,87.923035 574.170898,89.028824
	C571.575378,92.654579 567.601074,93.872971 564.243774,96.496368
	C563.056702,97.197037 562.148865,97.721840 561.161743,98.668625
	C560.210449,99.642975 559.325317,100.194962 558.191650,100.937973
	C557.570801,101.337563 557.229736,101.588623 556.661377,102.082153
	C556.092224,102.620316 555.757385,102.928101 555.146362,103.468597
	C542.582947,115.404648 534.501526,129.714722 528.897949,145.563629
	C528.179565,147.595520 527.540466,149.653519 526.756897,151.664734
	C525.459778,154.994202 523.205566,156.491226 519.510254,156.515778
	C513.072205,156.558533 506.544220,155.632446 499.879639,157.878021
	C499.058807,158.173737 498.579132,158.293564 497.729645,158.400177
	C491.736053,159.207718 486.814270,161.656784 481.936401,165.122009
	C481.422424,165.476761 481.197083,165.595627 480.595367,165.812805
	C479.398956,165.987396 478.638336,165.815338 477.691132,165.046539
	C477.126190,163.924438 477.129089,163.135040 477.728760,162.002960
	C488.397980,153.506516 500.541382,150.583679 513.440552,151.311768
	C519.538147,151.655914 522.363586,149.548767 524.074707,143.976379
	C526.462646,136.200043 530.114136,128.901749 533.932495,121.371338
	C533.509766,120.016815 534.042908,119.259888 534.825500,118.275566
	C535.087341,118.051399 535.039673,117.989601 535.048218,118.027618
	C535.640747,117.229485 535.893677,116.197937 536.842773,115.242500
	C537.078247,115.046783 537.084717,115.100594 537.109558,115.111618
z"
        />
        <path
          fill="#DABA90"
          opacity="1.000000"
          stroke="none"
          d="
M477.578491,161.727203
	C477.953766,162.503784 477.978851,163.362442 478.008820,164.647324
	C478.436188,168.452057 474.709351,168.752747 473.444397,171.280945
	C473.143951,171.727325 473.012848,171.897980 472.656982,172.298401
	C472.112823,172.807739 471.784454,173.076355 471.208618,173.540955
	C470.806732,173.882065 470.644257,174.017349 470.233032,174.350601
	C468.843689,175.548309 467.758636,176.596405 466.849792,178.253601
	C462.915802,184.218460 459.723358,190.150116 457.417694,196.579590
	C456.639435,198.749710 455.809906,201.669876 452.079590,199.957321
	C451.898010,198.839371 451.864594,198.069412 451.737274,196.920990
	C453.301575,190.141144 456.887024,184.618179 459.849762,178.444870
	C461.930511,175.680389 463.908081,173.235397 465.984741,170.143936
	C466.182281,169.303558 466.280792,169.109650 466.621521,168.696533
	C467.068298,168.402344 467.272888,168.327393 467.796875,168.321564
	C471.153625,166.196869 474.190948,164.003067 477.578491,161.727203
z"
        />
        <path
          fill="#617179"
          opacity="1.000000"
          stroke="none"
          d="
M468.071136,167.977478
	C468.055878,168.633957 467.920166,168.823349 467.543060,169.028000
	C467.012909,169.182175 466.687134,169.344131 466.228455,169.800644
	C466.057343,170.018372 466.092072,170.079071 466.087341,170.113831
	C464.108185,170.030640 462.552917,169.323364 462.413391,167.095840
	C462.341980,165.955994 463.236755,165.358856 464.248505,165.169632
	C466.203918,164.803848 467.886414,165.003860 468.071136,167.977478
z"
        />
        <path
          fill="#4B555C"
          opacity="1.000000"
          stroke="none"
          d="
M536.730347,115.098419
	C537.263977,116.306252 536.540283,117.110428 535.367065,117.955322
	C535.048218,116.888947 534.885132,115.641968 536.730347,115.098419
z"
        />
        <path
          fill="#4B555C"
          opacity="1.000000"
          stroke="none"
          d="
M534.767456,118.083435
	C535.275940,119.015800 534.845032,119.759392 534.073059,120.727875
	C532.849060,119.819489 532.703674,118.792435 534.767456,118.083435
z"
        />
        <path
          fill="#635B5F"
          opacity="1.000000"
          stroke="none"
          d="
M457.283630,205.253036
	C457.012360,204.242416 457.011017,203.490540 457.016418,202.362762
	C457.977997,193.736923 459.921021,186.000549 467.724152,181.286499
	C468.435669,180.856659 469.246033,180.405304 469.626801,179.699127
	C473.259277,172.961975 479.408783,169.468201 485.693024,165.497284
	C492.419952,161.246628 499.632690,160.835602 506.800659,159.414978
	C509.524017,158.875229 512.438904,159.309860 515.659912,159.300201
	C519.526428,160.050903 522.411804,156.619934 526.314331,158.064240
	C527.076294,158.479309 527.434814,158.802811 527.887207,159.499054
	C528.487183,160.679626 528.337463,161.903473 529.847534,162.384064
	C531.043701,162.566299 531.903931,162.794418 533.065002,163.274841
	C535.601562,165.669922 539.299438,166.637329 539.327759,170.937073
	C538.543884,171.827774 537.815613,172.076187 536.626099,172.136154
	C535.467712,171.994781 534.771362,171.685822 533.812927,171.074188
	C530.078979,166.715607 526.595947,168.976349 522.780640,170.940338
	C519.706421,171.904526 516.849609,171.848328 513.663574,172.254120
	C508.342255,172.117493 509.111206,176.291397 508.638794,178.979172
	C508.072266,182.202698 508.510986,185.594376 508.496643,188.913208
	C508.488770,190.733124 508.654816,192.601639 506.854065,194.237427
	C503.124939,194.506866 502.526520,192.028214 503.065826,189.706940
	C504.529999,183.405334 500.900024,177.266830 502.797913,170.981155
	C503.296783,169.328995 501.900513,168.630173 500.386322,168.629837
	C497.570618,168.629181 494.424744,167.955399 492.035736,169.336838
	C486.140839,172.745468 479.621704,175.589813 477.123901,182.927231
	C476.027496,186.147949 476.873291,187.864120 480.102875,188.108307
	C484.407074,188.433731 488.659485,189.208954 492.972595,189.413498
	C495.226013,189.520386 497.444885,190.026932 498.229309,192.428574
	C499.423401,196.084305 502.022400,196.650558 505.676208,196.210632
	C507.938629,196.970963 509.388763,198.083725 509.833038,200.571320
	C509.637573,202.002808 509.111176,203.169037 508.190887,203.272583
	C503.095215,203.845840 502.585693,207.932144 501.232849,211.624268
	C499.977631,215.049911 499.069733,219.431931 493.263123,217.430115
	C491.945404,215.436905 493.343231,214.619965 494.204590,213.390396
	C498.719940,206.944885 497.755402,201.215302 491.682800,196.487015
	C490.124725,195.273849 488.491241,194.672501 486.608124,195.007568
	C483.472961,195.565430 480.317993,195.504181 477.166595,195.473877
	C473.906281,195.442520 471.605133,196.635788 469.801178,199.540756
	C467.055817,203.961670 462.643494,205.580933 457.283630,205.253036
z"
        />
        <path
          fill="#79675E"
          opacity="1.000000"
          stroke="none"
          d="
M456.690552,205.140488
	C461.523285,203.060318 467.088135,202.939789 469.460632,197.376984
	C470.205200,195.631271 472.002716,194.538910 473.826904,194.329575
	C478.922333,193.744843 484.060211,193.025238 489.163208,193.143036
	C495.081879,193.279648 500.611115,202.760025 498.158936,208.134277
	C496.841431,211.021698 497.411896,215.159103 492.844849,216.972885
	C487.259827,221.521240 481.494263,219.540009 475.910309,218.957764
	C474.296509,218.789490 472.680481,218.546997 470.652466,218.480331
	C451.997498,216.361862 438.523560,225.304733 426.206238,237.261536
	C418.169495,245.063065 412.822723,254.153290 410.129608,265.082214
	C408.705902,270.859619 406.074707,283.401550 406.506134,288.721741
	C406.763245,291.892395 407.643494,294.928497 408.879425,298.274658
	C409.169952,299.162689 409.247040,299.691284 409.230469,300.637085
	C409.046417,301.867889 408.688873,302.582733 407.692383,303.316772
	C407.065613,303.496704 406.801697,303.506470 406.169830,303.377808
	C403.775269,300.835236 403.391937,297.730988 402.143433,294.587036
	C401.483154,292.526459 402.250549,290.753204 401.678589,288.667480
	C399.032196,256.748596 416.172668,227.621017 444.972229,214.689819
	C452.189606,211.449158 459.818665,210.059586 468.098083,210.062469
	C469.507141,210.263397 470.229553,211.065079 471.545410,211.432953
	C474.231903,212.106430 476.643158,212.263916 479.322144,212.895294
	C481.435516,214.147995 483.196075,215.103516 484.935547,212.370178
	C491.759277,210.342361 491.759277,210.342361 490.880005,202.501160
	C490.612854,201.025421 489.841278,200.372360 488.564026,199.737122
	C484.112427,197.713730 479.772583,197.896133 475.438293,198.665985
	C473.944000,198.931427 472.789795,199.923569 472.516907,201.965088
	C472.477539,203.057037 472.299255,203.761124 471.889008,204.793640
	C470.612152,206.515976 469.725800,208.077896 468.111816,209.531891
	C465.980988,210.044952 464.171387,210.167023 461.995789,210.019455
	C461.099884,209.877441 460.841156,208.650192 459.781067,209.748688
	C453.575958,211.107666 448.101501,214.225769 441.534821,213.517639
	C442.839294,209.538925 447.026337,210.167267 449.860077,208.470764
	C451.826385,207.293594 454.180603,206.331619 456.690552,205.140488
z"
        />
        <path
          fill="#DABA90"
          opacity="1.000000"
          stroke="none"
          d="
M402.868683,293.901459
	C404.783264,296.318207 405.359161,299.285400 406.228363,302.556274
	C405.743347,303.692993 404.930389,303.911224 403.599976,303.837616
	C399.049164,301.011566 398.308563,297.031189 399.133545,292.081909
	C397.119080,270.282410 402.664368,251.087906 415.954865,234.232056
	C418.929718,230.459167 422.509521,227.163284 425.961914,223.346191
	C426.221375,222.826065 426.329773,222.607559 426.771881,222.239120
	C427.105621,222.089188 427.010071,222.091476 427.051575,222.115204
	C428.079010,220.962387 428.936737,219.630127 430.916931,219.301666
	C431.728790,219.093628 432.211334,218.845047 432.851624,218.371552
	C434.397095,216.257935 436.361938,215.219574 438.785248,214.259888
	C439.077759,214.093948 439.024963,214.010406 439.032959,214.059174
	C439.407532,213.571198 440.003113,213.518555 440.808075,213.232819
	C441.054108,213.077194 441.089569,213.211609 441.152344,213.244385
	C447.272217,212.109619 452.889038,209.130783 459.593353,208.861664
	C460.170105,208.743454 460.330078,208.674973 460.738342,208.687088
	C463.039764,208.787064 465.092896,208.806427 467.586182,208.812759
	C468.752106,209.122009 469.160522,209.685211 469.163208,210.806580
	C437.439880,211.854828 408.999207,237.441833 403.855408,269.637085
	C402.876099,275.766632 402.747681,281.880493 403.003510,288.503540
	C402.559570,290.631531 401.059296,292.222229 402.868683,293.901459
z"
        />
        <path
          fill="#79675E"
          opacity="1.000000"
          stroke="none"
          d="
M516.340759,160.773697
	C510.065460,161.685867 503.646790,159.029739 498.277893,163.582367
	C490.141937,160.697662 486.338684,169.159927 479.794250,170.777420
	C476.518524,171.587036 473.553741,174.569702 472.870117,178.622757
	C472.618652,180.113724 471.477417,181.027130 470.345551,181.546600
	C463.305023,184.777847 460.608765,190.870224 459.132019,197.892746
	C458.866302,199.156326 458.094879,200.313568 457.290039,201.753387
	C454.753052,201.611588 452.205353,202.982117 450.066742,200.555145
	C450.375427,200.083557 450.823669,199.959625 451.601624,199.722504
	C454.830841,200.326294 455.210724,198.014160 455.834351,196.216553
	C458.188263,189.431412 461.460754,183.141876 466.026093,177.213531
	C467.573822,176.000687 469.138977,175.235733 469.812683,173.238525
	C470.007294,172.995651 470.002441,173.002090 469.999207,173.004822
	C470.430084,172.562057 471.038971,172.519897 471.782013,172.109482
	C471.983978,171.897797 471.928009,171.971252 471.893250,171.940826
	C474.760101,169.767914 477.130920,166.921875 480.731995,165.149567
	C480.967804,164.905121 480.925598,164.964569 480.904388,164.934921
	C485.744995,161.767593 491.003784,159.468094 496.727600,157.323441
	C497.446991,157.060623 497.876404,157.012497 498.629272,156.942627
	C505.451019,155.908295 511.997040,155.632431 518.895081,155.895798
	C520.173950,158.645264 518.484253,159.627396 516.340759,160.773697
z"
        />
        <path
          fill="#535C64"
          opacity="1.000000"
          stroke="none"
          d="
M398.958771,291.880798
	C400.406799,295.172333 400.760559,298.975800 402.924194,302.538116
	C406.742767,307.735168 409.243591,313.245819 411.813477,319.121338
	C411.950531,319.957184 411.935638,320.436005 411.714966,321.240967
	C410.823730,322.703644 409.730652,322.791351 408.215118,322.440186
	C407.704681,322.258057 407.508148,322.170898 407.038086,321.924561
	C406.596619,321.644928 406.428711,321.524506 405.808716,321.007751
	C405.217560,320.475006 405.078522,320.338593 404.736664,319.998596
	C404.399567,319.662140 404.265289,319.529205 403.929749,319.201904
	C403.460785,318.753723 403.181335,318.513580 402.667419,318.121887
	C402.164917,317.718750 401.904236,317.461700 401.505432,316.964783
	C401.163757,316.445831 400.998291,316.135590 400.732666,315.591797
	C400.616058,315.367004 400.378296,314.928314 400.249512,314.717194
	C400.120697,314.506073 399.836395,314.100800 399.519745,313.711121
	C399.093414,313.189850 398.983734,313.058258 398.713104,312.719208
	C398.450073,312.364929 398.348022,312.218079 398.102386,311.839874
	C397.773651,311.284546 397.597107,310.955933 397.290283,310.361511
	C397.066528,309.915894 396.980774,309.731903 396.783569,309.258240
	C396.261963,307.482544 395.436157,306.190186 394.609314,304.498199
	C392.196045,298.511169 391.001038,292.615448 390.446045,286.152222
	C388.913208,280.544983 389.178192,275.468475 391.778137,270.270264
	C393.882782,274.834137 392.912994,279.833344 392.886475,284.725189
	C392.866882,288.332397 395.293335,290.376953 396.833862,292.979309
	C397.359192,293.866699 398.135376,292.557587 398.958771,291.880798
z"
        />
        <path
          fill="#AFEAF5"
          opacity="1.000000"
          stroke="none"
          d="
M392.066650,269.599976
	C391.313843,275.040436 391.037323,280.082184 391.621704,285.553345
	C391.426849,286.789246 391.632782,288.020508 390.369629,288.007812
	C389.175293,287.995789 388.615204,286.900330 387.920898,285.549622
	C387.410156,275.952087 386.811737,266.759735 389.477203,257.748932
	C389.948608,256.155304 390.417358,254.615021 391.733765,252.986694
	C393.012878,252.376389 393.830750,252.505875 394.796082,253.597031
	C394.213623,259.051941 392.093048,263.912720 392.066650,269.599976
z"
        />
        <path
          fill="#79675E"
          opacity="1.000000"
          stroke="none"
          d="
M438.701477,214.070709
	C437.933807,216.298706 435.541290,216.771698 433.345642,218.065445
	C433.341614,214.913284 435.790588,214.424835 438.701477,214.070709
z"
        />
        <path
          fill="#79675E"
          opacity="1.000000"
          stroke="none"
          d="
M431.042419,219.071228
	C430.075684,220.254883 428.905121,221.167511 427.413818,222.109528
	C427.462555,220.039871 427.803711,217.909012 431.042419,219.071228
z"
        />
        <path
          fill="#CFECEF"
          opacity="1.000000"
          stroke="none"
          d="
M395.218994,253.653076
	C394.500397,253.671387 393.919800,253.405106 393.039001,253.028229
	C392.534149,252.455475 392.512360,251.984833 392.964783,251.282776
	C393.774414,251.119385 394.292542,251.178955 395.177673,251.412720
	C395.482086,252.180771 395.419525,252.774643 395.218994,253.653076
z"
        />
        <path
          fill="#79675E"
          opacity="1.000000"
          stroke="none"
          d="
M440.749084,213.082123
	C440.917999,213.839859 440.374023,214.117020 439.364380,214.166428
	C439.508636,213.767639 439.976379,213.427353 440.749084,213.082123
z"
        />
        <path
          fill="#79675E"
          opacity="1.000000"
          stroke="none"
          d="
M426.874664,222.072418
	C427.056824,222.440384 426.870972,222.689178 426.330505,222.940079
	C425.369019,222.220749 425.998901,222.134186 426.874664,222.072418
z"
        />
        <path
          fill="#532C24"
          opacity="1.000000"
          stroke="none"
          d="
M574.310364,86.216141
	C565.393066,88.434517 558.785583,94.102829 551.379639,99.034782
	C551.021912,98.306343 551.012573,97.533401 551.019409,96.373451
	C555.214966,92.856255 559.394287,89.726082 563.789734,86.351410
	C565.772522,85.436295 567.539185,84.765678 569.655396,84.033585
	C571.345825,85.970901 573.022888,83.930244 574.748169,84.512390
	C575.835388,85.412430 575.192627,85.824707 574.310364,86.216141
z"
        />
        <path
          fill="#532C24"
          opacity="1.000000"
          stroke="none"
          d="
M545.841614,104.120712
	C545.878479,104.288536 545.690369,104.587532 545.248291,104.981232
	C545.201782,104.801262 545.409241,104.526581 545.841614,104.120712
z"
        />
        <path
          fill="#532C24"
          opacity="1.000000"
          stroke="none"
          d="
M544.839600,105.150047
	C544.889893,105.317619 544.706543,105.646698 544.279846,106.130600
	C544.226257,105.960800 544.416138,105.636177 544.839600,105.150047
z"
        />
        <path
          fill="#532C24"
          opacity="1.000000"
          stroke="none"
          d="
M546.848572,103.120972
	C546.882812,103.287506 546.690186,103.582314 546.243530,103.971840
	C546.200195,103.794128 546.411011,103.521683 546.848572,103.120972
z"
        />
        <path
          fill="#532C24"
          opacity="1.000000"
          stroke="none"
          d="
M550.833618,99.112076
	C550.887817,99.301918 550.705444,99.606575 550.260498,99.992249
	C550.197754,99.791138 550.397400,99.509018 550.833618,99.112076
z"
        />
        <path
          fill="#532C24"
          opacity="1.000000"
          stroke="none"
          d="
M547.847656,102.118561
	C547.889771,102.295959 547.701294,102.599197 547.253174,102.989273
	C547.201416,102.798866 547.409241,102.521645 547.847656,102.118561
z"
        />
        <path
          fill="#532C24"
          opacity="1.000000"
          stroke="none"
          d="
M549.837402,100.114594
	C549.888794,100.302521 549.704712,100.607750 549.257324,100.994934
	C549.196655,100.795219 549.399353,100.513557 549.837402,100.114594
z"
        />
        <path
          fill="#532C24"
          opacity="1.000000"
          stroke="none"
          d="
M548.844788,101.118454
	C548.886780,101.292656 548.698120,101.592712 548.251221,101.985649
	C548.200073,101.800453 548.407104,101.522377 548.844788,101.118454
z"
        />
        <path
          fill="#A8FBFB"
          opacity="1.000000"
          stroke="none"
          d="
M407.519684,201.874023
	C408.194183,201.873138 408.474854,201.731934 408.853271,201.312073
	C417.395233,199.878098 425.746307,197.695084 434.318787,199.792953
	C435.198456,200.008240 437.263580,199.593674 435.172363,201.752655
	C434.429108,202.139771 433.864319,202.289185 433.084229,202.641144
	C432.556488,202.940369 432.286438,203.106094 431.915436,203.531097
	C431.251801,203.923950 430.731567,204.126602 430.000122,204.514435
	C428.467407,204.920654 427.222687,205.307022 426.071716,206.592346
	C425.592743,206.947937 425.351898,207.099213 425.023926,207.478287
	C424.614838,207.856735 424.328217,208.063507 423.898254,208.489685
	C422.722961,208.951126 421.689850,209.190887 420.851562,210.398651
	C420.212799,210.946655 419.746674,211.230576 419.130981,211.746674
	C418.649414,212.063721 418.380310,212.242966 418.067505,212.738342
	C417.639099,213.047867 417.369415,213.218216 417.028351,213.678711
	C413.851379,215.189972 411.941711,217.701523 410.037415,220.633636
	C409.613129,221.037857 409.349518,221.224091 409.066040,221.745346
	C408.606079,222.371826 408.241608,222.770416 407.767303,223.418991
	C406.453888,224.228027 405.569000,225.085434 405.060303,226.676880
	C404.618622,227.384201 404.250732,227.778793 403.766846,228.421509
	C402.313385,229.513443 401.604492,230.829651 400.897034,232.528412
	C398.738831,235.357452 396.587982,237.824219 395.893616,241.527740
	C393.852722,243.536560 393.636749,246.001053 392.969299,248.616241
	C392.996918,248.986099 393.015808,248.986618 393.012695,248.976059
	C391.252258,252.654037 391.161896,256.979401 388.270081,260.464172
	C379.856903,256.553833 371.902100,256.327728 364.030762,261.013855
	C363.591949,261.275085 363.086670,261.012909 362.417542,260.428314
	C360.751923,253.773132 362.970093,248.037842 365.482056,242.476654
	C366.349548,240.556122 367.355347,238.627945 368.436401,236.367767
	C368.940582,234.854782 369.996063,234.103958 370.584595,232.655746
	C371.617279,231.316452 371.946594,230.010513 372.659576,228.543671
	C373.464447,227.490845 374.215118,226.810150 375.095215,225.900818
	C375.470978,225.459167 375.703064,225.259964 375.969604,225.121277
	C375.591858,224.571960 376.232574,225.364929 375.385925,225.564346
	C374.658539,226.343918 374.091797,226.905014 373.303101,227.696289
	C372.172760,228.901062 372.018280,230.240738 371.237549,231.635590
	C370.357513,232.759048 370.076752,233.873032 369.096619,234.944611
	C368.063019,237.346207 367.323456,239.527863 365.621765,241.570587
	C362.701050,242.480713 360.135040,242.068527 358.608826,239.887589
	C354.915161,234.609421 349.180450,234.466309 343.519348,233.157471
	C344.403625,227.959747 347.625519,224.090942 350.075867,219.448807
	C350.009033,218.240952 350.287811,217.493668 350.983032,216.581680
	C351.370331,216.258728 351.547211,216.158112 352.029297,216.057709
	C353.267395,215.897034 353.789551,215.271317 354.173615,214.139633
	C355.832855,211.944763 357.701538,210.322861 359.865295,208.523651
	C361.279297,207.245956 362.530762,206.310455 364.188599,205.376648
	C364.673492,205.119247 364.870758,205.024796 365.370728,204.811829
	C367.201508,204.375458 368.205597,203.167160 369.783112,202.288269
	C371.477783,201.320786 373.141632,202.076950 374.862427,201.244675
	C379.850372,199.743561 384.545685,198.392715 389.598694,196.858231
	C391.492554,196.570923 393.028717,196.467255 395.015930,196.526825
	C394.465363,197.186386 393.487213,197.739410 392.456696,198.165955
	C390.863129,198.825531 390.914032,200.127533 391.172913,201.427597
	C391.483032,202.985123 392.649048,202.951035 393.832733,202.560165
	C395.561890,201.989182 396.979004,202.914078 398.724487,203.682434
	C397.714325,206.524063 394.769653,206.833389 392.545654,208.650742
	C391.505798,209.559326 390.623138,210.124756 389.397186,210.733536
	C388.832947,211.178864 388.533661,211.458603 388.032715,211.966919
	C387.390533,212.607727 386.963226,213.033981 386.324585,213.672455
	C385.505585,214.389420 384.761414,214.746628 384.192749,215.731171
	C383.730713,216.264175 383.447113,216.552963 382.920441,217.029999
	C382.055878,217.583115 381.517548,218.037521 381.149963,218.682678
	C381.387848,218.226425 381.758026,217.876236 382.419006,217.398560
	C382.993713,216.992279 383.293274,216.722229 383.788422,216.211258
	C384.509033,215.347641 384.930359,214.623032 385.930054,214.022797
	C386.613556,213.387817 387.055023,212.948318 387.715881,212.285248
	C388.235260,211.765869 388.533722,211.468491 389.080292,210.968521
	C390.273224,210.235641 391.292542,209.818878 392.310547,208.783447
	C397.282532,205.976532 401.777924,202.931244 407.519684,201.874023
z"
        />
        <path
          fill="#59B1BB"
          opacity="1.000000"
          stroke="none"
          d="
M387.690674,260.311584
	C390.369598,256.812531 390.428497,252.682266 392.700684,249.147430
	C393.054626,249.431747 393.099640,249.898010 393.200439,250.712036
	C393.131866,251.470215 393.007477,251.880600 392.810944,252.604309
	C388.336487,263.278748 388.555847,274.164917 388.780975,285.762848
	C388.209625,286.449341 387.941498,286.459137 387.300110,286.309265
	C386.270691,285.599091 385.895020,284.882324 385.613373,283.651855
	C385.079956,280.989563 385.102844,278.730103 385.430695,276.029694
	C384.931488,270.453552 385.186920,265.411560 387.690674,260.311584
z"
        />
        <path
          fill="#12232B"
          opacity="1.000000"
          stroke="none"
          d="
M587.066162,52.425854
	C585.557251,55.955158 584.530823,52.469326 583.686523,52.102657
	C581.646606,51.216774 580.913757,50.302284 578.093567,52.077057
	C574.043762,54.625675 568.355713,53.281231 563.466492,52.716766
	C560.269470,52.347672 557.316589,52.185089 554.162720,52.877167
	C550.641846,53.649776 547.125366,54.534233 543.342407,53.538342
	C541.615601,53.083736 539.450256,53.817291 537.721619,55.012634
	C531.909851,59.031528 528.764343,57.743187 527.896729,51.082584
	C527.815979,50.462738 527.400818,49.886459 526.791077,49.050072
	C530.547607,39.007858 544.859985,30.908239 555.356750,32.436478
	C557.468140,32.743877 559.585449,33.010143 562.140625,33.326279
	C572.553650,37.207130 580.927490,43.133709 587.066162,52.425854
z"
        />
        <path
          fill="#DE8A57"
          opacity="1.000000"
          stroke="none"
          d="
M573.876221,86.582344
	C574.263855,85.823792 574.563782,85.475540 574.932251,84.903091
	C575.538818,84.552200 576.076904,84.425507 576.828491,84.117737
	C577.514038,83.513893 578.133789,83.511848 578.990479,83.293739
	C579.281860,83.232742 579.677002,83.207756 579.850281,83.112000
	C580.360229,82.676399 580.767639,82.512199 581.589478,82.554520
	C583.019836,82.361595 584.106628,82.137878 585.532349,81.918938
	C586.026672,81.810379 586.182129,81.697052 586.620789,81.639664
	C596.014954,80.011650 605.120972,78.824097 614.581665,82.014481
	C614.981628,82.770660 614.983521,83.481209 614.976013,84.547012
	C612.873840,87.147072 610.454773,85.909088 607.729004,85.472153
	C605.628967,84.816994 603.907410,84.494240 601.756348,85.293259
	C593.870728,85.185829 586.705750,86.791061 579.664124,89.219246
	C577.846252,89.846115 575.957458,90.775452 573.569458,89.655396
	C573.410583,88.614426 573.599548,87.803535 573.876221,86.582344
z"
        />
        <path
          fill="#532C24"
          opacity="1.000000"
          stroke="none"
          d="
M581.744629,82.440323
	C581.519897,82.743919 581.106873,82.902527 580.358643,83.038696
	C580.340393,82.363396 580.808167,82.031425 581.744629,82.440323
z"
        />
        <path
          fill="#532C24"
          opacity="1.000000"
          stroke="none"
          d="
M578.968323,83.175026
	C578.739929,83.483803 578.198059,83.734863 577.349121,83.961288
	C577.579651,83.663544 578.117188,83.390427 578.968323,83.175026
z"
        />
        <path
          fill="#412C2E"
          opacity="1.000000"
          stroke="none"
          d="
M633.776367,88.407188
	C633.805664,88.503395 633.546082,88.626083 633.546082,88.626083
	C633.546082,88.626083 633.332520,88.434982 633.363403,88.296249
	C633.394226,88.157524 633.747131,88.310982 633.776367,88.407188
z"
        />
        <path
          fill="#C46B41"
          opacity="1.000000"
          stroke="none"
          d="
M586.722046,81.618073
	C586.715637,81.868973 586.493713,81.962036 586.054688,81.949265
	C586.029663,81.683205 586.252625,81.555481 586.722046,81.618073
z"
        />
        <path
          fill="#623B37"
          opacity="1.000000"
          stroke="none"
          d="
M666.695679,102.805603
	C668.536316,107.021538 671.569336,110.524437 670.971191,115.646057
	C669.125488,117.658768 667.919861,116.716072 667.164795,114.962189
	C666.555359,113.546486 665.590942,112.434631 664.264465,113.367126
	C662.965271,114.280418 664.047119,115.810219 664.622803,116.872581
	C665.395447,118.298256 666.662170,119.464874 667.377808,120.910492
	C668.237610,122.647232 666.927551,122.280403 665.694214,122.019218
	C661.203918,115.181274 657.519409,107.993118 651.566528,102.109879
	C654.138550,101.106827 656.006592,98.958351 658.927979,98.934891
	C662.173767,98.908836 664.881897,99.378876 666.695679,102.805603
z"
        />
        <path
          fill="#623B37"
          opacity="1.000000"
          stroke="none"
          d="
M651.132324,101.096756
	C649.689514,101.565430 649.177490,100.365662 648.472656,99.163933
	C649.801270,98.915016 650.872131,99.286880 651.132324,101.096756
z"
        />
        <path
          fill="#3B2C30"
          opacity="1.000000"
          stroke="none"
          d="
M1081.663818,600.149536
	C1085.271973,601.147339 1088.665405,601.040283 1092.058960,601.013123
	C1093.979004,600.997742 1096.071533,600.735535 1096.931641,603.610352
	C1094.208862,606.661804 1090.704956,605.502686 1087.501465,605.641052
	C1077.879272,606.056580 1070.533203,611.063171 1067.126099,619.848938
	C1065.087158,625.106750 1066.477295,630.401794 1067.448608,635.548523
	C1068.275024,639.927246 1070.060303,644.123352 1070.865112,648.552979
	C1071.267090,650.765320 1072.492920,652.540527 1074.016968,654.112915
	C1076.483765,656.658020 1078.219971,659.653625 1079.683228,662.852661
	C1083.323120,670.810791 1090.503784,672.504822 1097.735840,667.126404
	C1099.471558,665.835571 1100.865479,664.138123 1102.598999,662.845459
	C1104.309814,661.569763 1105.938599,659.999878 1108.691406,660.777649
	C1107.375977,670.884033 1101.063110,676.764832 1091.008667,678.094238
	C1085.314819,678.846985 1079.678101,680.044983 1073.448730,680.000000
	C1073.216919,676.571411 1073.218384,673.298096 1069.058594,671.950500
	C1067.682495,671.504578 1067.079224,670.266724 1067.032837,668.806885
	C1066.875732,663.858948 1064.947632,659.482483 1062.618530,655.243713
	C1061.561768,653.320435 1061.020874,651.331177 1060.940186,649.092529
	C1060.812378,645.542175 1059.580811,642.370056 1057.160645,639.609985
	C1053.438843,635.365356 1053.434082,630.358521 1056.626099,625.467102
	C1058.176392,623.091370 1058.864502,620.360901 1059.050537,617.472351
	C1059.328369,613.157837 1061.351196,609.914551 1065.494507,608.253784
	C1070.997925,606.047729 1075.094604,601.047119 1081.663818,600.149536
z"
        />
        <path
          fill="#533230"
          opacity="1.000000"
          stroke="none"
          d="
M1030.988892,626.640808
	C1030.183594,626.728699 1029.376953,626.486755 1028.270996,626.130859
	C1027.971558,626.016846 1027.911621,625.545227 1027.885498,625.308960
	C1027.723511,624.208008 1026.976562,623.884583 1026.187012,623.216370
	C1024.220825,623.651001 1022.460449,624.285950 1020.348816,624.961792
	C1015.571899,626.128784 1010.880127,623.747986 1006.516602,626.034668
	C1005.995911,626.307678 1005.108582,626.089844 1004.602173,625.710632
	C999.294373,621.736145 992.858521,620.772583 986.781372,618.764709
	C977.234924,615.610657 968.225159,610.846436 958.150635,609.265991
	C957.894165,609.225708 957.728394,609.180847 957.668518,609.146729
	C948.807373,604.102905 938.590454,602.625854 929.064758,598.273010
	C925.902710,596.493591 922.602539,595.874939 919.630554,593.708130
	C917.417847,590.795898 914.808472,589.140808 911.652344,588.354248
	C905.839905,586.905823 900.605713,583.854797 894.822571,582.323059
	C893.851807,582.065979 893.021667,581.483948 892.584778,580.081055
	C894.239563,576.726013 896.954651,575.832642 900.535767,575.980713
	C908.041443,583.085510 917.854553,583.293457 926.599670,586.065491
	C939.245667,590.074097 951.512268,595.014648 963.740295,600.082092
	C967.751770,601.744507 971.849915,603.198242 976.039062,604.156067
	C986.877686,606.634277 997.006042,611.280701 1007.701721,614.169495
	C1011.275940,615.134888 1014.846558,615.657104 1018.879517,615.235229
	C1020.550171,614.891846 1021.784302,615.210388 1023.061157,615.785706
	C1022.602173,615.129822 1021.910583,615.213684 1021.001099,614.967834
	C1019.408447,614.711182 1018.553467,613.838257 1017.342407,612.861694
	C1015.962524,610.762756 1013.769714,610.593811 1012.209778,609.465271
	C1010.701599,608.374084 1008.361267,608.049438 1008.280518,605.167236
	C1010.063171,601.882385 1012.344299,603.112244 1014.386597,604.170166
	C1019.201599,606.664368 1023.955566,609.243408 1028.172852,612.758057
	C1032.076294,616.011230 1033.475342,620.300842 1031.251099,624.709839
	C1030.993164,625.430481 1030.990356,625.870667 1030.988892,626.640808
z"
        />
        <path
          fill="#412C2E"
          opacity="1.000000"
          stroke="none"
          d="
M1031.361206,624.910950
	C1032.230835,622.317383 1031.532715,619.926086 1030.033203,617.579895
	C1025.196899,610.012512 1017.477112,607.042175 1009.213562,604.813232
	C1008.769592,605.185852 1008.551086,605.304260 1007.961670,605.389954
	C1003.378540,603.971313 999.351929,602.170837 995.534912,599.913696
	C990.235107,596.779480 984.098938,596.213684 978.041748,594.207275
	C977.457214,594.008179 977.231873,593.905518 976.700317,593.594849
	C975.099609,591.518127 973.025513,590.756958 971.123596,589.817688
	C966.049011,587.311646 961.330566,584.301331 957.119934,580.505432
	C956.259277,579.729614 955.140442,579.107178 954.627319,578.108215
	C951.701538,572.412415 948.030884,567.204102 944.331909,561.631897
	C944.387817,561.025452 944.455688,560.788269 944.767944,560.273071
	C950.453552,559.406738 953.869751,563.289917 956.540466,566.653137
	C964.608337,576.813171 975.104858,583.326111 986.510681,588.768738
	C990.644165,590.741150 994.516418,593.370361 999.617920,593.945984
	C1001.497314,596.558594 1003.649414,598.406067 1006.539307,598.972168
	C1015.078613,600.645081 1021.785950,606.601929 1030.167236,608.792603
	C1034.540894,609.935852 1035.283325,620.467896 1031.361206,624.910950
z"
        />
        <path
          fill="#3B3C43"
          opacity="1.000000"
          stroke="none"
          d="
M907.573242,652.976257
	C909.068115,655.814880 908.914856,658.325317 906.751587,660.778931
	C899.958984,668.482971 892.861572,675.877686 885.558716,683.101501
	C883.028076,685.604736 879.381104,686.437927 877.157471,689.705566
	C872.358337,691.389954 867.448059,691.491394 863.297607,694.895569
	C858.530396,698.805603 852.185791,697.018555 849.311279,691.614502
	C848.328064,689.765991 846.978577,688.707825 844.967407,689.038635
	C835.459534,690.602966 830.544495,685.166443 827.245483,677.419312
	C825.786255,673.992615 823.358215,672.672485 819.405701,673.003662
	C816.089172,672.467529 812.847351,674.206726 810.106079,671.359619
	C810.818176,668.705994 812.895691,668.447632 814.823303,668.113037
	C819.216492,667.350403 823.659119,667.048889 828.535034,666.957947
	C830.032410,666.925964 831.111267,666.913452 832.593140,666.863647
	C833.521301,666.397522 834.039917,665.960754 834.811035,665.297852
	C842.762634,665.301208 850.006287,662.774353 857.456177,661.612671
	C870.170288,659.630127 882.512390,655.636108 895.443726,654.880066
	C899.369934,654.650452 903.250122,653.631592 907.573242,652.976257
z"
        />
        <path
          fill="#43626E"
          opacity="1.000000"
          stroke="none"
          d="
M905.128601,645.479187
	C894.548279,647.142212 884.223267,648.693970 873.464111,650.275513
	C869.265747,648.848816 865.268555,648.802917 861.337280,648.496521
	C852.421326,647.801453 844.906128,642.957703 836.307495,640.120605
	C834.791321,638.696411 833.969482,637.299377 833.537598,635.258911
	C834.269226,632.456299 835.747803,631.010925 838.772400,631.055786
	C842.188416,632.559448 845.391418,633.001770 848.688416,633.491333
	C854.656982,634.377625 860.268616,636.986206 866.394592,637.438049
	C872.784180,637.909485 879.133118,639.005798 885.579285,638.348267
	C886.435303,638.260925 887.191406,638.094727 888.193481,637.361938
	C889.237671,636.851013 889.985779,636.700562 891.117676,636.629089
	C896.874390,638.169556 901.752441,637.031006 906.532532,633.619690
	C908.893372,632.502869 911.085938,634.040527 913.505249,633.081665
	C914.107666,633.100464 914.347656,633.133423 914.933411,633.277954
	C916.374939,634.134766 917.593567,634.693542 918.610657,635.556641
	C921.864990,638.318176 921.843628,639.450806 918.237122,642.718689
	C914.586243,643.622314 911.193726,644.280090 907.395752,644.956726
	C906.428040,644.996948 905.887146,645.105164 905.128601,645.479187
z"
        />
        <path
          fill="#3E474F"
          opacity="1.000000"
          stroke="none"
          d="
M849.276978,654.596069
	C838.917053,656.824646 828.816650,658.736938 818.348267,660.787109
	C817.776672,661.061707 817.573059,661.198303 817.028748,661.204102
	C814.727112,658.433228 812.924011,655.687988 810.706909,653.224243
	C808.693787,650.987000 808.340576,648.588196 811.284241,646.678406
	C805.295410,647.152100 799.446655,649.713562 793.067688,647.367065
	C792.747314,646.848816 792.693604,646.597168 792.763855,645.972839
	C797.692200,642.252625 803.420837,642.389282 808.848511,641.552063
	C810.803345,641.250488 812.720825,641.045593 814.927002,640.119873
	C815.569458,639.885376 815.832275,639.806152 816.501160,639.653687
	C818.003418,639.550415 819.100220,639.564148 820.609009,639.518372
	C821.575500,639.502747 822.122559,639.565491 823.057983,639.805298
	C824.952820,640.605652 826.692322,640.732849 828.033447,641.758667
	C833.172974,645.689514 839.022095,647.607361 845.404968,648.335815
	C848.732239,648.715576 851.226379,650.172180 849.276978,654.596069
z"
        />
        <path
          fill="#374651"
          opacity="1.000000"
          stroke="none"
          d="
M918.397034,642.998169
	C921.004639,638.818787 918.352600,636.617798 915.041016,634.279663
	C915.047058,630.944641 917.692688,630.794067 919.912354,629.698059
	C921.645386,629.565247 922.755005,628.758240 923.948059,628.194885
	C924.545105,627.913025 924.735107,627.435791 924.207275,627.842224
	C924.179810,627.863342 923.989075,627.672485 923.623169,627.408447
	C922.328491,626.277893 921.626404,625.102905 921.093567,623.421082
	C921.365967,620.530762 921.249207,618.451965 918.568542,616.794189
	C915.224792,614.726318 915.405396,614.434265 917.221497,610.379822
	C914.219971,608.782837 910.133179,610.102051 907.601440,606.612793
	C908.148499,603.766418 906.753662,602.487549 904.883484,601.052734
	C902.349854,599.108826 899.197876,597.193604 900.773682,592.753418
	C904.737122,590.734009 907.259949,593.006165 909.833923,595.100464
	C913.638672,598.196289 916.921936,601.961731 921.800293,604.352844
	C928.107056,609.759216 931.744507,615.949951 930.872681,624.198120
	C930.302612,629.590881 932.013184,635.164307 929.342407,640.775330
	C928.584106,641.410400 928.086304,641.687317 927.301453,642.023254
	C924.281372,642.398682 921.548401,642.715149 918.397034,642.998169
z"
        />
        <path
          fill="#3C545F"
          opacity="1.000000"
          stroke="none"
          d="
M849.421509,654.909607
	C849.438782,651.495239 848.392578,649.925110 844.490295,649.865417
	C838.302795,649.770874 832.584900,647.583435 827.756653,643.632263
	C826.406860,642.527649 824.875549,642.177856 823.217407,641.172241
	C826.129822,636.409485 830.539001,637.309692 835.267944,638.399658
	C840.320129,641.493347 846.171692,640.472229 850.950562,644.352844
	C854.305481,647.077087 859.984314,646.768860 864.768677,647.022949
	C867.655762,647.176270 870.699463,646.975952 872.952148,649.909424
	C865.574036,653.245667 857.474365,652.939575 849.421509,654.909607
z"
        />
        <path
          fill="#2A1B1B"
          opacity="1.000000"
          stroke="none"
          d="
M816.584473,660.496155
	C817.113586,660.776428 817.287292,660.804749 817.720642,660.879028
	C816.807068,664.950562 818.057739,666.690063 822.447266,666.082031
	C824.357361,665.817505 826.369263,666.288635 828.644287,666.682007
	C823.102478,669.284424 816.709778,668.977661 810.361206,670.821716
	C809.145264,671.144653 808.291443,671.306335 807.208069,671.703369
	C804.190369,671.700012 801.498413,672.272827 798.408936,672.932495
	C796.714294,673.925049 795.250366,674.083191 793.283813,673.711182
	C790.487732,671.447693 790.152832,668.811768 790.987427,665.477844
	C791.629517,664.220276 792.315063,663.617798 793.538452,662.966309
	C799.626282,661.882446 805.219910,660.513916 810.933594,659.828735
	C812.731750,659.613098 814.527893,659.285461 816.584473,660.496155
z"
        />
        <path
          fill="#2A1B1B"
          opacity="1.000000"
          stroke="none"
          d="
M834.765869,665.105591
	C834.887878,665.917664 834.545898,666.618164 833.301758,666.818909
	C833.140381,665.965393 833.525330,665.311035 834.765869,665.105591
z"
        />
        <path
          fill="#374651"
          opacity="1.000000"
          stroke="none"
          d="
M905.166748,645.665283
	C905.202454,644.778625 905.549133,643.997864 906.827026,644.780518
	C906.480225,645.230530 905.970215,645.485352 905.166748,645.665283
z"
        />
        <path
          fill="#3B2C30"
          opacity="1.000000"
          stroke="none"
          d="
M928.773010,597.299622
	C938.165283,600.734436 948.296509,601.748840 956.868896,607.234863
	C958.645020,608.371582 961.480896,607.696899 963.623230,608.444641
	C971.525391,611.202942 979.408508,614.044434 987.146973,617.224304
	C989.342773,618.126587 991.140015,618.671021 993.625244,618.570129
	C996.910583,618.436890 999.722778,620.763672 1002.393433,622.799805
	C1004.638000,624.511047 1006.991516,626.493652 1009.633606,623.124573
	C1010.974487,621.414673 1012.806946,622.082153 1014.093079,623.140137
	C1015.785095,624.532104 1017.611328,624.624573 1019.786255,624.778381
	C1014.487488,628.189575 1008.117859,627.482788 1002.191956,628.771851
	C988.614624,631.725281 974.873352,633.928833 961.191284,636.395996
	C952.737427,637.920410 944.270020,639.369751 935.402283,640.880493
	C933.510437,638.587524 935.240356,637.043152 936.569824,635.750977
	C939.958435,632.457336 940.040039,628.835632 939.306641,624.366760
	C937.925049,615.947449 934.819702,608.466309 929.704529,601.727905
	C928.787415,600.519836 928.041443,599.221375 928.773010,597.299622
z"
        />
        <path
          fill="#37343B"
          opacity="1.000000"
          stroke="none"
          d="
M928.825928,596.696838
	C930.914185,601.572266 934.147705,605.357971 936.598999,609.614563
	C940.243408,615.943115 940.095764,622.996155 941.849609,629.654968
	C942.462097,631.980103 940.506042,633.948975 938.855774,635.676270
	C937.496094,637.099243 935.715027,638.199219 935.048340,640.575073
	C933.304199,640.975159 931.612427,641.042053 929.501343,641.121155
	C929.056763,636.031433 928.432068,630.844421 929.140747,625.846558
	C930.336182,617.416626 926.580200,611.312683 921.147949,605.304932
	C918.914429,602.926636 916.709595,600.914734 915.564148,598.136841
	C914.149719,594.706604 915.333130,593.021484 919.666870,592.757996
	C922.887329,594.127258 926.062744,594.501465 928.825928,596.696838
z"
        />
        <path
          fill="#412C2E"
          opacity="1.000000"
          stroke="none"
          d="
M1026.039307,623.318848
	C1026.984985,623.254517 1028.729858,622.443665 1028.093384,624.872803
	C1026.996460,624.923828 1026.348267,624.514099 1026.039307,623.318848
z"
        />
        <path
          fill="#473033"
          opacity="1.000000"
          stroke="none"
          d="
M1109.030151,660.686707
	C1106.440186,661.524841 1104.265137,662.972351 1102.760132,664.870483
	C1099.186035,669.378052 1094.524048,671.146545 1089.024780,671.876587
	C1084.389893,672.491882 1081.065430,671.208618 1079.479736,666.774536
	C1077.713745,661.836365 1075.448975,657.304688 1071.376221,653.839050
	C1068.643799,651.513855 1069.496704,647.964966 1068.470093,645.062073
	C1066.195312,638.629639 1064.979858,632.007019 1064.228271,625.251953
	C1063.695923,620.468384 1069.140259,610.822571 1074.368652,607.843384
	C1078.761475,605.340210 1083.329468,603.507935 1088.577271,603.963074
	C1091.056274,604.178101 1093.569458,603.998718 1096.533569,603.996582
	C1102.810913,604.776855 1107.656860,607.083801 1110.756592,612.772461
	C1116.115234,621.853638 1117.078613,631.243591 1115.086060,641.182617
	C1113.979858,646.701355 1113.031372,652.216064 1110.270020,657.659729
	C1109.691650,658.787720 1109.378418,659.578125 1109.030151,660.686707
z"
        />
        <path
          fill="#3B2C30"
          opacity="1.000000"
          stroke="none"
          d="
M1110.350586,657.924683
	C1111.374634,651.764343 1112.713745,645.537598 1114.482910,639.384705
	C1115.705688,635.131775 1115.740845,630.430664 1114.386841,626.179016
	C1113.083008,622.085388 1112.072021,617.944885 1111.015869,613.400391
	C1114.197144,613.333252 1115.052734,616.139465 1115.468018,618.329285
	C1117.936035,631.345459 1118.088257,644.252747 1112.114990,656.559753
	C1111.855713,657.094116 1111.178711,657.425842 1110.350586,657.924683
z"
        />
        <path
          fill="#BEAAA0"
          opacity="1.000000"
          stroke="none"
          d="
M217.319702,474.893097
	C207.351776,476.393677 197.678223,477.766479 187.968735,478.802612
	C173.937714,480.299866 159.880646,481.553009 145.411102,482.882721
	C144.660614,482.584320 144.515884,482.242554 144.785431,481.595154
	C168.693573,478.929504 192.399460,476.486816 216.549500,474.077454
	C217.195129,474.278809 217.396576,474.446838 217.319702,474.893097
z"
        />
        <path
          fill="#DABA90"
          opacity="1.000000"
          stroke="none"
          d="
M144.733749,481.311523
	C144.972153,481.701752 144.971329,482.031342 144.986359,482.608032
	C131.626678,484.252747 118.270424,485.697083 104.900597,487.002441
	C102.656860,487.221527 101.884544,487.897339 102.873932,490.370544
	C102.404213,490.619110 101.903816,490.526489 101.021744,490.463257
	C99.391174,492.962250 97.168953,492.710541 95.017235,492.396759
	C93.477669,492.172241 92.102943,491.421570 91.651489,489.806610
	C91.061028,487.694397 92.462379,486.822449 94.642441,486.261963
	C95.520218,486.187714 95.932320,486.107422 96.642616,485.924866
	C99.664612,484.439117 102.585625,483.605133 105.594109,484.054047
	C110.184471,484.738892 114.337761,482.355591 119.223022,482.506348
	C121.513481,482.688507 123.086708,481.482849 125.275543,481.345947
	C125.657448,481.363617 125.242538,481.631073 125.308983,481.880341
	C130.516815,484.480774 135.512665,481.416412 140.977722,481.538330
	C142.018082,481.305145 142.679764,481.254089 143.667755,481.131104
	C144.155960,481.123047 144.317886,481.186951 144.733749,481.311523
z"
        />
        <path
          fill="#9B8881"
          opacity="1.000000"
          stroke="none"
          d="
M230.360809,474.982666
	C229.530807,474.991425 229.102631,475.007477 228.354034,475.048950
	C227.333359,475.583099 226.570480,475.795868 225.348236,475.374237
	C224.982712,475.183472 224.958527,475.056488 224.975708,474.994171
	C224.655685,474.578033 224.502441,474.161194 224.778549,473.394928
	C224.995865,473.118134 224.909836,472.966614 224.920639,472.880188
	C226.565811,471.644226 228.453949,472.129150 230.631958,471.946106
	C232.371811,471.895294 233.721664,471.867523 235.475006,471.804321
	C237.544495,470.357819 239.497940,471.053131 241.753784,471.099762
	C242.391357,471.315491 242.627441,471.453430 243.082245,471.938416
	C243.268906,472.556854 243.236801,472.828278 242.928955,473.403015
	C238.819321,475.334961 234.767395,474.943970 230.360809,474.982666
z"
        />
        <path
          fill="#79675E"
          opacity="1.000000"
          stroke="none"
          d="
M243.018234,471.617126
	C240.868057,471.929016 238.815704,471.908051 236.320923,471.828003
	C236.167206,471.041840 236.455948,470.314789 236.865570,469.316650
	C238.054810,469.347321 239.123123,469.649017 240.516769,470.032043
	C241.826706,470.063538 242.686874,470.255402 243.018234,471.617126
z"
        />
        <path
          fill="#DABA90"
          opacity="1.000000"
          stroke="none"
          d="
M224.594269,473.142090
	C225.003510,473.521362 225.011169,473.924561 225.005859,474.629822
	C222.636230,475.012573 220.279556,475.093323 217.482147,475.172668
	C217.034912,474.994415 217.028442,474.817535 217.007812,474.375702
	C219.393326,473.795837 221.792999,473.480927 224.594269,473.142090
z"
        />
        <path
          fill="#79675E"
          opacity="1.000000"
          stroke="none"
          d="
M230.745255,471.684357
	C229.472824,473.405518 227.536255,472.741608 225.337601,472.834778
	C226.207504,470.004547 228.415649,471.043915 230.745255,471.684357
z"
        />
        <path
          fill="#79675E"
          opacity="1.000000"
          stroke="none"
          d="
M94.908722,486.006622
	C94.285606,487.368378 92.017593,487.654388 92.673019,489.513123
	C93.357407,491.454041 95.344162,491.001648 96.941940,490.923553
	C97.915741,490.875977 98.880379,490.641174 100.244598,490.491516
	C100.733391,492.940247 102.345970,496.154816 98.187859,496.717743
	C94.777161,497.179443 88.056656,493.666840 88.343452,491.532288
	C88.792244,488.192108 90.551071,485.423981 94.908722,486.006622
z"
        />
        <path
          fill="#79675E"
          opacity="1.000000"
          stroke="none"
          d="
M120.833725,483.253662
	C113.236107,484.430786 105.510345,485.174774 97.362701,485.870667
	C100.344658,482.065857 104.898682,483.283875 109.556900,482.989197
	C110.752640,482.978180 111.499184,482.987549 112.618912,482.982300
	C115.538406,481.616180 118.108795,481.527008 120.833725,483.253662
z"
        />
        <path
          fill="#87655F"
          opacity="1.000000"
          stroke="none"
          d="
M247.150635,482.391510
	C247.002747,481.243225 246.998627,480.498322 246.995804,479.380981
	C248.043579,478.217926 249.191986,478.696777 250.314087,478.716400
	C253.534409,478.772675 255.526840,476.747559 255.252457,473.958130
	C254.938660,470.768097 252.130493,470.817047 249.220795,471.740356
	C247.940857,472.221893 246.968292,472.274170 245.617493,472.028931
	C244.069366,471.435974 243.392212,470.319885 242.232437,469.255371
	C242.044357,468.514771 242.025223,468.103943 242.004318,467.384583
	C243.153015,465.709167 244.767365,466.103027 246.618042,465.928986
	C247.442688,465.897736 247.889481,465.897156 248.671265,465.886627
	C253.875488,466.966614 259.305786,464.978241 264.118103,468.705688
	C265.074738,468.864899 265.634430,468.876831 266.473022,468.857574
	C271.669952,466.022003 275.843140,468.052246 280.189697,471.180908
	C282.354675,477.764587 280.733673,482.070129 274.976685,484.612732
	C270.099518,486.766724 264.965118,488.321564 259.526611,486.621979
	C256.914062,485.805511 254.204193,486.943604 251.601364,486.070312
	C249.775833,485.457764 248.492081,484.198761 247.150635,482.391510
z"
        />
        <path
          fill="#635B5F"
          opacity="1.000000"
          stroke="none"
          d="
M246.735977,465.664490
	C245.892242,467.397552 244.252899,466.915161 242.389954,467.057373
	C242.002548,467.076080 241.944763,466.604523 241.933441,466.368469
	C243.239838,464.598816 244.828674,464.824738 246.735977,465.664490
z"
        />
        <path
          fill="#9B8881"
          opacity="1.000000"
          stroke="none"
          d="
M241.816940,469.074890
	C243.136551,469.048706 244.041183,469.479614 244.836746,470.642944
	C245.115524,471.129669 245.254166,471.339539 245.219635,471.884644
	C244.590363,472.476288 244.106537,472.549591 243.292175,472.214417
	C242.989395,471.989227 242.955841,471.971680 242.938126,471.960815
	C242.356247,471.477753 241.792084,471.005524 241.035019,470.323334
	C241.084869,469.816956 241.327637,469.520569 241.816940,469.074890
z"
        />
        <path
          fill="#5B555B"
          opacity="1.000000"
          stroke="none"
          d="
M121.302055,483.689301
	C118.687706,482.408234 116.207932,483.271484 113.414459,482.985260
	C114.237915,479.578949 117.130920,481.364349 119.297325,481.061615
	C121.041039,480.817963 122.858200,481.099976 125.150116,481.256165
	C124.929291,483.192108 123.191154,483.278778 121.302055,483.689301
z"
        />
        <path
          fill="#635B5F"
          opacity="1.000000"
          stroke="none"
          d="
M264.438721,468.465820
	C259.480530,468.240662 254.295517,468.575500 249.252533,466.171692
	C251.803223,464.656586 254.796387,464.175934 257.306854,461.949860
	C260.766296,458.882233 261.086884,464.194733 263.628845,464.886078
	C264.102295,465.985657 264.232361,467.032135 264.438721,468.465820
z"
        />
        <path
          fill="#3C7D8E"
          opacity="1.000000"
          stroke="none"
          d="
M264.624329,442.996399
	C264.674164,443.158569 264.539124,443.468597 264.141266,443.843628
	C264.047302,443.640533 264.234314,443.385712 264.624329,442.996399
z"
        />
        <path
          fill="#337484"
          opacity="1.000000"
          stroke="none"
          d="
M228.836304,411.321686
	C236.276703,411.075897 243.042877,414.311615 250.535187,416.208740
	C250.615143,426.823944 251.542282,437.256531 252.265274,447.684753
	C252.503189,451.116394 251.701111,451.393890 248.858582,450.246735
	C243.340729,448.019897 237.451935,446.729370 232.055786,443.373901
	C230.865021,432.647827 229.881500,422.213257 228.836304,411.321686
z"
        />
        <path
          fill="#285362"
          opacity="1.000000"
          stroke="none"
          d="
M251.865845,414.505005
	C252.068527,414.184052 252.299210,414.038605 252.529877,413.893158
	C252.489243,414.170654 252.448624,414.448151 252.243500,414.854828
	C252.078995,414.984009 251.893860,414.680511 251.865845,414.505005
z"
        />
        <path
          fill="#847876"
          opacity="1.000000"
          stroke="none"
          d="
M280.276489,472.288269
	C276.041138,470.296204 272.088867,468.634491 267.201752,468.874725
	C266.778687,467.532166 266.805481,466.237976 266.830719,464.552246
	C267.142273,462.422058 268.265320,461.499878 270.226196,461.073730
	C270.749146,461.059845 270.956360,461.076263 271.463623,461.151276
	C272.162628,461.292450 272.564819,461.353638 273.596069,461.503937
	C274.412201,461.727325 274.602814,461.838409 275.042847,462.173401
	C278.559937,464.611053 282.077271,466.441040 285.224731,468.837921
	C286.508301,469.815338 288.171997,470.491089 288.130676,472.818024
	C285.339355,475.091003 282.872375,474.864441 280.276489,472.288269
z"
        />
        <path
          fill="#465962"
          opacity="1.000000"
          stroke="none"
          d="
M272.168518,461.645447
	C270.281036,461.929596 268.759827,462.568115 267.156281,463.987640
	C265.739807,462.539673 266.057709,460.675446 266.013947,458.906799
	C265.957214,456.614471 267.386353,457.914612 268.676727,458.026794
	C269.031250,457.969879 269.006622,457.994781 269.014465,457.984924
	C269.208282,458.239471 269.426605,458.473694 269.856415,458.838623
	C270.035522,458.999512 269.992096,459.007660 269.994995,458.985779
	C270.818573,459.687866 271.917664,460.144745 272.168518,461.645447
z"
        />
        <path
          fill="#76D4DF"
          opacity="1.000000"
          stroke="none"
          d="
M189.308685,408.381744
	C190.255920,408.355652 190.809113,408.298645 191.645813,408.258545
	C195.353409,412.184113 198.777512,416.092834 202.718735,420.591888
	C199.017548,420.701477 196.351608,420.294434 193.325089,421.964539
	C189.775299,423.923462 191.689499,419.018646 190.222778,417.311584
	C190.010574,414.440155 190.004959,411.878021 189.844055,408.913391
	C189.430725,408.457550 189.172699,408.404205 189.308685,408.381744
z"
        />
        <path
          fill="#285362"
          opacity="1.000000"
          stroke="none"
          d="
M189.416245,408.342773
	C189.702713,408.412628 189.659393,408.458099 189.674072,408.484497
	C189.199722,408.604187 188.679718,409.158630 188.246964,408.414124
	C188.541245,408.367035 188.835510,408.319977 189.416245,408.342773
z"
        />
        <path
          fill="#3A4D56"
          opacity="1.000000"
          stroke="none"
          d="
M355.790283,407.374176
	C359.892303,398.865753 357.559418,391.044342 354.701172,382.882141
	C354.416779,380.492249 355.884827,380.766052 357.178772,380.484924
	C365.197235,378.742432 372.805664,376.107452 378.993286,369.760956
	C380.107178,369.368134 380.814819,369.575104 381.723755,370.363190
	C383.504669,373.301147 384.220062,376.165405 384.330750,379.585632
	C383.769745,382.931976 381.260681,383.574829 379.028931,384.619415
	C375.016693,386.497345 370.394531,386.610474 366.621124,389.308807
	C363.827484,391.306519 362.187683,393.429901 362.579865,397.055634
	C362.936249,400.350098 362.301636,403.619598 361.030762,407.136078
	C359.154205,408.852142 357.466339,410.456055 355.790283,407.374176
z"
        />
        <path
          fill="#3E6978"
          opacity="1.000000"
          stroke="none"
          d="
M325.369568,355.138611
	C323.000824,348.994263 320.732727,343.241730 317.737549,337.397156
	C318.408203,336.015472 319.508118,335.838196 321.098511,335.932617
	C323.499176,337.383759 326.091766,337.270874 328.347107,338.915283
	C330.457550,344.138062 334.375946,346.080353 339.662842,346.579956
	C340.481873,346.683075 340.935303,346.802551 341.686035,347.135681
	C344.234192,350.529175 347.043976,348.877136 350.027649,347.994873
	C350.535675,347.678680 350.755096,347.570831 351.342407,347.391968
	C352.235748,347.325745 352.725464,347.462036 353.455078,348.014832
	C354.011475,348.778870 354.202209,349.283844 354.336243,350.235504
	C354.416840,354.418396 352.141296,355.348999 348.889160,355.231140
	C342.508698,354.999939 336.119537,354.673492 329.384155,356.085449
	C327.787109,356.323029 326.692474,356.054749 325.369568,355.138611
z"
        />
        <path
          fill="#4E5963"
          opacity="1.000000"
          stroke="none"
          d="
M379.238464,369.665436
	C379.901123,374.338257 375.629700,375.048889 373.546722,376.284119
	C369.683716,378.574921 364.957611,379.380432 361.197540,382.178528
	C359.158905,383.695587 357.148285,380.888763 354.845184,382.266602
	C354.080231,381.555054 353.538269,380.591644 352.930115,379.301025
	C355.524231,376.181549 359.544983,376.622406 362.694336,374.774109
	C367.489014,371.960236 372.510864,369.526672 377.507751,366.389221
	C379.477661,366.794281 379.891968,367.843964 379.238464,369.665436
z"
        />
        <path
          fill="#8B9698"
          opacity="1.000000"
          stroke="none"
          d="
M314.011963,331.269348
	C313.112640,330.309204 312.550659,329.547577 311.873688,328.522827
	C311.838928,328.036682 311.919159,327.813660 312.250366,327.339294
	C314.892883,325.408600 317.939178,325.981445 320.464294,324.780914
	C323.831848,323.179840 327.094421,321.519592 325.851501,316.760590
	C325.603943,315.812714 325.970306,314.809296 326.876465,313.739563
	C330.329224,312.748932 332.191956,314.511536 333.901794,317.249390
	C334.963043,321.481628 333.485199,324.686890 330.543732,326.836639
	C325.795441,330.306946 320.983612,334.290680 314.011963,331.269348
z"
        />
        <path
          fill="#929EAC"
          opacity="1.000000"
          stroke="none"
          d="
M348.096985,371.068054
	C347.147491,370.256958 346.437866,369.704926 345.482574,369.039001
	C342.692108,367.126526 340.885925,364.487854 338.149628,362.868317
	C336.654297,361.983368 335.839325,360.517456 337.186584,358.415161
	C342.041840,356.858521 346.651184,356.210754 351.739014,356.807861
	C353.818787,357.894348 353.756775,359.086884 352.785553,360.635498
	C349.879089,365.269897 349.915344,365.292633 354.918335,368.432434
	C356.160431,372.680054 352.857788,371.875488 350.494385,372.480042
	C349.481384,372.160126 348.867126,371.811890 348.096985,371.068054
z"
        />
        <path
          fill="#5E6B74"
          opacity="1.000000"
          stroke="none"
          d="
M377.254761,366.544373
	C372.274109,373.054688 365.213470,375.384125 358.246124,377.709198
	C356.729340,378.215393 355.373291,379.869873 353.158936,378.989868
	C351.792175,377.353668 350.720459,375.733490 349.486053,373.829651
	C349.281342,373.289124 349.239288,373.032227 349.566315,372.669495
	C358.235901,375.117920 364.002747,369.191864 370.677185,366.332581
	C372.036987,365.750092 373.189087,364.712219 375.049683,364.305908
	C376.586517,364.402252 377.382874,364.912598 377.254761,366.544373
z"
        />
        <path
          fill="#617179"
          opacity="1.000000"
          stroke="none"
          d="
M322.288727,337.264038
	C320.821869,337.023712 319.664093,337.012909 318.070465,337.021637
	C317.082642,336.163330 316.530701,335.285461 315.886719,334.024719
	C321.268341,331.468109 327.291748,330.661011 332.028595,326.636566
	C333.799622,325.131897 335.413452,323.471375 337.551331,321.819397
	C340.816864,321.821014 342.223328,323.520660 342.498993,326.649719
	C337.904816,330.582031 333.774933,334.654694 328.250946,336.687286
	C326.422272,337.360199 324.652069,338.414185 322.288727,337.264038
z"
        />
        <path
          fill="#77878D"
          opacity="1.000000"
          stroke="none"
          d="
M338.124023,321.653076
	C335.300110,328.718964 329.218811,330.851440 323.172272,332.878326
	C321.180389,333.546051 318.945740,333.489594 316.308380,333.703064
	C315.162750,332.951050 314.530853,332.260254 313.786804,331.320160
	C323.025513,331.989655 331.872589,326.334381 333.018066,318.321472
	C336.253693,316.725311 337.487152,318.638245 338.124023,321.653076
z"
        />
        <path
          fill="#485E6A"
          opacity="1.000000"
          stroke="none"
          d="
M355.716888,407.135437
	C356.828827,409.717682 358.355377,407.606995 359.799561,407.308502
	C361.350555,405.369202 363.308960,404.687866 365.624725,403.858337
	C370.571106,402.748871 374.651825,399.690063 379.970154,400.717834
	C381.160431,403.171570 379.902802,404.621216 378.219910,405.405853
	C372.379364,408.128937 366.471344,410.754303 359.400940,410.219940
	C357.318848,410.056274 355.615326,410.056976 353.487671,410.034393
	C353.854492,409.095612 354.645416,408.180115 355.716888,407.135437
z"
        />
        <path
          fill="#BAEEFA"
          opacity="1.000000"
          stroke="none"
          d="
M336.623230,358.891815
	C338.735535,362.936462 343.208954,364.382294 345.120697,368.599762
	C342.300140,369.073883 340.855255,366.584229 339.229126,364.981628
	C335.092499,360.904877 328.678162,360.380707 325.106079,355.186890
	C326.097290,354.992828 327.131927,355.151611 328.551849,355.390015
	C332.024475,355.114990 334.393616,356.521729 336.623230,358.891815
z"
        />
        <path
          fill="#CFECEF"
          opacity="1.000000"
          stroke="none"
          d="
M310.675476,327.458984
	C306.785187,327.544769 304.918549,325.207245 304.018066,321.440216
	C304.015503,321.078369 304.076843,321.016205 304.103027,320.981262
	C305.287872,320.489349 306.140594,321.141449 307.252014,321.934540
	C307.960114,322.538208 308.378113,322.994202 309.031982,323.650085
	C309.389587,324.019531 309.526855,324.173492 309.850861,324.575562
	C310.493835,325.536865 310.797150,326.297913 310.675476,327.458984
z"
        />
        <path
          fill="#8B9698"
          opacity="1.000000"
          stroke="none"
          d="
M310.781860,327.828247
	C310.115997,327.038544 309.650238,326.321747 309.062744,325.309326
	C309.923187,324.160919 310.878845,324.414490 312.080780,325.387024
	C312.557343,326.189941 312.609375,326.705658 312.287292,327.576782
	C312.082123,327.915222 311.920227,328.086151 311.839478,328.172913
	C311.498474,328.143066 311.239563,328.023529 310.781860,327.828247
z"
        />
        <path
          fill="#5E6B74"
          opacity="1.000000"
          stroke="none"
          d="
M347.939514,371.126221
	C348.453644,371.055084 349.050171,371.301239 349.845734,371.803131
	C350.179779,372.206665 350.314789,372.354462 350.254364,372.730255
	C350.058899,372.958221 349.710815,373.273163 349.517090,373.409607
	C348.889557,372.845154 348.455750,372.144287 347.939514,371.126221
z"
        />
        <path
          fill="#1C2D35"
          opacity="1.000000"
          stroke="none"
          d="
M1103.728027,428.178467
	C1103.923828,429.661774 1104.485229,431.661072 1101.497681,432.007935
	C1101.916260,430.812714 1102.700806,429.593353 1103.728027,428.178467
z"
        />
        <path
          fill="#48505A"
          opacity="1.000000"
          stroke="none"
          d="
M887.273071,433.707001
	C886.715454,435.700928 887.556213,437.601410 886.165833,439.524902
	C883.903259,437.926819 885.396729,435.593231 884.844849,433.363129
	C883.170349,430.708099 880.569763,430.445251 878.213806,429.778046
	C872.776917,428.238220 867.193481,427.260895 861.759949,425.725922
	C857.572815,424.543091 853.466003,425.425812 849.393005,425.878845
	C843.464417,426.538300 837.784180,425.814880 832.197754,424.006989
	C827.132446,422.367737 821.839050,421.627960 816.449768,419.705261
	C815.963318,419.216797 815.815613,418.976715 815.683228,418.311829
	C816.809204,415.783478 818.753601,415.569916 821.218262,415.676910
	C832.118347,419.053802 842.566833,422.959320 854.143066,418.609497
	C861.922852,420.481476 869.331238,422.263092 876.751831,423.980408
	C883.873657,425.628601 884.170654,425.326355 884.179016,418.193451
	C884.183899,414.037079 884.372314,409.907745 885.218872,405.834198
	C885.582703,404.083466 885.726562,402.123230 888.376038,401.622406
	C888.970520,401.896576 889.158875,402.097839 889.299011,402.709778
	C886.817505,410.927765 888.801025,419.094971 887.359436,426.992096
	C887.290649,427.368683 888.153076,427.915253 888.816528,428.652161
	C889.051270,428.921661 889.008606,428.990356 888.987366,429.024719
	C888.097473,430.397369 888.878235,432.286316 887.273071,433.707001
z"
        />
        <path
          fill="#492E2E"
          opacity="1.000000"
          stroke="none"
          d="
M889.568604,403.134338
	C889.205200,402.891754 889.159668,402.663055 889.054321,402.089722
	C887.318115,399.628571 888.067993,397.679169 889.696167,395.483765
	C895.631042,396.139160 901.276062,397.081177 907.313660,398.071106
	C907.853088,400.662903 905.796387,400.064117 904.033447,400.441406
	C902.669189,400.472504 901.685303,400.455780 900.350830,400.511841
	C899.541321,400.619843 899.094055,400.742035 898.325256,401.001556
	C897.545776,401.197845 897.094788,401.271240 896.302734,401.340302
	C894.598999,401.396851 893.376038,401.030914 892.389709,400.094879
	C892.672607,400.644257 893.328979,400.932617 893.679199,401.870422
	C893.768616,402.656708 893.658813,403.088562 893.221558,403.748962
	C891.957214,403.716888 890.921814,403.432556 889.568604,403.134338
z"
        />
        <path
          fill="#532C24"
          opacity="1.000000"
          stroke="none"
          d="
M893.251160,404.008301
	C893.023010,403.448761 893.053284,402.896332 893.122253,401.930725
	C893.875366,401.132965 894.652771,401.034149 895.843994,401.003265
	C898.365173,400.952362 900.279175,399.586212 902.880005,400.001587
	C906.279175,400.321106 909.208130,399.295013 912.608032,399.585876
	C913.192444,399.690002 913.369812,399.768433 913.782532,400.017944
	C913.817749,400.991699 913.617615,401.794373 913.173035,402.797302
	C911.799561,404.471466 909.893433,403.619598 908.280640,404.661865
	C907.235779,404.883087 906.462891,404.902191 905.303711,404.902771
	C901.478638,404.704681 897.936035,407.595947 894.310791,404.273560
	C893.854370,404.013245 893.682007,404.014282 893.251160,404.008301
z"
        />
        <path
          fill="#151E25"
          opacity="1.000000"
          stroke="none"
          d="
M984.032349,415.272461
	C989.486328,416.049194 994.991638,417.183441 1000.800903,418.551453
	C999.113586,419.746063 995.750061,421.925537 995.349182,421.474030
	C992.262512,417.998169 985.897888,421.061066 984.032349,415.272461
z"
        />
        <path
          fill="#C08056"
          opacity="1.000000"
          stroke="none"
          d="
M958.770996,409.318085
	C959.338196,409.013336 959.834900,409.066162 960.549927,409.231293
	C960.265015,410.928497 964.781494,412.783447 960.579651,414.115479
	C958.129883,414.892059 954.850037,415.041138 954.181396,412.410004
	C953.438354,409.486633 957.348877,411.000061 958.770996,409.318085
z"
        />
        <path
          fill="#492E2E"
          opacity="1.000000"
          stroke="none"
          d="
M894.045776,404.406067
	C897.422607,405.541534 900.956482,403.544891 904.647705,404.650757
	C901.285400,406.708649 897.648010,409.245819 894.045776,404.406067
z"
        />
        <path
          fill="#492E2E"
          opacity="1.000000"
          stroke="none"
          d="
M908.395752,404.899780
	C908.572449,402.403442 910.585388,403.180878 912.549377,402.999573
	C912.481201,405.642517 910.397644,404.787598 908.395752,404.899780
z"
        />
        <path
          fill="#151E25"
          opacity="1.000000"
          stroke="none"
          d="
M977.202515,413.722229
	C978.563660,413.668060 980.058960,413.952423 981.879272,414.429749
	C980.313538,416.758789 978.877197,414.958344 977.202515,413.722229
z"
        />
        <path
          fill="#9FA2B2"
          opacity="1.000000"
          stroke="none"
          d="
M915.380615,399.785156
	C915.814392,397.699188 916.753601,397.441650 917.947083,399.707581
	C917.249329,399.984711 916.492920,399.952148 915.380615,399.785156
z"
        />
        <path
          fill="#151E25"
          opacity="1.000000"
          stroke="none"
          d="
M972.211304,412.627747
	C972.855835,412.416138 973.592163,412.534363 974.673523,412.798950
	C974.117920,413.961548 973.213989,414.245575 972.211304,412.627747
z"
        />
        <path
          fill="#5B7D8C"
          opacity="1.000000"
          stroke="none"
          d="
M1100.151123,406.329773
	C1100.397583,406.175446 1100.608765,406.212555 1100.819824,406.249664
	C1100.750122,406.514862 1100.680298,406.780029 1100.610474,407.045166
	C1100.445557,406.870514 1100.280518,406.695862 1100.151123,406.329773
z"
        />
        <path
          fill="#F0F0EC"
          opacity="1.000000"
          stroke="none"
          d="
M1100.887451,403.107452
	C1100.815552,403.105957 1100.836670,403.308197 1100.864380,403.272156
	C1100.892090,403.236145 1100.959351,403.108948 1100.887451,403.107452
z"
        />
        <path
          fill="#6E96A8"
          opacity="1.000000"
          stroke="none"
          d="
M1073.325439,380.811676
	C1072.165039,382.972351 1071.406372,384.447571 1074.515869,384.926849
	C1076.605347,385.248932 1078.598999,386.192902 1080.844727,387.136780
	C1081.118164,387.855957 1081.287720,388.249939 1081.859497,388.760437
	C1082.140503,388.931061 1082.164551,388.818787 1082.220703,388.807831
	C1081.890869,389.989838 1081.161133,391.157410 1079.860718,390.894226
	C1071.621826,389.226715 1064.267090,393.865540 1055.988770,393.978485
	C1046.904419,394.102448 1037.170410,394.498962 1028.206299,397.969116
	C1025.589478,398.982086 1023.611694,400.978424 1023.351379,403.126160
	C1022.585266,409.448212 1017.306763,412.735138 1014.283264,417.837799
	C1007.189636,415.819244 1003.319397,410.571320 1000.007690,404.363892
	C998.017395,401.807831 998.305481,399.419739 999.588989,397.091400
	C1003.945251,389.188782 1010.618469,384.600891 1019.542175,383.155396
	C1033.294189,380.927795 1047.309204,381.647369 1061.064209,379.335785
	C1064.314331,378.789581 1067.442139,377.455750 1071.243652,377.746063
	C1071.933105,377.946259 1072.196777,378.063049 1072.784180,378.464661
	C1073.387695,379.266205 1073.502686,379.817261 1073.325439,380.811676
z"
        />
        <path
          fill="#63879A"
          opacity="1.000000"
          stroke="none"
          d="
M1092.056396,394.240936
	C1092.459839,394.357544 1092.897827,394.786774 1093.314453,395.530029
	C1092.892334,395.413879 1092.491577,394.983704 1092.056396,394.240936
z"
        />
        <path
          fill="#759EB0"
          opacity="1.000000"
          stroke="none"
          d="
M1073.246826,378.604370
	C1066.345459,379.700073 1059.774292,381.278107 1053.085205,382.228485
	C1044.840454,383.399902 1036.563232,382.811584 1028.319580,383.835205
	C1020.903870,384.756042 1013.378113,385.403900 1007.461426,390.041931
	C1003.522949,393.129272 999.531250,397.137817 1000.000000,403.587799
	C996.069397,402.097351 992.579834,405.126495 988.401611,405.000366
	C987.395325,402.922333 987.251099,401.229492 989.050537,399.283081
	C992.832092,395.192902 993.603088,390.148956 992.056274,384.391022
	C993.065552,378.941498 996.582397,377.032227 1001.288940,375.610474
	C1012.699646,372.163635 1024.365601,369.822327 1036.157104,370.248596
	C1045.437378,370.584015 1054.406616,369.607330 1063.499023,368.483856
	C1065.485840,368.238373 1067.712646,369.778046 1070.367920,368.005737
	C1071.240967,368.100922 1071.466431,368.236908 1071.934570,368.684265
	C1073.299438,371.988495 1073.567749,375.097748 1073.246826,378.604370
z"
        />
        <path
          fill="#25262A"
          opacity="1.000000"
          stroke="none"
          d="
M887.475891,433.972809
	C887.278442,432.332184 886.167297,430.364807 888.608093,429.132294
	C897.728821,432.682800 907.137634,433.939636 916.214722,436.410187
	C930.146912,440.202118 944.222351,443.467621 958.630188,446.978424
	C959.395752,446.452148 959.787781,445.924622 960.715759,445.830444
	C964.703125,446.796387 968.434021,447.119690 972.234192,447.039001
	C976.213806,446.954498 978.320862,450.193298 980.981384,452.770935
	C978.506897,453.609680 976.028320,454.091888 973.281006,454.838470
	C969.839355,456.715271 966.937500,456.303680 964.147034,453.529480
	C964.475098,449.372711 961.583069,449.274902 959.243286,449.058899
	C951.965210,448.386932 944.763184,446.308960 937.730713,445.672028
	C926.872742,444.688660 917.572693,438.029633 906.502380,438.744690
	C904.035278,438.904083 901.308533,437.832245 898.931885,436.490723
	C895.525085,434.567749 891.842712,433.665100 887.475891,433.972809
z"
        />
        <path
          fill="#151E25"
          opacity="1.000000"
          stroke="none"
          d="
M958.888184,444.698547
	C957.401917,444.553406 955.839661,444.037506 954.085083,443.219238
	C955.548523,443.331390 957.656860,442.168304 958.888184,444.698547
z"
        />
        <path
          fill="#151E25"
          opacity="1.000000"
          stroke="none"
          d="
M960.810242,445.767212
	C960.506470,446.250824 960.012878,446.502625 959.271362,446.877380
	C959.006470,446.565002 958.989563,446.129639 958.950317,445.368469
	C959.492188,445.206940 960.056335,445.371185 960.810242,445.767212
z"
        />
        <path
          fill="#635B5F"
          opacity="1.000000"
          stroke="none"
          d="
M963.578613,453.107788
	C966.414307,455.058380 969.289124,455.093628 972.592834,455.096313
	C972.986450,456.297760 973.866150,456.872253 974.813232,457.773010
	C972.620911,457.652222 970.274109,457.264801 967.538086,456.827698
	C965.347961,456.178406 963.547058,455.578766 961.379456,454.951263
	C960.003540,453.308777 958.109802,455.440094 956.745728,453.574554
	C958.933472,452.596649 961.089905,453.133972 963.578613,453.107788
z"
        />
        <path
          fill="#635B5F"
          opacity="1.000000"
          stroke="none"
          d="
M976.827026,458.949554
	C976.416992,459.000854 975.868652,458.769653 975.152344,458.290588
	C975.552429,458.250824 976.120667,458.458923 976.827026,458.949554
z"
        />
        <path
          fill="#F4E1D8"
          opacity="1.000000"
          stroke="none"
          d="
M1089.215820,386.315918
	C1089.001831,383.626801 1088.987671,381.185669 1088.956177,378.277771
	C1092.277954,374.524689 1096.375122,375.683594 1100.252808,376.037689
	C1102.011841,376.198334 1102.255005,377.806396 1101.386475,379.066071
	C1098.979858,382.556274 1095.582764,385.032532 1092.113159,387.354736
	C1091.216187,387.955048 1090.239502,387.120117 1089.215820,386.315918
z"
        />
        <path
          fill="#485E6A"
          opacity="1.000000"
          stroke="none"
          d="
M1073.080322,378.918823
	C1072.555420,375.819794 1072.056763,372.691833 1071.311768,369.277771
	C1070.818237,368.780945 1070.582397,368.558228 1070.203369,368.135406
	C1070.049072,367.947235 1070.180542,367.920349 1070.212036,367.859375
	C1070.152954,366.459290 1070.062500,365.120178 1069.978027,363.376282
	C1070.182007,362.905182 1070.380005,362.838837 1070.885742,362.831665
	C1071.795044,363.219238 1072.191772,363.703125 1072.206665,364.688751
	C1072.995483,368.777557 1074.476318,372.456055 1074.361572,376.903900
	C1074.430420,377.750977 1074.368530,378.151611 1074.209717,378.842224
	C1073.911987,379.282593 1073.711304,379.433044 1073.346802,379.244873
	C1073.182983,378.906311 1073.106567,378.889923 1073.080322,378.918823
z"
        />
        <path
          fill="#8B9698"
          opacity="1.000000"
          stroke="none"
          d="
M1096.663818,392.854614
	C1097.036377,393.344330 1097.063721,393.833771 1097.014160,394.682007
	C1095.974731,395.021698 1095.555420,394.299500 1095.028931,393.324646
	C1095.410522,392.969452 1095.864624,392.911896 1096.663818,392.854614
z"
        />
        <path
          fill="#847876"
          opacity="1.000000"
          stroke="none"
          d="
M1073.151978,379.291992
	C1073.182983,378.906311 1073.651855,378.998871 1073.882202,379.065521
	C1074.183594,379.283234 1074.254517,379.434296 1074.423340,379.815674
	C1074.252197,380.338715 1073.983154,380.631378 1073.456299,381.072266
	C1073.172485,380.706238 1073.146729,380.191956 1073.151978,379.291992
z"
        />
        <path
          fill="#8B9698"
          opacity="1.000000"
          stroke="none"
          d="
M1094.930054,392.783875
	C1094.692139,392.872437 1094.390625,392.698029 1094.020996,392.261658
	C1094.257324,392.173431 1094.561890,392.347168 1094.930054,392.783875
z"
        />
        <path
          fill="#8B9698"
          opacity="1.000000"
          stroke="none"
          d="
M1093.843262,391.755310
	C1093.632446,391.854218 1093.253784,391.665894 1092.661011,391.235016
	C1092.856445,391.151001 1093.265991,391.309570 1093.843262,391.755310
z"
        />
        <path
          fill="#9B8881"
          opacity="1.000000"
          stroke="none"
          d="
M1072.482666,364.993713
	C1071.817017,364.481476 1071.547852,363.970825 1071.236084,363.175507
	C1074.298584,359.399353 1079.383545,361.900330 1082.822021,358.988708
	C1084.202637,357.819580 1086.407104,357.623444 1088.620850,356.989777
	C1089.103149,359.256805 1088.168091,360.365387 1085.848389,361.121155
	C1081.552734,362.520752 1077.442749,364.489716 1072.482666,364.993713
z"
        />
        <path
          fill="#F0F0EC"
          opacity="1.000000"
          stroke="none"
          d="
M1067.933838,360.697449
	C1065.839600,360.421844 1065.437866,358.474792 1064.889526,356.340820
	C1066.825073,356.859436 1067.054932,358.791992 1067.933838,360.697449
z"
        />
        <path
          fill="#485E6A"
          opacity="1.000000"
          stroke="none"
          d="
M1070.111450,362.717834
	C1069.843262,363.004120 1069.657715,362.990814 1069.192871,362.978485
	C1068.650513,362.625549 1068.529907,362.225922 1068.612427,361.475739
	C1070.416260,360.119629 1069.983032,361.654236 1070.111450,362.717834
z"
        />
        <path
          fill="#F0F0EC"
          opacity="1.000000"
          stroke="none"
          d="
M1068.500977,361.155579
	C1068.749756,361.566681 1068.805908,361.969086 1068.887939,362.675476
	C1068.625977,362.494080 1068.338135,362.008728 1068.012695,361.238464
	C1067.974854,360.953552 1068.308472,361.146851 1068.500977,361.155579
z"
        />
        <path
          fill="#688FA1"
          opacity="1.000000"
          stroke="none"
          d="
M1082.198975,388.565125
	C1081.842285,388.631927 1081.544189,388.332794 1081.149902,387.724274
	C1081.455078,387.676331 1081.856323,387.937744 1082.198975,388.565125
z"
        />
        <path
          fill="#77878D"
          opacity="1.000000"
          stroke="none"
          d="
M280.739014,288.743652
	C283.116730,291.016541 283.878113,293.732025 284.979675,296.651428
	C284.267822,297.004425 283.496338,297.005310 282.338196,297.015930
	C277.277710,297.561615 272.851227,300.028961 267.986145,298.979584
	C263.037537,297.912170 257.455963,298.736481 253.292816,294.159393
	C252.997391,293.834137 252.996872,293.666840 252.998825,293.248535
	C256.664551,290.994049 260.768982,292.103333 264.632690,291.402344
	C267.270386,290.923737 269.871094,290.655090 272.068665,288.975372
	C274.749939,286.925934 277.535553,286.880402 280.739014,288.743652
z"
        />
        <path
          fill="#BAEEFA"
          opacity="1.000000"
          stroke="none"
          d="
M269.241852,312.285828
	C269.771393,311.993561 270.504364,311.924957 271.605835,311.842285
	C271.363525,312.917206 270.524689,313.159821 269.241852,312.285828
z"
        />
        <path
          fill="#142732"
          opacity="1.000000"
          stroke="none"
          d="
M338.283813,322.184174
	C336.928101,320.304626 335.437103,319.044006 333.352509,318.004242
	C331.707611,316.021790 329.765076,314.944824 327.400848,313.922424
	C326.450104,313.993256 325.906219,313.842010 325.273865,313.202515
	C324.167664,312.504791 323.207306,312.062988 322.026703,311.381775
	C322.217499,307.965851 324.796509,308.869232 326.725159,308.507629
	C332.204712,307.480225 338.028076,307.099884 341.243530,300.886536
	C347.687073,297.714813 351.309509,303.099976 355.839691,305.218414
	C364.138214,309.099121 366.945068,317.365082 369.935516,325.043915
	C371.581177,329.269592 374.226898,328.880859 377.785828,328.661224
	C388.975952,333.329041 400.033203,330.924072 411.025085,329.831726
	C413.681641,329.567719 416.297211,329.256561 419.290253,330.079041
	C421.762238,333.834656 418.665314,333.883209 416.389069,334.746704
	C415.724915,334.870422 415.490936,335.081360 415.477631,335.446289
	C415.469910,335.657928 415.630798,335.766296 416.080444,335.915253
	C416.899445,336.411316 417.358093,336.864166 417.914368,337.702148
	C418.603943,340.853912 420.260010,342.482483 423.093689,343.222809
	C425.410217,343.828033 427.833374,344.451721 429.520813,346.954529
	C428.309875,356.204834 426.555847,358.264557 417.908936,357.574402
	C408.844299,356.850861 400.194611,356.709076 391.161621,359.132751
	C384.348602,360.960785 376.280396,361.957794 370.574707,354.854706
	C369.977081,354.110718 368.481354,353.932800 366.918427,354.481171
	C365.240601,354.913788 363.984100,354.649017 362.391937,354.121216
	C359.599060,351.688690 356.227509,350.870392 353.419922,348.500275
	C352.694305,348.115448 352.220795,347.978424 351.379639,347.792267
	C348.483459,344.859863 348.984833,340.809448 347.001099,337.339905
	C346.510773,335.224274 345.628204,333.680450 344.412231,331.933929
	C343.508972,330.320251 342.169373,329.300995 342.194214,327.292786
	C341.040375,325.423981 339.811432,323.877197 338.283813,322.184174
z"
        />
        <path
          fill="#1D3540"
          opacity="1.000000"
          stroke="none"
          d="
M377.401428,328.716034
	C372.461517,331.950165 369.951660,330.996124 368.782593,326.169525
	C368.079742,323.267548 367.854553,319.855621 364.270386,318.600861
	C363.551331,318.349152 363.139862,317.924896 363.099854,317.201385
	C362.475922,305.928223 351.171875,306.471375 345.215576,301.101562
	C344.841461,300.764282 343.924591,301.029083 342.887329,301.015656
	C336.127563,307.532593 328.265106,309.678650 319.011597,308.698090
	C317.030548,308.464142 315.428284,308.359314 313.424438,308.216187
	C311.810120,307.946136 310.558258,308.075104 308.990540,307.703674
	C307.680542,307.522156 306.732361,307.416748 305.427490,307.292725
	C300.978180,305.311646 296.496429,305.226501 292.153564,305.114868
	C288.365051,305.017487 286.588318,303.754669 286.730164,299.548645
	C290.145142,299.776031 293.213562,300.276642 290.671082,294.912567
	C290.026581,293.552887 290.853882,291.495605 291.012268,289.370789
	C291.739441,289.300049 292.459229,289.617371 293.472504,289.972656
	C293.976562,290.103821 294.187073,290.196960 294.621613,290.552063
	C295.840179,293.264923 296.524109,295.986725 300.140961,296.488037
	C307.306610,298.202881 314.352203,298.134827 321.224182,299.362732
	C323.318390,299.736938 325.541534,299.814423 327.900696,298.600037
	C331.307556,297.211761 335.360596,297.181763 335.484253,292.024933
	C335.564789,290.416962 335.485260,289.167267 335.668854,287.546082
	C336.691528,284.783844 336.777496,282.330322 336.210907,279.856445
	C335.949402,278.714508 335.568726,277.530853 335.964508,276.434448
	C336.649689,274.536346 335.881927,271.351959 338.577789,271.139130
	C340.798431,270.963806 341.022400,273.798462 341.761993,275.600494
	C343.360748,279.496033 346.967926,281.909332 349.471008,285.585022
	C349.985962,285.942169 350.181213,286.099762 350.628632,286.533569
	C351.026306,287.009705 351.171814,287.209686 351.506989,287.722717
	C351.805054,288.250244 351.913452,288.464691 352.150391,289.010437
	C355.454041,296.202057 361.642334,300.049408 367.587433,304.653015
	C369.229431,306.724518 369.626953,308.833557 369.477448,311.028351
	C369.052460,317.267151 371.053986,322.407776 376.097656,326.262054
	C376.761353,326.769226 377.163910,327.522095 377.401428,328.716034
z"
        />
        <path
          fill="#C1ACB2"
          opacity="1.000000"
          stroke="none"
          d="
M312.408966,326.083832
	C311.191254,325.669189 310.392029,325.345612 309.266876,325.017883
	C308.940979,325.013733 308.994781,325.000824 308.996155,325.028259
	C308.563507,324.404785 308.189240,323.698853 307.197571,323.164673
	C306.181854,322.409271 305.395630,321.839386 304.369293,321.107910
	C305.481812,319.364563 303.086273,316.090790 306.813721,315.253601
	C311.410919,315.056183 315.663788,313.438049 320.535645,314.029266
	C324.580811,318.179169 323.586426,323.074463 318.583862,324.999298
	C316.785919,325.691071 314.849915,326.299713 312.408966,326.083832
z"
        />
        <path
          fill="#A8A6A9"
          opacity="1.000000"
          stroke="none"
          d="
M312.000610,326.314178
	C313.328796,325.388672 314.619843,324.627808 316.013611,324.207733
	C321.795013,322.465302 322.772247,320.724701 321.045898,314.411194
	C321.860901,312.869965 323.151184,313.019440 324.751953,312.954041
	C325.658569,313.102661 326.189117,313.258728 326.891418,313.639618
	C327.048279,314.525635 326.959320,315.194885 327.029938,315.846832
	C327.794342,322.907196 325.142761,326.080475 317.721100,327.031342
	C316.093536,327.239838 314.487152,327.613556 312.476562,327.913208
	C312.058319,327.488678 312.034485,327.062103 312.000610,326.314178
z"
        />
        <path
          fill="#457483"
          opacity="1.000000"
          stroke="none"
          d="
M318.747528,308.864807
	C321.705231,308.072296 324.765320,307.268921 327.852631,307.145447
	C333.571289,306.916718 337.350525,302.857941 342.258179,300.775574
	C339.881042,308.169983 333.378113,308.743530 327.268585,309.945557
	C325.671906,310.259674 324.036560,310.377258 322.112732,310.863708
	C320.428802,311.070587 319.261902,310.685608 318.747528,308.864807
z"
        />
        <path
          fill="#457483"
          opacity="1.000000"
          stroke="none"
          d="
M308.808960,307.883362
	C309.909210,307.026703 311.189728,307.061951 312.746582,307.902740
	C311.666931,309.355042 310.324493,309.120453 308.808960,307.883362
z"
        />
        <path
          fill="#8B9698"
          opacity="1.000000"
          stroke="none"
          d="
M280.984955,288.658112
	C277.450897,288.750732 274.512543,289.735718 271.103668,291.511749
	C265.995880,294.172913 259.759827,294.467896 253.407974,293.018372
	C252.998016,292.829498 252.994736,292.661469 252.996841,292.241211
	C253.285019,289.914978 254.981583,289.024017 256.547546,289.199493
	C261.157257,289.715881 264.983093,286.657928 269.523529,286.792603
	C272.244446,286.873322 274.049530,284.212341 276.840057,283.095673
	C278.422913,284.748077 279.667694,286.538849 280.984955,288.658112
z"
        />
        <path
          fill="#372624"
          opacity="1.000000"
          stroke="none"
          d="
M960.009521,520.560547
	C959.855652,526.602905 957.778442,529.647827 951.467773,530.630127
	C945.693787,531.528931 939.154724,533.084229 936.996826,540.622192
	C937.202759,542.542725 936.056030,543.137939 934.950745,543.293152
	C924.368530,544.780090 913.945740,547.214661 902.912109,548.503906
	C901.906677,548.351501 901.355103,548.189392 900.484680,547.694336
	C899.309082,546.241516 900.048828,545.386536 901.078491,544.296387
	C908.260254,540.412048 915.962769,540.674133 923.344727,539.384338
	C925.058533,539.084961 927.327759,539.659790 928.011475,537.036377
	C924.813843,535.416504 921.455078,537.388977 918.187866,535.608398
	C916.513855,532.252625 919.320679,531.997498 920.839294,530.794373
	C918.135010,530.271545 915.244873,531.997620 912.145386,530.524170
	C910.548096,526.753113 912.979614,525.590393 915.595703,524.761536
	C921.625061,522.851379 921.644226,522.902771 918.847412,517.475952
	C918.449341,516.703552 918.359314,515.924438 918.927612,514.824097
	C923.798889,513.752808 927.727783,515.729248 932.012939,517.878357
	C938.682800,520.827271 945.214111,519.443787 951.678467,518.982178
	C954.580017,518.774963 957.516663,517.650574 960.009521,520.560547
z"
        />
        <path
          fill="#1F0F0F"
          opacity="1.000000"
          stroke="none"
          d="
M901.813599,547.283142
	C913.537109,545.207825 924.972473,543.358276 936.696899,541.248291
	C943.838440,541.041443 950.339294,539.074890 956.915344,537.531311
	C958.607910,537.133911 960.994019,536.006836 961.568481,538.351135
	C962.182861,540.858154 959.473389,541.680420 957.633911,541.787109
	C951.196045,542.160583 944.951538,543.732300 938.291382,544.758423
	C932.703430,543.804443 927.991577,547.047180 922.426086,547.011597
	C919.092407,549.833313 915.198853,549.074646 911.382324,550.001831
	C909.588928,550.519470 908.349854,551.386536 906.591064,551.960815
	C905.504639,552.202576 904.777466,552.175110 903.722717,551.874146
	C902.836060,551.970215 903.085571,550.258545 901.896851,551.411621
	C901.104431,551.641663 900.640259,551.583496 899.920410,551.171265
	C898.922302,549.043579 900.210754,548.269043 901.813599,547.283142
z"
        />
        <path
          fill="#1B1E22"
          opacity="1.000000"
          stroke="none"
          d="
M960.458618,520.997742
	C957.601196,519.272766 954.960693,519.924438 952.431885,520.408936
	C946.070801,521.627625 939.674988,520.769409 933.298523,520.921936
	C932.233459,520.947510 931.429871,520.400879 931.058472,518.869873
	C932.383911,517.270081 934.080872,517.488708 935.678101,517.549988
	C941.070862,517.756775 946.410828,517.280457 952.191895,516.524475
	C958.269348,516.974487 964.078125,516.565186 969.112915,520.492981
	C966.421021,520.960571 963.667847,520.979248 960.458618,520.997742
z"
        />
        <path
          fill="#4E5963"
          opacity="1.000000"
          stroke="none"
          d="
M969.480469,520.944214
	C964.318054,518.631714 959.034607,518.545349 953.406250,517.953247
	C952.839722,517.660461 952.707275,517.409607 952.633362,516.736816
	C954.542480,513.731934 957.279053,514.794678 960.120972,514.633972
	C963.120361,515.129150 965.680725,515.728394 968.577087,516.572998
	C971.222900,517.673218 973.477173,518.753113 976.441528,518.584717
	C977.067383,518.631714 977.316589,518.668945 977.930542,518.805115
	C980.288635,519.951477 982.715759,519.937317 984.768066,521.731689
	C985.016541,522.015137 985.005676,522.007202 985.005249,522.006042
	C985.127747,522.166016 985.250671,522.327148 985.165894,522.710144
	C980.427429,523.385803 976.081909,522.448730 971.391113,521.145996
	C970.596375,521.059143 970.191589,521.002808 969.480469,520.944214
z"
        />
        <path
          fill="#2E252C"
          opacity="1.000000"
          stroke="none"
          d="
M968.945740,558.649292
	C966.694336,558.510620 963.033936,560.571045 963.003418,555.406067
	C965.498718,555.146545 967.843140,555.566467 968.945740,558.649292
z"
        />
        <path
          fill="#122430"
          opacity="1.000000"
          stroke="none"
          d="
M999.112183,521.075134
	C998.218445,517.658630 998.845642,516.301392 1002.387329,517.864136
	C1007.372559,520.063843 1013.107605,520.206360 1017.911621,523.673462
	C1014.499756,524.178467 1011.120789,523.749878 1008.046143,521.739380
	C1005.276550,519.928284 1002.250488,521.792358 999.112183,521.075134
z"
        />
        <path
          fill="#122430"
          opacity="1.000000"
          stroke="none"
          d="
M985.067566,523.305298
	C984.958252,522.932068 985.010864,522.469849 985.007874,522.237366
	C988.158630,522.554871 991.101135,521.103516 994.607788,521.067139
	C992.056152,525.592346 989.895996,526.158081 985.067566,523.305298
z"
        />
        <path
          fill="#457483"
          opacity="1.000000"
          stroke="none"
          d="
M1036.041748,542.823547
	C1040.087402,542.284424 1040.087402,542.284424 1043.236206,543.773254
	C1037.793091,548.397888 1033.671875,542.928345 1027.925415,542.188171
	C1030.104614,540.883911 1031.202637,541.873535 1032.291748,542.035278
	C1033.427612,542.204041 1034.540283,542.529175 1036.041748,542.823547
z"
        />
        <path
          fill="#457483"
          opacity="1.000000"
          stroke="none"
          d="
M1024.985107,540.016052
	C1025.322510,540.193420 1025.660889,540.346680 1025.999268,540.499878
	C1025.757446,540.435547 1025.515747,540.371216 1025.154053,540.147888
	C1025.034302,539.988831 1024.986206,539.991882 1024.985107,540.016052
z"
        />
        <path
          fill="#15313E"
          opacity="1.000000"
          stroke="none"
          d="
M1063.681030,527.864990
	C1064.549683,529.140442 1065.096313,530.266052 1064.821045,531.959106
	C1057.000854,531.069092 1049.787231,527.504639 1041.455566,526.797363
	C1038.640503,524.608582 1035.332031,524.745361 1032.308594,523.210205
	C1031.801514,522.552734 1031.933228,522.156311 1032.688477,521.912964
	C1034.740845,522.493652 1036.445679,522.377869 1038.479492,521.973022
	C1041.846802,522.940186 1045.063721,522.799133 1048.474243,523.256714
	C1054.492676,521.968811 1058.563599,525.976685 1063.681030,527.864990
z"
        />
        <path
          fill="#73676A"
          opacity="1.000000"
          stroke="none"
          d="
M943.660889,499.796631
	C946.136963,501.305725 948.621338,501.710510 951.487671,502.159790
	C958.803223,500.742157 965.100037,503.731964 972.024780,505.111389
	C976.294250,507.452362 981.343262,506.816132 984.849731,510.637390
	C984.975525,511.214905 984.984802,511.410553 984.999451,511.899902
	C982.338318,514.028320 979.805298,512.533691 976.854858,511.781250
	C970.118896,510.199249 963.780029,508.655914 956.970703,507.453308
	C955.842224,507.208862 955.138977,506.901917 954.042603,506.602905
	C952.928406,506.450867 952.181030,506.393707 951.062744,506.283478
	C948.279968,505.602905 945.848328,505.212982 942.986267,505.732727
	C941.621765,506.100006 940.609619,506.295959 939.181946,506.415649
	C935.446533,506.757629 932.342957,506.115356 929.291321,503.885620
	C928.902405,503.384766 928.786072,503.161621 928.671143,502.570374
	C930.390930,499.970886 932.703796,500.545685 934.906982,500.989380
	C936.638855,501.338165 938.326294,502.133270 940.156006,500.494324
	C940.508179,500.061310 940.658997,499.892731 941.106750,499.551758
	C942.097778,499.169403 942.771423,499.217529 943.660889,499.796631
z"
        />
        <path
          fill="#272228"
          opacity="1.000000"
          stroke="none"
          d="
M985.351685,511.043274
	C980.917969,509.704498 976.869751,508.389771 972.461914,506.961853
	C972.033081,505.544434 973.020691,504.941376 973.955200,504.978058
	C979.821289,505.208496 985.882446,504.161346 991.672119,507.681885
	C995.705811,509.936066 1000.036438,508.427582 1003.778442,510.133362
	C1005.608643,510.967682 1008.129761,510.676941 1008.959351,513.616333
	C1009.023499,513.975647 1008.994080,514.002747 1008.978638,514.015076
	C1005.989136,516.167603 1003.007812,514.070435 1000.029663,514.054504
	C998.228577,514.044800 996.506836,513.433838 994.297119,513.251221
	C991.168640,512.392090 988.452881,511.729736 985.351685,511.043274
z"
        />
        <path
          fill="#213642"
          opacity="1.000000"
          stroke="none"
          d="
M976.693848,510.547241
	C979.400757,510.835846 981.822754,511.447937 984.624817,512.126831
	C987.272827,513.080444 989.510437,514.059326 991.819946,514.820068
	C993.247009,515.290161 994.055969,516.068665 994.209717,517.550415
	C994.408997,519.470398 992.841125,519.534912 991.665894,519.881042
	C989.631775,520.480042 987.557617,520.943359 985.258423,521.739380
	C982.581970,522.106384 980.317749,521.428223 977.822632,520.220825
	C980.175781,514.675903 984.717712,518.152588 987.698792,517.604187
	C989.052307,517.597229 988.180176,516.472168 988.379272,517.108398
	C988.423157,517.248718 988.331604,517.339050 988.107178,517.327271
	C984.243774,517.125122 980.223877,517.601257 976.701721,514.451965
	C975.896179,513.049683 975.882751,511.962860 976.693848,510.547241
z"
        />
        <path
          fill="#142732"
          opacity="1.000000"
          stroke="none"
          d="
M994.168823,513.291931
	C998.665344,512.836243 1003.424744,512.937378 1008.541016,514.019348
	C1008.602905,515.099243 1008.631287,515.818542 1010.066345,516.063293
	C1013.739502,516.689880 1017.230652,518.253845 1021.078125,518.033508
	C1023.016602,517.922607 1025.126099,517.770142 1025.969482,520.685852
	C1014.865479,521.276184 1004.978333,516.067322 994.168823,513.291931
z"
        />
        <path
          fill="#142732"
          opacity="1.000000"
          stroke="none"
          d="
M1032.939941,521.638794
	C1032.773682,522.192810 1032.510986,522.399902 1032.119141,522.824768
	C1030.634521,522.921509 1029.234741,522.929199 1028.027954,521.491089
	C1029.513062,519.673340 1031.193726,519.312378 1032.939941,521.638794
z"
        />
        <path
          fill="#693324"
          opacity="1.000000"
          stroke="none"
          d="
M1044.892700,510.264771
	C1046.860352,512.950378 1047.301758,514.486938 1043.439331,515.054871
	C1039.527832,515.629944 1035.703613,516.686890 1031.410645,515.052002
	C1031.004883,515.004883 1030.516846,514.978455 1030.272705,514.973022
	C1028.455200,513.551514 1026.242676,514.455017 1024.291992,513.209534
	C1023.294800,512.948853 1022.559631,512.937378 1021.458984,512.917603
	C1020.598572,510.564453 1018.332336,510.826141 1016.797974,510.039459
	C1016.014404,509.637695 1015.237305,509.287598 1015.394348,508.299072
	C1015.456909,507.905243 1015.967896,507.316010 1016.327148,507.274963
	C1024.958008,506.289062 1033.493408,503.079193 1042.297974,506.043976
	C1042.868530,506.236084 1043.306763,506.821259 1043.918457,507.575958
	C1043.415527,508.923645 1042.405884,508.942383 1041.047363,509.001831
	C1039.510376,509.056549 1038.429565,509.533813 1037.293823,509.763397
	C1036.907471,509.841492 1036.696411,510.048035 1036.653809,510.404144
	C1038.001099,511.107086 1038.711304,509.778961 1040.066528,509.292206
	C1041.930908,509.055908 1043.499390,508.664978 1044.892700,510.264771
z"
        />
        <path
          fill="#5D696E"
          opacity="1.000000"
          stroke="none"
          d="
M1048.402954,523.026123
	C1045.504395,525.267090 1042.436646,522.905884 1039.038574,522.284546
	C1041.931641,521.804260 1045.026489,521.907104 1048.402954,523.026123
z"
        />
        <path
          fill="#3E2423"
          opacity="1.000000"
          stroke="none"
          d="
M1024.115479,513.330750
	C1025.980225,513.101929 1028.128418,512.436890 1029.837524,514.653198
	C1028.065918,514.637512 1025.741577,515.972412 1024.115479,513.330750
z"
        />
        <path
          fill="#1D1A1E"
          opacity="1.000000"
          stroke="none"
          d="
M1083.754395,531.753296
	C1082.597656,533.222229 1081.269531,533.560608 1080.071655,531.327087
	C1081.167969,531.164734 1082.335938,531.332886 1083.754395,531.753296
z"
        />
        <path
          fill="#1D1A1E"
          opacity="1.000000"
          stroke="none"
          d="
M1088.959229,533.665527
	C1088.129028,533.892639 1087.374023,533.613525 1087.045654,532.326538
	C1087.873901,532.104126 1088.626709,532.385864 1088.959229,533.665527
z"
        />
        <path
          fill="#E1B582"
          opacity="1.000000"
          stroke="none"
          d="
M1137.344727,543.965210
	C1137.009399,543.661255 1137.027710,543.458313 1137.042236,542.948914
	C1139.648315,543.119629 1142.378540,542.140625 1144.925171,543.257324
	C1145.798584,543.640320 1146.980103,544.015808 1146.629028,545.296204
	C1146.320068,546.423401 1145.142578,546.786987 1144.199341,546.499023
	C1141.990723,545.824524 1139.860352,544.893372 1137.344727,543.965210
z"
        />
        <path
          fill="#1C2129"
          opacity="1.000000"
          stroke="none"
          d="
M889.621582,395.115051
	C890.372864,397.256927 889.343201,399.083435 889.010376,401.386200
	C885.379700,404.517639 886.890808,408.743835 886.223389,412.279358
	C885.585022,415.660858 885.811646,419.256287 886.025940,422.735016
	C886.283325,426.911621 884.196716,427.604034 880.802429,426.782593
	C872.098511,424.676147 863.400330,422.546295 854.359497,420.270813
	C851.783691,419.001129 849.552307,417.826599 846.770325,419.745422
	C842.163269,419.879303 838.272156,418.016846 833.763428,417.416260
	C832.008606,417.262939 830.678101,416.250275 828.864624,417.198120
	C826.534241,417.887421 824.544189,416.640411 822.211182,417.245911
	C820.040527,417.129608 818.168884,416.685181 816.203552,418.117371
	C815.588379,418.515381 814.989746,418.369598 815.086792,418.982544
	C815.191101,419.640869 814.904236,418.650635 815.720398,418.963013
	C822.220459,418.862000 827.970947,421.127991 833.891785,422.673798
	C838.489685,423.874207 843.090820,425.207367 848.019531,424.033752
	C856.726379,421.960510 865.030273,424.564850 873.350647,426.651703
	C876.077454,427.335602 878.784302,428.105713 881.526245,428.720795
	C883.461121,429.154846 885.086243,429.740112 884.842163,432.558228
	C876.202332,433.479431 867.579590,433.272217 858.974731,433.613098
	C848.455688,434.029816 838.476990,437.371368 828.087830,437.706604
	C816.669739,438.075073 805.432434,440.997437 793.465454,439.970886
	C791.308777,439.275696 789.596008,438.702026 787.782288,438.387207
	C786.006409,438.079010 784.683289,437.050110 784.538147,435.148560
	C784.405884,433.416931 785.591919,432.168518 787.064087,431.862823
	C792.217896,430.792694 792.232361,426.923584 793.495728,422.766754
	C797.149780,410.743591 792.713867,399.088531 793.149292,387.283447
	C793.259033,384.309113 792.948608,381.313019 793.767456,377.997681
	C794.093201,377.167938 794.331604,376.730042 794.649048,376.342407
	C799.184204,370.804108 799.198486,370.795898 803.079468,377.028503
	C803.634216,377.919373 804.262512,378.742828 805.278809,378.903839
	C809.704163,379.604858 814.198853,379.783936 818.649658,380.100739
	C821.221191,380.283783 820.142639,378.595764 819.026428,377.243561
	C815.461426,372.925171 816.051697,369.141968 821.014160,365.533020
	C821.797180,365.719452 822.166931,365.978149 822.616455,366.637512
	C823.099548,367.712891 823.270813,368.576111 824.198059,369.398834
	C831.386963,381.084961 842.964478,383.622864 855.276489,385.494690
	C855.888916,385.578308 856.134033,385.621033 856.745300,385.748932
	C857.833374,386.047546 858.505066,386.420959 859.652832,386.535095
	C865.780396,388.352936 871.998779,388.448730 877.699951,391.703003
	C878.716064,392.174591 879.433472,392.386993 880.526001,392.623138
	C881.439880,392.278839 881.995544,392.247589 882.782715,392.771240
	C885.083496,393.664032 887.170288,394.348480 889.621582,395.115051
z"
        />
        <path
          fill="#59200F"
          opacity="1.000000"
          stroke="none"
          d="
M772.418152,438.965820
	C770.552917,439.149994 770.012817,440.041382 769.974609,441.723297
	C767.870544,443.601166 765.836060,442.710968 763.469360,441.704468
	C762.532532,440.738953 762.671936,439.979492 763.277710,438.884827
	C765.286072,437.429962 767.227600,436.555603 769.118652,434.881714
	C775.519348,431.734436 778.449219,426.558014 779.451111,420.199493
	C780.640137,412.653229 780.795410,405.017883 780.446350,397.408844
	C780.148376,390.912933 781.415588,384.544708 781.507812,377.685699
	C781.538757,372.634216 781.127197,367.947968 781.631836,363.360809
	C782.218201,358.030579 779.996399,354.941803 775.381714,352.997467
	C774.158142,352.481934 772.902161,352.069824 771.259033,351.539612
	C770.302979,351.427002 769.764709,351.312744 768.855469,350.966980
	C762.324707,349.042328 755.808960,348.732758 749.830994,346.432709
	C741.351074,343.170105 732.922668,345.715759 724.047180,346.215698
	C720.386963,346.604767 717.139038,346.425385 713.449097,346.486145
	C707.351318,346.451782 701.693665,346.313904 695.673218,347.480652
	C691.800293,347.318237 688.380981,348.224945 684.543213,347.575806
	C682.473145,347.239349 680.921692,346.113129 678.775757,346.915955
	C677.269043,347.563965 675.757324,347.009735 674.778320,348.465759
	C675.250061,349.977173 676.503235,350.577515 677.264709,352.014648
	C679.203186,355.534149 681.558899,358.604675 679.494263,362.939026
	C678.764160,363.575165 678.246094,363.763763 677.261414,363.817078
	C673.747070,363.110382 671.957581,361.150726 671.276184,358.102417
	C670.492249,354.595337 666.560486,352.276581 667.499878,347.672791
	C668.408142,342.199921 670.434143,340.162109 675.104980,339.606384
	C675.929932,339.508240 676.765564,339.502991 677.595642,339.529510
	C695.855408,340.112274 714.108948,339.733856 732.354492,339.029724
	C739.704834,338.746033 746.821350,340.160767 754.033508,341.118011
	C759.562439,341.851776 765.231689,340.922699 770.772217,342.549164
	C779.846375,345.212860 789.216003,351.323364 787.985046,364.145721
	C787.366150,370.592133 787.444702,377.082581 787.424927,384.008972
	C786.859436,394.906982 785.898804,405.325439 785.739502,415.803864
	C785.589539,425.669739 782.097229,433.977264 772.418152,438.965820
z"
        />
        <path
          fill="#2C1E22"
          opacity="1.000000"
          stroke="none"
          d="
M772.197144,439.338562
	C774.085815,434.720795 778.984680,433.022156 780.965637,428.135620
	C782.625549,424.041077 783.991394,420.134979 783.931335,415.866882
	C783.789001,405.741791 785.059875,395.703430 785.712585,385.299316
	C787.888306,383.333588 787.366150,381.087799 787.522217,378.976532
	C787.737671,376.062897 786.721497,373.002106 788.802368,370.002655
	C793.256836,368.934570 793.504517,372.592438 795.140381,375.209961
	C795.344971,375.817902 795.375854,376.076080 795.205811,376.668457
	C793.908813,388.647827 796.499268,400.081940 796.969055,411.635315
	C797.204712,417.430054 794.429260,422.813843 794.624084,428.748901
	C794.718750,431.634552 791.555664,433.168396 788.298462,433.057404
	C786.940918,433.011169 785.441162,433.353577 785.512695,435.140930
	C785.569885,436.568329 786.916626,436.724762 788.064392,437.031921
	C789.775879,437.490051 791.735168,437.494080 792.915894,439.621155
	C787.328369,440.307831 781.659973,440.660065 775.989990,440.985382
	C774.625366,441.063721 773.330750,440.849579 772.197144,439.338562
z"
        />
        <path
          fill="#2E252C"
          opacity="1.000000"
          stroke="none"
          d="
M794.982422,452.513672
	C797.167542,450.441833 800.270386,450.535645 802.041565,447.878326
	C803.079834,446.320557 805.188599,446.866486 806.579956,447.863892
	C810.357544,450.571838 814.116760,449.496643 817.706665,447.801147
	C823.481995,445.073486 829.789307,445.643799 835.745728,445.275116
	C845.794189,444.653107 855.908203,444.640442 865.984436,444.020111
	C867.790466,443.908936 869.648010,443.807251 871.349976,444.706970
	C875.509094,446.905640 879.669067,449.102844 883.915527,451.649078
	C882.260193,453.909851 879.825500,453.812347 877.626831,453.846985
	C872.890991,453.921631 868.175842,454.292572 863.007935,454.503174
	C856.356323,455.574188 850.100830,456.306915 843.446289,457.275452
	C839.360901,457.994385 835.611389,457.780426 831.519775,458.463623
	C824.903381,458.797974 819.145813,456.125000 812.963928,456.013428
	C808.548462,455.933716 804.057007,457.225311 799.654419,455.507538
	C797.956360,454.845032 796.130676,454.570892 794.982422,452.513672
z"
        />
        <path
          fill="#272228"
          opacity="1.000000"
          stroke="none"
          d="
M893.636414,449.333466
	C897.108643,447.334351 900.192627,446.249420 904.184692,446.820221
	C910.934814,447.785400 917.631836,449.069611 924.298096,450.100616
	C932.955383,451.439545 941.064331,454.513702 949.587341,455.854492
	C953.524963,456.473938 954.581116,457.749878 953.008911,461.658386
	C950.839905,463.794189 948.461548,463.114136 946.352112,462.375824
	C935.811768,458.686798 924.858887,460.281342 913.704834,459.742188
	C909.567993,460.227051 906.280823,458.472534 902.450684,457.415527
	C900.506287,456.540131 898.843872,456.012848 896.772461,455.533752
	C895.332703,455.495880 894.308899,455.342957 893.079712,454.518616
	C892.030090,452.594666 893.057678,451.169342 893.636414,449.333466
z"
        />
        <path
          fill="#1B1E22"
          opacity="1.000000"
          stroke="none"
          d="
M913.068848,459.388855
	C913.194336,458.193604 913.367371,457.333710 914.506409,457.433319
	C927.106873,458.535126 939.970886,457.457367 952.551636,462.005798
	C951.126282,467.955383 952.889893,474.251556 951.079834,480.600311
	C950.821655,482.455109 950.553589,483.887054 950.140015,485.656097
	C945.816833,488.888153 942.779236,484.970673 938.856323,483.987213
	C935.386292,479.858185 937.986572,475.447510 937.380005,470.940063
	C937.454895,468.674744 936.440491,467.660706 934.386108,467.059753
	C927.832825,464.427338 920.963806,464.131073 914.489868,461.130890
	C913.727661,460.631470 913.395264,460.239349 913.068848,459.388855
z"
        />
        <path
          fill="#6D3E2F"
          opacity="1.000000"
          stroke="none"
          d="
M762.653687,440.873718
	C765.129578,440.893829 767.210815,441.079529 769.576233,442.001221
	C770.734558,442.396393 771.512268,442.716888 772.619873,443.044922
	C774.352173,444.367188 776.275696,444.993439 777.755371,446.732880
	C777.988464,446.951843 778.055725,447.397919 778.089050,447.620972
	C778.301025,448.803345 778.941284,449.588043 779.215942,450.912354
	C778.612427,456.581055 777.917847,457.148712 772.591187,456.331421
	C770.774231,455.277954 770.033691,453.793091 769.156616,452.389069
	C768.029968,450.585510 766.427307,450.100677 764.121338,451.172485
	C762.261169,451.436737 760.897400,452.205261 758.985718,452.383453
	C755.007935,452.163666 751.465942,451.768188 747.982178,449.598572
	C746.858704,447.974548 746.985901,446.661896 748.032104,445.060516
	C748.687866,444.468567 749.112366,444.040253 749.562866,444.083832
	C754.233337,444.535736 758.360352,442.990265 762.653687,440.873718
z"
        />
        <path
          fill="#4B555C"
          opacity="1.000000"
          stroke="none"
          d="
M794.597473,452.004791
	C799.025024,454.040527 802.994568,454.858246 807.760620,454.310547
	C812.695984,453.743439 817.909180,455.089233 822.930359,455.990997
	C825.367065,456.428619 827.752319,457.138489 830.688354,457.241272
	C831.412659,457.772797 831.494812,458.284821 831.125671,459.144287
	C826.274963,461.100098 821.876709,459.314606 818.464722,458.557861
	C820.555176,458.651367 823.418762,460.408569 826.653687,460.064606
	C827.842834,459.938141 829.127136,460.307648 829.304504,461.761017
	C829.515930,463.493317 828.123352,464.048737 826.804199,464.310211
	C822.189575,465.224792 817.937073,463.422058 813.585632,462.393738
	C812.772644,462.201569 812.037415,461.783722 810.880432,461.460144
	C809.780945,461.262329 809.075195,461.026672 808.024170,460.657959
	C805.498108,460.117096 803.693176,458.647644 801.122314,458.243713
	C795.778809,456.155121 790.597107,454.679932 785.402466,452.263550
	C785.015076,451.777039 784.891541,451.558289 784.932617,450.649048
	C788.290161,449.913727 791.204956,451.154694 794.597473,452.004791
z"
        />
        <path
          fill="#5B555B"
          opacity="1.000000"
          stroke="none"
          d="
M893.676392,448.816223
	C893.613342,450.451691 893.346497,451.887695 893.028625,453.688416
	C893.063354,455.967896 891.410767,456.461975 890.326477,456.192017
	C884.438171,454.726013 879.492615,458.407257 873.618530,459.043091
	C871.928406,459.505157 870.610474,459.487091 868.913940,459.129822
	C867.552307,458.719879 866.828796,457.958649 865.572876,457.387238
	C863.711304,456.487976 861.807617,456.145416 861.740723,453.620239
	C868.898804,451.877563 875.982971,451.961761 883.534851,452.022583
	C884.970581,452.072693 886.022827,451.928070 886.892212,452.261078
	C890.643799,453.697998 892.511597,452.775146 892.051147,448.498932
	C892.007874,448.097595 892.266907,447.663757 892.385620,447.244995
	C892.747986,447.702271 893.110291,448.159515 893.676392,448.816223
z"
        />
        <path
          fill="#59200F"
          opacity="1.000000"
          stroke="none"
          d="
M777.938660,446.606049
	C775.880310,446.593414 773.892578,446.051605 773.012939,443.389282
	C775.409790,442.869019 777.090515,443.885345 777.938660,446.606049
z"
        />
        <path
          fill="#705250"
          opacity="1.000000"
          stroke="none"
          d="
M778.388428,450.314178
	C777.324341,449.509735 777.061462,448.933014 777.898926,448.018066
	C780.413818,447.821014 782.530823,448.513885 784.846436,449.689819
	C785.097229,449.958588 785.045166,449.981079 785.021362,449.996399
	C783.068298,451.741302 780.990540,451.953491 778.388428,450.314178
z"
        />
        <path
          fill="#342A31"
          opacity="1.000000"
          stroke="none"
          d="
M991.994019,507.639191
	C985.642639,506.433960 979.075134,507.325714 972.381958,506.610840
	C965.576843,505.430023 959.051208,504.011414 952.201660,502.384766
	C954.382202,498.413483 951.303406,497.448639 948.573730,496.671783
	C949.968567,493.756897 951.726074,494.330505 953.833618,494.692841
	C963.972168,496.435944 973.450195,500.469788 983.368347,502.946686
	C984.961060,503.344482 986.577637,503.646820 988.587341,503.997467
	C989.882690,505.194122 992.205444,505.085297 991.994019,507.639191
z"
        />
        <path
          fill="#87655F"
          opacity="1.000000"
          stroke="none"
          d="
M1045.394287,510.725403
	C1043.738403,509.712646 1042.267212,509.983612 1040.500732,509.864685
	C1039.755859,509.531830 1039.582397,509.119568 1039.808105,508.352112
	C1041.096802,508.040283 1042.162964,508.004150 1043.629883,507.948181
	C1052.078369,510.022125 1060.126465,512.115906 1068.579102,514.317444
	C1069.242432,514.680725 1069.501099,514.936279 1069.885254,515.330688
	C1070.219971,515.742554 1070.429199,516.015442 1070.262939,516.642395
	C1068.992920,516.970520 1068.098022,516.944763 1066.885986,516.915039
	C1059.997559,513.683167 1052.856567,512.393311 1045.394287,510.725403
z"
        />
        <path
          fill="#C99469"
          opacity="1.000000"
          stroke="none"
          d="
M975.153442,463.593903
	C974.715576,463.850159 974.561523,463.904297 974.403259,463.922913
	C972.893616,464.100616 971.437500,463.963715 970.656555,462.465576
	C970.315735,461.811768 970.853210,461.194031 971.589233,461.193878
	C973.251587,461.193512 975.247131,460.731720 975.153442,463.593903
z"
        />
        <path
          fill="#3D292C"
          opacity="1.000000"
          stroke="none"
          d="
M938.483521,483.266266
	C942.862305,482.040985 945.309631,486.592682 949.600586,485.995850
	C949.955505,487.953979 950.357666,490.119873 947.364624,490.933411
	C942.422974,492.275391 937.878357,493.386414 932.991638,492.669922
	C930.228271,492.264740 927.423523,492.925507 925.177917,495.615570
	C922.280212,496.459320 919.777588,497.451263 916.678711,497.527039
	C913.643250,497.746368 911.121643,495.791412 908.037598,497.194214
	C905.708496,497.463562 903.776733,497.622375 901.518433,496.841095
	C899.539307,494.854767 900.127747,493.265625 901.730286,491.943665
	C903.021667,490.878357 904.311890,489.860291 905.295166,487.983643
	C905.769653,487.496216 905.983643,487.325256 906.557617,486.955811
	C917.162292,484.161072 927.545105,482.869415 938.483521,483.266266
z"
        />
        <path
          fill="#533230"
          opacity="1.000000"
          stroke="none"
          d="
M925.137207,495.195679
	C925.597656,490.420959 931.284424,490.007996 932.201416,490.525085
	C937.026123,493.245636 941.700562,488.748840 946.592285,490.987549
	C945.647339,492.297363 944.424866,493.637543 945.403931,495.742493
	C946.113037,497.266968 946.025024,498.642975 944.214478,499.873718
	C943.172791,499.989746 942.447144,499.925934 941.358765,499.817474
	C939.106323,500.661865 937.362305,499.556946 935.207764,499.320709
	C934.640747,499.209503 934.416748,499.152344 933.863953,498.989136
	C933.319458,498.804779 933.103760,498.726379 932.567749,498.523621
	C932.035522,498.312775 931.823547,498.226227 931.298096,498.000488
	C929.181152,496.863464 927.011841,496.742218 925.137207,495.195679
z"
        />
        <path
          fill="#76D4DF"
          opacity="1.000000"
          stroke="none"
          d="
M166.820297,499.946533
	C165.712799,495.318481 169.350830,496.526642 171.071808,496.275604
	C182.822998,494.561523 194.610611,493.097076 206.722275,491.328979
	C207.426361,490.667053 207.854614,490.297852 208.743774,489.856567
	C215.518692,488.831848 221.886139,487.930450 228.317856,487.499237
	C232.489151,487.219574 236.156204,488.192322 239.410217,490.817780
	C242.861557,493.602417 247.263657,495.080536 250.224197,499.057983
	C246.384232,503.854431 240.769623,502.045776 236.025864,503.370911
	C234.180374,503.886475 232.090515,503.475769 229.679932,503.486816
	C221.128098,503.447449 213.161636,505.110779 204.729172,505.749695
	C202.417969,505.958191 200.567673,505.448303 198.710220,504.985962
	C198.678665,504.978119 198.523926,504.940002 198.242477,504.910278
	C197.877197,504.871674 197.878998,505.046326 198.174942,505.708282
	C197.153656,507.247162 195.805176,507.329590 194.083435,507.281219
	C192.509842,507.129364 191.292419,506.235382 189.750061,507.333740
	C187.380112,508.966736 184.581940,507.722504 182.304413,509.587097
	C181.904480,510.007507 181.728409,510.164185 181.241394,510.500305
	C179.190674,511.300354 177.567383,510.891449 176.344162,509.669800
	C173.205307,506.534912 169.451721,504.017914 166.820297,499.946533
z"
        />
        <path
          fill="#48505A"
          opacity="1.000000"
          stroke="none"
          d="
M209.287109,490.557068
	C208.560669,491.005005 208.135834,491.099243 207.384552,491.149658
	C201.281494,488.517883 195.412521,490.467987 189.681259,491.103973
	C184.807663,491.644745 180.133499,493.886719 175.059128,492.122284
	C174.025940,491.763031 172.493149,491.656433 171.632950,492.171692
	C164.931305,496.186035 157.695923,493.355682 150.759384,494.066498
	C146.778046,494.474518 143.657837,491.914337 139.702713,491.812103
	C131.987289,491.612793 124.887291,495.019928 117.260437,494.797852
	C115.773476,494.754517 114.279953,494.936249 112.396767,495.024170
	C111.229782,494.701111 110.455490,494.368073 109.370697,493.978027
	C108.563293,493.359375 108.066399,492.797760 107.327950,492.087738
	C107.041031,490.771362 106.915833,489.185516 108.470436,489.275330
	C114.411118,489.618622 120.116753,487.049194 126.536766,487.987274
	C134.787033,488.051392 142.437683,486.490814 150.596252,485.951172
	C151.451401,485.927704 151.889206,485.926117 152.655334,485.921265
	C155.489563,485.844757 158.039200,486.493439 160.734650,485.221863
	C163.335327,484.961914 165.688416,484.943298 168.481384,484.922150
	C171.156754,483.109375 174.051804,484.735260 176.695251,483.238770
	C178.742340,482.670441 180.488983,482.348480 182.618973,482.020508
	C189.149048,482.193848 195.271149,481.623474 201.404922,481.443420
	C205.686554,481.317780 209.367477,482.542297 210.257812,488.012939
	C210.146561,489.099487 209.929321,489.674744 209.287109,490.557068
z"
        />
        <path
          fill="#214A59"
          opacity="1.000000"
          stroke="none"
          d="
M182.373871,517.680481
	C181.515060,517.777100 180.949951,517.612549 180.183670,517.221924
	C179.999496,516.298645 180.016525,515.601379 180.016266,514.191589
	C179.970535,513.235535 179.942093,512.992004 179.959290,512.381470
	C180.166412,511.537415 180.428909,511.122498 181.099487,510.567993
	C183.173172,509.099579 185.412979,509.930695 187.616180,508.897095
	C188.493469,508.650330 189.010681,508.583954 189.930420,508.557251
	C194.332321,508.572662 198.199158,507.512054 202.529175,506.897858
	C203.461304,506.638184 204.011719,506.570160 204.992584,506.546387
	C209.106293,507.062958 212.688370,504.529297 216.755676,506.409546
	C218.151031,509.550568 216.161545,509.943298 214.120178,510.438629
	C209.507446,511.557922 204.666885,511.576447 199.843048,513.557007
	C194.049866,515.745239 188.073303,515.265076 182.373871,517.680481
z"
        />
        <path
          fill="#478D9B"
          opacity="1.000000"
          stroke="none"
          d="
M181.325378,510.913696
	C181.186295,511.670624 181.169525,512.058044 181.122101,512.734741
	C180.800034,513.180847 180.548752,513.386719 180.180054,513.828918
	C180.022537,514.016296 180.051025,514.108826 180.057983,514.158020
	C175.100983,509.975800 168.203217,507.640167 166.587067,499.938263
	C170.263763,501.554810 172.856522,504.597534 175.790817,507.246155
	C177.080780,508.410553 178.027481,510.257721 180.598755,509.951965
	C181.124191,510.138367 181.285934,510.341278 181.325378,510.913696
z"
        />
        <path
          fill="#3E6978"
          opacity="1.000000"
          stroke="none"
          d="
M237.191528,534.852295
	C235.994888,535.194885 235.026810,535.278687 233.704483,535.399597
	C229.912903,533.926025 227.278366,531.910217 225.260239,528.360840
	C223.543701,525.341919 219.389359,525.015015 215.822479,525.007812
	C207.527084,524.991089 199.228195,525.139099 190.938019,524.930298
	C187.824249,524.851868 184.030136,525.305481 182.984909,520.455078
	C183.283188,519.715698 183.668365,519.476196 184.482422,519.391724
	C187.505981,520.911804 190.182373,523.215027 193.191162,519.241150
	C194.747086,518.546448 196.034332,518.503357 197.747040,518.676331
	C201.764511,519.298767 205.388321,519.606567 209.057434,519.535583
	C213.688019,519.445984 218.260956,519.466370 222.906662,520.388977
	C228.125275,521.425293 233.353821,520.377075 238.560852,518.844299
	C248.460449,515.930237 256.543182,521.829895 257.411560,532.106018
	C257.978485,538.814819 255.607773,540.569763 248.423187,538.252075
	C248.104767,537.979980 247.919601,537.595032 247.855988,537.391174
	C245.164825,531.873413 241.737762,531.016418 237.191528,534.852295
z"
        />
        <path
          fill="#0D1920"
          opacity="1.000000"
          stroke="none"
          d="
M182.263443,518.013184
	C182.035782,516.541199 182.157684,515.291931 183.957794,515.036438
	C188.676453,514.366699 193.388611,513.651123 198.533310,512.938477
	C208.586166,510.903595 218.185104,508.366821 227.971710,507.752838
	C234.642731,507.334290 240.937363,505.114471 247.573074,504.920868
	C249.060349,504.877441 250.529236,504.544983 252.382401,505.295654
	C254.086395,506.766235 254.014206,508.161072 252.922897,509.974304
	C251.961716,510.900055 251.161865,511.205292 249.883270,511.397095
	C247.639923,511.616577 245.917252,512.393982 243.682892,512.462402
	C241.371887,512.527588 239.356766,512.156494 237.340332,513.703918
	C236.054199,514.244873 235.057632,514.349976 233.673660,514.362793
	C229.484009,514.554565 225.567490,513.700134 221.572632,515.612854
	C220.326172,516.181335 219.355576,516.278503 218.009216,516.238892
	C216.532410,516.074707 215.431091,515.564270 213.942886,516.132019
	C212.686142,516.418152 211.754013,516.475342 210.462753,516.462769
	C208.705750,516.519592 207.269928,516.289062 205.594086,517.078369
	C204.530716,517.451294 203.843719,517.778625 202.750381,518.164551
	C201.017151,518.640808 199.647919,518.669495 197.842133,518.506470
	C192.802353,517.645813 188.217957,520.353638 183.210266,518.406006
	C182.823196,518.199158 182.446732,518.084717 182.263443,518.013184
z"
        />
        <path
          fill="#214A59"
          opacity="1.000000"
          stroke="none"
          d="
M182.846832,518.510498
	C187.622635,518.029175 192.315079,516.510315 197.625122,517.119019
	C198.657379,517.835510 198.752350,518.586914 198.260895,519.719055
	C196.628937,520.035645 195.242752,520.037109 193.440826,520.049194
	C190.751999,522.120728 188.289047,521.646545 185.379425,520.336304
	C184.529510,520.061646 184.083603,520.052734 183.302917,520.058350
	C182.935577,519.655823 182.903015,519.238770 182.846832,518.510498
z"
        />
        <path
          fill="#5B555B"
          opacity="1.000000"
          stroke="none"
          d="
M209.319458,489.244324
	C209.085938,483.978851 205.599655,483.228210 201.756714,483.021454
	C195.720413,482.696777 189.652161,483.558075 183.316864,482.285339
	C189.306076,481.138855 195.586563,479.827362 201.919037,479.488983
	C211.607697,478.971283 221.343262,479.355652 231.036743,478.892792
	C235.666962,478.671692 239.761200,480.553986 244.581207,481.007874
	C246.367401,483.572998 249.693085,484.636475 250.308594,488.201141
	C250.544159,489.783173 251.034622,490.974457 250.579193,492.587036
	C249.944092,493.565430 249.265976,493.846161 248.131500,493.968567
	C247.522705,493.929291 247.283691,493.885345 246.707916,493.714417
	C242.921494,492.020874 240.937210,487.859833 236.171341,488.027405
	C235.378571,488.005005 234.933212,487.936584 234.183746,487.714600
	C232.903259,486.967621 231.944717,486.974182 230.679428,487.766968
	C228.972183,488.054352 227.578018,487.992096 225.835327,487.925171
	C223.038727,486.027679 220.873428,486.409393 218.589020,488.815674
	C215.360367,489.033569 212.600662,490.777679 209.319458,489.244324
z"
        />
        <path
          fill="#715F62"
          opacity="1.000000"
          stroke="none"
          d="
M249.358551,489.282166
	C249.298706,485.684448 244.972183,484.917389 245.002106,481.379608
	C245.490921,481.265076 245.991348,481.516022 246.749329,481.877533
	C253.900711,485.554596 261.302551,485.976318 268.745544,484.940613
	C276.744049,483.827576 280.097748,480.702759 279.989258,472.406342
	C282.447693,472.488586 284.859711,473.638031 287.741547,472.340118
	C288.611694,471.931366 289.105835,472.056061 289.755371,472.824219
	C290.509247,474.560852 290.674866,476.057800 291.120697,477.475098
	C293.149536,483.924500 292.682770,484.707001 286.063599,485.784302
	C284.933960,485.968140 283.727173,485.861786 282.382874,486.816925
	C278.489380,488.119354 274.705017,486.719788 271.210480,488.083466
	C269.527496,488.740234 267.711273,489.168915 266.053955,490.818054
	C260.227692,491.894257 254.910751,491.373718 249.358551,489.282166
z"
        />
        <path
          fill="#A67A5E"
          opacity="1.000000"
          stroke="none"
          d="
M242.981125,472.248413
	C243.430649,471.985443 243.871887,471.981628 244.643951,471.971283
	C247.126419,471.543915 248.993576,472.287415 250.895218,473.770264
	C250.509216,472.374908 249.332062,472.136505 248.952408,470.883148
	C251.515839,467.402557 253.820450,470.166809 255.793884,471.213623
	C257.859680,472.309387 257.484741,474.970001 256.435638,476.552856
	C255.078735,478.600189 253.746216,481.441650 250.334656,480.561005
	C249.389938,480.317169 248.535919,479.722015 247.318695,479.148285
	C246.394196,477.341309 244.719208,477.177368 243.214142,476.233643
	C242.423111,475.327454 242.261246,474.567047 242.722992,473.372375
	C242.951309,472.852631 242.962082,472.680084 242.981125,472.248413
z"
        />
        <path
          fill="#5B555B"
          opacity="1.000000"
          stroke="none"
          d="
M150.715317,485.675446
	C143.355499,488.100891 135.608490,489.473297 127.299751,488.260101
	C134.617416,485.442322 142.547256,485.706238 150.715317,485.675446
z"
        />
        <path
          fill="#87655F"
          opacity="1.000000"
          stroke="none"
          d="
M242.518921,473.031433
	C242.962372,473.768585 242.984222,474.511993 243.000336,475.627686
	C238.861435,475.762543 234.689774,476.534882 230.272491,475.251129
	C233.908417,473.723816 238.030182,473.551941 242.518921,473.031433
z"
        />
        <path
          fill="#5B555B"
          opacity="1.000000"
          stroke="none"
          d="
M160.556152,484.975037
	C159.145203,488.666870 156.157730,486.644928 153.264069,486.198792
	C155.179840,484.200592 157.747314,485.280975 160.556152,484.975037
z"
        />
        <path
          fill="#5B555B"
          opacity="1.000000"
          stroke="none"
          d="
M176.564392,482.988007
	C175.422577,486.645782 172.211365,484.358673 169.361176,484.947968
	C170.567825,481.453003 173.719452,483.592834 176.564392,482.988007
z"
        />
        <path
          fill="#87655F"
          opacity="1.000000"
          stroke="none"
          d="
M225.064026,475.483521
	C225.744705,475.135223 226.506699,475.087006 227.651154,475.056580
	C227.472168,476.945007 226.637680,477.703827 225.064026,475.483521
z"
        />
        <path
          fill="#95D1E2"
          opacity="1.000000"
          stroke="none"
          d="
M211.995758,543.553162
	C212.723755,542.963867 213.453384,542.815063 214.551086,542.736572
	C215.883057,543.993591 217.236679,543.304626 218.776596,543.586853
	C223.412567,548.207520 227.852600,552.585632 232.307434,556.960815
	C236.487198,561.065918 236.457397,565.805908 232.980743,569.934570
	C220.658035,573.539124 208.430389,575.473022 195.604401,576.177856
	C194.324615,575.904297 193.501694,575.668640 192.989136,574.429199
	C197.883362,573.629517 201.100327,569.391724 205.823029,568.503540
	C208.650452,567.971802 211.469635,569.336670 214.404495,567.522339
	C217.995880,565.302185 219.746719,561.315002 216.676437,558.449219
	C212.246368,554.314392 211.720139,549.399109 211.995758,543.553162
z"
        />
        <path
          fill="#99D7E8"
          opacity="1.000000"
          stroke="none"
          d="
M192.654495,574.001343
	C193.471466,574.311462 193.951782,574.628357 194.728165,574.980835
	C195.390396,575.424377 195.517868,575.896851 195.190887,576.752930
	C193.917130,577.048584 192.859146,577.025085 191.404510,576.995483
	C190.744568,576.505188 190.481277,576.020996 190.096786,575.289551
	C190.543381,574.215332 191.390289,574.020386 192.654495,574.001343
z"
        />
        <path
          fill="#33616C"
          opacity="1.000000"
          stroke="none"
          d="
M198.329880,520.301636
	C198.028000,519.317505 198.040863,518.600830 198.066147,517.530579
	C199.459061,517.137756 200.839569,517.098450 202.634750,517.095581
	C207.031387,517.401489 210.944031,517.145691 215.036591,515.458862
	C215.915405,515.255371 216.424500,515.329712 217.307358,515.660706
	C224.835220,515.822327 231.927292,515.412048 238.941864,513.956177
	C254.225021,510.784119 263.969452,517.292847 267.085846,532.699585
	C267.817047,536.314514 268.180603,539.942444 267.157104,543.986328
	C266.229645,546.059998 264.479431,546.257690 262.719238,547.123962
	C256.696960,547.464722 253.909836,542.335571 249.117142,540.176758
	C248.536118,539.583984 248.299484,539.077637 248.083801,538.275635
	C257.091949,538.029541 258.035126,536.712280 255.171951,528.109680
	C253.155701,522.051758 246.073578,518.178650 240.044189,520.182129
	C232.923965,522.548096 226.028946,523.199768 218.501053,521.337036
	C212.132812,519.761292 205.256790,521.633423 198.329880,520.301636
z"
        />
        <path
          fill="#85C1D1"
          opacity="1.000000"
          stroke="none"
          d="
M233.266968,569.161499
	C235.347656,564.261292 233.640564,558.984253 228.412659,555.261658
	C224.456741,552.444824 221.204208,549.103821 219.107071,544.286987
	C218.794708,543.699707 218.637207,543.423096 218.679718,542.713074
	C221.984940,541.840454 225.090164,541.401367 228.599762,540.996948
	C229.431686,541.279541 229.835968,541.560547 230.225891,542.243286
	C232.830063,543.989563 225.757080,541.757629 229.567841,544.751648
	C235.925446,549.746521 241.016632,556.257751 244.547928,563.666748
	C246.733566,568.252441 243.931122,572.754150 238.408401,573.638245
	C237.031723,571.601501 236.073563,569.593018 233.266968,569.161499
z"
        />
        <path
          fill="#6494A3"
          opacity="1.000000"
          stroke="none"
          d="
M236.771545,540.214783
	C239.117950,538.892639 241.261337,539.222168 243.747482,539.801453
	C247.120071,544.270691 249.405792,549.499207 256.186279,549.546021
	C256.976990,549.278564 257.452301,549.227844 258.296448,549.318970
	C258.872406,549.524048 259.086945,549.650146 259.535034,550.070374
	C260.003601,550.812805 260.166046,551.285950 260.293335,552.158569
	C260.339386,554.554260 259.810150,556.480530 259.157959,558.759521
	C256.490631,562.376526 254.484924,560.330078 252.599930,558.389648
	C248.020706,553.675903 243.346786,549.077271 237.633057,545.058044
	C236.897827,544.503418 236.568146,544.094238 236.178696,543.243896
	C235.920395,542.027588 236.059769,541.247864 236.771545,540.214783
z"
        />
        <path
          fill="#67BFC6"
          opacity="1.000000"
          stroke="none"
          d="
M248.824432,540.313538
	C252.120621,538.496338 253.460022,542.006531 255.780807,542.982483
	C257.786865,543.826050 259.411926,545.575684 261.902740,546.993530
	C266.524200,548.190369 268.804657,551.362976 271.471924,554.490845
	C271.484924,555.320007 271.309601,555.745422 270.679901,556.264343
	C268.571106,556.476440 266.711548,556.949585 264.806061,555.512573
	C264.310699,555.257629 264.115082,555.151917 263.643402,554.863037
	C263.191376,554.549011 263.015411,554.418152 262.343567,553.864990
	C260.963318,552.518799 259.910553,551.735046 259.216888,550.238159
	C259.032776,549.976685 259.003754,549.997559 259.012329,549.981995
	C258.385834,549.482361 257.668243,549.185852 256.601044,548.958008
	C256.134308,548.800720 255.956039,548.713562 255.534668,548.455139
	C254.989655,548.029236 254.706604,547.755005 254.201447,547.290283
	C253.808350,546.994141 253.654266,546.870911 253.271912,546.557861
	C252.892242,546.238831 252.740814,546.109680 252.138855,545.580383
	C251.104385,544.615173 250.552765,544.015869 249.654602,543.330200
	C249.247940,542.998901 249.101303,542.845703 248.804779,542.410400
	C248.635086,541.915894 248.615311,541.703491 248.789642,541.236694
	C248.983749,540.982239 248.876129,540.536743 248.824432,540.313538
z"
        />
        <path
          fill="#478D9B"
          opacity="1.000000"
          stroke="none"
          d="
M257.177002,549.813232
	C254.936554,553.821106 252.001633,551.165649 250.560287,550.139343
	C247.603973,548.034241 245.763306,544.549072 242.684158,542.329651
	C241.958496,541.806580 243.214661,541.030334 243.893158,540.197144
	C245.184784,540.523254 246.283279,541.099792 247.661804,541.820068
	C248.124893,541.838562 248.307968,541.713318 248.770203,541.769165
	C249.049362,541.950134 249.000610,541.990967 249.025421,541.974487
	C249.996414,543.111694 251.098663,544.061340 252.771179,544.782959
	C253.030563,544.961670 253.003143,544.997559 253.014771,544.980713
	C253.218933,545.219116 253.433380,545.454407 253.854431,545.821472
	C254.039108,545.973206 253.995895,545.990906 254.014511,545.975098
	C254.527039,546.535217 255.072250,547.061279 255.828644,547.776978
	C256.042542,547.963928 256.000244,548.000000 256.014771,547.976379
	C256.552765,548.394836 257.121490,548.798950 257.177002,549.813232
z"
        />
        <path
          fill="#457483"
          opacity="1.000000"
          stroke="none"
          d="
M237.405670,535.127686
	C237.202072,534.505554 237.501038,533.916199 237.669571,533.291626
	C238.308075,530.925598 237.679306,525.685364 241.823853,531.524231
	C241.954453,531.708252 242.512421,531.794922 242.738144,531.675537
	C249.279160,528.214905 246.869705,534.585938 248.046539,536.956055
	C244.830063,534.791199 241.290390,535.205322 237.405670,535.127686
z"
        />
        <path
          fill="#759EB0"
          opacity="1.000000"
          stroke="none"
          d="
M236.673431,539.867371
	C237.014008,540.675293 237.003189,541.453857 236.985474,542.621094
	C235.600082,544.306824 234.226059,544.176880 232.665085,542.574219
	C232.484451,541.843140 232.494095,541.534546 232.695892,540.804688
	C234.032700,540.201538 235.177353,540.019775 236.673431,539.867371
z"
        />
        <path
          fill="#9FDAEC"
          opacity="1.000000"
          stroke="none"
          d="
M218.477997,542.232056
	C218.890594,542.562500 218.901459,542.845459 218.932281,543.552368
	C217.738586,543.932251 216.471008,544.203979 215.155899,543.087524
	C215.971527,542.599426 217.023880,542.391968 218.477997,542.232056
z"
        />
        <path
          fill="#83AEBF"
          opacity="1.000000"
          stroke="none"
          d="
M232.557434,540.377991
	C232.903183,540.651978 232.918320,540.920654 232.959900,541.592041
	C232.748657,542.325928 232.427521,542.533325 231.738510,542.617371
	C231.262131,542.512024 231.082977,542.425354 230.751984,542.080933
	C230.612823,541.623901 230.625519,541.424561 230.800964,540.973511
	C231.384750,540.605469 231.805801,540.489014 232.557434,540.377991
z"
        />
        <path
          fill="#7DA7B8"
          opacity="1.000000"
          stroke="none"
          d="
M230.626556,540.677246
	C230.971283,540.934021 230.978836,541.146179 230.991058,541.676758
	C230.829895,542.110046 230.664062,542.225037 230.246979,542.171204
	C229.695389,541.881104 229.442001,541.695618 229.119858,541.238770
	C229.432556,540.898621 229.860977,540.765625 230.626556,540.677246
z"
        />
        <path
          fill="#246876"
          opacity="1.000000"
          stroke="none"
          d="
M249.143280,541.700684
	C248.865295,541.980835 248.681213,542.011597 248.219482,542.003052
	C248.127151,541.700012 248.342148,541.463135 248.785278,541.117676
	C249.068222,541.138550 249.152710,541.294922 249.143280,541.700684
z"
        />
        <path
          fill="#4B555C"
          opacity="1.000000"
          stroke="none"
          d="
M475.724121,692.768433
	C479.563690,694.687683 482.919647,696.683899 485.927307,699.660156
	C478.510345,700.013000 474.187347,704.990173 470.854980,710.557495
	C463.428802,722.964172 456.827087,735.871887 449.199280,748.146606
	C446.320892,752.778442 441.537872,756.226990 437.618927,760.210815
	C437.504700,760.326904 437.306396,760.367615 437.209137,760.491882
	C427.695648,772.649353 414.597626,768.016357 402.480896,767.813477
	C401.502136,767.797119 400.357666,766.846008 399.606995,766.035767
	C397.179504,763.415833 394.331329,761.693176 390.984192,760.338440
	C386.362518,758.467834 383.078857,754.223877 379.970917,750.803955
	C373.229950,743.386292 367.358215,734.947205 362.420837,726.197937
	C358.733124,719.663147 356.870636,712.049377 354.598511,704.789917
	C353.753448,702.089905 355.765808,700.167725 358.643982,699.055725
	C362.113312,708.518555 367.890350,716.738831 372.628113,725.377258
	C376.307800,732.086426 380.809875,739.203613 388.916962,742.239624
	C391.275665,743.122925 393.109650,744.885742 395.041840,746.474915
	C401.283997,751.609070 408.642151,752.750183 416.492798,752.834717
	C434.542938,753.029114 442.291595,740.138367 449.793915,727.001160
	C455.962433,716.199585 460.936218,704.659851 469.713562,695.525574
	C471.252045,693.924561 472.499725,691.548218 475.724121,692.768433
z"
        />
        <path
          fill="#535C64"
          opacity="1.000000"
          stroke="none"
          d="
M476.006409,692.624023
	C474.019379,692.715088 473.185791,694.201599 471.994904,695.386230
	C462.631226,704.700745 457.072021,716.463257 451.242340,727.975830
	C446.012970,738.302856 438.606689,746.450012 429.136566,752.883484
	C428.595673,753.250854 427.943237,753.626831 427.318848,753.672668
	C416.982910,754.430420 406.406036,756.167175 397.060425,749.934265
	C387.947754,743.856506 378.426300,738.223022 372.101776,728.718933
	C370.266174,725.960571 370.568054,722.437439 368.461639,720.091248
	C364.524292,715.705811 362.704498,710.176331 359.761200,705.267517
	C358.663177,703.436279 357.932312,701.755310 358.950134,699.356689
	C362.246948,694.768250 367.878357,694.746582 371.942139,691.439270
	C373.440704,691.052002 374.846161,690.999268 376.558807,690.917114
	C377.629608,690.392761 378.438019,690.178894 379.671417,690.763062
	C377.890930,694.362854 373.861298,693.241699 370.930908,694.886902
	C367.607697,696.752686 365.375946,700.954834 367.747650,703.868408
	C373.845276,711.359192 377.117310,720.678467 383.909607,727.772827
	C386.334839,730.305969 389.061340,731.811646 391.851929,733.505432
	C395.951294,735.993652 399.853638,739.034668 404.267212,740.723755
	C410.981750,743.293640 417.894775,745.029602 424.324982,739.046997
	C426.359985,737.153748 429.956085,737.060303 432.527496,735.597778
	C439.092682,731.863892 441.326050,724.713928 445.218933,718.855225
	C451.135620,709.950684 452.535339,698.898987 459.169220,690.273865
	C461.139160,687.712524 463.355286,685.652344 465.854584,683.281006
	C469.919678,683.380615 474.854614,687.960510 476.006409,692.624023
z"
        />
        <path
          fill="#4C707C"
          opacity="1.000000"
          stroke="none"
          d="
M140.651489,630.944275
	C142.102463,631.328308 143.195450,631.664490 144.635284,632.010071
	C145.278412,635.361694 149.133102,636.293762 149.963470,639.647583
	C146.279236,641.602417 144.299286,638.172974 141.701492,636.862183
	C138.722549,635.359009 137.429031,635.498047 136.314743,638.887878
	C133.363785,647.864990 134.265305,656.835754 136.927170,665.450806
	C139.915146,675.121277 141.550186,685.321167 146.385666,694.325012
	C154.152130,708.786377 160.741119,724.146057 174.377579,734.532227
	C181.077927,739.635437 188.110504,743.425537 197.045547,743.647217
	C203.456604,743.806213 209.838852,746.005737 216.371262,745.289612
	C228.494629,743.960571 239.491684,740.372253 246.388824,729.210815
	C246.651291,728.786072 246.960602,728.389160 247.282593,728.007568
	C252.295670,722.066345 256.487030,715.796387 255.783325,707.166992
	C257.079529,706.898987 258.140411,706.925293 259.594666,707.014404
	C263.686676,719.529724 257.293274,728.643188 249.736465,737.189331
	C246.462479,740.892029 242.584900,744.184692 239.287430,747.776611
	C233.885391,753.660950 227.043488,753.573730 220.357925,755.026062
	C208.921036,757.510498 197.697891,755.539368 187.312149,751.527710
	C178.640686,748.178284 170.239029,744.002625 164.157516,735.364929
	C155.812408,723.512390 147.566269,711.513916 141.653748,698.499390
	C134.289459,682.289185 127.418457,665.447144 128.964233,646.798584
	C129.210938,643.822327 128.979050,640.807800 129.003403,637.811279
	C129.081223,628.235352 132.180313,626.341858 140.651489,630.944275
z"
        />
        <path
          fill="#465962"
          opacity="1.000000"
          stroke="none"
          d="
M250.671844,657.224487
	C251.802917,656.697998 252.607346,656.395996 253.736298,656.070923
	C254.844757,656.351685 255.628708,656.655518 256.729584,657.003784
	C257.034241,657.488892 257.021973,657.929688 257.006073,658.702271
	C255.938522,659.035767 254.874603,659.037476 253.411896,659.049683
	C252.140747,659.798462 251.138504,660.022583 249.663574,659.649048
	C248.116547,658.018372 249.242538,657.739685 250.671844,657.224487
z"
        />
        <path
          fill="#5C8997"
          opacity="1.000000"
          stroke="none"
          d="
M168.723984,662.701111
	C169.016006,662.983704 169.506226,662.993713 169.751312,662.999268
	C172.405060,663.698059 173.791824,666.603333 177.067093,666.081177
	C178.260773,666.000671 179.130356,666.005615 180.319031,666.091431
	C181.807617,666.643127 182.983307,666.905029 184.518250,666.155334
	C185.574677,666.046265 186.284866,666.094055 187.300049,666.536133
	C187.993973,667.066711 188.290497,667.761353 188.612717,667.797119
	C195.721359,668.586548 202.848190,669.652344 210.168518,667.009155
	C210.903198,666.471863 211.385941,666.305176 212.283051,666.259033
	C215.821442,667.701965 219.013046,668.268311 222.631042,666.485352
	C223.477692,666.307495 223.961960,666.367981 224.713715,666.776855
	C229.643768,667.348389 234.290787,667.827026 238.946991,667.946594
	C242.113281,668.027954 245.209274,668.220764 248.290436,669.016663
	C255.627899,670.911987 258.172302,674.845520 256.649017,682.475891
	C255.720367,687.127808 254.412094,691.739075 256.971649,696.681519
	C255.873413,696.767090 254.769928,696.460938 253.339645,696.042725
	C248.602127,692.527222 243.964081,689.389465 240.141632,684.744080
	C239.553299,677.979187 235.596710,675.686523 229.705994,675.220276
	C220.052643,674.456238 210.408600,674.815857 200.753891,674.972107
	C192.605789,675.104126 184.449310,675.109314 176.376144,673.655945
	C173.741333,673.181702 171.261993,672.171265 168.664566,671.565613
	C166.064590,670.959351 164.867844,671.934937 164.846375,674.546692
	C164.778259,682.830261 167.304779,690.369934 174.067200,695.153870
	C186.613480,704.029358 201.075623,706.770508 216.248306,705.952881
	C223.155548,705.580627 228.513138,702.254639 232.740799,696.810486
	C234.376404,694.704285 235.674927,691.675476 239.660187,692.762939
	C240.096725,693.361938 240.213043,693.726501 240.365479,694.376404
	C240.915680,696.121460 241.776917,697.414551 242.579910,699.174805
	C244.226517,705.501404 242.743164,710.507263 238.356232,714.931335
	C230.982971,722.367065 221.926270,725.435120 211.760345,725.677307
	C197.920364,726.007019 184.350586,724.819580 173.190781,715.366272
	C167.093399,710.201294 160.107666,705.561829 158.807266,696.580566
	C158.494995,694.423889 156.858429,692.754883 155.643692,690.967590
	C148.987564,681.173889 147.327484,670.026123 147.483780,658.597290
	C147.578110,651.699341 152.083435,649.624207 157.932419,653.303284
	C161.883026,655.788269 165.137283,659.142395 168.723984,662.701111
z"
        />
        <path
          fill="#496A76"
          opacity="1.000000"
          stroke="none"
          d="
M215.792694,663.577759
	C216.138138,663.148499 216.301315,663.104553 216.712128,663.006348
	C221.813309,660.339355 227.109329,660.563354 232.706635,660.834839
	C232.908615,663.601440 229.731812,662.390808 229.071198,664.661255
	C228.550278,664.994324 228.107376,664.995911 227.332245,664.998779
	C225.130157,664.881470 223.524536,666.012695 221.756775,666.177307
	C219.443954,666.392700 216.705673,667.312073 215.792694,663.577759
z"
        />
        <path
          fill="#517581"
          opacity="1.000000"
          stroke="none"
          d="
M215.715790,663.103394
	C217.221130,666.221436 219.775223,664.846680 221.816742,664.784729
	C223.352844,664.738281 224.826981,663.548889 226.727737,664.742065
	C226.442078,665.517273 225.884171,666.034546 225.159546,666.787292
	C224.554443,667.022339 224.116043,667.021912 223.348755,667.022949
	C220.446487,668.663269 217.644669,667.891785 214.488983,667.585693
	C213.213089,666.336121 212.895020,665.197449 213.743469,663.536499
	C214.036560,663.170288 214.501526,663.008179 214.726135,662.905823
	C215.119354,662.873718 215.287994,662.944031 215.715790,663.103394
z"
        />
        <path
          fill="#3E505A"
          opacity="1.000000"
          stroke="none"
          d="
M250.637848,656.993103
	C250.444687,657.512512 249.890854,658.024963 249.171631,658.769897
	C248.182388,660.201660 246.898529,659.913696 245.375824,660.028320
	C244.266632,660.041321 243.516541,660.040344 242.391602,660.053467
	C240.996399,660.774780 240.008148,660.861633 239.045441,659.505005
	C239.021942,658.640076 239.026398,658.185364 239.049469,657.390991
	C240.910400,656.863525 242.752731,656.675720 244.796967,656.274292
	C245.444717,656.051453 245.890564,656.042236 246.670990,656.034851
	C247.876404,657.110168 249.103378,656.956604 250.637848,656.993103
z"
        />
        <path
          fill="#496A76"
          opacity="1.000000"
          stroke="none"
          d="
M238.653351,659.082642
	C239.611588,659.870911 240.465286,659.990295 241.685806,660.043274
	C242.010101,660.796082 242.003448,661.524597 241.992828,662.618042
	C240.014832,663.089111 238.280701,662.260254 236.256958,661.337585
	C235.617462,660.704407 235.479401,660.237122 235.848663,659.409546
	C236.834213,659.105408 237.561707,659.087891 238.653351,659.082642
z"
        />
        <path
          fill="#496A76"
          opacity="1.000000"
          stroke="none"
          d="
M245.308014,660.296326
	C245.855606,658.911743 247.093262,659.043396 248.642731,659.006958
	C250.074707,659.010132 251.143188,659.017883 252.612396,659.042908
	C251.118225,662.424500 248.227280,660.833313 245.308014,660.296326
z"
        />
        <path
          fill="#3E505A"
          opacity="1.000000"
          stroke="none"
          d="
M235.709427,659.083679
	C236.079330,659.560303 236.051956,659.997681 236.014633,660.766602
	C235.263809,661.085022 234.522934,661.071960 233.413589,661.089905
	C227.944656,661.458435 223.031555,663.315125 217.404861,662.973694
	C216.980942,662.251648 217.002121,661.551208 217.028320,660.503723
	C218.411804,660.113708 219.790314,660.070679 221.583405,660.025024
	C222.877304,659.985413 223.771988,660.021301 224.809631,659.285278
	C226.120773,659.064392 227.168777,659.044312 228.610077,659.021667
	C231.100693,660.422913 233.212128,658.308472 235.709427,659.083679
z"
        />
        <path
          fill="#6494A3"
          opacity="1.000000"
          stroke="none"
          d="
M240.019562,692.606079
	C236.791824,692.733398 235.768707,695.500366 234.471786,697.382202
	C227.869736,706.961304 218.598785,709.083984 207.837540,707.450928
	C206.856354,707.302002 205.848480,706.934814 204.896774,707.045471
	C192.639923,708.470947 182.860916,701.959778 172.806671,696.745544
	C166.388092,693.416870 160.944626,677.562256 163.081787,670.911072
	C163.815887,668.626465 165.617737,668.665405 167.084763,669.275513
	C176.506973,673.194580 186.551178,673.397522 196.387482,673.398254
	C204.976730,673.398926 213.610641,673.277710 222.214813,672.945801
	C226.420303,672.783508 230.484253,673.561462 234.557175,674.183533
	C239.751114,674.976868 240.997238,677.185364 240.968475,684.525940
	C241.269745,686.228577 240.899353,687.375732 240.222305,688.774292
	C240.124496,690.119629 240.091599,691.167236 240.019562,692.606079
z"
        />
        <path
          fill="#557E8B"
          opacity="1.000000"
          stroke="none"
          d="
M168.966995,662.643677
	C163.783722,661.165405 160.616302,656.324463 155.673203,653.973999
	C152.023743,652.238647 149.119308,653.166870 149.116516,657.317078
	C149.108307,669.520081 150.444336,681.471313 158.188080,691.682434
	C159.083435,692.863098 160.661911,694.304504 160.486893,695.394226
	C159.153366,703.696472 167.086075,706.363647 170.629089,711.426208
	C173.487289,715.510132 179.307114,718.125793 184.334686,719.972839
	C199.027603,725.370850 214.142075,726.402771 228.754074,719.937012
	C236.999863,716.288208 242.675018,709.922058 241.990662,699.449341
	C242.182800,698.858337 242.377121,698.697083 242.931686,698.652832
	C245.611542,699.654602 247.186935,701.502991 249.027740,703.437378
	C251.914413,707.681091 251.995010,711.830933 249.863724,716.012512
	C242.570877,730.321350 231.563812,739.009155 214.824509,738.726257
	C207.010223,738.594116 199.185776,739.209900 191.378830,738.058594
	C187.491028,737.485352 183.822052,736.460938 180.699814,734.169434
	C170.120514,726.404663 159.484314,718.867615 154.336960,705.767822
	C152.008316,699.841492 148.876587,694.002625 144.877701,688.826904
	C144.260437,688.027954 143.742905,687.111145 143.670792,686.114624
	C142.909790,675.598328 138.093750,665.888123 137.588303,655.314026
	C137.469040,652.819031 137.148636,650.331421 137.424301,647.838196
	C138.295380,639.960266 142.258362,637.727234 149.535675,641.323608
	C155.605652,644.394958 158.451065,650.251587 163.096283,654.681763
	C163.612991,656.013733 164.320465,656.684692 165.708588,656.935547
	C165.910217,659.340820 168.516602,660.219788 168.966995,662.643677
z"
        />
        <path
          fill="#517581"
          opacity="1.000000"
          stroke="none"
          d="
M150.249512,642.662964
	C141.856522,638.920288 138.966202,640.702087 139.023300,649.972473
	C139.079926,659.165833 141.328751,667.992859 143.895554,676.745239
	C144.081482,677.379211 144.611694,677.965576 144.633743,678.586426
	C145.080185,691.162964 154.603012,699.637146 159.042938,710.416504
	C162.086319,717.805298 167.213425,723.474182 174.868561,726.713074
	C177.370728,727.771790 178.181992,731.012268 180.809006,732.555908
	C184.929001,734.976807 189.276276,736.507446 193.873230,736.672058
	C203.673859,737.022949 213.849091,738.525391 223.216797,736.512390
	C235.873184,733.792725 244.914978,724.959412 249.637756,712.464478
	C250.636353,709.822510 248.395676,707.312561 249.081451,704.189575
	C250.211121,704.053345 251.301865,704.359558 252.697388,704.826050
	C253.539062,705.567932 254.127380,706.094055 255.006836,706.720215
	C255.275940,706.844055 255.648468,706.827576 255.833542,706.850098
	C258.654663,713.026062 256.254120,717.933716 253.021805,723.297546
	C245.733276,735.392395 236.028671,743.743408 221.954285,746.505371
	C216.668198,747.542725 211.243607,747.451355 206.042160,746.763184
	C200.967484,746.091980 195.799973,745.609558 190.820206,744.590698
	C178.026123,741.973328 168.698593,733.777161 161.403198,723.473328
	C153.272827,711.990234 145.387604,699.982178 141.377151,686.542603
	C137.580734,673.820374 131.993820,661.276917 132.851364,647.352600
	C133.114777,643.075439 134.838470,639.349182 135.582611,635.293640
	C136.100739,632.469910 138.409103,632.584595 140.639404,634.154602
	C143.474533,636.150330 146.416840,637.993896 149.661438,639.946289
	C150.009918,639.989502 150.001328,639.998169 150.006165,639.994202
	C150.600647,640.680237 150.767090,641.458862 150.249512,642.662964
z"
        />
        <path
          fill="#557E8B"
          opacity="1.000000"
          stroke="none"
          d="
M240.470978,689.163879
	C238.328644,687.660828 239.927643,686.565125 240.730835,685.163147
	C245.846344,687.344421 249.262970,691.437134 252.963837,695.593872
	C248.009583,695.404236 244.564117,692.023926 240.470978,689.163879
z"
        />
        <path
          fill="#496A76"
          opacity="1.000000"
          stroke="none"
          d="
M243.205261,698.452148
	C243.076447,698.820374 242.860962,698.870911 242.316986,698.970459
	C240.885666,698.050049 240.241379,696.857239 240.305344,695.014038
	C241.971390,695.299622 242.542770,696.718750 243.205261,698.452148
z"
        />
        <path
          fill="#496A76"
          opacity="1.000000"
          stroke="none"
          d="
M255.162964,706.543518
	C254.300659,706.910522 253.471024,706.715149 253.064117,705.317383
	C253.913177,705.033020 254.669571,705.331604 255.162964,706.543518
z"
        />
        <path
          fill="#5E6B74"
          opacity="1.000000"
          stroke="none"
          d="
M436.782318,679.217651
	C438.101471,678.701172 439.204132,678.402344 440.648926,678.069763
	C441.738098,680.815063 443.624634,679.873413 445.372009,679.375122
	C447.401428,678.796509 449.303955,678.785156 450.894348,680.472595
	C452.744537,682.435730 451.446686,684.215637 450.516632,685.922852
	C445.612213,694.925537 442.338440,704.756531 436.435120,713.265747
	C434.696655,715.771545 432.714478,717.908447 430.375366,719.781677
	C429.472015,720.505188 428.190918,720.993958 427.669495,721.920349
	C422.895813,730.401917 415.182739,727.972168 407.902863,727.492737
	C396.933563,726.770325 389.760254,720.480286 384.142853,711.747437
	C383.428436,710.636780 382.369019,709.720642 381.791473,708.554932
	C380.539185,706.027100 375.486969,704.461182 377.703796,701.509827
	C380.903442,697.250000 383.629425,691.860229 390.100586,690.901428
	C393.574097,690.386780 397.122864,689.402893 399.932434,686.448853
	C400.332489,685.676147 400.742554,685.388367 401.630798,685.187622
	C404.201721,685.927979 406.493164,684.779114 408.980011,685.708252
	C410.147095,688.350159 408.375946,689.267395 406.774078,690.128967
	C404.718536,691.234436 402.495972,692.142761 400.789948,693.711060
	C398.716766,695.616943 394.035065,695.277100 395.133301,700.027893
	C397.014038,708.163574 399.067902,710.539490 405.080658,711.310242
	C407.062927,711.564453 409.037323,712.055664 411.021332,712.075989
	C417.351532,712.140930 423.429169,711.617981 426.617554,704.767822
	C427.603119,702.650513 429.378296,701.024780 431.144257,699.483826
	C434.244171,696.778687 434.400818,693.075500 434.521027,689.395081
	C434.611603,686.621643 432.407013,686.869446 430.637939,686.708679
	C426.018433,686.288696 421.308899,687.372192 416.440735,685.662109
	C415.551422,684.699707 415.699646,684.041138 416.679810,683.215576
	C421.970367,681.609558 426.878174,679.911499 432.570312,679.999329
	C434.207642,679.923767 435.475067,680.236450 436.782318,679.217651
z"
        />
        <path
          fill="#394148"
          opacity="1.000000"
          stroke="none"
          d="
M402.382324,685.988892
	C401.554535,686.001038 401.115540,686.017395 400.349182,686.069580
	C396.098816,685.583862 392.852539,689.086548 388.400299,687.956787
	C387.494415,682.496582 382.941101,683.479919 379.289948,682.354736
	C379.673309,678.296082 383.098572,679.688354 385.757141,678.719849
	C388.293304,678.644409 390.444824,678.132202 392.699646,679.714233
	C397.791351,682.043091 402.794891,680.650940 407.686523,680.944397
	C409.810028,681.071777 410.430664,681.443787 409.012451,683.615112
	C407.554810,686.588318 404.997803,685.798706 402.382324,685.988892
z"
        />
        <path
          fill="#4B4D54"
          opacity="1.000000"
          stroke="none"
          d="
M380.385925,691.022278
	C379.226105,691.009155 378.440186,690.987244 377.260132,690.926514
	C378.665192,687.846191 381.822357,688.878784 384.730286,688.572021
	C384.279510,690.850342 382.582092,691.050842 380.385925,691.022278
z"
        />
        <path
          fill="#73676A"
          opacity="1.000000"
          stroke="none"
          d="
M386.334656,679.824463
	C384.159302,681.848450 381.538635,680.359863 379.264984,681.846558
	C377.300934,682.158325 375.601044,682.197632 373.477478,682.269043
	C369.258392,680.703979 368.394806,676.558167 365.798523,673.874023
	C362.876984,670.853638 359.506470,668.148010 357.498291,663.928528
	C357.847687,662.432190 358.650848,661.744751 359.994202,661.036377
	C365.579803,659.674011 370.264923,662.127686 374.897430,663.531677
	C383.086761,666.013916 391.374725,665.693359 399.568634,665.325012
	C410.154877,664.849121 420.712189,663.442322 431.287598,662.501709
	C433.607635,662.295349 435.452759,661.532471 436.995239,659.231445
	C441.689056,658.410583 445.757172,661.321411 447.286011,666.596802
	C437.398987,669.128845 428.299988,674.137573 417.468689,673.015442
	C412.352325,675.242676 407.276154,674.530762 402.399658,674.857239
	C398.524353,675.116699 394.822510,675.150208 391.744843,677.893921
	C390.291229,679.189758 388.228149,678.680664 386.334656,679.824463
z"
        />
        <path
          fill="#48505A"
          opacity="1.000000"
          stroke="none"
          d="
M490.311401,686.808350
	C488.372437,686.000305 486.896545,684.659363 484.416595,684.960022
	C483.068695,681.486328 478.952148,681.335938 477.152252,678.270691
	C476.404846,677.244751 476.234558,676.345398 476.824249,675.680908
	C482.665405,669.099426 486.181519,660.756409 493.153870,654.963440
	C498.047363,650.897644 503.769684,650.545410 509.884644,649.611145
	C511.670837,649.495239 512.938782,649.786682 514.182007,651.112061
	C514.002991,655.257202 510.438385,655.939270 508.106934,657.817200
	C501.074005,663.482056 494.702454,669.481750 495.436371,679.680298
	C495.686707,683.158997 494.546906,685.907959 490.311401,686.808350
z"
        />
        <path
          fill="#5B555B"
          opacity="1.000000"
          stroke="none"
          d="
M510.217407,650.615967
	C507.207458,651.427795 504.385529,651.676453 501.660156,652.328613
	C494.303802,654.088867 489.916290,659.807373 485.989105,665.469482
	C483.166992,669.538391 480.830933,673.910706 477.064789,677.639709
	C473.068298,675.364197 469.014801,672.942017 465.506012,669.730164
	C464.266510,668.595520 462.991028,667.612061 463.013123,665.375549
	C474.486969,660.513306 482.669373,649.649658 496.050140,647.949829
	C500.320251,647.204163 504.180939,646.183899 508.577362,647.067993
	C510.041840,647.959656 510.629456,648.909973 510.217407,650.615967
z"
        />
        <path
          fill="#635B5F"
          opacity="1.000000"
          stroke="none"
          d="
M495.699341,648.669006
	C495.504547,649.914612 494.837494,650.773743 493.788696,650.835754
	C485.694794,651.313721 479.812408,656.085022 474.124725,661.077393
	C471.106293,663.726807 467.935364,665.432129 463.425262,665.006592
	C461.246094,664.370667 459.483643,663.734009 457.371002,663.054199
	C455.131500,663.264221 453.687866,662.857910 453.607269,660.227356
	C460.032013,656.587891 466.219666,653.046570 473.065613,651.062988
	C477.023834,649.916138 480.951324,648.657959 485.359711,647.573730
	C488.984131,647.879211 492.315704,646.590698 495.699341,648.669006
z"
        />
        <path
          fill="#5E6B74"
          opacity="1.000000"
          stroke="none"
          d="
M386.481201,680.095764
	C390.839569,676.437927 394.873810,671.747986 402.267059,673.359375
	C406.640198,674.312439 411.464386,673.196106 416.547913,673.017944
	C414.393982,676.362549 410.650909,675.878723 407.056976,676.034180
	C404.888367,676.127930 402.240875,675.179504 401.079681,678.623169
	C398.548615,679.265015 396.017548,678.582336 393.302582,679.779175
	C390.978058,680.062805 388.946259,680.077209 386.481201,680.095764
z"
        />
        <path
          fill="#48505A"
          opacity="1.000000"
          stroke="none"
          d="
M447.495850,666.967651
	C445.505188,663.063171 441.226288,661.995605 438.429565,658.951111
	C437.679718,658.551819 437.434906,658.125549 437.372681,657.223145
	C437.679352,655.809631 438.281433,655.033936 439.622101,654.482910
	C446.382874,656.817688 451.459137,661.185059 456.723022,665.877869
	C456.391602,668.454041 454.650299,667.996338 453.084198,667.798950
	C451.350891,667.580444 449.630920,667.255798 447.495850,666.967651
z"
        />
        <path
          fill="#64777D"
          opacity="1.000000"
          stroke="none"
          d="
M456.958679,665.772705
	C450.780609,663.691467 446.035889,659.052124 440.330933,655.289551
	C439.652863,654.817322 439.223053,654.560669 438.631348,653.957642
	C438.533234,653.372559 438.600281,653.128479 438.943665,652.622803
	C440.141907,651.830078 441.136810,651.769226 442.525940,651.985901
	C444.789337,653.127625 446.781525,653.979004 449.175781,654.832703
	C451.509125,655.974915 453.842346,656.604187 454.170044,659.659790
	C454.022858,661.735779 455.575836,661.857483 456.806152,662.768066
	C457.008331,663.799683 456.995850,664.588135 456.958679,665.772705
z"
        />
        <path
          fill="#59646C"
          opacity="1.000000"
          stroke="none"
          d="
M402.253296,686.241821
	C403.725525,684.096375 406.397125,685.602600 408.613586,684.082336
	C411.336761,683.454285 413.686798,682.712219 416.583923,682.990173
	C416.743103,683.489258 416.486481,683.988708 416.116760,684.745361
	C414.686737,688.502380 412.253510,687.689392 409.379730,686.314941
	C406.847931,686.259644 404.785919,687.777893 402.253296,686.241821
z"
        />
        <path
          fill="#59646C"
          opacity="1.000000"
          stroke="none"
          d="
M436.635986,678.996521
	C436.546661,681.209473 435.188660,681.272644 433.237427,680.244385
	C433.854156,678.891479 435.085144,679.011963 436.635986,678.996521
z"
        />
        <path
          fill="#3E474F"
          opacity="1.000000"
          stroke="none"
          d="
M490.278870,687.152344
	C493.884949,683.942444 495.015320,680.223511 493.182129,675.484009
	C492.561035,673.878235 493.837311,672.338135 494.690002,670.903503
	C497.055420,666.923950 499.796814,663.085144 502.630524,659.565308
	C505.209473,656.362000 509.818237,654.792847 513.764893,652.255371
	C516.237854,649.759583 518.731384,649.997498 521.666992,651.626465
	C521.676575,653.417419 521.348389,654.842102 521.015015,656.635742
	C520.533142,658.233093 520.335449,659.693176 519.536987,660.654297
	C510.786285,671.188232 508.019073,682.945862 511.939606,696.173523
	C512.121826,696.788330 511.986023,697.497437 511.996735,698.582520
	C510.458496,698.869690 508.921844,698.737000 507.152832,698.840698
	C505.753143,698.552734 504.585785,698.028259 503.207825,697.264221
	C502.463684,696.479370 501.931396,695.932861 501.175476,695.205444
	C497.280151,692.745300 493.553711,690.533875 490.278870,687.152344
z"
        />
        <path
          fill="#2C4653"
          opacity="1.000000"
          stroke="none"
          d="
M501.029846,695.319458
	C501.869110,695.107971 502.636169,695.393005 502.943359,696.707703
	C502.115997,696.896790 501.350311,696.610596 501.029846,695.319458
z"
        />
        <path
          fill="#625457"
          opacity="1.000000"
          stroke="none"
          d="
M361.285583,683.940796
	C358.829254,685.370422 356.340912,685.780579 353.417908,686.013489
	C352.038452,683.589050 350.880432,681.207947 350.107208,678.707886
	C349.215851,675.825928 347.413513,673.976135 344.975006,672.270996
	C338.894653,668.019348 332.835815,663.371094 324.987305,664.360840
	C320.058105,664.982422 316.122009,663.885803 311.936646,661.569885
	C304.587921,657.503479 304.474518,657.708435 305.015442,649.400940
	C305.861450,647.932251 306.997040,647.886047 308.599609,648.206360
	C314.631531,656.561462 323.264099,658.473450 332.320953,659.154480
	C337.302704,659.529053 342.271606,659.789307 347.500244,661.116577
	C350.542511,666.379272 351.991730,672.043457 355.864868,676.602356
	C357.766602,678.840698 359.928314,680.967896 361.285583,683.940796
z"
        />
        <path
          fill="#623B37"
          opacity="1.000000"
          stroke="none"
          d="
M309.289368,649.216858
	C307.919434,648.986267 306.864532,648.984680 305.414703,648.986694
	C304.768188,648.518127 304.516571,648.046082 304.156372,647.332458
	C305.326294,646.233337 306.739227,645.883423 308.641785,645.981079
	C309.408997,645.620789 309.875519,645.452637 310.776764,645.618774
	C311.299805,645.961243 311.459534,646.166321 311.734161,646.745544
	C311.891876,647.649536 311.810455,648.159668 311.360626,648.955688
	C310.634521,649.504333 310.130554,649.565979 309.289368,649.216858
z"
        />
        <path
          fill="#635B5F"
          opacity="1.000000"
          stroke="none"
          d="
M361.588776,684.254517
	C354.490631,678.558472 348.657104,672.299805 347.994843,662.450806
	C351.471527,660.158264 354.579620,661.080566 357.756897,663.651611
	C363.891907,669.062012 369.265442,674.555420 372.923645,681.950989
	C369.563354,684.036377 365.779877,684.075195 361.588776,684.254517
z"
        />
        <path
          fill="#243A45"
          opacity="1.000000"
          stroke="none"
          d="
M359.087158,410.389465
	C364.470734,408.366425 369.928314,406.706268 375.362244,404.971954
	C377.210480,404.382080 379.239777,403.934509 379.957336,401.314301
	C378.805542,398.348999 380.897003,398.204590 382.444275,397.868866
	C392.219604,395.747772 402.084076,394.518494 412.110657,395.268585
	C418.552826,395.750549 424.802887,397.535614 431.392731,397.345062
	C438.583740,397.137146 443.341766,394.500641 445.862000,387.563141
	C447.786499,382.265594 451.529755,380.229309 457.198364,380.519989
	C463.425079,380.839264 469.252045,382.866333 475.087860,384.802765
	C477.645996,385.651611 479.294098,387.656128 479.870300,390.184143
	C481.153076,395.812073 484.703522,400.385834 486.724457,405.641602
	C488.269623,409.660065 491.758942,410.551941 495.495636,412.019958
	C497.045135,416.854889 496.490845,421.403870 495.976349,426.356995
	C494.388641,428.661652 492.328461,429.278412 490.135925,429.450378
	C477.742065,430.422272 465.392700,431.856018 453.017426,433.020172
	C426.127289,435.549713 399.156097,436.929382 372.239288,439.051483
	C365.503235,439.582520 358.832092,440.932343 351.182770,441.741669
	C354.976562,444.737946 360.337830,443.558594 361.764893,448.768127
	C361.596436,449.727234 361.312439,450.183075 360.539459,450.740356
	C359.949432,451.006409 359.703949,451.080200 359.084717,451.199097
	C357.347076,451.293823 355.923950,450.515808 354.297668,451.642731
	C353.505951,451.823090 353.044434,451.839569 352.245300,451.709442
	C351.070374,451.321381 350.374573,450.648163 349.063721,450.663025
	C345.051819,450.508667 341.470337,449.883606 337.633240,448.690552
	C335.208862,448.154846 333.219513,448.717682 330.862518,449.213287
	C327.611694,448.857056 324.744965,447.878082 321.413391,448.929565
	C319.638977,449.092194 318.474121,448.628784 317.280762,447.283752
	C318.138794,442.843170 320.540283,441.213928 325.159363,440.777466
	C332.151550,440.116821 338.816803,437.206421 345.975098,436.740265
	C354.191345,436.205170 362.373016,435.253204 370.600861,434.705963
	C379.812958,434.093231 388.880737,431.757324 398.214264,431.598145
	C400.377167,431.561249 403.109131,429.980591 402.668304,427.141815
	C402.218964,424.248352 402.190247,420.876617 397.881531,419.944550
	C388.323944,417.877167 378.600494,417.237427 368.944611,416.036438
	C364.897034,415.533020 362.037079,413.187988 359.087158,410.389465
z"
        />
        <path
          fill="#6D5A5B"
          opacity="1.000000"
          stroke="none"
          d="
M273.318787,459.270477
	C272.679993,456.250610 273.705078,454.380219 276.609070,454.158569
	C280.392670,453.869781 284.064667,452.584442 287.928314,453.011017
	C290.671021,453.313782 293.025146,452.709473 295.117249,450.562469
	C297.916168,447.690094 301.755859,449.499054 305.541565,448.904938
	C307.417786,448.904144 308.847534,448.952026 310.705200,449.029663
	C311.407104,449.457031 311.522095,449.892670 311.250183,450.660645
	C310.930847,451.055939 310.806732,451.162292 310.686493,451.272858
	C309.369659,452.483521 306.887482,451.166260 306.072449,453.366882
	C305.899475,453.833984 306.378876,454.391907 306.865967,454.445740
	C309.222198,454.706177 309.338318,452.167084 311.008240,450.988892
	C312.551147,449.792358 313.923584,449.923553 315.630859,450.618713
	C316.511566,451.166138 317.017395,451.628601 317.837097,452.223389
	C320.833710,453.047913 323.540863,453.622101 326.681702,453.544434
	C328.859100,454.259857 330.531769,453.414581 332.623291,452.975067
	C333.789185,452.921692 334.546082,453.096497 335.611755,453.675476
	C338.696808,458.061737 338.007996,460.365479 333.279022,461.198151
	C322.524841,463.091797 311.676819,464.253357 300.745880,463.153046
	C296.227112,462.698212 291.829712,463.383240 287.381866,463.736938
	C285.232544,463.907867 283.076111,463.764801 280.533478,463.318054
	C279.851044,463.231110 279.579071,463.216888 278.913391,463.122131
	C276.546936,462.333069 275.208527,460.734009 273.318787,459.270477
z"
        />
        <path
          fill="#33505C"
          opacity="1.000000"
          stroke="none"
          d="
M316.995117,447.288757
	C318.059692,447.290283 319.118195,447.572083 320.499207,447.924805
	C323.267578,448.691986 327.163330,445.067474 327.981873,450.834900
	C326.630951,454.154266 324.104431,453.987549 321.198517,453.697266
	C320.577911,453.511078 320.332794,453.438751 319.772522,453.159821
	C318.613678,451.800751 318.027802,450.477509 317.022949,449.037506
	C316.964630,448.316254 316.976807,447.942627 316.995117,447.288757
z"
        />
        <path
          fill="#63879A"
          opacity="1.000000"
          stroke="none"
          d="
M316.536926,448.747650
	C318.541626,448.964142 320.065979,449.310699 320.023865,451.814148
	C318.970947,452.878601 317.854431,453.074402 316.299225,452.260437
	C314.633423,451.154999 313.207306,450.821838 311.311340,451.013214
	C310.996124,450.622772 311.034790,450.175873 311.103271,449.394226
	C312.795868,448.974731 314.458679,448.890076 316.536926,448.747650
z"
        />
        <path
          fill="#214A59"
          opacity="1.000000"
          stroke="none"
          d="
M280.070740,430.300903
	C282.894196,430.756805 285.717957,431.623444 288.797058,432.741882
	C286.121002,431.987457 282.107849,435.238739 280.070740,430.300903
z"
        />
        <path
          fill="#594E52"
          opacity="1.000000"
          stroke="none"
          d="
M273.024902,459.202606
	C275.284607,458.775879 276.747864,459.961945 278.116516,461.984863
	C277.174377,463.125580 275.997955,463.175446 274.404480,462.239441
	C274.030975,461.955444 274.010437,462.004608 274.028076,461.983459
	C273.544556,461.550995 273.141846,461.073914 272.995605,460.097015
	C272.980408,459.778931 273.001251,459.393921 273.024902,459.202606
z"
        />
        <path
          fill="#929EAC"
          opacity="1.000000"
          stroke="none"
          d="
M272.667419,459.688599
	C273.385986,460.292297 273.791595,460.805664 274.121460,461.640625
	C273.850800,461.990387 273.655945,462.018463 273.166443,462.066223
	C272.710968,462.164764 272.550110,462.243622 272.198303,462.133362
	C272.007416,461.944214 272.026978,461.995331 272.050507,461.980682
	C271.426178,461.167786 270.778351,460.369537 270.064209,459.267578
	C270.978394,458.451019 271.642975,459.111725 272.667419,459.688599
z"
        />
        <path
          fill="#929EAC"
          opacity="1.000000"
          stroke="none"
          d="
M270.053711,458.696991
	C269.709869,458.845490 269.384216,458.691467 269.040405,458.256256
	C269.372162,458.114868 269.722015,458.254700 270.053711,458.696991
z"
        />
        <path
          fill="#3C7D8E"
          opacity="1.000000"
          stroke="none"
          d="
M294.908905,415.717590
	C297.484039,421.173645 296.519043,426.850952 297.677551,432.634888
	C292.932526,432.012451 288.936279,430.572754 285.012146,428.931519
	C282.137543,427.729218 280.786621,425.691559 280.982635,422.348114
	C281.243591,417.897247 280.995453,413.416534 280.966034,408.481140
	C283.165833,406.450592 285.006256,407.544556 287.090027,409.088257
	C288.657654,411.750336 288.153076,413.633850 285.581238,415.266541
	C283.826355,422.277008 284.878815,424.632141 289.604218,425.475067
	C291.798431,425.866486 292.683807,425.222839 292.541504,422.965698
	C292.397308,420.679230 292.123566,418.342072 293.642151,415.967987
	C294.130951,415.660828 294.362915,415.602478 294.908905,415.717590
z"
        />
        <path
          fill="#337484"
          opacity="1.000000"
          stroke="none"
          d="
M295.192688,415.440613
	C294.974792,415.943298 294.726624,415.995483 294.104614,416.117706
	C293.500244,416.214935 293.269775,416.242188 292.738770,416.094910
	C291.027374,413.882385 289.452606,411.993652 287.234009,410.235046
	C285.220367,409.363556 283.448242,408.728363 281.321075,408.053406
	C279.755371,400.529358 279.755371,400.529297 286.962341,402.108521
	C295.094788,403.890533 295.094757,403.890625 295.192688,415.440613
z"
        />
        <path
          fill="#693E39"
          opacity="1.000000"
          stroke="none"
          d="
M702.745728,686.301697
	C703.762634,686.056519 704.513245,686.043335 705.638733,686.052185
	C707.968811,691.882751 712.301880,695.614685 717.157959,698.915405
	C718.400391,699.759888 719.591614,700.826050 720.172668,702.195129
	C722.419983,707.489746 727.107300,709.564392 732.001099,711.334351
	C734.067139,712.081665 735.772827,713.390503 737.660156,714.377014
	C746.084167,718.780090 754.981323,720.919800 764.527710,718.916931
	C765.013184,718.815063 765.603394,718.693176 765.894043,718.343689
	C772.197388,710.765747 782.070740,705.993530 784.179565,695.112366
	C784.495850,693.480225 784.905457,691.867371 785.377502,690.269836
	C787.323120,683.685852 787.325745,683.670166 781.805908,679.873596
	C780.745056,679.144043 780.183960,678.249084 780.395691,676.569702
	C782.682983,673.082275 785.904053,673.187378 789.584106,673.421692
	C791.506836,673.588867 792.950562,673.889526 794.671265,674.708984
	C800.334961,684.298523 795.371399,693.640808 795.165955,702.955750
	C794.974243,711.648071 787.408936,716.057251 783.689636,722.703003
	C781.588623,726.457397 781.004883,731.226135 776.266602,733.347107
	C771.934509,735.286194 767.417725,737.320679 767.509033,743.395081
	C767.526917,744.588379 765.901367,744.881287 764.714050,745.039429
	C763.289612,745.229126 761.984924,745.987183 761.560181,747.222229
	C759.549744,753.068176 755.615479,752.487061 751.175354,750.587280
	C750.720703,750.392761 750.195618,750.107666 749.752869,750.177734
	C739.329224,751.828613 737.177856,743.587158 734.047668,736.818237
	C732.045593,732.488770 730.732239,731.654724 726.520752,733.984985
	C724.673645,735.007202 723.731079,734.550781 723.155701,732.758179
	C722.700317,731.339600 722.249023,729.901245 722.004456,728.436829
	C721.131653,723.211365 720.048767,718.445740 713.227112,717.930054
	C711.265015,717.781738 709.448669,715.713623 709.091553,713.377014
	C708.505676,709.543884 706.107239,707.274109 703.009705,705.355530
	C697.961975,702.228943 695.707031,697.168518 694.005127,691.393311
	C695.814026,687.591980 700.078918,688.828613 702.745728,686.301697
z"
        />
        <path
          fill="#5D3732"
          opacity="1.000000"
          stroke="none"
          d="
M702.634644,686.095947
	C701.663269,690.105347 696.956482,688.375305 694.365173,690.951660
	C693.253601,691.005554 692.502380,691.003784 691.375549,691.000977
	C690.541016,690.279968 690.969788,689.828674 691.739380,689.205200
	C695.415955,688.045593 698.836670,687.083862 702.634644,686.095947
z"
        />
        <path
          fill="#533230"
          opacity="1.000000"
          stroke="none"
          d="
M795.327393,675.011108
	C793.594604,675.007812 792.196472,674.998108 790.378906,674.973145
	C789.140015,672.666748 790.425964,672.338074 792.639648,672.728333
	C794.414551,672.952332 795.794800,672.954224 797.589600,672.957458
	C797.743225,673.463135 797.482300,673.967346 797.110962,674.732910
	C796.554382,674.997681 796.108215,675.001160 795.327393,675.011108
z"
        />
        <path
          fill="#533230"
          opacity="1.000000"
          stroke="none"
          d="
M691.648804,689.009460
	C691.739075,689.501709 691.482910,689.996155 691.113403,690.745300
	C690.546387,690.993591 690.092712,690.987122 689.298828,690.959473
	C689.132812,689.557617 689.987244,689.004395 691.648804,689.009460
z"
        />
        <path
          fill="#72443E"
          opacity="1.000000"
          stroke="none"
          d="
M780.751953,676.661255
	C781.293213,679.356140 783.318604,678.791992 784.828857,679.164429
	C788.433533,680.053406 789.763672,682.227600 788.553345,685.837830
	C786.301270,692.554871 785.023438,699.613098 781.519531,705.891357
	C781.196106,706.470764 781.014465,707.418396 780.548584,707.585205
	C774.571228,709.725281 771.967957,715.426941 767.731079,719.392334
	C764.663940,722.262878 759.984070,721.817688 756.308960,721.582520
	C750.477234,721.209412 743.914795,720.452576 739.208069,717.440979
	C733.929810,714.063660 728.202209,712.066895 722.840027,709.151672
	C722.287537,708.851379 721.692871,708.293152 721.497192,707.724060
	C719.524414,701.987061 714.760498,698.912903 710.176208,695.637024
	C707.202087,693.511780 705.122620,690.900879 706.011597,686.489685
	C707.215271,685.570679 708.416931,685.067139 709.826294,684.311890
	C711.859741,682.186951 714.113220,682.186829 716.856018,682.691528
	C719.286011,683.753235 721.227722,685.244202 724.027161,683.792786
	C726.655151,683.235168 728.929810,683.255066 731.565063,683.543335
	C733.384216,683.826111 734.850891,684.817078 736.604370,683.430359
	C737.508606,683.053162 738.144226,682.949036 739.103638,682.916016
	C740.368835,682.956421 741.350830,683.314148 742.515991,682.546875
	C743.160095,682.285339 743.546448,682.192993 744.240662,682.119507
	C745.327698,682.105530 746.118347,682.159180 747.198608,681.929565
	C747.906311,681.772400 748.318604,681.703918 749.055908,681.623535
	C749.813293,681.572388 750.248901,681.559143 751.016602,681.547852
	C753.111816,681.514282 754.939453,681.774902 756.703979,680.234680
	C758.953491,679.226379 760.776367,678.004395 763.369873,678.135376
	C766.805786,680.167358 769.563049,679.514465 772.355591,676.906982
	C773.006897,676.469177 773.426208,676.319336 774.203003,676.230713
	C775.397949,676.265015 776.262878,676.322693 777.446716,676.012512
	C778.736572,675.814697 779.656494,675.905396 780.751953,676.661255
z"
        />
        <path
          fill="#964F38"
          opacity="1.000000"
          stroke="none"
          d="
M698.343262,678.152893
	C698.021179,676.586487 698.011353,674.867798 696.245789,674.376099
	C689.405334,672.471375 683.395813,668.600708 675.965881,667.702271
	C669.187683,666.882629 662.747375,668.129517 656.157471,668.098267
	C651.958069,668.078308 647.899902,667.311218 643.777283,666.904297
	C641.483032,666.677917 640.389648,664.968811 639.608032,663.050903
	C638.785339,661.032349 640.944275,661.170837 641.890442,660.008911
	C645.679138,658.225037 649.285156,660.047729 653.269409,659.783936
	C655.969360,661.446533 658.308533,661.831482 661.095154,660.940308
	C668.538696,658.559937 676.435913,659.841675 684.375000,658.281616
	C689.901123,658.061462 693.719727,661.347473 697.906799,663.569885
	C702.165283,665.830261 706.301819,668.300659 711.203369,670.066589
	C711.695435,670.423828 711.868530,670.595581 712.202148,671.093262
	C712.585999,672.923340 711.710144,674.135681 711.141052,675.786194
	C710.852722,676.289551 710.710876,676.475037 710.272339,676.846497
	C706.280457,677.697998 702.582764,678.337769 698.343262,678.152893
z"
        />
        <path
          fill="#2A1B1B"
          opacity="1.000000"
          stroke="none"
          d="
M709.988647,677.468872
	C709.991394,676.863342 710.007141,676.694214 710.013977,676.270508
	C711.862244,674.239075 714.071960,674.407288 716.391357,674.711487
	C719.423706,675.109009 722.446167,675.925232 725.974121,675.162476
	C729.556946,674.479553 732.714600,673.932739 736.331909,674.823608
	C739.170715,676.881042 743.595276,676.978821 742.797607,682.104370
	C742.285706,683.291260 741.666382,683.758606 740.401184,683.943604
	C739.263794,683.999207 738.530334,684.026550 737.431030,684.083435
	C736.096313,684.978027 734.910278,685.010010 733.353821,684.982849
	C730.299194,684.975769 727.600830,684.973328 724.452820,684.964417
	C722.192322,684.905457 720.347229,685.296326 718.264526,684.323730
	C715.595459,684.088135 713.252380,684.069580 710.471680,684.055542
	C710.023254,682.008545 710.012512,679.956848 709.988647,677.468872
z"
        />
        <path
          fill="#533230"
          opacity="1.000000"
          stroke="none"
          d="
M611.371582,643.996094
	C610.065674,640.026672 606.643738,640.525635 603.394287,640.001770
	C601.747681,637.286865 603.948364,636.551697 605.609985,635.694824
	C609.300781,633.791504 613.350098,632.680969 617.369019,632.076294
	C621.307617,631.483765 625.022583,630.330017 629.149170,629.114746
	C629.977722,628.808594 630.465027,628.690918 631.331848,628.593628
	C633.223511,628.648804 634.548462,628.179871 635.991943,626.933838
	C643.854065,625.264709 651.390381,623.795898 659.476501,623.416931
	C663.563110,623.666321 667.001587,622.843933 670.323303,621.202576
	C675.661865,618.564758 681.604126,617.948242 687.629761,616.540405
	C688.307617,617.094055 688.659485,617.539917 688.579102,618.033264
	C687.686768,623.510376 681.022583,629.988037 675.526245,630.643799
	C671.522949,631.121399 667.575195,631.668640 664.050171,634.655334
	C661.006958,635.833679 658.229736,635.229370 655.530945,634.954468
	C653.718323,634.769775 652.389587,634.954590 651.047852,636.702148
	C648.168457,637.776733 645.612488,638.644897 642.438721,638.475586
	C639.548340,638.003906 637.091614,639.600403 634.308350,638.259033
	C633.618958,635.932678 632.036560,635.911072 630.523621,636.027771
	C624.757202,636.472656 618.964294,636.942383 615.047852,642.088257
	C614.185059,643.221802 613.218079,644.002563 611.371582,643.996094
z"
        />
        <path
          fill="#A8A6A9"
          opacity="1.000000"
          stroke="none"
          d="
M638.231873,656.820923
	C636.945496,656.610352 635.949829,656.132385 634.653809,655.510437
	C631.127991,653.163574 627.843445,651.040955 624.701294,648.724976
	C623.049988,647.507812 621.051514,646.375610 621.251465,643.470703
	C621.639343,642.739868 622.032654,642.503662 622.874390,642.521729
	C627.132874,645.944702 631.576355,648.445435 636.450439,650.956482
	C637.832458,651.938721 638.509705,652.992737 638.901917,654.658447
	C638.921753,655.583435 638.779907,656.084839 638.231873,656.820923
z"
        />
        <path
          fill="#9B8881"
          opacity="1.000000"
          stroke="none"
          d="
M623.418945,643.245850
	C622.695312,643.024658 622.304810,643.050232 621.623535,643.101440
	C624.121277,638.633179 629.244934,639.744751 633.627686,638.063354
	C636.423462,637.659302 638.838501,637.355896 641.662537,637.108643
	C642.419495,637.640625 642.537354,638.168091 642.226013,639.116638
	C636.357056,642.748474 630.158386,643.594055 623.418945,643.245850
z"
        />
        <path
          fill="#E1B582"
          opacity="1.000000"
          stroke="none"
          d="
M638.190552,657.275696
	C637.967834,656.509705 637.994507,655.931091 638.060059,654.919800
	C638.336304,654.427979 638.573669,654.368896 639.178345,654.332520
	C640.022095,654.483032 640.492554,654.630432 641.301270,654.938232
	C645.440491,656.942444 649.511475,656.381226 653.897095,656.355225
	C661.784668,655.486267 668.926453,652.546143 676.955444,652.522400
	C680.417053,652.159973 683.256653,652.889893 686.010864,654.206482
	C694.689697,658.355286 703.076599,663.050659 711.762329,667.992981
	C712.385620,668.676697 712.596008,669.148682 712.653076,670.066589
	C712.511536,670.689331 712.396912,670.922363 711.906433,671.257690
	C705.328674,670.349731 700.457092,666.482727 695.079102,663.702393
	C692.012207,662.116821 688.937866,660.545715 685.527100,658.861084
	C676.676147,658.035461 668.334290,658.920776 660.120361,661.261292
	C658.361145,661.762451 656.488464,661.584534 654.302856,661.208862
	C650.196716,661.302185 646.469849,661.377625 642.499512,659.811035
	C640.874756,658.977905 639.657349,658.220520 638.190552,657.275696
z"
        />
        <path
          fill="#C99469"
          opacity="1.000000"
          stroke="none"
          d="
M1070.170410,517.105469
	C1069.902588,516.741760 1069.917480,516.487244 1069.971680,515.851074
	C1071.603516,515.830505 1073.283569,515.984680 1074.763916,516.611206
	C1076.219604,517.227295 1077.056396,518.497375 1076.295410,520.237488
	C1075.755127,521.472900 1074.809814,521.998291 1073.512085,521.594360
	C1072.035522,521.134827 1070.509766,520.753235 1072.105957,518.609863
	C1072.516724,518.058350 1071.110962,517.608398 1070.170410,517.105469
z"
        />
        <path
          fill="#F0F0EC"
          opacity="1.000000"
          stroke="none"
          d="
M1064.116699,350.142334
	C1064.026855,349.992645 1064.508545,350.004333 1064.749390,350.003998
	C1064.729004,350.099762 1064.467773,350.195892 1064.116699,350.142334
z"
        />
        <path
          fill="#F0F0EC"
          opacity="1.000000"
          stroke="none"
          d="
M1073.224976,336.066650
	C1073.623169,336.023010 1074.191284,336.242157 1074.942139,336.710175
	C1074.548340,336.749207 1073.971558,336.539337 1073.224976,336.066650
z"
        />
        <path
          fill="#473033"
          opacity="1.000000"
          stroke="none"
          d="
M630.014404,629.589478
	C622.842957,634.311951 614.079285,633.199219 606.731506,636.900940
	C605.415710,637.563904 603.812317,637.704224 603.074036,639.653076
	C596.379822,642.280273 589.366516,642.280212 582.561584,642.741211
	C575.694641,643.206360 568.807556,643.903687 561.455627,644.003296
	C558.246643,640.879883 554.464172,642.217590 551.127319,641.878723
	C545.180298,641.274658 539.612366,639.145447 533.404846,638.014771
	C528.468933,639.450439 525.016663,635.589539 520.422241,635.503052
	C519.087280,635.444153 518.117676,635.425354 516.841553,635.073914
	C516.314941,634.845520 516.116638,634.734924 515.660034,634.413757
	C514.706665,633.453674 514.174438,632.474670 512.629639,632.449097
	C511.625641,632.411621 510.957611,632.296631 509.936188,632.088257
	C508.434052,631.466919 507.106903,631.377075 505.784576,630.290405
	C505.104187,629.206299 505.064484,628.390381 505.488892,627.163940
	C505.910248,626.586182 506.131927,626.395020 506.751190,626.011353
	C508.908173,625.268616 510.653351,625.569336 512.805969,626.062134
	C518.202209,629.378052 524.082214,629.875366 529.457214,631.749390
	C541.488464,635.943909 553.686523,633.844238 566.202759,634.420776
	C568.014709,634.733765 569.446533,635.244080 571.160645,634.125732
	C572.531311,633.690063 573.566895,633.657776 575.009277,633.851624
	C580.213440,635.224548 584.981934,634.848694 589.594177,633.205505
	C592.772034,632.073425 595.970581,631.260986 599.320984,631.385132
	C605.923279,631.629700 612.088745,629.273438 618.491699,628.385254
	C622.340210,627.851318 626.345459,626.660583 630.014404,629.589478
z"
        />
        <path
          fill="#5C4747"
          opacity="1.000000"
          stroke="none"
          d="
M519.889771,634.425781
	C524.413757,634.276367 528.309326,636.220825 532.670105,637.866211
	C537.472046,644.668091 536.408386,647.160339 528.466797,649.138550
	C526.409729,649.650940 524.482605,650.685120 522.250244,651.736206
	C519.632019,651.995117 517.259277,651.997559 514.442078,652.003662
	C513.140503,650.898926 511.905884,651.018738 510.349915,650.996643
	C509.742462,650.187683 509.499542,649.380310 509.126953,648.280151
	C509.067291,645.238708 511.379517,645.793640 512.932678,645.455872
	C515.498779,644.897888 518.170959,644.732727 520.598877,643.671326
	C524.633484,641.907593 524.808838,640.013550 521.032837,636.934448
	C520.218567,636.170288 519.876282,635.535217 519.889771,634.425781
z"
        />
        <path
          fill="#3E505A"
          opacity="1.000000"
          stroke="none"
          d="
M777.107361,167.374573
	C777.578918,167.014297 778.071716,167.015106 778.934875,167.019135
	C780.766602,167.746811 781.894653,168.971069 783.325500,169.732178
	C787.833557,172.130142 792.663818,169.510132 793.654419,163.834335
	C793.757141,163.278671 793.817200,163.061600 794.019531,162.547180
	C794.244751,161.777237 795.307373,161.650818 794.541809,160.602295
	C794.470764,159.256287 794.990173,158.631866 796.348938,158.438782
	C797.726074,158.720718 797.965088,159.703201 798.712769,160.685226
	C798.491150,168.494965 795.049988,173.879028 789.927734,175.041733
	C782.304199,176.772217 779.653992,175.259384 777.107361,167.374573
z"
        />
        <path
          fill="#59B1BB"
          opacity="1.000000"
          stroke="none"
          d="
M798.937012,160.612671
	C798.106567,160.766708 797.284058,160.577637 797.035278,159.252029
	C795.244019,157.558823 793.816345,156.025101 794.753662,153.200897
	C795.732666,152.952957 796.462708,153.019302 797.558228,153.075806
	C797.923645,153.065964 798.103149,153.507614 798.214539,153.718369
	C798.997498,154.868271 799.538513,155.854202 799.439819,157.404388
	C799.419800,157.968506 799.409668,158.194000 799.208252,158.698822
	C798.989136,159.413803 798.961304,159.849472 798.937012,160.612671
z"
        />
        <path
          fill="#314D58"
          opacity="1.000000"
          stroke="none"
          d="
M799.046143,158.277695
	C798.004578,157.038116 798.046387,155.832642 798.213989,154.276855
	C799.022095,153.998245 799.718323,154.067368 800.758179,154.221069
	C801.078003,155.195190 801.054260,156.084763 801.091919,157.299500
	C800.942322,157.695801 800.731262,157.766937 800.194824,157.913544
	C799.695374,158.144836 799.521423,158.300659 799.046143,158.277695
z"
        />
        <path
          fill="#3E505A"
          opacity="1.000000"
          stroke="none"
          d="
M798.773438,158.330917
	C798.932617,158.078735 799.120300,158.058563 799.588623,158.013702
	C799.821594,158.306641 799.674988,158.569092 799.223267,158.877243
	C799.016907,158.978134 798.801941,158.562927 798.773438,158.330917
z"
        />
        <path
          fill="#45AFBC"
          opacity="1.000000"
          stroke="none"
          d="
M794.819519,152.668396
	C795.636658,154.672180 796.270752,156.457764 796.955627,158.590759
	C796.464783,159.330627 795.923157,159.723083 795.262573,160.351807
	C795.226013,160.860016 795.308411,161.131958 795.229004,161.798248
	C795.067200,162.192566 795.063660,162.608322 795.012451,162.809784
	C794.245239,163.781723 794.177673,164.809006 793.837646,165.736343
	C792.424438,169.590485 788.780273,171.711807 785.291260,170.798935
	C781.713562,169.862854 780.352966,167.163895 780.990906,162.541489
	C781.249146,160.670181 781.719971,158.967773 783.572144,157.593536
	C784.941772,157.466537 785.422119,158.115753 785.788086,159.146362
	C785.821045,159.343460 785.813232,159.673721 785.903076,159.496872
	C786.088440,158.747940 785.744873,158.312653 785.387085,157.519867
	C785.777405,152.876572 789.037292,152.628952 792.673706,152.269135
	C793.546021,152.205322 794.091248,152.327744 794.819519,152.668396
z"
        />
        <path
          fill="#59B1BB"
          opacity="1.000000"
          stroke="none"
          d="
M785.293945,158.171677
	C781.399963,159.951843 781.692627,163.384094 782.292603,166.535507
	C782.670044,168.517853 784.727356,169.769623 786.770630,169.839706
	C789.979797,169.949783 791.911316,167.999664 792.856873,165.027740
	C793.112610,164.223785 793.414917,163.477737 794.624146,163.083130
	C795.939636,167.391495 793.706055,170.005478 789.935425,171.373688
	C785.578552,172.954636 781.848083,172.046127 779.394775,167.341064
	C780.006958,156.362183 783.707947,152.018158 792.589844,151.997437
	C790.647949,153.754105 787.005859,153.651733 785.970215,157.531509
	C785.809204,158.040787 785.676025,158.191879 785.293945,158.171677
z"
        />
        <path
          fill="#1D1A1E"
          opacity="1.000000"
          stroke="none"
          d="
M865.542480,162.997559
	C868.258972,166.541016 872.164124,165.405884 875.287292,165.814972
	C878.456848,166.230133 879.833557,167.522202 881.007568,170.256119
	C883.281433,175.551224 885.019226,180.763321 882.257324,186.724335
	C880.154663,187.656403 878.010071,186.084885 876.077759,187.907959
	C867.502747,187.528992 859.053589,188.821075 850.444580,186.341278
	C849.639526,183.647736 851.748596,183.256256 853.125366,182.160233
	C855.295898,180.432281 855.318176,178.772079 852.301819,178.075241
	C849.214783,177.362061 847.362366,175.870544 845.708679,172.852036
	C843.464600,168.755890 838.721130,171.523239 836.611389,172.752579
	C831.974609,175.454330 827.154907,175.446701 822.332397,175.908035
	C819.117981,176.215530 816.986267,177.337738 815.837952,180.337692
	C815.126038,182.197495 814.343994,184.030502 813.589294,185.873840
	C809.633789,195.534317 806.481689,196.224411 798.340332,189.067413
	C800.039734,187.046036 800.996948,184.926437 800.236206,181.916016
	C799.347473,178.398697 801.274353,175.455719 804.256226,174.228745
	C809.561646,172.045609 815.181580,170.629425 820.661011,168.864685
	C822.433167,168.293915 824.100220,168.174759 825.982971,168.714188
	C827.509521,169.151535 829.736206,169.479202 830.832642,168.700790
	C837.377441,164.054260 845.153992,165.573868 852.303955,164.228134
	C856.433411,163.450912 860.743835,162.415314 865.542480,162.997559
z"
        />
        <path
          fill="#414A51"
          opacity="1.000000"
          stroke="none"
          d="
M876.383606,190.608276
	C876.879822,201.130539 875.389526,211.311203 874.626282,221.543564
	C874.355713,225.170792 873.945679,228.787628 873.313354,232.703934
	C872.174255,233.953720 872.481750,235.127274 872.518066,236.200134
	C872.649353,240.077423 870.763184,240.571060 867.368652,238.959259
	C865.751282,237.122391 866.769287,235.620667 867.131165,234.027695
	C868.632385,227.418884 869.453552,220.714355 869.773376,213.941452
	C869.841492,212.498306 869.651062,211.316650 868.122498,210.238663
	C866.695435,208.876328 866.712158,206.872711 864.722778,205.959198
	C864.099243,205.435226 863.775635,205.100220 863.254883,204.472229
	C859.484558,201.742523 852.091431,203.082535 850.577148,206.674667
	C849.450562,209.347000 848.241150,212.095688 848.519897,215.520905
	C848.765686,218.573120 847.474854,221.002151 847.381531,223.697906
	C847.222717,228.281952 847.574280,232.686768 849.011475,237.270081
	C847.336304,238.429199 845.905396,238.424347 844.073059,237.510132
	C842.191528,229.699249 843.901184,222.223450 844.209717,214.723511
	C844.276855,213.092133 844.537781,211.459930 844.221130,209.407257
	C844.012146,207.899643 844.023560,206.797211 844.114624,205.288483
	C846.011169,201.315918 845.330872,197.445251 845.760132,193.329742
	C845.961060,192.974136 846.006409,193.019669 845.981201,193.039551
	C846.271545,192.755112 846.557007,192.424728 846.921265,191.758759
	C847.030090,191.449188 847.020081,191.522812 846.983032,191.520477
	C847.557983,189.873734 849.020142,189.340561 850.491943,189.265823
	C858.223450,188.873199 865.961731,188.667023 873.688721,189.384216
	C874.511475,189.460587 875.207458,189.999634 876.383606,190.608276
z"
        />
        <path
          fill="#2F252B"
          opacity="1.000000"
          stroke="none"
          d="
M813.731995,215.798203
	C815.615662,222.085663 817.242126,228.162857 818.922974,234.619141
	C819.014893,235.877121 818.979492,236.771805 819.744202,237.790985
	C819.951477,238.788864 819.943848,239.535202 819.921143,240.654449
	C817.205994,242.998840 815.451904,241.606354 814.556885,239.136032
	C812.937561,234.666672 810.499084,230.430389 810.187500,225.553406
	C809.920959,221.380524 806.200562,218.222321 806.897705,213.408615
	C806.167542,209.808823 805.299500,206.794846 802.102478,204.870041
	C799.433899,203.263351 799.975952,200.269211 800.106384,197.197647
	C802.445435,195.755310 803.418884,197.396988 804.529663,199.179657
	C804.575684,199.804840 804.625854,200.135971 805.049988,200.451508
	C805.907654,200.613785 806.347412,200.985001 806.869141,201.719330
	C807.290710,202.483215 807.458679,203.038788 808.010559,203.689209
	C808.764832,204.389084 809.380798,204.826691 810.150085,205.546143
	C810.428650,205.960175 810.508972,206.146912 810.611938,206.639709
	C810.574036,207.141663 810.513611,207.337570 810.226685,207.741623
	C809.365356,211.311447 812.679565,212.876740 813.731995,215.798203
z"
        />
        <path
          fill="#929EAC"
          opacity="1.000000"
          stroke="none"
          d="
M876.708313,190.563965
	C867.169373,190.972397 857.510132,191.104828 847.398438,191.377716
	C844.376160,188.060944 847.775757,187.607742 849.815674,186.220154
	C856.734802,186.198914 863.391663,186.264420 870.046692,186.412796
	C871.832275,186.452606 873.763794,186.142441 875.524109,187.864761
	C876.093811,188.789627 876.340942,189.538788 876.708313,190.563965
z"
        />
        <path
          fill="#496A76"
          opacity="1.000000"
          stroke="none"
          d="
M807.848022,201.470123
	C808.401123,200.649948 809.051636,200.310898 809.313354,199.780151
	C811.136292,196.082794 814.012878,193.892944 818.050049,195.366287
	C822.212158,196.885239 822.719238,200.491135 821.279907,204.727966
	C820.847839,205.159180 820.647827,205.284851 820.113159,205.291901
	C818.741638,204.207001 818.662903,202.834457 818.265991,201.597809
	C817.898682,200.453552 817.366333,199.228653 816.015747,199.373199
	C814.055664,199.582977 814.650818,201.221207 814.666748,202.839508
	C814.574158,203.445267 814.495911,203.682999 814.170654,204.210526
	C813.006714,205.108337 811.994690,205.221664 810.548645,204.966507
	C808.857788,204.265686 808.007812,203.307449 807.848022,201.470123
z"
        />
        <path
          fill="#77878D"
          opacity="1.000000"
          stroke="none"
          d="
M807.727539,200.818024
	C808.514099,201.900452 809.097656,202.723190 809.855103,203.794525
	C810.420959,204.612198 810.564636,205.238251 810.215149,206.353439
	C809.308533,206.273941 808.646790,205.762329 807.706787,205.104248
	C806.605774,204.683701 806.515137,203.927902 806.184082,202.908752
	C805.682800,202.018753 805.302734,201.470505 804.664795,200.868576
	C804.200623,200.953140 803.994324,201.091415 803.984802,200.913391
	C803.636841,198.829803 802.177979,197.912415 800.468750,196.853638
	C800.157532,196.768784 800.068237,196.826660 800.069336,196.879852
	C799.224792,196.341766 798.175110,196.100723 797.198730,195.149017
	C796.992676,194.917892 796.938721,194.545807 796.920898,194.358673
	C796.738953,193.442856 796.991028,192.910370 798.116821,192.749695
	C802.389099,194.497818 804.703430,197.825180 807.727539,200.818024
z"
        />
        <path
          fill="#617179"
          opacity="1.000000"
          stroke="none"
          d="
M843.965576,209.432022
	C846.020874,211.845016 845.272400,215.135834 844.758606,218.016495
	C843.705627,223.920059 844.094421,229.799850 844.064209,236.132935
	C843.878540,237.159851 843.437439,237.442856 842.426758,237.207474
	C842.053589,236.995270 841.834961,237.117783 841.714478,237.083038
	C843.227295,228.059631 842.871826,218.900040 843.965576,209.432022
z"
        />
        <path
          fill="#2E515F"
          opacity="1.000000"
          stroke="none"
          d="
M813.962891,204.375122
	C814.082703,203.850952 814.125549,203.636017 814.238037,203.099777
	C816.738647,201.304260 817.857056,203.326675 819.507324,204.826447
	C819.982666,205.022003 820.195618,205.022827 820.728271,205.028580
	C818.870239,210.240555 815.777771,211.219788 810.316284,208.140671
	C809.990662,207.752609 809.981018,207.555466 809.974487,207.062256
	C811.005188,205.555710 812.864624,205.906830 813.962891,204.375122
z"
        />
        <path
          fill="#5D696E"
          opacity="1.000000"
          stroke="none"
          d="
M845.631470,193.211945
	C845.516724,196.874817 847.149536,200.958359 844.561401,204.792389
	C844.563477,201.071365 844.932678,197.260574 845.631470,193.211945
z"
        />
        <path
          fill="#A8A6A9"
          opacity="1.000000"
          stroke="none"
          d="
M798.319092,192.652435
	C798.170471,193.159821 797.763977,193.393066 797.130249,193.898926
	C796.496765,193.631821 796.090576,193.092102 795.866333,192.248444
	C796.857483,191.447632 797.449219,191.960037 798.319092,192.652435
z"
        />
        <path
          fill="#929EAC"
          opacity="1.000000"
          stroke="none"
          d="
M846.705078,191.591644
	C846.796692,191.810455 846.563232,192.171722 846.142883,192.796204
	C846.097351,192.617661 846.238770,192.175888 846.705078,191.591644
z"
        />
        <path
          fill="#471B15"
          opacity="1.000000"
          stroke="none"
          d="
M806.745483,213.214783
	C811.935120,220.886154 812.219360,230.403961 815.918518,238.750931
	C816.691284,240.494644 817.516541,241.022675 819.518188,241.021225
	C821.114441,250.805908 820.729980,260.532745 818.091248,270.018280
	C817.506958,272.118713 816.738403,274.146545 817.014099,276.241730
	C817.512695,280.030396 816.339661,282.291046 812.048706,282.912750
	C810.235718,283.818146 809.006409,283.162720 807.528687,282.084625
	C812.297241,268.275085 814.594971,254.323822 812.101562,239.801926
	C810.584473,230.966034 807.194458,222.928543 801.949097,215.266998
	C803.453369,214.579849 806.123230,217.541153 806.745483,213.214783
z"
        />
        <path
          fill="#2F252B"
          opacity="1.000000"
          stroke="none"
          d="
M797.051453,195.269821
	C798.132446,194.994461 799.128845,195.360733 799.950439,196.627777
	C798.868896,196.980881 797.628906,197.115509 797.051453,195.269821
z"
        />
        <path
          fill="#631D07"
          opacity="1.000000"
          stroke="none"
          d="
M892.140625,272.578125
	C888.331787,269.018341 889.821533,264.047455 890.192810,259.681946
	C891.484253,244.497147 891.900513,229.310211 892.115234,214.083313
	C892.278076,202.531708 892.440063,190.832352 894.418640,179.516327
	C895.410889,173.841980 895.170471,168.091904 896.833557,162.223328
	C899.427734,167.236664 897.984375,172.751312 897.707092,178.131561
	C896.587158,199.863693 895.167358,221.580322 893.875854,243.303711
	C893.304138,252.919708 892.774231,262.538208 892.140625,272.578125
z"
        />
        <path
          fill="#88A6B8"
          opacity="1.000000"
          stroke="none"
          d="
M842.013550,237.400879
	C842.518433,236.922943 842.983337,236.850601 843.785706,236.674438
	C845.458069,236.648956 846.792908,236.727280 848.527710,236.771439
	C854.835388,233.629028 860.559937,235.266479 866.622925,237.718506
	C871.715759,240.185333 872.433289,237.243469 872.813354,233.300232
	C872.893799,235.581375 872.918213,238.184814 872.583252,240.741165
	C872.228210,243.450485 870.831543,245.263046 867.318970,244.674622
	C863.127319,243.476425 859.351501,241.744751 855.114136,244.115173
	C854.437195,244.519028 853.988342,244.535492 853.267151,244.200073
	C851.657715,243.789688 850.314087,243.608078 848.563110,243.451385
	C847.614014,243.739563 847.062073,243.839722 846.238647,243.428726
	C842.903870,242.736603 841.289734,241.114685 842.013550,237.400879
z"
        />
        <path
          fill="#92BFCE"
          opacity="1.000000"
          stroke="none"
          d="
M875.129700,279.442322
	C872.907593,279.906799 870.902344,279.994812 868.468384,280.122955
	C867.517456,280.566742 866.997559,281.150391 866.388794,281.219757
	C859.048218,282.055695 851.778198,282.115021 844.763855,278.406677
	C844.651733,277.935944 844.837646,277.479279 844.777100,277.240295
	C846.131287,274.526886 848.488892,273.984863 851.078979,273.824890
	C859.826721,273.284363 868.568665,272.799774 877.600830,274.963318
	C877.883118,277.211578 876.262024,277.206299 874.780090,276.993347
	C866.571106,275.813629 858.393921,276.769501 852.738525,277.006439
	C857.160889,276.361206 864.148499,276.420349 871.132019,276.543671
	C872.911926,276.575104 874.909424,276.617889 875.129700,279.442322
z"
        />
        <path
          fill="#557E8B"
          opacity="1.000000"
          stroke="none"
          d="
M807.204590,281.966858
	C808.748779,281.342926 810.033142,281.840546 811.488342,283.014893
	C814.886230,289.183319 820.536316,291.557007 826.785767,293.542236
	C828.342651,293.816345 829.577209,294.084839 831.171997,294.254333
	C833.269104,294.632629 835.020447,294.625763 837.150391,294.227966
	C838.653564,294.940002 841.054199,293.441528 841.157471,295.460358
	C841.255066,297.367279 838.985718,297.046448 837.640259,297.266266
	C828.542419,298.752747 820.915405,295.203613 813.096802,290.008820
	C810.735413,289.466248 809.730591,288.148193 808.431396,286.664734
	C807.529724,285.407837 807.683105,284.172272 807.136230,282.794373
	C807.041504,282.467407 807.188904,282.022125 807.204590,281.966858
z"
        />
        <path
          fill="#557E8B"
          opacity="1.000000"
          stroke="none"
          d="
M828.779785,254.429688
	C839.054443,253.662338 849.145203,253.201981 859.237366,252.777267
	C860.434570,252.726898 862.308228,251.960999 862.320251,254.005219
	C862.329712,255.606842 860.595337,255.077499 859.520630,255.113632
	C849.612793,255.446777 839.735046,256.621948 829.417053,256.134338
	C828.704773,255.698761 828.558716,255.253174 828.779785,254.429688
z"
        />
        <path
          fill="#688FA1"
          opacity="1.000000"
          stroke="none"
          d="
M875.228394,279.996765
	C874.046387,276.827148 871.515625,277.061920 869.252441,277.034363
	C863.961304,276.969818 858.672791,276.933838 853.419067,277.888062
	C851.698181,278.200653 849.872742,277.937256 848.077942,277.511292
	C857.718933,274.820557 867.681152,276.940155 877.700378,275.298309
	C879.196960,274.827301 879.924316,275.688538 880.967285,276.520020
	C880.861938,277.672241 880.569214,278.571411 880.192139,279.816406
	C879.951721,280.451935 879.729919,280.676758 879.239624,280.964294
	C877.872620,280.786194 876.708252,280.480499 875.228394,279.996765
z"
        />
        <path
          fill="#92BFCE"
          opacity="1.000000"
          stroke="none"
          d="
M855.299500,244.122223
	C855.762329,242.972275 851.885681,240.776794 855.641235,241.115646
	C859.049377,241.423096 862.340881,242.349457 865.846680,241.536438
	C866.953674,241.279724 866.923462,242.878677 866.944092,244.198486
	C864.224243,244.519485 861.506958,244.488007 858.416138,244.582794
	C857.175842,245.035324 856.351440,244.931381 855.299500,244.122223
z"
        />
        <path
          fill="#15191F"
          opacity="1.000000"
          stroke="none"
          d="
M854.696411,243.823135
	C855.796021,243.984833 856.555908,244.189499 857.679260,244.551620
	C857.455322,246.033966 860.045227,248.130676 857.456787,248.754852
	C854.480408,249.472549 853.873108,246.803665 853.047180,244.335754
	C853.453186,243.936234 853.904968,243.901169 854.696411,243.823135
z"
        />
        <path
          fill="#63879A"
          opacity="1.000000"
          stroke="none"
          d="
M828.749939,253.888489
	C828.975037,254.550339 828.986816,254.988708 829.016479,255.755615
	C828.377991,257.253998 827.414612,257.717804 826.181824,257.097198
	C825.383362,256.695282 825.011536,255.873032 825.336975,255.023026
	C825.906921,253.534225 827.097107,253.242218 828.749939,253.888489
z"
        />
        <path
          fill="#1D1A1E"
          opacity="1.000000"
          stroke="none"
          d="
M881.644226,191.007874
	C881.750488,191.802078 881.505798,192.609024 881.130554,193.707977
	C880.196228,193.813507 879.301270,193.656372 879.275757,192.604248
	C879.243164,191.256332 880.293335,191.135452 881.644226,191.007874
z"
        />
        <path
          fill="#15191F"
          opacity="1.000000"
          stroke="none"
          d="
M819.967834,237.702545
	C818.447693,237.737381 817.667175,237.030167 818.748291,235.211594
	C819.809692,235.575394 819.982788,236.431076 819.967834,237.702545
z"
        />
        <path
          fill="#1D1A1E"
          opacity="1.000000"
          stroke="none"
          d="
M878.801880,209.182404
	C879.315796,209.471878 879.626831,209.943222 879.967712,210.706055
	C879.125122,210.800980 878.040833,210.785812 878.801880,209.182404
z"
        />
        <path
          fill="#15191F"
          opacity="1.000000"
          stroke="none"
          d="
M846.063354,243.559540
	C846.537903,243.282013 847.086548,243.285233 847.895508,243.382355
	C847.639832,244.466904 847.071655,245.168579 846.063354,243.559540
z"
        />
        <path
          fill="#2C1E22"
          opacity="1.000000"
          stroke="none"
          d="
M886.885620,161.297821
	C888.031860,161.481369 888.740601,162.209991 888.970337,163.674316
	C887.495850,163.902542 885.671387,164.109695 886.885620,161.297821
z"
        />
        <path
          fill="#478D9B"
          opacity="1.000000"
          stroke="none"
          d="
M777.714111,91.924950
	C775.339478,90.146324 776.367004,88.834038 778.224976,89.273201
	C782.438782,90.269218 786.730286,89.607071 790.986267,90.862755
	C786.838867,92.517548 782.422913,91.796104 777.714111,91.924950
z"
        />
        <path
          fill="#1B292E"
          opacity="1.000000"
          stroke="none"
          d="
M671.381348,100.202057
	C671.143250,100.000092 670.927795,99.686211 670.882324,99.501907
	C671.295776,96.121330 677.424927,89.964249 680.688782,89.343208
	C682.253784,89.045418 682.766724,89.831238 682.969238,91.180916
	C683.480835,94.590286 684.552307,97.884117 683.462341,101.476456
	C682.809265,103.628777 682.896667,106.089943 683.055054,108.384399
	C683.288147,111.759109 682.167664,113.479927 678.138550,113.038666
	C676.103210,109.750359 674.523621,106.449783 672.881165,102.815285
	C672.778625,101.581169 672.315735,100.925224 671.381348,100.202057
z"
        />
        <path
          fill="#67BFC6"
          opacity="1.000000"
          stroke="none"
          d="
M689.003052,91.627274
	C688.056946,91.695145 687.240173,91.458237 686.100342,91.189224
	C684.333984,89.066925 685.253540,87.235657 687.293152,87.169594
	C690.028076,87.080994 689.198975,89.596199 689.003052,91.627274
z"
        />
        <path
          fill="#15313E"
          opacity="1.000000"
          stroke="none"
          d="
M671.224548,100.292023
	C672.130920,100.192726 672.800781,100.688461 672.830811,102.131950
	C672.314148,101.848892 671.809998,101.216423 671.224548,100.292023
z"
        />
        <path
          fill="#478D9B"
          opacity="1.000000"
          stroke="none"
          d="
M740.944458,109.096176
	C740.793274,111.727768 739.596985,112.487190 737.688904,111.356026
	C736.553833,110.683167 736.617981,109.142921 737.274536,108.240997
	C738.562378,106.471695 739.952454,106.581711 740.944458,109.096176
z"
        />
        <path
          fill="#478D9B"
          opacity="1.000000"
          stroke="none"
          d="
M735.654053,101.061401
	C737.766174,101.049522 739.571472,100.507545 741.219055,101.569931
	C738.140991,103.816429 735.977356,103.650085 735.654053,101.061401
z"
        />
        <path
          fill="#C08056"
          opacity="1.000000"
          stroke="none"
          d="
M785.823120,303.508057
	C786.678223,303.914978 787.102173,304.187561 787.767700,304.753021
	C788.192566,308.399963 785.527710,309.567780 783.278076,310.780426
	C775.123291,315.176178 766.365356,317.376129 757.085327,317.484741
	C743.518494,317.643524 730.056335,319.285004 716.547424,320.280609
	C708.788574,320.852448 701.022400,321.299713 692.851685,322.253998
	C691.833801,322.491821 691.242981,322.565033 690.203613,322.565552
	C676.038452,322.139893 662.483887,324.189697 648.864014,325.311554
	C642.934753,325.799988 636.981934,325.823395 631.034546,326.293243
	C622.633606,326.956940 614.221619,327.647186 605.411621,328.338745
	C604.514099,328.729492 603.986572,328.891357 603.031860,329.000214
	C600.851379,328.782257 599.069824,328.131989 596.910767,329.184967
	C596.242188,329.329620 595.967224,329.369019 595.270203,329.454712
	C591.822144,329.369232 589.569702,328.471222 590.339111,324.364075
	C593.659180,321.279602 597.690918,322.497528 601.639160,321.502625
	C602.619385,321.512756 603.177246,321.522217 604.147949,321.516235
	C604.834778,321.496246 605.108948,321.482574 605.790649,321.405151
	C606.746948,321.277679 607.296753,321.267120 608.255005,321.365112
	C612.246826,321.782959 615.772766,321.422791 619.672363,320.495483
	C624.327698,320.181793 628.495117,319.080475 633.200928,319.387695
	C634.186707,319.481171 634.751587,319.503448 635.739990,319.510742
	C639.799927,319.334930 643.472778,319.980408 647.486938,318.905151
	C648.469116,318.660614 649.046143,318.593292 650.070435,318.552979
	C663.943665,318.335846 677.266602,316.706177 690.633301,315.618866
	C698.056213,315.015015 705.529358,315.101471 712.986816,314.663879
	C729.017212,313.723267 744.984436,311.953278 761.041382,311.326782
	C767.494507,311.075012 773.726501,308.939362 779.366150,305.495270
	C781.209229,304.369720 783.096313,303.262970 785.823120,303.508057
z"
        />
        <path
          fill="#46170C"
          opacity="1.000000"
          stroke="none"
          d="
M786.480774,303.373230
	C774.794189,312.725800 761.012390,312.967499 747.395630,313.864929
	C715.191956,315.987366 682.989502,318.127441 650.322388,320.155426
	C649.549316,319.539093 649.442017,318.988251 649.737671,318.014465
	C652.715820,315.736572 655.989990,315.980408 659.532837,315.492401
	C660.266663,315.472565 660.561340,315.454559 661.297607,315.375244
	C671.447876,312.715485 681.485535,312.851624 691.363403,312.426849
	C703.599854,311.900696 715.827820,310.447449 728.533936,311.232513
	C741.281128,308.879272 754.039612,309.967194 766.253784,306.995056
	C775.020752,304.861755 781.502747,298.784637 787.958374,292.241211
	C788.718506,291.217682 789.412720,290.742371 790.553162,290.158752
	C791.783203,289.714172 792.644104,289.667908 793.942627,289.759918
	C795.515991,290.231110 796.471741,290.800812 796.213257,292.604248
	C792.906921,296.354889 789.807129,299.729492 786.480774,303.373230
z"
        />
        <path
          fill="#46170C"
          opacity="1.000000"
          stroke="none"
          d="
M691.786011,321.081299
	C702.098022,319.824219 712.676025,318.925812 723.262451,318.140076
	C737.350647,317.094482 751.457520,316.279999 765.532715,315.086945
	C773.868286,314.380402 781.163696,310.822632 787.839539,305.334900
	C795.869995,299.496063 801.207458,291.790649 806.451538,283.538879
	C809.240967,286.205719 808.096252,289.109222 806.586975,292.395996
	C801.964783,300.489166 795.581177,306.763458 789.004639,312.379822
	C784.604248,316.137726 777.924988,317.235870 771.339966,317.175598
	C770.444946,317.164154 769.894775,317.327087 769.154480,317.711670
	C764.122803,320.660400 758.845154,319.133636 753.375366,319.244507
	C747.644592,318.997253 742.299377,318.997498 736.488037,319.010437
	C734.418945,320.753815 732.221558,321.012451 730.089905,321.296448
	C718.856140,322.792969 707.572021,323.322754 696.246155,322.880524
	C694.730225,322.821350 693.274170,322.490540 691.786011,321.081299
z"
        />
        <path
          fill="#784534"
          opacity="1.000000"
          stroke="none"
          d="
M606.609131,83.735939
	C609.384888,84.247581 611.774963,84.578835 614.565796,84.906174
	C617.061584,85.599968 619.156677,86.297661 621.624756,87.010422
	C622.120544,87.156738 622.243347,87.287994 622.632935,87.458374
	C629.503235,89.190323 634.763611,93.395233 640.722107,97.045700
	C641.194824,97.329254 641.362915,97.431213 641.707092,97.769669
	C641.883240,98.006157 641.942749,98.044571 641.952271,98.078705
	C642.741699,99.019005 643.214050,100.240135 644.746460,100.812775
	C645.837463,101.859909 646.700134,102.704033 647.768616,103.773254
	C648.196045,104.472618 648.219238,104.957146 647.852051,105.779564
	C647.458435,106.275101 647.262268,106.442726 646.708252,106.766869
	C645.571228,107.118553 644.790588,107.098053 643.631104,106.824478
	C641.526917,105.973701 640.299866,104.600685 638.947876,103.375458
	C625.525208,91.211655 609.557861,87.054924 591.942200,89.238731
	C586.683533,89.890640 580.935486,89.735146 576.054199,93.623840
	C571.635498,95.197182 568.116577,99.251091 562.947998,96.352829
	C565.681030,93.309601 569.152649,91.515999 572.946838,89.612076
	C582.352966,86.753883 591.376160,83.534004 601.566528,83.881485
	C603.389282,83.263412 604.817078,83.564972 606.609131,83.735939
z"
        />
        <path
          fill="#5D3732"
          opacity="1.000000"
          stroke="none"
          d="
M743.684814,203.147598
	C744.692078,202.784119 745.419556,202.732376 746.511597,202.669098
	C750.897156,203.753601 754.747437,202.038589 758.666016,201.735291
	C766.982788,201.091644 774.538452,202.381409 781.611389,207.643372
	C784.657043,211.309784 787.121765,215.089996 792.339966,215.983261
	C793.091187,216.504623 793.440613,216.893692 793.897217,217.706055
	C794.643921,220.301346 794.219788,222.366852 792.803467,224.620300
	C789.403992,227.116638 787.560181,224.743729 786.035767,222.713852
	C782.086243,217.454575 775.754150,215.222855 771.242004,210.713181
	C770.956299,210.427582 770.290161,210.579636 769.406921,210.657593
	C762.367310,211.065704 755.751526,211.047562 748.935364,208.771027
	C747.713623,208.488235 746.877014,208.457687 745.632202,208.292496
	C744.955139,208.109970 744.693298,208.004349 744.090454,207.645721
	C743.171631,206.717270 742.935913,205.921097 742.984131,204.631714
	C743.165955,203.964218 743.285461,203.711777 743.684814,203.147598
z"
        />
        <path
          fill="#784534"
          opacity="1.000000"
          stroke="none"
          d="
M702.647278,138.808273
	C703.492310,139.194763 704.060486,139.348068 704.822021,139.726562
	C717.338013,146.452866 726.560425,155.843948 732.171265,168.718689
	C733.343750,171.408905 734.354248,174.255920 736.631104,176.774323
	C736.952026,177.449463 737.028625,177.861328 737.128723,178.586746
	C736.967285,182.170197 738.696045,185.136932 738.483765,188.715393
	C734.489685,189.089493 731.726013,186.879517 731.014648,181.706818
	C730.120300,175.203217 726.869324,169.964767 723.594971,164.606964
	C719.800171,158.397476 713.241943,155.541321 707.747620,151.404221
	C706.388000,150.380463 704.542297,150.900101 702.552002,150.701172
	C701.986023,150.459091 701.776672,150.326553 701.318298,149.920761
	C700.700317,149.117722 700.531372,148.475601 700.136353,147.962265
	C696.467712,143.195145 696.475037,143.119171 701.385193,139.019943
	C701.918640,138.745132 702.144470,138.660110 702.647278,138.808273
z"
        />
        <path
          fill="#7D2C11"
          opacity="1.000000"
          stroke="none"
          d="
M789.756226,209.407791
	C800.056030,215.194351 805.075562,224.935104 807.883423,235.630371
	C812.435669,252.970169 810.418335,269.800049 801.264648,285.775818
	C798.563782,283.535339 800.002991,280.911804 800.604736,277.992615
	C800.720886,277.194122 800.770996,276.739502 800.811768,275.944214
	C800.830627,274.925476 800.937012,274.260468 801.265991,273.305054
	C801.903381,272.053162 803.078308,271.626190 803.357910,270.159119
	C803.451843,268.886475 803.597534,267.957886 803.886719,266.670288
	C805.911255,258.285126 805.405334,250.169159 804.797546,242.075165
	C804.561279,238.929291 803.669800,235.920975 801.450806,233.410095
	C798.797058,230.407288 799.468323,226.089188 797.330933,222.429901
	C796.075134,218.867294 794.285950,216.202042 791.419250,214.275192
	C789.833679,213.209442 788.725769,211.812210 789.756226,209.407791
z"
        />
        <path
          fill="#632B1D"
          opacity="1.000000"
          stroke="none"
          d="
M802.726929,276.727478
	C802.126526,279.648132 800.338989,282.093262 800.971802,285.539398
	C799.667114,288.305237 798.648254,290.841858 796.325195,292.866211
	C795.877319,292.106079 795.615173,291.329163 794.307678,291.035034
	C791.844971,289.966309 790.458130,288.442139 790.594238,285.488739
	C790.579834,284.536591 790.620422,283.996307 790.811157,283.069763
	C793.356506,273.865936 799.509338,266.445709 800.855652,256.852722
	C804.341736,256.383881 804.341003,258.810760 804.647766,260.855103
	C804.912170,262.616882 804.181641,264.425964 804.878052,266.602386
	C804.984131,268.072113 804.984131,269.137329 804.976196,270.601868
	C804.338379,271.940674 803.135620,271.861053 802.185547,272.757690
	C802.036926,273.764954 802.031128,274.499695 802.020630,275.601746
	C802.171204,276.140137 802.326477,276.311218 802.726929,276.727478
z"
        />
        <path
          fill="#813920"
          opacity="1.000000"
          stroke="none"
          d="
M789.754211,208.881500
	C789.807373,211.209076 790.974426,212.326340 792.606628,213.440216
	C795.062744,215.116409 797.145813,217.241486 797.964111,220.650665
	C795.256226,222.507721 794.530823,220.257889 793.258301,218.278290
	C792.882324,217.688187 792.687317,217.434692 792.218384,217.109314
	C788.462891,214.959045 785.712708,211.920883 782.246704,209.246155
	C777.829346,208.757156 775.543213,204.674606 771.527710,203.952805
	C764.611877,202.709656 757.754517,202.406128 750.844238,203.964157
	C749.631348,204.237625 747.966431,205.662918 746.954224,203.021713
	C761.964294,198.383087 776.298096,199.481598 789.754211,208.881500
z"
        />
        <path
          fill="#784534"
          opacity="1.000000"
          stroke="none"
          d="
M665.934082,134.695618
	C667.175537,136.477097 667.773438,138.478775 670.633118,138.131760
	C671.164917,138.361176 671.334167,138.555359 671.619934,139.118744
	C671.765137,140.013565 671.658936,140.510376 671.144470,141.249298
	C665.627808,142.165070 663.226379,140.816574 660.671814,136.499695
	C656.267151,129.056671 652.388367,121.353630 648.451538,113.269218
	C647.448242,112.248207 646.860657,111.391487 646.210693,110.101028
	C645.772949,108.645042 645.841858,107.608307 646.732910,106.335426
	C646.980469,106.019699 646.995667,106.004555 647.004883,105.998901
	C650.020508,105.326202 650.579041,108.495476 652.774780,109.735703
	C653.033325,109.967346 653.017456,109.993263 653.019409,109.983063
	C653.216370,110.216171 653.427368,110.444649 653.822388,110.820343
	C653.990417,110.982407 654.011292,110.990746 654.012817,111.001938
	C655.972046,113.240074 656.915222,116.076508 658.755249,118.760742
	C658.989441,119.006615 659.000000,118.999695 658.996460,119.004150
	C660.890381,124.306046 662.745605,129.621613 665.934082,134.695618
z"
        />
        <path
          fill="#87462F"
          opacity="1.000000"
          stroke="none"
          d="
M736.863281,176.673615
	C734.750183,177.228745 734.272278,175.697510 733.481567,174.152328
	C730.786621,168.886093 728.419861,163.330582 724.938232,158.616379
	C719.618896,151.413864 712.600403,145.781799 705.167358,140.258209
	C714.541992,142.733566 721.242493,149.143768 726.899231,156.927429
	C731.223633,162.877716 734.008240,169.605621 736.863281,176.673615
z"
        />
        <path
          fill="#87462F"
          opacity="1.000000"
          stroke="none"
          d="
M737.719971,189.332306
	C738.214233,185.692963 735.586792,182.806290 736.879028,179.149033
	C739.538330,184.163788 740.101685,189.865906 741.235168,195.441116
	C741.713867,197.795792 741.792542,200.358383 743.703857,202.603729
	C743.971558,203.032745 743.978516,203.229614 743.986572,203.721985
	C742.309204,205.374283 740.646118,205.227600 738.685669,203.631805
	C737.546021,201.974869 737.313171,200.511612 737.405396,198.546997
	C737.726990,195.300186 735.756104,192.484787 737.719971,189.332306
z"
        />
        <path
          fill="#87462F"
          opacity="1.000000"
          stroke="none"
          d="
M666.208374,134.508362
	C661.945740,133.525467 661.402161,129.532562 660.243286,126.069427
	C659.574829,124.071922 659.395508,121.910728 658.994446,119.415909
	C661.972351,123.768997 664.834351,128.585190 666.208374,134.508362
z"
        />
        <path
          fill="#784534"
          opacity="1.000000"
          stroke="none"
          d="
M674.561035,137.326599
	C675.773132,136.905136 676.602722,136.629547 677.756104,136.290863
	C679.056641,136.695694 680.218323,136.292526 681.333374,137.260498
	C679.279419,142.342743 676.738403,143.159286 672.369995,140.132263
	C672.281433,138.449738 672.879517,137.663635 674.561035,137.326599
z"
        />
        <path
          fill="#784534"
          opacity="1.000000"
          stroke="none"
          d="
M688.760254,135.799194
	C689.863159,135.660812 690.668274,135.822220 691.775574,136.093582
	C693.476318,136.789688 694.964661,136.336685 696.695312,136.780045
	C696.997437,136.983582 697.004150,136.994843 697.010986,136.993454
	C697.707886,137.455322 698.370850,137.939606 698.382812,139.269348
	C694.709167,141.916702 691.451172,141.272110 688.303833,138.150406
	C688.130737,137.172379 688.225342,136.623413 688.760254,135.799194
z"
        />
        <path
          fill="#471B15"
          opacity="1.000000"
          stroke="none"
          d="
M805.296997,293.196411
	C805.631897,290.031158 807.725037,287.445618 806.794189,283.765564
	C806.722473,283.303741 806.802124,282.855194 806.921814,282.661316
	C808.257141,282.899689 808.575928,284.069611 809.252258,285.354004
	C808.888428,287.185730 810.036194,288.536957 809.713135,290.405579
	C808.953125,292.672668 808.009277,294.183472 805.296997,293.196411
z"
        />
        <path
          fill="#87462F"
          opacity="1.000000"
          stroke="none"
          d="
M652.979126,109.635071
	C650.033264,110.527740 649.538818,107.357834 647.311768,106.158745
	C647.261963,105.498245 647.509766,105.003235 647.865967,104.253288
	C650.100586,105.322243 651.818542,107.027153 652.979126,109.635071
z"
        />
        <path
          fill="#87462F"
          opacity="1.000000"
          stroke="none"
          d="
M658.947021,118.655228
	C655.639099,117.745224 655.748901,114.117203 654.018555,111.379028
	C656.871521,112.620308 657.669067,115.608986 658.947021,118.655228
z"
        />
        <path
          fill="#87462F"
          opacity="1.000000"
          stroke="none"
          d="
M688.645020,135.404846
	C689.039490,135.846619 689.020874,136.193848 688.994995,136.803726
	C686.753601,138.965973 684.361938,139.127716 681.458740,137.406204
	C680.248962,136.942245 679.408020,136.787949 678.323486,136.430725
	C681.463928,135.921951 684.847961,135.616119 688.645020,135.404846
z"
        />
        <path
          fill="#87462F"
          opacity="1.000000"
          stroke="none"
          d="
M698.320923,139.315247
	C697.748474,138.514923 697.490906,138.013870 697.125610,137.252441
	C699.018005,136.851318 700.679321,137.937546 702.704529,138.771515
	C702.751221,139.054626 702.578308,139.067780 702.146484,139.105438
	C700.892334,139.887024 699.815674,140.097076 698.320923,139.315247
z"
        />
        <path
          fill="#87462F"
          opacity="1.000000"
          stroke="none"
          d="
M644.843567,100.738663
	C643.432556,101.030685 642.087158,100.779373 641.983643,98.466194
	C642.878601,98.895737 643.795471,99.678650 644.843567,100.738663
z"
        />
        <path
          fill="#87462F"
          opacity="1.000000"
          stroke="none"
          d="
M696.731445,136.732529
	C695.545959,136.867050 694.094543,136.750519 692.360413,136.418762
	C693.559814,135.989120 695.034546,135.890030 696.731445,136.732529
z"
        />
        <path
          fill="#87462F"
          opacity="1.000000"
          stroke="none"
          d="
M674.596191,137.136627
	C674.389954,137.652649 673.836365,138.124557 673.130737,138.826965
	C672.584534,139.376709 672.135010,139.511261 671.321289,139.262787
	C671.012329,139.064438 671.001648,138.614883 670.998657,138.390930
	C671.962708,137.453674 672.990784,136.925125 674.596191,137.136627
z"
        />
        <path
          fill="#C46B41"
          opacity="1.000000"
          stroke="none"
          d="
M669.150879,132.115219
	C669.750000,132.286423 670.353271,132.808090 671.072754,133.614655
	C670.158447,133.921631 669.362488,133.610992 669.150879,132.115219
z"
        />
        <path
          fill="#C46B41"
          opacity="1.000000"
          stroke="none"
          d="
M622.773438,87.381195
	C622.643005,87.552589 622.422668,87.486343 622.118286,87.162109
	C622.234314,87.051140 622.450745,87.130951 622.773438,87.381195
z"
        />
        <path
          fill="#87462F"
          opacity="1.000000"
          stroke="none"
          d="
M653.894653,110.758698
	C653.680664,110.865578 653.429626,110.672447 653.129395,110.187943
	C653.345703,110.070175 653.604858,110.257553 653.894653,110.758698
z"
        />
        <path
          fill="#87462F"
          opacity="1.000000"
          stroke="none"
          d="
M641.803101,97.815147
	C641.636963,97.901871 641.425781,97.749115 641.138184,97.387589
	C641.324768,97.243835 641.556824,97.376122 641.803101,97.815147
z"
        />
        <path
          fill="#693E39"
          opacity="1.000000"
          stroke="none"
          d="
M348.428528,144.845612
	C348.858978,145.738464 348.925323,146.499847 348.920502,147.638992
	C347.118744,149.697403 349.073029,152.645157 346.378479,154.061584
	C344.095276,145.576660 345.024597,137.090118 345.719055,128.292496
	C348.069977,131.356339 346.489471,135.092163 347.009399,138.624847
	C347.309204,140.662155 346.126068,142.962006 348.428528,144.845612
z"
        />
        <path
          fill="#693E39"
          opacity="1.000000"
          stroke="none"
          d="
M348.593140,141.147034
	C348.700317,141.051193 348.825958,141.500183 348.778381,141.728058
	C348.438110,141.779526 348.356476,141.541855 348.593140,141.147034
z"
        />
        <path
          fill="#562118"
          opacity="1.000000"
          stroke="none"
          d="
M905.513550,551.716919
	C906.836792,550.609131 908.056702,549.898071 909.627808,549.151733
	C914.999207,550.246399 920.395813,548.260071 925.613770,550.984985
	C932.891663,552.426758 939.948059,553.463867 944.955505,559.648132
	C945.008240,560.165466 945.004333,560.335754 944.998535,560.761597
	C944.387390,561.942322 943.474792,562.406555 942.064575,562.758545
	C937.649597,562.739807 934.078430,560.891846 930.379700,559.663879
	C926.457947,558.361816 922.574036,557.240723 918.043213,557.245178
	C914.210449,557.303589 911.170837,555.461060 907.435059,554.940918
	C906.107117,554.140991 905.477478,553.302612 905.513550,551.716919
z"
        />
        <path
          fill="#3E1E1C"
          opacity="1.000000"
          stroke="none"
          d="
M925.777954,550.763184
	C920.731445,550.822632 915.360718,552.005554 910.107422,549.483337
	C913.567932,547.687317 917.370850,547.395996 921.579041,547.015869
	C922.006531,549.382751 923.975220,549.751099 925.777954,550.763184
z"
        />
        <path
          fill="#693E39"
          opacity="1.000000"
          stroke="none"
          d="
M349.536591,168.696594
	C350.465149,184.387955 351.227875,199.807495 352.162537,215.642471
	C352.334503,216.057922 352.140320,216.238037 352.014893,216.195557
	C347.277191,216.816513 345.550507,215.325333 346.067200,210.552597
	C346.674164,204.945877 346.506683,199.355072 346.189728,193.350342
	C346.017456,191.582077 346.022278,190.214493 346.026917,188.436600
	C346.005127,187.211716 345.999969,186.397003 346.015625,185.276703
	C346.020142,183.597321 346.019287,182.223419 346.016541,180.437347
	C346.003204,179.216324 346.000214,178.407440 346.008331,177.295197
	C346.008514,170.639343 346.005920,164.286804 346.007812,157.465698
	C349.865356,155.985687 347.881409,159.258713 348.907318,160.837723
	C349.297119,161.499847 349.391815,161.863358 349.402252,162.501389
	C348.921448,164.662735 348.758484,166.547379 349.536591,168.696594
z"
        />
        <path
          fill="#43434B"
          opacity="1.000000"
          stroke="none"
          d="
M345.845520,193.335632
	C347.985413,193.365067 348.069519,194.891006 347.984039,196.434082
	C347.736969,200.894653 348.527252,205.378952 347.291656,209.830414
	C346.403961,213.028503 347.521393,215.414322 351.515320,216.090942
	C352.173096,217.345306 351.444946,218.090546 350.418213,218.965637
	C347.562408,219.500305 345.612701,220.642181 344.930939,223.358505
	C344.513489,225.021667 343.123779,225.495651 341.333313,225.949432
	C340.877502,221.616760 342.156799,217.527893 343.782837,213.561874
	C344.551239,211.687561 345.147858,209.865295 344.978058,207.428497
	C345.356720,202.595383 344.253723,198.091370 345.845520,193.335632
z"
        />
        <path
          fill="#5D3732"
          opacity="1.000000"
          stroke="none"
          d="
M349.239929,160.660538
	C347.539062,160.330215 348.565796,157.824051 346.349243,157.075867
	C346.006622,156.248169 346.000946,155.499191 346.001160,154.375687
	C347.997498,152.548035 345.878754,149.557480 348.510193,148.117035
	C348.992004,152.072754 349.134735,156.128738 349.239929,160.660538
z"
        />
        <path
          fill="#43434B"
          opacity="1.000000"
          stroke="none"
          d="
M345.780151,177.191101
	C346.957611,177.729507 347.156647,178.597397 346.241852,179.822479
	C345.078094,179.284927 344.835754,178.422043 345.780151,177.191101
z"
        />
        <path
          fill="#43434B"
          opacity="1.000000"
          stroke="none"
          d="
M345.789734,185.174286
	C346.947937,185.719543 347.161285,186.590897 346.252014,187.821655
	C345.074493,187.284088 344.837830,186.415344 345.789734,185.174286
z"
        />
        <path
          fill="#213642"
          opacity="1.000000"
          stroke="none"
          d="
M336.299072,190.686615
	C335.135345,190.210464 334.227722,189.433853 333.214539,188.375061
	C334.179626,186.710037 333.776733,184.965973 334.332397,183.099243
	C334.414551,182.761765 334.551056,182.331253 334.648773,182.127716
	C338.792267,184.008926 336.662811,187.413651 336.299072,190.686615
z"
        />
        <path
          fill="#15191F"
          opacity="1.000000"
          stroke="none"
          d="
M795.355591,376.946228
	C795.003540,376.832153 795.002197,376.661560 794.999146,376.235107
	C791.602661,369.175537 794.282776,362.184113 794.915649,355.423767
	C795.307129,351.242706 794.926453,348.428986 791.724487,345.894135
	C790.433655,344.872223 789.247253,343.606201 789.774414,341.336487
	C791.082275,340.850250 791.717407,340.260498 792.084839,339.199615
	C793.451050,335.253723 796.468811,334.795746 800.043701,335.494995
	C801.324951,335.745605 802.635437,335.956451 803.936218,335.979431
	C809.599792,336.079285 815.401978,335.075775 820.272644,339.456909
	C821.395325,340.466736 823.464661,341.283508 825.686035,340.133667
	C826.137024,334.013000 827.284607,332.711700 832.803528,332.811584
	C833.353516,333.996399 833.668213,334.924286 834.125122,336.195801
	C834.280884,337.300110 833.984741,337.947113 833.290649,338.799286
	C827.939819,342.313293 825.322571,347.396576 822.678772,352.794769
	C822.165771,353.713013 821.987244,354.542847 821.879272,355.691284
	C823.335144,357.256927 823.559265,358.885376 823.467041,361.036377
	C823.472717,362.570740 823.517395,363.693024 822.494263,365.363342
	C817.021057,370.220642 816.924255,372.926849 821.602478,376.498138
	C823.016418,377.577606 823.317078,378.983795 822.545105,380.432831
	C821.713074,381.994751 819.967651,383.227356 818.459595,382.474182
	C814.337341,380.415314 810.037903,381.171051 805.790283,380.996155
	C803.107910,380.885712 801.397949,379.706879 801.047668,376.941223
	C800.842896,375.324554 800.872375,373.630493 799.073181,372.586517
	C797.328735,373.533234 798.279602,376.662476 795.355591,376.946228
z"
        />
        <path
          fill="#1D1415"
          opacity="1.000000"
          stroke="none"
          d="
M789.555725,341.000000
	C790.532593,344.442047 794.235046,344.932251 795.937256,347.345123
	C797.008301,348.863281 798.509705,350.123749 797.706787,352.165375
	C794.792725,359.574677 795.890076,367.439880 794.997559,375.527771
	C791.924805,375.348419 792.959473,371.521027 790.304443,370.161438
	C788.359558,367.011902 789.236572,363.782318 789.212280,360.679199
	C789.187439,357.505371 788.120178,355.058838 786.121582,352.586975
	C778.734558,343.450714 768.913330,340.724915 757.768372,341.380402
	C754.061401,341.598419 750.353943,340.972198 746.989990,340.072693
	C740.024048,338.209961 733.095154,338.603271 726.215820,339.231415
	C718.396912,339.945343 710.702332,340.170929 702.893311,339.140930
	C695.443298,338.158295 688.032227,340.652161 680.532959,340.008179
	C678.227966,339.810242 675.875671,340.345673 673.299622,339.283020
	C677.626709,336.028473 682.290405,333.049957 688.041199,333.019440
	C700.529480,332.953094 713.025452,332.737671 725.505188,333.071045
	C738.760315,333.425140 752.014038,334.158325 765.237732,335.139740
	C768.740479,335.399719 772.177246,337.054291 775.560303,338.297150
	C779.953918,339.911255 784.308777,341.530090 789.555725,341.000000
z"
        />
        <path
          fill="#321616"
          opacity="1.000000"
          stroke="none"
          d="
M672.647095,339.039154
	C680.456970,339.011932 687.877930,339.177582 695.258423,337.294647
	C698.876892,336.371460 702.836792,336.595825 706.559937,337.994843
	C711.061401,339.686371 715.922729,339.743652 720.315186,338.528839
	C727.401672,336.568939 734.495850,337.307892 741.597046,336.967010
	C744.709961,336.817535 747.118286,338.775543 750.129639,339.216309
	C756.612488,340.165253 763.101562,338.939209 769.697205,340.631897
	C774.517822,341.869080 778.946533,343.502441 782.580322,346.351837
	C789.922424,352.109161 793.397461,359.693573 790.174194,369.610291
	C787.976990,373.158600 789.370911,376.671326 789.022583,380.009216
	C788.822266,381.928101 789.293396,384.065857 786.372742,384.922119
	C785.989502,377.327667 785.557251,369.639191 786.118835,362.023987
	C786.712097,353.978058 781.994629,349.669708 776.083740,346.373993
	C772.849243,344.570557 769.050781,343.289703 765.178345,343.061768
	C747.957520,342.048218 730.769348,340.138550 713.474792,341.030334
	C707.850708,341.320312 702.230957,341.927856 696.607849,341.946320
	C689.816406,341.968628 683.082153,340.656342 676.240356,340.955109
	C671.919556,341.143799 669.221680,342.625641 668.919800,347.619537
	C664.954407,348.830353 664.353455,348.252472 665.618042,344.266937
	C667.747314,341.945312 669.336914,339.637451 672.647095,339.039154
z"
        />
        <path
          fill="#1F0F0F"
          opacity="1.000000"
          stroke="none"
          d="
M804.842041,293.236053
	C807.027283,293.311035 807.850342,292.017792 808.713989,290.204224
	C809.947327,288.879181 811.110962,289.090302 812.728210,289.676422
	C813.152588,289.836273 813.171936,289.877747 813.154297,289.867615
	C812.692261,292.090912 813.666016,293.984863 814.404419,296.007416
	C816.236755,301.026093 813.480286,304.452850 808.217407,304.320984
	C806.327881,304.273651 804.417114,305.073639 802.254395,305.739502
	C796.800354,309.101379 790.750244,310.747803 788.298035,317.650024
	C786.645813,322.300568 775.516968,321.971619 771.070862,317.352600
	C775.946777,315.875122 780.413513,313.118195 785.622986,312.767975
	C786.825378,312.687164 788.582275,311.981598 789.033691,311.052979
	C791.852783,305.253448 798.419373,302.746979 801.177673,296.896576
	C801.821533,295.531067 803.487488,294.647430 804.842041,293.236053
z"
        />
        <path
          fill="#2C1E22"
          opacity="1.000000"
          stroke="none"
          d="
M665.561035,344.011902
	C664.666382,346.505096 665.499878,347.779785 668.556396,348.000916
	C668.955627,350.315308 668.710999,352.715942 671.493469,353.863403
	C672.714844,354.367157 673.122559,355.577637 672.941772,356.771423
	C672.483337,359.798065 674.195862,361.339905 676.813354,362.781891
	C676.696960,367.013580 672.065796,366.480530 670.262756,369.456360
	C669.281311,370.061096 668.552002,370.290070 667.398193,370.456085
	C665.150513,370.689392 663.471436,371.945618 661.119568,371.199463
	C659.017090,369.004211 659.652222,366.584320 659.530273,364.318726
	C659.219299,358.544922 660.123779,352.731873 658.808838,346.559143
	C657.663208,339.496460 658.225708,332.783752 657.807068,325.684875
	C658.499268,323.966980 659.504456,323.446472 661.310181,323.582275
	C662.248474,324.050171 662.476685,324.575012 662.225464,325.608154
	C662.004395,330.748871 662.044128,335.496368 661.973938,340.242218
	C661.940552,342.501923 662.385498,344.182129 665.561035,344.011902
z"
        />
        <path
          fill="#1F0F0F"
          opacity="1.000000"
          stroke="none"
          d="
M691.271973,320.630920
	C704.281189,321.501770 716.995178,320.994415 729.670776,319.290192
	C731.470764,319.048157 733.308167,319.084229 735.575134,319.007385
	C736.422119,323.457428 733.786682,325.013092 729.979248,324.957458
	C716.271362,324.757111 702.555908,325.554260 688.851074,324.547028
	C685.453247,324.297333 682.017273,325.741547 678.292603,324.268860
	C678.415405,321.123962 680.905090,321.851990 682.601257,321.584595
	C684.855652,321.229156 687.194763,321.609283 689.771851,321.084167
	C690.364563,320.636810 690.690369,320.579956 691.271973,320.630920
z"
        />
        <path
          fill="#46170C"
          opacity="1.000000"
          stroke="none"
          d="
M689.579407,320.699371
	C686.614807,323.405609 681.954895,320.817261 678.255493,323.730103
	C677.224060,324.002991 676.455383,324.003571 675.302490,324.014038
	C673.243042,324.992096 671.398560,324.239777 669.321960,324.728424
	C667.605103,324.927551 666.224182,324.939941 664.428833,324.952942
	C663.415527,324.965302 662.870239,324.808044 662.173401,324.246887
	C661.120605,324.212067 660.287231,324.367981 659.234924,324.760559
	C656.663086,327.574402 653.713135,327.921387 650.129639,327.140442
	C649.191895,326.948578 648.970886,325.710999 647.834839,326.730621
	C644.080505,328.006012 640.538086,328.424286 636.585693,328.499268
	C635.321289,328.345520 634.432556,328.119843 633.192383,328.100891
	C632.228394,328.122192 631.595093,328.063782 630.687134,327.797638
	C629.924316,327.857300 630.261658,326.422913 629.223206,327.375336
	C628.593018,327.670532 628.202881,327.729034 627.520020,327.610901
	C626.948975,327.363556 626.856995,327.061249 626.710754,326.798584
	C626.677490,327.107361 626.717407,327.409882 626.804932,328.008789
	C626.707153,328.503235 626.635010,328.694946 626.370361,329.129211
	C625.660583,329.748230 625.073547,329.912323 624.140076,329.919128
	C623.645447,329.843750 623.453186,329.791748 622.993774,329.616882
	C622.234558,329.214447 621.785522,328.861542 620.987488,328.600464
	C619.930237,328.540222 619.301331,328.929565 618.426697,329.450745
	C617.829895,329.679443 617.475830,329.749023 616.869324,329.772095
	C615.576355,330.191467 616.098877,331.420349 615.217590,332.272949
	C614.380066,332.854095 613.728149,332.958618 612.708862,332.791718
	C611.504761,332.284058 611.164978,331.415558 610.426453,330.512939
	C609.843933,330.168823 609.477844,330.042938 608.798340,329.916931
	C607.230957,329.687225 605.948425,329.558960 604.329468,328.485596
	C604.391968,326.371552 605.799561,326.379303 607.123230,326.289398
	C623.350159,325.186829 639.578735,324.108154 655.804932,322.994568
	C666.907776,322.232574 678.007446,321.425659 689.579407,320.699371
z"
        />
        <path
          fill="#1F0F0F"
          opacity="1.000000"
          stroke="none"
          d="
M753.104248,319.380005
	C757.911743,317.262604 763.019714,318.221100 768.503784,317.944885
	C768.045227,321.036621 765.260193,320.985870 763.005615,320.885315
	C759.745239,320.739960 756.210938,322.309998 753.104248,319.380005
z"
        />
        <path
          fill="#2C1E22"
          opacity="1.000000"
          stroke="none"
          d="
M669.385864,324.937622
	C670.502625,323.481232 672.391785,324.152069 674.526978,324.013702
	C673.433167,325.574554 671.522522,324.791077 669.385864,324.937622
z"
        />
        <path
          fill="#1F0F0F"
          opacity="1.000000"
          stroke="none"
          d="
M661.968872,324.379974
	C662.511902,324.207336 663.069824,324.358521 663.821167,324.731689
	C663.543091,325.280518 663.071655,325.607391 662.304932,325.968170
	C662.001099,325.569305 661.992493,325.136566 661.968872,324.379974
z"
        />
        <path
          fill="#3C545F"
          opacity="1.000000"
          stroke="none"
          d="
M844.396179,278.256439
	C849.591553,280.473053 855.037476,278.630096 860.169922,279.707153
	C862.410217,280.177307 864.804688,279.913025 867.584351,280.072693
	C866.942749,287.825714 861.152161,291.746124 855.216370,295.854492
	C853.691650,295.185760 854.195679,293.468781 853.165955,292.853302
	C854.161621,296.017426 852.355591,296.888397 849.261475,297.015686
	C845.882812,299.993103 846.586243,294.640442 844.363525,295.078979
	C843.218079,294.409027 843.619019,293.468903 843.301514,292.390686
	C843.475952,287.599792 843.808289,283.088348 844.396179,278.256439
z"
        />
        <path
          fill="#5E6B74"
          opacity="1.000000"
          stroke="none"
          d="
M843.091309,292.516357
	C843.926819,292.682770 843.997498,293.526581 844.035400,294.713745
	C843.536560,298.189667 844.888428,301.542480 842.709412,304.651306
	C842.598694,300.806458 842.818848,296.863983 843.091309,292.516357
z"
        />
        <path
          fill="#101417"
          opacity="1.000000"
          stroke="none"
          d="
M837.020813,294.110199
	C835.835388,295.350708 834.004333,294.897949 831.860229,294.384827
	C833.195435,294.129303 834.858704,294.103210 837.020813,294.110199
z"
        />
        <path
          fill="#929EAC"
          opacity="1.000000"
          stroke="none"
          d="
M878.042480,391.632660
	C871.780579,391.310944 865.793640,389.562195 859.380127,387.972168
	C857.681396,380.922699 852.660339,379.122314 846.519287,378.394287
	C842.440430,377.910797 838.631775,376.119293 834.799316,374.644562
	C832.555969,373.781281 830.866638,372.241486 830.766968,369.237854
	C831.337341,368.576660 831.828308,368.469879 832.688721,368.758850
	C833.761841,368.980621 834.506348,368.993195 835.623047,369.012238
	C836.380249,371.961151 838.710388,372.529449 841.007202,372.991943
	C847.897644,374.379486 854.811707,375.649933 861.700195,377.046509
	C865.360107,377.788513 868.294678,380.086639 871.179443,382.207672
	C873.875366,384.189819 876.613831,385.913757 880.125244,386.960876
	C879.398621,388.508362 878.742432,389.905731 878.042480,391.632660
z"
        />
        <path
          fill="#A67A5E"
          opacity="1.000000"
          stroke="none"
          d="
M912.980225,399.372070
	C910.279114,401.947479 906.959595,400.665894 903.470764,401.017090
	C902.678833,400.691406 902.523315,400.342407 902.740234,399.655029
	C904.346497,399.136047 905.773865,398.869141 907.453735,398.360657
	C908.860535,398.097046 910.014832,398.075043 911.600586,398.041748
	C912.336609,398.397797 912.640991,398.765167 912.980225,399.372070
z"
        />
        <path
          fill="#929EAC"
          opacity="1.000000"
          stroke="none"
          d="
M882.988159,392.638824
	C882.455322,392.960114 881.914001,392.940643 881.136902,392.784027
	C881.044800,391.915558 880.882935,390.894257 881.831482,390.725494
	C882.981689,390.520844 882.815430,391.602020 882.988159,392.638824
z"
        />
        <path
          fill="#C1ACB2"
          opacity="1.000000"
          stroke="none"
          d="
M822.639526,352.815369
	C823.082642,346.345520 826.515625,341.535797 832.231262,337.957153
	C837.984741,336.393250 843.428650,335.585999 848.951599,337.466766
	C851.855042,338.455536 854.329651,339.915863 855.470703,343.492615
	C857.091248,344.033142 858.180786,344.615173 858.924500,345.895660
	C862.294128,351.696930 865.737305,357.455658 869.083984,363.269958
	C870.117981,365.066315 870.760864,367.267487 869.186951,368.854034
	C867.555420,370.498688 865.184875,371.543030 862.836792,370.322845
	C860.170105,368.937073 857.403015,368.888062 854.582642,369.019562
	C851.670044,369.155426 849.036865,368.864136 846.799622,366.601898
	C845.948975,365.741760 844.404480,365.106506 843.195190,365.136475
	C834.705688,365.347107 829.875549,359.963898 825.588745,353.800842
	C824.796631,352.661987 824.555359,351.309082 822.639526,352.815369
z"
        />
        <path
          fill="#F4E1D8"
          opacity="1.000000"
          stroke="none"
          d="
M855.750000,343.527161
	C849.652710,336.884064 841.476440,337.878479 833.054749,337.940308
	C832.971802,337.574799 833.382629,337.213654 834.030396,336.695923
	C841.965454,333.674835 849.929321,332.733124 858.541626,333.124084
	C858.916870,333.731110 858.844727,334.460480 858.670166,335.543823
	C855.947754,337.682343 855.216187,340.182434 855.750000,343.527161
z"
        />
        <path
          fill="#9FA2B2"
          opacity="1.000000"
          stroke="none"
          d="
M833.012573,368.573853
	C832.570068,368.976410 832.122803,368.984772 831.340210,368.996582
	C830.813171,369.126831 830.621521,369.253662 830.091675,369.321259
	C827.144409,367.210358 824.738403,364.951813 822.416626,362.147461
	C822.000854,360.256989 821.857544,358.548798 821.832031,356.425232
	C825.464600,360.222107 832.251221,361.461334 833.012573,368.573853
z"
        />
        <path
          fill="#517581"
          opacity="1.000000"
          stroke="none"
          d="
M849.168091,297.264160
	C849.031921,295.606934 858.316345,294.788300 849.193115,292.735016
	C848.468811,292.571991 848.285767,291.834076 848.703125,291.171204
	C849.176331,290.419739 849.952393,289.969299 850.793335,290.249268
	C853.143677,291.031616 854.698853,292.561005 854.886597,295.532501
	C853.279175,297.170715 851.812073,298.896149 849.168091,297.264160
z"
        />
        <path
          fill="#43626E"
          opacity="1.000000"
          stroke="none"
          d="
M861.680908,307.901917
	C864.279053,312.453552 860.474426,312.909790 857.377563,314.121643
	C855.277893,309.868073 858.292236,308.906708 861.680908,307.901917
z"
        />
        <path
          fill="#517581"
          opacity="1.000000"
          stroke="none"
          d="
M862.179382,299.746277
	C862.224548,300.468353 862.140137,300.893250 862.017395,301.640320
	C860.291748,301.914398 859.657715,300.937286 860.141235,299.457001
	C860.618896,297.995056 861.403198,298.444977 862.179382,299.746277
z"
        />
        <path
          fill="#4C707C"
          opacity="1.000000"
          stroke="none"
          d="
M150.227112,643.244995
	C149.999893,642.245972 150.001404,641.494263 150.006958,640.366394
	C150.757004,639.994995 151.503021,639.999756 152.622070,640.003479
	C153.832169,640.883179 154.669220,641.763855 155.743286,642.841797
	C156.211136,647.171936 159.309967,649.001160 162.648285,650.959961
	C162.991348,651.006348 162.999619,651.000000 162.996613,651.003052
	C163.790771,651.960449 163.986465,653.142151 164.157486,654.649231
	C164.075455,654.996338 163.607178,655.039551 163.373718,655.046204
	C159.683472,652.688049 156.827850,649.659302 154.323593,646.375916
	C153.229050,644.940857 151.952423,644.172974 150.227112,643.244995
z"
        />
        <path
          fill="#43626E"
          opacity="1.000000"
          stroke="none"
          d="
M164.300018,654.780884
	C163.718445,653.900146 163.361450,652.803894 162.999023,651.356934
	C164.427475,651.795349 165.413116,652.777344 164.300018,654.780884
z"
        />
        <path
          fill="#557E8B"
          opacity="1.000000"
          stroke="none"
          d="
M177.221924,666.702209
	C173.133606,669.139221 172.223526,665.185608 170.061844,663.337158
	C170.792267,662.768677 171.588120,662.532410 172.668869,662.168091
	C173.381943,662.050293 173.810104,662.060608 174.555786,662.100159
	C175.026871,663.220276 174.988297,664.414795 176.671844,664.928711
	C177.378479,665.389465 177.526062,665.852722 177.221924,666.702209
z"
        />
        <path
          fill="#517581"
          opacity="1.000000"
          stroke="none"
          d="
M177.397446,667.014771
	C177.000748,666.566956 176.999878,666.119202 176.999237,665.335693
	C178.390350,665.003845 179.781235,665.007690 181.928894,665.159607
	C183.047073,665.665894 183.252274,666.101074 183.163239,666.936951
	C182.546738,667.483093 182.071198,667.555237 181.266754,667.258362
	C179.904343,667.040100 178.848801,667.027466 177.397446,667.014771
z"
        />
        <path
          fill="#517581"
          opacity="1.000000"
          stroke="none"
          d="
M182.070923,664.341919
	C182.593857,664.275574 183.094559,664.430847 183.765808,664.795471
	C183.572067,665.375854 183.129837,665.507080 182.318268,665.221863
	C182.015854,665.011353 182.006012,665.004028 182.006317,665.005432
	C182.006638,665.006836 182.048691,664.563477 182.070923,664.341919
z"
        />
        <path
          fill="#496A76"
          opacity="1.000000"
          stroke="none"
          d="
M182.124344,665.266968
	C182.443176,665.003662 182.870514,664.995911 183.617111,664.996521
	C185.436737,664.857300 186.937088,664.709839 188.710785,664.315002
	C189.529755,663.909485 190.075363,663.751282 190.816101,663.386963
	C194.031281,663.189209 197.051315,663.197693 200.520737,663.249756
	C203.662415,664.631714 206.200745,663.717285 209.060349,662.640137
	C210.067841,662.355042 210.751190,662.184387 211.742981,662.673584
	C212.489639,662.910706 212.935440,662.958923 213.708893,663.088745
	C214.025375,664.189697 214.014206,665.209106 214.001526,666.614258
	C213.836807,667.131775 213.673630,667.263611 213.238922,667.236450
	C212.541443,667.079529 212.115494,667.081665 211.370178,667.092590
	C209.890594,668.181885 208.500061,668.586975 206.943863,668.488403
	C200.547958,668.083191 194.083328,669.397400 187.338165,667.426270
	C186.213669,667.113953 185.489349,667.096008 184.402740,667.085571
	C183.855988,667.195312 183.671509,667.297485 183.239853,667.202271
	C182.739380,666.510742 182.486115,666.016663 182.124344,665.266968
z"
        />
        <path
          fill="#83AEBF"
          opacity="1.000000"
          stroke="none"
          d="
M229.726639,542.255005
	C230.162384,542.000793 230.329071,541.999084 230.745743,541.996277
	C230.995728,541.995117 231.000397,542.000000 231.002533,542.002441
	C236.687012,545.144592 240.577515,550.350220 244.264587,555.257385
	C250.044632,562.950134 257.165192,569.061646 264.265472,575.276367
	C270.788971,580.986267 277.357117,586.567444 279.639221,595.876953
	C279.935547,596.989929 279.856232,597.721252 279.230072,598.703735
	C278.659058,605.365845 278.327881,611.734070 277.992432,618.557190
	C275.479706,619.124756 274.934082,617.638672 274.993774,615.497253
	C275.067963,612.836487 274.799500,610.152954 275.040039,607.512146
	C276.226257,594.490540 269.843781,585.607117 259.261841,579.385803
	C255.734497,577.312073 252.843506,574.363953 248.836243,572.871521
	C244.641144,571.309082 242.507629,572.554321 241.027649,576.652954
	C240.252792,577.008240 239.519394,577.016479 238.423401,577.072632
	C238.023270,576.612488 237.985733,576.104431 237.898529,575.218262
	C237.588684,574.433838 237.524033,573.998413 237.906998,573.295532
	C243.855896,571.520935 245.153961,567.705383 242.388611,562.730408
	C238.877884,556.414429 233.626419,551.564392 229.353973,545.908386
	C227.968140,544.073853 225.871368,544.305481 223.783630,543.400574
	C225.930359,543.062683 227.693954,542.785156 229.726639,542.255005
z"
        />
        <path
          fill="#95F8FC"
          opacity="1.000000"
          stroke="none"
          d="
M238.168243,572.683533
	C238.087219,573.453552 238.015320,573.850098 237.896118,574.543396
	C237.203018,575.317200 236.496826,575.731201 235.921844,576.282166
	C233.528381,578.576111 234.097702,583.298035 229.630508,583.913391
	C222.055527,584.956970 214.503296,587.565613 207.026886,583.124756
	C203.363266,580.948669 199.332123,579.391296 195.221222,577.312988
	C194.984253,576.614807 194.993378,576.157532 195.013367,575.358276
	C207.536407,573.705383 219.866638,571.269104 232.633728,568.952637
	C239.041962,567.473145 239.220428,567.591614 238.168243,572.683533
z"
        />
        <path
          fill="#855144"
          opacity="1.000000"
          stroke="none"
          d="
M320.732544,647.828308
	C321.278168,644.525635 323.704895,644.561462 325.420105,645.126160
	C330.609894,646.834839 334.663635,643.578735 338.942352,642.106812
	C343.050201,640.693665 346.595367,637.644897 350.689453,635.154907
	C352.818115,633.395020 354.757996,633.246338 357.109711,634.872986
	C358.712952,638.525146 356.501007,640.692749 354.526245,643.327271
	C345.783813,647.995789 337.172668,651.702820 326.940430,650.895996
	C326.024628,651.159912 325.486237,651.227356 324.530457,651.193237
	C322.473267,650.749573 320.999207,650.129150 320.732544,647.828308
z"
        />
        <path
          fill="#705250"
          opacity="1.000000"
          stroke="none"
          d="
M357.256012,636.214233
	C355.483154,634.454712 353.552094,635.190735 351.392639,634.997925
	C351.302765,631.850830 350.044220,628.429993 352.805237,625.354492
	C355.666260,625.063049 357.163666,628.667786 360.557068,627.340088
	C361.390808,627.790405 361.936554,628.356567 362.727112,629.100464
	C364.317352,633.212891 368.011200,634.259216 371.524597,636.004761
	C376.097046,637.805969 379.316986,641.089050 383.380676,643.749023
	C383.871216,644.506104 384.033264,644.998230 384.120392,645.921997
	C384.048340,646.871155 383.871948,647.382202 383.321472,648.163818
	C381.767822,649.243713 380.472534,649.210571 378.849487,648.335571
	C372.989166,641.595093 366.156525,637.398193 357.256012,636.214233
z"
        />
        <path
          fill="#E1B582"
          opacity="1.000000"
          stroke="none"
          d="
M320.476624,647.372192
	C321.230988,648.771362 322.632111,648.768799 323.719910,649.659546
	C324.347748,650.245728 324.543823,650.725891 324.411652,651.673218
	C318.762512,654.138672 315.278717,650.593628 311.411957,647.141602
	C311.182312,646.811890 310.955322,646.482971 310.835999,646.322632
	C310.510345,645.737244 310.467926,645.296387 310.820831,644.585205
	C314.424744,644.235718 316.813873,647.133911 320.476624,647.372192
z"
        />
        <path
          fill="#A5654C"
          opacity="1.000000"
          stroke="none"
          d="
M311.031738,643.978271
	C310.999573,644.740784 310.946747,645.150879 310.805298,645.861694
	C310.339722,646.113892 309.962738,646.065430 309.300385,646.002869
	C308.945190,645.570801 308.875397,645.152893 308.726929,644.426514
	C308.607666,643.881104 308.567078,643.644104 308.736938,643.114197
	C309.392853,642.856995 309.838287,642.892761 310.617920,642.925537
	C310.971771,643.156982 310.991425,643.391479 311.031738,643.978271
z"
        />
        <path
          fill="#594E52"
          opacity="1.000000"
          stroke="none"
          d="
M338.416016,607.985718
	C332.157745,603.026367 326.277466,597.995728 320.223114,593.184143
	C318.799194,592.052490 317.371765,590.970337 315.948914,589.347412
	C317.749847,586.581604 320.684052,586.613098 323.408722,586.449219
	C328.580444,586.138062 333.103027,584.645203 336.289825,580.324341
	C338.233734,577.688660 341.761078,577.390869 344.111633,574.734619
	C350.044464,571.493591 354.490509,567.060547 359.173553,562.787231
	C363.342743,558.982849 367.959991,554.953735 374.884399,556.390503
	C376.918762,558.122009 377.913147,559.966431 377.665100,562.702637
	C376.867432,564.342407 375.738647,564.966064 374.042114,565.359985
	C370.130707,565.627808 368.063080,567.807129 366.374512,570.671997
	C362.628479,577.027527 357.625702,582.571289 354.118073,589.496826
	C352.335449,591.413635 350.109406,591.438965 348.148560,592.141357
	C346.397125,592.768799 344.169922,592.705444 343.278137,595.312500
	C342.941925,596.022949 342.690948,596.393433 342.159790,596.964966
	C341.626831,597.457397 341.055695,597.511963 341.001556,597.896973
	C340.910065,598.547485 340.812958,597.775513 341.673370,598.238770
	C341.990082,598.700867 342.077606,598.914124 342.225708,599.473450
	C342.324768,600.802490 342.127716,601.716187 341.232544,602.746704
	C338.618927,603.941895 342.091522,607.496155 338.416016,607.985718
z"
        />
        <path
          fill="#182932"
          opacity="1.000000"
          stroke="none"
          d="
M341.198364,603.153442
	C340.999268,601.703186 341.030640,600.415405 341.179199,598.657654
	C341.965424,598.070862 342.722931,597.989746 343.223328,598.425232
	C351.457886,605.591125 361.349640,610.744324 368.600220,619.592407
	C368.793182,621.050293 368.430481,621.993286 367.464600,623.090576
	C366.649536,623.465759 366.130524,623.493042 365.289673,623.194092
	C364.972870,622.963623 364.979919,623.024719 365.010559,623.022095
	C364.798279,622.793152 364.561066,622.560974 364.161133,622.139648
	C363.992737,621.956238 363.989563,622.011108 364.016846,622.007935
	C363.386475,621.414612 362.812225,620.749268 362.136871,619.748291
	C361.992249,619.451660 361.752655,619.101013 361.654907,619.054932
	C360.463470,618.628906 359.408264,618.173096 358.245880,617.205322
	C358.005707,616.956116 357.997345,617.002808 358.019196,616.993530
	C357.586121,616.574463 356.993591,616.506531 356.221497,616.147461
	C356.002197,615.954895 355.998871,616.005005 356.021606,615.994324
	C355.140869,615.165649 354.295441,614.288696 353.238281,613.193542
	C353.034088,612.967651 353.001221,613.000000 353.014618,612.981323
	C351.794250,612.187012 350.403595,611.633057 349.254761,610.207031
	C349.044281,609.973816 349.010468,609.989014 349.017334,609.971741
	C348.850891,609.803040 348.677612,609.651672 348.276978,609.240540
	C348.049591,608.980835 348.027740,608.964722 348.017120,608.956299
	C347.560394,608.550598 346.976410,608.507446 346.230652,608.148010
	C346.019928,607.957336 345.998566,607.999878 346.017487,607.985535
	C345.781799,607.772827 345.544861,607.555237 345.165680,607.137451
	C345.005768,606.956482 344.990265,607.004944 345.015656,607.005127
	C344.718048,606.726013 344.434906,606.410950 344.083221,605.759827
	C343.126068,604.745178 342.277435,604.030579 341.198364,603.153442
z"
        />
        <path
          fill="#465962"
          opacity="1.000000"
          stroke="none"
          d="
M376.944702,636.388611
	C373.890381,632.416199 369.552338,629.990662 367.048065,625.382874
	C367.162231,624.876221 367.359009,624.702881 367.930847,624.407471
	C371.883423,624.628357 374.942139,625.660645 377.023529,629.035156
	C378.214172,630.965637 380.484436,632.073181 382.264587,633.554871
	C384.177490,635.146973 385.435516,636.934570 384.426636,639.899170
	C380.644226,641.439514 378.478699,639.686279 376.944702,636.388611
z"
        />
        <path
          fill="#3B2C30"
          opacity="1.000000"
          stroke="none"
          d="
M376.610901,635.992310
	C378.896881,637.662476 380.855865,639.171631 383.657898,639.925049
	C384.953369,638.959534 385.948578,638.047668 387.862305,638.673828
	C389.202637,642.797546 386.148834,643.214417 383.273071,644.167603
	C378.277069,643.152954 375.309753,639.814880 372.051575,636.343750
	C373.306885,636.037292 374.744141,636.016968 376.610901,635.992310
z"
        />
        <path
          fill="#77878D"
          opacity="1.000000"
          stroke="none"
          d="
M348.987366,610.333862
	C350.883453,609.835205 351.773438,611.277588 352.972198,612.630615
	C350.908325,613.567871 349.923401,612.123596 348.987366,610.333862
z"
        />
        <path
          fill="#77878D"
          opacity="1.000000"
          stroke="none"
          d="
M352.935059,613.306885
	C354.627441,613.056091 355.556305,614.074280 356.183929,615.651123
	C354.423340,615.961487 353.547211,614.916992 352.935059,613.306885
z"
        />
        <path
          fill="#77878D"
          opacity="1.000000"
          stroke="none"
          d="
M358.036255,617.345886
	C359.392059,616.749146 360.072174,617.909790 361.296570,618.764893
	C360.124298,619.322937 358.945251,618.941284 358.036255,617.345886
z"
        />
        <path
          fill="#77878D"
          opacity="1.000000"
          stroke="none"
          d="
M361.945251,619.826172
	C362.684479,620.068604 363.376709,620.685486 364.056519,621.653625
	C362.952637,621.850952 362.202301,621.291260 361.945251,619.826172
z"
        />
        <path
          fill="#77878D"
          opacity="1.000000"
          stroke="none"
          d="
M346.112427,608.290100
	C346.732788,607.482727 347.297607,607.838074 347.926453,608.615479
	C347.286224,609.503357 346.742279,609.081360 346.112427,608.290100
z"
        />
        <path
          fill="#77878D"
          opacity="1.000000"
          stroke="none"
          d="
M365.129517,623.235107
	C365.417023,622.945190 365.861145,622.926697 366.657166,623.450439
	C366.434753,623.830627 365.860474,623.668579 365.129517,623.235107
z"
        />
        <path
          fill="#77878D"
          opacity="1.000000"
          stroke="none"
          d="
M356.135803,616.246460
	C356.747375,615.374878 357.305450,615.802979 357.946045,616.641174
	C357.450500,616.835510 356.859924,616.686829 356.135803,616.246460
z"
        />
        <path
          fill="#77878D"
          opacity="1.000000"
          stroke="none"
          d="
M347.959015,609.302795
	C348.388947,609.109009 348.728302,609.237122 349.045929,609.659851
	C348.638916,609.844543 348.253693,609.734619 347.959015,609.302795
z"
        />
        <path
          fill="#77878D"
          opacity="1.000000"
          stroke="none"
          d="
M345.020935,607.246094
	C345.384033,607.082214 345.762329,607.207947 346.088501,607.652466
	C345.702972,607.826050 345.369507,607.680847 345.020935,607.246094
z"
        />
        <path
          fill="#77878D"
          opacity="1.000000"
          stroke="none"
          d="
M344.013245,605.804321
	C344.307465,605.750549 344.640259,606.041321 345.007050,606.668701
	C344.711304,606.719910 344.381561,606.434448 344.013245,605.804321
z"
        />
        <path
          fill="#77878D"
          opacity="1.000000"
          stroke="none"
          d="
M368.169464,624.686523
	C367.963196,625.027588 367.763672,625.020874 367.264832,625.031860
	C366.932587,624.871826 366.899689,624.694092 366.937927,624.254517
	C367.009064,623.992676 366.999695,624.000366 366.995972,624.003296
	C367.468079,623.828064 367.862732,623.939026 368.169464,624.686523
z"
        />
        <path
          fill="#77878D"
          opacity="1.000000"
          stroke="none"
          d="
M364.059082,622.219360
	C364.355072,622.098511 364.717377,622.240845 365.060425,622.701294
	C364.735931,622.840454 364.430664,622.661438 364.059082,622.219360
z"
        />
        <path
          fill="#1F3B45"
          opacity="1.000000"
          stroke="none"
          d="
M300.974915,585.401123
	C310.093964,592.001587 319.721649,598.343384 327.868378,606.501282
	C329.083618,607.718201 331.798248,608.182617 331.092987,610.789856
	C330.326569,613.622986 327.766449,614.041931 325.109711,614.877014
	C318.505493,616.105713 312.178558,617.065735 305.431641,618.043457
	C301.439545,609.650513 303.298981,600.087585 299.432983,591.742371
	C298.306152,589.309875 301.271118,587.971619 300.974915,585.401123
z"
        />
        <path
          fill="#534A50"
          opacity="1.000000"
          stroke="none"
          d="
M344.800964,574.687378
	C344.684937,575.745911 344.577820,576.759399 344.020874,577.187073
	C335.899963,583.423279 327.879181,589.953491 316.203247,589.041992
	C310.601562,585.278748 305.449249,581.494080 300.118042,577.429199
	C299.240082,576.249207 298.491821,575.397888 297.712524,574.561890
	C296.125854,572.859863 294.493744,570.856018 295.640259,568.487610
	C296.828217,566.033569 299.403107,566.368042 302.101746,566.765503
	C305.481567,567.998108 306.441315,571.218628 308.860168,572.994446
	C315.268311,577.699097 321.768646,577.212219 328.422882,573.721863
	C331.193573,572.268555 333.817566,570.384644 337.581726,570.948853
	C340.216583,572.041016 342.609741,572.805359 344.800964,574.687378
z"
        />
        <path
          fill="#4B4D54"
          opacity="1.000000"
          stroke="none"
          d="
M303.245453,567.717285
	C300.856781,568.460632 297.832886,566.705017 296.735504,569.125000
	C295.896942,570.974121 298.063019,572.904053 299.407227,574.556763
	C299.779022,575.013855 299.782349,575.770630 299.946899,576.769409
	C296.032867,575.984314 293.954651,571.737000 289.377991,571.131226
	C288.044495,569.673828 288.343536,567.158936 285.354553,567.021545
	C284.909973,564.519836 282.012848,564.676697 281.015137,562.617126
	C278.982910,558.809021 275.473022,556.943481 272.438599,554.035034
	C271.469849,551.694458 270.850464,549.640808 270.953796,547.032166
	C272.792694,542.099304 276.270050,540.958252 281.095978,541.849609
	C287.302032,548.234009 295.818542,551.905090 299.197845,560.549805
	C299.984039,562.560974 302.469147,563.282471 303.633728,565.574707
	C303.795227,566.457947 303.714813,566.956482 303.245453,567.717285
z"
        />
        <path
          fill="#6494A3"
          opacity="1.000000"
          stroke="none"
          d="
M282.997437,598.566345
	C282.539551,599.013428 282.100159,599.011841 281.331116,599.003906
	C279.296814,597.012817 280.251312,594.135132 278.485657,591.787170
	C278.075653,591.017639 277.895264,590.553101 277.674408,589.712646
	C276.391693,584.813599 274.270996,581.001709 270.719818,577.655701
	C266.524445,573.702576 263.167389,568.879272 259.254364,564.596191
	C258.222412,563.466675 257.751343,562.011902 258.587769,560.117126
	C260.959839,558.855408 263.175995,559.125122 265.723022,559.753418
	C268.049377,564.322998 272.304291,567.279236 273.358917,572.414490
	C273.784943,574.488831 276.863190,575.882996 277.877014,577.997742
	C280.908875,584.322205 283.450409,590.866577 282.997437,598.566345
z"
        />
        <path
          fill="#478D9B"
          opacity="1.000000"
          stroke="none"
          d="
M266.284973,559.697144
	C263.991760,561.087646 261.893097,561.440002 259.384125,560.118530
	C258.675415,559.940063 258.405548,559.765503 258.140076,559.171814
	C258.347504,556.866699 258.677277,554.797485 259.022949,552.332947
	C260.288055,551.508545 261.325623,552.205627 262.722900,552.766296
	C263.020844,552.958862 263.000458,552.989563 263.016418,552.981689
	C263.222565,553.223083 263.433441,553.453369 263.846741,553.812805
	C264.028412,553.960938 264.015289,553.999695 264.029419,553.982910
	C264.924652,555.010315 265.939270,555.895203 267.355652,556.819336
	C267.768005,557.480103 267.963562,557.880676 268.322906,558.575928
	C267.831848,559.064880 267.177002,559.259094 266.284973,559.697144
z"
        />
        <path
          fill="#6494A3"
          opacity="1.000000"
          stroke="none"
          d="
M283.349976,620.655945
	C281.925720,620.802246 279.739441,621.090393 280.769867,619.133606
	C282.659882,615.544434 281.945160,612.158691 281.170471,608.588623
	C280.704773,606.442322 280.986115,604.304382 282.725006,602.243713
	C284.992981,608.057373 283.932678,614.209167 283.349976,620.655945
z"
        />
        <path
          fill="#4E5963"
          opacity="1.000000"
          stroke="none"
          d="
M272.476379,554.077148
	C276.983490,555.097534 280.169159,557.039062 280.938721,561.909790
	C277.300476,560.952454 273.988220,559.017395 270.413177,556.819580
	C269.867462,556.582642 269.648529,556.486633 269.733887,556.200867
	C270.334412,555.814026 270.630585,555.616821 271.076721,555.197266
	C271.415283,554.659546 271.652222,554.386963 272.476379,554.077148
z"
        />
        <path
          fill="#246876"
          opacity="1.000000"
          stroke="none"
          d="
M269.678284,555.956055
	C270.038269,556.011230 270.053833,556.346924 270.070129,556.512756
	C269.528809,556.766235 268.971252,556.853882 267.993073,557.010498
	C265.871002,557.029663 264.531525,556.452271 264.037872,554.308228
	C265.801788,554.611023 267.560059,555.255920 269.678284,555.956055
z"
        />
        <path
          fill="#4B4D54"
          opacity="1.000000"
          stroke="none"
          d="
M284.986938,566.989258
	C284.974884,566.978699 285.080627,567.420776 284.955475,567.808472
	C284.199493,567.983398 283.203674,568.420837 283.143677,567.276062
	C283.140015,567.206299 283.992157,567.091614 284.733887,567.009888
	C285.021729,567.022034 284.998962,566.999756 284.986938,566.989258
z"
        />
        <path
          fill="#4B4D54"
          opacity="1.000000"
          stroke="none"
          d="
M285.068726,567.949280
	C285.237000,568.077148 285.322937,568.284546 285.408905,568.491882
	C285.286896,568.474243 285.164917,568.456604 284.936615,568.317505
	C284.830322,568.196106 284.986389,568.028748 285.068726,567.949280
z"
        />
        <path
          fill="#759EB0"
          opacity="1.000000"
          stroke="none"
          d="
M278.706940,591.340820
	C282.616943,592.550171 280.358093,595.750000 281.004883,598.566040
	C280.554596,598.998596 280.107727,598.999634 279.325562,598.999146
	C278.989288,598.250488 278.988403,597.503418 278.984314,596.382812
	C277.922363,594.683594 277.879883,593.222900 278.706940,591.340820
z"
        />
        <path
          fill="#623B37"
          opacity="1.000000"
          stroke="none"
          d="
M308.776489,642.645264
	C308.924164,643.042969 308.900909,643.264648 308.762970,643.802246
	C308.008606,643.916443 307.282318,643.697632 307.434113,642.856018
	C307.540955,642.263977 308.084015,642.214783 308.776489,642.645264
z"
        />
        <path
          fill="#273E4A"
          opacity="1.000000"
          stroke="none"
          d="
M285.274170,651.278687
	C287.749756,647.261597 291.736969,649.693909 295.549744,649.024048
	C293.118011,652.395264 289.133911,651.124451 285.274170,651.278687
z"
        />
        <path
          fill="#35535F"
          opacity="1.000000"
          stroke="none"
          d="
M209.290100,662.860046
	C206.727600,666.415039 203.981766,665.275024 201.094070,663.628052
	C203.385651,662.872437 205.801178,662.451538 208.596252,662.027466
	C209.172058,662.205505 209.368286,662.386780 209.290100,662.860046
z"
        />
        <path
          fill="#273E4A"
          opacity="1.000000"
          stroke="none"
          d="
M209.394043,663.116943
	C209.008560,662.964050 209.001480,662.776001 208.985107,662.306030
	C209.998459,661.684631 211.097443,660.536682 211.982559,662.582397
	C211.286697,662.935608 210.529556,663.008728 209.394043,663.116943
z"
        />
        <path
          fill="#273E4A"
          opacity="1.000000"
          stroke="none"
          d="
M224.730087,659.063293
	C224.725006,660.567810 223.987823,661.309814 222.210510,660.249390
	C222.584183,659.183167 223.447998,659.020508 224.730087,659.063293
z"
        />
        <path
          fill="#285362"
          opacity="1.000000"
          stroke="none"
          d="
M218.863983,176.869324
	C218.914032,179.185242 219.646362,181.542511 218.072754,183.668915
	C216.725723,185.367996 214.848419,185.083786 213.034225,184.957291
	C211.851578,184.874817 210.280640,184.550613 210.581467,183.131683
	C210.975601,181.272583 212.271194,182.490509 213.296539,182.855637
	C216.790451,184.099808 213.247421,179.935730 215.663544,180.071045
	C216.234436,179.806946 216.343811,179.502182 216.164032,178.887573
	C216.055420,176.905624 216.237534,175.409622 218.863983,176.869324
z"
        />
        <path
          fill="#285362"
          opacity="1.000000"
          stroke="none"
          d="
M214.082336,177.153351
	C214.258179,177.046921 214.341400,177.295517 214.424622,177.544098
	C214.279663,177.532181 214.134705,177.520264 214.082336,177.153351
z"
        />
        <path
          fill="#285362"
          opacity="1.000000"
          stroke="none"
          d="
M213.871063,177.107513
	C213.695801,176.970886 213.624512,176.733841 213.553207,176.496796
	C213.681671,176.515793 213.810120,176.534775 214.056763,176.676056
	C214.174942,176.798340 213.975006,177.007111 213.871063,177.107513
z"
        />
        <path
          fill="#5C8997"
          opacity="1.000000"
          stroke="none"
          d="
M216.027039,178.994019
	C216.459366,178.982773 216.898682,179.309845 216.195358,179.978271
	C215.959152,179.811890 215.977188,179.578094 216.027039,178.994019
z"
        />
        <path
          fill="#BAEEFA"
          opacity="1.000000"
          stroke="none"
          d="
M336.667145,291.714478
	C336.874817,298.166962 335.795166,299.302734 329.507690,299.237213
	C327.363129,300.464539 325.484039,299.770386 323.753784,299.462921
	C315.579590,298.010254 307.135620,298.368958 298.740509,295.651978
	C296.253326,295.045532 295.064056,293.657043 294.235321,291.334167
	C293.991180,290.820526 293.965851,290.653534 293.853271,290.248596
	C293.796753,288.945221 293.827484,287.879761 293.877075,286.412720
	C294.123505,285.848450 294.351044,285.685852 294.986755,285.428467
	C305.289246,287.127655 315.278259,288.190979 325.664856,289.234802
	C326.309631,289.297913 326.563110,289.331970 327.184692,289.443237
	C328.744507,289.684448 329.869965,290.111847 331.369751,290.430389
	C333.209808,291.353180 335.594177,287.699738 336.667145,291.714478
z"
        />
        <path
          fill="#8AB7C6"
          opacity="1.000000"
          stroke="none"
          d="
M288.808075,230.516510
	C289.099213,230.085007 289.323395,230.105194 289.884583,230.145157
	C292.566467,231.386383 292.164185,233.651947 292.522125,235.746292
	C293.518036,241.574234 293.000946,247.534576 294.289154,253.753510
	C294.417938,254.743912 294.449493,255.312637 294.451721,256.305939
	C294.403015,257.576538 294.317413,258.414764 293.734924,259.550171
	C293.068848,260.288971 292.561768,260.462463 291.623413,260.205872
	C290.425049,250.319839 289.583069,240.644028 288.808075,230.516510
z"
        />
        <path
          fill="#85C1D1"
          opacity="1.000000"
          stroke="none"
          d="
M291.239624,260.407898
	C291.625488,259.998077 291.983948,260.000488 292.611328,260.003082
	C293.737854,260.500427 294.118591,261.311890 294.437836,262.669373
	C295.686523,270.478455 297.593018,277.803253 295.390686,285.641785
	C294.866882,285.952759 294.672089,285.952332 294.186646,285.981506
	C292.211304,277.698273 292.363342,269.189789 291.239624,260.407898
z"
        />
        <path
          fill="#337484"
          opacity="1.000000"
          stroke="none"
          d="
M328.993408,232.607178
	C329.366730,232.741592 329.683929,232.847488 329.828430,232.931610
	C332.707214,236.758362 332.019409,241.199646 332.473877,245.400558
	C332.720093,247.676407 332.124298,250.022095 333.149719,252.683777
	C333.547760,263.296234 334.147827,273.448975 335.399689,283.562561
	C335.702972,286.012726 335.689117,288.545502 333.381531,290.782440
	C332.160828,291.429199 331.250000,291.098175 330.878845,290.455902
	C329.042023,287.277283 325.948303,285.015045 324.611053,281.214844
	C327.609406,279.459961 330.646484,278.185852 329.497559,273.599243
	C328.236572,262.890015 327.026672,252.580200 327.475861,241.748795
	C327.556610,239.158478 326.220276,237.271881 326.255890,234.717270
	C326.477783,233.805298 326.760193,233.360779 327.493652,232.780197
	C328.095337,232.539658 328.357727,232.506210 328.993408,232.607178
z"
        />
        <path
          fill="#246876"
          opacity="1.000000"
          stroke="none"
          d="
M293.253601,255.268036
	C292.045868,247.030075 291.229645,238.978027 290.317505,230.545471
	C296.494141,230.102478 302.766693,230.040009 309.500183,230.051041
	C310.356567,230.513275 310.516602,230.981094 310.264557,231.873871
	C306.518738,233.395462 302.877380,231.940552 299.290192,232.480667
	C297.782990,232.707611 295.845184,231.689774 294.979980,234.266266
	C296.094940,239.868500 295.844788,245.215454 296.423798,250.488770
	C296.650421,252.552505 296.656464,254.976349 293.253601,255.268036
z"
        />
        <path
          fill="#95D1E2"
          opacity="1.000000"
          stroke="none"
          d="
M332.245483,253.283264
	C331.225708,246.682312 330.616150,240.272995 329.989746,233.439713
	C330.558380,233.280441 331.143860,233.545166 332.039368,233.897018
	C332.602325,236.074829 332.855225,238.165512 333.233704,240.647919
	C333.792572,244.807343 336.876373,248.211090 334.636963,252.674545
	C333.851654,253.401291 333.281189,253.546341 332.245483,253.283264
z"
        />
        <path
          fill="#478D9B"
          opacity="1.000000"
          stroke="none"
          d="
M310.138794,232.014374
	C309.887726,231.265427 309.899628,230.939026 309.945862,230.368927
	C314.527802,231.165955 319.192749,230.688828 323.802704,231.041916
	C325.552460,231.175934 327.483429,230.553009 329.181946,232.377777
	C329.208557,232.803421 329.050354,232.865250 328.639343,232.958511
	C322.330566,234.583939 316.349426,233.745331 310.138794,232.014374
z"
        />
        <path
          fill="#285362"
          opacity="1.000000"
          stroke="none"
          d="
M323.186646,190.928802
	C323.292694,191.524384 323.364716,191.958328 323.586731,192.057953
	C324.696564,192.555939 326.824005,191.117081 326.770416,193.699921
	C326.762329,194.088837 325.683716,194.883408 325.446686,194.769501
	C324.020966,194.084457 321.354858,194.907425 321.571686,192.117889
	C321.673676,190.805878 319.701477,188.539581 322.632294,188.299377
	C324.047791,188.183365 323.398193,189.713852 323.186646,190.928802
z"
        />
        <path
          fill="#33616C"
          opacity="1.000000"
          stroke="none"
          d="
M334.110779,182.823151
	C336.077423,184.713989 336.915588,186.566101 333.447632,188.014709
	C333.003540,186.311447 328.671478,183.963486 334.110779,182.823151
z"
        />
        <path
          fill="#285362"
          opacity="1.000000"
          stroke="none"
          d="
M328.074310,191.906921
	C327.365448,190.533188 326.770142,189.431641 328.650879,189.120834
	C329.875000,188.918533 330.913971,189.311493 331.563843,190.390045
	C332.263184,191.550705 331.900482,192.667633 330.905212,193.373383
	C329.556000,194.330124 328.911865,193.128647 328.074310,191.906921
z"
        />
        <path
          fill="#95F8FC"
          opacity="1.000000"
          stroke="none"
          d="
M343.100952,233.110077
	C345.861298,231.979660 348.646332,229.399246 350.575714,233.807098
	C350.636322,233.945526 350.866852,234.156281 350.919800,234.130585
	C357.050323,231.157028 357.583649,237.468109 360.374268,239.922577
	C361.726379,241.111816 363.267517,240.126144 364.846741,240.950714
	C365.414062,246.119659 363.066895,250.617004 361.853455,255.707520
	C359.847748,257.342743 358.397797,256.332397 357.299225,255.074341
	C353.971893,251.264145 348.796570,251.622284 344.787201,249.361694
	C343.825165,248.819290 342.537994,249.803162 341.061951,249.348572
	C339.598145,244.334915 341.037872,240.839340 345.096069,238.713852
	C344.456512,237.595657 343.005280,237.031647 342.828369,235.219666
	C342.804230,234.364731 342.863342,233.838745 343.100952,233.110077
z"
        />
        <path
          fill="#193A47"
          opacity="1.000000"
          stroke="none"
          d="
M333.573975,253.377457
	C333.627655,249.268524 333.462341,245.568665 333.328125,241.454224
	C334.434326,241.327103 335.000305,242.008530 335.019531,243.139404
	C335.074036,246.346695 336.878723,245.990906 339.331848,244.932373
	C340.065125,247.588608 340.390442,250.380585 340.482910,253.600296
	C340.094421,256.699432 341.225342,259.082184 342.081116,261.900574
	C342.302399,266.312103 343.565002,270.097961 344.996094,274.201416
	C346.294891,278.147858 348.816071,281.033508 350.571625,284.736877
	C350.694458,285.580414 350.606262,286.053497 350.152954,286.760681
	C345.850403,286.599701 346.046265,281.824615 343.000549,280.353882
	C339.560059,278.692505 340.439667,274.590576 338.795776,271.736542
	C335.687897,276.716248 339.380951,281.835175 337.069580,286.796906
	C335.826630,286.926422 335.250885,286.344818 335.187286,285.439758
	C334.446167,274.896393 332.847565,264.401917 333.573975,253.377457
z"
        />
        <path
          fill="#A8FBFB"
          opacity="1.000000"
          stroke="none"
          d="
M339.876282,255.180542
	C338.940704,251.896759 339.306335,248.820572 339.711182,245.272644
	C340.569641,241.682007 341.433960,238.564194 342.521698,235.168549
	C343.246948,235.321014 343.782440,235.718216 344.244171,236.187897
	C345.557648,237.523987 349.041595,236.616684 348.607422,239.350311
	C348.153320,242.209457 345.183594,240.047623 343.736359,240.769363
	C340.788086,242.239685 342.141907,245.768127 341.042969,248.609039
	C342.333832,250.406372 342.645294,252.021576 341.903809,254.213104
	C341.359161,255.100662 340.875763,255.356171 339.876282,255.180542
z"
        />
        <path
          fill="#543937"
          opacity="1.000000"
          stroke="none"
          d="
M354.326263,197.478943
	C353.585327,182.595474 352.981689,168.156540 352.415466,153.300232
	C352.455719,145.282928 352.713745,137.681351 352.455688,129.656128
	C356.972961,127.603714 357.394318,130.804810 357.186127,133.516876
	C356.817719,138.316254 357.732666,142.927170 358.424072,148.053970
	C359.548431,157.082413 358.851990,165.689972 358.953552,174.280807
	C359.053253,182.715073 359.100311,191.153137 358.805786,200.039246
	C355.053528,203.234253 354.527985,200.955475 354.326263,197.478943
z"
        />
        <path
          fill="#6D3E2F"
          opacity="1.000000"
          stroke="none"
          d="
M353.527527,110.269341
	C357.303375,101.644051 364.809967,99.628387 373.139740,99.647209
	C390.555176,99.686577 407.970398,99.946999 425.385101,100.164085
	C431.020294,100.234337 436.653595,100.452728 442.691711,100.722183
	C443.063354,103.161972 441.322144,103.325294 439.669617,103.450745
	C437.858276,103.588234 436.034637,103.492767 433.752289,103.496536
	C430.848907,103.483086 428.409607,103.476021 425.516174,103.491180
	C422.345673,103.556450 419.626312,103.368591 416.465027,103.694855
	C413.867371,104.046494 411.712555,103.949303 409.092529,103.833527
	C398.964355,103.097900 389.288330,103.664513 379.173950,103.491272
	C376.177155,103.530403 373.625366,103.436760 370.684448,103.594795
	C365.715454,103.482483 361.667145,104.495445 358.862305,109.032410
	C358.065613,109.831413 357.431091,110.223694 356.377930,110.648560
	C355.256622,110.941689 354.518860,110.868439 353.527527,110.269341
z"
        />
        <path
          fill="#543937"
          opacity="1.000000"
          stroke="none"
          d="
M415.947968,102.479523
	C418.709961,102.026154 421.424774,102.016281 424.592682,102.012146
	C427.431549,103.038712 429.920990,102.308151 432.663239,102.235909
	C436.138763,101.723763 439.382233,102.617996 442.797729,101.141220
	C444.772247,100.817032 446.448853,100.793053 448.538757,100.859726
	C451.423340,102.980988 454.455627,101.574600 457.495941,102.424820
	C462.261139,103.890869 466.871368,104.524742 471.145691,106.323448
	C473.220459,107.196518 475.817902,107.650803 475.086029,111.226761
	C473.264862,112.713577 471.486084,112.413116 469.301514,112.251480
	C455.183716,105.849510 440.626740,106.191460 426.056732,106.426506
	C422.351227,106.486282 418.338776,106.817253 415.947968,102.479523
z"
        />
        <path
          fill="#705250"
          opacity="1.000000"
          stroke="none"
          d="
M354.114807,197.487183
	C355.480194,198.145187 354.425903,201.078094 357.617554,200.997406
	C361.732544,202.246948 361.267548,205.277649 360.947327,208.610138
	C359.154022,210.998184 357.289368,212.803314 354.525482,213.861237
	C354.148010,208.527786 354.094360,203.233871 354.114807,197.487183
z"
        />
        <path
          fill="#6D3E2F"
          opacity="1.000000"
          stroke="none"
          d="
M457.413391,103.300369
	C454.253632,103.273727 451.419464,104.008827 449.040405,101.300446
	C458.598663,101.095551 468.245209,101.240723 478.341278,101.447113
	C478.849548,101.859146 478.908295,102.209969 478.960083,102.829826
	C477.262329,103.083824 475.571594,103.068787 473.457092,103.064728
	C470.689636,105.215622 467.935516,105.150978 465.142609,104.400551
	C462.731110,103.752617 460.199738,104.390266 457.413391,103.300369
z"
        />
        <path
          fill="#6D3E2F"
          opacity="1.000000"
          stroke="none"
          d="
M353.338715,128.630432
	C352.964783,136.716522 352.886597,144.443695 352.630676,152.526855
	C352.355682,139.327515 349.746826,125.785309 352.490692,111.884216
	C353.000153,111.723671 353.259125,111.895348 353.774689,112.455933
	C355.263306,115.833084 354.927887,118.907387 354.533600,122.446777
	C354.724243,124.778435 354.892609,126.644623 353.338715,128.630432
z"
        />
        <path
          fill="#5D3732"
          opacity="1.000000"
          stroke="none"
          d="
M349.753723,168.576660
	C346.944244,167.497574 347.908752,165.385864 349.022278,163.037674
	C349.480377,164.578903 349.642700,166.381897 349.753723,168.576660
z"
        />
        <path
          fill="#543937"
          opacity="1.000000"
          stroke="none"
          d="
M353.420258,113.260544
	C353.022156,112.781708 352.976257,112.533951 352.835724,111.919098
	C352.818420,111.200783 352.895721,110.849571 353.106445,110.261696
	C353.925659,110.009842 354.611450,109.994644 355.901367,110.308449
	C356.922363,112.860374 356.070312,113.868240 353.420258,113.260544
z"
        />
        <path
          fill="#243942"
          opacity="1.000000"
          stroke="none"
          d="
M366.306580,205.650848
	C365.860535,206.021011 365.691528,206.040817 365.267639,206.047668
	C362.255463,205.780792 362.220306,203.546463 362.006592,201.643997
	C361.542358,197.511322 361.714142,193.376541 361.943207,189.212830
	C362.473938,179.565414 361.039612,169.967010 360.676025,160.334946
	C360.494293,155.520538 359.979950,150.737366 360.525391,145.482483
	C360.536041,144.752609 360.539642,144.462082 360.593811,143.741577
	C361.090179,141.980026 361.645233,140.673157 361.589355,138.804047
	C361.673920,137.560745 362.080444,136.601135 361.663177,135.930374
	C355.920013,126.698296 362.606079,120.009422 367.126648,113.717682
	C371.367065,107.815903 378.510773,108.651451 385.053101,110.401535
	C389.468903,111.582756 394.022003,111.670738 398.383575,110.720055
	C405.329559,109.206055 412.059326,109.226372 419.034454,110.724304
	C424.868591,111.977226 430.950623,111.086906 436.922058,111.174263
	C442.044128,111.249184 447.268616,110.609650 451.591095,114.455780
	C452.560822,115.318596 454.130829,115.097740 455.438904,115.297394
	C458.562958,115.774231 461.681732,116.260262 464.810242,118.140427
	C467.415710,121.653961 466.546417,125.808403 468.133362,129.643036
	C466.181305,132.158051 463.037933,129.880020 461.049103,132.371689
	C459.870026,132.735947 458.825928,132.766159 457.391327,132.755615
	C454.257233,132.260696 451.496246,132.575470 448.378601,132.620911
	C432.675812,132.690140 417.337860,132.666779 401.540771,132.715759
	C395.843781,134.243546 390.489868,133.133743 385.204834,133.510605
	C379.660614,133.905960 374.439850,134.989166 370.320801,139.306992
	C367.863342,141.883041 366.592529,144.776993 366.521729,148.211929
	C366.195953,164.017288 367.865021,179.773453 367.739929,195.577850
	C367.713776,198.882538 368.459503,202.233170 366.306580,205.650848
z"
        />
        <path
          fill="#6CDAE1"
          opacity="1.000000"
          stroke="none"
          d="
M366.336609,206.000580
	C367.411469,199.054932 366.284363,192.077194 366.190491,185.122696
	C366.035614,173.652008 365.442230,162.187866 365.212830,150.717377
	C364.997894,139.968475 370.178833,134.104172 380.911041,132.610199
	C387.374847,131.710434 393.856354,132.193848 400.707458,132.610596
	C401.046997,133.298782 401.012329,133.809479 400.983704,134.704407
	C395.839966,135.138336 390.686584,135.082733 385.541138,135.258850
	C373.648346,135.665909 367.863922,141.618057 368.179077,153.345734
	C368.563019,167.633316 369.167938,181.914993 369.803223,196.595428
	C370.389832,197.388168 370.755371,197.858643 371.152954,198.835739
	C371.499908,200.518402 371.286835,201.662750 370.221680,203.040649
	C368.871796,204.282471 367.757721,205.141220 366.336609,206.000580
z"
        />
        <path
          fill="#33616C"
          opacity="1.000000"
          stroke="none"
          d="
M448.450562,132.823395
	C450.735596,131.380142 453.558319,131.960754 456.683868,132.494019
	C454.296326,132.787674 451.591827,132.860474 448.450562,132.823395
z"
        />
        <path
          fill="#313D44"
          opacity="1.000000"
          stroke="none"
          d="
M468.360443,129.896027
	C463.853821,127.617485 465.193665,123.527168 464.995514,119.458168
	C463.560852,117.454895 463.287537,115.680351 464.267822,113.312492
	C465.463715,111.944168 466.632690,111.618256 468.404663,111.824272
	C474.105957,112.813400 474.935913,114.072533 474.221100,120.654556
	C473.871033,124.969460 471.345673,127.405724 468.360443,129.896027
z"
        />
        <path
          fill="#574649"
          opacity="1.000000"
          stroke="none"
          d="
M474.498108,121.082901
	C472.645264,119.840538 473.062561,118.107300 473.009949,116.518295
	C472.939545,114.393616 472.036499,113.118202 469.415405,112.990273
	C468.664642,112.593178 468.523132,112.143768 468.808136,111.315063
	C470.731232,110.998497 472.445587,110.992691 474.588440,110.981018
	C475.705414,110.414963 476.474060,110.259705 477.670868,110.743095
	C479.763336,111.061104 480.823120,112.170677 481.902344,113.786407
	C481.838684,118.648453 479.416351,120.945068 474.498108,121.082901
z"
        />
        <path
          fill="#693E39"
          opacity="1.000000"
          stroke="none"
          d="
M478.005341,110.604904
	C477.266724,110.973404 476.516327,110.978699 475.391479,110.979568
	C471.553009,106.306694 465.785431,106.982788 461.141144,105.059494
	C459.889282,104.541077 458.120880,105.460556 457.104248,103.396599
	C462.031525,103.031723 467.062134,103.030136 472.563049,103.052124
	C472.923523,106.684860 478.167145,106.588432 478.005341,110.604904
z"
        />
        <path
          fill="#1F3B45"
          opacity="1.000000"
          stroke="none"
          d="
M370.350037,199.229156
	C369.976440,198.546524 369.935913,198.102951 369.912292,197.325516
	C371.957367,190.835190 371.264740,184.447617 370.569244,178.313812
	C369.401581,168.015625 370.099701,157.752869 369.955353,147.480530
	C369.906860,144.032455 377.279572,138.502502 380.775024,137.796585
	C392.280121,135.473129 403.707672,138.269745 415.165771,137.867996
	C418.485291,137.751602 421.813049,137.870850 425.610596,137.903900
	C425.761597,141.145004 421.627228,146.313446 418.320099,146.737152
	C415.077515,147.152588 412.909088,148.851334 410.662781,150.888199
	C407.720734,153.555954 404.701263,156.172073 401.039948,157.881516
	C399.666534,158.522736 398.311005,159.360779 397.647308,160.709274
	C394.584930,166.931183 388.528259,171.084091 386.420013,178.156570
	C385.427948,181.484726 381.383850,185.017334 378.460449,185.693436
	C373.766693,186.778961 374.609650,189.544037 374.084961,192.669556
	C373.874603,194.847305 374.366547,196.744156 373.249329,198.804474
	C372.288177,199.616318 371.518402,199.748886 370.350037,199.229156
z"
        />
        <path
          fill="#28424F"
          opacity="1.000000"
          stroke="none"
          d="
M374.424866,193.028778
	C370.880463,190.380035 374.509460,186.673767 372.281952,183.534348
	C375.746002,185.442810 377.264313,185.145706 380.523193,182.160583
	C385.692535,177.425491 387.753662,171.053452 391.817047,165.876404
	C396.119080,160.395325 401.025055,156.299622 406.398010,152.328461
	C408.256927,150.954559 409.932800,149.332092 411.683746,147.813309
	C412.560852,147.052490 413.624237,145.461288 414.253479,145.621323
	C420.614716,147.239212 421.747192,140.440216 425.989868,138.271118
	C427.822693,137.930740 429.561249,137.935745 431.746521,137.969757
	C431.758636,140.897202 431.061493,143.616730 427.587769,144.266876
	C421.759216,145.357742 417.734253,149.607513 413.622192,153.138962
	C403.282959,162.018280 394.740784,172.491714 388.385376,184.712723
	C386.761322,187.835709 383.868713,190.338959 381.385345,192.962662
	C379.342316,195.121140 377.011505,193.130127 374.424866,193.028778
z"
        />
        <path
          fill="#246876"
          opacity="1.000000"
          stroke="none"
          d="
M369.934570,199.427063
	C370.761993,199.014450 371.506989,199.038818 372.624634,199.082245
	C373.123352,200.030609 373.531128,200.727356 374.837219,201.019669
	C373.967926,202.420380 372.283905,202.794510 370.355103,203.415436
	C369.941284,202.237137 369.896729,201.050583 369.934570,199.427063
z"
        />
        <path
          fill="#1F3B45"
          opacity="1.000000"
          stroke="none"
          d="
M435.977539,138.195312
	C436.126587,138.161743 436.470825,138.347839 436.996765,138.769775
	C436.843262,138.808746 436.507996,138.611847 435.977539,138.195312
z"
        />
        <path
          fill="#67BFC6"
          opacity="1.000000"
          stroke="none"
          d="
M392.382690,208.992676
	C393.367310,206.390594 396.167511,205.739609 398.664398,204.089539
	C401.757019,202.990082 404.207672,200.887863 407.653442,201.677841
	C402.533356,203.612427 397.844574,206.653030 392.382690,208.992676
z"
        />
        <path
          fill="#594E52"
          opacity="1.000000"
          stroke="none"
          d="
M562.489807,96.037369
	C567.269043,97.879440 570.254211,93.277061 574.654907,93.139046
	C575.685852,92.493370 576.448425,92.247581 577.682983,92.533478
	C579.080933,93.959892 578.751221,95.233032 577.877869,96.825851
	C569.048279,100.500961 562.900085,106.891273 556.815796,113.939941
	C554.465576,114.499100 553.961304,116.461021 552.783691,117.717766
	C550.795654,119.839310 548.976685,122.214645 545.246338,121.960709
	C544.410583,121.674057 544.006592,121.384468 543.479919,120.680969
	C542.564331,117.031662 544.546326,114.745674 546.443359,112.511375
	C549.079773,109.406296 552.130249,106.671989 554.871338,103.183121
	C555.268311,102.443863 555.454285,102.003937 555.836914,101.277481
	C556.139160,100.745705 556.281982,100.535828 556.681763,100.309341
	C558.594299,100.673187 559.945312,100.405083 560.164551,98.069717
	C560.737854,97.215378 561.476013,96.648750 562.489807,96.037369
z"
        />
        <path
          fill="#855144"
          opacity="1.000000"
          stroke="none"
          d="
M555.847046,102.821716
	C554.915344,107.278877 551.083862,109.380806 548.412231,112.326683
	C546.482422,114.454521 545.062317,116.658493 544.015564,119.606964
	C543.512756,121.872269 542.945129,123.734970 541.451294,125.595093
	C535.534424,134.246613 531.698608,143.469513 528.541992,153.008514
	C526.733704,158.473007 524.782349,159.479034 519.266235,157.334641
	C513.119263,155.075592 507.275909,157.734100 501.371887,157.373688
	C500.745728,157.335464 500.113373,157.398209 499.218323,157.167496
	C504.359833,153.929565 510.284546,154.808838 515.938477,155.218826
	C521.836670,155.646545 524.596069,153.869156 526.514282,147.978897
	C532.162231,130.636429 540.521484,114.719505 554.620239,102.082565
	C555.302979,102.018120 555.554077,102.240677 555.847046,102.821716
z"
        />
        <path
          fill="#855144"
          opacity="1.000000"
          stroke="none"
          d="
M496.630493,157.056198
	C492.498688,160.907974 486.804688,162.402237 481.264282,164.925323
	C484.741608,159.818878 490.665588,158.747742 496.630493,157.056198
z"
        />
        <path
          fill="#855144"
          opacity="1.000000"
          stroke="none"
          d="
M480.598999,164.847412
	C478.611420,167.750763 476.438965,170.817551 472.225281,171.890701
	C472.500397,168.605713 475.611176,167.611603 477.858734,165.369080
	C478.752533,164.978928 479.491333,164.884308 480.598999,164.847412
z"
        />
        <path
          fill="#784534"
          opacity="1.000000"
          stroke="none"
          d="
M559.722290,97.966751
	C561.065002,98.853577 561.224304,100.009125 560.126038,101.111214
	C559.076233,102.164719 558.122437,101.605392 557.062805,100.489647
	C557.723511,99.544533 558.584167,98.848007 559.722290,97.966751
z"
        />
        <path
          fill="#784534"
          opacity="1.000000"
          stroke="none"
          d="
M556.041992,102.696747
	C555.746033,102.818367 555.473389,102.602768 555.061646,102.164253
	C555.147888,101.692917 555.373230,101.444496 555.835449,101.101669
	C556.070007,101.458023 556.067749,101.908783 556.041992,102.696747
z"
        />
        <path
          fill="#855144"
          opacity="1.000000"
          stroke="none"
          d="
M469.722534,173.131104
	C470.052246,175.100784 469.514557,176.769684 466.685944,177.025391
	C466.854523,175.380173 468.241058,174.400467 469.722534,173.131104
z"
        />
        <path
          fill="#855144"
          opacity="1.000000"
          stroke="none"
          d="
M471.670807,171.849945
	C471.519928,172.240768 471.055908,172.583771 470.293915,172.967163
	C470.449829,172.605743 470.903748,172.203918 471.670807,171.849945
z"
        />
        <path
          fill="#5D696E"
          opacity="1.000000"
          stroke="none"
          d="
M466.337585,169.972076
	C466.312561,169.754730 466.567780,169.491074 467.099945,169.162292
	C467.123871,169.373352 466.870850,169.649551 466.337585,169.972076
z"
        />
        <path
          fill="#4E5963"
          opacity="1.000000"
          stroke="none"
          d="
M492.993408,216.991669
	C497.624268,218.071411 498.800995,214.596436 499.595062,211.457108
	C500.876831,206.389771 501.673798,201.241150 508.835693,201.276154
	C511.637573,199.093262 513.438110,200.207581 514.337769,202.819443
	C515.370544,205.817871 517.083618,208.430344 518.531311,211.582047
	C518.327454,214.451614 515.681274,213.595093 514.356506,215.084076
	C513.921204,215.879303 513.817871,216.443237 514.035156,217.343689
	C514.367249,218.487396 514.414978,219.361420 514.570129,220.552872
	C514.653870,222.041901 514.548706,223.554138 516.334351,223.097382
	C519.020752,222.410202 522.049255,222.960709 524.745117,220.835388
	C528.770081,220.505905 532.429749,220.211578 536.443909,221.153473
	C539.008484,224.578964 536.914795,225.810379 534.279602,226.582367
	C529.553650,227.966812 524.755310,229.055222 519.918396,230.014496
	C516.923767,230.608444 513.668762,230.859238 511.103455,233.743225
	C504.289948,235.109665 497.793152,234.179642 490.915985,234.513031
	C489.185120,234.548187 487.878723,234.612671 486.448303,235.675201
	C484.742432,236.544525 483.127411,236.361557 481.414368,237.211090
	C477.358887,238.866013 475.660431,242.133453 475.853027,245.627884
	C476.250305,252.835220 474.613739,260.247192 477.980042,267.247131
	C479.030273,269.431000 479.051575,272.152344 476.591522,274.358887
	C472.180298,272.722717 468.954163,269.654663 465.258209,267.306793
	C462.423065,265.505768 464.362366,261.554718 462.429962,258.706055
	C462.119049,257.869690 462.048920,257.370453 462.096252,256.493530
	C462.986877,254.353775 463.987549,252.725784 462.499512,250.640717
	C461.397705,249.096786 461.718689,247.126770 462.312805,245.357147
	C463.230957,242.622574 462.939941,239.512543 461.237823,237.789841
	C457.556396,234.063858 457.789459,228.574173 454.590363,224.453552
	C457.187042,220.544800 461.054993,220.641998 464.780090,220.202454
	C467.824188,219.843277 470.980408,221.320557 474.285034,219.416061
	C477.305145,218.571136 479.954010,219.076950 482.522736,219.589752
	C486.180115,220.319870 489.418579,219.720840 492.690552,217.257324
	C492.986420,216.989258 492.997375,216.997482 492.993408,216.991669
z"
        />
        <path
          fill="#4B4D54"
          opacity="1.000000"
          stroke="none"
          d="
M544.749084,121.256851
	C548.937744,121.065369 550.588501,117.927124 552.588196,115.431175
	C553.384583,114.437103 553.571594,112.956146 555.638062,113.025276
	C558.059875,115.038612 557.284180,117.314995 556.407654,119.462204
	C555.277161,122.231743 554.790283,125.207077 553.666565,127.942879
	C552.115967,131.717834 553.419739,133.431763 557.423218,134.148819
	C556.734131,140.845261 556.321106,147.251205 556.833252,153.696671
	C557.002319,155.823746 556.714966,158.092239 559.170959,159.322632
	C561.106445,160.292267 562.407166,161.806396 561.819702,164.139938
	C561.169067,166.724197 559.128113,167.832642 556.721924,168.336639
	C555.249023,168.645157 553.730591,168.422302 551.827271,168.491058
	C544.862915,169.889404 539.354065,166.934662 533.393921,164.136871
	C531.928894,164.022644 530.852356,164.012115 529.372070,163.996140
	C528.128357,163.332260 528.044495,162.222015 527.223938,161.210144
	C527.012024,160.551804 527.011780,160.108780 527.008179,159.333481
	C530.164795,152.395737 535.187561,146.612106 535.989685,138.582108
	C537.929932,135.651657 539.564087,132.949432 540.853088,129.664230
	C541.122925,129.161819 541.247925,128.970261 541.598022,128.521027
	C543.140259,127.518456 543.599426,126.338799 543.535400,124.540123
	C543.578003,123.198273 543.842590,122.287933 544.749084,121.256851
z"
        />
        <path
          fill="#4E5963"
          opacity="1.000000"
          stroke="none"
          d="
M506.254333,196.666931
	C500.012756,199.425247 499.667297,199.328659 496.921814,192.796967
	C496.242004,191.179657 495.146698,191.098755 493.798462,191.005249
	C489.187866,190.685501 484.580078,190.325027 479.969971,189.997604
	C474.589050,189.615433 473.817596,186.845840 475.369598,181.968262
	C477.551300,175.111801 483.807190,173.109375 488.255035,169.044510
	C492.405579,165.251373 498.177856,167.453766 503.167877,166.627380
	C505.281433,166.277328 505.517242,169.608780 504.826172,171.006866
	C503.224060,174.248138 503.964386,177.006699 504.784637,180.248337
	C505.524445,183.171951 504.922028,186.504608 504.544495,189.612274
	C504.355408,191.168579 503.896545,192.522095 505.691589,193.809937
	C506.642944,194.672821 506.768616,195.460220 506.254333,196.666931
z"
        />
        <path
          fill="#5D696E"
          opacity="1.000000"
          stroke="none"
          d="
M506.158234,197.287781
	C506.002258,196.251205 506.002808,195.499207 506.004822,194.371078
	C508.132477,189.790314 506.681671,185.309555 506.917572,180.967804
	C507.071899,178.127762 507.451294,175.420807 508.625275,172.865051
	C509.372284,171.238800 510.149841,169.356781 512.825562,170.865845
	C514.852783,173.762985 511.611694,175.196808 511.560028,177.440079
	C511.438538,182.719757 511.263580,188.010849 511.635925,193.268692
	C511.875732,196.655457 515.534119,199.163101 520.129944,199.451035
	C523.913940,199.688110 527.716797,199.525116 531.919495,199.480301
	C533.145813,199.294983 533.965820,199.306107 535.195557,199.487732
	C535.877563,199.590622 536.151062,199.631332 536.826538,199.778214
	C537.477173,200.006271 537.725952,200.128159 538.299683,200.508423
	C540.136780,202.561554 540.570129,204.637695 540.142273,207.310883
	C539.564636,209.248947 538.619385,210.546570 537.507812,212.198395
	C537.004395,212.641251 536.778198,212.789841 536.175903,213.093475
	C534.749084,213.566452 533.680420,213.669571 532.190430,213.521530
	C529.150818,213.917770 526.491211,213.695450 523.445740,213.771469
	C522.248230,213.728165 521.469604,213.904572 520.280151,214.015594
	C519.334473,213.924164 518.838806,213.723862 518.176270,213.065918
	C515.615112,209.920578 513.079651,207.229172 512.936218,203.245209
	C512.862610,201.203110 511.373169,201.015610 509.400452,200.991821
	C508.995270,199.145248 507.060059,198.826920 506.158234,197.287781
z"
        />
        <path
          fill="#625457"
          opacity="1.000000"
          stroke="none"
          d="
M539.350220,208.170609
	C539.102539,205.865265 538.922607,203.784088 538.096436,201.400635
	C539.460388,199.200699 541.528259,199.291260 543.492798,199.189362
	C546.140076,199.052063 548.794556,199.185562 551.444153,199.099289
	C557.195190,198.912033 557.897522,198.193481 558.101135,192.326828
	C558.198914,189.512146 558.050232,186.693329 558.284058,183.499023
	C558.613281,181.394760 558.226562,179.812714 557.101440,178.068665
	C554.158203,174.813965 550.356384,175.896927 546.687744,175.086273
	C545.259644,174.591492 544.445251,173.608688 542.855652,173.507904
	C541.491028,173.519897 540.541687,173.299118 539.342102,172.613159
	C538.917480,172.162216 538.797241,171.938828 538.830200,171.367096
	C538.180603,167.849533 534.492554,167.346695 533.070251,164.361694
	C534.654419,162.276703 536.560852,162.934525 537.919128,164.076309
	C541.556885,167.134277 545.758545,167.228653 550.548889,167.007111
	C564.217529,172.051727 568.023132,179.461273 564.842834,194.130783
	C564.107422,197.523026 563.270691,200.908188 560.944397,204.130585
	C560.195435,204.756241 559.707336,205.014587 558.782776,205.285065
	C555.735962,205.600647 553.298828,206.777893 550.284912,207.192596
	C546.492798,207.566147 543.141785,208.379501 539.350220,208.170609
z"
        />
        <path
          fill="#83AEBF"
          opacity="1.000000"
          stroke="none"
          d="
M532.916382,200.482452
	C528.409790,200.934464 523.927002,201.212921 519.467529,201.000748
	C512.761780,200.681671 510.274414,197.977997 510.114899,191.384384
	C510.034760,188.070435 510.205414,184.741470 509.953003,181.442993
	C509.673035,177.784653 510.495209,174.596863 512.983887,171.486847
	C515.980835,170.045685 519.042236,169.963593 522.552429,169.970322
	C526.362976,168.629211 529.756226,167.722961 533.618958,169.848999
	C534.426758,170.417007 534.870667,170.662872 535.549561,171.004974
	C537.005493,171.922943 537.310425,173.106339 537.013794,174.833801
	C536.275085,176.524216 535.156799,176.550323 534.036987,176.069778
	C532.196228,175.279861 530.566284,175.724579 528.621704,176.754822
	C525.489563,176.908966 522.757202,176.167526 519.881531,176.907471
	C513.744812,178.486542 513.450134,178.538895 513.545410,184.854324
	C513.592224,187.959152 513.759155,191.151047 516.254639,193.945847
	C519.770935,197.532364 523.951843,197.721008 528.582825,197.745972
	C530.355896,198.462738 532.047729,198.582642 532.916382,200.482452
z"
        />
        <path
          fill="#625457"
          opacity="1.000000"
          stroke="none"
          d="
M537.187500,137.726349
	C537.216675,146.446457 532.959961,152.898468 527.352295,158.952209
	C523.308960,158.157135 520.350098,161.082901 516.399963,160.998871
	C516.909119,159.855606 517.820496,158.709366 518.892578,157.291321
	C524.811768,158.426788 525.225769,157.702560 527.138672,151.859711
	C530.247070,142.365509 534.012390,133.082993 540.919678,125.167328
	C542.495667,126.162109 542.913940,127.193253 542.228149,128.731995
	C542.002441,129.001572 542.000000,129.000000 542.001221,129.000916
	C539.694153,131.398178 537.838806,134.043579 537.187500,137.726349
z"
        />
        <path
          fill="#5D696E"
          opacity="1.000000"
          stroke="none"
          d="
M533.920532,169.795349
	C530.620667,170.132736 527.258545,170.094330 523.440674,169.991486
	C526.727234,166.293610 530.189636,166.131989 533.920532,169.795349
z"
        />
        <path
          fill="#5D696E"
          opacity="1.000000"
          stroke="none"
          d="
M535.884888,176.296326
	C535.925598,174.598999 535.903137,173.250351 535.832642,171.501465
	C536.585266,171.071487 537.386047,171.041733 538.585083,171.015366
	C538.983337,171.018738 538.988892,171.494156 538.989441,171.731873
	C539.296692,173.125580 539.409912,174.280960 538.830750,175.759583
	C538.405396,176.478363 538.066711,176.808899 537.329834,177.195114
	C536.501648,177.267746 536.105896,177.054062 535.884888,176.296326
z"
        />
        <path
          fill="#625457"
          opacity="1.000000"
          stroke="none"
          d="
M527.017700,161.340942
	C528.399719,161.138474 528.976929,161.969910 528.968140,163.644806
	C527.583557,163.844635 527.018250,163.006607 527.017700,161.340942
z"
        />
        <path
          fill="#485E6A"
          opacity="1.000000"
          stroke="none"
          d="
M462.616669,257.942932
	C464.995789,263.379059 466.793945,268.863098 473.464874,270.446808
	C474.316589,270.648987 474.144135,272.895691 475.699219,273.824860
	C476.621277,275.012482 476.251556,275.940491 475.511658,277.137451
	C474.470886,278.046631 473.506775,278.235748 472.549133,278.472412
	C464.494690,280.462830 463.044128,284.030731 467.192383,291.237732
	C468.228821,293.038391 470.210785,294.359161 469.958282,297.057159
	C467.375214,299.136810 464.705048,298.628540 462.110504,298.152100
	C458.197906,297.433746 454.432373,296.078979 450.527618,295.298767
	C449.878937,295.169189 450.618530,295.107025 450.059296,295.211212
	C446.357086,295.901093 446.790161,287.240570 442.258667,291.383301
	C439.520264,293.886810 436.183716,294.627258 433.345459,296.551636
	C430.264252,298.640717 426.539886,300.107330 423.099274,299.974915
	C418.114380,299.783081 413.276978,299.116852 408.343262,301.184570
	C408.049957,300.507233 408.025421,300.072205 407.995300,299.310425
	C406.474304,291.703217 405.691711,284.394623 406.982361,276.979126
	C407.205231,275.698486 406.540344,273.872772 407.653290,273.201538
	C411.852509,270.669006 410.931793,266.970398 410.670319,263.133514
	C410.455750,259.985199 411.615723,257.443909 414.312500,255.015305
	C420.438873,249.498276 426.001160,243.345154 432.013092,237.131943
	C433.776184,235.124130 435.936340,234.615112 437.964172,233.952927
	C442.907288,232.338776 446.853058,229.653275 449.124573,224.362030
	C451.152435,223.392807 452.804321,223.401199 454.705536,224.685822
	C456.326599,225.630112 457.643311,226.274612 458.983276,226.866455
	C459.721222,227.192398 461.000397,227.755692 460.211487,228.421600
	C455.991211,231.984009 460.447296,232.597488 461.735901,234.827408
	C463.541016,237.951096 466.041687,241.648972 463.787079,245.770950
	C462.889038,247.412766 462.853882,248.873489 463.923950,250.389053
	C465.218262,252.222336 465.522766,254.005188 463.284210,255.821777
	C460.062927,256.225250 461.057159,252.579636 458.733643,251.719543
	C458.343323,251.267120 458.201904,251.072205 457.888245,250.558121
	C455.235291,244.853439 452.643646,243.791153 447.152618,245.710327
	C442.641388,247.287033 439.689697,250.612091 439.049866,255.013580
	C438.043762,261.934723 438.248505,268.914185 441.195740,275.588959
	C442.192291,277.845825 443.865021,279.573669 445.800140,279.666351
	C450.169342,279.875610 454.679413,280.343292 458.874298,278.086426
	C460.639435,277.136780 461.115906,276.279205 460.797302,274.242065
	C460.181061,270.301575 460.553925,266.276917 460.568481,262.287476
	C460.574493,260.635651 460.512939,258.898621 462.616669,257.942932
z"
        />
        <path
          fill="#3C545F"
          opacity="1.000000"
          stroke="none"
          d="
M408.019043,301.232788
	C411.074646,300.447113 413.170563,299.014587 414.263031,295.960236
	C414.943359,294.058228 416.387909,293.457489 416.973236,296.131775
	C417.616058,299.068756 419.724976,299.240173 421.930542,298.922272
	C428.357025,297.996033 433.607880,293.950531 439.662292,291.986877
	C440.241364,291.799042 440.817505,290.730316 440.889801,290.019165
	C441.087799,288.070801 441.744843,286.884766 443.947540,287.054108
	C445.986450,287.210815 446.783936,288.407318 447.052795,290.272736
	C447.312592,292.075256 448.412109,293.629517 450.145111,293.749847
	C456.588684,294.197296 462.454559,297.917389 469.886108,297.085999
	C473.674286,299.304230 476.604614,299.012085 479.639038,296.582428
	C480.268890,295.510620 480.751007,294.744263 481.517639,293.753204
	C482.143738,293.184784 482.557343,292.884125 483.032806,292.743622
	C490.339447,290.585327 491.177002,289.122284 489.818176,280.730042
	C491.286011,278.705780 493.046234,278.515625 495.358093,279.057953
	C496.468109,279.594330 497.001984,280.185425 497.601807,281.242249
	C498.042450,282.336060 498.116058,283.100128 497.994110,284.243164
	C497.793976,285.062469 497.630798,285.509766 497.318054,286.263031
	C496.564972,289.407349 497.368805,292.382294 496.224976,295.514893
	C493.813904,302.568054 487.703094,305.378265 482.002045,306.882690
	C476.668365,308.290131 472.426422,310.778290 467.788879,313.943420
	C450.882019,316.216064 434.319061,319.184357 417.633759,312.595886
	C416.976379,312.444092 416.715332,312.392639 416.089905,312.214417
	C415.518066,311.951935 415.310669,311.816254 414.870422,311.405640
	C414.564758,310.907898 414.491913,310.685089 414.434082,310.127258
	C414.620880,309.432556 414.847626,309.119415 415.292572,308.691284
	C415.389008,308.473206 414.965973,308.274963 414.965973,308.274963
	C414.965973,308.274963 415.304688,308.622009 415.154907,308.843933
	C414.855408,309.186310 414.705688,309.306793 414.291504,309.579651
	C410.835510,310.438629 409.972656,307.089111 407.460205,305.885498
	C406.671906,305.044128 406.435638,304.365448 406.866882,303.302063
	C407.407684,302.527496 407.685638,302.025421 408.019043,301.232788
z"
        />
        <path
          fill="#C99469"
          opacity="1.000000"
          stroke="none"
          d="
M406.929138,302.998810
	C407.322754,303.555573 407.515747,304.081573 407.944824,304.768372
	C412.560364,310.117706 415.708435,316.242767 420.887909,320.851105
	C432.788971,331.439789 446.909729,333.148834 461.979523,331.855743
	C474.881897,330.748596 487.777863,329.594971 500.734070,329.068481
	C513.003235,328.569916 525.245605,327.383270 537.503845,326.555786
	C539.658142,326.410370 541.796143,326.026917 544.380493,326.393921
	C546.460571,326.186340 548.114197,326.426147 550.173645,326.507812
	C555.289368,326.497498 559.966248,325.979370 565.031433,325.490173
	C566.438721,325.534210 567.422607,325.347656 568.752075,324.906006
	C569.621582,324.677155 570.130493,324.610016 571.034058,324.573944
	C573.746765,324.317383 576.126465,324.787933 578.814941,323.953857
	C581.750793,324.040466 584.376526,323.990814 585.483704,327.402161
	C585.097900,328.696533 584.410522,329.237946 583.260010,329.918823
	C581.993408,330.437592 581.100708,330.532501 579.733032,330.483887
	C567.015137,330.471344 554.875549,332.349060 542.607849,332.697357
	C528.223633,333.105804 513.860107,334.659668 499.482544,335.656128
	C483.273102,336.779572 467.104462,338.445007 450.392944,338.500061
	C449.378479,338.503693 448.806122,338.525696 447.823975,338.613464
	C439.209625,338.160217 431.318939,336.583893 424.002625,331.760132
	C422.602905,331.121124 421.664886,330.424225 420.421387,329.601471
	C419.976379,329.293732 419.804016,329.166962 419.384888,328.843201
	C418.525391,328.110107 417.946442,327.531555 416.975525,326.962311
	C416.394928,326.558685 416.076385,326.309082 415.534210,325.849548
	C414.838867,325.245209 414.418518,324.794312 413.723389,324.174255
	C413.334320,323.816071 413.182343,323.666718 412.820221,323.272980
	C411.922394,322.162262 411.722931,321.062347 411.100952,319.805664
	C408.122864,314.319489 404.682098,309.426514 403.054321,303.248047
	C403.879364,302.907318 404.691254,302.950745 405.913605,302.963135
	C406.324066,302.932068 406.728485,302.968048 406.929138,302.998810
z"
        />
        <path
          fill="#59646C"
          opacity="1.000000"
          stroke="none"
          d="
M455.236023,224.730164
	C453.287506,225.007935 451.574799,225.008255 449.434113,225.012512
	C446.446564,229.778244 442.831970,233.225266 437.425293,234.579193
	C436.026978,234.929367 434.641174,235.498810 433.265625,236.756866
	C432.870758,238.430344 433.291901,239.902313 431.611633,240.952347
	C425.771271,244.602051 422.318359,250.939056 416.954285,254.864273
	C411.642334,258.751343 411.651276,262.870209 412.913269,268.203613
	C413.447357,270.460724 413.339142,273.032074 409.801697,273.173004
	C409.283386,273.193604 408.384766,273.863312 408.350830,274.287720
	C407.970551,279.046204 406.831848,283.993958 407.736481,288.523407
	C408.416992,291.930603 408.590454,295.142151 408.243195,298.738403
	C405.845306,295.500061 404.717010,291.725189 405.029297,287.628174
	C405.482361,281.684448 405.094177,275.454651 406.806976,269.882385
	C409.198425,262.102112 411.390106,254.291183 416.205383,247.122971
	C423.714600,235.944595 437.971161,222.698868 447.115356,220.084564
	C454.039673,218.104935 461.267365,213.645996 469.589905,216.982666
	C471.107391,218.649307 473.312653,218.207642 474.819519,219.720917
	C474.283203,222.836716 471.948303,221.913162 470.159882,221.967255
	C465.139587,222.119110 459.984039,221.334412 455.236023,224.730164
z"
        />
        <path
          fill="#C99469"
          opacity="1.000000"
          stroke="none"
          d="
M470.615051,205.368103
	C470.905304,204.213303 470.948273,203.464554 471.001953,202.340836
	C471.486145,201.256317 471.962341,200.549927 473.309631,200.346512
	C479.585449,200.989929 485.454590,200.052368 491.648010,200.753296
	C493.988373,204.203888 493.315308,207.819763 492.700409,211.022217
	C492.007935,214.628387 488.269836,212.479492 485.543060,213.280792
	C483.787506,214.339752 482.334625,214.755585 480.344574,214.165894
	C477.235931,213.881104 474.423676,214.277649 471.371002,213.072815
	C469.575653,210.695282 469.207489,208.298584 470.615051,205.368103
z"
        />
        <path
          fill="#635B5F"
          opacity="1.000000"
          stroke="none"
          d="
M475.434845,220.002319
	C473.053345,219.927689 470.872375,220.349899 470.059143,217.360626
	C472.836121,216.705383 475.541351,217.471588 478.292877,217.933151
	C482.985291,218.720245 487.642639,218.546631 492.588501,216.989212
	C492.526093,220.055740 490.497131,220.832687 487.687317,221.049622
	C483.659393,221.360626 479.823853,220.117859 475.434845,220.002319
z"
        />
        <path
          fill="#916E67"
          opacity="1.000000"
          stroke="none"
          d="
M473.373535,201.018936
	C472.447357,201.277328 471.977997,201.527084 471.260620,201.871338
	C470.726196,198.676819 472.989807,197.696060 475.538208,197.079941
	C480.069336,195.984436 484.508362,196.275467 488.904205,198.567108
	C488.824371,201.525055 486.771637,200.903656 485.295837,200.954437
	C481.477264,201.085876 477.652557,200.983276 473.373535,201.018936
z"
        />
        <path
          fill="#E1B582"
          opacity="1.000000"
          stroke="none"
          d="
M473.244263,201.292114
	C477.865570,198.484512 483.313263,200.888580 488.693024,199.203262
	C490.081390,199.153534 491.291168,199.092224 491.910950,200.679993
	C485.876190,202.119095 479.742920,202.467621 473.244263,201.292114
z"
        />
        <path
          fill="#916E67"
          opacity="1.000000"
          stroke="none"
          d="
M470.552734,205.036774
	C470.903809,207.346085 470.945282,209.730118 470.979462,212.560104
	C470.129913,212.916916 469.423126,212.629913 469.082642,211.447266
	C468.762909,210.574326 468.487366,209.997208 468.119049,209.109924
	C468.264832,207.269516 468.732391,205.876984 470.552734,205.036774
z"
        />
        <path
          fill="#C99469"
          opacity="1.000000"
          stroke="none"
          d="
M403.074066,293.448120
	C402.982208,294.394745 402.901642,295.020752 402.821075,295.646729
	C401.071747,293.601440 400.062622,291.520630 402.693939,289.098999
	C404.263733,290.339630 402.756012,291.744812 403.074066,293.448120
z"
        />
        <path
          fill="#916E67"
          opacity="1.000000"
          stroke="none"
          d="
M480.084412,214.338989
	C481.363373,213.254868 482.808685,212.931976 484.732788,213.249146
	C484.315491,216.903564 482.493774,216.707779 480.084412,214.338989
z"
        />
        <path
          fill="#916E67"
          opacity="1.000000"
          stroke="none"
          d="
M460.878906,208.661377
	C460.986603,208.767700 460.501892,208.871933 460.256012,208.841949
	C460.203705,208.512772 460.457397,208.427139 460.878906,208.661377
z"
        />
        <path
          fill="#557E8B"
          opacity="1.000000"
          stroke="none"
          d="
M401.909180,316.204102
	C402.039642,316.527527 402.443451,316.725464 402.454712,316.843689
	C403.336487,317.248688 404.119507,317.658417 404.824890,318.740112
	C405.043335,318.995880 404.994751,319.005920 404.999512,318.981567
	C405.210083,319.211304 405.432556,319.449829 405.856812,319.833008
	C406.041931,319.993164 405.994781,320.001434 406.003357,319.979095
	C406.212006,320.212860 406.431549,320.450928 406.856598,320.827545
	C407.042603,320.984070 406.995453,320.994812 407.010315,320.975708
	C407.373352,321.371979 407.594666,321.846466 407.649048,322.775543
	C406.745850,325.010468 405.255066,324.820251 403.845062,324.134827
	C397.758362,321.175812 391.251709,319.150635 385.337067,315.826355
	C383.659454,314.883453 381.647552,314.134888 381.547058,311.366821
	C384.355560,308.532379 387.480225,309.534882 390.543640,309.923401
	C392.054626,310.115082 393.682648,311.467743 395.313965,309.323120
	C396.338409,308.701599 397.012573,308.733185 397.818176,309.693298
	C398.046326,309.991333 397.999847,310.003387 398.010437,309.981812
	C398.422760,310.422516 398.482727,311.019897 398.848877,311.793304
	C399.039551,312.015015 398.984863,312.012695 398.989105,311.985657
	C399.206665,312.208923 399.432770,312.447021 399.857361,312.834717
	C400.043091,312.996552 399.994110,313.004974 399.999756,312.980774
	C400.210297,313.211365 400.432465,313.450134 400.857269,313.832642
	C401.042694,313.992401 400.997955,314.003143 401.007507,313.982208
	C401.505432,314.508026 401.480225,315.258606 401.909180,316.204102
z"
        />
        <path
          fill="#85C1D1"
          opacity="1.000000"
          stroke="none"
          d="
M389.074951,286.014893
	C389.833588,286.393677 390.299774,288.455872 391.450134,286.119873
	C392.740204,292.123779 393.741943,298.323822 396.053345,304.604553
	C395.952393,305.137268 395.774536,305.294983 395.250244,305.542511
	C394.419678,305.617310 393.959198,305.502838 393.220612,305.102112
	C392.755127,304.729187 392.587891,304.557678 392.222412,304.087585
	C391.504761,302.477539 391.028717,301.147308 390.189880,299.579712
	C389.950134,299.023407 389.864014,298.795074 389.686768,298.208008
	C389.564850,297.602173 389.534027,297.355133 389.427734,296.739563
	C388.823425,293.148193 386.679413,290.089447 388.592957,286.327972
	C388.822052,286.014160 388.990906,286.005768 389.074951,286.014893
z"
        />
        <path
          fill="#8AB7C6"
          opacity="1.000000"
          stroke="none"
          d="
M407.170959,323.239258
	C407.001648,322.463318 406.993561,322.032562 407.005341,321.279205
	C408.341339,320.500732 409.062988,321.709686 410.712738,322.348267
	C411.489349,323.140686 411.457581,323.625397 410.946747,324.338348
	C409.300323,325.069000 408.160645,324.846771 407.170959,323.239258
z"
        />
        <path
          fill="#8AB7C6"
          opacity="1.000000"
          stroke="none"
          d="
M394.553772,305.430054
	C394.956726,305.118011 395.191071,305.088348 395.777832,305.019104
	C397.227295,306.225189 397.978058,307.638336 398.133301,309.644531
	C397.561188,309.710724 397.076080,309.430084 396.031555,308.898834
	C394.574402,307.867859 394.163361,306.907288 394.553772,305.430054
z"
        />
        <path
          fill="#372624"
          opacity="1.000000"
          stroke="none"
          d="
M410.964874,319.946533
	C411.916656,320.467560 412.845490,321.434296 413.920532,322.697571
	C413.067688,323.775452 412.186523,323.613617 411.099152,322.425842
	C410.794250,322.190186 410.780853,322.174561 410.772095,322.168365
	C410.822876,321.572174 410.882385,320.982208 410.964874,319.946533
z"
        />
        <path
          fill="#8AB7C6"
          opacity="1.000000"
          stroke="none"
          d="
M405.059937,318.588623
	C404.350494,318.465057 403.657623,317.934204 402.715393,317.182648
	C403.754395,316.473083 404.545746,317.048279 405.059937,318.588623
z"
        />
        <path
          fill="#8AB7C6"
          opacity="1.000000"
          stroke="none"
          d="
M402.176697,316.146545
	C401.712219,315.906769 401.384796,315.286011 401.037201,314.313263
	C401.911224,314.230682 402.230286,314.913452 402.176697,316.146545
z"
        />
        <path
          fill="#8AB7C6"
          opacity="1.000000"
          stroke="none"
          d="
M399.139740,311.685913
	C398.698517,311.533417 398.357483,311.051849 398.018738,310.265259
	C398.850464,310.056458 399.126923,310.635315 399.139740,311.685913
z"
        />
        <path
          fill="#8AB7C6"
          opacity="1.000000"
          stroke="none"
          d="
M406.022675,319.696411
	C405.762024,319.806274 405.482117,319.619385 405.103271,319.194885
	C405.337341,319.104706 405.670380,319.252167 406.022675,319.696411
z"
        />
        <path
          fill="#8AB7C6"
          opacity="1.000000"
          stroke="none"
          d="
M407.049683,320.679260
	C406.741547,320.816223 406.440521,320.648407 406.075714,320.218689
	C406.360229,320.095978 406.708527,320.235199 407.049683,320.679260
z"
        />
        <path
          fill="#8AB7C6"
          opacity="1.000000"
          stroke="none"
          d="
M400.039764,312.689636
	C399.763275,312.808014 399.483459,312.619507 399.098511,312.194824
	C399.341034,312.100006 399.688751,312.241394 400.039764,312.689636
z"
        />
        <path
          fill="#8AB7C6"
          opacity="1.000000"
          stroke="none"
          d="
M401.021118,313.696442
	C400.754150,313.813171 400.465607,313.633942 400.091217,313.205627
	C400.336761,313.104553 400.668182,313.252533 401.021118,313.696442
z"
        />
        <path
          fill="#95F8FC"
          opacity="1.000000"
          stroke="none"
          d="
M387.756287,259.787476
	C387.638489,265.020477 387.245911,270.028717 386.803223,275.481506
	C385.298492,277.357605 383.636017,276.684906 382.089264,276.266266
	C377.745819,275.090576 373.413696,274.174500 368.890137,275.216187
	C366.520111,275.761963 364.835510,274.174835 362.672333,272.851196
	C361.921356,272.208679 361.562469,271.766754 361.074066,270.874268
	C360.677124,266.918457 359.508698,263.267609 362.421631,259.887634
	C368.675415,254.880859 375.640930,255.972061 382.379364,256.129791
	C384.621796,256.182251 386.326202,257.603333 387.756287,259.787476
z"
        />
        <path
          fill="#394148"
          opacity="1.000000"
          stroke="none"
          d="
M361.681396,143.844040
	C362.673279,158.681015 363.547150,173.366577 363.900818,188.064682
	C364.041199,193.899872 362.921753,199.766006 364.771027,205.794037
	C363.840027,206.956329 362.667267,207.877960 361.181000,208.903336
	C360.071899,206.510513 359.276306,204.013916 358.227264,201.261459
	C356.685211,197.148392 357.015778,193.156326 357.010803,189.196487
	C356.993958,175.777023 356.997894,162.357513 356.995117,148.469421
	C357.972656,146.083801 358.808350,144.024338 361.681396,143.844040
z"
        />
        <path
          fill="#67BFC6"
          opacity="1.000000"
          stroke="none"
          d="
M362.865265,259.369324
	C361.233337,263.127716 362.371277,266.709442 361.935242,270.616791
	C361.613434,271.178192 361.343872,271.285248 360.645020,271.314941
	C357.810333,269.261414 358.292511,266.563416 358.456299,263.533691
	C358.843292,260.860107 358.889435,258.529175 360.716919,256.322113
	C362.330444,251.271698 363.667816,246.545776 365.071472,241.439545
	C366.187073,239.125412 367.236359,237.191589 368.510376,235.008179
	C368.980499,234.725815 369.225861,234.693039 369.411865,235.026810
	C366.885742,243.164597 362.965790,250.535736 362.865265,259.369324
z"
        />
        <path
          fill="#67BFC6"
          opacity="1.000000"
          stroke="none"
          d="
M379.654419,220.003769
	C379.731964,221.304428 379.263855,222.429581 377.672852,222.657318
	C376.916107,220.975021 378.191986,220.529373 379.654419,220.003769
z"
        />
        <path
          fill="#67BFC6"
          opacity="1.000000"
          stroke="none"
          d="
M372.782654,228.220001
	C373.194092,226.545578 373.856293,225.470230 375.626526,224.991470
	C375.819153,226.370789 375.074432,227.335083 373.724670,228.207748
	C373.208282,228.422577 372.975555,228.505524 372.782654,228.220001
z"
        />
        <path
          fill="#67BFC6"
          opacity="1.000000"
          stroke="none"
          d="
M379.947083,219.950714
	C379.917664,218.400101 380.834564,217.530792 382.595886,216.997620
	C382.292236,218.030334 381.467926,218.957718 380.342163,219.961609
	C380.040680,220.038101 379.978394,219.979736 379.947083,219.950714
z"
        />
        <path
          fill="#67BFC6"
          opacity="1.000000"
          stroke="none"
          d="
M373.284668,228.219788
	C373.142273,229.628677 372.670013,230.826904 371.210907,231.764648
	C370.773376,230.388412 371.344391,229.235962 372.565308,228.053467
	C372.822540,227.851517 373.128326,228.099960 373.284668,228.219788
z"
        />
        <path
          fill="#67BFC6"
          opacity="1.000000"
          stroke="none"
          d="
M368.891785,234.915009
	C369.240326,233.881348 369.745544,233.004120 370.506653,231.956497
	C370.762604,231.786102 370.932220,231.941101 371.017456,232.019302
	C370.975464,233.190323 370.818359,234.270844 369.622803,235.214233
	C369.352509,235.393356 369.048462,235.071457 368.891785,234.915009
z"
        />
        <path
          fill="#67BFC6"
          opacity="1.000000"
          stroke="none"
          d="
M389.387909,211.041565
	C389.452454,209.973740 390.200562,209.281067 391.590576,208.947464
	C391.897430,208.894196 392.019348,209.018036 392.082947,209.081360
	C391.823853,210.314316 390.991425,210.905182 389.387909,211.041565
z"
        />
        <path
          fill="#67BFC6"
          opacity="1.000000"
          stroke="none"
          d="
M386.257660,213.895294
	C386.307831,213.212555 386.773804,212.598984 387.764221,212.131668
	C387.750305,212.840118 387.211884,213.402298 386.257660,213.895294
z"
        />
        <path
          fill="#67BFC6"
          opacity="1.000000"
          stroke="none"
          d="
M386.020996,214.027893
	C385.590485,214.678818 385.100616,215.262177 384.175903,215.795044
	C383.882355,214.846298 384.371704,214.240036 385.542358,213.890060
	C385.841858,213.826126 385.961639,213.960327 386.020996,214.027893
z"
        />
        <path
          fill="#67BFC6"
          opacity="1.000000"
          stroke="none"
          d="
M382.815613,216.813080
	C382.858948,216.421097 383.063812,216.178604 383.535797,215.866455
	C383.741089,215.744553 383.916351,215.921982 384.003540,216.012177
	C383.977844,216.411041 383.793427,216.668427 383.326965,216.988739
	C383.116516,217.102951 382.915283,216.910339 382.815613,216.813080
z"
        />
        <path
          fill="#67BFC6"
          opacity="1.000000"
          stroke="none"
          d="
M389.133942,211.139282
	C389.101288,211.521942 388.922333,211.781174 388.478943,212.133545
	C388.288727,212.277924 388.052094,212.042542 387.934235,211.921906
	C387.975464,211.514511 388.195526,211.286621 388.695709,211.021545
	C388.914856,210.925491 389.061829,211.067093 389.133942,211.139282
z"
        />
        <path
          fill="#67BFC6"
          opacity="1.000000"
          stroke="none"
          d="
M375.874664,224.865143
	C375.984955,224.615921 376.166382,224.439377 376.347809,224.262817
	C376.376221,224.467758 376.404633,224.672684 376.262756,224.978149
	C376.092438,225.078674 375.945801,224.937805 375.874664,224.865143
z"
        />
        <path
          fill="#85EAF4"
          opacity="1.000000"
          stroke="none"
          d="
M362.041260,272.398956
	C370.121582,274.205048 378.571960,271.840149 386.395996,275.878845
	C386.805328,278.302155 386.857574,280.678223 386.898254,283.496826
	C383.118073,286.996155 379.015747,285.754456 375.097748,284.573822
	C371.897064,283.609253 368.753418,282.779053 364.985474,282.941162
	C363.457520,282.526184 362.686798,281.763092 361.897491,280.442871
	C361.543304,279.576233 361.343353,279.082367 360.996094,278.222839
	C360.573303,276.012054 360.460602,274.208099 362.041260,272.398956
z"
        />
        <path
          fill="#76D4DF"
          opacity="1.000000"
          stroke="none"
          d="
M363.673767,282.324646
	C367.531616,280.757782 370.927368,280.650146 374.407806,282.551025
	C378.008362,284.517487 382.072968,283.989471 386.444885,283.955566
	C387.079712,284.495056 387.272736,285.050812 387.676544,285.812683
	C388.753021,289.255829 389.052338,292.596161 389.548279,296.269531
	C381.796844,294.385590 373.605927,294.499939 365.902710,290.854034
	C363.277130,288.386200 362.109100,285.844666 363.673767,282.324646
z"
        />
        <path
          fill="#47A2AE"
          opacity="1.000000"
          stroke="none"
          d="
M389.306580,297.198608
	C388.917328,293.600098 387.529419,290.348694 387.898224,286.452789
	C387.887329,286.018860 388.355469,286.005585 388.588745,286.009888
	C388.980164,289.548431 389.884583,292.929077 390.976379,296.671753
	C390.876678,297.188171 390.735870,297.312439 390.319305,297.495239
	C389.862427,297.503723 389.681244,297.453674 389.306580,297.198608
z"
        />
        <path
          fill="#CD7D4C"
          opacity="1.000000"
          stroke="none"
          d="
M606.740234,83.690704
	C605.610535,83.935783 604.226257,83.955223 602.432007,83.909164
	C603.423157,82.694870 604.932922,82.825989 606.740234,83.690704
z"
        />
        <path
          fill="#6D3E2F"
          opacity="1.000000"
          stroke="none"
          d="
M1020.173218,615.340515
	C1016.201233,617.970276 1012.134827,617.440857 1008.203857,616.030701
	C1002.397705,613.947754 996.440430,612.703064 990.733521,609.714905
	C986.059998,607.267883 979.685913,607.026062 974.135071,605.296570
	C963.603821,602.015259 953.678223,597.334229 943.489441,593.299927
	C938.260681,591.229614 932.658936,589.037720 926.886902,587.904053
	C921.412292,586.828796 916.061157,585.130615 910.645996,583.744507
	C906.800903,582.760315 903.603210,580.833679 901.113037,577.262329
	C900.868713,576.709778 900.796082,576.452881 900.962036,575.872803
	C901.651489,575.251465 902.141907,575.050415 903.067993,574.914124
	C910.638184,577.847534 918.436584,578.737671 925.659546,581.574646
	C928.388367,582.646484 931.162842,583.954529 934.652832,583.010010
	C941.522034,583.824463 947.268494,586.963013 953.301514,589.160400
	C973.600342,596.553955 993.655762,604.610535 1014.102478,611.609253
	C1015.839050,612.203613 1017.550354,612.844849 1019.351074,614.190857
	C1019.854431,614.598633 1020.043274,614.776978 1020.173218,615.340515
z"
        />
        <path
          fill="#403D45"
          opacity="1.000000"
          stroke="none"
          d="
M919.957886,592.651123
	C915.497375,593.112732 915.497375,595.390015 917.342285,598.552795
	C918.481934,600.506470 921.061951,601.544006 920.984436,604.641846
	C915.942810,604.296143 913.268860,600.114319 909.782776,597.188293
	C907.370789,595.163818 905.300842,592.789734 901.394409,593.010193
	C900.632874,593.341248 900.200745,593.534912 899.346558,593.487061
	C896.284058,588.133484 890.439636,587.051514 885.978577,583.532349
	C885.703613,582.993713 885.647461,582.751526 885.679688,582.143433
	C886.508484,580.691956 887.714417,580.177856 889.057861,579.238098
	C890.481018,578.805420 891.497986,578.884949 892.736694,579.745605
	C900.139343,581.659485 906.449890,585.615112 913.621338,587.137268
	C916.507751,587.749878 918.766968,589.419556 919.957886,592.651123
z"
        />
        <path
          fill="#6D3E2F"
          opacity="1.000000"
          stroke="none"
          d="
M1007.687378,604.760681
	C1008.148438,605.015015 1008.316406,605.025085 1008.736206,605.051270
	C1010.656189,608.986023 1015.844177,607.938110 1017.929565,611.706787
	C1016.607483,613.508484 1015.210571,612.490417 1013.860901,612.022766
	C989.589539,603.613403 965.794495,593.921631 941.646606,585.179077
	C941.342651,585.068970 941.096069,584.812195 940.558716,584.307251
	C939.806274,583.316406 939.543152,582.549255 939.564026,581.300903
	C940.202637,579.845398 941.109741,579.304199 942.662781,579.120178
	C952.529053,582.845825 962.841431,584.454651 971.420654,590.409607
	C972.935425,591.461060 974.972961,591.251099 976.686890,592.757507
	C976.975464,593.011353 976.995239,593.004883 976.993225,593.015015
	C987.312622,596.360840 997.188293,600.875549 1007.687378,604.760681
z"
        />
        <path
          fill="#DABA90"
          opacity="1.000000"
          stroke="none"
          d="
M940.728394,583.051147
	C966.189148,593.328857 991.556702,603.086243 1017.612366,612.040894
	C1018.814758,612.638977 1019.635498,613.223328 1020.752075,613.879333
	C1021.263855,614.485657 1021.137512,614.920898 1020.299500,615.168457
	C995.549622,607.049316 971.787537,597.338562 947.710815,588.482361
	C943.730286,587.018188 939.762512,585.519226 935.418274,583.992920
	C928.995056,583.706848 923.803284,581.092407 918.622314,577.678345
	C918.473389,577.052551 918.471802,576.790222 918.621460,576.163208
	C919.149353,575.423523 919.588013,575.147095 920.468445,574.886902
	C924.789490,574.700134 927.945374,577.143127 931.902100,578.245117
	C932.722473,578.507935 933.199097,578.631165 934.023193,578.873230
	C936.576660,580.003784 938.567749,581.368469 940.728394,583.051147
z"
        />
        <path
          fill="#3E2423"
          opacity="1.000000"
          stroke="none"
          d="
M893.272095,579.760925
	C891.955933,580.017578 890.887756,580.019470 889.419556,580.024414
	C888.844666,579.869629 888.669861,579.711853 888.350891,579.231201
	C888.126587,578.444336 888.118408,577.979919 888.303955,577.186523
	C889.197144,575.501282 890.249695,574.324890 891.236328,572.692017
	C892.347412,571.381409 893.059509,570.139709 894.738159,569.447021
	C898.380127,570.022339 900.253906,572.103821 901.014038,575.640869
	C900.973389,576.012146 900.953369,576.489746 900.947388,576.728271
	C897.998352,576.441833 896.161011,579.148315 893.272095,579.760925
z"
        />
        <path
          fill="#9B8881"
          opacity="1.000000"
          stroke="none"
          d="
M1019.983032,615.239624
	C1020.231750,614.873230 1020.533203,614.666321 1020.941284,614.205200
	C1024.275635,613.732300 1025.547607,615.752136 1026.696777,618.745728
	C1023.839355,618.838135 1022.847168,616.234863 1020.448181,615.833740
	C1020.114380,615.725647 1020.035706,615.399109 1019.983032,615.239624
z"
        />
        <path
          fill="#533230"
          opacity="1.000000"
          stroke="none"
          d="
M976.934692,592.661011
	C973.479736,593.592041 970.656677,592.193420 967.928589,590.174683
	C962.537537,586.185425 955.767639,585.650940 949.729431,583.256775
	C947.604004,582.413879 945.675720,581.156921 943.232788,580.270935
	C941.465576,578.027649 941.136597,575.678284 942.051270,573.524170
	C943.649231,569.761047 943.098328,566.240540 941.843384,562.330200
	C942.571411,561.195801 943.412537,561.015320 944.662598,561.015381
	C946.184143,564.903992 951.212952,565.896423 952.049988,569.751709
	C953.332581,575.658752 957.569885,578.871887 961.792969,582.388367
	C965.164368,585.195557 969.152832,586.788147 972.859436,588.933655
	C974.397278,589.823792 976.400452,590.168945 976.934692,592.661011
z"
        />
        <path
          fill="#533230"
          opacity="1.000000"
          stroke="none"
          d="
M1007.898193,604.647339
	C1003.781921,605.330322 1000.237488,603.184692 996.639343,601.652222
	C990.183044,598.902405 983.149170,597.569885 977.071777,593.370728
	C983.233887,593.558289 989.149109,595.354492 994.817505,597.915100
	C999.213196,599.900757 1003.487610,602.155029 1007.898193,604.647339
z"
        />
        <path
          fill="#45AFBC"
          opacity="1.000000"
          stroke="none"
          d="
M920.984131,630.529480
	C918.872437,631.609192 917.108032,632.506958 915.153320,633.673950
	C914.962891,633.943237 914.486816,633.905212 914.248291,633.894409
	C909.880310,633.988037 906.758423,630.664978 902.727661,630.524170
	C898.844116,630.388428 894.906555,629.916687 890.811401,631.476868
	C880.755310,628.688660 870.964661,627.066284 860.521362,628.623108
	C858.750854,629.361755 857.311707,629.756104 855.435974,629.115051
	C854.238525,628.463684 853.711487,627.633972 852.885071,626.614014
	C852.634155,626.085449 852.569702,625.859375 852.513306,625.283447
	C852.692078,624.102661 853.091858,623.390686 853.627258,622.740234
	C854.271973,621.957092 854.973083,621.150085 853.974121,620.161499
	C853.159607,619.355408 852.184509,619.302979 851.246094,619.869873
	C849.173523,621.121521 847.159180,620.979065 844.879150,619.555237
	C843.322571,616.216492 840.727112,614.560242 837.514832,613.055054
	C836.711243,612.264893 836.400818,611.607788 836.401245,610.456787
	C836.759949,609.337463 837.297485,608.806885 838.313293,608.238770
	C839.145325,607.957397 839.637207,607.881653 840.499878,607.868286
	C842.297241,608.313721 843.710693,608.077148 845.186218,607.802063
	C843.823059,606.819946 843.008911,605.705017 843.871277,603.735229
	C844.533325,603.170044 844.992798,602.968689 845.875061,602.795471
	C850.851624,603.883423 855.676819,604.438843 859.396973,608.390686
	C859.525757,608.914795 859.583801,609.111816 859.515015,609.241394
	C856.601440,614.730469 856.564026,614.901611 862.639221,618.139709
	C869.630859,621.866272 877.281128,621.749390 884.897644,621.385620
	C887.696411,621.252014 890.448914,620.537476 893.270569,620.402222
	C898.730896,620.140503 902.338196,617.063782 905.132690,612.227905
	C907.892517,610.416138 909.731201,611.897095 911.378174,613.504272
	C913.147339,615.230774 912.240234,616.636658 911.053284,618.557617
	C909.635925,620.851440 912.834473,620.259888 913.989258,621.455200
	C916.141357,624.357239 919.986938,624.514404 921.792725,627.791870
	C921.995300,628.961670 921.827209,629.697571 920.984131,630.529480
z"
        />
        <path
          fill="#457483"
          opacity="1.000000"
          stroke="none"
          d="
M854.756592,627.728027
	C855.952759,628.608887 856.937805,628.899475 858.269531,628.213074
	C861.754211,628.476013 865.191040,628.241821 866.691589,632.303650
	C867.302734,633.958130 868.765076,633.173157 870.050415,632.546997
	C877.334167,628.999023 883.540771,630.386841 888.973022,636.990173
	C886.849487,641.801270 882.304932,640.673584 878.986023,640.580383
	C872.327820,640.393372 865.353149,639.919434 859.165344,637.718750
	C852.670776,635.408997 845.354431,636.694092 839.226135,632.287231
	C837.920105,630.967224 836.824036,629.879578 837.727539,628.245605
	C838.549255,626.759460 840.099854,626.569397 841.551636,626.739258
	C843.669312,626.986877 845.731445,627.304749 847.945557,625.696167
	C849.623901,624.946533 850.944946,624.752686 852.569092,625.778259
	C852.870056,626.061096 852.901428,626.094666 852.924438,626.095825
	C853.484009,626.541016 853.998657,627.009583 854.756592,627.728027
z"
        />
        <path
          fill="#3E6978"
          opacity="1.000000"
          stroke="none"
          d="
M848.692383,626.458191
	C846.998108,628.861267 844.702759,629.063293 841.976868,628.266724
	C839.574829,627.564758 837.622314,627.951355 838.953857,631.648254
	C836.614563,631.541199 835.857178,633.771240 834.047974,634.949036
	C828.233948,634.291931 823.002991,637.324890 817.394714,636.081665
	C815.545593,635.671692 811.604248,634.609680 813.234436,639.775574
	C810.706726,642.118347 808.514038,640.641479 805.955444,639.446655
	C805.407593,639.170471 805.191772,639.053528 804.676270,638.724487
	C803.362793,637.438599 802.162842,636.637756 800.467285,635.885010
	C799.481384,635.228271 798.925110,634.675537 798.509888,633.533447
	C798.684998,631.570435 799.755188,630.887512 801.162537,630.697571
	C810.108154,629.490295 818.803528,626.986084 828.123962,625.606262
	C832.539856,626.585022 836.426025,625.665222 840.727661,624.867065
	C843.569763,625.218140 846.112061,624.969238 848.692383,626.458191
z"
        />
        <path
          fill="#6CDAE1"
          opacity="1.000000"
          stroke="none"
          d="
M889.388672,637.418213
	C888.138306,637.039795 886.570801,636.982300 886.360352,636.418396
	C884.384521,631.123718 879.496277,633.176086 875.890259,632.184570
	C874.029419,631.672852 872.014099,631.661499 870.918396,634.157410
	C869.941223,636.383362 867.915161,635.589722 866.129272,635.167053
	C864.438782,634.766968 864.122498,633.925110 864.695984,632.281616
	C865.345642,630.419739 863.978943,630.061340 862.496033,629.982849
	C861.205933,629.914612 859.785583,630.192749 858.725342,628.444153
	C861.589417,625.382202 865.364563,625.779419 868.953796,626.055359
	C875.539062,626.561768 882.021667,628.119019 888.668823,628.112610
	C890.046692,628.111267 890.807617,628.836426 891.005615,630.603027
	C891.727112,631.479980 892.453369,631.902954 893.044922,633.040894
	C893.502625,634.874329 893.379761,636.225403 891.983643,637.583862
	C890.978394,637.692261 890.339722,637.598206 889.388672,637.418213
z"
        />
        <path
          fill="#47A2AE"
          opacity="1.000000"
          stroke="none"
          d="
M813.241211,640.729004
	C812.082886,639.114563 810.996399,637.902405 809.656372,636.407410
	C813.319214,634.668701 816.622742,632.496826 820.573853,635.411133
	C820.969238,635.702759 822.111511,635.693665 822.339844,635.393127
	C825.768372,630.880554 829.427429,633.898560 833.375366,635.041016
	C834.493347,636.042053 836.088013,636.412903 835.688354,638.391174
	C831.604614,639.269409 827.598206,639.849487 823.288086,640.667725
	C822.528748,640.905151 822.073181,640.904297 821.277100,640.875610
	C819.742004,640.270569 818.524658,640.418274 817.087280,640.960205
	C816.872559,641.187012 816.650879,641.268188 816.561523,641.191040
	C815.420959,641.443665 814.369385,641.719421 813.241211,640.729004
z"
        />
        <path
          fill="#457483"
          opacity="1.000000"
          stroke="none"
          d="
M891.992188,637.880981
	C891.672180,636.388062 891.727234,634.989929 891.818115,633.168091
	C896.879578,629.331299 903.391602,629.526794 907.078918,633.670044
	C904.507446,638.181519 896.913208,640.171753 891.992188,637.880981
z"
        />
        <path
          fill="#67BFC6"
          opacity="1.000000"
          stroke="none"
          d="
M907.377686,634.187256
	C902.558899,630.439880 897.562683,632.270569 892.276672,632.742065
	C891.635559,632.317200 891.417236,631.890015 891.123657,631.233337
	C895.720337,626.587463 901.101257,628.916626 906.132874,629.752136
	C908.761292,630.188538 911.150146,632.068298 913.828491,633.589844
	C912.003845,635.491333 909.885132,635.101501 907.377686,634.187256
z"
        />
        <path
          fill="#574649"
          opacity="1.000000"
          stroke="none"
          d="
M816.954346,660.391724
	C809.603088,661.866150 802.266296,662.984131 794.495117,664.089905
	C791.575256,661.061157 792.999329,657.462341 793.044373,654.253845
	C793.082275,651.553894 792.532104,649.636169 790.814270,647.462158
	C791.149658,646.744568 791.573975,646.574646 792.391846,646.724854
	C800.600098,647.720276 808.280762,644.441711 816.226501,645.214478
	C816.297302,647.345825 814.936646,647.498169 813.928589,647.799622
	C809.168579,649.223206 809.140076,649.416504 812.628906,653.171692
	C814.486694,655.171387 817.334717,656.607361 816.954346,660.391724
z"
        />
        <path
          fill="#8B9698"
          opacity="1.000000"
          stroke="none"
          d="
M792.797729,646.642578
	C792.315918,646.875244 791.915833,646.867004 791.217163,646.909058
	C789.351257,649.324280 786.752747,649.508667 784.335449,650.024109
	C780.927551,650.750854 777.435242,650.888184 773.561523,651.391724
	C770.882019,651.207642 769.325806,649.974548 767.967712,647.764404
	C767.093750,642.540710 767.710144,641.917297 772.298340,641.484436
	C775.913147,641.143372 780.033447,643.014526 783.239624,639.006592
	C785.061218,636.920410 787.105469,636.377930 789.741211,636.433960
	C791.077698,636.565430 792.093994,636.494751 792.960571,636.956055
	C795.371277,638.239319 796.247498,639.732788 793.276123,641.389099
	C792.239441,641.966919 790.884766,642.302307 793.093140,643.683350
	C793.510437,644.389343 793.546570,644.853149 793.256531,645.625488
	C792.994202,646.082397 792.936829,646.242004 792.797729,646.642578
z"
        />
        <path
          fill="#5E9BAB"
          opacity="1.000000"
          stroke="none"
          d="
M793.446045,645.911560
	C793.025146,645.440430 792.998657,644.957947 792.941223,644.114075
	C793.391479,640.796021 796.014038,640.667358 798.097046,639.931702
	C800.114319,639.219055 802.299133,639.036865 804.633179,638.056641
	C807.796936,637.556946 809.583252,640.508301 812.760315,640.258789
	C814.029541,640.571228 814.889648,640.815735 816.111023,641.087097
	C809.165283,643.830017 801.308289,643.945068 793.446045,645.911560
z"
        />
        <path
          fill="#5E9BAB"
          opacity="1.000000"
          stroke="none"
          d="
M817.284790,641.192261
	C817.837280,639.880676 819.065125,639.778137 820.694458,640.626221
	C819.856689,640.964417 818.776855,641.080933 817.284790,641.192261
z"
        />
        <path
          fill="#478D9B"
          opacity="1.000000"
          stroke="none"
          d="
M914.341187,621.657227
	C912.399109,621.859375 910.696533,621.880554 909.129761,621.429871
	C907.204712,620.876282 906.684082,619.319275 908.455627,618.191956
	C915.622437,613.631714 907.860718,613.950195 906.211853,612.181519
	C903.242126,614.022339 900.992310,613.090637 898.634216,610.909180
	C897.732239,608.374084 898.842041,606.743530 900.351196,604.848999
	C901.357483,604.057861 902.147156,603.794312 903.415466,603.682434
	C905.294006,603.705444 906.544922,604.209961 907.750977,605.671753
	C910.073120,607.504150 912.425293,608.055908 914.964233,608.103455
	C917.120117,608.143860 919.921448,608.245605 920.557556,610.326538
	C921.235962,612.545959 917.613586,611.814514 916.796082,613.647339
	C917.874817,616.883728 926.673462,615.667114 921.997681,622.733582
	C920.024963,623.599731 918.450500,622.478210 916.456787,621.922913
	C915.624939,621.729980 915.442566,620.592346 914.341187,621.657227
z"
        />
        <path
          fill="#3A4D56"
          opacity="1.000000"
          stroke="none"
          d="
M908.198853,605.775269
	C906.885437,605.703613 905.788086,605.392273 904.354004,605.008423
	C898.900269,601.348083 893.151367,598.665649 888.669067,594.165955
	C886.365906,591.853882 881.980408,592.869934 880.606628,588.737427
	C885.626953,584.984741 893.374939,586.737000 898.715210,592.673218
	C899.444092,592.988220 899.890686,592.993530 900.672241,593.005493
	C901.379211,597.355347 904.466553,599.361511 907.896362,601.141907
	C910.104126,602.287842 912.388550,603.586792 908.198853,605.775269
z"
        />
        <path
          fill="#76D4DF"
          opacity="1.000000"
          stroke="none"
          d="
M915.765808,620.860046
	C918.016907,620.889832 920.162170,620.357666 921.625122,622.867676
	C922.597778,624.177490 923.271912,625.277832 924.100281,626.662476
	C923.975342,628.816284 922.749878,628.740234 921.190674,627.899231
	C918.998474,625.437500 914.673279,626.852844 914.053467,622.404419
	C914.041443,622.047485 914.023682,622.022766 914.032043,622.025940
	C914.453125,621.572571 914.856079,621.104004 915.765808,620.860046
z"
        />
        <path
          fill="#43626E"
          opacity="1.000000"
          stroke="none"
          d="
M920.986938,627.861206
	C921.935974,627.729370 922.851807,628.304321 924.033752,627.245605
	C925.274292,627.054749 926.502563,626.444153 927.384033,628.142456
	C925.366150,629.040833 923.382019,629.924133 921.017334,630.759460
	C920.745728,629.898682 920.854675,629.085876 920.986938,627.861206
z"
        />
        <path
          fill="#471B15"
          opacity="1.000000"
          stroke="none"
          d="
M742.210815,683.161499
	C743.064209,678.772644 739.233704,678.398438 737.072266,676.330078
	C737.478210,672.822205 740.270020,673.060974 742.326904,672.685547
	C753.189148,670.702759 763.979797,668.341919 775.299316,666.522827
	C781.014221,666.090210 785.984192,663.097717 791.774048,664.877014
	C791.937500,667.583679 791.885315,669.952026 792.965210,672.570374
	C791.920227,673.088257 790.660706,672.911316 790.040894,674.626709
	C787.092163,675.164795 784.065247,674.420593 781.242981,676.652954
	C779.930054,676.947632 778.866028,676.968262 777.403687,677.012085
	C776.609253,677.370789 776.149841,677.507507 775.311646,677.283569
	C774.551941,677.098511 774.116028,677.101868 773.354370,677.134033
	C770.434082,678.615784 767.887207,680.235779 764.337097,679.320251
	C761.575073,678.566650 760.166992,681.458008 757.373535,680.962646
	C755.830811,682.391052 754.013916,681.774963 752.272827,682.441650
	C751.110413,682.514343 750.265381,681.986267 749.182861,682.708740
	C748.499023,682.907593 748.070618,682.917908 747.320862,682.927734
	C746.554688,682.959534 746.109497,682.985229 745.329834,683.018005
	C744.569885,683.047424 744.144165,683.064148 743.399048,683.089417
	C742.874573,683.194763 742.669495,683.291443 742.210815,683.161499
z"
        />
        <path
          fill="#705250"
          opacity="1.000000"
          stroke="none"
          d="
M792.289917,665.058655
	C786.971191,666.122559 781.900940,667.036072 776.417847,667.935913
	C773.391846,665.006897 774.663513,661.903625 776.143066,659.270508
	C778.305359,655.422424 776.967712,653.662292 772.995117,652.396484
	C772.512207,651.666443 772.460815,651.178894 772.806824,650.373657
	C778.734680,649.034851 784.428894,647.996094 790.520874,646.958313
	C792.210754,648.709045 794.361389,648.709351 796.622070,649.648926
	C791.930054,653.555725 796.163696,658.886230 794.041321,663.660461
	C793.553223,664.354492 793.045776,664.631409 792.289917,665.058655
z"
        />
        <path
          fill="#543937"
          opacity="1.000000"
          stroke="none"
          d="
M1093.638794,609.443604
	C1105.688599,613.906616 1112.463013,631.483704 1105.420288,642.326050
	C1104.248169,644.130554 1103.190918,646.009949 1102.090332,647.860474
	C1097.136719,656.188232 1085.817993,655.952576 1080.764160,647.600586
	C1076.338257,640.286255 1076.352295,631.844727 1074.320679,623.933533
	C1073.632690,621.253784 1081.884399,611.973206 1085.067749,611.181519
	C1087.788208,610.504883 1090.540527,609.955627 1093.638794,609.443604
z"
        />
        <path
          fill="#F6D1A1"
          opacity="1.000000"
          stroke="none"
          d="
M248.553970,470.591400
	C250.246750,471.211945 251.674210,471.713013 252.806778,472.593384
	C254.189178,473.668060 253.634750,474.928925 252.511719,475.924316
	C251.393219,476.915741 250.289719,476.761902 249.786560,475.360168
	C249.054153,473.319763 247.182404,473.055450 245.289032,472.129395
	C244.992111,471.790741 245.009460,471.616791 245.001862,471.181274
	C246.037231,470.826508 247.097580,470.733276 248.553970,470.591400
z"
        />
        <path
          fill="#67BFC6"
          opacity="1.000000"
          stroke="none"
          d="
M240.478058,424.794800
	C244.255905,425.420685 244.832092,427.980957 244.884003,430.773651
	C244.897263,431.487183 244.010437,431.820648 243.370575,431.364777
	C241.102341,429.748749 238.471451,428.288391 238.175308,425.165009
	C238.073425,424.090454 239.343948,424.402222 240.478058,424.794800
z"
        />
        <path
          fill="#929EAC"
          opacity="1.000000"
          stroke="none"
          d="
M274.069946,462.309814
	C275.160187,462.078339 276.289429,462.201233 277.860779,462.314423
	C278.302948,462.304718 278.420837,462.225586 278.434357,462.155884
	C282.742889,464.089661 286.373901,467.236694 291.295654,469.536102
	C292.087830,470.008026 292.278442,470.140594 292.733826,470.499634
	C295.065063,473.314667 299.046570,473.366333 300.767120,476.879639
	C300.865906,477.729797 300.776855,478.208710 300.377686,478.973206
	C295.233643,479.952667 292.397888,476.674866 289.215515,473.329712
	C288.913239,472.711060 288.738708,472.463043 288.254883,472.197540
	C283.497955,468.813782 278.175507,466.664734 274.069946,462.309814
z"
        />
        <path
          fill="#465962"
          opacity="1.000000"
          stroke="none"
          d="
M272.069916,462.104187
	C272.007416,461.944214 272.449158,461.960480 272.660461,462.023163
	C272.625336,462.145294 272.378876,462.204742 272.069916,462.104187
z"
        />
        <path
          fill="#1F323D"
          opacity="1.000000"
          stroke="none"
          d="
M495.164734,411.684784
	C489.598145,414.290863 486.769806,410.985077 485.335114,407.073181
	C483.185883,401.213074 478.656342,396.392120 478.065857,389.883667
	C477.906982,388.132782 476.769257,386.606934 475.029144,386.268799
	C468.879181,385.073761 463.065887,382.446747 456.738464,382.034210
	C451.963898,381.722992 448.226166,384.298462 447.056885,388.760529
	C445.385468,395.139099 440.788788,398.487701 434.260071,399.067078
	C423.573242,400.015442 413.301178,395.952789 402.607208,396.904297
	C397.991638,397.314972 393.239136,396.712097 388.948669,398.875854
	C386.103882,400.310516 382.821564,398.214813 380.086975,400.621460
	C375.601318,402.334412 371.192596,403.709747 366.393860,405.119568
	C364.058197,403.049042 365.084686,401.200592 366.598846,399.510315
	C368.499268,397.388885 370.828583,395.853516 373.560272,394.978485
	C385.810028,391.054718 382.732025,390.267212 383.258911,380.290466
	C382.379608,377.247162 381.729950,374.508636 381.025269,371.391754
	C384.380737,367.311340 388.594330,367.260895 392.882080,368.766541
	C395.689331,369.752228 398.247253,369.520020 401.121948,369.101074
	C412.001343,367.515564 422.921173,367.414215 433.825287,369.234497
	C444.551239,371.024994 455.369568,370.174713 466.124359,369.752777
	C470.429413,369.583893 473.978790,370.113007 477.436279,373.308411
	C479.086578,374.623169 480.319214,375.844391 482.680847,375.436096
	C484.442078,375.251526 485.653717,374.673309 486.999268,374.553528
	C493.377930,373.985809 495.331726,375.767853 495.483673,382.280762
	C495.665131,390.058716 494.932007,397.863281 496.376831,405.610870
	C496.730377,407.506622 496.747772,409.606537 495.164734,411.684784
z"
        />
        <path
          fill="#182932"
          opacity="1.000000"
          stroke="none"
          d="
M478.172974,375.045319
	C477.664978,374.980774 477.429596,375.044037 477.342865,374.957794
	C471.149109,368.797913 463.518005,372.648529 456.556122,372.087952
	C451.108856,371.649292 445.446686,372.814423 440.147156,371.829498
	C429.306793,369.814758 418.448822,370.058441 407.558289,369.989075
	C405.049286,369.973083 402.597931,370.003906 400.307068,371.288971
	C398.638550,372.224945 396.387207,372.670624 395.035034,371.672791
	C390.632599,368.423828 386.242676,369.894104 381.366150,370.997314
	C380.505951,370.705139 380.041595,370.396881 379.292725,370.038818
	C378.941833,368.866364 378.990814,367.690979 377.386169,367.010864
	C376.683868,366.231018 376.295319,365.568604 375.624146,364.795898
	C373.427673,364.393799 373.004242,362.790344 372.309021,361.039398
	C371.909576,359.854431 372.161835,358.667572 370.618286,358.191162
	C370.173157,357.963348 369.999237,357.862762 369.574951,357.593414
	C368.711731,356.900330 368.067444,356.427063 367.017212,356.018433
	C366.407104,355.609955 366.138458,355.293915 365.906982,354.611572
	C367.422516,350.897644 370.532928,352.251129 371.584961,353.643158
	C377.252411,361.142334 385.012299,357.337219 391.860931,357.645630
	C394.778503,357.777039 397.865265,356.013947 400.733826,354.782532
	C403.029114,353.797272 405.447449,353.444427 407.472961,354.457703
	C412.255707,356.850250 417.252747,355.662567 422.154968,355.999237
	C424.439331,356.156128 426.090973,355.619476 426.059418,352.845825
	C426.034332,350.642395 428.479095,349.730469 428.947205,347.364624
	C429.625580,346.356842 430.403931,346.182129 431.648712,346.398956
	C432.428314,346.830353 432.983307,347.141052 433.039307,347.592224
	C433.866852,354.259918 438.774658,353.467285 443.713745,354.628754
	C454.601257,357.189148 465.940338,354.631042 476.681335,358.681061
	C481.325928,360.432373 486.888092,359.308685 491.745239,361.482056
	C494.159210,362.562225 496.768921,363.194244 498.645203,365.789642
	C499.099365,363.805725 497.922119,362.887360 497.479645,361.742126
	C496.342682,358.799683 496.200592,355.986481 499.502228,354.529755
	C502.648804,353.141449 505.498505,354.043762 507.026581,357.274689
	C508.679840,360.770264 510.636536,363.987183 513.731689,366.950745
	C514.544556,368.999237 514.526306,370.694336 513.728027,372.762085
	C510.186676,375.732819 506.282135,374.579254 502.603516,374.810303
	C500.484161,374.943420 498.355713,374.510284 495.834961,374.859253
	C492.400177,375.394897 489.382965,373.848358 485.984497,374.761230
	C484.257141,375.192108 485.270325,371.704010 483.080048,373.549438
	C482.757660,373.996368 482.605438,374.162506 482.172638,374.527100
	C480.811401,375.254272 479.691589,375.432587 478.172974,375.045319
z"
        />
        <path
          fill="#2B3D45"
          opacity="1.000000"
          stroke="none"
          d="
M382.939758,380.332703
	C386.562653,383.674561 385.663666,391.251465 381.659698,393.733765
	C377.357147,396.401123 371.618347,395.861237 368.037689,400.208801
	C366.948914,401.530731 365.789124,402.528320 366.005005,404.760651
	C364.444519,406.327606 362.599091,406.698395 360.374542,407.061401
	C360.227631,402.362732 361.999023,397.696594 360.520264,392.891449
	C360.061462,391.400543 361.498138,391.093506 362.285004,390.312164
	C366.833496,385.795593 373.016815,385.006531 378.641510,382.927612
	C380.125092,382.379303 381.979126,382.413818 382.939758,380.332703
z"
        />
        <path
          fill="#3C5965"
          opacity="1.000000"
          stroke="none"
          d="
M344.805359,330.759796
	C346.783997,331.948700 347.374878,333.605286 347.918274,335.646973
	C343.431335,338.471558 342.107330,343.047729 340.252136,347.699158
	C333.314484,348.004150 328.955414,345.396301 328.002319,340.390350
	C328.383850,339.466705 328.579529,338.727356 329.136261,338.401794
	C334.107239,335.494659 339.469330,333.347015 344.805359,330.759796
z"
        />
        <path
          fill="#688FA1"
          opacity="1.000000"
          stroke="none"
          d="
M337.209198,359.135681
	C334.274384,358.140503 331.808960,357.053741 329.140320,355.718262
	C331.546417,352.948639 335.116302,353.128784 338.119232,353.229736
	C341.985565,353.359741 345.928467,352.457672 349.782684,353.691254
	C352.208618,354.467651 353.056732,352.989777 353.170837,350.417358
	C356.982452,349.547577 356.547852,352.044006 356.228943,354.772156
	C355.684723,356.398468 354.916504,357.375397 353.282166,357.961304
	C347.828033,358.352386 342.753326,358.698242 337.209198,359.135681
z"
        />
        <path
          fill="#59646C"
          opacity="1.000000"
          stroke="none"
          d="
M345.038086,330.668610
	C343.432251,331.025299 342.548553,332.088165 341.723999,333.416382
	C340.263550,335.768890 338.276062,337.422516 335.276154,336.984528
	C332.437225,336.570038 331.208099,339.592041 328.369019,339.948120
	C326.553497,337.977844 323.465118,340.162231 322.078430,337.382019
	C327.547455,336.005463 332.429688,333.514923 336.500641,329.654266
	C337.998688,328.233612 339.377747,327.068665 341.874695,326.987427
	C343.166077,328.099884 344.062836,329.228973 345.038086,330.668610
z"
        />
        <path
          fill="#2C4653"
          opacity="1.000000"
          stroke="none"
          d="
M340.347046,347.975281
	C340.038544,339.558960 341.204102,337.617615 347.572113,335.996429
	C349.876862,339.386749 351.261414,342.945251 351.022461,347.349731
	C351.011932,347.743134 351.027130,347.899902 351.026611,347.978638
	C348.344788,349.347198 345.600220,349.707642 342.382935,348.316681
	C341.565582,348.003906 341.122406,347.994293 340.347046,347.975281
z"
        />
        <path
          fill="#929EAC"
          opacity="1.000000"
          stroke="none"
          d="
M355.400238,355.155609
	C354.793640,353.290771 356.274719,351.196411 353.472107,350.149109
	C353.192871,349.630493 353.168976,349.237274 353.156433,348.548248
	C356.466248,348.865753 359.401703,350.313721 362.403015,352.428314
	C360.940369,355.117310 358.460419,355.587219 355.400238,355.155609
z"
        />
        <path
          fill="#3C5965"
          opacity="1.000000"
          stroke="none"
          d="
M342.021454,348.388977
	C344.712921,348.015442 347.417084,348.017303 350.573700,348.038269
	C349.673187,351.213867 344.307098,351.635803 342.021454,348.388977
z"
        />
        <path
          fill="#A19796"
          opacity="1.000000"
          stroke="none"
          d="
M354.910828,355.155029
	C357.223541,353.936737 359.226746,352.492645 362.208618,352.763916
	C363.534973,353.150909 364.425964,353.609406 365.600891,354.182373
	C366.000519,354.604004 366.211121,354.817719 366.742828,354.982361
	C367.769775,355.695801 368.871033,355.894836 369.660370,357.002625
	C369.735718,357.305786 369.741241,357.092621 369.712646,357.195312
	C370.189270,357.546570 370.570099,357.921692 370.949371,358.814880
	C371.243042,362.871124 369.388794,365.280518 366.098633,366.409882
	C362.692200,367.579102 359.553528,369.739410 355.382812,369.246490
	C351.598938,369.434998 349.438782,367.812164 348.411621,364.644958
	C347.130981,360.696045 351.537384,360.821228 352.855286,358.345276
	C353.520233,357.149475 354.137726,356.292419 354.910828,355.155029
z"
        />
        <path
          fill="#847876"
          opacity="1.000000"
          stroke="none"
          d="
M354.977386,369.324707
	C358.054871,367.844452 360.989532,366.387726 364.092834,365.574310
	C367.497314,364.681976 369.455505,362.782318 370.246033,359.039978
	C371.271942,358.500336 371.977966,358.880188 372.663147,359.854492
	C373.553955,361.458954 374.345856,362.764374 375.239655,364.377686
	C370.467163,369.178986 364.326508,371.466888 358.485168,374.241272
	C355.838745,375.498260 353.065796,375.552979 350.377380,373.143219
	C350.058899,372.958221 350.037750,372.508179 350.041260,372.283539
	C350.925751,369.766022 354.078857,372.026672 354.977386,369.324707
z"
        />
        <path
          fill="#8B9698"
          opacity="1.000000"
          stroke="none"
          d="
M307.103638,323.242920
	C307.860992,323.122620 308.659607,323.393707 308.951996,324.733582
	C308.411377,324.539368 307.825287,324.023041 307.103638,323.242920
z"
        />
        <path
          fill="#15313E"
          opacity="1.000000"
          stroke="none"
          d="
M846.741028,419.168182
	C849.616028,414.542450 851.441284,418.743744 853.808105,419.834167
	C852.508057,420.603638 849.311157,418.443787 850.130615,422.591797
	C849.527466,422.698822 848.904236,422.963409 848.324280,422.889191
	C839.562683,421.767700 830.746094,420.898224 822.137390,417.378082
	C823.726440,415.856506 825.877258,415.992920 828.394653,415.960938
	C830.056824,416.271729 831.285339,416.729767 832.911377,416.964417
	C837.871643,416.715729 842.007446,419.111969 846.741028,419.168182
z"
        />
        <path
          fill="#64777D"
          opacity="1.000000"
          stroke="none"
          d="
M815.927490,418.773132
	C815.614868,421.013000 814.363037,421.231201 812.719604,420.415436
	C811.938293,420.027618 811.310669,419.332001 811.860229,418.425385
	C812.759216,416.942444 814.051025,417.290039 815.592346,418.112518
	C815.859375,418.302734 815.886719,418.619476 815.927490,418.773132
z"
        />
        <path
          fill="#C99469"
          opacity="1.000000"
          stroke="none"
          d="
M902.511963,399.352966
	C902.945496,399.747375 902.971863,400.091797 903.013123,400.695190
	C900.970154,401.273376 898.912354,401.592621 896.473877,401.842468
	C895.698181,401.458221 895.521729,401.049133 895.778259,400.258026
	C896.383911,399.900818 896.775452,399.831268 897.465576,399.763153
	C897.941345,399.710846 898.118469,399.657135 898.573120,399.591919
	C899.935303,399.487976 901.020081,399.395477 902.511963,399.352966
z"
        />
        <path
          fill="#A67A5E"
          opacity="1.000000"
          stroke="none"
          d="
M895.651978,399.936951
	C896.012451,400.371490 896.032532,400.772614 896.072876,401.473389
	C895.358215,401.728638 894.623230,401.684174 893.524597,401.578613
	C892.767456,401.251617 892.014771,400.779480 892.032104,400.749359
	C892.821167,399.374603 894.213379,400.225403 895.651978,399.936951
z"
        />
        <path
          fill="#A67A5E"
          opacity="1.000000"
          stroke="none"
          d="
M898.664551,399.519409
	C898.650513,399.761749 898.415466,399.848633 897.954834,399.802856
	C897.965576,399.576660 898.203674,399.474579 898.664551,399.519409
z"
        />
        <path
          fill="#7DA7B8"
          opacity="1.000000"
          stroke="none"
          d="
M1070.161743,368.171051
	C1070.331909,368.123932 1070.614624,368.300659 1070.981445,368.734528
	C1070.801758,368.792755 1070.538086,368.593811 1070.161743,368.171051
z"
        />
        <path
          fill="#1C2129"
          opacity="1.000000"
          stroke="none"
          d="
M432.406860,347.100891
	C431.250061,347.002258 430.500031,347.001740 429.375000,347.002441
	C426.356018,346.066772 423.775879,344.718506 421.052124,344.295685
	C417.541595,343.750732 416.704498,341.845978 417.010742,338.388062
	C419.068665,336.093109 421.517029,336.714417 423.602783,337.449585
	C438.548004,342.717316 453.768494,341.685577 469.195984,340.503601
	C477.065369,339.900665 484.807312,337.649078 492.789642,338.464569
	C494.026123,338.590912 495.047455,338.071442 496.077515,336.750732
	C499.319305,334.663605 502.600189,335.045319 506.201660,335.004425
	C507.836639,335.011993 509.106812,335.191833 510.656433,334.467896
	C511.870178,334.174438 512.634033,334.333862 513.599731,335.163300
	C514.926880,339.285095 514.522949,343.106476 513.988770,347.329285
	C513.529297,348.134735 513.268555,348.737000 512.818665,348.798492
	C503.901062,350.017731 495.867889,355.236694 486.548492,354.610809
	C485.720154,354.555176 484.829803,354.567749 484.098083,354.226013
	C472.568451,348.840912 460.761505,351.700104 449.026123,353.070404
	C446.116333,353.410126 443.741882,352.596619 441.761261,350.662231
	C439.238556,348.198395 436.347595,346.930176 432.406860,347.100891
z"
        />
        <path
          fill="#372624"
          opacity="1.000000"
          stroke="none"
          d="
M496.644348,336.699768
	C496.706940,339.672913 494.817200,339.824951 492.675201,340.019684
	C477.147003,341.431274 461.650513,343.421448 446.093170,344.162994
	C440.265137,344.440826 434.293457,341.920166 428.403442,340.603271
	C424.859253,339.810883 421.346130,338.879456 417.417236,337.992584
	C416.552246,337.661346 416.089081,337.348053 415.337708,337.004669
	C414.346008,336.336884 413.669373,335.683441 413.823395,334.312561
	C415.382385,331.957153 418.648071,334.350586 419.766052,331.206848
	C419.233917,330.177582 419.186798,329.434662 419.672302,328.332214
	C420.823517,328.529785 421.738678,329.039490 422.821106,329.819672
	C429.673523,335.797485 437.552277,337.764465 446.521912,337.414856
	C447.364532,337.322083 447.834351,337.295593 448.648041,337.354950
	C451.294403,339.260834 453.798401,337.885773 456.201416,337.853424
	C469.583069,337.673279 482.892334,335.477539 496.644348,336.699768
z"
        />
        <path
          fill="#67BFC6"
          opacity="1.000000"
          stroke="none"
          d="
M419.944519,328.010254
	C419.864685,328.715881 419.821014,329.411713 419.782104,330.473602
	C410.348724,331.424622 400.912567,332.045135 391.471527,332.579529
	C386.713470,332.848816 382.227142,331.818909 377.760040,329.206726
	C368.499451,323.650543 367.506561,314.902588 367.486938,305.297485
	C366.195587,304.326294 366.613220,303.578644 367.506653,302.606598
	C369.558685,302.017639 370.652649,302.872070 371.765442,304.495239
	C372.490173,306.278931 372.845581,307.774902 372.686401,309.241150
	C371.278046,322.214630 383.038879,329.268707 395.537140,327.942963
	C401.607910,327.299042 407.668335,326.526947 414.194550,326.540710
	C415.143646,326.563904 415.679138,326.638062 416.604004,326.860352
	C417.816101,327.044403 418.605804,327.231842 419.677734,327.825806
	C420.002899,328.072021 419.980682,328.000458 419.944519,328.010254
z"
        />
        <path
          fill="#182932"
          opacity="1.000000"
          stroke="none"
          d="
M413.676300,334.053131
	C414.304291,334.777100 414.633514,335.559937 415.006104,336.658691
	C413.733826,336.463287 411.003113,336.778473 413.676300,334.053131
z"
        />
        <path
          fill="#182932"
          opacity="1.000000"
          stroke="none"
          d="
M406.542297,334.101654
	C407.185577,334.205048 407.596680,334.357178 408.007782,334.509277
	C407.441406,334.620361 406.877258,334.747955 406.306274,334.824707
	C406.220306,334.836273 406.022797,334.610840 406.022003,334.494019
	C406.021240,334.380096 406.206482,334.264954 406.542297,334.101654
z"
        />
        <path
          fill="#5E9BAB"
          opacity="1.000000"
          stroke="none"
          d="
M371.260315,305.293396
	C370.274475,304.414642 369.540710,303.797394 368.518250,303.127991
	C362.093170,299.228882 355.475525,295.966980 351.418762,289.078705
	C351.251617,288.769409 351.124786,288.794220 351.136017,288.857849
	C350.816193,288.764130 350.539551,288.539703 350.194397,288.006409
	C350.071686,287.764496 350.039337,287.373138 349.962463,287.192444
	C349.879272,286.626770 349.872925,286.241760 349.867340,285.565796
	C349.871765,281.781067 352.660950,281.666931 354.943512,281.460602
	C357.625702,281.218170 359.971283,280.394714 362.554596,279.003235
	C363.204193,279.861115 363.459961,280.649323 363.849304,281.721405
	C363.683594,284.648102 364.771179,286.963654 365.863861,289.649323
	C367.222778,291.125854 368.149933,292.458649 369.002594,293.886475
	C371.366547,297.845001 374.559967,300.287323 379.536102,299.588684
	C383.356506,299.052338 386.947479,300.774109 391.016235,301.143555
	C392.226227,301.747345 392.474030,302.587769 392.827942,303.736176
	C393.049103,303.977753 392.997742,303.983398 393.022156,303.971954
	C393.967133,305.438477 394.335632,307.220093 395.805542,308.748199
	C396.050629,308.965759 396.044067,308.924194 396.062317,308.910217
	C396.040985,312.092651 395.288879,314.287537 391.519623,312.042572
	C388.620361,310.315765 385.518829,311.158295 382.007874,311.354614
	C376.635834,312.147400 374.238617,308.571564 371.260315,305.293396
z"
        />
        <path
          fill="#4C707C"
          opacity="1.000000"
          stroke="none"
          d="
M297.726501,294.461670
	C308.066895,295.731964 318.196472,297.319702 328.705994,299.073059
	C327.641571,302.050995 324.958832,300.943542 322.922760,300.819916
	C315.247498,300.353729 307.533997,299.974731 299.666626,297.845276
	C298.088531,297.146027 297.225983,296.297791 297.726501,294.461670
z"
        />
        <path
          fill="#85C1D1"
          opacity="1.000000"
          stroke="none"
          d="
M333.466492,252.979492
	C334.662018,264.090973 335.531036,275.213531 336.583191,286.703003
	C336.827911,288.449677 336.889526,289.829498 336.868561,291.616058
	C335.369263,289.554352 333.907410,292.666443 332.270752,291.589417
	C334.856262,288.469116 334.026733,285.011688 333.739014,281.809235
	C332.907532,272.552948 331.915527,263.312286 331.808807,253.549866
	C332.270172,253.057953 332.705078,253.024277 333.466492,252.979492
z"
        />
        <path
          fill="#28424F"
          opacity="1.000000"
          stroke="none"
          d="
M351.179993,289.151276
	C356.868225,293.298035 362.484863,297.826630 368.165527,302.715576
	C367.994659,303.523529 367.759705,303.971191 367.440186,304.660339
	C359.743073,302.101349 354.253479,297.056519 351.179993,289.151276
z"
        />
        <path
          fill="#1E4552"
          opacity="1.000000"
          stroke="none"
          d="
M297.625092,294.035645
	C298.315796,295.158783 298.694275,296.173309 299.174469,297.496521
	C295.759399,297.312408 294.302216,295.025665 294.004517,291.363434
	C295.680115,291.334015 295.542908,293.699860 297.625092,294.035645
z"
        />
        <path
          fill="#193A47"
          opacity="1.000000"
          stroke="none"
          d="
M350.171875,288.021973
	C350.378571,287.970581 350.685486,288.176666 351.069824,288.652100
	C350.855499,288.707458 350.563782,288.493439 350.171875,288.021973
z"
        />
        <path
          fill="#473033"
          opacity="1.000000"
          stroke="none"
          d="
M918.894531,514.711792
	C919.210754,516.772095 920.471497,517.957581 921.699707,518.479065
	C924.292114,519.579773 923.254944,521.264160 923.004150,522.991577
	C922.669189,525.298950 921.226807,526.006653 919.145081,525.984680
	C916.292297,525.954651 913.319031,525.672180 912.020569,529.569214
	C908.188232,533.005493 903.520691,531.876648 898.866516,532.759033
	C896.776428,532.935730 895.280945,532.479919 893.880493,530.925049
	C894.287292,525.801758 897.377869,522.278076 900.048340,518.651123
	C901.895752,516.141968 903.699890,513.688293 905.179504,510.590332
	C910.247192,510.642181 914.814514,511.486664 918.894531,514.711792
z"
        />
        <path
          fill="#1F0F0F"
          opacity="1.000000"
          stroke="none"
          d="
M928.537476,535.710571
	C930.144958,536.157043 931.824524,535.837219 931.703674,537.861267
	C931.621094,539.244995 930.505066,539.721008 929.304260,539.948181
	C925.757996,540.619019 922.207092,541.828613 918.662231,541.813904
	C913.093689,541.790833 907.970459,543.290527 902.418335,544.925171
	C900.176025,542.900452 901.395081,540.995972 902.578491,538.746521
	C908.131775,535.987244 913.828796,536.586121 919.342041,536.065063
	C922.262512,535.789062 925.233154,535.970459 928.537476,535.710571
z"
        />
        <path
          fill="#212226"
          opacity="1.000000"
          stroke="none"
          d="
M902.681763,538.775879
	C902.691406,540.716187 902.362305,542.446899 902.032837,544.553711
	C901.429871,545.430725 900.827393,545.931641 900.082031,546.691833
	C898.670837,549.057312 896.522583,549.134705 894.407715,549.523804
	C887.828552,550.734314 880.981018,550.402649 874.296936,553.173218
	C873.712646,553.522583 873.467285,553.645630 872.820068,553.871277
	C872.140320,553.982056 871.862488,553.990234 871.175903,553.910828
	C870.520874,553.691467 870.274658,553.559753 869.763611,553.102539
	C868.828979,551.036804 869.379028,549.503174 870.579407,547.731995
	C876.592468,545.432922 882.308167,543.368774 888.524658,543.494751
	C892.375671,543.572754 896.098938,543.088867 899.466431,540.239014
	C899.976440,539.461243 900.350708,539.093079 901.173401,538.662354
	C901.812500,538.538269 902.077759,538.552307 902.681763,538.775879
z"
        />
        <path
          fill="#5E6B74"
          opacity="1.000000"
          stroke="none"
          d="
M919.380615,515.118530
	C914.584778,513.763611 910.170837,512.430603 905.377747,511.057892
	C899.196228,511.456543 894.007751,509.657593 888.947754,506.308014
	C888.774719,505.016052 889.322632,504.446106 890.472290,503.882996
	C896.654053,503.417389 901.838318,505.975281 907.106018,507.636169
	C914.200562,509.873047 921.405029,511.703949 928.906006,514.045898
	C935.113586,515.933533 941.077820,515.447632 947.003357,515.513428
	C948.796997,515.533264 950.649170,515.188965 952.678040,516.425781
	C953.005066,516.928772 953.000183,517.125427 952.983765,517.616699
	C945.969116,518.529602 938.983032,519.625793 931.457275,518.549927
	C927.247986,517.341919 923.505249,516.241150 919.380615,515.118530
z"
        />
        <path
          fill="#241A19"
          opacity="1.000000"
          stroke="none"
          d="
M898.039795,532.318726
	C902.214478,530.565369 906.605042,529.991516 911.514648,529.947327
	C915.711426,529.987488 919.332214,528.400635 923.140869,528.985352
	C924.238220,529.153931 925.668396,528.960144 925.589722,530.636597
	C925.528259,531.944519 924.292786,531.829773 923.353699,532.018372
	C921.465820,532.397583 919.420105,532.440613 918.082153,534.634827
	C915.022461,537.570801 911.245239,536.283508 907.520996,537.062378
	C905.213501,537.019592 904.745605,533.954346 902.057983,534.772339
	C900.220337,534.568604 898.939331,534.016663 898.039795,532.318726
z"
        />
        <path
          fill="#3E1E1C"
          opacity="1.000000"
          stroke="none"
          d="
M873.291748,553.282104
	C876.003540,549.162842 880.554626,549.809204 884.546448,549.054199
	C889.361694,548.143372 894.258789,547.665100 899.529785,546.975403
	C900.419250,546.972656 900.899353,546.994141 901.740601,547.036499
	C901.468384,548.065369 900.835022,549.073425 900.136108,550.377930
	C899.942627,550.926514 899.814819,551.178589 899.352783,551.691162
	C892.343506,553.935486 885.577454,555.080505 878.171082,554.249084
	C876.305298,554.415955 874.863525,554.462219 873.291748,553.282104
z"
        />
        <path
          fill="#534A50"
          opacity="1.000000"
          stroke="none"
          d="
M905.674316,535.718018
	C909.711853,535.649719 913.417847,535.336853 917.561951,535.007141
	C921.575012,535.869873 925.227783,533.545837 928.851562,535.592407
	C920.574463,536.942871 912.228577,537.952942 903.451599,538.974243
	C903.020508,538.985535 902.620667,538.895203 902.416260,538.879761
	C901.727051,538.568115 901.423889,538.142151 901.303894,537.178711
	C901.405334,536.513306 901.503052,536.257202 901.897583,535.714417
	C903.252136,534.875977 904.301697,534.885803 905.674316,535.718018
z"
        />
        <path
          fill="#875E55"
          opacity="1.000000"
          stroke="none"
          d="
M898.958374,551.373108
	C899.271545,550.986816 899.442993,550.958740 899.842529,550.802612
	C900.492004,550.734863 900.913513,550.795166 901.654541,550.863892
	C902.166138,550.786743 902.358154,550.701172 902.780884,550.830811
	C903.664185,551.134827 904.316772,551.223816 905.293213,551.316467
	C905.986755,551.888489 906.356567,552.456787 906.870117,553.242065
	C907.069946,553.707764 907.126099,553.956543 906.892700,554.459351
	C905.643127,555.214294 904.617859,555.253479 903.187866,555.072998
	C901.078491,554.396362 899.333984,553.811523 898.958374,551.373108
z"
        />
        <path
          fill="#3E1E1C"
          opacity="1.000000"
          stroke="none"
          d="
M902.927795,550.892822
	C902.834045,551.044434 902.656555,551.042969 902.226562,550.956909
	C902.264038,550.828125 902.554077,550.783875 902.927795,550.892822
z"
        />
        <path
          fill="#342C32"
          opacity="1.000000"
          stroke="none"
          d="
M976.595825,510.124084
	C976.986389,511.265533 976.994080,512.307312 977.001465,513.741699
	C976.159180,515.919800 974.107544,516.104797 972.529297,517.474976
	C971.395264,518.047974 970.475098,517.954895 969.248657,518.093262
	C966.466980,517.622742 964.020691,517.113953 961.284790,516.383057
	C959.548035,514.450439 956.826660,516.699158 955.516174,514.057251
	C956.584045,512.174500 955.753418,511.147644 954.405273,509.881317
	C953.889343,508.170227 954.182922,506.986328 955.674561,505.894714
	C962.879883,506.610260 969.612610,508.006439 976.595825,510.124084
z"
        />
        <path
          fill="#1B292E"
          opacity="1.000000"
          stroke="none"
          d="
M955.639771,512.803467
	C956.463013,515.864441 959.226929,514.380615 960.775391,515.872925
	C958.547791,516.211853 956.100525,516.262695 953.331543,516.522827
	C945.013550,517.001099 937.008484,517.508057 928.669312,515.797241
	C928.629150,513.197571 930.787903,513.536560 932.441101,513.295349
	C934.714050,512.963745 937.048645,513.164246 939.760742,512.625427
	C945.246033,512.783020 950.282776,512.068787 955.639771,512.803467
z"
        />
        <path
          fill="#496A76"
          opacity="1.000000"
          stroke="none"
          d="
M972.180542,517.310791
	C972.437256,514.444885 974.815186,515.043518 976.657471,514.196655
	C981.379089,514.705383 985.189758,517.375854 989.722412,517.468262
	C985.832397,518.088013 981.879944,517.932861 977.866211,519.695435
	C977.533447,519.955017 977.517334,519.973328 977.506836,519.984070
	C975.751404,519.251648 973.635254,519.328674 972.180542,517.310791
z"
        />
        <path
          fill="#213642"
          opacity="1.000000"
          stroke="none"
          d="
M971.694580,516.982056
	C974.022583,517.160645 975.293762,518.713379 977.226746,519.750671
	C974.692871,520.312927 972.057373,519.659668 969.201904,518.370728
	C969.490173,517.255859 970.363892,517.012573 971.694580,516.982056
z"
        />
        <path
          fill="#483A3F"
          opacity="1.000000"
          stroke="none"
          d="
M940.335327,513.772339
	C936.485779,516.161560 932.495422,514.205139 928.556091,515.513611
	C916.163513,512.123718 904.071167,508.506134 891.593750,504.858948
	C888.296936,503.382843 884.034241,503.533691 884.283569,498.432190
	C891.540588,495.894745 898.637024,495.604675 905.806152,496.694122
	C907.137939,496.896484 908.408386,496.846100 910.130493,496.708923
	C915.300415,497.795746 920.047607,498.853485 924.675964,500.402557
	C926.074890,500.870758 927.523499,501.098816 928.702271,502.732697
	C931.981689,503.767456 934.983826,504.496521 938.380005,505.265930
	C940.415955,506.826324 942.826172,506.095428 944.581238,507.283936
	C945.121826,507.676178 945.298584,506.795563 944.970642,507.376007
	C944.952332,507.408325 944.857361,507.363892 944.682251,507.247864
	C943.635742,506.554382 942.325012,506.152802 942.055664,504.422363
	C944.925354,503.980042 947.670593,503.636078 950.685852,504.648438
	C951.626953,505.704468 952.511475,505.468964 953.617920,505.299927
	C954.456482,505.223724 955.018433,505.306183 955.808838,505.559479
	C955.696533,506.902710 955.355835,508.075073 955.014465,509.613312
	C950.924561,513.948303 945.657410,513.183594 940.335327,513.772339
z"
        />
        <path
          fill="#635B5F"
          opacity="1.000000"
          stroke="none"
          d="
M928.978760,502.692810
	C923.175415,501.530365 917.371399,500.022339 911.278931,498.277283
	C910.859192,497.843262 910.727844,497.646271 910.729248,497.120117
	C912.349365,495.403687 914.029968,497.325592 915.849976,496.335510
	C918.814514,495.762665 921.480286,495.371460 924.553955,494.938538
	C927.324707,494.907318 929.565430,495.434723 931.947754,496.753815
	C930.839417,498.857086 928.703857,498.261719 926.781555,498.716583
	C927.117188,499.865997 928.591919,499.915161 928.975220,501.315247
	C928.995239,501.886810 928.986694,502.117035 928.978760,502.692810
z"
        />
        <path
          fill="#403D45"
          opacity="1.000000"
          stroke="none"
          d="
M929.436646,501.727966
	C927.090820,500.027679 922.333740,500.666138 922.914062,498.200684
	C923.543030,495.527954 927.726501,497.528229 930.297485,497.035889
	C930.609558,496.976135 930.944092,497.033661 931.740601,497.029785
	C932.212891,497.022797 932.566650,497.000519 932.743835,496.998108
	C933.168823,497.220734 933.417297,497.444946 933.847168,497.842712
	C934.027588,498.017242 933.997009,498.005676 933.992493,497.989929
	C935.879822,499.510040 938.379333,499.500641 940.738403,500.757812
	C942.175781,501.814514 943.042664,502.626587 940.747742,502.968140
	C937.025391,503.522217 933.539246,501.789185 929.436646,501.727966
z"
        />
        <path
          fill="#5B555B"
          opacity="1.000000"
          stroke="none"
          d="
M941.839111,504.190613
	C944.614380,505.640350 947.040039,507.184113 949.465698,508.727905
	C945.596741,508.939148 941.907593,508.792877 938.864990,505.661194
	C939.679199,504.965698 940.584351,504.625183 941.839111,504.190613
z"
        />
        <path
          fill="#635B5F"
          opacity="1.000000"
          stroke="none"
          d="
M941.001953,500.686951
	C938.487488,500.906891 935.930664,500.941498 934.067139,498.328186
	C936.284302,497.768127 938.377930,498.547638 940.735718,499.540771
	C940.997314,499.975983 940.998535,500.179108 941.001953,500.686951
z"
        />
        <path
          fill="#342C32"
          opacity="1.000000"
          stroke="none"
          d="
M953.535095,505.091766
	C953.122559,506.054626 952.268677,506.117523 951.168396,505.137878
	C951.724121,504.939667 952.449890,504.990967 953.535095,505.091766
z"
        />
        <path
          fill="#C99469"
          opacity="1.000000"
          stroke="none"
          d="
M1039.623291,508.036163
	C1040.027466,508.474274 1040.024536,508.872192 1040.078613,509.560791
	C1039.817139,511.004944 1039.376099,512.112854 1038.087280,512.442261
	C1036.061035,512.960083 1034.145630,512.377319 1033.699097,510.337677
	C1033.255981,508.313293 1035.271851,508.196320 1036.734131,508.014893
	C1037.549683,507.913757 1038.387939,507.996216 1039.623291,508.036163
z"
        />
        <path
          fill="#8396A6"
          opacity="1.000000"
          stroke="none"
          d="
M829.689636,368.866943
	C830.007324,368.971588 830.506104,368.984467 830.755493,368.992249
	C834.623535,375.265350 841.510132,375.076111 847.251587,376.802216
	C849.855713,377.585205 852.624329,377.888824 855.222473,379.042938
	C858.945374,380.696686 860.918274,382.910431 859.026611,387.460663
	C858.461914,387.809204 857.906616,387.789886 857.129761,387.596191
	C856.837402,387.055328 856.626709,386.807709 856.121948,386.892578
	C849.350037,386.338531 842.963440,384.571533 836.664062,382.500122
	C830.572266,380.497009 827.012390,375.743469 823.807373,370.222778
	C822.740356,369.904663 822.419922,369.297028 822.207642,368.237762
	C822.164612,367.457397 822.113220,367.032837 822.002441,366.293121
	C821.942993,365.977966 822.143372,366.002411 822.236206,366.042114
	C825.404114,365.063934 826.908813,368.172333 829.689636,368.866943
z"
        />
        <path
          fill="#3A4D56"
          opacity="1.000000"
          stroke="none"
          d="
M808.489990,437.623840
	C802.915649,438.538391 798.041199,437.934082 792.286987,434.549103
	C801.229431,434.407990 808.936646,431.768341 816.424683,436.612030
	C813.923706,436.908722 811.422668,437.205414 808.489990,437.623840
z"
        />
        <path
          fill="#3A4D56"
          opacity="1.000000"
          stroke="none"
          d="
M846.575623,418.869263
	C848.108948,421.767883 846.172729,420.914459 844.997009,420.387909
	C841.389404,418.772339 837.636780,417.960541 833.267700,417.842590
	C832.566895,417.426544 832.457275,417.016602 832.739014,416.306824
	C837.547729,416.109314 841.777527,417.918091 846.575623,418.869263
z"
        />
        <path
          fill="#64777D"
          opacity="1.000000"
          stroke="none"
          d="
M832.651855,415.930603
	C832.941345,416.441376 832.932434,416.831512 832.877319,417.510071
	C831.392578,417.966034 829.836487,418.532654 828.893799,416.311096
	C829.945618,415.070831 831.131653,414.996124 832.651855,415.930603
z"
        />
        <path
          fill="#15191F"
          opacity="1.000000"
          stroke="none"
          d="
M821.975464,368.087555
	C822.660461,368.369232 823.104919,368.856506 823.559937,369.683777
	C822.634155,369.790985 821.072632,370.221375 821.975464,368.087555
z"
        />
        <path
          fill="#929EAC"
          opacity="1.000000"
          stroke="none"
          d="
M856.191406,387.218445
	C856.227661,386.807770 856.482605,386.447388 856.863831,387.227203
	C856.743896,387.391357 856.579712,387.360901 856.191406,387.218445
z"
        />
        <path
          fill="#DE8A57"
          opacity="1.000000"
          stroke="none"
          d="
M682.642944,346.170746
	C686.642212,346.180969 690.332642,346.128387 694.480347,346.095398
	C695.301880,346.440887 695.507812,346.849976 695.536987,347.659668
	C694.598877,349.428192 695.749084,349.453583 696.661499,349.779022
	C701.920593,351.655090 707.578247,351.530731 713.248047,353.198242
	C715.688232,355.457794 719.484253,353.369293 721.136841,356.820190
	C715.430115,359.710724 709.631470,361.397369 702.952759,360.654877
	C700.088745,360.182129 697.430847,361.084534 694.940308,359.090637
	C694.127991,355.683197 696.560791,355.701904 697.919495,355.361328
	C689.864502,354.507935 681.685059,352.190247 673.400146,349.064819
	C672.295898,345.578857 672.721130,345.243195 677.548096,345.721497
	C679.390503,346.105316 680.874939,345.905060 682.642944,346.170746
z"
        />
        <path
          fill="#DE8A57"
          opacity="1.000000"
          stroke="none"
          d="
M769.301941,435.939758
	C767.910156,437.618927 766.636841,438.869171 764.361389,439.117065
	C760.058105,441.883148 755.455322,442.440521 750.901428,442.595764
	C748.064209,442.692505 745.418640,443.186340 742.369263,444.158569
	C739.741089,444.844513 737.476868,444.853394 734.798462,444.699097
	C730.960754,443.660187 730.207703,449.545166 726.267212,447.692383
	C723.604309,446.440277 720.778870,445.795227 717.635742,446.124512
	C709.957642,446.928772 702.306458,448.026215 694.172729,448.506714
	C692.252197,448.763306 690.850830,448.498016 689.233154,447.388489
	C688.076904,445.154175 689.249084,444.352539 691.200928,443.677551
	C696.710693,443.837891 701.645264,442.538788 707.060425,442.042786
	C707.975037,441.714447 708.513428,441.598602 709.474609,441.547607
	C711.744263,441.467133 713.643677,441.748230 715.816772,440.824219
	C717.554077,440.377533 718.918274,440.255249 720.714478,440.414185
	C726.543518,438.803741 732.174438,440.048645 738.033813,439.053986
	C739.718872,438.457550 741.078369,438.536163 742.813782,438.412964
	C743.712891,438.209747 744.213745,438.043854 745.090942,437.754486
	C751.111816,436.551910 756.920410,436.677216 762.880127,435.135742
	C764.463257,434.501099 765.791809,434.513977 767.436035,434.178406
	C768.078186,434.253204 768.329041,434.324066 768.895142,434.624359
	C769.296204,435.098511 769.382019,435.343201 769.301941,435.939758
z"
        />
        <path
          fill="#C08056"
          opacity="1.000000"
          stroke="none"
          d="
M769.497803,436.257324
	C769.107849,436.016052 769.079712,435.740540 768.997314,435.053101
	C768.668884,433.181122 769.250977,431.935730 770.261963,431.045013
	C774.954346,426.910980 776.886902,421.596619 776.940857,415.526062
	C777.026611,405.882538 778.041748,396.294098 778.506592,386.242920
	C778.787415,382.931183 777.850769,379.906311 779.880615,376.911591
	C780.802307,376.404022 781.387390,376.361298 782.352417,376.761841
	C782.117371,391.434082 781.800415,405.867767 780.793274,420.253082
	C780.296692,427.347229 776.775146,433.140106 769.497803,436.257324
z"
        />
        <path
          fill="#C46B41"
          opacity="1.000000"
          stroke="none"
          d="
M723.740967,345.053650
	C732.312988,343.856293 740.620667,342.263641 749.094604,344.733795
	C755.585205,346.625793 762.314880,347.697876 769.134827,349.470764
	C767.407471,351.948822 764.986511,353.088501 761.731812,353.362122
	C760.779968,353.301697 760.258545,353.169189 759.422852,352.739502
	C758.195679,351.286224 757.098145,350.611969 755.494202,350.279816
	C747.779297,348.682190 740.275208,345.840607 732.110596,347.602356
	C729.619446,348.139893 726.827637,348.045990 724.075378,346.439880
	C723.706116,345.915283 723.629578,345.660492 723.740967,345.053650
z"
        />
        <path
          fill="#632B1D"
          opacity="1.000000"
          stroke="none"
          d="
M679.250366,363.223114
	C679.820740,359.058746 677.561279,356.048889 676.073792,352.447021
	C681.974060,350.083466 686.287109,353.026245 690.494751,357.170715
	C690.999512,358.947968 690.649658,360.239838 689.798584,361.821350
	C688.839722,362.983948 687.828674,363.308868 686.458435,363.745605
	C683.898254,363.517334 681.640930,364.626953 679.250366,363.223114
z"
        />
        <path
          fill="#AA4B20"
          opacity="1.000000"
          stroke="none"
          d="
M690.591919,358.560242
	C686.513000,354.707123 681.853699,352.959167 676.418823,352.065521
	C675.420410,350.821106 673.350952,351.152435 673.160034,349.100403
	C682.254211,350.218018 691.201965,352.202667 700.599915,355.270569
	C698.444580,356.140106 696.893738,356.765717 695.075012,357.606750
	C694.667603,358.024872 694.528015,358.227600 694.084229,358.642487
	C692.805725,359.256500 691.841980,359.200409 690.591919,358.560242
z"
        />
        <path
          fill="#CD7D4C"
          opacity="1.000000"
          stroke="none"
          d="
M782.808472,376.556793
	C782.294128,377.008148 781.869507,376.995972 781.126160,376.972351
	C778.422058,373.175659 779.521423,368.898438 779.253235,364.856415
	C778.766724,357.524139 777.441833,356.089996 770.270142,356.398224
	C768.128113,356.490295 765.863586,356.827972 764.531250,354.033630
	C765.440247,350.773315 768.624817,352.236847 770.499634,350.394135
	C779.511719,351.286713 783.285217,355.349731 783.197021,363.609802
	C783.152588,367.771545 783.001160,371.932159 782.808472,376.556793
z"
        />
        <path
          fill="#813920"
          opacity="1.000000"
          stroke="none"
          d="
M741.531189,442.964294
	C748.639221,440.596466 756.026001,440.532959 763.620117,439.177856
	C763.759583,439.654175 763.486877,440.098907 763.108276,440.783020
	C759.389343,444.936707 755.273865,447.192932 749.406738,445.017212
	C746.282776,445.539062 743.462891,446.554596 741.531189,442.964294
z"
        />
        <path
          fill="#705250"
          opacity="1.000000"
          stroke="none"
          d="
M678.679993,362.979553
	C681.129761,363.437897 683.155334,361.307953 685.640625,362.844788
	C686.632690,364.445862 685.746704,365.455444 684.658936,366.754089
	C681.022766,369.068542 677.153320,368.998993 673.102661,369.770264
	C672.246887,369.954987 671.750244,369.970886 670.896240,369.809753
	C670.343506,369.557617 670.145813,369.411896 669.932922,368.898071
	C670.992798,365.314331 674.458252,365.323914 676.831055,363.311646
	C677.483398,363.042389 677.918945,363.028290 678.679993,362.979553
z"
        />
        <path
          fill="#C46B41"
          opacity="1.000000"
          stroke="none"
          d="
M695.375732,348.430695
	C695.030640,347.661987 695.008911,347.219513 694.962402,346.445984
	C700.633057,345.269348 706.363586,344.986664 712.580566,345.080536
	C713.390442,345.670837 713.444641,346.251404 712.922363,347.217957
	C707.089600,349.417542 701.518860,350.872284 695.375732,348.430695
z"
        />
        <path
          fill="#E1B582"
          opacity="1.000000"
          stroke="none"
          d="
M770.528809,350.038696
	C770.904297,354.498718 767.297852,352.204285 765.062683,353.205994
	C763.786743,354.055450 762.816589,354.102539 761.463135,353.588440
	C761.011353,353.177307 760.883789,352.960876 761.026428,352.481750
	C764.086182,352.707764 765.739319,349.505707 768.929810,349.899719
	C769.656311,349.839874 769.978577,349.889771 770.528809,350.038696
z"
        />
        <path
          fill="#C08056"
          opacity="1.000000"
          stroke="none"
          d="
M713.281372,347.137146
	C713.057007,346.416534 713.042236,346.045410 713.036621,345.396118
	C716.371521,344.977814 719.697266,344.837738 723.475952,344.699615
	C723.949463,344.919952 723.970093,345.138336 724.007996,345.685242
	C721.403503,348.437347 718.204773,348.484192 714.547363,348.083923
	C713.939575,347.793304 713.715271,347.639984 713.281372,347.137146
z"
        />
        <path
          fill="#C46B41"
          opacity="1.000000"
          stroke="none"
          d="
M682.736450,345.961426
	C681.461914,347.322754 679.928406,347.166840 678.154480,346.113098
	C679.429688,344.681427 680.970947,345.043335 682.736450,345.961426
z"
        />
        <path
          fill="#64777D"
          opacity="1.000000"
          stroke="none"
          d="
M861.487183,453.243103
	C862.302551,455.200958 864.243835,454.904572 865.714722,455.855713
	C866.462769,456.767822 866.485840,457.591034 866.124634,458.835022
	C864.556458,461.492584 862.391357,461.591644 859.703247,461.161713
	C857.491150,459.793091 855.405884,459.585999 853.254395,459.639618
	C852.442383,459.659851 852.019409,459.720886 851.589905,460.765778
	C849.810608,465.094086 845.534607,466.191895 840.494507,463.483765
	C839.952942,460.594391 840.737732,458.418274 842.705811,456.232361
	C849.002625,455.019165 855.037598,454.110779 861.487183,453.243103
z"
        />
        <path
          fill="#5E6B74"
          opacity="1.000000"
          stroke="none"
          d="
M842.520996,455.909790
	C843.067810,458.217468 842.084778,460.223236 841.148560,462.607941
	C837.848145,465.205872 834.713440,463.818359 831.215088,462.627075
	C830.267944,461.561523 830.175537,460.700806 830.739807,459.401184
	C831.014221,458.634735 831.046326,458.249878 831.100159,457.578796
	C834.652954,455.888916 838.386963,456.072571 842.520996,455.909790
z"
        />
        <path
          fill="#73676A"
          opacity="1.000000"
          stroke="none"
          d="
M872.971924,458.501373
	C879.220398,455.532257 885.484436,453.214325 892.696167,454.306458
	C894.030762,454.132568 895.083862,454.212006 896.517212,454.400513
	C897.831055,455.583374 902.599182,455.028534 898.470520,458.257599
	C897.043640,459.373627 899.195190,460.676971 898.165649,462.154877
	C894.387390,464.182617 890.867493,462.820587 887.392761,462.156860
	C885.439331,461.783722 883.582825,461.195404 881.242432,462.069092
	C877.748230,462.223206 874.808655,461.875641 872.971924,458.501373
z"
        />
        <path
          fill="#4B555C"
          opacity="1.000000"
          stroke="none"
          d="
M912.612183,458.967285
	C913.269836,459.174591 913.521240,459.382629 913.864136,459.842072
	C914.084106,460.300140 914.179565,460.546783 914.062622,461.130066
	C912.336975,462.796387 910.245605,462.151642 908.205566,463.126099
	C906.174500,463.602997 904.535828,463.597260 902.505249,463.035706
	C901.601318,462.621094 901.156311,462.273346 900.531250,461.493530
	C899.549927,459.382111 899.793823,457.810883 901.562439,456.282898
	C905.622925,456.160706 908.790161,458.058563 912.612183,458.967285
z"
        />
        <path
          fill="#5B555B"
          opacity="1.000000"
          stroke="none"
          d="
M901.723083,455.856323
	C901.240967,457.389313 900.898865,458.751556 901.004639,460.578430
	C900.956177,461.406311 900.767822,461.809753 900.161560,462.330750
	C899.407532,462.585846 898.960510,462.554474 898.296753,462.133636
	C897.952148,461.580505 897.835510,461.282288 897.719421,460.983887
	C897.111938,459.422546 893.910767,460.591949 894.422791,458.258850
	C894.898804,456.089691 897.484192,457.548218 899.031860,456.645111
	C898.274475,455.989380 897.701111,455.492981 897.012573,454.753082
	C898.612061,454.108459 900.042175,454.905853 901.723083,455.856323
z"
        />
        <path
          fill="#484750"
          opacity="1.000000"
          stroke="none"
          d="
M938.630371,482.783386
	C938.766846,484.837677 937.907837,486.174408 936.101440,485.744110
	C926.400818,483.433228 917.213684,487.102814 907.371826,487.952698
	C902.639282,487.207245 898.459229,488.627228 894.189758,488.755066
	C892.066101,488.818665 889.264404,490.040253 888.786438,487.246277
	C888.280884,484.291290 891.238037,483.854706 893.610535,483.585358
	C894.103333,483.529419 894.603088,483.540283 895.485962,483.515869
	C899.120728,482.483215 902.611816,483.151947 905.465332,482.588531
	C905.237732,482.893372 904.350952,482.764435 903.151367,482.610657
	C902.166077,482.001862 901.787659,481.372345 901.753296,480.209717
	C902.361938,479.231720 903.036682,478.893890 904.166992,478.652710
	C906.635498,478.430420 908.962097,478.941895 910.655762,476.435028
	C911.397339,475.970734 911.861877,475.787445 912.724548,475.614777
	C920.208191,473.985931 927.230774,471.872894 933.799133,472.383911
	C932.757874,472.794342 931.473145,472.210693 930.899658,469.964386
	C933.176453,467.850403 935.494507,467.183289 938.480469,468.614380
	C939.169739,473.417816 938.996338,477.959229 938.630371,482.783386
z"
        />
        <path
          fill="#496A76"
          opacity="1.000000"
          stroke="none"
          d="
M913.614990,461.396423
	C913.957764,460.995758 913.967224,460.807281 913.982666,460.336151
	C920.850769,461.851898 927.712952,463.650330 934.780884,465.752991
	C936.062988,468.799103 933.811890,468.610931 932.349915,468.925690
	C928.103638,469.839905 923.749268,469.261658 919.006226,469.682526
	C914.830383,469.785278 911.194336,469.566956 907.687988,467.046814
	C906.986511,465.889709 906.880249,465.038361 907.203735,463.759338
	C908.892639,461.314941 911.387329,462.472107 913.614990,461.396423
z"
        />
        <path
          fill="#5B7D8C"
          opacity="1.000000"
          stroke="none"
          d="
M917.650757,468.448120
	C922.034119,468.064117 926.146606,468.102905 930.255127,467.992188
	C931.808472,467.950317 933.617737,468.552338 934.874146,466.435822
	C936.445374,466.469818 938.343994,466.078918 938.852661,468.523499
	C936.483093,469.212830 934.135925,469.564667 931.394409,469.959595
	C927.252563,472.848541 922.999084,473.367493 918.115479,472.475769
	C916.929749,471.162628 916.862732,470.017761 917.650757,468.448120
z"
        />
        <path
          fill="#543937"
          opacity="1.000000"
          stroke="none"
          d="
M778.008118,450.410156
	C780.051453,450.008453 782.087830,450.010529 784.560852,450.012146
	C784.997559,450.011719 785.004761,450.511658 785.013062,450.761536
	C786.559570,453.261536 789.154724,453.303467 791.408142,453.997650
	C794.110291,454.830139 795.598389,456.321228 794.730408,459.674683
	C791.946167,462.667877 788.606140,463.027374 785.409180,462.013916
	C781.154602,460.665131 778.429871,461.476929 777.056519,465.904510
	C776.715393,467.004456 775.888855,467.877411 774.564270,468.646912
	C773.528564,469.341461 772.146790,468.667297 772.106995,470.182251
	C773.082214,470.607239 773.736389,471.082703 774.790771,471.523865
	C776.070251,471.944153 776.864624,472.451752 777.271606,473.804352
	C777.061096,474.677582 776.753052,475.090393 775.995483,475.618286
	C770.876160,476.384003 767.114868,473.466919 762.726685,471.781006
	C762.483887,470.747070 762.459717,469.889191 763.021606,469.576843
	C768.585632,466.483673 769.420349,460.629791 771.687256,455.196655
	C776.042175,456.558929 778.483521,455.810638 778.008118,450.410156
z"
        />
        <path
          fill="#855144"
          opacity="1.000000"
          stroke="none"
          d="
M747.663452,448.715942
	C751.382751,449.657776 754.755676,450.313080 758.524658,450.993774
	C759.526428,454.045288 757.621460,455.853455 755.344482,457.818298
	C751.280640,458.667816 748.010803,456.522278 744.062500,456.292664
	C742.966248,456.041321 742.279907,455.755280 741.263306,455.315613
	C740.057983,454.771088 739.196350,454.345367 737.910522,453.984314
	C735.314392,452.358978 732.193115,452.204926 730.885559,449.052338
	C731.216187,448.478729 731.426392,448.294281 732.020508,447.919678
	C737.419128,447.030792 742.441467,446.168182 747.663452,448.715942
z"
        />
        <path
          fill="#77878D"
          opacity="1.000000"
          stroke="none"
          d="
M771.812256,454.648285
	C772.170105,461.767456 770.019348,467.274109 763.150391,470.492859
	C762.463135,471.598419 761.679138,471.745300 760.405151,471.548950
	C759.337402,470.878479 758.986328,470.153931 758.743042,468.946167
	C758.704529,468.042206 758.780457,467.542328 759.087036,466.731842
	C760.347351,464.576599 762.741089,466.204163 764.170837,464.425140
	C766.013123,462.814972 765.337891,461.148193 764.943604,459.189087
	C765.260193,456.472778 767.187622,453.785614 763.502319,451.816010
	C763.307922,451.005524 763.394470,450.549164 763.763916,449.791809
	C768.466919,447.964355 770.568298,450.337952 771.812256,454.648285
z"
        />
        <path
          fill="#87462F"
          opacity="1.000000"
          stroke="none"
          d="
M748.012939,448.653961
	C742.693176,448.989868 737.376648,448.977325 731.602051,448.941101
	C729.606995,446.140594 731.682068,444.948212 733.758667,443.401794
	C736.285156,442.977356 738.517517,442.778625 741.143799,442.541077
	C743.298340,445.017792 746.275208,443.317291 748.710693,444.752563
	C748.670532,446.112823 748.343323,447.209137 748.012939,448.653961
z"
        />
        <path
          fill="#A19796"
          opacity="1.000000"
          stroke="none"
          d="
M763.550659,449.545471
	C763.919922,449.987793 763.920593,450.506927 763.896423,451.413361
	C763.810425,452.219147 763.666443,452.606842 763.249634,453.212250
	C762.921082,453.615509 762.782410,453.770264 762.425049,454.143005
	C761.243591,455.302063 761.413696,456.702728 760.664185,458.079163
	C758.807617,459.965912 758.601379,462.222900 757.740417,464.579895
	C757.373413,465.047089 757.188477,465.206909 756.642090,465.461975
	C755.094299,465.534271 754.409119,464.829712 753.814880,463.455750
	C753.469299,462.021515 753.386292,460.960297 753.633423,459.493988
	C753.877136,458.583099 754.134277,458.118347 754.759705,457.413788
	C756.242432,455.288666 757.451233,453.455597 758.790344,451.320801
	C760.341125,450.553497 761.761597,450.087891 763.550659,449.545471
z"
        />
        <path
          fill="#2F252B"
          opacity="1.000000"
          stroke="none"
          d="
M777.246948,474.353760
	C776.450745,473.181427 775.604248,472.997498 774.379639,472.880707
	C772.686646,470.763336 774.416138,469.826050 775.648254,468.855865
	C780.932068,464.695343 786.879028,462.547394 793.656860,463.569733
	C797.490784,464.147980 801.219116,463.997345 805.288696,462.823578
	C807.398865,462.646057 808.796448,462.025604 809.733276,460.042542
	C816.064758,460.705139 821.826355,463.858246 828.298706,462.265961
	C823.282898,460.345886 817.818604,459.918091 812.987732,457.384430
	C818.774475,457.262115 824.296753,459.571838 830.533264,458.992065
	C830.965759,459.757965 830.949524,460.496277 830.949341,461.603912
	C831.120728,462.127258 831.276001,462.281311 831.576782,462.733124
	C831.945068,463.906097 832.048767,464.805511 832.337463,466.037018
	C832.681458,468.414673 832.845398,470.440826 832.537476,472.877258
	C832.492859,474.928589 833.027771,476.573822 832.547363,478.656128
	C832.555969,480.246979 832.496399,481.395813 831.705505,482.824463
	C827.658569,485.628754 823.279968,485.277100 819.141602,485.186218
	C816.870667,485.136353 814.662720,485.357544 812.008362,485.443909
	C811.039001,485.439880 810.487915,485.419403 809.547119,485.288391
	C808.921631,485.077576 808.686401,484.963562 808.158691,484.600739
	C806.703003,481.401825 809.008667,481.111786 810.847717,480.462799
	C812.977051,479.711395 815.495483,480.037415 817.338379,477.879456
	C817.505981,477.413910 817.553955,477.230560 817.666382,476.799988
	C817.833130,476.229492 817.773804,475.940033 817.326416,475.577454
	C816.348877,475.250854 815.585815,475.071472 814.534058,474.659271
	C808.736084,471.660980 803.144043,472.700836 797.168091,474.166595
	C792.787476,474.684296 788.636292,473.995544 784.432983,476.030670
	C781.639404,476.646118 779.467957,476.128052 777.246948,474.353760
z"
        />
        <path
          fill="#3B2C30"
          opacity="1.000000"
          stroke="none"
          d="
M809.808350,459.603363
	C811.188416,462.266144 810.749451,463.831604 807.359131,463.987366
	C805.410522,462.566803 803.573059,463.623322 801.859619,463.539185
	C798.924622,463.395050 795.979309,463.463684 793.960938,460.407867
	C794.425354,456.878265 793.033508,455.211060 790.006470,454.453278
	C788.210693,454.003723 786.084229,453.814850 785.084717,451.359344
	C790.179382,452.798523 795.337402,454.585693 800.756226,456.611084
	C802.939209,458.370209 805.009644,459.506592 807.817139,459.161011
	C808.586243,459.096771 809.106750,459.248047 809.808350,459.603363
z"
        />
        <path
          fill="#2F252B"
          opacity="1.000000"
          stroke="none"
          d="
M807.695190,458.912903
	C805.285889,460.976837 803.131348,460.041992 801.164062,457.185455
	C803.361450,456.775146 805.158020,458.402496 807.695190,458.912903
z"
        />
        <path
          fill="#8B9698"
          opacity="1.000000"
          stroke="none"
          d="
M872.588440,458.079163
	C875.017639,460.075012 877.711365,460.354431 880.647400,461.000275
	C885.198242,460.839020 889.236328,461.128998 892.585938,464.674622
	C892.767395,466.332245 892.263367,467.221405 890.620239,467.672913
	C887.092041,465.477356 883.738159,467.356293 880.563660,467.403931
	C874.676086,467.492279 868.667114,466.480804 862.504150,468.629181
	C861.301697,469.035370 860.476807,469.027557 859.271973,468.609863
	C858.466858,468.050659 858.133972,467.606903 857.773071,466.699524
	C857.866394,464.043976 854.249390,461.390839 858.707458,459.688599
	C861.008728,459.581635 863.251404,460.834808 864.907959,458.339874
	C865.707153,457.489502 866.472229,457.262268 867.703674,457.613159
	C869.443054,457.882690 870.809937,457.975311 872.588440,458.079163
z"
        />
        <path
          fill="#73676A"
          opacity="1.000000"
          stroke="none"
          d="
M867.996704,457.503784
	C867.308777,457.862701 866.541443,457.935364 865.389038,457.994568
	C865.268127,457.487946 865.532288,456.994781 865.908691,456.244354
	C866.851624,456.091339 867.569580,456.369202 867.996704,457.503784
z"
        />
        <path
          fill="#847876"
          opacity="1.000000"
          stroke="none"
          d="
M897.109497,484.637512
	C894.350830,485.167175 891.573364,484.484070 889.048035,487.200958
	C895.226685,488.765533 900.968567,484.384064 906.722900,487.648895
	C906.950439,487.923401 906.484070,487.965271 906.252319,487.998474
	C901.154297,491.923187 894.971680,490.630066 889.128784,492.276093
	C887.715515,492.409851 886.695129,492.466034 885.326416,492.217010
	C884.084534,491.869873 883.331726,491.345245 882.310669,490.648499
	C881.878235,490.339600 881.706604,490.216705 881.310181,489.878448
	C880.972900,489.493286 880.860474,489.323486 880.702148,488.856201
	C880.781738,487.966858 881.126831,487.498260 881.666992,486.822937
	C882.135925,486.121460 882.350220,485.609467 882.452271,484.733582
	C882.474976,481.769867 884.208740,479.758270 885.272217,477.128052
	C886.005798,476.307770 886.645874,475.965149 887.657104,475.537109
	C888.454468,475.243073 888.916687,475.097107 889.726440,474.849579
	C892.393677,474.090759 894.701843,473.379669 897.479858,473.090851
	C898.594666,473.121979 899.308350,473.317200 900.362915,473.677826
	C902.118896,474.758423 904.202881,474.463684 905.204773,476.594910
	C904.989563,477.395935 904.671387,477.760986 903.942810,478.199066
	C902.228394,478.756348 900.879211,479.269836 899.416809,479.475891
	C896.209534,479.927734 895.609924,481.464417 897.109497,484.637512
z"
        />
        <path
          fill="#543937"
          opacity="1.000000"
          stroke="none"
          d="
M887.724854,491.420013
	C893.605713,489.298676 899.453003,488.969421 905.598267,487.989380
	C907.475586,492.546814 902.236877,492.229828 901.072021,495.216003
	C897.922180,497.188477 894.530762,496.996063 890.685181,496.937683
	C887.399841,496.232208 886.580750,494.478638 887.724854,491.420013
z"
        />
        <path
          fill="#73676A"
          opacity="1.000000"
          stroke="none"
          d="
M889.586487,496.139984
	C893.373779,495.976166 896.763306,495.879059 900.604553,495.674438
	C903.085632,495.694672 905.114929,495.822388 907.579956,495.964050
	C908.900513,496.032501 909.816040,495.943848 910.771851,496.808838
	C910.955750,497.090027 910.981384,497.564514 910.985962,497.802399
	C902.197937,496.067352 893.553284,498.041809 884.439270,499.012451
	C884.157837,495.154419 886.691223,495.713745 889.586487,496.139984
z"
        />
        <path
          fill="#543937"
          opacity="1.000000"
          stroke="none"
          d="
M911.309448,497.070312
	C910.114502,496.887909 909.273315,496.685791 908.223816,496.230835
	C910.474060,493.767334 912.898926,495.951752 915.744263,496.093933
	C915.182312,499.097717 913.124817,496.583771 911.309448,497.070312
z"
        />
        <path
          fill="#635B5F"
          opacity="1.000000"
          stroke="none"
          d="
M933.901489,497.791809
	C933.751465,497.820984 933.475281,497.624725 933.060059,497.212097
	C933.205811,497.185944 933.490601,497.376129 933.901489,497.791809
z"
        />
        <path
          fill="#59B1BB"
          opacity="1.000000"
          stroke="none"
          d="
M190.190125,509.621094
	C189.457153,509.834534 189.039764,509.849945 188.309113,509.869263
	C185.863937,509.703644 183.657043,510.929352 181.343216,509.312012
	C182.464447,505.100586 186.189606,508.191650 188.686951,506.430786
	C189.874557,505.076385 191.087814,505.637543 192.557587,505.754272
	C194.476120,506.008362 196.028778,506.273651 197.801376,505.339600
	C199.307053,504.023438 200.752731,504.670685 202.478226,504.713440
	C211.254333,503.213623 219.740952,502.434235 228.733063,502.172302
	C229.471008,502.701050 229.552200,503.197357 229.233002,504.060394
	C228.210632,505.098450 227.228592,505.367249 225.798126,505.456848
	C224.413727,505.472504 223.424759,505.571228 222.130585,506.109619
	C221.262131,506.324646 220.753098,506.384003 219.849930,506.410034
	C214.712784,505.216064 210.163254,507.023560 205.161316,507.509277
	C204.400497,507.702484 203.993729,507.725433 203.281403,507.749939
	C198.768631,507.932098 194.662445,508.866211 190.190125,509.621094
z"
        />
        <path
          fill="#385661"
          opacity="1.000000"
          stroke="none"
          d="
M224.610413,486.882660
	C226.708420,486.978790 228.416687,486.976837 230.551834,486.972290
	C231.678055,486.398621 232.454376,486.227600 233.669617,486.747528
	C234.459579,486.979645 234.899628,486.983276 235.669708,486.989655
	C240.270645,487.369781 242.495667,491.563202 246.708649,492.792358
	C246.993958,493.003906 247.000000,493.000000 246.998108,493.003662
	C248.099594,494.388336 250.092316,494.306854 251.245026,495.512512
	C252.374969,496.694366 254.527832,497.900146 251.515320,499.769806
	C250.898834,499.758392 250.654785,499.704437 250.123230,499.409821
	C246.542892,497.335022 243.226013,495.550690 240.331390,493.080963
	C235.747101,489.169586 230.447433,488.370483 224.618881,489.297668
	C219.726135,490.075958 214.770798,490.460815 209.414337,490.965332
	C208.988647,490.485931 208.991791,490.061127 208.997162,489.317535
	C212.111893,489.179840 215.172241,488.872742 218.585205,488.000793
	C220.500885,486.551361 222.258606,486.222717 224.610413,486.882660
z"
        />
        <path
          fill="#3C7D8E"
          opacity="1.000000"
          stroke="none"
          d="
M249.594482,499.447571
	C250.022522,499.129486 250.209305,499.089813 250.681427,499.024872
	C252.042511,499.612946 252.764389,500.403931 252.129791,502.128235
	C247.480865,505.802063 242.142807,504.604034 236.806183,505.476624
	C233.997772,505.039215 231.480515,506.506989 229.098907,504.599915
	C229.063141,503.755280 229.086594,503.319458 229.149689,502.560638
	C235.985870,502.004639 242.740738,501.436890 249.594482,499.447571
z"
        />
        <path
          fill="#85EAF4"
          opacity="1.000000"
          stroke="none"
          d="
M189.560776,499.638306
	C193.645203,499.094177 197.304413,498.682404 200.963623,498.270660
	C201.000961,498.720123 201.038284,499.169556 201.075607,499.619019
	C194.680893,500.322662 188.453278,502.493073 181.812332,501.210175
	C184.019577,499.410614 186.735764,500.426056 189.560776,499.638306
z"
        />
        <path
          fill="#85EAF4"
          opacity="1.000000"
          stroke="none"
          d="
M207.358871,497.921539
	C210.266296,496.937439 212.865570,496.764252 215.550919,497.406738
	C212.390961,498.046631 209.338730,499.336914 206.012939,498.930725
	C204.916733,498.796814 204.973892,498.356842 206.000992,498.069214
	C206.314926,497.981323 206.658432,497.998962 207.358871,497.921539
z"
        />
        <path
          fill="#3C7D8E"
          opacity="1.000000"
          stroke="none"
          d="
M181.047943,509.302948
	C183.229752,509.217163 185.364380,509.339264 187.747421,509.667297
	C186.292831,511.721741 183.974060,510.619598 181.597046,511.204742
	C181.159637,511.059296 181.116226,510.835419 181.017639,510.273468
	C180.962448,509.935425 181.000732,509.510834 181.047943,509.302948
z"
        />
        <path
          fill="#85EAF4"
          opacity="1.000000"
          stroke="none"
          d="
M197.422791,502.002441
	C199.031998,500.319092 201.325607,501.140747 204.362350,501.198730
	C201.764481,502.495819 199.745239,501.755707 197.422791,502.002441
z"
        />
        <path
          fill="#85EAF4"
          opacity="1.000000"
          stroke="none"
          d="
M202.612000,504.635132
	C201.540512,504.937561 200.217194,505.000122 198.496857,505.082886
	C197.788681,504.832581 196.412476,504.556641 197.602676,504.207703
	C199.046158,503.784576 200.762115,504.291016 202.612000,504.635132
z"
        />
        <path
          fill="#85EAF4"
          opacity="1.000000"
          stroke="none"
          d="
M192.717010,505.709473
	C191.887711,506.001160 190.835236,506.054199 189.387390,506.148254
	C189.929138,504.827942 191.149307,504.846313 192.717010,505.709473
z"
        />
        <path
          fill="#85EAF4"
          opacity="1.000000"
          stroke="none"
          d="
M189.855896,503.356293
	C189.413757,503.632874 188.831268,503.740845 188.243881,503.800720
	C188.164566,503.808777 188.060623,503.574768 187.968094,503.452820
	C188.550064,503.359406 189.132034,503.265991 189.855896,503.356293
z"
        />
        <path
          fill="#85EAF4"
          opacity="1.000000"
          stroke="none"
          d="
M196.675201,501.996399
	C196.128555,502.888367 195.204010,503.411957 193.670227,502.206116
	C194.809189,502.115479 195.579803,502.054169 196.675201,501.996399
z"
        />
        <path
          fill="#85EAF4"
          opacity="1.000000"
          stroke="none"
          d="
M207.143280,500.622437
	C207.578064,500.352112 208.151550,500.247192 208.729752,500.190430
	C208.810547,500.182465 208.914566,500.410828 209.007828,500.529785
	C208.433029,500.620880 207.858246,500.712006 207.143280,500.622437
z"
        />
        <path
          fill="#1D3540"
          opacity="1.000000"
          stroke="none"
          d="
M236.057297,504.452820
	C241.116409,503.414185 246.124100,502.723358 251.859711,502.149048
	C253.310516,502.964020 253.680023,503.808929 253.307953,505.425537
	C237.440613,508.428528 221.717072,509.423523 206.283615,512.475464
	C204.123840,512.902527 202.005676,512.950989 199.407532,512.954956
	C201.064972,509.629700 204.751892,511.052399 207.632095,510.069885
	C210.605057,509.055786 214.036026,509.766968 216.927521,507.455566
	C217.440674,506.439575 218.057419,506.163910 219.133270,506.072021
	C220.591324,506.184601 221.734360,506.502075 222.988861,505.396362
	C223.757370,505.131805 224.220093,505.078308 225.038239,505.130615
	C229.742386,506.537903 234.173355,505.032349 238.550995,505.550201
	C237.674835,505.381134 236.650421,505.762238 236.057297,504.452820
z"
        />
        <path
          fill="#3C7D8E"
          opacity="1.000000"
          stroke="none"
          d="
M220.182388,506.703125
	C219.240631,506.975067 218.506668,506.994446 217.408493,507.054504
	C213.187271,506.317413 209.532043,508.460663 205.247955,507.779541
	C209.316284,505.094238 214.288116,505.078308 219.714127,505.148651
	C220.421234,505.598206 220.487076,506.009552 220.182388,506.703125
z"
        />
        <path
          fill="#3C7D8E"
          opacity="1.000000"
          stroke="none"
          d="
M190.313644,509.892914
	C193.576706,506.705475 198.048157,507.458221 202.660828,507.544556
	C199.104874,509.612244 194.911575,509.694427 190.313644,509.892914
z"
        />
        <path
          fill="#3C7D8E"
          opacity="1.000000"
          stroke="none"
          d="
M180.265930,513.931152
	C180.158661,513.703003 180.366150,513.447693 180.868225,513.137207
	C180.897400,513.297974 180.703369,513.572021 180.265930,513.931152
z"
        />
        <path
          fill="#33616C"
          opacity="1.000000"
          stroke="none"
          d="
M185.067535,520.421753
	C187.360489,520.052368 189.745529,520.034180 192.577850,520.037964
	C191.451447,523.904541 189.302048,524.099426 185.067535,520.421753
z"
        />
        <path
          fill="#364F5A"
          opacity="1.000000"
          stroke="none"
          d="
M282.318085,543.243774
	C278.330994,542.889954 274.565704,542.478577 272.154633,546.719604
	C269.667725,548.443909 267.850983,547.586731 266.119141,545.378174
	C266.437073,536.884583 265.287659,529.292664 260.744904,522.178772
	C257.780273,517.536194 254.353699,515.179810 248.811462,514.909424
	C239.940918,514.476624 231.442352,517.900024 222.579605,517.013855
	C220.938568,516.849731 219.292267,516.738220 217.324722,516.344666
	C216.865082,515.895691 216.729126,515.704529 216.791901,515.223083
	C218.030396,514.914490 219.070145,514.896240 220.499481,514.860107
	C224.442139,512.824097 228.645020,515.158325 232.603149,513.052856
	C234.041992,512.929688 235.077850,512.907166 236.501831,512.867065
	C238.521439,511.424805 240.740768,512.489197 242.778641,511.314850
	C244.784592,510.827576 246.493744,510.554047 248.571381,510.320068
	C249.960876,511.226715 251.010635,510.674805 252.372314,510.291260
	C253.587433,509.751678 254.522324,509.734955 255.827530,509.973022
	C263.037354,511.977234 270.034760,513.267944 276.790985,515.717773
	C282.144897,517.659180 286.086456,514.944702 288.544495,508.946014
	C290.867676,506.966064 293.426056,506.698181 296.326599,506.264862
	C301.019226,506.524231 304.520874,508.971130 308.651245,510.816010
	C309.666962,511.252838 310.295868,511.634094 311.223267,512.224426
	C319.060059,517.914062 327.420441,522.055908 335.274506,527.021545
	C339.060577,529.415283 342.654602,532.133057 347.202820,533.947693
	C350.465088,537.205505 355.257843,537.768982 357.725372,541.962524
	C357.219238,545.491577 355.149933,545.180054 352.964264,544.364563
	C349.284363,542.991638 345.774323,541.226013 341.847412,539.434570
	C336.185089,538.948853 330.956726,539.988892 325.679016,540.059448
	C324.246643,540.078613 323.269196,541.086243 322.685577,542.386536
	C321.071533,545.982422 317.991486,546.692688 314.454590,546.756409
	C306.719910,546.895752 298.872711,548.378723 291.834076,543.333740
	C288.999390,541.301941 285.751221,544.707520 282.318085,543.243774
z"
        />
        <path
          fill="#364F5A"
          opacity="1.000000"
          stroke="none"
          d="
M214.991165,515.855469
	C211.518036,518.993896 207.694534,519.634766 203.286987,517.391174
	C203.502274,516.799561 203.955093,516.467102 204.702225,516.104919
	C206.511444,515.851501 208.111191,516.314880 209.829834,515.326843
	C211.140625,515.115723 212.176224,515.090088 213.600357,515.066406
	C214.372726,515.112793 214.687729,515.283997 214.991165,515.855469
z"
        />
        <path
          fill="#1D3540"
          opacity="1.000000"
          stroke="none"
          d="
M232.734665,512.727417
	C229.848083,516.824890 225.537231,514.241333 221.351669,514.896362
	C221.948593,512.059631 224.390533,513.251343 226.299347,513.008057
	C228.342239,512.747620 230.407623,512.663635 232.734665,512.727417
z"
        />
        <path
          fill="#1D3540"
          opacity="1.000000"
          stroke="none"
          d="
M256.382202,510.841583
	C254.925735,511.036133 253.846497,511.032135 252.362564,511.034515
	C251.794327,510.867554 251.630783,510.694214 251.731796,510.280457
	C252.325531,508.936371 252.654694,507.832703 252.976593,506.379639
	C252.969315,506.030212 253.011490,505.988434 253.032562,505.967529
	C257.247131,505.901703 257.795197,506.596283 256.382202,510.841583
z"
        />
        <path
          fill="#1D3540"
          opacity="1.000000"
          stroke="none"
          d="
M242.665115,511.061646
	C242.073730,513.986572 239.625366,512.686584 237.304138,512.901001
	C237.933914,510.054871 240.357712,511.311798 242.665115,511.061646
z"
        />
        <path
          fill="#1D3540"
          opacity="1.000000"
          stroke="none"
          d="
M209.710114,515.093689
	C209.191956,517.628418 207.357895,517.097412 205.256393,516.308411
	C206.172455,514.625732 207.806213,515.097656 209.710114,515.093689
z"
        />
        <path
          fill="#394148"
          opacity="1.000000"
          stroke="none"
          d="
M251.611023,510.038574
	C251.990631,510.206665 251.984924,510.373291 251.968536,510.790405
	C250.949112,511.478668 249.838226,514.419983 248.964996,510.639709
	C249.701813,510.252106 250.463745,510.144623 251.611023,510.038574
z"
        />
        <path
          fill="#1D3540"
          opacity="1.000000"
          stroke="none"
          d="
M215.372711,516.101196
	C214.784821,515.917969 214.521530,515.706421 214.123566,515.281677
	C214.674957,514.072632 215.534119,513.854980 216.786926,514.712036
	C216.994232,515.124695 216.997833,515.316711 217.001251,515.797791
	C216.566467,516.082214 216.131897,516.077576 215.372711,516.101196
z"
        />
        <path
          fill="#635B5F"
          opacity="1.000000"
          stroke="none"
          d="
M249.045135,489.338501
	C254.473572,488.490204 259.837585,489.703674 265.706543,489.960205
	C271.902802,489.315674 287.503998,497.541962 290.256836,503.098755
	C287.454437,506.610779 284.204590,505.363739 280.648682,504.537384
	C270.904816,500.672546 261.925629,495.825195 251.402206,494.205261
	C250.839218,493.924103 250.632523,493.776031 250.212906,493.311554
	C249.694794,491.890686 249.389587,490.786285 249.045135,489.338501
z"
        />
        <path
          fill="#48505A"
          opacity="1.000000"
          stroke="none"
          d="
M249.624557,492.997437
	C250.000000,492.995117 250.496887,493.005066 250.745361,493.008240
	C256.791168,497.150360 263.994904,497.497070 270.370056,500.121948
	C273.207336,501.290100 276.137573,502.533112 279.628296,502.985046
	C283.112885,503.086578 286.176605,505.096375 289.699066,503.063416
	C291.274597,501.697784 292.608276,502.453674 294.236450,502.755859
	C294.983673,503.007874 295.391937,503.196686 296.336823,503.796906
	C297.025055,504.357117 297.165009,504.531372 297.416077,505.020691
	C297.515656,505.557495 297.504089,505.779297 297.323334,506.288055
	C295.132965,508.672424 292.394928,508.607910 289.402527,509.016449
	C285.082367,512.060974 280.927673,510.480621 277.138062,509.137817
	C269.138763,506.303436 260.756561,504.981995 252.335510,502.282593
	C251.970200,502.133270 251.942154,502.041260 251.946655,501.993286
	C251.723694,501.148651 251.496216,500.352051 251.117737,499.277527
	C253.438431,498.212616 251.223648,497.464386 250.847290,496.958130
	C249.851135,495.618286 247.400635,495.900269 247.047699,493.352081
	C247.747177,493.004822 248.498154,493.002258 249.624557,492.997437
z"
        />
        <path
          fill="#48505A"
          opacity="1.000000"
          stroke="none"
          d="
M246.833206,492.707764
	C242.283844,493.482086 240.075729,489.032013 236.169037,487.293274
	C241.275528,485.414917 243.143188,490.549438 246.833206,492.707764
z"
        />
        <path
          fill="#48505A"
          opacity="1.000000"
          stroke="none"
          d="
M224.911896,486.632355
	C223.510010,488.562439 221.564560,487.811646 219.398956,487.989410
	C220.077713,484.464203 222.028030,483.924774 224.911896,486.632355
z"
        />
        <path
          fill="#48505A"
          opacity="1.000000"
          stroke="none"
          d="
M233.967880,486.648224
	C233.259430,486.980896 232.499313,486.985809 231.358963,486.980164
	C231.132080,486.068604 230.738007,484.800934 232.011871,484.544525
	C233.223633,484.300568 233.598465,485.377472 233.967880,486.648224
z"
        />
        <path
          fill="#465962"
          opacity="1.000000"
          stroke="none"
          d="
M288.998199,473.448242
	C292.424408,474.985413 295.855255,476.945404 299.621582,478.993835
	C300.165436,479.179810 300.373840,479.277374 300.825684,479.619659
	C301.912811,481.127197 301.941437,482.399231 300.900757,483.916168
	C300.368652,484.372253 300.032898,484.561707 299.442017,484.666840
	C299.236359,484.686584 298.955811,484.574646 298.955811,484.574646
	C298.955811,484.574646 299.027954,484.887451 299.260864,484.952179
	C304.460419,486.703888 308.900391,489.360016 313.323730,492.733826
	C313.698853,493.537537 313.749817,494.041687 313.562134,494.936157
	C313.147339,495.774536 312.778168,496.156647 311.958588,496.622742
	C304.396057,495.857697 297.455719,493.680878 290.309174,492.514343
	C286.551361,491.900970 282.827728,491.295868 278.712189,492.606201
	C275.412018,492.584503 271.316437,492.284821 271.290039,489.241882
	C271.261871,485.994354 275.579651,487.500458 277.928253,486.734436
	C279.046509,486.369690 280.225433,486.573944 281.676086,486.212646
	C282.918091,482.922791 285.523743,484.471832 287.402374,483.986755
	C289.707153,483.391632 293.859009,483.797943 289.798920,479.580414
	C288.285187,478.007904 289.202332,475.792328 288.998199,473.448242
z"
        />
        <path
          fill="#8B9698"
          opacity="1.000000"
          stroke="none"
          d="
M281.525391,486.008789
	C279.032928,488.298492 274.968445,487.186218 271.625244,489.267242
	C273.172699,491.170349 275.470093,491.085693 277.580963,492.108765
	C284.944153,493.452606 290.526031,497.318298 295.807770,502.322144
	C295.990601,503.232758 295.933472,503.752869 295.559753,504.605957
	C293.860565,504.318451 292.356293,503.684631 290.451904,502.967407
	C282.751770,497.796143 275.517151,492.585236 266.403015,490.193512
	C266.070587,487.743622 267.583374,487.447174 269.296661,486.934570
	C273.187134,485.770508 277.136902,486.181946 281.525391,486.008789
z"
        />
        <path
          fill="#4E5963"
          opacity="1.000000"
          stroke="none"
          d="
M265.615906,544.994507
	C267.755859,545.637451 269.520416,546.284607 271.635132,546.982727
	C272.428436,549.068665 273.281799,551.036194 273.003113,553.590820
	C273.008118,553.990784 272.991516,554.003174 272.982605,554.006958
	C270.571442,554.042786 269.270355,552.239075 267.838928,550.783386
	C266.317566,549.236206 264.719208,547.926636 262.263702,547.241943
	C261.962128,547.056458 261.922913,547.075623 261.901001,547.082520
	C262.254211,545.194153 263.644958,544.931763 265.615906,544.994507
z"
        />
        <path
          fill="#7DA7B8"
          opacity="1.000000"
          stroke="none"
          d="
M279.003174,590.664429
	C279.002625,592.387817 278.997955,593.780579 278.987183,595.591248
	C278.528778,595.816162 277.720001,595.683777 277.675385,595.421448
	C275.875336,584.836426 266.857819,580.120300 260.011658,573.663391
	C254.269913,568.248108 248.153915,563.315735 243.587997,556.631897
	C240.098602,551.523926 235.330200,547.289795 231.067703,542.335938
	C231.445145,542.002930 231.885605,542.000916 232.656204,541.996826
	C233.843063,543.086548 235.068390,542.986816 236.617706,543.008057
	C237.117798,543.310120 237.316757,543.563660 237.783920,543.895874
	C247.453629,553.703857 255.826797,564.411072 266.352417,573.110352
	C271.390686,577.274353 276.039062,582.111633 278.741882,588.746704
	C278.983032,589.451904 278.991028,589.892883 279.003174,590.664429
z"
        />
        <path
          fill="#759EB0"
          opacity="1.000000"
          stroke="none"
          d="
M278.973816,588.636414
	C274.775574,585.988892 273.255554,580.764282 269.147675,577.523499
	C257.831421,568.595764 248.376526,557.746582 238.823303,547.029358
	C238.326645,546.472107 238.272522,545.520386 238.002380,544.386963
	C244.364563,547.410828 249.179733,552.636780 254.128510,557.704529
	C255.084457,558.683472 255.551437,560.626709 257.803772,559.184875
	C258.323669,559.075378 258.583191,559.275146 258.925629,559.745605
	C258.939972,561.316467 259.041077,562.689392 260.163849,563.587585
	C265.328125,567.718811 268.303467,573.801270 273.355133,578.096558
	C276.339844,580.634338 277.865265,584.443787 278.973816,588.636414
z"
        />
        <path
          fill="#246876"
          opacity="1.000000"
          stroke="none"
          d="
M262.927917,552.646118
	C261.793060,553.230591 260.718750,552.806641 259.359436,552.079712
	C258.986115,551.499268 258.933319,551.061035 258.956635,550.299683
	C260.565338,550.334412 261.809937,551.156799 262.927917,552.646118
z"
        />
        <path
          fill="#246876"
          opacity="1.000000"
          stroke="none"
          d="
M257.360046,550.131653
	C256.740570,549.592285 256.457550,549.062866 256.101929,548.243103
	C257.367065,547.585510 258.107391,548.453796 258.935547,549.641724
	C258.579437,550.024841 258.137970,550.083191 257.360046,550.131653
z"
        />
        <path
          fill="#33616C"
          opacity="1.000000"
          stroke="none"
          d="
M262.070984,547.377563
	C267.366791,546.234619 268.888794,551.521362 272.684021,553.848999
	C272.564636,554.314026 272.155579,554.617432 271.486603,554.947815
	C268.211060,552.549438 265.195465,550.124084 262.070984,547.377563
z"
        />
        <path
          fill="#246876"
          opacity="1.000000"
          stroke="none"
          d="
M252.933258,544.643127
	C251.113098,545.234314 249.688065,544.648621 249.052994,542.319580
	C250.898041,541.809570 251.720413,543.301575 252.933258,544.643127
z"
        />
        <path
          fill="#246876"
          opacity="1.000000"
          stroke="none"
          d="
M256.064911,547.633423
	C255.100342,547.925659 254.376312,547.569885 254.051086,546.283508
	C255.049118,545.900757 255.678909,546.432617 256.064911,547.633423
z"
        />
        <path
          fill="#246876"
          opacity="1.000000"
          stroke="none"
          d="
M263.990631,553.696655
	C263.745148,553.794373 263.461884,553.627808 263.105530,553.217529
	C263.339233,553.126709 263.646027,553.279480 263.990631,553.696655
z"
        />
        <path
          fill="#246876"
          opacity="1.000000"
          stroke="none"
          d="
M253.998840,545.704712
	C253.745483,545.805420 253.451859,545.637634 253.092316,545.216858
	C253.337128,545.121277 253.647858,545.278748 253.998840,545.704712
z"
        />
        <path
          fill="#557E8B"
          opacity="1.000000"
          stroke="none"
          d="
M186.982544,667.527222
	C194.659912,667.101196 202.381866,667.070557 210.577316,667.070679
	C211.000290,668.769958 210.442902,669.971008 208.497299,669.968079
	C202.111893,669.958374 195.726486,669.966858 189.341110,669.953918
	C188.003922,669.951172 187.209549,669.270447 186.982544,667.527222
z"
        />
        <path
          fill="#557E8B"
          opacity="1.000000"
          stroke="none"
          d="
M213.089844,667.443542
	C213.139130,667.059692 213.310883,667.041992 213.741302,667.012207
	C216.705246,667.002502 219.410507,667.004944 222.567810,667.015869
	C220.011597,670.544250 216.630569,669.409546 213.089844,667.443542
z"
        />
        <path
          fill="#557E8B"
          opacity="1.000000"
          stroke="none"
          d="
M182.654022,667.007568
	C183.167648,667.013672 183.342636,667.022461 183.779022,667.062134
	C183.414474,669.040833 182.533295,669.708984 181.036713,667.364807
	C181.411697,667.038635 181.863541,667.024475 182.654022,667.007568
z"
        />
        <path
          fill="#617179"
          opacity="1.000000"
          stroke="none"
          d="
M408.931580,686.352234
	C411.142548,685.822327 413.338257,686.413269 415.713074,685.264160
	C421.488525,685.002930 426.973541,685.031311 432.458160,684.993530
	C435.818054,684.970398 436.953583,685.911438 436.439728,689.794800
	C435.736786,695.106750 435.005981,699.888733 429.868439,702.835693
	C428.963745,703.354553 428.516846,704.687195 427.882843,705.659851
	C423.306671,712.680176 417.248688,715.180481 409.044861,713.542175
	C407.271973,713.188110 405.434265,713.155090 403.625336,712.985535
	C399.064148,712.557861 396.762207,708.824097 394.768341,704.997375
	C390.813019,697.406128 394.625916,694.829895 400.485565,691.613770
	C403.252258,690.095276 406.953857,689.761902 408.931580,686.352234
z"
        />
        <path
          fill="#87655F"
          opacity="1.000000"
          stroke="none"
          d="
M437.601807,656.471069
	C437.894257,657.291321 437.967682,657.744690 438.066315,658.539917
	C437.581512,662.967468 435.893097,664.420532 431.215393,664.319031
	C423.510742,664.151794 415.763733,665.399170 408.051971,666.214844
	C394.386261,667.660278 380.808380,668.221436 367.591858,663.359070
	C365.418274,662.559448 363.217865,661.751465 360.404846,661.983887
	C356.909729,661.452209 354.282532,660.225281 352.710571,656.924316
	C352.967590,655.719788 353.593018,655.264771 354.759460,654.860657
	C355.710846,654.695862 356.262451,654.699890 357.218903,654.866089
	C365.420166,655.646240 372.747589,658.080688 380.217041,660.297668
	C389.604065,663.083862 399.506195,662.876221 409.158722,661.185913
	C414.734436,660.209473 420.361847,660.011597 425.927032,659.151062
	C428.669617,658.726929 431.567780,658.835632 434.019409,656.336182
	C435.346527,655.223145 436.377014,655.250366 437.601807,656.471069
z"
        />
        <path
          fill="#875E55"
          opacity="1.000000"
          stroke="none"
          d="
M352.702911,656.696899
	C355.219116,658.465515 357.440308,659.933533 359.825043,661.689331
	C359.435181,662.497192 358.881744,663.017212 358.162598,663.772217
	C354.923248,663.380310 351.849609,662.753418 348.384644,662.082275
	C340.144836,661.364502 332.034088,661.681702 324.532837,659.696167
	C318.781097,658.173706 311.295746,657.470520 309.030975,649.342163
	C309.424316,648.982544 309.874298,648.977295 310.661163,648.987244
	C315.370026,649.873047 318.672791,653.314880 323.320831,653.760620
	C330.308167,654.430725 337.217163,656.189758 344.710419,654.788818
	C347.425934,655.937744 350.172699,654.974792 352.702911,656.696899
z"
        />
        <path
          fill="#705250"
          opacity="1.000000"
          stroke="none"
          d="
M520.536499,636.593018
	C523.420898,636.596252 526.660706,637.316467 526.379333,640.037415
	C526.077271,642.959534 523.146118,644.945984 520.185669,645.679016
	C516.717712,646.537720 513.125366,646.893860 509.292114,647.727661
	C504.917419,647.802368 501.003479,649.602234 496.443146,649.003174
	C492.638153,649.008362 489.278717,649.009399 485.461487,649.011963
	C484.858215,648.792725 484.712769,648.571899 484.569946,647.954712
	C485.628937,645.277344 487.911865,644.972839 489.927887,644.415405
	C497.296295,642.377808 504.823059,641.416870 512.468506,641.178406
	C515.526123,641.083008 519.002319,641.329102 520.536499,636.593018
z"
        />
        <path
          fill="#79675E"
          opacity="1.000000"
          stroke="none"
          d="
M484.708160,647.681030
	C484.995697,648.171021 484.997162,648.339478 485.001129,648.760742
	C476.398712,651.918884 467.488556,654.019958 459.564331,658.717590
	C458.116638,659.575745 456.552185,660.130066 454.432129,660.010010
	C453.134216,658.032227 451.340485,657.213074 449.220856,656.291138
	C448.841125,653.528076 450.948547,653.981323 452.372009,653.394775
	C456.950409,651.508179 461.951324,650.783142 466.652100,648.066650
	C472.736603,645.114563 478.560181,645.541443 484.708160,647.681030
z"
        />
        <path
          fill="#A67A5E"
          opacity="1.000000"
          stroke="none"
          d="
M438.075134,656.695923
	C437.071106,656.561829 436.321411,656.285645 435.265106,655.996643
	C433.465393,657.555603 431.483917,657.969849 429.477753,658.043457
	C419.800629,658.398438 410.457397,660.997253 400.909363,662.208679
	C394.688843,662.997925 388.810547,661.418518 382.819305,660.073120
	C374.593994,658.226196 366.470459,655.520630 357.425171,655.970215
	C356.556854,655.960999 356.117188,655.965210 355.347809,655.973511
	C354.386688,655.408630 353.501495,655.551331 352.569824,654.847046
	C352.072601,653.688843 352.119293,652.882629 352.688660,651.745972
	C361.215851,647.507568 369.807465,645.876831 378.131195,650.553284
	C388.693604,656.487549 399.556152,656.335449 410.885651,654.015991
	C417.996246,652.560242 425.241516,651.784302 432.337708,650.211975
	C434.732635,649.681335 436.596436,650.172363 437.909729,652.819946
	C438.491547,653.604309 438.987335,653.977112 439.784515,654.709290
	C439.500397,655.563721 438.914917,656.058777 438.075134,656.695923
z"
        />
        <path
          fill="#F6D1A1"
          opacity="1.000000"
          stroke="none"
          d="
M438.240051,653.193176
	C436.605316,651.522583 434.985321,651.071655 432.719635,651.512085
	C423.444977,653.314636 414.130463,654.913452 404.825775,656.559814
	C397.311005,657.889526 389.630035,658.130005 382.983612,654.178894
	C373.434845,648.502441 363.810852,648.792603 353.384613,651.864197
	C343.525208,656.475403 333.860413,656.587402 323.909363,652.744324
	C323.813080,651.761230 323.857178,651.216003 323.924255,650.260010
	C324.396790,649.906555 324.846375,649.963867 325.633972,650.057556
	C335.764648,651.943909 345.109985,648.986023 354.414520,646.943604
	C364.882629,644.645813 374.707184,644.835693 384.118652,650.361084
	C385.106903,650.941284 386.218292,651.360046 387.788849,651.657837
	C395.691010,654.980164 403.131805,653.060669 410.600647,651.595703
	C426.478485,648.481384 442.307495,645.099121 458.732178,642.665466
	C460.738953,642.290527 462.248413,641.585144 464.297729,641.520752
	C466.555420,641.693176 468.287964,641.136536 470.395111,640.473511
	C473.041046,639.637573 475.381653,639.372314 478.147522,639.331848
	C479.351349,639.321655 480.100189,639.062134 481.225586,638.612671
	C492.651703,636.212402 503.740997,634.380981 515.472412,634.425110
	C516.477173,634.584717 516.979675,634.831299 517.602356,635.623718
	C518.347900,639.224487 516.408325,640.331421 513.758545,640.196960
	C501.629669,639.581726 490.130676,643.356079 478.350769,645.139648
	C474.278534,645.756165 470.180237,646.344727 465.786438,647.523254
	C464.825317,647.632751 464.296021,647.774170 463.393768,648.098877
	C462.210541,648.374512 461.406067,648.412476 460.207581,648.455017
	C453.074646,649.936462 446.134033,650.380005 439.227234,652.708252
	C438.942688,652.968872 438.484406,653.154907 438.240051,653.193176
z"
        />
        <path
          fill="#A67A5E"
          opacity="1.000000"
          stroke="none"
          d="
M439.345032,653.020020
	C438.863403,650.838196 440.093384,650.225830 442.010010,649.921204
	C447.731445,649.011902 453.423615,647.918213 459.546112,646.918091
	C460.560608,647.735535 462.084015,647.336121 462.073547,649.055420
	C456.172791,652.190308 450.577881,655.653809 443.362976,653.169373
	C441.902405,653.106812 440.824860,653.088989 439.345032,653.020020
z"
        />
        <path
          fill="#916E67"
          opacity="1.000000"
          stroke="none"
          d="
M443.157043,653.412354
	C449.419220,653.353516 455.250427,650.900330 461.599609,648.993774
	C463.314880,647.858215 464.757111,647.553467 466.704285,648.684204
	C462.773712,652.959290 456.888153,652.816467 451.899994,654.955994
	C451.163330,655.271973 450.319855,655.338989 449.260162,655.789246
	C447.016846,655.489807 444.879700,655.305237 443.157043,653.412354
z"
        />
        <path
          fill="#A5654C"
          opacity="1.000000"
          stroke="none"
          d="
M323.416504,652.218018
	C331.875946,655.861267 340.067505,653.770935 348.241486,652.662048
	C349.557312,652.483582 350.860992,652.215271 352.589264,651.984741
	C353.009216,652.728577 353.010193,653.476074 353.014069,654.597168
	C350.856842,656.837646 348.176270,655.562134 345.399017,656.079834
	C336.410187,657.978760 328.023529,655.924133 319.633911,654.616028
	C316.337189,654.101929 313.540253,652.204651 311.171509,649.296143
	C311.025116,648.514771 311.052155,648.027161 311.130737,647.175659
	C315.142883,648.584412 319.103485,650.356934 323.416504,652.218018
z"
        />
        <path
          fill="#1D3540"
          opacity="1.000000"
          stroke="none"
          d="
M361.228363,450.067474
	C361.059235,448.173767 360.444153,447.204987 358.876343,446.809021
	C356.787811,446.281525 354.755859,445.521240 352.658478,445.040161
	C350.588776,444.565399 347.543243,444.560547 347.761536,442.019867
	C347.928772,440.073303 350.708160,439.629700 353.021057,439.425049
	C367.841156,438.113617 382.617981,435.869629 397.457611,435.366791
	C408.758942,434.983826 419.901215,432.201355 431.178741,433.112640
	C441.408813,433.939270 451.347626,431.760895 461.280090,430.568817
	C470.913361,429.412628 480.572998,428.982574 490.188660,427.921936
	C491.496918,427.777649 492.773041,427.341583 494.453705,427.029907
	C496.688782,428.480591 496.441254,430.376282 495.711853,432.729095
	C494.222778,434.419189 492.578583,434.658417 490.909973,434.808838
	C474.385712,436.298523 457.811432,436.992859 441.255066,437.983124
	C429.034210,438.714111 416.789429,439.350922 404.267731,441.680969
	C405.530731,443.428070 407.089935,442.973206 408.362488,442.998322
	C424.794067,443.322906 441.203522,444.579895 457.656067,444.115417
	C460.573273,444.033051 463.712799,443.588470 466.293671,446.442017
	C464.714325,452.399078 460.043518,450.404022 455.869690,450.736206
	C444.226959,450.556854 433.038849,451.162018 421.844574,451.457977
	C420.081573,451.504578 418.239075,451.908386 416.269104,453.015381
	C415.493439,453.559601 415.005341,453.762726 414.104095,453.913422
	C410.306488,454.168304 407.937958,450.350677 403.873352,451.332642
	C404.860504,453.508209 407.653381,453.907715 407.778717,456.618256
	C407.633057,457.448700 407.400543,457.865479 406.779297,458.443542
	C405.000854,459.310486 403.601807,458.763763 401.843842,458.288971
	C401.274139,458.109314 401.053101,458.020264 400.520020,457.762756
	C396.503693,455.310059 392.886475,452.833038 388.039856,452.190674
	C387.425568,452.042480 387.178680,451.968201 386.565460,451.747131
	C383.516235,450.522736 380.870483,450.561340 377.974091,451.022308
	C372.480011,451.896606 366.882477,452.382019 361.228363,450.067474
z"
        />
        <path
          fill="#314651"
          opacity="1.000000"
          stroke="none"
          d="
M319.667053,452.779053
	C322.235016,452.753937 324.692047,453.769409 326.772095,451.445557
	C328.048096,449.482819 329.864166,449.477478 331.863159,449.064056
	C333.797821,448.927094 335.427979,448.955750 336.765564,447.253357
	C341.029419,447.814575 344.961426,449.153809 349.567383,449.011017
	C350.528168,449.544830 351.068115,450.066650 351.801514,450.793304
	C352.442688,450.991821 352.886230,450.989777 353.662415,450.986633
	C355.085175,450.290100 356.384369,450.783966 357.759094,450.176758
	C358.172180,449.991943 358.349304,449.995667 358.791809,450.013123
	C363.625763,452.670532 368.717133,451.591095 373.341949,451.086426
	C378.067963,450.570740 382.666107,449.751587 387.721313,450.790527
	C388.040558,451.000702 387.990295,451.016510 387.985748,450.990570
	C392.166840,452.749084 396.227295,454.811035 400.722137,456.776489
	C401.022827,456.976349 400.992401,457.005280 400.986511,456.985107
	C402.380035,458.282440 405.166962,458.069183 405.020752,461.154297
	C403.024475,462.610870 401.248108,462.186523 399.093018,461.414124
	C395.470093,460.605499 392.209351,459.953430 389.080627,458.790192
	C384.564514,457.111176 379.966095,457.423615 375.294952,458.021118
	C372.847839,458.334137 370.313049,458.340973 367.459167,457.718445
	C359.508453,457.677490 352.429199,454.345093 344.466797,454.491119
	C342.862244,454.475586 341.699310,454.965332 340.136047,454.909485
	C338.958832,454.793579 338.130188,454.563873 336.962036,454.712524
	C336.230774,454.738220 335.829926,454.644928 335.205566,454.269775
	C334.216919,454.072723 333.472229,454.069702 332.355347,454.076630
	C330.433563,453.867371 329.118561,454.833191 327.315613,454.945007
	C323.949280,454.623322 320.902252,454.303772 317.464752,453.967621
	C316.840454,451.915894 318.396057,452.744720 319.667053,452.779053
z"
        />
        <path
          fill="#478D9B"
          opacity="1.000000"
          stroke="none"
          d="
M459.572083,389.501953
	C462.738892,390.165009 465.558685,390.531128 468.272064,391.281799
	C472.176666,392.362061 475.667084,393.633209 476.171234,398.852295
	C476.512848,402.388489 474.964417,403.242493 472.416443,402.996643
	C465.132690,402.293915 457.975159,400.637878 451.226044,397.881012
	C447.375397,396.308197 449.246246,392.219208 449.528320,389.412964
	C449.855438,386.158295 452.564972,388.105896 454.305603,388.356171
	C455.944641,388.591888 457.554718,389.028564 459.572083,389.501953
z"
        />
        <path
          fill="#496A76"
          opacity="1.000000"
          stroke="none"
          d="
M494.590881,433.390015
	C494.895660,431.312653 494.914307,429.593445 494.888184,427.446716
	C494.865509,422.312805 494.887634,417.606415 494.846466,412.433960
	C494.564972,401.413513 494.302063,390.859711 494.153931,380.304291
	C494.105225,376.833069 492.416626,375.505127 489.150848,375.927032
	C487.029816,376.201050 484.927521,376.620087 482.402283,376.976257
	C481.447723,376.473236 481.294037,375.857697 481.656128,374.871490
	C481.847229,374.593597 481.834961,374.213104 481.902679,374.035217
	C482.682648,373.342163 483.463867,373.177856 484.683289,373.597290
	C488.071777,373.241302 491.096741,372.692139 494.386169,374.355957
	C497.380981,375.760406 497.643829,378.269653 497.787598,380.696106
	C498.018036,384.584564 498.177979,388.487518 497.642822,392.848846
	C498.355164,406.070984 498.012177,418.826813 497.750061,432.044617
	C497.863739,435.573334 498.159027,438.638580 497.683411,442.156372
	C497.614532,444.104431 497.994110,445.584991 497.816467,447.539093
	C497.145294,450.598450 498.394226,453.380798 496.832214,456.313904
	C496.360046,456.882904 496.124390,457.068481 495.439240,457.235962
	C493.952301,456.429535 493.605194,455.312103 493.494598,453.641296
	C493.456207,452.036957 493.131897,450.880981 493.432556,449.274597
	C493.457520,447.381592 493.426727,445.922821 493.407288,444.020050
	C493.023834,440.237579 492.564514,436.886810 494.590881,433.390015
z"
        />
        <path
          fill="#517581"
          opacity="1.000000"
          stroke="none"
          d="
M387.798157,450.704987
	C384.101898,451.053284 378.853668,449.534882 376.503906,451.493591
	C371.959442,455.281799 367.705139,450.979645 363.568054,452.784363
	C362.361603,453.310608 360.471405,452.014893 359.212463,450.345093
	C359.497375,449.974121 359.937500,449.921387 360.699799,449.781830
	C366.981232,450.187164 372.935883,450.375031 378.843842,449.195190
	C381.904541,448.583923 384.770538,449.153320 387.798157,450.704987
z"
        />
        <path
          fill="#5B7D8C"
          opacity="1.000000"
          stroke="none"
          d="
M332.997253,449.588776
	C331.187195,450.293457 329.466492,450.682953 327.360901,451.105774
	C327.486267,445.827637 323.072540,449.659668 321.166229,448.095856
	C323.907043,446.644501 327.007690,446.551331 330.516602,447.966492
	C331.528290,448.646301 332.685822,448.285858 332.997253,449.588776
z"
        />
        <path
          fill="#28424F"
          opacity="1.000000"
          stroke="none"
          d="
M333.145752,450.157410
	C332.343719,449.442474 331.779541,448.980957 331.061462,448.284912
	C332.747528,447.821777 334.117279,445.319183 336.672821,446.972321
	C336.800201,449.216431 336.757263,451.592316 333.145752,450.157410
z"
        />
        <path
          fill="#28424F"
          opacity="1.000000"
          stroke="none"
          d="
M357.712341,449.867737
	C357.602509,452.363281 356.193604,452.048004 354.302002,451.126526
	C354.747894,449.485596 355.792603,448.795044 357.712341,449.867737
z"
        />
        <path
          fill="#1F323D"
          opacity="1.000000"
          stroke="none"
          d="
M382.473022,403.955872
	C382.933380,403.307739 383.461365,403.349457 383.451355,403.971802
	C383.441315,404.595306 382.912079,404.616180 382.473022,403.955872
z"
        />
        <path
          fill="#28424F"
          opacity="1.000000"
          stroke="none"
          d="
M351.944031,450.682892
	C351.116302,450.891907 350.364685,450.607605 350.053650,449.323486
	C350.883820,449.110168 351.634979,449.394928 351.944031,450.682892
z"
        />
        <path
          fill="#594E52"
          opacity="1.000000"
          stroke="none"
          d="
M335.043152,454.409302
	C335.408173,454.044373 335.854797,454.013000 336.636902,453.976196
	C337.376404,453.606720 337.847137,453.465973 338.705444,453.761047
	C340.118011,453.684021 341.209747,453.394623 342.640015,453.068451
	C344.021210,454.103729 345.392426,453.946625 346.702881,454.039612
	C348.691406,454.180664 350.311951,454.949554 350.685974,457.080048
	C351.061523,459.219177 349.662415,460.473236 347.988312,461.382935
	C345.346375,462.818512 342.486481,464.208649 339.532104,464.000061
	C334.562805,463.649139 330.050476,465.108154 325.481293,466.504395
	C321.026245,467.865753 316.649231,469.054596 311.868347,467.993378
	C308.026459,467.140564 308.925323,469.678741 309.593506,472.200500
	C309.242096,473.681000 308.377167,474.229218 307.056885,474.810944
	C306.439880,474.977448 306.187988,475.018219 305.560425,475.054962
	C304.943512,475.008118 304.702301,474.965332 304.120361,474.804504
	C301.956543,473.165985 299.510284,473.387238 297.175873,472.353058
	C296.673950,472.200012 296.480347,472.128052 296.009094,471.941742
	C295.371185,471.691467 295.005707,471.571625 294.352295,471.486389
	C293.505371,471.570892 292.939606,471.604492 292.101410,471.410187
	C291.828461,471.326538 291.810699,471.270630 291.834778,471.253906
	C291.782806,470.845886 291.559967,470.687683 291.038116,471.028595
	C286.671631,468.622528 281.863922,466.823639 278.500793,462.457581
	C286.952240,462.735107 295.315521,460.827545 303.978699,461.558868
	C313.686829,462.378387 323.711273,461.371399 333.480164,459.645691
	C336.998016,459.024261 337.515778,457.363098 335.043152,454.409302
z"
        />
        <path
          fill="#DABA90"
          opacity="1.000000"
          stroke="none"
          d="
M310.952209,451.015686
	C310.850525,453.248505 310.643036,455.447418 308.180054,456.374268
	C305.869476,457.243713 304.703400,455.476929 303.926880,453.913147
	C302.797729,451.639038 304.829498,451.168884 306.372101,450.604858
	C307.725403,450.110016 308.973389,450.731476 310.600220,451.067871
	C310.957489,451.069641 310.987061,450.999390 310.952209,451.015686
z"
        />
        <path
          fill="#594E52"
          opacity="1.000000"
          stroke="none"
          d="
M327.199158,455.177185
	C328.360931,453.917480 329.803101,453.157562 331.789673,453.843201
	C330.620667,455.097290 329.190887,455.875061 327.199158,455.177185
z"
        />
        <path
          fill="#484750"
          opacity="1.000000"
          stroke="none"
          d="
M320.018677,452.804840
	C319.181946,453.163055 318.343109,453.320038 317.289276,453.713989
	C316.722534,453.502106 316.370819,453.053192 315.974365,452.308533
	C317.028870,452.032349 318.128052,452.051880 319.635559,452.136658
	C320.043854,452.201965 320.016602,452.603577 320.018677,452.804840
z"
        />
        <path
          fill="#53CDD7"
          opacity="1.000000"
          stroke="none"
          d="
M292.753815,415.670593
	C293.066223,415.921478 293.396851,416.061279 293.563782,416.124512
	C293.842468,418.485199 293.519440,420.911591 294.154694,423.053864
	C295.567535,427.818726 294.084900,428.435455 289.720001,427.250702
	C282.261047,425.226135 281.822357,424.660553 283.701233,414.252960
	C286.772339,414.691620 289.958679,413.017242 292.753815,415.670593
z"
        />
        <path
          fill="#47A2AE"
          opacity="1.000000"
          stroke="none"
          d="
M293.043884,415.556335
	C290.098419,416.192200 287.348938,415.152527 284.267853,414.231506
	C285.260468,413.101898 287.802063,413.392029 286.982300,410.326538
	C290.797333,409.645233 292.206451,412.073334 293.043884,415.556335
z"
        />
        <path
          fill="#693E39"
          opacity="1.000000"
          stroke="none"
          d="
M764.095581,679.445557
	C766.923401,679.162598 769.226746,676.676514 772.633301,677.117432
	C771.422241,680.711792 768.303772,681.640991 764.095581,679.445557
z"
        />
        <path
          fill="#533230"
          opacity="1.000000"
          stroke="none"
          d="
M718.049805,684.446106
	C719.781189,684.002380 721.634094,683.737610 723.713745,684.707153
	C722.013245,686.343628 720.054138,686.684570 718.049805,684.446106
z"
        />
        <path
          fill="#693E39"
          opacity="1.000000"
          stroke="none"
          d="
M750.804443,682.201294
	C752.732117,681.615234 754.476929,681.293518 756.608276,680.978882
	C756.205627,684.081116 753.653198,682.631836 751.397705,682.996033
	C750.866943,682.817017 750.744263,682.641357 750.804443,682.201294
z"
        />
        <path
          fill="#533230"
          opacity="1.000000"
          stroke="none"
          d="
M733.226868,685.226379
	C733.890869,683.892151 735.125549,684.019775 736.697754,684.076965
	C736.481018,686.207092 735.129211,686.266602 733.226868,685.226379
z"
        />
        <path
          fill="#533230"
          opacity="1.000000"
          stroke="none"
          d="
M741.671326,682.982544
	C742.144531,682.958618 742.331787,682.982300 742.799316,683.052002
	C742.709412,684.580872 741.962769,685.318298 740.200928,684.210449
	C740.460022,683.658020 740.922729,683.344116 741.671326,682.982544
z"
        />
        <path
          fill="#533230"
          opacity="1.000000"
          stroke="none"
          d="
M750.803833,681.745544
	C750.989441,682.112976 750.991638,682.289001 750.991699,682.728821
	C750.531250,682.983459 750.072937,682.974243 749.270996,682.931152
	C749.162537,682.034668 749.304382,681.054504 750.803833,681.745544
z"
        />
        <path
          fill="#693E39"
          opacity="1.000000"
          stroke="none"
          d="
M775.042603,677.367676
	C775.435852,677.065247 775.883850,677.035278 776.668579,677.020386
	C776.719360,678.341187 776.325623,679.307129 775.042603,677.367676
z"
        />
        <path
          fill="#533230"
          opacity="1.000000"
          stroke="none"
          d="
M745.160034,683.229492
	C745.410522,682.379700 745.922607,682.058655 746.834839,682.732605
	C746.589783,683.591492 746.079773,683.920227 745.160034,683.229492
z"
        />
        <path
          fill="#C46B41"
          opacity="1.000000"
          stroke="none"
          d="
M654.068237,661.404785
	C661.724243,659.109802 669.501282,657.044739 677.527344,656.550293
	C679.879333,656.405334 682.451965,656.913208 684.901611,658.527466
	C684.261353,659.370789 684.506104,660.816101 682.482117,660.848206
	C675.009155,660.966614 667.459839,660.473694 660.108582,662.674866
	C658.237244,663.235291 655.878479,663.868958 654.068237,661.404785
z"
        />
        <path
          fill="#847876"
          opacity="1.000000"
          stroke="none"
          d="
M726.917480,676.441650
	C721.461853,678.145752 716.241577,675.739563 710.443420,676.000671
	C709.985535,674.725952 710.096680,673.475281 711.273499,672.244873
	C711.970642,671.812683 712.345886,671.485168 712.894531,670.769714
	C718.451538,670.680603 723.285034,671.925049 726.917480,676.441650
z"
        />
        <path
          fill="#E1B582"
          opacity="1.000000"
          stroke="none"
          d="
M713.069214,670.798828
	C712.878906,671.283142 712.530945,671.589661 711.830627,671.951050
	C711.478333,672.005859 711.509399,671.680969 711.519958,671.520386
	C711.685425,671.226440 711.840332,671.093018 712.219116,670.750122
	C712.442993,670.540588 712.911438,670.621094 713.069214,670.798828
z"
        />
        <path
          fill="#855144"
          opacity="1.000000"
          stroke="none"
          d="
M713.572266,671.052246
	C713.226929,670.976624 712.833618,670.758789 712.638306,670.649658
	C712.274841,670.234497 712.106750,669.928406 711.880981,669.365662
	C711.579895,666.625977 709.564209,665.721985 707.806030,664.575500
	C706.208313,663.533508 704.194824,662.793701 704.631165,659.971313
	C707.956421,656.462830 706.438599,652.123413 707.501709,648.055908
	C709.022522,642.188293 705.585632,637.022583 706.583923,631.262329
	C707.000549,630.835449 707.202942,630.706177 707.752930,630.520508
	C708.686584,630.386658 709.115417,631.364014 710.122681,630.638611
	C712.994812,630.956543 714.544189,632.757874 716.272705,634.847412
	C720.732544,639.974548 723.636902,646.244568 730.421021,648.454224
	C735.009644,649.948792 739.110840,652.542969 743.637939,654.272034
	C747.610107,655.789185 751.315125,656.395447 755.243774,655.349670
	C760.868225,653.852478 766.787842,654.015930 772.668823,652.058716
	C774.160217,652.002869 775.313965,651.932983 776.451355,652.038269
	C779.807007,652.349060 780.799683,653.961243 779.013916,656.965515
	C777.156067,660.091064 775.597717,663.299866 776.004517,667.508850
	C763.190308,670.439880 750.375549,672.957520 737.273987,675.728394
	C733.874939,676.069153 730.745667,675.979675 727.294067,676.848206
	C723.010498,673.897766 718.052185,673.449402 713.572266,671.052246
z"
        />
        <path
          fill="#3E2423"
          opacity="1.000000"
          stroke="none"
          d="
M630.331299,630.000244
	C623.251953,628.504639 616.825073,630.439148 610.356628,631.857788
	C609.395874,632.068481 608.443115,632.919006 607.565796,632.813782
	C597.964172,631.662048 589.106079,635.863953 579.773132,636.573242
	C578.197388,636.693054 576.863098,636.112366 575.248413,635.275146
	C574.901794,631.579773 577.539307,631.823181 579.767578,631.431030
	C586.895386,630.176392 594.158325,629.584229 601.432861,626.973450
	C607.622620,626.496277 612.867798,623.319824 619.166199,623.509521
	C622.995422,623.007385 626.732361,624.794983 630.169067,621.884033
	C630.907227,621.367920 631.383301,621.184875 632.268982,621.047241
	C635.734070,621.794617 638.427002,620.765686 641.362183,619.032471
	C650.634644,619.463135 659.017761,616.593811 667.556580,614.828613
	C672.378540,613.831909 676.725098,610.890625 682.278809,611.336060
	C684.756348,616.095032 680.948425,617.135498 678.165283,618.312012
	C672.449219,620.728394 666.377808,622.204224 660.297607,624.733521
	C652.118164,624.925903 644.599182,627.779846 636.368530,627.955811
	C635.123474,629.212341 633.529358,628.760010 632.236023,629.770020
	C631.554993,629.997681 631.110046,629.997864 630.331299,630.000244
z"
        />
        <path
          fill="#693E39"
          opacity="1.000000"
          stroke="none"
          d="
M651.232178,636.341492
	C650.928101,633.978699 651.621155,632.839478 653.926758,633.143311
	C656.682495,633.506409 659.458679,633.713989 662.614075,633.992065
	C666.644897,633.119629 670.483521,633.101868 674.031555,632.040710
	C679.260193,630.476746 683.692993,627.653137 687.633545,623.530640
	C692.203491,618.749817 697.314697,613.995117 705.171692,614.778931
	C705.840820,615.773560 705.921143,616.543457 705.637573,617.723022
	C701.855408,621.611938 699.965576,625.911682 698.315125,630.742188
	C693.408325,645.103516 690.652832,646.474915 678.529785,643.029724
	C677.437439,642.719299 676.264587,642.583862 674.721130,642.503174
	C670.332581,643.509338 667.139465,640.541565 663.006836,640.278076
	C658.998108,638.753784 654.853455,638.861023 651.232178,636.341492
z"
        />
        <path
          fill="#532C24"
          opacity="1.000000"
          stroke="none"
          d="
M660.438599,624.996948
	C660.177246,622.491943 662.099670,621.533325 664.038391,621.236389
	C668.985840,620.478516 673.450256,618.276489 678.183105,616.899353
	C680.338379,616.272278 681.471741,614.299255 682.950806,612.382812
	C686.368347,609.364075 690.487793,608.253296 694.613647,606.285645
	C695.676392,605.786316 696.433105,605.604797 697.610840,605.530334
	C702.254517,605.689880 705.927612,602.679443 710.610840,603.473938
	C712.377747,606.271423 710.473267,607.385498 708.628235,608.035828
	C704.865784,609.361938 701.633362,611.666443 697.761230,613.566772
	C694.448914,614.879272 692.534729,618.586182 688.314209,617.253052
	C681.481384,621.053345 673.123718,619.963074 666.747620,624.701355
	C665.439026,625.673828 662.865295,624.943970 660.438599,624.996948
z"
        />
        <path
          fill="#5D3732"
          opacity="1.000000"
          stroke="none"
          d="
M687.957520,617.366028
	C691.727722,617.689331 692.814880,612.917603 696.630798,613.017212
	C699.640381,612.452148 701.915588,609.923584 705.222290,611.816895
	C705.822144,612.870117 705.830444,613.635315 705.251465,614.700073
	C698.544373,616.433105 693.125732,619.720642 688.732788,624.538635
	C681.921021,632.009583 673.568726,635.299500 663.273560,634.255371
	C665.839722,629.750671 670.339050,629.242371 674.795776,629.060730
	C678.716370,628.900940 682.017029,628.269714 683.099304,623.811584
	C683.767456,621.059631 687.214355,620.463562 687.957520,617.366028
z"
        />
        <path
          fill="#BEAAA0"
          opacity="1.000000"
          stroke="none"
          d="
M650.611938,636.079529
	C654.772217,636.703064 658.565979,637.264771 662.369751,639.151367
	C665.253601,639.060913 666.883301,640.628357 668.649414,642.567139
	C668.881836,643.436218 668.854797,643.944641 668.534058,644.790649
	C667.972656,645.498169 667.529236,645.751587 666.640564,645.946289
	C665.215271,646.002930 664.276794,645.626892 663.027100,645.024902
	C660.906250,643.260620 658.916321,641.997253 656.280945,641.107300
	C651.594482,639.255371 646.900452,640.467896 642.284973,639.033264
	C642.083740,638.357788 642.077576,638.016785 642.071472,637.420288
	C644.783752,636.827026 647.495972,636.489197 650.611938,636.079529
z"
        />
        <path
          fill="#473033"
          opacity="1.000000"
          stroke="none"
          d="
M632.370728,629.997375
	C632.263062,627.989014 633.763306,628.002441 635.627441,628.007324
	C635.732849,630.016418 634.230103,629.995361 632.370728,629.997375
z"
        />
        <path
          fill="#554441"
          opacity="1.000000"
          stroke="none"
          d="
M641.699707,638.828369
	C646.964966,637.908569 651.805847,637.839294 656.876099,639.646118
	C657.016785,640.559692 656.740356,641.310303 656.114380,641.458984
	C651.495178,642.556213 647.503235,645.442017 643.049255,646.548767
	C639.586121,647.409363 637.685608,648.890564 636.244629,652.178467
	C631.489136,649.913452 626.216064,648.422852 623.119507,643.345703
	C629.160339,641.651978 635.234924,640.304932 641.699707,638.828369
z"
        />
        <path
          fill="#674541"
          opacity="1.000000"
          stroke="none"
          d="
M636.200562,652.640259
	C635.960205,648.275452 635.161194,643.443909 642.222107,645.607788
	C642.721252,645.760803 643.650513,645.380676 644.022034,644.944092
	C647.308899,641.080994 651.989868,641.007080 656.802856,640.074585
	C659.764771,640.036438 661.225159,642.133423 663.491211,643.544556
	C663.678284,643.971252 663.569519,644.190369 663.171143,644.633850
	C656.388123,644.591248 652.129700,649.302612 646.965576,651.998474
	C645.066406,652.989868 643.188171,654.027832 640.187500,654.896423
	C639.020020,654.919800 638.838867,654.827515 638.378296,654.611084
	C637.522888,653.925964 636.946838,653.364807 636.200562,652.640259
z"
        />
        <path
          fill="#964F38"
          opacity="1.000000"
          stroke="none"
          d="
M667.686523,645.174805
	C667.859924,644.445312 667.838623,644.057617 667.803040,643.377319
	C669.329834,640.843445 671.095093,641.867920 672.398987,643.109009
	C675.152039,645.729492 678.591797,646.721436 682.023376,647.896423
	C690.115356,650.667175 697.674866,654.537781 704.900818,659.625000
	C705.569519,662.841675 708.346863,662.919495 710.233032,664.066772
	C712.040222,665.166077 713.859985,666.063538 712.098022,668.867310
	C702.995667,665.578857 695.162354,660.112183 686.562378,656.136047
	C684.146973,655.019409 681.814331,653.698181 678.664001,653.604004
	C674.857056,652.953918 671.696655,651.720886 668.584778,649.424561
	C667.481384,648.070740 667.008362,646.900146 667.686523,645.174805
z"
        />
        <path
          fill="#CD7D4C"
          opacity="1.000000"
          stroke="none"
          d="
M668.025513,648.595947
	C672.019348,649.075806 674.734436,651.570251 678.103210,653.270630
	C670.426514,655.002747 662.576843,656.447144 654.311523,657.865967
	C650.445862,655.778870 653.506042,653.652466 654.009521,651.229980
	C654.485779,650.514343 654.834778,650.172852 655.574341,649.742065
	C656.150208,649.582458 656.392029,649.570740 656.974426,649.678528
	C659.367615,651.923950 661.521851,651.909668 663.772827,650.118225
	C664.924500,649.201721 666.108826,648.345581 668.025513,648.595947
z"
        />
        <path
          fill="#C46B41"
          opacity="1.000000"
          stroke="none"
          d="
M655.348022,650.094727
	C655.701111,652.898682 652.749390,654.531555 653.777100,657.530640
	C649.359741,658.407532 644.901245,658.384766 640.369934,655.605103
	C640.000183,655.102478 640.057739,655.060608 640.081665,655.034302
	C644.887817,653.665588 649.915894,652.926147 654.171814,649.440186
	C654.744324,649.569153 654.959290,649.674744 655.348022,650.094727
z"
        />
        <path
          fill="#964F38"
          opacity="1.000000"
          stroke="none"
          d="
M639.802490,655.061523
	C640.000183,655.102478 640.034363,655.414307 640.051208,655.569824
	C639.739685,655.809753 639.473022,655.717163 639.234619,655.229858
	C639.201233,655.012085 639.604858,655.020569 639.802490,655.061523
z"
        />
        <path
          fill="#122430"
          opacity="1.000000"
          stroke="none"
          d="
M513.233704,626.498413
	C511.260925,627.068298 509.566132,627.028015 507.447754,626.974243
	C495.492340,622.474731 485.202026,615.798096 475.006104,608.928894
	C467.139343,603.628906 458.626984,599.403564 450.542847,594.439453
	C434.126526,584.358887 417.173920,575.157593 400.840912,564.931763
	C395.188385,561.392822 389.326782,558.281738 383.497772,555.085632
	C381.885590,554.201660 380.245422,553.364136 379.163025,551.315186
	C378.648560,550.038879 378.356201,549.023865 378.502625,548.058899
	C379.148895,543.799561 378.939819,539.653137 377.372192,535.287109
	C380.235046,531.734009 383.359863,532.920471 386.357208,534.298706
	C388.107971,535.103760 389.643250,536.365906 391.699524,537.439331
	C392.301788,537.631226 392.542725,537.704895 393.122070,537.941040
	C408.990143,548.040527 424.809875,557.490662 440.726776,566.797852
	C455.347382,575.347046 469.790497,584.201172 484.913879,592.592041
	C485.480682,592.795532 485.701447,592.892578 486.227905,593.180603
	C492.780945,597.925659 499.121490,602.319275 506.800293,605.124878
	C507.948242,605.798767 508.555969,606.549316 509.441956,607.512329
	C510.763275,612.585510 514.314148,616.915283 512.157776,622.336365
	C511.669739,623.563293 513.444641,624.534912 513.233704,626.498413
z"
        />
        <path
          fill="#213642"
          opacity="1.000000"
          stroke="none"
          d="
M379.570007,550.991333
	C383.361694,553.018982 386.621918,555.284485 390.153229,556.987976
	C398.044739,560.794983 405.106476,565.950989 412.627380,570.351562
	C423.122955,576.492737 433.936371,582.131104 444.144531,588.712402
	C455.652771,596.131836 467.952789,602.181335 479.148468,610.191956
	C487.282440,616.011780 496.632446,620.130737 505.425720,625.034058
	C505.969025,625.337158 506.384888,625.868835 506.942139,626.627441
	C506.840485,626.982178 506.656830,627.003662 506.196991,627.051147
	C502.195160,630.050232 498.759277,627.834595 494.918030,626.354858
	C491.896301,624.356750 489.585876,621.980042 486.223633,620.551941
	C485.435699,620.406738 484.992096,620.296021 484.238129,620.020996
	C482.883362,619.427734 482.066010,618.553955 480.597046,618.154907
	C480.065369,617.901978 479.858429,617.785156 479.363037,617.454834
	C472.805664,611.738831 465.345581,607.990112 458.368805,603.587097
	C435.647552,589.247864 412.445862,575.688477 389.172638,562.257629
	C385.478668,560.125793 381.129425,558.946411 378.247559,554.938721
	C377.781799,553.182129 377.892700,551.906006 379.570007,550.991333
z"
        />
        <path
          fill="#875E55"
          opacity="1.000000"
          stroke="none"
          d="
M516.231201,635.300720
	C504.313507,635.434448 493.142548,638.224426 481.427551,640.004883
	C480.464050,640.003601 479.930176,640.076904 479.173218,640.401855
	C476.547485,640.951355 474.172638,641.397156 471.401917,641.848511
	C469.065430,641.199707 467.295105,642.099121 465.202332,642.641785
	C463.554047,643.115967 462.169250,643.409790 460.432190,643.693604
	C457.444794,642.863586 454.986816,644.195862 452.425873,644.347900
	C450.080933,644.487000 447.836823,644.661011 445.733704,642.527283
	C444.617615,639.377136 445.820557,637.141663 447.810120,634.772583
	C460.286530,633.128418 472.421204,631.998596 484.641937,633.095703
	C485.493958,633.172241 486.278290,632.990173 487.438904,632.494507
	C495.884277,633.180481 503.929657,632.384338 512.384827,632.244202
	C513.646545,632.053345 514.483215,632.065491 515.587952,632.772339
	C515.918884,633.037415 515.993042,633.514465 516.024963,633.753723
	C516.235046,634.298828 516.413147,634.604675 516.231201,635.300720
z"
        />
        <path
          fill="#2C1E22"
          opacity="1.000000"
          stroke="none"
          d="
M601.989441,627.545959
	C595.543640,631.962524 588.057251,631.609253 580.984131,632.968689
	C579.091797,633.332397 576.921753,632.467712 575.204590,634.687134
	C573.936218,635.022705 572.871460,635.023438 571.407349,635.028259
	C570.382690,635.732117 569.573120,635.983887 568.337891,636.006104
	C560.227783,636.015991 552.425476,636.359985 544.665894,635.931885
	C533.666809,635.325073 523.047485,632.816162 513.047729,627.442566
	C512.955688,627.108643 512.979980,627.038818 512.992432,627.004028
	C515.203247,625.070251 517.547363,625.944641 520.248962,626.745117
	C521.568848,627.228882 522.290466,628.057861 523.730835,628.175171
	C526.484497,628.259277 528.863464,628.185913 531.634644,628.551941
	C533.510010,628.594299 534.833496,629.100098 536.491821,629.962646
	C538.449951,631.862732 540.526855,631.468018 542.487427,631.493469
	C558.114502,631.696106 573.387634,628.913635 588.632263,625.944275
	C591.424500,625.400391 594.195068,625.008362 597.475525,625.380005
	C599.412231,625.646973 600.990784,625.626770 601.989441,627.545959
z"
        />
        <path
          fill="#484750"
          opacity="1.000000"
          stroke="none"
          d="
M493.765991,624.753540
	C497.756927,625.360229 501.116760,627.820129 505.499847,627.058960
	C505.944641,627.805908 505.968414,628.534668 505.992432,629.629272
	C505.031525,631.569641 503.217224,631.249634 501.449951,631.975830
	C500.296570,632.199829 499.527954,632.149048 498.408508,631.852112
	C496.388733,629.638916 493.996063,629.408997 491.243469,629.105164
	C486.339325,627.272339 482.313171,624.578125 477.884583,621.830200
	C475.906921,619.780090 473.076538,618.716553 473.642761,615.158203
	C476.292694,613.377686 477.544495,615.962402 479.717407,616.740234
	C480.020691,616.985352 480.509796,616.992798 480.754089,617.004944
	C481.743500,617.713135 482.397064,618.524414 483.688293,618.966370
	C484.436157,619.020996 484.881073,619.023865 485.659607,619.036011
	C488.033325,621.516907 491.007172,622.705322 493.765991,624.753540
z"
        />
        <path
          fill="#705250"
          opacity="1.000000"
          stroke="none"
          d="
M497.644165,630.685547
	C498.754852,630.974487 499.503998,630.980469 500.627686,630.986145
	C503.747223,631.028503 506.492828,630.961609 509.611237,631.057007
	C510.721649,631.084106 511.458496,631.087463 512.563110,631.114868
	C513.348633,631.489868 513.522339,631.935852 513.246582,632.796265
	C504.932861,633.843018 496.835815,635.856689 488.355652,633.173157
	C486.469635,633.848511 485.761078,633.190552 485.699463,631.204224
	C487.044403,629.589478 488.515350,629.297607 490.583252,629.470215
	C493.140991,629.670105 495.361938,629.011414 497.644165,630.685547
z"
        />
        <path
          fill="#5C4747"
          opacity="1.000000"
          stroke="none"
          d="
M509.747437,630.812561
	C507.258484,632.651794 504.421844,632.104004 501.292725,631.251953
	C502.174011,629.647095 503.763611,630.093384 505.609436,629.996155
	C507.165131,630.178223 508.337585,630.361267 509.747437,630.812561
z"
        />
        <path
          fill="#705250"
          opacity="1.000000"
          stroke="none"
          d="
M515.978638,635.803040
	C515.898743,635.407654 515.926331,635.124329 516.005432,634.416992
	C517.108826,634.003845 518.160767,634.014648 519.608398,634.049072
	C520.261597,634.546082 520.519104,635.019531 520.894958,635.736084
	C521.013367,635.979187 520.976440,636.023010 520.957520,636.044556
	C519.896423,636.982605 518.741821,637.121399 517.081055,636.338806
	C516.452271,636.126038 516.255798,636.060303 515.978638,635.803040
z"
        />
        <path
          fill="#3E2423"
          opacity="1.000000"
          stroke="none"
          d="
M568.221924,636.246094
	C568.576233,635.192688 569.420349,635.020752 570.673218,635.026855
	C570.737122,636.561829 570.033569,637.319214 568.221924,636.246094
z"
        />
        <path
          fill="#5C4747"
          opacity="1.000000"
          stroke="none"
          d="
M513.401001,633.095520
	C513.016235,632.674805 512.991394,632.233887 512.948730,631.466064
	C514.098450,631.064575 515.305847,630.894958 515.876099,632.694763
	C515.199524,633.050110 514.480225,633.062744 513.401001,633.095520
z"
        />
        <path
          fill="#59B1BB"
          opacity="1.000000"
          stroke="none"
          d="
M795.259155,162.033936
	C795.070740,161.925552 795.074341,161.658554 795.110840,160.989807
	C795.246094,161.017136 795.348633,161.446228 795.259155,162.033936
z"
        />
        <path
          fill="#3C7D8E"
          opacity="1.000000"
          stroke="none"
          d="
M785.026489,158.339005
	C785.045044,158.000381 785.497009,157.968613 785.719727,157.929153
	C786.806763,158.105560 788.103210,157.707092 788.379639,158.817017
	C788.709106,160.139572 787.104187,159.862381 786.216614,160.781586
	C785.096375,160.476822 784.980652,159.604324 785.026489,158.339005
z"
        />
        <path
          fill="#3C7D8E"
          opacity="1.000000"
          stroke="none"
          d="
M786.036377,160.963776
	C786.684021,161.043106 787.364807,161.105072 787.473938,161.880707
	C787.498535,162.055923 787.155151,162.282867 786.981079,162.485992
	C786.662720,162.195221 786.344360,161.904449 785.972290,161.347717
	C785.918579,161.081741 785.997070,161.003098 786.036377,160.963776
z"
        />
        <path
          fill="#33616C"
          opacity="1.000000"
          stroke="none"
          d="
M867.056519,237.671844
	C861.268860,237.659790 855.556946,237.289230 849.386353,236.827972
	C841.524719,232.075699 847.416382,225.362732 846.366028,219.714981
	C846.142578,218.513718 846.765137,217.155121 846.998962,215.444183
	C849.542175,208.492142 853.670166,203.832718 861.090027,203.038315
	C861.935791,202.947769 862.721558,203.074997 863.726013,203.769806
	C864.298218,204.141891 864.544434,204.332947 864.864380,204.786896
	C865.528564,205.543015 865.804871,206.201431 865.673462,207.334473
	C859.663147,209.318680 856.438171,214.277466 852.591370,218.399857
	C851.398315,219.678375 849.462708,221.398727 852.002991,223.755554
	C852.995972,226.046722 851.649414,229.379517 855.754578,229.312302
	C859.136353,229.256927 862.127869,226.802338 862.464172,223.267136
	C862.603821,221.799713 862.033936,220.015091 863.539062,219.157562
	C867.877319,216.685928 866.603394,213.836761 864.404358,210.448975
	C864.629089,209.622772 864.915894,209.229675 865.602417,208.690277
	C866.421448,208.353638 866.917114,208.373596 867.708252,208.776108
	C872.863098,209.253204 871.677979,212.762604 871.234558,215.461594
	C870.033325,222.773331 871.513245,230.602753 867.056519,237.671844
z"
        />
        <path
          fill="#4B555C"
          opacity="1.000000"
          stroke="none"
          d="
M863.994446,203.657074
	C856.057861,203.867966 851.586487,208.950424 847.336792,214.946030
	C846.597107,211.397949 848.809631,208.332550 849.148804,204.835236
	C849.401184,202.232239 853.302002,201.818298 853.716675,202.001572
	C857.081238,203.488556 861.407532,197.459854 863.994446,203.657074
z"
        />
        <path
          fill="#4B555C"
          opacity="1.000000"
          stroke="none"
          d="
M864.906372,208.450836
	C864.945190,207.311417 864.964478,206.537659 864.984619,205.376465
	C866.998840,205.163177 867.960022,206.298691 868.001648,208.644531
	C867.581543,209.012848 867.153259,209.018341 866.404053,209.033691
	C865.616211,209.327133 865.214172,209.268845 864.906372,208.450836
z"
        />
        <path
          fill="#15313E"
          opacity="1.000000"
          stroke="none"
          d="
M813.635315,204.052765
	C814.802551,204.603455 816.093262,205.201080 815.314331,206.208054
	C814.149902,207.713593 812.310486,206.606094 810.374939,206.755386
	C809.977539,206.766190 809.969421,206.743073 809.969849,206.764313
	C810.036377,206.176193 810.102417,205.566818 810.098633,204.500305
	C811.096191,204.041962 812.163513,204.040802 813.635315,204.052765
z"
        />
        <path
          fill="#15191F"
          opacity="1.000000"
          stroke="none"
          d="
M806.085815,202.942291
	C806.443787,203.140945 806.824707,203.714935 807.317139,204.623352
	C806.686340,204.654312 806.220947,204.128067 806.085815,202.942291
z"
        />
        <path
          fill="#15191F"
          opacity="1.000000"
          stroke="none"
          d="
M804.336670,200.774734
	C804.242920,200.733521 804.148315,201.592072 804.058594,200.723358
	C804.181519,200.597092 804.266541,200.734589 804.336670,200.774734
z"
        />
        <path
          fill="#15313E"
          opacity="1.000000"
          stroke="none"
          d="
M819.839905,204.604401
	C816.850952,207.294449 817.132568,202.301819 814.629822,202.838791
	C813.534607,201.856659 811.537354,201.719894 812.193237,199.900360
	C812.720825,198.436951 813.947510,197.534012 815.481689,197.168594
	C817.175781,196.765106 818.369873,197.592148 819.268982,198.904388
	C820.387695,200.537125 820.105042,202.375977 819.839905,204.604401
z"
        />
        <path
          fill="#17232A"
          opacity="1.000000"
          stroke="none"
          d="
M813.035034,289.543396
	C812.034790,289.885864 810.916931,289.935455 809.379639,289.977509
	C808.402222,288.709595 807.522095,287.463776 809.070801,285.899475
	C810.542664,286.868744 811.730042,288.059631 813.035034,289.543396
z"
        />
        <path
          fill="#241A19"
          opacity="1.000000"
          stroke="none"
          d="
M584.612000,328.264038
	C584.663635,324.229767 581.350769,326.968750 579.762390,325.402344
	C573.589783,325.648438 572.846558,324.768005 573.850159,318.032166
	C576.284851,314.865723 578.436584,317.028931 580.525635,317.937927
	C583.168823,319.087982 585.476685,321.279175 589.080261,320.326782
	C590.171753,320.555847 590.677307,321.035187 591.170532,322.015839
	C591.305420,322.589264 591.316284,322.827240 591.137695,323.368134
	C589.553406,328.433441 593.157410,327.257935 595.969360,327.661133
	C598.703308,335.458008 597.739746,343.357697 597.190918,351.041016
	C596.704041,357.856689 596.629333,364.643402 596.499634,371.445740
	C596.309448,381.429230 596.759583,391.418457 596.094604,401.846191
	C594.174316,404.477936 592.732849,403.370331 591.683411,401.692047
	C590.650818,400.040680 589.901917,398.200562 589.137817,395.894409
	C587.837891,381.636017 588.823120,367.810669 588.504333,353.535095
	C588.510986,345.670868 588.490723,338.274597 588.362427,330.879791
	C588.136597,330.218964 587.735474,331.329529 588.293884,330.965332
	C588.758484,338.052063 588.364258,345.146942 588.495605,352.712646
	C588.907776,368.563477 587.559937,383.973053 589.430908,399.323029
	C589.649109,401.113647 589.580505,402.965698 588.929199,405.196411
	C588.141968,406.498810 587.282410,406.782806 585.854004,406.685120
	C583.868347,405.788513 584.026855,404.326202 584.003540,402.579834
	C583.994385,401.954987 584.015808,401.683441 583.981567,401.696655
	C583.952759,402.676208 583.844116,403.349274 583.160522,404.234619
	C582.017578,404.908569 581.169800,404.897858 579.931396,404.334564
	C578.917664,403.324432 578.701782,402.422485 578.578003,401.001953
	C578.755676,390.333923 577.959229,380.126099 579.213318,369.535156
	C580.116699,368.406952 580.963379,368.175354 582.313843,368.342651
	C584.396667,369.567078 583.868591,371.311005 584.001648,372.872528
	C583.132202,358.876801 583.572998,344.867401 583.692749,330.475403
	C583.940247,329.580780 584.148987,329.088226 584.612000,328.264038
z"
        />
        <path
          fill="#1D1415"
          opacity="1.000000"
          stroke="none"
          d="
M634.327820,320.589294
	C629.634460,321.376160 625.239624,321.800568 620.402466,322.172791
	C615.069641,316.503815 616.723328,310.414215 618.999512,303.959686
	C620.632324,302.628265 622.143982,302.347626 624.182495,302.388275
	C627.287842,302.540436 629.944519,302.645264 633.045959,302.359375
	C633.771606,302.562225 634.044434,302.704742 634.602478,303.214722
	C635.724243,309.144897 636.094543,314.700287 634.327820,320.589294
z"
        />
        <path
          fill="#151117"
          opacity="1.000000"
          stroke="none"
          d="
M591.377441,323.835938
	C590.948181,323.671082 591.021667,323.408783 591.026733,323.272675
	C588.662903,317.952271 589.656311,312.481140 589.764099,306.613220
	C589.998352,305.830200 590.131897,305.528107 590.588745,304.846741
	C594.725098,302.448090 598.611145,302.658661 602.938110,304.384338
	C605.333801,310.415741 604.638428,316.231750 603.604004,322.459778
	C599.605713,324.406067 595.663269,323.766510 591.377441,323.835938
z"
        />
        <path
          fill="#1D1415"
          opacity="1.000000"
          stroke="none"
          d="
M608.404175,322.746521
	C607.599915,323.003418 607.129333,323.080719 606.303894,323.094757
	C605.436218,322.711090 604.963989,322.339081 604.352295,321.500244
	C603.912109,315.942169 603.853333,310.800110 604.404480,305.238556
	C607.452942,302.405029 610.689270,301.973511 614.576416,303.164551
	C618.198608,308.939209 616.920593,314.819305 616.033020,320.638794
	C615.847351,321.856018 614.746460,322.506989 613.410400,322.442932
	C612.290955,322.389221 611.522827,321.962769 611.494507,320.697205
	C611.482666,320.169525 611.573181,319.640198 611.470398,319.114349
	C611.483765,319.610840 611.451904,320.100281 611.311340,320.582336
	C610.934998,321.873230 609.956299,322.373169 608.404175,322.746521
z"
        />
        <path
          fill="#574649"
          opacity="1.000000"
          stroke="none"
          d="
M594.562378,403.308472
	C595.286621,378.184418 595.724487,353.462402 596.281372,328.263672
	C596.400452,327.786987 596.507507,327.807007 596.523438,327.859070
	C596.827942,328.064331 597.116516,328.217529 597.701233,328.760071
	C599.195557,331.468994 599.020508,333.987885 598.983643,336.462372
	C598.739807,352.859985 599.569946,369.267548 598.515198,385.658691
	C598.388000,387.635529 598.372925,389.638214 598.925232,392.057312
	C598.350464,399.180756 601.349731,405.895569 598.510681,412.909790
	C597.337585,413.824158 596.408569,413.889313 595.018799,413.350037
	C592.634705,410.030701 592.631958,406.898987 594.562378,403.308472
z"
        />
        <path
          fill="#2C1E22"
          opacity="1.000000"
          stroke="none"
          d="
M608.508606,322.990906
	C611.723083,321.048676 610.867920,317.395325 611.136719,313.522034
	C612.468323,315.931030 611.785950,317.734467 612.020691,319.438110
	C612.158569,320.438934 612.208191,321.631470 613.501099,321.602448
	C614.753662,321.574402 614.924255,320.407562 614.931885,319.391724
	C614.967712,314.592499 614.972717,309.793030 614.986206,304.529633
	C616.314941,302.078064 617.907410,301.432861 619.968079,303.524323
	C618.296875,309.711426 618.744812,315.511810 619.815552,321.720764
	C616.343140,323.069885 612.624695,322.824341 608.508606,322.990906
z"
        />
        <path
          fill="#5C4747"
          opacity="1.000000"
          stroke="none"
          d="
M602.035767,328.156067
	C602.788940,327.839081 603.100830,327.840057 603.644348,327.970398
	C603.876282,328.012085 604.096375,327.990326 604.203918,328.015930
	C605.193726,328.837585 605.996155,329.719879 605.987732,330.980499
	C605.893860,345.063751 606.165466,359.156891 604.623047,373.607574
	C603.771484,374.385437 603.150269,374.524536 602.040161,374.189941
	C600.324829,372.476715 600.068604,370.664978 600.054260,368.827515
	C599.960754,356.890106 599.953064,344.952393 600.057251,333.014923
	C600.065918,332.017548 600.241882,331.034698 600.799316,329.638000
	C601.195129,328.916168 601.394836,328.651276 602.035767,328.156067
z"
        />
        <path
          fill="#2C1E22"
          opacity="1.000000"
          stroke="none"
          d="
M634.343323,321.009460
	C634.021545,315.285950 634.013855,309.620117 634.007568,303.487701
	C634.694031,302.316620 635.528809,302.202698 636.828003,302.612518
	C638.902222,306.707245 637.465637,310.720520 637.632874,315.085876
	C637.488220,316.631775 637.696533,317.777710 637.166870,319.272766
	C636.831665,319.889374 636.665833,320.117493 636.214600,320.657715
	C635.505188,321.002289 635.081299,321.034729 634.343323,321.009460
z"
        />
        <path
          fill="#4B4D54"
          opacity="1.000000"
          stroke="none"
          d="
M605.268311,304.792175
	C605.068909,310.432892 605.033203,315.750427 605.014099,321.535187
	C605.147034,322.206665 605.263489,322.410889 605.054199,322.750122
	C604.422241,322.987732 604.107971,323.031372 603.559998,322.942871
	C603.271179,317.202484 603.207764,311.535278 603.102539,305.396790
	C602.940247,304.717377 602.819763,304.509247 602.897034,303.998901
	C603.538208,303.482910 604.004028,303.407623 604.812500,303.639343
	C605.232666,304.028900 605.332397,304.248932 605.268311,304.792175
z"
        />
        <path
          fill="#342C32"
          opacity="1.000000"
          stroke="none"
          d="
M650.303101,317.586761
	C649.971802,318.403992 649.945007,318.874969 649.888184,319.698181
	C649.499512,320.089020 649.140808,320.127594 648.510132,320.161102
	C647.998962,320.007385 647.759705,319.858704 647.273376,319.366089
	C646.803711,318.498718 646.660828,317.954376 646.550110,316.968323
	C646.236328,312.031219 645.554688,307.483734 647.776367,302.781525
	C648.270325,302.240387 648.501099,302.052887 649.173950,301.778839
	C649.884277,301.833893 650.152344,301.975555 650.699097,302.480957
	C652.258911,307.679413 652.686401,312.492188 650.303101,317.586761
z"
        />
        <path
          fill="#46170C"
          opacity="1.000000"
          stroke="none"
          d="
M647.673645,318.712341
	C648.063782,319.192902 648.091492,319.386871 648.178711,319.868469
	C644.547058,321.708069 640.641357,320.825623 636.385986,321.065063
	C635.945068,320.801270 635.960999,320.632690 635.999390,320.211060
	C639.483765,317.227600 643.274414,316.919830 647.673645,318.712341
z"
        />
        <path
          fill="#241A19"
          opacity="1.000000"
          stroke="none"
          d="
M602.212524,327.611633
	C602.380005,328.097443 602.283203,328.269073 602.090576,328.720459
	C600.712463,330.124268 599.315186,330.405975 597.405273,329.293701
	C596.979736,328.818451 596.915405,328.625946 596.695251,328.172302
	C598.227417,326.693848 600.062195,326.769775 602.212524,327.611633
z"
        />
        <path
          fill="#2C1E22"
          opacity="1.000000"
          stroke="none"
          d="
M605.024658,322.995789
	C604.728394,322.885101 604.890442,322.447357 604.960571,322.224915
	C605.322205,322.140839 605.561829,322.341309 605.849243,322.817688
	C605.739685,323.056488 605.530334,323.081512 605.024658,322.995789
z"
        />
        <path
          fill="#2B2F37"
          opacity="1.000000"
          stroke="none"
          d="
M661.329956,316.590576
	C660.865967,316.922821 660.685608,316.925995 660.234619,316.927368
	C658.386780,315.409027 657.987366,313.383667 658.255005,311.431915
	C659.037964,305.720581 656.822571,302.416870 650.735962,301.330200
	C648.811096,297.357422 649.677490,293.526062 649.817749,289.353912
	C650.261169,288.383850 650.647278,287.557953 651.293213,287.658142
	C658.473328,288.771820 664.167175,284.081543 670.710693,282.724426
	C672.647949,282.322662 674.451904,281.169434 675.716736,279.626038
	C677.942444,276.910248 680.612732,276.521484 684.102173,277.554626
	C689.929138,277.803253 695.155640,275.740631 700.622864,275.990265
	C702.143250,276.059692 702.865723,274.828522 703.500977,273.620056
	C704.218872,272.254242 705.136963,271.120911 707.261353,271.242981
	C713.291565,275.716949 719.162720,276.470612 725.650574,272.161102
	C726.783020,271.739624 727.627625,271.522095 728.436829,271.661255
	C739.092224,273.493591 745.418274,267.676117 749.942200,259.174164
	C751.651123,255.962646 754.798706,253.860718 756.432373,250.384613
	C749.320557,250.510208 747.734192,248.875046 747.520142,241.768463
	C747.403381,237.888596 748.043640,234.150345 749.582153,230.604462
	C752.042236,224.934448 756.383423,222.225555 762.553406,222.476257
	C766.266846,222.627136 768.606934,223.744125 770.138611,227.994354
	C771.772278,232.527451 772.371399,237.701324 776.066040,241.508270
	C778.129883,243.634918 777.357422,246.901352 778.224609,249.967804
	C778.690979,259.369873 778.707214,268.324402 772.977722,276.089935
	C772.483643,276.759674 772.039612,277.528717 771.993347,278.360260
	C771.654907,284.445984 768.383240,289.290955 765.045898,294.543152
	C762.427246,296.405487 759.774231,296.692017 757.103516,296.840332
	C729.539673,298.371399 702.018250,300.572571 674.452332,302.085815
	C670.699524,302.291809 666.825134,302.377045 663.039429,304.189606
	C662.703247,305.097290 662.594055,305.638550 662.519104,306.606201
	C662.492981,307.581787 662.489380,308.141449 662.497559,309.126404
	C662.712036,311.855255 663.090637,314.181580 661.329956,316.590576
z"
        />
        <path
          fill="#151117"
          opacity="1.000000"
          stroke="none"
          d="
M661.464844,316.942444
	C661.028259,314.573486 661.010193,312.227325 661.004089,309.438843
	C662.125122,307.939301 663.546692,308.062744 664.801941,308.315247
	C667.923950,308.943329 670.652222,308.247162 673.353760,306.653625
	C675.871521,305.168457 678.777283,305.131348 681.619873,305.191010
	C695.620667,305.484833 709.517273,304.041199 723.453369,303.222473
	C730.804138,302.790649 738.057861,301.953857 745.369995,301.378448
	C753.577332,300.732574 761.520569,299.242981 769.401245,297.146240
	C771.939697,296.470886 774.529663,295.599182 776.381165,292.835419
	C777.739502,291.908905 778.852661,291.785553 780.408081,292.273590
	C782.820068,294.852509 781.241028,296.809753 779.911621,298.678284
	C777.288757,302.364624 773.459229,304.529388 769.254822,305.572693
	C760.614868,307.716766 752.006226,310.293549 742.896362,309.523743
	C738.521423,309.154083 734.491516,310.598633 730.279541,312.670380
	C707.231934,313.482727 684.486511,314.019226 661.464844,316.942444
z"
        />
        <path
          fill="#412C2E"
          opacity="1.000000"
          stroke="none"
          d="
M792.009888,282.567688
	C792.002136,283.443939 791.997742,283.888245 791.989319,284.665833
	C789.908081,286.653076 792.324280,288.743805 791.223389,290.753052
	C790.519348,291.303040 790.041138,291.614563 789.269226,291.963043
	C788.012634,292.890289 786.888000,293.264526 785.148865,292.926086
	C783.007080,291.824097 782.703125,290.170990 782.875183,288.485077
	C783.534729,282.023132 782.510803,275.558838 783.020935,269.095367
	C783.520813,262.762421 782.201782,256.541077 781.601746,249.838470
	C781.207886,246.956436 780.850952,244.558182 779.422607,242.383850
	C776.798706,238.389420 776.578369,234.049622 778.357544,229.211304
	C780.598999,227.313263 782.602722,228.022293 785.006226,228.996017
	C791.086487,234.379059 792.664551,241.339691 793.637573,248.505829
	C795.171692,259.805359 794.977478,271.043030 792.009888,282.567688
z"
        />
        <path
          fill="#241312"
          opacity="1.000000"
          stroke="none"
          d="
M784.626587,292.113220
	C786.034302,291.987885 787.104553,291.992798 788.575195,291.998840
	C789.114868,293.587341 788.738708,295.039001 787.449158,296.022095
	C778.315002,302.985504 769.482605,309.904846 756.881897,310.276886
	C748.221802,310.532623 739.263794,310.032806 730.383179,312.953064
	C731.440186,307.947235 736.607910,310.172913 739.584839,308.074982
	C740.143494,307.681274 741.228638,307.874847 742.033325,308.014282
	C751.818359,309.710510 760.753052,305.632477 769.951843,303.681793
	C775.123291,302.585144 779.008606,298.960938 780.939331,293.348846
	C781.870605,291.812744 783.067932,291.975525 784.626587,292.113220
z"
        />
        <path
          fill="#151117"
          opacity="1.000000"
          stroke="none"
          d="
M650.653931,301.003357
	C660.040039,301.249786 660.040466,301.249786 660.009827,310.569580
	C660.003845,312.382904 659.992676,314.196198 659.973938,316.467529
	C656.948303,317.435486 653.990540,318.440338 650.430542,317.962036
	C649.997803,312.930115 649.997009,307.927216 649.982544,302.457031
	C650.012268,301.640320 650.167053,301.347168 650.653931,301.003357
z"
        />
        <path
          fill="#532C24"
          opacity="1.000000"
          stroke="none"
          d="
M791.372314,290.998108
	C791.742615,289.077179 788.593689,286.954803 791.647644,285.072479
	C791.849243,287.001892 792.197327,288.853333 793.827271,290.668884
	C793.245361,290.989227 792.496155,290.997009 791.372314,290.998108
z"
        />
        <path
          fill="#5D413D"
          opacity="1.000000"
          stroke="none"
          d="
M578.296265,93.257248
	C577.248474,93.006416 576.495972,93.004898 575.367188,92.996231
	C576.095398,90.575111 578.355713,89.840431 580.634399,89.332840
	C593.942871,86.368378 607.335632,85.473839 620.366333,90.173477
	C629.211731,93.363625 637.355347,98.079185 643.792725,105.729462
	C645.253235,106.888657 645.926575,108.200790 646.772461,109.776199
	C647.572510,110.499222 648.153687,110.992203 648.863770,111.745895
	C650.113342,116.906830 653.094604,121.407227 651.991821,127.123459
	C648.026917,130.289413 647.226624,126.653297 646.228271,124.580933
	C644.238708,120.450882 642.515015,116.201385 639.356201,112.356041
	C633.928467,108.462296 628.685913,104.936768 624.156799,100.517754
	C623.033752,99.422035 621.734802,98.859955 620.282776,98.421745
	C612.963501,96.212807 605.491577,94.724602 597.946533,93.555206
	C594.714233,93.054237 591.722656,94.295197 588.661499,94.865219
	C585.080505,95.532043 581.767517,95.311668 578.296265,93.257248
z"
        />
        <path
          fill="#674541"
          opacity="1.000000"
          stroke="none"
          d="
M646.995361,109.607147
	C645.019714,109.771843 644.552307,108.224266 644.044678,106.332726
	C644.748840,105.994011 645.493103,105.999321 646.608887,106.012161
	C646.986816,107.082481 646.993103,108.145271 646.995361,109.607147
z"
        />
        <path
          fill="#553635"
          opacity="1.000000"
          stroke="none"
          d="
M738.767029,202.711212
	C740.375427,203.677643 741.760437,204.059174 743.622314,204.002396
	C743.991150,204.763535 743.994507,205.509583 743.997681,206.628906
	C743.396729,209.762711 741.272339,210.994827 738.956848,212.047745
	C733.307007,214.616791 732.527954,218.386200 736.602173,223.138458
	C744.395752,232.228958 745.039551,242.408188 741.099915,253.256516
	C740.814941,254.041382 740.287781,254.692932 739.319824,255.468857
	C738.186401,255.975571 737.387024,255.986694 736.220337,255.578125
	C734.054382,253.420303 734.266235,251.017136 734.865845,248.794098
	C735.886719,245.009735 735.297852,241.199112 735.536011,237.408981
	C735.692993,234.911835 734.486145,233.270035 732.482971,232.150604
	C723.605713,227.189545 714.023499,225.753616 704.069580,227.069672
	C700.204346,227.580719 697.373657,230.407501 696.041443,233.872482
	C693.844421,239.586639 691.927612,245.459091 690.988892,251.549438
	C690.476685,254.872696 690.737122,258.185822 692.922852,261.410278
	C694.659729,262.993835 694.490417,266.242249 698.033386,265.561218
	C700.480408,265.314606 702.563904,265.175812 704.854736,266.401855
	C705.886108,270.963593 704.051453,273.367889 699.955750,272.936401
	C697.369690,272.663971 694.756042,272.645721 691.809326,272.057465
	C687.721191,269.311340 686.890503,264.527588 683.446472,261.214417
	C683.083862,260.339996 682.988525,259.806458 683.007446,258.845795
	C684.207397,255.182816 683.296204,251.826553 683.321472,248.555008
	C683.373413,241.847061 684.021729,235.459045 686.882141,229.232315
	C688.352600,226.031464 688.153748,222.426727 684.736511,219.840652
	C682.491150,218.141449 682.358704,215.564240 682.715027,213.021759
	C683.270996,209.055679 683.113220,205.074310 683.176575,201.095215
	C683.240845,197.064560 683.463013,193.148346 678.506897,191.319885
	C676.629211,190.627167 675.840881,188.456039 676.218994,185.855957
	C677.877258,183.459763 679.484558,184.595444 681.033630,185.394318
	C682.584045,186.193954 683.813293,187.647018 686.127808,187.787689
	C689.236694,189.106735 690.217529,191.463623 690.780640,194.164688
	C691.524353,197.732590 691.546143,201.389252 692.161743,204.961502
	C693.051880,210.126404 695.725098,212.378799 700.948425,212.480865
	C705.404297,212.567932 709.864014,212.444427 714.320129,212.521576
	C716.637024,212.561691 718.587402,212.121353 719.872192,209.491180
	C720.802307,208.050827 722.204773,207.681015 723.171387,206.264374
	C728.984619,198.710434 730.723389,198.303528 738.767029,202.711212
z"
        />
        <path
          fill="#433437"
          opacity="1.000000"
          stroke="none"
          d="
M738.644409,255.626465
	C743.258606,246.778442 741.709473,238.095474 739.589844,229.426041
	C739.150635,227.629715 738.038757,226.067886 736.331787,225.216858
	C732.564819,223.338821 732.403198,219.687881 732.139221,216.319931
	C731.956482,213.987869 732.874084,211.344925 735.698914,211.170883
	C739.002808,210.967346 741.130310,209.142990 743.656921,207.073364
	C743.997559,207.002197 744.490479,207.011047 744.736938,207.013855
	C745.746277,207.828140 746.592468,208.113449 747.804932,207.222900
	C754.932861,208.690811 761.932007,209.479828 769.515991,209.020264
	C774.496765,213.363129 779.396240,217.298752 783.607605,221.954010
	C785.556580,224.108444 787.162415,226.405624 785.287720,229.754364
	C783.256226,229.673691 781.512512,229.344955 779.385620,229.004944
	C775.260254,225.081070 772.277100,220.365372 766.883911,218.250031
	C761.151306,216.001572 755.570740,215.695923 750.177734,219.032440
	C748.468628,220.089813 746.528625,221.611588 747.870789,223.845932
	C750.393616,228.045563 748.814575,232.321106 748.335144,236.440323
	C747.701782,241.881180 747.218445,247.302521 747.081482,252.771866
	C746.962158,257.535095 745.508911,259.058594 740.294678,259.648193
	C738.769043,258.609955 738.260376,257.443054 738.644409,255.626465
z"
        />
        <path
          fill="#532C24"
          opacity="1.000000"
          stroke="none"
          d="
M785.146545,230.317047
	C785.487488,226.770706 784.076294,224.486710 781.829651,222.207794
	C777.819153,218.139877 773.156555,214.657639 770.035400,209.362762
	C775.479492,212.234222 781.121033,215.191605 785.623474,219.893570
	C787.357727,221.704590 788.291870,224.521149 791.953369,225.209137
	C796.061218,232.439743 798.942017,239.585037 799.210205,247.538666
	C799.288208,249.851135 799.855835,252.115204 801.399414,254.434967
	C801.669556,255.326675 801.638184,255.847031 801.231628,256.681213
	C801.646545,263.337128 799.946655,268.815643 795.945129,273.908051
	C794.116272,276.235413 793.620544,279.610291 792.263428,282.754822
	C790.684631,278.288330 792.037476,273.591980 792.318970,268.934082
	C793.128784,255.534576 792.998291,242.421341 785.146545,230.317047
z"
        />
        <path
          fill="#693324"
          opacity="1.000000"
          stroke="none"
          d="
M801.375977,257.111023
	C800.995972,256.556305 800.991943,256.108917 800.984741,255.326050
	C799.821838,253.657761 799.475830,251.900146 799.604797,250.363358
	C800.375183,241.182266 795.955444,233.549347 792.263916,225.310272
	C792.000000,225.011398 791.999695,225.001648 791.993774,225.000427
	C792.324341,222.929260 792.660889,220.859314 793.004822,218.390564
	C795.093262,218.015701 795.367493,220.718201 797.674194,220.955536
	C798.287842,221.187912 798.695557,221.309647 798.820190,221.567963
	C805.701294,235.832001 810.144409,250.466537 805.250000,266.728241
	C802.404724,266.419006 803.217407,264.309937 802.983276,262.634583
	C802.726807,260.799438 803.845520,258.636566 801.375977,257.111023
z"
        />
        <path
          fill="#693324"
          opacity="1.000000"
          stroke="none"
          d="
M782.048950,209.361725
	C787.191223,208.888153 788.531616,213.961517 791.943970,216.656281
	C787.277832,216.506683 783.690125,214.467148 782.048950,209.361725
z"
        />
        <path
          fill="#553635"
          opacity="1.000000"
          stroke="none"
          d="
M747.638550,207.010910
	C747.433167,208.961380 746.594849,209.753815 745.051758,207.335358
	C745.742004,207.012909 746.500610,207.009155 747.638550,207.010910
z"
        />
        <path
          fill="#623B37"
          opacity="1.000000"
          stroke="none"
          d="
M737.642029,188.925629
	C738.316345,191.713715 738.654236,194.438065 738.982666,197.579773
	C734.647339,198.309326 730.511780,196.777084 725.910156,196.445435
	C723.840820,193.796082 724.198120,190.808167 723.414124,188.126526
	C721.844788,182.758499 720.306396,181.382416 714.810791,180.860733
	C711.852783,180.579956 708.828369,181.072159 705.580688,179.930786
	C705.029114,179.609604 704.827820,179.455521 704.396240,179.007370
	C703.371216,177.168991 703.191772,175.468964 701.914978,173.982880
	C698.930298,170.508957 699.849060,167.621384 704.145996,165.623413
	C705.286804,165.092987 706.792419,165.040497 706.754028,162.942001
	C706.478394,160.729248 704.826660,159.608597 704.065063,157.625305
	C703.798279,156.873108 703.696838,156.429749 703.598267,155.642441
	C703.624390,153.888565 703.072754,152.737610 701.861938,151.506287
	C701.660095,150.986877 701.627747,150.762115 701.716064,150.218842
	C706.310913,148.093597 709.110779,150.717743 712.856689,152.846390
	C721.316101,157.653595 726.160706,164.942337 730.441162,173.052719
	C731.309265,174.697586 732.051453,176.294952 732.010925,178.208893
	C731.916504,182.667542 733.819336,186.166260 737.642029,188.925629
z"
        />
        <path
          fill="#77878D"
          opacity="1.000000"
          stroke="none"
          d="
M707.721436,161.771423
	C709.718933,165.199860 709.233154,167.121536 705.126953,166.980103
	C703.477722,166.923325 702.379028,167.439972 701.683838,169.157394
	C700.869751,171.168732 701.539185,172.275253 703.198303,173.248825
	C704.661255,174.107254 705.042603,175.520798 704.885986,177.495514
	C704.461548,178.176331 703.917725,178.453827 703.500122,178.257401
	C699.311279,176.287170 696.275452,177.395905 694.058594,181.439392
	C693.053650,183.272308 691.449341,183.949570 689.249817,182.275284
	C687.825378,181.063950 686.976562,181.843796 685.917908,182.411392
	C681.437012,184.813858 679.518494,183.432755 679.644775,177.799561
	C678.635864,177.549789 678.093140,178.555084 677.292236,178.766495
	C675.527466,179.232361 674.003235,179.007538 672.895874,177.462921
	C671.852539,176.007401 672.365234,174.568481 673.326721,173.367279
	C674.807312,171.517700 675.885681,169.547409 676.160889,166.798065
	C677.067749,164.971191 678.339600,164.282761 680.355957,164.236389
	C682.082642,164.912262 683.462524,165.479782 685.041504,166.454285
	C685.585022,167.113953 685.780396,167.556885 685.916504,168.393616
	C685.861084,170.953217 685.861084,170.953217 689.496704,169.217834
	C690.824951,169.386566 691.550842,169.958527 692.206726,170.839371
	C691.977112,169.474701 690.468445,168.823929 690.764282,167.089935
	C691.229004,166.465210 691.615540,166.232254 692.378906,166.010376
	C694.381104,165.881683 696.145447,165.974640 696.486023,163.348938
	C696.545288,162.135361 696.470398,161.254532 696.278198,160.036560
	C697.990356,153.949570 693.263794,153.538864 689.589111,151.815125
	C688.121277,150.644608 686.497192,150.343262 685.371582,148.699844
	C685.685486,143.361191 689.381592,140.710831 692.490356,143.575394
	C695.182922,146.056534 698.415710,146.908554 701.533020,149.001312
	C701.752808,149.361725 701.756348,149.638824 701.796509,149.769562
	C701.831787,150.102829 701.826904,150.305344 701.860474,150.809311
	C703.273254,151.921249 703.882141,153.296234 704.655212,154.942291
	C704.790955,155.580383 704.783997,155.931595 704.777710,156.546280
	C702.609497,160.150162 705.754578,160.440979 707.721436,161.771423
z"
        />
        <path
          fill="#705250"
          opacity="1.000000"
          stroke="none"
          d="
M701.795288,148.980209
	C699.808289,149.221954 697.865417,149.048157 695.918701,148.954056
	C694.557007,148.888260 693.133789,148.837677 693.067139,147.064133
	C692.981567,144.784073 691.906006,143.841278 689.683289,143.974884
	C686.899048,144.142242 686.206482,145.945465 686.206421,148.707947
	C685.543579,151.698608 683.809448,150.702347 682.266052,150.231598
	C681.326050,149.944870 680.451233,149.329239 679.403503,149.733719
	C678.761719,149.981491 673.191223,160.053696 673.422668,160.711807
	C674.331238,163.295181 673.243958,164.869537 670.608398,166.009705
	C670.029785,166.165756 669.792358,166.207382 669.192627,166.258209
	C665.171204,165.800537 664.174255,163.785446 665.203735,160.358719
	C665.773743,158.461288 666.114746,156.447479 667.367126,154.892609
	C670.558838,150.929962 671.665283,146.536713 670.767700,141.292511
	C670.992493,140.574677 670.999634,140.142487 671.009521,139.387360
	C671.449402,139.059036 671.886536,139.053635 672.651245,139.052841
	C676.374573,143.239426 678.462952,139.475662 680.988770,137.426331
	C683.428772,137.080673 685.767578,137.064789 688.546997,137.057648
	C691.043518,140.614731 694.755310,138.302216 697.745300,139.241516
	C699.043640,139.037018 700.081299,139.058044 701.503296,139.104507
	C701.643616,139.705933 701.559753,140.485641 701.129211,140.824097
	C698.746765,142.696564 697.730103,144.417282 701.060486,146.402573
	C701.573608,146.708435 701.591003,147.845840 701.795288,148.980209
z"
        />
        <path
          fill="#693324"
          opacity="1.000000"
          stroke="none"
          d="
M802.384888,273.014191
	C800.852295,270.215088 803.255798,271.369690 804.625122,271.002075
	C804.856750,272.380096 804.043945,272.971924 802.384888,273.014191
z"
        />
        <path
          fill="#693324"
          opacity="1.000000"
          stroke="none"
          d="
M802.858887,276.765381
	C802.670471,276.849670 802.424866,276.655945 802.125488,276.180237
	C802.309692,276.102783 802.552979,276.299133 802.858887,276.765381
z"
        />
        <path
          fill="#674541"
          opacity="1.000000"
          stroke="none"
          d="
M670.603149,140.991638
	C674.561890,146.641876 672.361572,151.516479 668.558960,155.773041
	C666.450745,158.132935 667.640320,161.140198 666.113281,163.532715
	C665.759583,164.086838 667.462280,164.640594 668.688477,165.008301
	C669.429626,165.306641 669.840759,165.569656 670.312378,166.212585
	C670.306458,166.806824 670.227966,167.039917 669.893433,167.538971
	C666.163391,169.482773 662.896973,171.391693 660.679382,174.736832
	C659.839661,176.003510 658.663330,177.034195 656.645142,175.946991
	C655.461426,172.245010 657.904419,169.397888 658.024048,166.094131
	C658.087585,164.339951 658.501038,162.514084 658.491638,160.699890
	C658.459534,154.502365 657.082703,153.047058 650.979919,152.777252
	C648.659119,152.674652 646.333130,152.655869 644.016541,152.899536
	C638.704102,153.458282 636.906616,155.360397 636.484924,160.777420
	C636.195435,164.495483 634.942383,167.873550 632.466675,171.142761
	C628.536926,171.901367 627.870850,169.315018 627.807068,166.774338
	C627.484314,153.912766 633.715637,145.558014 646.316345,146.223862
	C649.412659,146.387482 652.490662,145.434540 654.639099,145.801590
	C649.303223,141.473465 650.940002,134.853302 650.899536,128.369385
	C651.762573,122.776047 647.885742,118.163239 648.993896,112.447372
	C653.099365,115.706818 653.898743,121.266739 656.479675,125.828499
	C658.635681,129.639114 661.580261,133.040359 662.969849,137.424408
	C663.893433,140.338257 667.522461,140.291565 670.603149,140.991638
z"
        />
        <path
          fill="#5D3732"
          opacity="1.000000"
          stroke="none"
          d="
M725.677734,196.237122
	C730.264526,195.488770 734.682129,193.976883 738.615967,197.945984
	C738.998047,199.376541 739.022888,200.755936 739.060486,202.547180
	C737.936890,202.946060 736.484436,203.385605 735.714600,202.847839
	C730.013550,198.865417 727.343262,202.758301 724.345032,206.933899
	C721.502808,203.781921 724.729370,200.973480 724.791260,197.540726
	C725.059814,196.930054 725.206421,196.703979 725.677734,196.237122
z"
        />
        <path
          fill="#693324"
          opacity="1.000000"
          stroke="none"
          d="
M941.650269,561.844360
	C945.250916,565.509338 946.290100,569.239807 943.415588,573.570068
	C942.226379,575.361633 943.756897,577.450195 943.105347,579.700134
	C942.189026,580.258057 941.390320,580.496582 940.302124,580.863892
	C937.974060,582.329224 936.436890,579.503540 934.166992,580.469910
	C933.458191,580.494995 933.042908,580.373779 932.337891,580.104126
	C931.893555,579.667847 931.755432,579.346924 931.508606,579.149414
	C929.473145,577.520996 927.026672,575.997742 928.227661,572.833008
	C929.339844,569.902161 932.060425,569.654297 934.733582,569.554016
	C935.873840,569.511292 937.019348,569.500854 938.195801,568.533081
	C938.672607,567.790405 938.974304,567.295471 939.161011,566.485657
	C938.952393,565.667053 938.491943,565.385193 937.706787,565.001953
	C932.609131,563.076477 927.695496,561.583557 922.921326,559.692566
	C921.234253,559.024353 919.386475,558.712524 918.061157,556.845764
	C921.945679,554.304932 925.497498,556.843018 929.110718,557.687866
	C933.262451,558.658630 937.250916,560.327576 941.650269,561.844360
z"
        />
        <path
          fill="#DE8A57"
          opacity="1.000000"
          stroke="none"
          d="
M917.591431,556.358032
	C924.728638,558.901489 931.484314,561.312012 938.553345,563.897583
	C939.312073,564.431335 939.514221,564.895874 939.327148,565.838867
	C936.623535,568.159546 934.086365,566.955200 931.684082,565.972534
	C927.558228,564.284668 923.618347,562.162598 919.098145,560.390625
	C914.789856,558.449280 910.017883,558.451416 906.919434,554.451355
	C906.936157,554.060913 907.066223,553.774231 907.040039,553.616699
	C910.412476,554.381104 913.811218,555.303101 917.591431,556.358032
z"
        />
        <path
          fill="#929EAC"
          opacity="1.000000"
          stroke="none"
          d="
M829.958008,368.629822
	C826.807617,370.253326 824.691040,368.750183 822.648804,366.157898
	C822.237366,364.983368 822.145630,363.884918 822.098999,362.375793
	C822.664429,361.609924 823.184692,361.254700 823.704956,360.899475
	C824.653564,364.302155 828.740601,365.069672 829.958008,368.629822
z"
        />
        <path
          fill="#151117"
          opacity="1.000000"
          stroke="none"
          d="
M826.003662,339.997131
	C826.007324,339.994263 826.405151,340.242706 826.405151,340.242706
	C826.405151,340.242706 826.097900,340.231506 826.046143,340.119507
	C825.994385,340.007538 826.000000,340.000000 826.003662,339.997131
z"
        />
        <path
          fill="#282429"
          opacity="1.000000"
          stroke="none"
          d="
M661.614746,371.341492
	C663.201294,369.735321 664.508240,368.648102 666.844604,369.081299
	C667.900024,370.036102 668.688843,370.686310 670.282593,370.137329
	C671.364014,370.052582 672.088013,370.087036 673.063477,370.663940
	C673.638062,371.355164 673.820496,371.838074 673.943359,372.747681
	C673.512451,375.473480 675.082336,376.849304 676.446472,378.294708
	C681.026428,383.147400 683.340088,388.444550 682.074646,395.496216
	C680.878845,402.160095 682.328552,409.167114 680.084961,416.166443
	C676.497253,418.603912 679.537537,419.998322 680.465332,422.015991
	C674.639465,427.418060 668.120789,427.952698 660.782898,425.200317
	C659.841003,424.033569 659.545288,423.089081 659.575317,422.073151
	C659.960449,409.034454 658.484009,395.957397 660.413269,382.948883
	C660.966980,379.215240 659.706726,375.338257 661.614746,371.341492
z"
        />
        <path
          fill="#151117"
          opacity="1.000000"
          stroke="none"
          d="
M648.766113,326.455444
	C652.169189,326.088348 655.215820,325.843018 658.604492,325.005951
	C660.311951,331.635468 659.956177,338.353302 659.986816,345.519684
	C660.008728,352.447937 660.040039,358.909302 659.875122,365.793488
	C656.513672,368.701752 653.023193,370.631531 648.405151,369.779114
	C647.107239,366.821960 647.686096,364.082672 647.512695,360.933044
	C647.521484,351.179840 647.491516,341.894836 647.533447,332.610046
	C647.542297,330.645355 647.243530,328.603943 648.766113,326.455444
z"
        />
        <path
          fill="#3A292B"
          opacity="1.000000"
          stroke="none"
          d="
M658.674316,367.365601
	C659.400574,360.255035 658.245483,353.463043 659.830139,346.372437
	C662.053040,349.874146 660.597961,354.051941 660.965271,358.080750
	C661.337585,362.163788 660.485657,366.303864 661.710632,370.691467
	C660.796326,388.672119 660.979370,406.358032 660.974854,424.843262
	C660.231628,427.322021 660.660095,429.358368 658.968384,431.011292
	C654.308533,430.047272 651.671143,427.269470 650.955811,422.545685
	C659.118652,419.901154 657.036743,413.291870 657.281799,407.294556
	C657.327942,395.734009 656.777710,384.602631 657.015442,373.467468
	C657.058167,371.464813 657.084778,369.451782 658.674316,367.365601
z"
        />
        <path
          fill="#8B9698"
          opacity="1.000000"
          stroke="none"
          d="
M670.609375,370.715820
	C669.824036,371.991180 669.136414,373.055054 667.787720,372.403564
	C666.698242,371.877289 667.026123,370.771606 667.173462,369.464600
	C667.897827,368.974884 668.572754,368.844269 669.582764,368.621826
	C669.917786,368.529968 670.054749,368.860504 670.216492,368.936951
	C670.623901,369.435272 670.742676,369.890259 670.609375,370.715820
z"
        />
        <path
          fill="#1D1415"
          opacity="1.000000"
          stroke="none"
          d="
M634.510864,327.401886
	C638.967346,327.485535 642.844971,325.391937 647.388977,326.056854
	C649.211060,328.930115 648.655518,331.994141 648.404175,334.866974
	C647.675964,343.189026 648.206726,351.508148 647.999817,360.292511
	C647.936646,363.239563 648.152893,365.724884 647.620972,368.609314
	C642.564148,370.809113 638.956787,368.386108 638.574036,362.776001
	C638.241638,357.905090 638.713806,353.006561 638.524719,348.853760
	C638.359192,347.460724 637.379639,348.352509 637.963074,348.029907
	C638.158386,347.921906 638.306519,348.039703 638.308472,348.383881
	C638.376221,360.100891 639.722961,371.843903 637.570312,383.975586
	C637.500916,388.053741 637.588867,391.657471 637.873596,395.278748
	C638.111145,398.298706 638.602844,401.620270 635.332214,404.015381
	C632.471558,400.913757 633.364746,397.221771 633.411926,393.899689
	C633.611938,379.798859 633.439026,365.699768 633.475281,351.600861
	C633.490601,345.651855 632.013245,339.765198 632.509766,333.769623
	C632.686157,331.639191 632.329041,329.400513 634.510864,327.401886
z"
        />
        <path
          fill="#241312"
          opacity="1.000000"
          stroke="none"
          d="
M629.782410,327.399933
	C630.734558,327.020142 631.469360,327.015198 632.570557,327.039917
	C634.034180,335.784515 634.021606,344.591614 633.812195,353.299988
	C633.532227,364.944214 633.712158,376.582184 633.515015,388.220367
	C633.478333,390.382690 633.427856,392.540527 633.325195,395.156738
	C633.462219,397.154327 633.466858,398.688629 633.104980,400.650146
	C631.106140,403.675873 631.605835,406.675842 631.447632,409.587036
	C631.310669,412.107178 632.240417,414.877991 629.569031,417.100494
	C628.030823,416.525360 627.672913,415.445770 627.543213,413.814453
	C627.413025,410.389618 627.768677,407.374542 627.149780,403.999634
	C626.905518,403.093048 626.843872,402.563873 626.863647,401.633362
	C627.824341,399.092194 626.958923,397.252899 625.790771,395.038208
	C625.321899,387.992584 625.611938,381.350281 625.511353,374.243866
	C625.522583,361.051666 625.499939,348.326904 625.528809,335.602264
	C625.533264,333.632629 625.294556,331.626160 626.248901,329.275635
	C626.471863,328.512482 626.514465,328.194855 626.785400,327.454224
	C627.449158,327.030518 627.884521,327.029846 628.646240,327.044128
	C629.356873,327.522858 629.528992,328.055237 629.491760,329.088501
	C629.548889,333.473175 629.385437,337.428375 629.593994,341.378845
	C629.383972,337.711487 629.562439,334.039093 629.504639,329.937225
	C629.499451,328.927155 629.519653,328.350525 629.782410,327.399933
z"
        />
        <path
          fill="#3E1E1C"
          opacity="1.000000"
          stroke="none"
          d="
M603.625977,374.445740
	C604.092590,358.956085 604.250916,343.852966 604.360352,328.395721
	C605.737610,328.228821 607.163696,328.416046 608.787842,328.768127
	C610.255371,334.435486 609.162659,340.009705 609.473938,345.544006
	C609.612549,348.007629 609.535522,350.487396 609.404541,353.375122
	C608.470032,356.100098 606.471252,358.324829 608.965820,361.262634
	C609.515259,363.084351 609.437561,364.510956 608.866943,366.357056
	C607.282288,368.853149 607.496399,371.222778 607.489807,373.542358
	C607.464844,382.362976 607.562805,391.184540 607.425781,400.003632
	C607.392212,402.169128 607.995605,404.944153 604.109009,405.225128
	C603.105774,404.396118 602.694580,403.657990 602.699585,402.816132
	C602.754395,393.485046 601.663696,384.127655 603.625977,374.445740
z"
        />
        <path
          fill="#554441"
          opacity="1.000000"
          stroke="none"
          d="
M632.178223,396.401978
	C630.190308,393.930389 631.950500,391.849304 631.974182,389.788727
	C632.210449,369.188629 632.611572,348.590424 632.951660,327.530640
	C633.345276,326.322815 633.875061,326.381653 634.668335,326.955841
	C633.596008,331.485138 633.838074,336.039459 634.161438,340.482605
	C635.675354,361.282654 634.444641,382.112946 635.114258,403.375763
	C638.012268,407.108887 636.344910,410.722961 635.617798,414.640930
	C631.948486,416.142517 631.914612,413.653046 631.591187,411.530243
	C630.834961,406.567383 632.461792,401.735504 632.178223,396.401978
z"
        />
        <path
          fill="#4C393B"
          opacity="1.000000"
          stroke="none"
          d="
M617.714233,329.380981
	C618.234619,326.973389 619.711853,327.059509 621.529541,327.107239
	C622.444702,327.427399 622.477783,328.015717 622.782837,328.779083
	C622.940857,329.012115 622.966248,328.994720 622.963135,329.007080
	C623.900940,329.986847 624.507996,331.097168 624.463074,332.977478
	C624.393555,351.784119 624.757874,370.108215 624.099976,388.861145
	C623.715210,389.530914 623.518494,389.768951 622.885925,390.147827
	C620.157410,389.190155 620.689575,387.029846 620.544678,385.145050
	C620.358948,382.728790 620.955994,380.207062 619.326416,377.566650
	C618.800842,376.303467 618.731323,375.407898 618.851440,374.053711
	C621.610779,364.741699 620.772644,355.581177 620.805420,346.551239
	C620.823364,341.606750 620.591309,336.599579 620.423279,331.622345
	C621.000305,343.840424 620.835449,356.055511 620.265747,368.703796
	C619.521729,369.440674 618.913025,369.514526 617.881653,369.087158
	C616.636536,367.670349 616.558716,366.335785 616.550781,365.002380
	C616.488281,354.533173 616.497925,344.063812 616.545532,333.594574
	C616.551575,332.263092 616.606812,330.926178 617.714233,329.380981
z"
        />
        <path
          fill="#2A1B1B"
          opacity="1.000000"
          stroke="none"
          d="
M617.686157,328.993805
	C618.049316,342.050873 618.073792,355.103790 618.155640,368.628387
	C619.706421,374.933655 619.398743,380.896637 619.487793,387.299164
	C619.473877,393.302521 619.512512,398.837830 619.499329,404.828369
	C619.498779,405.585480 619.498596,405.887421 619.498657,406.640564
	C619.499939,410.075256 619.501831,413.058777 619.496826,416.493378
	C619.327637,419.349457 619.940796,421.817169 618.638306,424.523132
	C618.076599,425.020508 617.794983,425.148468 617.085815,425.093872
	C614.902527,420.246124 614.514282,415.378174 614.513672,409.980652
	C614.516846,397.312805 614.518616,385.116821 614.510498,372.434540
	C614.519836,362.350616 614.510010,352.753021 614.516296,343.155396
	C614.519226,338.706970 614.273071,334.237762 615.777588,329.449341
	C616.444824,329.002258 616.896179,328.995972 617.686157,328.993805
z"
        />
        <path
          fill="#1F0F0F"
          opacity="1.000000"
          stroke="none"
          d="
M607.670776,355.454163
	C608.549500,346.655731 607.002991,338.151581 608.921570,329.352966
	C609.440125,328.938843 609.894348,328.944733 610.688843,328.965668
	C613.222534,335.249725 612.399353,341.754059 612.461609,348.176575
	C612.553406,357.639893 612.482483,367.104675 612.487427,377.057861
	C612.382935,379.822876 612.747070,382.139801 611.605652,384.753784
	C610.446838,385.779694 609.497986,385.872314 608.081421,385.304504
	C606.495239,378.956635 606.799744,372.863739 607.747742,366.366272
	C608.018860,364.578979 608.016724,363.192322 607.994995,361.390015
	C606.750305,359.371613 606.867737,357.623596 607.670776,355.454163
z"
        />
        <path
          fill="#241312"
          opacity="1.000000"
          stroke="none"
          d="
M611.853638,332.393738
	C612.818115,331.987518 613.508972,331.983459 614.545166,331.992432
	C616.220520,336.035919 615.376343,340.180969 615.448181,344.264740
	C615.602356,353.038513 615.495361,361.816895 615.495728,371.071075
	C615.483093,375.010529 615.521240,378.472382 615.391296,382.399261
	C614.618408,383.681244 613.728271,383.825714 612.319336,383.401245
	C611.187622,381.655182 611.630371,380.038971 611.504395,377.989380
	C611.554504,362.603943 611.404541,347.699646 611.853638,332.393738
z"
        />
        <path
          fill="#3B3036"
          opacity="1.000000"
          stroke="none"
          d="
M647.350525,361.258911
	C646.432190,349.683014 647.653259,338.349365 647.857666,326.542328
	C648.159790,325.980896 648.501038,325.869537 648.968384,325.928589
	C649.039612,337.449097 648.984741,348.799225 648.875977,360.621033
	C648.475891,361.398895 648.071167,361.511047 647.350525,361.258911
z"
        />
        <path
          fill="#5C4747"
          opacity="1.000000"
          stroke="none"
          d="
M612.084900,331.689301
	C612.104431,347.028687 612.081726,362.065826 611.995911,377.570496
	C611.810364,378.209564 611.687927,378.381073 611.361267,378.311310
	C611.120056,362.020599 611.083008,345.971100 611.037598,329.451172
	C612.861694,329.153259 611.612915,330.623627 612.084900,331.689301
z"
        />
        <path
          fill="#554441"
          opacity="1.000000"
          stroke="none"
          d="
M626.700073,327.791534
	C626.949402,343.128082 627.010193,358.111603 627.013306,373.562805
	C626.635498,374.079773 626.315308,374.129028 625.640869,373.845825
	C624.172363,371.866760 624.517944,369.992249 624.514221,368.195618
	C624.490540,356.922516 624.491150,345.649323 624.513794,333.895203
	C624.532043,332.121887 624.500732,330.819550 625.686646,329.355286
	C625.993835,328.981201 626.037659,328.534821 626.039551,328.310577
	C626.198059,327.870392 626.354797,327.654449 626.700073,327.791534
z"
        />
        <path
          fill="#594E52"
          opacity="1.000000"
          stroke="none"
          d="
M615.544067,371.600830
	C615.084961,359.021301 615.020386,345.980988 614.923218,332.473053
	C614.983948,330.733917 615.013367,329.453003 615.197327,328.194763
	C615.291260,327.552887 616.022339,327.556396 615.638977,328.100830
	C615.601685,328.153748 615.789917,328.365479 615.933472,328.756042
	C615.975220,343.052368 615.956909,357.096222 615.544067,371.600830
z"
        />
        <path
          fill="#241312"
          opacity="1.000000"
          stroke="none"
          d="
M625.615112,328.980927
	C625.995972,330.055878 625.998108,331.130554 625.966187,332.606201
	C625.262329,333.586365 624.502441,333.719910 623.329590,333.232422
	C622.980957,331.937927 622.966919,330.876099 622.956421,329.416870
	C623.718811,329.006531 624.477661,328.993591 625.615112,328.980927
z"
        />
        <path
          fill="#3E1E1C"
          opacity="1.000000"
          stroke="none"
          d="
M629.259827,329.323608
	C629.045837,328.642090 629.017944,328.189484 628.981262,327.398010
	C629.248047,326.948120 629.523560,326.837189 629.899414,326.875671
	C629.981506,327.496674 629.963196,327.968262 629.908569,328.792175
	C629.730103,329.280548 629.588013,329.416534 629.259827,329.323608
z"
        />
        <path
          fill="#3E1E1C"
          opacity="1.000000"
          stroke="none"
          d="
M622.927673,328.717285
	C622.628906,328.559662 622.316956,328.107208 621.948059,327.379333
	C622.232239,327.543427 622.573364,327.982941 622.927673,328.717285
z"
        />
        <path
          fill="#3E1E1C"
          opacity="1.000000"
          stroke="none"
          d="
M626.929932,327.867371
	C626.888550,328.144592 626.467102,328.078644 626.254272,328.082489
	C626.134705,327.587524 625.758911,326.857513 626.851624,326.901306
	C626.999634,327.217499 626.985474,327.403839 626.929932,327.867371
z"
        />
        <path
          fill="#A67A5E"
          opacity="1.000000"
          stroke="none"
          d="
M388.193542,652.944946
	C387.292419,653.078369 386.537903,653.089417 386.022797,652.743713
	C374.798828,645.212097 362.845001,646.558960 350.633728,649.540466
	C343.889069,651.187195 337.038055,652.451721 329.999329,651.928650
	C328.656738,651.828979 327.338409,651.986877 326.200928,650.413330
	C327.097748,648.768250 328.657806,648.950439 330.094360,649.064636
	C337.036041,649.616455 343.256714,647.681824 348.927551,643.774963
	C350.257721,642.858643 351.749298,643.074219 353.613647,643.008118
	C362.649536,640.384521 370.801788,641.254333 378.697937,646.657837
	C379.847351,648.046753 381.067078,647.992004 382.597229,648.088989
	C385.315460,647.516541 386.927429,648.636841 388.408569,650.775696
	C388.672638,651.649841 388.633606,652.158569 388.193542,652.944946
z"
        />
        <path
          fill="#87655F"
          opacity="1.000000"
          stroke="none"
          d="
M378.971436,646.615662
	C371.352142,643.390076 363.027130,643.813782 354.459534,643.010010
	C354.027496,640.461121 356.184845,638.894714 356.946350,636.322510
	C366.977112,633.708679 372.611145,640.707153 378.971436,646.615662
z"
        />
        <path
          fill="#784534"
          opacity="1.000000"
          stroke="none"
          d="
M388.314880,651.407593
	C386.472534,650.260986 384.945343,649.412109 383.187073,648.332397
	C382.970184,647.647339 382.984406,647.193054 382.999634,646.398560
	C385.202698,644.020874 387.677917,644.904297 390.526642,645.445557
	C391.488403,645.526733 392.038696,645.569031 392.988525,645.724487
	C398.212280,647.022949 403.065094,646.678650 408.303741,645.964478
	C409.725983,645.511841 410.797607,645.510742 412.258942,645.513977
	C416.453186,645.397156 420.093933,644.447083 424.039978,643.181335
	C424.836609,642.860413 425.305176,642.725891 426.139343,642.588867
	C429.720001,641.754944 432.881470,640.702576 435.585205,638.771240
	C437.124359,637.671692 438.927979,636.406921 438.258362,633.635620
	C437.778961,632.460754 437.561584,631.559814 437.209686,630.326660
	C437.132751,629.730591 437.125671,629.487671 437.179565,628.881775
	C437.486237,627.795593 437.917633,627.205261 438.854431,626.575317
	C443.992859,627.003662 445.774933,631.045837 448.126282,634.671265
	C448.250183,637.377502 446.615936,639.154419 446.060242,641.636902
	C442.668549,645.972656 437.556641,645.432129 433.238281,646.763367
	C421.182373,650.480042 408.847504,652.996155 396.184387,653.470642
	C393.554810,653.569214 390.838531,653.608643 388.314880,651.407593
z"
        />
        <path
          fill="#3A292B"
          opacity="1.000000"
          stroke="none"
          d="
M391.245483,646.703369
	C388.577423,646.716980 386.193146,646.382996 383.404755,646.053711
	C382.973389,645.644714 382.946136,645.230896 382.895752,644.510498
	C383.064514,641.059448 387.572937,642.752563 387.933228,639.381592
	C388.413605,638.598755 388.928162,638.358398 389.966370,638.362305
	C390.960907,638.661804 391.415497,639.028931 391.965454,639.924438
	C392.685242,642.413879 392.966919,644.475037 391.245483,646.703369
z"
        />
        <path
          fill="#554441"
          opacity="1.000000"
          stroke="none"
          d="
M345.359406,574.955200
	C342.576416,574.787903 340.206970,574.416748 338.171814,572.312988
	C336.091766,567.671692 337.432892,563.934143 340.355408,560.672852
	C344.318298,556.250549 348.716522,552.196411 351.237183,546.648376
	C351.886658,545.218872 353.001312,544.403809 354.769958,544.451782
	C357.315094,544.520935 359.591675,543.570618 361.891754,541.773621
	C363.131165,540.756714 364.388458,540.416992 365.463379,539.168701
	C367.279846,537.817688 369.022675,537.505737 370.871613,537.499023
	C372.859619,537.491821 375.004395,537.312866 376.252716,539.175537
	C377.583038,541.160461 376.348755,542.964355 375.193848,544.581970
	C373.660919,546.729126 372.791321,548.894043 374.549500,551.720276
	C375.269745,553.208313 375.701569,554.393616 375.348145,556.358887
	C367.351624,557.112976 361.972809,561.647827 357.267273,566.811035
	C353.938324,570.463684 350.302887,573.252502 345.359406,574.955200
z"
        />
        <path
          fill="#635B5F"
          opacity="1.000000"
          stroke="none"
          d="
M372.766449,564.329956
	C374.126770,563.756531 375.252350,563.513123 376.696899,563.125122
	C379.046448,561.141724 381.027954,562.098267 382.977112,563.120239
	C393.965210,568.881531 404.907959,574.763123 414.902740,582.142273
	C420.826050,586.515503 427.895294,588.752808 434.039581,593.322693
	C435.007660,594.396973 435.498993,595.450012 436.873108,596.088379
	C437.328247,596.411804 437.502838,596.553406 437.909241,596.941528
	C440.676300,600.638245 444.887848,601.537292 448.492767,603.959778
	C448.954773,604.250977 449.127380,604.382324 449.524567,604.741943
	C450.267761,605.589355 450.649628,606.357544 451.820038,606.701538
	C452.528687,606.984863 452.918549,607.194885 453.572266,607.645020
	C458.991821,611.623413 464.091064,615.442444 469.562195,618.739563
	C471.754059,620.060486 474.433380,621.225220 473.432922,624.996521
	C468.514587,627.759644 464.061859,626.631226 460.241852,623.618042
	C456.611938,620.754883 453.343872,617.405273 449.928284,614.260864
	C443.358551,608.212769 436.563019,602.396851 428.057556,598.368225
	C422.843048,596.701904 419.208954,593.090210 414.933960,590.545532
	C409.534729,587.331604 404.853241,582.841614 398.282501,581.882324
	C397.809967,581.813354 397.272949,581.532410 396.997467,581.150452
	C392.191833,574.486572 385.329224,572.216797 377.510040,571.894836
	C376.700378,571.861572 375.945618,571.357056 374.897156,570.714722
	C373.093903,568.823853 371.924713,567.053345 372.766449,564.329956
z"
        />
        <path
          fill="#151E25"
          opacity="1.000000"
          stroke="none"
          d="
M342.314148,596.006592
	C341.969971,592.099243 344.370239,591.275208 347.169189,590.780090
	C348.945526,590.466003 350.693665,589.992065 352.738831,589.346741
	C357.466461,585.984436 362.825104,586.104431 368.211121,584.840515
	C369.262207,584.639038 368.445190,584.815186 369.093445,584.663452
	C380.612061,581.966553 391.321655,582.558960 400.480560,591.387085
	C405.589172,596.311218 412.056122,599.623840 417.328186,604.971802
	C417.520691,605.589966 417.547424,605.849365 417.467804,606.482178
	C415.629456,608.576477 414.110626,607.136047 412.188965,606.428955
	C409.298676,605.233582 407.131531,603.423828 404.186829,602.368652
	C403.654816,602.167908 403.449310,602.069885 402.959534,601.783997
	C401.692017,600.748901 401.020844,599.438477 399.114990,599.393555
	C392.629486,597.896667 386.318512,596.844055 381.611145,591.446716
	C381.295502,590.595703 381.283325,590.086609 381.578430,589.237610
	C383.785889,588.104980 385.629059,589.231384 387.906799,589.504639
	C389.176147,590.234497 390.187744,590.674561 391.350769,590.710449
	C390.666687,590.615051 389.993011,590.473267 389.052917,590.057129
	C386.470703,589.177246 384.196564,588.337830 381.390167,588.886230
	C379.436218,589.824646 377.560272,589.129272 375.593231,590.078979
	C373.098511,590.345825 371.549713,592.047791 369.103760,592.484436
	C360.841125,594.395264 352.807098,595.347290 344.466339,596.937012
	C343.476440,596.930176 342.961121,596.733765 342.314148,596.006592
z"
        />
        <path
          fill="#4B555C"
          opacity="1.000000"
          stroke="none"
          d="
M453.308777,608.269836
	C452.556030,607.998718 452.115723,607.995056 451.345184,607.989380
	C450.474426,607.460205 449.935577,606.931274 449.211487,606.187073
	C449.013855,605.984314 449.009613,605.492188 449.005920,605.246094
	C445.349701,602.215576 441.074554,600.383423 437.253448,597.238281
	C437.006836,596.996948 437.000000,597.000000 437.000549,596.996582
	C436.243439,596.305298 435.182007,596.118469 434.210205,595.216797
	C431.455963,592.187500 427.785400,591.053589 424.576263,589.627625
	C417.929626,586.674072 412.714752,581.696289 406.541595,578.206909
	C397.105865,572.873230 388.213989,566.460693 377.401001,562.985718
	C376.377350,561.226074 375.738861,559.471741 375.053284,557.369629
	C375.006256,557.021973 375.011414,557.003418 375.019409,557.004150
	C376.739685,555.408691 378.458252,556.148376 380.059418,557.118286
	C393.527893,565.276794 407.184509,573.119629 420.662903,581.265137
	C430.748199,587.359985 440.702820,593.669678 451.082642,599.980469
	C453.682007,603.864197 458.128510,604.724243 461.158508,607.964111
	C461.200195,608.576782 461.118469,608.825317 460.754761,609.356384
	C457.906647,611.027283 455.717041,610.074158 453.308777,608.269836
z"
        />
        <path
          fill="#6D5A5B"
          opacity="1.000000"
          stroke="none"
          d="
M369.243958,586.151428
	C364.014404,587.362610 358.936615,588.207825 353.441040,589.078369
	C355.866089,581.229065 362.295715,575.581360 366.003510,568.218140
	C367.178406,565.884827 369.178314,563.938843 372.624451,564.001343
	C373.637817,565.764526 374.274445,567.528992 374.956726,569.644287
	C376.399902,571.756531 375.609039,573.542847 374.843719,575.159729
	C373.141388,578.755981 371.661469,582.475464 369.243958,586.151428
z"
        />
        <path
          fill="#92BFCE"
          opacity="1.000000"
          stroke="none"
          d="
M341.863708,595.829956
	C342.451660,595.942017 342.778870,596.255188 343.373901,596.780884
	C345.674377,596.798279 346.960876,598.165344 348.424500,599.220886
	C354.840271,603.848022 360.887085,608.961853 367.644745,613.720581
	C368.127167,614.060913 368.309906,614.212646 368.734802,614.627014
	C370.051208,616.819763 371.741821,618.081055 373.495636,619.359436
	C375.201263,620.602722 376.998688,621.998413 376.161377,624.898254
	C371.922546,626.124695 370.262878,623.138672 368.181946,620.274414
	C359.300446,612.844360 350.560455,605.758545 341.558411,598.430176
	C341.296356,598.187622 341.242462,598.210510 341.246582,598.239380
	C341.078491,598.112854 340.906281,597.957458 340.809570,597.462524
	C341.124390,596.758911 341.363708,596.394958 341.863708,595.829956
z"
        />
        <path
          fill="#31383F"
          opacity="1.000000"
          stroke="none"
          d="
M340.755341,597.255737
	C340.953339,597.309387 341.021606,597.495911 341.170319,597.975342
	C341.042328,597.975037 340.833954,597.681885 340.755341,597.255737
z"
        />
        <path
          fill="#243A45"
          opacity="1.000000"
          stroke="none"
          d="
M367.909943,620.242981
	C370.473602,621.649536 372.906738,623.368896 376.051147,625.063354
	C378.224426,625.836426 379.343292,627.061401 380.559296,628.153564
	C387.534882,634.418823 395.946960,635.558472 404.932037,631.170349
	C409.708099,629.916199 414.094666,628.158691 418.960480,630.432129
	C419.092072,634.003967 416.553528,634.329529 414.317261,634.394714
	C411.731720,634.469971 409.906281,635.434082 408.189117,637.763428
	C402.172577,640.658936 396.390442,640.407898 390.151550,638.818237
	C389.356659,638.998840 388.965363,638.997559 388.280640,639.011475
	C386.887085,639.320862 385.786957,639.614929 384.344543,639.941467
	C383.960266,638.125610 383.777649,636.074158 382.062805,635.192261
	C377.068909,632.623840 374.682831,626.619690 368.485168,625.164307
	C367.941101,624.753723 367.719482,624.473083 367.245056,624.099365
	C366.992218,624.006226 366.980499,623.562439 366.973389,623.340820
	C367.237305,622.264709 367.508362,621.410217 367.909943,620.242981
z"
        />
        <path
          fill="#43434B"
          opacity="1.000000"
          stroke="none"
          d="
M362.826599,541.453430
	C362.324738,545.141785 359.625641,545.055237 357.170166,544.954407
	C354.209076,544.832703 352.528412,545.830200 351.976654,548.904602
	C351.222046,553.109192 347.466125,555.443970 344.875671,558.167847
	C341.192322,562.040894 338.463654,565.871704 338.052307,571.618347
	C335.167633,571.655579 332.759003,572.849243 330.553558,574.311951
	C322.902496,579.386169 315.439392,579.545837 307.617767,574.448975
	C305.226776,572.890991 305.126953,570.252991 303.152344,568.305664
	C302.987427,567.555176 302.990723,567.110352 302.994507,566.331909
	C305.161591,562.812439 308.556580,563.280090 311.604279,563.443604
	C316.817780,563.723389 319.650757,561.799072 322.509857,557.028198
	C327.080505,549.401306 333.886993,543.327820 341.626740,538.066406
	C346.013824,538.982239 349.518921,541.151917 353.222473,542.847961
	C354.581360,543.470276 355.802002,544.726440 356.963074,542.248779
	C357.575439,541.046204 358.425049,540.635803 359.697937,540.252319
	C361.064484,540.102661 361.960724,540.348389 362.826599,541.453430
z"
        />
        <path
          fill="#3E474F"
          opacity="1.000000"
          stroke="none"
          d="
M341.715942,537.741943
	C337.422119,542.748535 332.848083,547.492432 328.267365,552.229858
	C325.769440,554.813232 323.078430,557.074646 322.296783,561.041870
	C321.671539,564.215149 318.639252,564.908264 315.614868,565.018311
	C311.676300,565.161499 307.661621,564.363647 303.407959,565.934998
	C297.949432,564.169617 297.004730,558.949097 294.201477,555.276855
	C292.663269,553.261841 291.250916,551.531311 288.535065,550.826416
	C285.040039,549.919434 284.939514,545.493408 282.062622,543.345093
	C287.336884,540.559875 292.068420,540.477844 297.505432,543.976929
	C304.015320,548.166565 311.672791,543.978394 318.804169,544.754761
	C319.765015,544.859436 320.723724,543.866333 320.760681,542.810913
	C320.928986,537.999451 324.250671,538.022522 327.733398,537.973083
	C332.301331,537.908264 336.866547,537.651062 341.715942,537.741943
z"
        />
        <path
          fill="#534A50"
          opacity="1.000000"
          stroke="none"
          d="
M292.021912,561.798462
	C293.045105,563.417480 294.679718,564.767151 293.227020,566.381714
	C292.211517,567.510559 290.732635,566.979919 289.704041,566.052856
	C288.541290,565.004883 286.615295,563.983398 287.444458,562.145996
	C288.307861,560.232666 290.180786,561.011475 292.021912,561.798462
z"
        />
        <path
          fill="#3C7D8E"
          opacity="1.000000"
          stroke="none"
          d="
M331.995422,290.812744
	C332.065308,291.190948 331.954376,291.508545 331.903320,291.668549
	C330.774139,291.658997 329.695953,291.489441 328.317932,291.182312
	C327.855774,290.897888 327.693451,290.751038 327.265717,290.797546
	C316.426422,289.970093 305.782959,289.449127 295.262512,286.277252
	C294.420197,278.559601 293.778656,271.165955 293.193268,263.346558
	C294.318756,261.132050 294.280792,259.304413 293.216187,257.221924
	C292.928162,256.571198 292.865814,256.147125 292.832764,255.402588
	C295.451111,254.542480 295.026031,252.517258 294.856720,250.773636
	C294.304321,245.084930 293.647461,239.406372 293.050964,233.288376
	C296.365875,231.189957 299.951080,231.782196 303.288605,232.114380
	C311.016022,232.883514 318.794708,232.669235 326.761871,234.031403
	C327.289825,236.243561 329.303345,237.390335 328.893066,239.794678
	C327.987579,241.181931 327.481323,242.585739 325.615234,243.161346
	C318.927673,242.246613 312.586060,242.524078 305.894531,242.423187
	C304.347290,243.052536 302.996155,240.578186 301.935730,242.900177
	C302.097961,243.807892 302.154663,244.427795 302.180908,245.377991
	C302.034729,247.028732 302.032532,248.369629 301.578003,249.993790
	C301.109894,250.932983 300.584625,251.415680 300.051941,252.333374
	C299.275879,258.170502 299.204437,263.679138 300.145874,269.536438
	C300.329224,271.273529 300.068695,272.644379 300.162170,274.323303
	C300.200134,275.018341 300.243896,275.405945 300.496460,276.022095
	C307.405945,277.694855 314.325439,277.543732 321.119202,278.533051
	C322.590027,278.747223 324.103668,278.687317 325.020081,280.522339
	C328.415070,283.278961 330.827515,286.380005 331.995422,290.812744
z"
        />
        <path
          fill="#B3D5DF"
          opacity="1.000000"
          stroke="none"
          d="
M308.878754,292.452454
	C309.906494,292.037994 310.746521,291.892975 311.273743,292.692291
	C311.382599,292.857300 310.981781,293.650940 310.699341,293.728027
	C309.773438,293.980927 308.723541,294.166687 308.878754,292.452454
z"
        />
        <path
          fill="#337484"
          opacity="1.000000"
          stroke="none"
          d="
M327.251740,291.061646
	C327.275421,290.909973 327.550537,290.829041 327.921844,290.896423
	C327.846436,291.073944 327.674805,291.103180 327.251740,291.061646
z"
        />
        <path
          fill="#246876"
          opacity="1.000000"
          stroke="none"
          d="
M292.986450,257.372040
	C296.223389,258.433533 295.301239,260.545135 293.584717,262.825928
	C293.174805,262.188904 293.100220,261.457092 292.952911,260.364258
	C292.914276,259.251770 292.948334,258.500305 292.986450,257.372040
z"
        />
        <path
          fill="#47A2AE"
          opacity="1.000000"
          stroke="none"
          d="
M325.419006,242.281067
	C326.625366,241.465698 327.437195,240.824020 328.558777,240.163986
	C328.683960,251.257736 329.650055,262.299652 330.849426,273.766357
	C330.341309,274.989166 330.564331,275.872528 330.316193,276.998169
	C329.409088,278.301575 328.533905,278.561951 327.288971,277.377777
	C326.291870,276.313599 325.152374,276.132233 324.015991,275.334686
	C323.736359,274.927460 323.679413,274.737091 323.645996,274.274628
	C318.939056,272.412689 313.804443,273.214783 308.847351,271.266205
	C308.424286,270.530365 308.368774,270.050720 308.559540,269.213959
	C313.170776,266.608795 317.616669,269.127350 322.081146,268.980743
	C323.013885,268.950134 323.972321,269.480133 325.257202,269.141296
	C325.689972,268.897949 325.840057,268.773926 326.141724,268.410767
	C326.346161,267.993317 326.399078,267.814972 326.477081,267.360870
	C326.517822,266.523773 326.397095,265.985046 326.145996,265.179260
	C326.010071,264.716919 325.960144,264.528992 325.850830,264.052490
	C325.729828,263.372559 325.684387,262.979309 325.631104,262.285095
	C325.597076,261.785980 325.586884,261.586029 325.562683,261.086334
	C325.482971,259.784393 325.256500,258.810577 324.954956,257.541626
	C324.803802,256.849854 324.743164,256.450928 324.669067,255.753036
	C324.626251,255.261307 324.614532,255.065842 324.591003,254.581940
	C324.571289,254.104813 324.563416,253.916107 324.536774,253.450958
	C324.468811,252.808044 324.355713,252.463318 323.949463,251.964569
	C322.203613,251.146545 320.331604,251.630600 318.839050,250.039734
	C318.688446,249.515244 318.704071,249.286514 318.922607,248.775803
	C320.018738,247.691788 321.229279,247.657013 322.670441,247.237961
	C316.881531,244.947571 311.192078,245.607224 305.216675,245.408401
	C303.443512,245.291840 302.392181,244.569855 301.220398,243.269028
	C299.935150,242.796661 298.835510,242.330002 299.298767,241.210815
	C300.120178,239.226562 301.882629,240.005981 303.686829,240.470596
	C305.063293,242.424484 306.843079,241.961945 308.406586,241.976517
	C313.949707,242.028229 319.496552,241.886887 325.419006,242.281067
z"
        />
        <path
          fill="#85C1D1"
          opacity="1.000000"
          stroke="none"
          d="
M327.331360,234.275269
	C316.014496,233.897491 305.003693,233.359085 293.531982,232.836761
	C291.221985,230.252396 293.684937,230.961548 294.648804,230.985611
	C299.462921,231.105850 304.273468,231.368607 309.490051,231.586884
	C315.728607,232.361237 321.672729,231.627716 327.928589,232.936768
	C328.136810,233.364853 327.887146,233.739746 327.331360,234.275269
z"
        />
        <path
          fill="#67BFC6"
          opacity="1.000000"
          stroke="none"
          d="
M329.777435,277.327271
	C330.138947,276.192688 330.261902,275.406525 330.595642,274.414154
	C330.904724,277.729004 332.370453,282.248688 325.443237,280.927734
	C316.378204,279.028259 307.557281,278.875214 298.818695,277.979279
	C298.540405,277.950775 298.186615,277.789795 298.049194,277.574799
	C297.963745,277.441040 298.219696,277.089081 298.612274,276.717285
	C303.731995,274.682556 308.652161,275.662109 313.591797,275.993439
	C318.935364,276.351868 324.109436,278.232300 329.777435,277.327271
z"
        />
        <path
          fill="#85EAF4"
          opacity="1.000000"
          stroke="none"
          d="
M360.659760,255.931671
	C361.037476,258.128967 360.925354,260.239319 360.092102,262.647949
	C353.981506,263.417419 348.200806,262.125427 342.186523,259.968506
	C340.845337,258.592255 340.515717,257.286102 340.877960,255.406036
	C340.984314,253.285126 341.002777,251.573074 341.031128,249.433273
	C343.351196,246.227112 345.499939,246.164749 347.826172,249.121216
	C348.815338,250.378357 350.595642,249.944016 352.062012,250.043121
	C356.000275,250.309265 357.640747,253.827515 360.659760,255.931671
z"
        />
        <path
          fill="#76D4DF"
          opacity="1.000000"
          stroke="none"
          d="
M341.716553,259.250427
	C345.958466,259.625183 349.929077,260.549683 353.950104,260.908295
	C356.025269,261.093384 357.685913,261.727325 359.660889,262.947144
	C360.160614,265.494873 360.290344,267.955048 360.670380,270.723755
	C362.337341,272.833771 361.132050,274.892639 361.454163,277.216919
	C355.472717,279.488800 349.832428,278.795776 344.166046,275.343903
	C341.753387,271.549896 341.259308,267.493469 340.283020,263.300049
	C342.371399,262.365662 340.799255,260.607483 341.716553,259.250427
z"
        />
        <path
          fill="#67BFC6"
          opacity="1.000000"
          stroke="none"
          d="
M343.913574,275.449097
	C349.174103,276.650421 354.926208,275.743500 360.549225,277.921143
	C361.155609,277.790619 361.383850,277.590363 361.972198,277.668976
	C362.609894,278.098633 362.795593,278.324829 362.918945,278.849670
	C361.944214,281.977661 360.276703,283.631134 356.841370,283.066650
	C354.583130,282.695587 352.206055,282.846375 350.104401,284.945190
	C347.147217,282.587494 345.593842,279.176483 343.913574,275.449097
z"
        />
        <path
          fill="#95F8FC"
          opacity="1.000000"
          stroke="none"
          d="
M341.966431,258.579315
	C341.884766,260.213440 342.240967,261.600922 340.713074,262.792572
	C340.013580,260.564148 339.653931,258.146820 339.392029,255.362457
	C339.818054,254.976883 340.146332,254.975494 340.720276,254.994232
	C342.066620,255.834717 341.954315,257.048279 341.966431,258.579315
z"
        />
        <path
          fill="#37343B"
          opacity="1.000000"
          stroke="none"
          d="
M362.010315,143.746201
	C360.552246,145.287964 359.096558,146.585510 357.318604,147.941925
	C356.085632,143.218430 353.961670,138.568130 355.646057,133.516312
	C356.277771,131.621567 356.033905,129.882736 353.406738,129.052490
	C353.039459,127.276009 353.035950,125.562653 353.048157,123.421112
	C355.293793,120.603561 356.049957,117.598419 356.645782,114.513344
	C357.057648,112.380585 358.075043,110.511620 359.629913,108.489998
	C360.160248,108.075729 360.397705,107.938293 361.031036,107.669479
	C373.408569,102.176224 386.275726,101.602135 398.306305,104.987091
	C407.308350,107.519951 415.637085,105.721741 424.248962,105.938759
	C431.882568,106.131126 439.517181,106.434784 447.149078,106.636223
	C453.318024,106.799042 458.926636,109.713135 464.740295,112.664658
	C465.000305,114.729073 464.999420,116.443367 464.997437,118.586998
	C460.562256,118.539810 455.023407,118.536560 452.044952,116.555168
	C441.910278,109.813225 431.167999,114.501808 420.880371,113.048958
	C416.683075,112.456192 412.833771,110.604980 408.336517,110.850143
	C402.068024,111.191887 396.146667,114.184021 389.739746,113.015671
	C388.123993,112.721016 386.392700,112.846870 384.860992,112.338333
	C374.359222,108.851601 367.364075,112.102394 363.786438,122.235420
	C362.973114,124.539078 362.667358,126.966103 360.831299,128.959152
	C359.098358,130.840286 360.682098,132.223465 362.417389,133.246078
	C364.709045,134.596588 365.433960,136.260956 363.165588,138.688934
	C362.264709,139.999512 362.303864,141.249329 362.019592,142.710297
	C362.016022,143.013565 362.012695,143.501984 362.010315,143.746201
z"
        />
        <path
          fill="#483A3F"
          opacity="1.000000"
          stroke="none"
          d="
M465.403076,113.012070
	C460.861847,113.448456 457.102692,111.557755 453.675842,110.008232
	C450.127625,108.403831 446.783112,108.012360 443.135803,107.988243
	C439.155823,107.961929 435.206390,107.672028 431.228607,107.267288
	C426.044739,106.739845 420.762726,106.382996 415.500793,108.494972
	C408.014862,111.499603 401.125946,106.638672 393.924164,105.635628
	C387.084381,104.682991 380.274506,104.836288 373.517853,105.215477
	C369.515625,105.440071 366.307831,109.681145 361.342041,108.946907
	C359.611816,106.390305 361.570801,105.428185 363.289978,104.432777
	C364.880310,103.511986 366.750183,103.268333 368.711670,102.264435
	C371.707672,102.014381 374.405304,102.002251 377.552673,101.994934
	C388.049225,103.099678 398.124298,102.275276 408.592407,102.232765
	C411.040314,102.020782 413.080597,102.019470 415.557983,102.027092
	C418.387268,104.894531 421.798187,104.857445 425.015900,104.947281
	C431.125031,105.117859 437.262726,105.317581 443.351288,104.925941
	C452.347504,104.347275 460.507233,106.946770 468.769104,110.748459
	C469.016785,111.442245 469.016724,111.880196 469.020203,112.646103
	C467.950836,112.985817 466.877869,112.997581 465.403076,113.012070
z"
        />
        <path
          fill="#543937"
          opacity="1.000000"
          stroke="none"
          d="
M408.531616,102.011200
	C406.469543,103.902664 403.555664,102.965813 400.847717,102.939873
	C393.442261,102.868935 386.017944,103.544380 378.316040,102.272583
	C388.022705,101.999939 398.042969,102.000130 408.531616,102.011200
z"
        />
        <path
          fill="#543937"
          opacity="1.000000"
          stroke="none"
          d="
M368.621033,102.027939
	C367.442413,105.463860 362.233826,104.304001 361.048645,108.638390
	C360.996307,108.986420 360.497314,109.000145 360.247742,109.004555
	C359.729645,109.326057 359.392792,109.516266 358.473602,109.265053
	C359.436798,103.768303 363.759949,102.788757 368.621033,102.027939
z"
        />
        <path
          fill="#693E39"
          opacity="1.000000"
          stroke="none"
          d="
M432.528076,102.015274
	C430.617584,103.692612 428.107025,103.068001 425.324341,102.276718
	C427.393311,102.015091 429.740875,102.012291 432.528076,102.015274
z"
        />
        <path
          fill="#543937"
          opacity="1.000000"
          stroke="none"
          d="
M355.992859,109.988129
	C356.453583,109.665428 356.923737,109.355309 357.682312,109.008186
	C357.970703,108.971176 357.991699,108.991364 357.999817,109.001839
	C358.171570,110.046600 357.557220,110.357018 356.355774,110.226631
	C356.023254,110.022659 356.002289,110.000710 355.992859,109.988129
z"
        />
        <path
          fill="#483A3F"
          opacity="1.000000"
          stroke="none"
          d="
M356.003967,110.382721
	C356.562744,109.836311 357.102203,109.649971 357.824829,109.237968
	C358.450226,109.009613 358.892487,109.006920 359.666504,109.006592
	C358.949982,112.083138 358.082184,115.175369 357.545288,118.412498
	C357.234192,120.288490 356.561707,122.717361 353.430054,122.982422
	C353.048981,119.972023 353.034058,116.951118 353.043640,113.479843
	C354.631653,113.021507 355.952942,112.704521 356.003967,110.382721
z"
        />
        <path
          fill="#394148"
          opacity="1.000000"
          stroke="none"
          d="
M362.264862,142.757904
	C360.464783,141.557861 361.882568,140.511536 362.765625,139.166779
	C362.845337,140.151352 362.679535,141.326782 362.264862,142.757904
z"
        />
        <path
          fill="#483A3F"
          opacity="1.000000"
          stroke="none"
          d="
M577.957764,93.350212
	C583.861938,93.290436 589.477356,93.582748 595.440308,91.609161
	C599.311523,90.327911 604.446838,92.614761 608.955750,93.582382
	C613.445801,94.545959 617.863647,95.848274 622.306274,97.027901
	C623.075195,97.232063 624.171875,97.338974 624.505005,97.872787
	C628.150940,103.715584 634.821411,105.998070 639.716858,110.849487
	C643.977295,117.518135 646.612610,124.349388 645.743103,132.211884
	C645.280396,136.395599 643.586060,139.531082 639.577026,141.097183
	C636.005920,142.492233 632.848083,144.781021 629.139282,145.857605
	C627.186279,146.424545 625.225647,146.693222 622.941956,145.486511
	C621.230408,143.424088 621.592407,141.365646 621.784119,138.939957
	C623.740784,131.503174 629.761414,125.844170 628.362976,117.660507
	C627.793518,114.328384 626.891663,111.610153 623.982971,109.757057
	C618.396057,106.197624 612.652405,102.563187 606.772888,99.927979
	C601.207825,97.433746 594.314270,96.975182 587.812988,97.697815
	C586.005493,97.898727 584.254395,98.529137 581.999756,98.460594
	C580.198792,98.416870 578.776123,98.507881 577.264160,97.322975
	C577.293457,95.897697 577.604004,94.795097 577.957764,93.350212
z"
        />
        <path
          fill="#43434B"
          opacity="1.000000"
          stroke="none"
          d="
M576.585815,96.998489
	C578.378845,96.999680 579.774719,96.999054 581.588989,96.999367
	C583.306091,99.852577 580.665833,100.250046 579.239807,101.307465
	C576.239746,103.531967 572.419861,105.079521 574.679199,110.215256
	C575.504761,112.091736 573.538635,113.476250 571.946533,114.497475
	C569.953979,115.775543 570.479736,117.062630 572.265503,117.709869
	C577.703186,119.680664 582.592529,123.249756 588.664307,123.396469
	C591.369690,123.461838 593.972229,124.036652 596.305908,125.439735
	C597.947937,126.426987 599.532898,127.572975 597.291870,129.934265
	C589.318420,131.779175 581.601013,130.667923 573.503296,130.837311
	C567.526245,129.591019 562.600098,131.459457 557.397705,133.962006
	C555.182617,133.890884 553.909180,137.412567 551.860352,134.979141
	C550.333496,133.165634 551.108521,130.982071 551.568970,128.841675
	C552.671143,123.718681 555.808899,119.208122 556.000000,113.422752
	C560.623779,108.162415 564.085815,102.211578 570.374390,98.914513
	C572.315063,97.897018 573.966858,96.821213 576.585815,96.998489
z"
        />
        <path
          fill="#625457"
          opacity="1.000000"
          stroke="none"
          d="
M544.797668,120.875610
	C545.005127,122.065247 544.995605,123.127098 544.982056,124.587234
	C543.999207,125.820488 542.928650,126.083710 541.362976,125.254898
	C541.002441,125.002441 540.978821,124.978722 540.965759,124.965454
	C541.263977,123.138870 541.936890,121.514854 543.675842,120.128952
	C544.169189,120.222389 544.375000,120.485107 544.797668,120.875610
z"
        />
        <path
          fill="#48505A"
          opacity="1.000000"
          stroke="none"
          d="
M538.704407,208.152832
	C542.093140,205.887344 545.586731,205.816650 549.564270,205.997955
	C551.219543,206.485321 551.857056,207.525558 552.594360,208.936401
	C553.034363,210.647934 552.789917,212.000275 552.085876,213.127548
	C549.055359,217.980057 549.550232,222.552536 552.925232,226.994308
	C553.436951,227.667725 553.659058,228.474808 553.495605,229.677475
	C552.881714,230.719955 552.177124,231.108871 551.359741,231.359619
	C548.031311,232.380661 546.293884,234.713654 546.925171,238.090057
	C547.520386,241.273758 549.910461,242.707581 553.177307,242.558411
	C553.839417,242.528183 554.505432,242.557404 555.516357,242.585754
	C559.276306,242.002350 561.611145,242.473434 562.838440,246.568741
	C563.892578,250.086075 572.671570,252.383789 576.110962,250.773682
	C577.470154,250.137405 578.121826,249.117767 578.231750,247.681168
	C578.370789,245.862991 578.388489,244.038406 578.507568,241.810211
	C578.490234,232.321899 578.522827,223.242493 578.514221,214.163071
	C578.512512,212.365250 578.225708,210.519730 579.341125,208.407990
	C580.183167,207.628113 580.795898,207.475677 581.908081,207.773468
	C583.411499,209.149551 583.851501,210.592758 583.856873,212.089462
	C583.908325,226.446030 584.470154,240.806824 583.435120,255.611877
	C582.216187,258.424927 580.312744,259.857086 577.555786,260.951965
	C574.930664,261.736633 572.669250,261.455688 570.016968,261.351379
	C568.071960,260.880920 566.784973,259.903595 564.950317,259.231140
	C558.698059,257.699493 553.085938,254.950974 546.631714,256.501495
	C543.530457,257.246521 540.643005,258.408966 537.309448,259.488770
	C536.717102,259.612091 536.477478,259.639862 535.880493,259.631775
	C534.255981,259.272491 533.838074,258.072723 532.859192,256.969727
	C532.515137,256.530273 532.389771,256.347290 532.104126,255.878036
	C531.504822,254.750427 531.465332,253.448486 529.654602,253.955078
	C528.393494,254.330338 527.422974,254.612213 526.276001,255.275757
	C525.779724,255.357605 525.576111,255.345856 525.096436,255.205841
	C524.073975,254.444260 523.420166,253.677017 522.130005,253.265198
	C519.963501,251.420837 519.278870,249.023880 519.343201,246.785461
	C519.522949,240.525146 516.279480,236.605118 511.157654,233.300568
	C511.358673,230.063995 513.672852,229.520462 515.940125,229.013947
	C522.110107,227.635590 528.280518,226.253174 534.478516,225.010437
	C536.001831,224.704987 536.912903,224.194061 536.912354,222.159012
	C536.667480,218.673538 540.428162,215.728668 537.116455,212.234711
	C536.986084,210.524200 537.085571,209.185593 538.704407,208.152832
z"
        />
        <path
          fill="#535C64"
          opacity="1.000000"
          stroke="none"
          d="
M496.277100,280.237183
	C494.211731,280.064240 492.374603,279.751190 490.351685,280.849609
	C487.622803,282.355286 485.522766,279.982117 482.802429,280.515137
	C479.537445,280.645233 476.781342,280.463287 474.956360,277.405945
	C475.246124,276.184235 475.494019,275.382111 475.871582,274.289093
	C477.803558,271.331604 477.250397,268.614868 475.975616,266.011383
	C473.682861,261.328888 473.542297,256.434875 474.404724,251.461243
	C474.637054,250.121521 474.767273,248.966904 474.328094,247.617065
	C472.851044,243.077530 475.579132,237.877792 480.624146,236.007782
	C481.746979,236.538055 482.087708,237.312210 482.279266,238.609146
	C480.416931,243.401917 480.884705,248.082825 480.495392,252.658401
	C479.587921,263.324371 483.166656,267.386566 493.728455,266.352020
	C498.698120,265.865234 503.633881,265.511383 508.606598,265.517212
	C510.203979,265.519104 511.510193,264.942291 513.169250,264.006531
	C514.340515,263.736694 515.121338,263.776459 516.240234,264.124908
	C519.063171,269.124481 514.645935,269.793091 512.107178,270.621735
	C509.089417,271.606689 509.589569,272.872040 510.567383,275.151306
	C510.406189,277.004150 508.438293,275.374725 508.127625,277.078735
	C507.625580,277.575256 507.245728,277.733368 506.521240,277.862488
	C504.984772,277.762329 503.725372,277.383331 502.446014,278.665039
	C501.225708,279.436157 500.236542,279.960541 498.955750,280.675903
	C497.893738,280.937927 497.199768,280.855072 496.277100,280.237183
z"
        />
        <path
          fill="#4C707C"
          opacity="1.000000"
          stroke="none"
          d="
M458.797882,251.312012
	C462.007019,251.133148 461.683105,253.745377 462.727783,255.757828
	C462.975342,256.445129 462.978699,256.889801 462.980713,257.668060
	C460.860626,262.956787 461.539246,268.283478 462.328339,273.153107
	C463.018188,277.410583 461.542328,278.721466 458.349976,280.123291
	C452.639740,282.630798 444.920410,282.207886 441.620270,279.045654
	C439.314850,276.836517 438.394043,273.870148 437.959808,271.001190
	C436.711243,262.751984 434.815399,254.380997 441.389374,247.068039
	C444.442719,243.671463 448.455261,243.037018 452.155273,243.344696
	C455.481995,243.621338 459.241119,245.403717 459.034790,250.552231
	C456.540924,253.054474 454.538330,252.398438 452.946960,249.787094
	C451.943695,248.140762 451.065063,248.043655 449.418793,249.276505
	C444.934540,252.634705 442.130951,256.574829 443.568481,262.835571
	C444.493469,265.589050 444.463165,268.087036 444.481384,270.590698
	C444.524323,276.490662 444.550446,276.652893 450.644379,276.423065
	C453.204987,276.326477 456.493164,278.015350 458.200684,273.940674
	C458.592529,267.186707 458.868134,260.832336 458.082916,254.491394
	C457.958191,253.484314 458.007416,252.547806 458.797882,251.312012
z"
        />
        <path
          fill="#635B5F"
          opacity="1.000000"
          stroke="none"
          d="
M517.316589,265.196289
	C516.254211,265.006805 515.496704,265.000977 514.360474,264.990662
	C513.415222,264.553284 512.828552,264.141693 512.269653,263.198822
	C511.950928,262.396118 511.855865,261.909485 511.813568,261.031311
	C511.864105,260.397339 511.910614,260.145020 512.095398,259.532104
	C512.373352,258.955444 512.513062,258.739380 512.929565,258.256836
	C515.392517,256.873444 516.161499,254.953094 516.098450,252.651886
	C516.012329,249.505753 515.692017,246.335800 514.858398,243.333893
	C513.876221,239.796982 511.781769,237.812698 507.507050,237.324982
	C498.656525,236.315247 490.214935,238.259491 481.335938,239.251648
	C481.020172,238.272217 481.010681,237.514267 480.998779,236.377228
	C482.163635,234.647202 483.761261,235.094406 485.610077,234.981598
	C486.652130,234.124481 487.775146,234.049530 488.790771,233.220032
	C496.025696,233.003372 503.044739,233.000671 510.532532,232.992889
	C517.212524,234.413483 519.462585,237.300507 520.213135,243.375992
	C520.591370,246.437027 520.932678,249.656128 522.724731,252.762268
	C523.098511,253.206528 523.211426,253.388992 523.301392,253.893616
	C522.946838,255.013016 522.383423,255.682571 521.896973,256.771118
	C520.106812,259.336975 520.481018,262.393341 518.546875,264.895508
	C518.069092,265.222107 517.845337,265.301056 517.316589,265.196289
z"
        />
        <path
          fill="#759EB0"
          opacity="1.000000"
          stroke="none"
          d="
M536.814941,212.015594
	C540.776672,213.711029 540.913757,216.639526 537.184814,221.536942
	C532.903076,221.809540 528.901611,221.868713 524.436462,221.958801
	C520.457458,223.238953 516.948608,224.242706 513.331543,221.295288
	C513.042114,219.846817 512.953430,218.818863 512.805420,217.408630
	C512.511963,216.587112 512.425659,216.120468 512.656067,215.302780
	C513.453613,212.428665 515.415894,212.663528 517.651428,212.769455
	C518.379456,212.812119 518.795288,212.902496 519.525940,213.046341
	C520.752991,214.750015 518.521301,215.534149 518.555481,216.925644
	C522.886841,219.245575 527.351807,218.421967 531.713806,218.415024
	C535.732422,218.408630 534.645447,214.820999 535.885559,212.464233
	C536.161377,212.251541 536.605164,212.115128 536.814941,212.015594
z"
        />
        <path
          fill="#5D696E"
          opacity="1.000000"
          stroke="none"
          d="
M513.093506,221.282593
	C516.297485,222.677933 519.763489,221.631760 523.531006,221.955338
	C520.258789,225.713852 514.163635,225.546722 513.093506,221.282593
z"
        />
        <path
          fill="#535C64"
          opacity="1.000000"
          stroke="none"
          d="
M488.659424,233.013153
	C488.852966,234.390564 488.025085,234.980087 486.341919,234.974197
	C486.151093,233.595535 486.984711,233.015411 488.659424,233.013153
z"
        />
        <path
          fill="#5D696E"
          opacity="1.000000"
          stroke="none"
          d="
M512.567200,215.037903
	C512.841431,215.436172 512.857605,215.893280 512.809998,216.688385
	C511.509644,216.630188 510.696564,216.138123 512.567200,215.037903
z"
        />
        <path
          fill="#534A50"
          opacity="1.000000"
          stroke="none"
          d="
M556.990112,134.381470
	C560.357422,128.534821 565.812500,128.988525 571.558350,129.005829
	C573.227417,131.771088 571.031799,132.480301 569.299133,133.276779
	C560.780212,137.192810 560.059387,138.587021 562.029480,148.050232
	C562.235474,149.039841 562.424438,150.037216 562.224121,151.016663
	C560.281616,160.514893 569.811035,166.553238 577.097290,165.799622
	C580.743469,165.422516 584.410034,165.427704 588.047546,164.998047
	C589.373779,164.841385 590.715515,164.710754 592.022644,165.029327
	C594.293152,165.582809 597.022034,166.042847 597.368774,168.764679
	C597.702881,171.387238 595.085022,172.146362 593.161499,173.043533
	C589.988403,174.523483 586.559021,175.289017 583.243225,176.368469
	C582.109375,176.737610 580.959717,176.900360 580.477783,178.597046
	C580.337219,179.186386 580.275940,179.419052 580.075745,179.980194
	C578.983826,181.620544 578.264526,183.010971 578.464539,185.048767
	C578.554749,186.304581 578.417725,187.205551 578.239624,188.440613
	C577.084290,191.686935 578.110291,194.312820 579.495972,197.195770
	C579.829529,197.676620 579.952087,197.880295 580.218384,198.416779
	C580.694031,199.711594 580.850220,200.678009 580.209656,201.966263
	C579.368896,202.767685 578.650879,202.985916 577.504272,203.061035
	C572.019775,200.701721 567.933167,204.444595 562.994995,205.363800
	C561.828308,205.299835 561.101868,205.067169 560.215088,204.290405
	C561.880249,198.367828 563.809021,192.817215 564.072388,186.745712
	C564.454712,177.934036 560.906433,171.985229 552.612061,168.886627
	C552.056030,168.678940 551.640625,168.095139 551.076904,167.346481
	C553.620239,166.836380 556.335083,167.488480 558.824097,166.056976
	C560.066711,165.342270 560.959106,164.470459 560.852112,162.923401
	C560.746521,161.396530 559.659790,161.076874 558.415833,161.005051
	C556.508240,160.894928 555.182861,160.066177 555.077209,158.036469
	C554.792969,152.580673 555.013855,147.119583 554.488159,141.647141
	C554.277283,139.451630 556.088989,137.061859 556.990112,134.381470
z"
        />
        <path
          fill="#594E52"
          opacity="1.000000"
          stroke="none"
          d="
M537.352905,137.935303
	C535.134521,133.445816 537.607727,130.950317 541.639893,129.012177
	C541.630981,132.474213 540.392334,135.524704 537.352905,137.935303
z"
        />
        <path
          fill="#594E52"
          opacity="1.000000"
          stroke="none"
          d="
M541.043518,125.345375
	C542.063049,124.999382 543.123596,124.996323 544.581116,124.989380
	C544.883301,126.673470 545.481018,128.747589 542.379517,128.985352
	C541.696533,127.897148 541.390564,126.792732 541.043518,125.345375
z"
        />
        <path
          fill="#AA8367"
          opacity="1.000000"
          stroke="none"
          d="
M559.224121,181.533142
	C560.458313,186.481110 559.936707,191.157410 559.809753,195.803925
	C559.713135,199.338348 557.793823,201.130783 553.974426,200.985825
	C548.983459,200.796387 543.977966,200.986496 538.516357,201.014038
	C537.894958,200.960083 537.735901,200.905426 537.332092,200.789612
	C536.251465,196.525375 535.371704,192.317673 535.756104,187.997498
	C536.209534,182.901917 538.566223,180.387543 543.675293,180.300262
	C548.755066,180.213501 553.937622,179.252228 559.224121,181.533142
z"
        />
        <path
          fill="#81A2AC"
          opacity="1.000000"
          stroke="none"
          d="
M559.649658,181.486176
	C554.210266,181.902863 548.872559,182.182114 543.557495,181.946808
	C538.810303,181.736664 537.727478,184.407745 537.184570,188.313370
	C536.632812,192.283325 539.084045,196.137421 537.245667,200.404266
	C536.881226,200.734818 536.675171,200.741165 536.162598,200.705566
	C534.685120,199.646317 534.431274,198.301376 534.499146,196.433701
	C534.504089,194.150192 534.545837,192.277191 534.495300,190.013611
	C534.364685,188.855530 534.322998,188.084991 534.433105,186.937546
	C534.525208,186.069122 534.558350,185.573135 534.611206,184.710083
	C534.661377,184.100662 534.687256,183.858032 534.775269,183.256882
	C534.931458,182.424606 535.027588,181.951294 535.179321,181.119202
	C535.463867,179.559952 535.660034,178.346924 536.732178,177.093613
	C537.121887,176.527786 537.323364,176.280838 537.773438,175.937881
	C539.307617,174.674942 540.851562,174.100082 542.563477,174.524399
	C547.383301,175.719116 552.411255,175.195343 557.638916,176.599503
	C560.289307,177.595825 559.889954,179.414185 559.649658,181.486176
z"
        />
        <path
          fill="#478D9B"
          opacity="1.000000"
          stroke="none"
          d="
M535.744263,212.184204
	C537.810669,219.388611 537.385925,219.921494 530.148071,219.978378
	C526.352844,220.008209 522.574341,220.003540 518.840698,219.108170
	C517.322632,218.744095 515.227112,219.365387 515.138184,216.899353
	C515.059387,214.713058 517.106689,215.141357 518.359558,214.595856
	C518.794678,214.406372 519.143433,214.018234 519.686157,213.410339
	C520.405090,212.841034 520.969482,212.582260 521.835144,212.258453
	C526.171021,216.365952 527.597412,216.421417 531.739685,212.304825
	C533.094910,212.073700 534.211060,212.095276 535.744263,212.184204
z"
        />
        <path
          fill="#59B1BB"
          opacity="1.000000"
          stroke="none"
          d="
M531.529297,212.018829
	C532.228760,214.992599 531.966370,217.468613 527.959290,217.025787
	C525.247437,216.726089 521.416565,218.196716 522.106750,212.600677
	C525.117554,212.124115 528.098755,212.054825 531.529297,212.018829
z"
        />
        <path
          fill="#67BFC6"
          opacity="1.000000"
          stroke="none"
          d="
M535.633423,195.782745
	C535.921448,197.332870 535.915405,198.620468 535.882751,200.285828
	C535.116516,200.759476 534.376953,200.855347 533.259766,200.913422
	C531.700134,200.389252 530.518127,199.902908 529.114319,199.167831
	C528.830261,196.570618 530.668823,195.930649 532.699402,195.028046
	C533.902588,194.882156 534.658569,195.055176 535.633423,195.782745
z"
        />
        <path
          fill="#73676A"
          opacity="1.000000"
          stroke="none"
          d="
M557.933960,176.483765
	C551.607910,177.579376 545.270813,176.018509 538.417725,175.874695
	C538.148071,174.686203 538.326294,173.573135 538.747192,172.214828
	C540.056763,171.982803 541.123535,171.996002 542.590149,172.011688
	C543.639587,172.872742 544.757812,172.947464 545.781006,173.758057
	C550.042297,174.136398 554.244141,173.430038 557.933960,176.483765
z"
        />
        <path
          fill="#534A50"
          opacity="1.000000"
          stroke="none"
          d="
M552.340027,209.247437
	C550.608398,208.856110 550.017517,208.027908 550.007935,206.346283
	C552.160767,204.446091 554.855896,204.839478 557.610596,204.008362
	C558.581299,204.667984 558.855530,205.438339 558.634399,206.736221
	C557.036011,209.085754 555.293274,210.362457 552.340027,209.247437
z"
        />
        <path
          fill="#48505A"
          opacity="1.000000"
          stroke="none"
          d="
M558.352661,207.275772
	C557.998962,206.239990 558.000305,205.492172 558.002075,204.370346
	C558.440308,203.993927 558.878174,203.991516 559.643860,203.985596
	C560.727722,203.989059 561.483826,203.996017 562.617493,204.004242
	C563.764709,207.840836 561.386780,207.883743 558.352661,207.275772
z"
        />
        <path
          fill="#635B5F"
          opacity="1.000000"
          stroke="none"
          d="
M545.929321,173.634155
	C544.859802,173.952850 543.653442,174.083679 543.062073,172.347443
	C544.123047,172.048950 545.334167,171.910126 545.929321,173.634155
z"
        />
        <path
          fill="#5E9BAB"
          opacity="1.000000"
          stroke="none"
          d="
M516.335083,194.463745
	C511.750763,194.418350 512.919128,190.674026 512.423462,188.293503
	C510.267212,177.936859 513.250793,174.606232 523.808350,175.053406
	C525.274414,175.115509 526.724487,175.556427 528.586060,175.868988
	C529.087891,176.144058 529.185669,176.372116 529.149109,176.947540
	C527.015747,179.263290 523.550232,177.773636 521.510437,180.558838
	C517.580261,181.587296 516.165222,183.843521 516.445129,187.537231
	C516.604126,189.634674 516.693481,191.756454 516.335083,194.463745
z"
        />
        <path
          fill="#67BFC6"
          opacity="1.000000"
          stroke="none"
          d="
M529.071411,177.328812
	C529.115662,176.775436 529.095520,176.603210 529.032837,176.173492
	C530.932129,171.350922 533.161194,173.769516 535.658203,175.800690
	C536.103088,176.218414 536.303101,176.450378 536.760376,176.730438
	C536.928101,178.306900 536.883545,179.796478 536.752686,181.722748
	C536.666260,182.159454 536.499573,182.495926 536.363525,182.625366
	C536.153564,182.956879 536.079529,183.158936 535.918335,183.671844
	C535.453247,184.466049 535.278076,184.988083 535.691650,185.829468
	C535.869995,186.803513 535.881470,187.538620 535.886230,188.641357
	C534.798950,190.341751 536.693176,193.160629 533.247803,193.392319
	C529.631592,189.569092 531.261475,184.746429 529.749390,180.268845
	C529.151733,179.248810 528.968323,178.505798 529.071411,177.328812
z"
        />
        <path
          fill="#5E9BAB"
          opacity="1.000000"
          stroke="none"
          d="
M529.841797,179.063843
	C531.755371,179.251328 532.140564,180.487427 532.014709,181.906067
	C531.702148,185.430862 532.583923,188.827057 533.037231,192.705215
	C533.595886,193.758057 533.722595,194.460159 533.254883,195.538025
	C531.798462,196.711426 530.568481,197.583038 529.115479,198.686874
	C524.335083,199.066864 519.689697,199.569214 516.030762,195.403137
	C515.944824,195.057037 516.007812,194.992325 516.039551,194.960205
	C517.400696,193.743774 518.872009,194.390686 520.270142,194.668884
	C527.913391,196.189667 529.641174,194.758026 529.530212,186.914230
	C529.516357,185.936035 529.561646,184.952362 529.164429,183.621582
	C528.836914,181.890747 528.790100,180.561691 529.841797,179.063843
z"
        />
        <path
          fill="#4C707C"
          opacity="1.000000"
          stroke="none"
          d="
M474.567780,276.992615
	C477.099335,278.696869 479.589111,279.029419 482.579254,279.013306
	C487.188049,282.321564 487.491272,283.942810 484.342712,288.420227
	C481.988800,288.475098 481.280731,286.780426 480.207275,285.583771
	C478.681519,283.882935 476.838806,282.887939 474.531555,282.995667
	C472.191956,283.104889 470.851654,284.332672 470.782532,286.745453
	C470.719208,288.957306 471.412598,290.601135 473.858154,291.142944
	C476.479645,291.723633 479.158600,290.537048 482.022095,291.739624
	C482.496307,292.435425 482.540405,292.912689 482.248535,293.712769
	C481.855255,294.865692 481.668549,295.709991 481.240326,296.775635
	C477.672546,298.626923 474.286011,299.248322 470.382996,297.305725
	C470.001709,297.002441 470.000000,297.000000 469.998718,296.999390
	C466.984131,293.707367 463.516693,290.685669 463.566833,285.644653
	C463.621643,280.131592 466.492249,277.218475 472.151581,277.010162
	C472.812744,276.985809 473.475372,277.001648 474.567780,276.992615
z"
        />
        <path
          fill="#4E5963"
          opacity="1.000000"
          stroke="none"
          d="
M433.379028,236.951508
	C433.107056,235.425674 434.263611,234.503479 435.507629,234.311935
	C441.378815,233.407928 444.443237,228.712280 448.674316,225.075653
	C449.367279,231.121475 444.232971,232.433258 440.349976,234.651627
	C438.368530,235.783630 435.962738,236.172897 433.379028,236.951508
z"
        />
        <path
          fill="#372624"
          opacity="1.000000"
          stroke="none"
          d="
M545.300598,327.412659
	C532.206482,328.743835 519.468140,329.851654 506.713593,330.721344
	C488.976959,331.930756 471.241364,333.318787 453.481110,333.976990
	C432.200958,334.765656 416.822388,325.419495 408.182281,305.287872
	C410.964020,305.162811 411.134125,309.283051 414.617737,309.017273
	C414.979858,309.021149 414.941742,309.502014 414.930115,309.742920
	C414.827454,310.177765 414.736389,310.371674 414.882568,310.782379
	C415.119843,310.999146 415.527405,311.001129 415.729431,311.027771
	C427.011383,318.166473 438.804138,321.140289 451.970459,318.104919
	C457.150513,316.910736 462.570923,318.063690 467.839111,318.558838
	C472.007996,318.950653 476.162170,319.461273 480.222473,318.406067
	C486.874512,316.677338 493.189423,321.119812 500.477692,317.973145
	C506.293335,315.462311 513.829163,316.170807 520.651733,316.145569
	C527.034058,316.121918 533.519653,317.886597 539.729309,314.678345
	C541.307678,313.862885 543.335999,314.380615 545.312683,315.731201
	C546.740112,319.725922 547.545837,323.403564 545.300598,327.412659
z"
        />
        <path
          fill="#374651"
          opacity="1.000000"
          stroke="none"
          d="
M468.304504,313.315063
	C471.344604,309.530823 474.359833,306.426300 479.934845,305.679443
	C486.063385,304.858459 491.907440,301.643341 495.018463,294.936890
	C495.897552,294.524475 496.516327,294.801788 497.311920,295.490265
	C498.413147,296.582825 498.284454,298.314880 500.284241,298.463013
	C505.437592,299.614655 509.561768,297.591705 513.694641,295.582733
	C515.464417,294.722443 516.559753,293.328339 516.892334,291.420319
	C517.191589,289.703491 517.809021,288.244934 519.665649,287.136963
	C522.638611,286.753265 523.320679,284.664307 524.150940,282.644775
	C526.626221,276.623657 528.999756,275.410492 534.839172,277.715088
	C536.992310,278.564819 538.180359,278.325928 539.374084,276.452820
	C540.537781,274.626770 542.075500,273.450562 544.604248,275.145264
	C546.007446,278.946777 545.367920,282.525024 545.232300,286.456482
	C544.901489,287.484222 544.439270,288.094147 544.944458,289.199707
	C545.775208,291.649261 545.397583,293.934875 546.349976,296.429749
	C547.204590,300.867950 547.103271,304.828308 545.497803,309.094543
	C543.820435,310.548584 542.177917,310.894226 540.505981,310.890503
	C522.205505,310.849396 504.059753,313.507599 485.791931,313.990112
	C482.783234,314.069580 479.874084,314.925659 476.868866,314.917023
	C474.059540,314.908966 471.185211,315.315460 468.304504,313.315063
z"
        />
        <path
          fill="#1C2129"
          opacity="1.000000"
          stroke="none"
          d="
M467.541504,312.975250
	C482.267853,312.755402 496.458954,311.215118 510.689240,310.298065
	C521.780029,309.583313 532.893799,308.311890 544.524170,308.952759
	C546.082642,309.713654 546.306091,310.850952 546.390076,312.494629
	C546.404846,313.849823 546.139282,314.676697 545.256104,315.690796
	C541.576294,315.905212 538.303833,315.871826 535.465210,318.189484
	C534.131958,319.278015 532.284241,319.230225 530.647461,318.899384
	C523.725037,317.500214 516.845886,317.681976 509.908569,318.892548
	C507.486053,319.315277 504.933105,318.968964 502.440765,319.022491
	C501.285217,319.047272 499.447998,318.761658 499.084961,319.343597
	C495.832428,324.557465 492.369659,322.098389 488.473175,320.264008
	C486.692352,319.425598 484.135864,319.659821 482.074402,320.091492
	C472.937286,322.004852 463.905670,318.911377 454.982910,319.570740
	C449.174469,320.000000 443.321930,320.831360 437.426270,321.040436
	C429.895630,321.307526 424.076477,316.710571 417.496582,314.297577
	C416.806061,314.044342 416.537140,312.641357 416.002075,311.413086
	C420.766785,312.061798 425.622375,312.981598 430.433105,314.095123
	C440.058838,316.323029 449.589813,313.507233 459.179169,313.573639
	C461.755371,313.591461 464.446045,313.128265 467.541504,312.975250
z"
        />
        <path
          fill="#6494A3"
          opacity="1.000000"
          stroke="none"
          d="
M497.398682,296.082672
	C496.440948,295.523865 496.017578,295.208008 495.378296,294.736084
	C496.737854,291.815613 494.159302,288.935699 495.700714,286.070862
	C496.165558,285.824799 496.326324,285.669708 496.704987,285.260193
	C496.927185,284.251953 496.931427,283.498138 496.945435,282.367310
	C497.198547,280.996826 498.013794,280.705688 499.045319,280.185547
	C499.340576,278.674927 499.917358,277.926880 501.602783,278.031799
	C503.086395,277.702942 504.108398,276.895630 505.713135,277.033447
	C506.464691,277.012299 506.910553,276.986450 507.691223,276.949036
	C509.641418,276.483124 510.718079,277.962189 512.477417,278.236481
	C512.959412,278.530396 513.134521,278.680176 513.470459,279.141327
	C513.736145,279.916168 513.747620,280.382843 513.560059,281.186462
	C511.832794,284.299194 509.206665,283.966736 506.105927,283.327606
	C509.124359,285.827606 509.124359,285.827606 515.510132,281.768341
	C516.916565,281.553345 517.519409,282.229797 518.228516,283.298706
	C518.828064,284.592468 519.593628,285.380737 520.291016,286.595551
	C520.412415,287.114319 520.410583,287.331299 520.171387,287.770935
	C516.825867,289.679016 519.967468,294.219727 516.184082,295.964081
	C515.158936,296.436676 514.755920,298.298218 512.907104,297.406921
	C512.526917,297.223633 511.624023,297.257996 511.545258,297.441864
	C509.186829,302.946808 504.609528,299.019897 500.781097,299.859161
	C499.132751,299.470154 498.774780,298.452179 498.392212,297.090210
	C498.176605,296.641937 498.054779,296.483765 497.398682,296.082672
z"
        />
        <path
          fill="#485E6A"
          opacity="1.000000"
          stroke="none"
          d="
M482.719299,288.203766
	C487.082520,285.314423 484.703674,282.517914 483.066162,279.375122
	C485.323486,278.737213 487.383301,279.529663 489.673340,280.851257
	C494.955658,283.218170 491.245087,287.004120 491.151947,289.828583
	C491.095703,291.533630 490.151337,296.302063 485.571167,293.517670
	C484.953705,293.142303 483.701141,293.811554 482.392334,294.009247
	C482.001678,293.511902 481.961395,293.002350 481.870758,292.113037
	C481.317627,290.495667 481.660217,289.411438 482.719299,288.203766
z"
        />
        <path
          fill="#485E6A"
          opacity="1.000000"
          stroke="none"
          d="
M470.063843,297.380005
	C473.362122,296.998566 476.722534,296.994659 480.540863,296.993896
	C477.664459,300.862244 473.461609,301.156250 470.063843,297.380005
z"
        />
        <path
          fill="#485E6A"
          opacity="1.000000"
          stroke="none"
          d="
M498.831055,279.945435
	C499.660034,281.493927 498.218964,281.350616 497.215027,281.918610
	C496.655273,281.520508 496.355377,281.050751 496.027954,280.292938
	C496.808624,279.991333 497.616760,279.977814 498.831055,279.945435
z"
        />
        <path
          fill="#485E6A"
          opacity="1.000000"
          stroke="none"
          d="
M414.995056,307.685730
	C414.173981,307.496490 413.219177,307.060974 413.535278,305.825684
	C413.567139,305.701233 414.292145,305.589905 414.324646,305.655701
	C414.595367,306.203674 414.778961,306.794678 414.995056,307.685730
z"
        />
        <path
          fill="#485E6A"
          opacity="1.000000"
          stroke="none"
          d="
M414.999390,308.000610
	C415.388092,308.168488 415.777435,308.335785 416.166748,308.503052
	C415.925323,308.655792 415.683929,308.808563 415.211182,308.991211
	C414.979858,309.021149 415.000000,309.000000 414.989777,309.010132
	C414.985718,308.850067 414.991882,308.679871 414.999634,308.254242
	C415.001221,307.998779 415.000000,308.000000 414.999390,308.000610
z"
        />
        <path
          fill="#485E6A"
          opacity="1.000000"
          stroke="none"
          d="
M496.727539,285.124023
	C496.891602,285.347046 496.733948,285.613647 496.227417,285.892700
	C496.115662,285.687592 496.291443,285.441742 496.727539,285.124023
z"
        />
        <path
          fill="#1C2129"
          opacity="1.000000"
          stroke="none"
          d="
M415.082916,310.742676
	C414.965668,310.741364 414.811462,310.483551 414.787903,310.104797
	C414.961029,310.151306 415.003510,310.318756 415.082916,310.742676
z"
        />
        <path
          fill="#3E1E1C"
          opacity="1.000000"
          stroke="none"
          d="
M497.389801,336.980591
	C484.715912,336.583862 472.534149,338.634949 460.260223,338.100281
	C457.862305,337.995880 455.408752,338.976227 452.994171,339.515839
	C451.367889,339.879272 449.661621,340.646423 449.025360,337.792908
	C483.930206,335.223572 518.869080,333.014618 553.806458,330.783112
	C562.280518,330.241882 570.749878,329.626129 579.628784,329.109009
	C580.568054,329.729614 580.790222,330.393463 580.617920,331.496887
	C580.237488,332.216919 579.897278,332.516907 579.150513,332.793091
	C578.005249,332.952118 577.182800,332.723633 576.059448,332.904175
	C575.362122,333.055969 574.957092,333.118103 574.235107,333.169189
	C573.507629,333.148804 573.101440,333.075287 572.434814,332.824097
	C571.080750,331.351440 571.116394,333.148346 570.306152,333.444946
	C569.686768,333.789825 569.306641,333.932587 568.627319,334.140228
	C567.745239,334.397583 567.206909,334.675354 566.424805,335.177246
	C565.937317,335.366302 565.729492,335.405121 565.210449,335.389709
	C564.350037,335.084351 563.871216,334.706329 563.045471,334.415436
	C562.401672,334.358154 562.034973,334.382172 561.417358,334.560303
	C559.631592,336.595825 561.200195,339.443817 558.879517,341.374512
	C558.072754,341.601746 557.600891,341.536926 556.890625,341.087952
	C556.510010,338.690277 556.264160,336.563660 556.691467,334.462677
	C556.088989,337.431946 556.870178,340.427368 556.476807,343.735107
	C550.768433,344.325012 554.269531,340.647186 553.936646,338.291748
	C554.005249,336.607483 554.070007,335.301514 554.619934,334.103119
	C554.101746,335.110626 554.012512,336.234070 553.975708,337.744629
	C552.541199,340.797913 554.830505,345.597656 549.080872,345.943542
	C546.154785,344.538422 546.784302,342.065338 546.471558,339.874359
	C546.246582,338.297607 547.488647,336.359741 545.127319,335.214661
	C544.341003,335.319519 543.881592,335.366150 543.054199,335.428955
	C541.929871,335.538605 541.172241,335.625031 540.011475,335.498352
	C538.795776,335.483429 537.978882,335.480988 536.741577,335.583069
	C529.636047,336.433258 522.964233,337.498108 515.763062,336.484131
	C514.736084,336.260529 514.191956,336.023590 513.333618,335.412659
	C512.237488,335.116150 511.500977,335.089325 510.395782,335.070068
	C509.600830,335.457764 509.154999,335.805023 508.297180,335.952728
	C504.571777,335.898712 501.146393,335.993561 497.389801,336.980591
z"
        />
        <path
          fill="#2C1E22"
          opacity="1.000000"
          stroke="none"
          d="
M566.129517,326.757263
	C561.182983,327.174530 556.517944,327.446625 551.401855,327.686462
	C549.504639,327.467438 548.208252,327.134338 548.738403,324.843323
	C551.202087,323.292267 551.056702,321.089935 551.204529,319.008148
	C551.421570,315.952393 550.847595,312.828491 552.202271,309.511444
	C553.034729,308.520142 553.778259,308.405151 554.919739,308.931061
	C555.504822,309.653076 555.641235,310.155640 555.653992,311.080750
	C556.917419,313.965027 556.375732,316.652618 556.507080,319.269989
	C556.381592,317.123169 556.615356,314.981232 556.247925,312.819519
	C555.474304,308.269318 556.407898,306.768951 560.928040,305.869568
	C564.282532,305.202179 567.726807,304.264893 571.444214,306.000000
	C573.216003,311.950165 572.266602,317.683014 572.229614,323.828979
	C571.975830,324.549591 571.829346,324.820862 571.363770,325.426941
	C570.581970,325.997955 570.119202,326.234131 569.279541,326.585266
	C568.082703,326.372589 567.246948,326.489868 566.129517,326.757263
z"
        />
        <path
          fill="#1E0504"
          opacity="1.000000"
          stroke="none"
          d="
M579.738647,332.362854
	C580.053284,331.283752 580.061035,330.579926 580.052490,329.524780
	C580.783020,329.049042 581.529846,328.924713 582.659241,328.801819
	C583.241638,329.058228 583.441467,329.313171 583.765259,330.037598
	C583.867249,343.092560 584.493408,355.683807 583.214478,368.611816
	C582.189758,368.967377 581.499695,368.972046 580.464966,368.961548
	C578.207397,367.523987 578.574585,365.410278 578.545166,363.472015
	C578.467163,358.337982 578.524048,353.202484 578.511475,347.582947
	C578.935425,342.319153 577.582214,337.425903 579.738647,332.362854
z"
        />
        <path
          fill="#2F252B"
          opacity="1.000000"
          stroke="none"
          d="
M571.466125,325.856506
	C571.044800,325.761810 571.061340,325.361267 571.085571,325.162292
	C572.318665,318.883209 570.598816,312.652771 572.368652,306.260193
	C573.659790,305.475983 574.461914,305.874603 575.527222,306.812439
	C577.115967,310.229370 577.327148,313.261475 575.341431,316.617889
	C575.632019,319.964355 572.173950,324.737000 579.052734,325.174255
	C577.036865,326.480255 574.434937,325.931763 571.466125,325.856506
z"
        />
        <path
          fill="#533230"
          opacity="1.000000"
          stroke="none"
          d="
M549.252869,324.640625
	C548.971069,326.120972 549.614929,326.781982 550.742859,327.453369
	C549.236938,327.725067 547.523132,327.795959 545.380615,327.804565
	C544.956726,324.123688 544.961365,320.505096 544.937622,316.425568
	C544.941833,315.195679 544.974365,314.426788 545.033325,313.273560
	C546.297852,311.681427 544.126770,308.175140 548.249390,308.956757
	C550.551025,314.148987 550.334595,319.193756 549.252869,324.640625
z"
        />
        <path
          fill="#3E1E1C"
          opacity="1.000000"
          stroke="none"
          d="
M446.479980,337.259247
	C437.767548,339.484070 429.827515,337.324707 423.045105,330.431091
	C430.329010,333.596375 437.748962,336.843964 446.479980,337.259247
z"
        />
        <path
          fill="#535C64"
          opacity="1.000000"
          stroke="none"
          d="
M417.330627,327.301147
	C416.653625,327.703674 416.150116,327.745483 415.270355,327.740540
	C413.486938,327.005890 411.563660,327.095459 411.040710,324.767670
	C410.992249,323.924286 410.915894,323.430603 410.816895,322.563568
	C411.659363,322.442963 412.524445,322.695740 413.728149,322.971313
	C414.066711,322.994110 414.004822,323.001251 414.002136,322.970215
	C414.507446,323.685791 414.967499,324.481659 416.225769,324.890656
	C416.754852,324.932983 416.902893,325.000214 417.000244,325.324615
	C417.342224,325.894257 417.508209,326.388794 417.330627,327.301147
z"
        />
        <path
          fill="#372624"
          opacity="1.000000"
          stroke="none"
          d="
M417.507904,327.816833
	C417.102814,327.173004 417.048462,326.684143 417.000214,325.825989
	C417.931885,326.113403 418.857422,326.770142 419.892944,327.749451
	C419.259125,328.659180 418.562988,328.227478 417.507904,327.816833
z"
        />
        <path
          fill="#A5654C"
          opacity="1.000000"
          stroke="none"
          d="
M583.460083,330.216217
	C583.187439,329.804108 583.144470,329.606171 583.071655,329.105774
	C583.462891,328.528534 583.883911,328.253723 584.567261,327.947876
	C584.840881,328.372986 584.852234,328.829163 584.808228,329.625519
	C584.484985,330.318604 584.134766,330.482941 583.460083,330.216217
z"
        />
        <path
          fill="#372624"
          opacity="1.000000"
          stroke="none"
          d="
M416.363098,324.713409
	C415.540680,324.922455 414.522614,324.870361 414.060303,323.308411
	C414.725037,323.438141 415.450623,323.937073 416.363098,324.713409
z"
        />
        <path
          fill="#A5654C"
          opacity="1.000000"
          stroke="none"
          d="
M566.159058,326.971985
	C566.672241,326.741333 567.496460,326.580200 568.611694,326.559631
	C568.091858,326.813965 567.281006,326.927734 566.159058,326.971985
z"
        />
        <path
          fill="#E1B582"
          opacity="1.000000"
          stroke="none"
          d="
M480.994446,204.033783
	C483.564728,204.034897 485.687103,204.034897 487.809509,204.034897
	C483.619690,206.785583 479.212677,205.807999 474.806824,205.341782
	C476.425049,203.350464 478.613098,204.296906 480.994446,204.033783
z"
        />
        <path
          fill="#15313E"
          opacity="1.000000"
          stroke="none"
          d="
M410.717224,324.300964
	C412.225891,325.358368 413.383209,326.298798 414.717316,327.466492
	C408.635193,328.369019 402.388000,329.191071 396.115112,329.691376
	C378.635773,331.085510 370.449280,323.334839 370.920654,305.481812
	C374.302948,307.107819 377.597626,309.183746 381.217712,311.358917
	C385.683990,314.810730 390.680908,316.517944 395.452850,318.642487
	C399.127014,320.278259 402.868622,321.762451 406.794983,323.104065
	C407.968750,323.739380 409.110840,324.108582 410.717224,324.300964
z"
        />
        <path
          fill="#67BFC6"
          opacity="1.000000"
          stroke="none"
          d="
M391.336548,301.513000
	C385.970398,302.368927 380.781128,301.331238 375.600189,301.179962
	C370.622498,301.034607 367.199127,296.122955 366.012512,290.360474
	C372.170654,291.262543 378.209778,293.111481 384.590790,293.167175
	C386.586151,293.184570 387.421997,295.183319 388.815247,296.787689
	C389.113098,296.993530 389.526550,296.996033 389.732239,297.016632
	C390.301514,297.250214 390.593262,297.534760 390.915527,298.192444
	C391.029694,298.482056 391.014923,298.502441 391.013489,298.492249
	C391.430115,299.305206 391.515991,300.189667 391.336548,301.513000
z"
        />
        <path
          fill="#557E8B"
          opacity="1.000000"
          stroke="none"
          d="
M396.017456,308.627014
	C393.564606,308.589020 393.300659,306.643219 393.041626,304.328979
	C393.464996,304.253815 393.883362,304.547089 394.512054,304.994049
	C395.142975,306.194580 395.563599,307.241425 396.017456,308.627014
z"
        />
        <path
          fill="#557E8B"
          opacity="1.000000"
          stroke="none"
          d="
M391.384705,302.121887
	C391.113342,301.071533 391.047699,300.211060 390.997070,298.916321
	C392.162140,299.866241 392.888702,301.436584 393.074554,303.605530
	C392.562866,303.422424 392.076660,302.867126 391.384705,302.121887
z"
        />
        <path
          fill="#557E8B"
          opacity="1.000000"
          stroke="none"
          d="
M391.047943,298.127350
	C390.755981,298.181915 390.482269,297.881775 390.073242,297.309448
	C390.118134,297.029297 390.298279,297.021362 390.747955,297.038696
	C391.033722,297.300140 391.049957,297.536377 391.047943,298.127350
z"
        />
        <path
          fill="#5E9BAB"
          opacity="1.000000"
          stroke="none"
          d="
M362.260071,277.577881
	C362.098389,277.954773 361.864471,277.961670 361.278961,277.979736
	C360.937134,275.960266 360.946960,273.929596 360.938721,271.465637
	C360.920715,271.032318 361.402771,271.040894 361.642883,271.056030
	C362.081787,271.265198 362.223328,271.494568 362.330017,271.963074
	C362.297546,273.847260 362.242676,275.527618 362.260071,277.577881
z"
        />
        <path
          fill="#87462F"
          opacity="1.000000"
          stroke="none"
          d="
M918.709290,576.797546
	C924.163757,579.204895 929.357117,581.318054 934.799072,583.690430
	C931.572876,586.360168 928.245483,584.252930 925.315186,583.020020
	C922.401672,581.794189 919.582703,580.763550 916.475830,580.683044
	C911.736084,580.560242 908.047729,577.525818 903.390320,576.134155
	C902.209717,575.106750 901.883972,574.083435 902.340149,572.499146
	C902.885315,571.680054 903.344238,571.380127 904.298157,571.158813
	C909.420227,572.557129 914.447571,573.224609 918.709290,576.797546
z"
        />
        <path
          fill="#7D2C11"
          opacity="1.000000"
          stroke="none"
          d="
M903.218445,571.927979
	C902.993103,573.173218 903.007202,574.194214 903.040710,575.596924
	C902.596313,575.984680 902.132568,575.990723 901.321045,576.004395
	C899.171387,574.110901 897.369446,572.209656 895.270996,570.161194
	C892.352722,568.677795 889.934998,567.189331 890.382568,563.315552
	C891.012573,562.303650 891.687439,562.060974 892.827393,562.143311
	C893.725342,562.162109 893.898804,563.452881 894.977661,562.477295
	C896.507874,561.955750 897.633789,562.388794 899.107178,562.842041
	C900.909790,563.722229 902.581726,564.200867 903.489868,566.201782
	C903.893066,567.875305 902.458923,569.047668 903.342529,570.738281
	C903.456055,571.266235 903.456970,571.484985 903.218445,571.927979
z"
        />
        <path
          fill="#262C32"
          opacity="1.000000"
          stroke="none"
          d="
M888.641724,578.685486
	C888.985046,579.142029 888.992126,579.318970 889.009338,579.761719
	C888.852539,581.409973 887.998657,581.976318 886.335693,582.011963
	C880.307800,585.569458 873.620422,584.946899 867.474365,586.540588
	C859.856323,588.515808 851.962280,588.682129 844.313477,590.468445
	C834.181396,592.834778 824.177185,595.756165 813.477722,597.541260
	C810.959534,597.737854 808.895874,597.845337 806.896301,595.999023
	C806.078064,591.889282 809.755615,591.996521 811.850403,590.101318
	C820.904968,586.292480 830.257751,585.577393 839.325623,583.787720
	C851.836609,581.318665 864.410522,579.026733 877.107544,577.618164
	C880.865356,577.201355 884.702209,576.332947 888.641724,578.685486
z"
        />
        <path
          fill="#33444C"
          opacity="1.000000"
          stroke="none"
          d="
M812.704224,596.388550
	C818.533936,596.005737 823.484253,593.409058 828.800171,592.311218
	C837.949158,590.421753 846.981018,588.071167 856.295288,586.903625
	C862.039490,586.183594 867.779358,585.171082 873.420044,583.589600
	C877.179016,582.535645 881.225403,582.506531 885.565796,582.024414
	C885.988464,582.030151 885.996277,582.527466 885.999878,582.776123
	C884.117188,588.332397 879.211060,587.928223 875.101013,588.375732
	C865.599243,589.410278 856.204956,590.867493 846.641235,593.700378
	C838.822388,596.636414 831.167542,598.758850 823.727173,601.530334
	C820.260254,602.821716 816.672485,603.420898 812.796021,601.335205
	C811.620850,599.613220 811.584778,598.184509 812.704224,596.388550
z"
        />
        <path
          fill="#374651"
          opacity="1.000000"
          stroke="none"
          d="
M846.291565,593.329407
	C846.193176,592.152710 846.517822,591.295349 847.461182,591.186951
	C856.669373,590.128784 865.598633,587.206238 874.929810,587.020874
	C878.857849,586.942810 882.200500,585.206177 885.814758,583.332642
	C890.893494,585.306885 897.044189,585.815918 898.989258,592.617310
	C893.416321,590.613953 888.245239,586.661316 881.290710,588.734619
	C880.452454,589.794861 879.653137,590.181702 878.292847,590.415771
	C870.360596,591.770752 862.749634,592.280823 855.319702,593.961609
	C852.390198,594.624268 849.451111,595.096680 846.291565,593.329407
z"
        />
        <path
          fill="#87462F"
          opacity="1.000000"
          stroke="none"
          d="
M934.134888,580.812744
	C935.752319,577.435486 937.539490,580.281677 939.688110,580.846191
	C940.269592,581.574707 940.526489,582.156677 940.840210,583.054749
	C938.730103,582.583618 936.563171,581.796448 934.134888,580.812744
z"
        />
        <path
          fill="#9A4A2B"
          opacity="1.000000"
          stroke="none"
          d="
M918.631653,559.092773
	C923.941162,559.917114 928.088257,562.749512 932.640259,564.600525
	C934.297363,565.274414 935.954956,566.119690 938.224121,565.906738
	C939.103271,565.565491 939.653992,565.450439 940.616699,565.775024
	C940.824158,566.559509 940.663452,567.114136 940.274841,567.855774
	C938.366882,570.132812 936.003601,569.819275 933.686584,570.059998
	C929.208069,570.525146 924.163879,569.923523 921.343140,575.383911
	C920.623779,575.616882 920.204407,575.599182 919.470703,575.546570
	C916.188110,575.297852 913.562195,574.064636 910.698730,572.499512
	C909.930786,568.485291 905.724548,567.473328 904.544250,563.866577
	C908.406982,559.728943 913.588196,560.276428 918.631653,559.092773
z"
        />
        <path
          fill="#87462F"
          opacity="1.000000"
          stroke="none"
          d="
M921.368530,575.823364
	C920.053650,570.375061 923.033936,569.065491 927.487427,568.970825
	C931.402283,568.887756 935.389343,569.605835 939.627930,568.088501
	C939.782593,569.971497 938.629333,570.737854 936.735657,571.019775
	C927.701721,572.364929 927.707764,572.405518 932.138000,579.678162
	C928.596802,578.641113 925.145386,577.326660 921.368530,575.823364
z"
        />
        <path
          fill="#E1B582"
          opacity="1.000000"
          stroke="none"
          d="
M910.789429,571.656982
	C913.630676,573.002075 916.157043,574.037354 918.919922,575.292175
	C919.122681,575.774719 919.088867,576.037720 919.012695,576.696167
	C914.508972,575.566895 910.047607,574.041992 905.287231,572.328247
	C904.167297,570.871460 904.578674,569.866211 905.861084,569.400452
	C907.911316,568.655884 909.291382,569.850037 910.789429,571.656982
z"
        />
        <path
          fill="#15191F"
          opacity="1.000000"
          stroke="none"
          d="
M888.985352,578.635437
	C874.711426,579.014221 860.790955,581.617554 846.882935,584.476074
	C838.417175,586.216003 829.890015,587.737122 821.326721,588.879333
	C818.344177,589.277100 816.002014,591.793579 812.403809,591.002075
	C806.913086,592.996643 801.883850,593.991089 796.857300,589.914429
	C796.768921,586.284973 799.452576,585.948547 801.604187,585.695801
	C808.026062,584.941406 814.414368,583.791687 820.777527,582.828125
	C829.260498,581.543640 837.793823,580.356689 846.253540,578.813049
	C858.000000,576.669556 869.560730,573.644958 881.723999,571.441162
	C885.564209,571.407104 887.881042,573.061829 889.101562,576.583801
	C888.999573,577.425476 888.996094,577.865601 888.985352,578.635437
z"
        />
        <path
          fill="#1F0F0F"
          opacity="1.000000"
          stroke="none"
          d="
M889.334106,576.929138
	C887.199768,575.428589 885.396484,573.871887 883.304749,572.158813
	C877.824341,569.708252 872.830322,567.144775 870.012207,561.834229
	C868.812012,559.572571 867.928040,557.248779 870.207214,554.707397
	C878.585144,559.495605 887.597351,563.289490 892.174255,572.677856
	C891.720520,574.600037 891.374695,576.156006 889.334106,576.929138
z"
        />
        <path
          fill="#46170C"
          opacity="1.000000"
          stroke="none"
          d="
M892.346558,572.958008
	C887.627136,567.971130 883.261292,562.906372 876.603149,560.603699
	C874.118103,559.744263 873.820740,556.563416 871.287476,555.164001
	C870.881958,554.784912 870.781128,554.560120 870.876831,554.022644
	C871.311829,553.650208 871.550354,553.590332 872.157288,553.521118
	C878.973450,554.216187 884.709290,556.972900 890.739075,559.952881
	C891.370850,560.541687 891.636169,560.951721 891.872314,561.779114
	C889.723877,565.825989 893.985840,566.870789 894.911987,569.658142
	C893.863037,570.700134 894.155029,572.514709 892.346558,572.958008
z"
        />
        <path
          fill="#3C5965"
          opacity="1.000000"
          stroke="none"
          d="
M877.692871,589.252197
	C878.755371,589.006409 879.503601,589.004456 880.625854,589.000000
	C889.865234,592.241760 897.033630,598.032837 903.963989,604.598511
	C903.269226,604.965332 902.520996,604.994873 901.398804,605.040649
	C896.997864,606.794312 893.181213,604.863037 889.294678,604.338257
	C887.320801,604.071838 885.335510,603.821289 882.997925,603.293091
	C882.125488,603.012329 881.659912,602.790649 880.911682,602.314697
	C878.557922,599.936401 876.140930,600.151489 873.281372,601.156250
	C872.701050,601.339600 872.460571,601.395874 871.855591,601.545593
	C863.336609,604.282654 854.996643,604.284180 846.237671,603.559814
	C845.386292,603.743042 844.890930,603.754883 844.028625,603.826965
	C843.345764,604.447327 843.020081,605.001404 842.415405,605.749634
	C841.029846,606.567383 840.281860,607.598206 839.219299,608.753174
	C838.430908,609.217896 837.869202,609.464294 837.019592,609.815918
	C832.506470,613.674988 827.888916,616.321594 821.539734,615.037659
	C818.807068,613.233215 815.862305,612.287170 814.461365,609.031372
	C815.141479,606.036255 817.288696,605.492676 819.477905,605.406433
	C824.331848,605.215149 828.633057,603.383667 832.319946,600.542297
	C836.972107,596.957092 842.029907,595.624695 847.791992,594.745850
	C857.677063,593.238098 867.648193,591.971985 877.692871,589.252197
z"
        />
        <path
          fill="#53CDD7"
          opacity="1.000000"
          stroke="none"
          d="
M898.687012,609.604248
	C900.745361,611.487610 902.902039,611.864929 905.483582,612.143250
	C904.821472,616.854004 902.522034,619.997314 897.400574,621.105713
	C887.427368,623.264160 877.489014,623.569214 867.438477,621.997192
	C861.942139,621.137634 858.354248,616.876892 853.770752,614.395142
	C852.274048,613.584717 853.398132,611.986938 854.706543,611.345398
	C855.994873,610.713562 857.727661,611.062988 858.825623,609.261230
	C861.068176,606.138550 864.223511,606.389038 867.162170,606.045837
	C875.822815,605.034180 884.434448,605.402466 893.005920,606.961487
	C894.979736,607.320557 896.979187,607.582764 898.687012,609.604248
z"
        />
        <path
          fill="#3C7D8E"
          opacity="1.000000"
          stroke="none"
          d="
M899.075073,609.592896
	C886.019409,607.032959 872.980652,605.962891 859.421021,608.926636
	C855.140015,606.636047 850.816833,605.478333 846.246948,603.876831
	C849.428833,601.602356 853.452515,602.202698 857.245178,602.073730
	C862.014526,601.911499 866.665894,601.455627 871.630554,600.065674
	C874.502380,604.075439 877.861450,603.989746 881.931396,602.005005
	C888.433899,601.786987 894.295044,603.724915 900.664185,604.883240
	C901.349426,606.886108 899.850830,607.897827 899.075073,609.592896
z"
        />
        <path
          fill="#3E6978"
          opacity="1.000000"
          stroke="none"
          d="
M820.972046,614.171387
	C826.933411,615.603577 830.972595,610.919983 836.370239,609.940125
	C837.000793,610.486755 837.269958,611.052307 837.773682,611.825684
	C840.553528,616.784729 838.136047,620.394043 834.952271,624.132507
	C834.157593,624.592407 833.662415,624.719666 832.774963,624.686279
	C831.017822,624.180115 830.501892,622.616272 828.900208,621.881958
	C827.495850,620.613464 826.147827,619.880798 824.469604,619.052734
	C823.615845,618.547729 823.120728,618.115906 822.396729,617.446960
	C821.982178,617.091431 821.821716,616.942383 821.452637,616.538269
	C820.884705,615.711853 820.687378,615.097961 820.972046,614.171387
z"
        />
        <path
          fill="#47A2AE"
          opacity="1.000000"
          stroke="none"
          d="
M849.258362,626.548218
	C846.527954,626.724792 844.065430,626.702148 841.298523,626.428772
	C840.630310,625.720032 840.303589,625.237000 839.874023,624.310181
	C839.439026,620.651489 840.597168,618.675598 844.598145,618.894958
	C847.095947,618.593689 849.410767,621.069153 851.412842,617.492554
	C852.212158,616.064697 855.430847,618.812744 856.937988,620.578613
	C858.375671,622.263000 857.089417,622.803406 855.582520,623.091003
	C854.278015,623.340027 853.828125,624.474670 853.043884,625.748779
	C851.755432,626.157043 850.640808,626.252991 849.258362,626.548218
z"
        />
        <path
          fill="#3C7D8E"
          opacity="1.000000"
          stroke="none"
          d="
M844.996277,618.611633
	C843.730713,620.396240 840.399048,619.758728 840.983643,623.406738
	C839.048035,625.497803 836.941284,625.978333 834.283386,624.193054
	C836.167053,620.458984 837.932617,616.956665 838.006592,612.432495
	C840.842102,613.519653 845.290161,613.194153 844.996277,618.611633
z"
        />
        <path
          fill="#3C7D8E"
          opacity="1.000000"
          stroke="none"
          d="
M841.721863,605.273804
	C842.364136,604.646973 842.760376,604.317383 843.409180,603.937500
	C843.994568,606.374817 846.241577,607.065125 849.090332,608.733154
	C845.743835,608.771973 843.796204,610.062500 841.232971,609.328857
	C840.165833,607.798706 840.653259,606.660400 841.721863,605.273804
z"
        />
        <path
          fill="#3E6978"
          opacity="1.000000"
          stroke="none"
          d="
M841.620728,605.034668
	C841.731079,606.137329 841.494263,607.298035 841.106934,608.791260
	C840.518616,609.106445 840.080872,609.089050 839.317871,609.021606
	C838.967285,607.216553 839.225647,605.628418 841.620728,605.034668
z"
        />
        <path
          fill="#47A2AE"
          opacity="1.000000"
          stroke="none"
          d="
M854.947876,627.657776
	C854.124634,627.914246 853.400574,627.614075 853.009949,626.401367
	C853.856445,626.107056 854.597534,626.377380 854.947876,627.657776
z"
        />
        <path
          fill="#43626E"
          opacity="1.000000"
          stroke="none"
          d="
M858.483459,635.473145
	C858.519897,635.454651 858.447021,635.491638 858.483459,635.473145
z"
        />
        <path
          fill="#64777D"
          opacity="1.000000"
          stroke="none"
          d="
M804.997192,637.996094
	C804.588013,640.620667 802.435303,640.700928 800.488281,640.985291
	C797.996704,641.349182 795.660828,642.133301 793.174561,643.522949
	C791.302917,643.752625 789.695496,643.752625 787.714294,643.752625
	C788.676392,641.592651 790.619446,641.127075 792.146973,640.172424
	C792.786987,639.772461 794.479797,640.436035 793.804932,638.785034
	C793.618591,638.329224 792.375000,638.305542 791.277588,638.072754
	C789.875000,636.810181 789.519592,635.408203 790.247070,633.413818
	C790.859070,632.539368 791.345276,632.186279 792.350952,631.832886
	C794.925903,631.487000 796.973022,631.572632 798.839844,633.692993
	C799.682800,634.139893 800.193909,634.293091 800.893127,634.641357
	C801.781616,636.330994 803.501831,636.525085 804.792603,637.786621
	C805.025757,638.009094 804.997803,638.010132 804.997192,637.996094
z"
        />
        <path
          fill="#5E9BAB"
          opacity="1.000000"
          stroke="none"
          d="
M799.221802,633.615173
	C797.381104,633.653870 795.590576,633.320984 793.408569,632.991821
	C792.868103,632.764709 792.719055,632.533875 792.590210,631.890015
	C794.091187,628.909485 796.997253,629.584778 799.583496,628.564941
	C808.926697,627.183594 817.643127,624.589050 827.103149,624.040405
	C828.356140,624.503113 828.888489,625.129211 829.106689,626.427246
	C819.118103,628.887390 809.030823,630.299683 799.221802,633.615173
z"
        />
        <path
          fill="#76D4DF"
          opacity="1.000000"
          stroke="none"
          d="
M829.406250,627.060730
	C828.837219,626.436279 828.602417,626.019226 828.251587,625.391296
	C828.718567,623.738647 829.812866,623.250427 831.640198,623.677246
	C832.428101,623.889343 832.871033,623.910278 833.641724,623.866760
	C836.020325,623.820374 838.071350,623.838440 840.560059,623.815552
	C841.007874,624.308960 841.018066,624.843323 841.011230,625.777832
	C837.208069,626.180664 833.748840,629.558350 829.406250,627.060730
z"
        />
        <path
          fill="#5E9BAB"
          opacity="1.000000"
          stroke="none"
          d="
M804.982422,637.674744
	C803.288025,637.586975 801.367615,637.452209 800.910278,635.060547
	C802.567505,635.362488 804.163635,635.719177 804.982422,637.674744
z"
        />
        <path
          fill="#875E55"
          opacity="1.000000"
          stroke="none"
          d="
M773.013794,651.672485
	C773.069519,654.043457 772.134094,655.156494 770.021973,654.973755
	C763.440552,654.403992 757.362061,657.170715 750.963379,657.805237
	C749.305725,657.969604 747.711487,658.124329 746.097778,657.370483
	C740.206787,654.618713 734.306641,651.882263 728.349670,649.277649
	C724.948853,647.790649 721.783813,646.302612 720.473145,642.347046
	C719.701904,640.019226 717.549622,639.001038 715.256042,638.157104
	C712.121887,637.003967 714.966858,636.321594 715.840088,635.263916
	C721.018616,634.264099 724.262817,638.221191 728.793091,639.769409
	C731.345703,641.988708 733.656860,642.530212 736.806641,641.826782
	C742.728027,640.504395 748.621887,638.787659 755.152344,638.728882
	C756.534729,638.980957 757.419739,639.522888 758.754395,639.901855
	C762.056335,642.514832 765.954956,643.637756 768.733765,646.944092
	C769.849060,648.451965 770.902222,649.485291 772.724976,650.001038
	C773.031494,650.499817 773.022522,650.926025 773.013794,651.672485
z"
        />
        <path
          fill="#847876"
          opacity="1.000000"
          stroke="none"
          d="
M759.241333,641.082336
	C758.004272,640.832703 757.209656,640.624939 756.223022,640.169922
	C757.239807,636.499390 760.490417,637.149414 762.999023,636.632446
	C771.233337,634.935608 779.706665,634.576965 788.269409,632.225525
	C789.279907,632.155823 789.837280,632.275635 790.673218,632.828003
	C790.976440,634.501038 790.973633,635.868652 790.954712,637.649048
	C789.015625,638.368713 786.839539,637.276794 785.058350,639.441589
	C781.885559,642.006165 778.305481,641.592224 774.918213,641.552979
	C769.842224,641.494202 764.718994,642.601196 759.241333,641.082336
z"
        />
        <path
          fill="#A19796"
          opacity="1.000000"
          stroke="none"
          d="
M759.002075,641.324463
	C760.289856,639.584534 762.183777,640.039001 763.944275,640.017700
	C770.638672,639.936646 777.333862,639.919250 784.477112,639.829712
	C781.580872,644.550293 776.549561,642.721985 772.101135,642.953674
	C769.624451,643.082825 767.596130,643.113647 768.917358,646.855957
	C765.462219,645.754944 761.906555,644.418152 759.002075,641.324463
z"
        />
        <path
          fill="#45AFBC"
          opacity="1.000000"
          stroke="none"
          d="
M916.007935,620.524536
	C915.601318,621.214172 915.129089,621.589417 914.348633,621.996948
	C914.400391,620.621216 911.964661,619.373047 913.284790,618.361572
	C914.385254,617.518372 915.462219,618.942627 916.007935,620.524536
z"
        />
        <path
          fill="#6E96A8"
          opacity="1.000000"
          stroke="none"
          d="
M300.273621,479.995697
	C300.089172,479.699615 300.041229,479.387085 299.999146,479.234680
	C299.985077,478.602753 300.013092,478.123230 300.039490,477.283386
	C300.365631,476.534302 300.780762,476.286896 301.639984,476.136322
	C302.249725,476.152405 302.490692,476.193756 303.067505,476.375580
	C306.738403,479.508087 310.875488,481.274933 314.821411,483.967438
	C318.414001,485.725647 321.862885,485.416016 325.303314,484.918732
	C327.757294,484.564026 330.204712,484.183044 332.681793,484.528595
	C333.969208,484.708160 335.634430,484.820526 335.877289,486.325775
	C336.158081,488.066467 334.298096,488.019897 333.212677,488.637665
	C330.966827,489.915894 328.267731,488.774078 326.079956,490.372314
	C327.406158,491.976410 329.269623,493.015625 328.568115,495.709229
	C328.261597,496.199280 328.097046,496.368774 327.617340,496.705841
	C322.539581,497.532593 319.846222,493.089691 315.460571,491.933044
	C314.088501,489.253601 311.767548,488.209991 309.679169,486.902649
	C306.466919,484.891663 303.023071,483.205170 300.273621,479.995697
z"
        />
        <path
          fill="#3C5965"
          opacity="1.000000"
          stroke="none"
          d="
M302.412476,476.901489
	C301.694031,477.107788 301.221283,477.051147 300.393188,476.958801
	C297.544708,474.953217 294.226837,474.321777 291.902985,471.641418
	C292.362366,470.971527 292.896271,470.616547 293.716278,470.126282
	C294.598297,470.014984 295.133911,470.200348 295.801086,470.789551
	C296.007446,470.993134 296.000000,471.000000 296.003174,470.996155
	C297.928802,472.881989 300.929565,472.385315 303.112122,474.422852
	C303.480499,475.497284 303.277618,476.138000 302.412476,476.901489
z"
        />
        <path
          fill="#3C5965"
          opacity="1.000000"
          stroke="none"
          d="
M291.148438,471.308228
	C291.103790,470.941589 291.209991,470.445618 291.804749,471.044037
	C291.858887,471.237183 291.386749,471.360413 291.148438,471.308228
z"
        />
        <path
          fill="#92BFCE"
          opacity="1.000000"
          stroke="none"
          d="
M481.519653,374.439880
	C481.924683,375.189789 482.002136,375.785950 482.033691,376.680237
	C480.509491,377.080383 478.879059,377.652649 477.946930,375.347839
	C478.837494,374.207703 479.921021,373.797333 481.519653,374.439880
z"
        />
        <path
          fill="#142732"
          opacity="1.000000"
          stroke="none"
          d="
M432.010712,347.385101
	C435.842255,343.308929 439.052032,345.342651 441.792267,348.381958
	C444.547424,351.437805 447.211243,352.990173 451.257629,350.566559
	C452.534088,349.802032 454.478271,350.080261 456.122131,350.015045
	C460.742981,349.831726 465.340149,350.098877 469.981934,349.207031
	C473.232147,348.582550 476.450348,349.101013 479.897308,350.892731
	C485.661652,353.889038 492.243805,354.924744 498.123322,350.339050
	C499.735046,349.081970 501.434052,348.921234 503.261841,349.015472
	C506.270416,349.170593 509.211609,348.845001 512.519714,348.006195
	C514.290894,348.910309 514.320129,350.332184 514.328430,352.167419
	C513.546753,354.999176 514.433960,357.387360 514.455078,360.209045
	C514.360596,362.604553 514.799072,364.666962 513.212891,366.730591
	C508.469849,365.774323 506.723602,361.846649 505.691986,358.301697
	C505.035431,356.045654 503.860718,355.922638 502.513397,355.387665
	C500.940674,354.763123 499.451691,355.400269 498.570862,356.704987
	C497.550385,358.216522 497.868469,359.877686 498.943634,361.339050
	C499.336060,361.872467 499.896149,362.297302 500.221039,362.862885
	C501.254456,364.661957 503.254608,366.725464 500.552948,368.417633
	C497.946808,370.049957 497.855988,367.050842 496.541016,365.905975
	C492.921967,362.755157 488.067719,362.034180 483.440125,360.968140
	C471.428162,358.200928 459.000305,358.779144 446.949890,356.273041
	C444.075470,355.675201 440.995514,356.005371 438.010498,356.015717
	C434.692047,356.027191 431.271667,356.385193 432.722748,351.126587
	C432.993317,350.146027 432.280396,348.894104 432.010712,347.385101
z"
        />
        <path
          fill="#1B2E3A"
          opacity="1.000000"
          stroke="none"
          d="
M496.414673,393.320679
	C495.480408,387.087708 496.924957,381.037079 494.817139,374.858948
	C495.014771,372.655579 496.199860,372.845764 497.650726,372.891937
	C502.419891,373.043701 507.196045,372.975342 512.430176,372.985779
	C514.491760,374.097229 514.446411,375.820648 514.443787,377.463257
	C514.434753,383.093719 514.692322,388.727600 514.155029,394.794739
	C512.723938,397.624725 510.368011,398.262299 508.094818,397.866455
	C504.086212,397.168427 500.012054,396.354401 496.414673,393.320679
z"
        />
        <path
          fill="#33505C"
          opacity="1.000000"
          stroke="none"
          d="
M512.582581,396.364258
	C512.906067,388.625671 512.930969,381.272675 512.923340,373.449829
	C512.905884,371.268585 512.921021,369.557159 512.900146,367.418365
	C512.956665,364.850708 513.049316,362.710419 513.249634,360.121765
	C513.999817,359.487457 514.622009,359.540680 515.543213,360.193115
	C518.019409,369.319611 516.864258,378.175537 516.611328,387.475098
	C517.269165,405.198975 517.096497,422.456940 516.713562,439.714417
	C516.691467,440.709015 516.558167,441.695770 516.892944,443.104889
	C517.277771,444.059692 517.582397,444.558655 518.128479,445.421021
	C518.495422,447.181244 517.797241,448.033630 516.252930,448.760071
	C514.542236,448.980316 513.497314,448.496674 512.369507,447.208679
	C511.201996,445.151733 511.371399,443.334106 511.414612,441.075073
	C510.885406,437.571869 510.974792,434.518341 511.333344,430.994141
	C510.630615,420.670868 511.098480,410.807404 511.072937,400.948517
	C511.069092,399.457123 511.369171,398.001648 512.582581,396.364258
z"
        />
        <path
          fill="#1F323D"
          opacity="1.000000"
          stroke="none"
          d="
M485.110535,373.486206
	C484.273407,373.883881 483.500427,373.968414 482.348938,373.955109
	C482.041016,372.888306 481.973694,371.802246 482.904388,371.218079
	C483.953583,370.559570 485.038147,368.798401 486.156036,369.627747
	C488.097839,371.068329 485.378571,371.914948 485.110535,373.486206
z"
        />
        <path
          fill="#142732"
          opacity="1.000000"
          stroke="none"
          d="
M373.020020,359.934692
	C372.126068,359.743469 371.490112,359.333405 370.601257,358.790253
	C370.171661,358.366455 369.994965,358.075745 369.751160,357.541565
	C371.494324,357.193787 373.791016,356.366577 373.020020,359.934692
z"
        />
        <path
          fill="#142732"
          opacity="1.000000"
          stroke="none"
          d="
M369.760010,356.940308
	C368.916718,356.723724 368.097687,356.141693 367.123871,355.293213
	C368.160217,355.083160 369.485077,354.896362 369.760010,356.940308
z"
        />
        <path
          fill="#2B3D45"
          opacity="1.000000"
          stroke="none"
          d="
M513.348267,353.121399
	C512.966675,351.509979 512.966980,350.154755 512.951111,348.392609
	C512.951538,344.002930 512.968140,340.020233 512.979370,335.590271
	C513.427795,335.113495 513.881470,335.083954 514.676147,335.071777
	C517.810364,338.286621 516.733582,342.133575 516.729980,346.241577
	C516.372375,348.508911 516.995728,350.430847 515.714844,352.531616
	C514.943726,353.318146 514.371399,353.474518 513.348267,353.121399
z"
        />
        <path
          fill="#372624"
          opacity="1.000000"
          stroke="none"
          d="
M508.186035,336.174347
	C508.489929,335.675690 508.966553,335.382477 509.735168,335.083466
	C509.796478,335.928741 509.713989,336.969666 508.186035,336.174347
z"
        />
        <path
          fill="#554441"
          opacity="1.000000"
          stroke="none"
          d="
M888.619080,505.902191
	C894.174072,507.489258 899.330566,508.990265 904.742920,510.754700
	C906.091797,512.639648 905.835876,514.345703 904.598694,515.555847
	C900.353577,519.708008 897.796814,525.005798 894.205444,529.911255
	C891.894897,531.177917 889.679321,530.027100 887.295288,530.664062
	C886.445374,530.595825 885.977295,530.457886 885.197754,530.089478
	C882.425720,527.429993 878.049622,527.921387 876.242859,523.980347
	C876.214111,521.904053 877.219482,520.695251 878.649109,520.237793
	C883.464783,518.696899 884.671570,515.164734 884.405884,510.637024
	C884.348450,509.658386 884.611084,508.680573 885.173096,507.405823
	C886.099915,506.144440 887.083557,505.803558 888.619080,505.902191
z"
        />
        <path
          fill="#4E5963"
          opacity="1.000000"
          stroke="none"
          d="
M886.962036,530.464539
	C888.835938,528.224243 891.011963,529.007690 893.518066,529.837158
	C895.013733,530.575989 896.172668,531.167908 897.641357,531.864990
	C899.077637,532.616272 900.204163,533.262329 901.616333,534.059448
	C902.255310,534.514282 902.437073,534.899170 902.259399,535.643555
	C902.051392,536.123901 902.031006,536.325989 901.985962,536.831848
	C896.060242,537.257446 891.539124,534.127319 886.962036,530.464539
z"
        />
        <path
          fill="#0B050D"
          opacity="1.000000"
          stroke="none"
          d="
M871.278564,553.731689
	C870.976746,554.017151 870.980774,554.513245 870.981750,554.761475
	C869.583252,559.179993 871.401367,563.427002 876.048767,566.375061
	C878.412415,567.874451 881.086853,568.919617 882.853149,571.704956
	C881.710876,573.490845 879.992676,573.833740 878.101013,574.034729
	C871.400269,574.746582 865.129089,577.717712 858.511963,578.395508
	C851.382019,579.125916 844.692627,581.901123 837.478943,582.050598
	C828.740417,582.231628 820.407776,585.264282 811.652893,585.959839
	C807.053528,586.325378 802.002319,586.357483 797.288452,588.781250
	C795.415222,591.001648 793.231384,591.183533 790.540466,590.948914
	C787.545776,588.937317 784.381531,589.786804 781.535583,589.091492
	C779.008057,588.473938 775.897766,588.390747 775.296509,585.145386
	C774.654663,581.680420 777.623901,580.390747 779.955872,578.874695
	C780.785889,578.335083 781.682556,577.854248 782.629211,577.586792
	C788.939575,575.804443 792.985168,571.393066 796.493896,565.715942
	C800.659729,563.213379 805.170898,563.132568 809.701172,561.658447
	C827.539246,559.201416 844.759338,555.679321 861.963257,552.064819
	C864.426392,551.547302 866.903625,551.463806 869.638855,552.728882
	C869.979736,552.974304 870.423279,553.005615 870.645508,553.012390
	C871.105286,553.161499 871.342834,553.303833 871.278564,553.731689
z"
        />
        <path
          fill="#2D3A41"
          opacity="1.000000"
          stroke="none"
          d="
M900.685791,539.783997
	C899.925415,544.729309 895.491028,544.706848 892.582947,544.642395
	C885.343018,544.481689 878.745300,547.159119 871.372559,548.098389
	C870.450623,548.488281 869.892578,548.658569 868.843506,548.546997
	C864.476013,547.180115 863.036743,544.306030 863.170776,540.385803
	C863.335144,535.582092 862.557983,530.726807 864.175659,525.596008
	C864.528748,524.943420 864.679443,524.687683 865.112061,524.090698
	C865.631958,523.579590 865.869873,523.409668 866.511169,523.077637
	C871.548584,522.798950 875.586731,524.490845 879.551880,527.357727
	C880.595154,530.882874 878.442932,533.396545 877.821045,536.252014
	C876.687683,541.455444 877.286438,542.244751 882.460938,541.485840
	C886.545349,540.886841 890.626526,540.250488 894.662659,539.354980
	C896.581482,538.929260 898.537109,538.310059 900.685791,539.783997
z"
        />
        <path
          fill="#11181F"
          opacity="1.000000"
          stroke="none"
          d="
M868.661316,547.855103
	C869.460144,548.053589 869.887390,548.068970 870.633667,548.109680
	C870.666321,549.512756 870.379944,550.890503 870.036621,552.621277
	C850.109192,555.307434 830.868164,561.357178 810.436279,562.977661
	C809.110779,562.409241 808.518250,561.645508 808.346191,560.212769
	C808.589600,558.759277 809.301758,558.051025 810.515747,557.281738
	C811.334045,556.892944 811.830200,556.751709 812.727905,556.624634
	C813.627502,556.532776 814.143982,556.506042 815.049683,556.467041
	C825.799927,556.449036 835.323059,551.959045 845.867371,550.744263
	C853.649414,549.995605 860.840759,547.879578 868.661316,547.855103
z"
        />
        <path
          fill="#182932"
          opacity="1.000000"
          stroke="none"
          d="
M901.109009,539.808289
	C894.167297,541.245667 887.382996,542.413391 880.609070,543.638977
	C875.808105,544.507629 874.981689,543.389587 875.587952,538.434998
	C876.025024,534.862793 877.812805,531.927124 878.955383,528.307983
	C881.391418,526.592896 883.374634,528.596680 885.793091,529.115479
	C886.129395,529.698547 886.300781,529.854858 886.674805,530.033142
	C891.655945,532.367249 896.829346,533.890625 901.619019,537.041016
	C902.001831,537.522217 902.042358,537.908813 902.147339,538.579834
	C901.898193,539.097290 901.584473,539.330383 901.109009,539.808289
z"
        />
        <path
          fill="#964F38"
          opacity="1.000000"
          stroke="none"
          d="
M891.147949,560.811829
	C884.915039,558.758789 878.930176,556.409424 872.591431,553.993835
	C872.014282,553.794800 871.791077,553.661987 871.908508,553.340210
	C872.249207,553.151245 872.640869,553.097534 872.824707,553.024048
	C874.322205,553.014465 875.635864,553.078308 877.344238,553.157227
	C882.687073,554.140869 887.892334,554.510193 891.422791,559.376831
	C891.476074,560.006470 891.436035,560.260986 891.147949,560.811829
z"
        />
        <path
          fill="#3E1E1C"
          opacity="1.000000"
          stroke="none"
          d="
M871.905518,553.100952
	C872.249207,553.151245 872.203613,553.539062 872.220520,553.733337
	C872.027649,553.946716 871.817932,553.965759 871.292480,554.000977
	C870.956055,553.851074 870.935425,553.685059 870.891235,553.269043
	C871.099060,553.029663 871.330383,553.040161 871.905518,553.100952
z"
        />
        <path
          fill="#635B5F"
          opacity="1.000000"
          stroke="none"
          d="
M889.218018,505.741516
	C888.417969,506.792755 887.560120,506.969818 886.290527,506.975555
	C884.704285,508.262207 883.139771,508.671478 881.012451,508.584900
	C879.573547,508.422485 878.514221,508.530640 877.131653,508.141632
	C876.560974,507.894958 876.345947,507.773071 875.857422,507.409454
	C874.873108,505.940613 873.658630,505.167114 872.778076,503.726715
	C872.807800,502.621521 874.661743,502.211945 873.085327,501.111572
	C870.367920,499.214661 867.809631,497.165833 864.540283,496.114990
	C855.532898,493.219757 846.533325,490.335205 837.245056,488.434784
	C834.978821,487.971100 832.713318,487.319580 830.744263,485.337097
	C830.402405,484.548645 830.396851,484.062744 830.687012,483.246460
	C830.953796,481.807465 831.039368,480.695862 831.208862,479.174591
	C833.529907,476.959839 835.760498,478.181244 837.884827,478.922180
	C851.050903,483.514252 864.067627,488.518799 877.145813,493.351868
	C880.188782,494.476379 882.204285,494.203888 884.048096,491.192444
	C885.370178,491.061676 886.329895,491.024963 887.649536,491.007629
	C888.026306,492.708221 887.685547,494.494049 889.678833,495.879639
	C888.490540,497.771027 885.648560,496.196655 884.183350,498.698425
	C886.316040,500.743317 888.624817,502.480743 891.071045,504.523804
	C890.611938,505.051239 890.015259,505.273010 889.218018,505.741516
z"
        />
        <path
          fill="#372624"
          opacity="1.000000"
          stroke="none"
          d="
M902.463013,535.959717
	C902.029968,535.541016 901.988220,535.160156 901.924194,534.494873
	C903.756409,532.589355 905.293884,532.224304 905.992188,535.600769
	C904.955261,535.974243 903.904785,535.985962 902.463013,535.959717
z"
        />
        <path
          fill="#310604"
          opacity="1.000000"
          stroke="none"
          d="
M891.181885,559.818726
	C887.105835,556.794006 882.468140,555.730591 878.016357,553.407959
	C879.831177,550.929321 882.081177,553.034485 884.216797,553.069153
	C889.035095,553.147339 893.380310,550.438721 898.659424,551.011475
	C899.802185,552.510437 901.313049,552.786865 902.888062,553.537781
	C904.329163,556.497009 902.127075,557.408081 900.441772,558.578369
	C898.674011,559.806030 896.378418,559.602234 894.140625,560.803223
	C892.838562,560.987671 892.016418,560.833984 891.181885,559.818726
z"
        />
        <path
          fill="#631D07"
          opacity="1.000000"
          stroke="none"
          d="
M893.883728,560.317993
	C896.239441,556.938049 901.135681,558.228027 903.041382,554.086792
	C904.155640,553.845520 905.164429,553.972107 906.554688,554.079834
	C910.773560,555.551880 914.610962,557.042908 918.727112,558.762939
	C917.429749,562.517334 913.773193,560.906982 911.226685,562.082092
	C909.466187,562.894470 907.425659,563.100037 905.254761,563.863281
	C903.464111,565.494812 902.074341,563.953369 900.239380,563.810669
	C897.998230,562.388367 895.181519,562.997803 893.883728,560.317993
z"
        />
        <path
          fill="#534A50"
          opacity="1.000000"
          stroke="none"
          d="
M940.462524,514.011414
	C944.746338,512.700623 949.493164,511.404236 954.626953,510.043518
	C956.838196,510.484375 958.662476,510.989563 960.564209,511.516205
	C959.717957,513.662048 957.995667,512.706543 956.343994,513.087280
	C950.975281,513.953064 945.957703,514.111267 940.462524,514.011414
z"
        />
        <path
          fill="#7D2C11"
          opacity="1.000000"
          stroke="none"
          d="
M760.633545,353.153046
	C762.034607,353.252167 762.987671,353.276031 764.293579,353.311188
	C766.447693,355.661896 768.778992,354.802765 771.034241,354.375427
	C777.185547,353.209747 780.959045,356.280151 780.975708,362.579071
	C780.987610,367.062256 780.860718,371.545807 780.801636,376.495056
	C779.713867,379.609009 780.390930,382.431610 779.924194,385.578278
	C777.272583,385.570984 777.501465,383.454773 777.560059,381.744995
	C777.681091,378.220428 776.812134,374.827240 776.414917,371.382965
	C775.426636,362.813049 772.995728,360.219574 764.046692,359.646667
	C754.019165,359.004730 743.896423,358.431396 733.978088,360.948364
	C730.746155,361.768555 727.428894,361.633545 724.156250,362.175262
	C715.732605,363.569550 707.731384,366.875793 699.165833,367.487366
	C697.384155,367.614563 696.129333,368.711975 694.340576,369.781982
	C690.345947,370.809113 687.877747,369.962830 687.676636,365.320526
	C687.765320,364.591431 687.829651,364.306213 688.098511,363.638916
	C689.835999,362.048676 691.205994,360.594208 693.568481,359.955322
	C696.832886,359.884308 699.717529,360.410309 702.894165,359.373108
	C709.094421,360.270477 714.375549,356.626892 720.585999,357.063965
	C725.212158,354.075134 730.208374,355.403290 734.779114,354.760193
	C741.233398,353.852081 747.814148,353.609741 754.298462,352.649841
	C756.294312,352.354401 758.231384,352.823395 760.633545,353.153046
z"
        />
        <path
          fill="#7D2C11"
          opacity="1.000000"
          stroke="none"
          d="
M694.741638,348.201904
	C700.816711,348.571075 706.450989,347.045105 712.626465,346.886902
	C713.071777,346.787628 713.068054,347.012115 713.024780,347.115723
	C714.258118,349.859955 718.624207,350.073059 718.013428,354.264191
	C716.412476,355.130859 715.071533,355.053131 713.349426,354.475159
	C705.738586,353.579865 698.626343,352.323853 690.948120,349.363342
	C692.664612,348.838928 693.547791,348.569122 694.741638,348.201904
z"
        />
        <path
          fill="#CD7D4C"
          opacity="1.000000"
          stroke="none"
          d="
M713.101440,354.625671
	C714.397644,354.126678 715.818237,353.966614 717.653015,353.692261
	C721.101440,351.440247 724.581421,352.247620 728.370483,352.112640
	C731.489075,352.582306 734.153259,352.776917 737.256836,352.057129
	C744.540344,351.441010 751.338257,349.471588 758.573120,351.584106
	C759.419739,351.977203 759.955627,352.038177 760.894165,352.159119
	C761.251953,352.385376 761.207153,352.551636 761.121948,352.973083
	C754.577026,355.202301 747.776062,354.822021 741.128723,355.664764
	C734.769592,356.471008 728.329834,356.640717 721.468018,357.050262
	C718.852478,354.666473 715.366943,357.373718 713.101440,354.625671
z"
        />
        <path
          fill="#CD7D4C"
          opacity="1.000000"
          stroke="none"
          d="
M702.785156,359.113953
	C700.989441,362.318787 697.620300,360.199036 694.428162,360.972290
	C693.382263,360.304047 693.259644,359.535431 693.741394,358.343567
	C693.966614,357.998505 694.385742,357.898987 694.596436,357.860596
	C697.025208,360.022430 699.846802,358.504852 702.785156,359.113953
z"
        />
        <path
          fill="#562118"
          opacity="1.000000"
          stroke="none"
          d="
M693.866333,369.353638
	C695.290833,364.841095 699.260986,365.733856 701.980591,365.359711
	C713.560120,363.766632 724.614380,359.662048 736.311523,358.656494
	C747.984436,357.653015 759.615112,356.492126 771.204224,359.236786
	C774.622498,360.046295 776.450867,362.112579 777.082520,365.695435
	C778.230774,372.207886 779.136230,378.728546 779.617310,385.672241
	C779.368286,396.608490 778.816895,407.206818 778.434509,417.811279
	C778.180115,424.864624 774.771667,430.041504 769.140137,434.395447
	C768.942932,434.641235 768.482666,434.669159 768.253784,434.641785
	C761.916016,434.241333 755.845032,434.469055 749.779663,435.396851
	C747.863708,435.689941 745.816345,435.711304 743.522339,434.773315
	C741.543823,432.034454 743.279358,430.872406 745.154175,430.047455
	C746.798584,429.323914 748.652588,429.101288 750.673462,428.102173
	C756.193481,427.649078 761.283386,428.647125 766.456726,427.197510
	C770.756897,425.992554 772.830383,423.335632 772.934753,419.496399
	C773.362061,403.780731 776.980469,388.106873 773.211426,372.304626
	C772.118164,367.720795 770.349792,364.461426 765.623901,363.645081
	C755.916077,361.968201 746.166382,361.355103 736.494629,363.882477
	C734.529724,364.395935 732.554688,364.642029 730.585388,364.723297
	C725.215088,364.945038 720.025574,365.889648 714.942078,367.654236
	C709.463989,369.555847 703.247620,368.185425 697.638184,371.524597
	C695.603455,371.944885 694.406189,371.400848 693.866333,369.353638
z"
        />
        <path
          fill="#693324"
          opacity="1.000000"
          stroke="none"
          d="
M742.660400,433.689606
	C749.694336,433.652649 756.384277,433.280823 763.078918,433.040497
	C764.639465,432.984467 766.399353,432.456024 767.868408,434.271667
	C766.961609,435.126770 765.898376,435.639099 764.480713,436.203918
	C759.627686,435.642639 755.164734,436.364746 750.804688,437.143951
	C743.906982,438.376678 736.997559,438.758301 730.048706,438.446198
	C721.572998,438.065460 713.405396,440.804626 704.969482,440.700775
	C704.303345,440.692566 703.799866,441.266083 703.033325,442.056061
	C701.731750,443.025909 700.565613,442.938080 699.057373,443.091614
	C696.531738,441.970642 693.939819,442.770599 691.649658,441.054749
	C691.078796,439.933807 691.077759,439.127899 691.571350,437.953674
	C694.579834,435.378479 697.937866,435.355530 701.230896,435.226349
	C705.910767,435.042725 710.399109,434.153748 715.028320,431.894623
	C722.419861,431.282562 729.403748,431.580627 736.837158,431.609985
	C738.987183,432.166199 740.810181,432.334351 742.660400,433.689606
z"
        />
        <path
          fill="#813920"
          opacity="1.000000"
          stroke="none"
          d="
M702.205811,442.202515
	C702.293274,439.614594 703.847229,439.151031 705.880493,438.981262
	C717.732666,437.991425 729.531677,436.524109 741.491821,436.849365
	C746.538757,436.986633 751.612549,435.522217 756.699707,435.080933
	C758.946411,434.886047 761.341431,434.479584 763.832581,435.940948
	C758.256226,438.342834 752.019958,438.064331 745.560547,439.077606
	C744.963013,439.202271 744.786865,439.315613 744.312561,439.528046
	C742.622620,439.743927 741.230774,439.860718 739.419617,439.979736
	C733.217834,440.412048 727.297058,438.964142 721.286377,441.418823
	C719.559937,441.723694 718.199036,441.869415 716.426880,442.024353
	C714.183594,442.105988 712.317017,441.685608 710.241577,442.609436
	C709.516541,442.854523 709.081726,442.909882 708.317871,442.962860
	C706.140503,442.888885 704.250427,443.329285 702.205811,442.202515
z"
        />
        <path
          fill="#9A4A2B"
          opacity="1.000000"
          stroke="none"
          d="
M733.665894,443.128784
	C733.966858,445.371033 731.518188,446.220001 731.150757,448.587952
	C731.144043,448.917480 731.084778,448.969879 731.067810,449.005615
	C729.664429,450.724182 727.484375,450.294739 725.335022,451.120026
	C720.267212,451.443298 715.775452,449.088501 710.626892,450.424500
	C706.212036,450.772247 702.227234,450.412811 698.238159,450.448547
	C696.446106,450.464630 694.466003,450.510773 693.820374,447.815399
	C702.138367,446.332184 710.338440,445.394562 718.501221,444.201630
	C721.907288,443.703888 724.454529,445.338287 727.331604,446.557281
	C730.404053,447.859039 729.307678,440.952576 733.665894,443.128784
z"
        />
        <path
          fill="#6D3E2F"
          opacity="1.000000"
          stroke="none"
          d="
M693.560364,447.329559
	C695.630188,450.267487 698.407104,448.710876 700.675415,448.905121
	C703.786316,449.171509 706.936584,448.979309 710.537842,448.988068
	C713.673096,449.122650 716.168701,450.107605 718.776611,450.562408
	C722.105713,451.142975 723.683960,453.113342 722.494324,456.969330
	C720.638428,458.599030 718.670227,458.784241 717.091736,457.812469
	C709.002991,452.832550 699.756714,454.034546 690.594971,452.609344
	C689.936096,452.480957 689.678772,452.410400 689.062378,452.172913
	C687.367371,451.166473 687.342834,449.953186 687.897522,448.283142
	C688.218079,447.758301 688.379578,447.570496 688.883545,447.238770
	C690.539856,447.158661 691.853638,447.222473 693.560364,447.329559
z"
        />
        <path
          fill="#916E67"
          opacity="1.000000"
          stroke="none"
          d="
M692.305237,437.700134
	C692.087097,438.773438 692.078247,439.519653 692.129822,440.634949
	C692.463501,441.368225 692.698059,441.755615 692.946594,442.506897
	C692.982483,443.305145 692.871521,443.743317 692.444824,444.414978
	C691.062500,444.970398 689.581482,444.678802 689.202087,446.719788
	C689.226074,447.094849 689.132324,447.277740 689.082764,447.367737
	C687.635864,448.233704 686.268433,448.290405 684.668884,446.956421
	C683.983276,445.847229 683.881104,445.022095 684.132935,443.772552
	C684.497986,442.913666 684.828979,442.514618 685.565491,441.999695
	C688.007996,441.143555 688.245239,439.251251 688.779053,437.138916
	C688.977417,436.563629 689.079712,436.338654 689.405334,435.811066
	C690.215698,434.970856 690.893799,434.671967 692.025513,434.931763
	C692.763123,435.861908 692.811401,436.604553 692.305237,437.700134
z"
        />
        <path
          fill="#9A4A2B"
          opacity="1.000000"
          stroke="none"
          d="
M721.339600,441.639709
	C726.266602,437.010437 732.462219,439.383606 738.687256,439.711365
	C733.385498,441.884155 727.428955,440.359406 721.339600,441.639709
z"
        />
        <path
          fill="#9A4A2B"
          opacity="1.000000"
          stroke="none"
          d="
M692.642212,444.735321
	C692.185181,444.285217 692.152161,443.899353 692.151428,443.222687
	C693.665710,441.611389 695.543274,442.064240 697.638428,441.848694
	C699.065857,441.911194 700.115540,441.924316 701.556946,441.916260
	C703.775818,442.075562 705.602905,442.256042 707.709473,442.698486
	C703.109802,444.345612 698.131470,444.925110 692.642212,444.735321
z"
        />
        <path
          fill="#9A4A2B"
          opacity="1.000000"
          stroke="none"
          d="
M710.349731,442.860229
	C711.244995,439.992828 713.421570,440.969116 715.755432,441.769287
	C714.421570,443.289886 712.534119,442.804504 710.349731,442.860229
z"
        />
        <path
          fill="#9A4A2B"
          opacity="1.000000"
          stroke="none"
          d="
M744.237366,439.635315
	C744.204102,439.405029 744.437195,439.246857 744.926392,439.120789
	C744.989685,439.367889 744.763428,439.552734 744.237366,439.635315
z"
        />
        <path
          fill="#631D07"
          opacity="1.000000"
          stroke="none"
          d="
M729.324951,353.731079
	C725.653198,353.866486 722.307007,353.814148 718.513916,353.669922
	C717.167053,351.002655 711.466064,352.784943 712.992004,347.608704
	C716.381287,346.854950 719.780945,346.490601 723.603027,346.070007
	C735.248840,345.207245 746.410461,344.980042 757.205566,349.027771
	C759.205627,349.777740 759.268494,349.610107 759.106628,351.725586
	C752.169495,352.489441 745.455261,353.062592 738.327515,353.611298
	C735.160339,353.418335 732.405334,353.487671 729.324951,353.731079
z"
        />
        <path
          fill="#9B8881"
          opacity="1.000000"
          stroke="none"
          d="
M689.240601,362.705383
	C689.153442,363.250336 689.069214,363.703308 689.025513,363.929474
	C687.448059,366.996643 687.919800,370.206543 687.213867,373.587494
	C686.994385,374.189087 686.875977,374.421570 686.489563,374.948120
	C685.734009,375.512451 685.212097,375.620300 684.261475,375.433228
	C683.666687,375.090057 683.467896,374.888092 683.061707,374.311127
	C681.942688,371.322815 682.225037,368.831116 683.873596,366.107086
	C684.675659,365.008484 685.220886,364.255219 685.866821,363.241913
	C686.658691,362.516144 687.349854,362.050415 688.326660,361.452362
	C688.850830,361.600189 689.089355,361.880280 689.240601,362.705383
z"
        />
        <path
          fill="#C08056"
          opacity="1.000000"
          stroke="none"
          d="
M689.481323,363.171448
	C689.064331,362.929504 688.975159,362.608643 688.749146,361.803894
	C689.023315,360.453918 689.434326,359.587830 690.071350,358.526947
	C691.273499,358.232880 692.249634,358.133636 693.596191,358.016418
	C693.974609,358.742279 693.982605,359.486023 693.988892,360.601501
	C692.594543,361.679657 691.201843,362.386078 689.481323,363.171448
z"
        />
        <path
          fill="#532C24"
          opacity="1.000000"
          stroke="none"
          d="
M683.768494,365.958252
	C684.141357,368.510803 684.152283,371.259918 684.140503,374.457428
	C680.294495,377.041992 676.855957,375.703888 673.243958,373.364807
	C672.990540,372.563385 672.981079,372.125885 672.963745,371.360229
	C672.561279,370.583344 672.113159,370.169495 671.989441,369.244690
	C675.514587,366.858734 679.737488,367.640198 683.768494,365.958252
z"
        />
        <path
          fill="#3A292B"
          opacity="1.000000"
          stroke="none"
          d="
M671.721924,368.972839
	C672.303040,369.438202 672.614014,369.932831 672.940430,370.729797
	C672.337708,371.032562 671.719543,371.032959 670.792847,371.043335
	C670.429626,370.600983 670.375000,370.148682 670.349304,369.354889
	C670.736084,369.009613 671.093994,369.005859 671.721924,368.972839
z"
        />
        <path
          fill="#688FA1"
          opacity="1.000000"
          stroke="none"
          d="
M858.926758,459.495758
	C855.046814,461.569122 860.702759,463.453705 858.987427,465.567810
	C857.796997,467.572388 855.852966,467.290039 853.866638,467.901794
	C851.432800,468.450592 849.361450,468.718842 847.545532,467.178436
	C847.422485,467.074036 847.211548,466.872498 847.173462,466.899872
	C846.822571,467.151886 847.316040,467.293213 847.191040,467.843872
	C846.677734,468.431519 845.855591,468.351624 845.784729,468.500671
	C845.508240,469.082275 845.545410,469.281555 845.380249,468.635712
	C845.373779,468.610260 845.444031,468.565186 845.478577,468.529266
	C845.659546,470.809967 848.460327,470.402863 849.212524,472.458435
	C848.921753,473.395294 848.349304,473.477173 847.586060,473.491669
	C847.431213,473.501617 847.331909,473.546967 847.372925,473.499634
	C847.755798,473.569580 848.110596,473.490448 848.766418,473.539734
	C849.290771,473.587738 849.506958,473.616699 850.041504,473.761475
	C853.082214,475.089325 855.998657,475.783813 858.730957,476.938110
	C860.582520,477.720306 863.016296,478.152618 862.663818,481.303955
	C858.013977,483.265533 854.353882,480.304596 850.397644,479.522308
	C843.964905,478.250366 837.429321,476.951050 831.322876,473.311157
	C831.016724,470.640686 830.988403,468.265350 830.965027,465.444458
	C830.975159,464.555542 830.950562,464.113708 830.835449,463.347412
	C830.783813,462.843353 830.797363,462.665131 830.888184,462.230042
	C834.086609,461.810760 837.151184,462.183472 840.585449,462.940491
	C844.055237,465.221680 847.512695,463.458252 849.809021,462.360840
	C852.782166,460.940002 848.625305,460.077698 848.086548,458.210144
	C851.760071,458.305786 855.349243,456.902130 858.926758,459.495758
z"
        />
        <path
          fill="#77878D"
          opacity="1.000000"
          stroke="none"
          d="
M892.302002,465.409424
	C889.128906,462.342041 885.326355,462.032440 881.305542,461.284821
	C882.179138,459.686890 883.831360,459.713043 885.180542,460.117889
	C889.156250,461.310760 893.255920,461.443512 897.691040,461.914124
	C898.500305,461.888947 898.932129,461.899078 899.688354,461.898743
	C900.449829,461.938538 900.887390,461.982178 901.654602,462.044067
	C903.726318,462.098816 905.469116,462.127686 907.635193,462.344452
	C908.035400,463.407806 908.012451,464.283203 907.986267,465.596893
	C907.405334,466.858276 906.579163,467.333008 905.315430,467.774261
	C904.487183,467.906921 904.011719,467.896149 903.199280,467.739136
	C901.280579,466.739716 899.691162,465.939056 897.621216,467.394653
	C897.092163,467.597321 896.870850,467.653351 896.306396,467.719299
	C894.506104,467.551758 893.504700,466.634491 892.302002,465.409424
z"
        />
        <path
          fill="#77878D"
          opacity="1.000000"
          stroke="none"
          d="
M881.473877,457.518311
	C881.455261,457.480164 881.492554,457.556488 881.473877,457.518311
z"
        />
        <path
          fill="#8396A6"
          opacity="1.000000"
          stroke="none"
          d="
M908.434631,462.671478
	C908.058350,462.532379 908.080994,462.368530 908.081421,462.288208
	C909.411743,460.187775 911.408325,461.231506 913.549316,461.144592
	C912.930054,463.919189 910.687195,462.785797 908.434631,462.671478
z"
        />
        <path
          fill="#73676A"
          opacity="1.000000"
          stroke="none"
          d="
M901.873352,461.839844
	C901.499817,462.694916 900.953491,462.923370 900.145874,462.113770
	C900.146790,461.600433 900.343628,461.365112 900.803345,461.061096
	C901.256592,461.163116 901.509888,461.386444 901.873352,461.839844
z"
        />
        <path
          fill="#4B555C"
          opacity="1.000000"
          stroke="none"
          d="
M917.550903,471.561096
	C921.981750,471.186401 926.067688,470.581604 930.576843,469.989746
	C932.576416,470.793762 934.152710,471.584869 936.466675,472.746155
	C928.499023,474.220123 921.134460,475.582550 913.361572,476.945465
	C911.419739,474.837646 912.681274,473.360901 914.431274,471.679443
	C915.570618,471.110138 916.381409,471.067474 917.550903,471.561096
z"
        />
        <path
          fill="#635B5F"
          opacity="1.000000"
          stroke="none"
          d="
M897.255310,484.850159
	C896.058228,481.593475 893.994324,484.035095 892.511353,483.887360
	C891.723877,483.808899 890.736389,483.998871 890.594299,482.872467
	C890.466309,481.858093 891.276733,481.377991 892.084290,481.233978
	C895.747925,480.580505 898.327515,477.133453 902.649048,477.179199
	C903.710144,477.871368 905.019226,478.107849 905.151733,479.689697
	C904.289978,480.020660 903.546692,480.038116 902.431763,480.067413
	C901.127136,480.849701 901.571350,481.269653 902.717468,481.761658
	C904.269531,481.942291 905.546387,481.942291 907.609619,481.942291
	C904.151245,484.658997 900.595459,483.582764 897.255310,484.850159
z"
        />
        <path
          fill="#59646C"
          opacity="1.000000"
          stroke="none"
          d="
M914.732605,471.795166
	C914.935303,473.754028 912.817749,474.448273 912.977417,476.602692
	C912.518433,476.962921 912.083557,476.979858 911.322021,477.004974
	C909.345581,478.449493 907.560364,478.752380 905.272339,477.304169
	C903.759033,475.490570 901.829163,476.461212 900.218994,475.338715
	C900.726074,473.355225 902.294556,472.845215 904.382446,472.472076
	C907.960144,471.652496 911.091736,470.484619 914.732605,471.795166
z"
        />
        <path
          fill="#4B555C"
          opacity="1.000000"
          stroke="none"
          d="
M904.614014,476.961731
	C906.668945,476.967865 908.401245,476.984344 910.564453,477.006989
	C910.613708,480.336121 908.095337,479.958038 905.426697,480.002075
	C904.392761,479.219604 903.752197,478.436035 903.056458,477.350952
	C903.431396,477.023682 903.861389,476.997864 904.614014,476.961731
z"
        />
        <path
          fill="#4B555C"
          opacity="1.000000"
          stroke="none"
          d="
M902.883179,481.705078
	C901.704529,481.779083 900.029968,482.653015 899.258057,481.356934
	C898.927673,480.802307 900.528564,480.341187 901.734009,480.092285
	C902.297974,480.542114 902.535828,481.004974 902.883179,481.705078
z"
        />
        <path
          fill="#6E96A8"
          opacity="1.000000"
          stroke="none"
          d="
M915.388123,472.031128
	C911.926025,472.656586 908.821472,473.234802 905.321533,473.813751
	C903.512573,472.103058 903.730103,470.466705 905.371399,469.125610
	C906.939087,467.844604 904.222778,468.407745 904.898438,467.306763
	C905.582642,466.204102 906.420288,466.041901 907.653076,466.038086
	C910.921021,467.172607 913.940186,467.941437 917.514771,468.063721
	C917.930908,469.075500 917.938538,470.066223 917.921021,471.424072
	C917.179077,471.855438 916.462341,471.919678 915.388123,472.031128
z"
        />
        <path
          fill="#3A292B"
          opacity="1.000000"
          stroke="none"
          d="
M759.959778,470.509521
	C760.906677,470.380219 761.560974,470.516602 762.547668,470.669983
	C767.026550,472.112427 771.173035,473.537842 775.709595,474.953857
	C780.573792,475.367462 784.865784,476.459991 789.013733,478.219116
	C794.051025,480.355377 799.573303,481.128571 804.822815,483.803223
	C807.255066,485.843292 809.915894,486.220886 812.873413,486.889038
	C813.437988,487.173950 813.647949,487.321716 814.079285,487.788818
	C814.308044,490.767029 812.604736,491.715210 809.950745,492.073456
	C808.866028,491.941071 808.228577,491.642853 807.365479,491.081238
	C806.754822,490.709656 806.387207,490.568176 805.697083,490.543335
	C804.810547,490.691498 804.213318,490.791473 803.291382,490.843140
	C801.700073,490.530396 800.443542,490.540802 798.880005,491.143372
	C798.312195,491.280365 798.078369,491.321991 797.483215,491.392731
	C795.364258,491.546112 793.608582,491.571106 791.478577,491.259216
	C784.789062,489.192596 778.548950,486.995880 772.056396,484.381866
	C771.207153,484.171204 770.523743,484.074799 770.379944,483.698242
	C768.367920,478.427185 763.817566,475.570526 759.593018,471.934357
	C759.560730,471.291199 759.613953,471.033203 759.959778,470.509521
z"
        />
        <path
          fill="#3A292B"
          opacity="1.000000"
          stroke="none"
          d="
M793.591187,460.005798
	C796.578125,461.543762 799.415771,461.956604 802.371765,462.027466
	C803.907104,462.064240 805.712830,461.416016 806.894897,463.618958
	C802.209351,465.327026 797.558167,466.043121 792.377197,465.222931
	C785.363708,464.112579 779.176270,467.081512 774.110657,472.525391
	C773.444336,472.415131 772.839111,471.983551 772.096130,471.300629
	C771.322021,470.036682 771.834412,469.211700 772.696045,468.195038
	C775.989563,467.410950 775.841614,464.636505 776.588928,462.611237
	C777.827820,459.253937 780.152466,458.129272 783.137756,459.686127
	C786.571350,461.476746 789.809387,460.860382 793.591187,460.005798
z"
        />
        <path
          fill="#73676A"
          opacity="1.000000"
          stroke="none"
          d="
M805.315491,485.318848
	C795.544189,481.794708 786.154297,478.501740 776.432007,475.076599
	C776.194824,474.652191 776.368408,474.417877 776.807739,474.121246
	C779.077209,474.388367 781.159241,474.775818 783.639709,475.212402
	C784.527649,475.184143 785.017273,475.183258 785.865479,475.350769
	C791.743835,476.916504 797.248413,478.444702 802.635437,480.357971
	C804.458313,481.005341 806.467957,481.383881 807.802856,483.561951
	C808.043396,483.844482 808.505188,483.938782 808.737549,483.977905
	C808.687378,486.254150 807.193115,485.904175 805.315491,485.318848
z"
        />
        <path
          fill="#3B2C30"
          opacity="1.000000"
          stroke="none"
          d="
M772.798462,467.790375
	C772.744995,468.815582 772.489990,469.635162 772.096680,470.752014
	C770.953186,470.717804 768.950562,471.595490 769.084473,470.246826
	C769.213135,468.951630 770.633728,467.444366 772.798462,467.790375
z"
        />
        <path
          fill="#AA8367"
          opacity="1.000000"
          stroke="none"
          d="
M754.634399,457.065552
	C754.983032,457.574402 754.932373,458.027008 754.822021,458.816833
	C750.389160,460.414825 746.627136,459.061279 743.094238,455.701355
	C746.938843,454.683044 750.478760,456.437958 754.634399,457.065552
z"
        />
        <path
          fill="#AA8367"
          opacity="1.000000"
          stroke="none"
          d="
M725.358154,450.274109
	C726.587097,448.312225 728.482971,448.981476 730.649353,449.003906
	C733.248108,450.162018 735.445312,451.282745 737.571838,452.981445
	C732.929077,454.990173 729.395935,452.234558 725.358154,450.274109
z"
        />
        <path
          fill="#AA8367"
          opacity="1.000000"
          stroke="none"
          d="
M738.005005,452.998657
	C738.889160,453.122742 739.748352,453.281281 740.581116,453.948120
	C739.541382,454.857178 738.640503,454.782593 737.938354,453.471191
	C737.931641,453.102997 737.980164,453.033173 738.005005,452.998657
z"
        />
        <path
          fill="#AA8367"
          opacity="1.000000"
          stroke="none"
          d="
M741.009155,453.997070
	C741.614685,454.103455 742.165222,454.282837 742.871521,454.711517
	C743.027344,454.960785 742.980042,455.030396 742.956299,455.065155
	C742.240417,455.597443 741.606079,455.478119 740.929565,454.568787
	C740.846191,454.217743 740.954102,454.070099 741.009155,453.997070
z"
        />
        <path
          fill="#5D696E"
          opacity="1.000000"
          stroke="none"
          d="
M762.204407,453.224091
	C762.417786,452.652008 762.856140,452.295105 763.583008,451.869446
	C769.727173,452.380554 767.647705,455.447144 765.872070,458.762604
	C765.428284,459.807159 764.890991,460.313080 763.808533,460.720337
	C761.775024,460.848083 760.445740,460.304596 759.819824,458.305328
	C760.065125,456.251312 759.247009,454.284332 761.646484,453.061768
	C762.225098,453.203278 762.436768,453.430847 762.644043,453.662445
	C762.852112,453.895020 762.780579,453.820618 762.204407,453.224091
z"
        />
        <path
          fill="#543937"
          opacity="1.000000"
          stroke="none"
          d="
M760.225952,470.036072
	C760.199219,470.533264 760.146057,470.822693 760.029297,471.548920
	C756.198059,473.339600 754.912598,469.867950 752.498291,467.907562
	C751.966614,466.579529 752.049500,465.599884 752.621338,464.306091
	C753.124390,463.592438 753.505737,463.282013 754.329041,463.074097
	C755.168335,463.735413 755.637024,464.383911 756.384399,465.156372
	C758.390259,465.336945 758.840393,466.850891 759.805542,468.248718
	C760.021912,468.992615 760.110779,469.410431 760.225952,470.036072
z"
        />
        <path
          fill="#3E505A"
          opacity="1.000000"
          stroke="none"
          d="
M763.807129,460.311707
	C764.403870,459.737213 764.836792,459.443481 765.537476,459.138702
	C767.141235,461.050110 767.646729,462.980225 765.230530,464.922852
	C764.309509,464.581573 764.069519,464.214813 763.789307,463.495422
	C763.530457,462.321472 763.352234,461.460999 763.807129,460.311707
z"
        />
        <path
          fill="#3E505A"
          opacity="1.000000"
          stroke="none"
          d="
M765.152588,465.141357
	C764.263489,467.959564 762.435852,467.061890 760.307800,466.149414
	C759.846191,465.787933 759.715820,465.568329 759.583374,464.964508
	C759.995605,463.457550 760.909729,463.086578 762.381897,463.002686
	C763.551819,463.229553 764.144104,463.683807 764.778931,464.687836
	C765.000000,465.000000 765.101135,465.094879 765.152588,465.141357
z"
        />
        <path
          fill="#A19796"
          opacity="1.000000"
          stroke="none"
          d="
M759.323730,464.863647
	C759.581238,465.139893 759.780823,465.573029 759.878784,465.790283
	C759.973572,466.577576 759.970520,467.147614 759.950317,468.146240
	C758.460938,468.219391 757.381531,467.401154 756.751404,465.614868
	C756.663025,465.280304 756.828491,465.147217 756.909363,465.078491
	C757.593262,464.431671 758.291382,464.320770 759.323730,464.863647
z"
        />
        <path
          fill="#784534"
          opacity="1.000000"
          stroke="none"
          d="
M754.763306,462.696289
	C754.483215,463.239197 754.303223,463.426544 754.072571,463.825012
	C752.401367,464.532410 750.739441,465.080383 749.133667,464.455627
	C740.541748,461.112915 731.621277,459.089783 722.280762,457.309692
	C722.323730,453.890778 721.203308,452.214752 717.867004,451.677795
	C715.713074,451.331085 713.292542,451.162323 711.239014,449.269470
	C715.576721,447.310638 719.853760,449.667603 724.706665,449.952393
	C729.468018,449.737701 732.947266,452.799774 737.541504,453.098572
	C738.689880,453.450195 739.448059,453.797363 740.526245,454.181152
	C741.345276,454.496918 741.844421,454.776123 742.638062,455.077606
	C746.200623,457.650879 750.455078,457.369598 754.407715,459.063629
	C754.783875,460.204590 754.805359,461.255096 754.763306,462.696289
z"
        />
        <path
          fill="#77878D"
          opacity="1.000000"
          stroke="none"
          d="
M759.825684,464.847229
	C758.935486,465.084320 758.289673,465.028717 757.317078,464.991455
	C757.444946,462.627655 755.836365,459.586884 759.542236,458.031128
	C760.779724,459.035370 762.011169,459.541748 763.640503,459.982086
	C764.283813,460.781189 764.596619,461.531464 764.901489,462.590759
	C764.628113,463.548676 764.046570,463.590240 763.201416,463.751312
	C761.978821,464.092712 761.024475,464.323608 759.825684,464.847229
z"
        />
        <path
          fill="#929EAC"
          opacity="1.000000"
          stroke="none"
          d="
M830.958679,473.425537
	C841.439880,475.683777 851.834717,478.351501 862.934814,480.966125
	C864.475525,480.897919 865.236694,481.177063 866.269531,481.600800
	C866.738770,481.769531 866.924072,481.842194 867.383728,482.028137
	C868.377014,482.484741 869.142944,482.672363 870.219238,482.889099
	C870.718079,483.009094 870.917236,483.064087 871.412598,483.216553
	C872.292969,483.549500 872.890076,483.749512 873.781372,484.079041
	C877.317566,484.031433 878.305847,487.269775 880.853882,488.734650
	C881.094910,488.956207 881.042419,488.998383 881.032593,488.966187
	C881.169617,489.308105 881.427368,489.415070 881.960938,489.086060
	C883.127686,489.000244 883.862183,489.441376 884.292786,490.772827
	C883.513550,497.483551 883.546570,497.393066 877.262939,495.101166
	C862.209473,489.610413 847.121826,484.213226 831.670410,478.771576
	C830.594543,477.169220 830.765686,475.499390 830.958679,473.425537
z"
        />
        <path
          fill="#37343B"
          opacity="1.000000"
          stroke="none"
          d="
M808.140381,483.494507
	C800.504150,481.397705 792.964966,478.950928 785.164307,476.248047
	C787.081116,473.469910 789.933350,473.227844 792.938110,473.720337
	C794.709106,474.010620 796.430725,474.607056 798.661133,474.538116
	C799.608948,474.522858 800.148987,474.563843 801.085632,474.757202
	C805.625916,476.964478 810.050842,476.723053 814.462463,476.316040
	C815.763733,476.196014 817.022095,475.742126 818.611572,476.667480
	C819.282776,479.798309 817.109680,480.216492 815.138489,481.198578
	C812.908630,482.309509 810.096619,481.021851 808.140381,483.494507
z"
        />
        <path
          fill="#6D3E2F"
          opacity="1.000000"
          stroke="none"
          d="
M830.432007,482.952820
	C830.905151,483.389496 830.942139,483.859955 830.991211,484.683655
	C831.777161,485.896210 832.134766,486.878326 831.741882,488.413300
	C829.993469,490.925690 827.690613,489.559998 825.362183,490.019348
	C823.186096,490.246460 821.554932,489.334229 819.429932,489.195068
	C817.017395,488.489746 815.283752,486.728729 812.501343,487.426270
	C810.366394,487.216278 810.114807,486.119110 810.762207,484.325409
	C817.327087,483.666809 823.661499,483.326721 830.432007,482.952820
z"
        />
        <path
          fill="#43434B"
          opacity="1.000000"
          stroke="none"
          d="
M801.291260,476.224182
	C800.493652,476.006866 800.070312,476.013000 799.329407,476.011658
	C798.301392,475.118744 796.905762,474.885437 796.824463,473.231018
	C802.958191,470.586456 808.927185,469.544861 814.993530,473.425232
	C815.090149,476.977051 812.687927,477.149475 810.376343,477.493469
	C807.370178,477.940887 804.594849,476.574341 801.291260,476.224182
z"
        />
        <path
          fill="#5B555B"
          opacity="1.000000"
          stroke="none"
          d="
M801.191895,476.275330
	C803.043335,475.986603 805.101990,476.269867 807.188538,476.795807
	C810.065552,477.520966 812.705872,476.878601 814.850708,474.025146
	C816.140808,473.851624 817.147217,473.938812 818.533325,474.040771
	C819.334290,474.393890 819.523865,474.832367 819.314087,475.709503
	C819.146545,476.048004 819.041870,476.481018 818.991272,476.697205
	C813.086914,478.191406 807.243591,478.965637 801.191895,476.275330
z"
        />
        <path
          fill="#5B555B"
          opacity="1.000000"
          stroke="none"
          d="
M796.527283,472.914124
	C797.622559,473.736572 798.268066,474.557251 798.962769,475.691071
	C794.536682,476.121979 790.151062,472.316833 785.252075,475.844971
	C784.649902,475.910767 784.432312,475.773621 784.144043,475.421082
	C787.539612,471.856171 791.985718,473.292175 796.527283,472.914124
z"
        />
        <path
          fill="#574649"
          opacity="1.000000"
          stroke="none"
          d="
M810.655762,483.990723
	C811.089661,485.094635 810.904053,486.312561 812.610901,486.869507
	C813.067688,487.045746 813.197571,487.161591 813.266602,487.531219
	C810.290466,488.061981 807.499512,487.838440 805.048828,485.436401
	C806.129211,484.918365 807.324341,484.749054 808.744690,484.298401
	C809.419556,484.002838 809.869141,483.988678 810.655762,483.990723
z"
        />
        <path
          fill="#4B555C"
          opacity="1.000000"
          stroke="none"
          d="
M830.603149,463.192322
	C831.510681,463.371613 831.853943,463.869598 831.177368,464.834656
	C830.382507,464.587311 830.094055,464.078064 830.603149,463.192322
z"
        />
        <path
          fill="#37343B"
          opacity="1.000000"
          stroke="none"
          d="
M819.417480,475.981506
	C819.093933,475.604828 819.041382,475.161682 818.950989,474.387024
	C819.430542,474.328827 819.947876,474.602142 820.465271,474.875458
	C820.206299,475.221985 819.947327,475.568512 819.417480,475.981506
z"
        />
        <path
          fill="#85C1D1"
          opacity="1.000000"
          stroke="none"
          d="
M891.978027,465.476654
	C893.458130,464.994354 894.610229,465.476654 895.818848,466.704773
	C898.073730,468.633362 899.065186,470.745178 898.305786,473.799072
	C895.721191,474.805786 893.312683,475.444946 890.525269,476.082062
	C889.864929,476.224731 889.603455,476.399506 889.180664,476.755890
	C888.328003,477.002716 887.656738,477.098053 886.646118,477.202972
	C885.589478,477.480682 885.096130,478.049652 884.204590,478.697815
	C879.619995,479.213287 875.896851,476.908722 871.443604,476.417053
	C870.844177,476.341064 870.605042,476.304962 870.014160,476.184021
	C868.745667,475.847351 867.859741,475.430725 866.493896,475.575623
	C859.675964,473.917114 853.016663,472.837799 847.297119,468.319427
	C846.387573,467.439972 845.705505,466.873413 844.645325,465.992737
	C847.485107,466.385803 849.833374,466.710846 852.588257,467.052612
	C854.820312,466.997803 856.661682,466.802795 858.685181,467.851013
	C859.747620,467.942078 860.480896,467.937805 861.580872,467.928864
	C867.747375,465.866425 873.772522,467.255249 879.676575,466.964081
	C882.759155,466.812042 885.933838,466.065826 889.532471,466.878632
	C890.828613,466.857361 891.561096,466.624512 891.978027,465.476654
z"
        />
        <path
          fill="#88A6B8"
          opacity="1.000000"
          stroke="none"
          d="
M889.809937,466.628296
	C887.322632,468.989624 884.224121,467.857849 881.334717,467.946503
	C875.193115,468.134979 869.041504,467.996094 862.420776,467.957794
	C862.255066,466.593872 863.132874,466.036377 864.454895,466.014465
	C872.153870,465.886780 879.864014,466.108337 887.539917,465.180573
	C888.177551,465.103516 888.923157,465.919617 889.809937,466.628296
z"
        />
        <path
          fill="#88A6B8"
          opacity="1.000000"
          stroke="none"
          d="
M859.017395,467.610413
	C857.039185,469.582886 855.324768,467.633240 853.234985,467.306549
	C854.440186,465.367462 856.428589,466.193726 858.591736,465.940125
	C859.003784,466.375671 859.012146,466.825073 859.017395,467.610413
z"
        />
        <path
          fill="#83AEBF"
          opacity="1.000000"
          stroke="none"
          d="
M846.791565,468.002563
	C853.506775,469.749115 859.498352,472.907898 866.534058,474.008545
	C867.660522,474.714233 868.628174,474.403717 869.737061,474.817932
	C869.998230,475.029816 870.001221,474.997192 869.988159,475.007141
	C874.333679,476.550842 878.771179,477.776550 883.654419,478.243225
	C884.517212,477.735718 885.076111,477.434784 885.970947,477.173218
	C886.512329,480.155487 883.370178,482.157806 884.038330,485.639526
	C883.528992,486.416382 883.512451,486.997986 883.216919,487.756714
	C882.523987,488.163483 881.988770,488.340027 881.274231,488.736389
	C877.627014,489.930206 876.957520,484.713745 873.291443,485.325958
	C872.877747,484.932312 872.725891,484.759735 872.523376,484.248138
	C872.042603,483.383362 874.050232,482.629059 872.334290,482.242310
	C864.444763,480.464081 857.342590,476.396790 849.237671,474.219177
	C848.950073,474.026459 849.007568,474.018341 848.988525,474.040283
	C848.788025,473.907532 848.606628,473.752869 848.712646,473.385559
	C847.830078,471.558716 845.108215,471.266815 845.110535,468.670807
	C845.111450,467.619995 846.032837,468.204376 846.791565,468.002563
z"
        />
        <path
          fill="#88A6B8"
          opacity="1.000000"
          stroke="none"
          d="
M898.358826,474.280518
	C897.460083,471.994171 896.790344,469.821716 896.106689,467.265930
	C896.092773,466.882538 896.497742,466.817230 896.702026,466.798401
	C898.635193,465.813995 900.428589,465.834747 902.651794,466.673065
	C903.477295,466.930359 903.910583,466.943848 904.668579,466.969391
	C905.680908,468.058594 907.027100,467.893646 908.572266,468.433746
	C905.885132,469.366211 904.750122,470.789337 904.946899,473.455200
	C903.517578,474.210358 902.108948,474.606262 900.331665,475.054260
	C899.504578,474.869080 899.046204,474.631775 898.358826,474.280518
z"
        />
        <path
          fill="#929EAC"
          opacity="1.000000"
          stroke="none"
          d="
M883.298157,487.825684
	C883.225769,487.451935 883.392395,486.916931 883.791382,486.213104
	C883.861633,486.584320 883.699402,487.124359 883.298157,487.825684
z"
        />
        <path
          fill="#83AEBF"
          opacity="1.000000"
          stroke="none"
          d="
M881.849792,488.901428
	C881.822632,489.033264 881.519409,489.149292 881.119507,489.099670
	C881.206360,488.917877 881.390015,488.901764 881.849792,488.901428
z"
        />
        <path
          fill="#83AEBF"
          opacity="1.000000"
          stroke="none"
          d="
M889.225830,476.826172
	C889.133728,476.590240 889.355774,476.356140 889.905945,476.142548
	C889.958862,476.347443 889.727478,476.569061 889.225830,476.826172
z"
        />
        <path
          fill="#214A59"
          opacity="1.000000"
          stroke="none"
          d="
M235.715820,504.069550
	C237.024872,505.298035 238.535370,504.791046 240.318695,505.418243
	C235.087372,506.934418 230.425140,507.618164 225.305267,506.281769
	C224.652649,505.649658 224.523834,505.227478 224.950745,504.552002
	C226.263184,504.308044 227.271027,504.248383 228.659271,504.189941
	C231.161346,505.229065 233.213638,503.491180 235.715820,504.069550
z"
        />
        <path
          fill="#214A59"
          opacity="1.000000"
          stroke="none"
          d="
M220.176788,507.194580
	C220.009613,506.568878 220.044647,506.182068 220.122772,505.508362
	C220.570984,505.186920 220.976089,505.152405 221.686310,505.102661
	C222.376541,505.125275 222.689163,505.299408 222.994446,505.865356
	C222.697983,507.681915 221.903351,508.310669 220.176788,507.194580
z"
        />
        <path
          fill="#3C7D8E"
          opacity="1.000000"
          stroke="none"
          d="
M223.372406,506.095428
	C222.789368,505.927734 222.528961,505.716187 222.129990,505.296021
	C222.631866,504.195862 223.541473,504.043945 224.901474,504.262512
	C225.182312,504.730255 225.109283,505.092773 225.016647,505.733948
	C224.563034,506.025543 224.129044,506.038574 223.372406,506.095428
z"
        />
        <path
          fill="#394148"
          opacity="1.000000"
          stroke="none"
          d="
M252.027039,502.458252
	C253.013138,501.138458 253.755554,499.833038 255.704773,500.834625
	C263.102356,504.635864 271.682190,504.632080 279.244934,508.004730
	C282.033936,509.248505 285.103333,509.089508 288.549805,509.018311
	C289.880127,513.634338 286.761322,517.179810 281.088318,517.946045
	C278.726135,518.265076 276.382751,517.934143 274.268982,516.901489
	C269.278931,514.463623 264.082458,513.014343 258.510498,512.852844
	C257.800201,512.832275 257.110809,512.087830 256.208374,511.359436
	C257.518890,508.329590 256.055145,506.992554 253.381302,506.004578
	C252.730377,504.892120 252.407120,503.837677 252.027039,502.458252
z"
        />
        <path
          fill="#759EB0"
          opacity="1.000000"
          stroke="none"
          d="
M347.221069,534.991699
	C334.657867,528.736572 322.943512,521.150513 311.181030,513.195251
	C312.922333,511.613129 314.546112,512.072998 316.001587,512.878784
	C326.252197,518.554199 336.157684,524.795593 345.896545,531.303955
	C346.592072,531.768738 347.106445,532.419800 347.517456,533.568848
	C347.544037,534.191833 347.498901,534.441345 347.221069,534.991699
z"
        />
        <path
          fill="#63879A"
          opacity="1.000000"
          stroke="none"
          d="
M347.160858,535.560364
	C346.985931,535.078613 346.983551,534.864624 346.988892,534.329712
	C350.108215,531.768372 352.734619,536.137817 356.155731,534.603455
	C358.040680,534.480591 359.513611,534.594421 360.961975,535.920654
	C361.250580,536.740784 361.101471,537.165710 360.623627,537.421753
	C360.183868,537.657410 359.666779,537.348999 359.221069,537.592896
	C359.703583,537.723083 360.136322,537.935059 360.571472,538.658875
	C360.728882,539.440491 360.655609,539.886658 360.249268,540.582336
	C359.478455,541.687927 358.680145,541.994141 357.373840,541.925537
	C353.801117,539.881775 350.567261,537.854919 347.160858,535.560364
z"
        />
        <path
          fill="#759EB0"
          opacity="1.000000"
          stroke="none"
          d="
M297.470215,506.747498
	C297.069305,506.230713 296.984436,505.886353 296.939636,505.276154
	C302.765656,502.964905 305.986694,506.941925 309.510498,510.713074
	C309.490143,511.350616 309.419708,511.600922 309.034149,512.081055
	C305.074768,510.513885 301.430511,508.716919 297.470215,506.747498
z"
        />
        <path
          fill="#759EB0"
          opacity="1.000000"
          stroke="none"
          d="
M310.892212,513.130188
	C310.275604,513.041382 309.705383,512.894165 308.927124,512.528931
	C308.719055,512.310852 308.921112,512.088684 309.022247,511.977692
	C309.871857,511.405334 310.510437,511.557312 311.045837,512.584839
	C311.032959,512.956360 310.938629,513.071838 310.892212,513.130188
z"
        />
        <path
          fill="#5B555B"
          opacity="1.000000"
          stroke="none"
          d="
M279.756470,502.734863
	C276.989288,504.734100 274.193024,503.045654 271.719116,501.939362
	C266.616150,499.657349 260.938843,499.549164 255.875992,497.202179
	C253.991913,496.328796 252.560654,495.164001 251.157196,493.312805
	C261.508820,493.134705 270.073059,499.132233 279.756470,502.734863
z"
        />
        <path
          fill="#465962"
          opacity="1.000000"
          stroke="none"
          d="
M311.405334,512.960693
	C310.597961,512.607605 310.162994,512.258789 309.425690,511.888367
	C306.171448,508.149231 301.964081,506.467896 297.352173,504.924408
	C296.979675,505.010284 297.001770,504.999207 297.000000,505.011292
	C296.737213,504.664734 296.403564,504.594238 295.738678,504.900818
	C295.470398,505.028015 295.391968,504.964874 295.378418,504.958557
	C295.319244,504.284424 295.273621,503.616608 295.130219,502.459045
	C296.872131,499.994995 298.966339,500.795349 300.974426,501.520660
	C303.304657,502.362335 305.697968,502.955658 308.069733,503.643219
	C311.904144,504.754822 312.864197,503.176575 312.080933,499.351837
	C312.205170,498.783600 312.287445,498.564026 312.590790,498.072021
	C313.027435,497.711487 313.243011,497.623413 313.806702,497.532898
	C315.275879,497.784119 316.190460,498.531921 317.591248,498.989929
	C318.080444,499.263184 318.267029,499.386841 318.702759,499.731995
	C320.459778,502.247589 323.331940,502.664154 325.411865,504.704285
	C326.191528,505.512360 326.540314,506.303131 327.633301,506.764221
	C328.110992,506.985687 328.302704,507.079071 328.783600,507.325226
	C329.264130,507.591919 329.455414,507.705872 329.927185,508.015198
	C330.384857,508.361176 330.562103,508.511810 330.978394,508.922180
	C332.183350,511.123718 334.324158,511.526154 335.854034,512.727966
	C337.674316,514.157898 338.445923,516.080566 338.346375,518.727417
	C337.257172,521.229126 336.618073,522.463623 340.034271,523.704773
	C346.377228,526.009338 350.855560,531.416077 356.126587,535.705444
	C352.612946,538.121338 350.550751,534.431519 347.350403,534.053223
	C335.652405,526.332458 324.242798,518.765442 311.405334,512.960693
z"
        />
        <path
          fill="#759EB0"
          opacity="1.000000"
          stroke="none"
          d="
M295.852478,505.065186
	C295.742645,504.392090 296.128723,504.017761 296.926636,504.724518
	C296.743683,505.049744 296.489105,505.076050 295.852478,505.065186
z"
        />
        <path
          fill="#635B5F"
          opacity="1.000000"
          stroke="none"
          d="
M313.016541,497.948059
	C315.254608,506.627411 314.850311,506.904327 306.112274,504.931915
	C302.616547,504.142822 299.632568,501.663513 295.439117,501.981781
	C289.484619,498.872955 283.936798,495.776581 278.159546,492.438080
	C280.206482,488.401154 284.073822,489.648926 286.930023,490.207733
	C294.987946,491.784088 303.325348,492.361481 311.290253,495.919312
	C312.505280,496.160156 312.844666,496.789062 313.016541,497.948059
z"
        />
        <path
          fill="#3E6978"
          opacity="1.000000"
          stroke="none"
          d="
M315.695557,490.773834
	C320.348816,490.754272 322.421722,495.188416 326.622437,496.061401
	C326.768494,498.807922 323.540131,498.892731 322.297546,501.229675
	C320.605255,502.033905 319.522827,501.459900 318.251923,500.259460
	C317.965210,499.998077 318.009583,499.986023 318.011871,500.008911
	C317.012787,499.107605 315.633636,498.995697 314.224304,498.191406
	C313.964569,497.992706 314.007690,497.986176 314.006836,498.007965
	C313.604980,497.663544 313.309052,497.236023 313.231384,496.456329
	C313.240173,496.226257 313.371735,495.843872 313.318542,495.660126
	C313.265381,495.476379 312.959839,495.233246 312.956482,495.038025
	C312.961456,494.438446 312.969818,494.034058 312.965668,493.326599
	C312.876312,492.289795 313.043488,491.620087 313.757751,490.801636
	C314.495483,490.441376 314.956940,490.423889 315.695557,490.773834
z"
        />
        <path
          fill="#33616C"
          opacity="1.000000"
          stroke="none"
          d="
M315.974915,490.639679
	C315.496155,491.010834 315.022766,491.025848 314.195374,491.073059
	C308.271942,491.255096 304.527039,487.428558 300.118958,484.487671
	C300.619690,482.900482 301.017365,481.664856 300.167358,480.030701
	C305.603607,482.901215 311.806091,485.069702 315.974915,490.639679
z"
        />
        <path
          fill="#6E96A8"
          opacity="1.000000"
          stroke="none"
          d="
M299.687500,484.104950
	C304.403656,486.411713 308.778778,488.698486 313.497620,491.045258
	C313.728271,491.661163 313.615204,492.217041 313.227631,492.898193
	C307.831757,490.563049 302.593445,488.309448 298.064758,484.340942
	C298.437103,484.046509 298.891754,484.065735 299.687500,484.104950
z"
        />
        <path
          fill="#88A6B8"
          opacity="1.000000"
          stroke="none"
          d="
M314.515778,495.110352
	C314.368286,496.136566 314.282745,496.773926 314.101593,497.720520
	C313.841187,498.074921 313.676392,498.120087 313.268097,498.245636
	C312.604980,497.782593 312.185303,497.239227 311.701843,496.342224
	C311.936554,495.740417 312.235077,495.492218 312.743317,495.043396
	C312.953064,494.842804 313.372650,494.806091 313.581909,494.782166
	C314.053314,494.746002 314.315521,494.733795 314.515778,495.110352
z"
        />
        <path
          fill="#6E96A8"
          opacity="1.000000"
          stroke="none"
          d="
M298.016052,483.989441
	C298.029449,483.978851 297.618347,483.800842 297.618347,483.800842
	C297.618347,483.800842 297.889587,483.810822 297.936005,483.919067
	C297.982422,484.027283 298.002686,484.000061 298.016052,483.989441
z"
        />
        <path
          fill="#916E67"
          opacity="1.000000"
          stroke="none"
          d="
M357.291809,656.232788
	C360.485626,654.142334 364.080048,654.885742 367.626617,655.628296
	C373.793488,656.919556 380.018890,657.248230 386.209473,659.333740
	C392.871674,661.578125 400.632233,662.622192 407.701813,659.780396
	C416.257355,656.341248 425.299164,657.288635 434.518585,655.984680
	C435.225586,658.736267 433.796204,659.881042 431.213074,660.161072
	C422.830170,661.069702 414.409271,661.762512 406.090912,663.089600
	C399.105042,664.204102 392.248718,663.346130 385.321289,663.076416
	C381.554718,662.929810 378.206543,662.019897 374.850952,660.351196
	C369.456787,657.668579 363.494659,657.115173 357.291809,656.232788
z"
        />
        <path
          fill="#916E67"
          opacity="1.000000"
          stroke="none"
          d="
M345.288330,656.260132
	C347.243561,654.647705 349.743042,655.059875 352.595581,654.978149
	C353.558990,655.157593 354.101044,655.344360 354.830566,655.754395
	C354.467987,656.164429 353.917877,656.351196 353.182861,656.767761
	C350.529175,656.807495 348.007690,657.445984 345.288330,656.260132
z"
        />
        <path
          fill="#875E55"
          opacity="1.000000"
          stroke="none"
          d="
M516.737793,636.472656
	C517.815247,636.084412 518.955078,636.056396 520.516724,636.047241
	C522.711975,639.912598 521.597046,641.924988 517.274414,642.434753
	C509.892151,643.305298 502.375275,642.998474 495.116974,644.949097
	C491.923096,645.807495 488.723663,646.645203 485.260498,647.747437
	C479.280914,648.711304 473.397247,646.449036 467.418213,648.999390
	C465.643219,649.003601 464.238953,648.997375 462.414307,648.993164
	C461.432343,648.467651 460.870758,647.940247 460.137085,647.176147
	C460.612213,646.842712 461.259369,646.745850 462.232697,646.684204
	C462.937317,646.778503 463.274292,646.681519 463.799774,646.289429
	C477.414642,643.651184 490.812836,641.213562 504.189484,638.662964
	C507.173737,638.093933 510.105560,638.016907 513.097900,638.370667
	C514.561951,638.543823 516.556519,639.709778 516.737793,636.472656
z"
        />
        <path
          fill="#A5654C"
          opacity="1.000000"
          stroke="none"
          d="
M357.479492,652.469727
	C357.517120,652.450623 357.441864,652.488892 357.479492,652.469727
z"
        />
        <path
          fill="#B27252"
          opacity="1.000000"
          stroke="none"
          d="
M387.994293,651.462891
	C395.602020,652.912659 403.100616,651.579102 410.700897,650.764160
	C422.665802,649.481140 433.551270,644.273621 445.622467,642.019653
	C449.513000,643.622437 453.127655,643.165833 456.719299,642.549622
	C457.867981,642.352478 458.954254,641.773926 459.952087,643.360107
	C457.018616,644.424683 453.994904,645.508179 450.889679,645.848999
	C435.007294,647.592285 419.612488,651.981079 403.847626,654.345581
	C398.680237,655.120605 393.598541,655.337646 388.254669,653.437256
	C387.953217,652.760986 387.971039,652.288513 387.994293,651.462891
z"
        />
        <path
          fill="#B27252"
          opacity="1.000000"
          stroke="none"
          d="
M465.326660,642.875000
	C466.157074,639.964417 468.393585,641.067932 470.720154,641.631836
	C469.242218,642.211914 467.478394,642.569824 465.326660,642.875000
z"
        />
        <path
          fill="#B27252"
          opacity="1.000000"
          stroke="none"
          d="
M479.204224,640.577271
	C479.216736,639.614990 479.633667,638.965271 480.857361,639.799316
	C480.502716,640.216125 479.994415,640.432556 479.204224,640.577271
z"
        />
        <path
          fill="#A67A5E"
          opacity="1.000000"
          stroke="none"
          d="
M463.747131,646.126038
	C463.724243,646.335693 463.424255,646.541931 462.841553,646.733765
	C462.862610,646.520508 463.166351,646.321533 463.747131,646.126038
z"
        />
        <path
          fill="#182932"
          opacity="1.000000"
          stroke="none"
          d="
M494.421722,433.022583
	C494.920776,436.381470 494.964508,439.731110 494.976868,443.537262
	C490.971405,447.966522 486.037537,447.906830 481.049133,447.086884
	C477.580444,446.516754 474.190582,445.811737 470.463501,447.721588
	C468.881592,448.271698 467.731140,448.203308 466.305176,447.313904
	C464.643250,445.599548 462.893677,446.028107 461.275055,446.017273
	C455.454559,445.978363 449.623871,445.785156 443.814972,446.047546
	C431.676514,446.595886 419.634155,444.465820 407.501495,444.960663
	C404.587799,445.079529 400.036041,445.214783 399.942444,442.227081
	C399.851257,439.317780 404.450928,439.356415 407.333374,439.016174
	C420.482208,437.464050 433.663940,436.437408 446.902222,435.871155
	C462.602325,435.199646 478.279358,433.989838 494.421722,433.022583
z"
        />
        <path
          fill="#3B3C43"
          opacity="1.000000"
          stroke="none"
          d="
M465.787415,447.331268
	C467.063416,446.992645 468.137695,446.987000 469.614502,446.971344
	C473.857910,445.712769 477.675812,444.960144 481.389679,447.941833
	C481.295105,448.998505 480.968658,449.473328 480.061340,449.995544
	C477.565216,450.922333 475.392120,450.432098 472.867310,450.510681
	C471.483887,450.553528 470.550507,450.788361 469.458862,451.678436
	C468.914185,452.000793 468.685028,452.115906 468.085693,452.349670
	C463.836121,453.324463 461.162476,456.227295 458.009033,458.286926
	C454.554077,460.543427 450.900452,461.780090 446.780701,461.741852
	C438.330963,461.663422 429.838104,462.346436 421.506531,460.267426
	C418.576782,459.536285 415.941010,460.548248 412.906738,461.713867
	C409.904297,461.820892 407.456299,461.463196 405.859985,458.589996
	C406.113922,457.937805 406.408936,457.704315 406.859070,457.233582
	C408.574951,455.402435 410.157440,453.838257 412.670593,453.046967
	C413.445129,453.001221 413.889038,453.001221 414.665863,453.000519
	C422.419250,452.686005 429.829590,453.147797 437.284637,452.434692
	C443.098572,451.878571 448.776703,451.153992 454.690918,449.269440
	C458.531586,448.466888 462.342743,450.275055 465.787415,447.331268
z"
        />
        <path
          fill="#2A363C"
          opacity="1.000000"
          stroke="none"
          d="
M454.553284,449.013489
	C452.678040,452.523682 448.752045,453.399841 445.176239,453.788727
	C437.607117,454.611877 429.954193,454.774384 422.330231,454.915283
	C420.081299,454.956818 417.814240,454.017700 415.277130,453.263916
	C416.349945,449.086151 420.168427,449.370270 422.921387,449.257782
	C433.294464,448.833771 443.693115,449.036682 454.553284,449.013489
z"
        />
        <path
          fill="#2A363C"
          opacity="1.000000"
          stroke="none"
          d="
M412.764771,452.747742
	C411.700836,454.937195 410.400970,456.873840 407.368866,456.991089
	C406.744171,454.984497 405.116943,454.524933 403.562134,454.014832
	C402.234253,453.579193 401.025208,452.945526 401.295166,451.336578
	C401.550140,449.817078 402.991241,449.509827 404.240967,449.149323
	C407.867981,448.103149 409.563507,451.876923 412.764771,452.747742
z"
        />
        <path
          fill="#77878D"
          opacity="1.000000"
          stroke="none"
          d="
M405.468506,458.094971
	C407.626343,459.060120 409.433746,459.948944 411.559692,460.954773
	C412.575684,461.323303 413.096283,461.749603 413.209839,462.844482
	C412.634064,463.731964 411.995697,464.012573 410.951019,464.234863
	C410.373016,464.291992 410.140839,464.295776 409.565369,464.259644
	C409.000488,464.163940 408.778900,464.108093 408.241089,463.925415
	C406.925720,463.251404 406.083740,462.539825 405.456787,461.196594
	C403.991272,459.777069 402.608307,458.678070 401.102966,457.272003
	C402.525024,456.667358 403.769073,457.549133 405.468506,458.094971
z"
        />
        <path
          fill="#517581"
          opacity="1.000000"
          stroke="none"
          d="
M400.913208,456.646179
	C396.195251,456.572815 392.276123,454.035217 388.153442,451.267883
	C392.953918,451.074554 396.821564,453.832275 400.913208,456.646179
z"
        />
        <path
          fill="#43434B"
          opacity="1.000000"
          stroke="none"
          d="
M367.735474,456.317810
	C370.458191,457.293030 373.409546,456.861145 375.563477,456.359528
	C381.870178,454.890717 387.486694,456.234802 393.200287,458.483154
	C394.830505,459.124634 396.694336,459.172485 398.726746,459.757263
	C400.367432,464.430115 396.117401,464.447357 393.847717,466.459412
	C389.928558,468.304688 387.152954,470.612579 386.257599,474.789520
	C385.521759,478.222198 385.858093,480.515686 390.034485,481.191833
	C393.067902,481.682892 396.575684,482.438477 395.151367,487.351196
	C391.819397,488.672333 388.702026,489.217712 385.195923,488.053833
	C384.231140,487.365845 383.568054,486.833832 382.323914,486.872009
	C381.528412,486.850403 381.070984,486.771881 380.270660,486.520264
	C375.137848,484.327179 370.462402,486.507599 365.775970,486.928284
	C363.663239,487.117981 361.533905,487.427704 358.971802,487.546509
	C351.650543,489.173187 344.778137,491.188751 337.273132,488.665222
	C335.567841,485.549469 336.995728,484.004181 339.494873,483.037079
	C341.709259,482.180206 344.091095,481.909271 346.382507,482.190674
	C352.572784,482.950928 358.637115,482.037109 365.100403,481.136902
	C365.695587,480.592499 366.961487,481.318390 366.366333,479.783783
	C366.115601,475.034515 369.780914,474.502563 373.024200,473.071869
	C374.730530,472.317474 376.531067,473.273834 377.601685,471.487122
	C376.562866,469.599213 375.203430,468.590546 372.923187,468.462677
	C371.841827,468.451721 371.121002,468.376526 370.036163,468.154053
	C368.481140,467.537384 367.132172,467.380096 365.830048,466.196899
	C364.300751,462.415222 365.834167,459.511505 367.735474,456.317810
z"
        />
        <path
          fill="#484750"
          opacity="1.000000"
          stroke="none"
          d="
M367.579285,455.999695
	C368.694794,459.369385 366.511383,462.114227 365.780884,465.810486
	C364.770905,466.692169 363.952271,466.768158 362.858215,467.033630
	C362.137177,467.166565 361.719055,467.213745 360.978088,467.252747
	C358.592743,466.826538 356.873444,467.435333 355.180176,469.180115
	C352.833618,471.393372 350.242371,471.507751 347.579926,471.472717
	C344.815918,471.436371 341.987549,471.154053 339.291565,473.208191
	C338.848022,473.629608 338.667053,473.795563 338.205200,474.198486
	C333.308624,479.987396 332.179565,480.308014 326.218658,477.416565
	C322.568512,474.791565 318.705139,475.705566 315.026276,475.500610
	C312.912994,475.382904 310.661194,475.977051 308.970764,473.581604
	C308.690186,472.566315 308.620544,471.834717 308.232208,471.368774
	C307.239319,470.177490 304.794434,469.571899 305.747833,467.615082
	C306.604065,465.857727 308.931885,465.604614 310.676605,466.066132
	C318.392334,468.107117 325.177460,465.033630 332.078552,462.496490
	C333.372803,462.020691 334.732025,461.751404 335.978790,462.229218
	C337.820465,462.935089 339.523682,462.769165 341.295624,462.124756
	C344.298340,461.032715 348.880096,461.228973 349.475128,457.740448
	C350.121124,453.953247 344.626862,456.283325 343.076111,453.382721
	C351.266174,452.149353 359.026245,455.575928 367.579285,455.999695
z"
        />
        <path
          fill="#534A50"
          opacity="1.000000"
          stroke="none"
          d="
M392.993713,466.373566
	C393.661713,462.771362 398.347198,464.051056 398.951233,460.368561
	C400.844116,460.331665 402.685760,460.641357 404.950867,460.913574
	C406.519409,460.916229 407.305786,461.608124 408.269745,462.601501
	C408.360809,463.056152 408.243866,463.264618 407.847351,463.696625
	C402.407440,465.918152 401.898254,467.744659 406.195068,470.773621
	C411.637756,474.610321 416.371155,479.328918 421.937744,482.980743
	C425.881470,485.567902 429.842834,486.483337 434.107452,482.862244
	C434.778748,482.429291 435.196472,482.251648 435.962616,482.050690
	C437.405975,481.855225 438.443909,481.468170 439.811584,480.924133
	C443.020477,480.017059 445.651733,481.894684 448.885162,481.556580
	C452.154633,481.954865 455.018616,482.335297 456.368988,485.928711
	C454.892303,489.907257 451.493561,489.987701 448.454010,490.497803
	C445.336823,491.020874 442.159576,490.970306 438.614044,490.419495
	C437.180481,490.089966 436.312103,489.537567 435.246368,488.620026
	C434.708832,488.065155 434.441071,487.747192 434.181091,487.422455
	C434.165619,487.403107 434.151581,487.617889 434.176575,488.321045
	C433.273621,490.299927 431.800079,491.390717 430.136841,491.270447
	C421.968445,490.679810 413.313416,491.293427 407.677460,483.408447
	C407.101105,482.602112 406.564484,481.598572 405.559570,481.398346
	C397.518066,479.796295 396.241760,472.430725 392.993713,466.373566
z"
        />
        <path
          fill="#484750"
          opacity="1.000000"
          stroke="none"
          d="
M338.971863,453.693329
	C338.569916,453.979767 338.113586,453.986084 337.314850,453.981628
	C337.095978,453.350037 337.185333,452.606232 337.958923,452.577698
	C338.267731,452.566315 338.597351,453.117859 338.971863,453.693329
z"
        />
        <path
          fill="#1D3540"
          opacity="1.000000"
          stroke="none"
          d="
M512.504517,395.987366
	C512.914795,407.347473 512.948486,418.716217 512.963135,430.554810
	C507.866455,433.687531 502.746033,436.088348 496.509583,433.350922
	C496.131317,420.004059 496.079224,406.964874 496.078949,393.458557
	C501.609406,393.214233 506.275208,397.764832 512.504517,395.987366
z"
        />
        <path
          fill="#9FA2B2"
          opacity="1.000000"
          stroke="none"
          d="
M494.898193,457.242859
	C495.176117,457.216736 495.537506,457.202332 495.713135,457.159058
	C496.943970,456.902618 497.947083,456.948517 499.007690,458.027191
	C499.181763,461.169220 497.169098,462.470825 495.017578,463.406555
	C482.041138,469.050049 469.175140,475.064178 460.853821,475.850586
	C473.530579,474.441772 491.014618,468.723755 507.115662,458.935516
	C510.100250,457.121094 513.174194,455.453033 516.064880,453.476959
	C517.597473,452.429321 519.288574,451.693207 521.545654,452.599854
	C522.796082,455.904999 520.254517,456.882874 518.454407,458.107208
	C504.895996,467.328979 490.582825,474.946991 474.319733,478.230927
	C472.492706,478.599854 470.831299,479.280212 468.767029,480.126221
	C463.896973,480.318115 459.463043,480.898376 454.615601,481.609528
	C452.761871,481.993103 451.358307,481.565552 449.509644,481.498566
	C443.837463,480.403503 438.066559,481.073578 433.651245,476.465210
	C433.635040,475.068176 434.207184,474.445648 435.409729,473.818970
	C439.671295,472.707428 443.682739,473.581573 447.954224,472.633575
	C452.786011,471.758026 457.114227,470.514618 461.873077,469.492126
	C470.474365,466.875305 478.929932,464.988861 486.866852,461.496307
	C487.719391,461.121155 488.958405,461.204987 488.859467,459.405579
	C489.470428,458.277405 490.307983,458.028503 491.498444,457.825623
	C492.389648,457.737823 492.976868,457.717743 493.816589,457.503296
	C494.260101,457.362671 494.440216,457.315796 494.898193,457.242859
z"
        />
        <path
          fill="#182932"
          opacity="1.000000"
          stroke="none"
          d="
M496.125916,433.490112
	C501.723969,434.192352 506.693420,430.874969 512.523987,431.016174
	C512.970032,434.051208 512.996094,437.077759 513.011047,440.558868
	C508.147369,445.198669 502.497803,444.382355 496.521149,443.277740
	C496.149994,439.958344 496.109161,436.947662 496.125916,433.490112
z"
        />
        <path
          fill="#2D3037"
          opacity="1.000000"
          stroke="none"
          d="
M496.122925,443.382935
	C501.521149,442.356537 506.851501,441.744080 512.590942,441.072510
	C513.005920,442.770569 513.011841,444.527679 513.067078,446.722107
	C513.164307,447.414856 513.212280,447.670349 513.036438,448.245972
	C509.195251,450.317841 505.391052,451.241211 501.357727,451.205383
	C499.271942,451.186859 497.375000,450.869263 496.262939,448.311371
	C496.064575,446.583954 496.059814,445.190430 496.122925,443.382935
z"
        />
        <path
          fill="#374651"
          opacity="1.000000"
          stroke="none"
          d="
M496.041748,448.431610
	C499.001465,450.876282 502.515045,449.410309 505.810822,449.170197
	C507.940002,449.015076 510.036224,448.407227 512.549072,447.994476
	C514.388855,450.385071 512.366211,451.191071 510.932373,452.100525
	C508.160889,453.858337 505.072845,455.081696 502.122864,457.268860
	C501.400970,457.736572 500.943268,457.908875 499.731140,457.987915
	C498.113586,457.844543 497.226990,457.733307 496.114563,457.368958
	C495.930542,454.372437 495.972351,451.629059 496.041748,448.431610
z"
        />
        <path
          fill="#554441"
          opacity="1.000000"
          stroke="none"
          d="
M495.116486,456.793335
	C495.176117,457.216736 495.129120,457.608185 495.089111,457.801208
	C494.604523,458.419769 494.138824,458.815552 493.266602,459.142273
	C491.899170,459.144775 490.824799,459.056458 488.996368,458.906494
	C488.033936,458.735535 487.825531,458.626221 487.351379,458.287262
	C484.652161,454.845703 479.749176,454.167938 478.711029,449.424622
	C479.368683,449.092987 479.840240,449.051971 480.666016,448.987457
	C481.864227,448.222382 482.834808,447.855042 484.353943,448.041901
	C487.718079,450.062958 491.540314,450.139130 494.626465,452.714966
	C494.954315,454.105042 495.005585,455.237488 495.116486,456.793335
z"
        />
        <path
          fill="#1D3540"
          opacity="1.000000"
          stroke="none"
          d="
M485.248962,449.276978
	C483.918793,449.038879 482.864410,449.013367 481.415131,448.975891
	C478.324097,444.883087 474.221527,447.988434 470.469757,446.974548
	C472.480438,443.169830 476.434204,444.737793 479.270416,445.316803
	C484.525757,446.389771 489.304779,445.669586 494.561859,444.055298
	C494.954620,445.368866 494.963684,446.743927 494.941589,448.530640
	C491.835114,450.038361 488.681549,449.791992 485.248962,449.276978
z"
        />
        <path
          fill="#3B3C43"
          opacity="1.000000"
          stroke="none"
          d="
M485.067932,449.407227
	C488.063354,449.107666 491.086029,445.852753 494.573761,448.908569
	C494.929413,450.018402 494.948456,451.094543 494.935303,452.571655
	C491.240234,453.155945 488.188202,451.493103 485.067932,449.407227
z"
        />
        <path
          fill="#517581"
          opacity="1.000000"
          stroke="none"
          d="
M308.620026,473.188477
	C312.992218,475.003784 317.301178,473.528229 321.476959,474.023407
	C323.476990,474.260559 325.919983,473.442963 326.939636,476.614746
	C325.908478,478.719391 324.163086,478.997040 321.997528,479.133484
	C319.795837,479.589478 317.869446,476.393372 315.827332,479.496704
	C315.148224,480.005920 314.690002,480.191986 313.813232,480.387299
	C309.405273,481.559204 306.568787,478.708954 302.893616,477.232239
	C302.523102,477.033630 302.356384,477.135803 302.261566,477.150085
	C302.387268,476.646240 302.607819,476.128082 302.972839,475.364258
	C303.113129,474.734344 303.269836,474.427673 303.836426,474.147858
	C304.107025,474.107544 304.469604,474.096802 304.649231,474.071655
	C305.103394,474.270233 305.416412,474.360687 305.990051,474.190674
	C306.919800,473.775879 307.627441,473.487946 308.620026,473.188477
z"
        />
        <path
          fill="#484750"
          opacity="1.000000"
          stroke="none"
          d="
M303.993622,473.741821
	C303.964294,474.399292 303.765930,474.646332 303.314636,474.983612
	C301.016113,473.522888 297.839417,474.105042 296.077698,471.334839
	C298.687897,471.598907 301.650085,471.278503 303.993622,473.741821
z"
        />
        <path
          fill="#484750"
          opacity="1.000000"
          stroke="none"
          d="
M295.967041,470.714233
	C295.459381,470.814117 294.911346,470.635071 294.182831,470.223511
	C294.901001,469.025269 295.521210,469.265137 295.967041,470.714233
z"
        />
        <path
          fill="#484750"
          opacity="1.000000"
          stroke="none"
          d="
M305.866455,474.046082
	C305.845886,474.170227 305.479645,474.276611 304.971130,474.214752
	C305.059509,474.040436 305.290161,474.034393 305.866455,474.046082
z"
        />
        <path
          fill="#72443E"
          opacity="1.000000"
          stroke="none"
          d="
M673.760620,641.381226
	C677.703918,640.654175 681.167358,641.837219 684.702942,642.698120
	C688.513306,643.625854 691.393494,642.638550 693.209290,638.716492
	C695.228394,634.355225 697.386353,630.125183 698.061890,625.261292
	C698.562500,621.657349 701.384888,619.765503 704.963745,618.158936
	C707.812439,620.099487 710.320801,619.226013 713.105896,619.042847
	C715.066528,619.081665 716.403015,619.647583 717.436096,621.384155
	C717.556091,621.986938 717.552795,622.234253 717.416138,622.823608
	C715.436951,624.806396 713.095581,625.281555 710.816956,625.317322
	C706.498413,625.385132 706.715210,627.405212 708.234314,630.626831
	C706.257507,632.352417 707.503235,633.932678 707.988342,635.359863
	C709.308044,639.242310 710.154236,643.133179 708.985962,647.616821
	C707.169434,650.153259 704.901794,651.891479 702.087280,651.336426
	C693.832153,649.708191 685.174133,650.065247 677.340759,646.277588
	C675.289551,645.285706 673.357788,644.486755 673.760620,641.381226
z"
        />
        <path
          fill="#8B9698"
          opacity="1.000000"
          stroke="none"
          d="
M708.379395,630.986572
	C707.472229,630.269653 706.754700,629.593689 706.098877,628.862427
	C705.270691,627.938904 703.655029,627.322510 704.336121,625.695801
	C704.920410,624.300171 706.373962,624.261108 707.693665,624.088989
	C710.454834,623.728821 713.207397,623.302856 716.372437,622.895752
	C720.677734,620.389099 725.024414,621.897156 729.157471,621.528870
	C730.930969,621.370850 732.731445,621.518372 734.960571,621.539734
	C735.692871,621.560669 735.984192,621.573975 736.703857,621.640015
	C737.406921,621.761963 737.681580,621.831055 738.335938,622.076782
	C739.734924,623.536316 742.665161,623.684631 741.713684,626.011780
	C740.989380,627.783264 738.743530,627.998413 736.925110,627.754761
	C734.975281,627.493530 732.969055,627.703186 730.702026,627.020264
	C726.583618,626.005371 722.757385,626.555481 718.982788,626.608521
	C716.322266,626.645874 715.830872,628.109863 718.145874,630.147583
	C718.313416,630.312317 718.541931,630.573425 718.541931,630.573425
	C718.541931,630.573425 718.473755,630.466614 718.293579,630.253601
	C716.261414,626.640625 718.462769,626.470642 721.290405,626.525879
	C723.115540,626.919861 724.574036,626.475098 726.037170,626.526794
	C731.391785,626.716064 736.667175,627.047668 739.769043,632.937988
	C741.077148,636.097412 738.902283,636.845276 736.991943,637.799866
	C735.752136,638.419434 733.920898,637.715576 733.027649,639.785156
	C732.573120,640.443176 732.248474,640.763184 731.579346,641.205322
	C730.280518,641.687439 729.546875,640.960022 728.366699,640.822693
	C724.260498,639.025452 720.478577,637.131409 716.368652,635.134766
	C713.688354,634.604919 712.559387,632.345703 710.250061,631.301147
	C709.771118,631.278625 709.610229,631.332703 709.232300,631.253784
	C709.015259,631.120728 708.596375,631.002930 708.379395,630.986572
z"
        />
        <path
          fill="#87462F"
          opacity="1.000000"
          stroke="none"
          d="
M673.574768,641.007141
	C674.164001,642.838989 675.144897,644.336426 676.840210,644.679138
	C682.105347,645.743896 686.881531,649.050537 692.614807,648.054504
	C693.863770,647.837524 695.400208,647.883301 696.496399,648.427795
	C700.535339,650.434021 704.369202,650.000122 708.595947,648.028564
	C709.560120,651.437805 707.376221,654.682495 708.622009,658.271729
	C709.199829,659.936523 707.053101,659.880371 705.388306,659.989014
	C694.993103,656.133240 685.963562,649.959473 675.549561,647.186523
	C672.496887,646.373596 671.993469,642.179504 668.091980,642.967651
	C666.254761,641.952637 664.720825,640.820618 662.957336,639.458374
	C664.070618,638.432495 665.449402,637.802795 666.724854,639.272949
	C668.499634,641.318726 670.875061,640.964844 673.574768,641.007141
z"
        />
        <path
          fill="#72443E"
          opacity="1.000000"
          stroke="none"
          d="
M709.103027,631.241211
	C709.015259,631.120728 709.481689,631.104187 709.706787,631.164368
	C709.931946,631.224548 709.583130,631.472290 709.583130,631.472290
	C709.583130,631.472290 709.190735,631.361633 709.103027,631.241211
z"
        />
        <path
          fill="#2A1B1B"
          opacity="1.000000"
          stroke="none"
          d="
M694.759033,606.706421
	C691.724182,609.677429 687.961914,611.285278 683.418701,612.032776
	C674.576111,614.442566 666.120483,616.823242 657.676758,619.245178
	C652.485657,620.734192 647.182739,619.668091 641.218506,619.674072
	C640.855103,613.704407 645.291260,615.860657 648.685059,615.292969
	C651.545532,615.840759 653.663452,615.184326 655.820923,613.284668
	C659.870911,612.642761 663.406433,611.359863 667.506348,610.839966
	C674.467590,611.752136 679.853027,607.744751 685.902893,606.487488
	C688.851379,605.874756 691.603760,604.851013 694.759033,606.706421
z"
        />
        <path
          fill="#2A1B1B"
          opacity="1.000000"
          stroke="none"
          d="
M602.433838,628.003662
	C601.142151,626.907043 599.912109,627.019958 598.359253,626.999451
	C597.023438,625.475586 597.864807,624.151428 599.086792,623.817383
	C605.902100,621.954590 612.718140,619.691162 620.203003,622.060547
	C620.655823,623.029663 620.662415,623.626770 620.241089,624.610474
	C613.918518,623.900208 608.929443,629.012939 602.433838,628.003662
z"
        />
        <path
          fill="#2C1E22"
          opacity="1.000000"
          stroke="none"
          d="
M620.447021,624.997803
	C619.998352,624.549866 619.999207,624.104553 620.000244,623.325317
	C620.515503,622.241699 621.269043,621.869751 622.542358,621.612305
	C625.453918,621.949707 627.905029,620.357849 630.711243,621.718018
	C630.662354,624.939453 628.520386,625.025574 626.289673,625.001709
	C624.492188,624.982483 622.694275,624.999512 620.447021,624.997803
z"
        />
        <path
          fill="#2A1B1B"
          opacity="1.000000"
          stroke="none"
          d="
M631.345215,622.009766
	C628.614075,622.338562 626.213867,622.667297 623.401367,622.993347
	C621.898926,620.107971 624.236938,619.838440 625.952698,619.243408
	C627.938660,618.554626 629.997070,618.223328 632.453857,619.282837
	C633.286621,619.995911 633.477905,620.553101 633.209290,621.602722
	C632.558716,622.009521 632.117371,622.009644 631.345215,622.009766
z"
        />
        <path
          fill="#2C1E22"
          opacity="1.000000"
          stroke="none"
          d="
M633.260376,622.260498
	C633.001587,621.566040 633.003174,621.122742 633.006042,620.347046
	C633.412598,619.415405 634.021301,619.168762 635.028198,619.007202
	C636.228943,618.971985 637.098572,619.074829 638.298950,618.974121
	C639.305542,618.984985 639.941040,619.144287 640.748535,619.763794
	C640.992676,619.989868 641.001526,620.004211 641.005981,620.014160
	C639.221680,622.984497 636.453979,622.997131 633.260376,622.260498
z"
        />
        <path
          fill="#813920"
          opacity="1.000000"
          stroke="none"
          d="
M704.993347,615.371033
	C704.994507,614.242188 705.003662,613.489319 705.014771,612.359863
	C707.166931,607.487305 712.043762,607.160217 715.748535,605.103210
	C720.239502,602.609741 725.510559,602.240601 730.635315,600.240295
	C731.757507,599.734070 732.554443,599.566650 733.796753,599.539734
	C737.765137,599.907166 741.358948,598.755127 745.132507,600.145020
	C745.643982,603.424011 743.629211,604.182190 741.369141,604.406372
	C731.514160,605.383606 722.812500,609.827576 713.797241,613.364746
	C710.737244,614.565430 709.641296,618.770874 705.359192,618.224792
	C705.030090,617.997070 705.008423,617.997192 704.996887,617.999817
	C704.990662,617.250610 704.995972,616.498779 704.993347,615.371033
z"
        />
        <path
          fill="#46170C"
          opacity="1.000000"
          stroke="none"
          d="
M734.326538,600.716431
	C733.231506,600.977966 732.489990,600.991394 731.377625,601.011475
	C725.659302,602.404053 720.038818,602.727173 714.983521,605.291443
	C713.971375,605.804749 712.707336,605.308044 711.352173,604.352173
	C706.937744,605.055420 702.872009,606.031433 698.401917,607.002441
	C696.805969,604.882019 698.415283,604.197571 699.834412,603.444519
	C701.372314,602.628418 703.137512,602.253967 704.851440,600.839661
	C710.919006,598.286072 716.733337,596.650269 722.914795,595.849121
	C734.743896,594.315918 746.474915,592.124390 758.336426,590.666138
	C765.277405,589.812805 772.019043,588.508850 779.356323,590.113831
	C780.211304,590.729614 780.492126,591.230774 780.544067,592.279114
	C779.943970,594.220886 778.453613,594.739929 776.978088,595.888306
	C771.383972,597.376038 766.028381,596.956848 760.751404,597.439392
	C752.032349,598.236450 743.333435,599.137695 734.326538,600.716431
z"
        />
        <path
          fill="#241312"
          opacity="1.000000"
          stroke="none"
          d="
M704.757080,601.732178
	C703.668518,604.959229 699.692139,603.681641 698.072021,606.657959
	C697.252869,607.005981 696.508240,607.014465 695.391663,607.022949
	C689.010132,606.726440 683.485962,608.569824 678.098206,610.932251
	C675.022034,612.281128 671.772217,612.365234 668.252991,612.258606
	C667.432434,610.434143 668.640991,609.615723 669.770020,608.385376
	C670.344971,608.035034 670.592163,607.921997 671.238037,607.717102
	C680.618530,604.682312 689.604065,601.758728 699.114014,601.000366
	C700.929321,600.855591 702.757446,600.410034 704.757080,601.732178
z"
        />
        <path
          fill="#632B1D"
          opacity="1.000000"
          stroke="none"
          d="
M710.921143,604.419861
	C717.584229,604.389648 723.285461,599.271912 730.550293,601.002197
	C730.674133,601.559937 730.375732,602.549316 730.003723,602.577881
	C720.997437,603.268616 713.577209,608.271606 705.261963,611.736206
	C702.270447,611.303955 700.124084,613.698914 697.230347,613.232361
	C699.749512,610.236572 703.102600,608.398499 706.715149,607.092468
	C708.263733,606.532593 709.981628,606.471252 710.921143,604.419861
z"
        />
        <path
          fill="#F6D1A1"
          opacity="1.000000"
          stroke="none"
          d="
M668.461548,648.414673
	C666.431946,650.023560 664.595825,651.472290 662.572144,652.577698
	C660.199890,653.873535 657.737244,654.662598 657.032715,650.314514
	C656.902405,649.720886 656.800842,649.492920 656.879639,648.937378
	C659.562683,646.614136 663.588440,648.626038 665.900757,645.144409
	C666.569397,644.920044 666.945740,644.912292 667.601685,644.868713
	C668.227600,645.898315 668.944519,646.885376 668.461548,648.414673
z"
        />
        <path
          fill="#C46B41"
          opacity="1.000000"
          stroke="none"
          d="
M665.936035,644.825684
	C666.115051,651.368408 660.836914,647.931763 657.618408,649.241943
	C655.565002,647.188354 657.124634,646.320557 658.696655,645.707214
	C659.891846,645.240906 661.233215,645.148438 662.750244,644.358887
	C663.081055,644.138611 663.402161,643.898010 663.594604,643.825073
	C664.417664,644.075867 665.048340,644.399658 665.936035,644.825684
z"
        />
        <path
          fill="#964F38"
          opacity="1.000000"
          stroke="none"
          d="
M662.647339,644.087891
	C662.646118,647.626587 658.296570,645.903870 657.206787,648.903320
	C657.191711,649.266296 657.124329,649.615417 657.064209,649.782104
	C656.737976,650.032715 656.471863,650.116699 655.885864,650.340332
	C654.525635,650.183472 653.612183,650.339905 652.973694,651.287170
	C649.936584,655.792908 645.552551,655.948486 640.473511,655.035522
	C647.106567,650.608765 652.890869,643.757751 662.647339,644.087891
z"
        />
        <path
          fill="#F6D1A1"
          opacity="1.000000"
          stroke="none"
          d="
M655.546875,650.466248
	C655.565979,650.479919 655.494568,650.492554 655.467285,650.515808
	C655.440002,650.539062 655.495117,650.457458 655.508545,650.433289
	C655.521912,650.409119 655.527832,650.452576 655.546875,650.466248
z"
        />
        <path
          fill="#4E5963"
          opacity="1.000000"
          stroke="none"
          d="
M321.888428,501.688110
	C321.360321,497.803619 323.597504,496.894806 326.713287,496.303467
	C326.996002,496.056488 327.388580,495.924683 327.592224,495.887268
	C330.044983,493.968323 332.610168,493.274689 335.859009,493.921600
	C337.690887,494.841187 337.801056,495.986908 337.025604,497.325226
	C335.762634,499.504883 337.703430,499.253296 339.130402,499.725159
	C346.390442,499.838135 353.190399,498.879730 360.329834,497.540405
	C361.218262,497.505585 361.723694,497.537048 362.597534,497.697571
	C368.092560,498.900116 372.188721,502.058929 376.600220,504.610168
	C379.028503,506.014496 381.544769,507.287659 384.660156,508.081360
	C386.650055,509.749298 390.194611,510.746124 386.197571,513.935181
	C385.258057,514.534607 384.569244,514.772522 383.483154,514.906921
	C379.521729,513.926697 376.087463,514.831116 372.418945,516.088501
	C372.007324,516.421631 371.843964,516.554565 371.436462,516.881470
	C370.871155,517.341553 370.556488,517.615112 370.077057,518.165222
	C368.969604,520.940979 370.525269,522.066589 372.414520,523.077515
	C377.971985,526.051453 383.178375,529.595581 388.451874,533.029785
	C390.254517,534.203674 392.234985,535.215759 392.572418,538.009033
	C390.008820,539.325317 388.684906,537.102600 386.919434,536.185486
	C384.296509,534.822937 381.812927,533.195190 378.365265,534.899414
	C372.923126,538.327820 367.230927,537.813354 361.261414,537.252563
	C359.781647,535.580750 358.216644,536.091370 356.400299,536.034302
	C351.290253,533.667542 346.944702,530.752014 343.338715,526.832092
	C342.130066,525.518311 340.650909,525.136414 338.920105,524.911560
	C335.197021,524.427856 334.352051,522.604736 336.963959,519.299927
	C337.557190,518.475769 338.185547,518.123291 339.304932,518.014771
	C340.394867,518.067078 341.067596,518.341248 342.111023,518.566895
	C348.196899,521.137390 353.327057,524.805786 359.257935,527.321655
	C359.485474,527.252380 359.738007,526.916260 359.713379,526.699097
	C359.688721,526.481934 359.410370,526.073181 359.208008,525.891968
	C357.359955,524.620605 355.781891,523.422791 353.690063,522.434692
	C352.995544,522.134094 352.608398,521.950012 351.941772,521.624390
	C351.266632,521.348328 350.882141,521.188049 350.226532,520.868774
	C349.769989,520.620544 349.593201,520.510925 349.171143,520.210327
	C348.019287,519.142517 346.936523,518.570068 345.452942,518.047241
	C344.970215,517.801880 344.782288,517.690186 344.331970,517.378052
	C342.292328,515.468872 340.350464,514.019226 337.634094,513.161560
	C337.118286,512.921204 336.918945,512.809998 336.443970,512.496460
	C333.661346,510.019165 330.966919,508.011047 327.601959,506.372589
	C325.705444,504.553741 323.131012,504.147339 321.888428,501.688110
z"
        />
        <path
          fill="#B3D5DF"
          opacity="1.000000"
          stroke="none"
          d="
M392.707886,538.406128
	C389.032928,533.766663 383.630554,531.786804 379.122864,528.594910
	C376.577393,526.792480 373.724182,525.430542 371.062927,523.783997
	C368.803955,522.386353 367.198456,520.618530 368.721497,517.481812
	C369.158997,517.089417 369.312378,516.890442 369.828369,516.790039
	C377.077271,523.603516 385.783234,527.613525 393.869720,532.468079
	C425.704132,551.579285 457.567749,570.644165 489.698639,589.260010
	C497.786469,593.945923 505.520508,599.149048 514.926636,600.878906
	C515.752258,601.030762 516.415771,601.598999 517.152344,602.578369
	C517.344177,603.166748 517.354675,603.421509 517.185059,604.016052
	C516.193420,605.069092 515.204895,605.294861 513.794250,605.387634
	C504.636780,605.450867 497.559753,601.100464 490.565857,596.540344
	C489.319733,595.727905 488.259033,594.600098 486.362091,594.236267
	C486.010437,594.168274 485.613220,593.940613 485.387390,593.903076
	C477.592102,588.442566 469.446472,583.944336 461.431396,579.252625
	C444.927826,569.592102 428.455597,559.880920 412.031830,550.085083
	C405.911438,546.434570 400.223114,542.017761 393.360077,539.033569
	C393.194031,538.708862 392.896942,538.455261 392.707886,538.406128
z"
        />
        <path
          fill="#0C1119"
          opacity="1.000000"
          stroke="none"
          d="
M532.223083,629.728210
	C529.303894,629.992371 526.610168,629.984741 523.467896,629.966919
	C522.465576,629.451782 521.929565,628.928101 521.181641,628.219482
	C518.590088,627.682190 516.202942,627.337646 513.410400,626.981079
	C511.016144,624.621277 507.546478,622.687073 511.643555,619.055237
	C512.651978,618.161255 511.155273,616.296082 510.476074,615.016296
	C509.447144,613.077637 508.771393,611.096680 509.035217,608.471802
	C509.228027,607.867126 509.406433,607.665222 509.947723,607.282715
	C510.833923,606.955505 511.367065,606.892273 512.305542,606.970825
	C517.894775,607.575745 523.049133,608.909119 528.717773,607.743042
	C530.965759,607.564392 532.822449,607.394653 535.023865,607.911621
	C537.856201,611.780640 538.066956,615.604919 534.949585,618.091003
	C531.269043,621.026245 530.100586,623.914673 532.621765,628.028015
	C532.853699,628.406433 532.627808,628.990601 532.223083,629.728210
z"
        />
        <path
          fill="#4E5963"
          opacity="1.000000"
          stroke="none"
          d="
M393.213867,539.064697
	C397.587860,538.493408 400.491882,541.667358 403.835419,543.628601
	C429.802673,558.860352 455.677979,574.248901 481.563263,589.620056
	C483.057495,590.507385 485.142395,590.854858 485.276215,593.502014
	C481.304169,593.180359 478.277283,590.760925 475.047089,588.850586
	C452.171417,575.321838 429.293854,561.793945 406.565247,548.020935
	C402.058533,545.289978 397.112823,543.153137 393.213867,539.064697
z"
        />
        <path
          fill="#372F35"
          opacity="1.000000"
          stroke="none"
          d="
M360.613770,536.972290
	C366.334839,536.267334 371.728973,535.601074 377.559662,534.945557
	C382.748993,539.480225 379.873810,545.002747 379.980835,550.571533
	C378.896515,551.864685 379.017242,553.083923 378.995728,554.620483
	C376.843842,556.556885 375.830688,554.630188 374.276672,553.269043
	C370.284424,550.348022 370.536163,547.404968 373.304565,544.206787
	C374.336121,543.015137 376.392609,542.023743 375.559387,540.178162
	C374.618225,538.093506 372.323914,539.148132 370.623260,539.056152
	C369.344666,538.987061 368.038971,539.419128 366.381287,539.806580
	C364.155945,541.535522 362.471802,540.107971 360.381287,539.466797
	C359.770172,538.198730 357.816528,538.727966 358.131866,537.330383
	C358.189514,537.074768 359.535553,537.109985 360.613770,536.972290
z"
        />
        <path
          fill="#4E5963"
          opacity="1.000000"
          stroke="none"
          d="
M512.360107,607.943481
	C511.569977,608.046265 511.150269,607.991455 510.416412,607.890869
	C509.319946,607.166687 508.274811,607.026123 507.141998,606.378296
	C498.740845,604.621033 492.748810,599.091309 486.035156,594.501526
	C487.870178,591.939758 489.141876,594.068176 490.427795,594.886047
	C497.245941,599.222656 503.987366,603.691528 512.756714,604.123657
	C514.134216,605.539612 513.838379,606.699219 512.360107,607.943481
z"
        />
        <path
          fill="#2B3D45"
          opacity="1.000000"
          stroke="none"
          d="
M506.976074,606.518555
	C508.044525,606.051880 509.192352,605.928345 509.992981,607.526367
	C509.928711,607.891785 509.755188,607.938538 509.315613,608.027161
	C508.230316,607.899780 507.449280,607.670654 506.976074,606.518555
z"
        />
        <path
          fill="#3E474F"
          opacity="1.000000"
          stroke="none"
          d="
M374.045959,553.345886
	C375.442688,553.635498 376.879272,554.268555 378.655426,554.941040
	C392.497375,562.601135 405.794434,570.601440 419.271698,578.229858
	C438.526886,589.128662 456.831116,601.518372 475.905518,612.668884
	C477.422424,613.555664 478.562805,615.086609 479.949432,616.651733
	C477.867523,617.428650 476.498810,615.643066 474.334229,615.064331
	C473.228973,615.724976 472.364380,616.129700 470.951355,615.974854
	C469.641052,615.649536 468.957520,614.971069 467.935364,614.185303
	C467.300873,613.710205 466.958099,613.418945 466.376648,612.899536
	C465.014954,611.292480 463.534241,610.471313 461.429596,609.463379
	C460.989014,608.992676 460.995514,608.500000 460.997742,608.253662
	C457.926514,605.683899 454.286072,604.188477 451.226166,601.250000
	C443.694397,598.535217 438.080475,593.202209 431.472748,589.530396
	C420.293457,583.318237 409.503967,576.404785 398.547668,569.791077
	C392.195557,565.956665 385.857452,562.098206 379.463745,558.334290
	C378.357666,557.683167 377.005188,557.450562 375.396942,557.014771
	C374.713531,555.899719 374.399658,554.794495 374.045959,553.345886
z"
        />
        <path
          fill="#3E474F"
          opacity="1.000000"
          stroke="none"
          d="
M493.941467,624.651184
	C490.851013,624.015381 487.561523,623.244751 486.043793,619.387024
	C489.137024,620.030823 491.763733,621.788696 493.941467,624.651184
z"
        />
        <path
          fill="#3E474F"
          opacity="1.000000"
          stroke="none"
          d="
M483.843536,618.734192
	C482.084564,620.544678 481.621246,619.008118 481.037354,617.330872
	C482.136536,617.044983 483.061859,617.473450 483.843536,618.734192
z"
        />
        <path
          fill="#715F62"
          opacity="1.000000"
          stroke="none"
          d="
M427.682251,597.277832
	C430.111115,596.893494 431.817657,597.827087 433.490723,599.015198
	C442.874542,605.679199 451.774719,612.928894 459.703888,621.278442
	C463.246429,625.008728 467.581696,625.105713 472.559998,624.995544
	C475.101959,623.111755 477.241486,623.649109 479.224182,625.086914
	C481.453369,626.703552 483.837830,628.148499 485.820618,630.640503
	C486.099091,631.896423 486.402771,632.650269 487.698120,632.960144
	C487.195679,635.883057 484.927429,635.007507 483.093170,634.778748
	C473.701111,633.607300 464.393188,635.299805 455.047272,635.574524
	C452.914093,635.637329 450.825653,636.283997 448.384796,635.180481
	C445.331543,632.403503 442.642395,629.780273 439.654480,627.049072
	C438.508911,626.714783 438.237488,626.036316 438.023285,624.918823
	C438.009857,623.821777 438.211029,623.126099 438.375000,622.077271
	C438.474976,618.596313 435.072479,616.712036 435.593018,613.175476
	C437.909821,611.136414 439.618042,614.131348 441.706421,613.503418
	C440.338043,609.999207 438.947388,609.810852 434.888550,612.843140
	C434.271820,613.738892 433.681641,614.121948 432.625153,614.390625
	C430.882629,613.998840 429.603577,613.310730 427.937317,612.582275
	C427.450104,612.167358 427.277374,611.971802 426.914185,611.428345
	C424.684418,606.462769 425.643585,601.988892 427.682251,597.277832
z"
        />
        <path
          fill="#0B050D"
          opacity="1.000000"
          stroke="none"
          d="
M532.339966,630.045471
	C528.521851,624.661682 527.529480,620.066956 534.660583,616.542480
	C536.915405,615.427979 534.772766,611.899414 535.984009,609.346924
	C537.417480,606.414368 540.046143,606.300781 542.470825,605.916443
	C562.169128,602.793762 581.850098,599.561646 601.502686,596.169006
	C617.858337,593.345520 634.160706,590.213867 650.568604,587.690369
	C652.549622,587.385681 654.529053,587.203857 656.942078,587.565063
	C661.730713,590.411072 666.372192,589.567444 670.861511,588.288208
	C674.953674,587.122131 678.026428,588.305481 680.701843,591.273071
	C681.817078,592.510254 682.934875,593.741455 684.098999,594.935364
	C688.234497,599.176331 687.523315,602.511536 681.857300,604.526672
	C678.407471,605.753540 674.961243,606.958435 671.320679,608.736206
	C670.840576,608.991333 670.672119,608.994812 670.250977,609.004028
	C664.356812,612.157043 657.934143,612.428650 651.824646,613.643921
	C641.766785,615.644592 631.933533,618.715942 621.660889,619.731140
	C610.779175,620.806580 600.078064,622.963501 589.387085,625.312927
	C580.336914,627.301758 571.242981,629.486328 561.436279,629.492188
	C556.031128,629.907654 550.932678,628.506165 545.680542,630.669189
	C542.514160,631.842651 539.686462,631.649719 536.426697,631.037720
	C534.896606,630.675842 533.789490,630.383362 532.339966,630.045471
z"
        />
        <path
          fill="#1D1415"
          opacity="1.000000"
          stroke="none"
          d="
M560.649475,628.264526
	C573.378418,627.205505 585.384521,624.263855 597.459473,621.701782
	C601.494873,620.845520 605.982605,621.105225 609.617676,619.758972
	C618.754639,616.375122 628.617249,618.718872 637.747986,614.890686
	C643.692200,612.398499 650.578552,612.016357 657.099670,611.087952
	C661.135315,610.513428 664.821045,607.955383 669.557739,609.005737
	C669.986755,610.190857 669.172668,610.848022 668.178101,611.759888
	C664.332947,612.935974 660.799500,614.550598 656.446899,614.010071
	C654.581604,615.732544 652.387512,615.786133 650.250488,616.753296
	C647.070557,617.956055 643.153809,614.906128 641.018250,619.589844
	C640.245178,619.991272 639.497681,619.992676 638.376343,619.994629
	C637.557434,619.997070 637.112488,619.999084 636.333740,620.002991
	C635.251709,620.005554 634.503418,620.006165 633.381226,620.010742
	C629.552673,619.976624 626.180054,620.296021 623.088074,622.629883
	C622.242004,622.991394 621.494873,622.992065 620.374146,622.992065
	C617.024963,620.580933 613.902954,622.466797 611.114624,623.142212
	C606.806702,624.185608 601.837097,622.670227 598.085327,626.638000
	C592.090454,626.427246 586.371643,627.914673 580.842896,629.417419
	C576.070862,630.714355 571.333862,630.508301 566.605103,631.118591
	C558.248596,632.197021 549.954346,633.596375 541.483948,632.999878
	C539.700439,632.874207 537.762512,633.380249 536.207520,631.309082
	C538.523315,629.099854 541.420593,630.379272 544.555969,629.993652
	C548.004395,628.095825 551.343140,629.346069 554.516968,629.013977
	C556.439636,628.812622 558.424438,629.323547 560.649475,628.264526
z"
        />
        <path
          fill="#1D1415"
          opacity="1.000000"
          stroke="none"
          d="
M521.040222,628.335449
	C521.863586,628.104675 522.616821,628.376465 522.956482,629.649475
	C522.126526,629.890076 521.378296,629.612488 521.040222,628.335449
z"
        />
        <path
          fill="#5B555B"
          opacity="1.000000"
          stroke="none"
          d="
M486.397339,631.003845
	C483.190674,630.755005 481.344727,628.846436 479.413727,627.164551
	C477.771027,625.733826 476.092316,624.622620 473.396790,624.996033
	C473.272919,622.008789 470.777069,621.229004 468.934387,620.008850
	C464.012421,616.749756 459.022400,613.593384 454.101654,610.332520
	C453.617981,610.012024 453.425934,609.251343 453.047424,608.348755
	C455.387146,608.334229 457.777985,608.665955 460.578918,608.995239
	C460.989014,608.992676 461.002502,608.998779 461.007935,608.995728
	C462.008301,610.395325 463.824463,610.365845 465.325806,611.558594
	C467.049774,614.998108 469.894836,616.442261 472.958313,617.681885
	C474.613464,618.351624 475.998016,619.538635 477.739929,620.750732
	C482.765411,622.003723 486.298187,625.434082 490.776184,627.724854
	C491.577820,628.673096 491.732361,629.445190 491.237335,630.644958
	C489.607178,630.992065 488.211884,630.996338 486.397339,631.003845
z"
        />
        <path
          fill="#4B555C"
          opacity="1.000000"
          stroke="none"
          d="
M477.927063,620.659851
	C473.187897,618.993042 467.176331,618.905151 465.007263,612.382690
	C465.369141,611.604614 465.826080,611.455078 466.683105,611.784058
	C467.299286,612.133606 467.551147,612.328613 467.875916,612.794922
	C468.541199,613.524353 469.063873,614.066833 469.808258,614.786621
	C471.068726,614.993774 472.127502,615.003235 473.582581,615.016113
	C473.963593,617.749329 476.916290,618.307739 477.927063,620.659851
z"
        />
        <path
          fill="#5C4747"
          opacity="1.000000"
          stroke="none"
          d="
M491.439941,630.990967
	C491.000580,630.238098 490.998749,629.488342 490.997833,628.363220
	C493.395142,628.415894 496.467194,626.771179 497.940735,630.598999
	C495.962952,630.976990 493.920197,630.985535 491.439941,630.990967
z"
        />
        <path
          fill="#47A2AE"
          opacity="1.000000"
          stroke="none"
          d="
M864.992126,209.862274
	C865.215820,210.302719 865.157227,210.544281 865.244934,210.623138
	C870.371765,215.231995 870.389221,215.250885 864.444092,220.060120
	C863.904846,220.496338 863.519836,220.660477 863.592896,221.486465
	C864.143250,227.710754 860.866455,231.331558 855.046509,231.022156
	C850.482239,230.779510 849.476135,228.736511 852.048706,224.006042
	C852.516846,222.801544 853.022766,222.391037 853.518616,221.578140
	C853.747437,221.103882 853.839111,220.913574 854.072266,220.438736
	C854.533325,219.590622 854.758484,218.983948 855.157288,218.085205
	C856.784912,215.193161 859.177307,213.285690 860.520569,210.555084
	C861.396179,208.775101 863.067078,208.644989 864.992126,209.862274
z"
        />
        <path
          fill="#59B1BB"
          opacity="1.000000"
          stroke="none"
          d="
M865.408264,210.003540
	C864.035706,210.324814 862.141663,209.242844 861.859436,211.015656
	C861.296570,214.552094 857.995178,215.720276 856.145569,218.512299
	C855.627563,219.314758 855.505920,219.920425 855.172363,220.757599
	C854.973633,220.993668 855.003662,220.997116 854.993286,220.985703
	C854.151917,221.863907 853.281677,222.714722 852.192566,223.776779
	C851.972412,223.986771 852.000427,223.993835 851.991882,223.980286
	C850.811218,223.530548 849.824646,225.416656 848.801270,224.432755
	C847.804321,223.474380 848.596436,222.110809 849.034119,221.160248
	C851.679565,215.415390 856.934570,211.845551 860.986816,207.271072
	C861.789124,206.365341 863.144531,207.611450 864.579529,208.002823
	C865.151794,208.337723 865.377686,208.590302 865.843384,208.943207
	C866.030701,209.379181 865.873108,209.654678 865.408264,210.003540
z"
        />
        <path
          fill="#15191F"
          opacity="1.000000"
          stroke="none"
          d="
M575.391296,317.038513
	C575.022156,313.945648 575.003052,310.910522 574.979614,307.419189
	C575.373962,304.282410 577.617188,304.438354 579.431396,304.281128
	C582.322815,304.030579 585.229736,303.781067 588.589844,304.054413
	C589.616333,304.447235 590.052551,304.866119 590.411621,305.894653
	C590.463318,311.003448 590.595886,315.653412 590.094604,320.647278
	C587.092957,323.123810 584.809448,323.002380 582.171143,320.497131
	C580.477966,318.889435 577.914551,318.198273 575.391296,317.038513
z"
        />
        <path
          fill="#3A292B"
          opacity="1.000000"
          stroke="none"
          d="
M594.519897,402.841675
	C594.896423,406.011627 594.944092,409.116852 595.053406,412.683044
	C596.842957,414.942078 596.673157,417.043488 595.874268,419.620667
	C591.585266,422.445709 590.644470,422.175018 589.612183,418.065002
	C589.292603,416.792023 588.991516,415.559204 588.063660,414.048523
	C586.991455,407.772858 589.066467,402.082550 589.278931,396.134796
	C592.482666,397.423004 592.364258,400.741638 594.519897,402.841675
z"
        />
        <path
          fill="#594E52"
          opacity="1.000000"
          stroke="none"
          d="
M583.149719,330.462219
	C583.569092,329.984070 583.907715,329.966095 584.499634,329.956909
	C584.752808,346.785889 584.752808,363.606049 584.752808,380.426239
	C582.124084,377.132080 583.481995,373.390564 582.931580,369.424072
	C582.942871,356.282593 583.005920,343.602478 583.149719,330.462219
z"
        />
        <path
          fill="#705250"
          opacity="1.000000"
          stroke="none"
          d="
M579.542786,400.901306
	C579.978271,401.769958 580.007019,402.500092 580.055054,403.595032
	C581.968445,405.805359 581.332703,408.197174 581.516663,410.432159
	C581.847595,414.452667 580.669495,418.563110 582.460144,422.824493
	C582.949585,423.601685 583.150269,424.088348 583.341553,425.002563
	C583.645996,429.016632 582.455505,432.443787 581.822510,436.315613
	C578.997681,437.747772 579.430542,436.311310 579.897888,434.756165
	C578.494080,435.865204 579.964661,438.967346 576.751953,438.940857
	C574.706543,433.466919 573.642212,428.206665 575.573486,422.357056
	C576.772217,416.464020 577.527588,410.948853 577.540894,404.923981
	C577.580200,403.643860 577.651001,402.794434 578.206604,401.625244
	C578.669800,401.095703 578.902954,400.929230 579.542786,400.901306
z"
        />
        <path
          fill="#916E67"
          opacity="1.000000"
          stroke="none"
          d="
M582.264587,425.489197
	C582.045105,424.607513 582.009827,424.124695 581.929688,423.281616
	C580.888184,416.822296 581.169800,410.752716 582.581787,404.328552
	C582.912781,403.226746 582.966187,402.499176 583.062561,401.409241
	C583.449768,400.670410 583.878784,400.523468 584.660889,400.805542
	C584.999390,402.380035 585.058289,403.724548 585.122070,405.472961
	C586.843567,407.634796 586.355408,409.871643 586.520081,411.996185
	C586.963135,417.714203 585.465027,423.526733 587.676392,429.436676
	C588.592773,430.297272 589.083130,431.007721 589.132324,431.835449
	C589.291504,434.513641 589.369263,437.702423 586.016113,438.047516
	C582.734375,438.385254 582.546875,435.154846 582.182739,432.746796
	C581.837585,430.464661 582.854126,428.197144 582.264587,425.489197
z"
        />
        <path
          fill="#4C393B"
          opacity="1.000000"
          stroke="none"
          d="
M579.986084,400.568512
	C579.754395,401.030334 579.559204,401.020782 579.071533,400.989929
	C576.281555,400.409729 576.588623,398.304901 576.589172,396.602295
	C576.590637,392.154541 576.351562,387.726990 577.191284,383.260590
	C577.982117,379.054962 577.846863,374.673798 577.195862,370.410828
	C576.064270,363.000183 576.379028,355.596100 577.147461,347.753113
	C577.811157,346.804871 578.372192,346.559204 579.477600,346.820740
	C579.953552,354.040894 580.006531,361.031036 580.089844,368.483765
	C580.087646,379.329956 580.055176,389.713562 579.986084,400.568512
z"
        />
        <path
          fill="#5C4747"
          opacity="1.000000"
          stroke="none"
          d="
M587.263794,429.639648
	C584.924500,431.785339 585.097168,430.266571 585.092407,428.809296
	C585.068420,421.481964 585.008179,414.154694 585.043823,406.352142
	C585.786560,405.878998 586.446167,405.881104 587.434570,405.839020
	C589.559753,408.609039 587.617126,411.921265 589.117249,415.235168
	C589.671448,420.339203 589.817871,424.972717 587.263794,429.639648
z"
        />
        <path
          fill="#73676A"
          opacity="1.000000"
          stroke="none"
          d="
M588.400146,416.180756
	C588.014404,412.948608 587.971924,409.840332 587.846436,406.263428
	C587.852295,388.521851 587.941162,371.248901 588.057495,353.510895
	C588.223999,352.843079 588.362976,352.640320 588.710938,352.741333
	C589.398376,367.081665 588.441284,381.145447 589.719543,395.585266
	C588.467957,401.609894 589.454285,407.265411 589.396729,413.348572
	C589.611572,414.261658 589.679138,414.737701 589.496094,415.561401
	C589.145935,416.031342 588.944641,416.167999 588.400146,416.180756
z"
        />
        <path
          fill="#412C2E"
          opacity="1.000000"
          stroke="none"
          d="
M582.511108,403.969177
	C583.132202,410.026306 583.170349,416.086548 581.918213,422.502563
	C580.362183,424.649414 579.959534,424.043091 579.973755,422.080475
	C580.015076,416.354431 579.982788,410.627869 580.026855,404.430664
	C580.770569,403.967896 581.466736,403.975952 582.511108,403.969177
z"
        />
        <path
          fill="#534A50"
          opacity="1.000000"
          stroke="none"
          d="
M588.976685,352.575256
	C588.919983,353.045074 588.502075,353.029144 588.293518,353.037476
	C588.084961,345.487488 588.084961,337.929138 588.084961,330.370789
	C588.401123,330.366119 588.717224,330.361450 589.033386,330.356750
	C589.033386,337.606323 589.033386,344.855865 588.976685,352.575256
z"
        />
        <path
          fill="#625457"
          opacity="1.000000"
          stroke="none"
          d="
M589.871460,321.305084
	C589.850891,316.307556 589.918884,311.622253 590.028931,306.473083
	C590.196716,305.828461 590.322449,305.647736 590.648621,305.729370
	C590.898132,311.385132 590.947266,316.778534 591.014099,322.654236
	C590.674561,322.630157 590.317261,322.123749 589.871460,321.305084
z"
        />
        <path
          fill="#715F62"
          opacity="1.000000"
          stroke="none"
          d="
M585.014526,400.615479
	C584.532654,401.033691 584.124817,401.031921 583.411255,401.038513
	C583.105530,399.150909 583.105530,397.254974 583.105530,394.877625
	C585.959412,396.389984 584.811157,398.517639 585.014526,400.615479
z"
        />
        <path
          fill="#3A292B"
          opacity="1.000000"
          stroke="none"
          d="
M591.385193,390.998840
	C591.570312,391.269806 591.596313,391.646637 591.622314,392.023499
	C590.970032,391.941620 591.174072,391.490387 591.385193,390.998840
z"
        />
        <path
          fill="#32353F"
          opacity="1.000000"
          stroke="none"
          d="
M605.510071,305.068085
	C605.076721,304.920532 605.048828,304.725647 604.977905,304.238678
	C603.638306,302.648651 603.532776,300.984985 603.539917,299.302582
	C603.589966,287.485443 603.313904,275.666931 603.789429,263.417480
	C605.238525,260.185486 607.912659,260.576782 610.323242,260.260071
	C615.332886,259.601929 620.586670,259.817505 625.208435,256.167786
	C628.745178,255.665375 630.642090,254.080139 631.314880,250.825882
	C632.408569,245.535263 635.530884,241.501450 639.773621,238.295883
	C643.687317,235.338882 647.989746,235.755798 651.936340,239.781311
	C652.403137,241.023880 652.460815,241.905884 652.470337,243.254974
	C651.441772,262.829651 650.788635,281.963470 649.364502,301.467834
	C649.106689,301.863983 649.003479,302.019135 648.950256,302.095581
	C645.346558,304.327362 641.640686,305.178223 637.274536,303.234131
	C636.187500,302.971344 635.461182,302.987885 634.371948,303.012756
	C633.839111,303.005432 633.669189,302.989746 633.243286,302.968933
	C630.496216,303.237091 627.936218,302.515717 625.217529,303.689758
	C623.565857,303.916229 622.190002,303.936432 620.400269,303.953247
	C618.551025,302.302856 617.107117,303.697693 615.325134,304.001556
	C611.876648,303.582642 608.939148,304.710022 605.510071,305.068085
z"
        />
        <path
          fill="#2C1E22"
          opacity="1.000000"
          stroke="none"
          d="
M625.374756,303.927368
	C626.901306,300.675049 629.764954,302.164368 632.693848,302.714172
	C630.806091,304.824951 628.207214,303.671967 625.374756,303.927368
z"
        />
        <path
          fill="#313D44"
          opacity="1.000000"
          stroke="none"
          d="
M603.520203,303.352661
	C603.260742,303.956573 603.215210,304.149384 603.115234,304.633820
	C599.408997,304.974182 595.757141,305.022888 591.657959,305.092712
	C589.367920,303.671600 589.327820,301.574646 589.609802,299.630554
	C591.433960,287.056610 590.076355,274.416382 590.526367,261.817291
	C590.556030,260.986450 590.560974,260.154846 590.703613,258.917969
	C592.239685,255.358444 593.878235,256.879822 595.129944,258.540710
	C596.855591,260.830597 597.411926,263.570557 597.426758,266.443573
	C597.433838,267.813721 596.413208,269.306091 597.928040,270.772949
	C599.675293,269.562256 598.603210,267.770569 598.881836,266.356628
	C599.582764,262.800385 601.090393,261.974182 604.466187,263.871460
	C604.407654,277.168915 604.865051,290.070953 603.520203,303.352661
z"
        />
        <path
          fill="#64777D"
          opacity="1.000000"
          stroke="none"
          d="
M592.376465,256.694153
	C591.650818,272.727997 592.293945,288.482086 591.167847,304.657532
	C591.210571,305.113800 591.019836,305.548096 590.934448,305.769928
	C590.849060,305.991760 590.459534,305.991791 590.265259,306.000488
	C589.894775,305.725098 589.718445,305.441040 589.357727,304.987244
	C586.846436,302.819611 587.148926,300.064911 586.999878,297.416199
	C586.399414,286.740265 588.183533,276.155945 588.080139,265.479614
	C587.901062,246.985367 587.786133,228.485062 588.237305,209.511154
	C588.399841,208.726547 588.492371,208.419647 588.758423,207.664795
	C589.221375,206.644241 589.590393,206.128632 590.401001,205.366943
	C593.100952,203.741577 595.415283,203.375717 597.946716,205.609589
	C598.529541,206.806503 598.636047,207.865768 597.924500,208.283356
	C593.064636,211.135468 593.979309,215.842575 593.964722,220.233887
	C593.932739,229.867203 593.969910,239.500763 593.934021,249.134064
	C593.924805,251.606613 594.142212,254.146423 592.376465,256.694153
z"
        />
        <path
          fill="#1D1415"
          opacity="1.000000"
          stroke="none"
          d="
M597.006714,329.477356
	C598.418884,329.004974 599.793701,328.999054 601.581726,328.996643
	C602.039490,343.733093 602.084045,358.466003 602.219849,373.668091
	C603.731201,380.927856 602.780212,387.782471 602.722595,395.034546
	C600.011719,396.795288 599.968750,394.822968 599.945496,393.280945
	C599.911194,391.003967 600.085266,388.723083 599.868591,386.804413
	C599.583069,388.722229 601.002075,391.308411 598.261230,393.223450
	C596.869507,391.704407 597.130554,390.000183 597.125122,388.356201
	C597.060852,368.885437 597.017944,349.414612 597.006714,329.477356
z"
        />
        <path
          fill="#372624"
          opacity="1.000000"
          stroke="none"
          d="
M597.781311,393.590454
	C600.144775,390.886688 598.284180,387.743500 599.470825,384.836670
	C602.313904,388.134827 600.029724,392.121979 601.691040,395.681213
	C604.057312,400.366577 602.850830,405.115723 603.376343,409.674652
	C603.850037,413.784454 603.534668,417.995239 603.428833,422.633850
	C603.329651,423.417908 603.272766,423.729492 603.048096,424.486816
	C601.690491,426.467957 600.453247,427.912659 597.813904,427.095062
	C595.797913,422.648529 596.390076,418.407104 597.243530,413.786072
	C598.313538,409.343384 598.265259,405.248566 597.500854,401.217743
	C597.028748,398.728577 596.949463,396.361206 597.781311,393.590454
z"
        />
        <path
          fill="#9B8881"
          opacity="1.000000"
          stroke="none"
          d="
M597.216064,413.314453
	C597.697876,417.601837 597.891296,421.797272 598.098022,426.450043
	C600.451050,430.111450 599.513306,433.606140 598.740112,437.466492
	C596.613831,439.208710 595.475769,438.119385 594.213623,436.252502
	C593.004700,430.768921 592.016479,425.714569 594.714966,420.383759
	C595.055054,418.005890 595.090454,415.999542 595.120361,413.568604
	C595.719238,413.170197 596.323486,413.196350 597.216064,413.314453
z"
        />
        <path
          fill="#87655F"
          opacity="1.000000"
          stroke="none"
          d="
M602.276306,424.186707
	C601.943726,414.917633 602.007507,405.918427 602.012451,396.452332
	C602.044678,389.006561 602.135681,382.027679 602.268921,374.593018
	C602.671387,374.107147 603.031677,374.077057 603.663147,374.053070
	C604.031860,384.009247 604.129456,393.959290 604.271606,404.363831
	C606.684814,410.810791 606.063904,417.063965 605.654663,423.670349
	C604.554932,424.715546 603.644836,424.801178 602.276306,424.186707
z"
        />
        <path
          fill="#151117"
          opacity="1.000000"
          stroke="none"
          d="
M636.872070,303.369629
	C640.675049,303.264618 644.260254,301.417053 648.472290,302.109497
	C647.495483,306.738098 648.206787,311.446655 647.936829,316.553314
	C644.131592,318.573181 640.398804,318.661743 636.406250,316.323425
	C636.182678,311.932312 635.394592,307.789520 636.872070,303.369629
z"
        />
        <path
          fill="#310604"
          opacity="1.000000"
          stroke="none"
          d="
M636.058716,316.413483
	C639.733643,316.351593 643.394592,316.683746 647.485535,317.016022
	C647.948486,317.456421 647.981506,317.896698 648.025269,318.667938
	C644.240051,318.495178 640.626465,320.026581 636.447632,319.978943
	C636.029602,318.907867 636.037231,317.857666 636.058716,316.413483
z"
        />
        <path
          fill="#64777D"
          opacity="1.000000"
          stroke="none"
          d="
M603.573059,303.825409
	C603.476562,290.497253 603.646790,277.230774 603.747681,263.503601
	C602.531738,261.436584 602.271912,259.726440 603.336914,257.519104
	C603.866577,257.004303 604.134766,256.862213 604.835999,256.862183
	C606.379089,258.300720 606.498596,259.727051 605.607422,261.655151
	C605.247620,275.708649 605.106873,289.364166 604.950500,303.483093
	C604.569885,303.926727 604.204834,303.906891 603.573059,303.825409
z"
        />
        <path
          fill="#4E5963"
          opacity="1.000000"
          stroke="none"
          d="
M649.320923,301.904419
	C648.651123,282.551819 650.095642,263.299347 650.922607,243.587830
	C651.386169,242.675842 651.946655,242.493011 653.009277,242.825623
	C654.706543,244.669205 654.793640,246.519669 654.460449,248.916565
	C654.060242,259.462585 652.711426,269.490143 652.937988,279.601837
	C652.996643,282.218781 653.268738,284.936127 651.352112,287.664001
	C651.006592,292.019531 650.951965,296.009369 650.893799,300.451782
	C650.754150,301.197906 650.568237,301.455536 650.150757,301.833557
	C649.968872,301.989777 649.535156,301.944885 649.320923,301.904419
z"
        />
        <path
          fill="#0D1114"
          opacity="1.000000"
          stroke="none"
          d="
M776.674561,292.712585
	C777.242554,297.216217 773.779419,298.020233 771.106018,298.679749
	C762.106628,300.899872 753.147156,303.598785 743.678955,303.026398
	C741.012634,302.865234 738.354492,302.851776 736.751343,305.792450
	C735.755371,307.619476 733.241150,307.567017 732.349060,306.651031
	C728.095398,302.283539 723.040894,304.364960 718.684937,305.191803
	C705.629333,307.669952 692.514526,306.773376 679.413330,306.989227
	C677.571533,307.019592 675.431519,306.687439 674.148621,308.036713
	C670.240356,312.147339 666.040527,310.477905 661.395874,309.053467
	C661.009766,308.553986 661.003540,308.111420 661.005798,307.337036
	C661.521423,306.573547 661.677368,306.049835 661.217407,305.208313
	C660.892944,302.841492 661.035339,301.132416 663.980103,300.957611
	C679.200012,300.054138 694.414856,299.055145 709.624329,297.987946
	C727.801025,296.712585 745.970459,295.334564 764.570312,293.986511
	C768.517578,291.536804 772.294861,290.930176 776.674561,292.712585
z"
        />
        <path
          fill="#372F35"
          opacity="1.000000"
          stroke="none"
          d="
M739.656982,259.192108
	C743.917297,258.473022 745.955933,257.091034 745.487732,252.196365
	C745.136536,248.525177 745.630798,244.543228 746.354492,240.650940
	C747.363708,235.222900 750.077637,229.388260 745.435425,223.916962
	C744.074890,222.313522 745.750366,220.270187 747.343933,218.818542
	C751.844788,214.718430 757.470398,213.979614 762.789001,215.219559
	C769.972656,216.894318 776.542969,220.259872 778.993652,228.627838
	C779.255859,233.974319 777.714355,239.282944 782.372864,243.394226
	C783.280457,244.195206 782.825562,246.539932 782.998291,248.587219
	C781.551453,250.648239 779.740906,250.888474 777.365112,250.183319
	C775.021545,242.587448 770.567322,236.163651 768.207092,229.067917
	C766.944519,225.272385 765.994141,223.937744 762.470337,224.002319
	C757.979553,224.084595 754.151367,225.146149 751.826782,229.628387
	C749.016418,235.047165 748.252869,240.513031 749.854065,246.426880
	C750.560303,249.034943 751.115051,250.716034 753.929810,248.199890
	C755.384888,246.899185 757.090942,247.039719 758.339539,248.535446
	C759.489136,249.912689 759.120300,251.669647 757.814270,252.522400
	C753.118774,255.588303 751.426086,260.824921 748.319580,265.030518
	C742.983643,272.254303 735.977661,275.174622 726.681274,273.014465
	C725.798157,272.769012 725.532471,272.376923 725.538818,271.472473
	C727.359375,268.531311 730.889709,268.892792 733.139954,266.464691
	C736.360413,264.802460 738.499390,262.655304 739.656982,259.192108
z"
        />
        <path
          fill="#2F252B"
          opacity="1.000000"
          stroke="none"
          d="
M776.998535,250.456482
	C778.618347,249.044968 780.396912,248.915405 782.607239,249.002167
	C784.499023,255.579666 785.293762,262.260345 784.940125,268.982971
	C784.546387,276.468781 785.666992,283.977539 784.716553,291.710144
	C784.120361,293.105560 782.881775,292.941315 781.343384,292.990082
	C779.916138,293.002380 778.851868,293.007202 777.388062,293.031921
	C773.284241,293.367340 769.579834,293.682892 765.436523,293.985199
	C764.423096,291.868195 763.832092,290.007141 766.543091,288.570984
	C770.021973,286.728058 768.905090,282.715668 769.934631,279.706024
	C770.353333,278.482025 769.961487,276.691193 770.716553,275.916931
	C777.697449,268.757690 776.186707,259.557098 776.998535,250.456482
z"
        />
        <path
          fill="#313D44"
          opacity="1.000000"
          stroke="none"
          d="
M651.384766,288.091187
	C650.609985,275.240173 651.530212,262.505615 652.756836,249.396057
	C654.741028,247.640518 656.453918,248.046829 658.416504,249.688843
	C660.225159,254.767899 659.155945,259.628540 659.486938,264.829987
	C659.701904,274.219696 659.701904,274.219879 668.546692,274.219910
	C669.868164,274.219910 671.199585,274.112213 672.509216,274.227325
	C675.989868,274.533203 678.206055,273.153687 679.215942,269.336029
	C679.668091,268.827637 679.887329,268.655151 680.511902,268.352753
	C685.975769,270.028320 687.147583,273.210266 684.333496,278.720215
	C680.882935,278.198822 678.804077,278.633118 676.285034,281.488617
	C672.709290,285.542053 666.409363,285.154999 661.677002,287.748962
	C660.229736,288.542267 658.136292,288.231598 656.718262,289.558136
	C654.279907,291.839081 653.144226,289.456421 651.384766,288.091187
z"
        />
        <path
          fill="#433437"
          opacity="1.000000"
          stroke="none"
          d="
M684.327881,279.143494
	C685.002197,275.226685 684.963074,271.725037 681.181030,268.972809
	C678.032837,264.258514 679.777283,259.407379 680.393555,254.277283
	C684.796204,252.926086 683.696777,256.083801 684.001465,258.563721
	C684.004761,259.442780 684.005432,259.885468 684.005493,260.660217
	C686.238892,264.118256 686.155029,268.822784 690.749146,270.756165
	C694.100647,271.599060 697.146973,269.462860 700.497437,271.577026
	C702.385010,272.768127 704.168945,270.279449 704.981323,267.793213
	C706.453735,264.758240 708.997314,265.423309 711.736938,265.447693
	C714.984192,266.445923 717.807434,267.320557 720.929565,265.107300
	C724.343323,263.959229 727.432312,264.112488 730.952759,264.630341
	C732.218323,265.004150 732.939941,265.468048 733.745972,266.510284
	C732.854614,270.689514 729.243835,270.020386 726.174805,271.056702
	C720.466370,277.070129 714.563782,275.212067 708.263489,272.188171
	C706.783569,272.085419 705.242859,272.026367 705.216003,273.519806
	C705.130737,278.253052 701.893311,278.576202 698.678101,277.906738
	C693.774292,276.885742 689.505066,280.700073 684.327881,279.143494
z"
        />
        <path
          fill="#496A76"
          opacity="1.000000"
          stroke="none"
          d="
M756.741089,231.319366
	C763.415710,230.815369 765.053772,232.018158 765.016602,236.980576
	C764.983459,241.398666 761.451416,245.004898 757.296143,244.863220
	C753.414368,244.730881 750.816345,241.561890 751.094666,237.457901
	C751.324768,234.065460 754.129578,233.036575 756.741089,231.319366
z"
        />
        <path
          fill="#59646C"
          opacity="1.000000"
          stroke="none"
          d="
M707.960083,272.377502
	C708.997009,271.623566 710.286621,270.604279 711.155090,271.383270
	C715.947937,275.682465 720.479614,273.084106 725.427185,271.105530
	C725.896118,271.514526 726.013245,271.941345 726.202820,272.693481
	C720.461121,277.886230 711.726746,277.762634 707.960083,272.377502
z"
        />
        <path
          fill="#151117"
          opacity="1.000000"
          stroke="none"
          d="
M660.996277,305.331116
	C661.863953,305.127716 663.112427,304.732269 663.329956,305.909576
	C663.529968,306.992615 662.377380,306.828033 661.344788,306.994202
	C661.005432,306.558746 660.996582,306.112305 660.996277,305.331116
z"
        />
        <path
          fill="#5C4747"
          opacity="1.000000"
          stroke="none"
          d="
M579.328613,180.247314
	C578.601746,176.796509 579.926636,175.310089 583.235168,174.649597
	C586.776062,173.942688 590.178406,172.462952 593.572205,171.143021
	C594.683105,170.710983 596.518860,170.475525 596.281860,168.768509
	C596.114502,167.562988 594.604553,167.340500 593.509583,167.007324
	C588.765076,165.563675 583.949768,166.787704 579.330139,167.316483
	C567.686584,168.649216 559.993347,162.121109 560.873108,150.681229
	C561.119690,147.474289 558.515625,144.865509 559.283569,141.519745
	C560.331909,136.952560 561.495728,132.782440 567.310669,132.294800
	C569.010254,132.152252 570.971375,131.666626 571.928223,129.356445
	C580.355713,129.010361 588.706848,129.012329 597.526245,129.011719
	C603.228088,129.333206 607.305176,132.223343 610.991577,135.459961
	C614.254272,138.324570 617.502930,141.144379 621.390747,143.755508
	C622.412720,148.877792 621.889465,153.738510 621.939392,158.592316
	C622.375183,154.225128 621.366150,149.795654 622.729919,145.234009
	C630.277588,145.575790 636.118286,141.293259 641.514160,137.841492
	C646.503418,134.649902 644.101562,127.772118 643.511597,122.535492
	C643.097351,118.858429 641.293274,115.337944 640.062195,111.376328
	C644.054260,113.805939 644.646362,118.699226 646.960754,122.546249
	C648.043152,124.345520 648.198303,126.711601 650.660034,127.933945
	C652.297180,132.087250 652.941833,136.264786 653.025085,140.551926
	C653.077698,143.261276 653.155518,146.100037 657.206421,143.577652
	C658.757019,142.612137 660.317017,143.186768 660.651001,145.011169
	C661.006104,146.951233 659.306641,146.987244 657.977966,146.974655
	C652.842957,146.926071 647.836731,148.231735 642.655518,148.097412
	C636.133789,147.928345 630.024719,153.825729 630.150635,160.324097
	C630.217834,163.789032 626.745605,167.822678 631.605530,170.925064
	C632.982788,171.831375 633.110718,172.933044 632.781372,174.538605
	C632.188782,175.713425 631.516785,176.235825 630.384644,176.843582
	C628.104675,177.509354 626.298950,177.525360 624.632935,175.672485
	C624.216797,174.544662 624.371582,173.675217 623.992920,172.660400
	C622.643555,174.916733 621.150940,177.122818 618.377563,178.588867
	C617.090027,180.834000 617.185303,182.546753 618.866394,184.243454
	C620.570923,185.963715 621.391663,188.092834 620.686890,190.915176
	C617.479065,194.292297 614.112244,197.105865 612.281494,201.638702
	C607.805420,205.138947 603.588684,208.346771 597.454041,205.455994
	C596.473877,204.738083 596.164673,204.033508 596.244080,202.818329
	C597.959412,199.581757 601.918152,200.786392 603.953918,197.942017
	C604.684937,197.166977 605.248352,196.740326 606.056335,196.070343
	C609.397339,191.735275 610.131165,187.331070 607.720276,182.332825
	C607.302063,181.940704 607.133240,181.782684 606.728027,181.369080
	C599.266663,178.419373 591.837097,178.557297 584.369080,179.619156
	C582.785889,179.844284 581.351624,180.973938 579.328613,180.247314
z"
        />
        <path
          fill="#473235"
          opacity="1.000000"
          stroke="none"
          d="
M684.275085,258.684998
	C682.226074,257.874359 683.880554,255.182190 681.477112,254.096909
	C679.738037,253.116104 679.689819,251.704620 679.758484,249.865158
	C681.453247,246.818481 680.198059,243.954025 680.431641,241.202713
	C680.672241,238.369263 681.271545,235.625458 682.292603,232.582336
	C682.474060,231.731567 682.539612,231.240051 682.632263,230.383713
	C682.730896,229.787643 682.783752,229.553940 682.949890,228.985046
	C684.899048,223.246231 684.285828,222.308945 677.193848,220.105759
	C670.370789,218.441528 666.565918,214.301971 665.119873,207.841843
	C664.720093,206.055847 663.818787,204.424683 663.110168,202.380432
	C662.657715,201.268646 662.317444,200.482590 661.077271,200.073364
	C657.226135,198.595108 653.069702,199.904449 649.543335,197.132233
	C648.072571,191.281738 649.976501,188.735886 655.623779,188.639130
	C658.577209,188.588501 661.520386,188.867676 664.496338,188.791855
	C668.304565,188.694809 670.786377,187.431030 672.035156,183.358063
	C674.344971,181.736526 676.275146,181.023010 677.117920,184.639008
	C677.215027,188.276077 678.044189,190.954132 682.062683,191.085068
	C684.105835,191.151642 685.006531,192.533569 684.947937,194.435547
	C684.753967,200.727005 685.691589,207.056793 684.375244,213.308151
	C683.950195,215.326614 683.785095,217.077682 685.542175,218.767227
	C689.631775,222.699600 690.263062,227.558838 687.548645,232.518051
	C685.585693,236.104263 684.843262,239.860336 684.919678,243.980011
	C685.008423,248.767441 685.538818,253.599304 684.275085,258.684998
z"
        />
        <path
          fill="#784534"
          opacity="1.000000"
          stroke="none"
          d="
M693.191284,262.815186
	C688.390625,260.108154 688.387756,255.407822 689.210022,251.126450
	C690.416748,244.843155 691.874695,238.416290 694.555298,232.671036
	C696.552612,228.390213 700.173340,224.940750 706.154968,224.875473
	C715.239563,224.776352 723.876343,225.794479 732.142639,229.830872
	C735.305298,231.375168 737.114441,232.967712 737.095093,236.831894
	C737.065979,242.652557 736.543457,248.407806 735.992981,254.620239
	C735.919556,256.774933 734.713806,256.671844 733.148315,256.418457
	C730.038269,251.373154 731.197388,246.227524 731.784729,241.156693
	C731.898254,240.176895 732.333557,238.636444 734.003296,239.304108
	C734.608032,239.545898 734.477356,240.455063 734.480835,240.985107
	C734.798584,238.306473 733.012756,237.346298 730.729797,237.167313
	C722.581177,236.528503 714.416016,236.641327 706.265381,237.086975
	C703.385681,237.244431 702.027100,239.015686 701.787842,242.047699
	C701.410583,246.828125 701.322510,251.741150 698.400146,256.337952
	C697.620728,257.481354 696.790466,258.008148 695.717590,258.764648
	C693.784973,259.632629 694.117371,261.404419 693.191284,262.815186
z"
        />
        <path
          fill="#574649"
          opacity="1.000000"
          stroke="none"
          d="
M677.344177,185.069366
	C676.418701,183.445084 675.390259,182.610107 673.312073,182.995239
	C667.225159,178.099838 661.867737,179.768280 655.339294,182.418564
	C648.466370,185.208725 640.744263,186.414551 632.896851,187.168091
	C631.501099,184.066940 632.031494,181.458649 634.080078,178.790863
	C641.104919,177.044479 648.174927,178.665482 655.085571,175.881500
	C655.714355,175.672256 655.980591,175.636932 656.603455,175.788879
	C659.963379,175.566360 659.719116,172.197952 661.683655,170.801285
	C663.898987,169.226334 665.749390,167.020523 669.144775,167.014374
	C671.778076,166.578613 674.014221,165.447952 676.642700,166.887299
	C678.789917,170.285095 676.637207,172.043610 674.718872,173.924026
	C673.771423,174.852798 672.601685,175.970795 674.176514,177.216888
	C675.500610,178.264542 676.959717,178.327759 677.934143,176.597305
	C678.618164,175.382538 678.766174,173.388641 680.980591,175.070908
	C682.787781,176.443832 682.004456,177.417114 680.800903,178.604294
	C679.448486,179.938217 680.391418,180.981186 681.453613,181.921402
	C682.723816,183.045822 683.729370,182.578720 684.929932,181.614212
	C686.315308,180.501266 687.321411,177.209030 689.909790,180.655609
	C689.248901,182.191055 688.682861,183.454742 687.916504,185.002258
	C687.209778,186.308441 686.904358,187.415817 686.189819,188.709198
	C682.133545,190.022018 680.598267,186.103165 677.344177,185.069366
z"
        />
        <path
          fill="#9B8881"
          opacity="1.000000"
          stroke="none"
          d="
M693.782837,258.446747
	C694.582764,257.329376 695.409424,257.086609 696.659302,257.017395
	C699.783508,254.454300 703.325317,254.694427 706.504272,255.173462
	C714.473633,256.374390 722.429565,254.780487 730.796509,255.681168
	C736.781433,260.259644 737.157776,262.586426 732.424438,265.950439
	C728.707703,266.113434 725.424866,266.231781 721.703491,266.388550
	C718.850830,267.410278 716.307678,266.819153 713.414429,267.065765
	C710.623047,267.142212 708.247986,267.189453 705.438354,267.343475
	C702.632202,267.328491 700.260498,267.206787 697.443176,267.079681
	C694.052856,265.054749 693.265869,262.140198 693.782837,258.446747
z"
        />
        <path
          fill="#693E39"
          opacity="1.000000"
          stroke="none"
          d="
M686.309204,189.166092
	C685.518616,187.628021 684.121399,186.176987 686.698853,185.020233
	C688.301208,183.576614 689.820679,182.997482 692.044922,183.761414
	C694.386475,189.826126 695.267578,195.872177 695.477844,202.009354
	C695.673950,207.734924 697.258789,209.481140 702.864136,209.508286
	C708.481934,209.535492 714.139893,209.956024 720.321777,208.593811
	C720.993408,212.587936 719.037964,214.037399 715.630432,214.003479
	C711.477051,213.962128 707.322998,213.999680 703.169189,213.996841
	C693.548584,213.990265 690.487183,210.818863 689.901123,201.342987
	C689.647156,197.235672 690.360962,192.610275 686.309204,189.166092
z"
        />
        <path
          fill="#715F62"
          opacity="1.000000"
          stroke="none"
          d="
M732.178467,266.205200
	C735.997131,263.267303 734.273926,260.500519 731.975403,257.393738
	C731.992554,256.648743 732.207458,256.319946 732.828735,255.993530
	C733.937439,255.787704 734.749390,255.648529 735.779236,255.286469
	C736.712402,255.088989 737.427734,255.114426 738.492249,255.205063
	C739.167847,256.291046 739.494263,257.311798 739.871643,258.654175
	C740.569580,263.176514 739.709961,266.340576 734.311340,266.754578
	C733.429810,266.729828 732.898071,266.572601 732.178467,266.205200
z"
        />
        <path
          fill="#473235"
          opacity="1.000000"
          stroke="none"
          d="
M690.951294,270.634583
	C685.520264,270.498474 684.471497,269.124390 684.003357,261.404663
	C688.344543,262.569611 688.997681,266.883881 690.951294,270.634583
z"
        />
        <path
          fill="#623B37"
          opacity="1.000000"
          stroke="none"
          d="
M725.669189,196.775757
	C726.399719,200.300095 724.337952,203.075333 724.015991,206.616165
	C723.858215,208.380356 723.041504,208.979309 721.371033,209.001709
	C721.026855,209.014954 721.007751,209.003677 721.000244,209.000671
	C719.725952,204.399521 721.961914,198.003830 725.669189,196.775757
z"
        />
        <path
          fill="#715F62"
          opacity="1.000000"
          stroke="none"
          d="
M693.716553,258.233582
	C693.473877,261.345490 696.044556,263.562256 696.855591,266.737030
	C693.565491,268.404297 694.109192,265.140411 693.017944,263.352448
	C693.072998,261.458252 691.270020,259.704681 693.716553,258.233582
z"
        />
        <path
          fill="#5D3732"
          opacity="1.000000"
          stroke="none"
          d="
M792.001709,225.404541
	C793.229492,225.118164 794.290588,225.563431 794.731201,226.794373
	C797.616516,234.855011 803.020325,242.278259 801.015015,251.590271
	C800.845276,252.378433 800.990356,253.234406 800.985413,254.524719
	C797.317444,253.259888 797.465332,249.354080 797.334167,246.530258
	C797.035645,240.102020 795.217651,234.329880 792.455811,228.681839
	C792.047302,227.846466 792.141724,226.765182 792.001709,225.404541
z"
        />
        <path
          fill="#784534"
          opacity="1.000000"
          stroke="none"
          d="
M725.995728,196.747726
	C724.615479,197.148697 722.966675,197.782272 723.299438,199.147278
	C724.167480,202.707870 721.554199,205.182922 721.005615,208.626831
	C714.533447,211.794220 707.705750,210.858231 700.976929,211.022247
	C697.148193,211.115570 694.308533,208.932205 694.334839,205.694672
	C694.391968,198.643921 691.248962,192.043854 691.994324,184.548172
	C695.090942,179.988251 698.937195,177.526291 704.688599,178.755005
	C707.335754,178.973480 709.643127,178.988190 711.950562,178.992538
	C721.597839,179.010757 725.573486,182.874512 725.988464,192.653702
	C726.023376,193.476349 725.994934,194.301712 725.999146,195.561066
	C726.000488,196.163315 725.998474,196.330276 725.995728,196.747726
z"
        />
        <path
          fill="#625457"
          opacity="1.000000"
          stroke="none"
          d="
M704.999207,178.687637
	C700.487793,179.562714 696.418152,181.327667 692.340576,184.024658
	C690.574768,184.386658 689.161316,184.669540 687.384277,184.950470
	C686.981995,183.208847 687.224426,181.632553 689.604126,181.047195
	C691.043152,182.742737 691.785706,182.929901 692.994568,180.865585
	C697.048157,173.943848 697.685181,173.834137 704.560303,177.738037
	C704.903748,178.047836 704.936951,178.229797 704.999207,178.687637
z"
        />
        <path
          fill="#705250"
          opacity="1.000000"
          stroke="none"
          d="
M707.925110,161.666016
	C707.120667,161.994049 706.271912,162.092850 705.465576,161.950790
	C704.095398,161.709396 701.983948,162.656815 701.563110,160.818710
	C701.197083,159.220169 702.376160,157.688370 704.450439,156.904800
	C705.818787,158.315994 706.859314,159.822220 707.925110,161.666016
z"
        />
        <path
          fill="#705250"
          opacity="1.000000"
          stroke="none"
          d="
M704.855042,154.847412
	C703.077454,154.715820 702.482849,153.282776 701.938232,151.443695
	C704.143799,151.114838 704.993835,152.371796 704.855042,154.847412
z"
        />
        <path
          fill="#8B9698"
          opacity="1.000000"
          stroke="none"
          d="
M677.089844,166.919983
	C674.636047,167.271011 672.461365,168.978897 669.813171,167.273956
	C669.522827,166.819885 669.547241,166.623230 669.551331,166.129974
	C669.530945,165.833405 669.644409,165.371140 669.791260,165.183838
	C672.274475,163.881271 674.379761,162.925995 671.102661,160.199783
	C669.187317,158.606400 669.932617,156.735382 672.287231,156.239822
	C674.534058,155.766968 675.583374,154.932816 676.012878,152.481354
	C677.027405,146.689621 678.271973,146.276306 683.364807,149.378342
	C684.157043,149.860901 684.893799,149.887100 685.964294,149.323120
	C687.543640,149.119400 689.044983,148.562195 689.887695,150.594849
	C689.887390,152.796829 691.343872,153.269821 692.642456,153.936234
	C693.508789,154.380829 694.612061,155.101349 693.912781,156.134903
	C691.492188,159.712540 694.659363,159.090546 696.660461,159.772430
	C697.790955,160.542511 697.969604,161.415863 697.974548,162.708908
	C696.607544,164.418884 694.985107,165.457138 693.192017,166.797150
	C692.498901,167.002548 692.059570,167.012405 691.290833,167.027649
	C690.149780,166.997421 689.289612,166.892639 689.161377,168.396866
	C687.900696,169.202667 686.913879,169.941971 685.310303,169.414917
	C684.976013,168.675156 684.949280,168.132492 684.882690,167.184204
	C684.393799,164.889740 682.799316,165.206406 681.152039,164.985138
	C679.655640,165.513931 678.471375,166.081467 677.089844,166.919983
z"
        />
        <path
          fill="#5E6B74"
          opacity="1.000000"
          stroke="none"
          d="
M697.116211,159.659912
	C696.346008,160.211304 695.765320,160.593353 695.135986,160.707947
	C693.417725,161.020859 691.355591,161.917862 690.309021,159.798553
	C689.159729,157.471222 691.738525,156.934814 692.848877,155.870544
	C692.252930,153.760269 685.398865,156.792938 689.667847,151.127563
	C691.526550,151.301743 693.115723,151.607071 694.661255,152.060760
	C698.505005,153.189087 699.308716,155.485855 697.116211,159.659912
z"
        />
        <path
          fill="#485E6A"
          opacity="1.000000"
          stroke="none"
          d="
M684.783936,169.463196
	C686.123901,168.847504 687.245117,168.477188 688.718750,168.086517
	C690.040039,167.700577 690.290161,168.244980 690.088745,169.317902
	C688.605042,169.974503 688.067017,170.877411 687.831360,172.125061
	C687.437988,174.207031 686.011169,174.375992 684.502380,173.481873
	C682.525024,172.310074 682.681213,170.937286 684.783936,169.463196
z"
        />
        <path
          fill="#5E6B74"
          opacity="1.000000"
          stroke="none"
          d="
M690.098633,169.882126
	C689.706726,169.169693 689.563599,168.762177 689.245850,168.210419
	C689.195435,167.344986 689.319763,166.623795 689.444031,165.902618
	C689.811157,166.164551 690.178284,166.426483 690.753418,166.860703
	C691.728638,167.876144 692.452881,168.764435 693.276062,169.548950
	C694.005798,170.244461 695.898987,169.839523 695.443420,171.610535
	C695.144592,172.772079 694.178284,173.590302 692.946716,173.729919
	C690.954712,173.955734 691.180115,172.229721 690.777954,171.069931
	C690.671448,170.762833 690.492981,170.480667 690.098633,169.882126
z"
        />
        <path
          fill="#59646C"
          opacity="1.000000"
          stroke="none"
          d="
M693.270142,167.143188
	C692.714661,163.903732 694.441101,162.906906 697.588013,163.036957
	C698.124268,166.125412 697.963074,168.875687 693.270142,167.143188
z"
        />
        <path
          fill="#485E6A"
          opacity="1.000000"
          stroke="none"
          d="
M680.931824,165.308929
	C681.869019,163.789444 683.279968,163.071640 684.694702,163.300552
	C687.051575,163.681885 687.443665,164.856781 685.063049,166.553131
	C683.455078,166.805176 681.815613,167.700241 680.931824,165.308929
z"
        />
        <path
          fill="#574649"
          opacity="1.000000"
          stroke="none"
          d="
M669.705566,165.013397
	C669.938049,164.996552 669.817261,165.455017 669.674072,165.644211
	C669.368286,165.697464 669.236572,165.536240 669.071777,165.205017
	C669.007812,165.060287 669.473022,165.030243 669.705566,165.013397
z"
        />
        <path
          fill="#855144"
          opacity="1.000000"
          stroke="none"
          d="
M656.980042,175.587540
	C656.960144,175.976120 656.484985,175.988831 656.247375,175.993958
	C654.500488,177.173920 652.656860,177.487442 650.892151,177.431442
	C646.123108,177.280060 641.424866,177.852554 636.283752,178.288818
	C634.361633,177.784164 633.091980,177.064835 632.060181,175.331909
	C631.924194,173.874390 631.950806,172.831696 631.968506,171.398056
	C632.697571,166.467880 635.676147,162.480286 635.178955,157.599121
	C635.097168,156.795914 635.928101,155.796783 636.531433,155.043839
	C639.933472,150.797852 652.450623,149.079193 656.853577,152.334625
	C661.255066,155.589020 660.115967,160.407974 659.730896,164.744736
	C659.414612,168.307770 658.616699,171.899597 656.980042,175.587540
z"
        />
        <path
          fill="#87462F"
          opacity="1.000000"
          stroke="none"
          d="
M940.932007,565.681763
	C940.456055,565.987366 939.927307,565.969849 938.997437,565.921143
	C938.671143,565.487854 938.745972,565.085693 938.843750,564.378052
	C939.813110,564.069885 940.591492,564.330261 940.932007,565.681763
z"
        />
        <path
          fill="#3A292B"
          opacity="1.000000"
          stroke="none"
          d="
M672.716064,373.154846
	C676.436096,373.618561 679.872192,374.236206 683.713013,374.879852
	C684.117798,374.905853 684.159790,374.792633 684.218384,374.807129
	C685.187439,376.095703 685.456360,377.540009 685.459900,379.074188
	C685.490662,392.560852 685.520630,406.047516 685.442566,419.534149
	C685.434753,420.882843 685.119507,422.172028 684.689148,423.915039
	C684.421204,425.870270 684.529724,427.394928 684.061890,429.293335
	C680.485535,434.020142 676.510315,435.193634 671.352478,432.522980
	C668.177002,430.878754 664.282471,430.933044 660.916992,428.515320
	C660.181458,427.412201 660.130371,426.594757 660.721436,425.400452
	C660.957458,425.046509 660.990295,425.011200 661.007202,424.993378
	C666.725647,425.091431 672.433838,425.386871 677.852783,422.957275
	C678.834717,422.517029 679.642395,422.224548 679.634521,420.621765
	C679.052246,419.677216 678.794983,419.016663 678.797607,417.909790
	C680.692566,408.807220 679.316406,399.830566 680.860779,391.036469
	C681.793579,385.725067 677.629700,382.617096 674.691467,378.991699
	C673.449585,377.459259 669.404175,376.653778 672.716064,373.154846
z"
        />
        <path
          fill="#77878D"
          opacity="1.000000"
          stroke="none"
          d="
M678.535339,417.459045
	C679.094604,418.291443 679.332520,419.031403 679.580811,419.992828
	C677.833496,421.139587 675.668640,421.717896 675.033020,419.426575
	C674.570190,417.758087 676.590759,417.259552 678.535339,417.459045
z"
        />
        <path
          fill="#1C2024"
          opacity="1.000000"
          stroke="none"
          d="
M658.640015,366.951416
	C658.983459,380.345886 658.982727,393.697845 658.976318,407.517517
	C657.391113,409.846497 655.453918,410.822266 653.494324,408.866241
	C650.604797,405.981995 651.904968,402.839447 653.834106,399.789215
	C651.433289,399.341034 648.947388,400.975433 647.034363,398.253693
	C646.160156,389.813751 646.619751,381.730316 646.586426,373.652710
	C646.580872,372.315582 646.824951,371.011475 648.450073,370.046326
	C652.308228,369.760498 654.085266,364.640625 658.640015,366.951416
z"
        />
        <path
          fill="#554441"
          opacity="1.000000"
          stroke="none"
          d="
M648.923035,369.548218
	C647.056152,375.459991 648.398499,381.096832 648.065002,386.644623
	C647.837280,390.432404 648.034912,394.245819 648.066772,398.514648
	C649.429810,400.395630 649.849182,402.160645 649.794067,404.008881
	C649.611389,410.129028 650.198059,416.175812 651.174255,422.590363
	C652.980896,425.984711 655.715332,428.234222 658.873413,430.966125
	C660.178528,434.044189 660.433716,437.213989 664.842285,436.948792
	C665.873169,437.081451 666.489624,437.354858 667.204895,438.120270
	C669.598633,442.438416 674.019409,443.090607 677.859558,445.200256
	C678.758728,445.488708 679.377380,445.553741 680.275635,445.701660
	C681.335266,445.824554 682.080017,445.711395 683.098022,445.390717
	C683.795959,445.313477 684.183472,445.392395 684.777771,445.765381
	C686.112854,446.380310 687.222412,446.793335 688.682617,447.332062
	C688.774719,448.446228 688.516235,449.434723 688.130127,450.712219
	C687.632751,451.226013 687.232422,451.300354 686.500854,451.172913
	C685.396790,450.712799 684.565247,450.868103 683.406067,450.978394
	C681.261414,450.860748 679.492798,450.245239 677.297485,450.533508
	C676.018005,450.604523 675.108276,450.451019 673.888184,450.112305
	C672.188049,449.471649 671.347107,447.861389 669.274902,447.992004
	C664.023132,446.395203 659.414001,444.345978 655.988037,439.771729
	C654.097351,433.494843 650.449280,428.621429 646.764709,423.329529
	C643.881775,418.776337 645.519592,414.172394 645.442871,409.311401
	C645.637939,406.373413 644.398499,404.041779 644.332764,401.106567
	C644.061584,398.604706 644.169678,396.540955 644.615967,394.040405
	C645.328552,388.020782 644.891296,382.428589 645.041992,376.849396
	C645.108032,374.405426 644.823181,371.886993 646.631226,369.337280
	C646.960327,366.625427 647.001343,364.279602 647.063599,361.497528
	C647.472168,361.058624 647.859436,361.055939 648.534424,361.072968
	C648.855896,363.761810 648.889709,366.430939 648.923035,369.548218
z"
        />
        <path
          fill="#282429"
          opacity="1.000000"
          stroke="none"
          d="
M651.408569,422.939453
	C647.497925,418.262848 648.051147,412.740479 648.003235,407.338715
	C647.981262,404.865967 648.012390,402.392731 648.055298,399.450623
	C649.622925,399.097260 650.950012,398.484741 652.304626,397.880371
	C653.763489,397.229523 655.493103,396.169220 656.609741,398.135040
	C657.615112,399.905151 655.907715,400.529449 654.760681,401.476990
	C652.929688,402.989624 652.619629,405.006226 653.662537,407.224976
	C654.820862,409.689087 656.581909,408.560944 658.611816,408.040436
	C658.981812,409.798950 659.002075,411.612671 659.002319,413.426422
	C659.003479,420.922394 659.001221,420.922394 651.408569,422.939453
z"
        />
        <path
          fill="#554441"
          opacity="1.000000"
          stroke="none"
          d="
M660.599243,425.131775
	C660.968323,425.781952 660.979126,426.517395 660.998657,427.621399
	C661.763367,428.829834 662.127625,429.784912 661.712769,431.290558
	C660.914490,432.437469 660.234375,432.344666 659.302002,431.424469
	C659.072327,431.099915 658.987122,430.997406 658.941223,430.947174
	C659.417786,429.021118 658.078125,426.704132 660.599243,425.131775
z"
        />
        <path
          fill="#2E282E"
          opacity="1.000000"
          stroke="none"
          d="
M645.865723,393.745270
	C646.092346,396.071503 646.086365,398.071838 646.063843,400.500610
	C644.714539,403.451233 646.032715,405.853333 646.573792,408.588715
	C647.953247,413.274017 645.072449,418.015625 647.964050,422.627045
	C648.520752,424.466736 648.021851,425.712311 646.824158,427.086792
	C646.132812,427.550690 645.683838,427.694427 644.852417,427.719910
	C644.277893,427.605499 644.057434,427.521149 643.558350,427.220825
	C642.347290,425.964294 641.837280,424.659668 640.911072,423.212524
	C639.924072,421.340881 638.972656,419.835358 638.181702,418.058014
	C637.418274,419.514832 636.830139,421.306030 634.440613,419.785583
	C633.622742,418.216095 633.635986,416.963837 634.598206,415.422546
	C634.916565,411.668732 634.985352,408.228912 635.093628,404.311340
	C636.989929,397.614441 635.524597,391.238464 636.027466,384.488220
	C638.948547,382.335205 640.187683,384.309387 640.389282,386.555481
	C640.709839,390.127808 642.428711,392.243408 645.865723,393.745270
z"
        />
        <path
          fill="#1B1E22"
          opacity="1.000000"
          stroke="none"
          d="
M646.332397,393.726685
	C641.001953,394.666595 638.089294,393.037201 638.958740,387.281464
	C639.189514,385.754303 638.907471,384.221924 636.428589,384.038635
	C637.708740,371.561218 636.088257,358.901978 638.647644,345.188904
	C639.050598,351.488831 640.224365,356.423065 640.081177,361.582733
	C639.971985,365.516235 641.250977,368.889374 646.537842,368.979248
	C646.920654,377.109955 647.366943,385.255066 646.332397,393.726685
z"
        />
        <path
          fill="#554441"
          opacity="1.000000"
          stroke="none"
          d="
M628.391113,417.092590
	C631.056213,414.725708 629.586731,411.784393 630.033325,409.150024
	C630.481812,406.504364 629.028015,403.600586 631.474609,401.007263
	C632.132141,401.220032 632.498901,401.559875 632.457397,402.117340
	C631.867981,410.024902 633.766724,417.815796 633.645142,426.108185
	C636.056519,435.220062 640.711060,442.795471 645.551025,450.663513
	C646.380249,452.560303 647.232056,454.035156 648.674622,455.483215
	C649.063416,455.809662 649.218567,455.939117 649.593689,456.272369
	C649.953857,456.616119 650.093994,456.756042 650.434631,457.111267
	C650.762329,457.473999 650.889404,457.621399 651.196411,457.995850
	C651.489441,458.377808 651.602539,458.532684 651.877747,458.922668
	C652.251465,459.472107 652.463867,459.785797 652.854187,460.321747
	C653.269470,460.842896 653.500916,461.146362 653.894958,461.690430
	C654.480591,462.593140 654.710938,463.320953 654.795959,464.375641
	C654.885437,465.185089 655.075989,465.666779 655.650757,466.266937
	C655.952881,466.628479 656.059692,466.786560 656.244080,467.219238
	C656.279846,467.856476 656.168213,468.191711 656.210083,468.520538
	C656.672607,468.631592 656.859619,468.701050 657.308899,468.940796
	C659.656921,470.852875 662.074097,472.407837 661.072388,476.014343
	C657.733948,477.342041 656.518066,474.598511 654.598999,473.238007
	C652.020752,471.410126 649.704102,469.315430 647.486572,466.571716
	C647.212524,466.009338 647.126465,465.772766 646.971680,465.164734
	C647.404114,462.626556 645.747681,461.577942 644.633972,460.162201
	C635.373962,448.390869 628.463562,435.574371 627.151672,420.301758
	C627.047119,419.084747 627.025696,417.992126 628.391113,417.092590
z"
        />
        <path
          fill="#73676A"
          opacity="1.000000"
          stroke="none"
          d="
M628.771790,416.673981
	C627.947510,425.388031 630.841125,432.867798 634.163757,440.206543
	C637.583740,447.760315 641.372375,455.112793 647.416443,461.010254
	C648.426147,461.995483 649.187500,463.034637 647.496460,464.944702
	C646.621338,465.355988 646.429199,465.287628 645.974426,465.064484
	C645.390808,464.668335 645.083008,464.411163 644.573975,463.935822
	C644.216675,463.601074 644.076660,463.465271 643.532593,462.913513
	C642.736267,462.078033 642.370544,461.634979 641.794861,460.995117
	C641.484070,460.632355 641.364563,460.483002 641.078308,460.100311
	C640.604492,459.382233 640.330322,458.876251 639.796997,458.189911
	C639.503235,457.798676 639.390503,457.638214 639.117615,457.231018
	C638.759949,456.653656 638.569946,456.318695 638.220215,455.755371
	C637.936096,455.389557 637.819885,455.247467 637.537964,454.891907
	C637.265320,454.537262 637.158386,454.396088 636.899292,454.043274
	C636.653503,453.688995 636.559875,453.546356 636.338623,453.186340
	C636.052551,452.681152 635.902710,452.388947 635.645874,451.883728
	C635.530212,451.675171 635.302734,451.260803 635.189026,451.055267
	C635.075317,450.849731 634.844910,450.442688 634.725586,450.241272
	C634.606323,450.039886 634.354248,449.642761 634.219910,449.446442
	C634.085571,449.250122 633.806885,448.856232 633.666931,448.653931
	C633.436218,448.311157 633.345520,448.170715 633.125732,447.811157
	C632.831543,447.288757 632.674683,446.981262 632.408997,446.427490
	C632.216675,446.020081 632.142456,445.854187 631.952637,445.442047
	C631.567566,444.673584 631.166626,444.282135 630.381592,443.795013
	C627.632507,439.303284 626.120544,434.695526 625.420837,429.374664
	C623.673340,421.760437 624.791565,414.455719 624.524841,407.206604
	C624.385010,403.407196 624.498718,399.598419 624.501465,395.319824
	C624.995850,388.135895 623.471802,381.338593 625.751099,374.380676
	C626.128052,374.071716 626.541992,374.047913 626.748840,374.039215
	C627.031372,380.990601 627.107117,387.950684 627.192139,395.368011
	C628.148010,397.176178 628.015259,398.701477 627.850952,400.596802
	C627.817261,401.426453 627.844482,401.881317 627.859009,402.677979
	C628.299438,406.168457 627.610107,409.371979 628.448242,412.845093
	C628.655518,414.215118 628.716492,415.249176 628.771790,416.673981
z"
        />
        <path
          fill="#715F62"
          opacity="1.000000"
          stroke="none"
          d="
M633.437256,428.222260
	C630.939636,425.196747 632.121460,421.986938 632.022766,418.976288
	C631.835266,413.255615 631.975281,407.524261 631.906738,401.317505
	C631.854980,399.493439 631.875610,398.149200 631.909424,396.399048
	C634.188660,400.053619 632.426086,404.465790 633.035706,408.668060
	C633.333008,410.717407 632.174866,413.035004 634.490662,414.946960
	C634.891968,416.461731 634.936096,417.814972 634.994751,419.575745
	C636.924927,422.100189 637.006287,424.485992 635.789185,427.421082
	C635.064941,428.273193 634.501587,428.482910 633.437256,428.222260
z"
        />
        <path
          fill="#554441"
          opacity="1.000000"
          stroke="none"
          d="
M628.722107,412.732910
	C625.751221,410.315460 627.319153,406.883850 627.608765,403.333282
	C629.917908,405.920013 628.642151,409.182739 628.722107,412.732910
z"
        />
        <path
          fill="#554441"
          opacity="1.000000"
          stroke="none"
          d="
M629.071960,329.546448
	C629.073730,329.094666 629.476501,329.075958 629.674316,329.110260
	C629.872192,332.948395 629.872192,336.752228 629.872192,340.556030
	C629.604858,340.558899 629.337524,340.561737 629.070190,340.564606
	C629.070190,337.042480 629.070190,333.520355 629.071960,329.546448
z"
        />
        <path
          fill="#554441"
          opacity="1.000000"
          stroke="none"
          d="
M628.077148,400.758118
	C626.615234,399.674316 627.038757,398.102722 627.120850,396.214417
	C629.519531,396.922729 629.163696,398.679016 628.077148,400.758118
z"
        />
        <path
          fill="#554441"
          opacity="1.000000"
          stroke="none"
          d="
M628.915039,349.438324
	C628.888062,350.341980 628.854919,350.960022 628.816040,351.684479
	C627.597656,350.868561 628.362305,349.799561 628.201965,348.910980
	C628.177734,348.776733 628.242920,348.523834 628.332214,348.487640
	C629.125793,348.166199 628.722046,348.919739 628.915039,349.438324
z"
        />
        <path
          fill="#543937"
          opacity="1.000000"
          stroke="none"
          d="
M604.770874,424.376099
	C605.292053,417.908875 603.327393,411.899933 604.227051,405.292236
	C606.902588,403.994598 605.907593,401.787689 605.927612,400.168121
	C606.046326,390.525848 605.975342,380.881409 606.069397,371.238647
	C606.086060,369.526825 605.312012,367.512726 607.636414,366.086456
	C608.047119,371.980377 608.073242,377.995056 608.116882,384.473694
	C609.991211,386.854462 609.353821,389.280212 609.507507,391.555237
	C609.951538,398.128998 608.469177,404.796051 610.760620,411.624390
	C613.683716,416.698364 611.240234,421.663574 611.389648,426.497192
	C611.525208,430.882050 611.700989,435.353485 611.504395,440.220947
	C610.966675,442.378113 610.468384,444.076172 608.421021,445.165344
	C606.013367,443.591370 605.807068,441.276978 605.502930,438.594055
	C605.288696,434.215118 606.100891,430.198639 604.788818,425.905273
	C604.620850,425.265411 604.601685,424.998199 604.770874,424.376099
z"
        />
        <path
          fill="#543937"
          opacity="1.000000"
          stroke="none"
          d="
M607.578003,355.158966
	C607.939941,356.763306 607.957031,358.446991 607.974792,360.552490
	C604.822510,359.438263 605.682190,357.382996 607.578003,355.158966
z"
        />
        <path
          fill="#554441"
          opacity="1.000000"
          stroke="none"
          d="
M626.524719,429.449371
	C627.864807,434.522949 629.279663,439.124054 631.406006,443.845093
	C632.775024,443.962494 632.354919,444.946533 632.775269,445.744507
	C632.969666,446.027924 632.995789,445.994141 632.976135,446.003418
	C633.430359,446.419495 633.481384,447.012451 633.803955,447.794678
	C633.970703,448.030060 634.000000,447.998779 633.981018,448.008972
	C634.211487,448.219269 634.445007,448.436523 634.817505,448.853485
	C634.972412,449.036011 635.000000,448.999878 634.979187,449.009094
	C635.433167,449.422333 635.481262,450.014160 635.801941,450.793976
	C635.967346,451.029114 636.000732,451.001343 635.980103,451.007690
	C636.431213,451.420868 636.481384,452.012451 636.802490,452.793030
	C636.968811,453.027924 637.000000,453.001221 636.982300,453.011047
	C637.212402,453.219940 637.444336,453.436005 637.813782,453.850983
	C637.967102,454.032898 637.994629,453.996918 637.976379,454.012451
	C638.710083,454.740784 638.857178,455.832245 639.755859,456.805634
	C639.956726,457.032410 640.000000,457.000000 639.979370,457.016479
	C640.713257,457.742920 640.857361,458.835907 641.758423,459.806427
	C641.959778,460.032593 642.000000,460.000000 641.979248,460.015076
	C642.295471,460.365875 642.514465,460.763855 642.603271,461.582764
	C641.253723,464.432770 643.099731,467.394714 641.190430,470.079712
	C638.973938,470.550446 637.884888,469.305756 636.882812,467.974518
	C633.240906,463.136322 629.372864,458.416626 628.363403,451.839050
	C630.999817,449.783569 631.245422,452.283417 632.311340,453.807190
	C632.743958,454.264099 632.691772,454.533203 632.620911,454.806732
	C632.929504,454.678070 632.895325,454.489594 632.383972,453.959839
	C629.334351,449.870483 627.593201,445.532776 626.626038,440.889130
	C626.419495,443.301117 629.638611,445.596191 626.306702,448.051239
	C621.071899,442.869110 620.640320,436.139160 619.630127,429.697540
	C619.147095,426.617004 619.447388,423.400543 619.613647,419.795227
	C621.425171,412.612610 620.091309,405.752228 620.523926,398.965912
	C620.701721,396.176239 619.947876,393.284576 621.763367,390.329803
	C622.127197,390.031647 622.526978,390.022369 622.726868,390.018921
	C624.392029,391.013397 624.497375,392.531128 624.488525,394.541992
	C624.754272,404.249268 623.973755,413.525818 624.986328,422.773712
	C625.221680,424.923065 625.664124,427.015289 626.524719,429.449371
z"
        />
        <path
          fill="#1F0F0F"
          opacity="1.000000"
          stroke="none"
          d="
M623.365479,395.205627
	C623.031921,393.587067 623.009766,392.212311 622.957153,390.426514
	C622.939270,371.322540 622.951782,352.629639 622.979614,333.468262
	C623.730042,333.000580 624.465027,333.001373 625.566101,333.004700
	C625.952271,346.381500 625.972412,359.755829 626.060303,373.600922
	C626.060425,380.719788 625.992859,387.367859 625.878296,394.478577
	C625.224976,395.584869 624.493591,395.697998 623.365479,395.205627
z"
        />
        <path
          fill="#2A1B1B"
          opacity="1.000000"
          stroke="none"
          d="
M622.090881,389.566925
	C621.604065,399.854584 622.734558,409.716522 621.140991,419.834503
	C620.094238,419.490234 619.725647,418.640289 619.581299,417.192871
	C619.643616,406.806244 619.341309,396.895660 619.000122,386.518219
	C619.133789,383.244812 618.686218,380.414581 619.643860,377.261108
	C622.873291,377.523254 621.864380,379.911072 621.974487,381.699615
	C622.125916,384.159454 622.039246,386.633972 622.090881,389.566925
z"
        />
        <path
          fill="#1E0504"
          opacity="1.000000"
          stroke="none"
          d="
M619.621094,369.437531
	C619.856079,355.738342 619.856079,342.425751 619.856079,329.113129
	C621.666199,343.913452 622.019775,358.704315 620.292236,373.777283
	C618.749512,372.788666 618.966370,371.320374 619.621094,369.437531
z"
        />
        <path
          fill="#594E52"
          opacity="1.000000"
          stroke="none"
          d="
M619.581299,369.044434
	C619.903381,370.440552 619.950684,371.830170 619.983887,373.639343
	C619.955811,374.767761 619.941833,375.476593 619.931091,376.538269
	C619.941711,379.652893 619.949097,382.414734 619.913818,385.632355
	C619.341309,386.685394 619.644531,387.729126 618.476196,388.062042
	C618.166748,381.993042 618.151489,376.003784 618.174683,369.557312
	C618.577515,369.079315 618.942017,369.058624 619.581299,369.044434
z"
        />
        <path
          fill="#847876"
          opacity="1.000000"
          stroke="none"
          d="
M620.353821,416.866089
	C620.674805,417.893799 620.752808,418.641724 620.840576,419.768799
	C620.363892,429.774719 622.051453,438.958557 627.031677,447.861969
	C627.800049,448.247559 627.979248,448.362610 628.351807,448.732788
	C628.697815,449.391418 628.765198,449.807831 628.605713,450.531799
	C631.571533,457.731842 635.245483,464.278961 640.620728,470.157104
	C641.110168,470.432922 641.329407,470.488770 641.839294,470.722412
	C643.090210,471.846832 643.938965,472.903320 643.394592,474.327118
	C642.915894,475.578979 641.880066,475.228760 640.644531,474.707947
	C640.174561,474.410156 639.996582,474.277832 639.575195,473.925171
	C639.183105,473.548492 639.034424,473.392181 638.681152,472.993713
	C638.351135,472.587463 638.225708,472.423340 637.928528,472.009216
	C637.388977,471.057312 636.873840,470.502441 635.890747,470.031738
	C635.275085,469.746094 634.930969,469.563751 634.350464,469.197327
	C633.956360,468.899475 633.808105,468.767944 633.463440,468.413818
	C633.029480,467.877899 632.800537,467.558441 632.403564,466.999268
	C632.107849,466.610687 631.989014,466.455566 631.690308,466.068787
	C631.014465,465.230255 630.613647,464.546234 629.830383,463.785339
	C629.518555,463.393097 629.404663,463.227661 629.145996,462.798462
	C628.831360,462.175385 628.678040,461.809204 628.409546,461.174255
	C628.191528,460.738495 628.105713,460.564301 627.890503,460.131134
	C627.608215,459.520508 627.429871,459.182312 627.049072,458.630798
	C626.750549,458.254883 626.628479,458.105988 626.329590,457.727692
	C626.039917,457.341248 625.927002,457.184143 625.657959,456.783813
	C625.409912,456.372864 625.317993,456.205139 625.107422,455.778748
	C624.851135,455.177673 624.724670,454.831268 624.514404,454.236755
	C624.352539,453.837646 624.288940,453.681396 624.117920,453.306824
	C623.820984,452.811646 623.593323,452.571411 623.094360,452.235992
	C622.139465,451.696442 621.262390,452.415344 620.296997,451.528290
	C616.357361,443.123566 614.000305,434.647095 613.522522,425.277893
	C614.432312,420.163086 611.787537,415.385956 614.119263,410.395416
	C614.672363,409.955048 614.930664,409.828918 615.592896,409.854004
	C616.372314,414.656769 616.747864,419.308411 617.122681,424.396240
	C618.448975,427.104126 618.009705,429.718292 618.700378,432.461731
	C619.891235,433.951263 619.300476,435.355255 619.470032,436.190857
	C619.487000,435.451843 619.729858,434.229004 618.872925,432.800354
	C618.456238,430.308319 617.702148,428.150543 618.250854,425.537994
	C618.252686,422.767975 618.147278,420.357544 618.071045,417.496582
	C618.664673,416.430603 619.348022,416.334656 620.353821,416.866089
z"
        />
        <path
          fill="#6D5A5B"
          opacity="1.000000"
          stroke="none"
          d="
M620.717834,416.677429
	C619.973816,417.103912 619.350891,417.061951 618.414001,417.033020
	C618.058044,413.976135 618.015991,410.906189 618.041870,407.380676
	C618.212280,406.785767 618.314697,406.646423 618.269531,406.293762
	C618.108398,400.360382 618.094971,394.640289 618.131714,388.451233
	C618.609741,387.453705 619.037598,386.925110 619.668274,386.242340
	C620.396240,396.121918 621.338074,406.142303 620.717834,416.677429
z"
        />
        <path
          fill="#79675E"
          opacity="1.000000"
          stroke="none"
          d="
M616.060425,409.539398
	C615.817139,410.000366 615.637573,409.995544 615.188599,409.980743
	C614.599609,409.598663 614.094788,409.310486 614.052979,408.793518
	C613.377075,400.438568 612.772461,392.082428 614.643921,383.365234
	C614.994263,379.640106 615.033691,376.301727 615.111267,372.512482
	C615.149475,372.061615 615.479248,372.059631 615.643066,372.069275
	C615.912537,384.410522 616.018311,396.742065 616.060425,409.539398
z"
        />
        <path
          fill="#6D5A5B"
          opacity="1.000000"
          stroke="none"
          d="
M618.051270,425.084106
	C619.625000,427.372040 618.804199,429.782806 618.972900,432.578064
	C617.276428,430.847626 616.931335,428.307800 617.071411,425.246063
	C617.329590,424.884918 617.537048,424.937347 618.051270,425.084106
z"
        />
        <path
          fill="#847876"
          opacity="1.000000"
          stroke="none"
          d="
M618.099792,406.291382
	C618.461609,406.245575 618.541504,406.474152 618.235718,406.845642
	C618.109802,406.925110 618.077820,406.502258 618.099792,406.291382
z"
        />
        <path
          fill="#79675E"
          opacity="1.000000"
          stroke="none"
          d="
M610.078613,441.421661
	C608.830444,431.837494 610.169495,422.726044 610.379517,413.293213
	C609.847595,412.607208 609.329956,412.263977 609.356934,411.770203
	C609.830200,403.095825 608.676086,394.360596 610.615234,385.352844
	C610.969360,382.959259 611.028503,380.943787 611.122314,378.499207
	C611.157043,378.070068 611.545105,378.049530 611.738953,378.043823
	C611.975708,379.401276 612.018555,380.764435 612.096985,382.535767
	C612.862976,383.856537 613.521545,384.820190 613.455383,386.050232
	C612.986328,394.759369 614.324951,403.445099 613.705261,412.167999
	C613.438843,415.917572 614.170471,419.695526 614.124390,423.918152
	C614.584900,425.483734 614.472656,426.649445 614.470703,428.210388
	C614.024719,432.085968 614.682068,435.448669 615.184326,438.866791
	C615.492126,440.962158 616.539062,443.694946 613.123230,444.958160
	C612.222229,445.291382 613.653015,447.354889 612.240540,448.675110
	C607.685120,447.494720 610.754761,444.209106 610.078613,441.421661
z"
        />
        <path
          fill="#2C1E22"
          opacity="1.000000"
          stroke="none"
          d="
M610.562256,384.980316
	C611.255493,394.040070 611.026306,403.089020 610.143494,412.555450
	C607.442871,412.561401 608.055420,410.414520 608.045532,408.711548
	C608.001221,401.102783 608.034607,393.493622 608.087158,385.411072
	C608.827637,384.953705 609.520996,384.969818 610.562256,384.980316
z"
        />
        <path
          fill="#3A292B"
          opacity="1.000000"
          stroke="none"
          d="
M614.322449,425.230286
	C613.196289,424.238617 613.112000,423.232910 613.122314,422.234192
	C613.253906,409.434753 611.433655,396.693481 612.058228,383.413025
	C612.837891,382.964874 613.543152,382.985870 614.601685,382.992676
	C614.933289,391.665497 614.911743,400.352509 614.904724,409.505127
	C613.741150,414.560852 614.998779,419.206543 614.619385,424.244446
	C614.637024,424.938690 614.638672,425.202972 614.322449,425.230286
z"
        />
        <path
          fill="#1D1415"
          opacity="1.000000"
          stroke="none"
          d="
M427.246552,643.653931
	C426.533600,643.940979 426.094604,643.984741 425.325195,644.045532
	C423.650330,643.785156 422.412354,644.913269 420.808197,644.117249
	C419.924286,639.383179 421.953949,636.329346 425.914795,633.898682
	C429.155853,630.163757 433.627625,630.540405 437.909668,629.433960
	C438.602509,630.407593 438.909454,631.302551 439.236572,632.560181
	C439.271759,637.337585 436.154663,639.279907 432.749664,640.736145
	C430.927338,641.515381 429.025818,642.043213 427.246552,643.653931
z"
        />
        <path
          fill="#3A292B"
          opacity="1.000000"
          stroke="none"
          d="
M438.262939,629.412964
	C435.214050,632.566895 430.469696,631.580688 427.049744,634.429932
	C420.911469,636.271301 415.120972,639.278320 408.290161,637.270508
	C408.346954,633.970764 410.015625,632.704163 412.997498,632.982056
	C414.989441,633.167786 416.905396,632.850708 418.774780,631.235168
	C420.157715,628.815735 422.373199,628.614868 424.718109,627.871704
	C426.612335,627.449280 428.139374,627.439453 430.031738,627.706787
	C432.677094,629.384399 435.313568,627.896851 437.918396,628.861023
	C438.133728,629.126953 438.230316,629.313232 438.262939,629.412964
z"
        />
        <path
          fill="#3A292B"
          opacity="1.000000"
          stroke="none"
          d="
M427.340149,643.894043
	C427.199768,641.460876 429.134918,640.705566 430.994751,640.195190
	C434.744843,639.166260 437.257233,636.913391 438.961548,633.133423
	C442.645355,634.642822 439.736725,636.273499 438.937347,637.479065
	C436.320740,641.425476 431.743805,642.246399 427.340149,643.894043
z"
        />
        <path
          fill="#1D1415"
          opacity="1.000000"
          stroke="none"
          d="
M413.317139,646.698181
	C411.917114,646.986267 410.860382,647.031860 409.407257,647.090942
	C406.434845,646.725769 403.768066,647.483032 400.949005,646.149536
	C400.245544,643.612793 401.758514,642.735779 403.407043,642.571838
	C407.730316,642.142029 412.071930,641.043213 416.754211,642.671021
	C418.195251,646.337585 415.081604,645.567749 413.317139,646.698181
z"
        />
        <path
          fill="#1D1415"
          opacity="1.000000"
          stroke="none"
          d="
M391.298950,647.068970
	C390.971924,644.997742 390.982117,642.944580 390.994049,640.453003
	C395.411469,639.387695 397.721985,640.851196 399.376312,645.800476
	C397.576538,647.516357 395.595154,646.768250 393.383118,647.102051
	C392.535217,647.126038 392.085724,647.106506 391.298950,647.068970
z"
        />
        <path
          fill="#3A292B"
          opacity="1.000000"
          stroke="none"
          d="
M393.306335,647.404602
	C394.414642,645.355103 396.433990,646.258728 398.606873,645.989136
	C399.404633,645.633179 399.860565,645.500732 400.680664,645.759277
	C403.466125,646.274597 406.023621,645.504822 408.729553,646.813965
	C403.942932,648.933899 398.777557,648.081909 393.306335,647.404602
z"
        />
        <path
          fill="#241312"
          opacity="1.000000"
          stroke="none"
          d="
M438.211365,628.820129
	C435.816559,628.820740 433.596100,631.928162 431.028931,629.049988
	C431.071106,626.355469 433.831726,626.872742 435.079956,625.091125
	C436.233154,623.972229 437.175659,623.212158 438.850311,623.804138
	C439.180420,624.757935 439.218689,625.487122 439.306335,626.578735
	C439.000153,627.465210 438.644562,627.989258 438.211365,628.820129
z"
        />
        <path
          fill="#3A292B"
          opacity="1.000000"
          stroke="none"
          d="
M413.386993,646.977905
	C413.170624,644.681519 415.365723,645.331299 416.767853,644.241516
	C417.912323,642.929749 419.031036,642.946228 420.591309,643.632202
	C422.200684,644.142822 423.319489,642.850220 424.800110,643.823425
	C421.618927,646.395447 417.609924,646.327698 413.386993,646.977905
z"
        />
        <path
          fill="#0A0D13"
          opacity="1.000000"
          stroke="none"
          d="
M421.025208,643.533630
	C419.899628,643.921692 418.845215,643.957947 417.394287,643.992554
	C411.661865,643.407349 406.370300,643.395630 401.104553,645.591919
	C400.549530,645.976257 400.110626,645.982788 399.342712,645.981445
	C397.612427,642.719482 394.357574,641.774597 391.343628,640.055420
	C390.749573,639.750549 390.503326,639.486389 390.002563,639.111206
	C392.601440,637.074097 395.820648,638.118469 398.863403,637.996887
	C401.666656,637.884949 404.418854,637.671021 407.556030,636.973511
	C414.031372,636.557556 419.879730,634.357849 426.486755,634.814941
	C426.738892,635.988464 426.543671,637.210449 426.316376,638.633240
	C424.685242,637.119019 423.095490,634.979980 422.862030,639.266724
	C422.789429,640.599854 421.713165,641.878357 421.025208,643.533630
z"
        />
        <path
          fill="#B1A5B1"
          opacity="1.000000"
          stroke="none"
          d="
M360.056213,539.527344
	C361.777802,539.470642 363.521820,539.678040 365.641541,539.935303
	C365.734772,541.257385 364.842712,541.691650 363.321411,541.740173
	C362.207458,541.515381 361.419434,541.259521 360.328430,540.926697
	C360.043182,540.497009 360.060913,540.144287 360.056213,539.527344
z"
        />
        <path
          fill="#73676A"
          opacity="1.000000"
          stroke="none"
          d="
M427.681702,596.859070
	C428.605682,601.791504 425.995453,606.553162 427.833008,611.765869
	C423.151123,612.447571 420.330139,609.437073 417.252930,606.190430
	C410.135986,600.867554 402.877319,596.249573 396.475494,590.651733
	C389.604095,584.643311 382.151489,583.862854 373.843170,585.668213
	C372.554718,585.948242 371.242188,586.117554 369.516541,586.427734
	C368.447205,580.301636 373.757080,576.277283 374.953918,570.358276
	C378.116730,570.371765 381.152802,571.192078 384.359253,571.078064
	C387.263977,570.974792 389.937714,572.226379 392.097198,574.094849
	C402.775421,583.334229 416.319427,588.079346 427.681702,596.859070
z"
        />
        <path
          fill="#5B555B"
          opacity="1.000000"
          stroke="none"
          d="
M437.052673,597.344971
	C441.892670,597.935852 445.456299,601.014526 448.937927,604.664917
	C444.449707,603.516663 439.867371,602.081970 437.052673,597.344971
z"
        />
        <path
          fill="#5B555B"
          opacity="1.000000"
          stroke="none"
          d="
M434.067139,595.336792
	C435.128326,595.041199 436.059418,595.459717 436.849426,596.712402
	C435.863037,596.950378 434.646210,597.078064 434.067139,595.336792
z"
        />
        <path
          fill="#5B555B"
          opacity="1.000000"
          stroke="none"
          d="
M449.064331,606.298950
	C449.890930,606.106079 450.643402,606.397522 450.956299,607.677490
	C450.120148,607.891113 449.363464,607.603638 449.064331,606.298950
z"
        />
        <path
          fill="#246876"
          opacity="1.000000"
          stroke="none"
          d="
M381.552002,590.669556
	C387.139740,594.452576 392.790497,597.044434 399.498474,598.036804
	C400.669067,598.742004 401.428467,599.395508 401.662872,600.829834
	C401.582153,601.447083 401.500122,601.685608 401.161926,602.202148
	C399.179840,603.535034 397.538910,602.744873 395.553741,602.107361
	C394.386536,601.554260 393.785522,600.803955 392.650269,600.246277
	C390.016968,599.115662 387.679230,599.173462 385.047546,600.215820
	C384.573273,600.486877 384.387604,600.602844 383.923401,600.894348
	C383.457367,601.185181 383.269928,601.300476 382.797607,601.583191
	C382.319092,601.855164 382.125427,601.959717 381.631653,602.197937
	C378.788727,603.856323 376.739716,605.603577 378.530518,609.261475
	C382.942383,613.646973 387.104980,617.787903 393.864960,614.972656
	C395.250824,614.395508 396.817291,614.675781 398.384827,615.875977
	C399.141907,617.077576 399.204346,618.048584 398.788025,619.382324
	C394.944031,621.424072 390.971527,620.879150 387.132965,621.411560
	C391.113281,620.871399 395.207031,621.317627 399.382385,619.635620
	C399.891144,619.357971 400.095306,619.245789 400.628754,619.012573
	C401.854523,618.617249 402.812866,618.650208 404.041931,618.229614
	C404.616577,618.067505 404.851074,618.014343 405.447021,617.923096
	C406.545563,617.875916 407.248688,618.024536 408.202606,618.546692
	C408.805237,619.136047 409.233978,619.633667 409.093475,620.036621
	C408.738129,621.056091 408.651215,622.695557 408.879456,623.432739
	C402.908936,626.524658 396.606873,626.288818 390.103424,627.131042
	C387.631470,626.640015 385.520569,626.316650 383.177795,625.334106
	C379.232208,623.839233 376.352112,621.587036 373.956329,618.099670
	C369.650208,609.873779 371.650177,601.572021 372.115845,593.401855
	C372.231445,591.373108 374.015198,590.416016 376.151947,589.524414
	C378.285065,588.847290 379.853882,589.224792 381.552002,590.669556
z"
        />
        <path
          fill="#213642"
          opacity="1.000000"
          stroke="none"
          d="
M367.958344,615.258667
	C359.205414,610.031982 351.458771,603.897583 343.762207,597.265625
	C346.172333,593.702881 350.206665,594.033203 353.556915,593.528748
	C358.463684,592.789978 363.302429,591.677002 368.634155,591.039001
	C369.726837,591.519531 370.778534,591.901062 370.825531,592.691406
	C371.277252,600.284912 373.687439,608.113464 367.958344,615.258667
z"
        />
        <path
          fill="#193A47"
          opacity="1.000000"
          stroke="none"
          d="
M403.743530,601.381592
	C406.804016,601.484375 409.109192,603.038086 411.677307,604.825928
	C413.560150,606.581482 415.144379,608.176636 418.001343,608.078186
	C418.748749,608.367432 419.122437,608.631653 419.673462,609.224792
	C420.386963,610.841248 421.447174,611.766663 422.497498,613.151001
	C422.732117,614.012085 422.700134,614.521484 422.362793,615.381226
	C418.887329,618.363647 414.815308,617.558533 410.698517,618.208801
	C407.636871,617.110657 406.867065,614.543457 407.251251,612.422363
	C407.914703,608.759766 406.685303,606.288269 404.138397,604.023926
	C403.511078,603.466187 403.391418,602.570923 403.743530,601.381592
z"
        />
        <path
          fill="#214A59"
          opacity="1.000000"
          stroke="none"
          d="
M367.533417,615.418213
	C370.735962,607.759460 370.498047,599.818848 369.207367,591.428894
	C370.724121,590.339050 372.398163,589.628235 374.421814,588.915405
	C375.439667,588.976501 376.098938,589.066345 376.784424,589.835449
	C374.632080,591.336548 373.232941,593.138306 373.237762,595.836426
	C373.250610,602.981689 371.638367,610.263428 375.040283,617.519775
	C374.188507,619.945618 372.853882,619.377869 371.570557,618.444275
	C370.397400,617.590820 369.518921,616.382874 367.956421,615.475464
	C367.603516,615.394470 367.555969,615.408020 367.533417,615.418213
z"
        />
        <path
          fill="#285362"
          opacity="1.000000"
          stroke="none"
          d="
M403.728485,601.018555
	C408.796051,605.129150 408.428040,611.080811 409.789856,616.780212
	C409.427246,620.041138 407.042206,617.856812 405.305206,618.767456
	C404.977356,618.981323 405.001221,619.002441 404.999512,618.986572
	C403.982727,619.893616 402.702637,619.297485 401.281372,619.773926
	C400.989014,619.979919 401.003815,620.002441 401.008301,619.989563
	C400.305817,620.664429 399.444305,620.468201 398.334595,620.069763
	C398.049561,618.747131 398.034637,617.752197 398.024841,616.384094
	C398.190674,615.018433 398.888885,614.472290 399.981384,613.877197
	C400.742859,613.538025 401.381042,613.547302 401.339539,612.580933
	C401.036346,612.016968 400.867035,611.680176 400.662231,611.029724
	C400.648468,610.523682 400.691315,610.320374 400.893799,609.846924
	C401.718628,608.588501 403.830261,608.131226 402.011169,606.108154
	C401.466736,605.589355 401.192902,605.254395 400.785309,604.604980
	C400.483459,603.883789 400.430969,603.445740 400.641052,602.701294
	C400.845123,602.172302 400.890747,601.938416 400.989502,601.351074
	C401.418579,600.604919 401.874817,600.452576 402.733978,600.741455
	C403.056641,600.942627 403.506134,600.980652 403.728485,601.018555
z"
        />
        <path
          fill="#2A1B1B"
          opacity="1.000000"
          stroke="none"
          d="
M422.101074,615.451904
	C421.971771,614.673096 421.973541,614.194763 421.970276,613.357666
	C421.010956,612.269287 419.808319,611.793823 419.200195,610.273621
	C418.854553,609.741943 418.649719,609.488403 418.166992,609.162476
	C417.524689,608.594849 416.954346,608.275513 416.841614,607.352783
	C416.958282,606.867676 416.969025,606.677612 416.998596,606.202148
	C420.407227,607.904602 423.796967,609.892395 427.488708,612.030273
	C427.790771,612.180420 427.868561,612.160706 427.891998,612.128174
	C429.452606,612.760559 430.863434,613.646301 432.595520,614.720947
	C434.887329,616.134399 436.288208,617.792603 435.411835,620.211853
	C434.561951,622.557861 432.800995,623.980774 429.760742,623.737061
	C427.687683,622.406860 428.723755,619.612427 426.262573,618.509277
	C424.935150,617.960022 424.052124,617.312195 422.919128,616.449707
	C422.518127,616.082886 422.375122,615.917603 422.101074,615.451904
z"
        />
        <path
          fill="#5C8997"
          opacity="1.000000"
          stroke="none"
          d="
M377.301575,590.126831
	C376.348999,589.896973 375.758301,589.662354 374.969482,589.170532
	C376.491852,588.015991 378.370941,587.961121 380.650482,587.904114
	C381.235413,588.222778 381.492310,588.419373 381.911133,588.748047
	C381.947388,589.418945 381.898590,589.857300 381.851013,590.629761
	C380.455994,590.683289 379.059723,590.402649 377.301575,590.126831
z"
        />
        <path
          fill="#2D3037"
          opacity="1.000000"
          stroke="none"
          d="
M416.613342,606.992554
	C417.214386,607.545044 417.481201,608.032349 417.851257,608.778503
	C415.478210,608.834106 413.129456,608.408081 412.037048,605.332764
	C413.412170,605.629761 414.845673,606.278503 416.613342,606.992554
z"
        />
        <path
          fill="#535C64"
          opacity="1.000000"
          stroke="none"
          d="
M382.378418,589.044312
	C381.672150,588.871338 381.427917,588.666748 381.159424,588.146362
	C383.680786,587.827393 386.272186,587.914917 388.783264,589.694092
	C386.881561,589.754944 384.821106,589.431458 382.378418,589.044312
z"
        />
        <path
          fill="#193A47"
          opacity="1.000000"
          stroke="none"
          d="
M402.995728,600.607178
	C402.608734,600.961365 402.160797,600.980103 401.377747,600.998230
	C400.693817,600.240479 400.345001,599.483215 399.936340,598.419922
	C401.486389,597.996216 402.495911,598.729614 402.995728,600.607178
z"
        />
        <path
          fill="#535C64"
          opacity="1.000000"
          stroke="none"
          d="
M389.059937,589.933960
	C390.701538,589.963013 392.438690,589.612610 393.691193,591.479797
	C391.883331,592.648621 390.709045,591.083374 389.149261,590.350464
	C388.942017,590.078430 389.019684,589.981323 389.059937,589.933960
z"
        />
        <path
          fill="#484750"
          opacity="1.000000"
          stroke="none"
          d="
M451.034119,601.359131
	C454.711639,602.391479 457.901123,604.591797 460.842224,607.708008
	C457.102417,606.839478 453.503357,605.202393 451.034119,601.359131
z"
        />
        <path
          fill="#213642"
          opacity="1.000000"
          stroke="none"
          d="
M367.654266,615.721375
	C370.819336,614.048279 371.411438,619.218140 374.787079,618.042236
	C377.744598,619.531494 379.204132,622.778198 382.771820,623.755615
	C383.113831,624.226746 383.231567,624.449524 383.183289,625.013977
	C382.434082,625.883850 381.736603,626.168396 380.595184,626.351074
	C379.963287,626.392334 379.710144,626.396484 379.079468,626.365356
	C377.973450,626.119080 377.311218,625.756348 376.332581,625.205322
	C376.030518,624.984314 376.021362,624.949951 376.021576,624.931396
	C374.837433,620.468262 369.752991,619.682678 367.654266,615.721375
z"
        />
        <path
          fill="#92BFCE"
          opacity="1.000000"
          stroke="none"
          d="
M375.987335,625.331787
	C376.616608,623.848022 377.220520,624.806763 378.152435,625.128906
	C378.950012,625.269104 379.397125,625.346252 380.145691,625.649048
	C383.197418,626.547424 385.028290,629.017761 388.177185,629.782471
	C393.506073,631.447266 398.713379,629.687378 404.289276,631.540161
	C404.877502,633.704712 403.750793,633.827393 402.525330,634.003784
	C398.245667,634.619812 393.952911,634.921631 389.633057,634.704834
	C383.304565,634.387329 380.113068,629.286438 375.987335,625.331787
z"
        />
        <path
          fill="#43626E"
          opacity="1.000000"
          stroke="none"
          d="
M425.280090,628.424316
	C423.617676,630.288025 421.432770,629.911865 419.390808,630.902466
	C414.467957,629.828186 410.172791,631.766846 405.308960,632.096863
	C404.878784,632.097107 404.945740,632.066284 404.940308,632.102051
	C404.423553,631.412048 404.135468,630.625854 404.431732,629.323486
	C409.060760,625.451538 414.716827,626.294556 420.152161,624.742554
	C421.428925,624.653198 422.279114,624.671387 423.508545,625.069824
	C424.952972,625.877014 425.535492,626.783752 425.280090,628.424316
z"
        />
        <path
          fill="#59B1BB"
          opacity="1.000000"
          stroke="none"
          d="
M309.032532,268.532410
	C309.012817,269.289673 309.027313,269.695618 309.060547,270.405762
	C306.209991,273.443420 302.997467,272.616486 299.374390,271.294922
	C297.316132,264.616211 298.123749,258.159363 298.455078,251.357819
	C299.857971,250.273453 301.079132,250.595490 302.590240,251.196320
	C303.011719,251.580933 303.143188,251.763016 303.326477,252.256027
	C308.487457,255.408447 314.290955,254.412033 319.720978,255.492523
	C321.628540,255.872086 323.746735,255.099213 325.728882,256.762695
	C326.287445,258.150543 326.593567,259.306519 326.874329,260.827362
	C319.999481,259.858948 313.055420,259.138367 306.182159,257.962097
	C312.871063,259.096802 319.604370,259.978302 326.536804,261.680969
	C326.888489,262.309387 326.918915,262.806366 326.955780,263.676758
	C320.454010,264.936005 314.292542,262.601837 308.515381,262.044617
	C314.154297,262.983429 320.331848,264.197815 326.898315,264.809387
	C327.393921,265.566040 327.613831,266.110931 327.806488,266.960449
	C324.267639,268.827209 320.668152,267.443634 317.196136,267.226105
	C313.480469,266.993347 309.712646,266.716980 306.133545,266.341705
	C307.135040,266.701630 308.459747,266.870087 309.032532,268.532410
z"
        />
        <path
          fill="#76D4DF"
          opacity="1.000000"
          stroke="none"
          d="
M298.958496,271.269287
	C302.042725,270.941467 305.104065,270.900635 308.622345,270.784851
	C315.491455,271.309448 321.903595,271.908997 328.709351,272.545349
	C327.388184,274.669525 325.946747,273.806122 324.420776,274.108704
	C316.958160,275.701965 310.346130,272.377869 303.397278,272.402374
	C307.127441,272.601318 310.853760,272.877655 314.316132,274.916016
	C309.476501,277.712311 304.609009,275.643250 299.482910,274.995667
	C297.925659,273.715363 299.018738,272.580994 298.958496,271.269287
z"
        />
        <path
          fill="#478D9B"
          opacity="1.000000"
          stroke="none"
          d="
M299.161743,274.957092
	C304.033386,274.957947 308.844849,275.288879 313.983826,275.385803
	C314.583862,274.700043 314.965302,274.376923 315.833679,274.118561
	C317.489594,273.982361 318.768066,273.999756 320.430725,274.062012
	C321.585663,274.236298 322.269531,274.600372 323.319855,275.092163
	C324.926361,275.727051 326.260040,275.350647 327.747437,275.946381
	C328.091766,277.305725 328.591919,277.654846 329.788727,277.060089
	C330.285126,279.334259 329.314880,279.666901 327.073578,279.348511
	C318.040710,278.065369 309.002319,276.699371 299.368347,276.706726
	C298.968597,276.162079 299.035095,275.724640 299.161743,274.957092
z"
        />
        <path
          fill="#47A2AE"
          opacity="1.000000"
          stroke="none"
          d="
M301.172546,249.968323
	C300.983948,248.383316 299.624451,247.063248 301.530884,245.852875
	C302.728546,244.249786 304.193329,244.541229 305.980530,244.539459
	C312.613068,245.392624 318.961029,244.862244 325.536713,245.973907
	C323.486481,248.944763 320.901642,247.562241 318.431183,248.199631
	C314.931488,248.450409 311.803802,248.880600 308.731262,247.919495
	C308.367126,247.805603 308.122986,247.863235 307.998871,248.092270
	C308.144714,248.169449 308.290588,248.246628 308.514221,248.659973
	C308.562897,249.510773 308.381409,249.968506 307.739563,250.629700
	C305.316986,251.722458 303.342377,251.295883 301.172546,249.968323
z"
        />
        <path
          fill="#85EAF4"
          opacity="1.000000"
          stroke="none"
          d="
M300.567993,249.636841
	C303.013519,249.785736 305.095306,249.986160 307.622955,250.235687
	C309.576630,249.545181 311.148285,249.440369 313.154510,249.926102
	C317.413055,251.113861 321.635681,250.524109 325.597198,252.811218
	C325.881348,253.101395 325.931824,253.504532 325.946350,253.707153
	C323.951996,256.016998 321.285156,255.702316 318.904877,255.369278
	C313.836609,254.660172 308.651337,254.515060 303.399231,252.462997
	C301.825012,251.815079 300.614594,251.448029 299.019836,251.035004
	C298.854950,250.189453 299.393158,249.774338 300.567993,249.636841
z"
        />
        <path
          fill="#76D4DF"
          opacity="1.000000"
          stroke="none"
          d="
M325.413544,242.003326
	C318.542145,243.552490 311.294769,244.119202 304.057404,241.093704
	C310.978485,241.309357 318.049408,240.690155 325.413544,242.003326
z"
        />
        <path
          fill="#85C1D1"
          opacity="1.000000"
          stroke="none"
          d="
M309.825867,286.798035
	C308.028198,286.523102 306.475861,286.557922 305.282227,284.752319
	C307.197144,283.657349 309.144073,283.936157 310.765961,284.767273
	C312.571350,285.692444 311.649261,286.482849 309.825867,286.798035
z"
        />
        <path
          fill="#76D4DF"
          opacity="1.000000"
          stroke="none"
          d="
M305.277710,244.665726
	C304.107269,245.120987 303.220917,245.387985 302.082031,245.684677
	C301.553192,245.050644 301.276886,244.386917 300.966309,243.358765
	C302.054291,243.320633 303.176575,243.646912 304.645203,244.038605
	C305.181671,244.228500 305.371735,244.352982 305.277710,244.665726
z"
        />
        <path
          fill="#85EAF4"
          opacity="1.000000"
          stroke="none"
          d="
M309.422607,268.906769
	C306.365173,267.144562 302.688354,267.620697 300.452118,264.715485
	C309.403870,264.944946 318.247070,265.476654 327.393860,267.144562
	C327.779236,267.265106 327.795776,267.488220 327.799774,267.600037
	C325.182312,269.432373 322.187347,267.900452 319.744629,268.602997
	C322.060455,268.420502 324.666840,268.459900 327.521057,268.964478
	C327.402802,272.144897 325.284302,270.913971 323.946625,270.776459
	C319.233246,270.291870 314.544769,269.564850 309.422607,268.906769
z"
        />
        <path
          fill="#85EAF4"
          opacity="1.000000"
          stroke="none"
          d="
M326.868713,261.657166
	C317.820526,261.890778 309.255463,259.374237 300.661224,256.939148
	C309.129730,258.598358 318.184937,256.417999 326.516113,261.105988
	C326.848969,261.192200 326.879364,261.501953 326.868713,261.657166
z"
        />
        <path
          fill="#6CDAE1"
          opacity="1.000000"
          stroke="none"
          d="
M305.397491,244.978668
	C304.993591,244.853989 304.995178,244.478119 304.993378,244.291077
	C310.601440,243.650238 316.169495,244.152969 321.720490,244.892731
	C323.330902,245.107346 325.845093,244.235397 325.705872,247.161224
	C325.583588,249.730728 323.293671,248.700912 321.842102,248.957581
	C321.200562,249.070999 320.526855,249.002182 319.431702,249.021942
	C318.347015,249.603195 317.677734,249.539032 316.798431,248.780029
	C316.583313,248.222183 316.565887,247.976517 316.747437,247.419586
	C319.202698,247.004547 321.458923,246.900772 323.715179,246.796982
	C317.776337,245.442078 311.651428,247.048599 305.397491,244.978668
z"
        />
        <path
          fill="#85EAF4"
          opacity="1.000000"
          stroke="none"
          d="
M327.128906,264.776703
	C318.360748,265.383057 309.975006,263.101654 301.365631,262.202850
	C301.475525,261.474213 301.585419,260.745544 301.695312,260.016907
	C309.843201,261.329865 317.991119,262.642853 326.550598,264.002991
	C326.962219,264.050201 327.083801,264.532257 327.128906,264.776703
z"
        />
        <path
          fill="#85EAF4"
          opacity="1.000000"
          stroke="none"
          d="
M326.014099,256.678101
	C317.141541,257.052948 308.407715,256.332642 299.947174,253.161316
	C300.738953,253.110306 301.530701,253.059296 302.669495,252.981171
	C310.457306,252.980896 317.740692,255.167435 325.593811,254.832520
	C325.989502,255.515396 326.018158,255.938538 326.014099,256.678101
z"
        />
        <path
          fill="#45AFBC"
          opacity="1.000000"
          stroke="none"
          d="
M325.969482,254.796646
	C318.509125,255.173843 311.070007,255.113174 303.408752,253.042358
	C303.016510,252.954041 303.017181,252.567230 303.026306,252.374680
	C310.443024,251.973450 317.603424,254.772247 325.509399,253.951202
	C325.966614,254.106857 325.972351,254.303940 325.969482,254.796646
z"
        />
        <path
          fill="#59B1BB"
          opacity="1.000000"
          stroke="none"
          d="
M323.454407,275.692688
	C322.588806,275.780945 322.014801,275.707977 321.227325,275.432373
	C319.754150,273.856476 318.139435,274.723938 316.416443,274.725006
	C315.734161,274.959869 315.326019,275.014343 314.614624,275.110321
	C310.623047,273.040955 306.403229,273.532990 302.494568,271.906067
	C309.425842,272.514832 316.357117,273.123627 323.663605,273.929321
	C324.038818,274.126190 324.027313,274.525330 324.024445,274.724976
	C323.929718,275.126892 323.837860,275.329163 323.454407,275.692688
z"
        />
        <path
          fill="#67BFC6"
          opacity="1.000000"
          stroke="none"
          d="
M316.564545,247.790970
	C317.462524,248.321869 317.939697,248.627640 318.706299,248.980606
	C318.995697,249.027802 319.011749,249.449310 319.034729,249.658966
	C317.844635,251.651642 315.975433,250.747192 313.667816,251.038391
	C311.509155,250.938980 310.073395,250.809158 308.353210,250.482056
	C308.030182,249.849747 307.991608,249.414734 307.920166,248.655502
	C310.503387,246.608704 313.395172,247.859741 316.564545,247.790970
z"
        />
        <path
          fill="#67BFC6"
          opacity="1.000000"
          stroke="none"
          d="
M325.909851,252.736313
	C321.757477,252.617065 317.633575,252.132736 313.227295,251.358597
	C312.944916,251.068787 313.011200,251.005829 313.047058,250.977463
	C317.038055,250.002335 321.047729,250.702362 325.456726,250.731445
	C325.902161,251.396683 325.920258,251.883957 325.909851,252.736313
z"
        />
        <path
          fill="#85EAF4"
          opacity="1.000000"
          stroke="none"
          d="
M325.635742,250.620178
	C321.918823,250.914261 317.953552,250.919113 313.535583,250.936523
	C314.560669,249.322937 316.530090,250.298813 318.655579,249.920410
	C321.167603,250.022736 323.277466,250.176849 325.635742,250.620178
z"
        />
        <path
          fill="#59B1BB"
          opacity="1.000000"
          stroke="none"
          d="
M327.796326,268.924988
	C325.260223,269.110443 322.752472,268.892090 320.244720,268.673706
	C320.251160,268.421539 320.257599,268.169373 320.264038,267.917206
	C322.504608,267.917206 324.745209,267.917206 327.394775,267.814514
	C327.810730,267.981598 327.817688,268.251373 327.796326,268.924988
z"
        />
        <path
          fill="#76D4DF"
          opacity="1.000000"
          stroke="none"
          d="
M323.560150,275.888916
	C323.242035,275.562775 323.406952,275.333069 323.839569,275.044739
	C325.414276,275.415283 327.167206,274.042542 328.143738,276.014221
	C326.628510,276.156036 325.293030,276.039978 323.560150,275.888916
z"
        />
        <path
          fill="#3A3D45"
          opacity="1.000000"
          stroke="none"
          d="
M621.082397,143.763779
	C618.335815,144.343964 616.412720,142.796310 615.169617,141.360809
	C610.546692,136.022415 604.359863,133.000275 598.296570,129.168732
	C595.975098,125.562317 592.510803,125.076302 589.086487,125.052895
	C581.443420,125.000633 575.384644,120.640350 568.843201,117.731316
	C565.671082,116.320656 567.798462,114.522041 569.792725,113.678253
	C572.745178,112.429047 576.022522,111.641479 571.736816,107.871803
	C570.344849,106.647438 571.303894,105.159286 572.708252,104.172394
	C575.768433,102.022034 578.808044,99.842422 581.931641,97.337532
	C590.006592,93.803314 597.991699,97.667099 605.986938,97.058128
	C606.307068,97.033737 606.758728,97.046005 606.948059,97.238724
	C612.897400,103.293472 621.613892,105.001793 627.857422,110.522255
	C630.473450,112.835327 631.538391,124.713120 628.859741,128.148468
	C626.503235,131.170685 625.289124,134.494736 623.422363,137.898087
	C622.585449,138.040207 622.055237,137.087158 622.008057,137.474045
	C621.760193,139.509674 622.483154,141.639893 621.082397,143.763779
z"
        />
        <path
          fill="#64777D"
          opacity="1.000000"
          stroke="none"
          d="
M620.842529,144.390137
	C620.459351,140.889694 622.362976,137.373535 618.774780,135.038696
	C618.239319,134.690277 618.136047,133.898682 618.824768,133.410049
	C619.556946,132.890564 620.658691,132.815384 621.067627,133.396942
	C621.878296,134.549881 622.316650,135.964615 622.988953,137.634308
	C623.034363,140.042542 622.999756,142.087616 622.981323,144.571289
	C623.140808,149.304077 622.220947,153.651749 623.690247,157.880249
	C624.280884,159.580170 623.742676,160.771881 621.990417,161.402954
	C620.812622,161.827148 619.617798,162.009537 618.774841,160.963074
	C617.332520,159.172455 619.387817,158.677994 619.995483,157.507797
	C622.062195,153.527893 620.630493,149.095581 620.842529,144.390137
z"
        />
        <path
          fill="#3E474F"
          opacity="1.000000"
          stroke="none"
          d="
M557.644653,207.041534
	C559.958008,206.942886 561.838440,206.728806 562.924866,204.347168
	C564.143555,203.986877 565.350708,204.020905 566.219727,203.142471
	C569.265259,200.063843 572.741089,199.907562 576.764893,201.755524
	C577.323059,202.314117 577.619751,202.630096 578.104431,203.226212
	C578.401367,203.703079 578.501709,203.907913 578.716064,204.443954
	C578.880859,205.008606 578.931641,205.242065 579.014709,205.838577
	C579.046509,206.705536 578.968445,207.197388 578.716553,208.053589
	C578.524719,218.289993 579.581360,228.153687 578.516724,238.013290
	C578.354370,239.517258 578.745361,240.958038 579.678711,242.634125
	C580.128235,253.213058 576.795471,255.239182 566.643127,251.454819
	C562.792175,250.019348 559.510071,248.610870 560.516846,243.741150
	C558.781250,242.800461 557.883118,245.546158 556.101074,244.094131
	C555.472778,242.625809 556.203064,241.783844 557.108032,240.681885
	C557.592712,240.124725 557.823792,239.789764 558.048584,239.136093
	C558.115295,238.460770 558.141968,238.069962 558.187622,237.380310
	C558.185974,236.679886 558.093567,236.296448 557.749634,235.672546
	C556.381042,233.596252 554.856506,232.086349 553.112000,230.356674
	C552.662354,227.262527 549.865234,226.148438 548.824829,223.575516
	C546.545959,217.939896 550.392273,214.200912 551.947815,209.362045
	C554.132874,209.400360 555.501587,207.662643 557.644653,207.041534
z"
        />
        <path
          fill="#33444C"
          opacity="1.000000"
          stroke="none"
          d="
M589.883484,206.722839
	C590.024231,230.373962 590.265320,253.692871 589.748901,276.994995
	C589.550171,285.963715 589.229797,294.937256 589.176147,304.365906
	C584.834045,306.859222 580.060974,305.297791 575.294922,306.706421
	C574.473083,306.700623 573.970825,306.438202 573.211792,306.054016
	C570.444824,302.739136 571.809570,299.082703 571.836487,295.195709
	C573.292847,291.461700 577.475403,293.613892 579.361206,290.699188
	C580.438538,288.240112 580.223938,286.078339 579.670044,283.510620
	C580.254456,276.899292 579.529785,270.691345 579.494324,264.480286
	C579.480591,262.077118 579.972107,259.925537 581.566345,257.609741
	C581.894775,241.159027 581.958496,225.047516 582.008240,208.467438
	C581.459290,207.372940 581.063416,206.682526 581.032776,205.438766
	C581.170593,204.787201 581.269348,204.537781 581.630127,203.984390
	C585.205383,202.516495 587.302795,204.855499 589.883484,206.722839
z"
        />
        <path
          fill="#535C64"
          opacity="1.000000"
          stroke="none"
          d="
M535.762451,259.129028
	C543.966064,254.377487 552.103088,252.194946 560.857178,256.700623
	C561.990662,257.284027 563.391663,257.347809 564.702637,258.204987
	C563.491089,259.446167 562.153015,259.966034 560.659546,260.964630
	C556.244934,262.298615 551.941040,261.231262 547.519714,262.430176
	C546.743164,262.599548 546.337646,262.775452 545.759521,263.298065
	C545.246033,264.180176 544.881836,264.769104 544.194153,265.585938
	C538.675598,268.808228 537.051880,268.046448 535.151733,261.362793
	C535.112915,260.425842 535.256165,259.920105 535.762451,259.129028
z"
        />
        <path
          fill="#59B1BB"
          opacity="1.000000"
          stroke="none"
          d="
M544.785889,261.380066
	C549.993530,259.814545 555.089294,260.202087 560.594666,259.816376
	C561.849487,259.142426 562.765869,259.007935 564.066162,259.020081
	C565.828247,258.859772 566.928284,260.053833 568.670654,259.914917
	C571.373108,259.866516 573.739136,259.840912 576.547729,259.843018
	C578.201416,260.174255 579.343323,260.543182 578.902588,262.536682
	C577.648926,263.875732 576.418884,264.423248 575.071289,264.362152
	C565.537415,263.929871 556.094177,266.406982 546.118896,265.067932
	C545.170654,264.578888 544.797852,264.125702 544.549561,263.100189
	C544.533142,262.358124 544.546997,262.061371 544.785889,261.380066
z"
        />
        <path
          fill="#6494A3"
          opacity="1.000000"
          stroke="none"
          d="
M578.959778,262.349152
	C578.460693,261.509918 577.885193,260.950195 577.150024,260.130615
	C578.398804,259.000183 579.807251,258.129608 581.523315,257.264771
	C580.296814,265.855682 582.175598,274.538422 581.060547,283.572815
	C578.198853,284.255463 578.504761,281.456604 577.086792,279.896729
	C576.776184,279.478119 576.657410,279.307709 576.349976,278.898804
	C574.339417,277.453979 572.232361,278.853485 570.026733,277.917053
	C568.132446,276.191864 564.479492,276.862671 564.860840,272.920532
	C565.102966,272.365356 565.228333,272.158752 565.609192,271.701172
	C569.065369,270.122253 572.200867,270.072205 575.308716,271.795715
	C578.116150,273.352600 577.771362,271.878571 576.781311,269.727478
	C576.433777,269.022522 576.315125,268.593719 576.290039,267.815308
	C576.486938,266.614594 577.076782,265.975006 577.678406,264.997650
	C578.138428,264.012329 578.524780,263.327606 578.959778,262.349152
z"
        />
        <path
          fill="#33444C"
          opacity="1.000000"
          stroke="none"
          d="
M579.938232,242.496704
	C576.314880,241.703873 576.692871,238.526840 577.092896,235.930984
	C578.231018,228.546448 578.189270,221.244293 577.077393,213.870956
	C576.770020,211.833267 576.555725,209.591492 577.840759,207.258087
	C578.766602,206.926926 579.295959,207.152466 579.868042,207.850922
	C579.976074,219.427200 579.965881,230.729813 579.938232,242.496704
z"
        />
        <path
          fill="#5B7D8C"
          opacity="1.000000"
          stroke="none"
          d="
M535.768066,258.798523
	C535.991089,259.281830 535.984558,259.756195 535.958862,260.584442
	C535.052185,262.471222 537.746643,265.147491 534.042786,265.912842
	C532.756042,265.979401 531.734985,265.429413 530.585144,266.393494
	C529.430420,267.237671 528.485291,267.770996 526.979004,267.432617
	C525.827820,266.782745 525.091187,266.126526 524.387512,265.456329
	C519.318420,260.628174 519.331604,260.624969 525.221375,256.927246
	C525.629089,256.671265 525.790649,256.150635 525.969055,255.333252
	C526.198608,253.669037 526.867371,252.902405 528.640869,253.006592
	C530.508484,253.376801 531.885864,253.942627 532.810669,255.726349
	C533.019409,255.972977 533.006592,256.003082 533.020264,255.992752
	C533.724548,256.679047 533.538879,257.927490 534.822754,258.580292
	C535.080872,258.747498 535.538452,258.789581 535.768066,258.798523
z"
        />
        <path
          fill="#63879A"
          opacity="1.000000"
          stroke="none"
          d="
M552.592224,230.082581
	C555.725037,230.458160 557.228699,232.314621 558.562866,234.876556
	C559.281189,235.389893 559.498108,235.804077 559.224243,236.679535
	C559.004517,237.441574 558.997803,237.887833 559.007507,238.668533
	C559.468384,239.699707 559.087036,240.165115 558.337219,240.803101
	C557.466614,241.788101 556.836731,242.564743 556.056030,243.599670
	C554.910645,243.919601 553.914734,244.050964 552.921875,244.030960
	C548.322205,243.938263 545.328064,241.367874 545.116211,237.381973
	C544.916992,233.633347 547.673828,230.804306 552.592224,230.082581
z"
        />
        <path
          fill="#535C64"
          opacity="1.000000"
          stroke="none"
          d="
M525.775879,254.986481
	C528.657837,256.778351 528.313477,258.131165 525.357605,258.869873
	C523.826294,259.252563 521.887146,258.720001 521.407776,261.219666
	C525.133179,260.895905 525.655945,264.024628 526.989502,266.661865
	C524.346741,270.227356 522.695190,269.991730 518.161560,265.388580
	C518.463623,262.027039 518.759949,259.006134 520.812683,256.194916
	C521.879578,255.077011 522.971130,255.472519 524.478027,255.124023
	C525.032349,254.958069 525.528076,254.977493 525.775879,254.986481
z"
        />
        <path
          fill="#535C64"
          opacity="1.000000"
          stroke="none"
          d="
M533.014282,255.594086
	C531.772095,255.111862 529.819702,255.450333 529.049377,253.347321
	C531.852112,250.962540 532.921021,252.190903 533.014282,255.594086
z"
        />
        <path
          fill="#929EAC"
          opacity="1.000000"
          stroke="none"
          d="
M580.318726,208.075851
	C579.508362,207.857697 579.030457,207.590790 578.344727,207.145126
	C578.179443,206.587662 578.221985,206.208969 578.307373,205.547134
	C579.103455,204.076447 580.146301,204.069794 581.633789,204.808823
	C582.017456,205.809067 582.073181,206.539841 582.061584,207.634735
	C581.546509,208.008286 581.098816,208.017700 580.318726,208.075851
z"
        />
        <path
          fill="#6494A3"
          opacity="1.000000"
          stroke="none"
          d="
M568.785278,259.688599
	C568.030579,261.319946 566.831238,260.872650 565.324585,260.149536
	C564.634644,259.631958 564.487549,259.178375 564.843933,258.361267
	C565.096680,258.079071 565.126282,258.166260 565.166626,258.191650
	C566.325745,258.639679 567.444580,259.062378 568.785278,259.688599
z"
        />
        <path
          fill="#535C64"
          opacity="1.000000"
          stroke="none"
          d="
M535.050964,258.426880
	C533.067078,259.333710 532.285522,258.689850 532.989380,256.295044
	C534.308533,256.117523 534.955994,256.839447 535.050964,258.426880
z"
        />
        <path
          fill="#4E5963"
          opacity="1.000000"
          stroke="none"
          d="
M523.121582,254.321045
	C523.000244,254.020340 522.991150,253.521942 522.988403,253.273010
	C523.295166,252.537811 523.703552,251.595490 523.898438,251.637756
	C525.394836,251.962204 524.820862,253.298096 525.020386,254.615021
	C525.032349,254.958069 524.990662,255.003006 524.969482,255.025146
	C524.255676,255.402954 523.726074,255.105453 523.121582,254.321045
z"
        />
        <path
          fill="#48505A"
          opacity="1.000000"
          stroke="none"
          d="
M517.747437,265.000610
	C521.179443,264.624420 522.685425,269.899628 526.730835,267.267883
	C528.203979,267.038269 528.874207,266.231445 529.770020,265.192963
	C530.158691,264.893097 530.286255,264.770294 530.679199,264.645386
	C531.280273,264.752380 531.619263,264.850067 532.486694,264.979370
	C534.336182,264.963165 536.083252,265.273499 534.618103,262.977356
	C534.063293,262.107880 534.375610,261.423401 535.647705,261.004944
	C536.268982,261.485748 536.815369,262.004242 536.892883,262.585266
	C537.476318,266.959045 539.799744,266.918213 543.230347,265.038574
	C546.508789,267.940247 545.842224,271.184784 543.957886,274.753326
	C539.774353,275.542023 540.628235,280.440247 538.207092,281.214203
	C535.529419,282.070251 534.133850,277.309631 530.555969,277.929169
	C527.600769,278.440887 526.392151,279.924103 525.822754,282.686218
	C525.268005,285.377411 524.735779,288.449493 520.334717,287.995178
	C519.934021,287.993591 519.868958,287.561584 519.851074,287.343872
	C519.775208,285.669037 518.643127,285.602325 517.362061,285.210083
	C516.906616,284.120850 516.698486,283.229919 516.215576,282.124603
	C515.511108,281.886963 515.114136,281.760864 514.528381,281.306152
	C514.012573,280.529449 513.812439,279.925964 513.146484,279.254883
	C512.899475,279.065033 512.979614,279.017609 512.957153,279.058350
	C512.292969,277.977966 510.844604,277.735535 510.118195,276.304443
	C509.888062,274.804047 509.391296,273.894836 508.265808,273.264221
	C507.326050,272.737671 505.069031,273.493927 505.760193,271.286957
	C506.218140,269.824554 507.914673,270.213654 509.168060,269.967499
	C512.172668,269.377350 515.136841,268.621979 516.953125,265.367920
	C517.011658,265.012604 517.502197,265.004883 517.747437,265.000610
z"
        />
        <path
          fill="#79675E"
          opacity="1.000000"
          stroke="none"
          d="
M512.797913,262.773926
	C513.378784,263.480927 513.659790,263.950562 513.961304,264.703186
	C512.994446,265.580658 511.987305,266.719360 511.022858,266.684357
	C502.593933,266.378265 494.299744,268.715118 485.837921,267.793213
	C479.454895,267.097778 478.815613,261.494751 478.689392,257.558990
	C478.501678,251.704132 477.612640,245.369171 480.961548,239.409683
	C490.150513,235.721527 499.458405,234.505478 509.146027,235.930481
	C512.968506,236.492752 515.567810,238.908340 516.461304,241.813339
	C517.740112,245.971207 517.687195,250.581894 517.877075,255.013184
	C517.971985,257.228363 516.421692,258.604370 513.818970,259.020111
	C512.110535,258.750824 511.879150,257.649139 511.604767,256.153381
	C511.556580,255.495529 511.546387,255.235367 511.530823,254.591675
	C511.348969,251.315552 511.982269,248.378311 510.863495,245.228058
	C510.589935,244.715912 510.499908,244.503204 510.326569,243.959290
	C510.141327,242.979660 510.142334,242.317490 509.785095,241.422058
	C502.087830,240.250290 494.592285,239.604294 486.907654,241.794128
	C486.553864,242.252563 486.430969,242.448395 486.110352,242.925842
	C484.977356,244.146545 484.018982,245.077682 484.827209,246.920654
	C484.975586,247.702835 485.009918,248.156631 484.973267,248.954407
	C484.128418,252.632431 484.161224,255.916656 485.716003,259.403259
	C486.259521,260.233643 486.740204,260.690735 487.423035,261.424622
	C487.817413,261.841370 487.974701,262.012024 488.364075,262.445007
	C491.252991,264.145538 494.111572,263.742889 496.808167,263.201202
	C502.029205,262.152344 507.262817,262.575287 512.797913,262.773926
z"
        />
        <path
          fill="#3C545F"
          opacity="1.000000"
          stroke="none"
          d="
M509.775391,275.907043
	C511.546448,276.128632 512.612915,276.798309 512.914307,278.753357
	C511.281586,278.941010 509.055573,280.298004 508.232544,277.168213
	C507.923187,276.467224 507.575165,275.778534 507.763214,275.567535
	C508.337891,274.922668 508.945587,275.580933 509.775391,275.907043
z"
        />
        <path
          fill="#3C545F"
          opacity="1.000000"
          stroke="none"
          d="
M505.783997,276.784912
	C504.936920,277.924805 504.054993,279.301514 502.117859,278.303223
	C502.433746,275.874969 503.767700,275.671051 505.783997,276.784912
z"
        />
        <path
          fill="#67BFC6"
          opacity="1.000000"
          stroke="none"
          d="
M442.921387,263.252747
	C442.370880,260.477356 442.170288,257.846558 442.020294,255.212906
	C441.874237,252.648666 442.221161,250.460037 445.658173,250.853439
	C447.423676,251.055542 447.204132,249.701706 447.503998,248.607544
	C448.085571,246.485413 449.744873,245.978500 451.759247,246.044556
	C453.839386,246.112747 454.591248,246.996246 454.546814,249.156067
	C454.487732,252.024506 456.950104,250.444672 458.653198,250.917755
	C459.040009,250.924362 458.997772,250.991898 459.035034,250.977188
	C460.407867,258.622192 459.872467,266.364044 460.019806,274.537842
	C459.710175,275.094971 459.467804,275.187195 458.889984,275.086243
	C456.279480,271.526611 456.614197,267.633362 456.215057,263.858673
	C455.966400,261.506989 457.417542,258.232483 453.294800,257.530151
	C448.381317,256.693146 446.770966,257.531158 444.873993,262.410522
	C444.307007,263.165131 443.848480,263.381866 442.921387,263.252747
z"
        />
        <path
          fill="#76D4DF"
          opacity="1.000000"
          stroke="none"
          d="
M458.449890,274.523865
	C458.950256,274.836212 459.149597,274.876617 459.650757,274.959900
	C459.871857,277.215637 458.190765,277.547638 456.542023,277.845947
	C445.366150,279.867798 443.466705,278.396393 442.840637,267.328186
	C442.775757,266.181488 442.564667,265.043030 442.485352,263.505066
	C442.823395,263.102966 443.097198,263.090729 443.578552,263.066711
	C445.943848,264.446991 445.768799,266.756287 445.823517,268.813293
	C445.907928,271.987396 447.452698,273.106445 450.453552,273.151367
	C453.041229,273.190094 455.745239,272.786285 458.449890,274.523865
z"
        />
        <path
          fill="#C99469"
          opacity="1.000000"
          stroke="none"
          d="
M512.660278,254.790726
	C513.136841,255.973740 513.176697,257.099457 513.337952,258.630310
	C513.393311,259.204437 513.327271,259.373444 513.174927,259.800598
	C510.800659,262.197021 507.699615,261.806671 505.096588,261.941010
	C499.254456,262.242554 493.464813,264.220917 487.149231,262.534058
	C486.222748,262.036285 485.695953,261.781433 485.011292,261.274109
	C485.375061,257.703430 487.840515,257.598206 490.457062,257.478302
	C496.217651,257.214355 502.011749,258.172302 507.761597,256.652069
	C509.422119,256.213043 510.737518,255.363922 512.660278,254.790726
z"
        />
        <path
          fill="#C99469"
          opacity="1.000000"
          stroke="none"
          d="
M513.099243,262.670227
	C505.084778,264.400360 496.982422,264.669830 488.880157,264.899292
	C487.812012,264.929565 486.391418,265.172058 486.575073,263.044434
	C495.225952,262.167358 503.645905,261.079041 512.582581,260.910004
	C513.056946,261.421417 513.078918,261.875336 513.099243,262.670227
z"
        />
        <path
          fill="#AA8367"
          opacity="1.000000"
          stroke="none"
          d="
M513.064026,260.741272
	C504.656982,262.394409 496.198242,262.768860 487.244751,262.827545
	C486.767944,262.745789 486.793182,262.515045 486.771362,262.403076
	C495.223846,261.532013 503.698151,260.772919 512.630493,260.036255
	C513.088623,260.058716 513.093079,260.514984 513.064026,260.741272
z"
        />
        <path
          fill="#5B7D8C"
          opacity="1.000000"
          stroke="none"
          d="
M522.696655,254.078308
	C523.484497,254.282562 523.968811,254.544800 524.700684,254.927170
	C525.015930,255.841507 525.388550,257.175964 525.099915,257.338959
	C523.634338,258.166290 523.077454,255.983200 521.409363,256.015411
	C521.145813,255.087509 521.458984,254.392624 522.696655,254.078308
z"
        />
        <path
          fill="#855144"
          opacity="1.000000"
          stroke="none"
          d="
M579.025452,180.018280
	C587.253052,175.073776 595.979370,177.782928 604.544495,178.432144
	C605.703430,178.519989 607.264404,178.789551 607.827637,180.730377
	C607.314148,181.835297 606.542297,182.196045 605.239136,182.401978
	C598.598450,183.088989 592.284119,182.182800 585.796448,183.807922
	C581.755554,187.108490 583.986023,191.143890 583.880371,195.147980
	C584.170288,196.274048 584.160034,197.042023 583.895752,198.187256
	C583.626160,198.783783 583.480164,199.004272 583.010071,199.453979
	C581.627625,200.145020 580.629028,199.912994 579.345093,199.178741
	C579.037415,198.935486 579.038452,198.461884 579.027771,198.225327
	C577.625000,195.425995 577.137329,192.644073 577.041870,189.379852
	C577.056519,187.941055 577.055115,186.893387 577.050903,185.452850
	C578.358765,183.832214 577.757202,181.950302 578.807617,180.290680
	C579.027222,180.022980 579.015503,180.008835 579.025452,180.018280
z"
        />
        <path
          fill="#4B4D54"
          opacity="1.000000"
          stroke="none"
          d="
M562.570923,171.591690
	C561.028931,170.144928 560.410767,168.749054 562.690918,168.194183
	C564.478638,167.759140 566.350037,168.889557 566.341248,170.580994
	C566.329651,172.830460 564.225220,171.760132 562.570923,171.591690
z"
        />
        <path
          fill="#5C4747"
          opacity="1.000000"
          stroke="none"
          d="
M576.790161,189.247955
	C578.350220,191.566483 578.640930,194.383453 579.015808,197.584717
	C575.930054,195.824982 576.317139,192.639374 576.790161,189.247955
z"
        />
        <path
          fill="#48505A"
          opacity="1.000000"
          stroke="none"
          d="
M581.998230,204.827301
	C580.993408,205.088181 580.025146,205.098053 578.703491,205.185974
	C578.350220,205.264008 578.139954,205.123703 578.039673,205.046768
	C577.786377,204.804245 577.633301,204.638672 577.242310,204.232635
	C577.005615,203.550873 577.006775,203.109604 577.012451,202.337555
	C577.764771,202.000931 578.512451,201.995087 579.956360,202.030289
	C581.264954,202.359268 581.711792,202.811371 582.037109,203.748123
	C582.064087,204.239395 582.049438,204.407852 581.998230,204.827301
z"
        />
        <path
          fill="#5C4747"
          opacity="1.000000"
          stroke="none"
          d="
M579.150757,199.238251
	C580.063904,198.975006 581.090393,199.014526 582.501831,199.080490
	C583.647339,199.720016 583.781860,200.526657 583.399353,201.807541
	C582.428101,202.742722 581.606079,202.758575 580.383667,202.215820
	C580.018005,201.991470 580.005127,201.997147 580.006714,201.988022
	C579.760254,201.166275 579.512146,200.353638 579.150757,199.238251
z"
        />
        <path
          fill="#5C4747"
          opacity="1.000000"
          stroke="none"
          d="
M578.710571,180.195328
	C578.747986,181.669373 579.747620,183.653442 577.400757,184.953506
	C577.029663,183.361954 576.695374,181.573303 578.710571,180.195328
z"
        />
        <path
          fill="#83AEBF"
          opacity="1.000000"
          stroke="none"
          d="
M533.390015,195.882660
	C533.026917,195.163666 533.025391,194.487503 533.041748,193.474792
	C535.208801,192.817551 534.757507,190.791168 535.659912,189.258820
	C535.909363,191.059494 535.939209,193.109985 535.948242,195.602875
	C535.202148,196.005341 534.476868,195.965408 533.390015,195.882660
z"
        />
        <path
          fill="#83AEBF"
          opacity="1.000000"
          stroke="none"
          d="
M535.889954,185.720581
	C534.695190,185.686401 533.662964,185.268051 535.572754,184.044083
	C535.861328,184.446045 535.891296,184.909424 535.889954,185.720581
z"
        />
        <path
          fill="#83AEBF"
          opacity="1.000000"
          stroke="none"
          d="
M536.347656,182.694916
	C536.229187,182.566132 536.302429,182.411484 536.556763,182.225159
	C536.686768,182.354324 536.620605,182.512848 536.347656,182.694916
z"
        />
        <path
          fill="#6E96A8"
          opacity="1.000000"
          stroke="none"
          d="
M530.102661,178.536438
	C530.328857,180.065659 530.500000,181.326828 530.756958,182.960724
	C530.373840,185.417328 530.176697,187.537460 530.001099,189.660248
	C529.664429,193.730927 527.332825,195.970062 523.440857,195.956818
	C519.159973,195.942276 516.748779,193.631668 516.508545,189.313904
	C516.142273,182.731705 516.848145,181.507721 521.526428,180.626907
	C521.607849,180.611588 521.706055,180.685028 521.796387,180.717041
	C521.701111,180.582413 521.605774,180.447800 521.247925,180.163559
	C522.219788,176.125351 525.541992,177.260651 528.706543,176.965652
	C529.439697,177.387924 529.743591,177.828171 530.102661,178.536438
z"
        />
        <path
          fill="#85C1D1"
          opacity="1.000000"
          stroke="none"
          d="
M520.593994,180.035080
	C522.252747,180.013947 523.520081,180.013947 524.787476,180.013947
	C517.764343,181.774948 517.522095,182.278931 517.890747,189.861511
	C518.056213,193.265945 519.886292,194.621262 522.815674,194.857498
	C525.921143,195.107956 528.277954,193.904236 528.994507,190.558487
	C529.475342,188.313461 529.917908,186.060226 530.610229,183.572067
	C530.862854,184.659393 530.896667,185.985291 530.900940,187.311264
	C530.928894,196.063828 527.500732,198.575424 519.146606,195.919495
	C518.358154,195.668839 517.588867,195.357971 516.440918,195.001556
	C514.763489,191.808990 514.861206,188.524902 515.093445,185.268814
	C515.313171,182.187744 516.529785,179.860092 520.593994,180.035080
z"
        />
        <path
          fill="#59B1BB"
          opacity="1.000000"
          stroke="none"
          d="
M482.933990,287.651184
	C482.720795,289.055695 482.427460,290.107574 481.977295,291.446350
	C480.116180,292.380066 478.460724,293.132629 476.558350,293.117157
	C473.530090,293.092529 470.088867,293.437958 469.195374,289.665955
	C468.537476,286.888611 467.951691,283.509613 471.517944,281.812531
	C475.135895,280.090851 478.359192,281.297363 481.080627,283.798828
	C481.982208,284.627563 482.279999,286.113220 482.933990,287.651184
z"
        />
        <path
          fill="#81A2AC"
          opacity="1.000000"
          stroke="none"
          d="
M543.584229,275.466309
	C543.580505,271.978363 543.656799,268.948425 543.644531,265.470520
	C543.659119,264.263397 543.762390,263.504181 543.905884,262.361877
	C544.117371,261.865082 544.288513,261.751434 544.728271,261.788849
	C545.019592,262.447449 545.042358,262.955017 545.092285,263.842651
	C548.474976,266.132935 552.397095,263.913574 555.978699,265.990417
	C555.481995,269.179901 552.931763,267.989471 551.336365,268.537537
	C554.584717,268.870056 557.863342,267.672699 560.967834,269.913330
	C560.544312,272.998352 558.209534,272.111511 556.422363,272.465729
	C554.300232,272.886322 551.842957,271.456696 550.147888,273.894867
	C549.952332,275.777954 548.855896,275.647736 547.824219,275.410553
	C548.914795,275.860016 550.492615,275.715088 551.202454,277.565094
	C550.544922,278.793701 549.578003,278.415009 548.399780,278.495667
	C547.815430,278.908295 547.578308,277.849091 547.618652,278.492615
	C548.569031,278.507172 549.210693,278.499786 550.097778,278.855225
	C549.685730,282.497589 548.828003,285.891022 548.317932,289.670349
	C548.414612,290.459473 546.575256,290.298950 547.830811,290.986755
	C549.365173,291.827301 551.409668,291.215179 553.049561,292.825195
	C552.666748,296.220459 549.414368,294.848206 547.514648,296.476501
	C546.638123,296.706604 546.131470,296.667206 545.306641,296.291809
	C543.270935,294.239136 544.162170,291.909698 543.759460,289.393433
	C543.701843,288.977966 543.607361,288.519653 543.626465,288.286499
	C543.651733,284.010315 543.657959,279.967316 543.584229,275.466309
z"
        />
        <path
          fill="#4B555C"
          opacity="1.000000"
          stroke="none"
          d="
M544.980225,296.543091
	C545.419556,296.000183 545.860901,295.912354 546.634033,295.762756
	C547.513916,295.448486 548.081909,295.390930 549.045654,295.755829
	C551.554138,299.637695 550.850342,303.309601 549.339355,307.395508
	C549.018982,308.048492 548.856384,308.291931 548.337585,308.792328
	C545.653931,309.090057 546.231384,311.224915 545.297729,312.665649
	C545.049255,311.845001 545.038818,310.800751 545.010193,309.361938
	C544.988770,304.977631 544.985535,300.987915 544.980225,296.543091
z"
        />
        <path
          fill="#3C545F"
          opacity="1.000000"
          stroke="none"
          d="
M497.824066,295.193115
	C500.753540,295.223572 499.976898,297.438324 500.321930,299.422913
	C497.778564,300.340759 496.875824,299.032471 496.903870,296.251831
	C496.864349,295.839722 496.939270,295.877777 496.939758,295.919800
	C497.214233,295.802734 497.454712,295.603210 497.824066,295.193115
z"
        />
        <path
          fill="#535C64"
          opacity="1.000000"
          stroke="none"
          d="
M543.548584,288.155029
	C543.645508,288.053314 543.780273,288.509827 543.741089,288.743896
	C543.432068,288.794739 543.348633,288.554352 543.548584,288.155029
z"
        />
        <path
          fill="#33616C"
          opacity="1.000000"
          stroke="none"
          d="
M514.631409,280.724304
	C515.274109,281.055878 515.518921,281.248474 515.829102,281.705017
	C514.046021,285.976685 510.930664,287.696259 506.456696,286.928406
	C505.245300,286.720490 504.283936,286.465790 504.054291,284.994904
	C503.762573,283.126648 504.581909,281.730072 505.905365,280.710083
	C507.549622,279.442780 507.588074,281.356110 508.221497,282.099335
	C509.998169,284.183960 511.005554,282.262665 512.485229,281.126282
	C513.219727,280.595917 513.720703,280.456635 514.631409,280.724304
z"
        />
        <path
          fill="#33616C"
          opacity="1.000000"
          stroke="none"
          d="
M504.057129,297.683624
	C503.051270,296.920746 502.560272,295.744507 503.118988,295.358826
	C505.240753,293.894196 507.446564,292.746613 505.521606,289.561859
	C505.276031,289.155548 506.427460,288.252319 507.360138,288.279510
	C509.192444,288.332886 510.469818,289.513062 511.472443,290.797729
	C512.393677,291.978149 512.153381,293.637512 510.897003,294.326599
	C508.748627,295.504944 507.160553,297.774231 504.057129,297.683624
z"
        />
        <path
          fill="#33616C"
          opacity="1.000000"
          stroke="none"
          d="
M498.130829,290.908569
	C499.016388,288.593597 500.720215,288.017365 502.405090,288.954437
	C503.356689,289.483734 503.163971,291.145599 502.213837,292.003174
	C500.658264,293.407135 499.245148,293.003967 498.130829,290.908569
z"
        />
        <path
          fill="#3C545F"
          opacity="1.000000"
          stroke="none"
          d="
M500.585602,286.227356
	C498.873169,286.746674 498.102570,286.062897 498.357666,284.616058
	C498.500763,283.804443 499.294861,283.086395 500.195526,283.452576
	C501.368591,283.929535 501.006409,285.027802 500.585602,286.227356
z"
        />
        <path
          fill="#374651"
          opacity="1.000000"
          stroke="none"
          d="
M517.184998,285.305634
	C518.288818,285.360138 520.345581,284.037720 520.066711,286.910370
	C518.973877,286.617279 518.114563,286.108398 517.184998,285.305634
z"
        />
        <path
          fill="#3C545F"
          opacity="1.000000"
          stroke="none"
          d="
M514.924988,280.613586
	C514.494629,280.937927 514.006348,280.953827 513.147400,280.957062
	C512.815857,280.527405 512.854919,280.110413 512.896729,279.379211
	C513.793762,279.100159 514.568420,279.324951 514.924988,280.613586
z"
        />
        <path
          fill="#374651"
          opacity="1.000000"
          stroke="none"
          d="
M516.403809,289.162018
	C516.034485,289.885071 515.569641,289.902588 515.211670,289.449585
	C515.067688,289.267334 515.050049,288.760590 515.191956,288.618652
	C515.644531,288.166138 516.044312,288.459167 516.403809,289.162018
z"
        />
        <path
          fill="#374651"
          opacity="1.000000"
          stroke="none"
          d="
M497.730896,295.074524
	C497.845276,295.319092 497.639587,295.560181 497.154846,295.854004
	C497.118561,295.683258 497.296936,295.404663 497.730896,295.074524
z"
        />
        <path
          fill="#374651"
          opacity="1.000000"
          stroke="none"
          d="
M513.467773,289.571533
	C513.385376,289.455017 513.703064,289.408997 513.703064,289.408997
	C513.703064,289.408997 513.550171,289.688080 513.467773,289.571533
z"
        />
        <path
          fill="#554441"
          opacity="1.000000"
          stroke="none"
          d="
M544.554688,334.357483
	C545.580994,333.064819 546.532532,332.259613 547.732544,332.744019
	C548.857483,333.198120 548.444214,334.310730 548.376648,335.302734
	C548.177429,338.227295 548.136536,341.162659 548.047791,344.543213
	C550.123962,347.145813 549.901733,349.882538 549.911499,352.521667
	C549.958313,365.150818 550.032593,377.781372 549.886414,390.408875
	C549.817078,396.397522 550.721313,402.333649 550.502686,408.315918
	C550.428650,410.342529 550.764404,412.405243 550.050781,413.503998
	C550.115295,412.858032 550.469971,411.395660 550.532288,409.856903
	C551.181213,393.836609 551.347534,377.813171 551.084229,361.782776
	C551.040649,359.133148 550.889465,356.458710 551.793579,353.432098
	C552.154419,352.762970 552.335083,352.514191 552.915161,352.030518
	C554.712891,352.218567 554.994568,353.355469 554.868591,354.555603
	C554.022034,362.617554 554.793152,370.709229 554.465027,377.945831
	C554.304504,379.440094 553.414978,378.682861 554.058472,378.857117
	C554.361755,378.939270 554.532532,378.882416 554.524902,378.688354
	C554.136658,368.947174 555.115540,359.191833 554.214172,349.454529
	C554.058533,347.772766 554.299622,346.137360 555.622375,344.344849
	C555.912170,340.402252 555.912170,336.827515 555.912170,333.252747
	C556.285095,333.246552 556.658020,333.240326 557.030945,333.234131
	C557.030945,335.527679 557.030945,337.821228 557.043762,340.551788
	C558.404663,342.747253 557.976929,344.812744 557.981506,346.769867
	C558.015625,361.236664 558.010376,375.703583 557.993958,390.170441
	C557.988037,395.421936 559.065430,400.673004 558.212769,404.227783
	C557.903503,386.661255 558.010132,367.397705 558.019409,348.134155
	C558.020386,345.995239 557.644409,343.805359 558.786499,341.362427
	C559.695251,338.321747 557.794189,335.341125 560.287415,332.981812
	C562.771973,336.436432 561.423523,340.128845 561.346497,343.585480
	C561.139404,352.879211 560.900818,362.102142 561.759766,371.430054
	C562.744995,382.129486 561.278992,392.940887 561.491150,403.712921
	C561.497009,404.377533 562.611389,403.633484 561.952026,403.731842
	C562.038452,403.677338 561.557007,403.548828 561.549194,403.311707
	C561.350586,397.327942 561.648804,391.348511 561.799316,385.367584
	C562.183350,370.112091 559.843018,354.871735 561.548157,339.609070
	C561.622986,338.939331 561.799561,338.304749 562.394653,337.327881
	C563.245239,336.595642 563.854126,336.467133 564.937744,336.786804
	C566.670776,338.571259 566.637634,340.489716 566.397034,342.264069
	C564.528748,356.040192 565.934570,369.878540 565.498657,384.143616
	C565.883911,394.177063 564.708191,403.779999 566.345581,413.702209
	C568.533936,419.335693 564.935120,423.153473 562.843750,427.737579
	C562.152588,428.372681 561.677612,428.572235 560.779602,428.587158
	C557.691406,426.903381 558.991638,424.158539 558.623474,421.833221
	C557.923950,422.812775 556.889893,423.462097 556.418213,424.923035
	C556.125488,425.858429 555.821289,426.432129 555.358521,427.285522
	C555.096741,427.751678 554.984009,427.933624 554.681946,428.375244
	C554.216125,428.965118 553.921143,429.277832 553.357300,429.780701
	C552.571472,430.374451 551.982117,430.649231 551.019531,430.870728
	C550.254089,430.911621 549.822388,430.850342 549.096436,430.612885
	C547.895935,429.941193 547.020508,429.354706 545.945923,428.544434
	C545.562195,428.016510 545.417175,427.688843 545.385193,427.125488
	C545.760132,426.371033 545.708618,427.292816 545.568848,426.642517
	C545.509094,426.364166 545.230042,426.339325 544.664917,426.466003
	C544.181396,426.300751 543.989563,426.189178 543.554810,425.812805
	C542.125549,423.550354 542.214539,421.374115 542.389526,418.745209
	C541.808350,411.897003 541.861145,405.484131 542.354614,398.612640
	C541.925781,394.175018 541.872620,390.192291 542.349487,385.753693
	C542.575745,382.882111 542.493347,380.459808 542.486572,377.582367
	C542.386536,375.906616 542.055786,374.692902 542.427551,373.000977
	C542.075562,363.802856 541.649841,355.067413 542.411011,345.868530
	C544.306824,342.053589 542.678589,338.119263 544.554688,334.357483
z"
        />
        <path
          fill="#1A2026"
          opacity="1.000000"
          stroke="none"
          d="
M544.082947,344.650391
	C544.085632,354.038757 544.042725,363.051514 543.977173,372.532471
	C542.014771,374.236176 541.196350,372.679596 540.564331,371.424438
	C539.008545,368.334595 536.626404,365.713593 535.476013,362.409271
	C534.473938,359.531128 532.589417,358.602692 529.698853,359.585907
	C527.140564,360.456116 524.578064,360.969116 521.932739,358.822418
	C521.435181,358.116455 521.262024,357.661041 521.168457,356.816162
	C522.512146,353.638062 521.333740,351.718689 518.935181,350.240387
	C517.664368,349.457184 516.361267,348.717041 515.235229,347.225433
	C515.001160,343.321808 515.000488,339.655792 515.008484,335.539490
	C522.079651,335.274750 529.103088,334.693451 536.566895,334.015472
	C537.754700,334.832855 538.584106,335.114075 539.776001,334.227173
	C540.739380,334.017853 541.496826,334.015900 542.632996,334.015472
	C545.230469,337.248627 542.408386,340.984192 544.082947,344.650391
z"
        />
        <path
          fill="#372624"
          opacity="1.000000"
          stroke="none"
          d="
M552.860596,352.759186
	C552.897095,375.054810 554.587158,397.178558 549.767883,419.013977
	C547.590271,394.691437 548.047546,370.304504 548.036194,345.461670
	C554.210266,345.536621 550.929688,339.819794 553.036011,337.222046
	C553.508484,336.563202 553.932983,336.459839 554.676758,336.724731
	C555.783203,339.186981 553.544983,341.595978 555.591919,343.900574
	C555.985718,355.889496 554.348083,367.738983 555.109924,379.676849
	C554.609558,379.675140 554.109253,379.673431 553.608887,379.671692
	C553.608887,370.693695 553.608887,361.715668 553.381958,352.400940
	C552.988098,352.209290 552.821167,352.354370 552.860596,352.759186
z"
        />
        <path
          fill="#473235"
          opacity="1.000000"
          stroke="none"
          d="
M569.719360,333.359039
	C570.450745,332.316132 570.614197,331.297577 571.956787,331.904175
	C572.657288,332.722656 573.187256,333.395844 573.243896,334.247894
	C574.347412,350.861267 575.269348,367.478210 573.702209,384.546021
	C572.908936,385.409149 572.303467,385.652679 571.122925,385.616241
	C568.831116,384.676361 568.593140,383.010986 568.581604,381.327942
	C568.479370,366.410065 568.458618,351.491974 568.589050,336.574097
	C568.597900,335.570160 568.826843,334.600403 569.719360,333.359039
z"
        />
        <path
          fill="#241A19"
          opacity="1.000000"
          stroke="none"
          d="
M563.248718,336.876221
	C560.758545,359.863525 564.357056,382.761932 561.444336,405.446198
	C561.425171,405.430542 561.103088,405.292511 561.100464,405.148590
	C560.695618,383.045715 560.298462,360.942627 559.962708,338.838593
	C559.937805,337.201538 560.361633,335.557648 560.560547,333.513550
	C561.088440,333.072662 561.632690,333.035126 562.583618,332.998474
	C563.114075,333.136383 563.237976,333.273407 563.438232,333.676453
	C563.568420,334.308075 563.617432,334.674286 563.692139,335.319458
	C563.716980,335.978027 563.615295,336.332153 563.248718,336.876221
z"
        />
        <path
          fill="#2A1B1B"
          opacity="1.000000"
          stroke="none"
          d="
M569.686890,332.972839
	C570.020935,348.223175 569.991699,363.476532 570.029602,378.729736
	C570.034424,380.673889 569.524109,382.724091 571.062927,384.809601
	C572.916443,387.248169 572.375488,389.701691 572.297363,392.534424
	C571.108337,394.896973 572.013977,398.242096 568.006470,398.168610
	C565.930725,395.387268 566.640625,392.498932 566.574951,389.765686
	C566.413086,383.024078 566.529724,376.276978 566.511841,369.058624
	C566.520569,359.266357 566.519775,349.947540 566.511169,340.628754
	C566.508972,338.291199 566.545349,335.967377 567.662354,333.359741
	C568.431641,332.983582 568.888062,332.979675 569.686890,332.972839
z"
        />
        <path
          fill="#372624"
          opacity="1.000000"
          stroke="none"
          d="
M558.725647,341.000000
	C559.015442,364.278168 558.970703,387.556091 558.926025,410.834015
	C558.287537,410.824982 557.649048,410.815918 557.010559,410.806885
	C557.010559,387.847198 557.010559,364.887482 557.033569,341.458282
	C557.501404,340.992432 557.946289,340.996094 558.725647,341.000000
z"
        />
        <path
          fill="#1F0F0F"
          opacity="1.000000"
          stroke="none"
          d="
M578.426025,346.674957
	C578.296021,355.070923 577.730530,363.026825 578.799988,370.999969
	C579.843872,378.781311 577.835449,386.554443 578.023682,394.361115
	C578.071594,396.346375 578.213867,398.329407 578.545959,400.641052
	C578.796814,401.679901 578.814392,402.391144 578.832886,403.457520
	C578.369812,404.303345 577.809509,404.467621 576.787598,404.016632
	C574.789856,400.474823 575.825378,396.984100 575.506714,393.144226
	C576.205200,377.452271 574.289429,362.288361 574.511475,346.599762
	C574.852295,341.688232 573.743713,337.167725 575.607361,332.429321
	C576.385376,331.348297 576.983398,331.507050 577.832642,331.909790
	C579.835144,336.682892 578.936279,341.475891 578.426025,346.674957
z"
        />
        <path
          fill="#1F0F0F"
          opacity="1.000000"
          stroke="none"
          d="
M564.594604,385.157532
	C564.026978,369.253906 563.600098,353.642456 564.801941,337.614227
	C564.529175,336.700745 564.453186,336.195465 564.793091,335.338745
	C565.192322,335.000793 565.365906,334.985657 565.800171,334.951965
	C567.447083,336.484711 566.954224,338.393402 566.977844,340.165070
	C567.101624,349.442108 566.842529,358.721466 567.234985,368.454346
	C567.671997,373.857574 567.421875,378.809906 567.434448,384.220734
	C566.791687,385.624176 565.923584,385.700836 564.594604,385.157532
z"
        />
        <path
          fill="#241312"
          opacity="1.000000"
          stroke="none"
          d="
M572.592285,385.546234
	C573.606262,367.740173 572.479370,350.376556 572.095947,332.545044
	C572.508545,332.068817 572.900146,332.049866 573.586670,332.044739
	C576.153870,336.216858 574.489624,340.714966 575.002075,345.500366
	C574.849548,361.147888 575.865479,376.308105 575.481384,391.482269
	C575.432190,393.425110 575.747375,395.509399 573.491394,397.078125
	C570.730713,393.551910 572.345398,389.707825 572.592285,385.546234
z"
        />
        <path
          fill="#554441"
          opacity="1.000000"
          stroke="none"
          d="
M567.169922,369.292419
	C565.185669,364.867126 566.244873,360.523132 566.125610,356.281128
	C565.934509,349.487427 566.087952,342.684082 566.078247,335.409058
	C566.324280,333.977844 565.521301,332.333557 567.787598,332.768982
	C567.979370,344.697052 567.983398,356.406647 567.942383,368.586609
	C567.729431,369.230255 567.561584,369.403534 567.169922,369.292419
z"
        />
        <path
          fill="#3A292B"
          opacity="1.000000"
          stroke="none"
          d="
M578.489319,347.081665
	C578.147461,342.380859 578.086609,337.655975 578.044312,332.464905
	C578.502869,331.993347 578.942810,331.987946 579.714111,331.985046
	C580.024597,336.695282 580.003784,341.402985 579.941711,346.580750
	C579.523804,347.053040 579.147034,347.055298 578.489319,347.081665
z"
        />
        <path
          fill="#3A292B"
          opacity="1.000000"
          stroke="none"
          d="
M574.393188,346.250977
	C574.052429,341.690796 573.990906,337.333313 573.905518,332.517151
	C574.332153,332.045441 574.782776,332.032379 575.568542,332.034973
	C575.924072,336.401337 575.944336,340.752106 575.939758,345.568970
	C575.562561,346.358093 575.151550,346.507050 574.393188,346.250977
z"
        />
        <path
          fill="#372624"
          opacity="1.000000"
          stroke="none"
          d="
M544.361023,344.695312
	C540.352356,342.002258 542.975464,338.274597 543.009521,334.424500
	C543.414856,334.009369 543.817993,334.001709 544.520630,333.990326
	C544.916077,337.449677 545.324524,340.919586 544.361023,344.695312
z"
        />
        <path
          fill="#372624"
          opacity="1.000000"
          stroke="none"
          d="
M565.008911,334.696228
	C564.991699,335.497528 564.964600,335.979187 564.866455,336.819122
	C564.411865,337.141296 564.028381,337.105194 563.355591,337.078918
	C563.042175,336.624878 563.018188,336.160980 562.989441,335.349487
	C562.858459,334.835938 562.732239,334.670044 562.793518,334.253937
	C562.982971,333.836334 562.984802,333.668976 562.988464,333.250488
	C564.898743,331.651276 564.817932,333.204865 565.008911,334.696228
z"
        />
        <path
          fill="#554441"
          opacity="1.000000"
          stroke="none"
          d="
M554.958923,336.572327
	C554.545837,336.917999 554.134949,336.918213 553.421204,336.868591
	C553.469360,335.582458 553.820496,334.346130 554.236389,332.881531
	C555.412476,334.049652 554.756775,335.215759 554.958923,336.572327
z"
        />
        <path
          fill="#372624"
          opacity="1.000000"
          stroke="none"
          d="
M539.610840,334.013214
	C539.428589,336.017181 538.595947,336.753906 537.073242,334.334229
	C537.754822,334.013947 538.497253,334.010254 539.610840,334.013214
z"
        />
        <path
          fill="#3E474F"
          opacity="1.000000"
          stroke="none"
          d="
M548.404053,308.387665
	C548.584595,304.410065 549.326843,300.694397 549.000366,296.444580
	C555.530518,292.281555 562.592834,293.466675 570.004944,293.965546
	C573.289246,297.702576 571.828369,301.645111 571.187866,305.829437
	C570.039856,306.353485 569.234741,306.510223 568.436646,306.697052
	C564.708862,307.569672 559.507507,305.706665 557.547607,309.440399
	C555.971008,312.443970 557.090942,316.855743 556.982178,320.641754
	C556.951904,321.696259 556.807190,322.747498 556.643188,324.618896
	C555.007874,319.957306 557.503662,315.470184 554.251099,311.627686
	C554.222656,310.937775 554.398682,310.615784 554.838013,310.136932
	C554.583008,309.605621 554.123474,309.262604 553.365234,308.897949
	C551.577698,309.366180 550.101562,309.634094 548.404053,308.387665
z"
        />
        <path
          fill="#271B1F"
          opacity="1.000000"
          stroke="none"
          d="
M548.079102,308.372620
	C549.567017,308.245148 551.023682,308.343353 552.773438,308.658936
	C553.043335,312.990997 552.964600,317.106415 553.019043,321.220062
	C553.049316,323.498230 552.594543,325.177704 549.403687,324.999451
	C547.725830,320.053497 547.993042,315.000397 547.988037,309.507019
	C547.981445,309.049255 548.047791,308.598358 548.079102,308.372620
z"
        />
        <path
          fill="#4B555C"
          opacity="1.000000"
          stroke="none"
          d="
M570.894653,306.632507
	C570.822388,302.547516 571.584595,298.875488 570.035889,294.975098
	C570.253052,293.321503 571.243408,293.206665 572.715393,293.898254
	C572.987732,297.811249 572.976257,301.421906 572.959839,305.482422
	C570.672791,311.933197 572.873108,318.257568 571.368530,324.648315
	C571.055725,319.002930 571.001709,313.042511 570.894653,306.632507
z"
        />
        <path
          fill="#271B1F"
          opacity="1.000000"
          stroke="none"
          d="
M554.815125,310.116516
	C554.870483,310.271881 554.698425,310.595093 554.320740,311.107300
	C554.272644,310.958954 554.430176,310.621643 554.815125,310.116516
z"
        />
        <path
          fill="#DE8A57"
          opacity="1.000000"
          stroke="none"
          d="
M899.756958,562.794617
	C901.526917,563.244385 902.916748,563.593018 904.652832,564.045654
	C907.102966,566.412170 911.441467,566.790222 911.035645,571.596802
	C908.998474,572.932129 907.424622,567.865784 905.038574,571.787781
	C904.541016,572.125427 904.093750,572.111450 903.312744,572.124878
	C902.864319,571.975281 902.749695,571.798279 902.444824,571.368591
	C901.632080,569.574219 903.213684,568.778564 903.361084,567.225464
	C902.419128,565.431824 900.773865,565.205627 899.209717,564.257324
	C898.748474,563.483276 898.885315,563.034363 899.756958,562.794617
z"
        />
        <path
          fill="#CD7D4C"
          opacity="1.000000"
          stroke="none"
          d="
M899.880310,562.649292
	C899.844788,563.120056 899.552429,563.344360 899.113281,563.804443
	C897.828857,563.723694 896.691223,563.407104 895.200012,563.135742
	C894.846375,563.181030 894.481201,563.391846 894.305542,563.275208
	C893.585266,562.880920 893.040588,562.603333 892.212036,562.234131
	C891.605347,562.020874 891.355469,561.811890 891.039185,561.311890
	C890.923523,560.825928 890.947205,560.543701 891.004883,559.837646
	C891.768799,559.563110 892.498657,559.712463 893.597534,559.900269
	C895.361023,561.887817 897.669250,561.738892 899.880310,562.649292
z"
        />
        <path
          fill="#87462F"
          opacity="1.000000"
          stroke="none"
          d="
M903.008423,567.154602
	C908.508911,568.996826 901.593994,569.129639 902.464966,570.828735
	C897.712036,569.232605 901.914795,568.540649 903.008423,567.154602
z"
        />
        <path
          fill="#46170C"
          opacity="1.000000"
          stroke="none"
          d="
M894.194641,563.271790
	C894.129944,563.158569 894.490051,563.133972 894.668213,563.157471
	C894.846375,563.181030 894.594177,563.458862 894.594177,563.458862
	C894.594177,563.458862 894.259338,563.385010 894.194641,563.271790
z"
        />
        <path
          fill="#433437"
          opacity="1.000000"
          stroke="none"
          d="
M812.586304,596.024170
	C812.989441,597.409973 812.991089,598.788269 812.995117,600.580811
	C812.302124,603.910095 810.245544,605.294067 807.093262,605.878418
	C801.856567,603.924744 796.359985,603.415588 792.332153,598.955688
	C791.853943,596.954529 792.360229,595.626587 793.926086,594.265686
	C798.358276,592.761963 802.379822,592.472107 806.669250,594.638000
	C808.477478,596.561340 810.423462,595.819214 812.586304,596.024170
z"
        />
        <path
          fill="#2C1E22"
          opacity="1.000000"
          stroke="none"
          d="
M807.164185,594.693298
	C802.965637,594.997375 798.945984,595.000244 794.463135,595.002808
	C789.457275,595.223694 784.926453,595.326416 780.291870,593.330322
	C779.998901,592.570679 779.988037,592.134033 779.969666,591.369873
	C782.748047,588.672607 785.965454,588.843994 789.629028,589.718872
	C792.073303,589.660828 794.147522,589.347351 796.622070,589.043213
	C801.437134,591.646790 806.317078,590.884155 811.531982,591.002747
	C811.719849,593.889038 808.235962,592.363770 807.164185,594.693298
z"
        />
        <path
          fill="#3A4D56"
          opacity="1.000000"
          stroke="none"
          d="
M806.683838,605.316772
	C809.267090,604.472290 810.470520,602.342957 812.701904,601.148438
	C822.786377,601.889709 831.012451,596.456909 840.016846,594.182983
	C841.726746,593.751099 843.344116,592.936768 845.590210,592.996521
	C855.085266,593.117432 863.850464,590.718506 872.775574,589.569519
	C874.235413,589.381592 875.694885,589.191040 877.580872,589.005066
	C875.554993,592.219482 870.553711,593.442627 868.359558,593.051697
	C861.364319,591.805420 855.805054,595.664124 849.507446,596.325562
	C847.702820,596.515076 845.892456,597.028259 844.107117,596.940002
	C841.089905,596.790833 838.925781,597.917175 836.502930,599.751770
	C831.236877,603.739319 825.742065,607.707275 818.484192,607.028259
	C817.274048,606.915039 816.186340,607.326355 815.199402,608.665833
	C812.424255,610.773560 809.930420,609.347656 807.070435,608.527466
	C806.201477,607.436462 806.127075,606.574341 806.683838,605.316772
z"
        />
        <path
          fill="#5C8997"
          opacity="1.000000"
          stroke="none"
          d="
M806.690552,607.736694
	C809.441345,608.077637 811.908081,607.829529 814.603638,608.929688
	C816.983154,610.427002 818.971191,611.859375 821.075073,613.609802
	C821.402588,614.404053 821.614258,614.880249 821.969360,615.581848
	C822.051941,616.083679 821.991150,616.360046 821.683960,616.981873
	C821.208923,617.492676 820.980469,617.658081 820.359741,617.978516
	C812.855896,616.938538 805.862244,619.134949 798.386963,618.985840
	C795.959290,619.485962 794.161926,619.206787 792.821655,616.952515
	C793.441345,614.292969 795.516479,614.661682 797.209229,614.396179
	C800.284424,613.913757 803.408142,613.679321 806.358948,612.307983
	C805.883728,610.758118 803.635803,610.875671 803.627869,608.753540
	C804.333374,607.347839 805.264526,607.181580 806.690552,607.736694
z"
        />
        <path
          fill="#67BFC6"
          opacity="1.000000"
          stroke="none"
          d="
M882.109741,601.685730
	C881.728455,606.848389 877.958801,604.595215 875.466187,604.849976
	C872.735474,605.129150 871.716431,603.434937 872.010254,600.442627
	C872.030273,600.033813 872.514038,600.022888 872.755981,600.025330
	C875.457214,600.132446 877.948059,599.643005 880.725708,600.631348
	C881.375366,601.047119 881.670959,601.250549 882.109741,601.685730
z"
        />
        <path
          fill="#3C7D8E"
          opacity="1.000000"
          stroke="none"
          d="
M880.943970,600.496765
	C878.573425,601.102722 876.073853,601.197205 873.301392,600.283325
	C875.624573,598.580750 878.232605,598.324768 880.943970,600.496765
z"
        />
        <path
          fill="#6CDAE1"
          opacity="1.000000"
          stroke="none"
          d="
M886.050415,611.995117
	C889.880493,611.601990 893.089417,612.631653 896.359802,613.092285
	C897.848999,613.301941 898.786926,614.154175 898.872253,615.708496
	C898.987671,617.811584 897.496460,617.098022 896.251953,617.439331
	C888.061157,619.686218 879.863525,620.967712 871.411743,619.102295
	C870.255005,618.846985 869.075439,618.669250 868.293762,617.730347
	C867.192688,616.407959 864.789001,615.580139 865.616089,613.426575
	C866.312805,611.612366 868.386047,611.213318 870.248718,611.033569
	C875.409912,610.535400 880.485291,611.481628 886.050415,611.995117
z"
        />
        <path
          fill="#59B1BB"
          opacity="1.000000"
          stroke="none"
          d="
M798.187439,618.571655
	C805.351685,616.642517 812.694092,615.370728 820.595215,616.976807
	C821.456421,618.066956 822.545776,617.734619 823.620483,618.157471
	C826.010498,617.944214 827.777039,618.456665 829.093506,620.669128
	C828.949463,623.250183 827.157715,623.178528 825.566956,623.551575
	C817.598083,625.420105 809.517090,626.728577 801.106689,628.424561
	C800.170532,628.396606 799.647522,628.335510 798.775635,628.110474
	C793.750488,623.960571 793.740540,623.632141 798.350220,620.516602
	C798.548645,619.880371 798.497131,619.505798 798.187439,618.571655
z"
        />
        <path
          fill="#337484"
          opacity="1.000000"
          stroke="none"
          d="
M799.823364,627.490112
	C809.558960,625.271057 819.030945,623.347229 828.847656,621.220032
	C830.721008,620.962219 831.930664,621.318054 831.978455,623.522827
	C830.921509,624.253906 829.857910,624.639404 828.464966,625.102661
	C818.919250,626.074524 809.951477,628.394836 800.442078,629.948730
	C799.428894,629.333618 799.302002,628.601624 799.823364,627.490112
z"
        />
        <path
          fill="#5C8997"
          opacity="1.000000"
          stroke="none"
          d="
M823.916382,617.929199
	C822.664612,619.129761 821.367065,619.361206 819.985535,618.584045
	C820.230286,618.207703 820.474976,617.831421 820.869751,617.221497
	C821.019836,616.987915 820.998291,617.001404 820.988403,617.008179
	C821.436523,616.557495 822.064819,616.515503 822.865479,616.135498
	C823.370911,616.490662 823.647400,617.045776 823.916382,617.929199
z"
        />
        <path
          fill="#59B1BB"
          opacity="1.000000"
          stroke="none"
          d="
M822.848511,615.907227
	C822.594910,616.272583 822.095337,616.609741 821.287170,616.980957
	C821.208679,616.727600 821.438843,616.440186 821.890869,615.979980
	C822.112671,615.807251 822.602539,615.879028 822.848511,615.907227
z"
        />
        <path
          fill="#A19796"
          opacity="1.000000"
          stroke="none"
          d="
M791.317871,633.096252
	C790.544373,633.105896 790.109619,633.078491 789.254639,632.699585
	C789.133545,631.633484 789.705872,631.247864 790.777710,630.986511
	C791.622131,630.971863 792.082947,631.098450 792.772400,631.567688
	C793.025452,632.022705 793.028015,632.217346 793.023865,632.703796
	C792.563538,633.016846 792.110046,633.038025 791.317871,633.096252
z"
        />
        <path
          fill="#517581"
          opacity="1.000000"
          stroke="none"
          d="
M793.302917,631.610474
	C792.570984,631.846497 792.119080,631.865051 791.328613,631.865967
	C790.063599,631.061279 788.647888,630.664673 788.970215,628.724976
	C791.643921,626.609558 794.342651,625.961853 797.663818,626.916138
	C798.461731,627.119812 798.926636,627.117371 799.739197,627.154907
	C800.064575,627.885986 800.042175,628.577148 800.027954,629.614380
	C797.885071,630.437988 795.734070,630.915527 793.302917,631.610474
z"
        />
        <path
          fill="#A19796"
          opacity="1.000000"
          stroke="none"
          d="
M728.341431,641.112671
	C728.801880,639.895752 729.659119,640.340942 730.782593,640.860657
	C745.298523,638.289062 759.455872,635.075500 773.818054,633.093323
	C778.598633,632.433533 783.442322,630.473938 788.690186,632.639832
	C788.977295,632.964539 788.998901,632.998108 789.009766,633.015564
	C781.221802,636.722534 772.514099,635.899353 764.386353,637.979797
	C761.835876,638.632751 759.202454,638.961792 756.318970,639.680908
	C749.420532,640.187378 743.059814,641.978333 736.661255,643.370789
	C733.360535,644.089050 730.882874,643.689453 728.341431,641.112671
z"
        />
        <path
          fill="#3A4D56"
          opacity="1.000000"
          stroke="none"
          d="
M313.819092,479.449768
	C314.515594,479.169281 314.952728,479.121185 315.718689,479.046326
	C317.191437,477.621826 318.779999,478.104919 320.610596,478.049438
	C322.731201,477.721466 324.477722,477.373535 326.607239,477.014648
	C331.615417,479.245392 334.568726,477.311920 336.934357,473.136200
	C344.203888,475.223053 350.014221,480.512054 358.117554,480.517700
	C361.127197,481.016846 363.744904,478.818115 366.677246,480.767731
	C363.789215,484.803528 359.168701,483.099213 355.252502,483.830841
	C351.273682,484.574097 347.039337,483.831665 342.937561,484.080688
	C340.647186,484.219727 337.774658,483.908295 337.021362,487.618896
	C337.106873,488.248352 337.208679,488.489929 337.115112,489.073303
	C336.099731,490.156555 335.088654,490.346161 333.659729,490.432922
	C332.304474,490.484772 331.323730,490.477142 329.991516,490.580383
	C328.043884,491.284607 326.386902,491.175537 324.374664,491.054016
	C323.187469,490.965668 322.152740,490.857849 321.973633,489.969604
	C321.711853,488.670990 322.986725,489.117676 323.635559,488.880463
	C327.183380,487.583466 331.217865,488.483704 335.051727,486.213379
	C331.561157,485.827423 328.569519,486.248444 325.525269,486.651672
	C321.798767,487.145294 317.940796,488.031586 314.176575,485.368591
	C313.581879,483.291290 312.528534,481.557007 313.819092,479.449768
z"
        />
        <path
          fill="#59646C"
          opacity="1.000000"
          stroke="none"
          d="
M324.229126,491.330994
	C325.060944,489.495239 327.066864,490.270447 328.674530,489.159485
	C331.476990,491.692383 336.058624,489.161133 338.263092,493.099365
	C338.302979,493.976135 338.068634,494.395020 337.323975,494.852356
	C334.197723,495.293884 331.426178,495.613922 328.225281,495.891907
	C328.632996,492.886536 325.715088,492.884888 324.229126,491.330994
z"
        />
        <path
          fill="#3C5965"
          opacity="1.000000"
          stroke="none"
          d="
M313.652344,479.163147
	C312.915619,481.053558 316.132385,482.747833 314.088165,484.840393
	C310.178680,482.691315 305.979980,480.990509 302.684509,477.362854
	C306.352905,476.373566 309.374573,479.881622 313.652344,479.163147
z"
        />
        <path
          fill="#6494A3"
          opacity="1.000000"
          stroke="none"
          d="
M521.477783,355.885864
	C521.365601,356.561401 521.338623,356.912415 521.353210,357.525024
	C520.581848,362.273041 516.918274,358.414246 514.777954,359.856384
	C514.462219,359.773743 514.116394,359.602448 514.116394,359.602448
	C514.116394,359.602448 513.731140,359.621948 513.544189,359.647644
	C513.734680,357.464600 510.933411,355.733673 512.687378,353.112549
	C513.343994,353.001099 513.721680,353.137054 514.393372,353.337585
	C517.345642,352.955292 520.126465,352.117767 521.477783,355.885864
z"
        />
        <path
          fill="#20323A"
          opacity="1.000000"
          stroke="none"
          d="
M543.655762,397.751160
	C543.927673,404.663269 543.944214,411.341583 543.955933,418.485352
	C534.427185,427.420868 525.521606,436.526672 516.296509,445.800476
	C514.244751,443.826294 515.011108,441.219971 515.000061,438.814301
	C514.923706,422.189758 514.971436,405.564636 514.993896,388.471436
	C516.189697,386.851685 516.914124,385.339294 518.125854,384.192474
	C519.746094,382.659119 521.130737,381.820129 523.093201,383.887451
	C525.367310,386.283142 528.744995,386.461487 531.707642,387.174011
	C535.563538,388.101379 538.355835,389.982208 539.794189,393.642242
	C540.511536,395.467590 541.854553,396.547211 543.655762,397.751160
z"
        />
        <path
          fill="#182932"
          opacity="1.000000"
          stroke="none"
          d="
M543.939636,397.523712
	C541.385254,398.320801 539.047852,399.282166 538.602234,394.923187
	C538.264099,391.615997 535.805481,389.064301 531.720154,388.931305
	C528.627258,388.830627 526.022339,386.521393 522.671875,386.798065
	C520.619995,386.967499 521.958008,384.695404 521.286255,383.229034
	C519.388489,385.046265 518.168518,387.308258 515.356323,387.952820
	C514.920349,378.904388 514.832031,369.805634 514.602966,360.240295
	C514.450928,357.995453 514.436584,356.049194 516.992371,357.849396
	C518.333801,358.794281 519.513062,359.284668 521.177307,358.023010
	C524.193665,358.989227 526.714172,359.504395 529.741455,357.639313
	C532.387451,356.009155 535.936584,357.047333 536.606567,360.094940
	C537.704895,365.090637 541.606628,368.323669 543.616089,372.916443
	C543.968445,374.066589 543.982483,375.132538 543.973877,376.597687
	C543.730591,377.239471 543.504944,377.477295 543.112610,377.891296
	C542.950867,378.072174 542.674194,378.467163 542.598999,378.695068
	C542.462891,379.236481 542.412720,379.551666 542.358276,380.107910
	C542.329346,380.692383 542.325684,381.037567 542.353149,381.662933
	C542.399963,382.124237 542.421997,382.305450 542.482483,382.747742
	C542.568481,383.172180 542.615906,383.335510 542.801758,383.706055
	C543.150452,384.162842 543.370361,384.403564 543.758667,384.815369
	C543.934265,389.017731 543.951233,393.040131 543.939636,397.523712
z"
        />
        <path
          fill="#3B3C43"
          opacity="1.000000"
          stroke="none"
          d="
M516.426758,446.038849
	C516.684082,444.335754 517.732361,442.700165 517.931519,440.967010
	C518.475342,436.235016 523.561829,437.959656 525.706299,435.076202
	C529.340820,430.189209 533.916748,426.006195 538.048096,421.482635
	C539.459045,419.937714 540.973938,418.772736 543.575073,418.968750
	C544.082764,421.088440 544.214294,423.226074 544.569824,425.680420
	C545.260437,426.197052 545.499207,426.543182 545.454224,427.409119
	C545.359741,427.982025 545.285767,428.204010 544.996643,428.702026
	C539.817566,432.617554 535.102783,436.568115 530.169312,440.785339
	C529.787964,441.218475 529.619629,441.377838 529.166626,441.737152
	C525.437561,443.115875 522.851990,446.116394 518.746704,446.431152
	C517.896606,446.218384 517.363525,446.140106 516.426758,446.038849
z"
        />
        <path
          fill="#688FA1"
          opacity="1.000000"
          stroke="none"
          d="
M500.738159,456.767334
	C504.408112,453.046844 509.719788,452.492126 512.944946,448.340881
	C512.950989,447.986694 513.060608,447.577850 513.088501,447.368622
	C513.890442,447.468353 514.664429,447.777344 515.753479,448.111877
	C516.232910,448.327026 516.397339,448.516602 516.669678,449.067383
	C516.507141,452.669617 514.767578,454.691956 511.567139,455.126770
	C507.929565,455.621033 505.154938,458.792114 500.958679,458.073761
	C500.628906,457.584259 500.586975,457.337128 500.738159,456.767334
z"
        />
        <path
          fill="#929EAC"
          opacity="1.000000"
          stroke="none"
          d="
M516.559937,449.798523
	C516.172668,449.392426 516.146057,449.141876 516.093994,448.514374
	C516.712646,447.665466 517.356873,447.193542 518.215332,446.509094
	C521.709167,444.459167 524.988647,442.621704 528.631592,440.864197
	C528.133667,445.351562 522.978027,449.198700 516.559937,449.798523
z"
        />
        <path
          fill="#182932"
          opacity="1.000000"
          stroke="none"
          d="
M521.756958,356.065308
	C520.097351,352.971375 516.336365,357.043854 514.824402,353.721802
	C514.787231,351.566864 514.887024,349.731567 514.994263,347.442017
	C517.926697,347.648224 520.505493,348.949707 522.599365,351.128815
	C524.304443,352.903198 524.371460,354.531799 521.756958,356.065308
z"
        />
        <path
          fill="#705250"
          opacity="1.000000"
          stroke="none"
          d="
M879.772583,507.243011
	C881.719299,506.996796 883.414246,506.991150 885.530151,506.982605
	C885.969604,508.794891 885.886780,510.617859 886.026306,512.423706
	C886.390625,517.137573 885.081787,520.548340 879.774841,521.285706
	C878.659058,521.440613 877.589539,522.067871 877.049744,523.641907
	C875.562927,525.574097 874.119812,524.479675 872.596619,524.185303
	C870.960693,523.869202 869.440613,522.359497 867.334900,523.746460
	C866.882812,523.976318 866.702087,523.973633 866.251465,523.944458
	C862.990051,523.603943 860.420837,522.419922 858.149475,519.898438
	C857.745728,519.127869 857.608154,518.651245 857.551819,517.782959
	C857.664795,517.183777 857.761963,516.957397 858.122925,516.477173
	C863.453003,513.309265 868.132874,509.534546 874.708618,510.659302
	C876.605225,510.983704 877.854614,508.502625 879.772583,507.243011
z"
        />
        <path
          fill="#5D696E"
          opacity="1.000000"
          stroke="none"
          d="
M867.446655,524.007080
	C867.574768,521.451965 869.270203,522.036804 870.926880,522.504639
	C872.681702,523.000122 874.438843,523.487061 876.581055,523.984009
	C879.388428,526.508911 883.364197,526.178284 885.871948,529.135437
	C883.929016,529.013672 881.855835,528.533630 879.392151,527.995728
	C875.708008,525.405029 871.567200,525.297668 867.446655,524.007080
z"
        />
        <path
          fill="#1E0504"
          opacity="1.000000"
          stroke="none"
          d="
M789.890381,589.603638
	C786.937866,590.320129 783.876648,590.666077 780.388794,591.027222
	C765.503235,590.310913 751.428650,593.405457 737.272400,595.499939
	C726.677490,597.067566 715.917114,598.019348 705.422363,602.021484
	C694.903076,603.280457 684.738770,604.363525 675.174194,608.342468
	C674.138550,608.773315 672.928650,608.785156 671.404297,608.989563
	C671.666626,606.194824 674.188721,605.748840 676.308960,605.209412
	C678.429993,604.669739 680.400513,603.912781 682.245789,602.837524
	C686.760742,600.206665 686.741577,599.616516 682.709045,596.072510
	C681.612976,595.109253 680.724060,593.884094 679.851990,592.696899
	C677.530640,589.536438 674.776733,588.364746 670.846497,589.832214
	C666.776062,591.352112 662.542725,592.050415 658.243164,589.226562
	C657.983093,587.157349 659.383301,586.706665 660.638611,586.460144
	C674.824524,583.674133 689.005188,580.825684 703.934143,580.381470
	C705.503540,580.565002 706.782043,580.224304 707.803833,580.737122
	C720.374329,587.046265 733.629761,583.561462 746.456726,582.738892
	C755.103760,582.184448 763.232971,577.656006 770.703125,572.966858
	C773.474670,571.227112 776.690369,570.184753 779.403198,567.572815
	C780.217224,567.029053 780.736389,566.820984 781.706787,566.638428
	C786.938782,566.992004 791.500183,564.359741 796.679932,565.710144
	C795.679993,571.049133 792.771362,574.841125 788.039368,577.116211
	C784.606079,578.766968 780.815857,579.667480 777.763733,582.125366
	C776.805603,582.896912 775.944946,583.638367 776.278015,585.001038
	C776.603577,586.333069 777.711792,586.598022 778.865173,586.990540
	C782.418640,588.199768 786.463867,586.955444 789.890381,589.603638
z"
        />
        <path
          fill="#2A1B1B"
          opacity="1.000000"
          stroke="none"
          d="
M797.407227,565.991272
	C792.299438,566.767212 787.774719,568.762756 782.410034,567.951538
	C780.609253,565.263367 783.368896,564.794250 784.745361,563.163208
	C792.636169,560.528381 800.189880,558.230896 808.572998,559.552246
	C809.220886,560.673706 809.566406,561.491760 809.953247,562.640259
	C806.146973,564.827332 801.967407,565.342834 797.407227,565.991272
z"
        />
        <path
          fill="#534A50"
          opacity="1.000000"
          stroke="none"
          d="
M858.702148,518.841919
	C860.421082,521.748230 864.076477,520.913513 865.820007,523.626953
	C865.977478,524.101257 865.973206,524.284668 865.958923,524.743164
	C863.823914,526.633850 861.330566,527.484375 858.855286,528.330078
	C853.893066,530.025574 849.476929,532.781921 844.573853,535.346924
	C841.025146,535.686646 839.805603,532.719177 837.281738,531.260254
	C835.499878,530.153748 833.891968,529.455566 832.839539,527.888428
	C830.615234,524.576294 830.010925,527.257935 829.047485,529.266479
	C828.483521,530.337646 827.805908,530.849365 826.831848,531.474243
	C826.429443,531.763245 826.283691,531.897644 825.912598,532.224976
	C825.533386,532.542664 825.379456,532.667236 824.993774,532.978027
	C824.437683,533.407898 824.119812,533.659485 823.553650,534.108521
	C822.923767,534.527039 822.521545,534.694031 821.758362,534.851440
	C820.945435,534.850708 820.499756,534.718384 819.804688,534.268555
	C819.282654,533.598328 819.121460,533.138306 819.032715,532.278137
	C819.083130,531.662109 819.135254,531.419189 819.338013,530.836426
	C821.485474,528.309814 823.530823,526.177307 824.755493,523.397156
	C826.429749,519.596497 830.069214,518.124451 833.450012,516.455566
	C835.825012,515.283203 838.146362,514.053223 840.671387,512.415649
	C845.542480,511.944336 848.747314,515.402893 853.180786,516.468994
	C855.340881,516.823120 856.823486,517.838196 858.702148,518.841919
z"
        />
        <path
          fill="#3A4D56"
          opacity="1.000000"
          stroke="none"
          d="
M843.785156,534.298584
	C844.810303,533.856750 845.906677,533.941223 846.347290,533.444336
	C851.363464,527.786377 858.411865,526.757874 865.549683,525.013245
	C864.028015,531.265869 865.301392,537.660767 865.099304,543.986450
	C865.020935,546.440735 868.224304,545.474243 868.929565,547.675415
	C861.907104,550.728760 854.418518,551.450439 846.479004,552.102051
	C844.312195,551.054077 843.510437,549.144287 844.983276,547.989380
	C849.063477,544.790100 846.661621,541.933472 844.994568,538.995178
	C844.194336,537.584595 842.684875,536.480896 843.785156,534.298584
z"
        />
        <path
          fill="#3C5965"
          opacity="1.000000"
          stroke="none"
          d="
M843.647583,534.014771
	C845.574402,537.698425 847.809875,541.024719 849.487915,544.635010
	C850.949585,547.779663 849.656433,547.742920 848.036377,547.859619
	C845.283630,548.057922 845.094666,549.279297 845.984009,551.730225
	C835.922668,554.379333 825.972656,557.522827 815.151123,558.062134
	C814.210754,556.969727 814.571838,556.059998 815.765747,555.064209
	C817.342712,554.524475 818.490784,553.461121 819.691101,553.514648
	C823.023743,553.663330 823.807983,551.962769 823.719238,548.856689
	C826.161865,547.758240 828.427185,548.854492 830.988892,548.385925
	C836.323425,549.226074 835.948303,544.045349 838.721680,541.869629
	C840.656006,540.687378 840.467651,539.289124 839.679077,537.931824
	C838.648987,536.158752 837.114746,536.300110 835.277466,537.357422
	C834.765381,537.944275 834.471497,538.283997 833.951233,538.885498
	C832.125977,541.344604 832.450500,544.350220 830.382324,546.732788
	C828.174316,548.252380 826.288452,548.818176 824.581665,546.200867
	C824.904541,544.737305 826.016907,544.231262 826.684692,543.105774
	C825.920166,543.668396 825.652283,544.427063 824.745850,545.059875
	C820.439270,542.089294 820.669617,539.201538 825.510803,535.297852
	C826.126160,535.174011 826.444397,535.167419 826.693481,535.016724
	C825.848267,534.339783 824.850891,534.289612 823.952637,533.370667
	C823.698486,532.910156 823.651245,532.699280 823.807495,532.236084
	C824.010925,531.983765 824.497131,531.974548 824.740234,531.973816
	C825.119873,531.817810 825.256348,531.662598 825.204468,531.261230
	C825.620605,530.181824 826.509094,530.021729 827.797729,529.971802
	C831.039978,527.918762 833.935608,531.280884 837.178406,529.973755
	C841.289612,528.665405 840.340088,534.123108 843.647583,534.014771
z"
        />
        <path
          fill="#2E282E"
          opacity="1.000000"
          stroke="none"
          d="
M817.255371,555.640503
	C816.051880,556.110291 815.265503,556.434387 814.888000,557.771851
	C814.463867,558.098450 814.134888,558.101440 813.557129,558.098267
	C812.388794,557.117188 811.358459,557.193481 809.931763,557.872864
	C806.586853,558.659241 803.540649,558.708496 800.139709,558.404053
	C798.742065,556.577087 799.226746,555.012390 800.162476,553.111450
	C805.124695,550.028503 806.945374,546.202148 806.594238,540.505127
	C806.230164,534.598083 807.987244,528.755859 810.263245,522.781372
	C812.615234,520.080994 815.160889,519.092224 818.657532,520.322632
	C820.132507,521.892517 820.052185,523.524841 819.209961,524.717407
	C816.174988,529.014954 816.589539,534.138184 815.659912,538.901733
	C814.895386,542.819153 815.651001,546.761414 817.805176,550.740601
	C818.419128,552.582642 818.428955,553.990295 817.255371,555.640503
z"
        />
        <path
          fill="#574649"
          opacity="1.000000"
          stroke="none"
          d="
M799.692505,557.718628
	C803.287781,556.993469 806.674561,556.903870 810.520752,557.019531
	C811.118713,557.217896 811.275208,557.400269 811.232544,557.883301
	C810.440002,558.606995 809.846680,559.030151 809.064270,559.654541
	C800.995605,560.137634 793.489746,562.524658 785.411499,563.988831
	C784.818237,563.851685 784.626770,563.676514 784.268677,563.148315
	C783.979614,562.282532 783.958801,561.765808 784.157227,560.873230
	C785.075928,559.013855 786.523438,558.457703 788.349854,557.820312
	C790.766235,557.759827 792.378174,556.270264 794.744507,556.066040
	C796.665710,556.169312 798.167358,556.417786 799.692505,557.718628
z"
        />
        <path
          fill="#3C5965"
          opacity="1.000000"
          stroke="none"
          d="
M811.410217,558.163635
	C811.022827,557.992188 811.012329,557.800598 810.982056,557.322266
	C811.934753,556.063477 812.598938,556.409485 813.195007,557.810730
	C812.801331,558.107849 812.294189,558.125671 811.410217,558.163635
z"
        />
        <path
          fill="#875E55"
          opacity="1.000000"
          stroke="none"
          d="
M831.041504,489.289124
	C830.955383,487.878723 830.966858,486.844238 830.990845,485.423340
	C842.430908,488.147003 854.040588,490.591522 865.237610,494.551483
	C867.783142,495.451782 870.168274,496.460999 872.063843,498.422821
	C873.786560,500.205841 876.021484,501.066681 878.911682,501.666077
	C877.112671,503.653412 874.930908,502.458862 873.263428,503.786072
	C872.636719,506.594452 870.812805,505.981995 869.220947,505.759033
	C865.616150,505.254211 862.037964,504.624359 858.384644,502.854004
	C857.976196,499.166260 857.976196,499.166260 862.455017,499.893219
	C859.334167,498.417389 856.444885,498.941803 853.317383,498.540100
	C852.453796,498.397919 851.963989,498.279724 851.121338,497.988708
	C846.690796,494.862396 841.680786,494.392487 837.112610,492.740997
	C834.988281,491.973022 832.607361,491.706024 831.041504,489.289124
z"
        />
        <path
          fill="#875E55"
          opacity="1.000000"
          stroke="none"
          d="
M858.885742,518.818970
	C857.250122,518.757263 855.511292,518.424133 853.398193,518.026123
	C851.848511,516.617371 850.989685,515.039429 849.740417,513.734375
	C848.387634,512.321228 848.406738,510.832184 850.367188,509.382202
	C852.278992,508.477295 854.126892,508.100677 855.236206,506.001984
	C856.507874,503.366364 858.118958,502.700897 860.666077,503.519165
	C862.997803,504.268219 865.499878,504.468231 868.271667,505.089569
	C870.570007,507.253235 873.279602,505.413879 875.701538,506.701080
	C877.065369,506.942017 878.140625,506.970001 879.620117,507.000214
	C878.797668,510.989868 877.108948,513.868103 871.947205,512.278381
	C869.029114,511.379700 865.922119,512.414429 863.905212,514.824524
	C862.443665,516.571045 860.893799,517.193726 858.399048,516.997925
	C857.801636,516.800842 857.603027,516.601929 857.403992,516.403381
	C857.204956,516.204834 857.270874,516.271362 857.800903,516.801453
	C858.260742,517.515869 858.521606,518.031677 858.885742,518.818970
z"
        />
        <path
          fill="#9B8881"
          opacity="1.000000"
          stroke="none"
          d="
M869.452209,506.373291
	C866.068604,505.594482 863.014160,505.253204 860.088623,504.445221
	C857.882568,503.835999 857.185486,504.951355 855.945557,506.644623
	C854.041443,505.603027 852.965088,504.306610 852.452881,502.232513
	C853.919617,499.332062 855.645569,499.379028 857.822144,501.629700
	C862.937317,502.442078 867.470581,504.772736 872.734863,504.282898
	C874.256531,504.251068 875.880249,503.672699 876.154846,505.845947
	C873.971497,506.517273 871.964233,507.254456 869.452209,506.373291
z"
        />
        <path
          fill="#705250"
          opacity="1.000000"
          stroke="none"
          d="
M869.141724,506.473083
	C871.090088,506.091248 873.112488,506.046143 875.569580,506.037170
	C876.004272,506.073303 875.999146,506.494659 875.994629,506.704346
	C873.738708,506.444336 871.432556,509.530365 869.141724,506.473083
z"
        />
        <path
          fill="#543937"
          opacity="1.000000"
          stroke="none"
          d="
M686.217041,374.234253
	C685.800415,370.592651 685.911743,367.245941 688.679443,364.329407
	C688.767029,367.134247 689.195740,369.555573 693.530396,368.932251
	C694.427002,369.953491 695.139282,370.727814 696.646240,370.982422
	C697.093018,371.200470 697.217041,371.380463 697.337646,371.890442
	C697.184998,372.642426 696.966187,373.025604 696.442749,373.605957
	C695.624512,374.229340 694.993774,374.444366 693.966553,374.403503
	C692.642944,373.746826 691.637695,373.381134 690.251282,374.312195
	C688.789856,374.915985 687.643005,375.107239 686.217041,374.234253
z"
        />
        <path
          fill="#7D2C11"
          opacity="1.000000"
          stroke="none"
          d="
M729.445801,353.976990
	C731.631348,352.245728 734.474121,353.100677 737.585205,353.408508
	C735.240112,353.736298 732.566101,353.885773 729.445801,353.976990
z"
        />
        <path
          fill="#3E1E1C"
          opacity="1.000000"
          stroke="none"
          d="
M697.211365,372.314941
	C696.945740,371.999237 696.955139,371.509552 696.962036,371.265015
	C698.575256,368.647522 701.459900,367.163635 703.649048,367.294128
	C713.925049,367.906708 723.046875,361.750610 733.414246,362.776489
	C736.590515,363.090820 739.854126,360.824310 743.446594,360.804932
	C751.352478,360.762329 759.243652,360.560394 767.062012,362.003540
	C771.548584,362.831726 774.497559,365.761322 774.684937,370.065735
	C775.024414,377.861847 777.678833,385.398224 776.997742,393.268433
	C776.199646,402.490448 774.533630,411.611176 774.799316,420.965363
	C774.930481,425.583893 769.874023,429.122437 763.166321,429.855621
	C759.417969,430.265381 755.524414,430.557770 751.408447,429.085785
	C750.528503,428.197906 750.681885,427.369995 751.220093,426.174866
	C754.134033,422.930481 757.448547,422.803772 761.090515,423.369446
	C766.006531,424.132935 767.285034,422.945892 766.614319,418.108917
	C765.867188,412.721466 766.276489,407.321106 767.770081,402.297699
	C770.906860,391.747742 768.675598,381.585876 766.721069,371.343872
	C765.889526,366.986237 762.040771,365.915100 758.650452,366.163147
	C749.092590,366.862244 739.310181,365.792816 730.041321,369.162537
	C726.868103,370.316162 723.629456,370.880280 720.270447,370.794983
	C716.744202,370.705475 713.419312,371.320862 710.073303,372.607422
	C705.990234,374.177368 701.677429,374.549408 697.211365,372.314941
z"
        />
        <path
          fill="#532C24"
          opacity="1.000000"
          stroke="none"
          d="
M751.709656,426.761475
	C751.744507,427.508087 751.481628,428.007721 751.107910,428.767944
	C749.118164,431.801208 744.776367,430.041504 743.079224,433.632996
	C741.501404,433.902252 739.951355,434.290100 738.257874,433.244202
	C739.178040,428.261627 744.081787,429.303894 747.306580,427.037750
	C748.885437,426.535248 750.096191,425.973389 751.709656,426.761475
z"
        />
        <path
          fill="#532C24"
          opacity="1.000000"
          stroke="none"
          d="
M692.513184,438.012756
	C692.007385,437.330322 691.918945,436.633453 691.777771,435.589905
	C691.385681,434.792145 691.238464,434.285614 691.397644,433.335327
	C692.386475,431.596283 693.802246,430.620636 695.225464,430.849792
	C700.212097,431.652802 704.484131,429.577148 709.291504,427.976013
	C712.044373,428.752625 714.170776,429.793762 715.106934,432.646332
	C712.134277,436.339722 707.961243,436.446899 704.184753,436.851074
	C700.445129,437.251282 696.649109,437.240601 692.513184,438.012756
z"
        />
        <path
          fill="#3B3C43"
          opacity="1.000000"
          stroke="none"
          d="
M715.479919,433.030090
	C713.486511,431.856934 711.960266,430.677582 710.236694,429.246399
	C709.718018,427.077667 711.158936,426.750244 712.875977,426.295349
	C723.022583,433.151001 732.969360,430.215546 742.908325,426.888519
	C744.321289,426.415466 745.660156,425.716034 747.556946,426.450470
	C748.049805,426.879456 748.191101,427.109253 748.150024,427.700256
	C744.790649,429.524353 741.613586,430.987244 738.213501,432.717468
	C730.642639,432.997833 723.294861,433.010895 715.479919,433.030090
z"
        />
        <path
          fill="#813920"
          opacity="1.000000"
          stroke="none"
          d="
M697.937866,441.586304
	C696.599609,443.545563 694.685242,442.824127 692.567627,442.947083
	C692.153259,442.502808 692.122803,442.073761 692.141296,441.324341
	C694.048340,441.757446 696.062622,439.238007 697.937866,441.586304
z"
        />
        <path
          fill="#5D3732"
          opacity="1.000000"
          stroke="none"
          d="
M672.777466,449.366211
	C674.070374,449.008636 675.133362,449.010040 676.594971,449.013855
	C680.753052,449.978729 684.473389,451.124451 688.669006,451.227051
	C696.729736,450.890869 704.341370,452.457336 712.037720,453.082947
	C715.463867,453.361420 717.066895,458.192078 721.607849,456.990417
	C730.072876,455.106140 737.234436,459.567078 744.891052,460.600586
	C747.344177,460.931702 750.341431,462.922729 753.635803,463.840393
	C753.680298,464.780182 753.375244,465.559296 753.035522,466.651398
	C752.144348,467.885620 751.002380,468.357819 749.859497,468.034363
	C743.983643,466.371399 737.644287,466.847900 731.995911,464.230652
	C727.429871,462.114960 722.616455,461.091888 717.238586,460.577362
	C711.516235,461.130920 706.302368,461.281830 701.694824,457.851990
	C700.771912,457.164948 699.569519,456.990570 698.396729,456.978546
	C692.669067,456.919800 686.947083,456.789734 680.879639,455.788696
	C676.786987,455.162262 673.002014,454.584869 672.777466,449.366211
z"
        />
        <path
          fill="#674541"
          opacity="1.000000"
          stroke="none"
          d="
M688.727295,451.002380
	C684.722534,453.832794 681.239502,450.759155 677.256226,449.269745
	C679.137390,448.871277 681.233154,449.074341 683.634521,449.932709
	C684.712158,449.404236 685.528992,449.514313 686.667725,449.791870
	C687.133179,450.320953 687.328918,450.567993 687.795410,450.882812
	C688.002441,451.001221 688.485657,451.000671 688.727295,451.002380
z"
        />
        <path
          fill="#342A31"
          opacity="1.000000"
          stroke="none"
          d="
M685.874023,374.193848
	C687.012817,373.782410 688.085815,373.561188 689.450684,373.180664
	C690.744751,373.313812 691.398743,374.008820 692.060852,375.341797
	C691.643372,390.132477 691.356140,404.468811 691.452209,418.817047
	C691.488525,424.242462 692.130432,429.735901 690.419434,435.542450
	C690.212280,436.167694 690.163574,436.340179 690.042480,436.771606
	C686.116028,437.149200 684.001892,435.409058 683.858215,431.624451
	C683.779114,429.539734 684.018982,427.430206 684.010864,424.854828
	C684.646301,411.291962 685.000488,398.196503 685.020447,385.090851
	C685.025269,381.946075 684.676636,378.779907 685.535034,375.216522
	C685.729858,374.778870 685.808289,374.384033 685.874023,374.193848
z"
        />
        <path
          fill="#5E6B74"
          opacity="1.000000"
          stroke="none"
          d="
M690.446411,435.901489
	C689.740784,416.017700 689.906311,396.046356 690.796692,375.637390
	C691.519165,374.343201 692.430420,374.244934 693.861755,374.764587
	C694.624573,381.899780 695.013428,388.797791 694.993896,395.710571
	C694.963989,406.306580 695.270569,416.911285 693.986328,427.470032
	C693.784729,429.127930 693.671631,430.771240 692.421204,432.593109
	C692.061829,433.485168 691.985535,433.988617 691.817139,434.867676
	C691.360657,435.431427 690.996216,435.619598 690.446411,435.901489
z"
        />
        <path
          fill="#623B37"
          opacity="1.000000"
          stroke="none"
          d="
M684.649658,424.733673
	C685.384705,429.274078 683.676880,434.410736 689.617310,436.963898
	C689.996338,439.283600 690.246521,441.620819 687.390137,442.960541
	C684.702087,440.909302 682.043823,440.141632 678.870911,440.124573
	C673.743286,440.096985 669.065613,437.527161 663.623291,436.889893
	C661.662476,436.270416 660.366394,435.467651 660.711304,433.127930
	C664.178162,430.352142 667.566650,431.699371 670.874146,432.634521
	C672.782043,433.173950 674.624878,433.897491 676.638184,433.977173
	C679.315918,434.083038 681.512573,433.395905 682.726196,430.283020
	C683.007507,428.570312 683.044861,427.154480 683.129272,425.316345
	C683.527893,424.603424 683.927673,424.476898 684.649658,424.733673
z"
        />
        <path
          fill="#784534"
          opacity="1.000000"
          stroke="none"
          d="
M663.872375,436.359253
	C668.791016,435.233704 672.970398,438.260193 677.658752,438.404205
	C678.631531,438.434021 679.880615,439.235046 680.517273,438.859100
	C684.725403,436.373962 685.423035,439.972015 686.949829,442.655121
	C686.527588,443.031311 686.029419,443.069366 685.163696,443.179321
	C683.086975,444.866089 681.061462,445.563995 678.313477,445.285461
	C673.907104,443.654541 669.831726,442.230896 666.747559,438.957886
	C666.556946,438.437134 666.156860,438.264038 665.536987,438.064392
	C664.615540,437.815399 664.038147,437.450897 663.872375,436.359253
z"
        />
        <path
          fill="#BEAAA0"
          opacity="1.000000"
          stroke="none"
          d="
M677.821411,444.406067
	C680.238281,444.675934 681.992126,442.985321 684.427429,443.164001
	C684.859802,443.928345 684.923462,444.605469 684.995117,445.624908
	C684.598755,446.051178 684.194214,446.135132 683.492554,446.307953
	C682.664612,446.566864 682.163879,446.849457 681.306885,446.770599
	C680.356506,446.668213 679.681335,446.623932 678.667603,446.576355
	C677.419617,446.212860 677.326843,445.515869 677.821411,444.406067
z"
        />
        <path
          fill="#625457"
          opacity="1.000000"
          stroke="none"
          d="
M684.936157,424.466675
	C684.528198,424.932709 684.140076,424.937500 683.464111,424.918121
	C684.828857,408.547180 683.555725,392.145752 684.185364,375.299896
	C684.600525,374.828430 684.923950,374.825653 685.488525,374.796082
	C686.928528,391.210632 684.415039,407.584015 684.936157,424.466675
z"
        />
        <path
          fill="#92BFCE"
          opacity="1.000000"
          stroke="none"
          d="
M849.116455,474.343872
	C857.774597,474.364960 865.166199,479.496826 873.533997,481.398834
	C875.980652,481.954926 874.933228,482.850708 873.199219,483.647827
	C872.687439,484.367279 872.235352,484.561462 871.321167,484.196777
	C870.996277,483.957123 870.995667,484.004822 871.016968,483.994232
	C869.993408,483.118439 868.709534,483.692200 867.285095,483.183044
	C867.001953,482.958832 867.010071,483.007111 867.027649,482.990082
	C866.068970,481.984619 864.841370,481.556244 863.243774,481.172241
	C862.987854,480.962433 862.993652,481.001801 863.013245,480.995911
	C859.169800,477.315582 853.395325,477.793915 849.116455,474.343872
z"
        />
        <path
          fill="#92BFCE"
          opacity="1.000000"
          stroke="none"
          d="
M845.828491,472.784546
	C845.296631,472.937775 844.159485,473.404022 844.129822,472.454529
	C844.112122,471.884705 845.139709,472.221313 845.828491,472.784546
z"
        />
        <path
          fill="#92BFCE"
          opacity="1.000000"
          stroke="none"
          d="
M848.631714,473.111938
	C849.000061,473.172852 848.982788,473.617126 848.976074,473.839661
	C848.108521,473.895813 847.247559,473.729431 846.182129,473.298218
	C845.977539,473.033417 846.001709,472.999512 846.016235,472.983856
	C846.775024,472.995819 847.519165,473.023407 848.631714,473.111938
z"
        />
        <path
          fill="#6E96A8"
          opacity="1.000000"
          stroke="none"
          d="
M902.930542,466.577637
	C901.289368,466.913940 899.534668,466.911011 897.343140,466.843842
	C898.642517,464.043060 900.676514,464.559357 902.930542,466.577637
z"
        />
        <path
          fill="#532C24"
          opacity="1.000000"
          stroke="none"
          d="
M716.751587,459.326874
	C721.999695,458.893036 726.723083,459.912231 731.305969,462.063507
	C735.185425,463.884552 739.363098,465.333252 743.699402,464.897919
	C746.921265,464.574493 749.137146,467.284424 752.595703,466.972076
	C755.099365,468.604736 757.197815,470.245087 759.630981,471.935577
	C765.713867,473.563110 769.974976,476.700531 771.723022,482.943420
	C773.303162,484.186127 774.745117,485.085693 776.095947,486.649750
	C779.630920,490.303833 782.409058,493.960175 782.621155,498.919830
	C782.760254,502.171906 781.570251,503.749908 777.864014,501.847229
	C776.047302,499.604004 775.801575,496.992035 774.424194,494.942413
	C771.315918,490.316925 767.589294,486.518829 761.304810,485.780151
	C759.519958,484.247864 758.830811,482.088806 756.376038,481.240967
	C752.588501,478.538788 747.468811,479.546509 744.451843,475.387878
	C744.340271,471.136749 741.894653,469.955109 738.556213,469.835022
	C732.546936,469.618896 726.904236,467.750580 721.292175,465.881348
	C718.480042,464.944702 715.583252,463.641632 716.751587,459.326874
z"
        />
        <path
          fill="#372624"
          opacity="1.000000"
          stroke="none"
          d="
M807.594849,488.742798
	C808.157166,489.494141 808.359436,490.061493 808.800293,490.820526
	C809.066772,493.408630 807.081726,492.613190 806.126770,493.022888
	C813.946350,492.080902 821.417969,492.868317 826.517212,500.786072
	C826.520508,501.403259 826.469604,501.650879 826.205566,502.217163
	C823.431030,504.591949 820.713806,503.898163 817.937988,503.071381
	C812.519043,501.457367 807.368408,499.117340 801.664673,497.169067
	C800.832642,496.843719 800.396057,496.586426 799.707275,496.035797
	C798.647095,494.779266 797.387512,494.300446 796.599060,493.327545
	C795.498413,491.969208 795.248230,490.756622 797.815430,490.195007
	C798.109741,489.986206 798.509888,490.009430 798.710205,490.015808
	C799.898193,489.198975 801.057007,489.585419 802.531250,489.762024
	C803.466003,489.813202 804.027405,489.664948 804.786133,489.296448
	C805.615845,488.390533 806.435730,488.520203 807.594849,488.742798
z"
        />
        <path
          fill="#5D3732"
          opacity="1.000000"
          stroke="none"
          d="
M826.558105,502.529663
	C822.138611,496.325867 816.357910,493.867096 809.261902,493.916199
	C807.128235,493.931000 804.740723,494.640656 802.665710,492.826965
	C804.566467,491.229736 806.777588,492.576874 808.760681,491.265198
	C811.138000,491.203369 812.617981,490.219482 813.888916,488.228943
	C815.292847,486.811523 816.475403,487.253876 817.898438,488.343475
	C818.905334,489.346802 816.351624,490.970551 819.401733,491.446716
	C823.886780,492.146881 828.184753,494.046295 831.342590,497.692688
	C832.625488,499.174072 834.118225,500.577911 833.171997,503.157654
	C830.748230,504.723846 828.807068,503.639465 826.558105,502.529663
z"
        />
        <path
          fill="#6D5A5B"
          opacity="1.000000"
          stroke="none"
          d="
M775.205933,487.178101
	C773.757751,486.032288 772.392212,485.323456 772.096741,483.351746
	C778.261536,485.173767 784.441162,487.311584 790.873169,489.714722
	C791.908081,491.530426 794.993103,491.487122 793.781921,494.440491
	C787.939514,495.518890 783.692749,492.243225 779.165222,489.975616
	C777.872803,489.328308 776.638794,488.393066 775.205933,487.178101
z"
        />
        <path
          fill="#574649"
          opacity="1.000000"
          stroke="none"
          d="
M794.504150,494.468994
	C793.525085,492.157990 791.183533,492.045624 789.931396,490.613434
	C789.892029,490.568420 790.026001,490.309387 790.135071,490.214233
	C790.249084,490.114868 790.430725,490.093140 790.854126,490.008728
	C793.156250,489.982910 795.187073,489.985809 797.663818,489.987457
	C794.438660,493.614960 800.587585,492.590668 800.457336,494.935547
	C800.425903,495.395020 800.239990,495.533752 799.719971,495.772797
	C798.192200,496.056427 797.110596,495.546204 795.653687,495.210083
	C795.127075,494.975708 794.929260,494.854279 794.504150,494.468994
z"
        />
        <path
          fill="#B27252"
          opacity="1.000000"
          stroke="none"
          d="
M818.253296,489.203339
	C816.835388,488.671478 815.782776,488.279572 814.388306,487.902832
	C814.046509,487.918060 813.628113,487.834351 813.416992,487.809692
	C813.205872,487.785034 813.087036,487.353729 813.012451,487.141846
	C814.858704,485.361053 816.681030,486.475311 818.788574,487.329895
	C819.203491,487.741058 819.349976,487.957306 819.388306,488.549683
	C819.059631,489.064972 818.839050,489.204132 818.253296,489.203339
z"
        />
        <path
          fill="#574649"
          opacity="1.000000"
          stroke="none"
          d="
M802.691650,489.718262
	C801.842407,489.978668 800.780273,489.995880 799.314331,490.017639
	C799.946960,488.841980 801.157776,488.799103 802.691650,489.718262
z"
        />
        <path
          fill="#574649"
          opacity="1.000000"
          stroke="none"
          d="
M807.754089,488.705750
	C807.212097,488.953430 806.469177,488.980042 805.354858,489.041443
	C805.651062,488.058441 806.503418,487.842621 807.754089,488.705750
z"
        />
        <path
          fill="#8B9698"
          opacity="1.000000"
          stroke="none"
          d="
M761.993042,453.008026
	C763.166382,453.163483 764.826111,452.644714 764.830933,454.652344
	C764.833313,455.629486 764.282959,456.726898 763.112366,456.514618
	C761.543762,456.230164 762.131531,454.767212 762.009766,453.353271
	C762.009094,452.979767 762.006775,453.007141 761.993042,453.008026
z"
        />
        <path
          fill="#5D696E"
          opacity="1.000000"
          stroke="none"
          d="
M763.191528,464.006897
	C763.401550,463.574188 763.869934,463.286591 764.615967,462.949402
	C764.926514,463.366394 764.959290,463.832977 764.996094,464.649780
	C764.483276,464.717346 763.966614,464.434662 763.191528,464.006897
z"
        />
        <path
          fill="#688FA1"
          opacity="1.000000"
          stroke="none"
          d="
M863.179138,481.235474
	C864.378418,481.153229 866.042053,480.512146 866.984253,482.613770
	C865.640564,482.935913 864.459229,482.338348 863.179138,481.235474
z"
        />
        <path
          fill="#688FA1"
          opacity="1.000000"
          stroke="none"
          d="
M867.244995,483.225281
	C868.189148,483.117859 869.376343,483.276886 870.800903,483.709778
	C869.854919,483.819672 868.671448,483.655701 867.244995,483.225281
z"
        />
        <path
          fill="#688FA1"
          opacity="1.000000"
          stroke="none"
          d="
M871.173584,484.203522
	C871.442444,483.940247 871.888550,483.923370 872.668579,483.880188
	C873.003540,484.061981 873.004639,484.270050 873.017700,484.791565
	C872.470032,484.886597 871.910461,484.668274 871.173584,484.203522
z"
        />
        <path
          fill="#B27252"
          opacity="1.000000"
          stroke="none"
          d="
M858.142212,501.583832
	C856.416199,501.622589 854.632874,499.794342 853.152954,502.673187
	C850.776855,506.921997 847.069458,505.453308 843.332642,505.327087
	C838.973877,502.680298 834.184570,501.123749 832.094238,495.921692
	C832.080200,495.351013 832.104858,495.127747 832.244568,494.603882
	C832.557922,493.852753 833.389893,493.828949 833.321228,493.485046
	C833.218689,492.971283 832.652466,493.575134 831.982544,493.364075
	C829.275513,492.048584 825.960144,492.419434 824.733887,489.026123
	C826.738708,487.287048 828.435669,489.199829 830.588867,488.933472
	C837.241638,492.257263 844.738037,492.317169 850.963867,496.512268
	C847.700317,498.393097 844.476196,497.209717 841.237671,496.280090
	C844.770386,498.380768 848.634033,497.309174 852.647949,497.235718
	C856.853638,496.334595 860.195557,498.244507 863.867981,499.420532
	C862.106995,500.797119 859.464966,498.864380 858.142212,501.583832
z"
        />
        <path
          fill="#C99469"
          opacity="1.000000"
          stroke="none"
          d="
M824.527649,488.635681
	C826.935425,490.929474 830.132080,490.627380 832.766602,492.364563
	C833.213928,492.819366 833.392517,493.066071 833.295044,493.676758
	C833.018921,494.040710 832.939575,494.316193 832.887817,494.449890
	C827.542358,495.042297 823.078796,493.160278 819.107056,489.335876
	C819.055054,488.702576 819.058472,488.468262 819.059509,487.879395
	C820.761230,487.872620 822.465393,488.220398 824.527649,488.635681
z"
        />
        <path
          fill="#99D7E8"
          opacity="1.000000"
          stroke="none"
          d="
M883.572754,478.012115
	C878.905701,480.191650 874.796814,477.145355 870.197144,475.298737
	C874.610596,474.977997 878.844604,476.724335 883.572754,478.012115
z"
        />
        <path
          fill="#99D7E8"
          opacity="1.000000"
          stroke="none"
          d="
M869.780029,474.770630
	C869.125610,474.859192 868.253052,474.688568 867.155151,474.264343
	C867.807251,474.177704 868.684509,474.344574 869.780029,474.770630
z"
        />
        <path
          fill="#92BFCE"
          opacity="1.000000"
          stroke="none"
          d="
M877.979004,483.304108
	C878.022400,483.857697 877.936218,483.864258 877.979004,483.304108
z"
        />
        <path
          fill="#314D58"
          opacity="1.000000"
          stroke="none"
          d="
M352.006775,520.139648
	C352.433289,520.115601 352.582825,520.265991 352.831726,520.541504
	C355.025726,522.933655 357.646667,524.396057 360.717804,525.806763
	C360.991211,526.024475 360.996246,525.996765 360.985779,526.007080
	C361.413910,526.386108 361.771057,526.814636 362.006531,527.643860
	C360.333984,529.695862 358.534912,529.186157 356.701355,528.132202
	C352.033386,525.448975 347.359833,522.775513 342.339355,520.049927
	C341.605011,519.559692 341.158539,519.209229 340.291260,519.035583
	C339.253693,519.006165 338.503784,518.998962 337.379089,518.986328
	C337.218445,516.117249 336.457275,513.866638 333.378296,512.957520
	C331.727905,512.470276 330.574432,511.398193 330.032532,509.344055
	C332.835266,508.053589 334.381226,510.477661 336.727875,511.773193
	C336.989166,512.029907 337.002502,511.999176 336.987549,512.006531
	C339.623138,513.296814 342.136047,514.809082 344.751953,516.787415
	C344.988037,517.031799 345.002441,517.000000 344.987152,517.008301
	C346.409210,518.015564 348.217987,518.347534 349.764648,519.795410
	C349.992126,520.032166 350.003387,519.998230 349.987244,520.005737
	C350.491608,520.514954 351.095825,520.516968 352.006775,520.139648
z"
        />
        <path
          fill="#6494A3"
          opacity="1.000000"
          stroke="none"
          d="
M336.984314,511.682922
	C334.567535,511.785645 332.787292,510.084198 330.306763,509.111664
	C329.980469,508.990936 329.710876,508.906006 329.571930,508.931763
	C329.048157,508.844269 328.701263,508.661041 328.203766,508.170959
	C328.008911,507.945648 328.014771,508.003784 328.036072,507.983765
	C327.550690,507.640411 327.017181,507.348175 326.675781,506.495026
	C326.773193,505.791748 327.025787,505.496826 327.674316,505.172577
	C331.213470,506.769165 334.550354,508.401611 336.984314,511.682922
z"
        />
        <path
          fill="#88A6B8"
          opacity="1.000000"
          stroke="none"
          d="
M327.653351,504.884338
	C327.708008,505.342438 327.469879,505.647949 326.979858,506.107056
	C326.301178,506.464569 325.861359,506.509674 325.224792,506.077026
	C323.016907,503.592407 319.423279,503.599731 317.934540,500.347229
	C319.053680,500.368286 320.142151,500.738495 321.562897,501.223511
	C323.716949,502.469482 325.538757,503.600616 327.653351,504.884338
z"
        />
        <path
          fill="#88A6B8"
          opacity="1.000000"
          stroke="none"
          d="
M314.132935,498.297424
	C315.504822,497.689087 316.742706,498.199280 317.870789,499.711365
	C316.422211,500.475372 315.322632,499.640350 314.132935,498.297424
z"
        />
        <path
          fill="#314D58"
          opacity="1.000000"
          stroke="none"
          d="
M325.095337,506.144165
	C325.416656,505.895996 325.789917,506.007782 326.445557,506.190063
	C327.089630,506.666473 327.451324,507.072327 327.935181,507.721008
	C326.851776,507.946838 325.568420,508.084991 325.095337,506.144165
z"
        />
        <path
          fill="#314D58"
          opacity="1.000000"
          stroke="none"
          d="
M328.178345,508.194763
	C328.352478,508.130859 328.696075,508.316071 329.236328,508.729401
	C329.071259,508.786316 328.709503,508.615082 328.178345,508.194763
z"
        />
        <path
          fill="#88A6B8"
          opacity="1.000000"
          stroke="none"
          d="
M313.952393,494.947418
	C314.244537,494.309540 314.807800,493.825439 315.381897,494.308624
	C315.884613,494.731750 315.278809,495.038452 314.703552,495.401459
	C314.453827,495.499176 314.113617,495.136627 313.952393,494.947418
z"
        />
        <path
          fill="#554441"
          opacity="1.000000"
          stroke="none"
          d="
M463.256348,470.541443
	C458.657043,472.108826 454.305695,473.317902 449.403992,473.909210
	C442.145081,470.768921 434.784973,469.062592 427.620453,466.885773
	C424.507233,465.939941 421.399841,463.525818 417.336639,464.945801
	C415.519318,465.033478 414.297638,464.593231 413.259674,463.050659
	C412.804047,462.275482 412.481964,461.885406 412.019043,461.283569
	C414.859070,458.110992 418.311340,457.501343 422.339447,458.583832
	C431.426178,461.025726 440.733490,459.875671 449.951691,459.904572
	C454.342407,459.918335 457.547485,456.681244 460.718262,454.266724
	C463.105194,452.449066 465.516846,451.648956 468.589111,450.994843
	C466.811859,455.845459 466.418274,460.839508 467.511383,466.062653
	C468.130310,469.020203 465.039917,469.062439 463.256348,470.541443
z"
        />
        <path
          fill="#705250"
          opacity="1.000000"
          stroke="none"
          d="
M463.341980,470.760498
	C467.207672,468.095337 465.726776,464.016327 465.230743,460.454254
	C464.705078,456.679047 465.023376,453.608795 468.810364,451.295776
	C468.975250,450.980225 468.990051,450.983459 468.986023,450.976898
	C469.543182,450.469482 470.072845,449.934326 470.879425,449.288940
	C473.423767,449.117554 475.732788,449.101624 478.469482,449.109863
	C480.896637,452.967499 485.885040,453.420990 488.001373,457.520203
	C484.765594,458.587952 482.377319,456.120789 479.146301,455.395203
	C478.006287,454.860016 477.184937,454.466278 475.944641,454.220093
	C474.389008,453.825684 472.758087,452.945923 472.307190,454.754242
	C471.956726,456.159576 473.447052,456.950073 474.744415,457.382080
	C475.204712,457.535370 475.827545,457.664154 476.198364,457.449951
	C480.571075,454.924194 484.392334,457.985748 488.731140,458.789062
	C489.013947,458.979523 488.967133,458.998596 488.953918,458.977356
	C490.094330,460.461182 491.048737,461.456604 488.259186,462.744812
	C480.366119,466.389771 471.888397,468.093597 463.341980,470.760498
z"
        />
        <path
          fill="#554441"
          opacity="1.000000"
          stroke="none"
          d="
M470.790710,449.140808
	C470.907501,449.974884 470.535095,450.674408 469.292511,450.931702
	C469.112488,450.066040 469.434967,449.318176 470.790710,449.140808
z"
        />
        <path
          fill="#875E55"
          opacity="1.000000"
          stroke="none"
          d="
M433.615082,475.788879
	C438.232483,478.688873 443.227783,479.071838 448.421783,480.171906
	C449.574188,480.769653 449.675323,481.520935 449.186462,482.690125
	C445.900604,482.687592 442.866180,482.363739 439.410461,482.047852
	C438.585114,482.458374 438.145905,482.805420 437.296082,482.972656
	C436.566010,483.007263 436.117767,483.018158 435.333374,483.040222
	C429.831970,485.880615 424.789520,485.370667 420.813751,481.253967
	C417.265289,477.579773 413.286743,474.814575 408.871246,472.408539
	C404.505463,470.029663 404.022858,467.806885 406.732666,463.336426
	C407.277985,463.033020 407.517609,463.008362 408.117523,462.915649
	C408.477783,462.847656 408.685181,462.850647 408.784332,462.881042
	C409.039124,463.059143 409.194733,463.206848 409.565063,463.593567
	C410.528168,464.846863 410.762878,466.165131 411.928589,467.296204
	C412.900269,468.276672 413.832153,468.475616 414.847534,468.524841
	C419.624084,468.756500 422.908997,470.648468 423.782928,475.845703
	C424.147766,478.015320 425.570892,479.491638 428.262299,479.570557
	C430.766632,479.643951 431.398041,478.437866 431.714050,476.088348
	C432.359497,475.561310 432.831512,475.495239 433.615082,475.788879
z"
        />
        <path
          fill="#DABA90"
          opacity="1.000000"
          stroke="none"
          d="
M411.181854,468.308167
	C409.340424,467.710083 409.009979,466.588104 409.117249,464.856323
	C409.141052,464.038666 409.367950,463.616638 410.085205,463.143494
	C411.113190,462.969849 411.794037,462.924347 412.800537,462.772217
	C414.183411,463.120941 415.240692,463.576294 416.617126,464.159119
	C424.436371,465.493073 430.923645,468.726257 436.466797,474.372070
	C435.848938,474.993256 435.151855,475.249481 434.180237,475.761139
	C433.470886,475.987244 433.036102,475.957886 432.274445,475.952759
	C431.622040,478.393646 430.177887,479.400879 427.834839,479.108826
	C426.049011,478.886200 424.629730,478.047882 424.553528,476.053131
	C424.501251,474.684265 425.687164,473.106964 423.753876,472.155121
	C421.152802,470.874512 419.054230,468.364044 415.805145,468.675964
	C414.329498,468.817657 412.888428,469.253693 411.181854,468.308167
z"
        />
        <path
          fill="#674541"
          opacity="1.000000"
          stroke="none"
          d="
M410.044495,462.998779
	C410.181915,463.406281 409.931519,463.797150 409.369324,464.362915
	C409.036469,464.266235 409.015381,463.994690 408.938873,463.317322
	C409.141174,462.935059 409.398895,462.958618 410.044495,462.998779
z"
        />
        <path
          fill="#443A42"
          opacity="1.000000"
          stroke="none"
          d="
M434.685181,486.773682
	C435.512177,487.416321 436.114929,487.928040 436.863861,488.702148
	C439.932312,492.689850 439.947784,494.882324 436.770416,497.834717
	C434.139954,500.278839 431.557312,502.950714 427.431213,502.565613
	C426.349060,502.464600 425.650513,503.274841 424.717163,504.320862
	C418.147034,506.112976 412.207611,506.284454 406.375580,502.494720
	C401.409973,499.267944 396.160034,496.443573 391.192902,493.190247
	C389.753143,492.247253 387.472595,491.315643 388.097198,489.234344
	C388.667603,487.333710 390.954132,488.186340 392.521027,487.991852
	C393.179688,487.910095 393.836212,487.871155 394.723877,487.307800
	C394.874969,482.767090 391.339539,482.450928 388.686554,482.585052
	C384.849518,482.779053 384.303833,480.604034 384.085419,477.932465
	C383.664520,472.784088 387.128754,467.873352 392.634399,466.004395
	C395.792755,468.419159 397.314240,471.781830 399.293762,474.775879
	C400.989594,477.340942 401.668610,480.695251 406.078125,479.188782
	C407.529938,478.692749 408.182861,480.449188 408.748474,481.657654
	C410.559540,485.526886 413.471039,487.681274 417.844116,488.094727
	C420.620697,488.357239 423.321838,489.336914 426.091644,489.774139
	C428.898041,490.217163 431.793701,490.546936 433.852203,487.260925
	C434.173889,486.836487 434.317383,486.739624 434.685181,486.773682
z"
        />
        <path
          fill="#594E52"
          opacity="1.000000"
          stroke="none"
          d="
M336.893707,495.319977
	C337.119995,494.683990 337.325592,494.440430 337.796967,494.127350
	C340.066498,493.632019 342.169495,493.606842 344.233398,493.346863
	C341.670441,493.493286 339.344330,492.813293 336.905823,491.264099
	C336.467682,490.577118 336.446899,490.109833 336.766785,489.344971
	C336.987488,488.880188 336.991394,488.705505 337.000183,488.268799
	C344.215790,488.558624 351.282715,487.532532 358.744720,486.102783
	C359.557465,486.602295 359.956665,487.046387 360.514313,487.757141
	C361.330750,489.324524 365.245667,488.371307 363.296356,491.693390
	C363.166138,491.915344 365.194427,492.472321 366.524902,492.156525
	C370.363007,491.245483 374.272949,491.091766 378.494995,492.024353
	C381.647217,494.691650 384.618408,494.955048 388.065094,492.537048
	C391.003845,491.914642 392.649078,493.805603 394.495453,495.032349
	C399.932861,498.645172 405.860352,501.466187 411.311340,505.709869
	C411.673981,506.552460 411.693481,507.072815 411.404907,507.963959
	C409.963837,509.484650 408.386353,509.930939 406.362152,510.340668
	C405.113190,510.403015 404.282257,510.360504 403.050690,510.147522
	C401.134735,509.502991 399.826813,508.331696 398.075409,508.516357
	C392.345612,509.120300 386.791534,508.322327 380.977753,506.416870
	C376.656982,505.361786 373.452026,502.928101 370.057098,500.891479
	C368.008606,499.662628 365.958435,498.744293 363.145630,498.984741
	C362.379242,498.981079 361.989716,498.983490 361.308105,498.988403
	C353.783417,498.091675 346.862518,500.236847 339.405212,501.089844
	C338.830505,501.014801 338.667084,501.012360 338.503784,501.006653
	C336.543274,500.938049 334.149109,501.115326 333.450500,498.930878
	C332.612946,496.312042 336.508881,497.654999 336.893707,495.319977
z"
        />
        <path
          fill="#3E6978"
          opacity="1.000000"
          stroke="none"
          d="
M360.990234,466.247925
	C361.615936,466.128296 361.967194,466.144928 362.582214,466.160553
	C363.043243,466.218567 363.240448,466.277588 363.692993,466.511475
	C364.501282,467.351044 365.249084,467.725433 366.337311,468.191406
	C369.726776,468.677399 372.931396,468.357971 375.701538,470.191162
	C376.639343,470.811798 378.227875,471.186005 377.998779,472.447357
	C377.667084,474.273529 375.824738,473.175385 374.365295,473.735809
	C371.761017,475.794037 367.829102,475.449463 366.963867,479.579773
	C364.416168,480.825684 361.692932,480.739258 358.725159,481.480896
	C352.071381,480.717651 346.422791,478.756653 342.498779,473.047668
	C343.487793,470.475128 345.497559,471.248352 347.152039,470.964264
	C349.824738,470.505280 352.622498,470.706482 355.504913,469.356750
	C356.294556,469.039062 356.740387,468.867981 357.518341,468.639954
	C358.462952,468.434998 359.040680,468.223389 359.672729,467.500854
	C360.126770,466.949463 360.413788,466.659454 360.990234,466.247925
z"
        />
        <path
          fill="#77878D"
          opacity="1.000000"
          stroke="none"
          d="
M411.117035,506.236206
	C402.890533,502.545197 396.444153,496.627441 388.379944,492.733521
	C387.823730,492.683350 387.615845,492.740540 387.085175,492.763855
	C386.345795,492.608185 385.944672,492.447632 385.277252,492.087372
	C384.817841,491.802490 384.640228,491.678528 384.213745,491.347046
	C382.823212,489.767944 380.863617,489.563751 379.609009,487.858521
	C379.429901,486.793518 379.680542,486.197601 380.691162,485.889862
	C381.441742,485.951111 381.850952,485.933563 382.567261,485.906952
	C383.303589,485.573395 383.781860,485.464905 384.604553,485.815063
	C387.624939,489.708954 391.151642,486.000153 394.578369,487.045349
	C394.224457,490.805115 390.830170,488.039398 388.459229,490.010590
	C399.817474,495.676819 408.551575,506.583740 423.538635,504.033447
	C429.137939,501.933441 434.231232,499.649475 440.371582,501.206482
	C440.918396,501.676575 441.093445,501.909302 441.260406,502.575684
	C440.290222,504.522888 438.728912,505.103790 437.118652,505.402130
	C430.204834,506.682892 423.363312,508.391052 415.925659,508.939026
	C414.022675,508.305420 412.706116,507.423340 411.117035,506.236206
z"
        />
        <path
          fill="#617179"
          opacity="1.000000"
          stroke="none"
          d="
M380.901611,485.596741
	C380.727509,486.402527 380.422516,486.836365 380.100037,487.544006
	C375.370667,490.413879 370.117767,488.414062 365.196014,489.528168
	C363.533997,489.904388 361.589386,489.595825 359.428864,489.172241
	C359.082672,488.222351 359.088135,487.524658 359.136841,486.483765
	C363.738861,485.513916 368.284271,484.744659 372.861572,484.315369
	C375.503937,484.067596 378.397217,482.731445 380.901611,485.596741
z"
        />
        <path
          fill="#5B7D8C"
          opacity="1.000000"
          stroke="none"
          d="
M374.441223,473.946655
	C375.016571,473.431671 376.000946,472.940216 376.946716,472.468018
	C373.588715,469.814728 369.478210,470.255341 365.334717,469.353943
	C366.075836,467.317200 367.888702,467.596313 369.900269,467.420288
	C370.840576,467.368988 371.420837,467.337372 372.289368,467.267090
	C374.749481,467.969025 376.634155,469.227234 378.748840,470.750061
	C380.616638,472.138214 381.700653,473.318390 378.700684,473.905945
	C377.460541,474.148804 376.136230,473.961761 374.441223,473.946655
z"
        />
        <path
          fill="#3A4D56"
          opacity="1.000000"
          stroke="none"
          d="
M370.010559,467.086884
	C369.310547,469.413086 367.410217,468.855865 365.410034,469.082428
	C364.168549,468.914215 363.318726,468.700653 362.974060,467.372986
	C363.567993,466.194122 364.495819,466.292084 365.703552,466.166504
	C366.001099,466.003601 366.000000,466.000000 366.001221,466.000610
	C367.295013,466.064758 368.655975,465.795288 370.010559,467.086884
z"
        />
        <path
          fill="#63879A"
          opacity="1.000000"
          stroke="none"
          d="
M357.691589,481.276062
	C360.602722,480.221222 363.307617,479.742493 366.529724,479.964783
	C367.067169,480.233002 367.182709,480.518799 367.142029,480.918640
	C364.256073,481.340973 361.526337,481.649261 358.379028,481.924774
	C357.786652,481.757263 357.611847,481.622498 357.691589,481.276062
z"
        />
        <path
          fill="#3A4D56"
          opacity="1.000000"
          stroke="none"
          d="
M378.926208,470.643616
	C376.693573,470.464203 374.524933,469.685913 372.743408,467.533447
	C375.437103,466.687500 377.317444,468.146912 378.926208,470.643616
z"
        />
        <path
          fill="#617179"
          opacity="1.000000"
          stroke="none"
          d="
M384.919373,485.760315
	C384.449982,486.032990 383.999939,486.008057 383.212128,485.940491
	C383.334259,484.670319 383.821075,483.570374 384.919373,485.760315
z"
        />
        <path
          fill="#5E9BAB"
          opacity="1.000000"
          stroke="none"
          d="
M357.574585,481.044556
	C357.946136,481.064362 357.965973,481.478485 357.963715,481.685242
	C355.450531,482.355469 353.036865,481.853790 350.814850,480.783752
	C346.214264,478.568237 341.695770,476.182251 337.192383,473.497070
	C337.241211,473.127808 337.627502,473.132751 337.817200,473.096558
	C339.311188,471.961304 340.732056,471.763306 342.615570,472.817078
	C347.310669,476.470551 351.926758,479.343079 357.574585,481.044556
z"
        />
        <path
          fill="#3A4D56"
          opacity="1.000000"
          stroke="none"
          d="
M343.235657,472.839600
	C341.581848,473.115601 340.207489,473.105103 338.420044,473.077454
	C339.556763,469.751465 342.623291,470.113739 345.471588,470.009033
	C348.504852,469.897522 351.698578,470.617645 354.616516,468.098877
	C355.996338,467.992493 356.798065,468.321808 357.098724,469.655151
	C352.694733,471.853943 347.860962,470.921875 343.235657,472.839600
z"
        />
        <path
          fill="#43434B"
          opacity="1.000000"
          stroke="none"
          d="
M357.328613,469.984436
	C356.439758,469.480957 355.883789,468.974945 355.157837,468.240601
	C356.173523,465.516418 357.999847,464.772278 360.902649,466.046692
	C361.217377,466.540771 361.170044,466.969818 361.099609,467.722717
	C360.470856,468.496643 359.961609,469.052887 359.200745,469.751373
	C358.548737,469.954742 358.105133,469.968323 357.328613,469.984436
z"
        />
        <path
          fill="#43434B"
          opacity="1.000000"
          stroke="none"
          d="
M365.723969,465.882812
	C365.860260,467.973694 364.521759,467.131927 363.215149,467.057678
	C362.917664,467.051880 362.890015,466.603729 362.868011,466.381653
	C363.564362,465.054840 364.472656,465.192993 365.723969,465.882812
z"
        />
        <path
          fill="#3A3D45"
          opacity="1.000000"
          stroke="none"
          d="
M441.413147,501.822632
	C435.494629,501.699585 430.242645,503.479187 424.413086,503.990234
	C424.161621,502.525787 424.966980,500.908630 426.289490,501.241638
	C431.069031,502.445251 432.989014,498.087646 436.200500,496.393433
	C438.841095,495.000366 437.995178,492.137238 437.058929,489.346863
	C443.248718,488.999878 449.569641,489.731171 455.743103,486.569397
	C458.317169,483.047119 462.160461,484.867279 465.120209,483.660126
	C466.406219,483.135559 468.050232,483.358765 469.886597,482.901794
	C474.237366,483.294250 478.039734,484.485962 482.063293,484.857635
	C486.385803,485.256927 491.243011,487.265839 495.804108,488.763000
	C496.981995,489.149658 496.936890,490.636536 496.419586,492.179871
	C492.058960,495.839935 486.853180,495.624451 481.616150,496.763733
	C471.569214,498.534698 461.761566,498.288483 452.067749,499.380737
	C449.081268,499.717224 446.141785,499.894928 442.983276,501.284302
	C442.339905,501.543091 442.080505,501.641602 441.413147,501.822632
z"
        />
        <path
          fill="#77878D"
          opacity="1.000000"
          stroke="none"
          d="
M471.400269,484.121948
	C466.328888,484.843048 461.610565,485.503326 456.461365,486.259216
	C454.607361,483.914642 451.935974,483.928467 449.307678,483.068176
	C448.926331,482.307465 448.917572,481.603546 448.864563,480.552124
	C450.334198,480.167633 451.848083,480.130585 453.812012,480.135254
	C455.951385,480.863647 457.697968,481.132294 459.847626,480.926758
	C462.086853,481.611572 464.014343,481.779663 466.283905,481.107391
	C467.015656,480.869781 467.443481,480.768555 468.201569,480.640198
	C469.182098,480.526764 469.819702,480.388489 470.743530,480.025024
	C471.487274,479.791168 471.922272,479.690308 472.698425,479.573486
	C474.588715,479.165070 476.094513,478.651031 477.889893,477.880798
	C492.314697,473.589203 505.084015,466.613464 517.690308,459.269318
	C520.957153,457.366119 523.991943,454.927216 528.264404,454.396118
	C528.839294,454.616486 529.046814,454.757690 529.412109,455.241394
	C529.747925,456.671112 529.281067,457.536072 528.362671,458.600769
	C527.535339,459.330109 526.904480,459.710449 526.069824,460.424744
	C525.588989,460.794373 525.388306,460.931152 524.866577,461.237549
	C515.800659,465.987305 507.528442,471.433960 498.503845,475.510101
	C495.644989,476.801392 492.703522,477.826111 489.437958,479.046234
	C485.895935,480.270538 482.625519,481.157410 479.093018,482.407440
	C476.521240,483.502655 474.074738,483.500977 471.400269,484.121948
z"
        />
        <path
          fill="#674541"
          opacity="1.000000"
          stroke="none"
          d="
M406.646545,463.051239
	C405.024353,467.446075 406.343292,470.008240 410.936340,471.752197
	C415.042023,473.311127 418.717743,475.668671 421.338928,479.657318
	C424.382202,484.288116 429.568207,482.867035 434.546570,483.031982
	C431.797150,487.503052 428.673462,488.270264 423.563385,485.777893
	C418.921478,483.513916 415.435822,479.691223 411.376343,476.640930
	C409.132660,474.955017 406.825287,473.353271 404.533447,471.732178
	C402.534027,470.317902 400.942474,468.434052 401.206787,465.988007
	C401.509796,463.183533 404.278992,463.562164 406.646545,463.051239
z"
        />
        <path
          fill="#674541"
          opacity="1.000000"
          stroke="none"
          d="
M437.190094,483.198975
	C437.478577,482.688873 437.942871,482.381409 438.698181,482.064880
	C438.798126,482.926605 438.741882,483.960022 437.190094,483.198975
z"
        />
        <path
          fill="#674541"
          opacity="1.000000"
          stroke="none"
          d="
M434.824768,486.782440
	C434.909454,486.904633 434.469727,486.928955 434.250061,486.931152
	C434.199097,486.604858 434.435669,486.513824 434.824768,486.782440
z"
        />
        <path
          fill="#465962"
          opacity="1.000000"
          stroke="none"
          d="
M516.041138,449.899353
	C520.831055,447.622650 524.958496,444.807678 528.921143,441.280273
	C528.995117,440.944153 529.001343,440.990326 528.982178,440.977478
	C529.699158,440.323608 530.398804,439.613861 531.824524,439.758667
	C533.679443,442.415680 531.057739,443.784454 530.443420,446.085327
	C529.770935,448.415131 528.184509,449.430542 526.242065,450.559631
	C525.030029,451.305145 523.922119,451.574097 522.608643,452.726868
	C518.822327,452.424377 516.698181,454.763275 514.497314,456.238983
	C497.787628,467.442993 479.625336,474.765076 459.661621,477.516449
	C456.262054,477.984985 452.871796,478.205566 448.527771,477.493256
	C451.812622,474.685730 455.102234,474.844086 457.972015,474.417175
	C471.143494,472.457733 483.018463,466.802795 495.118927,461.792084
	C496.574036,461.189575 497.673431,459.728088 498.968933,458.310242
	C499.000183,457.955780 499.000977,457.999390 498.981384,457.989197
	C499.362885,457.633484 499.826538,457.489868 500.643921,457.788422
	C505.137817,456.942017 508.115173,452.801605 513.078857,453.315643
	C513.895691,453.400238 514.942444,451.265076 516.041138,449.899353
z"
        />
        <path
          fill="#3E505A"
          opacity="1.000000"
          stroke="none"
          d="
M473.216614,480.554138
	C472.509125,480.822662 472.079895,480.883362 471.325073,480.945007
	C470.332001,480.772278 469.713470,480.490997 468.900391,479.858948
	C468.555084,479.410736 468.453461,479.205353 468.388916,478.658295
	C483.900879,474.815460 498.626190,469.487335 511.870209,460.531860
	C515.256470,458.242126 518.675293,456.000397 522.054077,453.370636
	C522.029053,453.004669 521.995605,453.000916 522.002075,453.016846
	C522.636536,452.318237 523.411133,451.795654 524.556396,451.192566
	C527.150818,450.293610 528.923157,448.555023 531.571716,447.781494
	C532.922974,447.691559 533.849365,447.895050 534.904297,448.752319
	C535.721558,450.277466 534.967712,451.229401 534.220215,452.503540
	C533.898926,452.947327 533.755981,453.111694 533.369446,453.487671
	C532.617188,454.073212 532.049561,454.344635 531.254639,454.827301
	C530.839172,455.120209 530.663757,455.224030 530.196655,455.416077
	C529.704041,455.481964 529.503113,455.459686 529.130005,455.187897
	C521.239624,459.132721 513.538940,463.359772 505.798462,467.512665
	C497.143799,472.155975 488.249664,476.248840 478.371552,478.939209
	C476.333038,478.916199 474.740540,479.065216 473.216614,480.554138
z"
        />
        <path
          fill="#875E55"
          opacity="1.000000"
          stroke="none"
          d="
M436.955475,474.767853
	C430.151489,471.426392 423.757019,468.115784 417.149384,464.545898
	C418.097046,462.540039 420.056610,462.630188 421.410400,463.240265
	C426.466797,465.518890 432.280945,465.238617 437.138275,468.312317
	C439.289124,469.673431 442.092194,470.081146 444.785645,470.038452
	C446.973389,470.003784 448.785522,470.475494 448.987091,473.527466
	C445.249115,475.855011 441.265717,474.783600 436.955475,474.767853
z"
        />
        <path
          fill="#3E505A"
          opacity="1.000000"
          stroke="none"
          d="
M469.178619,481.433960
	C468.480743,481.763092 468.049591,481.812225 467.292786,481.860718
	C464.857819,482.590973 462.617523,481.669067 460.210968,482.229126
	C458.037811,482.111847 456.287720,481.672791 454.480530,480.412598
	C458.714996,479.573334 463.167999,478.969666 468.023499,478.341309
	C468.425964,478.316589 468.728210,478.657196 468.870209,478.835144
	C469.571716,479.642731 469.710114,480.357574 469.178619,481.433960
z"
        />
        <path
          fill="#496A76"
          opacity="1.000000"
          stroke="none"
          d="
M493.179749,459.417847
	C493.452057,458.847656 493.930573,458.462189 494.729126,458.035522
	C494.992615,459.045227 494.713379,459.865875 493.179749,459.417847
z"
        />
        <path
          fill="#9FA2B2"
          opacity="1.000000"
          stroke="none"
          d="
M500.955078,457.632507
	C500.486877,458.103607 500.038605,458.178680 499.276062,458.116364
	C499.415863,457.550323 499.869965,457.121643 500.627686,456.568817
	C500.945892,456.708588 500.960419,456.972534 500.955078,457.632507
z"
        />
        <path
          fill="#C99469"
          opacity="1.000000"
          stroke="none"
          d="
M478.453827,454.106812
	C481.630432,455.316711 484.475311,456.564697 487.676239,457.847900
	C488.299988,458.088562 488.567688,458.294006 488.924652,458.739502
	C484.591949,458.512634 480.329437,457.064484 475.676666,458.680664
	C473.191895,459.543762 470.516083,457.267029 470.433228,454.666229
	C470.328217,451.368073 473.279968,451.794769 475.935120,452.278870
	C476.285675,453.709351 476.618713,454.589478 478.453827,454.106812
z"
        />
        <path
          fill="#3C5965"
          opacity="1.000000"
          stroke="none"
          d="
M320.741089,477.808411
	C319.806854,479.345978 318.241241,478.902985 316.423889,479.016296
	C315.286560,478.435181 313.801819,477.676361 313.893127,477.297241
	C314.243256,475.843719 315.557465,476.058502 316.722290,476.402222
	C317.983551,476.774323 319.239319,477.164886 320.741089,477.808411
z"
        />
        <path
          fill="#964F38"
          opacity="1.000000"
          stroke="none"
          d="
M717.250977,622.159729
	C715.916260,621.307983 714.781128,620.697449 713.318298,620.052185
	C713.200195,614.743103 717.396423,612.653198 721.120911,610.648376
	C726.344055,607.836792 732.080872,606.272705 737.878296,604.791443
	C749.634094,601.787781 761.350891,601.820801 773.602783,603.903748
	C778.529846,607.854309 778.749695,609.328979 774.790894,612.051880
	C770.205872,610.681519 765.949829,610.415649 761.615845,611.051025
	C760.130127,611.268799 758.577332,611.418030 757.163879,610.960999
	C749.096924,608.352905 741.849426,611.056335 734.577942,614.132690
	C731.807434,615.304871 730.143433,617.511597 728.031738,619.888000
	C727.014404,620.846191 726.077881,621.145264 724.765381,621.428345
	C723.966187,621.506714 723.516052,621.560791 722.754517,621.729980
	C722.228394,621.859741 722.016602,621.898987 721.485718,621.962463
	C720.533386,622.012451 719.905640,622.074219 718.999023,622.363770
	C718.280273,622.481873 717.864563,622.465576 717.250977,622.159729
z"
        />
        <path
          fill="#87462F"
          opacity="1.000000"
          stroke="none"
          d="
M774.263672,604.657104
	C771.032166,604.877808 768.081543,604.661255 765.118286,604.363770
	C751.776062,603.024353 738.914551,604.779724 726.359802,610.058899
	C721.065491,612.285217 717.388062,616.014160 713.074036,619.672119
	C710.104431,621.158264 707.163574,622.652588 705.041809,618.392090
	C705.510803,617.881226 706.141968,617.922302 706.450378,617.626709
	C716.404053,608.084900 728.984192,604.944336 742.044983,602.976746
	C743.075317,602.821411 743.960083,602.497314 744.807617,601.333435
	C745.411804,600.520569 745.906189,600.149170 746.942139,599.782959
	C755.370239,599.432556 763.346375,599.397827 771.769409,599.801636
	C773.113281,600.253052 773.774597,600.863464 774.428589,602.103516
	C774.737732,603.118958 774.711975,603.711304 774.263672,604.657104
z"
        />
        <path
          fill="#9B8881"
          opacity="1.000000"
          stroke="none"
          d="
M722.979248,620.175903
	C724.197998,620.051697 725.214600,620.047729 726.611816,620.016663
	C727.916016,619.174988 728.979065,618.707214 730.664001,618.695801
	C737.646423,619.006165 744.184387,618.115906 751.141846,618.731079
	C755.194763,620.755676 758.598328,623.134888 762.659302,624.246460
	C764.277405,624.689392 765.776001,625.586426 765.113770,628.098999
	C762.541870,629.741150 760.301819,628.915466 758.224060,627.942993
	C754.569031,626.232239 750.755249,625.315369 746.771362,625.552063
	C743.786926,625.729370 741.428101,624.053406 738.365417,623.228149
	C738.003174,623.006348 738.000000,623.000000 737.996460,622.999878
	C737.835083,622.870361 737.677368,622.740967 737.286499,622.809937
	C730.621826,622.996338 724.190125,622.984314 717.269897,622.929993
	C716.833923,622.728577 716.886536,622.569519 716.995239,622.164429
	C717.510498,621.899719 717.969604,621.881042 718.768494,621.796631
	C719.594910,621.515198 720.138428,621.514282 720.838867,621.163086
	C721.213501,620.989197 721.382202,620.979980 721.801392,620.932373
	C722.278076,620.675842 722.519836,620.476562 722.979248,620.175903
z"
        />
        <path
          fill="#A8A6A9"
          opacity="1.000000"
          stroke="none"
          d="
M738.149536,623.288879
	C746.873596,623.877502 755.797485,624.466003 764.557373,627.960876
	C766.139648,626.671997 769.781128,627.218140 768.258972,623.427368
	C770.926880,620.890930 774.010132,620.972778 777.047546,621.078613
	C778.336548,621.123413 779.603333,621.516846 780.995056,620.242310
	C782.278076,619.697144 783.260742,619.594360 784.245361,619.710327
	C786.480408,619.973328 788.814636,620.693420 788.980652,623.172852
	C789.154602,625.771973 786.694153,625.820190 784.740051,626.163818
	C783.713745,626.344299 782.538147,626.245117 781.930542,626.740479
	C783.572388,626.677368 785.764099,625.039368 786.470520,628.865356
	C783.442627,633.149902 778.930664,632.021729 775.035461,632.856567
	C765.565369,634.886108 755.960815,636.164673 746.069214,637.874146
	C745.600159,636.240967 745.106323,635.497253 743.323730,635.965820
	C742.242798,636.250000 741.202820,635.519104 740.236572,634.304199
	C738.765015,633.654297 737.518433,633.267944 736.247314,632.996399
	C735.423462,632.820251 734.208679,632.562195 734.672119,631.672607
	C737.537170,626.173523 732.737427,628.414246 730.953430,628.083679
	C728.230469,627.579224 725.370300,628.466980 722.324768,627.286499
	C724.261414,625.688965 726.726929,625.462280 729.618286,625.773071
	C733.637756,627.584717 737.211548,626.537720 740.859680,625.656250
	C739.971252,624.933777 739.133606,624.252563 738.149536,623.288879
z"
        />
        <path
          fill="#847876"
          opacity="1.000000"
          stroke="none"
          d="
M744.576904,637.024780
	C758.418213,634.576538 771.875549,631.995972 785.684692,629.258179
	C786.703979,628.392578 787.506653,628.271667 788.731201,628.836182
	C789.673401,629.838928 790.286926,630.561707 790.945190,631.566406
	C790.442078,632.065369 789.894226,632.282471 789.161865,632.732056
	C773.114319,634.326416 757.493958,637.169739 741.921997,640.408630
	C738.635254,641.092285 735.301758,643.043701 731.397705,641.133972
	C731.247070,640.698975 731.443359,640.431702 731.847900,640.023682
	C735.735535,637.399719 739.790100,636.439514 744.576904,637.024780
z"
        />
        <path
          fill="#929EAC"
          opacity="1.000000"
          stroke="none"
          d="
M745.102417,636.903564
	C741.123047,638.973450 736.791504,638.534424 732.445862,639.846924
	C731.980469,637.843262 731.676208,635.825195 735.024414,636.720215
	C736.838196,637.205017 738.421875,635.975830 739.848389,634.294495
	C741.742432,634.340637 743.477844,634.629822 745.179077,635.055176
	C746.772461,635.453552 745.509521,636.101990 745.102417,636.903564
z"
        />
        <path
          fill="#77878D"
          opacity="1.000000"
          stroke="none"
          d="
M729.691101,625.714050
	C727.593811,626.319397 725.208862,626.653931 722.419800,627.000610
	C719.856628,627.009094 717.423828,626.746582 718.961548,630.649780
	C717.572510,630.592407 716.107910,630.270020 714.830261,629.594360
	C714.254822,629.290161 713.816223,628.306458 713.738281,627.585327
	C713.633606,626.617310 714.639893,626.043152 715.369080,626.145996
	C720.104370,626.813782 724.649231,623.976257 729.691101,625.714050
z"
        />
        <path
          fill="#77878D"
          opacity="1.000000"
          stroke="none"
          d="
M719.042419,630.957153
	C719.707458,631.122742 720.864319,630.600769 720.854431,631.579041
	C720.848877,632.136841 719.850281,631.813354 719.143311,631.266113
	C718.960876,631.058838 719.013672,630.989807 719.042419,630.957153
z"
        />
        <path
          fill="#77878D"
          opacity="1.000000"
          stroke="none"
          d="
M711.203979,625.619995
	C710.991577,625.661987 710.870239,625.607788 710.748901,625.553589
	C710.930908,625.543701 711.112976,625.533752 711.203979,625.619995
z"
        />
        <path
          fill="#A8A6A9"
          opacity="1.000000"
          stroke="none"
          d="
M737.288391,623.012085
	C737.311157,622.899902 737.568909,622.791443 737.909729,622.841370
	C737.992859,622.999756 737.523315,623.015869 737.288391,623.012085
z"
        />
        <path
          fill="#241312"
          opacity="1.000000"
          stroke="none"
          d="
M650.380859,616.986938
	C650.689636,614.068420 653.605774,615.228027 655.646057,614.057617
	C655.311951,616.848511 653.018127,616.883057 650.380859,616.986938
z"
        />
        <path
          fill="#2A1B1B"
          opacity="1.000000"
          stroke="none"
          d="
M636.157410,620.208191
	C636.441895,619.377747 636.960632,619.062500 637.844604,619.792236
	C637.560120,620.621216 637.042053,620.940125 636.157410,620.208191
z"
        />
        <path
          fill="#632B1D"
          opacity="1.000000"
          stroke="none"
          d="
M747.351440,600.735962
	C746.545532,600.996033 746.105774,600.996887 745.336304,601.004395
	C741.624817,601.008423 738.242981,601.005920 734.417114,600.983948
	C734.719177,598.119202 737.480286,598.442078 739.265198,598.282715
	C751.528015,597.188110 763.659058,594.550354 776.518188,594.988159
	C781.388489,594.919556 785.637939,595.418518 789.380127,598.773804
	C789.676758,599.723999 789.631897,600.283691 789.172363,601.178467
	C784.196472,604.229126 779.823608,601.802429 774.935303,600.537231
	C765.573547,599.766663 756.644958,599.651367 747.351440,600.735962
z"
        />
        <path
          fill="#3E2423"
          opacity="1.000000"
          stroke="none"
          d="
M789.310608,599.239136
	C785.355225,597.030518 780.952942,597.810730 777.223206,595.262939
	C777.151123,593.608398 777.978821,593.013428 779.661560,593.013733
	C784.521118,593.240967 789.174194,592.166931 793.730957,594.733643
	C793.664062,596.092590 793.328186,597.182739 792.988098,598.619629
	C792.020813,599.997681 790.900757,600.053589 789.310608,599.239136
z"
        />
        <path
          fill="#15313E"
          opacity="1.000000"
          stroke="none"
          d="
M517.366943,603.254272
	C501.906921,601.434509 490.528046,591.823303 478.157471,584.599854
	C442.984833,564.061829 408.073608,543.075806 373.087219,522.219666
	C371.453766,521.245911 369.362732,520.523499 369.139221,517.745422
	C369.338440,517.064331 369.489014,516.770447 370.081909,516.253662
	C370.524261,516.030823 370.545654,516.149353 370.605774,516.146423
	C374.106995,513.560303 378.289520,514.272217 382.539886,513.741211
	C383.482422,513.863037 384.036438,513.802612 384.799805,513.551514
	C391.394562,510.020111 398.475555,510.834351 405.667114,509.919617
	C407.273895,509.567291 408.454498,509.126831 410.044708,508.872864
	C410.813171,508.629303 411.241119,508.473053 411.992249,508.340210
	C412.813171,508.310944 413.061554,509.369415 414.001099,508.561523
	C414.510010,508.465973 414.724426,508.484009 415.260986,508.565002
	C416.008118,508.783600 416.408936,508.987640 417.079102,509.428467
	C424.734070,513.440979 432.395966,511.224731 439.953430,510.636414
	C441.703461,510.500214 443.382385,509.504395 445.553131,510.237366
	C447.187622,511.501282 447.653656,513.268555 446.714417,514.342590
	C444.369202,517.024414 445.374329,518.558777 447.836304,520.197266
	C448.728943,520.791382 449.482788,521.834778 448.859131,523.416992
	C445.446228,524.319946 442.251129,524.538818 439.739807,524.503967
	C441.082855,524.443909 443.147095,524.636658 445.530701,524.238281
	C447.683136,526.014954 449.722656,526.505554 452.173584,525.922913
	C454.750397,525.310425 457.407806,525.108521 460.051514,525.520691
	C461.529663,525.751099 463.290924,526.075928 463.309265,527.935303
	C463.329651,529.996460 461.511353,529.930908 460.013641,529.985168
	C458.528503,530.038940 457.039673,529.945618 455.869141,530.056091
	C458.245056,530.215942 460.977997,529.489014 463.850464,530.760132
	C463.590454,534.520203 461.101227,533.933289 458.854309,533.686340
	C459.630493,534.149048 460.697632,533.895081 461.917053,534.475220
	C462.357300,534.869202 462.498535,535.066589 462.752869,535.614258
	C462.876617,536.210938 462.887299,536.457397 462.814423,537.061768
	C462.605774,537.626709 462.480713,537.833618 462.046387,538.221130
	C459.311890,538.927002 456.861908,539.146484 455.089050,539.018433
	C458.371124,539.947327 462.054779,537.429382 466.322815,538.134277
	C466.808594,538.456604 466.979736,538.618713 467.251160,539.114746
	C465.851227,540.885864 467.514130,540.568604 468.291138,541.161072
	C468.563263,543.109009 467.669220,543.716187 465.839233,543.597229
	C461.971130,541.340759 458.128235,542.662476 454.927856,542.491333
	C457.500854,542.390076 460.576508,542.220703 463.985046,542.540161
	C466.596436,545.174988 470.018677,544.204468 473.114685,545.371948
	C475.104187,545.579163 476.618561,546.236694 478.500488,546.871460
	C479.024567,547.135498 479.225800,547.255249 479.704010,547.586060
	C481.100098,549.053528 482.579224,549.558594 484.507263,549.916626
	C488.605194,551.047424 492.427521,549.962952 496.219727,550.296143
	C497.868683,550.441040 499.546600,550.292419 501.153076,550.816833
	C501.950623,551.077148 502.868896,551.430054 502.946472,552.315430
	C503.058380,553.592834 501.893341,553.793152 500.956940,554.111206
	C500.160461,554.381714 499.345673,554.463928 498.510956,554.499268
	C493.195587,554.724426 487.726959,553.386475 483.950500,555.738586
	C490.929993,553.302490 499.543243,554.623230 507.999786,554.082336
	C509.971130,553.956360 509.960327,552.963562 510.536743,551.502014
	C513.752319,549.951416 516.125854,551.336853 518.780823,553.103516
	C519.212219,553.637634 519.343506,553.882263 519.516479,554.538452
	C519.470398,555.208740 519.382751,555.467468 519.020813,556.026855
	C514.979858,558.818542 510.455170,557.160645 506.208618,558.827637
	C504.681610,560.213684 503.164917,559.974548 501.353027,559.863281
	C500.720062,559.669800 500.492615,559.360779 500.230133,559.295532
	C499.886536,559.210083 500.275879,559.469543 500.091858,559.848572
	C498.227142,561.577698 495.647339,561.627808 494.337555,560.751099
	C491.205597,558.654785 488.089050,559.508728 484.906860,559.592529
	C484.241211,559.629150 485.006348,560.313049 484.786041,559.683838
	C484.749695,559.580017 484.903168,559.493591 485.242462,559.509705
	C486.908722,559.588623 488.550507,559.349243 490.202637,559.201538
	C491.066406,559.124329 492.066406,559.585754 491.955048,560.502747
	C491.787903,561.879028 490.462158,561.353516 489.016785,561.573914
	C494.982788,560.886780 500.502167,561.042053 506.013550,558.994812
	C510.217285,557.458252 514.341858,557.801270 518.385437,557.424744
	C519.809998,557.292114 519.326904,556.679504 519.250610,555.730042
	C520.836182,554.438171 522.369263,554.552856 523.859497,554.538330
	C530.826782,554.470337 537.822449,555.009277 545.136353,553.530640
	C546.624634,553.528870 547.698059,553.544006 549.143250,553.656982
	C551.420837,554.241638 553.242859,555.020447 555.108765,555.673157
	C556.029968,555.995361 557.119019,556.473511 556.919067,557.675903
	C556.734863,558.784180 555.627808,559.115479 554.646362,559.249146
	C551.656311,559.656250 548.756958,558.623413 545.777344,558.609985
	C544.160339,558.602661 543.673584,559.001526 544.452881,560.858154
	C544.229309,562.005005 543.684570,562.462402 542.555176,562.749329
	C536.065063,560.764282 530.019836,559.315430 523.446472,561.307190
	C520.259338,562.272888 516.662170,562.365356 513.055176,561.696167
	C514.431824,562.154297 516.046997,561.997314 516.587219,564.016357
	C516.291992,566.231873 515.049927,567.083862 513.277466,567.331299
	C513.121338,567.353088 512.913086,567.264771 512.807983,567.339111
	C512.498840,567.557739 512.611572,567.652954 513.373413,567.631958
	C515.603210,569.129761 517.938538,569.475647 520.177612,570.075134
	C526.252441,571.701538 526.682190,572.808899 522.830139,578.769043
	C528.099426,579.388000 533.247009,580.045349 536.692261,584.921875
	C537.802002,590.342651 537.679688,595.496033 537.550781,601.002319
	C534.202576,604.152283 530.064392,602.903687 526.105347,604.194336
	C522.991089,604.353394 520.248901,605.035400 517.366943,603.254272
z"
        />
        <path
          fill="#64777D"
          opacity="1.000000"
          stroke="none"
          d="
M363.064331,499.210022
	C365.110168,496.851807 367.357239,498.180634 369.493530,499.293549
	C372.803406,501.017853 376.023987,502.913513 379.596893,504.875366
	C381.434357,506.266632 383.893433,505.787201 384.998322,508.400513
	C379.132385,509.159271 375.093323,505.100311 370.446533,502.566498
	C368.138947,501.308167 365.862823,500.169617 363.064331,499.210022
z"
        />
        <path
          fill="#64777D"
          opacity="1.000000"
          stroke="none"
          d="
M407.339172,510.114197
	C400.349915,512.347961 393.084259,512.466858 385.452423,513.278931
	C386.807465,512.143005 387.023376,510.898560 385.024902,509.321838
	C384.977631,509.026154 385.019501,508.969574 385.039948,508.940979
	C390.795990,507.513550 396.595917,509.256073 402.680664,508.779175
	C403.762543,508.996002 404.509613,508.992035 405.630188,508.995789
	C406.396240,509.308746 406.824951,509.551605 407.339172,510.114197
z"
        />
        <path
          fill="#535C64"
          opacity="1.000000"
          stroke="none"
          d="
M339.261719,501.271118
	C346.018341,499.233612 352.985107,496.968750 360.708862,498.717285
	C353.911072,500.320557 346.748108,501.157654 339.261719,501.271118
z"
        />
        <path
          fill="#64777D"
          opacity="1.000000"
          stroke="none"
          d="
M382.641846,513.666870
	C379.135620,514.644653 375.342834,515.365967 371.108002,516.115356
	C373.946259,512.597351 378.116547,512.857422 382.641846,513.666870
z"
        />
        <path
          fill="#535C64"
          opacity="1.000000"
          stroke="none"
          d="
M338.930969,504.213684
	C341.007996,504.041748 342.786560,503.677551 344.756958,504.790375
	C342.109375,508.090607 339.508575,505.682281 337.065338,505.449585
	C337.572357,505.069458 338.079346,504.689331 338.930969,504.213684
z"
        />
        <path
          fill="#6494A3"
          opacity="1.000000"
          stroke="none"
          d="
M344.995605,516.678955
	C341.870605,516.504028 339.637329,514.403992 337.089355,512.334839
	C340.414917,512.025696 342.901031,513.818359 344.995605,516.678955
z"
        />
        <path
          fill="#5B7D8C"
          opacity="1.000000"
          stroke="none"
          d="
M360.847046,525.717468
	C357.460693,525.971558 354.957611,524.109558 352.925598,521.049316
	C355.795898,521.801758 358.418213,523.330078 360.847046,525.717468
z"
        />
        <path
          fill="#6494A3"
          opacity="1.000000"
          stroke="none"
          d="
M349.978363,519.673218
	C348.061829,519.922241 346.608704,518.873047 345.113892,517.321411
	C347.153351,516.658508 348.779388,517.507507 349.978363,519.673218
z"
        />
        <path
          fill="#465962"
          opacity="1.000000"
          stroke="none"
          d="
M340.185486,519.239502
	C340.894409,518.030273 341.508972,518.271790 341.951843,519.723022
	C341.449280,519.823059 340.908356,519.644348 340.185486,519.239502
z"
        />
        <path
          fill="#5B7D8C"
          opacity="1.000000"
          stroke="none"
          d="
M362.261902,527.750549
	C361.695587,527.482422 361.340424,527.041748 360.980286,526.309204
	C361.858917,526.168701 362.977722,526.094299 362.261902,527.750549
z"
        />
        <path
          fill="#6494A3"
          opacity="1.000000"
          stroke="none"
          d="
M351.891693,519.946533
	C351.807159,520.847717 351.152802,521.082520 350.124084,520.277344
	C350.482452,519.982910 350.993774,519.952698 351.891693,519.946533
z"
        />
        <path
          fill="#64777D"
          opacity="1.000000"
          stroke="none"
          d="
M370.176392,516.107422
	C370.352386,516.302002 370.180511,516.573242 369.598267,517.101318
	C369.187897,517.358154 369.089294,517.336731 369.047485,517.312561
	C369.279938,516.920288 369.554230,516.552185 370.176392,516.107422
z"
        />
        <path
          fill="#535C64"
          opacity="1.000000"
          stroke="none"
          d="
M328.471375,500.517151
	C328.453430,500.481384 328.489319,500.552948 328.471375,500.517151
z"
        />
        <path
          fill="#2B3D45"
          opacity="1.000000"
          stroke="none"
          d="
M512.287231,608.354004
	C512.338318,607.043030 512.686951,605.985046 513.041443,604.591492
	C514.099182,604.198242 515.151062,604.140625 516.593628,604.025513
	C519.542175,602.977356 522.205566,603.752930 525.243408,603.483093
	C527.361389,602.903015 529.093262,602.987671 531.247437,603.143738
	C534.463013,606.464966 534.276245,606.948853 529.300659,608.717163
	C523.448792,611.234009 518.030334,609.296692 512.287231,608.354004
z"
        />
        <path
          fill="#88A6B8"
          opacity="1.000000"
          stroke="none"
          d="
M526.074219,603.685913
	C523.132385,603.888916 520.495422,604.012024 517.421387,604.051514
	C516.993408,603.799927 517.002441,603.631958 517.016418,603.211609
	C519.700500,603.025024 522.379761,603.090759 525.505737,603.149170
	C526.094788,603.296692 526.237000,603.451416 526.074219,603.685913
z"
        />
        <path
          fill="#282025"
          opacity="1.000000"
          stroke="none"
          d="
M529.399902,608.975281
	C528.393005,606.243103 533.748169,608.063904 532.156494,604.348083
	C537.765991,600.528259 544.436401,600.686584 550.700623,599.766052
	C560.014465,598.397522 569.269714,596.678711 578.587769,595.347534
	C588.554810,593.923523 598.521301,592.388306 608.388184,590.432312
	C615.677734,588.987244 623.185486,588.516785 630.626465,585.758301
	C631.273987,585.470398 631.540955,585.373474 632.229858,585.223022
	C634.041748,585.305542 635.418640,585.598755 637.247925,585.516968
	C647.850098,584.771301 657.784363,582.388977 667.863647,580.977295
	C674.539185,580.042297 681.026184,578.062256 688.170898,577.437073
	C691.726440,576.896179 695.025635,577.729492 698.447510,576.132202
	C700.217224,575.585205 701.606079,575.516785 703.402527,576.061096
	C704.580750,576.652283 705.111511,577.314392 705.677551,578.483154
	C706.016663,579.742065 705.922668,580.561646 705.195801,581.645447
	C693.639282,582.917175 682.327026,583.465027 671.326782,586.704041
	C667.268433,587.898987 662.855957,587.891113 658.301880,588.681824
	C644.485779,589.957703 631.262939,592.825745 617.945862,595.133606
	C596.286499,598.887146 574.580139,602.368469 552.898315,605.993408
	C547.518433,606.892883 542.157043,607.902649 536.388672,608.921143
	C533.940491,608.982727 531.890808,608.984985 529.399902,608.975281
z"
        />
        <path
          fill="#484750"
          opacity="1.000000"
          stroke="none"
          d="
M466.829651,611.692139
	C466.553497,612.003662 466.108948,612.004883 465.330994,612.010376
	C463.245239,612.006775 461.620575,611.784790 461.060913,609.344543
	C463.310303,608.809692 465.352661,609.228516 466.829651,611.692139
z"
        />
        <path
          fill="#484750"
          opacity="1.000000"
          stroke="none"
          d="
M469.951050,614.674438
	C469.119263,614.891663 468.365387,614.607422 468.053589,613.314575
	C468.880188,613.107971 469.633484,613.392395 469.951050,614.674438
z"
        />
        <path
          fill="#875E55"
          opacity="1.000000"
          stroke="none"
          d="
M425.494568,628.837158
	C424.781372,628.033752 424.477600,627.267029 424.053009,626.210388
	C424.647217,623.863098 426.299377,623.183105 428.613464,623.023804
	C431.592957,623.292786 433.559235,622.036316 434.540833,619.787048
	C435.379852,617.864441 433.183990,617.059387 432.089722,615.526123
	C431.784943,615.039001 431.667755,614.816467 431.771729,614.288452
	C432.492432,613.728027 432.991913,613.473145 433.745850,613.099365
	C434.390900,612.612061 434.849243,612.481934 435.688232,612.798096
	C436.203094,616.307739 440.822540,617.477417 439.985291,621.660706
	C438.895599,623.554077 436.218597,623.077576 435.819641,625.699707
	C435.126892,628.252930 432.949615,627.727905 431.105347,628.558044
	C429.197357,628.809265 427.550629,628.841553 425.494568,628.837158
z"
        />
        <path
          fill="#73676A"
          opacity="1.000000"
          stroke="none"
          d="
M436.347015,613.050171
	C435.556519,613.002747 435.111816,612.998840 434.333679,612.987732
	C434.975616,609.314453 440.663300,607.324158 443.011505,609.958862
	C444.591583,611.731628 444.625702,614.168030 443.153351,615.890320
	C441.465851,617.864258 440.391205,615.516113 439.159088,614.669861
	C438.355347,614.117798 437.516144,613.617310 436.347015,613.050171
z"
        />
        <path
          fill="#784534"
          opacity="1.000000"
          stroke="none"
          d="
M436.139404,625.826843
	C435.181122,625.790344 433.490814,626.492188 434.346375,624.840942
	C435.301025,622.998352 437.437988,622.682312 439.629730,622.076904
	C439.832916,622.575073 439.686676,623.105591 439.341309,623.832397
	C437.815796,623.869385 437.127045,624.770630 436.139404,625.826843
z"
        />
        <path
          fill="#73676A"
          opacity="1.000000"
          stroke="none"
          d="
M431.906158,613.633423
	C431.982086,614.195801 431.971283,614.408691 431.931274,614.941528
	C430.238647,615.020325 429.215820,613.847656 428.045837,612.397461
	C429.322601,612.143982 430.936737,611.512451 431.906158,613.633423
z"
        />
        <path
          fill="#0C1119"
          opacity="1.000000"
          stroke="none"
          d="
M560.533569,628.006592
	C560.546936,630.276794 558.749390,629.980408 557.152832,629.990540
	C553.411560,630.014404 549.669922,629.998169 545.461060,629.995361
	C545.454468,627.734863 547.245972,628.022705 548.845154,628.013062
	C552.585632,627.990417 556.326294,628.004333 560.533569,628.006592
z"
        />
        <path
          fill="#33616C"
          opacity="1.000000"
          stroke="none"
          d="
M852.310669,223.943451
	C852.042969,222.440643 852.811279,221.435196 854.640320,220.980270
	C854.941223,222.536942 854.177551,223.523666 852.310669,223.943451
z"
        />
        <path
          fill="#33616C"
          opacity="1.000000"
          stroke="none"
          d="
M855.249756,220.819031
	C855.152283,220.404816 855.330872,219.815948 855.742310,219.007416
	C855.825378,219.406631 855.675659,220.025497 855.249756,220.819031
z"
        />
        <path
          fill="#574649"
          opacity="1.000000"
          stroke="none"
          d="
M594.661682,420.058899
	C594.992554,425.045563 594.965393,430.078888 594.637939,435.779846
	C590.959595,436.176727 590.110962,433.645233 589.692871,430.964996
	C589.388672,429.014679 589.468628,427.005402 588.529968,424.761169
	C588.537048,421.810730 588.440369,419.274261 588.777344,416.373749
	C589.063721,415.511597 589.134399,415.015228 589.323547,414.159485
	C591.477417,414.844025 590.969543,416.795013 591.018127,418.475952
	C591.097595,421.221008 592.712158,420.571655 594.661682,420.058899
z"
        />
        <path
          fill="#AA8367"
          opacity="1.000000"
          stroke="none"
          d="
M576.819946,439.321045
	C577.827759,437.388641 578.640442,435.655060 579.549377,433.716095
	C579.878845,434.703583 580.171143,435.579529 580.705811,436.635162
	C581.210693,437.129272 581.335327,437.488220 581.242126,438.186218
	C580.975830,439.205688 580.809937,439.943176 580.989014,441.009399
	C581.063110,441.509674 581.086304,441.713715 581.116882,442.231262
	C580.913086,444.919983 579.763489,447.055420 579.318237,449.710510
	C579.197388,450.247498 579.134521,450.459656 578.946777,450.980896
	C578.624207,451.686279 578.393127,452.062134 577.920044,452.661133
	C577.540100,453.023987 577.372253,453.145508 576.911499,453.337524
	C576.460327,453.315247 576.302124,453.222412 575.938721,452.982025
	C575.688049,452.525665 575.516174,452.336426 575.359863,452.525421
	C575.827393,452.826263 576.083557,452.983521 576.447693,453.472443
	C576.980286,454.213287 577.102722,454.807770 577.139771,455.765717
	C577.057434,457.053650 576.614685,457.900787 576.303528,459.161926
	C575.175354,461.770325 574.186218,464.060150 571.543762,465.511047
	C570.929565,465.605225 570.678101,465.595215 570.075317,465.446991
	C568.446350,464.296844 568.003052,462.918762 568.021851,460.996979
	C568.230469,459.512360 569.034058,458.575317 569.080200,457.061951
	C569.156677,456.504364 569.196838,456.281525 569.327637,455.729004
	C569.363037,453.225739 573.420837,451.948486 570.451965,449.053467
	C568.496155,447.875305 566.708862,447.036957 565.513184,444.958008
	C565.452454,442.224274 567.068054,441.868103 569.284790,441.931946
	C569.899353,442.121704 570.156738,442.278351 570.404663,442.451294
	C570.660156,442.629486 570.845398,442.858948 570.945190,443.161133
	C571.173645,442.731842 570.535645,442.811523 570.425903,442.204102
	C570.115601,439.482819 570.929382,437.350159 572.464111,435.123169
	C575.818848,434.711212 575.212280,437.638000 576.275757,439.158844
	C576.471924,439.439392 576.442078,439.765259 576.436340,439.961517
	C576.499146,439.814301 576.561951,439.667053 576.819946,439.321045
z"
        />
        <path
          fill="#9B8881"
          opacity="1.000000"
          stroke="none"
          d="
M580.231567,442.244781
	C579.990356,441.825165 579.970459,441.600311 579.914490,441.038818
	C579.441162,440.032227 579.501282,439.296875 579.839661,438.276489
	C580.321411,437.765015 580.560059,437.533936 580.873413,437.058838
	C580.503906,433.106049 581.869141,429.574463 582.047729,425.506897
	C584.105957,427.638763 582.328857,430.892548 583.681335,433.617004
	C584.297791,434.858887 583.842834,437.067596 585.733276,437.076691
	C587.938110,437.087280 587.500977,434.726685 587.873962,433.315460
	C588.098633,432.465424 587.625610,431.431030 587.197083,430.221252
	C587.293396,425.624237 587.658630,421.285461 588.040405,416.501770
	C588.056885,416.056854 588.524109,416.015137 588.758545,416.011536
	C589.021790,418.687683 589.050598,421.367401 589.093445,424.492798
	C589.789490,430.569397 592.364563,436.070801 590.122437,442.258850
	C589.488708,443.765900 588.602966,444.715851 588.302551,446.342163
	C588.168762,446.944733 588.104736,447.184479 587.922241,447.778717
	C585.936768,450.940125 587.251709,455.382874 582.883362,456.861511
	C580.270874,452.383240 581.999146,448.115417 582.604065,443.517487
	C582.700195,442.990570 582.753418,442.793488 582.905945,442.340149
	C583.005310,442.083862 583.235901,441.661469 583.369324,441.501648
	C583.502747,441.341827 583.299377,441.001556 583.246216,441.214142
	C583.193115,441.426727 582.954773,441.841644 582.769043,442.014954
	C582.278015,442.386780 581.945740,442.522583 581.302124,442.622864
	C580.824158,442.567932 580.638489,442.503723 580.231567,442.244781
z"
        />
        <path
          fill="#87655F"
          opacity="1.000000"
          stroke="none"
          d="
M577.007446,438.662781
	C577.015076,439.929504 577.015076,440.736755 577.015076,441.544037
	C576.808899,441.632385 576.602661,441.720703 576.396484,441.809052
	C575.441956,439.726318 574.487427,437.643585 573.274658,435.328369
	C572.164917,435.663605 571.418945,435.469940 570.555786,434.397461
	C571.960510,426.678009 572.361450,419.053436 576.076538,411.973053
	C578.551453,414.751343 577.042480,418.055511 577.228882,421.563782
	C575.033813,427.372375 577.386719,432.789948 577.007446,438.662781
z"
        />
        <path
          fill="#4C393B"
          opacity="1.000000"
          stroke="none"
          d="
M577.296753,421.667725
	C576.893005,418.752167 576.761719,415.542969 576.396973,411.973541
	C575.754089,411.354248 575.486633,410.986877 575.295288,410.177368
	C575.289185,409.351440 575.358643,408.882446 575.495605,408.061523
	C575.598816,406.482544 575.673279,405.261505 576.833313,404.068848
	C577.521667,403.872803 577.898499,403.872528 578.554565,403.842468
	C579.666687,409.756775 577.929871,415.515839 577.296753,421.667725
z"
        />
        <path
          fill="#543937"
          opacity="1.000000"
          stroke="none"
          d="
M663.810181,200.794769
	C665.842896,204.307434 666.500916,208.057251 667.896667,211.506485
	C669.426086,215.286102 672.676147,217.479553 676.839966,218.654022
	C678.240906,220.657043 676.899902,221.618027 675.772766,222.482544
	C673.821655,223.978912 672.614136,226.186432 670.309082,227.859192
	C660.358643,234.274475 649.403992,233.825043 638.674255,234.186905
	C633.743164,234.353180 632.943848,234.634888 632.807495,239.825058
	C632.705505,243.704498 631.966431,247.408875 630.744629,251.063873
	C629.976013,253.363297 628.757385,255.257797 626.306152,256.735901
	C623.143127,262.291595 617.540710,260.602539 613.152832,261.886902
	C611.015198,262.512604 608.546997,262.009094 605.808411,262.034943
	C605.305176,260.652740 605.221985,259.252350 605.133118,257.430939
	C605.877808,253.497620 608.835999,254.548660 611.052124,254.224182
	C614.758911,253.681427 618.730103,255.153015 622.489075,252.472107
	C627.078369,246.097733 625.881897,239.415756 624.551392,232.862045
	C623.987244,230.083145 621.473083,229.369354 618.701355,229.454910
	C616.322876,229.528351 613.599487,230.066742 612.292786,226.636810
	C612.992432,223.234909 615.705750,222.805222 617.994446,222.081055
	C621.404846,221.001923 623.108948,219.041000 623.520569,215.351883
	C624.207642,209.193848 625.479919,203.216522 631.073120,199.167801
	C636.783875,195.033966 642.796753,194.082642 649.696960,196.735992
	C653.760681,198.154968 657.880737,196.485397 661.760803,198.742523
	C662.539978,199.522995 663.065674,200.067627 663.810181,200.794769
z"
        />
        <path
          fill="#433437"
          opacity="1.000000"
          stroke="none"
          d="
M626.417664,257.006531
	C629.610352,250.709610 631.646790,244.034760 631.012573,236.687912
	C630.651001,232.498398 633.841553,232.022049 636.610779,232.126648
	C647.693970,232.545166 658.629517,232.400925 668.783691,227.059540
	C668.919189,226.988281 669.111755,227.025558 669.657104,226.999252
	C672.921997,229.976715 675.645630,233.302704 680.587646,230.892563
	C681.462708,230.465836 682.557251,230.829727 683.682190,231.675186
	C682.930908,236.828644 680.959717,241.667282 682.539978,246.888260
	C682.685242,247.367966 681.752502,248.173981 680.987427,249.002502
	C680.407654,249.208908 680.160828,249.241318 679.572388,249.127274
	C676.397400,245.880432 672.635559,246.309189 669.054810,246.505646
	C663.836182,246.791946 658.715149,247.206406 655.848389,241.432739
	C655.532104,240.795593 654.658081,241.049866 653.635742,241.076233
	C652.481506,241.057571 651.770996,240.826935 650.815735,240.155716
	C647.321411,237.241028 643.327820,237.376007 640.487671,239.761154
	C636.280884,243.293991 633.362183,247.457703 632.512024,252.949753
	C632.002502,256.241760 629.961670,257.392639 626.417664,257.006531
z"
        />
        <path
          fill="#8396A6"
          opacity="1.000000"
          stroke="none"
          d="
M650.500244,240.364868
	C651.350647,239.956512 652.216919,239.950928 653.508545,240.040802
	C654.836243,241.109451 654.797913,242.067230 653.481567,243.077423
	C652.597778,243.135040 652.119324,243.125946 651.280945,243.132507
	C650.786072,242.354660 650.651062,241.561157 650.500244,240.364868
z"
        />
        <path
          fill="#403D45"
          opacity="1.000000"
          stroke="none"
          d="
M596.772461,205.231522
	C602.486267,206.263138 606.795349,203.407654 611.604736,201.000641
	C616.962891,197.907761 620.045471,199.130295 621.233521,204.657684
	C622.560608,210.831955 618.805603,217.620697 612.338257,220.770294
	C610.428650,221.700287 608.203979,222.026978 606.516235,224.028183
	C605.540039,226.069244 603.835693,226.854324 602.363037,228.312469
	C602.106445,228.769104 601.993652,228.944519 601.700928,229.371567
	C601.014709,230.255997 600.498291,230.884216 600.549255,232.092255
	C600.491760,233.552063 600.305603,234.663559 599.741577,236.047318
	C599.215698,236.925949 598.228333,237.394043 598.271973,237.777328
	C598.934875,243.605774 596.898804,249.725830 600.441284,255.368469
	C601.259827,255.535583 601.730957,255.629074 602.536194,255.884491
	C603.068237,256.178741 603.262756,256.328522 603.641052,256.797455
	C603.828613,258.812012 603.832458,260.507416 603.757324,262.622894
	C599.673584,263.692139 599.444641,266.187683 600.861145,269.205841
	C601.494751,270.555786 602.120972,271.771454 600.459106,272.528595
	C598.763672,273.301086 596.861694,273.397461 595.208191,272.314850
	C593.886719,271.449585 594.595337,270.411438 595.103027,269.283386
	C597.140808,264.755219 595.328613,260.947601 592.337646,257.174072
	C592.034119,242.376083 592.390625,227.744659 591.829468,213.148514
	C591.642822,208.294876 596.086243,209.105515 597.171753,206.234100
	C596.837524,205.819305 596.679932,205.647583 596.772461,205.231522
z"
        />
        <path
          fill="#534A50"
          opacity="1.000000"
          stroke="none"
          d="
M597.016602,204.612976
	C597.017822,205.154541 597.013184,205.321884 597.001831,205.740112
	C595.256714,204.827637 593.501526,205.199219 591.397949,205.988892
	C590.664795,206.040771 590.421509,206.234344 590.168091,206.825287
	C590.032532,207.052719 590.056458,207.030472 590.070923,207.046112
	C586.957458,207.827271 585.409790,204.636017 582.431152,204.108246
	C581.477783,203.557480 580.876770,203.044006 580.146851,202.261017
	C580.773132,201.989243 581.528320,201.987015 582.661255,201.978424
	C585.575195,200.295013 588.373596,200.966080 591.433655,201.176819
	C592.248596,201.198212 592.455750,202.539841 593.420776,201.369598
	C594.685730,200.852692 595.617188,200.923813 596.757263,201.717712
	C597.008484,202.740891 597.009644,203.489822 597.016602,204.612976
z"
        />
        <path
          fill="#33444C"
          opacity="1.000000"
          stroke="none"
          d="
M590.251953,206.941589
	C590.144104,206.564056 589.769653,205.810593 590.889282,205.912949
	C590.916870,206.348846 590.722778,206.610916 590.251953,206.941589
z"
        />
        <path
          fill="#705250"
          opacity="1.000000"
          stroke="none"
          d="
M597.479980,438.443390
	C597.811340,434.690094 597.890320,431.263489 598.040283,427.372131
	C599.613403,427.081177 600.086487,425.578278 601.447754,424.941895
	C605.028381,427.782471 602.437622,431.363525 602.731934,435.025513
	C602.438538,439.608185 602.227661,443.786499 602.820557,448.320587
	C603.250183,449.651123 603.312073,450.650055 603.268311,452.021240
	C603.592834,453.582855 601.205017,456.053162 605.130249,455.887939
	C606.220215,456.728851 606.490906,457.703827 607.029175,458.964630
	C607.341125,460.418945 607.256287,461.474854 606.700439,462.873718
	C603.049316,466.220856 599.429871,467.374939 595.153625,463.912140
	C594.409119,462.953278 594.285889,462.199615 594.446655,461.034637
	C595.065552,459.302185 595.182312,457.901489 594.801575,456.082153
	C595.304565,450.015503 594.916809,444.195709 597.479980,438.443390
z"
        />
        <path
          fill="#9B8881"
          opacity="1.000000"
          stroke="none"
          d="
M601.361511,436.188812
	C601.418823,432.468353 601.683594,429.082245 601.845825,425.269440
	C601.743286,424.842743 601.808044,424.379669 601.843994,424.148254
	C602.650574,423.943817 603.421143,423.970795 604.575500,424.009460
	C604.959229,424.021149 604.990051,424.514282 604.994385,424.761292
	C607.205261,428.944214 605.197205,433.319885 606.449463,437.761475
	C606.986328,440.327515 607.362549,442.504242 607.826355,445.096497
	C608.051697,445.741058 608.189514,445.970123 608.389038,446.595520
	C608.377075,449.682129 608.784729,452.363739 608.531738,455.472443
	C608.285583,457.362244 608.011169,458.780884 605.966675,459.286621
	C605.300293,458.400513 605.057861,457.632111 604.777466,456.558868
	C603.803589,455.415100 602.612549,454.838318 601.920776,453.332397
	C601.818298,451.971741 601.834839,450.905060 601.825195,449.438843
	C601.807068,444.865692 602.514832,440.679230 601.361511,436.188812
z"
        />
        <path
          fill="#BEAAA0"
          opacity="1.000000"
          stroke="none"
          d="
M596.014282,460.725800
	C595.475403,461.477386 595.291626,461.985504 595.154297,462.768951
	C597.636963,468.622894 594.643555,473.906311 594.212708,479.319427
	C594.009399,481.872711 593.121033,484.420929 592.083374,487.253052
	C591.841614,487.864044 591.756653,488.110840 591.587524,488.737061
	C591.416199,490.411469 590.905457,491.599121 590.526123,493.211487
	C590.320435,495.933472 589.794678,498.211243 589.154785,500.877747
	C588.874390,501.497040 588.728577,501.731110 588.266968,502.238464
	C587.157471,502.907379 586.336365,502.947296 585.121765,502.497559
	C583.459778,500.945068 583.458008,499.264099 583.532043,497.156006
	C583.536560,496.210052 583.542603,495.671936 583.575623,494.737457
	C583.627991,494.078857 583.656067,493.816711 583.755676,493.166870
	C584.187500,491.533356 584.674561,490.319183 584.851074,488.618286
	C586.927551,480.708923 588.763062,473.155487 589.721924,464.994049
	C590.980042,457.630554 590.834351,450.527069 592.188110,443.593872
	C592.656982,441.192474 592.748230,438.666901 594.625366,436.275177
	C594.893066,435.932831 594.964966,435.996918 595.006348,436.021790
	C595.760559,436.677338 596.473328,437.308044 597.459290,438.027710
	C597.183838,444.162628 596.635254,450.208527 596.128540,456.676208
	C596.650146,458.201813 596.503235,459.342468 596.014282,460.725800
z"
        />
        <path
          fill="#916E67"
          opacity="1.000000"
          stroke="none"
          d="
M604.149902,257.082031
	C603.824829,257.116608 603.443237,256.999756 603.248047,256.958221
	C600.130859,251.422989 600.780579,245.678253 602.036011,239.516998
	C603.121948,237.929291 604.112061,236.556152 605.797241,237.130249
	C607.189026,237.604355 607.456909,239.126907 607.449829,240.473068
	C607.441895,241.999283 608.144165,243.395538 608.214783,244.841507
	C608.362366,247.863770 608.970703,249.950745 612.759338,249.575729
	C616.357422,249.219574 619.430664,248.573547 619.536316,244.093628
	C619.586670,241.957932 620.066101,239.880417 620.048645,237.707626
	C620.025513,234.819687 621.445312,233.262375 624.767212,235.399170
	C626.042908,241.273819 625.541931,246.757980 624.337708,252.627136
	C623.718689,255.690170 621.759338,255.873138 619.645142,255.937668
	C615.001526,256.079376 610.359802,256.284210 605.422424,256.736603
	C604.909973,257.022430 604.692444,257.034943 604.149902,257.082031
z"
        />
        <path
          fill="#32353F"
          opacity="1.000000"
          stroke="none"
          d="
M653.052246,243.559875
	C653.767151,242.510544 654.142883,241.755707 653.957336,240.465057
	C656.633362,236.429794 658.150635,240.309784 658.391724,241.466980
	C659.167664,245.190567 661.564087,245.098022 664.334595,245.020447
	C667.485901,244.932220 670.643005,244.948349 673.795166,245.023026
	C676.350281,245.083542 678.805054,245.475403 679.937988,248.695038
	C679.012207,250.279297 677.675293,250.340439 675.902466,250.147003
	C672.884583,248.558533 670.225281,247.529648 667.071533,249.856140
	C664.133118,250.368652 661.582092,251.238419 658.602234,250.410248
	C656.766113,249.779572 655.240967,249.403152 653.317505,249.021027
	C652.955505,247.335403 652.991882,245.655518 653.052246,243.559875
z"
        />
        <path
          fill="#478D9B"
          opacity="1.000000"
          stroke="none"
          d="
M679.614746,268.578186
	C680.118225,275.645691 680.066040,275.711334 673.512512,275.941467
	C671.348083,276.017487 669.182312,276.060974 667.016846,276.100616
	C657.851257,276.268250 657.851196,276.265656 657.937012,266.463501
	C658.371521,265.650360 658.842102,265.405762 659.771362,265.247803
	C665.639526,265.927765 671.166260,264.584320 677.001648,265.814453
	C678.434448,266.366455 679.046387,267.210846 679.614746,268.578186
z"
        />
        <path
          fill="#64777D"
          opacity="1.000000"
          stroke="none"
          d="
M659.964539,265.919067
	C659.244019,266.029327 658.877380,266.021790 658.235474,266.011444
	C658.021179,261.022491 658.082275,256.036377 658.217285,250.603119
	C660.916565,249.784882 663.541992,249.413788 666.583618,249.010132
	C669.293152,247.483978 671.744568,247.925491 674.598633,248.627884
	C676.385437,248.924713 677.771545,248.983246 679.573303,249.054489
	C679.989014,249.067200 680.320679,249.146622 680.487610,249.161560
	C680.784912,250.515274 680.915283,251.854050 681.095337,253.598083
	C681.027222,258.729401 680.909241,263.455444 680.810059,268.533630
	C680.645630,268.904144 680.462402,268.922455 680.000732,268.949829
	C678.852356,268.870026 678.284668,268.426880 678.033691,267.261353
	C676.732239,264.054016 677.601440,261.081696 677.795593,258.146759
	C678.034363,254.538528 676.930725,252.364746 672.862244,252.742325
	C671.880920,252.833389 670.879883,252.733612 669.888489,252.769012
	C662.597290,253.029327 662.111694,253.499008 661.703125,260.652496
	C661.599670,262.464386 661.638672,264.315979 659.964539,265.919067
z"
        />
        <path
          fill="#715F62"
          opacity="1.000000"
          stroke="none"
          d="
M713.298645,267.331421
	C715.277283,265.289490 717.900024,266.325470 720.823730,266.314575
	C719.033142,268.980438 716.258179,267.884460 713.298645,267.331421
z"
        />
        <path
          fill="#1E4552"
          opacity="1.000000"
          stroke="none"
          d="
M754.136475,237.325424
	C754.595032,236.405930 754.888733,235.791763 755.350647,235.355804
	C757.262817,233.550766 759.516602,233.415787 761.678711,234.642120
	C763.360107,235.595764 763.332397,237.436066 762.782410,239.037430
	C762.063843,241.129608 760.404541,242.089676 758.184753,241.938538
	C755.571777,241.760605 753.741333,240.770905 754.136475,237.325424
z"
        />
        <path
          fill="#483A3F"
          opacity="1.000000"
          stroke="none"
          d="
M605.251221,225.328094
	C604.959534,221.742844 606.665833,220.468338 609.808777,219.893661
	C615.103516,218.925522 619.837769,213.023651 619.992554,207.872513
	C620.172241,201.893616 618.496155,200.299545 612.367493,200.989685
	C612.087585,195.931183 615.294434,193.185928 619.708923,191.005829
	C624.475830,190.328125 627.864990,186.841797 632.651733,186.719574
	C639.301941,184.158478 645.945984,182.415604 652.897400,181.975174
	C655.083923,181.836670 656.369995,180.215744 658.071167,179.261139
	C664.492065,175.657944 666.963257,176.211548 672.933289,182.657288
	C672.668640,184.774231 672.207275,186.541168 672.038513,188.335632
	C671.823059,190.627472 670.807739,190.770569 668.703735,190.491150
	C664.037109,189.871429 659.360718,191.085938 654.555542,190.289108
	C651.060669,189.709549 649.379211,192.503738 650.007385,196.571869
	C646.693359,196.996857 643.370972,196.876846 640.061768,197.033066
	C633.641479,197.336151 627.520630,203.583603 626.550903,209.778656
	C626.048218,212.990158 624.822510,216.077545 624.829712,219.441055
	C624.833923,221.408234 623.014648,222.082855 621.463928,222.669388
	C618.834106,223.664032 616.159912,224.541168 613.252686,225.718613
	C612.179565,226.759995 611.208801,227.196533 609.650024,227.188110
	C607.940979,226.788177 606.604431,226.502060 605.251221,225.328094
z"
        />
        <path
          fill="#B27252"
          opacity="1.000000"
          stroke="none"
          d="
M591.791687,135.035858
	C600.329529,135.172989 601.980347,136.703400 601.924805,144.732239
	C601.892822,149.353592 603.244690,154.341537 600.823608,158.508621
	C598.018921,163.336121 592.540588,160.490112 588.360596,161.448471
	C582.967102,162.685089 577.392090,163.558716 571.619507,162.110092
	C564.884399,160.419891 563.756042,159.791122 563.116821,152.745285
	C562.832581,149.611694 562.853210,146.484695 562.256348,143.340942
	C561.534119,139.536957 565.940796,135.139145 570.332458,135.060455
	C577.326355,134.935135 584.324219,135.031952 591.791687,135.035858
z"
        />
        <path
          fill="#715F62"
          opacity="1.000000"
          stroke="none"
          d="
M633.000488,186.551575
	C629.463074,187.497849 627.626038,191.233047 623.882751,191.450394
	C622.740417,191.516708 621.704529,191.783966 620.350891,191.075867
	C619.707764,188.345657 619.413086,186.004272 616.557556,184.489120
	C613.619446,182.930099 615.949768,180.518311 616.750854,178.236511
	C619.408630,176.530075 622.011536,176.282455 624.564209,177.264084
	C626.527405,178.019043 628.081909,177.794006 629.783691,176.155029
	C630.456543,175.605179 630.904419,175.306473 631.625000,174.962402
	C633.046082,175.045700 634.053162,175.434433 634.880127,176.739319
	C635.362793,177.427368 635.494873,177.878036 635.199707,178.693420
	C633.099060,181.058167 634.606934,184.021118 633.000488,186.551575
z"
        />
        <path
          fill="#B27252"
          opacity="1.000000"
          stroke="none"
          d="
M604.621033,181.338654
	C605.656311,181.107040 606.396667,181.091370 607.504395,181.088257
	C607.871826,181.100800 607.900818,181.078064 607.906372,181.087509
	C610.795227,186.223190 608.285767,191.228043 607.326599,196.664078
	C606.612793,197.394257 606.142761,197.733627 605.385742,198.145569
	C603.480896,199.333344 601.714905,199.921753 599.368652,199.575439
	C597.755432,198.195007 597.822144,196.846466 598.524902,195.354675
	C598.707825,194.966415 599.858948,194.824875 599.158752,194.434845
	C597.692322,193.618011 596.956482,192.514252 596.813354,190.927048
	C596.546875,187.972992 598.771423,187.150833 600.869812,186.228073
	C597.905823,183.995667 600.097717,183.045670 601.952820,182.148041
	C602.662842,181.804489 603.583984,181.972580 604.621033,181.338654
z"
        />
        <path
          fill="#855144"
          opacity="1.000000"
          stroke="none"
          d="
M598.518677,198.479294
	C600.703857,198.551498 602.504761,198.325134 604.702271,198.158463
	C603.894104,201.865799 600.613281,201.473862 597.399109,201.982956
	C595.936646,201.990356 594.865906,201.988739 593.393921,201.993866
	C592.827637,202.131760 592.662598,202.262894 592.250000,202.197266
	C589.172302,202.099945 586.342102,202.199417 583.275513,202.135498
	C583.011658,201.254242 582.984253,200.536407 582.921875,199.462738
	C582.886841,199.106918 583.026855,198.970947 583.096069,198.902191
	C588.038879,195.951492 593.066284,196.598419 598.518677,198.479294
z"
        />
        <path
          fill="#9B8881"
          opacity="1.000000"
          stroke="none"
          d="
M629.625854,175.858505
	C628.666321,178.647324 628.428345,183.741470 623.628174,178.360321
	C622.719055,177.341187 619.848450,178.071762 617.445007,177.994476
	C617.088196,177.150009 617.240906,176.220093 618.241455,176.134735
	C621.143555,175.887161 622.498047,174.648697 621.477173,171.625748
	C621.131836,170.603058 621.149353,169.573639 622.380371,169.239319
	C623.969604,168.807739 624.889954,169.549667 625.078735,171.569565
	C625.425964,172.372086 625.554321,172.853882 625.316528,173.731461
	C625.561157,176.893814 627.801697,175.410370 629.625854,175.858505
z"
        />
        <path
          fill="#855144"
          opacity="1.000000"
          stroke="none"
          d="
M607.428040,196.957108
	C606.325745,191.965271 608.218201,187.026016 607.925659,181.530243
	C611.365845,183.129150 610.922058,186.760361 610.649719,189.702835
	C610.427917,192.100006 609.592346,194.822052 607.428040,196.957108
z"
        />
        <path
          fill="#715F62"
          opacity="1.000000"
          stroke="none"
          d="
M625.410767,174.009750
	C625.097351,173.601425 625.051636,173.126923 625.055420,172.297012
	C626.361511,172.303741 627.350159,172.742615 625.410767,174.009750
z"
        />
        <path
          fill="#635B5F"
          opacity="1.000000"
          stroke="none"
          d="
M683.951660,231.585236
	C681.737549,232.125610 679.351868,231.683838 677.619812,233.722610
	C677.252197,234.155319 676.499329,234.260727 675.923889,234.516907
	C674.645325,231.617645 669.949219,231.658081 670.014648,227.303955
	C671.663818,223.941483 673.749390,221.318130 676.906921,219.256561
	C685.243713,219.515106 687.676025,222.599442 684.324341,228.812149
	C683.715576,229.382706 683.305481,229.488144 682.864014,229.523422
	C683.093872,229.591537 683.337219,229.544327 683.770142,229.838806
	C683.965942,230.475601 683.959351,230.879395 683.951660,231.585236
z"
        />
        <path
          fill="#483A3F"
          opacity="1.000000"
          stroke="none"
          d="
M663.952637,200.683823
	C663.120300,200.891968 662.362793,200.604492 662.054871,199.306229
	C662.882812,199.107422 663.639343,199.395508 663.952637,200.683823
z"
        />
        <path
          fill="#433437"
          opacity="1.000000"
          stroke="none"
          d="
M683.983459,229.807053
	C683.567993,229.880783 683.163452,229.689743 682.758972,229.498734
	C683.012817,229.340607 683.266663,229.182465 683.763916,229.018417
	C684.002991,229.189102 683.998718,229.365707 683.983459,229.807053
z"
        />
        <path
          fill="#C46B41"
          opacity="1.000000"
          stroke="none"
          d="
M733.078369,255.468262
	C732.877625,256.204742 732.629822,256.482605 732.143005,256.908813
	C720.578369,258.046478 709.254028,257.065216 697.461304,257.017303
	C699.026062,251.696426 700.192566,246.207962 699.909851,240.527847
	C699.733398,236.983307 701.817078,235.615005 704.657593,235.373276
	C713.596619,234.612564 722.565491,234.887802 731.518433,235.203247
	C733.789490,235.283249 735.056458,236.770508 734.937195,239.235153
	C734.849548,241.047073 734.919983,242.866623 734.919983,245.018890
	C733.312866,243.485306 734.607666,241.553497 733.031250,239.384872
	C733.031250,245.204956 733.031250,250.107315 733.078369,255.468262
z"
        />
        <path
          fill="#674541"
          opacity="1.000000"
          stroke="none"
          d="
M635.276733,179.261993
	C634.995972,178.560699 635.001343,178.122269 635.023926,177.355865
	C641.706299,176.437927 648.359009,175.610992 655.541992,175.992676
	C655.805786,177.972519 654.108643,178.393539 652.747742,178.506165
	C647.030518,178.979248 641.292969,179.207153 635.276733,179.261993
z"
        />
        <path
          fill="#A5654C"
          opacity="1.000000"
          stroke="none"
          d="
M720.191345,199.257843
	C719.872314,206.300385 717.161133,208.916107 710.511780,208.968033
	C696.845764,209.074738 696.772766,209.073807 696.946045,195.316055
	C697.125549,181.056320 695.231628,183.223175 709.041138,182.991196
	C721.587341,182.780441 721.249695,182.139816 720.893066,195.900360
	C720.867737,196.876526 720.483276,197.843369 720.191345,199.257843
z"
        />
        <path
          fill="#5E6B74"
          opacity="1.000000"
          stroke="none"
          d="
M686.006165,157.611420
	C684.443176,158.639267 683.228027,160.943344 681.224060,159.472443
	C679.869324,158.477997 677.857483,157.504730 678.411560,155.260986
	C678.814026,153.631287 679.727722,151.920914 681.737061,152.183121
	C684.510254,152.544998 686.244263,154.145233 686.006165,157.611420
z"
        />
        <path
          fill="#496A76"
          opacity="1.000000"
          stroke="none"
          d="
M679.236450,161.406921
	C679.491821,163.816925 678.037598,163.959167 676.421143,163.779022
	C675.632202,163.691086 675.183044,163.097641 675.557556,162.391449
	C676.289673,161.011032 677.550110,160.763596 679.236450,161.406921
z"
        />
        <path
          fill="#5E6B74"
          opacity="1.000000"
          stroke="none"
          d="
M685.995300,158.004120
	C687.591614,158.193298 688.202454,159.140198 687.944641,160.661682
	C687.866150,161.124786 687.527710,161.564224 686.957703,161.230118
	C685.983582,160.659134 686.034180,159.652039 686.003418,158.344925
	C686.009460,157.990509 686.000000,157.999847 685.995300,158.004120
z"
        />
        <path
          fill="#5E6B74"
          opacity="1.000000"
          stroke="none"
          d="
M688.464111,163.531784
	C688.428833,163.465942 688.499451,163.597626 688.464111,163.531784
z"
        />
        <path
          fill="#A5654C"
          opacity="1.000000"
          stroke="none"
          d="
M656.792114,160.811584
	C657.013794,161.466125 657.016724,161.901993 657.009827,162.664673
	C656.782593,163.621674 657.027283,162.833603 656.694763,163.385742
	C655.226868,165.822952 657.185242,170.182358 654.458862,171.011917
	C650.697876,172.156250 646.225403,172.108978 642.325195,171.225082
	C636.503662,169.905792 639.673157,164.694992 639.085571,161.186371
	C638.634216,158.491165 639.558960,156.075531 642.915710,156.082932
	C647.360046,156.092728 651.832031,155.478271 656.625305,156.760376
	C657.009583,157.460709 657.016174,157.888855 657.016113,158.638123
	C656.478027,159.400696 656.331116,159.944855 656.792114,160.811584
z"
        />
        <path
          fill="#A67A5E"
          opacity="1.000000"
          stroke="none"
          d="
M656.899780,156.641510
	C653.023987,157.026947 649.039124,157.152512 645.067627,156.976593
	C641.536316,156.820145 639.682068,158.046173 639.995300,161.807037
	C640.105042,163.124207 640.136841,164.472458 639.988037,165.781937
	C639.554077,169.600571 641.663330,170.400162 644.810547,169.930542
	C647.369324,169.548737 649.758057,168.514572 652.395569,170.370605
	C654.257263,171.680679 654.796143,169.835297 655.003479,168.181015
	C655.216858,166.479080 654.239868,164.450089 656.627563,163.093658
	C656.939819,172.763412 656.693726,173.016769 647.207764,173.036209
	C645.548462,173.039612 643.886597,172.971085 642.230530,173.042725
	C639.333069,173.168121 637.858582,171.900360 637.957031,168.939423
	C637.979065,168.276596 637.866455,167.599228 637.963440,166.950623
	C638.533020,163.140656 635.928162,157.327820 639.590271,155.893356
	C644.788513,153.857162 651.109802,153.682129 656.899780,156.641510
z"
        />
        <path
          fill="#A67A5E"
          opacity="1.000000"
          stroke="none"
          d="
M657.017822,160.685211
	C656.374329,160.918060 655.617981,160.834076 655.584290,160.043625
	C655.570984,159.731689 656.135864,159.395081 656.723511,159.014465
	C657.014648,159.419556 657.019714,159.879868 657.017822,160.685211
z"
        />
        <path
          fill="#492E2E"
          opacity="1.000000"
          stroke="none"
          d="
M682.619751,430.042572
	C683.243958,433.874573 683.409424,436.415863 677.522400,435.445007
	C672.351318,434.592163 667.298279,432.302368 661.437256,432.989807
	C660.887512,432.836090 660.774475,432.682037 660.801147,432.277710
	C660.958801,430.951019 660.976807,429.874603 661.001099,428.394043
	C665.460266,429.106018 670.152466,429.460571 674.145081,432.049896
	C677.554077,434.260712 679.622559,431.000610 682.619751,430.042572
z"
        />
        <path
          fill="#674541"
          opacity="1.000000"
          stroke="none"
          d="
M744.703552,474.768066
	C748.268738,477.753052 752.902344,477.212372 756.671448,479.835571
	C755.670166,483.715118 752.947937,482.018188 750.899170,481.282013
	C743.863586,478.753815 736.428528,477.435089 729.580017,474.424042
	C722.695007,471.397003 715.378174,471.485809 708.147278,470.740173
	C702.378235,470.145325 696.464111,470.217804 690.717651,467.401245
	C685.822449,463.446350 680.795532,460.368286 675.504272,457.012299
	C672.467834,453.645782 668.658386,455.118103 665.362305,454.651459
	C659.105835,453.765747 655.343994,450.429382 654.292114,443.636230
	C656.705139,441.069305 658.527954,442.936249 660.450256,443.981110
	C663.315369,445.538391 665.643066,448.488800 669.742798,447.250977
	C671.906799,445.421906 672.376526,446.983429 672.966919,448.688782
	C673.460754,452.741028 676.929810,453.135864 679.635864,454.865662
	C682.081604,455.339142 684.204590,455.725342 686.017395,457.018127
	C694.016357,462.722565 703.221497,464.412079 712.741699,465.319580
	C723.870056,466.380371 734.455444,469.526459 744.703552,474.768066
z"
        />
        <path
          fill="#3B3036"
          opacity="1.000000"
          stroke="none"
          d="
M645.727783,427.604767
	C646.516418,426.059601 647.122192,424.830658 647.875793,423.292023
	C651.943604,427.673615 655.710083,432.451813 656.926392,439.092041
	C656.492859,439.716919 656.013672,439.821625 655.146057,439.737000
	C650.718811,436.521759 647.148254,432.995331 645.727783,427.604767
z"
        />
        <path
          fill="#AA8367"
          opacity="1.000000"
          stroke="none"
          d="
M654.744995,439.180450
	C655.458801,439.040283 655.887634,439.157166 656.631958,439.386871
	C660.241943,443.329376 665.009460,444.718201 669.630005,446.978821
	C668.744690,452.240051 665.493286,448.978302 663.808533,447.959991
	C661.150269,446.353363 658.730286,444.292145 655.556885,442.815796
	C654.730347,442.519562 654.394348,442.155243 654.131348,441.280365
	C654.071777,440.381195 654.205017,439.892731 654.744995,439.180450
z"
        />
        <path
          fill="#916E67"
          opacity="1.000000"
          stroke="none"
          d="
M677.774048,443.930725
	C678.122681,444.742493 678.154480,445.360748 678.257690,446.276031
	C673.980896,445.629150 670.349915,443.207794 666.676636,440.885193
	C665.860962,440.369415 664.901917,439.745026 666.664795,438.720551
	C670.065369,441.121429 673.866028,442.214874 677.774048,443.930725
z"
        />
        <path
          fill="#875E55"
          opacity="1.000000"
          stroke="none"
          d="
M660.705688,431.911438
	C660.940796,432.027466 660.966614,432.509155 660.983582,432.749634
	C660.926636,434.643097 662.206421,435.140381 663.653015,435.881226
	C664.279236,436.524994 664.608215,437.036530 665.127197,437.792969
	C660.742920,438.853943 658.208557,436.356598 659.007690,431.428284
	C659.538391,431.331726 660.004456,431.563568 660.705688,431.911438
z"
        />
        <path
          fill="#1B1E22"
          opacity="1.000000"
          stroke="none"
          d="
M646.814819,408.487000
	C643.635132,406.887268 645.142273,404.107635 645.781311,401.224976
	C646.319336,403.317993 646.591248,405.706970 646.814819,408.487000
z"
        />
        <path
          fill="#916E67"
          opacity="1.000000"
          stroke="none"
          d="
M681.139954,446.958984
	C681.565491,446.547333 682.099243,446.382141 682.914185,446.306885
	C682.776917,447.221588 682.354614,448.037201 681.139954,446.958984
z"
        />
        <path
          fill="#5D3732"
          opacity="1.000000"
          stroke="none"
          d="
M686.809448,449.789093
	C686.256409,450.013733 685.511414,450.004120 684.394775,449.990540
	C684.753296,449.033875 685.611633,448.850555 686.809448,449.789093
z"
        />
        <path
          fill="#4C393B"
          opacity="1.000000"
          stroke="none"
          d="
M654.587097,440.983398
	C654.834900,441.623779 654.946777,441.945068 655.134949,442.508209
	C655.995483,449.896851 661.422729,454.006683 669.920715,453.376862
	C672.368713,453.195404 674.057312,453.813232 675.841187,455.669037
	C674.965393,457.401764 673.449585,457.396149 671.553040,457.237854
	C670.554565,457.055908 669.907471,456.900146 668.919678,457.060547
	C665.640442,458.283630 662.835815,456.841522 659.954407,456.679138
	C658.605347,456.603119 656.813782,454.791016 655.613586,457.577271
	C654.625793,458.708710 653.691406,458.475159 652.417542,458.003143
	C651.891785,457.676025 651.698120,457.520264 651.270996,457.075928
	C647.334229,450.952362 642.114685,446.181549 639.088806,439.879395
	C637.330933,436.218048 634.535522,432.961823 634.753174,428.301849
	C634.965942,425.635284 634.958557,423.255432 634.980225,420.429413
	C639.347961,418.997772 634.125244,415.144989 637.408142,413.425293
	C638.904480,416.342041 640.410583,419.277832 641.969604,422.566101
	C641.956970,424.371643 642.745911,425.461853 643.803589,426.773315
	C644.046509,427.005524 643.990540,427.007202 643.995728,426.979675
	C646.969299,431.915405 651.152893,435.952515 654.587097,440.983398
z"
        />
        <path
          fill="#73676A"
          opacity="1.000000"
          stroke="none"
          d="
M654.803833,440.909363
	C649.814941,437.809662 646.292297,433.221710 643.982117,427.321228
	C644.429077,427.007385 644.857239,427.062592 645.598022,427.203186
	C648.192322,431.495209 651.595093,434.806366 654.880310,438.627228
	C654.981567,439.454376 654.933105,439.985321 654.803833,440.909363
z"
        />
        <path
          fill="#73676A"
          opacity="1.000000"
          stroke="none"
          d="
M643.989136,426.676941
	C642.111267,426.544525 641.194946,425.396667 641.807129,423.141174
	C642.658997,424.061798 643.295410,425.205078 643.989136,426.676941
z"
        />
        <path
          fill="#4C393B"
          opacity="1.000000"
          stroke="none"
          d="
M636.473694,410.516785
	C636.458069,410.483643 636.489319,410.549927 636.473694,410.516785
z"
        />
        <path
          fill="#847876"
          opacity="1.000000"
          stroke="none"
          d="
M634.649170,428.010254
	C637.759583,437.093079 643.333313,444.508850 649.178406,451.752533
	C650.313660,453.159424 651.949341,454.206726 652.087524,456.589355
	C651.708984,457.291168 651.314575,457.495819 650.513306,457.434875
	C648.159912,456.161224 647.263245,453.895752 645.246460,452.243896
	C639.825684,444.870850 635.478210,437.309540 633.040833,428.379730
	C633.460571,428.001770 633.892761,428.003540 634.649170,428.010254
z"
        />
        <path
          fill="#412C2E"
          opacity="1.000000"
          stroke="none"
          d="
M661.348022,476.574585
	C660.715576,472.978271 658.121704,471.522217 656.208374,469.195740
	C659.070251,466.570587 661.032227,469.088684 663.554565,470.503632
	C664.058716,470.667267 664.257690,470.734711 664.733459,470.940002
	C665.181580,471.184387 665.352966,471.290833 666.001038,471.740295
	C666.785706,472.309174 667.095886,472.531921 667.652893,472.897095
	C668.061951,473.140289 668.226318,473.237946 668.638672,473.482605
	C669.226562,473.829102 669.567505,474.026825 670.185303,474.364014
	C671.625916,475.132263 672.630310,475.934143 673.445435,477.409393
	C674.608887,479.836060 676.703552,480.391449 678.611023,481.293427
	C680.465027,482.170166 682.716614,482.847626 682.275757,485.964569
	C675.810791,487.445953 671.488770,483.371887 666.398376,480.360596
	C664.620972,479.200806 663.664795,477.516022 661.348022,476.574585
z"
        />
        <path
          fill="#847876"
          opacity="1.000000"
          stroke="none"
          d="
M647.993591,465.726715
	C652.289429,469.273987 656.246887,472.635925 660.541077,476.052979
	C660.877808,476.108032 660.906799,476.088501 660.917847,476.095032
	C662.899536,476.953613 664.713501,478.026123 666.163696,480.100098
	C666.039368,481.230743 665.477478,481.669647 664.387573,482.031250
	C659.607605,481.914612 656.745911,478.936523 653.383179,476.094238
	C650.965698,472.615448 645.908936,471.437805 646.672363,466.039246
	C647.157104,465.669769 647.406189,465.605560 647.993591,465.726715
z"
        />
        <path
          fill="#73676A"
          opacity="1.000000"
          stroke="none"
          d="
M665.164795,471.958130
	C661.593750,472.549408 660.242432,468.178650 656.461060,468.828125
	C656.080566,468.881775 656.029602,468.919342 656.040283,468.949158
	C655.916687,468.755676 655.782288,468.532379 655.882019,467.995972
	C656.116150,467.682922 656.203857,467.481964 655.981445,467.188507
	C656.093201,466.798004 656.203125,466.691559 656.094910,466.570251
	C655.985962,466.448120 655.881958,466.384369 655.515930,466.260651
	C654.885681,465.998047 654.561096,465.788086 654.140625,465.254333
	C653.692749,464.179688 653.377686,463.369904 652.890198,462.277039
	C652.846802,461.827484 652.975830,461.661102 653.384644,461.346741
	C654.073914,461.091980 654.489685,461.046387 655.217285,461.137390
	C655.709229,461.286377 655.896484,461.370728 656.328369,461.637390
	C656.855103,462.098755 657.118713,462.394226 657.544739,462.925598
	C657.980835,463.414825 658.237183,463.683319 658.706543,464.122925
	C659.197510,464.522797 659.481750,464.744537 659.990112,465.116150
	C660.641113,465.584747 661.040833,465.936768 661.649963,466.443665
	C661.982239,466.719940 662.113220,466.831268 662.440918,467.106049
	C662.905945,467.480133 663.178772,467.684814 663.676636,468.023804
	C664.043396,468.263885 664.188904,468.364655 664.541504,468.638184
	C664.873169,468.944183 664.997681,469.077423 665.382812,469.698395
	C665.669617,470.371277 665.695923,470.556458 665.685669,471.020477
	C665.566223,471.467560 665.483276,471.635834 665.164795,471.958130
z"
        />
        <path
          fill="#705250"
          opacity="1.000000"
          stroke="none"
          d="
M645.045471,452.338470
	C647.693054,452.208405 648.507812,454.518188 649.840210,456.838043
	C649.673218,457.365662 649.479370,457.331024 649.021606,457.163940
	C646.626648,456.343018 645.931702,454.443848 645.045471,452.338470
z"
        />
        <path
          fill="#705250"
          opacity="1.000000"
          stroke="none"
          d="
M654.328491,461.760864
	C653.812012,461.952728 653.593994,461.953522 653.046875,461.974121
	C652.497314,461.885498 652.312500,461.733795 652.068909,461.382263
	C651.628662,460.690186 651.516663,460.075012 651.172119,459.234741
	C650.967224,459.000214 651.005005,458.993561 651.000732,459.012329
	C650.783081,458.790131 650.565186,458.553406 650.157715,458.165894
	C649.972778,458.010864 650.003052,458.011932 649.989136,458.020538
	C649.860718,457.861115 649.746216,457.693085 649.804565,457.273804
	C649.977478,457.022522 650.049072,456.947601 650.085571,456.907532
	C650.546204,456.906738 650.970337,456.945984 651.715088,456.967285
	C652.035706,456.949310 652.012024,456.981506 652.018555,456.974609
	C653.111511,457.849792 654.229492,458.697144 655.234375,460.124359
	C655.304199,460.962433 655.077393,461.338165 654.328491,461.760864
z"
        />
        <path
          fill="#412C2E"
          opacity="1.000000"
          stroke="none"
          d="
M653.725281,465.030487
	C654.550171,465.180176 655.092651,465.370911 655.817993,465.785767
	C656.118958,466.164429 656.236877,466.318970 656.293884,466.758667
	C656.232971,467.043823 656.237732,467.160156 656.208618,467.212158
	C655.252930,466.551086 652.589233,468.006348 653.725281,465.030487
z"
        />
        <path
          fill="#73676A"
          opacity="1.000000"
          stroke="none"
          d="
M650.975952,459.319397
	C651.301270,459.523621 651.635376,460.046997 651.971985,460.898071
	C651.644592,460.696716 651.314636,460.167664 650.975952,459.319397
z"
        />
        <path
          fill="#9B8881"
          opacity="1.000000"
          stroke="none"
          d="
M648.218750,465.707214
	C648.084473,465.952118 647.837036,465.992157 647.217407,466.086151
	C646.792603,465.945709 646.739868,465.751282 646.820129,465.285461
	C646.953125,465.014130 647.439026,465.023621 647.680542,465.047607
	C647.922058,465.071564 648.105469,465.502350 648.218750,465.707214
z"
        />
        <path
          fill="#412C2E"
          opacity="1.000000"
          stroke="none"
          d="
M655.958740,467.832672
	C656.144592,467.900757 656.173035,468.118622 656.126221,468.657715
	C655.892578,468.765320 655.708984,468.564270 655.592163,468.329926
	C655.559814,468.264923 655.726074,468.100983 655.958740,467.832672
z"
        />
        <path
          fill="#73676A"
          opacity="1.000000"
          stroke="none"
          d="
M649.672485,457.020081
	C649.978516,457.190308 649.979614,457.358063 649.977966,457.777466
	C649.676636,457.843018 649.377991,457.656891 648.918457,457.251099
	C648.960938,457.026917 649.164185,457.022308 649.672485,457.020081
z"
        />
        <path
          fill="#73676A"
          opacity="1.000000"
          stroke="none"
          d="
M650.058472,458.254486
	C650.294800,458.125183 650.558411,458.318970 650.880005,458.811646
	C650.712341,458.853424 650.428223,458.675781 650.058472,458.254486
z"
        />
        <path
          fill="#625457"
          opacity="1.000000"
          stroke="none"
          d="
M646.967285,465.003601
	C646.908691,465.198761 646.864258,465.383392 646.832581,465.854065
	C647.655029,470.029633 651.671387,471.158569 653.842773,474.292175
	C654.205811,477.095184 656.022034,478.431366 657.768005,479.964569
	C661.561768,483.296143 666.171143,485.584686 669.722290,489.688110
	C669.881409,490.515686 669.834045,490.992126 669.523193,491.778351
	C666.902954,493.678009 664.859985,492.134369 662.921082,491.074402
	C656.207458,487.404358 650.475952,482.474426 644.880920,476.287964
	C644.323669,475.528595 644.221252,475.317719 644.046509,474.767181
	C643.681396,472.970978 642.673767,472.096924 641.217041,471.165558
	C640.950134,470.959808 640.892639,470.667847 640.891785,470.522461
	C640.943359,468.449585 641.403931,466.476349 640.131958,464.715485
	C638.963501,463.097992 640.594666,462.888092 641.680054,462.136169
	C642.352234,461.613403 642.820435,461.470093 643.676147,461.799255
	C643.988770,462.027130 644.004700,462.004211 643.991943,462.007690
	C644.256531,462.283569 644.550293,462.537140 645.111328,462.918396
	C645.478943,463.194977 645.603882,463.316803 645.846558,463.503052
	C646.115723,463.945007 646.320312,464.285553 646.784485,464.809265
	C646.994324,465.022797 646.981445,464.993042 646.967285,465.003601
z"
        />
        <path
          fill="#372624"
          opacity="1.000000"
          stroke="none"
          d="
M623.021729,395.433228
	C623.748962,394.965057 624.443909,394.968323 625.484985,394.956360
	C625.851074,403.355316 625.800049,411.770569 625.929260,420.183014
	C625.974548,423.133545 626.462646,426.077240 626.751709,429.423645
	C623.743713,425.939758 623.306152,421.253723 623.109497,416.667114
	C622.813171,409.757294 623.005066,402.826508 623.021729,395.433228
z"
        />
        <path
          fill="#9B8881"
          opacity="1.000000"
          stroke="none"
          d="
M639.944031,456.708679
	C638.337219,457.111816 638.005249,455.968719 637.865112,454.379883
	C639.092285,454.414795 639.670227,455.267242 639.944031,456.708679
z"
        />
        <path
          fill="#9B8881"
          opacity="1.000000"
          stroke="none"
          d="
M641.962585,459.706116
	C640.383179,460.069489 640.037781,458.936035 639.864319,457.380920
	C641.088684,457.420929 641.729187,458.227417 641.962585,459.706116
z"
        />
        <path
          fill="#9B8881"
          opacity="1.000000"
          stroke="none"
          d="
M643.876465,461.750244
	C643.537964,462.046234 643.087097,462.065338 642.297607,462.043030
	C641.908081,461.560730 641.857178,461.119873 641.882446,460.354584
	C642.560425,460.511261 643.162292,460.992310 643.876465,461.750244
z"
        />
        <path
          fill="#9B8881"
          opacity="1.000000"
          stroke="none"
          d="
M632.996887,445.735596
	C632.595581,445.581726 632.221558,445.135498 631.729980,444.408844
	C633.266785,443.295929 632.884766,444.649475 632.996887,445.735596
z"
        />
        <path
          fill="#9B8881"
          opacity="1.000000"
          stroke="none"
          d="
M635.962524,450.726929
	C635.196411,450.898773 634.906006,450.354675 634.873901,449.339630
	C635.291504,449.487122 635.624634,449.955963 635.962524,450.726929
z"
        />
        <path
          fill="#9B8881"
          opacity="1.000000"
          stroke="none"
          d="
M636.966919,452.724823
	C636.589600,452.567017 636.210327,452.106110 635.895325,451.329620
	C636.294678,451.483246 636.629822,451.952484 636.966919,452.724823
z"
        />
        <path
          fill="#9B8881"
          opacity="1.000000"
          stroke="none"
          d="
M633.981323,447.717407
	C633.612610,447.565491 633.254456,447.100922 632.926392,446.324524
	C633.301636,446.476685 633.646790,446.940704 633.981323,447.717407
z"
        />
        <path
          fill="#9B8881"
          opacity="1.000000"
          stroke="none"
          d="
M637.881592,453.793976
	C637.651733,453.884277 637.336365,453.735657 636.992798,453.303955
	C637.241760,453.198914 637.518921,453.376984 637.881592,453.793976
z"
        />
        <path
          fill="#9B8881"
          opacity="1.000000"
          stroke="none"
          d="
M634.893066,448.788818
	C634.661194,448.886841 634.349976,448.737701 634.000366,448.303833
	C634.245911,448.193298 634.529846,448.367493 634.893066,448.788818
z"
        />
        <path
          fill="#9B8881"
          opacity="1.000000"
          stroke="none"
          d="
M646.863770,464.755798
	C646.666016,464.764282 646.337769,464.505768 645.981567,463.912903
	C646.213501,463.881958 646.473389,464.185364 646.863770,464.755798
z"
        />
        <path
          fill="#9B8881"
          opacity="1.000000"
          stroke="none"
          d="
M645.154663,462.856079
	C644.975037,462.912628 644.585449,462.763123 644.087463,462.312378
	C644.300964,462.224121 644.622742,462.437042 645.154663,462.856079
z"
        />
        <path
          fill="#847876"
          opacity="1.000000"
          stroke="none"
          d="
M608.023071,445.903320
	C607.966003,445.506256 607.941162,445.491852 607.927551,445.501923
	C609.317078,444.616272 609.207458,442.988190 609.880371,441.344421
	C612.024597,443.171844 610.185059,446.105194 612.014526,448.904358
	C613.488647,450.544861 613.236450,452.093933 614.010864,453.688599
	C614.195435,454.278900 614.255127,454.522980 614.363708,455.146912
	C614.436829,455.858063 614.477966,456.188904 614.485718,456.520477
	C614.529358,458.391266 616.108582,461.243683 613.697205,461.814972
	C610.687866,462.527924 610.504578,459.125977 609.845764,457.071991
	C608.728577,453.588989 608.622620,449.900604 608.023071,445.903320
z"
        />
        <path
          fill="#87655F"
          opacity="1.000000"
          stroke="none"
          d="
M606.733398,437.688171
	C603.345947,434.446960 605.695618,429.975464 604.968689,425.468567
	C608.361267,426.295563 606.533936,429.206116 606.875977,431.374207
	C607.176025,433.276306 606.886108,435.271484 606.733398,437.688171
z"
        />
        <path
          fill="#87655F"
          opacity="1.000000"
          stroke="none"
          d="
M606.262207,421.414459
	C606.121216,418.888428 606.121216,416.641327 606.121216,414.394226
	C606.851562,416.830048 607.294800,419.261658 606.262207,421.414459
z"
        />
        <path
          fill="#79675E"
          opacity="1.000000"
          stroke="none"
          d="
M628.826416,450.965424
	C628.312988,450.510498 628.148499,450.196533 627.988159,449.689697
	C627.881470,449.095032 627.693909,448.733032 627.200073,448.203003
	C626.970947,447.995026 627.006836,448.003784 626.998901,448.020813
	C627.609558,444.502258 624.774475,441.530365 625.304321,436.515076
	C628.254761,443.232117 630.656738,448.700378 632.991150,454.630127
	C631.674194,453.763214 630.424744,452.434784 628.826416,450.965424
z"
        />
        <path
          fill="#79675E"
          opacity="1.000000"
          stroke="none"
          d="
M624.863281,434.706421
	C624.601990,434.874359 624.376282,434.833435 624.150513,434.792542
	C624.224060,434.501923 624.297546,434.211273 624.371033,433.920593
	C624.546936,434.112946 624.722900,434.305267 624.863281,434.706421
z"
        />
        <path
          fill="#625457"
          opacity="1.000000"
          stroke="none"
          d="
M635.550049,457.475342
	C635.584534,457.534119 635.515625,457.416534 635.550049,457.475342
z"
        />
        <path
          fill="#625457"
          opacity="1.000000"
          stroke="none"
          d="
M636.936279,459.876831
	C636.824341,459.921295 636.731201,459.738159 636.638062,459.555023
	C636.743774,459.586426 636.849487,459.617828 636.936279,459.876831
z"
        />
        <path
          fill="#625457"
          opacity="1.000000"
          stroke="none"
          d="
M637.062134,459.955750
	C637.199646,460.082886 637.285828,460.256470 637.371948,460.430054
	C637.275085,460.398315 637.178223,460.366547 636.999390,460.219604
	C636.917419,460.104431 637.010803,460.002228 637.062134,459.955750
z"
        />
        <path
          fill="#79675E"
          opacity="1.000000"
          stroke="none"
          d="
M633.063232,454.949219
	C633.208069,455.084473 633.304016,455.264862 633.399963,455.445221
	C633.294434,455.413025 633.188904,455.380829 633.003540,455.220123
	C632.923645,455.091614 633.014404,454.994354 633.063232,454.949219
z"
        />
        <path
          fill="#6D5A5B"
          opacity="1.000000"
          stroke="none"
          d="
M620.644165,452.519623
	C621.859131,451.823395 622.275330,448.108521 623.594482,451.897705
	C624.191528,452.004089 624.488892,452.139923 624.808533,452.783813
	C624.967041,453.023895 625.000000,453.003723 624.984009,453.010620
	C625.724121,453.702606 625.388855,454.660492 625.778442,455.751343
	C625.971924,456.017120 626.000000,456.001221 625.986694,456.011230
	C626.214111,456.220978 626.441406,456.434998 626.808716,456.840210
	C626.962219,457.017151 626.995850,457.007782 626.984009,457.021057
	C627.711243,457.751862 627.859741,458.837982 628.763794,459.802673
	C628.967102,460.025940 629.000000,460.003662 628.984436,460.012085
	C629.518250,460.511108 629.482483,461.252716 629.862915,462.184814
	C629.967651,462.508881 629.943481,462.537018 629.948975,462.554718
	C630.904358,463.490906 631.465271,464.729553 632.716309,465.821136
	C632.975403,466.028931 633.000000,466.000214 632.983887,466.009491
	C633.434143,466.425049 633.481384,467.013336 633.802002,467.787476
	C633.969116,468.019470 634.000000,468.001221 633.985535,468.012695
	C634.676697,469.503479 636.777405,470.070801 636.541016,472.439331
	C635.833923,473.612518 635.051331,473.657043 633.999512,473.097290
	C633.824280,472.926788 633.540039,472.544128 633.537354,472.806488
	C633.463989,473.733490 633.059387,474.157196 632.174438,474.566406
	C631.593018,474.635010 631.353210,474.618103 630.770142,474.472229
	C625.176575,468.153870 621.933777,460.997559 620.644165,452.519623
z"
        />
        <path
          fill="#6D5A5B"
          opacity="1.000000"
          stroke="none"
          d="
M613.991699,425.428772
	C614.004639,424.993286 614.325439,424.841400 614.480347,424.757874
	C615.466309,433.817719 618.075623,442.532867 621.050293,451.547241
	C621.086304,451.925751 620.840271,452.110992 620.716309,452.202393
	C616.891846,450.945526 616.289490,447.436066 615.037048,443.968079
	C614.835144,439.046692 612.914856,434.710938 613.680603,429.719513
	C613.872009,428.182709 613.925415,427.023468 613.991699,425.428772
z"
        />
        <path
          fill="#A19796"
          opacity="1.000000"
          stroke="none"
          d="
M635.972046,473.357361
	C635.345703,471.558289 634.669250,470.134247 633.981934,468.367188
	C634.481506,468.264771 634.991943,468.505371 635.759277,468.868713
	C637.210022,469.509857 637.982666,470.460754 638.812744,471.790131
	C638.994141,472.041412 639.010193,471.994324 638.985352,471.993225
	C639.205872,472.211884 639.442871,472.440430 639.839111,472.858948
	C640.006775,473.040192 640.009644,472.988098 639.983765,472.991699
	C640.709229,474.253143 641.885986,475.175354 642.736511,476.780762
	C642.812378,477.933258 642.547058,478.605316 641.577148,479.250793
	C638.097656,478.769440 636.400452,476.822601 635.972046,473.357361
z"
        />
        <path
          fill="#554441"
          opacity="1.000000"
          stroke="none"
          d="
M642.223389,477.336182
	C641.290527,475.894318 640.587402,474.776154 639.921143,473.326630
	C640.889404,473.082764 641.626038,474.747925 642.371765,473.899384
	C643.029663,473.150787 641.672363,472.300171 641.075195,471.228394
	C642.702332,471.008392 643.736206,472.149414 644.842041,473.708466
	C644.868652,474.169159 644.740234,474.338287 644.820190,474.748413
	C645.028564,474.989349 645.000000,475.000732 645.010376,474.989441
	C645.686340,475.921204 645.888977,476.890808 644.865845,478.143799
	C643.695801,478.574799 642.984131,478.381165 642.223389,477.336182
z"
        />
        <path
          fill="#705250"
          opacity="1.000000"
          stroke="none"
          d="
M619.018372,432.959229
	C620.345947,435.169647 620.428284,437.630859 620.752441,440.691284
	C618.197510,438.411865 619.311951,435.986816 618.898132,433.484222
	C618.869934,433.076202 618.973755,433.004059 619.018372,432.959229
z"
        />
        <path
          fill="#554441"
          opacity="1.000000"
          stroke="none"
          d="
M638.967590,471.731689
	C637.635986,471.700806 637.128296,470.467499 636.182068,469.255920
	C638.192383,468.355988 638.348145,470.152039 638.967590,471.731689
z"
        />
        <path
          fill="#A19796"
          opacity="1.000000"
          stroke="none"
          d="
M628.949463,459.700684
	C627.399414,460.059875 627.040649,458.984436 626.919922,457.386353
	C628.162659,457.364990 628.715759,458.229187 628.949463,459.700684
z"
        />
        <path
          fill="#A19796"
          opacity="1.000000"
          stroke="none"
          d="
M632.867615,465.706116
	C631.130615,466.069397 630.810425,464.470856 630.011719,462.948853
	C631.426392,462.973663 632.148071,464.123657 632.867615,465.706116
z"
        />
        <path
          fill="#705250"
          opacity="1.000000"
          stroke="none"
          d="
M620.858215,444.391907
	C619.611694,445.079010 620.287170,444.042572 620.161377,443.715515
	C620.090393,443.530884 620.379761,443.207733 620.506714,442.946960
	C620.637817,443.353577 620.768982,443.760193 620.858215,444.391907
z"
        />
        <path
          fill="#A19796"
          opacity="1.000000"
          stroke="none"
          d="
M626.006470,455.677917
	C625.030945,455.518646 624.857300,454.637268 624.889771,453.354736
	C625.877380,453.536255 626.041260,454.399567 626.006470,455.677917
z"
        />
        <path
          fill="#A19796"
          opacity="1.000000"
          stroke="none"
          d="
M633.971863,467.717377
	C633.594971,467.561554 633.220764,467.103638 632.907166,466.332275
	C633.303345,466.484283 633.638977,466.949768 633.971863,467.717377
z"
        />
        <path
          fill="#A19796"
          opacity="1.000000"
          stroke="none"
          d="
M624.970886,452.780426
	C624.661255,452.873230 624.355408,452.722595 623.913086,452.335632
	C624.495544,451.370270 624.836121,451.677063 624.970886,452.780426
z"
        />
        <path
          fill="#A19796"
          opacity="1.000000"
          stroke="none"
          d="
M629.988525,462.148438
	C629.587341,461.918610 629.206970,461.328369 628.897705,460.379303
	C629.315674,460.609680 629.662537,461.198853 629.988525,462.148438
z"
        />
        <path
          fill="#554441"
          opacity="1.000000"
          stroke="none"
          d="
M627.074585,448.284485
	C627.265076,448.286194 627.559204,448.577362 627.922791,449.182678
	C627.720947,449.189178 627.449646,448.881531 627.074585,448.284485
z"
        />
        <path
          fill="#A19796"
          opacity="1.000000"
          stroke="none"
          d="
M626.892151,456.770630
	C626.667053,456.859619 626.371948,456.702118 626.025085,456.282898
	C626.256287,456.188812 626.539185,456.356445 626.892151,456.770630
z"
        />
        <path
          fill="#554441"
          opacity="1.000000"
          stroke="none"
          d="
M639.895081,472.808563
	C639.689819,472.878876 639.372864,472.717560 639.008179,472.274170
	C639.234802,472.187042 639.509094,472.381989 639.895081,472.808563
z"
        />
        <path
          fill="#9B8881"
          opacity="1.000000"
          stroke="none"
          d="
M613.575928,429.614441
	C613.658386,434.107941 615.195374,438.614166 615.952454,443.649139
	C615.602417,444.724579 615.856567,445.427307 615.786987,446.468140
	C615.324402,450.008972 616.451782,452.892548 617.510437,455.787415
	C618.284119,457.903168 619.455750,459.950104 618.338379,462.626984
	C613.293884,461.968872 614.490906,457.656128 613.220093,454.285187
	C613.001221,453.967255 612.982483,454.007233 613.003418,454.014374
	C612.013000,452.646057 612.759705,451.038940 612.213257,449.262878
	C611.988464,448.953156 611.996155,448.983673 612.007324,448.968811
	C612.018433,446.723633 612.018433,444.493317 612.018433,441.574188
	C612.815308,442.764130 613.192993,443.328094 613.769043,444.188263
	C614.524780,439.146362 611.941345,434.567383 613.575928,429.614441
z"
        />
        <path
          fill="#15313E"
          opacity="1.000000"
          stroke="none"
          d="
M421.269592,625.768127
	C416.123352,627.846558 410.907898,627.959595 405.462616,629.031494
	C399.984070,631.907104 394.823578,631.884460 389.328735,629.246582
	C388.946259,628.679016 388.821014,628.434143 388.595367,627.789490
	C388.512573,627.115784 388.530151,626.841858 388.756958,626.213440
	C395.310150,624.884949 401.654175,623.911011 408.408905,622.910645
	C410.544434,620.036194 414.452881,620.336365 416.622314,617.479980
	C418.609009,616.754517 420.332245,616.436340 422.439331,616.178406
	C423.762115,616.583740 424.569031,617.241516 425.895905,617.692871
	C427.645111,622.244629 423.940552,623.471252 421.269592,625.768127
z"
        />
        <path
          fill="#337484"
          opacity="1.000000"
          stroke="none"
          d="
M401.222717,614.750854
	C400.191193,615.240784 399.396332,615.492676 398.315735,615.877686
	C396.878479,616.043274 395.481842,615.660522 394.613678,616.172974
	C386.638153,620.880615 382.323273,614.687256 377.176361,610.219727
	C376.855316,606.235535 377.909302,603.204407 381.691223,601.203125
	C381.980988,600.979980 381.997559,601.000000 381.992615,600.989868
	C382.230042,600.771912 382.461304,600.552429 382.844604,600.146362
	C383.007751,599.971436 383.006439,600.008423 382.988861,600.002686
	C385.985840,598.802917 389.154510,598.610779 392.717346,598.780518
	C393.933929,599.062439 394.620026,599.348877 394.958038,600.528564
	C394.529877,601.857056 393.593445,602.191284 392.197449,602.414001
	C391.181885,602.593994 391.965637,602.283936 391.343018,602.508484
	C388.426880,603.560242 383.405029,602.851135 383.736725,607.069824
	C384.002014,610.443542 388.631592,610.071777 392.004700,610.531006
	C393.238739,610.585876 394.066681,610.334595 395.172913,609.817810
	C395.912750,609.522156 396.357605,609.412598 397.166687,609.336304
	C398.679901,609.568726 399.915924,609.599121 401.087280,610.778198
	C401.601837,611.148254 401.766754,611.347351 401.911926,611.800293
	C402.142029,612.899658 402.142487,613.775452 401.222717,614.750854
z"
        />
        <path
          fill="#478D9B"
          opacity="1.000000"
          stroke="none"
          d="
M397.598755,619.818970
	C398.801666,619.794617 399.538849,619.847168 400.644409,619.938232
	C394.519043,621.518372 388.001251,624.683716 381.601868,619.829834
	C386.776123,620.090393 391.952057,620.181946 397.598755,619.818970
z"
        />
        <path
          fill="#214A59"
          opacity="1.000000"
          stroke="none"
          d="
M416.839050,617.970276
	C415.830078,621.906860 412.115753,621.409485 409.198151,622.873840
	C407.930603,621.472473 406.110657,622.553040 405.081299,621.545654
	C405.679535,620.094910 408.267334,622.165649 408.170837,619.409363
	C410.831024,618.043945 413.552277,616.966492 416.839050,617.970276
z"
        />
        <path
          fill="#557E8B"
          opacity="1.000000"
          stroke="none"
          d="
M417.454285,618.200500
	C414.269257,617.425415 411.687836,619.268738 408.518677,619.097778
	C407.328583,619.091980 406.544556,619.047119 405.368958,618.991821
	C406.149384,617.417114 408.146240,618.421631 409.795654,617.286499
	C413.788696,616.517883 417.516907,615.981934 421.607544,615.298706
	C421.969971,615.151489 422.385040,615.191833 422.593506,615.188354
	C423.292328,615.467041 423.512451,615.889893 423.255615,616.778992
	C421.227478,617.007324 419.466705,617.240784 417.454285,618.200500
z"
        />
        <path
          fill="#47A2AE"
          opacity="1.000000"
          stroke="none"
          d="
M381.613525,600.927490
	C380.185303,603.635620 377.741974,605.951355 377.292053,609.674316
	C374.962860,605.275085 377.215210,602.661316 381.613525,600.927490
z"
        />
        <path
          fill="#47A2AE"
          opacity="1.000000"
          stroke="none"
          d="
M392.782440,598.757080
	C390.003815,599.358337 386.917053,599.710388 383.400787,600.029663
	C385.816406,597.441956 389.128418,597.866882 392.782440,598.757080
z"
        />
        <path
          fill="#76D4DF"
          opacity="1.000000"
          stroke="none"
          d="
M390.925354,601.643250
	C392.086945,601.155212 393.153687,601.039307 394.620972,600.871216
	C396.702545,601.299133 398.383514,601.779236 400.432007,602.332764
	C400.941254,602.661621 401.122864,602.884644 401.452454,603.263550
	C401.558136,603.713562 401.555756,603.975037 401.222961,604.446533
	C397.706604,605.255920 394.898254,603.793884 391.637695,602.817566
	C391.151398,602.414368 390.990967,602.214905 390.925354,601.643250
z"
        />
        <path
          fill="#214A59"
          opacity="1.000000"
          stroke="none"
          d="
M388.709839,625.634766
	C388.979126,626.041382 388.992065,626.223877 389.016449,626.680664
	C386.662781,628.936279 384.713409,628.265381 382.999146,625.567322
	C382.986298,624.936157 382.986359,624.749634 382.991241,624.283569
	C384.815216,624.472839 386.634369,624.941772 388.709839,625.634766
z"
        />
        <path
          fill="#557E8B"
          opacity="1.000000"
          stroke="none"
          d="
M401.353180,619.997437
	C401.772339,618.663513 403.096069,619.055664 404.629333,618.957886
	C404.152008,620.096802 402.914368,619.992065 401.353180,619.997437
z"
        />
        <path
          fill="#47A2AE"
          opacity="1.000000"
          stroke="none"
          d="
M382.763062,600.056885
	C382.827515,600.249207 382.647247,600.526978 382.227325,600.892212
	C382.164551,600.700562 382.341461,600.421448 382.763062,600.056885
z"
        />
        <path
          fill="#478D9B"
          opacity="1.000000"
          stroke="none"
          d="
M379.544922,618.549561
	C379.489990,618.581360 379.599823,618.517822 379.544922,618.549561
z"
        />
        <path
          fill="#2D3037"
          opacity="1.000000"
          stroke="none"
          d="
M419.007568,610.407166
	C419.899414,610.846558 420.804779,611.644897 421.837646,612.721069
	C420.396423,613.029114 419.065094,612.746277 419.007568,610.407166
z"
        />
        <path
          fill="#76D4DF"
          opacity="1.000000"
          stroke="none"
          d="
M402.233337,604.429443
	C405.840759,606.557983 406.516266,608.601990 401.786682,610.308838
	C401.201904,610.300964 400.976746,610.280823 400.644958,609.938293
	C400.544403,608.258728 400.891876,606.956299 401.307831,605.330811
	C401.595947,604.853760 401.751770,604.689575 402.233337,604.429443
z"
        />
        <path
          fill="#246876"
          opacity="1.000000"
          stroke="none"
          d="
M401.310547,614.954468
	C401.153137,614.137512 401.320221,613.286133 401.728851,612.212036
	C402.704742,612.171021 404.072266,612.348572 404.073517,612.535217
	C404.083618,614.050964 402.870453,614.539124 401.310547,614.954468
z"
        />
        <path
          fill="#76D4DF"
          opacity="1.000000"
          stroke="none"
          d="
M401.387085,610.841919
	C400.026062,610.937012 398.693512,610.859985 397.181915,610.425781
	C397.944489,608.996399 399.087769,608.966187 400.670166,609.725159
	C401.014526,610.024780 401.218353,610.173157 401.322723,610.247131
	C401.427094,610.321106 401.415558,610.669861 401.387085,610.841919
z"
        />
        <path
          fill="#76D4DF"
          opacity="1.000000"
          stroke="none"
          d="
M401.416504,603.584961
	C401.854492,603.556030 402.105255,603.724304 402.436005,604.145264
	C402.559113,604.333557 402.282104,604.699402 402.123291,604.864990
	C401.497681,605.172913 401.127899,605.031494 400.920715,604.323364
	C401.000153,604.000000 401.272461,603.717957 401.416504,603.584961
z"
        />
        <path
          fill="#2D3037"
          opacity="1.000000"
          stroke="none"
          d="
M421.372375,626.039185
	C420.853699,622.415100 425.573212,622.042175 425.924927,618.375244
	C425.796661,617.852112 425.628265,617.681458 425.721954,617.264526
	C428.846863,617.802673 430.860565,619.064575 429.005127,622.776611
	C427.486359,623.941345 426.014862,624.804871 424.237793,625.794434
	C423.201202,625.960144 422.470215,625.999756 421.372375,626.039185
z"
        />
        <path
          fill="#214A59"
          opacity="1.000000"
          stroke="none"
          d="
M425.707153,616.874023
	C425.985718,617.185730 425.987427,617.353149 425.977081,617.771667
	C425.138885,617.868347 424.312744,617.713867 423.267761,617.332031
	C423.009247,616.674866 422.969574,616.245117 422.865967,615.500122
	C424.011475,615.132629 424.598877,616.138794 425.707153,616.874023
z"
        />
        <path
          fill="#3E6978"
          opacity="1.000000"
          stroke="none"
          d="
M382.606995,625.062073
	C384.442535,626.618225 386.284607,627.008972 388.652283,626.962402
	C389.027863,626.954834 389.030212,626.983948 389.042908,626.987366
	C389.460602,628.550903 388.845520,629.693420 387.301575,630.770874
	C384.146332,630.597961 382.495331,628.264954 380.126343,626.532776
	C379.837067,626.047058 379.723297,625.835510 379.770447,625.301514
	C380.696808,624.986572 381.462280,624.994019 382.606995,625.062073
z"
        />
        <path
          fill="#214A59"
          opacity="1.000000"
          stroke="none"
          d="
M379.566803,625.040283
	C379.939484,625.192200 379.947662,625.405212 379.953308,625.938477
	C379.590393,626.089539 379.229950,625.920410 378.678375,625.520020
	C378.725586,625.226257 378.963928,625.163879 379.566803,625.040283
z"
        />
        <path
          fill="#214A59"
          opacity="1.000000"
          stroke="none"
          d="
M387.366333,631.200195
	C386.909119,629.485046 387.975159,628.604248 388.907166,627.284424
	C391.708038,631.435791 395.984802,629.876160 399.803711,629.815247
	C401.269165,629.791931 402.727112,629.298828 404.618103,629.034546
	C405.020447,629.820068 404.993134,630.590759 404.950348,631.749634
	C399.200653,631.791931 393.395569,633.174255 387.366333,631.200195
z"
        />
        <path
          fill="#76D4DF"
          opacity="1.000000"
          stroke="none"
          d="
M316.545410,274.927429
	C317.565277,273.689056 319.071625,273.838287 320.815582,274.930939
	C319.658722,275.136261 318.303650,275.042847 316.545410,274.927429
z"
        />
        <path
          fill="#85EAF4"
          opacity="1.000000"
          stroke="none"
          d="
M316.982422,247.788879
	C314.254333,248.064346 311.523346,248.112595 308.339813,248.246063
	C307.260010,248.164673 306.233673,248.162796 306.083527,247.799164
	C305.699432,246.868942 306.621735,246.947922 307.194672,246.950470
	C310.144470,246.963623 313.094147,247.006790 316.495178,247.073914
	C316.946472,247.108337 316.979492,247.561646 316.982422,247.788879
z"
        />
        <path
          fill="#59B1BB"
          opacity="1.000000"
          stroke="none"
          d="
M316.141357,267.147949
	C315.512878,268.575470 314.334564,267.878113 313.139893,267.626587
	C313.965393,266.721954 314.973480,267.253418 316.141357,267.147949
z"
        />
        <path
          fill="#48505A"
          opacity="1.000000"
          stroke="none"
          d="
M558.408630,240.997025
	C558.312012,240.502945 558.527588,239.994431 558.883545,239.244446
	C559.504333,239.308121 560.376160,239.592804 560.393372,239.922180
	C560.449707,240.998077 559.435791,240.846680 558.408630,240.997025
z"
        />
        <path
          fill="#48505A"
          opacity="1.000000"
          stroke="none"
          d="
M559.297607,236.944061
	C558.955811,236.579010 558.900391,236.162704 558.815674,235.433823
	C559.445801,235.124725 560.206421,235.099274 560.372742,235.882492
	C560.429749,236.151337 559.864746,236.552246 559.297607,236.944061
z"
        />
        <path
          fill="#33444C"
          opacity="1.000000"
          stroke="none"
          d="
M577.114990,204.195526
	C577.302124,204.109207 577.546448,204.295959 577.838501,204.761108
	C577.648682,204.845520 577.410706,204.655212 577.114990,204.195526
z"
        />
        <path
          fill="#557E8B"
          opacity="1.000000"
          stroke="none"
          d="
M553.422607,293.052246
	C550.549194,293.121918 548.033142,293.094727 546.332947,291.068756
	C545.315552,289.856415 546.904907,290.213806 547.730286,289.963806
	C549.801941,287.895050 552.263794,288.353973 554.537781,288.205627
	C561.155273,287.773987 567.668030,286.551910 574.556641,285.547821
	C575.418762,285.024567 576.438171,285.052460 576.060547,283.696167
	C575.693420,281.749329 572.976440,279.756561 576.817505,278.739624
	C580.883301,278.568909 578.469727,282.730194 580.783447,283.809814
	C582.551758,286.022156 581.852600,288.235077 581.412109,290.723083
	C576.721924,293.829529 571.514954,292.179413 566.679260,293.270691
	C562.620422,294.186707 558.123840,293.704742 553.422607,293.052246
z"
        />
        <path
          fill="#6494A3"
          opacity="1.000000"
          stroke="none"
          d="
M552.758362,293.190002
	C554.935669,291.210175 557.722900,291.291199 559.583069,291.597931
	C566.577637,292.751404 573.353455,290.076111 580.734985,291.026733
	C579.890198,294.827057 576.503967,293.580139 573.411621,294.130920
	C572.111755,294.247925 571.224304,294.295227 570.038391,294.497437
	C563.117432,295.209351 556.429749,294.719574 549.423218,295.943726
	C548.544495,295.954407 548.088074,295.913879 547.298828,295.787201
	C547.941345,292.795258 550.899597,294.802612 552.758362,293.190002
z"
        />
        <path
          fill="#48505A"
          opacity="1.000000"
          stroke="none"
          d="
M564.731323,258.059204
	C565.098083,258.506744 565.099426,258.934387 565.066833,259.683044
	C563.967041,259.964783 562.901184,259.925507 561.444580,259.807800
	C561.464233,257.806732 562.978210,258.046875 564.731323,258.059204
z"
        />
        <path
          fill="#6494A3"
          opacity="1.000000"
          stroke="none"
          d="
M545.005005,261.703857
	C544.821960,261.949158 544.647095,261.958374 544.209229,261.973145
	C544.139038,261.705261 544.331970,261.431763 544.767944,261.076904
	C545.010986,260.995514 545.013062,261.467834 545.005005,261.703857
z"
        />
        <path
          fill="#557E8B"
          opacity="1.000000"
          stroke="none"
          d="
M556.271057,267.280090
	C552.759460,264.380707 548.469116,267.650513 545.210144,264.579834
	C556.169739,263.898560 567.215088,263.496643 578.619873,262.122314
	C579.960632,262.751617 580.106934,263.600159 579.380005,264.811829
	C571.929810,268.326019 564.234192,267.775696 556.271057,267.280090
z"
        />
        <path
          fill="#847876"
          opacity="1.000000"
          stroke="none"
          d="
M576.473389,285.664978
	C575.819946,287.272797 574.178467,287.713409 572.863525,287.699707
	C564.802856,287.615784 557.095825,291.130798 548.493530,289.960205
	C545.477173,287.571289 545.968628,283.423157 549.140076,282.384827
	C548.036194,282.121857 547.121948,281.904114 546.108948,281.662811
	C547.035889,280.330017 549.165344,281.636597 549.744751,279.505127
	C553.835815,276.308563 558.450806,277.788635 562.813660,277.570160
	C567.247131,277.348145 571.702271,277.904633 576.539124,277.788452
	C576.930481,278.064545 576.990540,278.506744 577.052124,278.720886
	C574.282166,280.233551 578.266357,281.828156 576.903687,283.526367
	C571.983276,285.863831 566.735107,284.534424 561.741211,285.605865
	C561.061523,285.515045 561.989563,286.553192 561.684937,285.960205
	C561.594788,285.784729 561.713684,285.664429 562.043274,285.654327
	C566.751221,285.509277 571.434753,283.933899 576.473389,285.664978
z"
        />
        <path
          fill="#77878D"
          opacity="1.000000"
          stroke="none"
          d="
M551.345581,278.185425
	C549.747681,276.278595 547.713623,276.224609 545.421021,275.579041
	C547.253967,275.050293 548.442749,274.707397 549.839233,274.093414
	C550.057251,272.828491 550.663574,272.244415 551.548340,272.230164
	C556.096924,272.156982 560.654358,271.344849 565.563110,272.638123
	C564.983093,276.488220 569.344299,274.194763 569.851807,276.608826
	C564.061035,278.831940 557.871582,278.262085 551.345581,278.185425
z"
        />
        <path
          fill="#77878D"
          opacity="1.000000"
          stroke="none"
          d="
M561.243103,271.248779
	C557.372437,268.743835 553.203247,271.086487 549.304077,269.111267
	C549.183777,268.370026 549.052612,267.684479 549.743652,267.698486
	C558.626587,267.878815 567.402893,265.823395 576.654114,266.756104
	C577.003662,267.473846 577.004089,267.874725 577.004700,268.576202
	C576.650513,269.438751 576.118225,269.759064 575.552368,270.092194
	C573.975159,271.020599 571.519409,269.283875 571.084717,270.137787
	C569.677612,272.901703 567.341919,270.697601 565.347046,271.772858
	C563.786255,271.982239 562.641479,272.054688 561.243103,271.248779
z"
        />
        <path
          fill="#457483"
          opacity="1.000000"
          stroke="none"
          d="
M564.979492,271.421143
	C569.024353,271.752930 572.115112,267.745636 576.590576,268.916473
	C578.620789,268.978271 580.105347,269.302368 579.875610,271.409851
	C579.729248,272.752991 579.319031,274.171844 577.908386,274.635773
	C576.544189,275.084412 575.554443,275.198425 574.425842,273.285248
	C572.888977,270.679932 569.591858,272.510345 566.668823,272.140564
	C565.690674,272.468231 565.244568,272.355896 564.979492,271.421143
z"
        />
        <path
          fill="#5D696E"
          opacity="1.000000"
          stroke="none"
          d="
M550.547302,277.962769
	C557.001770,277.616272 563.056763,277.289673 569.574097,276.919769
	C572.305908,276.630524 574.570618,276.291016 576.846863,276.172729
	C578.267883,276.098938 579.270996,276.535919 577.249878,277.992096
	C568.245605,279.333282 559.494873,278.873047 550.282104,279.124298
	C549.060913,279.146515 548.326416,279.105408 547.226685,279.003784
	C546.861450,278.943268 546.673706,278.539398 546.812561,278.365295
	C548.016907,278.121674 549.082397,278.052185 550.547302,277.962769
z"
        />
        <path
          fill="#496A76"
          opacity="1.000000"
          stroke="none"
          d="
M577.319031,266.994263
	C567.852905,266.547638 558.795166,268.055573 549.359863,268.556976
	C547.933777,268.791840 546.736938,269.270905 545.567322,267.907562
	C548.711609,266.327881 551.978821,267.327850 555.507263,266.936371
	C563.393494,266.293427 570.829285,265.651611 578.724487,265.019897
	C578.995789,265.928284 578.609070,266.663239 577.319031,266.994263
z"
        />
        <path
          fill="#617179"
          opacity="1.000000"
          stroke="none"
          d="
M564.640869,271.015808
	C565.286743,271.307495 565.520569,271.589996 565.999268,272.013702
	C566.244141,272.154907 566.083008,272.547577 566.007446,272.745605
	C560.854065,273.079803 555.776306,273.215942 550.372803,273.587219
	C548.643005,273.784393 547.089294,274.458038 545.738831,272.692322
	C550.440186,270.680298 555.270264,271.070251 560.482910,270.958252
	C562.032776,270.964417 563.130859,270.985535 564.640869,271.015808
z"
        />
        <path
          fill="#535C64"
          opacity="1.000000"
          stroke="none"
          d="
M530.562683,258.496948
	C530.650024,259.273895 530.387573,259.712677 529.853210,259.542816
	C529.148560,259.318878 529.019897,258.637024 529.378479,258.074615
	C529.724670,257.531769 530.144775,257.911041 530.562683,258.496948
z"
        />
        <path
          fill="#535C64"
          opacity="1.000000"
          stroke="none"
          d="
M531.320312,265.221771
	C531.007324,265.000000 530.518982,265.012115 530.275085,265.014008
	C530.742310,264.000153 531.612000,263.844971 532.824890,264.778320
	C533.012268,265.020905 533.004456,264.991425 533.018799,264.992157
	C532.637512,265.363708 532.170898,265.513885 531.320312,265.221771
z"
        />
        <path
          fill="#535C64"
          opacity="1.000000"
          stroke="none"
          d="
M527.463013,262.531616
	C527.440125,262.489990 527.485901,262.573212 527.463013,262.531616
z"
        />
        <path
          fill="#3E6978"
          opacity="1.000000"
          stroke="none"
          d="
M553.542969,241.677658
	C549.665894,242.281845 547.695862,240.548843 547.449280,237.238708
	C547.285278,235.036865 548.162537,232.964050 550.675110,232.255508
	C553.446777,231.473877 555.260132,233.080536 556.367981,235.167572
	C557.857727,237.974197 556.985779,240.290405 553.542969,241.677658
z"
        />
        <path
          fill="#5B7D8C"
          opacity="1.000000"
          stroke="none"
          d="
M531.164185,265.208954
	C531.457336,265.005798 531.907349,265.011597 532.695190,265.005157
	C532.591431,265.654083 532.058167,265.946411 531.164185,265.208954
z"
        />
        <path
          fill="#AA8367"
          opacity="1.000000"
          stroke="none"
          d="
M513.112427,254.666168
	C511.019531,259.006561 506.926361,258.635834 503.328186,258.905640
	C499.055481,259.225983 494.741302,258.999603 490.445099,258.997009
	C488.629547,258.995911 486.757935,258.783356 485.180389,260.789001
	C482.638123,257.428101 482.603455,253.553787 483.626831,249.257935
	C488.589691,247.762482 493.317139,248.886963 497.648651,248.565384
	C493.585785,248.008102 488.907898,249.384415 484.219849,247.277100
	C481.736603,245.581635 481.621490,244.235199 484.418304,242.979614
	C492.256409,241.798096 499.934113,243.349976 507.446228,241.670319
	C508.565704,241.420013 509.750183,241.812927 510.868622,243.073944
	C511.062805,243.503174 511.021057,243.522568 511.033875,243.504700
	C511.996124,246.138245 513.498169,248.994278 508.413940,249.772278
	C500.894531,251.356537 493.705170,251.906982 486.442841,251.387115
	C493.406891,252.010956 500.292267,251.055817 507.540436,250.258514
	C509.428070,249.429398 511.510864,248.559769 512.127075,250.572525
	C512.942871,253.237137 510.058777,253.188202 508.555725,253.558441
	C503.299377,254.853165 497.857239,254.540207 493.546967,255.000809
	C498.231445,254.365494 504.081421,254.929062 509.716309,253.293716
	C510.695404,253.009567 511.678619,252.992386 512.840637,253.863510
	C513.105774,254.119949 513.127808,254.484299 513.112427,254.666168
z"
        />
        <path
          fill="#C99469"
          opacity="1.000000"
          stroke="none"
          d="
M513.125366,253.657822
	C504.064728,255.966599 494.978516,256.647247 485.830750,255.740067
	C485.789307,255.306122 485.747894,254.872162 485.706451,254.438217
	C491.275085,253.949539 496.836761,253.332733 502.415497,253.030365
	C504.916168,252.894821 507.371490,252.768936 509.763000,252.010986
	C510.462677,251.789230 511.722839,251.792023 511.008301,250.629410
	C510.715271,250.152695 509.521362,250.229691 508.346680,250.004700
	C510.442322,248.888840 511.400421,246.987534 511.032562,243.920197
	C515.057434,246.007492 512.330811,249.984192 513.125366,253.657822
z"
        />
        <path
          fill="#E1B582"
          opacity="1.000000"
          stroke="none"
          d="
M511.352356,243.287460
	C504.474091,243.672287 497.886047,243.892899 491.296234,243.978851
	C489.339142,244.004379 487.306732,244.435333 485.070496,243.201843
	C484.724640,242.921158 484.876099,242.465500 484.942871,242.234879
	C487.707916,239.973984 490.980804,240.158279 494.012238,240.106140
	C499.740814,240.007629 505.504181,239.475571 511.607666,240.743317
	C511.879211,241.701019 511.760559,242.386368 511.352356,243.287460
z"
        />
        <path
          fill="#AA8367"
          opacity="1.000000"
          stroke="none"
          d="
M511.899597,240.624420
	C503.278381,240.864914 494.572876,241.075272 485.452759,242.013947
	C485.274750,239.932816 486.899933,239.192856 488.532776,239.173477
	C495.938019,239.085663 503.345856,239.187943 510.751892,239.285736
	C511.105865,239.290405 511.451752,239.903442 511.899597,240.624420
z"
        />
        <path
          fill="#C99469"
          opacity="1.000000"
          stroke="none"
          d="
M483.883850,247.360641
	C489.045898,248.167435 494.309204,248.058914 499.574310,248.995804
	C494.661316,248.995804 489.748322,248.995804 484.354767,248.968292
	C483.877930,248.519455 483.881653,248.098145 483.883850,247.360641
z"
        />
        <path
          fill="#3C7D8E"
          opacity="1.000000"
          stroke="none"
          d="
M458.779449,274.383881
	C455.590759,274.826355 452.411255,274.648590 449.275482,274.940491
	C445.412262,275.300171 443.641876,273.951660 443.967865,269.949005
	C444.129059,267.969788 443.919739,265.960419 443.832214,263.512695
	C444.621643,260.871216 442.517395,257.223114 445.461700,256.487122
	C449.024323,255.596512 452.979706,256.077362 456.745026,256.302002
	C458.123169,256.384216 457.886871,258.075073 457.953644,259.190796
	C458.248505,264.117249 458.524902,269.044800 458.779449,274.383881
z"
        />
        <path
          fill="#CD7D4C"
          opacity="1.000000"
          stroke="none"
          d="
M598.933594,198.425308
	C593.955444,198.827377 589.008057,198.876907 583.612915,198.879929
	C583.110596,198.130249 583.055969,197.427078 582.998779,196.369110
	C583.196045,191.862488 582.582764,187.685913 583.570679,183.150284
	C585.579773,180.315262 588.238831,181.053085 590.630859,181.012115
	C595.085876,180.935806 599.543640,181.021912 604.458130,181.081573
	C604.807617,182.612732 603.710999,182.793045 602.541992,183.145432
	C601.214844,183.545486 599.101257,184.010223 601.442505,185.941315
	C602.932068,187.169891 601.707092,187.683075 600.842773,187.824005
	C598.889221,188.142593 597.911194,188.957245 598.057922,191.077621
	C598.225708,193.500854 600.101135,193.757812 602.640808,194.529297
	C600.032532,194.977188 598.720337,195.938126 598.933594,198.425308
z"
        />
        <path
          fill="#B27252"
          opacity="1.000000"
          stroke="none"
          d="
M583.420776,182.940826
	C583.952087,186.965988 584.398315,191.188309 583.265991,195.700562
	C581.445923,191.710846 581.251953,187.415802 583.420776,182.940826
z"
        />
        <path
          fill="#847876"
          opacity="1.000000"
          stroke="none"
          d="
M546.812561,278.235260
	C546.951416,278.191193 546.927917,278.570679 546.894653,278.756958
	C546.861450,278.943268 546.563782,278.649353 546.563782,278.649353
	C546.563782,278.649353 546.673706,278.279327 546.812561,278.235260
z"
        />
        <path
          fill="#6D5A5B"
          opacity="1.000000"
          stroke="none"
          d="
M577.138306,403.427063
	C577.068359,405.333069 576.991882,406.793091 576.882690,408.689148
	C576.610168,409.346466 576.402466,409.594635 576.144897,410.098694
	C576.134521,410.611298 576.168457,410.899323 576.164185,411.402527
	C574.975891,418.961853 574.289124,426.422333 571.158875,433.711853
	C569.017639,434.393585 568.486450,433.240845 568.505737,431.215973
	C568.720703,430.468262 568.941956,430.079773 569.206970,429.716736
	C569.080627,429.908691 568.942505,430.092712 568.658752,430.608093
	C567.530273,434.474609 565.021301,435.431824 562.017212,433.997955
	C559.204285,432.655273 559.631104,430.378082 561.736877,427.934540
	C562.180359,423.226501 566.409424,420.197021 565.810425,415.189453
	C565.500671,414.368927 565.121155,413.958191 565.144714,413.466034
	C565.588379,404.196503 564.943604,394.886078 566.401550,385.256714
	C566.721497,379.890991 566.821594,374.915283 566.933960,369.473816
	C566.946167,369.008026 567.422668,369.028961 567.660034,369.042969
	C568.031616,378.378815 568.165833,387.700684 568.345703,397.393494
	C571.591736,404.607513 570.011047,411.635803 569.270813,418.027924
	C571.100220,410.419739 569.731262,401.947693 571.261597,393.352600
	C571.320740,390.653198 571.282715,388.297516 571.323975,385.508789
	C571.721619,385.108582 572.040588,385.117706 572.600830,385.117371
	C572.929504,388.729034 573.017578,392.326508 573.119507,396.372192
	C575.000671,402.653107 573.700378,408.521393 573.583679,413.706818
	C574.853882,408.078247 572.910950,401.477448 574.989563,395.215546
	C575.251221,394.427155 575.383728,393.624908 576.512451,393.081360
	C577.005737,396.380676 577.068787,399.680878 577.138306,403.427063
z"
        />
        <path
          fill="#A5654C"
          opacity="1.000000"
          stroke="none"
          d="
M568.744263,430.216522
	C568.993774,431.430115 569.024292,432.829224 570.708069,433.892456
	C571.489868,434.367432 571.962646,434.679443 572.725891,435.043640
	C572.361572,437.451538 571.706787,439.807251 570.983765,442.565674
	C569.320557,443.267914 567.413513,442.285828 566.098206,444.462677
	C563.904724,446.270050 563.174011,444.785858 563.535034,443.229736
	C564.076294,440.896362 562.742188,442.810944 562.077637,442.583282
	C559.554688,441.591614 557.407410,440.531586 555.376099,438.539062
	C555.138306,438.191986 554.790405,438.378448 554.672729,438.565247
	C554.301331,439.200928 554.009338,439.624023 553.481201,440.254028
	C553.097839,440.602814 552.926453,440.728302 552.466064,441.004913
	C548.221863,441.712585 545.044495,443.701416 542.057373,446.746002
	C539.892456,448.268982 538.467285,450.427521 535.455750,450.192688
	C534.568054,449.100372 533.627686,448.897095 532.267273,448.774536
	C531.606567,445.904022 534.083557,444.928406 535.810791,443.032410
	C536.211060,442.616760 536.382446,442.461304 536.834595,442.102356
	C541.476013,440.033356 544.135132,436.157410 548.066711,432.693573
	C548.852783,432.176544 549.069214,432.077850 549.624451,431.870972
	C550.194458,431.720398 550.425659,431.678040 551.004272,431.596436
	C554.433350,431.396881 556.998413,430.017670 559.692993,428.066833
	C560.448853,427.911804 560.873291,427.852051 561.602478,427.678375
	C562.416016,429.395874 559.449951,431.817627 562.487549,433.074249
	C565.049377,434.134094 567.122070,432.781586 568.744263,430.216522
z"
        />
        <path
          fill="#625457"
          opacity="1.000000"
          stroke="none"
          d="
M529.522400,446.568176
	C528.656738,443.867340 531.382751,442.886108 531.930908,440.481262
	C533.895630,435.795074 538.429749,434.186890 541.526917,431.073090
	C542.429260,430.165955 543.549072,429.383087 544.743896,428.212738
	C544.979431,427.954742 544.997437,428.002747 544.975464,427.989502
	C545.148499,427.786499 545.343445,427.596771 545.821899,427.700500
	C547.272705,428.019562 548.458862,428.008087 548.902954,429.969391
	C548.888794,430.512939 549.159180,430.902832 549.333923,431.074585
	C549.557129,431.406677 549.605591,431.567017 549.633545,431.981842
	C549.525452,432.383484 549.437866,432.530640 549.143433,432.830261
	C548.936707,432.982727 548.994080,433.006653 548.999023,432.975952
	C544.682983,435.557007 541.713501,439.798523 537.222290,442.740662
	C536.971252,442.973450 536.995117,443.000000 536.979858,442.986877
	C534.521301,444.075348 533.014221,446.950775 529.522400,446.568176
z"
        />
        <path
          fill="#372624"
          opacity="1.000000"
          stroke="none"
          d="
M566.324158,384.898560
	C567.119019,392.814514 567.292542,400.749268 566.067993,408.663940
	C565.792358,410.445587 565.898254,412.286224 565.790588,414.444489
	C563.230042,414.276489 564.078674,412.252289 564.070312,410.760742
	C564.023865,402.447571 564.074402,394.133820 564.164185,385.341492
	C564.833374,384.885254 565.430115,384.907837 566.324158,384.898560
z"
        />
        <path
          fill="#6D5A5B"
          opacity="1.000000"
          stroke="none"
          d="
M560.014282,427.541748
	C558.996521,432.580414 555.187134,432.550354 551.253967,432.966125
	C550.311829,432.140381 550.214722,431.301239 551.026733,430.185852
	C551.660034,429.682983 551.913757,429.413849 552.333618,429.060364
	C552.902283,428.879669 553.261353,428.697754 553.782349,428.202759
	C553.987732,427.975403 553.997803,428.004913 553.983887,427.997253
	C554.490234,427.550537 554.492004,426.890839 554.835632,426.039490
	C554.880798,421.930664 558.719360,420.697845 560.651489,417.906219
	C560.435669,420.974792 560.219910,424.043335 560.014282,427.541748
z"
        />
        <path
          fill="#1A2026"
          opacity="1.000000"
          stroke="none"
          d="
M541.827637,377.178528
	C542.421326,377.002502 542.858887,377.000336 543.623901,376.997559
	C543.962097,379.367767 543.972961,381.738647 543.950562,384.552429
	C543.491577,384.996490 543.065857,384.997620 542.320068,384.998413
	C541.788086,384.763092 541.568909,384.534973 541.171265,384.149567
	C541.001343,383.546600 541.002686,383.107880 541.002014,382.340088
	C541.477661,381.568756 541.514343,381.021149 541.188599,380.212952
	C541.001038,379.551361 541.002075,379.108795 541.001587,378.334351
	C541.233276,377.795502 541.457092,377.578827 541.827637,377.178528
z"
        />
        <path
          fill="#847876"
          opacity="1.000000"
          stroke="none"
          d="
M551.015503,429.936829
	C551.260681,430.698792 551.115051,431.445465 550.909546,432.568146
	C550.671204,432.926239 550.492737,432.908295 550.047974,432.851624
	C549.622253,432.662567 549.462830,432.512238 549.109924,432.096252
	C548.916443,431.830597 548.885986,431.393341 548.881104,431.174225
	C548.736145,430.768677 548.596008,430.582214 548.756836,430.217255
	C549.057678,430.038757 549.054443,430.069275 549.070068,430.081665
	C549.598694,430.036499 550.111694,429.979004 551.015503,429.936829
z"
        />
        <path
          fill="#8B9698"
          opacity="1.000000"
          stroke="none"
          d="
M546.268311,426.038086
	C546.333191,425.725555 546.954651,425.216614 547.014893,425.276031
	C547.680969,425.933746 546.891663,425.908051 546.268311,426.038086
z"
        />
        <path
          fill="#847876"
          opacity="1.000000"
          stroke="none"
          d="
M554.723145,425.963501
	C554.819031,426.377686 554.650757,426.988434 554.226257,427.794373
	C554.133057,427.379761 554.296021,426.769928 554.723145,425.963501
z"
        />
        <path
          fill="#8B9698"
          opacity="1.000000"
          stroke="none"
          d="
M546.132690,427.670654
	C545.913391,427.995911 545.721375,427.997864 545.241394,427.988037
	C544.892517,427.541016 544.831604,427.105743 544.782227,426.333801
	C544.990112,425.986481 545.186401,425.975830 545.677612,425.967834
	C545.972412,425.970520 546.022339,426.018066 546.050415,426.041138
	C546.105591,426.491913 546.132751,426.919617 546.132690,427.670654
z"
        />
        <path
          fill="#847876"
          opacity="1.000000"
          stroke="none"
          d="
M553.678955,428.030579
	C553.697327,428.263428 553.406982,428.551453 552.808228,428.907776
	C552.789917,428.679260 553.080017,428.382507 553.678955,428.030579
z"
        />
        <path
          fill="#3E1E1C"
          opacity="1.000000"
          stroke="none"
          d="
M553.085571,352.779938
	C552.911621,352.843811 552.740417,352.679047 552.614624,352.484833
	C552.589661,352.446350 552.739319,352.294739 552.981689,352.129761
	C553.154968,352.064240 553.104248,352.540955 553.085571,352.779938
z"
        />
        <path
          fill="#8B9698"
          opacity="1.000000"
          stroke="none"
          d="
M548.515320,423.554871
	C548.480591,423.575073 548.550049,423.534668 548.515320,423.554871
z"
        />
        <path
          fill="#3E1E1C"
          opacity="1.000000"
          stroke="none"
          d="
M562.843262,334.102600
	C562.981079,334.003723 562.985413,334.502808 562.985046,334.752319
	C562.891602,334.735046 562.798523,334.468262 562.843262,334.102600
z"
        />
        <path
          fill="#473235"
          opacity="1.000000"
          stroke="none"
          d="
M571.042969,393.194672
	C572.789612,403.100677 572.056885,413.085846 569.872864,422.998047
	C569.733643,423.629700 569.646973,424.403595 568.778442,424.394012
	C567.788574,424.383087 567.184998,423.584503 567.235657,422.791687
	C567.382446,420.496887 567.557434,418.170654 568.076599,415.939362
	C569.417542,410.176056 567.830994,404.433075 568.281494,398.226166
	C571.061035,397.310760 569.262512,394.476257 571.042969,393.194672
z"
        />
        <path
          fill="#473235"
          opacity="1.000000"
          stroke="none"
          d="
M576.987732,392.606079
	C575.273071,395.856049 575.050415,398.933075 575.029419,402.065582
	C574.998718,406.635284 575.266785,411.242035 573.030518,417.068298
	C573.030518,409.693268 573.030518,403.741119 573.081970,397.304688
	C573.412842,396.066528 573.932312,395.313873 573.936279,394.558533
	C574.019104,378.637238 574.043823,362.715607 574.096924,346.421204
	C574.514343,346.038147 574.914795,346.027954 575.615051,346.026428
	C575.807129,361.412262 577.591980,376.743561 576.987732,392.606079
z"
        />
        <path
          fill="#554441"
          opacity="1.000000"
          stroke="none"
          d="
M788.953308,599.318848
	C790.018677,598.980347 791.098328,598.979980 792.580994,598.973022
	C797.459229,600.818115 801.934509,602.669800 806.686096,604.743408
	C806.974426,605.713135 806.986389,606.460999 807.010132,607.581116
	C806.166687,608.177307 805.311523,608.401184 804.259399,608.893066
	C803.614258,609.767090 802.981628,610.086121 801.938599,610.389038
	C801.361389,610.510315 801.129333,610.546082 800.552734,610.641907
	C799.065552,610.882812 797.938293,611.133179 796.480591,611.525146
	C794.982483,612.027283 793.816833,612.192078 792.315430,611.597778
	C791.316711,608.087585 792.092834,604.305725 789.008789,601.348267
	C788.969666,600.558838 788.968628,600.107910 788.953308,599.318848
z"
        />
        <path
          fill="#929EAC"
          opacity="1.000000"
          stroke="none"
          d="
M791.691406,610.509399
	C793.140137,611.003174 794.244568,611.044922 795.615051,610.321167
	C797.946350,613.772827 794.644775,614.150269 792.873291,615.710938
	C793.846069,617.743164 795.435730,618.158936 797.640259,618.032837
	C798.013367,618.001953 798.000061,617.997620 797.997253,617.991821
	C798.677368,618.823120 799.672668,619.189819 800.791260,619.922424
	C800.403870,622.770752 798.042053,622.713257 796.016052,623.227539
	C796.710144,624.392883 797.324646,625.424622 797.968018,626.789307
	C795.273621,627.747803 792.550293,628.373413 789.443420,629.057495
	C788.303223,629.107239 787.546692,629.098450 786.413330,629.095337
	C785.591980,626.059998 782.860901,627.504578 781.014526,626.572449
	C783.553223,625.644287 785.902527,624.785339 788.578003,623.807190
	C786.701721,620.998718 784.267639,620.987427 781.422485,621.015869
	C780.924500,620.842407 780.822266,620.667236 780.759644,620.211731
	C777.535950,616.567749 777.524902,616.544067 782.207886,614.361084
	C783.847412,613.596802 784.920959,612.055603 786.874634,611.143921
	C787.589111,610.699585 788.023376,610.487061 788.827087,610.194092
	C789.934631,609.944336 790.666016,610.007751 791.691406,610.509399
z"
        />
        <path
          fill="#759EB0"
          opacity="1.000000"
          stroke="none"
          d="
M793.344727,616.000000
	C792.466248,615.618469 790.529297,616.685486 791.632507,614.999390
	C792.698059,613.371155 795.500366,613.369446 795.942261,610.579102
	C797.066895,609.915039 798.189819,609.588684 799.661133,609.266357
	C800.176819,609.320435 800.344116,609.370483 800.761169,609.341797
	C801.773132,609.208374 802.535339,609.153625 803.679993,609.130005
	C805.865234,610.733643 808.834473,609.329285 810.644226,612.146729
	C805.410706,615.256836 799.748291,616.168274 793.344727,616.000000
z"
        />
        <path
          fill="#5C8997"
          opacity="1.000000"
          stroke="none"
          d="
M800.885376,619.833862
	C799.888123,619.949524 798.597961,620.089111 798.042358,618.330811
	C799.185242,618.014343 800.118896,618.506042 800.885376,619.833862
z"
        />
        <path
          fill="#5C8997"
          opacity="1.000000"
          stroke="none"
          d="
M814.148804,619.645142
	C814.609863,619.355408 815.214905,619.241943 815.825195,619.178955
	C815.908020,619.170410 816.017029,619.414978 816.113892,619.542358
	C815.507385,619.640686 814.900879,619.738953 814.148804,619.645142
z"
        />
        <path
          fill="#4E5963"
          opacity="1.000000"
          stroke="none"
          d="
M336.757263,490.771912
	C340.687042,491.463074 344.439056,491.687012 347.930664,493.384430
	C344.701141,493.786560 341.888977,497.001526 338.180420,494.303070
	C337.103394,492.064545 335.371094,491.876556 333.558624,491.999786
	C331.619507,492.131622 330.104340,491.607208 329.158051,489.356018
	C330.076416,489.029175 331.155365,489.030884 332.638611,489.048767
	C333.929321,490.205292 335.485901,489.769531 336.757263,490.771912
z"
        />
        <path
          fill="#59646C"
          opacity="1.000000"
          stroke="none"
          d="
M336.993225,490.673462
	C335.635773,490.845245 334.023804,491.481506 333.118439,489.405640
	C334.093597,489.054169 335.144287,489.043427 336.589264,489.043793
	C336.988647,489.486389 336.993713,489.917877 336.993225,490.673462
z"
        />
        <path
          fill="#20323A"
          opacity="1.000000"
          stroke="none"
          d="
M541.000793,380.330048
	C541.634888,380.440643 541.941711,380.966705 541.205688,381.853149
	C541.000549,381.562775 541.001038,381.114471 541.000793,380.330048
z"
        />
        <path
          fill="#20323A"
          opacity="1.000000"
          stroke="none"
          d="
M541.778564,377.126617
	C541.863098,377.309204 541.673645,377.562286 541.207703,377.883179
	C541.126953,377.702332 541.318115,377.451050 541.778564,377.126617
z"
        />
        <path
          fill="#20323A"
          opacity="1.000000"
          stroke="none"
          d="
M541.119629,384.196838
	C541.305298,384.115479 541.558533,384.312500 541.879883,384.787231
	C541.694031,384.869202 541.440430,384.672638 541.119629,384.196838
z"
        />
        <path
          fill="#8B9698"
          opacity="1.000000"
          stroke="none"
          d="
M544.625366,427.877716
	C542.230713,433.362183 536.717407,435.836670 532.250000,439.919250
	C531.189758,440.429779 530.395630,440.724060 529.282227,440.991516
	C533.006104,435.344299 539.055969,432.057678 544.625366,427.877716
z"
        />
        <path
          fill="#310604"
          opacity="1.000000"
          stroke="none"
          d="
M705.321655,581.976746
	C704.952271,581.217346 704.981262,580.471741 705.024658,579.352783
	C706.058350,577.595154 707.584167,577.710815 709.070801,577.469666
	C722.833069,575.237244 736.793945,574.494385 750.518127,572.078125
	C758.035034,570.754700 765.489441,569.018921 772.992126,567.567078
	C775.105164,567.158203 777.205017,566.418762 779.674438,567.718994
	C778.288330,572.207092 773.845093,573.623474 770.613586,575.205872
	C760.206482,580.301575 749.926819,585.982910 737.397949,585.327637
	C732.551392,585.074097 727.338501,584.799622 722.270935,585.859436
	C718.000061,586.752686 713.640564,585.835205 709.902893,583.263916
	C708.595581,582.364563 707.262207,581.930847 705.321655,581.976746
z"
        />
        <path
          fill="#59200F"
          opacity="1.000000"
          stroke="none"
          d="
M780.326538,568.002197
	C769.358582,570.162231 758.763611,572.584839 748.060486,574.353027
	C740.091125,575.669617 731.991821,576.255188 723.931580,576.947937
	C717.918030,577.464844 712.145081,580.071533 705.498535,578.980103
	C704.723267,578.533936 704.407471,578.088379 704.061768,577.360718
	C705.195251,574.008606 708.147217,574.490784 710.937866,573.736816
	C725.761353,571.522888 740.114868,569.062744 754.528687,567.030823
	C763.525269,565.762634 772.199341,562.809509 781.306702,562.210144
	C782.310669,562.144104 783.266296,561.953918 784.537720,562.721313
	C784.902100,563.159607 784.925537,563.332703 784.979370,563.766235
	C785.073975,566.031494 782.559570,565.744446 782.033875,567.612793
	C781.523804,567.950867 781.088440,567.967346 780.326538,568.002197
z"
        />
        <path
          fill="#4C393B"
          opacity="1.000000"
          stroke="none"
          d="
M841.122314,512.648193
	C839.298523,517.230164 834.683105,517.503418 831.423889,519.305359
	C828.411438,520.970947 826.504822,522.900513 825.160339,525.733765
	C824.142883,527.877991 823.032532,529.849182 820.341553,530.865601
	C817.298279,529.740417 818.292053,527.903320 819.453125,526.249756
	C820.755066,524.395569 822.359802,522.757812 823.651855,520.463928
	C823.912170,519.944519 824.027039,519.742493 824.337524,519.255493
	C826.111450,514.896667 827.275574,510.624146 830.442688,506.944641
	C831.117859,506.468018 831.565491,506.330200 832.394531,506.318756
	C833.872864,506.685852 834.707886,507.600800 836.166504,508.055725
	C838.208801,509.402374 840.324158,510.146423 841.122314,512.648193
z"
        />
        <path
          fill="#6D5A5B"
          opacity="1.000000"
          stroke="none"
          d="
M841.384644,513.040771
	C839.461121,511.851929 837.892578,510.711853 836.155273,509.290527
	C835.354065,508.128937 834.238403,508.048309 833.194641,507.269684
	C832.518494,507.069611 832.075562,507.062714 831.300720,507.074524
	C830.708008,507.152679 830.447144,507.212097 829.876892,507.015686
	C829.165100,506.019684 829.112061,505.249390 829.586426,504.127075
	C834.016724,502.633545 837.782959,504.513184 842.041260,505.599426
	C843.349915,505.876465 844.216919,506.325287 845.508972,506.591492
	C846.088928,506.696350 846.317932,506.753540 846.880981,506.926392
	C848.488525,507.644592 849.758789,508.251801 850.805481,509.712402
	C849.041504,511.597687 849.681885,512.984009 851.570801,513.604187
	C853.790771,514.333008 853.832642,515.552917 853.061279,517.628540
	C848.890564,517.199097 845.552368,514.594666 841.384644,513.040771
z"
        />
        <path
          fill="#372F35"
          opacity="1.000000"
          stroke="none"
          d="
M824.656738,519.669312
	C824.727417,522.644409 823.070190,524.467285 821.279053,526.241455
	C820.140381,527.369263 818.550171,528.405090 819.893005,530.715942
	C820.032532,531.013000 820.007446,531.510071 819.996399,531.758667
	C818.215759,532.676331 818.536987,533.989563 818.984253,535.692383
	C818.083801,539.099060 818.275024,542.184204 818.585754,545.300232
	C818.765015,547.098145 818.687256,548.932129 817.267944,550.765259
	C813.440491,547.502747 813.662537,543.001953 814.166931,538.708679
	C814.855286,532.849426 815.243591,526.805847 818.956787,521.375122
	C820.294983,518.886475 822.102356,518.438354 824.656738,519.669312
z"
        />
        <path
          fill="#33444C"
          opacity="1.000000"
          stroke="none"
          d="
M816.970947,551.422729
	C817.838318,545.955322 814.919861,540.670593 817.936279,535.375244
	C818.261047,534.406067 818.759521,534.093811 819.680176,533.947388
	C820.480286,533.967224 820.975281,533.973206 821.841187,533.994141
	C821.741577,538.841064 820.616394,543.757446 824.054749,548.442383
	C826.209656,554.962341 820.769470,554.364685 817.353027,555.945068
	C816.982544,554.600403 816.983398,553.218811 816.970947,551.422729
z"
        />
        <path
          fill="#759EB0"
          opacity="1.000000"
          stroke="none"
          d="
M824.469604,548.868835
	C819.541931,544.861450 818.687805,539.536804 822.015625,534.346191
	C822.489380,533.957336 822.707458,533.814819 822.942749,533.396240
	C823.136719,533.035034 823.254395,532.859253 823.670166,532.792236
	C825.628540,533.149719 827.790100,532.521057 828.018066,535.620300
	C822.826599,536.950439 821.644775,539.752075 824.635925,544.870728
	C825.677856,547.851135 827.436462,547.255127 829.616821,546.122559
	C830.348816,545.603210 830.829834,545.440857 831.743347,545.586975
	C832.507996,546.085327 832.778381,546.528503 832.966309,547.439697
	C832.953125,548.367554 832.775513,548.856140 832.200073,549.583618
	C829.468811,550.339111 827.133850,549.860229 824.469604,548.868835
z"
        />
        <path
          fill="#8396A6"
          opacity="1.000000"
          stroke="none"
          d="
M837.430237,529.637817
	C834.674927,534.288391 831.753967,530.475220 828.503540,530.011353
	C827.823059,528.635315 827.630188,527.219910 828.345398,526.063843
	C829.695496,523.881287 832.908752,523.154114 833.343811,525.185730
	C833.945068,527.993347 835.975281,528.016357 837.430237,529.637817
z"
        />
        <path
          fill="#4C393B"
          opacity="1.000000"
          stroke="none"
          d="
M819.988647,533.635742
	C819.515320,534.287170 819.045288,534.613098 818.279541,534.972290
	C816.831055,533.252014 815.745483,531.529053 819.678711,531.973633
	C819.987549,532.441650 819.989746,532.875977 819.988647,533.635742
z"
        />
        <path
          fill="#33444C"
          opacity="1.000000"
          stroke="none"
          d="
M825.005127,531.254395
	C825.159973,531.179199 825.321716,531.332275 825.434937,531.515381
	C825.463257,531.561035 825.335449,531.703430 825.131409,531.887207
	C824.983398,531.973083 824.994080,531.493835 825.005127,531.254395
z"
        />
        <path
          fill="#33444C"
          opacity="1.000000"
          stroke="none"
          d="
M823.978699,532.671631
	C823.968262,532.900879 823.488647,533.039124 823.253784,533.124878
	C823.197449,532.899963 823.375916,532.589172 823.782654,532.131104
	C824.010925,531.983765 823.989136,532.442322 823.978699,532.671631
z"
        />
        <path
          fill="#5C8997"
          opacity="1.000000"
          stroke="none"
          d="
M832.205566,545.921021
	C831.501099,546.017822 831.052002,546.006836 830.265991,545.971130
	C830.738647,543.423157 831.548096,540.900024 832.934265,538.206543
	C834.967224,538.650574 836.579346,538.478149 838.395142,539.069031
	C839.832581,539.872192 840.602905,540.744629 840.268311,542.465820
	C837.573730,543.285217 838.314819,545.976135 837.019714,547.303955
	C835.792908,548.561646 834.725281,550.046021 832.303406,549.903687
	C831.946533,549.453491 831.964966,549.038696 831.991699,548.311890
	C832.061951,547.251709 833.819153,546.860779 832.205566,545.921021
z"
        />
        <path
          fill="#6E96A8"
          opacity="1.000000"
          stroke="none"
          d="
M840.438782,542.732178
	C839.681274,541.977417 839.291321,541.175415 838.698364,540.185486
	C837.405090,539.235046 836.108398,538.929199 834.658386,538.178101
	C834.261047,537.798828 834.126282,537.623169 833.867188,537.139404
	C835.514038,533.435913 838.654236,534.458923 840.486084,535.814758
	C842.592346,537.373718 843.223816,540.269470 840.438782,542.732178
z"
        />
        <path
          fill="#7DA7B8"
          opacity="1.000000"
          stroke="none"
          d="
M824.996826,545.020508
	C825.019836,544.537781 825.064331,544.046814 825.027649,543.561951
	C824.885071,541.676270 825.021729,539.846985 827.118835,539.127014
	C828.310303,538.717957 828.954346,539.676392 829.043457,540.729065
	C829.242859,543.083374 828.763794,545.036011 825.373657,544.997314
	C824.971191,544.982910 824.981018,545.015076 824.996826,545.020508
z"
        />
        <path
          fill="#759EB0"
          opacity="1.000000"
          stroke="none"
          d="
M828.004150,535.996826
	C828.112671,536.133606 828.216614,536.274048 828.320557,536.414490
	C828.232605,536.371277 828.144714,536.328003 828.023804,536.147827
	C827.990845,536.010864 827.999573,536.000488 828.004150,535.996826
z"
        />
        <path
          fill="#5C8997"
          opacity="1.000000"
          stroke="none"
          d="
M832.964417,537.955566
	C832.977722,537.583130 833.141235,537.321045 833.553772,536.963867
	C833.742920,536.831360 833.932434,537.035645 833.998535,537.158325
	C834.000000,537.497070 833.935303,537.713135 833.554688,538.049805
	C833.238647,538.170471 833.054749,538.028564 832.964417,537.955566
z"
        />
        <path
          fill="#2C1E22"
          opacity="1.000000"
          stroke="none"
          d="
M810.671875,522.650391
	C811.266846,527.813782 808.621582,532.102234 808.415771,536.900024
	C808.325562,539.003784 807.884216,541.139648 808.091797,543.259521
	C808.660522,549.066589 807.521301,550.880615 801.370483,552.985962
	C798.600159,553.476135 797.426453,551.753174 797.247742,550.040527
	C796.393433,541.854065 793.462585,534.124695 792.377502,525.635010
	C798.113586,522.041626 804.137451,521.564270 810.671875,522.650391
z"
        />
        <path
          fill="#1F0F0F"
          opacity="1.000000"
          stroke="none"
          d="
M811.317993,522.826050
	C805.055786,522.927307 799.386597,524.671570 793.290283,525.714722
	C791.167114,528.141663 788.310913,527.293579 785.679443,528.368042
	C784.168701,528.506104 783.067078,528.471497 781.553833,528.475708
	C780.030579,528.520874 778.930542,528.491760 777.557495,527.768188
	C777.051147,527.331238 776.889038,527.115662 776.718628,526.499512
	C777.363464,524.804932 778.721497,524.570923 780.200073,523.882690
	C783.506104,522.468323 787.241882,523.976501 789.757324,520.881653
	C791.020996,518.053589 793.278137,518.515747 795.365356,518.897034
	C797.470886,519.281677 799.475952,520.142151 801.632385,520.500183
	C799.802368,519.041321 794.223328,519.960083 798.185486,514.891846
	C798.903259,513.973633 797.530212,514.228027 797.174255,514.012939
	C795.617432,513.072144 794.962585,512.029968 796.658081,510.241455
	C804.510437,508.192169 810.263916,512.630920 816.704712,515.849731
	C817.079285,516.354431 817.194031,516.575378 817.328125,517.159912
	C816.669983,518.511475 815.757080,519.028503 814.533997,518.924683
	C815.742859,519.077026 816.615234,518.484375 817.664978,517.404907
	C820.799438,516.155334 823.313721,516.328918 825.023560,519.574646
	C824.966064,519.984985 824.981018,519.998657 824.979492,519.986450
	C823.237183,520.277161 821.496338,520.579956 819.381836,520.948792
	C816.381470,520.789917 813.903931,521.225342 811.317993,522.826050
z"
        />
        <path
          fill="#3E2423"
          opacity="1.000000"
          stroke="none"
          d="
M784.795044,527.346924
	C787.355286,526.336243 789.709656,525.785278 792.585938,525.983215
	C795.755188,533.367188 798.406555,540.767883 798.255127,548.850586
	C798.227661,550.316467 799.150635,551.681396 800.700684,552.822510
	C800.700073,554.412231 800.397705,555.830017 800.040161,557.606384
	C798.488098,557.832397 796.991211,557.699829 795.221130,557.310791
	C792.715088,555.857117 791.587463,553.939941 791.445190,551.461060
	C790.960144,543.009277 789.096069,534.963745 784.795044,527.346924
z"
        />
        <path
          fill="#813920"
          opacity="1.000000"
          stroke="none"
          d="
M776.479614,527.261841
	C777.941528,527.384766 779.021851,527.307739 780.508057,527.258118
	C783.817200,528.280090 785.959167,530.244690 786.123474,533.289978
	C786.477173,539.843262 789.593445,545.662170 790.663879,552.003601
	C791.052979,554.308838 791.703674,556.596375 789.252380,558.719910
	C787.778076,559.503662 786.600037,560.000000 785.192505,560.715637
	C781.897888,562.891846 778.548767,562.673157 774.764771,562.023193
	C772.051453,560.056152 772.412354,557.428223 772.190430,554.992371
	C771.426514,546.608032 771.507812,537.993469 765.537231,530.596802
	C768.106567,526.042053 772.194214,526.866028 776.479614,527.261841
z"
        />
        <path
          fill="#964F38"
          opacity="1.000000"
          stroke="none"
          d="
M773.663452,560.737427
	C777.345032,560.988892 780.700134,560.985168 784.509033,560.958130
	C784.959106,561.391541 784.955322,561.848145 784.915100,562.645630
	C764.666443,566.570740 744.461670,570.198303 724.235657,573.702820
	C720.164978,574.408081 716.013367,574.646118 711.464111,575.118103
	C709.466492,571.794617 713.064697,571.973877 714.558472,570.258728
	C722.288696,567.344971 730.064148,566.504089 737.740662,565.222961
	C739.204529,564.978638 740.711060,565.027527 742.459717,564.177979
	C746.366028,563.996887 749.870972,563.727234 753.787537,563.500183
	C758.992859,563.267944 763.726929,562.713135 768.153015,560.670105
	C769.864868,559.879944 771.565918,559.368591 773.663452,560.737427
z"
        />
        <path
          fill="#632B1D"
          opacity="1.000000"
          stroke="none"
          d="
M789.332031,559.013245
	C791.039917,554.238403 788.441772,549.760071 787.228210,545.590332
	C786.217896,542.118958 784.922852,538.861938 784.884216,535.156616
	C784.853516,532.212524 783.369690,529.817139 781.022583,527.598938
	C782.007202,527.192871 783.100464,527.100220 784.606079,527.009094
	C791.711426,532.751282 791.765686,540.972656 792.906250,548.700317
	C793.294067,551.327576 793.159851,553.992920 794.808228,556.694275
	C793.081421,557.389587 791.906494,559.569275 789.332031,559.013245
z"
        />
        <path
          fill="#E1B582"
          opacity="1.000000"
          stroke="none"
          d="
M852.615295,497.015015
	C847.042847,499.643127 841.435303,499.849365 835.862305,494.694214
	C841.338562,493.285614 845.542969,497.090210 850.672546,496.914703
	C851.489319,496.898865 851.897888,496.951233 852.615295,497.015015
z"
        />
        <path
          fill="#9B8881"
          opacity="1.000000"
          stroke="none"
          d="
M851.314331,510.023041
	C849.669800,509.550385 848.396790,509.065735 846.875122,508.343262
	C849.123596,505.582336 852.121704,505.718292 855.620972,506.743164
	C856.003662,507.003448 856.001953,507.002106 856.008667,507.008972
	C855.583557,509.476196 853.620483,509.723053 851.314331,510.023041
z"
        />
        <path
          fill="#705250"
          opacity="1.000000"
          stroke="none"
          d="
M857.958618,516.733521
	C857.519897,516.710938 857.039795,516.421875 856.559692,516.132812
	C856.724915,515.939148 856.890198,515.745544 857.055481,515.551941
	C857.342712,515.856934 857.629944,516.161987 857.958618,516.733521
z"
        />
        <path
          fill="#916E67"
          opacity="1.000000"
          stroke="none"
          d="
M855.965393,506.661560
	C853.260071,507.936157 850.425415,508.111237 847.092529,508.062195
	C846.626465,508.105408 846.403809,508.113037 846.293823,508.095215
	C845.418091,507.307037 844.393677,507.578552 843.181030,507.207031
	C838.561951,507.130463 834.997253,504.150787 830.333984,504.064911
	C828.168152,505.149719 826.565247,505.553406 825.950928,502.654755
	C826.208923,502.284119 826.245300,502.262726 826.261780,502.249023
	C828.255493,502.438690 830.232788,502.642090 832.591064,502.835541
	C835.942810,500.466156 838.547363,503.023041 841.677490,503.691467
	C845.473145,503.785126 848.961365,504.619293 852.711304,503.164368
	C855.055969,503.248840 855.473999,504.799255 855.965393,506.661560
z"
        />
        <path
          fill="#282025"
          opacity="1.000000"
          stroke="none"
          d="
M748.314453,428.007599
	C747.943359,427.839661 747.919067,427.617920 747.848511,427.064819
	C747.632446,424.327789 749.855164,423.694183 751.100952,422.364594
	C752.455566,420.918854 753.357483,419.290344 753.912720,417.041260
	C754.144104,416.487061 754.252991,416.269470 754.571655,415.749084
	C757.552979,408.333893 757.093262,401.116669 755.297607,393.521545
	C754.096741,390.691376 754.593384,388.077362 754.515869,385.515900
	C754.460449,383.681885 754.503479,381.881897 751.871826,381.123535
	C743.793884,376.632782 735.679077,378.997375 727.649719,379.529266
	C718.931580,380.106842 712.024902,383.992706 705.950928,390.150574
	C702.348083,393.803162 701.331970,392.820343 697.746643,389.207245
	C692.875000,384.297882 693.335876,379.273804 695.755127,373.360474
	C696.078064,372.716949 696.268250,372.457031 696.737366,372.123962
	C702.328369,371.719788 708.011047,373.230103 712.793213,369.332123
	C713.024292,369.143799 713.438843,369.063660 713.746277,369.102844
	C726.029785,370.670319 737.213257,363.340454 749.603088,364.808655
	C753.880615,365.315552 758.304321,363.522247 762.867249,364.844299
	C766.200378,365.809967 768.298584,367.424377 768.681702,370.739655
	C769.675781,379.342743 772.474487,387.787445 771.005676,396.617828
	C770.361877,400.488556 768.894958,404.126587 768.582581,408.151581
	C768.298218,411.817474 767.908813,415.306946 768.727722,418.970581
	C769.910034,424.260040 766.434143,427.447937 761.291809,425.422699
	C757.828064,424.058533 755.233032,424.760559 752.228882,426.740845
	C750.892090,427.323547 749.776672,427.638672 748.314453,428.007599
z"
        />
        <path
          fill="#262C32"
          opacity="1.000000"
          stroke="none"
          d="
M695.709717,373.132812
	C695.642029,375.807434 695.261841,378.581055 695.030273,381.367065
	C694.856873,383.454437 695.143555,385.392212 697.648010,386.060242
	C698.658081,386.329651 699.668213,386.936768 699.727295,387.972534
	C700.076965,394.097351 703.057678,390.976044 704.609375,389.082184
	C707.716248,385.290497 712.829041,383.753906 715.196777,379.268921
	C715.671936,378.368958 716.473022,377.934906 717.187683,378.448456
	C720.782349,381.031372 724.098022,378.595947 727.484741,377.981018
	C735.664001,376.495850 743.778625,375.977081 751.898804,379.325195
	C751.140625,381.517029 749.417175,381.391296 747.752991,381.502502
	C739.654968,382.043762 731.566223,382.721466 723.491089,383.520508
	C717.743835,384.089233 715.065063,387.164764 714.947205,393.009003
	C714.762878,402.146149 714.126648,411.258698 713.537109,420.803223
	C713.267944,422.924683 714.077576,424.588989 713.418152,426.637665
	C712.258789,427.491425 711.338989,427.994659 710.229309,428.746216
	C704.887573,432.785065 698.817200,432.507111 692.514465,432.924072
	C692.621582,413.862732 693.105042,394.743774 693.537109,375.205017
	C693.519470,374.613617 693.553284,374.441986 693.798462,374.114899
	C694.493530,373.718658 694.977295,373.477905 695.709717,373.132812
z"
        />
        <path
          fill="#8B9698"
          opacity="1.000000"
          stroke="none"
          d="
M693.903198,373.621033
	C694.009827,373.959412 693.771240,374.386627 693.628418,374.585938
	C692.816223,374.892365 692.146851,374.999481 691.137207,375.149963
	C690.510925,374.664581 690.224915,374.135773 689.840698,373.314148
	C691.197144,371.506958 692.562073,371.383240 693.903198,373.621033
z"
        />
        <path
          fill="#77878D"
          opacity="1.000000"
          stroke="none"
          d="
M713.454163,427.168762
	C712.775696,425.580322 712.372864,424.172394 712.009277,422.397278
	C712.661011,421.451965 713.388672,421.199280 714.602173,421.367828
	C719.007202,427.847443 725.058838,427.911530 731.542847,426.900482
	C739.170349,425.711212 746.495300,424.016113 751.528015,416.820557
	C752.596924,416.222534 753.370117,416.200958 754.461792,416.757843
	C755.081604,421.866516 751.091980,423.641113 748.005737,426.469849
	C741.543396,429.373047 735.072144,431.035461 728.269592,431.645599
	C722.756531,432.140106 717.704956,431.744873 713.454163,427.168762
z"
        />
        <path
          fill="#3E2423"
          opacity="1.000000"
          stroke="none"
          d="
M745.166016,474.835602
	C735.308105,473.107666 726.412964,468.564789 716.610779,467.378967
	C708.894897,466.445557 701.101074,466.254517 693.724609,463.150604
	C688.926392,461.131622 684.850891,458.017059 680.147827,455.293152
	C683.115967,452.445862 686.303040,455.137207 689.479858,455.137390
	C695.202881,455.137695 701.145203,453.570312 706.396667,458.468384
	C708.210876,460.160522 712.765686,458.914398 716.525757,459.012512
	C716.873108,461.987305 718.487061,463.558472 721.219482,464.207611
	C727.254883,465.641418 732.999512,468.102966 739.426819,468.131104
	C745.549438,468.157867 746.307983,469.367920 745.166016,474.835602
z"
        />
        <path
          fill="#693324"
          opacity="1.000000"
          stroke="none"
          d="
M689.741577,465.732300
	C700.017639,468.595001 710.075500,469.395813 720.313538,469.653839
	C724.854980,469.768311 729.295898,472.718506 733.838623,474.210571
	C740.892700,476.527527 747.990845,478.710785 755.086731,480.897583
	C755.476868,481.017792 756.016846,480.651703 756.740112,480.258575
	C759.457520,480.343811 761.230774,481.338409 761.008972,484.607056
	C760.448486,487.978394 758.244019,486.287628 756.310791,486.481659
	C755.130066,486.511627 754.365967,486.373199 753.255371,486.007477
	C749.159607,483.926758 745.237793,482.491272 740.750122,481.569580
	C737.904724,481.643616 736.010437,480.057983 733.829956,479.301422
	C723.689087,475.782593 713.188171,474.400421 702.511414,473.946838
	C697.701843,473.742493 698.829407,477.027863 698.745483,480.067902
	C698.483154,480.865906 698.228699,481.257080 697.681763,481.333710
	C698.128418,481.604767 698.443115,481.208801 698.785706,481.077881
	C702.257874,479.751099 704.056152,484.350586 707.292480,483.471100
	C706.031799,483.462067 704.504517,483.709412 704.560486,481.297333
	C710.602966,477.876801 716.104248,480.246521 721.606812,482.383789
	C722.196838,482.612946 722.887024,483.189178 722.919800,483.793335
	C723.131653,487.698608 725.504028,487.023315 728.308960,486.154877
	C728.921631,485.992065 729.177612,485.947571 729.833069,485.884216
	C732.387451,485.999146 734.475220,486.482178 736.477844,487.253754
	C738.201599,487.917877 739.933472,488.684082 739.081299,491.477051
	C734.930298,494.494354 730.321777,493.946594 726.021423,494.383636
	C721.131287,494.880615 716.152527,494.501465 710.763062,494.496399
	C703.842346,494.041565 697.404602,493.308899 690.609497,491.984497
	C689.162781,491.139465 688.619629,490.148438 688.356384,488.563110
	C689.127869,485.677826 691.003723,485.763428 693.141479,485.832489
	C697.651855,485.978180 701.793335,487.679382 705.298340,488.484070
	C706.788147,488.617493 706.187805,487.421051 706.271057,488.082489
	C706.302063,488.328949 706.190552,488.456207 705.950012,488.402740
	C701.147461,487.335571 696.377747,486.117340 691.204468,485.062988
	C690.278931,484.367401 689.832397,483.753265 689.269775,482.761658
	C688.619080,480.751312 687.763733,478.921722 690.724304,478.430481
	C685.168701,477.273743 680.036316,475.546722 675.391846,472.656769
	C673.090149,471.224579 670.942871,469.434082 673.317383,465.968750
	C678.835876,463.331726 684.088196,463.423798 689.741577,465.732300
z"
        />
        <path
          fill="#632B1D"
          opacity="1.000000"
          stroke="none"
          d="
M755.759399,485.280762
	C757.494934,485.150421 759.024536,485.294220 760.784180,485.210449
	C769.722778,485.031342 775.457642,490.174225 777.994324,500.578064
	C775.446472,502.677246 776.677246,504.206726 778.099976,506.054474
	C777.408447,506.937347 776.737305,507.244446 775.629089,507.531403
	C772.733215,508.400604 770.185486,508.714691 767.232178,507.850037
	C766.147400,507.571960 765.413879,507.491699 764.314575,507.469177
	C762.354919,507.273163 760.428894,507.532715 760.001953,505.355164
	C759.819153,504.422455 761.500671,504.990051 761.627197,503.951050
	C757.208069,507.110962 753.993286,503.526123 750.110352,501.851318
	C749.435120,501.344727 749.096619,500.999268 748.582031,500.319702
	C747.792908,499.426544 747.138062,498.952484 746.348267,498.677185
	C745.016357,498.212891 742.994629,498.217865 743.055054,496.396362
	C743.112183,494.675446 745.218689,494.713989 746.815674,493.901306
	C748.696777,493.526215 750.213440,493.517609 752.071655,493.918518
	C754.849976,497.392151 757.882507,497.286621 761.414917,495.330872
	C761.959656,492.297424 760.115906,490.735291 758.231934,489.194611
	C757.028809,488.210632 755.306335,487.606842 755.759399,485.280762
z"
        />
        <path
          fill="#3E1E1C"
          opacity="1.000000"
          stroke="none"
          d="
M778.226196,506.324707
	C776.720581,504.295563 774.373535,505.639801 772.662964,503.875549
	C774.196777,502.834930 775.128174,501.095886 777.649902,500.977051
	C779.482056,501.427216 781.340759,503.597168 781.178894,499.499115
	C780.978577,494.427399 777.867737,491.173981 775.018188,487.248871
	C781.332092,488.737823 786.888550,492.712646 793.857544,494.179016
	C794.276978,494.205200 794.403442,494.164948 794.437256,494.107849
	C794.732178,494.130737 794.993164,494.210754 795.369934,494.684326
	C794.581238,498.499603 795.218018,502.160980 793.625244,505.849182
	C788.426758,506.855621 783.512695,508.982361 778.226196,506.324707
z"
        />
        <path
          fill="#2C1E22"
          opacity="1.000000"
          stroke="none"
          d="
M792.887146,506.339508
	C793.478027,502.527863 791.908020,498.820892 794.414673,495.281433
	C796.380920,494.045929 797.827820,495.801941 799.749023,495.242920
	C800.046875,495.047638 800.307983,495.188446 800.459839,495.222351
	C800.611755,495.256256 800.859497,495.430450 800.928284,495.476013
	C806.547241,499.031616 812.798950,500.864716 818.932922,502.963593
	C819.867798,503.283478 820.823120,503.452484 821.761414,504.527740
	C823.330444,509.798401 820.970093,513.393372 817.357666,516.845337
	C810.502686,515.339417 805.048035,510.422729 797.434326,510.990997
	C795.400635,509.737915 793.555847,508.720795 792.887146,506.339508
z"
        />
        <path
          fill="#574649"
          opacity="1.000000"
          stroke="none"
          d="
M821.339600,505.233032
	C814.238342,502.420349 807.207764,500.427094 801.051575,495.908081
	C806.582642,497.495544 812.199524,499.386505 817.739746,501.480530
	C820.294983,502.446289 822.795227,502.730560 825.817749,502.202179
	C826.429871,504.230713 827.964294,503.915466 829.588501,504.063690
	C829.968506,504.793915 830.000732,505.505096 830.040649,506.573303
	C826.856506,508.778534 824.239807,507.206696 821.339600,505.233032
z"
        />
        <path
          fill="#964F38"
          opacity="1.000000"
          stroke="none"
          d="
M841.958618,503.608368
	C839.195129,504.436707 836.572815,503.059448 833.385376,502.883057
	C829.564514,495.080109 822.287781,493.546600 815.197327,491.661011
	C815.972107,491.008972 816.798889,490.313202 817.756836,489.340454
	C818.081299,489.036530 818.274475,489.009674 818.759583,488.959839
	C822.967834,491.806793 827.948669,492.060730 832.486511,494.422791
	C833.831421,499.578369 839.059875,500.174561 841.958618,503.608368
z"
        />
        <path
          fill="#6D5A5B"
          opacity="1.000000"
          stroke="none"
          d="
M799.652466,494.975037
	C798.587708,497.071533 796.997803,495.571808 795.077637,495.130615
	C794.671265,494.876465 794.637634,494.709961 794.537537,494.297119
	C796.160706,493.806885 797.706909,494.368591 799.652466,494.975037
z"
        />
        <path
          fill="#E1B582"
          opacity="1.000000"
          stroke="none"
          d="
M833.291199,494.062744
	C833.012878,493.797089 833.006775,493.553497 833.018005,492.941284
	C833.325684,493.036774 835.329041,492.902496 833.291199,494.062744
z"
        />
        <path
          fill="#F6D1A1"
          opacity="1.000000"
          stroke="none"
          d="
M478.599121,453.828400
	C477.352295,456.706696 475.277588,456.759064 473.681488,454.640686
	C472.519470,453.098419 474.525970,453.045319 475.880615,452.597046
	C476.971405,452.857147 477.692017,453.222595 478.599121,453.828400
z"
        />
        <path
          fill="#B27252"
          opacity="1.000000"
          stroke="none"
          d="
M411.034088,468.470398
	C417.190094,465.710052 421.992126,469.389862 427.004242,472.113586
	C427.911652,472.606689 429.469696,473.287964 427.786285,474.606323
	C426.916046,475.287842 424.561829,474.764404 425.550110,476.827271
	C426.398895,478.598999 428.368713,478.318756 429.993500,477.781677
	C430.541046,477.600708 430.909454,476.877808 431.653625,476.189789
	C432.759521,477.738739 434.198914,479.997253 431.276794,480.745361
	C427.848694,481.623077 424.415161,481.423859 422.582550,477.289337
	C421.815887,475.559662 422.781616,473.217560 420.547394,472.015625
	C418.838379,471.096252 417.395874,469.745636 415.188629,469.947876
	C413.800140,470.075104 412.166138,470.403534 411.034088,468.470398
z"
        />
        <path
          fill="#43434B"
          opacity="1.000000"
          stroke="none"
          d="
M358.979645,489.161743
	C362.037659,488.543091 365.089508,488.496307 367.936218,487.708405
	C371.801208,486.638641 375.489075,487.004425 379.661957,487.860107
	C381.931915,488.168304 383.815186,488.452393 384.999542,490.616882
	C383.812897,492.879852 381.913971,493.418762 379.390747,493.182800
	C374.450256,493.179688 369.774994,492.366150 365.306305,493.950592
	C362.747986,494.857758 362.391296,491.311401 360.119446,491.381775
	C361.068359,491.136780 362.017273,490.891754 363.078369,490.617798
	C361.793732,489.269928 359.952332,490.817169 358.979645,489.161743
z"
        />
        <path
          fill="#414A51"
          opacity="1.000000"
          stroke="none"
          d="
M402.759277,508.710388
	C397.289734,510.854309 391.568024,510.868835 385.455444,508.937378
	C383.465149,507.798431 381.869873,506.684509 380.092987,505.292389
	C387.207550,507.704132 395.197998,505.789856 402.759277,508.710388
z"
        />
        <path
          fill="#59646C"
          opacity="1.000000"
          stroke="none"
          d="
M379.087952,493.393372
	C380.757294,492.204163 382.332153,491.015442 384.721771,490.973755
	C385.090363,490.950989 385.062500,490.978973 385.049805,490.969543
	C385.446594,491.518372 386.108368,491.487274 386.964905,491.786011
	C387.486206,491.991211 387.686371,492.128784 387.940369,492.473450
	C389.055695,495.246643 386.791595,495.309753 385.429077,495.597351
	C383.112213,496.086395 380.460205,496.978577 379.087952,493.393372
z"
        />
        <path
          fill="#B1A5B1"
          opacity="1.000000"
          stroke="none"
          d="
M413.153931,509.371094
	C412.409485,509.406006 411.995605,509.465973 411.269409,509.555664
	C410.810303,509.288940 410.663483,508.992493 410.545288,508.200684
	C410.655334,507.327789 410.736694,506.950256 410.813049,506.283020
	C412.294128,506.164612 414.058319,505.521729 415.066803,507.779846
	C415.328857,508.322083 415.481842,508.495117 415.504089,508.989014
	C415.162567,509.259399 414.951874,509.208984 414.416504,509.144409
	C413.889404,509.218842 413.686951,509.307465 413.153931,509.371094
z"
        />
        <path
          fill="#77878D"
          opacity="1.000000"
          stroke="none"
          d="
M410.247589,507.776611
	C410.670715,508.011292 410.767426,508.317261 410.910583,509.104309
	C409.978546,509.876495 408.999969,510.167633 407.708435,510.471619
	C407.056427,510.179321 406.717407,509.874268 406.191040,509.286377
	C407.309509,508.618317 408.615356,508.233124 410.247589,507.776611
z"
        />
        <path
          fill="#63879A"
          opacity="1.000000"
          stroke="none"
          d="
M361.372437,474.985260
	C361.024658,474.855072 361.017731,474.692963 361.029602,474.532196
	C361.130371,473.167877 360.557343,471.433502 362.771973,471.195557
	C363.622070,471.104218 364.650330,471.329742 364.568329,472.280090
	C364.433075,473.847839 363.493317,474.938416 361.372437,474.985260
z"
        />
        <path
          fill="#5C8997"
          opacity="1.000000"
          stroke="none"
          d="
M360.999908,474.994019
	C358.829712,476.618744 356.478516,475.882446 353.161743,475.842224
	C356.089691,474.497131 358.169159,475.258026 360.594849,475.018127
	C361.022888,475.016571 361.002106,475.004669 360.999908,474.994019
z"
        />
        <path
          fill="#3A4D56"
          opacity="1.000000"
          stroke="none"
          d="
M359.309570,469.912354
	C359.121918,469.052582 359.448364,468.320129 360.758423,468.080963
	C360.951172,468.941254 360.627472,469.679504 359.309570,469.912354
z"
        />
        <path
          fill="#314651"
          opacity="1.000000"
          stroke="none"
          d="
M415.450226,509.471405
	C415.289490,509.124939 415.205688,508.940063 415.148865,508.452087
	C423.336517,505.686462 431.833282,504.862854 440.521484,502.982788
	C442.526764,501.546906 444.293884,501.318359 446.522827,502.443298
	C446.962311,502.905212 447.093414,503.126221 447.211243,503.728424
	C446.416443,505.365417 445.854523,506.768860 444.396851,508.009033
	C434.928528,510.166199 425.853638,512.800964 416.033447,510.606720
	C415.688049,510.088409 415.622406,509.856506 415.450226,509.471405
z"
        />
        <path
          fill="#485E6A"
          opacity="1.000000"
          stroke="none"
          d="
M447.266144,504.276367
	C445.453552,502.430481 443.496918,503.210052 441.326233,502.951172
	C440.936859,502.762146 440.954315,502.591309 440.988495,502.162811
	C441.028992,501.716736 441.052734,501.528381 441.146240,501.063873
	C451.515106,498.151001 462.047058,497.853668 472.867798,498.005188
	C471.889038,501.682709 468.444611,501.134003 465.578369,502.437378
	C459.388397,504.040833 453.610016,505.470459 447.266144,504.276367
z"
        />
        <path
          fill="#594E52"
          opacity="1.000000"
          stroke="none"
          d="
M387.024841,491.685547
	C386.637360,491.763550 386.026093,491.619110 385.225983,491.217407
	C385.625122,491.127747 386.213074,491.295441 387.024841,491.685547
z"
        />
        <path
          fill="#B1A5B1"
          opacity="1.000000"
          stroke="none"
          d="
M464.843506,501.389526
	C467.378876,500.349884 469.737335,499.683868 472.460663,498.964783
	C474.732361,496.301422 478.012115,497.188629 480.787231,495.843750
	C485.822998,494.531250 490.517395,493.295441 495.586700,491.969910
	C496.833862,490.612976 497.907227,489.690674 499.545197,489.584961
	C500.203705,489.542480 499.408295,489.749695 500.029297,489.530884
	C500.811249,489.255432 499.867889,489.455078 499.633453,489.278900
	C498.858826,489.057465 498.448120,488.828491 497.827911,488.248352
	C497.407532,487.458801 497.373077,486.956238 497.671478,486.105103
	C502.087921,484.019226 506.371307,486.464691 510.982239,485.574188
	C512.321960,485.525787 513.294739,485.525513 514.524902,484.938995
	C520.591187,483.237244 525.918884,480.771912 531.196838,477.337463
	C533.704895,476.164917 535.547913,474.335815 538.509155,474.333771
	C539.321106,474.621490 539.708069,474.915741 540.177124,475.632568
	C539.007446,480.425934 534.324646,480.828674 531.098022,483.517273
	C530.219543,484.010345 529.604065,484.259766 528.695190,484.633728
	C527.646973,485.103058 526.836548,485.300964 525.818359,485.801819
	C525.210815,486.190002 524.852722,486.396942 524.202637,486.728027
	C523.159607,487.249207 522.302002,487.394989 521.271057,487.989563
	C520.346069,488.401428 519.712646,488.652222 518.759033,489.064484
	C514.585938,490.028290 510.761383,490.904938 506.981049,493.117188
	C495.291199,496.948914 483.674896,499.538727 472.131104,502.403656
	C470.054321,502.919067 468.033386,503.898804 465.506226,502.655945
	C464.999146,502.202576 464.832855,501.982849 464.843506,501.389526
z"
        />
        <path
          fill="#4B555C"
          opacity="1.000000"
          stroke="none"
          d="
M499.734436,488.226685
	C501.467224,487.871185 502.864777,488.041840 504.194702,489.294556
	C501.961121,491.099640 499.116486,490.859528 496.335022,491.847473
	C496.005859,490.176605 495.616425,489.420715 493.486603,489.184753
	C489.993866,488.797760 485.333466,491.173126 483.425049,485.806641
	C483.333435,485.549072 482.269531,485.301056 482.175385,485.429016
	C478.188690,490.847229 475.108643,484.598328 471.306702,484.407227
	C472.611053,481.935547 475.371399,482.414062 477.820953,481.299133
	C485.066742,481.620056 491.874512,482.368439 498.285095,486.004700
	C498.388245,486.721588 498.305969,487.042419 498.174805,487.602478
	C498.305145,488.753387 498.971008,488.408142 499.734436,488.226685
z"
        />
        <path
          fill="#8B9698"
          opacity="1.000000"
          stroke="none"
          d="
M480.731262,495.669250
	C479.013306,497.773651 476.119324,497.897308 473.204529,498.899475
	C467.263123,497.216736 461.829376,499.487610 456.308105,499.508026
	C451.548645,499.525604 446.792267,500.368439 441.625244,500.817078
	C447.267822,496.605347 454.368591,498.113495 461.009674,497.246490
	C467.414795,496.410248 473.889648,496.108185 480.731262,495.669250
z"
        />
        <path
          fill="#2A363C"
          opacity="1.000000"
          stroke="none"
          d="
M539.391113,475.010315
	C536.952332,476.203949 534.877747,477.370728 532.461914,478.672485
	C531.966125,478.732544 531.811646,478.657623 531.567261,478.377075
	C531.345581,477.333527 530.951416,477.920197 530.344910,478.166046
	C526.787476,478.988373 523.529297,479.670685 520.406067,480.829956
	C510.917450,484.351837 501.415405,482.937744 491.915161,480.943176
	C490.595093,480.666046 489.404938,480.046997 488.859283,478.305969
	C501.263885,473.080780 512.455444,465.996613 524.525635,459.885864
	C526.841614,462.466675 529.305481,463.509125 532.603088,462.612518
	C534.190125,462.181030 535.901794,462.311310 537.561462,462.417572
	C544.654602,462.871826 546.046021,465.200714 542.898682,471.830261
	C542.291077,473.662537 541.457764,474.797943 539.391113,475.010315
z"
        />
        <path
          fill="#403D45"
          opacity="1.000000"
          stroke="none"
          d="
M488.542969,477.941986
	C499.145264,481.291229 509.437317,482.853333 519.951965,479.170532
	C523.561768,477.906128 527.161255,476.536255 531.568115,476.995483
	C532.042175,477.317169 532.058899,477.614868 532.098145,478.360016
	C527.150269,482.276001 521.632263,484.545410 515.473389,486.316101
	C514.202820,486.090851 513.321472,485.966125 512.268921,486.834595
	C507.677429,488.556152 503.414124,486.959869 498.771729,486.517334
	C491.827484,484.933441 485.103455,483.953918 478.432007,481.462402
	C481.507721,480.145416 484.835541,479.059479 488.542969,477.941986
z"
        />
        <path
          fill="#483A3F"
          opacity="1.000000"
          stroke="none"
          d="
M542.713318,472.365662
	C539.069092,471.503937 541.389648,470.144348 542.207825,469.327087
	C545.125244,466.412933 543.644653,465.017120 540.513672,464.390625
	C537.073608,463.702332 533.627502,463.765564 530.174683,464.718781
	C526.856812,465.634705 524.522888,464.865204 524.916016,460.271057
	C524.881897,459.834991 524.939819,459.878998 524.932983,459.914673
	C525.514099,459.611908 526.090149,459.257446 526.772339,458.490204
	C527.587280,457.719055 528.177979,457.201843 528.912842,456.427917
	C529.202759,455.854858 529.389771,455.565094 529.829773,455.151123
	C530.041687,455.000427 529.990662,454.988312 529.989746,455.014526
	C530.654541,454.196442 531.640137,453.840637 532.794800,453.180481
	C533.042175,452.995789 532.991150,452.988556 532.993042,453.014252
	C533.949890,452.118164 534.864746,451.115997 536.761475,451.464294
	C539.776672,455.364716 544.131226,455.655914 548.014343,456.899017
	C549.890564,457.499603 551.992371,457.433441 553.849854,459.089539
	C554.951599,460.951447 554.031860,462.220123 553.196289,463.848877
	C551.051453,466.682587 548.449341,468.616150 546.301025,471.440430
	C545.943604,471.889526 545.779419,472.052673 545.318237,472.398315
	C544.342224,472.831726 543.662720,472.840240 542.713318,472.365662
z"
        />
        <path
          fill="#485E6A"
          opacity="1.000000"
          stroke="none"
          d="
M460.209473,482.329437
	C462.005707,480.562469 464.317596,481.410706 466.761108,481.662292
	C464.981171,483.628448 462.733765,483.025116 460.209473,482.329437
z"
        />
        <path
          fill="#485E6A"
          opacity="1.000000"
          stroke="none"
          d="
M473.288086,480.774658
	C473.577393,478.225525 475.127014,477.519104 477.748779,478.728638
	C476.792847,480.186584 475.179749,480.401855 473.288086,480.774658
z"
        />
        <path
          fill="#485E6A"
          opacity="1.000000"
          stroke="none"
          d="
M469.210815,481.755829
	C468.940948,481.040131 468.970032,480.366241 469.005646,479.352722
	C469.874969,479.119019 470.610901,479.405548 470.947296,480.640686
	C470.502899,481.229889 470.006317,481.513763 469.210815,481.755829
z"
        />
        <path
          fill="#2A363C"
          opacity="1.000000"
          stroke="none"
          d="
M526.721680,458.307373
	C526.834778,459.077362 526.457092,459.728149 525.229492,459.923889
	C525.432983,459.426392 525.939880,458.902405 526.721680,458.307373
z"
        />
        <path
          fill="#A19796"
          opacity="1.000000"
          stroke="none"
          d="
M529.773804,454.930969
	C529.880127,455.311218 529.718506,455.621979 529.306885,456.051941
	C529.037231,455.966187 529.017578,455.761230 528.977905,455.247314
	C529.140503,454.912750 529.323242,454.887115 529.773804,454.930969
z"
        />
        <path
          fill="#8B9698"
          opacity="1.000000"
          stroke="none"
          d="
M529.036987,446.687012
	C531.530579,445.236359 533.883789,444.055695 536.600769,442.924377
	C536.557129,445.759003 533.416931,446.097321 532.084473,448.483917
	C529.886841,449.872620 527.872864,450.996124 525.523499,452.213135
	C524.728088,451.917847 524.517334,451.422913 524.770142,450.456543
	C526.288330,449.046478 527.592468,448.001709 529.036987,446.687012
z"
        />
        <path
          fill="#9FA2B2"
          opacity="1.000000"
          stroke="none"
          d="
M524.653076,450.157440
	C525.015625,450.583527 525.046997,451.075745 525.133301,451.937317
	C524.341309,452.651825 523.494507,452.996979 522.328125,453.187439
	C521.720520,451.224213 523.003174,450.709106 524.653076,450.157440
z"
        />
        <path
          fill="#A19796"
          opacity="1.000000"
          stroke="none"
          d="
M537.352051,451.807617
	C535.881592,452.148010 534.791931,452.578705 533.348511,453.024689
	C532.608215,451.619873 533.748230,451.121460 534.848267,450.245178
	C536.850037,448.783936 538.573486,447.508331 540.572815,446.063477
	C541.308472,446.175812 541.616821,446.574829 541.825195,447.477722
	C541.478577,450.221649 540.125000,451.593231 537.352051,451.807617
z"
        />
        <path
          fill="#A19796"
          opacity="1.000000"
          stroke="none"
          d="
M532.690491,452.917419
	C532.671753,454.174347 531.839905,454.819366 530.322998,455.049652
	C530.034729,453.519714 531.121399,453.109589 532.690491,452.917419
z"
        />
        <path
          fill="#A5654C"
          opacity="1.000000"
          stroke="none"
          d="
M731.388367,620.221924
	C729.943420,620.015625 728.870178,620.023132 727.394653,620.010132
	C728.269409,615.642639 731.277710,613.565857 735.476929,611.840332
	C743.082458,608.714905 750.389893,607.005371 758.595093,609.498596
	C761.547729,610.395752 765.122131,609.192139 768.417053,609.039185
	C770.223083,608.955261 772.138245,608.730713 773.720093,610.764038
	C774.471130,611.846619 774.297180,612.668579 773.688599,613.842163
	C771.994019,615.738892 770.064453,616.327087 768.092407,616.666748
	C763.362000,617.481628 758.593506,618.047607 753.403931,618.591492
	C745.901489,619.129761 738.776794,619.174622 731.388367,620.221924
z"
        />
        <path
          fill="#855144"
          opacity="1.000000"
          stroke="none"
          d="
M773.344116,613.786865
	C773.225952,612.876892 773.478394,612.246094 773.878906,611.307617
	C780.209656,609.534241 774.361084,607.413696 774.159424,605.313416
	C774.006958,604.575073 774.015259,604.132507 774.031494,603.358276
	C780.103333,600.860535 788.883972,605.010010 789.245361,610.681763
	C788.778687,611.280884 788.553528,611.524902 788.120361,611.910522
	C783.259766,613.287231 778.839294,616.158569 773.344116,613.786865
z"
        />
        <path
          fill="#72443E"
          opacity="1.000000"
          stroke="none"
          d="
M720.774475,621.016479
	C720.582031,621.256592 720.119263,621.514771 719.382324,621.751953
	C719.573547,621.498901 720.038818,621.266724 720.774475,621.016479
z"
        />
        <path
          fill="#72443E"
          opacity="1.000000"
          stroke="none"
          d="
M722.951782,620.136414
	C723.031799,620.357605 722.808472,620.587830 722.281494,620.820129
	C722.203796,620.597595 722.427307,620.372009 722.951782,620.136414
z"
        />
        <path
          fill="#72443E"
          opacity="1.000000"
          stroke="none"
          d="
M789.384216,611.045410
	C789.065674,609.064758 788.482849,607.678162 786.368713,606.924255
	C782.536865,605.557739 778.845886,603.791199 774.423340,603.033081
	C773.707825,602.549500 773.376221,602.072388 773.001587,601.298340
	C773.237183,600.298157 773.870789,599.948364 774.692627,599.287292
	C779.401550,599.229736 783.448547,602.102478 788.527832,601.002380
	C792.200256,603.260742 796.278076,605.209229 792.105957,610.383484
	C791.262756,610.818604 790.513733,610.936218 789.384216,611.045410
z"
        />
        <path
          fill="#813920"
          opacity="1.000000"
          stroke="none"
          d="
M774.491821,599.037964
	C774.872314,599.943970 774.522095,600.638672 773.270386,600.947815
	C764.614319,601.003235 756.270142,601.005066 747.455688,601.000977
	C747.597900,598.434570 749.695312,599.047852 751.387695,599.037170
	C758.934204,598.989441 766.481140,599.021851 774.491821,599.037964
z"
        />
        <path
          fill="#BEAAA0"
          opacity="1.000000"
          stroke="none"
          d="
M772.684021,613.697998
	C777.669250,613.004028 782.365051,612.500366 787.486572,612.024414
	C785.403259,614.137878 783.104492,616.568481 779.226074,616.631226
	C781.141174,619.148743 784.311646,615.332092 785.026917,618.303833
	C785.497803,620.260254 783.048279,619.777710 781.333862,619.967957
	C774.809875,620.694580 768.743225,622.735962 762.420166,621.116211
	C764.502075,621.575012 766.670959,621.398376 768.788208,622.774780
	C768.839294,625.328491 770.528320,628.829346 765.322998,627.979126
	C764.471619,625.357605 762.044250,625.647888 760.345825,624.983276
	C757.454834,623.851929 754.287720,623.308472 751.961304,620.422546
	C751.330627,619.101318 751.739441,618.301819 752.815186,617.401245
	C759.569458,616.311035 766.093384,615.752747 772.684021,613.697998
z"
        />
        <path
          fill="#C99469"
          opacity="1.000000"
          stroke="none"
          d="
M752.676880,617.140015
	C752.746887,617.972046 752.395447,618.765137 751.888306,619.857422
	C744.997925,619.434509 738.298157,620.554688 731.296753,620.273560
	C738.096252,619.039062 745.175842,618.070007 752.676880,617.140015
z"
        />
        <path
          fill="#A19796"
          opacity="1.000000"
          stroke="none"
          d="
M769.404175,623.035522
	C766.664124,623.032532 764.328796,623.108154 762.009766,622.948303
	C760.731140,622.860168 758.499939,623.702393 758.639526,621.378845
	C758.782349,619.002441 760.968140,618.468201 762.787292,619.207275
	C766.182373,620.586731 769.876221,621.222229 772.875244,619.629517
	C775.790466,618.081177 777.843872,618.888855 780.616028,619.930359
	C780.980225,620.146973 780.992554,620.320801 781.015869,620.756104
	C780.806946,622.219299 780.965881,624.454407 779.019592,623.290161
	C775.830811,621.382629 772.856995,623.280701 769.404175,623.035522
z"
        />
        <path
          fill="#A19796"
          opacity="1.000000"
          stroke="none"
          d="
M742.291870,627.596436
	C747.224182,626.979492 751.918579,625.994690 756.129883,629.392273
	C752.349670,629.059753 748.694885,628.931152 745.103882,630.341736
	C744.629150,630.528137 740.965210,631.174805 742.291870,627.596436
z"
        />
        <path
          fill="#273E4A"
          opacity="1.000000"
          stroke="none"
          d="
M449.228394,523.139404
	C446.166168,521.488831 444.792023,517.547913 440.132050,519.199585
	C441.619476,515.428223 447.140472,516.258667 446.013123,511.406921
	C446.501495,507.340363 449.917389,508.228546 452.430298,506.996521
	C453.549744,506.633362 454.319489,506.543365 455.479675,506.541565
	C458.867249,506.651154 461.720490,506.126190 464.630035,504.284485
	C466.371094,503.324951 468.029541,503.708740 469.853271,503.005981
	C474.253693,501.574554 478.544525,501.682281 482.995422,500.548828
	C491.645813,499.512756 499.649567,497.526947 507.578735,493.931396
	C508.450745,493.231659 509.095795,492.868469 510.094604,492.379517
	C510.648773,492.118439 510.876129,492.027496 511.455139,491.835022
	C517.338806,490.955414 521.710876,487.011139 527.571533,486.129700
	C528.724304,486.323456 529.417358,486.673798 530.402039,487.331055
	C531.933594,488.862823 532.336609,490.388916 531.981323,492.508484
	C531.468140,493.946716 530.702637,494.705719 529.562378,495.646881
	C521.844543,498.049744 515.003174,501.792938 507.148926,503.735962
	C505.984833,504.241699 505.071991,504.342651 503.906006,504.725159
	C502.821899,505.116974 502.005463,505.285065 500.940186,505.674133
	C500.479584,505.890167 500.293610,505.973907 499.825623,506.182037
	C499.352356,506.385834 499.161011,506.465240 498.679565,506.667847
	C498.189087,506.864166 497.988770,506.937225 497.479187,507.104279
	C496.095551,507.412720 495.001160,507.545746 493.647827,508.069946
	C492.385132,508.335419 491.450745,508.314819 490.164307,508.180481
	C483.052979,507.661285 476.460114,508.502136 470.371704,510.120483
	C469.812714,511.043121 469.924438,511.248322 470.362793,511.337921
	C472.083832,511.689758 474.917877,511.384277 474.837097,513.451294
	C474.744049,515.831970 471.815704,515.358826 469.942719,515.711365
	C469.457733,515.802673 468.954620,515.766296 468.456116,515.733582
	C467.645111,515.680420 466.851898,515.823975 466.071655,516.040344
	C466.948212,516.061035 467.671814,516.392700 468.335632,517.426270
	C468.499237,518.877380 467.836029,519.469055 466.561218,519.942566
	C465.134705,520.182129 464.019836,520.254333 462.738708,521.030884
	C459.754639,522.420105 456.750549,522.362610 453.640991,523.396423
	C452.008545,523.518555 450.747192,524.101624 449.228394,523.139404
z"
        />
        <path
          fill="#11181F"
          opacity="1.000000"
          stroke="none"
          d="
M513.377014,568.274109
	C512.087158,567.992981 511.148651,567.950012 509.385345,567.869202
	C511.919098,566.750488 513.693237,565.967102 515.786743,565.013916
	C524.103027,561.196899 532.464417,560.313965 541.507263,561.735352
	C542.479980,561.749268 543.050354,561.576416 543.801880,561.157593
	C545.262207,559.337524 547.119202,559.049561 548.900024,559.102173
	C555.540955,559.298340 561.708313,556.710938 568.596069,555.858276
	C577.016418,554.965149 584.917908,553.404114 593.339294,552.509277
	C607.563293,550.737488 621.038879,547.102661 635.266968,545.371887
	C642.857300,543.799316 650.204468,543.622070 657.841675,542.388977
	C662.674683,541.133545 667.160278,540.660645 672.119141,541.189148
	C672.722717,541.564392 672.933655,541.760437 673.311768,542.354004
	C673.470276,543.038391 673.461731,543.325195 673.258301,543.995483
	C670.752808,546.321899 667.874451,546.432007 664.676270,546.903076
	C656.720581,549.729187 648.751160,549.311279 640.537231,550.197388
	C634.360046,550.935059 628.742432,552.555603 622.554016,553.131958
	C616.917419,554.699951 611.378052,554.490784 605.765503,556.211670
	C602.953369,556.631958 600.664368,557.751038 597.746216,557.470581
	C597.070740,557.483582 596.800903,557.488708 596.127319,557.512695
	C593.045105,557.807617 590.396179,558.316589 587.302979,558.599365
	C575.295593,561.233337 563.412598,561.827881 551.296204,563.658691
	C548.913269,563.627808 547.241211,564.739563 544.968262,565.207642
	C542.088806,565.822205 539.590027,565.921265 536.657654,565.687988
	C528.831360,567.392700 521.416687,569.046265 513.377014,568.274109
z"
        />
        <path
          fill="#193A47"
          opacity="1.000000"
          stroke="none"
          d="
M468.311096,559.609131
	C456.554199,558.095032 447.876434,551.837891 439.663452,544.402649
	C436.393890,541.442749 432.739441,538.883972 429.503265,535.812317
	C427.637878,534.041748 426.415344,532.065491 425.015381,529.531311
	C428.543915,529.076721 430.010834,531.794128 432.074615,532.993164
	C444.784668,540.377686 457.359009,547.997192 469.920746,555.633545
	C471.066620,556.330139 473.135956,556.944275 472.584625,558.773804
	C472.010895,560.677673 470.126678,559.862610 468.311096,559.609131
z"
        />
        <path
          fill="#1E4552"
          opacity="1.000000"
          stroke="none"
          d="
M464.174194,532.245667
	C461.151001,530.229492 458.173370,530.910706 455.301514,531.474304
	C453.451080,531.837463 451.768921,532.278320 450.054230,530.695862
	C453.942078,528.683655 458.241547,529.386047 463.136536,528.383972
	C459.361786,526.201477 456.246613,526.968872 453.173645,527.447021
	C450.632965,527.842224 447.925964,529.144836 446.050232,525.621155
	C447.700134,523.057434 450.589386,523.939514 453.106934,522.638000
	C459.403473,522.158630 464.964813,519.755737 471.310059,519.780212
	C476.670715,522.041809 482.311859,522.022766 487.111603,524.696655
	C487.948364,525.162842 489.128235,525.490540 488.880341,526.685852
	C488.691345,527.597168 487.731018,527.713013 486.852051,527.509949
	C488.512360,528.790283 493.342987,526.014587 491.486115,531.627808
	C489.855347,532.466064 488.531647,532.466675 486.867493,531.788330
	C483.685638,528.965454 480.005188,530.603577 477.758118,530.009949
	C479.554352,530.207336 482.402863,529.638184 485.443176,530.709595
	C486.202362,531.287170 486.239044,531.750183 485.658295,532.544434
	C484.217224,533.533386 483.011658,534.135376 481.250061,534.381104
	C478.012329,534.392029 475.157440,534.749756 472.023956,533.929443
	C470.976685,533.725281 471.427765,531.819153 469.788513,532.922058
	C467.724792,533.549011 466.002441,533.632568 464.174194,532.245667
z"
        />
        <path
          fill="#213642"
          opacity="1.000000"
          stroke="none"
          d="
M513.098389,568.387939
	C520.440369,566.765137 527.855042,565.494324 535.668396,564.216736
	C539.711365,567.501160 538.688782,571.840637 538.624756,576.382324
	C538.355774,579.459961 538.281677,582.130859 536.343506,584.709717
	C531.966125,582.509583 527.989258,579.949524 522.839905,580.875305
	C521.673035,581.085083 520.592712,580.298279 520.282043,578.970520
	C519.964722,577.614136 520.781677,576.841919 521.790039,576.129761
	C522.798828,575.417358 524.748840,575.291321 524.392883,573.603210
	C524.001404,571.746826 521.992493,572.368042 520.675964,571.877319
	C518.140015,570.932007 514.891296,571.622253 513.098389,568.387939
z"
        />
        <path
          fill="#1E4552"
          opacity="1.000000"
          stroke="none"
          d="
M511.242889,551.685913
	C511.009460,553.916870 513.669861,552.800781 513.929871,554.871460
	C501.705780,556.698303 489.203552,554.613953 477.000610,557.287537
	C484.776184,553.024963 493.186005,552.500854 501.759399,552.718506
	C496.065216,551.262451 490.254791,552.859436 484.320923,551.219482
	C483.256653,548.288391 480.983765,550.031555 479.272034,549.199097
	C479.011292,548.836609 479.010651,548.664429 479.008698,548.233887
	C479.677429,544.543457 482.859283,545.279175 484.940338,544.801636
	C491.834869,543.219666 498.851562,542.299561 506.339539,543.295227
	C507.618286,544.097107 507.982208,545.032532 508.182861,546.415405
	C508.070282,548.756042 509.997589,548.694153 511.207275,549.811768
	C511.552734,550.515808 511.556915,550.960449 511.242889,551.685913
z"
        />
        <path
          fill="#59646C"
          opacity="1.000000"
          stroke="none"
          d="
M631.883057,585.693848
	C629.601746,589.683228 625.331055,589.030823 621.805725,589.716187
	C611.413208,591.736511 600.921265,593.250671 590.459595,594.908386
	C571.266357,597.949890 552.066895,600.952209 532.456055,604.011230
	C530.247131,604.023682 528.452148,603.995911 526.213257,603.866943
	C525.769287,603.765808 525.823608,603.442444 525.888062,603.292175
	C529.067139,601.060547 532.908142,602.395325 536.568481,600.913879
	C541.325562,597.045898 546.987244,597.885071 552.462463,596.528076
	C557.208069,595.569763 561.686951,595.688110 566.398560,594.526367
	C571.928406,593.437439 577.094543,592.675354 582.661987,591.817871
	C589.328308,590.432373 595.751404,590.097107 602.389282,588.553467
	C609.642517,588.800659 616.139404,586.347473 623.284790,585.925781
	C624.651245,585.649902 625.671265,585.668396 627.345581,585.145874
	C628.294922,584.876221 628.539673,584.834778 629.156982,584.777283
	C630.287415,584.802734 630.995056,585.006531 631.883057,585.693848
z"
        />
        <path
          fill="#1D3540"
          opacity="1.000000"
          stroke="none"
          d="
M635.995728,546.562866
	C622.197754,549.279602 608.429199,551.584045 594.239380,553.843384
	C592.082275,552.636902 589.509766,551.811157 588.199280,552.610229
	C581.240356,556.853455 572.943359,552.646912 565.707886,556.648438
	C560.392151,557.024963 555.700378,554.944641 550.353760,555.003418
	C548.907898,555.001892 547.854004,555.001038 546.404907,554.998901
	C545.876892,554.776733 545.744080,554.555969 545.684998,553.955017
	C545.999207,553.115906 546.320557,552.723572 547.049072,552.209229
	C550.779236,550.714844 554.586731,552.602051 558.243530,550.504883
	C561.650330,549.998840 564.683411,549.507263 567.821228,550.284241
	C570.947815,551.058411 574.039124,550.492371 577.283447,548.773071
	C581.944458,548.410156 586.209106,548.511902 590.880005,548.560242
	C596.627258,550.042725 601.802673,548.834778 607.195679,547.297729
	C608.628235,545.102783 610.790771,544.758850 612.936035,543.635376
	C614.299866,543.038391 615.354553,542.854553 616.853394,542.859375
	C619.352417,543.484253 621.398010,543.530823 623.583130,543.101929
	C628.135315,542.208435 632.354614,543.188477 635.995728,546.562866
z"
        />
        <path
          fill="#2A363C"
          opacity="1.000000"
          stroke="none"
          d="
M553.191284,597.723511
	C547.968628,599.016479 542.878174,599.959595 537.381104,600.879761
	C535.158997,595.956238 535.541626,590.913086 536.002563,585.427551
	C535.680542,582.506042 536.542175,580.182495 536.992798,577.399353
	C538.185425,576.020142 539.388123,576.494080 539.973511,577.487976
	C544.034180,584.382080 549.656128,590.223206 553.191284,597.723511
z"
        />
        <path
          fill="#193A47"
          opacity="1.000000"
          stroke="none"
          d="
M467.246094,540.185425
	C466.982941,539.957642 466.991547,539.475281 466.995300,539.234131
	C468.025116,536.185974 470.566833,537.299805 472.706726,536.712708
	C469.199493,536.528870 465.795563,538.662354 462.276001,536.236938
	C461.995453,535.991455 461.987396,535.529114 461.984375,535.297974
	C464.077576,531.408508 467.508240,533.107788 470.325104,533.284729
	C473.062134,533.456726 475.784698,534.128845 478.977264,533.999451
	C481.408051,534.284607 483.262848,533.345032 485.207550,533.186951
	C486.712555,533.064636 488.591705,533.596619 488.843658,535.149902
	C489.160919,537.106018 487.159119,537.224976 485.731903,537.438293
	C484.593140,537.608398 483.422791,537.491638 482.694733,537.497009
	C486.641632,538.189453 491.028046,535.787415 495.414551,537.584412
	C496.948883,538.213013 498.880066,538.424133 497.388794,541.154175
	C493.001892,543.595276 489.550323,540.582825 486.209778,540.575500
	C486.076141,540.383423 486.447906,540.723267 486.926208,540.836853
	C488.664093,541.249451 491.214630,541.295959 490.837494,543.642578
	C490.546661,545.451965 488.060852,544.714478 486.509430,545.033325
	C482.287872,545.901184 478.047272,546.535034 473.385834,546.767334
	C470.201080,546.807312 467.556641,544.379150 464.508331,546.306641
	C463.832886,546.733826 463.064545,546.171509 463.240295,545.454468
	C463.417419,544.731812 464.125763,544.139343 464.771484,543.228882
	C466.077454,542.951721 467.300873,543.140076 467.908752,541.392761
	C471.841583,538.055481 474.426239,543.978394 477.447693,542.419739
	C475.060120,539.229492 471.252472,539.984131 467.246094,540.185425
z"
        />
        <path
          fill="#142732"
          opacity="1.000000"
          stroke="none"
          d="
M453.282288,507.228699
	C451.603851,509.634949 448.657715,509.061096 446.272522,510.757080
	C440.291107,512.675720 434.414337,513.138672 428.491302,513.370850
	C423.917480,513.550110 419.530609,513.025574 415.590149,510.561646
	C419.567200,511.666260 423.372467,510.288391 426.991699,509.597748
	C432.700256,508.508362 438.477814,508.062714 444.599457,506.999390
	C447.660675,505.014709 450.339233,505.029175 453.282288,507.228699
z"
        />
        <path
          fill="#2C4653"
          opacity="1.000000"
          stroke="none"
          d="
M541.684082,561.658630
	C533.463318,561.885681 525.347595,564.610779 516.523804,564.877563
	C515.473572,562.323547 512.765442,563.052612 511.099426,561.373779
	C517.805481,559.851746 524.590393,559.575806 531.195984,558.294373
	C534.587158,557.636475 538.150940,559.898010 541.684082,561.658630
z"
        />
        <path
          fill="#33505C"
          opacity="1.000000"
          stroke="none"
          d="
M550.218506,555.243652
	C555.279785,552.598633 560.329285,554.459167 565.809937,555.984375
	C566.888306,555.170532 567.647339,555.229797 568.524292,556.077271
	C568.511292,557.683960 567.611511,557.928772 566.544067,558.067932
	C559.316345,559.010315 552.091797,559.978027 544.424683,560.924622
	C542.487488,559.976318 540.992004,559.041016 539.008362,557.800415
	C544.953125,555.684387 550.401550,557.991150 557.147522,557.988770
	C554.079651,556.837341 552.277405,556.160950 550.218506,555.243652
z"
        />
        <path
          fill="#285362"
          opacity="1.000000"
          stroke="none"
          d="
M473.420502,547.009399
	C478.191284,543.764099 484.252960,545.315552 489.876495,543.587036
	C489.118011,541.414917 485.897614,542.616272 485.049286,540.358032
	C488.895386,539.122375 492.496033,540.670227 496.941254,540.982666
	C500.698151,541.761719 503.984161,540.893433 506.887329,543.335510
	C497.805389,544.743225 488.553528,545.027405 479.290161,547.719238
	C477.270966,547.662964 475.534515,547.350281 473.420502,547.009399
z"
        />
        <path
          fill="#28424F"
          opacity="1.000000"
          stroke="none"
          d="
M494.300293,568.121643
	C494.015991,566.207825 492.110901,566.236267 491.317230,564.486938
	C497.453888,563.728821 503.256744,561.609497 510.321655,563.594238
	C506.379242,566.179749 502.191376,564.912659 499.047333,567.532837
	C497.640320,569.670776 496.124481,569.520752 494.300293,568.121643
z"
        />
        <path
          fill="#28424F"
          opacity="1.000000"
          stroke="none"
          d="
M499.716064,559.260742
	C501.716156,559.006042 503.435089,559.007446 505.575226,558.997437
	C505.253448,562.163025 502.476105,560.956970 500.637238,561.202942
	C495.538818,561.885010 490.382141,562.131348 485.248474,562.549805
	C485.214020,562.134277 485.179535,561.718689 485.145050,561.303101
	C486.936951,561.125916 488.728851,560.948730 490.520752,560.771545
	C490.536591,560.584961 490.552429,560.398315 490.568268,560.211670
	C487.850403,560.211670 485.132538,560.211670 482.414673,560.211670
	C482.419952,559.790771 482.425232,559.369873 482.430542,558.948975
	C486.466736,559.923767 490.428314,556.577637 494.636169,559.730347
	C495.765381,560.576416 497.850128,560.248230 499.716064,559.260742
z"
        />
        <path
          fill="#2E515F"
          opacity="1.000000"
          stroke="none"
          d="
M461.990784,536.244812
	C465.906219,535.991455 469.816956,535.991455 474.079926,535.991455
	C472.213196,538.290894 469.581543,537.611694 467.334167,538.844727
	C460.946869,538.514099 454.946503,541.262573 448.832764,539.016296
	C452.777985,537.436096 456.981537,538.308228 461.515015,537.948181
	C462.293396,537.600098 462.702606,537.507629 463.141724,537.493530
	C462.913849,537.432922 462.675812,537.464233 462.221985,537.210938
	C461.993713,536.835999 461.989929,536.667053 461.990784,536.244812
z"
        />
        <path
          fill="#2E515F"
          opacity="1.000000"
          stroke="none"
          d="
M480.252197,534.796326
	C474.229370,535.651611 468.524963,532.470459 462.355530,534.885010
	C459.854553,535.062012 457.789032,534.821899 455.777222,533.424927
	C458.051392,531.213379 461.077332,533.776672 463.591736,531.994324
	C465.998108,531.951111 468.053131,531.965820 470.563232,531.997192
	C473.869965,533.951904 477.084778,532.576843 480.546387,533.013794
	C481.446503,533.734314 481.021790,534.180847 480.252197,534.796326
z"
        />
        <path
          fill="#33505C"
          opacity="1.000000"
          stroke="none"
          d="
M545.824280,553.649048
	C546.089722,554.141602 546.078247,554.313232 546.038208,554.741211
	C537.364197,556.604797 528.635559,556.056396 519.475464,555.999512
	C519.024902,555.996582 519.042053,555.545349 519.054504,555.319885
	C519.394043,554.708740 519.705444,554.310791 520.281982,553.639160
	C520.785095,553.250366 521.007996,553.123474 521.595703,552.873535
	C523.984924,552.483887 526.022583,552.512573 528.435913,552.500793
	C529.569641,552.557251 530.323975,552.601318 531.446167,552.736816
	C533.475769,553.322266 535.140259,553.586914 537.199951,552.803833
	C539.163818,552.505432 540.759949,552.550537 542.767334,552.570129
	C544.027710,552.646790 544.841492,552.815063 545.824280,553.649048
z"
        />
        <path
          fill="#2E515F"
          opacity="1.000000"
          stroke="none"
          d="
M466.743073,540.008118
	C472.419891,537.911499 477.223602,539.888611 482.069824,542.257019
	C477.573914,544.538452 475.096710,544.179138 471.572662,541.187805
	C470.984772,540.688843 469.661652,541.056152 468.328430,541.040649
	C467.309418,540.915771 466.640198,540.773499 465.971008,540.631226
	C466.148407,540.440369 466.325806,540.249451 466.743073,540.008118
z"
        />
        <path
          fill="#214A59"
          opacity="1.000000"
          stroke="none"
          d="
M518.996094,555.990479
	C521.231567,558.196716 519.196838,558.714172 517.638916,558.814331
	C514.077515,559.043274 510.495392,558.949585 506.459076,558.986572
	C505.996460,558.986084 505.999115,559.000000 506.006195,559.001465
	C509.388031,555.286438 514.083557,556.918335 518.587830,556.001099
	C519.024902,555.996582 518.999329,556.002930 518.996094,555.990479
z"
        />
        <path
          fill="#33505C"
          opacity="1.000000"
          stroke="none"
          d="
M511.420959,551.971436
	C511.038422,551.547729 511.040405,551.131897 511.060669,550.404419
	C512.483582,548.355286 514.221924,548.894775 516.026917,549.240845
	C517.942078,549.608032 519.803894,550.387207 521.800659,550.221191
	C523.386658,550.089355 525.704224,549.450989 525.834290,551.517517
	C525.999878,554.150452 523.314636,553.254639 521.352783,553.773315
	C521.004639,553.997314 521.000000,554.000000 520.997803,554.001404
	C520.600037,554.369141 520.134277,554.522522 519.300964,554.222412
	C516.487183,553.734619 514.484619,551.640991 511.420959,551.971436
z"
        />
        <path
          fill="#2C4653"
          opacity="1.000000"
          stroke="none"
          d="
M479.695038,553.569458
	C475.590729,554.664490 472.262726,553.732727 469.087830,551.199341
	C473.218140,550.801086 477.116608,550.724487 480.841736,552.439026
	C480.874237,552.454041 480.851654,552.736755 480.774353,552.814270
	C480.547577,553.041565 480.280121,553.228333 479.695038,553.569458
z"
        />
        <path
          fill="#63879A"
          opacity="1.000000"
          stroke="none"
          d="
M453.295258,523.145874
	C450.928558,524.008972 448.802185,524.513855 446.319641,525.140930
	C443.523804,525.152039 441.084167,525.040833 438.644501,524.929626
	C438.626953,524.760559 438.609406,524.591553 438.591888,524.422485
	C441.772430,523.942871 444.952972,523.463196 448.549133,522.918091
	C450.087402,522.598999 451.210052,522.345459 452.681396,522.090210
	C453.198578,522.321594 453.367096,522.554626 453.295258,523.145874
z"
        />
        <path
          fill="#314D58"
          opacity="1.000000"
          stroke="none"
          d="
M464.974304,542.982788
	C461.043915,543.632507 457.142914,543.799744 452.902344,542.531494
	C456.987000,541.231750 460.699036,540.272705 464.703369,542.693542
	C464.941589,542.967896 464.988678,543.003235 464.974304,542.982788
z"
        />
        <path
          fill="#11181F"
          opacity="1.000000"
          stroke="none"
          d="
M493.832886,567.902832
	C495.493896,568.230164 496.885193,568.521423 498.613708,567.933716
	C501.174866,567.886292 503.432495,567.886292 505.690155,567.886292
	C501.531311,568.405212 497.548706,571.500061 493.259979,569.037903
	C493.147827,568.973572 492.998810,568.751892 493.030243,568.696960
	C493.174347,568.444824 493.376617,568.225952 493.832886,567.902832
z"
        />
        <path
          fill="#285362"
          opacity="1.000000"
          stroke="none"
          d="
M479.224152,549.277039
	C481.697296,548.306763 484.373260,547.453064 487.227509,548.407532
	C487.382233,550.651978 485.225281,549.587097 484.261414,550.701172
	C482.317291,551.044373 480.817139,550.491882 479.224152,549.277039
z"
        />
        <path
          fill="#33505C"
          opacity="1.000000"
          stroke="none"
          d="
M448.474060,536.985229
	C447.112213,536.925293 446.160309,536.868408 445.208405,536.811523
	C445.213898,536.624512 445.219360,536.437561 445.224854,536.250549
	C449.047821,536.250549 452.870789,536.250549 456.693726,536.250549
	C456.696198,536.496460 456.698639,536.742371 456.701080,536.988281
	C454.095367,536.988281 451.489685,536.988281 448.474060,536.985229
z"
        />
        <path
          fill="#193A47"
          opacity="1.000000"
          stroke="none"
          d="
M439.174988,530.625305
	C440.919373,531.830566 440.805420,532.644226 439.029755,532.903625
	C437.917603,533.066101 436.376373,533.111450 436.342987,531.573669
	C436.306061,529.870300 437.685333,530.098755 439.174988,530.625305
z"
        />
        <path
          fill="#193A47"
          opacity="1.000000"
          stroke="none"
          d="
M477.077179,562.771118
	C475.960663,562.189514 474.851685,561.667236 475.823547,560.533020
	C476.440125,559.813477 477.364136,560.153564 477.969269,560.771790
	C478.888947,561.711365 478.749420,562.464355 477.077179,562.771118
z"
        />
        <path
          fill="#193A47"
          opacity="1.000000"
          stroke="none"
          d="
M444.597839,531.190125
	C445.674866,532.044189 444.952332,532.353699 444.324615,532.568176
	C444.123077,532.637024 443.605682,532.366028 443.548096,532.161743
	C443.377960,531.558044 443.815338,531.284668 444.597839,531.190125
z"
        />
        <path
          fill="#28424F"
          opacity="1.000000"
          stroke="none"
          d="
M500.002563,558.997559
	C499.394135,558.928833 498.783173,558.862549 498.172180,558.796265
	C498.235748,558.596130 498.336609,558.217773 498.357452,558.222168
	C498.795685,558.313782 499.224854,558.448547 499.826538,558.789734
	C499.997253,559.004578 500.000000,559.000000 500.002563,558.997559
z"
        />
        <path
          fill="#193A47"
          opacity="1.000000"
          stroke="none"
          d="
M519.015625,554.260010
	C519.446411,553.975891 519.889282,553.970154 520.663940,553.983643
	C520.542969,554.331055 520.090332,554.659302 519.352295,555.041016
	C519.053894,554.909119 519.040833,554.723816 519.015625,554.260010
z"
        />
        <path
          fill="#193A47"
          opacity="1.000000"
          stroke="none"
          d="
M461.993988,537.238159
	C462.392944,537.171936 462.788361,537.338989 463.183807,537.506042
	C462.938049,537.650818 462.692261,537.795532 462.212158,537.939087
	C461.977844,537.937866 461.990448,537.471436 461.993988,537.238159
z"
        />
        <path
          fill="#64777D"
          opacity="1.000000"
          stroke="none"
          d="
M412.973572,509.493744
	C413.025330,509.255005 413.227295,509.163971 413.760559,509.101593
	C413.769196,509.300629 413.446503,509.471008 412.973572,509.493744
z"
        />
        <path
          fill="#3A292B"
          opacity="1.000000"
          stroke="none"
          d="
M632.283936,585.985229
	C631.361755,586.035461 630.597229,586.097290 629.450806,586.157959
	C628.953552,585.786072 628.705444,585.638428 628.193726,585.911743
	C628.062866,586.109680 628.038818,586.101379 628.043213,586.089844
	C627.281189,586.663635 626.339294,586.400879 625.254272,586.835205
	C617.998901,588.289795 611.077820,590.176880 603.419312,590.082092
	C601.647095,587.598450 603.702942,585.574341 604.384399,583.672119
	C606.395935,578.057007 608.726685,572.553162 610.557373,566.515747
	C611.392395,565.771667 612.053284,565.457703 613.182251,565.362061
	C613.795349,565.463623 614.031555,565.547852 614.568298,565.854492
	C619.903809,570.460449 624.859558,574.779907 631.885620,575.977295
	C636.078979,576.692078 636.920898,580.222900 634.360229,585.110474
	C633.884216,585.494385 633.672546,585.620361 633.108765,585.858154
	C632.756592,585.969971 632.441589,585.996704 632.283936,585.985229
z"
        />
        <path
          fill="#4B555C"
          opacity="1.000000"
          stroke="none"
          d="
M688.941406,578.534058
	C677.976135,580.791199 667.056946,582.776672 656.083862,584.397705
	C650.021729,585.293213 644.070862,586.998596 637.446533,587.028137
	C636.860901,586.773010 636.714539,586.530823 636.592957,585.864380
	C638.170532,582.782471 640.986816,582.635132 643.516663,582.201477
	C651.950012,580.755920 660.378784,579.271057 669.237793,577.911499
	C673.077026,576.622925 676.585999,575.785828 680.665466,575.792236
	C683.543335,577.180420 686.872620,575.501038 688.941406,578.534058
z"
        />
        <path
          fill="#483A3F"
          opacity="1.000000"
          stroke="none"
          d="
M689.370789,578.975586
	C686.770996,576.918091 683.868408,578.800293 681.284607,577.284546
	C682.033386,573.246216 685.482788,574.198608 688.491577,573.389709
	C692.140015,572.398926 695.352600,571.617004 698.947998,573.510864
	C699.804382,574.651123 699.855286,575.529663 699.262085,576.833618
	C696.509705,580.678284 692.862183,578.247375 689.370789,578.975586
z"
        />
        <path
          fill="#72443E"
          opacity="1.000000"
          stroke="none"
          d="
M699.443359,577.179688
	C699.023193,576.459656 699.016724,575.691101 699.027710,574.541504
	C701.401794,569.267700 706.362610,570.885437 710.632690,569.664917
	C712.255005,569.562134 713.397827,569.703308 714.702148,570.729736
	C714.954102,573.612854 711.582642,572.353271 711.083496,574.786255
	C709.202637,576.795166 706.614014,575.431213 704.364746,576.905884
	C702.640259,577.096069 701.248718,577.113586 699.443359,577.179688
z"
        />
        <path
          fill="#59646C"
          opacity="1.000000"
          stroke="none"
          d="
M636.643005,585.492676
	C637.006287,585.954956 637.004639,586.166931 637.005127,586.697021
	C635.723267,587.118896 634.417419,587.419800 632.996216,586.283936
	C632.756592,585.969971 632.876587,585.989807 632.925415,585.953491
	C633.962158,585.137451 635.041870,584.806763 636.643005,585.492676
z"
        />
        <path
          fill="#875E55"
          opacity="1.000000"
          stroke="none"
          d="
M594.547852,435.872253
	C593.813354,445.421661 592.733582,454.910492 591.431152,464.733276
	C589.300476,465.513000 587.237305,464.358948 585.372681,465.885620
	C584.361023,466.713837 583.278687,466.001343 582.480347,464.600006
	C582.953247,458.500092 586.517090,453.512756 586.805298,447.320007
	C587.037354,447.001007 586.997986,446.997070 587.010498,447.012268
	C587.879883,445.984070 587.305969,444.703674 587.829285,443.291870
	C591.109619,437.447235 588.160767,431.545258 589.059875,425.396057
	C591.372559,425.548615 591.000183,427.429596 590.941223,429.019135
	C590.834167,431.900055 591.753784,434.200562 594.547852,435.872253
z"
        />
        <path
          fill="#E1B582"
          opacity="1.000000"
          stroke="none"
          d="
M541.231079,448.471741
	C540.907776,447.740906 540.890625,447.213715 540.861084,446.290344
	C544.151184,442.911499 547.328430,439.690247 552.645630,439.923279
	C555.431213,442.248932 558.639160,441.915619 561.894531,442.370605
	C563.243042,441.100403 564.279053,439.944244 565.352478,438.746368
	C566.900940,440.965942 562.371704,442.818909 565.676147,444.836029
	C567.299988,446.112030 569.563538,445.771606 570.824463,447.763031
	C571.540222,448.588348 571.809631,449.244293 571.686279,450.355804
	C570.483276,452.420197 568.825684,452.193420 567.233032,451.781097
	C565.023499,451.209137 563.428589,451.917328 562.030945,454.060089
	C561.476501,454.682861 561.091492,454.965240 560.320801,455.309143
	C559.727722,455.393799 559.484314,455.368958 558.947937,455.122437
	C557.941406,453.722626 558.372864,452.546143 558.746094,451.027893
	C558.989136,449.740967 559.522888,448.230865 558.310425,448.129730
	C553.434326,447.722992 548.433777,444.336456 543.325806,448.594574
	C542.500061,448.802399 542.017273,448.781952 541.231079,448.471741
z"
        />
        <path
          fill="#847876"
          opacity="1.000000"
          stroke="none"
          d="
M569.669434,464.730194
	C570.112854,464.976135 570.340393,465.026550 570.908936,465.154388
	C572.523254,469.852692 568.310669,471.503571 566.069336,474.627136
	C562.977600,477.699463 559.831299,480.152893 556.718750,482.637878
	C555.358887,483.723541 554.015869,485.335571 551.718628,483.706970
	C550.923706,481.200134 552.987549,480.056976 553.656982,477.989471
	C554.564697,475.999298 555.783691,474.701813 557.519226,473.448425
	C558.286133,472.954498 558.825989,472.698059 559.566223,472.244049
	C559.924622,471.966705 560.067688,471.859406 560.427856,471.603058
	C560.931580,471.272644 561.221741,471.097015 561.715881,470.793823
	C561.915955,470.659760 562.300415,470.385620 562.481384,470.239868
	C562.662354,470.094116 562.998840,469.778046 563.293091,469.421082
	C563.765747,468.804840 563.942566,468.544403 564.250122,468.075745
	C564.382446,467.868652 564.678406,467.465973 564.839844,467.271973
	C565.231995,466.824066 565.463562,466.571045 565.868286,466.113525
	C566.163696,465.773651 566.286865,465.639130 566.611877,465.313049
	C567.108215,464.881561 567.427612,464.680328 568.044922,464.426270
	C568.715454,464.314362 569.093079,464.373810 569.669434,464.730194
z"
        />
        <path
          fill="#6D5A5B"
          opacity="1.000000"
          stroke="none"
          d="
M565.109070,474.267700
	C566.757324,471.094391 570.071289,469.372498 571.142517,465.552856
	C572.424744,463.412811 573.599548,461.593811 574.911865,459.479431
	C575.561462,458.479523 576.271851,458.242371 577.480530,458.439911
	C578.265320,458.903320 578.530884,459.328705 578.646301,460.225800
	C577.030823,464.597137 575.009155,468.369476 572.170959,472.096466
	C570.832214,473.676056 569.691223,474.945984 567.599121,475.496338
	C566.468445,475.459259 565.825806,475.128967 565.109070,474.267700
z"
        />
        <path
          fill="#9B8881"
          opacity="1.000000"
          stroke="none"
          d="
M578.205566,459.220612
	C577.236084,459.013611 576.501343,459.017395 575.407959,459.102600
	C575.263245,458.334839 575.477112,457.485687 575.834778,456.338806
	C576.743591,455.304047 576.421631,454.334625 576.773926,453.214325
	C577.016907,452.971222 577.001282,453.004730 576.987976,453.017609
	C577.421265,452.595428 577.493408,452.000946 577.827881,451.208984
	C578.005188,450.975281 578.006653,451.003632 577.993652,451.009277
	C578.668518,450.364716 578.403931,449.188416 579.622192,448.545044
	C580.185974,448.466949 580.416321,448.490753 580.946594,448.688446
	C582.400024,450.598236 581.248596,452.395996 581.336060,454.486328
	C581.234131,455.026855 581.184937,455.240173 581.042419,455.765564
	C580.746277,456.688843 580.451599,457.262817 580.084229,458.150208
	C579.818481,458.607330 579.693359,458.778595 579.316101,459.141846
	C578.856079,459.366364 578.648193,459.398895 578.205566,459.220612
z"
        />
        <path
          fill="#916E67"
          opacity="1.000000"
          stroke="none"
          d="
M569.871704,464.572601
	C569.505066,464.944641 569.124817,464.963593 568.457825,464.981049
	C567.896790,465.182922 567.630432,465.396179 567.188843,465.798279
	C567.005737,465.977234 566.992859,465.987885 566.985352,465.991394
	C566.764771,466.124146 566.551636,466.253357 565.980713,466.268738
	C563.474670,463.046509 564.915771,461.066406 568.385864,459.779724
	C569.326477,461.438141 569.592285,462.828827 569.871704,464.572601
z"
        />
        <path
          fill="#DABA90"
          opacity="1.000000"
          stroke="none"
          d="
M570.736816,451.255219
	C571.010376,450.238708 571.015869,449.496826 571.033081,448.384918
	C573.194946,447.985474 574.807678,448.443756 573.426758,451.079010
	C572.600647,452.655487 571.580872,454.130493 570.101074,455.654907
	C568.708435,454.027496 569.184021,452.689056 570.736816,451.255219
z"
        />
        <path
          fill="#DABA90"
          opacity="1.000000"
          stroke="none"
          d="
M579.871826,448.808289
	C579.183838,449.538177 578.805237,450.132599 578.203674,450.870972
	C577.542358,448.023346 579.124268,445.500854 579.950562,442.419495
	C580.260986,442.002594 580.511597,441.955170 581.144104,441.882721
	C582.168335,442.623016 582.280090,443.534454 582.215820,444.831909
	C582.134399,445.404999 582.086365,445.631317 581.928162,446.185547
	C581.420410,447.345490 580.903320,448.086853 579.871826,448.808289
z"
        />
        <path
          fill="#DABA90"
          opacity="1.000000"
          stroke="none"
          d="
M574.264648,453.894958
	C574.377930,452.657318 574.771851,451.325562 575.207642,449.852112
	C576.402649,450.600006 575.753967,451.580505 575.981079,452.664124
	C575.526367,453.255157 575.035828,453.528015 574.264648,453.894958
z"
        />
        <path
          fill="#916E67"
          opacity="1.000000"
          stroke="none"
          d="
M569.772644,457.071899
	C571.428406,458.243134 570.394470,458.979431 569.310425,459.901611
	C569.060669,460.047485 568.973145,459.972443 568.930176,459.934021
	C568.760925,459.713287 568.634705,459.530975 568.694336,458.414551
	C569.066589,457.356781 569.252991,457.233185 569.772644,457.071899
z"
        />
        <path
          fill="#DABA90"
          opacity="1.000000"
          stroke="none"
          d="
M576.759033,452.994781
	C576.836548,453.840240 576.656128,454.709229 576.227173,455.809631
	C575.991638,455.276062 576.004700,454.511078 576.017334,453.364197
	C576.016907,452.982300 576.000305,452.999542 575.991821,453.007874
	C576.155945,453.016907 576.328552,453.017639 576.759033,452.994781
z"
        />
        <path
          fill="#705250"
          opacity="1.000000"
          stroke="none"
          d="
M579.843994,438.159515
	C580.025208,438.674591 579.967590,439.353027 579.894226,440.366821
	C578.789978,440.023651 579.102844,439.184174 579.843994,438.159515
z"
        />
        <path
          fill="#DABA90"
          opacity="1.000000"
          stroke="none"
          d="
M574.012939,454.012329
	C573.905273,454.471680 573.788208,454.922180 573.671143,455.372681
	C572.963684,455.063477 573.282043,454.648224 573.768921,454.113403
	C573.984009,453.989044 574.003479,454.003418 574.012939,454.012329
z"
        />
        <path
          fill="#DABA90"
          opacity="1.000000"
          stroke="none"
          d="
M570.110046,456.808228
	C569.898254,457.299744 569.690552,457.565216 569.248779,457.927917
	C568.474731,457.387543 568.488403,456.731384 569.268433,455.888794
	C569.587830,455.717377 569.874329,455.922607 570.017395,456.027496
	C570.160461,456.132385 570.114136,456.582153 570.110046,456.808228
z"
        />
        <path
          fill="#DABA90"
          opacity="1.000000"
          stroke="none"
          d="
M577.732056,451.121246
	C577.832825,451.550446 577.660400,452.125641 577.231323,452.865662
	C577.136108,452.442719 577.297546,451.854950 577.732056,451.121246
z"
        />
        <path
          fill="#E1B582"
          opacity="1.000000"
          stroke="none"
          d="
M570.974609,442.925354
	C571.196106,443.234924 571.435242,443.574829 571.647095,443.930939
	C571.659119,443.951141 571.447571,444.104309 571.340088,444.195618
	C571.230652,443.934967 571.121155,443.674286 570.963623,443.191040
	C570.915527,442.968445 570.986389,442.959229 570.974609,442.925354
z"
        />
        <path
          fill="#87655F"
          opacity="1.000000"
          stroke="none"
          d="
M574.112305,441.944092
	C574.067322,441.957031 574.157288,441.931091 574.112305,441.944092
z"
        />
        <path
          fill="#87655F"
          opacity="1.000000"
          stroke="none"
          d="
M574.006775,442.009705
	C574.014954,442.020081 573.747070,442.418762 573.747070,442.418762
	C573.747070,442.418762 573.749023,442.090118 573.863159,442.036530
	C573.977295,441.982941 573.998596,441.999298 574.006775,442.009705
z"
        />
        <path
          fill="#C1ACB2"
          opacity="1.000000"
          stroke="none"
          d="
M586.744629,447.218872
	C587.165405,453.078064 584.920776,458.640381 583.232300,464.703430
	C584.263916,471.949951 580.162415,477.434937 578.180481,483.480865
	C577.671204,485.034576 576.671570,486.439941 575.609192,488.190369
	C573.522156,491.424316 572.931458,495.395569 568.502930,496.453644
	C567.407410,495.989380 566.900391,495.411682 566.375610,494.356171
	C565.993958,489.447815 569.095093,486.164032 570.812805,481.969299
	C573.161377,477.268616 575.850098,473.102600 576.634949,467.780823
	C576.739075,467.215210 576.801086,466.994293 577.000061,466.460602
	C577.545288,465.358978 578.222778,464.757782 578.800476,463.696381
	C580.063049,461.572815 580.449890,459.360687 582.450562,457.360046
	C585.192017,454.202423 584.739014,450.429718 586.744629,447.218872
z"
        />
        <path
          fill="#BEAAA0"
          opacity="1.000000"
          stroke="none"
          d="
M579.728394,456.196960
	C579.773071,453.852264 580.148438,451.793060 580.936646,449.399231
	C580.391235,448.311249 580.516724,447.480682 580.779419,446.317505
	C581.000427,445.969757 581.013611,446.007782 580.993896,446.011536
	C582.173889,444.759094 581.822571,442.941681 582.820190,441.268250
	C583.043884,441.005127 582.994385,441.001892 583.006104,441.023743
	C583.170288,440.901062 583.322754,440.756531 583.734375,440.791321
	C584.270142,446.058105 582.287170,450.992615 582.984802,456.565369
	C583.000916,457.023224 582.997925,456.990723 583.013916,456.990173
	C582.160828,458.144257 581.314331,459.331909 579.314331,459.167419
	C579.142761,458.144409 579.298279,457.270203 579.728394,456.196960
z"
        />
        <path
          fill="#916E67"
          opacity="1.000000"
          stroke="none"
          d="
M582.833252,441.082886
	C583.025635,442.715485 583.067810,444.443878 581.304688,445.874084
	C581.056641,444.898621 581.139160,443.781952 581.373779,442.261475
	C581.891479,441.625336 582.257080,441.393005 582.833252,441.082886
z"
        />
        <path
          fill="#C1ACB2"
          opacity="1.000000"
          stroke="none"
          d="
M587.782227,443.244995
	C587.906250,444.207214 587.750183,445.397125 587.308594,446.807251
	C587.182800,445.842560 587.342468,444.657593 587.782227,443.244995
z"
        />
        <path
          fill="#916E67"
          opacity="1.000000"
          stroke="none"
          d="
M583.998596,440.987854
	C583.993530,440.970612 583.505859,441.011200 583.261841,441.028412
	C583.214966,440.299896 582.599060,439.322052 583.855896,438.936768
	C584.379150,439.456116 584.488770,439.914764 584.193359,440.715149
	C583.968750,441.009521 584.003662,441.005066 583.998596,440.987854
z"
        />
        <path
          fill="#BEAAA0"
          opacity="1.000000"
          stroke="none"
          d="
M584.185486,440.872894
	C583.984985,440.574127 584.001160,440.138733 584.038147,439.378052
	C584.721802,439.502106 584.901306,440.049988 584.185486,440.872894
z"
        />
        <path
          fill="#87655F"
          opacity="1.000000"
          stroke="none"
          d="
M576.250977,410.166077
	C576.190674,410.021484 576.318481,409.715302 576.648071,409.267151
	C576.793274,409.455719 576.656311,409.748840 576.250977,410.166077
z"
        />
        <path
          fill="#C46B41"
          opacity="1.000000"
          stroke="none"
          d="
M636.382629,226.368225
	C636.012756,226.067245 635.574951,225.941147 635.350098,225.904526
	C630.131348,220.988571 631.798096,215.074020 633.047180,209.539703
	C634.139038,204.702057 638.660278,203.905090 642.858276,203.321381
	C647.822937,202.631058 652.789856,203.216431 657.741089,203.595352
	C660.153198,203.779968 661.877014,205.153381 661.896179,207.704941
	C661.938416,213.314835 662.215576,218.989502 659.236450,224.582642
	C658.913330,224.935226 658.466675,224.987793 658.246948,225.035950
	C651.090393,227.688629 644.010559,228.375275 636.382629,226.368225
z"
        />
        <path
          fill="#674541"
          opacity="1.000000"
          stroke="none"
          d="
M608.640625,226.077209
	C610.058472,225.983231 611.128540,225.984085 612.599854,225.977234
	C613.884094,229.115616 616.496277,227.867645 618.421326,227.944214
	C624.443665,228.183731 626.918640,230.341873 626.942200,236.431351
	C626.963501,241.953705 627.927734,247.667984 624.450073,252.915283
	C624.083679,247.337021 624.074036,241.649994 624.045044,235.497269
	C617.654541,230.225311 606.971924,232.108383 604.029602,238.715332
	C602.132629,244.564468 603.394165,250.313004 603.058228,256.458099
	C602.593506,256.940216 602.134155,256.963745 601.330078,256.985046
	C598.625122,255.015152 597.654846,252.452774 598.024902,249.458725
	C598.548279,245.223190 596.799133,240.930817 598.682434,236.369400
	C598.982910,234.609756 598.996826,233.216522 599.017578,231.405319
	C599.515198,230.494827 600.003784,230.000092 600.742004,229.263382
	C600.987610,229.017349 601.000916,229.001663 601.009033,228.995239
	C603.032776,226.991913 605.740662,226.783112 608.640625,226.077209
z"
        />
        <path
          fill="#784534"
          opacity="1.000000"
          stroke="none"
          d="
M659.294800,224.910522
	C659.580750,220.201736 660.268982,215.471069 660.909058,210.733871
	C661.430420,206.875412 660.169556,204.714645 655.807190,205.000244
	C650.214539,205.366394 644.591064,204.576935 638.998535,205.889893
	C636.091980,206.572281 634.516479,208.125916 634.298340,210.823303
	C633.913696,215.580872 633.329041,220.390778 635.026245,225.473083
	C633.511963,225.930801 631.867310,225.454788 631.325867,223.993057
	C629.174011,218.183914 628.923340,212.111710 631.522400,206.526489
	C633.588562,202.086441 637.850159,200.721771 642.842529,200.917953
	C647.818054,201.113419 652.774658,201.186676 657.703674,202.000595
	C660.498352,202.462082 662.599915,204.022568 662.805908,206.674255
	C663.228699,212.117783 663.540649,217.638901 661.783997,222.979828
	C661.448730,223.999298 660.745789,224.656250 659.294800,224.910522
z"
        />
        <path
          fill="#784534"
          opacity="1.000000"
          stroke="none"
          d="
M636.027222,226.469910
	C643.047607,225.716980 650.082520,225.366699 657.572327,225.050262
	C657.764587,227.420242 656.068359,227.621841 654.169556,228.108627
	C649.251465,229.369415 644.289490,228.881744 639.339417,228.990128
	C637.789246,229.024063 636.268250,228.935730 636.027222,226.469910
z"
        />
        <path
          fill="#543937"
          opacity="1.000000"
          stroke="none"
          d="
M598.602539,236.064087
	C599.253906,242.767349 597.933289,249.691986 600.984436,256.581512
	C598.691345,257.073303 597.141663,256.273468 597.030518,253.713196
	C596.817932,248.816666 597.453857,243.884613 596.150635,239.026627
	C595.780823,237.647919 596.770569,236.545883 598.602539,236.064087
z"
        />
        <path
          fill="#543937"
          opacity="1.000000"
          stroke="none"
          d="
M608.738403,225.734756
	C607.496216,229.126633 604.350037,228.328384 601.416992,228.990051
	C600.590515,226.362534 602.686096,226.018372 604.684082,225.147980
	C606.167725,225.164124 607.328064,225.325623 608.738403,225.734756
z"
        />
        <path
          fill="#543937"
          opacity="1.000000"
          stroke="none"
          d="
M600.678650,229.067596
	C600.886780,229.891556 600.572998,230.611893 599.331665,230.935776
	C599.114868,230.106628 599.429199,229.386917 600.678650,229.067596
z"
        />
        <path
          fill="#5C4747"
          opacity="1.000000"
          stroke="none"
          d="
M592.097412,202.145584
	C592.002441,202.000473 592.497681,201.989044 592.745178,201.994843
	C592.725891,202.097321 592.459106,202.194016 592.097412,202.145584
z"
        />
        <path
          fill="#5D413D"
          opacity="1.000000"
          stroke="none"
          d="
M590.353882,488.409058
	C592.638794,480.158081 593.466492,471.934052 595.131836,463.454010
	C598.608337,465.347717 602.012329,464.573242 605.794373,462.971771
	C611.075378,466.444824 611.673767,471.912201 612.491455,477.594910
	C605.490662,479.356537 600.178101,483.300415 595.500977,488.310150
	C595.082458,488.758423 594.364685,488.940094 593.424316,489.340637
	C592.836792,489.520203 592.596741,489.576538 591.986572,489.621887
	C591.127869,489.471741 590.744446,489.193787 590.353882,488.409058
z"
        />
        <path
          fill="#A19796"
          opacity="1.000000"
          stroke="none"
          d="
M611.708252,478.308075
	C610.138733,473.153687 608.371033,468.310516 606.388306,463.189178
	C606.035522,461.906982 605.897705,460.902893 605.651306,459.533875
	C607.073242,458.236176 606.945984,456.629059 607.249878,454.719940
	C608.725342,454.790131 609.222290,455.985199 609.623535,457.271790
	C611.972900,464.805908 615.383728,471.935974 618.161621,479.706421
	C619.472290,483.769623 621.848328,486.871338 623.684509,490.671692
	C623.946167,491.521973 623.979309,492.028168 623.827515,492.916260
	C623.364624,494.031891 622.781494,494.508362 621.607056,494.772583
	C619.497559,494.636108 618.626221,493.319824 617.417114,491.860138
	C615.453735,488.454590 614.042603,485.172089 612.079834,481.767212
	C611.446777,480.552551 611.184387,479.626038 611.708252,478.308075
z"
        />
        <path
          fill="#87655F"
          opacity="1.000000"
          stroke="none"
          d="
M601.101074,436.320953
	C604.674500,439.745728 602.630371,444.203247 602.118530,448.755798
	C598.755188,447.442596 601.519836,445.063324 601.068970,443.062714
	C600.620056,441.070526 601.020264,438.887054 601.101074,436.320953
z"
        />
        <path
          fill="#87655F"
          opacity="1.000000"
          stroke="none"
          d="
M601.594849,453.286469
	C603.390991,453.154541 604.566284,453.763702 604.754456,455.892273
	C603.768066,456.348419 604.417419,458.703735 602.879333,457.750031
	C601.401001,456.833374 600.448059,455.445099 601.594849,453.286469
z"
        />
        <path
          fill="#5D413D"
          opacity="1.000000"
          stroke="none"
          d="
M595.953369,460.806152
	C595.777466,459.933441 595.895752,458.897614 596.092224,457.479919
	C597.186890,458.258087 597.270203,459.438507 595.953369,460.806152
z"
        />
        <path
          fill="#5C4747"
          opacity="1.000000"
          stroke="none"
          d="
M617.399658,481.290466
	C613.660767,472.483032 609.528931,464.176910 607.512939,454.655823
	C607.442261,451.579620 606.600891,448.871277 607.704468,445.846497
	C611.554749,447.158630 609.711670,450.779663 610.683105,453.387939
	C611.618713,455.900360 611.592590,458.777466 613.635681,461.109833
	C613.409912,458.997711 613.184143,456.885559 612.979797,454.370361
	C614.439087,456.911926 615.876892,459.856598 617.950439,463.076324
	C619.288330,464.008301 619.600464,464.853058 619.775757,466.118073
	C620.438049,470.125580 622.214172,473.271484 624.489502,476.573059
	C625.458252,480.787781 629.659058,482.769897 630.098755,487.082489
	C627.527344,488.886749 625.576904,487.787170 623.740417,486.329498
	C621.725037,484.729736 619.920715,482.863647 617.399658,481.290466
z"
        />
        <path
          fill="#37343B"
          opacity="1.000000"
          stroke="none"
          d="
M618.164551,490.589264
	C619.236938,491.673248 619.903381,492.613068 620.725952,493.795654
	C622.678406,495.291656 624.275513,496.669006 624.019165,499.536743
	C623.548889,501.256989 622.497437,501.992310 621.382812,502.715576
	C619.888123,503.685516 617.966614,503.392120 616.790100,505.708588
	C615.876160,507.508087 612.314819,507.115204 609.879639,508.559448
	C608.314758,509.367126 606.796387,509.422913 605.401978,510.645721
	C604.855225,510.929626 604.626221,511.025818 604.034058,511.213196
	C598.647522,511.951416 594.400757,509.725922 589.827759,507.438110
	C589.375793,507.079498 589.212463,506.922272 588.881714,506.484131
	C588.375610,505.751373 589.387878,505.145111 588.306885,504.560760
	C587.618774,503.894531 587.393555,503.335571 587.583862,502.399841
	C587.800659,501.933960 587.868042,501.745728 588.029907,501.272888
	C590.958191,497.997894 594.723938,498.260010 598.303467,498.292053
	C603.697937,498.340240 608.767761,497.344238 613.533142,494.768707
	C614.442017,494.277527 615.259521,493.844177 615.699585,492.403290
	C616.277283,491.239197 616.882019,490.676422 618.164551,490.589264
z"
        />
        <path
          fill="#5C4747"
          opacity="1.000000"
          stroke="none"
          d="
M574.286072,488.318665
	C577.665894,480.833344 580.445129,473.363281 583.087524,465.436401
	C584.642456,465.845551 585.090393,464.649414 585.798523,463.806915
	C587.856140,461.359009 589.491577,461.703400 590.926270,464.857971
	C590.934326,473.071075 588.632690,480.695862 586.659180,488.727081
	C584.344055,491.162231 581.856201,492.675537 578.572388,493.522736
	C577.730774,493.683685 577.245239,493.728516 576.397461,493.729156
	C575.807800,493.657867 575.577698,493.600739 575.046753,493.360168
	C573.426636,491.746674 574.411255,490.150970 574.286072,488.318665
z"
        />
        <path
          fill="#412C2E"
          opacity="1.000000"
          stroke="none"
          d="
M618.455017,490.395081
	C618.091370,491.254944 617.612183,491.776459 617.062866,492.655090
	C614.592163,495.264404 611.418762,495.135315 608.554810,495.283173
	C602.201904,495.611237 595.799316,495.674774 589.327393,493.276611
	C589.285156,491.876038 589.553406,490.751923 589.997803,489.323608
	C590.305481,488.878418 590.437134,488.737396 590.761902,488.811920
	C591.130066,489.037384 591.305237,489.047272 591.743408,489.060791
	C596.109070,489.436279 598.203125,486.369232 600.575684,483.922180
	C603.952942,480.438843 607.635132,478.472626 612.855835,480.752136
	C614.912170,483.927795 616.625854,486.992249 618.455017,490.395081
z"
        />
        <path
          fill="#4C393B"
          opacity="1.000000"
          stroke="none"
          d="
M588.861816,493.372559
	C598.043579,493.043365 607.069824,494.345001 616.544922,493.011658
	C615.827759,497.772156 611.304504,497.698120 607.945129,498.944305
	C601.859192,501.202057 595.171936,499.078522 588.543457,500.989563
	C587.677368,498.522278 588.194153,496.133789 588.861816,493.372559
z"
        />
        <path
          fill="#574649"
          opacity="1.000000"
          stroke="none"
          d="
M573.895996,488.251099
	C577.225708,488.839478 574.511902,490.856689 575.009766,492.553101
	C577.617249,495.505920 581.169617,494.819611 584.586853,495.754791
	C584.873596,497.727814 584.894531,499.489319 584.993652,501.685455
	C585.604736,502.748413 585.799072,503.473541 585.556152,504.635620
	C584.852966,505.646118 583.852966,506.309875 583.304321,505.889252
	C577.909912,501.753601 572.329102,505.209381 566.844116,505.335205
	C566.352722,505.346466 565.865662,505.260773 565.047913,505.038391
	C564.511597,504.791229 564.311768,504.663666 563.891968,504.257629
	C563.141968,502.447235 564.370728,501.367126 564.808350,499.735596
	C565.043457,499.221924 565.151306,499.023743 565.450439,498.548645
	C566.067261,497.759125 566.568604,497.315186 567.550537,496.472534
	C571.142883,494.451691 571.787170,491.036407 573.895996,488.251099
z"
        />
        <path
          fill="#92BFCE"
          opacity="1.000000"
          stroke="none"
          d="
M584.601196,505.397827
	C584.904236,504.333038 584.954712,503.593353 585.038452,502.486877
	C585.737305,502.105316 586.402771,502.090515 587.400757,502.098969
	C587.846191,502.702911 587.959045,503.283600 588.184326,504.140289
	C588.647034,504.437561 588.997375,504.458862 588.852966,504.660187
	C588.333862,505.074493 588.309448,505.308746 588.357056,505.888916
	C589.703796,506.271790 590.198364,507.284973 590.289978,508.207642
	C590.711670,512.455994 593.247803,515.939453 594.331177,520.321289
	C594.134399,520.929077 593.970825,521.140137 593.434021,521.502441
	C587.967346,521.416626 586.754395,517.252502 584.839111,513.277466
	C584.022400,510.530396 582.972290,508.186859 584.601196,505.397827
z"
        />
        <path
          fill="#412C2E"
          opacity="1.000000"
          stroke="none"
          d="
M584.937866,495.638184
	C583.255371,496.486145 581.933960,497.893890 579.909607,496.568817
	C577.419617,494.938904 574.648254,495.156982 571.492554,497.186249
	C571.821960,494.715240 573.001343,493.827759 574.686340,492.981873
	C575.173340,492.906586 575.344055,492.919098 575.771362,492.925659
	C576.192444,492.774689 576.356750,492.629669 576.744263,492.243286
	C580.284363,492.363861 582.598206,489.561829 586.085938,489.136200
	C586.232361,490.225891 585.989563,491.414032 585.567261,492.917847
	C584.970276,493.214142 584.706970,493.415070 584.845825,493.882233
	C585.085876,494.411163 585.054504,494.860596 584.937866,495.638184
z"
        />
        <path
          fill="#574649"
          opacity="1.000000"
          stroke="none"
          d="
M585.235229,493.798340
	C584.729065,493.767731 584.340881,493.573792 583.952698,493.379822
	C584.260071,493.298889 584.567383,493.217896 585.131226,493.185242
	C585.387756,493.233551 585.353271,493.635040 585.235229,493.798340
z"
        />
        <path
          fill="#4C393B"
          opacity="1.000000"
          stroke="none"
          d="
M590.882385,488.833130
	C590.954956,489.027496 590.564270,489.027161 590.369080,489.023285
	C590.173889,489.019409 590.104675,488.537018 590.164246,488.300873
	C590.474670,488.199371 590.670044,488.390747 590.882385,488.833130
z"
        />
        <path
          fill="#B27252"
          opacity="1.000000"
          stroke="none"
          d="
M604.011902,238.812164
	C604.108215,231.815994 604.904053,231.036102 611.958862,230.991409
	C614.604858,230.974655 617.251343,231.041306 619.897583,231.043533
	C622.099731,231.045380 623.870972,231.568604 624.022095,234.625320
	C622.400635,235.017441 620.395081,235.477570 621.275696,237.349335
	C622.723755,240.427124 620.428955,242.985580 620.884521,245.868790
	C621.203552,247.887589 619.544312,249.082031 617.887878,250.042252
	C615.256409,251.567627 612.368347,250.965363 609.596191,251.030365
	C604.717590,251.144775 607.882751,247.163940 606.897339,245.255234
	C605.902588,243.328369 606.062744,241.081146 605.931030,238.923340
	C605.834534,237.340988 604.908569,238.387833 604.011902,238.812164
z"
        />
        <path
          fill="#313D44"
          opacity="1.000000"
          stroke="none"
          d="
M674.883545,248.513092
	C672.628479,248.925064 670.257629,248.983963 667.443237,249.010208
	C669.257690,245.556015 671.988770,246.630493 674.883545,248.513092
z"
        />
        <path
          fill="#43434B"
          opacity="1.000000"
          stroke="none"
          d="
M659.775146,266.382782
	C659.733032,262.742584 659.987976,259.446259 659.936768,256.154724
	C659.894287,253.419250 660.612854,251.951736 663.704468,251.629898
	C666.329407,251.356628 668.937927,251.069870 671.577209,251.037796
	C680.459534,250.929840 680.784973,251.298172 679.511475,260.139130
	C679.208435,262.243347 678.888794,264.345154 678.281982,266.706421
	C672.838440,267.152588 667.661255,266.671051 662.544861,267.634918
	C661.521301,267.827728 660.542786,267.792175 659.775146,266.382782
z"
        />
        <path
          fill="#337484"
          opacity="1.000000"
          stroke="none"
          d="
M758.902344,239.818665
	C756.326355,239.527725 755.715393,238.393433 757.089417,236.515030
	C757.799866,235.543869 758.900391,234.713272 759.984192,235.760239
	C761.553162,237.275986 760.957031,238.681946 758.902344,239.818665
z"
        />
        <path
          fill="#DE8A57"
          opacity="1.000000"
          stroke="none"
          d="
M585.079041,149.664001
	C580.623169,150.342560 577.853760,153.831207 574.203796,155.632828
	C565.375488,159.990509 566.183960,158.157913 566.133423,150.935608
	C566.029480,136.086533 563.861938,138.171494 578.940491,137.958405
	C583.259583,137.897354 587.581177,137.914108 591.899902,137.991608
	C593.135437,138.013779 594.834961,137.411926 595.411133,139.018066
	C595.892395,140.359497 594.723816,141.461044 594.001587,142.558563
	C592.973450,144.120895 591.750366,145.385483 589.869873,145.947311
	C587.932190,146.526230 586.250854,147.545822 585.079041,149.664001
z"
        />
        <path
          fill="#DE8A57"
          opacity="1.000000"
          stroke="none"
          d="
M584.997070,150.003036
	C587.945740,150.297592 589.618103,147.683609 592.465454,146.758118
	C593.515442,148.097214 592.932922,150.081375 594.021057,151.564163
	C596.119202,154.423218 594.837952,158.344696 591.382751,158.681870
	C585.907959,159.216141 580.412964,160.988464 574.162048,159.355682
	C577.778381,155.998413 582.244080,154.389099 584.941162,150.328629
	C585.006165,149.994141 585.000000,150.000000 584.997070,150.003036
z"
        />
        <path
          fill="#3A292B"
          opacity="1.000000"
          stroke="none"
          d="
M690.021729,465.659973
	C684.646973,466.347778 679.086243,465.099121 673.287598,467.038452
	C671.433044,469.754517 669.420288,468.978516 667.403809,468.041962
	C665.485535,467.151062 663.842712,465.721283 661.433472,464.974426
	C660.494385,464.394501 660.014282,463.853333 659.364136,462.995972
	C658.588745,461.449066 657.742615,460.468231 656.094360,459.830353
	C655.480103,459.310272 655.233459,458.929108 655.062134,458.147888
	C654.762756,456.210785 654.472412,454.619019 654.102783,452.592621
	C658.709412,455.347046 663.357666,456.312622 668.839905,456.034729
	C669.399780,456.054962 669.516296,456.431793 669.759399,456.214203
	C671.721008,455.993988 673.439636,455.991333 675.588501,455.978271
	C681.124390,458.391083 685.824585,461.416718 690.021729,465.659973
z"
        />
        <path
          fill="#847876"
          opacity="1.000000"
          stroke="none"
          d="
M654.736328,457.745636
	C655.348755,458.021881 655.644348,458.241211 656.091003,458.693970
	C656.635132,459.442749 657.239197,459.775421 657.567749,460.718506
	C657.580078,461.257385 657.537109,461.472229 657.300598,461.952271
	C656.897217,462.279205 656.687500,462.340973 656.237915,462.179047
	C655.998047,461.955353 655.993591,462.009247 656.020264,462.004639
	C655.760620,461.788177 655.458008,461.602875 655.095642,461.180359
	C654.110291,459.800568 653.168701,458.684235 652.126038,457.267822
	C652.823242,457.208069 653.621399,457.448395 654.736328,457.745636
z"
        />
        <path
          fill="#3A292B"
          opacity="1.000000"
          stroke="none"
          d="
M669.001709,456.001892
	C669.272339,455.904449 669.541260,455.804260 669.906372,455.850342
	C670.002441,455.996613 670.000000,456.000000 669.997620,456.001587
	C669.827393,456.108612 669.659485,456.214081 669.242126,456.157440
	C668.992737,455.995300 669.000000,455.999115 669.001709,456.001892
z"
        />
        <path
          fill="#705250"
          opacity="1.000000"
          stroke="none"
          d="
M660.932678,464.117798
	C663.667297,463.575867 665.485046,465.061829 667.404114,466.286560
	C668.890320,467.235107 670.268372,468.656921 672.566101,467.269684
	C673.769165,471.735199 677.929626,472.427216 681.274841,473.814392
	C685.531372,475.579437 689.949646,476.948273 693.853577,479.313202
	C693.522034,481.967743 689.286255,476.702118 689.918030,481.637634
	C689.788086,482.114868 689.637573,482.296082 689.170776,482.634338
	C686.665710,483.275208 684.789368,482.178406 682.488403,481.503052
	C681.321106,481.083008 680.444214,481.019562 679.203186,481.090088
	C676.346436,480.895813 674.501831,479.224365 672.222107,477.848755
	C671.814941,475.993164 670.275330,476.106659 669.180664,475.176727
	C668.739807,474.754791 668.511230,474.542053 668.126282,474.153412
	C667.946838,474.002380 668.007812,473.995209 668.006348,474.025879
	C667.594727,473.570770 666.995056,473.507507 666.190369,473.191772
	C665.944275,473.030945 665.994202,472.999115 665.992188,473.028656
	C665.788818,472.798370 665.572449,472.551727 665.145630,472.168671
	C664.950134,472.019104 664.978088,472.073395 664.953674,472.092773
	C664.937561,471.942596 664.945801,471.773071 664.955444,471.348999
	C665.091187,470.920441 665.225464,470.746429 665.171021,470.302124
	C664.982422,470.031769 664.988892,470.011292 664.978882,470.007629
	C664.812134,469.834137 664.655396,469.664337 664.243530,469.259827
	C663.988403,469.025146 663.997559,469.001221 663.987610,469.010315
	C663.574829,468.554169 662.990540,468.508026 662.220215,468.192261
	C661.989380,468.026062 661.993530,468.003326 661.983032,468.009033
	C661.702148,467.666718 661.486023,467.287689 661.187256,466.576965
	C660.761169,465.669434 660.553406,465.048096 660.932678,464.117798
z"
        />
        <path
          fill="#3E1E1C"
          opacity="1.000000"
          stroke="none"
          d="
M681.628174,480.069244
	C684.067627,480.729065 686.167847,481.343201 688.626831,482.034576
	C689.503967,482.568909 690.020081,483.028381 690.775635,483.738220
	C696.985962,485.059540 702.919861,486.234283 708.692383,488.563934
	C704.922241,489.794250 701.358154,488.626434 697.831848,487.756409
	C695.047119,487.069397 692.335388,486.537415 689.276001,487.874695
	C686.334595,489.883209 684.302673,488.546661 682.166199,486.415100
	C681.451721,483.215668 678.866638,482.939209 676.859253,482.169922
	C674.792175,481.377777 672.948975,480.575317 672.152710,477.903442
	C674.668884,477.560760 676.856140,478.921875 679.574280,479.771271
	C680.388000,479.876526 680.837280,479.956757 681.628174,480.069244
z"
        />
        <path
          fill="#705250"
          opacity="1.000000"
          stroke="none"
          d="
M681.604126,486.006592
	C684.070068,486.711304 686.167969,487.374359 688.634949,488.104309
	C689.348328,488.841064 689.692688,489.510956 690.111328,490.457153
	C690.048767,490.973145 689.911865,491.212921 689.437256,491.693207
	C686.629578,492.922424 684.257080,492.327972 681.512878,491.394623
	C676.845886,489.637146 672.492737,488.143707 668.421875,485.989258
	C666.560120,485.003906 664.470276,484.222412 663.891724,481.480591
	C664.496704,480.926361 665.031982,480.754303 665.772095,480.387207
	C665.831848,479.998596 665.686829,479.804993 665.784607,479.341736
	C671.181274,481.183685 675.737305,484.614563 681.604126,486.006592
z"
        />
        <path
          fill="#554441"
          opacity="1.000000"
          stroke="none"
          d="
M665.874756,478.742249
	C666.020874,479.258240 666.014404,479.444458 665.992432,479.911438
	C664.268372,479.187469 662.361694,478.453278 661.067139,476.398376
	C663.054138,475.777924 664.836487,476.165314 665.874756,478.742249
z"
        />
        <path
          fill="#73676A"
          opacity="1.000000"
          stroke="none"
          d="
M666.116211,473.282776
	C666.506409,473.214264 667.068542,473.397552 667.817749,473.818726
	C667.432617,473.882568 666.860413,473.708588 666.116211,473.282776
z"
        />
        <path
          fill="#73676A"
          opacity="1.000000"
          stroke="none"
          d="
M668.051270,474.226257
	C668.246521,474.126801 668.505371,474.309174 668.857422,474.746155
	C668.653320,474.879578 668.374756,474.715393 668.051270,474.226257
z"
        />
        <path
          fill="#73676A"
          opacity="1.000000"
          stroke="none"
          d="
M665.025879,472.272675
	C665.217468,472.217010 665.484802,472.414917 665.871094,472.835510
	C665.693970,472.880890 665.397827,472.703583 665.025879,472.272675
z"
        />
        <path
          fill="#3D292C"
          opacity="1.000000"
          stroke="none"
          d="
M663.621582,481.004761
	C668.659363,485.513275 674.881653,486.849579 680.828186,489.521729
	C682.685913,492.554840 680.998901,493.474762 678.241882,494.078003
	C674.533264,494.268646 671.809448,492.969543 669.265259,490.332397
	C663.971375,486.652527 658.840942,483.388947 654.583191,478.996033
	C653.521118,477.900238 651.666565,476.906250 653.915161,474.837189
	C656.503723,477.556946 659.876343,479.257751 663.621582,481.004761
z"
        />
        <path
          fill="#554441"
          opacity="1.000000"
          stroke="none"
          d="
M661.826172,466.152954
	C662.004028,466.805908 662.008057,467.151672 661.992310,467.756073
	C661.360291,467.569275 660.748047,467.123810 660.223511,466.157837
	C660.785950,465.528900 661.232849,465.598663 661.826172,466.152954
z"
        />
        <path
          fill="#554441"
          opacity="1.000000"
          stroke="none"
          d="
M659.926880,466.079834
	C659.320618,465.966705 658.789612,465.761047 658.288574,465.097412
	C659.023193,464.413971 659.630737,464.544952 660.132568,465.464417
	C660.150085,465.800385 660.002014,465.987335 659.926880,466.079834
z"
        />
        <path
          fill="#554441"
          opacity="1.000000"
          stroke="none"
          d="
M655.983398,462.268311
	C655.998047,461.955353 656.411560,462.080811 656.623840,462.120972
	C657.216797,462.393036 657.425049,462.735260 657.155884,463.673706
	C656.541626,463.643250 656.255188,463.112274 655.983398,462.268311
z"
        />
        <path
          fill="#554441"
          opacity="1.000000"
          stroke="none"
          d="
M654.831665,461.036560
	C655.342957,461.085663 655.588379,461.305389 655.917603,461.788025
	C655.596924,462.022461 655.146912,462.044891 654.363525,462.009613
	C654.133850,461.620972 654.327637,461.355743 654.831665,461.036560
z"
        />
        <path
          fill="#554441"
          opacity="1.000000"
          stroke="none"
          d="
M662.140259,468.287659
	C662.539001,468.195648 663.088562,468.365234 663.807922,468.777100
	C663.415527,468.862701 662.853333,468.705994 662.140259,468.287659
z"
        />
        <path
          fill="#554441"
          opacity="1.000000"
          stroke="none"
          d="
M657.967834,465.019928
	C657.566345,465.003204 657.313232,464.813019 656.966125,464.369141
	C656.828003,464.174255 657.100830,463.929504 657.239258,463.809448
	C657.580566,463.804840 657.783447,463.920319 658.131592,464.354248
	C658.276978,464.672729 658.072083,464.905273 657.967834,465.019928
z"
        />
        <path
          fill="#554441"
          opacity="1.000000"
          stroke="none"
          d="
M656.381348,466.928223
	C656.181946,466.874268 656.130859,466.704712 656.040344,466.272552
	C656.177185,466.277496 656.353394,466.545044 656.381348,466.928223
z"
        />
        <path
          fill="#554441"
          opacity="1.000000"
          stroke="none"
          d="
M664.053955,469.268890
	C664.315674,469.108307 664.578918,469.286224 664.873535,469.781372
	C664.685791,469.840149 664.402588,469.676392 664.053955,469.268890
z"
        />
        <path
          fill="#554441"
          opacity="1.000000"
          stroke="none"
          d="
M664.950256,470.296814
	C665.155762,470.205994 665.345337,470.368378 665.488342,470.564697
	C665.516174,470.602814 665.362671,470.773071 665.124878,470.988403
	C664.944031,470.916931 664.931091,470.739410 664.950256,470.296814
z"
        />
        <path
          fill="#3A292B"
          opacity="1.000000"
          stroke="none"
          d="
M643.694397,478.375153
	C644.748474,477.515350 645.012634,476.672943 645.002930,475.329376
	C652.266541,481.290283 658.934143,488.391876 668.541626,492.036011
	C670.358704,491.839386 671.498413,492.402557 672.627869,493.859619
	C673.233459,495.255493 673.223816,496.303589 672.635498,497.691864
	C671.115356,499.252411 669.564514,498.887268 668.191345,498.390228
	C659.099060,495.099548 651.505188,489.604462 645.181946,482.341522
	C644.330750,481.363831 643.643982,480.157593 643.694397,478.375153
z"
        />
        <path
          fill="#87655F"
          opacity="1.000000"
          stroke="none"
          d="
M672.298950,494.358704
	C671.098206,493.587067 670.199463,493.083893 669.093872,492.351013
	C668.918884,491.663208 668.950806,491.205109 668.985840,490.403625
	C671.750183,491.023621 674.511353,491.987061 677.648682,492.951599
	C682.457764,493.687836 687.262268,492.964294 691.293945,496.593262
	C691.907959,497.698181 691.966675,498.503052 691.553711,499.694794
	C684.187866,501.423920 678.446167,497.846588 672.298950,494.358704
z"
        />
        <path
          fill="#554441"
          opacity="1.000000"
          stroke="none"
          d="
M642.477173,468.462524
	C642.518066,468.441193 642.436340,468.483856 642.477173,468.462524
z"
        />
        <path
          fill="#3A292B"
          opacity="1.000000"
          stroke="none"
          d="
M645.042114,474.741333
	C644.913757,474.723724 644.798950,474.458130 644.840637,474.096252
	C644.997131,474.000000 645.055603,474.493317 645.042114,474.741333
z"
        />
        <path
          fill="#5C4747"
          opacity="1.000000"
          stroke="none"
          d="
M612.005859,449.341949
	C613.386230,450.125336 612.725952,451.805206 613.035522,453.632294
	C611.684265,452.826050 612.203003,451.196930 612.005859,449.341949
z"
        />
        <path
          fill="#A19796"
          opacity="1.000000"
          stroke="none"
          d="
M614.756836,446.443604
	C615.133667,445.561493 615.317261,444.999786 615.729858,444.247803
	C617.365906,446.626923 618.772949,449.196320 620.386230,452.029755
	C623.645935,459.485840 626.564819,466.742676 630.920898,473.693085
	C630.618347,475.007446 629.748047,475.462616 628.327026,475.667725
	C626.224426,475.307861 625.761963,473.812683 624.926880,472.187103
	C623.473877,468.886353 621.981689,466.028961 619.487183,463.369141
	C619.230408,462.829132 619.161865,462.596466 619.053345,461.999695
	C618.197327,456.478760 614.612366,452.150452 614.756836,446.443604
z"
        />
        <path
          fill="#847876"
          opacity="1.000000"
          stroke="none"
          d="
M627.831421,475.396729
	C628.776733,474.715515 629.602783,474.392395 630.762817,474.031433
	C631.096802,473.993561 631.569092,473.981262 631.805298,473.986603
	C636.031006,475.894257 637.327698,480.320496 640.732178,483.260620
	C642.144592,485.063507 643.579041,486.480560 643.229980,488.980835
	C641.006653,490.336792 639.519836,489.040680 638.121521,487.959076
	C633.811340,484.625336 629.440186,481.305695 627.831421,475.396729
z"
        />
        <path
          fill="#483A3F"
          opacity="1.000000"
          stroke="none"
          d="
M640.182068,484.082489
	C636.739929,481.507019 634.506836,478.070648 632.164551,474.307678
	C632.841919,473.407349 632.958069,472.548645 632.984253,471.332947
	C633.792603,471.649689 634.595459,472.285217 635.710205,472.951538
	C637.545715,474.815918 639.069336,476.649506 640.799011,478.741028
	C641.381226,479.370514 641.635864,479.812378 641.798035,480.713562
	C641.708252,482.181976 641.283142,483.116486 640.182068,484.082489
z"
        />
        <path
          fill="#483A3F"
          opacity="1.000000"
          stroke="none"
          d="
M633.007690,470.994202
	C633.014893,470.988586 632.589478,470.769928 632.589478,470.769928
	C632.589478,470.769928 632.892883,470.781555 632.941284,470.897858
	C632.989746,471.014160 633.000488,470.999817 633.007690,470.994202
z"
        />
        <path
          fill="#847876"
          opacity="1.000000"
          stroke="none"
          d="
M641.200317,481.278870
	C641.068054,480.490997 641.040161,480.065063 641.008667,479.319031
	C641.318054,478.532410 641.631042,478.065918 641.968811,477.305969
	C642.439148,477.267090 642.884644,477.521729 643.560974,477.889038
	C650.661987,487.825317 659.935974,494.352448 671.651245,498.088318
	C677.234741,496.562927 681.769897,499.458557 686.938110,500.608093
	C687.524475,501.410034 687.603638,501.965240 687.313843,502.932800
	C683.023193,505.917542 678.859375,504.425079 674.307678,503.455261
	C661.004150,499.196045 649.680054,492.628571 641.200317,481.278870
z"
        />
        <path
          fill="#6D5A5B"
          opacity="1.000000"
          stroke="none"
          d="
M614.660950,446.353455
	C615.133179,451.819550 619.413208,456.128632 620.132141,462.034912
	C619.613831,462.869110 619.168335,463.301575 618.235229,463.181580
	C617.971069,463.020630 617.996887,463.005066 617.990234,463.020264
	C618.110718,458.947021 615.284790,455.725189 614.698242,451.821198
	C614.434387,450.065308 613.512451,448.397644 614.660950,446.353455
z"
        />
        <path
          fill="#47A2AE"
          opacity="1.000000"
          stroke="none"
          d="
M400.623230,603.934204
	C401.080536,604.332581 401.252258,604.608215 401.739929,604.928772
	C402.337982,606.592468 403.800903,608.296509 401.198181,609.759277
	C399.946503,610.044861 398.878479,610.065002 397.406677,610.076904
	C396.820343,610.221313 396.620300,610.345825 396.226196,610.471069
	C395.718689,611.320862 395.033081,611.494019 393.879730,611.477539
	C391.225403,611.155212 389.741974,609.787659 388.396118,607.981995
	C385.561066,604.178528 385.580872,603.932373 390.637787,601.868652
	C394.185760,601.991394 397.282349,602.635071 400.623230,603.934204
z"
        />
        <path
          fill="#6CDAE1"
          opacity="1.000000"
          stroke="none"
          d="
M390.997253,601.657288
	C386.773865,604.312988 387.061615,605.915222 392.811615,610.634888
	C393.255676,611.043457 393.428772,611.234497 393.316437,611.686523
	C389.834961,612.328003 386.817749,611.637756 383.879242,610.440674
	C382.272247,609.785950 380.952209,608.923645 380.887238,606.943359
	C380.820984,604.925415 382.229767,603.936035 383.735992,603.313721
	C385.839355,602.444641 388.081207,601.910706 390.642029,601.252197
	C391.020233,601.271118 391.003937,601.528503 390.997253,601.657288
z"
        />
        <path
          fill="#76D4DF"
          opacity="1.000000"
          stroke="none"
          d="
M393.429230,611.986145
	C393.033539,611.764832 393.036072,611.582336 393.060547,611.126099
	C393.833344,610.814941 394.584167,610.777527 395.692322,610.619934
	C396.104614,612.167480 394.820770,611.884521 393.429230,611.986145
z"
        />
        <path
          fill="#635B5F"
          opacity="1.000000"
          stroke="none"
          d="
M576.928589,285.721924
	C571.276306,285.920380 565.776367,285.920380 560.276489,285.920380
	C565.525146,284.995758 570.673645,283.603882 576.435242,283.896790
	C577.611572,284.334198 577.547668,284.904297 576.928589,285.721924
z"
        />
        <path
          fill="#C99469"
          opacity="1.000000"
          stroke="none"
          d="
M508.008118,250.016998
	C500.602173,252.887695 492.919312,251.897964 485.270355,251.434937
	C492.586884,251.629578 499.780640,250.174347 507.511688,249.966690
	C507.956055,249.954605 507.992310,249.994949 508.008118,250.016998
z"
        />
        <path
          fill="#5E9BAB"
          opacity="1.000000"
          stroke="none"
          d="
M448.401062,260.122223
	C448.767853,259.575653 449.017151,259.258667 449.293182,259.233398
	C450.829651,259.092804 452.554108,258.335541 453.601715,260.189331
	C453.750031,260.451843 453.460754,261.313538 453.215332,261.390289
	C451.447937,261.942963 449.520874,262.980408 448.401062,260.122223
z"
        />
        <path
          fill="#A5654C"
          opacity="1.000000"
          stroke="none"
          d="
M568.986877,429.986511
	C569.169250,429.350952 569.361328,428.725067 569.553406,428.099213
	C570.096069,428.667969 569.745850,429.166809 569.224854,429.839355
	C569.019104,430.010315 568.996643,429.996216 568.986877,429.986511
z"
        />
        <path
          fill="#C08056"
          opacity="1.000000"
          stroke="none"
          d="
M561.931885,441.987610
	C559.181274,444.651428 553.865417,443.727631 552.941772,440.299774
	C552.992859,439.961945 553.007507,439.996490 552.989075,440.000366
	C553.501343,439.566467 553.488525,438.899078 553.840698,438.048340
	C554.075195,436.918274 554.475586,436.297607 555.713013,436.044464
	C556.014343,437.533386 556.177429,438.948059 558.008667,438.930603
	C560.059570,438.911072 560.930359,440.152802 561.931885,441.987610
z"
        />
        <path
          fill="#A19796"
          opacity="1.000000"
          stroke="none"
          d="
M537.327881,442.988464
	C539.599487,438.330627 543.751831,435.392181 548.638672,432.905914
	C545.652649,436.772736 542.605347,440.942352 537.327881,442.988464
z"
        />
        <path
          fill="#E1B582"
          opacity="1.000000"
          stroke="none"
          d="
M553.757446,437.995270
	C553.815613,438.381622 553.632324,438.985596 553.209839,439.796875
	C553.152405,439.407104 553.334167,438.809998 553.757446,437.995270
z"
        />
        <path
          fill="#C08056"
          opacity="1.000000"
          stroke="none"
          d="
M555.944946,435.925537
	C556.049622,435.814148 556.281433,435.112518 556.229126,436.005035
	C556.115723,436.092285 555.998596,435.984436 555.944946,435.925537
z"
        />
        <path
          fill="#A19796"
          opacity="1.000000"
          stroke="none"
          d="
M548.871338,432.119507
	C549.184998,431.976898 549.417847,432.166901 549.698364,432.606720
	C549.781677,432.812866 549.363342,432.918091 549.150024,432.950409
	C548.899902,432.791290 548.863037,432.599884 548.871338,432.119507
z"
        />
        <path
          fill="#554441"
          opacity="1.000000"
          stroke="none"
          d="
M548.827637,430.041168
	C549.057678,430.038757 549.026428,430.508728 548.951294,430.731903
	C548.528442,430.769470 548.180603,430.583862 547.832825,430.398254
	C548.087708,430.280029 548.342651,430.161804 548.827637,430.041168
z"
        />
        <path
          fill="#554441"
          opacity="1.000000"
          stroke="none"
          d="
M558.114014,424.951904
	C558.010254,425.007355 558.243286,424.607178 558.243286,424.607178
	C558.243286,424.607178 558.217834,424.896484 558.114014,424.951904
z"
        />
        <path
          fill="#554441"
          opacity="1.000000"
          stroke="none"
          d="
M557.996094,424.994843
	C557.992249,424.989685 557.769958,425.396515 557.769958,425.396515
	C557.769958,425.396515 557.786804,425.106018 557.898560,425.056702
	C558.010254,425.007355 558.000000,425.000000 557.996094,424.994843
z"
        />
        <path
          fill="#5C8997"
          opacity="1.000000"
          stroke="none"
          d="
M800.760193,609.206909
	C801.010986,609.263062 800.654419,609.527649 800.654419,609.527649
	C800.654419,609.527649 800.224365,609.481445 800.116943,609.375916
	C800.176086,609.230530 800.342712,609.190613 800.760193,609.206909
z"
        />
        <path
          fill="#372F35"
          opacity="1.000000"
          stroke="none"
          d="
M821.024292,505.361053
	C823.974304,504.785278 826.552246,506.162842 829.666931,506.902985
	C830.048401,506.930328 830.510193,507.003510 830.739502,507.048370
	C829.209290,511.335358 827.449646,515.577454 825.328003,519.902283
	C823.036255,517.689880 820.374695,518.039185 817.406860,518.002441
	C816.995117,517.997559 816.992004,517.506531 816.985352,517.261230
	C817.504333,512.974548 822.012756,510.361908 821.024292,505.361053
z"
        />
        <path
          fill="#574649"
          opacity="1.000000"
          stroke="none"
          d="
M833.044922,507.401123
	C834.119019,507.052216 835.326843,506.912170 835.921265,508.671631
	C834.866699,508.953461 833.670776,509.067322 833.044922,507.401123
z"
        />
        <path
          fill="#9B8881"
          opacity="1.000000"
          stroke="none"
          d="
M843.135254,507.262207
	C843.814148,507.189026 844.745728,507.369720 845.930542,507.813904
	C845.251831,507.890259 844.319885,507.703156 843.135254,507.262207
z"
        />
        <path
          fill="#33616C"
          opacity="1.000000"
          stroke="none"
          d="
M831.939331,546.357666
	C832.418335,544.781128 831.337036,542.862305 833.346252,542.320618
	C834.510315,542.006897 835.048340,543.087646 835.377319,543.993408
	C836.314758,546.574646 833.983276,546.793701 832.304382,547.847412
	C831.976135,547.562134 831.952271,547.124329 831.939331,546.357666
z"
        />
        <path
          fill="#33616C"
          opacity="1.000000"
          stroke="none"
          d="
M833.287842,538.531494
	C833.498657,538.053223 833.711792,537.878723 833.971313,537.463989
	C835.964172,536.922791 837.163879,537.929993 838.318420,539.684082
	C836.778564,539.617126 834.736511,540.754822 833.287842,538.531494
z"
        />
        <path
          fill="#321616"
          opacity="1.000000"
          stroke="none"
          d="
M792.599487,505.998718
	C794.081543,507.668243 797.062134,507.553101 796.992493,510.640381
	C797.660217,512.773010 797.659180,512.783264 799.829895,513.008423
	C801.254150,513.156250 803.330261,512.804565 803.304810,514.781250
	C803.281311,516.617859 801.251709,515.760315 800.079651,516.005554
	C799.313049,516.165955 798.534851,516.271179 797.092468,516.513611
	C799.954163,519.002197 803.392944,518.585510 805.971436,520.504089
	C802.893127,522.394592 799.882507,522.469421 796.756287,520.902161
	C794.756226,519.899414 792.684570,519.615784 790.268921,520.756348
	C787.887085,522.653625 785.925415,521.286133 783.973267,520.668579
	C782.263855,520.127808 780.677856,519.178040 778.446167,519.202209
	C777.599609,519.125000 777.127075,519.006958 776.344849,518.646301
	C775.654541,518.098083 775.420898,517.666260 775.319702,516.790161
	C775.389893,516.183167 775.460938,515.948242 775.729370,515.406494
	C778.136780,512.847595 781.248535,512.975586 784.142761,512.166260
	C783.671875,509.788452 782.017456,509.509766 780.184937,509.404755
	C778.035583,509.281555 775.902588,509.057648 774.984253,506.385681
	C775.829773,506.037811 776.551758,506.017639 777.634766,505.992004
	C782.730957,507.767151 787.464294,503.382904 792.599487,505.998718
z"
        />
        <path
          fill="#813920"
          opacity="1.000000"
          stroke="none"
          d="
M775.879944,517.627380
	C776.581238,517.790039 777.029724,517.815796 777.814819,517.852722
	C777.728760,521.103027 777.728760,521.103027 773.924194,521.091492
	C776.645691,521.507080 779.748657,521.121155 781.091797,524.645752
	C779.904053,525.328186 778.789551,525.644470 777.324341,525.976562
	C775.722534,527.261047 774.075745,526.895630 772.192749,527.125793
	C767.866211,528.729858 764.199219,524.938171 759.890381,526.682983
	C756.851440,527.393250 754.513367,526.575012 752.127258,524.692383
	C751.795288,524.174683 751.711182,523.942261 751.680908,523.348816
	C753.041931,521.332397 755.035522,521.712585 756.808167,521.328857
	C755.487854,520.376892 751.765442,522.006592 753.607300,517.869202
	C754.075439,517.517029 754.324402,517.337219 754.401489,516.981201
	C753.540222,516.439026 753.008118,515.885681 752.626892,514.799927
	C752.666809,514.224548 752.748474,514.000366 753.101501,513.537598
	C754.514160,512.683167 755.689087,512.625793 757.196777,513.280518
	C758.404907,514.596558 759.614502,515.281189 761.194092,516.019897
	C762.410950,516.698914 763.064026,518.139832 764.427368,516.844727
	C762.988220,514.515442 760.254211,516.228027 758.431519,514.381714
	C759.487366,511.411041 758.012268,506.771362 763.529785,506.466187
	C764.593262,506.194641 765.367615,506.113403 766.531616,506.020508
	C769.904602,507.864044 770.660095,512.408447 775.126892,512.611206
	C776.119812,512.656311 776.760071,514.123230 776.181030,515.692139
	C775.948242,515.945862 775.960022,515.999268 775.944580,515.974609
	C775.481812,516.395508 775.344360,516.901794 775.879944,517.627380
z"
        />
        <path
          fill="#631D07"
          opacity="1.000000"
          stroke="none"
          d="
M770.507202,526.224976
	C772.586121,525.993164 774.342712,525.980957 776.536499,525.980591
	C776.964417,526.236633 776.955139,526.480896 776.903503,527.093445
	C773.427246,528.124634 769.993286,528.787476 766.257385,529.688782
	C765.600708,530.366394 765.184692,530.736694 764.318115,531.190735
	C758.684631,533.343628 752.974854,532.539978 747.250854,534.176758
	C738.580750,535.579468 730.175354,535.991638 721.628906,538.228149
	C716.469421,537.977600 712.009033,539.565308 707.030762,540.426392
	C704.755371,540.541931 703.027893,541.408569 700.758179,541.468750
	C699.581055,541.479126 698.806152,541.611755 697.635376,541.621826
	C695.443481,541.705994 693.670410,542.640869 691.493286,541.584839
	C690.993835,541.154846 690.833557,540.939209 690.593750,540.326782
	C690.584778,539.379272 690.799438,538.892151 691.470215,538.209106
	C694.108398,536.325500 697.006470,536.904663 699.889709,535.815369
	C704.248352,534.964783 708.373047,535.308960 712.645447,533.840088
	C717.025940,532.669983 721.195862,532.850037 725.599243,531.797546
	C733.896912,529.905029 741.954529,529.304016 750.394165,528.464722
	C751.334534,528.317871 751.854248,528.169983 752.743408,527.849487
	C755.438843,527.001099 757.878357,527.898560 760.555908,527.024414
	C763.960327,525.690308 767.152222,527.766663 770.507202,526.224976
z"
        />
        <path
          fill="#562118"
          opacity="1.000000"
          stroke="none"
          d="
M781.439209,525.020020
	C778.599548,523.445435 776.118835,522.024963 772.551514,521.771301
	C774.625305,520.441101 777.134155,520.802856 778.047607,518.217896
	C782.586853,516.212158 785.272583,520.910828 789.619385,520.969849
	C791.148621,521.289185 793.028137,520.408020 793.286560,522.093628
	C793.554016,523.837158 791.731750,523.759277 790.487244,523.923462
	C787.612854,524.302612 784.735840,524.661255 781.439209,525.020020
z"
        />
        <path
          fill="#2C1E22"
          opacity="1.000000"
          stroke="none"
          d="
M817.001221,518.002441
	C814.791687,521.732117 812.725525,519.822449 810.207031,518.020264
	C812.551208,516.398499 814.351746,518.526672 816.607178,517.989502
	C816.995117,517.997559 817.000000,518.000000 817.001221,518.002441
z"
        />
        <path
          fill="#AA4B20"
          opacity="1.000000"
          stroke="none"
          d="
M763.632446,530.573975
	C764.376282,530.226501 764.825012,530.126465 765.614624,529.976807
	C770.770203,533.959412 773.076721,539.035522 773.100952,545.394287
	C773.119568,550.284729 774.495178,555.126282 774.000061,560.533203
	C769.389771,560.198853 766.482300,565.117188 761.668640,564.470093
	C759.640381,564.197388 757.186035,564.970459 754.454285,564.982361
	C753.028992,563.776794 753.597351,562.638428 754.523621,561.964355
	C762.920837,555.853210 762.517090,547.142822 761.679077,538.268372
	C761.429138,535.622559 761.266785,532.964905 763.632446,530.573975
z"
        />
        <path
          fill="#BB5B23"
          opacity="1.000000"
          stroke="none"
          d="
M763.522095,530.279053
	C762.592896,535.978333 763.350281,541.651123 763.888245,547.295166
	C764.653870,555.327637 759.455994,559.818787 754.080688,564.640137
	C750.613770,564.798218 747.254700,567.093811 743.439331,565.104980
	C742.421082,565.011230 741.788818,564.959045 740.985535,564.429810
	C740.283813,563.078552 739.642700,562.020508 739.109497,560.558655
	C739.066284,558.458618 739.866394,556.980774 741.162842,555.928223
	C745.427917,552.465881 747.746521,547.954895 749.362976,542.767944
	C750.318665,539.701294 751.645508,537.240845 747.547241,535.674011
	C746.597473,535.310852 746.200012,534.276978 746.723938,532.855957
	C752.161194,530.831299 757.749329,531.305969 763.522095,530.279053
z"
        />
        <path
          fill="#310604"
          opacity="1.000000"
          stroke="none"
          d="
M740.755127,563.716248
	C741.465088,564.260742 742.023010,564.475891 742.816284,564.880920
	C738.937988,568.032471 733.873169,567.044983 729.334106,568.321472
	C724.927551,569.560669 720.320374,570.086853 715.395508,570.994507
	C713.921265,571.059448 712.852844,571.056091 711.384399,571.016357
	C711.747009,567.232483 708.822449,567.235229 706.523438,566.561890
	C704.466919,565.959656 702.253418,565.855164 700.116943,564.370728
	C699.731750,563.544250 699.855225,563.057434 700.515625,562.366943
	C703.501587,560.725708 706.503174,560.627808 709.744019,559.834595
	C715.924011,558.939026 721.764648,558.440491 727.847534,556.958374
	C729.836121,557.037292 731.152344,557.888062 732.885254,558.683350
	C735.592651,560.503052 738.647339,561.053284 740.755127,563.716248
z"
        />
        <path
          fill="#5C8997"
          opacity="1.000000"
          stroke="none"
          d="
M715.163757,421.875946
	C714.082336,422.033325 713.404053,422.023041 712.387207,422.021362
	C712.407288,411.731110 712.703186,401.429443 713.153259,391.134521
	C713.356079,386.494446 716.867188,382.762878 722.314331,382.166504
	C731.886536,381.118561 741.514465,380.578613 751.578125,379.744202
	C755.491760,380.493866 756.220825,382.968353 756.032837,386.101929
	C755.913940,388.083862 756.000061,390.078125 756.040039,392.521973
	C757.976196,400.748901 756.506287,408.439606 755.348022,416.576843
	C755.169434,417.011566 754.969727,417.017181 754.869568,417.018555
	C754.177124,417.023132 753.584900,417.026337 752.696167,417.036560
	C749.690308,415.487946 750.282593,412.765106 750.134644,410.371796
	C749.722656,403.706787 751.473816,397.142212 751.095947,390.460083
	C750.807312,385.356171 750.273193,384.618561 745.171936,385.054749
	C738.217957,385.649261 731.314392,386.752075 724.326477,387.073425
	C720.762085,387.237305 719.025391,389.274170 718.961731,392.927094
	C718.840088,399.906067 717.569458,406.821899 717.843750,413.837280
	C717.953064,416.631836 717.967834,419.563751 715.163757,421.875946
z"
        />
        <path
          fill="#262C32"
          opacity="1.000000"
          stroke="none"
          d="
M755.463684,416.803192
	C754.517517,409.272156 755.834961,401.595673 755.997314,393.428284
	C756.607422,393.332062 757.582458,393.685486 757.583740,394.042328
	C757.610413,401.607391 759.835571,409.366486 755.463684,416.803192
z"
        />
        <path
          fill="#557E8B"
          opacity="1.000000"
          stroke="none"
          d="
M698.236816,399.942688
	C699.005249,397.358246 700.537537,397.645874 702.107422,398.589355
	C702.739380,398.969147 702.729492,399.858002 702.247559,400.329224
	C700.994202,401.554962 699.626038,401.486542 698.236816,399.942688
z"
        />
        <path
          fill="#2B3D45"
          opacity="1.000000"
          stroke="none"
          d="
M714.832092,422.412903
	C716.786072,412.547546 716.034729,402.853027 716.752869,393.262421
	C717.208496,387.177979 718.335205,385.591888 724.251099,385.063354
	C732.355408,384.339294 740.464478,383.658112 748.557434,382.821808
	C752.005127,382.465546 753.376892,383.614197 753.205566,387.216095
	C752.747803,396.841553 752.544983,406.479156 752.320312,416.577759
	C751.008667,422.907867 746.146118,424.857880 741.288879,426.563568
	C735.290161,428.670044 728.983459,429.249695 722.668701,429.059479
	C718.702271,428.939972 715.715637,427.069977 714.832092,422.412903
z"
        />
        <path
          fill="#813920"
          opacity="1.000000"
          stroke="none"
          d="
M755.595459,485.007629
	C758.444397,488.040894 762.479797,489.588196 763.989624,493.599976
	C764.381104,494.640076 764.951111,495.317932 762.933838,495.902527
	C758.950745,495.100067 755.476624,494.867065 752.069214,493.049927
	C749.370178,490.704529 746.475647,491.579620 743.754822,491.588409
	C742.321777,491.593048 740.885315,492.427094 739.213257,491.219177
	C736.803406,487.470520 732.719910,488.645447 729.379028,487.059113
	C728.362610,486.357300 727.540588,486.539307 726.767944,486.501404
	C726.877930,487.158936 726.526550,486.150543 727.090820,486.465881
	C727.454895,486.669403 728.019897,486.531006 728.746094,486.807373
	C727.644592,488.440186 725.640869,488.100372 723.994507,488.780426
	C722.434326,489.424866 721.050659,488.529175 721.523376,486.975800
	C722.692200,483.135223 719.951172,482.513458 717.597351,482.201996
	C713.718262,481.688751 709.879517,480.503967 705.444641,481.014282
	C703.039673,482.640900 700.871643,481.135925 698.429749,481.201996
	C697.578369,479.728577 696.946411,478.519684 696.700684,477.236786
	C696.122864,474.220367 696.792542,471.532288 700.512085,472.081848
	C709.903870,473.469482 719.572021,472.291473 728.773193,476.084747
	C732.410095,477.584106 736.362000,478.829254 740.619568,479.998352
	C744.366943,482.633148 748.351379,483.685791 752.715088,484.739197
	C753.744385,484.982361 754.485046,484.995483 755.595459,485.007629
z"
        />
        <path
          fill="#C08056"
          opacity="1.000000"
          stroke="none"
          d="
M738.571411,491.041199
	C743.490173,490.779877 748.078918,488.452454 752.738770,491.620361
	C750.572327,494.602081 747.082275,492.717194 743.978516,494.150513
	C743.046631,494.372284 742.504150,494.445709 741.557129,494.571411
	C738.098206,496.061371 734.864746,496.604584 731.103088,496.669556
	C725.275208,498.755005 719.717163,497.429718 713.775635,497.459167
	C712.436523,497.407501 711.539429,497.372253 710.341309,496.701782
	C709.328552,495.689087 709.223267,494.838806 709.809692,493.567627
	C719.506165,493.226776 728.950562,493.632690 738.571411,491.041199
z"
        />
        <path
          fill="#A5654C"
          opacity="1.000000"
          stroke="none"
          d="
M709.657837,493.139221
	C710.074158,493.853302 710.057190,494.488373 710.051453,495.442596
	C709.543152,496.743652 708.593689,497.344696 707.622742,497.221832
	C701.098145,496.396362 694.435669,495.997925 688.663696,491.577057
	C688.819275,491.093567 689.095398,491.035522 689.778564,490.855469
	C696.531921,491.509033 702.878296,492.284637 709.657837,493.139221
z"
        />
        <path
          fill="#632B1D"
          opacity="1.000000"
          stroke="none"
          d="
M752.909180,484.617981
	C748.409668,485.315857 744.339661,484.212402 741.076660,480.341217
	C745.092041,481.009369 749.106384,482.214691 752.909180,484.617981
z"
        />
        <path
          fill="#562118"
          opacity="1.000000"
          stroke="none"
          d="
M697.732666,480.990540
	C700.065002,480.978760 702.095215,480.986481 704.561462,481.006165
	C706.194092,484.906799 710.106445,481.630371 712.321228,483.921295
	C709.340698,485.704590 706.413086,484.112335 703.899963,483.661072
	C701.470398,483.224854 699.146606,481.750488 696.462341,481.865387
	C696.398376,481.868134 696.325928,481.671753 696.257446,481.568237
	C696.648438,481.382141 697.039490,481.196075 697.732666,480.990540
z"
        />
        <path
          fill="#412C2E"
          opacity="1.000000"
          stroke="none"
          d="
M690.988708,483.673401
	C690.163818,483.896973 689.425842,483.626953 689.049316,482.411285
	C688.985718,482.111816 689.458740,482.002197 689.698669,481.967957
	C690.274841,482.414093 690.611023,482.894501 690.988708,483.673401
z"
        />
        <path
          fill="#9A4A2B"
          opacity="1.000000"
          stroke="none"
          d="
M730.685913,495.613129
	C734.123718,495.010437 737.362366,494.754272 740.680176,493.164612
	C741.611084,493.611481 742.222595,493.809296 743.268433,493.529663
	C744.504028,493.169312 745.408142,492.953918 746.650940,493.461792
	C747.067566,493.864166 747.181580,494.074493 747.109619,494.591736
	C745.987427,495.462128 745.051270,496.025604 744.001587,496.657410
	C745.933289,497.210938 748.023193,496.986511 749.773071,498.685547
	C749.602539,499.458252 748.770630,499.848846 749.845825,500.767059
	C754.583679,500.813293 757.675232,506.223389 762.778137,504.350189
	C762.190247,504.748322 761.602417,505.146423 761.014526,505.544495
	C761.744385,505.754181 762.474304,505.963898 763.511475,506.224731
	C763.632690,508.721008 760.753113,507.727966 759.782166,509.623962
	C760.870728,510.354706 762.006714,511.117279 763.765198,512.297729
	C761.639282,512.772827 760.062561,513.125183 758.241699,513.739990
	C756.475830,514.021851 754.954163,514.041321 752.978516,514.115112
	C748.560913,518.204407 743.455933,515.750122 738.551575,516.630005
	C737.934753,516.754211 737.691589,516.809631 737.080078,516.888794
	C735.750916,516.924866 734.858826,516.534485 733.578918,516.402100
	C733.019104,516.332153 732.797241,516.295898 732.253906,516.164917
	C731.523438,515.904053 731.132080,515.703735 730.496582,515.280396
	C729.909241,514.817688 729.603882,514.522034 729.113892,513.962402
	C728.786682,513.543335 728.664062,513.367432 728.359497,512.924133
	C725.497925,509.786957 726.274597,508.114594 730.001160,507.612671
	C731.319702,507.435059 732.508789,507.049225 733.899841,506.151062
	C734.966064,505.443604 735.703125,504.859711 736.912292,504.398010
	C737.902588,503.950104 738.787354,505.035583 739.337524,503.915649
	C740.314941,501.926239 734.357300,500.897644 738.604858,498.491730
	C737.787720,498.490265 736.979431,498.510620 736.165955,498.668213
	C733.585571,499.168182 730.605042,500.413269 730.685913,495.613129
z"
        />
        <path
          fill="#562118"
          opacity="1.000000"
          stroke="none"
          d="
M776.309998,515.819641
	C774.299683,513.091675 774.299683,513.091675 767.970642,512.810608
	C772.227112,509.650360 766.896912,508.736511 766.834106,506.285522
	C769.367920,507.439514 771.843811,507.068665 774.718384,506.093536
	C776.670715,507.903717 778.834167,508.270844 780.976685,507.944885
	C783.383606,507.578705 784.501526,509.080414 785.770691,510.521942
	C787.631470,512.635498 786.971191,513.889282 784.328003,513.919250
	C781.608032,513.950073 779.131165,514.706055 776.309998,515.819641
z"
        />
        <path
          fill="#813920"
          opacity="1.000000"
          stroke="none"
          d="
M762.999390,495.998901
	C760.280945,499.063080 756.148621,498.712158 753.022705,498.605011
	C748.596008,498.453247 755.097778,496.762665 753.393188,494.977295
	C756.674866,493.473053 759.394958,495.281647 762.658691,495.781982
	C763.002869,496.002441 763.000000,496.000000 762.999390,495.998901
z"
        />
        <path
          fill="#813920"
          opacity="1.000000"
          stroke="none"
          d="
M747.359863,494.922607
	C746.923523,494.898651 746.913086,494.536926 746.910828,494.356018
	C748.979309,491.157501 751.045044,492.493805 753.053406,494.593933
	C752.996521,495.005524 753.000000,495.000000 753.002991,494.996979
	C751.269409,494.978180 749.532837,494.962372 747.359863,494.922607
z"
        />
        <path
          fill="#813920"
          opacity="1.000000"
          stroke="none"
          d="
M750.028259,500.615326
	C748.886169,500.844086 747.153137,501.416168 747.007263,499.739136
	C746.913086,498.657074 748.474121,499.167206 749.636902,498.978455
	C749.983704,499.410828 749.993652,499.849335 750.028259,500.615326
z"
        />
        <path
          fill="#632B1D"
          opacity="1.000000"
          stroke="none"
          d="
M776.531982,503.475159
	C776.554016,503.515686 776.510010,503.434601 776.531982,503.475159
z"
        />
        <path
          fill="#594E52"
          opacity="1.000000"
          stroke="none"
          d="
M368.273804,491.295776
	C369.245972,491.211548 370.018433,491.292572 370.790894,491.373627
	C370.772522,491.487793 370.784119,491.684601 370.731293,491.703735
	C369.811829,492.036926 368.889740,492.293182 368.273804,491.295776
z"
        />
        <path
          fill="#485E6A"
          opacity="1.000000"
          stroke="none"
          d="
M453.753845,507.695251
	C451.062042,506.361450 448.426331,507.191406 445.452606,506.975220
	C445.300629,506.020538 443.689423,504.736511 445.821411,504.059265
	C449.848267,505.177856 453.503113,503.770569 457.203003,503.643555
	C458.473755,503.599945 459.762939,502.947601 461.165833,504.264099
	C461.094330,508.103149 457.788635,506.309418 455.875183,507.767914
	C455.003815,507.884033 454.500549,507.809937 453.753845,507.695251
z"
        />
        <path
          fill="#77878D"
          opacity="1.000000"
          stroke="none"
          d="
M461.251587,505.333679
	C458.202271,502.830200 455.365753,505.621399 452.527283,505.034943
	C450.634583,504.643890 448.648132,504.706665 446.404022,504.308716
	C446.104401,504.051086 446.540619,504.018524 446.757202,503.989197
	C452.763397,503.221832 458.629272,502.943451 464.627625,501.056152
	C464.963379,501.324005 464.906372,501.632111 464.795959,502.407166
	C464.652954,503.835571 464.055481,504.517395 463.165649,505.372375
	C462.421509,505.652039 461.973694,505.654419 461.251587,505.333679
z"
        />
        <path
          fill="#485E6A"
          opacity="1.000000"
          stroke="none"
          d="
M463.225769,505.162842
	C462.996185,504.001587 463.411072,503.354584 464.539337,502.983337
	C478.378845,498.827332 492.531464,496.678314 506.282745,491.607727
	C507.250763,491.430420 507.744202,491.601837 508.229187,492.462891
	C507.835388,494.835419 505.994202,495.037750 504.545868,495.684845
	C498.346313,498.454712 491.816437,500.026825 484.706635,500.936371
	C479.740692,501.839691 475.034454,501.984650 470.297546,503.817291
	C468.767090,505.347168 467.119385,504.846832 465.260529,505.147949
	C464.442139,505.458466 463.984100,505.521362 463.225769,505.162842
z"
        />
        <path
          fill="#73676A"
          opacity="1.000000"
          stroke="none"
          d="
M529.888184,483.037109
	C533.083069,480.686218 536.384827,478.641754 539.912720,476.342407
	C540.793640,473.908691 542.897766,473.438965 544.736145,472.147034
	C544.966309,471.909668 544.986694,471.981506 544.952881,471.965576
	C547.343384,469.806519 549.517334,467.407532 552.100159,464.924866
	C552.848328,464.159271 553.409302,463.736908 554.471436,463.492004
	C555.571472,463.523193 556.220703,463.817688 557.039551,464.541931
	C557.344971,465.039124 557.414246,465.266174 557.453003,465.845947
	C556.646240,468.603729 553.990417,469.137939 552.475830,471.267548
	C552.160522,471.721466 552.025146,471.895172 551.655640,472.297943
	C551.087524,472.795135 550.740662,473.045258 550.122192,473.447327
	C549.691833,473.726227 549.519165,473.833710 549.085327,474.091370
	C548.088806,474.547516 547.466919,475.019012 546.683411,475.768555
	C546.330994,476.113007 546.189514,476.251434 545.825378,476.589355
	C545.449280,476.918030 545.295715,477.047119 544.903442,477.363525
	C544.499634,477.670593 544.334595,477.790344 543.911987,478.077942
	C542.907166,478.610291 542.264648,479.136139 541.315552,479.773254
	C539.947388,480.593872 538.798706,481.160461 537.470703,482.056946
	C537.030212,482.363464 536.846985,482.475708 536.374878,482.729736
	C535.496155,483.120972 534.884399,483.305573 533.973145,483.601135
	C533.469849,483.777405 533.264038,483.836792 532.740173,483.952118
	C531.989075,484.039520 531.559021,484.014069 530.824890,483.833893
	C530.343872,483.599548 530.169434,483.471497 529.888184,483.037109
z"
        />
        <path
          fill="#73676A"
          opacity="1.000000"
          stroke="none"
          d="
M508.223389,493.200500
	C507.722443,492.615509 507.449432,492.282745 506.917389,491.745819
	C510.208130,489.599762 514.074890,488.611969 518.622437,488.013641
	C517.629883,491.395569 514.369934,491.623993 511.265015,492.837250
	C511.005585,493.083893 511.006714,493.015228 511.035889,493.033325
	C510.688507,493.318237 510.271912,493.469299 509.468994,493.494446
	C508.903229,493.486450 508.677246,493.469543 508.223389,493.200500
z"
        />
        <path
          fill="#D0C3BE"
          opacity="1.000000"
          stroke="none"
          d="
M544.726074,471.625305
	C544.391724,474.122406 542.084717,474.443176 540.430603,475.941589
	C539.848328,475.894409 539.557922,475.701233 539.147217,475.272644
	C540.034912,474.246307 541.042847,473.455475 542.247314,472.405823
	C543.124512,471.878296 543.805176,471.609589 544.726074,471.625305
z"
        />
        <path
          fill="#4B555C"
          opacity="1.000000"
          stroke="none"
          d="
M512.367004,487.112091
	C512.316772,485.355072 513.039551,484.557648 514.919800,486.097443
	C514.293030,486.639771 513.489563,486.884033 512.367004,487.112091
z"
        />
        <path
          fill="#3A3D45"
          opacity="1.000000"
          stroke="none"
          d="
M499.678040,487.970734
	C499.311768,488.591736 498.701965,489.798645 498.013367,488.033569
	C498.566406,487.864899 498.973785,487.879517 499.678040,487.970734
z"
        />
        <path
          fill="#73676A"
          opacity="1.000000"
          stroke="none"
          d="
M521.051208,487.120850
	C521.685364,486.534424 522.511719,486.239075 523.756958,486.317993
	C523.872925,487.406036 523.288452,487.712189 522.212891,487.767151
	C521.658875,487.621094 521.451172,487.516541 521.051208,487.120850
z"
        />
        <path
          fill="#73676A"
          opacity="1.000000"
          stroke="none"
          d="
M525.586792,485.377991
	C525.750366,484.638641 526.483459,484.255005 527.720581,484.135498
	C527.967590,485.514893 527.156250,485.770599 525.586792,485.377991
z"
        />
        <path
          fill="#73676A"
          opacity="1.000000"
          stroke="none"
          d="
M527.971436,483.966980
	C528.323914,483.512543 528.784424,483.205597 529.513123,482.814697
	C529.781311,482.730713 530.069885,483.100861 530.214050,483.286011
	C530.110352,484.139130 529.619202,484.488739 528.608398,484.489380
	C528.294922,484.410187 528.079529,484.114502 527.971436,483.966980
z"
        />
        <path
          fill="#73676A"
          opacity="1.000000"
          stroke="none"
          d="
M518.888794,487.866028
	C519.276794,487.448334 519.780396,487.180450 520.571533,486.871155
	C520.859009,486.829773 521.145569,487.202820 521.288086,487.389923
	C521.094849,488.211060 520.564087,488.527924 519.542236,488.432495
	C519.233521,488.317139 519.004456,488.015808 518.888794,487.866028
z"
        />
        <path
          fill="#73676A"
          opacity="1.000000"
          stroke="none"
          d="
M524.036743,486.046997
	C524.177673,485.732452 524.341064,485.446472 524.760864,485.091370
	C525.017334,485.022308 525.215027,485.282532 525.313904,485.412598
	C525.233582,485.867310 524.973755,486.103333 524.369019,486.191925
	C524.104248,486.132751 524.059265,486.075592 524.036743,486.046997
z"
        />
        <path
          fill="#403D45"
          opacity="1.000000"
          stroke="none"
          d="
M531.988525,476.984741
	C531.977783,476.971436 532.255920,476.565979 532.255920,476.565979
	C532.255920,476.565979 532.256348,476.904480 532.140869,476.961975
	C532.025452,477.019440 531.999207,476.998016 531.988525,476.984741
z"
        />
        <path
          fill="#855144"
          opacity="1.000000"
          stroke="none"
          d="
M540.775513,448.516541
	C541.384888,448.128357 541.844849,447.988617 542.650757,447.751953
	C548.505676,448.749298 554.143738,449.092224 559.874878,450.778839
	C559.717468,452.133575 559.282593,453.217712 558.797791,454.650391
	C558.249939,457.121552 557.381958,458.987305 554.927979,460.018799
	C550.316650,459.022766 546.092529,457.791992 541.783569,457.060150
	C538.954102,456.579529 537.197937,455.529602 536.984802,452.127197
	C538.189575,450.733154 539.407837,449.749054 540.775513,448.516541
z"
        />
        <path
          fill="#BEAAA0"
          opacity="1.000000"
          stroke="none"
          d="
M554.326050,460.296448
	C555.783020,458.432861 556.989197,456.824036 558.471619,455.107056
	C558.923889,455.016998 559.099915,455.035095 559.539551,455.085388
	C560.739380,459.167297 558.458557,461.589325 555.109009,463.643768
	C554.330200,464.007935 553.923889,464.387421 553.286011,464.920837
	C552.664307,465.014282 552.342773,464.832458 552.013428,464.252808
	C552.159424,462.528076 552.779114,461.328430 554.326050,460.296448
z"
        />
        <path
          fill="#D0C3BE"
          opacity="1.000000"
          stroke="none"
          d="
M551.677368,464.178223
	C552.227844,464.181732 552.518311,464.387543 552.931641,464.834076
	C550.905762,467.740234 548.587769,470.224030 545.267578,471.977905
	C545.798645,468.322083 549.703308,467.291626 551.677368,464.178223
z"
        />
        <path
          fill="#829FB1"
          opacity="1.000000"
          stroke="none"
          d="
M453.492432,523.450317
	C453.048035,523.268250 453.041138,523.032349 453.032166,522.442505
	C456.084381,521.389343 459.138672,520.690063 462.574707,519.880554
	C463.429901,519.475952 463.887238,519.148193 464.733704,519.034668
	C465.879547,518.877075 466.746033,518.732849 467.893341,518.415283
	C471.317993,514.901733 476.048798,514.800659 480.121460,512.512573
	C482.402435,512.738281 483.945312,511.513550 486.106689,511.219971
	C496.671204,512.255066 506.188538,509.155243 515.778809,506.576904
	C517.846130,506.021118 519.771423,504.879761 522.413574,505.408661
	C523.247009,505.917175 523.510803,506.380554 523.550049,507.360962
	C522.648438,509.615326 520.652954,510.252808 519.025391,511.875214
	C508.714142,515.528198 498.408112,517.078125 487.555328,517.712280
	C482.523804,517.752380 478.223572,519.365601 473.505432,520.759399
	C472.916229,520.847839 472.676819,520.853699 472.080109,520.822205
	C465.791870,521.655396 459.860931,522.525940 453.492432,523.450317
z"
        />
        <path
          fill="#2E515F"
          opacity="1.000000"
          stroke="none"
          d="
M480.932922,513.839478
	C476.562958,514.910461 473.246643,517.921631 468.556000,518.216003
	C466.610870,516.092834 464.082947,517.596741 461.815979,516.520447
	C465.541260,513.991028 469.606201,514.102539 473.668945,513.920349
	C473.562592,512.796326 472.817413,513.101562 472.346008,512.988525
	C470.102448,512.450562 466.285400,514.303589 465.921417,511.665741
	C465.637543,509.608337 469.842651,508.039185 472.066528,507.756287
	C477.808533,507.025879 483.579071,505.590729 489.736694,506.806335
	C490.130859,507.273376 490.249451,507.459412 490.268982,507.962097
	C489.498352,509.178467 488.527924,509.727448 487.390686,510.587341
	C484.872833,511.190186 483.275391,512.889099 480.932922,513.839478
z"
        />
        <path
          fill="#43434B"
          opacity="1.000000"
          stroke="none"
          d="
M531.312683,493.221466
	C531.001282,491.469513 531.075623,489.931000 530.121216,488.191559
	C529.642090,487.508484 529.451782,487.105713 529.423218,486.304291
	C529.996094,484.513977 531.427429,484.179108 532.810425,483.247925
	C533.067932,483.029480 533.006165,483.005432 533.002563,483.038391
	C533.676636,482.261353 534.661255,482.616333 535.776306,482.224304
	C536.054016,482.036102 536.001404,482.004456 536.008911,482.034241
	C536.272278,481.782684 536.578186,481.575287 537.196716,481.343323
	C537.991394,481.099731 538.562439,481.116119 539.291138,480.688049
	C539.674561,480.415283 539.835083,480.314026 540.263428,480.112427
	C541.098267,479.843536 541.592163,479.566986 542.086670,478.809418
	C542.581177,478.046570 543.029663,477.656891 543.811584,477.188629
	C544.063232,477.020050 544.002380,477.005890 544.016052,477.033966
	C544.200684,476.915924 544.371582,476.769806 544.879944,476.626465
	C546.536316,476.823456 547.717834,476.454071 548.905273,475.886505
	C551.039246,474.866547 552.613403,475.448242 553.498291,478.082703
	C547.802551,485.746490 540.359558,490.556396 531.312683,493.221466
z"
        />
        <path
          fill="#88A6B8"
          opacity="1.000000"
          stroke="none"
          d="
M499.562561,504.863159
	C500.331573,504.293396 501.258453,503.990326 502.548889,503.618591
	C503.834412,504.049500 504.747040,504.373535 505.655426,503.114899
	C513.166199,500.109344 520.612488,497.396332 528.429443,494.561035
	C528.984070,494.631165 529.168152,494.823547 529.413086,495.412048
	C529.219727,497.732391 528.292786,499.393341 526.626709,500.234222
	C517.204407,504.989441 507.780945,509.805084 496.549408,510.085205
	C494.805328,509.863586 495.504150,507.874481 493.906525,507.539490
	C493.515808,507.237122 493.389771,507.078003 493.324524,506.620483
	C494.587708,506.012909 495.790100,505.703674 497.283264,505.641144
	C498.435608,506.048950 499.198151,505.929565 499.562561,504.863159
z"
        />
        <path
          fill="#B1A5B1"
          opacity="1.000000"
          stroke="none"
          d="
M529.127319,497.277710
	C528.775391,496.712006 528.782837,496.257935 528.795166,495.121307
	C529.401184,494.072723 530.002319,493.706696 530.811768,493.166534
	C538.993347,489.276184 546.002930,484.095520 553.019897,478.354523
	C553.514038,477.579681 553.936584,477.231598 554.893921,476.977417
	C555.769653,479.544891 553.488953,480.658844 552.167969,482.741028
	C546.814819,490.523071 538.999084,494.091675 530.609131,497.411957
	C529.986206,497.436432 529.736450,497.412872 529.127319,497.277710
z"
        />
        <path
          fill="#929EAC"
          opacity="1.000000"
          stroke="none"
          d="
M507.678223,493.115479
	C508.184143,492.959045 508.372803,492.969788 508.843201,493.012115
	C509.247314,493.310547 509.369751,493.577393 509.272034,494.225586
	C501.532837,498.830017 493.277405,500.558777 484.468079,501.757141
	C483.692108,501.495605 483.538208,501.129639 483.867218,500.464691
	C492.291443,499.342926 499.990204,496.858856 507.678223,493.115479
z"
        />
        <path
          fill="#D0C3BE"
          opacity="1.000000"
          stroke="none"
          d="
M532.720947,482.998871
	C533.530640,485.116119 531.395691,484.802124 530.294312,485.896088
	C529.585449,486.461761 529.148132,486.811249 528.308716,486.999512
	C522.372437,488.741882 517.464294,492.303589 511.332642,493.364929
	C512.812622,490.278351 516.329041,490.534424 518.977173,488.546692
	C519.791626,488.158264 520.349731,487.999359 521.169250,487.708740
	C522.182617,487.247498 522.934570,486.917999 523.895386,486.360626
	C524.433594,486.103058 524.734558,485.994568 525.209961,485.675018
	C526.199219,485.284393 526.985657,485.026093 528.033508,484.588989
	C528.831421,484.214569 529.367920,484.018951 530.131348,483.647217
	C531.030151,483.303497 531.702087,483.135895 532.720947,482.998871
z"
        />
        <path
          fill="#77878D"
          opacity="1.000000"
          stroke="none"
          d="
M483.709381,500.218994
	C484.106415,500.595673 484.090302,500.929993 484.073792,501.515137
	C479.659180,502.508911 475.244965,503.251862 470.411682,504.027527
	C473.608856,499.962372 478.934296,501.719757 483.709381,500.218994
z"
        />
        <path
          fill="#142732"
          opacity="1.000000"
          stroke="none"
          d="
M462.621979,504.959229
	C463.377350,505.002441 463.803589,505.077209 464.552216,505.180603
	C462.797852,508.383545 459.446136,507.669739 455.968414,507.968262
	C456.327057,505.404022 458.838226,506.451691 460.723450,505.380310
	C461.415253,505.085663 461.854065,505.038208 462.621979,504.959229
z"
        />
        <path
          fill="#829FB1"
          opacity="1.000000"
          stroke="none"
          d="
M505.419373,502.804260
	C506.613892,503.488983 507.035126,504.236755 505.848907,504.970123
	C504.713898,505.671814 503.517792,506.213196 502.961182,503.901123
	C503.647919,503.295410 504.383392,503.040802 505.419373,502.804260
z"
        />
        <path
          fill="#829FB1"
          opacity="1.000000"
          stroke="none"
          d="
M499.306427,504.600739
	C499.971680,505.072906 500.952576,505.468323 500.232422,506.400330
	C499.622894,507.189178 498.814880,506.719788 497.844299,506.110046
	C497.574036,505.887848 497.755188,505.787720 497.837433,505.850525
	C498.248810,505.735718 498.540894,505.510132 498.949554,505.003998
	C499.103119,504.771332 499.208160,504.604980 499.306427,504.600739
z"
        />
        <path
          fill="#635B5F"
          opacity="1.000000"
          stroke="none"
          d="
M528.173218,487.282990
	C528.482788,486.693695 528.936401,486.354156 529.695679,486.046448
	C529.997131,486.471497 529.992859,486.864716 529.979553,487.550537
	C529.419434,487.739655 528.868347,487.636230 528.173218,487.282990
z"
        />
        <path
          fill="#88A6B8"
          opacity="1.000000"
          stroke="none"
          d="
M489.881348,508.156952
	C489.537323,507.798431 489.842285,507.487732 489.927277,507.287537
	C490.915558,506.882782 491.818878,506.678253 493.053711,506.397949
	C493.385284,506.322144 493.709229,506.660339 493.860046,506.839478
	C493.516541,509.458557 491.893219,509.043304 489.881348,508.156952
z"
        />
        <path
          fill="#2E515F"
          opacity="1.000000"
          stroke="none"
          d="
M464.844696,518.819946
	C464.523102,519.288086 464.033081,519.554932 463.249756,519.796021
	C463.197418,518.890076 463.433167,518.002136 464.844696,518.819946
z"
        />
        <path
          fill="#D0C3BE"
          opacity="1.000000"
          stroke="none"
          d="
M509.423157,494.564636
	C509.058746,494.346252 509.065582,494.085571 509.098633,493.434296
	C509.559937,492.983978 509.994965,492.924194 510.747559,492.957886
	C511.266022,494.081146 510.440399,494.224091 509.423157,494.564636
z"
        />
        <path
          fill="#88A6B8"
          opacity="1.000000"
          stroke="none"
          d="
M498.756836,504.828491
	C498.877258,505.069275 498.651398,505.367249 498.172607,505.789276
	C498.083313,505.570801 498.246979,505.228210 498.756836,504.828491
z"
        />
        <path
          fill="#182932"
          opacity="1.000000"
          stroke="none"
          d="
M636.385742,546.967834
	C630.152588,543.776489 623.765564,544.903564 617.288696,544.270264
	C617.951965,542.013489 619.701355,541.897522 620.598877,542.054077
	C618.283386,542.166931 615.017395,542.927124 611.718506,542.402893
	C610.569336,542.220276 609.141052,541.864197 609.046692,540.592651
	C608.945251,539.225830 610.393799,538.686401 611.515869,538.652527
	C616.780945,538.493286 621.537598,535.015015 627.044861,536.511597
	C625.116455,536.007263 623.323303,535.554138 622.876221,533.005310
	C625.481140,530.680054 628.644287,532.088562 631.664307,530.934692
	C633.974243,530.922913 635.871399,532.177551 638.180298,530.939575
	C635.987854,527.865173 635.111328,524.312500 634.213562,520.395630
	C634.039795,517.399292 635.548523,516.017151 637.985962,516.073425
	C639.619324,516.111145 640.653687,515.303833 641.765076,514.573975
	C640.623657,514.711060 639.396667,515.353271 637.841431,514.435425
	C636.493958,512.456116 635.060486,510.825378 635.707214,508.184906
	C639.087341,504.938721 641.397888,508.664917 644.054749,509.180481
	C646.981995,509.748444 649.813904,511.361786 652.719971,512.457886
	C656.041504,513.710571 655.945618,515.410400 652.318115,516.900635
	C651.294373,516.998474 650.626465,516.983215 649.969543,517.087219
	C649.492188,517.162781 648.830750,517.190002 648.657715,517.584900
	C648.412964,518.143677 648.472473,518.179688 648.725464,517.660828
	C648.938721,517.223633 649.541321,517.203796 650.377869,517.058411
	C653.445068,516.644409 655.447876,517.787659 656.797668,520.017700
	C657.877625,521.802002 656.903809,523.027039 655.003418,524.086426
	C650.190552,526.085266 648.548340,520.767395 644.833557,520.091248
	C645.672668,526.268921 650.432251,523.807800 653.963745,524.679993
	C655.533020,525.144775 656.731384,525.565735 658.088745,526.529663
	C658.864258,530.933716 658.620300,535.152710 660.290222,539.125122
	C660.869507,540.503296 660.257202,542.000671 659.236816,543.609863
	C651.619263,544.943359 644.212158,545.951965 636.385742,546.967834
z"
        />
        <path
          fill="#262C32"
          opacity="1.000000"
          stroke="none"
          d="
M637.299500,585.526611
	C635.932251,585.813354 634.856689,585.883728 633.377625,585.935608
	C636.113586,578.758545 635.770386,579.132751 628.099792,576.735046
	C622.630066,575.025269 617.360413,571.887268 614.120483,566.205811
	C615.973511,564.654968 617.297546,565.970581 618.648499,566.815491
	C621.948792,568.879639 625.208740,571.006714 628.458679,572.484436
	C625.664551,570.816101 622.019714,569.823059 619.863953,566.323364
	C625.912720,560.267700 625.912720,560.267700 621.835327,552.255310
	C627.643433,550.668823 633.249207,549.040955 639.564331,548.979858
	C642.940002,550.793335 644.189331,553.988770 642.819824,556.602478
	C638.925171,564.035767 643.205750,567.831604 648.768555,571.679077
	C654.757385,573.807983 660.310486,576.114990 666.408813,576.423096
	C667.739746,576.490356 669.055481,576.835449 669.935791,578.432983
	C663.720642,580.987488 657.156616,581.642090 650.729370,582.825745
	C646.348450,583.632507 641.944214,584.334961 637.299500,585.526611
z"
        />
        <path
          fill="#574649"
          opacity="1.000000"
          stroke="none"
          d="
M549.806091,562.555908
	C562.106079,560.580505 574.129272,558.900574 586.551880,557.200684
	C587.743591,557.925842 588.089783,558.921875 587.737610,559.875793
	C585.859985,564.962585 585.214905,570.595520 582.347046,575.175110
	C580.095520,578.770447 578.760681,582.465942 577.676880,586.816528
	C576.143921,588.403687 574.505920,588.555603 572.827209,588.464417
	C562.784546,587.919250 549.353455,573.142700 549.806091,562.555908
z"
        />
        <path
          fill="#1D2229"
          opacity="1.000000"
          stroke="none"
          d="
M648.417297,572.804688
	C645.637390,571.060608 643.049866,569.327087 640.890015,567.166199
	C639.049438,565.324829 638.210876,562.789917 639.883606,560.369141
	C642.356445,556.790466 642.148254,553.311218 640.052124,549.346313
	C645.252014,547.294373 650.766174,547.788452 656.138245,547.093567
	C658.636169,546.770508 661.094421,546.711792 663.652649,545.208496
	C667.171814,549.399170 665.641602,554.304749 665.551331,558.986206
	C665.500549,561.621094 665.028015,564.238892 665.718567,567.279175
	C665.307922,569.698792 663.740295,570.335693 662.056885,570.535950
	C657.607117,571.065186 653.287476,572.395569 648.417297,572.804688
z"
        />
        <path
          fill="#473033"
          opacity="1.000000"
          stroke="none"
          d="
M611.803955,566.719299
	C610.264893,572.872498 607.859375,578.540039 605.936279,584.294617
	C605.229858,586.408508 604.629150,587.981995 603.042236,589.749756
	C596.750122,591.914734 590.337219,592.480286 583.494751,593.073669
	C580.159119,592.689819 581.520752,590.894104 582.066162,589.099915
	C587.586426,586.082153 589.822510,581.207214 591.450989,575.896240
	C591.742859,574.944275 591.963989,573.900269 592.601929,573.158630
	C596.853821,568.215454 595.573547,562.328918 595.751221,556.359497
	C596.191040,556.072571 596.398193,556.073059 596.913818,556.110596
	C600.167847,557.327332 603.177185,558.374268 606.065063,560.446228
	C606.909912,560.988831 607.411499,561.442200 608.330261,561.870605
	C608.809143,562.168945 608.992249,562.302490 609.414551,562.678772
	C610.430420,564.027588 610.824463,565.345459 611.803955,566.719299
z"
        />
        <path
          fill="#443A42"
          opacity="1.000000"
          stroke="none"
          d="
M582.786743,588.706421
	C583.138245,590.244385 581.825806,591.330627 582.834595,592.862305
	C578.132019,594.872925 572.880310,594.949890 567.380127,595.923584
	C566.554321,595.616211 566.100403,595.153442 565.656616,595.236877
	C555.960938,597.059692 552.587341,589.074768 547.651917,583.599915
	C543.153687,578.610046 542.692505,572.572449 543.592834,566.268860
	C543.687988,565.603088 544.021606,565.019165 544.650696,564.135132
	C546.384033,563.260925 547.883789,562.728943 549.733154,562.228760
	C553.308105,573.470947 559.378601,582.321472 570.714111,586.593994
	C572.343018,587.208008 574.324890,586.885864 576.569946,586.996521
	C579.020081,586.811707 580.873474,587.296814 582.786743,588.706421
z"
        />
        <path
          fill="#543937"
          opacity="1.000000"
          stroke="none"
          d="
M583.375244,588.948730
	C581.058533,588.836853 578.888916,589.580688 577.186157,587.312256
	C576.996765,582.755737 576.522034,578.607056 580.577271,575.318787
	C582.643616,573.643250 582.549683,570.211243 583.368958,567.543640
	C584.374084,564.271179 585.743286,561.110474 586.953186,557.541138
	C589.663940,556.669678 592.313965,555.711487 595.563599,556.052734
	C600.873901,565.246338 594.401367,572.317566 591.591492,580.090881
	C590.132141,584.128174 588.926025,588.243591 583.375244,588.948730
z"
        />
        <path
          fill="#313D44"
          opacity="1.000000"
          stroke="none"
          d="
M544.447754,563.834290
	C544.922852,566.430420 545.124817,569.077942 544.962646,571.703064
	C544.701233,575.934448 546.193420,579.389404 548.927490,582.507019
	C551.419006,585.348022 553.903259,588.217834 556.120789,591.270935
	C558.858459,595.040222 563.689819,592.634644 566.862061,595.547363
	C562.605835,596.627502 558.251587,597.349182 553.478210,598.072144
	C546.957825,591.809326 543.041260,583.940613 537.343567,577.065308
	C536.962463,572.977051 537.324524,568.924561 536.065002,564.604858
	C538.715088,564.098511 541.363098,563.987122 544.447754,563.834290
z"
        />
        <path
          fill="#2F252B"
          opacity="1.000000"
          stroke="none"
          d="
M659.399963,543.784058
	C658.957153,541.042419 659.163574,538.244751 657.279541,535.553040
	C655.557312,533.092529 656.585205,530.247681 657.973389,527.353516
	C659.357666,525.073792 661.535706,524.973633 663.835999,524.303833
	C664.381287,524.086426 664.604431,524.008179 665.173706,523.842163
	C665.992432,523.675293 666.468140,523.624512 667.306885,523.576050
	C668.872742,523.457581 670.077332,523.421692 671.632446,523.657349
	C674.653931,524.974182 675.211121,527.093689 674.121887,529.625610
	C673.933777,530.062805 673.199829,529.917664 674.180420,530.303894
	C675.876282,530.971680 675.888977,532.972412 677.151794,534.453430
	C678.291565,537.940491 674.692383,538.758301 673.491943,541.548706
	C668.567261,542.539551 664.170471,543.086426 659.399963,543.784058
z"
        />
        <path
          fill="#2C1E22"
          opacity="1.000000"
          stroke="none"
          d="
M665.346313,568.390747
	C663.294495,565.828979 662.744385,563.603577 663.490417,560.705994
	C664.727539,555.900757 663.776611,550.896362 664.003662,545.522705
	C666.720825,544.705322 669.436829,544.348328 672.563660,543.987305
	C674.607666,545.245239 674.849609,547.099182 674.862000,548.925415
	C674.901489,554.734070 675.207520,560.538147 674.975647,566.817383
	C672.218384,570.518250 669.050537,570.228577 665.346313,568.390747
z"
        />
        <path
          fill="#473033"
          opacity="1.000000"
          stroke="none"
          d="
M621.549316,551.960571
	C623.152222,553.465332 625.328186,554.040527 625.866272,556.191040
	C626.914795,560.381531 627.470032,564.808350 619.981689,566.049194
	C619.126953,566.141541 618.931946,566.125427 618.447021,566.042603
	C617.965515,565.914368 617.773865,565.852783 617.303284,565.668457
	C616.455688,565.261108 615.856750,565.075073 614.888672,564.948486
	C611.417236,563.736755 608.769714,561.684448 605.897217,560.050110
	C604.247559,559.111450 602.268372,557.814819 604.676453,555.218628
	C610.031738,552.269958 615.766602,553.135864 621.549316,551.960571
z"
        />
        <path
          fill="#3E2423"
          opacity="1.000000"
          stroke="none"
          d="
M674.322693,567.297607
	C672.284424,559.681091 673.234619,552.275024 672.981812,544.453552
	C672.974548,543.983337 673.021912,543.560303 673.037964,543.348022
	C675.541321,540.848145 678.406067,541.431702 681.677368,542.316162
	C683.932800,549.938416 682.932922,557.332764 682.161743,565.161682
	C681.529480,566.467773 680.821594,567.081604 679.575623,567.786987
	C677.599976,568.505432 676.090515,568.463623 674.322693,567.297607
z"
        />
        <path
          fill="#617179"
          opacity="1.000000"
          stroke="none"
          d="
M604.581909,555.046143
	C603.475403,556.868530 604.460632,557.849548 606.165283,558.777344
	C609.181030,560.418823 612.125244,562.191467 615.433167,563.975952
	C616.312683,564.370911 616.928528,564.493164 617.762024,564.859375
	C617.989441,565.074585 617.995300,565.008911 617.967285,565.026245
	C618.413818,565.421082 619.006897,565.493103 619.772217,565.871704
	C619.985718,566.071777 619.935669,566.038086 619.918396,566.063721
	C623.615356,569.776917 629.277466,570.625610 632.675537,574.826355
	C626.006470,573.287720 620.798096,568.871460 614.484497,565.922607
	C614.149170,565.834473 614.094971,565.862854 614.084595,565.891602
	C612.529236,565.140259 610.259338,565.822998 609.202820,563.351929
	C608.983459,563.088928 608.550903,563.019592 608.333252,562.994873
	C607.580017,562.515808 606.870178,562.510437 606.003784,562.031982
	C603.014282,560.100159 600.280457,558.386169 597.384521,556.409912
	C599.545105,555.792603 601.867798,555.437683 604.581909,555.046143
z"
        />
        <path
          fill="#465962"
          opacity="1.000000"
          stroke="none"
          d="
M569.072266,556.555115
	C567.974792,556.249023 567.355957,556.109070 566.478271,555.981506
	C565.247437,554.418335 566.017090,554.147766 567.513367,553.918701
	C573.568665,552.991699 579.605347,552.331848 585.773682,554.087036
	C585.800842,552.072144 583.755798,552.628418 583.085266,551.096863
	C586.885071,551.224304 590.606140,550.156982 593.768616,553.456055
	C585.691162,554.457825 577.834106,557.358337 569.072266,556.555115
z"
        />
        <path
          fill="#310604"
          opacity="1.000000"
          stroke="none"
          d="
M682.282593,543.151123
	C679.281982,543.000854 676.609924,543.038696 673.495972,543.106201
	C673.036987,542.945496 673.019897,542.755188 672.983521,542.278809
	C672.964111,541.992676 672.993286,541.962708 673.000305,541.943176
	C674.876160,539.956299 677.767639,540.025452 680.163330,538.297607
	C681.138489,537.755493 681.842834,537.534729 682.967896,537.470459
	C685.697205,538.296448 688.307678,538.071289 690.810059,539.561829
	C691.057434,540.035889 691.055054,540.230408 690.813599,541.024780
	C688.059814,542.845093 685.531677,544.003235 682.282593,543.151123
z"
        />
        <path
          fill="#285362"
          opacity="1.000000"
          stroke="none"
          d="
M485.707306,516.366333
	C496.772400,515.566833 507.322784,513.604248 518.077515,510.631714
	C519.005310,510.531525 519.538818,510.373230 520.472839,510.112976
	C523.850708,510.802856 526.323853,508.783142 529.128418,508.596436
	C535.567932,508.167694 541.703918,509.212311 547.712036,512.565002
	C548.486145,515.531006 546.507690,516.371582 544.695007,517.136780
	C535.946228,520.829468 526.808044,522.876221 517.388000,524.128479
	C512.232117,524.813904 507.210327,524.484375 502.111206,524.035889
	C514.252930,525.559143 525.954163,523.498230 537.836487,519.673706
	C538.499023,519.583313 538.767273,519.575073 539.438049,519.579407
	C541.798828,519.717834 543.451965,520.495850 545.004761,522.248657
	C545.718933,525.278931 542.683716,525.384155 541.610229,527.370728
	C540.302612,528.326538 539.131958,528.706909 537.603882,529.174072
	C536.052734,529.410889 534.863953,529.586975 533.447144,530.337769
	C530.836304,531.228699 528.661011,531.228149 526.418091,529.416382
	C524.199890,529.406982 528.985352,526.861145 525.597595,527.799927
	C523.020142,528.514282 520.428650,527.138672 517.583801,528.244873
	C512.574341,529.471558 507.939362,526.232117 503.225952,528.224854
	C508.027100,526.219666 512.784607,529.641479 517.893799,528.104492
	C520.586182,527.560852 522.910889,528.000793 525.550293,528.595215
	C526.977051,529.101440 524.550537,532.614746 528.193115,531.060059
	C530.044434,530.269897 532.797974,530.678223 535.016296,531.627869
	C535.919922,532.014648 537.080627,532.422363 536.999329,533.560608
	C536.911682,534.786987 535.704285,534.890442 534.698853,534.956360
	C532.706543,535.086914 530.708557,535.029358 528.713013,535.088684
	C524.762268,535.206055 521.283508,536.567932 518.151001,539.110596
	C523.350769,535.322510 529.255127,534.721191 535.354309,534.919312
	C536.770203,534.965332 537.713684,533.839417 538.832825,533.133545
	C541.663147,531.348267 544.774109,530.964783 547.937378,531.988831
	C549.443970,532.476501 551.494812,532.921326 551.298828,535.071594
	C551.103394,537.216736 549.144409,537.285095 547.472534,537.425171
	C545.496826,537.590759 543.461487,537.216980 541.217896,538.203735
	C536.136902,538.906616 531.597595,540.742676 527.337036,540.523621
	C530.699402,541.834473 534.803101,541.502258 538.876587,541.526489
	C540.198669,541.534363 541.534485,541.430481 542.838867,541.615356
	C544.921936,541.910645 547.891357,542.086304 547.733154,544.595459
	C547.582947,546.979309 544.619751,546.432373 542.743469,546.427917
	C540.557983,546.422668 538.510437,547.090271 536.007629,547.318115
	C535.385315,547.277649 535.139526,547.243103 534.540039,547.105347
	C532.161621,545.603516 530.119629,545.548889 527.652039,546.033264
	C524.333008,546.684814 519.640076,548.030457 517.877930,545.235352
	C516.424622,542.930176 515.085022,543.648315 513.293579,543.004639
	C512.338562,542.609924 511.319794,542.130310 511.438293,541.737549
	C512.461121,538.347351 509.376617,540.358276 508.266205,539.226257
	C504.794342,538.110535 501.581940,537.421875 498.253387,537.494324
	C501.647064,537.458496 504.932251,538.053772 508.275208,539.581543
	C505.373108,543.998352 501.410156,541.450195 497.395752,541.214355
	C496.995178,540.997253 497.000000,541.000000 497.002411,541.003174
	C495.084351,537.119507 491.551361,538.526367 488.615143,538.705261
	C485.020416,538.924255 481.487213,539.461060 477.314880,537.787415
	C480.850342,534.933655 484.466278,536.605225 487.760010,535.768860
	C485.638794,533.021912 483.035309,535.382202 480.385010,534.994385
	C480.254303,534.502930 480.510345,534.012756 480.878906,533.270203
	C482.101379,532.708618 483.211395,532.399292 484.665955,532.049622
	C486.899109,532.272888 488.752899,532.192505 490.767883,531.223145
	C492.274506,529.219666 490.825134,529.372742 489.723846,528.948059
	C488.772125,528.580994 485.167511,530.032043 487.696472,526.492432
	C488.074982,525.962769 486.755432,526.114319 486.224365,526.011597
	C481.662231,525.128540 477.628632,522.423889 472.888397,522.139038
	C472.643555,522.124329 472.423340,521.700562 471.957245,521.126038
	C471.722839,520.784851 471.862274,520.793030 471.897156,520.853516
	C476.174316,518.665955 481.240387,519.018799 485.707306,516.366333
z"
        />
        <path
          fill="#33616C"
          opacity="1.000000"
          stroke="none"
          d="
M485.745789,530.703796
	C482.394684,530.980042 478.786560,530.980042 475.178467,530.980042
	C478.569641,528.423279 482.005127,528.383850 485.745789,530.703796
z"
        />
        <path
          fill="#2E515F"
          opacity="1.000000"
          stroke="none"
          d="
M490.578674,530.997437
	C489.901672,534.094666 487.635712,532.986572 485.278809,532.262268
	C485.315216,531.874695 485.568573,531.674988 485.886658,531.195068
	C486.002777,530.980042 486.000885,531.002075 485.989655,530.998901
	C487.372620,530.996277 488.766815,530.996765 490.578674,530.997437
z"
        />
        <path
          fill="#2E515F"
          opacity="1.000000"
          stroke="none"
          d="
M470.992554,531.989807
	C470.985260,531.980469 471.250519,531.584412 471.250519,531.584412
	C471.250519,531.584412 471.244537,531.906250 471.131409,531.960083
	C471.018311,532.013916 470.999817,531.999146 470.992554,531.989807
z"
        />
        <path
          fill="#193A47"
          opacity="1.000000"
          stroke="none"
          d="
M534.772156,546.320374
	C538.736206,545.254028 542.653198,544.807251 546.687866,544.551941
	C538.810486,541.531372 529.891052,544.810791 521.930481,540.187134
	C528.147095,539.095825 534.156372,538.040955 540.581909,536.976013
	C541.787109,537.310730 542.222229,537.903076 542.065063,539.100098
	C538.524597,541.283691 534.911682,540.355774 531.412476,540.602417
	C530.731812,540.395874 531.434692,541.543396 531.228760,540.909302
	C534.981384,540.453979 538.779724,540.723267 542.874756,540.092285
	C543.459656,539.960205 543.697571,539.932007 544.291809,539.914185
	C547.573486,541.373657 551.848694,539.100037 553.996155,543.509888
	C554.753601,544.501770 555.536072,544.990845 556.338318,545.965942
	C556.549927,546.531189 556.582764,546.777710 556.528381,547.393921
	C556.279968,547.963318 556.118713,548.163147 555.598267,548.511963
	C551.091187,547.500916 546.846313,548.325317 542.661316,547.924561
	C541.989624,548.080872 542.821167,548.800903 542.586060,548.177063
	C545.822144,547.769775 549.056335,547.974976 552.642456,548.401978
	C553.364685,549.010437 553.562134,549.494751 553.500793,550.440918
	C551.913696,552.906311 549.347290,551.327271 547.295959,552.787292
	C546.883728,553.322205 546.708618,553.555359 546.288330,553.857422
	C545.323975,553.980042 544.546753,553.990112 543.382385,553.997375
	C542.859802,553.857422 542.724304,553.720337 542.421204,553.350830
	C538.411255,551.748962 534.489136,552.680847 530.306335,552.880493
	C529.844360,553.192688 529.674194,553.338379 529.261963,553.717041
	C526.644409,553.975464 524.268982,554.000916 521.449097,554.011841
	C521.867798,552.348572 524.362122,553.668823 525.371155,551.123962
	C520.460205,552.909241 516.326233,550.274048 511.479919,550.076477
	C509.645905,550.030273 508.194824,550.085876 506.785217,549.870117
	C505.547729,549.680664 505.049988,549.038696 506.707764,548.059265
	C510.657501,546.554443 514.179993,547.408691 517.386475,548.498596
	C515.770264,547.637268 513.685730,547.231384 511.359711,546.406921
	C510.812256,546.147034 510.601074,546.021912 510.117249,545.642578
	C509.729858,545.165771 509.615173,544.943115 509.466187,544.344482
	C509.751190,542.424500 510.882080,542.047913 512.625854,541.997803
	C515.671875,542.391724 518.902466,539.858521 521.013794,543.644897
	C520.082031,543.862549 519.177185,544.073914 518.254517,544.289490
	C518.425354,544.533875 518.571350,544.926514 518.719177,544.927246
	C522.141602,544.943665 525.559204,545.311340 528.957947,544.290649
	C530.584656,543.802124 532.548096,543.335999 533.956543,545.644165
	C532.880127,547.700684 530.993591,547.305481 529.698792,547.517639
	C530.961121,546.370728 533.484619,550.175842 534.772156,546.320374
z"
        />
        <path
          fill="#2E515F"
          opacity="1.000000"
          stroke="none"
          d="
M509.668945,543.752441
	C510.008789,543.997925 510.031982,544.491882 510.035767,544.739136
	C510.039581,544.986328 510.179077,545.440125 510.179077,545.440125
	C510.179077,545.440125 510.634918,545.306824 510.813873,545.149658
	C515.294495,544.668762 517.984009,549.012268 522.332153,548.887390
	C517.345703,550.080566 512.594299,548.885193 507.418274,548.053833
	C507.009613,546.925720 507.003143,545.863647 506.998352,544.403198
	C507.000000,544.004883 507.000000,544.000000 507.002441,544.000000
	C507.677979,543.356506 508.462341,543.237549 509.668945,543.752441
z"
        />
        <path
          fill="#285362"
          opacity="1.000000"
          stroke="none"
          d="
M498.017181,547.320312
	C498.525787,547.177917 499.015961,546.971313 498.997864,547.662109
	C498.626587,547.594238 498.255341,547.526428 498.017181,547.320312
z"
        />
        <path
          fill="#285362"
          opacity="1.000000"
          stroke="none"
          d="
M492.677521,547.133362
	C492.838135,547.390503 492.799805,547.610229 492.761444,547.829956
	C492.485321,547.756775 492.209198,547.683533 491.933105,547.610229
	C492.114929,547.438843 492.296722,547.267395 492.677521,547.133362
z"
        />
        <path
          fill="#262C32"
          opacity="1.000000"
          stroke="none"
          d="
M625.352661,587.057739
	C625.565674,586.187561 626.422913,586.016235 627.706909,586.060669
	C627.505310,586.991638 626.589233,587.000183 625.352661,587.057739
z"
        />
        <path
          fill="#262C32"
          opacity="1.000000"
          stroke="none"
          d="
M628.313171,586.147705
	C628.236694,585.739014 628.430786,585.425293 628.972656,585.989014
	C628.900452,586.166443 628.731995,586.176086 628.313171,586.147705
z"
        />
        <path
          fill="#1E4552"
          opacity="1.000000"
          stroke="none"
          d="
M545.464844,541.230103
	C544.962646,541.031555 544.767578,541.026428 544.280212,541.008911
	C543.320557,540.491516 542.191040,540.758423 541.919800,539.393921
	C541.738342,538.549927 541.483521,538.026428 541.113525,537.234497
	C543.756836,534.764465 547.434387,537.007874 550.956726,535.059570
	C547.150146,532.695740 543.869812,532.193542 540.364685,534.088440
	C537.991150,535.371643 535.501282,536.034180 532.765686,536.034607
	C529.300720,536.035156 525.865967,535.334656 522.743713,538.328125
	C520.998596,540.001221 517.802612,539.048828 515.202759,538.843933
	C521.461182,534.655945 528.425415,533.938538 535.973083,533.964050
	C530.564636,530.173706 524.949768,534.094727 519.421997,532.579651
	C520.978210,529.655945 524.594727,532.369080 525.898682,529.365845
	C528.129517,528.664246 530.154663,530.702393 532.668701,529.157837
	C534.024841,528.102417 535.307129,528.703552 536.742554,528.236328
	C538.130920,527.702087 539.220581,527.368042 540.656311,527.020630
	C542.586731,525.346802 544.331543,525.996643 546.024292,526.726379
	C547.721008,527.457825 549.450012,527.891541 551.392639,527.635437
	C548.350220,527.720886 547.657349,524.586243 545.237915,523.248901
	C543.579712,522.349060 542.152405,521.700806 540.391602,521.000977
	C539.957764,520.693726 539.857300,520.438110 540.007935,519.854736
	C540.966248,519.068237 541.751221,518.883118 542.975037,518.907104
	C549.153137,519.372009 554.754211,521.176331 560.587646,521.266418
	C561.751831,521.284424 562.924438,521.360352 564.023071,521.778625
	C564.939270,522.127380 566.063110,522.502747 565.892883,523.722473
	C565.774536,524.570679 564.877808,525.031555 564.039978,525.180359
	C562.085693,525.527527 560.115173,525.926819 558.658752,525.351746
	C561.860962,528.227417 566.456909,527.188660 570.993286,528.050903
	C573.018311,529.915588 571.888489,530.751221 570.299072,531.501648
	C572.034363,531.006104 573.674622,530.043152 574.987122,530.778015
	C574.356812,530.750916 573.295959,530.422974 572.230957,529.285156
	C572.080505,525.407654 574.648499,525.522827 576.795837,526.359253
	C579.088074,527.252136 581.157715,527.633850 583.520508,526.951233
	C584.642578,526.627014 586.313782,526.733765 586.679565,527.800598
	C587.685852,530.735657 589.921265,528.648071 590.786316,529.158630
	C592.093506,529.387512 591.609070,528.424988 591.617737,529.091614
	C591.621765,529.402039 591.502014,529.450867 591.245239,529.433472
	C589.354187,529.305420 587.782898,528.377869 587.994080,526.476196
	C588.187378,524.735291 590.157288,524.690979 591.626770,524.515198
	C593.586853,524.280884 595.626526,524.873535 597.873352,523.844238
	C600.459106,523.543396 602.705750,523.442688 605.289124,523.027222
	C607.386902,523.675049 608.902588,525.263977 610.981628,524.189941
	C611.647095,524.139221 610.640869,523.624451 610.859619,524.254211
	C610.911499,524.403687 610.769958,524.461853 610.434753,524.459473
	C609.502197,524.452881 608.506226,524.691284 607.803833,523.387512
	C608.064575,522.614868 608.412415,522.283142 609.171265,521.906006
	C611.846619,521.049072 614.468506,522.405762 616.911499,520.547363
	C618.752014,517.975464 621.268005,517.180664 624.107788,516.205444
	C624.683899,515.967041 624.923157,515.886841 625.535889,515.720825
	C629.538330,515.147034 632.736084,515.640564 634.761169,519.693604
	C635.648315,521.968079 637.972778,521.777771 639.190369,522.870483
	C638.636902,524.783752 636.442932,523.378174 635.968872,525.414856
	C636.830444,526.919312 639.312683,525.147827 640.414673,526.867920
	C642.305359,527.863525 634.947937,527.718994 640.019043,529.578491
	C641.907715,530.271179 641.143005,532.512756 638.924194,532.870483
	C637.177490,533.152100 635.323486,533.302917 633.355713,531.983398
	C631.284363,529.666931 629.163208,528.359375 626.407776,527.498779
	C624.116394,527.372009 622.157532,527.770630 620.033997,526.940552
	C620.386841,525.027649 622.022278,525.380310 623.167419,524.953064
	C624.850220,524.325256 624.829285,524.446716 625.722839,522.438171
	C626.420105,520.870789 627.818726,520.885193 628.449463,521.043274
	C626.751221,520.995361 624.266541,520.702881 623.213257,523.864319
	C622.782898,525.156067 621.603638,525.422791 620.199463,525.905273
	C619.458130,526.563354 618.937134,526.909363 618.242676,526.799377
	C618.417419,527.189148 618.794250,526.988892 619.071045,527.074158
	C621.581848,527.847778 624.170532,527.356628 627.084595,527.585449
	C628.294861,528.212097 628.123840,528.879028 627.329346,529.838806
	C619.456360,532.452332 611.624390,531.710327 603.889404,532.005249
	C602.435669,532.060730 600.955261,531.429382 599.208618,532.414917
	C595.717285,534.333984 592.205505,534.393372 588.509766,534.878784
	C588.286011,537.204163 587.259155,538.472961 584.794861,538.884888
	C578.887817,539.071899 573.273926,537.905090 567.788574,539.849304
	C566.059265,540.462158 564.330505,539.703369 562.738708,538.748962
	C564.816223,540.142334 566.899353,540.035706 569.250854,539.480835
	C573.556519,538.464783 577.981812,537.829285 582.375366,540.329224
	C582.710754,541.336426 582.707153,542.040039 582.626282,543.092529
	C582.497131,543.655212 582.412292,543.871155 582.089722,544.339478
	C580.732056,545.387451 579.420288,545.385559 577.962524,545.519043
	C577.791809,545.551941 577.848572,545.885193 577.848572,545.885193
	C577.848572,545.885193 578.199097,545.973328 578.481934,546.033447
	C581.514404,547.326294 584.097473,545.471619 587.143188,545.688965
	C587.725403,545.769714 587.955261,545.821777 588.514648,546.001465
	C589.879761,546.672607 590.677856,547.547607 591.901123,548.412781
	C592.205078,548.893494 592.277344,549.114075 592.190186,549.643982
	C587.293640,549.972534 582.556519,549.991699 577.344971,550.014465
	C575.549927,550.772644 574.094116,550.928040 572.248169,550.972168
	C571.639648,550.946350 571.397583,550.927307 570.802368,550.828247
	C567.613281,549.085022 564.477722,549.763794 561.145020,549.404907
	C560.728271,549.000671 560.616943,548.788513 560.482422,548.219177
	C561.200317,545.977417 562.855530,546.110413 564.281128,546.028870
	C561.757874,545.418579 559.294617,548.715149 556.278625,546.243652
	C555.161621,545.832581 554.305420,545.657532 553.224609,545.238770
	C555.352783,542.638672 558.025818,543.404785 559.690735,543.745789
	C555.964172,541.997498 551.111694,540.403198 545.464844,541.230103
z"
        />
        <path
          fill="#1F3B45"
          opacity="1.000000"
          stroke="none"
          d="
M597.942383,532.273682
	C599.339417,528.509521 602.111572,530.421204 604.321960,530.286987
	C611.603394,529.844971 618.952148,530.674927 626.588989,529.067139
	C627.389709,529.423767 627.552673,529.916138 627.328125,530.851196
	C624.000549,532.939026 620.481201,531.342834 616.922241,532.481201
	C616.127686,533.156555 615.662354,533.725952 614.928711,533.057922
	C615.139954,533.344788 615.392700,533.426758 615.699280,533.363525
	C616.337402,533.231812 616.674255,532.675049 617.530273,532.230103
	C619.430969,532.028320 620.987305,531.786499 622.747559,532.735718
	C625.754517,536.347656 629.988464,534.077454 633.318237,536.472534
	C625.718384,537.759277 618.118591,539.046021 609.821289,540.450806
	C614.817871,541.834717 619.723877,539.720093 624.069458,542.508057
	C621.837769,542.996643 619.799194,543.442993 617.380859,543.945862
	C616.150391,544.164490 615.299744,544.326538 614.220459,544.724609
	C609.094666,546.203125 604.209290,546.758301 599.168274,545.567078
	C597.311707,545.128357 595.257385,545.357239 592.899414,545.936035
	C589.188660,545.276245 585.542786,546.068359 582.368652,543.337891
	C581.905273,542.849670 581.729248,542.665527 581.613953,542.449036
	C581.580994,542.387207 581.724243,542.231628 581.946899,542.007751
	C582.280090,540.184631 586.407593,541.817688 584.953125,538.332764
	C586.243896,537.372192 588.184875,536.929993 585.916870,535.140259
	C589.715637,532.860657 594.256958,535.006165 597.942383,532.273682
z"
        />
        <path
          fill="#385661"
          opacity="1.000000"
          stroke="none"
          d="
M560.718140,548.761475
	C564.217163,548.175842 567.452820,547.075867 570.763184,549.718445
	C572.681396,550.012146 574.362915,550.024597 576.457458,550.027588
	C575.475220,551.776428 573.503540,552.101868 571.476624,551.974915
	C567.233765,551.708984 562.999390,551.307190 558.179321,550.678833
	C557.466675,549.613281 557.740967,548.997375 558.708252,548.403320
	C559.533875,548.240601 559.997803,548.330444 560.718140,548.761475
z"
        />
        <path
          fill="#182932"
          opacity="1.000000"
          stroke="none"
          d="
M592.233032,545.237976
	C594.953796,543.124756 598.266541,543.692505 601.202515,544.239441
	C605.182068,544.980774 609.107605,544.921326 613.532471,544.971069
	C612.892212,546.813538 609.969360,545.044067 609.046753,547.761719
	C606.328552,549.707886 603.437012,548.754639 600.265808,548.883545
	C599.644653,548.708191 599.416992,548.592468 598.965210,548.183472
	C597.791260,547.229492 601.047302,546.642700 598.900269,546.325867
	C596.765381,546.010803 594.462708,546.940796 592.233032,545.237976
z"
        />
        <path
          fill="#314D58"
          opacity="1.000000"
          stroke="none"
          d="
M598.600891,548.000183
	C602.021851,548.102356 605.044067,548.087280 608.516235,548.093323
	C608.754089,548.557556 608.561890,549.375977 608.327087,549.388489
	C603.133240,549.662903 598.032593,551.655762 592.390381,550.120361
	C592.030823,549.953430 592.024841,549.504883 592.032043,549.280762
	C593.774536,546.990295 596.006897,547.533813 598.600891,548.000183
z"
        />
        <path
          fill="#33505C"
          opacity="1.000000"
          stroke="none"
          d="
M547.455200,553.026489
	C547.756958,549.915771 550.371643,551.408936 552.611572,550.928833
	C554.335571,549.801636 555.743164,549.537109 557.645996,550.714966
	C558.013489,550.994324 558.001465,551.001587 558.002563,550.994995
	C558.600525,552.606201 557.842163,553.018616 556.349365,552.993408
	C553.536987,552.946045 550.723022,552.997314 547.455200,553.026489
z"
        />
        <path
          fill="#1E4552"
          opacity="1.000000"
          stroke="none"
          d="
M510.210571,543.765259
	C509.257660,543.997498 508.506500,543.997070 507.380127,543.998291
	C504.199554,541.737915 500.182831,544.228088 497.236633,541.284363
	C500.563812,540.400146 504.413391,542.278870 507.887512,539.379761
	C510.288513,538.547302 512.042419,539.068970 512.859619,541.774048
	C512.139526,542.501160 511.275940,543.016846 510.210571,543.765259
z"
        />
        <path
          fill="#15313E"
          opacity="1.000000"
          stroke="none"
          d="
M542.664612,552.690552
	C542.993347,553.121521 542.994202,553.296143 542.995117,553.732666
	C541.284424,554.002441 539.573669,554.010315 537.409790,553.598694
	C538.539429,551.409851 540.444824,551.970581 542.664612,552.690552
z"
        />
        <path
          fill="#15313E"
          opacity="1.000000"
          stroke="none"
          d="
M537.003113,554.004883
	C535.636597,555.432739 534.066223,555.207336 532.464478,553.996948
	C533.788269,552.649048 535.101013,552.668335 536.707153,553.687927
	C536.993286,553.987793 536.999329,553.998596 537.003113,554.004883
z"
        />
        <path
          fill="#15313E"
          opacity="1.000000"
          stroke="none"
          d="
M529.386292,553.967041
	C529.018250,553.780823 529.016663,553.611633 529.020996,553.188843
	C530.055664,552.214539 531.044189,552.151489 532.005127,553.526245
	C532.040283,553.937378 531.996460,553.997681 531.972290,554.027710
	C531.216370,554.033203 530.484558,554.008667 529.386292,553.967041
z"
        />
        <path
          fill="#262C32"
          opacity="1.000000"
          stroke="none"
          d="
M608.989380,563.442627
	C610.554688,563.854614 612.125854,564.620300 613.885620,565.653198
	C613.511902,566.122070 612.949646,566.323792 612.194763,566.757507
	C610.044617,566.824585 609.065491,565.738342 608.989380,563.442627
z"
        />
        <path
          fill="#0B050D"
          opacity="1.000000"
          stroke="none"
          d="
M673.700317,567.233215
	C675.383545,566.954712 676.766785,566.944519 678.565063,566.926941
	C681.483154,567.794556 684.278625,567.419250 686.678406,568.782898
	C688.830566,570.005920 690.705322,571.307068 689.260559,574.563965
	C686.508423,575.422119 684.010803,575.954712 681.255737,576.757019
	C677.584778,577.617432 674.171265,578.208069 670.355103,578.813599
	C662.633118,577.870056 655.363831,576.719727 648.366821,573.126892
	C651.102539,568.836914 656.069946,570.451538 660.046631,569.060425
	C661.393799,568.589111 662.944885,568.700745 664.701477,568.282471
	C667.796753,567.790405 670.647827,568.444641 673.700317,567.233215
z"
        />
        <path
          fill="#1E0504"
          opacity="1.000000"
          stroke="none"
          d="
M689.410522,574.867432
	C689.365540,572.098816 687.953064,570.836060 685.427856,569.864990
	C683.356689,569.068542 680.701843,569.873413 679.073364,567.285522
	C679.480713,566.623413 679.981262,566.327332 680.774170,565.968262
	C682.902527,563.841064 685.300842,563.325989 688.328918,563.436768
	C691.160278,563.243591 693.505859,562.449951 696.372559,562.536011
	C697.618530,562.618103 698.418640,562.797241 699.434448,563.538269
	C702.475891,564.239197 705.258301,564.494751 707.966492,565.088989
	C710.709717,565.691040 712.438171,567.168518 710.996338,570.655334
	C707.473145,572.887268 703.163757,571.628418 699.384644,573.989746
	C695.930542,573.878357 692.837341,573.884705 689.410522,574.867432
z"
        />
        <path
          fill="#CD7D4C"
          opacity="1.000000"
          stroke="none"
          d="
M560.416626,450.826355
	C554.322205,451.586670 548.613403,451.365662 543.173523,448.007507
	C544.970154,444.684570 547.541382,443.642426 550.949158,445.122040
	C553.265503,446.127747 555.754333,445.927826 558.194092,446.042969
	C561.524414,446.200165 562.877930,447.433075 560.416626,450.826355
z"
        />
        <path
          fill="#875E55"
          opacity="1.000000"
          stroke="none"
          d="
M554.951172,463.919800
	C556.351807,460.992523 557.967102,458.356567 559.692688,455.419128
	C560.016113,454.825226 560.229187,454.532867 560.638184,454.055389
	C562.500977,452.786957 563.949951,453.057678 565.390015,454.932678
	C565.554443,460.579468 562.156982,463.251251 557.485168,465.134003
	C556.455688,464.760651 555.810730,464.485901 554.951172,463.919800
z"
        />
        <path
          fill="#C08056"
          opacity="1.000000"
          stroke="none"
          d="
M565.259460,456.412659
	C564.446289,454.563416 563.188110,453.980042 561.208557,453.912781
	C562.401367,447.842865 566.352173,449.486206 570.625366,450.963226
	C570.596252,452.336792 570.187683,453.693024 569.683472,455.383301
	C569.353760,456.281952 569.119751,456.846558 568.950256,457.718140
	C569.014832,458.025146 569.001953,458.000366 569.013672,458.006653
	C568.862061,458.155060 568.698853,458.297211 568.217346,458.510620
	C566.694763,458.449249 565.921082,457.753296 565.259460,456.412659
z"
        />
        <path
          fill="#484750"
          opacity="1.000000"
          stroke="none"
          d="
M529.837036,497.388672
	C537.419800,492.888458 544.804443,488.721924 551.706970,483.212952
	C557.396240,481.945007 560.197815,477.152405 564.664368,474.135803
	C565.515503,474.165741 566.096619,474.388000 566.869629,474.861237
	C566.950989,479.253174 563.604248,481.254059 561.119812,483.697449
	C559.130493,485.653900 556.937622,487.423523 554.775452,489.719055
	C554.384399,490.143066 554.229126,490.314514 553.824341,490.728516
	C553.220947,491.272522 552.852722,491.555450 552.181335,492.014832
	C547.042603,494.912628 542.862976,498.726166 537.290100,500.909241
	C536.461121,501.107452 535.974182,501.158447 535.108154,501.153717
	C532.870056,500.230499 529.894958,500.975403 529.837036,497.388672
z"
        />
        <path
          fill="#635B5F"
          opacity="1.000000"
          stroke="none"
          d="
M555.438721,476.769653
	C554.865967,477.233978 554.421021,477.539673 553.592773,477.958130
	C551.903137,476.864014 550.769836,475.576019 548.799194,477.385590
	C547.989258,478.129333 546.592041,479.708282 545.085938,477.528015
	C544.862183,477.028412 544.712280,476.823151 544.798096,476.318207
	C545.033875,476.018555 545.001282,475.996765 545.007812,476.015137
	C546.191956,475.151917 547.632080,474.630096 548.808899,473.213165
	C549.038818,472.993958 548.987732,472.982910 548.984863,473.008911
	C549.411377,472.580688 550.008179,472.517456 550.799805,472.185242
	C551.035950,472.012756 550.997559,471.997437 551.002991,472.017365
	C552.706787,469.901123 555.067810,468.564911 557.440918,466.738770
	C558.313416,466.303497 558.922852,466.304657 559.768433,466.741394
	C560.231323,467.212616 560.140564,467.761108 560.331543,467.864014
	C560.909546,468.175446 560.211609,468.103119 560.618164,467.217529
	C561.862000,465.703522 563.122131,464.960144 565.089478,465.841492
	C565.529480,466.520813 565.550232,466.972504 565.196472,467.698334
	C564.963806,467.963043 565.000000,467.992798 564.979736,467.983459
	C564.566895,468.424927 564.506470,469.005188 564.151489,469.755035
	C563.956543,469.963470 564.000000,469.998779 563.977539,469.982849
	C563.769897,470.220581 563.560730,470.452698 563.145996,470.811707
	C562.964355,470.960236 563.000000,470.998779 562.983765,470.977478
	C562.572632,471.439850 561.980225,471.480682 561.204895,471.801392
	C560.970215,471.965302 561.000000,472.000000 560.985840,471.982849
	C560.474426,472.706177 560.005127,473.475555 558.774475,473.888916
	C557.492432,474.862610 556.529480,475.736786 555.438721,476.769653
z"
        />
        <path
          fill="#B1A5B1"
          opacity="1.000000"
          stroke="none"
          d="
M565.306763,468.027740
	C565.002930,467.525208 565.041992,467.087402 565.197876,466.338806
	C565.404419,465.852753 565.494202,465.677460 565.809387,465.693115
	C566.034851,465.884003 566.512024,465.891449 566.744995,465.943176
	C567.105896,467.055542 566.726135,467.794556 565.306763,468.027740
z"
        />
        <path
          fill="#B1A5B1"
          opacity="1.000000"
          stroke="none"
          d="
M558.854370,474.049011
	C559.085999,473.381409 559.716553,472.774323 560.659424,472.066467
	C561.187500,473.312408 560.523254,473.953705 558.854370,474.049011
z"
        />
        <path
          fill="#B1A5B1"
          opacity="1.000000"
          stroke="none"
          d="
M561.285889,472.061890
	C561.430908,471.554382 561.891602,471.143494 562.659912,470.844391
	C562.790833,471.673492 562.310059,472.045349 561.285889,472.061890
z"
        />
        <path
          fill="#B1A5B1"
          opacity="1.000000"
          stroke="none"
          d="
M564.260498,469.853027
	C563.566772,469.264282 563.874512,468.717407 564.650024,468.089661
	C564.827759,468.563629 564.696106,469.153107 564.260498,469.853027
z"
        />
        <path
          fill="#B1A5B1"
          opacity="1.000000"
          stroke="none"
          d="
M567.286560,465.946747
	C567.199951,465.662781 567.394104,465.348328 567.879700,465.006714
	C567.969849,465.291779 567.768616,465.604034 567.286560,465.946747
z"
        />
        <path
          fill="#B1A5B1"
          opacity="1.000000"
          stroke="none"
          d="
M563.224243,470.907684
	C563.087158,470.605011 563.209961,470.249817 563.643921,469.930725
	C563.798035,470.262970 563.641052,470.559052 563.224243,470.907684
z"
        />
        <path
          fill="#A19796"
          opacity="1.000000"
          stroke="none"
          d="
M553.213623,490.245789
	C557.553040,485.220764 562.163330,480.463013 566.917480,475.408752
	C568.181152,474.155151 569.300781,473.198090 570.699402,472.093506
	C571.781799,472.168915 572.312561,472.701019 572.752441,473.811890
	C568.728088,481.273071 562.891785,486.589844 555.867615,490.662811
	C555.153442,491.076935 554.261658,490.872498 553.213623,490.245789
z"
        />
        <path
          fill="#9B8881"
          opacity="1.000000"
          stroke="none"
          d="
M572.477173,474.355774
	C571.820312,473.627350 571.518860,473.077972 571.097900,472.237305
	C572.987610,468.323059 574.996887,464.700134 577.326843,460.963501
	C579.067993,461.113434 578.260620,459.862122 578.820862,459.197174
	C579.014526,458.985229 579.000305,459.000244 578.993774,459.009430
	C580.129150,458.409424 581.271179,457.800232 582.721558,457.090332
	C582.196228,459.198547 581.362427,461.407471 580.243347,463.817230
	C579.483093,464.740234 578.963806,465.423981 578.138916,466.252747
	C578.003967,466.545715 577.986694,466.489044 578.015808,466.483429
	C576.097412,469.027588 577.442566,473.707489 572.477173,474.355774
z"
        />
        <path
          fill="#BEAAA0"
          opacity="1.000000"
          stroke="none"
          d="
M578.753540,458.989563
	C578.781921,459.726868 579.557922,460.894562 577.879395,460.986572
	C577.727173,460.441956 577.806824,460.034088 577.928589,459.318054
	C578.144775,459.004517 578.318726,458.999207 578.753540,458.989563
z"
        />
        <path
          fill="#916E67"
          opacity="1.000000"
          stroke="none"
          d="
M580.723877,446.166138
	C581.021912,446.730499 581.043396,447.491241 581.023804,448.632080
	C580.747009,449.002441 580.511353,448.992676 579.919067,448.963348
	C579.857361,448.083313 580.152344,447.222900 580.723877,446.166138
z"
        />
        <path
          fill="#9B8881"
          opacity="1.000000"
          stroke="none"
          d="
M565.870117,465.625916
	C566.034851,465.884003 565.681091,465.989441 565.497803,466.008728
	C563.331665,465.542786 562.428406,467.476562 560.374390,467.941193
	C559.437012,467.624420 559.021179,467.366669 558.346313,467.085205
	C557.678589,466.998016 557.338623,466.813232 557.010071,466.195221
	C556.942261,465.901581 557.007080,465.465820 557.053894,465.250610
	C560.158081,462.720886 562.834351,460.055542 564.930542,456.420471
	C566.219604,456.020355 566.806213,456.760620 567.564270,457.762390
	C568.077271,458.136261 568.359802,458.338348 568.786987,458.770996
	C568.952087,458.973389 568.931458,459.436371 568.909302,459.666016
	C566.465698,460.928406 565.454895,462.781403 565.870117,465.625916
z"
        />
        <path
          fill="#DABA90"
          opacity="1.000000"
          stroke="none"
          d="
M569.002075,458.735718
	C568.649353,458.792084 568.346558,458.610779 567.910461,458.194061
	C567.986938,457.927063 568.196777,457.895447 568.715942,457.938416
	C569.025330,458.012939 569.052063,458.497986 569.002075,458.735718
z"
        />
        <path
          fill="#403D45"
          opacity="1.000000"
          stroke="none"
          d="
M572.553223,481.718445
	C570.819031,485.762543 568.878967,489.505432 566.925415,493.614410
	C566.615540,495.250549 565.820129,496.147888 564.444580,497.034241
	C560.419861,498.565765 556.924194,500.389099 552.948730,502.082153
	C549.722290,502.757019 546.929504,503.826172 543.527710,503.363556
	C542.290344,503.170502 541.577881,502.801880 540.856812,501.786804
	C541.056946,499.184601 542.864868,498.528534 544.975220,497.699829
	C553.480652,494.979309 559.255310,488.920441 565.621277,483.712860
	C567.549622,482.135437 569.133850,479.321259 572.553223,481.718445
z"
        />
        <path
          fill="#6D5A5B"
          opacity="1.000000"
          stroke="none"
          d="
M572.927002,481.754333
	C570.130615,480.616669 568.917969,482.720062 567.359558,484.132416
	C561.018616,489.878998 554.757446,495.752533 546.133057,498.683838
	C544.815125,494.422180 549.230347,494.496429 551.039795,492.094299
	C551.849243,491.531769 552.381531,491.157410 552.797607,490.233398
	C552.976929,489.963867 552.977905,490.002563 552.960327,489.990540
	C556.841064,490.338348 558.761108,487.142212 561.172302,485.107056
	C564.955139,481.914215 568.348267,478.259613 572.014038,474.488647
	C575.731750,473.169159 575.116211,468.942719 577.800293,466.760590
	C578.534424,472.187073 575.102234,476.603119 572.927002,481.754333
z"
        />
        <path
          fill="#929EAC"
          opacity="1.000000"
          stroke="none"
          d="
M563.924072,496.348480
	C564.923828,495.472595 565.621399,494.763763 566.615479,494.017700
	C567.281006,494.487427 567.650024,494.994354 568.069458,495.827576
	C568.119751,496.153870 568.049255,496.108246 568.067871,496.070618
	C567.245239,496.699493 567.141357,497.837616 566.253723,498.837341
	C566.061157,499.077087 566.016235,499.011139 566.054199,499.023438
	C565.442993,499.625549 565.049988,500.557678 563.655273,500.554657
	C563.121155,500.381287 562.926819,500.258209 562.565186,499.831726
	C562.071899,498.225891 562.663269,497.296143 563.924072,496.348480
z"
        />
        <path
          fill="#6D5A5B"
          opacity="1.000000"
          stroke="none"
          d="
M578.345703,466.451813
	C578.466492,465.814545 578.929016,465.083344 579.674805,464.185120
	C579.856079,464.972656 579.657715,465.874878 578.345703,466.451813
z"
        />
        <path
          fill="#9B8881"
          opacity="1.000000"
          stroke="none"
          d="
M580.001465,455.998962
	C580.002930,455.997955 580.373291,456.215179 580.373291,456.215179
	C580.373291,456.215179 580.124329,456.191223 580.061218,456.097107
	C579.998108,456.002991 580.000000,456.000000 580.001465,455.998962
z"
        />
        <path
          fill="#4C393B"
          opacity="1.000000"
          stroke="none"
          d="
M613.055237,480.577515
	C606.325562,480.403839 601.745056,484.140533 597.676086,489.082062
	C596.388489,490.645782 594.344482,490.154144 592.283325,489.307404
	C595.321716,486.501373 598.513489,484.098663 600.960571,480.319550
	C602.704346,477.626495 607.455566,478.026550 611.483582,478.001099
	C612.241638,478.761810 612.576782,479.526733 613.055237,480.577515
z"
        />
        <path
          fill="#4C393B"
          opacity="1.000000"
          stroke="none"
          d="
M596.673218,480.161682
	C596.827271,480.405731 596.790344,480.615051 596.753357,480.824341
	C596.487793,480.754791 596.222290,480.685211 595.956726,480.615662
	C596.131897,480.452759 596.307007,480.289825 596.673218,480.161682
z"
        />
        <path
          fill="#2B2F37"
          opacity="1.000000"
          stroke="none"
          d="
M664.717468,524.816284
	C663.145020,526.399536 660.878418,526.422363 658.401733,526.983826
	C656.893677,526.756958 655.763000,526.521240 654.312500,526.141541
	C653.461182,525.255310 653.547729,524.433289 653.832275,523.331055
	C655.631897,522.648743 657.600037,522.321777 655.316772,520.171387
	C654.051392,518.979736 652.763855,517.651733 650.361572,517.979004
	C649.563782,517.263733 649.972656,516.817810 650.731689,516.208313
	C652.095886,515.806885 653.297546,515.771667 654.071045,514.241943
	C648.838074,513.526794 644.271851,511.432709 640.072510,508.477234
	C638.859619,507.623657 637.525574,508.129822 635.861084,508.502350
	C635.365356,508.437469 635.246460,508.237762 635.041260,507.701782
	C634.830688,503.696655 630.893799,504.215576 628.961914,502.020386
	C625.889709,499.729401 623.807251,497.169342 623.165649,493.254364
	C622.967834,492.559357 622.905029,492.198425 622.844788,491.562683
	C622.743042,491.059174 622.638794,490.830475 622.667603,490.248779
	C624.710022,488.858063 626.431641,489.874908 627.905457,490.731689
	C631.598694,492.878693 635.659912,492.910950 640.035950,493.186340
	C640.866516,493.005341 641.349121,492.977844 642.189819,493.049011
	C643.002991,493.214661 643.448730,493.367737 644.188904,493.717102
	C645.092957,494.206421 645.686096,494.545532 646.690735,494.805023
	C647.236328,494.995514 647.452454,495.081146 647.979309,495.327576
	C654.284058,498.214752 660.559265,500.338928 666.372620,504.150970
	C667.309631,507.764038 671.792725,510.773499 667.371704,514.503479
	C668.904846,517.821228 669.169189,521.151367 667.221924,524.739380
	C666.537476,524.979309 666.100708,524.980591 665.336365,524.983337
	C663.251038,523.857483 661.200867,524.993042 660.014832,524.524048
	C661.059998,524.646362 662.775513,524.352417 664.717468,524.816284
z"
        />
        <path
          fill="#8396A6"
          opacity="1.000000"
          stroke="none"
          d="
M622.837830,493.172943
	C624.769043,495.453308 626.507568,497.986298 628.501831,500.718750
	C631.580688,502.584045 633.485413,505.158447 635.565796,508.001038
	C635.685791,508.324280 635.528381,508.440186 635.506287,508.538635
	C636.467407,510.209564 637.450623,511.781982 638.544495,513.696777
	C637.462036,515.150330 635.987183,515.033997 635.012146,514.115356
	C630.586365,509.945679 625.319397,506.447571 623.305420,499.843353
	C623.359192,497.467041 621.729370,496.300690 620.932495,494.371704
	C621.469788,493.834167 622.057434,493.629883 622.837830,493.172943
z"
        />
        <path
          fill="#3B3036"
          opacity="1.000000"
          stroke="none"
          d="
M623.329346,489.928223
	C622.979980,490.297577 622.963257,490.496429 622.896973,490.991577
	C620.329224,488.474823 618.822571,485.067139 617.169434,481.374573
	C619.901733,480.476624 621.704468,482.019043 623.400818,483.894257
	C624.931946,485.586945 626.502686,487.286560 629.494141,487.046997
	C632.880615,487.345886 635.189514,488.586121 635.931763,492.003296
	C631.085632,494.394440 627.790894,490.116943 623.329346,489.928223
z"
        />
        <path
          fill="#847876"
          opacity="1.000000"
          stroke="none"
          d="
M636.507446,492.478638
	C634.143188,490.508087 632.194397,489.062378 630.056396,487.338928
	C628.195251,483.819519 624.558411,481.918457 623.690796,477.745483
	C623.703918,477.181702 623.772400,476.962585 624.173096,476.645477
	C625.223022,476.564911 625.861633,476.823792 626.749878,477.388367
	C630.835022,483.325226 637.383057,486.358124 641.233215,492.370697
	C640.455994,493.937561 639.332275,493.785767 637.852844,493.418518
	C637.325928,493.212738 637.124390,493.108063 636.507446,492.478638
z"
        />
        <path
          fill="#847876"
          opacity="1.000000"
          stroke="none"
          d="
M623.795715,477.247864
	C620.567322,474.416382 619.107544,470.659851 618.561279,466.234558
	C618.896729,465.795807 619.089233,465.655914 619.631836,465.445557
	C622.572754,465.762604 622.813416,468.265869 624.235474,470.150024
	C624.790710,470.963562 624.941284,471.573914 624.977661,472.505341
	C624.904175,473.156616 624.832153,473.515961 624.726257,474.126282
	C624.716431,474.549011 624.726196,474.717896 624.754395,475.140930
	C624.769531,475.738129 624.715881,476.072723 624.442261,476.595062
	C624.280029,476.792542 623.956055,477.094940 623.795715,477.247864
z"
        />
        <path
          fill="#9B8881"
          opacity="1.000000"
          stroke="none"
          d="
M620.455200,465.589722
	C619.976929,466.011902 619.721985,466.014404 619.085571,465.976318
	C618.436584,465.168823 618.168945,464.401886 617.936157,463.327820
	C618.485779,462.811951 619.000549,462.603271 619.790710,462.421600
	C620.066162,462.448608 620.039368,462.466644 620.023315,462.472534
	C620.528748,463.301331 620.878296,464.167206 620.455200,465.589722
z"
        />
        <path
          fill="#1F3B45"
          opacity="1.000000"
          stroke="none"
          d="
M623.071045,499.780396
	C628.216858,504.281921 631.332397,510.898529 638.252502,514.091064
	C640.969910,514.039062 643.284729,514.039062 645.816589,514.039062
	C645.492065,515.778381 640.527100,513.731873 643.094727,517.524841
	C643.172119,517.639160 640.726746,518.057617 639.494263,517.636902
	C637.528442,516.965942 635.885193,516.846558 635.072937,519.620789
	C632.070740,518.669556 629.575928,515.939331 625.426270,516.999390
	C619.880615,518.012329 614.865051,516.342407 609.777283,516.367981
	C608.670593,516.373535 607.398560,515.990540 607.067627,514.637451
	C606.692139,513.102356 608.733948,514.121765 608.900696,513.122375
	C607.551819,513.664612 606.220581,514.314941 604.853149,514.753723
	C602.952881,515.363342 600.465149,515.803650 599.794678,513.369263
	C599.026184,510.578766 602.187195,511.444580 603.736206,510.241638
	C604.017700,510.002441 604.506409,510.001556 604.750732,510.002838
	C605.860168,508.789398 607.449097,509.235809 608.752319,508.251465
	C609.166138,507.206512 609.425964,506.287201 610.356750,506.205597
	C615.318604,505.770691 618.613403,501.982208 623.071045,499.780396
z"
        />
        <path
          fill="#2C4653"
          opacity="1.000000"
          stroke="none"
          d="
M603.595825,509.998779
	C603.987244,512.165833 601.505554,511.698395 600.019714,513.387207
	C604.605835,514.196777 608.411377,512.167603 612.471802,512.390930
	C611.672058,515.020996 609.182922,513.101807 608.101501,514.868896
	C613.703796,514.791443 619.037598,515.779907 624.704224,516.730713
	C625.002441,517.000000 625.000000,517.000000 624.999023,517.002441
	C619.548096,517.759949 614.324158,520.332764 608.596436,519.044983
	C608.333984,518.986023 608.804932,519.839294 608.786926,520.646484
	C608.707153,521.246277 608.630371,521.486633 608.304871,522.028687
	C603.585266,523.410156 599.051819,525.730713 594.702209,521.266602
	C590.558777,517.132996 588.963562,512.321106 588.412231,506.642761
	C593.641968,506.319824 597.785645,510.597839 603.595825,509.998779
z"
        />
        <path
          fill="#2C4653"
          opacity="1.000000"
          stroke="none"
          d="
M608.623657,508.026489
	C608.732239,510.052826 607.199585,509.966705 605.365479,509.999542
	C605.259521,507.992615 606.762146,508.016724 608.623657,508.026489
z"
        />
        <path
          fill="#2C4653"
          opacity="1.000000"
          stroke="none"
          d="
M588.583618,504.832214
	C588.358215,504.840210 588.268066,504.634949 588.282349,504.525604
	C588.520264,504.485931 589.247131,503.923584 588.583618,504.832214
z"
        />
        <path
          fill="#574649"
          opacity="1.000000"
          stroke="none"
          d="
M576.773865,492.114502
	C576.859314,492.293732 576.679871,492.531799 576.228638,492.817871
	C576.152527,492.641296 576.336609,492.410431 576.773865,492.114502
z"
        />
        <path
          fill="#394148"
          opacity="1.000000"
          stroke="none"
          d="
M584.530273,505.050079
	C585.267151,507.441132 585.680542,509.809509 586.115112,512.578003
	C580.766235,516.674988 574.754089,516.065918 569.001404,514.817749
	C565.720642,514.105957 561.358887,514.531921 559.585693,509.789185
	C559.467590,506.685486 560.810547,504.879578 563.627319,503.895691
	C568.329163,504.198212 572.337341,502.309021 576.547363,502.105652
	C578.648254,502.004150 582.160400,500.209961 582.347229,504.823029
	C582.350952,504.916107 583.560120,504.960388 584.530273,505.050079
z"
        />
        <path
          fill="#929EAC"
          opacity="1.000000"
          stroke="none"
          d="
M564.043701,503.748077
	C562.730286,505.443237 561.445312,506.898865 560.046265,508.661743
	C558.768188,511.487915 556.948425,512.972656 553.688110,512.552917
	C552.730713,511.828369 552.694763,511.129120 553.012573,510.048370
	C554.055481,508.149963 555.708191,507.168213 556.591614,505.185059
	C556.781250,504.675812 556.867920,504.477264 557.104980,503.994080
	C557.563232,503.154419 557.919128,502.624969 558.279358,501.733826
	C558.741455,500.859253 559.210693,500.417725 560.075073,499.932434
	C561.009949,499.579437 561.658264,499.513245 562.616455,499.784363
	C562.911438,499.929108 563.073730,500.075287 563.154419,500.148926
	C563.830872,500.833435 564.173645,501.543213 564.108032,502.723572
	C564.122559,503.028748 564.072144,503.508545 564.043701,503.748077
z"
        />
        <path
          fill="#C1ACB2"
          opacity="1.000000"
          stroke="none"
          d="
M564.395020,502.829895
	C563.241577,502.553131 563.058228,501.759552 563.182251,500.536255
	C563.990479,499.895844 564.745850,499.569092 565.796692,499.139038
	C566.723389,500.672485 565.414001,501.540222 564.395020,502.829895
z"
        />
        <path
          fill="#C1ACB2"
          opacity="1.000000"
          stroke="none"
          d="
M566.425049,499.100342
	C566.047668,497.899384 566.492859,496.954193 567.782104,496.157715
	C568.537964,497.434357 567.816650,498.343292 566.425049,499.100342
z"
        />
        <path
          fill="#2E515F"
          opacity="1.000000"
          stroke="none"
          d="
M552.336365,512.034119
	C555.997986,512.962036 557.040833,509.764221 559.589600,509.033569
	C563.744690,512.707397 568.710999,513.592285 573.645813,513.882812
	C577.520081,514.110901 581.453308,513.337341 585.748352,512.993408
	C587.977722,515.755371 589.010132,519.117981 592.258545,521.266479
	C594.552063,521.944641 596.468201,522.656494 598.023438,524.457031
	C595.437744,527.377747 591.949890,525.008301 589.152771,526.384583
	C589.583435,529.501648 592.873413,528.408386 594.425171,530.094482
	C590.877380,529.324341 587.594910,531.515625 583.648743,530.778748
	C584.668335,529.810242 585.324341,529.187073 585.971252,528.572571
	C585.825256,528.378418 585.678528,528.171082 585.671448,528.175720
	C581.275818,531.071106 576.747681,525.678345 572.189575,528.698853
	C565.512390,529.600586 559.332825,528.978943 554.258423,523.911804
	C557.719116,523.911804 561.158020,523.911804 564.596924,523.911804
	C557.313660,523.443359 550.252014,522.005554 543.085815,519.607666
	C544.492432,516.016846 548.771545,515.954224 550.742126,512.627930
	C551.235535,512.324768 551.442078,512.217712 552.336365,512.034119
z"
        />
        <path
          fill="#759EB0"
          opacity="1.000000"
          stroke="none"
          d="
M598.429749,524.817505
	C596.308167,523.872253 594.598694,522.937683 592.707336,521.732910
	C592.860901,521.350891 593.196472,521.239075 594.047974,520.998169
	C598.493530,523.463135 602.679993,522.815063 607.285034,521.795593
	C608.510132,521.372192 609.270813,521.443604 609.945679,522.521851
	C609.561584,522.986023 609.168152,523.035889 608.477295,523.086670
	C607.729919,523.504272 607.237061,523.841125 606.309082,523.938110
	C603.688354,524.807434 601.278564,524.926636 598.429749,524.817505
z"
        />
        <path
          fill="#C46B41"
          opacity="1.000000"
          stroke="none"
          d="
M618.591431,239.868286
	C618.938477,242.661545 619.716980,245.266281 617.928711,247.369934
	C616.044250,249.586838 613.359070,249.657654 611.166077,248.264130
	C607.889221,246.181854 608.878845,242.824387 609.505920,239.741333
	C609.535706,239.594864 609.678223,239.463226 609.787598,239.342224
	C611.306885,237.661850 612.672485,234.816833 614.389038,234.585861
	C616.837402,234.256424 616.300781,238.367966 618.591431,239.868286
z"
        />
        <path
          fill="#847876"
          opacity="1.000000"
          stroke="none"
          d="
M658.533630,464.775879
	C658.118652,464.418518 657.929871,464.189362 657.544189,463.837341
	C657.249146,463.352570 657.120544,463.015717 656.914062,462.419983
	C656.836121,462.161102 657.129150,461.863892 657.275024,461.714691
	C658.085205,461.194336 658.760193,461.182007 659.732910,461.711548
	C660.335876,462.389801 660.651062,462.861816 661.063110,463.603821
	C661.401611,464.506805 661.643127,465.139771 661.942383,466.116455
	C661.594971,466.293091 661.189880,466.126038 660.467407,465.879669
	C659.696838,465.493256 659.243530,465.186157 658.533630,464.775879
z"
        />
        <path
          fill="#4C393B"
          opacity="1.000000"
          stroke="none"
          d="
M659.967834,461.583038
	C659.357971,461.876251 658.695251,461.834686 657.726685,461.679291
	C657.060913,460.879822 656.700989,460.194183 656.291504,459.217957
	C657.929382,458.968201 659.402588,459.347900 659.967834,461.583038
z"
        />
        <path
          fill="#4C393B"
          opacity="1.000000"
          stroke="none"
          d="
M669.089478,456.140289
	C669.159851,455.997223 669.326965,455.999115 669.744629,456.002075
	C669.725586,456.097198 669.455872,456.191223 669.089478,456.140289
z"
        />
        <path
          fill="#412C2E"
          opacity="1.000000"
          stroke="none"
          d="
M681.846924,479.885071
	C681.431213,480.761749 680.853027,480.887451 680.077576,480.019470
	C680.501831,479.260132 681.087280,479.083008 681.846924,479.885071
z"
        />
        <path
          fill="#562118"
          opacity="1.000000"
          stroke="none"
          d="
M688.175537,491.160400
	C695.220276,493.678955 702.146667,495.121948 709.643616,495.833740
	C710.782227,495.857483 711.501831,495.953217 712.582031,496.094727
	C714.246948,497.939423 720.510681,495.516144 716.978333,501.424805
	C715.841003,502.119354 714.917664,502.184174 713.628906,501.961060
	C711.926208,501.191132 710.599609,500.279236 708.620178,501.166565
	C705.612183,501.658691 703.157227,500.233521 700.190186,500.260193
	C698.812927,499.831146 697.851562,499.208282 696.353333,499.279755
	C694.308350,499.287567 692.708191,499.014526 691.298340,497.332214
	C686.861145,495.895264 682.677368,494.707947 678.259155,493.236633
	C678.742065,491.755646 681.539978,492.999146 681.076660,490.055725
	C683.342224,490.211487 685.575073,490.690338 688.175537,491.160400
z"
        />
        <path
          fill="#532C24"
          opacity="1.000000"
          stroke="none"
          d="
M687.118408,501.638245
	C681.984314,500.804138 677.259521,499.980438 672.349243,498.310669
	C672.019470,497.052643 672.013916,495.973114 672.002686,494.491943
	C678.424744,494.948822 683.919617,498.774170 690.664124,499.837219
	C694.198364,498.618073 696.968994,499.690338 699.918823,501.601257
	C700.247925,502.532135 700.065125,503.044220 699.279785,503.705902
	C694.900635,504.388885 691.037903,503.971954 687.118408,501.638245
z"
        />
        <path
          fill="#A67A5E"
          opacity="1.000000"
          stroke="none"
          d="
M700.229126,502.422974
	C697.212891,501.397034 694.494873,500.724518 691.411499,499.943176
	C691.034363,499.146332 691.022644,498.458313 691.027954,497.426453
	C692.776428,497.431244 694.507996,497.779938 696.622925,498.162231
	C697.570923,498.093536 698.101013,498.171692 698.819092,498.642090
	C702.087402,499.207062 705.120728,499.626404 708.567322,500.077026
	C710.169312,500.035187 711.369385,499.857544 712.771729,500.698547
	C714.095337,500.949615 715.129028,500.989868 716.549744,501.000854
	C718.655823,499.848663 720.289062,500.033661 720.906677,502.018616
	C721.493347,503.903870 719.624878,504.629822 718.300964,504.955933
	C712.133728,506.474792 706.218018,505.418976 700.229126,502.422974
z"
        />
        <path
          fill="#483A3F"
          opacity="1.000000"
          stroke="none"
          d="
M623.893677,474.329529
	C624.070007,473.363007 623.989807,472.557007 623.830444,471.352905
	C624.177429,470.586853 624.664307,470.468567 625.514465,470.853821
	C626.345520,472.298798 626.874023,473.489929 627.676575,474.859802
	C632.241211,480.564392 636.463074,486.162811 643.440063,489.277283
	C644.070190,489.367157 644.286621,489.462860 644.761230,489.801453
	C645.758789,491.570953 647.556702,492.048798 648.415283,493.420441
	C649.186890,494.653076 650.296631,496.143799 647.250427,496.204224
	C646.962952,496.000183 647.008972,495.979614 647.017273,496.003448
	C646.238220,495.172150 645.076111,495.705750 643.823242,495.125916
	C643.468018,494.992676 643.112915,494.734619 643.062866,494.635681
	C642.786560,494.045135 642.588257,493.523987 641.472229,493.246826
	C636.430054,487.873749 629.854919,484.773682 626.133789,478.295776
	C625.313599,477.230743 625.127686,476.192352 624.329224,475.155914
	C624.165100,474.904846 624.114136,474.911530 624.115112,474.885834
	C623.956482,474.736786 623.796814,474.613434 623.893677,474.329529
z"
        />
        <path
          fill="#6D5A5B"
          opacity="1.000000"
          stroke="none"
          d="
M625.742798,470.768280
	C625.360291,471.071991 624.903564,471.036285 624.098999,470.977661
	C621.954163,469.981140 621.751892,467.908478 620.441223,466.232422
	C620.142517,465.129425 620.053284,464.249390 619.985596,462.923889
	C623.174133,464.217255 623.941650,467.664612 625.742798,470.768280
z"
        />
        <path
          fill="#3B3036"
          opacity="1.000000"
          stroke="none"
          d="
M644.168823,489.821045
	C643.992188,489.463013 643.898193,489.314850 643.875977,489.293152
	C642.594177,487.805908 641.334656,486.340363 639.955872,484.571106
	C640.077637,483.318481 640.318665,482.369568 640.827759,481.168762
	C645.182739,482.364197 646.919617,486.491455 650.256958,488.896149
	C657.222900,493.915344 664.086731,498.970734 672.878052,501.351807
	C673.325317,501.766296 673.465332,502.000122 673.490173,502.625488
	C671.768250,504.710938 669.791687,504.615082 667.876892,503.898590
	C659.255737,500.672760 650.577698,497.513336 644.168823,489.821045
z"
        />
        <path
          fill="#532C24"
          opacity="1.000000"
          stroke="none"
          d="
M672.659180,503.674805
	C672.889221,503.071564 672.947449,502.764801 673.095459,501.995270
	C677.441711,502.452820 681.706299,503.307007 686.549561,503.152313
	C688.098145,503.201874 688.802551,503.823883 689.422974,505.069580
	C689.785522,508.692657 689.303284,511.344177 685.172424,511.692688
	C683.246216,511.855194 681.572876,513.880676 683.134583,514.976501
	C686.327332,517.216736 684.026611,518.382446 682.963379,519.983398
	C681.722412,521.851868 680.474121,524.159119 682.457642,525.816162
	C687.023987,529.630859 684.942932,533.669495 683.205383,537.993164
	C682.440308,538.432617 681.975525,538.688660 681.270569,539.058289
	C679.299622,538.455933 678.783264,536.566223 677.211914,535.245422
	C675.786438,534.480774 672.942383,536.417297 674.085571,532.420471
	C674.831360,529.812927 671.054138,532.047729 670.142029,530.417969
	C670.829529,528.745544 672.894897,529.604431 673.900574,528.428406
	C672.645386,527.430237 670.877625,528.576538 669.650879,527.200073
	C669.983887,525.587219 671.417542,526.240356 672.383667,525.973938
	C672.543152,525.929993 672.701111,525.880249 672.859741,525.833191
	C672.744446,525.580078 672.629089,525.326965 672.263916,525.023987
	C669.393799,521.928284 669.127258,517.449097 670.881409,513.895691
	C672.179199,511.266876 671.752441,508.165283 672.421631,504.993103
	C672.431641,504.424805 672.459473,504.198029 672.659180,503.674805
z"
        />
        <path
          fill="#855144"
          opacity="1.000000"
          stroke="none"
          d="
M689.333618,505.014893
	C688.439941,504.475952 687.861511,504.051910 687.141968,503.397400
	C686.963501,502.773010 686.926086,502.379089 686.820862,501.700958
	C690.536621,501.954956 694.320312,502.493164 698.514587,503.052216
	C699.319153,503.382172 699.521118,503.791718 699.480957,504.638123
	C698.609436,506.350739 697.064758,506.571960 695.528931,507.428101
	C694.953552,507.547607 694.718201,507.560730 694.139404,507.501587
	C692.367188,506.746490 691.025635,505.906372 689.333618,505.014893
z"
        />
        <path
          fill="#562118"
          opacity="1.000000"
          stroke="none"
          d="
M776.093872,517.463806
	C775.205566,517.866699 773.978516,518.302734 773.634583,517.137695
	C773.297119,515.994507 774.545288,516.149597 775.593384,515.955505
	C775.971008,516.354370 776.012939,516.758850 776.093872,517.463806
z"
        />
        <path
          fill="#BB5B23"
          opacity="1.000000"
          stroke="none"
          d="
M730.801697,514.072998
	C731.103943,514.568481 731.291199,514.747864 731.711487,514.947021
	C735.357300,518.700989 740.229675,516.979065 744.518921,518.057129
	C744.859009,517.715332 744.999268,517.575806 745.398621,517.274780
	C747.404602,516.232544 749.501404,517.002563 751.478638,515.755615
	C751.943848,515.480408 752.139526,515.386414 752.648193,515.202637
	C753.848328,515.565735 754.735474,516.018677 755.816406,516.698364
	C755.905457,517.137085 755.800598,517.348999 755.379517,517.721436
	C754.521240,518.442688 752.871704,518.976257 754.535400,519.759888
	C755.368408,520.152222 756.732361,519.292664 757.873657,519.186340
	C759.320801,519.051514 759.908447,520.015503 760.125854,521.304749
	C760.463928,523.309082 758.930725,522.916992 757.896179,522.967468
	C756.239014,523.048462 754.573975,522.967712 752.478821,522.958191
	C751.851562,523.082703 751.657776,523.204285 751.183350,523.132812
	C749.933228,522.275635 748.739136,522.423157 748.058655,522.077698
	C748.435669,522.092346 749.126770,522.398621 750.191284,522.567383
	C750.883850,522.901245 751.232788,523.187805 751.796997,523.742188
	C754.446411,524.708069 756.868713,525.420471 759.674561,526.180664
	C760.759155,526.388489 761.181030,526.833557 761.228149,527.809814
	C758.823425,529.608215 756.275330,528.729919 753.434692,528.964783
	C752.636902,528.967834 752.266418,528.969910 751.615723,528.937866
	C751.150818,528.875366 750.966003,528.836426 750.510132,528.712158
	C749.698792,528.435547 749.159607,528.240906 748.335999,527.965454
	C747.872498,527.795166 747.688904,527.718994 747.238770,527.505920
	C746.157166,526.738281 745.219543,526.414185 743.913208,526.181213
	C743.420715,526.023193 743.226868,525.949219 742.753052,525.738525
	C741.601685,525.062744 740.798889,524.403198 739.514893,523.963867
	C738.016052,523.327820 736.727966,523.474304 735.133545,523.392273
	C730.977417,523.634583 727.490051,521.701599 723.375671,521.536255
	C722.871399,521.229126 722.700989,521.057251 722.391907,520.547729
	C721.973694,516.772339 724.619141,517.716003 726.453369,517.429626
	C725.707947,517.499268 724.956665,517.403137 724.249939,517.531921
	C722.030945,517.936401 719.916931,518.121826 718.738586,515.631348
	C718.185486,514.462341 717.391357,513.236755 718.768005,512.065247
	C720.572815,510.529327 722.674744,510.383148 724.834412,510.946045
	C726.099976,511.275848 727.261597,511.948730 728.704407,512.772583
	C728.957397,513.035706 728.979248,513.028931 728.974670,513.025024
	C729.439575,513.465576 730.108948,513.487549 730.801697,514.072998
z"
        />
        <path
          fill="#C46B41"
          opacity="1.000000"
          stroke="none"
          d="
M752.882446,514.862915
	C752.961182,515.112854 752.982300,515.566895 752.991211,515.793945
	C750.698547,517.370544 748.065308,517.127808 745.261353,517.789795
	C744.948425,518.011963 745.003418,518.013062 744.999023,517.985901
	C742.107971,518.810791 739.408447,518.421326 736.999817,516.311157
	C741.819153,513.809204 747.054688,516.210388 752.154968,514.185242
	C752.617737,514.317383 752.710754,514.465210 752.882446,514.862915
z"
        />
        <path
          fill="#964F38"
          opacity="1.000000"
          stroke="none"
          d="
M761.506958,528.052917
	C760.821045,527.635559 760.556946,527.166565 760.175415,526.463013
	C759.234802,522.228333 762.203064,524.097595 763.365540,524.411255
	C765.580261,525.008728 767.613403,526.281860 770.437256,526.013184
	C768.356018,528.870972 764.926392,527.472839 761.506958,528.052917
z"
        />
        <path
          fill="#9A4A2B"
          opacity="1.000000"
          stroke="none"
          d="
M758.001221,513.998779
	C760.692017,514.658325 764.255859,512.406677 766.025513,516.770996
	C764.752930,518.235352 763.289673,518.060364 761.420349,517.297913
	C759.585266,516.845947 758.435181,516.263123 758.041016,514.340576
	C757.997559,514.002441 758.000000,514.000000 758.001221,513.998779
z"
        />
        <path
          fill="#631D07"
          opacity="1.000000"
          stroke="none"
          d="
M761.105713,517.402222
	C762.446411,517.070740 763.848083,517.100464 765.675415,517.144470
	C766.694946,517.581238 767.760132,518.519348 766.772583,518.727905
	C764.947388,519.113403 762.687500,519.955383 761.105713,517.402222
z"
        />
        <path
          fill="#9A4A2B"
          opacity="1.000000"
          stroke="none"
          d="
M765.454590,509.089233
	C766.187744,510.314240 765.158142,509.820618 764.770264,509.895752
	C764.543213,509.939758 764.251648,509.651062 763.989136,509.512024
	C764.400391,509.357147 764.811646,509.202271 765.454590,509.089233
z"
        />
        <path
          fill="#C46B41"
          opacity="1.000000"
          stroke="none"
          d="
M755.323608,517.903076
	C755.179993,517.590515 755.365540,517.352966 755.815063,517.047119
	C756.336365,517.178955 756.662537,517.432678 756.988647,517.686462
	C756.520447,517.765747 756.052185,517.844971 755.323608,517.903076
z"
        />
        <path
          fill="#7D2C11"
          opacity="1.000000"
          stroke="none"
          d="
M752.030518,523.737000
	C751.693298,523.901367 751.424072,523.717224 751.106567,523.224243
	C751.170898,522.989929 751.345215,522.974365 751.782471,522.959900
	C752.042603,523.133484 752.039795,523.305969 752.030518,523.737000
z"
        />
        <path
          fill="#CC6824"
          opacity="1.000000"
          stroke="none"
          d="
M733.309448,560.213135
	C731.657288,560.110474 730.330688,559.982361 729.064514,558.774170
	C727.352783,555.953674 724.719055,557.255005 722.703613,556.985291
	C714.893127,555.940186 710.009644,549.884949 711.165344,542.161560
	C711.532104,539.710632 713.039307,538.367981 715.447693,538.064819
	C717.074585,537.860046 718.772156,538.202454 720.636230,537.136841
	C729.023132,533.784790 737.608521,533.773621 746.448059,532.606934
	C747.086975,533.534119 747.300598,534.661255 748.485046,534.787292
	C754.717224,535.450317 752.191345,538.490173 751.386780,542.373169
	C749.803467,550.014587 744.075806,554.547241 740.045715,560.736389
	C737.509827,563.315063 735.879761,559.717712 733.309448,560.213135
z"
        />
        <path
          fill="#632B1D"
          opacity="1.000000"
          stroke="none"
          d="
M688.830688,505.173798
	C691.234497,503.276276 692.696655,504.617676 694.188599,506.525818
	C694.505981,507.287933 694.676392,507.852722 695.085449,508.048615
	C700.069336,510.435028 700.074951,510.423279 696.072815,514.801636
	C695.735413,515.529175 695.502930,515.924133 695.031982,516.575562
	C691.309204,520.006104 695.387329,521.691833 696.368469,523.820190
	C697.056396,525.312378 699.481812,525.551758 700.060791,527.755859
	C698.406616,529.863403 696.194824,529.984802 694.089172,530.377380
	C693.433838,530.499634 694.048035,530.083252 693.643066,530.473145
	C693.114868,530.981750 694.074219,530.705627 694.477295,531.150269
	C694.956970,531.460022 695.132629,531.610779 695.496338,532.055420
	C695.962097,533.916382 697.194397,535.188904 697.218201,536.824890
	C695.791809,537.937439 693.860535,537.174194 692.387695,538.669739
	C691.831909,539.032654 691.566406,539.215454 691.198059,539.645508
	C688.411438,539.607849 685.560486,541.076477 683.024292,538.537903
	C684.191345,535.930359 680.484741,532.744629 684.351868,530.826538
	C684.935913,530.536865 683.887634,529.404663 682.982178,528.749634
	C678.852051,525.761658 678.657593,522.748779 681.955811,519.001343
	C682.645142,518.218140 684.229919,517.660217 681.850647,516.196899
	C680.202454,515.183228 680.033142,511.854065 682.485535,510.299164
	C684.918091,508.756836 687.897400,506.758942 688.830688,505.173798
z"
        />
        <path
          fill="#BB5B23"
          opacity="1.000000"
          stroke="none"
          d="
M720.509216,536.898743
	C720.113403,539.046570 718.210083,538.865295 716.368347,539.041565
	C712.966309,539.367310 711.685303,541.078308 712.958252,544.494263
	C713.530273,546.029297 713.693726,547.714844 714.065247,549.326843
	C714.904968,552.970764 716.750000,555.224609 720.942322,555.063965
	C723.802307,554.954346 727.025696,554.449768 728.802185,558.181763
	C723.033508,560.246643 717.110962,560.802795 710.764648,561.375732
	C710.174072,561.385559 709.954224,561.469177 709.853516,561.393738
	C704.729370,557.555786 702.258301,545.426392 705.702576,539.317932
	C710.620972,537.858643 715.224304,536.570129 720.509216,536.898743
z"
        />
        <path
          fill="#813920"
          opacity="1.000000"
          stroke="none"
          d="
M697.373291,563.410278
	C694.680542,564.069458 692.287109,564.436340 689.492554,564.781311
	C685.521729,559.048035 686.218689,545.396118 690.650757,541.343262
	C691.029297,541.054749 691.014771,541.032959 691.018127,541.020386
	C692.800476,540.763794 694.579407,540.519775 696.740906,540.302612
	C698.013123,541.544861 697.696228,542.869324 697.398560,544.151733
	C696.513916,547.963257 696.763123,551.682495 697.919556,555.418579
	C698.707458,557.964111 699.176392,560.599243 697.373291,563.410278
z"
        />
        <path
          fill="#AA4B20"
          opacity="1.000000"
          stroke="none"
          d="
M705.611450,538.981445
	C702.520203,547.058289 708.922302,553.427185 710.040161,561.066650
	C707.585449,562.363342 704.726562,562.758789 701.420654,562.860718
	C700.656494,562.489990 700.137329,562.238037 700.044189,561.737366
	C698.755554,554.811279 698.170044,547.858887 699.695496,540.505127
	C701.755188,539.763916 703.491760,539.368774 705.611450,538.981445
z"
        />
        <path
          fill="#C46B41"
          opacity="1.000000"
          stroke="none"
          d="
M752.016907,529.683716
	C743.432861,530.878357 735.125366,531.876282 726.403931,532.885010
	C725.820496,532.843750 725.651062,532.791748 725.239441,532.623413
	C724.681763,532.314758 724.377808,532.105408 723.852539,531.699951
	C723.483582,531.369507 723.344849,531.222290 723.039551,530.812622
	C722.704651,530.149841 722.636719,529.731323 722.778442,528.989990
	C723.080933,528.531311 723.250977,528.381348 723.725220,528.073242
	C728.414917,527.492554 732.835388,526.720520 736.525330,528.828613
	C735.210510,527.682251 732.971069,527.743286 730.580933,527.082031
	C730.039246,526.781555 729.835144,526.633362 729.389038,526.187195
	C728.447876,521.473267 731.700562,523.191772 734.080933,522.974976
	C734.973755,522.999695 735.481750,523.009949 736.364136,523.044556
	C737.477966,523.169434 738.179871,523.429810 739.262695,523.677673
	C740.563782,524.016663 741.543030,524.144043 742.721191,524.810181
	C743.010559,525.035034 743.007568,524.989685 742.985962,524.996826
	C744.358276,525.841553 746.059998,525.807190 747.739136,526.812988
	C748.006897,527.030579 748.005371,526.992676 747.987915,527.000122
	C748.685364,527.721130 749.647949,527.400146 750.741516,527.819580
	C750.999329,528.030334 750.998718,527.995300 750.985229,528.006531
	C751.545532,528.387634 752.078857,528.793884 752.016907,529.683716
z"
        />
        <path
          fill="#9A4A2B"
          opacity="1.000000"
          stroke="none"
          d="
M699.654907,540.136902
	C700.835144,547.389343 701.200012,554.640320 701.016296,562.368164
	C700.719055,563.030029 700.419250,563.240356 699.922729,563.642822
	C699.062622,563.820129 698.399292,563.805115 697.404968,563.746338
	C696.961853,560.241760 696.732849,556.912231 695.543274,553.490173
	C694.119751,549.395142 694.567566,544.969177 697.013794,540.671753
	C697.846008,540.257874 698.568665,540.186340 699.654907,540.136902
z"
        />
        <path
          fill="#BB5B23"
          opacity="1.000000"
          stroke="none"
          d="
M725.825562,531.911377
	C726.051086,532.111511 726.029480,532.504883 726.009705,532.700317
	C722.099915,534.483093 717.991211,534.777466 713.428955,534.925781
	C712.582703,534.929932 712.170166,534.917603 711.087891,534.258545
	C710.379944,533.440369 710.342712,533.231689 710.314209,532.704712
	C710.541321,531.130188 710.529114,529.801758 711.713623,528.554077
	C713.122986,527.595459 714.507996,527.864136 715.915894,527.256958
	C716.238403,526.670410 716.405762,526.308472 716.805237,525.696533
	C717.216858,525.309998 717.411926,525.180603 717.942749,524.915833
	C721.981689,525.035828 725.870422,522.688049 729.711792,525.779846
	C730.037476,525.987183 729.995605,525.997559 730.007690,525.979736
	C730.918396,527.619995 730.146851,528.337036 728.537842,528.461731
	C726.947937,528.584900 725.346985,528.431946 723.391235,528.791138
	C723.028809,529.023254 723.023438,529.033142 723.031006,529.038696
	C723.038574,529.044312 722.728821,529.390686 722.563538,529.508789
	C722.398254,529.626831 722.112183,529.801819 722.230713,529.950928
	C722.774536,530.280701 723.193176,530.475586 723.823975,530.832825
	C724.040710,530.985046 723.998718,531.007751 723.996948,530.983887
	C724.418396,531.449280 725.051636,531.489746 725.825562,531.911377
z"
        />
        <path
          fill="#9A4A2B"
          opacity="1.000000"
          stroke="none"
          d="
M711.696289,534.202271
	C712.263245,534.104553 712.528809,534.265686 712.871094,534.705322
	C709.229614,537.114441 705.012634,536.841736 700.449341,537.124390
	C697.386414,536.618713 697.146912,535.114807 698.602417,532.742798
	C700.778564,531.211243 702.915466,531.645020 705.364136,531.614258
	C705.995239,531.669617 706.245483,531.700989 706.861755,531.817017
	C708.348328,532.378296 709.556458,532.676575 710.773193,533.751099
	C711.043396,533.993958 710.994507,534.011230 710.992188,533.985413
	C711.141174,534.100098 711.292358,534.240601 711.696289,534.202271
z"
        />
        <path
          fill="#813920"
          opacity="1.000000"
          stroke="none"
          d="
M698.783447,532.820679
	C698.780457,534.412354 698.217712,535.712402 699.863586,536.901855
	C697.835388,538.609863 695.093262,537.593384 692.497681,538.848999
	C692.301880,538.450806 692.478760,537.604919 692.638977,537.608093
	C696.561279,537.685059 695.123657,535.345886 694.790161,533.212646
	C696.046082,532.139160 697.184021,531.876282 698.783447,532.820679
z"
        />
        <path
          fill="#7D2C11"
          opacity="1.000000"
          stroke="none"
          d="
M752.011719,529.821716
	C751.497192,529.433289 751.253967,528.986145 750.991211,528.278442
	C751.539124,528.164978 752.106506,528.312134 752.840576,528.708008
	C752.765930,529.225525 752.524475,529.494263 752.011719,529.821716
z"
        />
        <path
          fill="#964F38"
          opacity="1.000000"
          stroke="none"
          d="
M733.193848,560.522888
	C735.461670,557.295227 737.051025,561.724426 739.634644,561.070007
	C740.394409,561.910217 741.887756,562.233459 741.010010,563.807739
	C738.411133,562.952393 735.915039,561.859192 733.193848,560.522888
z"
        />
        <path
          fill="#2C4653"
          opacity="1.000000"
          stroke="none"
          d="
M734.524902,409.645691
	C735.874512,410.484741 737.191711,410.826233 737.942932,412.406677
	C735.617798,415.504944 732.761780,415.109497 729.421875,413.502625
	C731.427490,408.723267 729.339905,406.155518 725.173218,404.106628
	C725.643250,401.466644 728.134521,400.038300 728.147888,397.120453
	C728.205505,394.592651 728.340271,392.277435 731.762390,392.063477
	C732.956848,393.062683 733.816406,393.992126 735.578125,393.975769
	C736.403320,394.031647 736.912598,394.050140 737.802734,394.101135
	C740.421448,397.254578 742.081970,400.461639 739.437866,404.569336
	C738.067688,405.865692 736.954956,406.864868 735.533447,407.951965
	C734.785889,407.967590 734.246521,407.537262 733.936768,407.861328
	C733.393677,408.429504 734.007507,408.920807 734.524902,409.645691
z"
        />
        <path
          fill="#557E8B"
          opacity="1.000000"
          stroke="none"
          d="
M728.740601,413.353333
	C731.752502,413.065094 734.448059,412.974579 737.589539,412.799316
	C739.020081,412.775757 740.025940,412.735748 740.981079,412.936920
	C741.531494,413.052856 742.286743,413.457764 742.445618,413.909821
	C742.782776,414.868805 741.986938,415.504913 741.178101,415.653442
	C736.167114,416.573578 731.133728,417.364990 726.010132,417.085846
	C724.976746,417.029572 723.873596,416.638641 724.237305,415.341278
	C724.849121,413.158905 727.098999,414.342865 728.740601,413.353333
z"
        />
        <path
          fill="#496A76"
          opacity="1.000000"
          stroke="none"
          d="
M739.547729,404.875427
	C739.869263,401.426666 738.224304,398.243408 738.133850,394.514160
	C743.684265,393.489807 743.348938,398.710754 744.501831,401.422485
	C745.803650,404.484711 741.938965,404.241608 739.547729,404.875427
z"
        />
        <path
          fill="#557E8B"
          opacity="1.000000"
          stroke="none"
          d="
M727.809448,397.081940
	C729.224426,399.857178 731.104736,403.128540 725.166443,403.612244
	C723.458130,404.009552 722.939026,403.249268 723.127319,401.515594
	C723.466858,399.844421 723.868713,398.588409 725.798218,398.210083
	C726.618774,397.829498 727.136475,397.580811 727.809448,397.081940
z"
        />
        <path
          fill="#557E8B"
          opacity="1.000000"
          stroke="none"
          d="
M734.836060,409.591003
	C731.250671,410.006958 731.874390,407.613892 732.135376,405.532898
	C732.249512,404.623138 732.940002,404.064362 733.875916,404.368713
	C735.320496,404.838470 734.836914,406.220703 735.143677,407.649872
	C735.129333,408.455933 735.033936,408.871948 734.836060,409.591003
z"
        />
        <path
          fill="#64777D"
          opacity="1.000000"
          stroke="none"
          d="
M735.818604,393.672363
	C735.302795,395.079407 734.527588,395.991486 733.186340,395.636719
	C731.680298,395.238373 731.957581,393.931183 732.076660,392.432892
	C733.480530,392.079529 735.041077,391.474396 735.818604,393.672363
z"
        />
        <path
          fill="#2C4653"
          opacity="1.000000"
          stroke="none"
          d="
M725.791748,397.931763
	C725.283691,398.989197 724.466309,399.900177 723.403503,400.994202
	C722.589172,400.194031 720.968323,399.559723 722.128479,398.005676
	C723.168335,396.612640 724.323242,397.227264 725.791748,397.931763
z"
        />
        <path
          fill="#632B1D"
          opacity="1.000000"
          stroke="none"
          d="
M753.413940,495.006775
	C751.450867,493.337036 749.723206,492.501953 747.218018,493.993286
	C745.866211,494.141388 744.823914,494.107666 743.389099,494.083435
	C742.878479,493.915100 742.760315,493.737244 742.841858,493.308960
	C745.917542,491.585388 749.033447,492.003845 752.518311,491.922791
	C755.810120,494.112701 759.943665,492.705322 762.815125,495.688538
	C759.859497,496.466278 756.901001,495.224731 753.413940,495.006775
z"
        />
        <path
          fill="#693324"
          opacity="1.000000"
          stroke="none"
          d="
M729.001526,486.998413
	C727.727722,486.996826 726.452454,486.996826 724.717834,486.996826
	C726.258972,485.510132 727.396606,486.040924 728.765381,486.760803
	C728.998718,487.002777 729.000000,487.000000 729.001526,486.998413
z"
        />
        <path
          fill="#813920"
          opacity="1.000000"
          stroke="none"
          d="
M717.437744,525.973755
	C717.028564,526.419373 717.049500,526.844604 717.101562,527.587769
	C716.952454,528.063599 716.772156,528.221558 716.236938,528.328857
	C711.710754,527.050781 707.536072,527.019470 703.029053,528.400330
	C702.224854,528.698975 701.739441,528.745728 700.617432,528.350281
	C697.311890,525.685059 692.061157,525.772705 692.809021,520.269531
	C692.815735,520.219971 692.535889,520.105408 692.378540,520.063843
	C691.212585,519.755859 689.165344,520.335693 689.377258,518.417542
	C689.627380,516.152954 692.249512,517.525696 693.737122,516.249756
	C695.430603,515.185303 696.833679,515.428162 698.302673,515.684265
	C697.481201,515.299805 696.445007,515.615356 695.287903,515.161621
	C693.658508,511.239410 696.181213,510.862396 698.923645,510.871521
	C697.817383,508.549286 693.156860,512.386780 694.159973,507.192810
	C694.277649,506.859619 694.439270,506.915619 694.518555,506.947601
	C697.583069,505.715271 700.401978,506.869934 703.122986,507.810852
	C705.014404,508.464905 706.776672,509.252289 708.374023,508.872559
	C705.044373,509.129120 701.902710,507.477570 698.907837,505.316589
	C698.993652,504.556885 698.986938,504.131683 698.952759,503.389771
	C699.226562,502.937592 699.485474,502.745056 699.816406,502.282532
	C704.350342,502.680817 708.757507,503.423737 713.195374,503.838135
	C714.954102,504.002411 716.793152,503.502441 718.570190,503.168976
	C719.213318,503.048309 720.552490,502.997253 719.844299,501.808105
	C719.539062,501.295532 718.390991,501.284882 717.281250,501.008881
	C718.389343,497.114594 713.782288,498.724365 713.039917,496.450195
	C718.648376,495.608398 724.427551,496.673157 730.499634,495.346436
	C731.004700,497.111176 731.827087,498.436188 733.691650,497.806946
	C736.570068,496.835602 739.094788,498.464172 741.853455,498.679596
	C740.520691,498.921143 739.187927,499.162720 737.783325,499.417267
	C738.698120,501.807434 741.032959,500.259186 742.116333,501.569641
	C740.557922,504.046295 740.557922,504.046295 742.369080,506.390961
	C740.247986,508.036163 739.163757,505.652893 737.247070,505.116638
	C734.783813,506.747131 732.422058,504.986176 730.390015,505.543396
	C731.757446,505.457367 733.271667,505.446411 734.774902,506.732086
	C733.153320,509.457642 730.167542,508.690552 727.980957,509.423004
	C726.126404,511.644043 731.921509,510.024109 729.097046,512.797302
	C726.540283,513.634888 724.453735,511.867371 722.114990,512.064758
	C720.935486,512.164246 718.859070,512.333923 719.339539,513.609009
	C719.693176,514.547546 719.583740,516.867859 721.903748,516.792603
	C723.868958,516.728760 725.845276,517.003540 728.065735,517.144226
	C726.582275,518.917786 723.158325,516.893677 723.031982,520.640503
	C721.061646,522.644775 719.706909,520.790649 718.104004,520.209961
	C715.466797,519.254578 712.674194,518.892944 710.149231,518.288330
	C712.315125,520.402161 717.275391,517.685791 718.151245,522.621460
	C719.618835,522.062317 721.505981,523.297668 722.833984,521.270020
	C726.930176,519.311951 730.446167,522.579041 734.654358,522.074341
	C735.336365,522.407593 735.475952,522.747986 735.223755,523.394836
	C733.548218,524.809875 730.807434,522.340759 729.999512,525.677429
	C725.983826,524.930786 721.923828,526.193726 717.437744,525.973755
z"
        />
        <path
          fill="#813920"
          opacity="1.000000"
          stroke="none"
          d="
M742.710632,493.044434
	C743.032288,493.230560 743.023132,493.402557 743.005249,493.833771
	C742.152832,494.938477 741.517456,494.826569 741.085083,493.416138
	C741.502014,493.110565 741.940918,493.070465 742.710632,493.044434
z"
        />
        <path
          fill="#7D2C11"
          opacity="1.000000"
          stroke="none"
          d="
M736.827515,515.909363
	C739.203552,517.502563 741.882690,517.080872 744.607666,517.918091
	C744.969116,518.672852 746.314087,519.834473 745.644287,519.721375
	C740.745178,518.894531 735.520508,519.473206 731.059753,516.657715
	C731.044250,516.647949 731.482666,515.918823 731.801636,515.272400
	C732.083679,515.005981 732.273132,514.992798 732.747253,514.966797
	C733.686523,515.778870 734.654480,515.387024 735.745544,515.765076
	C736.199524,515.944031 736.379333,515.939941 736.827515,515.909363
z"
        />
        <path
          fill="#A5654C"
          opacity="1.000000"
          stroke="none"
          d="
M735.231323,506.883270
	C732.612854,506.769287 730.037659,507.585388 727.829224,505.606049
	C730.528198,502.897797 733.383911,504.629303 736.515625,505.027344
	C736.427612,505.630096 735.956848,506.193115 735.231323,506.883270
z"
        />
        <path
          fill="#631D07"
          opacity="1.000000"
          stroke="none"
          d="
M745.714905,508.519440
	C746.563232,508.193420 747.191467,508.178162 747.439331,508.909698
	C747.503357,509.098663 747.252625,509.579285 747.058899,509.638245
	C746.258118,509.882141 745.494141,509.899780 745.714905,508.519440
z"
        />
        <path
          fill="#813920"
          opacity="1.000000"
          stroke="none"
          d="
M735.802490,515.699524
	C735.169983,515.781433 734.320129,515.614746 733.251099,515.200989
	C733.882996,515.119629 734.734070,515.285217 735.802490,515.699524
z"
        />
        <path
          fill="#813920"
          opacity="1.000000"
          stroke="none"
          d="
M730.843994,514.030823
	C730.395874,514.083862 729.824890,513.831055 729.111938,513.299683
	C729.553772,513.255737 730.137390,513.490356 730.843994,514.030823
z"
        />
        <path
          fill="#321616"
          opacity="1.000000"
          stroke="none"
          d="
M778.873169,509.994934
	C780.290100,510.211334 781.601440,509.570068 782.406982,511.261322
	C779.410767,512.980347 776.911743,510.558319 774.245728,509.992157
	C775.657471,509.992157 777.069214,509.992157 778.873169,509.994934
z"
        />
        <path
          fill="#C1ACB2"
          opacity="1.000000"
          stroke="none"
          d="
M556.674927,466.105164
	C557.237732,466.122009 557.533203,466.342438 557.958008,466.812225
	C555.957458,468.710602 553.827515,470.359650 551.353027,472.022980
	C551.430237,468.829041 554.579590,468.191559 556.674927,466.105164
z"
        />
        <path
          fill="#C1ACB2"
          opacity="1.000000"
          stroke="none"
          d="
M548.710144,473.032104
	C548.972473,475.136536 547.304016,475.629486 545.369141,476.123474
	C545.611389,474.448883 547.025146,473.792175 548.710144,473.032104
z"
        />
        <path
          fill="#D0C3BE"
          opacity="1.000000"
          stroke="none"
          d="
M538.276917,481.901550
	C537.545288,481.993103 537.109924,482.075562 536.345459,482.111023
	C536.441528,479.754028 538.668091,479.788452 540.691772,479.170288
	C541.106262,479.933411 540.849915,480.304657 540.120850,480.639404
	C539.774231,480.686829 539.753906,480.741974 539.726074,480.732605
	C539.652588,481.429871 539.155151,481.701843 538.276917,481.901550
z"
        />
        <path
          fill="#D0C3BE"
          opacity="1.000000"
          stroke="none"
          d="
M543.725830,476.976013
	C543.772644,477.449677 543.481995,477.879272 543.020996,478.530731
	C542.609802,479.220367 542.233704,479.464844 541.381165,479.262756
	C541.062134,479.076294 540.971008,478.968964 540.921753,478.912964
	C541.333313,477.721497 542.169617,477.076721 543.725830,476.976013
z"
        />
        <path
          fill="#D0C3BE"
          opacity="1.000000"
          stroke="none"
          d="
M535.713135,481.885864
	C535.638611,483.177765 534.644958,483.371796 533.311035,483.295441
	C533.368652,481.877228 534.406555,481.870483 535.713135,481.885864
z"
        />
        <path
          fill="#C1ACB2"
          opacity="1.000000"
          stroke="none"
          d="
M550.718689,471.940582
	C550.932861,472.837158 550.345032,473.116119 549.308594,473.146271
	C549.455200,472.646057 549.928345,472.257233 550.718689,471.940582
z"
        />
        <path
          fill="#C1ACB2"
          opacity="1.000000"
          stroke="none"
          d="
M544.735291,476.034454
	C545.096375,476.222229 545.158813,476.425903 545.116577,476.931641
	C544.842529,477.238251 544.672974,477.242828 544.266602,477.154724
	C544.165405,476.724823 544.301025,476.387573 544.735291,476.034454
z"
        />
        <path
          fill="#2E515F"
          opacity="1.000000"
          stroke="none"
          d="
M529.726074,497.059387
	C530.308105,499.938690 533.192078,498.507996 534.746216,499.900970
	C533.021118,505.304657 528.155212,505.542267 523.296936,506.331451
	C513.218140,509.521362 503.362885,512.196228 493.089355,512.984741
	C490.969025,513.147461 488.805786,513.469971 486.429291,512.420532
	C486.239288,511.934998 486.328217,511.744354 486.658630,511.351868
	C488.040680,510.635498 489.043457,509.854980 490.519836,509.312073
	C491.830566,508.956635 492.868103,509.018738 494.510468,509.106628
	C507.185211,508.065582 517.877014,503.017609 528.587891,497.338776
	C528.979614,497.124695 529.191284,497.083313 529.726074,497.059387
z"
        />
        <path
          fill="#33616C"
          opacity="1.000000"
          stroke="none"
          d="
M521.334839,510.975403
	C520.387024,510.926239 519.832947,510.884705 518.868835,510.744629
	C519.231262,509.036255 520.848511,508.697845 522.625854,508.023987
	C527.116821,504.476166 532.280334,503.049255 537.166016,501.104797
	C539.278687,500.264008 540.652710,502.821625 543.045654,503.054810
	C543.680359,503.208099 543.928772,503.299255 544.492554,503.638214
	C545.559814,504.781555 545.829834,505.768707 545.107544,507.191132
	C542.358582,508.811707 539.658508,507.897430 537.070374,508.200745
	C531.885315,508.808319 526.758484,509.586853 521.334839,510.975403
z"
        />
        <path
          fill="#63879A"
          opacity="1.000000"
          stroke="none"
          d="
M544.086365,503.689484
	C536.294250,500.376892 530.124084,504.896912 523.301575,507.844543
	C522.954895,507.561249 522.950623,507.173340 522.934814,506.495911
	C526.849548,504.352295 530.775696,502.498199 534.879272,500.343323
	C535.461548,500.023804 535.866577,500.005066 536.575684,499.972443
	C537.222412,499.707153 537.607300,499.567200 538.342407,499.532837
	C539.067078,499.638397 539.460754,499.781372 540.180725,500.487366
	C541.106506,501.317627 541.689331,501.631042 542.608032,502.006775
	C543.493408,502.388885 544.031006,502.719971 544.086365,503.689484
z"
        />
        <path
          fill="#1E4552"
          opacity="1.000000"
          stroke="none"
          d="
M485.586548,516.070312
	C485.962555,517.519592 485.705688,518.668579 483.943512,518.953308
	C480.205811,519.557373 476.488739,520.289185 472.347595,520.941284
	C475.661346,517.403320 480.599854,517.186584 485.586548,516.070312
z"
        />
        <path
          fill="#B3D5DF"
          opacity="1.000000"
          stroke="none"
          d="
M487.186157,511.465302
	C486.931091,511.873566 486.545837,512.015625 486.348083,512.070679
	C484.500519,512.600769 483.469147,514.605591 481.013062,514.003052
	C481.821655,511.627075 484.390900,511.059448 486.588928,509.427917
	C487.350464,509.793060 487.523041,510.388153 487.186157,511.465302
z"
        />
        <path
          fill="#829FB1"
          opacity="1.000000"
          stroke="none"
          d="
M487.246155,511.905518
	C486.935791,511.296051 486.940521,510.718506 486.934326,509.706360
	C487.290680,508.312714 488.201935,508.166870 489.275757,507.835754
	C490.852051,507.946960 492.174957,508.220001 493.723236,507.280884
	C494.836884,507.271729 497.280243,506.249298 495.224915,508.946167
	C495.119385,509.200592 495.062408,509.155792 495.057556,509.119873
	C493.928009,509.887085 492.475220,509.118805 491.192810,510.218628
	C489.885773,511.030884 488.723480,511.484192 487.246155,511.905518
z"
        />
        <path
          fill="#635B5F"
          opacity="1.000000"
          stroke="none"
          d="
M540.956055,479.317993
	C541.458923,479.002533 541.855774,478.928772 542.551636,478.803802
	C542.889465,480.353241 542.090210,481.028015 540.149902,480.772095
	C540.132812,480.311127 540.491394,479.935425 540.956055,479.317993
z"
        />
        <path
          fill="#635B5F"
          opacity="1.000000"
          stroke="none"
          d="
M538.158447,482.143738
	C538.374573,481.556366 538.768433,481.202087 539.430237,480.785522
	C539.761902,481.700745 540.063293,482.874023 538.158447,482.143738
z"
        />
        <path
          fill="#243A45"
          opacity="1.000000"
          stroke="none"
          d="
M653.620361,523.046997
	C654.374084,523.847473 654.727173,524.641113 654.091064,525.754089
	C651.347168,525.892029 648.674194,525.980225 646.065857,525.611633
	C644.377380,525.372986 643.818909,523.628113 643.613403,521.664917
	C643.462341,520.221802 641.108215,521.660889 640.578247,519.661926
	C643.335266,518.374817 646.284729,519.139404 649.133545,519.110962
	C650.040771,519.101929 651.039673,519.806458 650.670959,520.614197
	C648.993774,524.288452 652.068298,522.608215 653.620361,523.046997
z"
        />
        <path
          fill="#1E4552"
          opacity="1.000000"
          stroke="none"
          d="
M616.233398,533.222046
	C618.678467,528.859924 622.833008,532.027649 626.592529,530.940186
	C629.055908,529.304199 631.019592,529.340576 632.968933,531.537598
	C632.997253,532.004883 633.000977,531.998718 633.005737,531.993713
	C630.044556,533.002747 626.979492,533.129272 623.452515,533.014465
	C621.288940,533.017639 619.559448,533.013306 617.398193,533.014648
	C616.801575,533.161499 616.636658,533.302795 616.233398,533.222046
z"
        />
        <path
          fill="#1F3B45"
          opacity="1.000000"
          stroke="none"
          d="
M650.671875,516.046265
	C650.729065,516.500366 650.476746,516.987549 650.107666,517.723022
	C649.291199,517.870544 648.140808,518.280640 648.112854,517.377014
	C648.100220,516.968079 649.566895,516.513367 650.671875,516.046265
z"
        />
        <path
          fill="#3A292B"
          opacity="1.000000"
          stroke="none"
          d="
M605.981628,562.069824
	C606.395569,562.014343 607.043091,562.214539 607.903076,562.692505
	C607.482117,562.755249 606.848633,562.540344 605.981628,562.069824
z"
        />
        <path
          fill="#3D292C"
          opacity="1.000000"
          stroke="none"
          d="
M672.765991,503.834473
	C673.728394,505.221069 676.991272,505.769287 673.588318,507.770172
	C672.376160,508.482880 672.991455,510.520782 673.197021,511.963806
	C673.332031,512.911621 674.173340,514.146362 673.334167,514.744202
	C669.416931,517.535034 671.883606,521.070984 672.004517,524.652100
	C670.614563,524.980286 669.215027,524.986389 667.394836,524.985352
	C666.822754,523.958008 668.065918,522.711426 666.387817,521.929077
	C665.034729,521.298218 664.517883,519.856201 666.030823,519.406250
	C669.650085,518.329773 666.653381,517.973511 665.984741,517.212280
	C664.751343,515.808167 664.310364,513.371948 665.702576,512.790405
	C670.281616,510.877899 667.234131,509.419159 665.792053,507.724213
	C665.020020,506.816864 665.133118,505.917786 666.237305,504.875824
	C668.364990,503.487823 670.529480,504.070374 672.765991,503.834473
z"
        />
        <path
          fill="#3E2423"
          opacity="1.000000"
          stroke="none"
          d="
M676.888855,535.372192
	C679.367126,534.885864 680.944946,535.721497 681.011353,538.778931
	C679.373901,542.050171 676.341736,541.357910 673.406860,541.939453
	C671.964355,538.443420 674.588196,537.206970 676.888855,535.372192
z"
        />
        <path
          fill="#262C32"
          opacity="1.000000"
          stroke="none"
          d="
M619.813721,565.820312
	C619.421265,565.893005 618.856812,565.714294 618.115784,565.289551
	C618.506714,565.218628 619.074158,565.393738 619.813721,565.820312
z"
        />
        <path
          fill="#262C32"
          opacity="1.000000"
          stroke="none"
          d="
M617.800781,564.810913
	C617.390564,564.890198 616.791626,564.705872 615.979858,564.282104
	C616.381958,564.210938 616.997070,564.379089 617.800781,564.810913
z"
        />
        <path
          fill="#632B1D"
          opacity="1.000000"
          stroke="none"
          d="
M690.628113,541.027771
	C687.935364,545.142639 689.183472,549.902283 689.013611,554.425049
	C688.895508,557.569458 689.019775,560.723083 689.063660,564.315979
	C686.680115,565.140869 684.268677,565.522400 681.461853,565.904602
	C681.329773,558.536682 680.055420,551.113403 681.902954,543.381104
	C684.711670,542.308899 687.469299,541.654846 690.628113,541.027771
z"
        />
        <path
          fill="#759EB0"
          opacity="1.000000"
          stroke="none"
          d="
M552.246460,513.060303
	C549.901062,516.303467 546.554321,517.446045 543.131531,519.050171
	C542.162109,519.440979 541.443115,519.649353 540.371399,519.979675
	C539.845581,520.255554 539.672485,520.409485 539.216309,520.765015
	C532.042542,522.671570 525.140686,524.197205 518.057556,525.052307
	C512.059326,525.776306 506.191010,526.044312 499.598328,524.386719
	C505.852173,522.085815 511.813232,524.016174 517.564453,523.053345
	C528.085999,521.291687 538.455078,519.307861 548.125610,513.184326
	C549.374146,511.692871 550.452942,511.372986 551.982178,511.829224
	C552.293518,512.264404 552.321228,512.495483 552.246460,513.060303
z"
        />
        <path
          fill="#1E4552"
          opacity="1.000000"
          stroke="none"
          d="
M521.206970,511.228516
	C528.565796,508.025085 536.266235,505.506134 545.054932,506.621887
	C545.922729,506.407776 546.367371,506.417572 547.069092,506.773254
	C547.833618,508.831146 548.487366,510.648895 548.323853,512.882507
	C548.165710,513.163452 548.147461,513.188721 548.163818,513.203064
	C542.189636,512.499756 536.622620,509.778412 530.397583,509.875366
	C527.235168,509.924591 524.789368,513.047852 521.206970,511.228516
z"
        />
        <path
          fill="#2E515F"
          opacity="1.000000"
          stroke="none"
          d="
M544.780457,523.243164
	C546.477783,523.011658 546.196472,523.420288 549.499084,525.693787
	C551.263794,526.908630 554.151978,526.578186 556.890320,527.906921
	C551.250732,530.835754 546.520203,528.834534 541.391052,527.006348
	C540.993835,524.630920 542.958801,524.246704 544.780457,523.243164
z"
        />
        <path
          fill="#2E515F"
          opacity="1.000000"
          stroke="none"
          d="
M534.654297,546.002014
	C535.663879,547.443176 537.976746,547.221863 538.532104,549.665100
	C533.493958,550.378601 529.009583,549.176819 524.586426,547.129333
	C527.353699,545.483398 530.305542,546.150574 533.612732,546.003845
	C534.068726,546.014648 534.459106,546.005554 534.654297,546.002014
z"
        />
        <path
          fill="#1E4552"
          opacity="1.000000"
          stroke="none"
          d="
M526.002441,528.997559
	C523.544250,529.050293 521.085327,529.091614 518.355469,528.080200
	C522.270813,526.227539 526.790710,527.053711 532.207886,525.642029
	C530.126770,528.869141 527.907837,527.391296 526.263306,528.748718
	C525.993042,529.003479 526.000000,529.000000 526.002441,528.997559
z"
        />
        <path
          fill="#3E6978"
          opacity="1.000000"
          stroke="none"
          d="
M517.972412,528.013916
	C516.912659,529.140259 515.537292,528.956055 514.226685,528.978821
	C509.854828,529.054749 505.548584,527.334595 501.119934,528.839172
	C506.354614,525.124146 511.780975,528.539978 517.525635,528.021118
	C517.964539,528.032227 517.994141,527.998901 517.972412,528.013916
z"
        />
        <path
          fill="#1E4552"
          opacity="1.000000"
          stroke="none"
          d="
M507.990662,539.009399
	C504.105652,538.632446 500.228394,538.247803 496.351166,537.863159
	C500.169525,536.705994 503.894257,536.616577 507.740448,538.716919
	C508.009369,538.984436 507.998383,539.001709 507.990662,539.009399
z"
        />
        <path
          fill="#1E4552"
          opacity="1.000000"
          stroke="none"
          d="
M515.531982,533.325073
	C516.664307,533.195801 517.836670,533.343262 517.239136,534.358887
	C516.650452,535.359314 515.080139,535.419006 514.206482,534.600769
	C513.689087,534.116211 514.761353,533.744019 515.531982,533.325073
z"
        />
        <path
          fill="#2E515F"
          opacity="1.000000"
          stroke="none"
          d="
M536.673950,528.025269
	C536.158813,529.116882 534.918823,528.986450 533.361084,529.014771
	C533.864014,527.901245 535.102661,528.015198 536.673950,528.025269
z"
        />
        <path
          fill="#2E515F"
          opacity="1.000000"
          stroke="none"
          d="
M539.214722,520.971436
	C539.127869,520.707642 539.322327,520.448608 539.767822,520.145630
	C540.018738,520.101624 540.021423,520.526917 540.039795,520.738159
	C539.870789,520.958313 539.683411,520.967224 539.214722,520.971436
z"
        />
        <path
          fill="#33616C"
          opacity="1.000000"
          stroke="none"
          d="
M545.404297,541.300537
	C550.994690,539.986328 556.761108,539.457947 562.240540,543.561279
	C559.647461,545.789551 556.610107,544.688171 553.433044,544.993042
	C552.197632,540.322632 548.027588,542.864441 545.404297,541.300537
z"
        />
        <path
          fill="#33505C"
          opacity="1.000000"
          stroke="none"
          d="
M532.452087,553.940125
	C531.455505,553.132690 530.589233,553.014221 529.359009,552.954346
	C529.506348,550.557434 531.413025,551.069946 532.986023,551.039246
	C536.131470,550.977783 539.278625,550.987976 542.424988,550.996521
	C543.456482,550.999329 544.662109,550.981323 543.166199,552.712280
	C541.175598,553.114319 539.358765,553.281677 537.267578,553.718384
	C535.616821,553.972778 534.240356,553.957825 532.452087,553.940125
z"
        />
        <path
          fill="#33616C"
          opacity="1.000000"
          stroke="none"
          d="
M541.549683,539.055664
	C542.548706,539.562988 543.104248,540.052612 543.823792,540.769409
	C539.319458,542.630127 534.719055,542.184509 529.044861,540.720581
	C533.722778,539.428101 537.337769,538.675659 541.549683,539.055664
z"
        />
        <path
          fill="#33616C"
          opacity="1.000000"
          stroke="none"
          d="
M553.365356,549.277466
	C549.364380,548.762390 545.534912,549.733704 540.787231,547.318176
	C546.519104,546.865295 550.864197,546.503784 555.564636,547.966187
	C556.002197,549.738403 554.882935,549.650208 553.365356,549.277466
z"
        />
        <path
          fill="#1D3540"
          opacity="1.000000"
          stroke="none"
          d="
M553.008789,549.368835
	C553.850647,548.878235 554.690125,548.695007 555.750854,548.277100
	C555.981201,547.868958 555.990356,547.695618 556.005859,547.261841
	C556.960693,546.242676 557.944092,546.420898 559.157166,547.218384
	C559.394165,547.805054 559.412292,548.066040 559.213379,548.653931
	C558.697876,549.457520 558.399353,549.934265 558.057129,550.702637
	C556.625732,550.976807 555.238037,550.959290 553.435181,550.922729
	C553.015503,550.494507 553.010925,550.085388 553.008789,549.368835
z"
        />
        <path
          fill="#385661"
          opacity="1.000000"
          stroke="none"
          d="
M559.310547,548.216797
	C558.425354,547.183105 557.561768,547.075745 556.342407,547.016418
	C556.012207,547.001465 556.015381,546.504456 556.016602,546.256042
	C558.991516,545.754272 561.965271,545.500916 565.053406,545.237793
	C564.368042,547.050232 562.491333,546.611572 561.221069,547.763489
	C560.613831,548.392273 560.151184,548.542053 559.310547,548.216797
z"
        />
        <path
          fill="#1E4552"
          opacity="1.000000"
          stroke="none"
          d="
M510.754517,544.990356
	C510.631256,545.294800 510.420593,546.312439 509.905365,545.118286
	C510.039581,544.986328 510.516205,544.988220 510.754517,544.990356
z"
        />
        <path
          fill="#285362"
          opacity="1.000000"
          stroke="none"
          d="
M515.481506,543.479004
	C515.520996,543.460083 515.442017,543.497864 515.481506,543.479004
z"
        />
        <path
          fill="#385661"
          opacity="1.000000"
          stroke="none"
          d="
M584.766479,537.758850
	C586.079590,539.617676 588.296387,538.289734 589.599060,540.038025
	C587.667053,541.850891 585.302856,541.934814 582.504761,541.902832
	C580.196106,538.741699 576.978333,539.684448 574.196716,539.508545
	C572.754333,539.417358 571.198853,539.768799 569.820312,540.267822
	C565.886108,541.692017 562.389160,541.173950 558.947327,537.830383
	C567.711121,539.424072 576.062317,537.469055 584.766479,537.758850
z"
        />
        <path
          fill="#193A47"
          opacity="1.000000"
          stroke="none"
          d="
M608.265747,521.081787
	C607.922791,518.973694 606.046509,519.355957 604.836304,518.051575
	C611.578003,518.389099 617.727539,516.378540 624.537964,517.005066
	C622.986328,518.822876 619.742493,518.488403 617.787109,521.171204
	C614.506897,522.555725 611.532227,521.930969 608.265747,521.081787
z"
        />
        <path
          fill="#35535F"
          opacity="1.000000"
          stroke="none"
          d="
M591.667053,544.827820
	C594.743286,545.232727 597.601807,544.343567 600.152039,545.690247
	C602.218689,546.781494 600.394958,546.782043 599.341919,547.047974
	C595.977844,548.723999 592.867004,549.226868 589.330688,547.280396
	C589.014893,547.016602 588.563721,546.986328 588.337646,546.983398
	C586.650024,546.024719 584.978149,546.755493 583.112854,546.289795
	C582.589966,546.054626 582.396240,545.927917 581.979492,545.535156
	C581.700378,545.044861 581.644226,544.820740 581.782349,544.309509
	C581.976562,544.022400 582.002502,543.532593 582.030396,543.289307
	C585.138672,543.595764 588.219116,544.145386 591.667053,544.827820
z"
        />
        <path
          fill="#193A47"
          opacity="1.000000"
          stroke="none"
          d="
M620.437012,525.992920
	C619.896240,524.748840 624.004700,525.022278 621.052246,522.634155
	C619.653625,521.502869 621.329956,521.148560 622.120361,520.849487
	C625.449219,519.589905 628.706360,519.510376 631.980347,521.503784
	C630.249451,522.624634 628.159180,521.226013 626.608887,522.871338
	C627.072021,524.559570 628.941956,523.562927 629.973450,524.485718
	C626.965759,525.904968 623.965820,526.141846 620.437012,525.992920
z"
        />
        <path
          fill="#33616C"
          opacity="1.000000"
          stroke="none"
          d="
M558.678467,530.010071
	C561.105286,530.031494 562.836670,530.614685 564.647522,532.635010
	C558.971985,533.444885 554.057434,533.040222 548.764099,531.489319
	C552.174622,530.715271 555.116821,529.763916 558.678467,530.010071
z"
        />
        <path
          fill="#35535F"
          opacity="1.000000"
          stroke="none"
          d="
M597.601257,531.999634
	C599.112915,532.100037 600.361267,531.794373 601.289124,533.498657
	C596.414001,534.238831 591.783936,535.407959 586.489319,534.981934
	C586.344421,532.927063 587.912781,532.895691 589.407410,533.008118
	C592.080139,533.209229 594.621460,532.579163 597.601257,531.999634
z"
        />
        <path
          fill="#33616C"
          opacity="1.000000"
          stroke="none"
          d="
M562.167603,534.553772
	C564.446594,535.258240 566.891907,533.096558 568.582581,536.157288
	C564.143127,538.282043 560.101013,535.767883 554.975708,535.652161
	C557.682800,533.481567 559.750854,534.005554 562.167603,534.553772
z"
        />
        <path
          fill="#15313E"
          opacity="1.000000"
          stroke="none"
          d="
M633.357056,532.006409
	C631.523804,530.453735 629.590637,531.157959 627.446533,530.937866
	C627.035400,530.496094 627.020325,530.070557 627.003906,529.324951
	C627.099365,528.733826 627.196228,528.462769 627.149048,528.093018
	C626.815735,527.830078 626.626648,527.665771 626.724670,527.259277
	C629.939209,526.845886 632.019592,529.322205 634.860413,529.530762
	C635.533264,529.580200 636.642151,530.146973 636.281799,531.159973
	C635.875183,532.302856 634.644470,531.908264 633.357056,532.006409
z"
        />
        <path
          fill="#33616C"
          opacity="1.000000"
          stroke="none"
          d="
M575.128540,533.088867
	C577.932251,532.951782 579.464966,535.069397 581.660767,535.740967
	C578.398499,535.740967 575.136169,535.740967 571.801819,535.740967
	C571.711548,533.331421 573.464905,533.502991 575.128540,533.088867
z"
        />
        <path
          fill="#33616C"
          opacity="1.000000"
          stroke="none"
          d="
M572.003418,529.012817
	C573.924927,529.416931 575.841187,529.808716 578.882141,530.430542
	C574.834045,532.669739 571.453308,531.690979 567.999512,532.028748
	C568.943176,530.401794 571.056213,531.141785 571.851318,529.302246
	C572.021179,529.008423 571.998108,529.000610 572.003418,529.012817
z"
        />
        <path
          fill="#193A47"
          opacity="1.000000"
          stroke="none"
          d="
M626.595093,527.012634
	C627.011841,527.016968 627.004761,527.505737 627.004822,527.750000
	C622.469849,527.824768 617.750610,528.776123 613.290405,525.944214
	C615.561035,524.453979 617.338989,526.509094 619.585327,525.974792
	C619.972351,525.971863 620.002991,526.000244 620.021606,526.011047
	C621.867432,527.712097 624.124817,526.725952 626.595093,527.012634
z"
        />
        <path
          fill="#5C8997"
          opacity="1.000000"
          stroke="none"
          d="
M607.909180,520.989258
	C610.873474,520.907959 613.773621,521.082336 617.073608,521.323120
	C617.737976,522.179688 618.102844,523.085938 616.748779,523.109985
	C614.762695,523.145264 612.774414,523.060852 610.371033,522.981812
	C609.411255,522.601440 608.867554,522.266663 608.000977,521.838379
	C607.733765,521.578186 607.789429,521.411560 607.909180,520.989258
z"
        />
        <path
          fill="#3C5965"
          opacity="1.000000"
          stroke="none"
          d="
M578.503357,547.261841
	C577.137939,547.054077 576.184448,547.054565 575.235046,546.991882
	C574.633362,546.952148 573.764648,546.925842 573.959656,546.091248
	C574.059937,545.662048 574.898376,545.405334 575.720459,545.021484
	C577.716003,544.093994 579.506165,544.636108 581.625916,544.793457
	C582.003906,545.024353 582.003906,544.996704 581.990112,544.995544
	C582.998352,548.361267 580.406067,547.213318 578.503357,547.261841
z"
        />
        <path
          fill="#1D3540"
          opacity="1.000000"
          stroke="none"
          d="
M589.081299,547.353027
	C592.081116,548.239136 595.154968,547.966919 598.631592,547.068542
	C599.022705,547.247253 599.014160,547.420715 599.002563,547.855835
	C597.129150,549.601074 594.915283,548.789978 592.449463,549.046509
	C590.912842,548.944702 589.654907,549.111023 589.081299,547.353027
z"
        />
        <path
          fill="#33616C"
          opacity="1.000000"
          stroke="none"
          d="
M551.541870,538.831238
	C548.871887,538.908020 546.373169,539.589111 543.312012,537.139282
	C546.907410,537.109314 549.529785,536.505676 551.541870,538.831238
z"
        />
        <path
          fill="#1D3540"
          opacity="1.000000"
          stroke="none"
          d="
M578.369202,547.326294
	C579.400024,546.796021 581.064087,547.455261 581.896484,545.338745
	C583.845764,545.489746 585.715088,545.985107 587.848022,546.730469
	C584.922546,546.622925 581.899597,548.920288 578.369202,547.326294
z"
        />
        <path
          fill="#193A47"
          opacity="1.000000"
          stroke="none"
          d="
M605.426392,526.992065
	C607.057434,526.989990 608.326843,526.989990 609.596313,526.989990
	C609.613159,527.304138 609.630005,527.618347 609.646851,527.932495
	C607.753906,527.932495 605.839478,528.104309 603.975647,527.871582
	C602.746887,527.718140 603.328125,527.217651 604.098816,527.051514
	C604.410461,526.984314 604.742249,527.010498 605.426392,526.992065
z"
        />
        <path
          fill="#5C8997"
          opacity="1.000000"
          stroke="none"
          d="
M606.255188,524.176147
	C606.520630,523.641785 607.034973,523.339478 607.864502,523.062439
	C608.513977,524.383850 610.184875,523.654785 610.850464,524.985046
	C609.259277,524.773865 607.881653,524.591003 606.255188,524.176147
z"
        />
        <path
          fill="#1D3540"
          opacity="1.000000"
          stroke="none"
          d="
M582.005127,544.773682
	C580.297852,545.014587 578.591858,545.004822 576.461670,544.983643
	C577.522156,543.427368 579.437317,544.174072 581.578613,544.014099
	C581.986511,544.189270 581.996399,544.356140 582.005127,544.773682
z"
        />
        <path
          fill="#193A47"
          opacity="1.000000"
          stroke="none"
          d="
M559.005127,548.229736
	C559.451599,547.983398 559.893738,547.987488 560.667603,547.998047
	C560.999390,548.004639 560.999756,548.498413 560.997314,548.744873
	C560.550903,548.993591 560.106995,548.995972 559.329712,548.989624
	C558.997925,548.813904 558.999390,548.647034 559.005127,548.229736
z"
        />
        <path
          fill="#285362"
          opacity="1.000000"
          stroke="none"
          d="
M553.533447,536.477173
	C553.555481,536.518555 553.511353,536.435791 553.533447,536.477173
z"
        />
        <path
          fill="#182932"
          opacity="1.000000"
          stroke="none"
          d="
M612.333862,536.010986
	C612.826477,536.033325 613.020691,536.000305 613.139954,536.079712
	C613.334900,536.209595 613.481079,536.412720 613.647827,536.584900
	C611.950928,537.344421 610.216003,536.923340 608.284058,536.814453
	C609.413391,535.340393 610.846436,536.328125 612.333862,536.010986
z"
        />
        <path
          fill="#182932"
          opacity="1.000000"
          stroke="none"
          d="
M615.761597,533.031860
	C615.995117,533.000000 616.481201,533.009644 616.723816,533.014954
	C616.448608,533.731628 615.852783,534.193909 614.942993,533.654175
	C615.138000,533.457397 615.333008,533.260559 615.761597,533.031860
z"
        />
        <path
          fill="#1E4552"
          opacity="1.000000"
          stroke="none"
          d="
M571.000000,549.999878
	C570.489319,550.275146 569.982971,550.559937 569.456543,550.800720
	C569.427856,550.813843 569.231018,550.459167 569.112427,550.275696
	C569.556641,550.186890 570.000793,550.098083 570.722656,550.004761
	C571.000366,550.000305 571.000000,550.000000 571.000000,549.999878
z"
        />
        <path
          fill="#77878D"
          opacity="1.000000"
          stroke="none"
          d="
M540.829529,500.797363
	C540.523743,501.004211 540.553101,501.013550 540.540649,501.016235
	C540.158264,500.912811 539.818848,500.745941 539.327148,500.292572
	C538.637878,500.052277 538.136108,500.028412 537.257080,499.981537
	C541.591248,496.955688 546.302673,493.952850 551.334045,490.836243
	C551.929321,490.929230 552.204651,491.135956 552.256226,491.808105
	C550.690613,494.905609 547.073059,495.138153 545.872009,498.315552
	C544.227417,499.269165 542.681396,499.929840 540.829529,500.797363
z"
        />
        <path
          fill="#A19796"
          opacity="1.000000"
          stroke="none"
          d="
M552.299316,492.057190
	C551.979614,492.012939 551.926758,491.752380 551.763916,491.107178
	C551.920349,490.496094 552.186646,490.269623 552.714966,490.003510
	C552.839966,490.589539 552.703064,491.215210 552.299316,492.057190
z"
        />
        <path
          fill="#9B8881"
          opacity="1.000000"
          stroke="none"
          d="
M560.127808,468.116913
	C559.984863,468.570648 559.750916,468.945435 559.516968,469.320251
	C558.962463,468.959412 559.178406,468.568207 559.662354,468.028076
	C559.852905,467.882202 560.036743,468.037994 560.127808,468.116913
z"
        />
        <path
          fill="#4E5963"
          opacity="1.000000"
          stroke="none"
          d="
M562.961670,499.599487
	C562.488647,500.260620 562.065918,500.592133 561.386597,501.013031
	C558.548035,499.529022 556.641479,503.729462 553.471313,501.416290
	C556.230103,498.883362 559.696716,497.190582 563.841553,496.165527
	C563.821472,497.210907 563.416687,498.240356 562.961670,499.599487
z"
        />
        <path
          fill="#4E5963"
          opacity="1.000000"
          stroke="none"
          d="
M543.980774,503.989136
	C543.712280,503.506653 543.458008,503.030243 543.073853,502.311462
	C546.083679,502.432251 549.046326,501.334351 552.502991,500.929688
	C552.909363,500.899902 552.977722,500.980438 553.010803,501.021606
	C553.309387,501.747284 553.183350,502.452698 552.623291,502.818298
	C551.465210,503.574280 550.086914,504.127045 551.105957,506.134369
	C548.229431,507.474884 546.607727,505.346680 544.286499,504.199982
	C543.968933,503.980072 543.994995,503.995209 543.980774,503.989136
z"
        />
        <path
          fill="#847876"
          opacity="1.000000"
          stroke="none"
          d="
M672.967529,503.931061
	C671.101379,504.294952 669.161682,504.482697 666.875488,504.670715
	C660.130920,501.979706 653.732788,499.288483 647.148804,496.298706
	C649.257324,495.601227 648.056885,494.706848 647.216919,493.963440
	C645.958984,492.850037 643.865784,492.499298 643.943604,489.851257
	C652.278442,496.221924 661.389587,501.304230 672.434692,503.319763
	C672.831055,503.378326 672.893921,503.754913 672.967529,503.931061
z"
        />
        <path
          fill="#5C4747"
          opacity="1.000000"
          stroke="none"
          d="
M636.738403,492.202728
	C638.114990,492.318054 639.223511,492.625122 640.678467,492.964111
	C641.024841,492.996033 640.996094,492.999573 641.001099,492.985535
	C641.201233,493.150726 641.396423,493.329926 641.291443,493.769684
	C634.547058,495.925781 629.138733,492.978516 623.332092,490.211853
	C625.244812,487.888214 627.154175,490.268982 629.185852,490.619904
	C631.227966,490.972626 633.094238,492.169556 635.681580,491.976074
	C636.092041,491.953827 636.034241,491.990601 636.018555,491.960205
	C636.158691,492.084717 636.314514,492.239624 636.738403,492.202728
z"
        />
        <path
          fill="#182932"
          opacity="1.000000"
          stroke="none"
          d="
M635.784180,507.968689
	C632.248718,507.282440 631.025940,504.028778 628.865417,501.261169
	C631.963501,500.517212 633.134094,504.742249 636.557007,504.348328
	C637.163818,504.278503 635.547791,506.354309 635.784180,507.968689
z"
        />
        <path
          fill="#2F252B"
          opacity="1.000000"
          stroke="none"
          d="
M664.990540,525.009949
	C662.731445,525.017883 660.479919,525.017883 658.228455,525.017883
	C660.300232,523.899109 662.366882,523.710876 664.724854,524.725952
	C665.008789,524.984802 664.998169,525.002014 664.990540,525.009949
z"
        />
        <path
          fill="#182932"
          opacity="1.000000"
          stroke="none"
          d="
M656.409424,516.663452
	C656.537354,517.455627 656.118347,517.784912 655.695496,517.337708
	C655.269104,516.886841 655.627563,516.494080 656.409424,516.663452
z"
        />
        <path
          fill="#847876"
          opacity="1.000000"
          stroke="none"
          d="
M643.784912,495.212463
	C644.536987,494.817902 645.590454,494.760956 646.789246,495.712433
	C646.050964,495.828918 645.076355,495.630585 643.784912,495.212463
z"
        />
        <path
          fill="#847876"
          opacity="1.000000"
          stroke="none"
          d="
M641.335938,494.080536
	C640.992859,493.853729 640.994568,493.677277 641.001099,493.236145
	C641.797546,492.877899 642.443787,493.105438 642.942139,494.176300
	C642.568787,494.401489 642.124695,494.266174 641.335938,494.080536
z"
        />
        <path
          fill="#6D5A5B"
          opacity="1.000000"
          stroke="none"
          d="
M624.134705,475.221741
	C625.512878,475.151367 626.025635,476.026398 625.971863,477.664246
	C625.531555,477.717072 625.102234,477.425323 624.476440,476.963074
	C624.221436,476.374573 624.162903,475.956635 624.134705,475.221741
z"
        />
        <path
          fill="#3B3036"
          opacity="1.000000"
          stroke="none"
          d="
M636.755859,491.987579
	C636.728882,492.089905 636.451233,492.168823 636.088257,492.088806
	C636.170288,491.941284 636.337708,491.952759 636.755859,491.987579
z"
        />
        <path
          fill="#6D5A5B"
          opacity="1.000000"
          stroke="none"
          d="
M623.973877,474.196381
	C624.150208,474.168976 624.133240,474.515991 624.124695,474.688049
	C623.934021,474.737152 623.751831,474.614197 623.569702,474.491211
	C623.645630,474.402069 623.721619,474.312927 623.973877,474.196381
z"
        />
        <path
          fill="#2C4653"
          opacity="1.000000"
          stroke="none"
          d="
M621.644409,514.977295
	C620.237610,512.519897 616.877747,513.381714 614.739136,509.837372
	C620.347290,511.844330 625.064453,511.605743 629.928467,511.441925
	C626.418152,510.926514 624.996033,514.706360 621.644409,514.977295
z"
        />
        <path
          fill="#2C4653"
          opacity="1.000000"
          stroke="none"
          d="
M625.533264,508.616821
	C626.486023,508.573120 627.314758,508.360870 627.709045,508.682007
	C628.490417,509.318390 627.899292,509.926117 627.167603,509.910583
	C625.298706,509.870911 623.277283,510.530334 621.476501,509.184113
	C622.728394,507.317413 624.040161,508.909698 625.533264,508.616821
z"
        />
        <path
          fill="#2E515F"
          opacity="1.000000"
          stroke="none"
          d="
M573.780273,512.573975
	C573.833069,512.403076 573.955261,512.346130 574.077515,512.289185
	C574.001587,512.422119 573.925598,512.555115 573.780273,512.573975
z"
        />
        <path
          fill="#403D45"
          opacity="1.000000"
          stroke="none"
          d="
M550.924500,506.448303
	C549.153564,503.649750 551.220520,502.730652 552.879150,501.325348
	C555.622131,501.695862 558.019104,499.631256 560.873413,500.828186
	C560.706665,501.290375 560.283325,501.478333 559.682373,501.813171
	C559.191833,502.558563 558.869690,503.151611 558.362061,504.019562
	C558.250122,504.338043 558.071350,504.662842 557.943237,504.796814
	C556.634216,505.997925 555.939941,507.510956 554.252075,508.501831
	C552.623352,508.453217 551.522888,508.034607 550.924500,506.448303
z"
        />
        <path
          fill="#285362"
          opacity="1.000000"
          stroke="none"
          d="
M548.498840,513.114868
	C547.882812,511.263489 544.250610,510.075470 547.003418,507.210541
	C549.404297,506.145538 551.191772,507.940033 553.623962,508.499084
	C554.085083,508.880890 554.225586,509.082062 554.177002,509.598999
	C553.625427,510.397095 553.263062,510.879425 552.962402,511.659698
	C553.024170,511.957611 553.000183,511.985168 552.988037,511.998901
	C552.754761,512.138550 552.533569,512.264404 552.125244,512.057556
	C550.902710,512.171997 549.867371,512.619141 548.498840,513.114868
z"
        />
        <path
          fill="#4E5963"
          opacity="1.000000"
          stroke="none"
          d="
M554.303223,509.814423
	C553.987854,509.914764 553.986389,509.555756 554.000854,509.376831
	C553.911438,508.913208 553.807434,508.628540 554.115356,508.151642
	C554.911316,506.511688 555.201355,504.981262 557.499451,504.935242
	C557.902649,507.295746 556.160889,508.438293 554.303223,509.814423
z"
        />
        <path
          fill="#4E5963"
          opacity="1.000000"
          stroke="none"
          d="
M558.510254,504.250763
	C558.443665,503.654846 558.637146,502.971649 559.167725,502.124268
	C559.992065,502.938568 559.725647,503.665833 558.510254,504.250763
z"
        />
        <path
          fill="#1E4552"
          opacity="1.000000"
          stroke="none"
          d="
M568.656555,525.036377
	C570.097839,525.407837 571.203125,526.227295 569.448547,526.704224
	C567.514404,527.230042 565.327026,526.824219 562.745422,526.824219
	C564.707275,525.297668 566.446960,524.984741 568.656555,525.036377
z"
        />
        <path
          fill="#1E4552"
          opacity="1.000000"
          stroke="none"
          d="
M572.868225,523.562012
	C574.642944,523.588806 576.196167,523.852051 577.749390,524.115295
	C575.976196,524.559326 574.103516,526.088928 572.868225,523.562012
z"
        />
        <path
          fill="#1E4552"
          opacity="1.000000"
          stroke="none"
          d="
M581.309753,526.934570
	C580.417419,526.862183 579.794739,526.740540 579.171997,526.618896
	C579.601196,525.592896 580.483521,526.129272 581.181946,526.047729
	C581.330444,526.030396 581.508240,526.101135 581.645264,526.179138
	C582.061768,526.416321 582.033630,526.662537 581.309753,526.934570
z"
        />
        <path
          fill="#1E4552"
          opacity="1.000000"
          stroke="none"
          d="
M584.463135,525.245605
	C584.969299,524.266663 585.459351,524.864746 585.931335,525.149231
	C585.989807,525.184448 585.825684,525.588989 585.764771,525.822327
	C585.349915,525.703308 584.935120,525.584290 584.463135,525.245605
z"
        />
        <path
          fill="#929EAC"
          opacity="1.000000"
          stroke="none"
          d="
M551.984131,512.142822
	C552.129211,511.707458 552.320312,511.690094 552.743652,511.842712
	C552.881042,512.368225 552.786194,512.723755 552.417603,513.236694
	C552.106079,513.116272 552.068115,512.838562 551.984131,512.142822
z"
        />
        <path
          fill="#813920"
          opacity="1.000000"
          stroke="none"
          d="
M712.956177,500.590332
	C711.878113,500.801086 710.694641,500.692810 709.245850,500.346436
	C710.330200,498.749084 711.634338,498.468231 712.956177,500.590332
z"
        />
        <path
          fill="#813920"
          opacity="1.000000"
          stroke="none"
          d="
M698.992432,498.520264
	C698.496643,498.733673 697.939148,498.679596 697.194092,498.410706
	C697.680481,497.113586 698.323364,497.084137 698.992432,498.520264
z"
        />
        <path
          fill="#59200F"
          opacity="1.000000"
          stroke="none"
          d="
M698.637695,504.983490
	C703.040100,506.797272 707.180603,508.229950 712.093567,508.072327
	C705.917603,512.472290 700.861328,507.449982 695.023682,507.030640
	C695.415161,505.561890 696.664185,504.939819 698.637695,504.983490
z"
        />
        <path
          fill="#7D2C11"
          opacity="1.000000"
          stroke="none"
          d="
M745.368896,517.976929
	C747.081909,516.313782 749.616333,516.096191 752.598572,516.035034
	C751.221008,519.005432 748.245911,517.500488 745.368896,517.976929
z"
        />
        <path
          fill="#7D2C11"
          opacity="1.000000"
          stroke="none"
          d="
M751.002808,522.997192
	C748.752991,522.994446 746.500427,522.994446 743.797729,522.994446
	C746.282898,520.796997 748.393005,520.629883 750.795654,522.719971
	C750.996643,523.005432 751.000000,523.000000 751.002808,522.997192
z"
        />
        <path
          fill="#7D2C11"
          opacity="1.000000"
          stroke="none"
          d="
M747.832886,526.716797
	C746.195618,527.388489 744.727478,526.613892 743.146118,525.308533
	C744.645752,525.079285 746.423523,524.830994 747.832886,526.716797
z"
        />
        <path
          fill="#7D2C11"
          opacity="1.000000"
          stroke="none"
          d="
M735.345337,523.695984
	C735.020081,523.293457 735.011902,522.966003 735.002014,522.393066
	C736.493103,522.256653 737.985474,522.365784 739.518066,523.006958
	C738.964722,523.918518 738.304443,523.958557 737.296509,523.880615
	C736.530701,523.818909 736.096802,523.794983 735.345337,523.695984
z"
        />
        <path
          fill="#7D2C11"
          opacity="1.000000"
          stroke="none"
          d="
M742.867432,524.745728
	C741.960449,525.031555 740.910339,525.028076 739.466431,524.995972
	C738.552368,524.248596 739.002502,523.803894 739.777710,523.176453
	C740.034119,522.974365 740.007446,522.995789 739.994385,523.007324
	C741.106689,523.095520 742.074829,523.472107 742.867432,524.745728
z"
        />
        <path
          fill="#7D2C11"
          opacity="1.000000"
          stroke="none"
          d="
M750.798828,527.758179
	C750.110657,527.886475 749.221924,527.742554 748.151855,527.303101
	C748.846375,527.166992 749.722351,527.326538 750.798828,527.758179
z"
        />
        <path
          fill="#813920"
          opacity="1.000000"
          stroke="none"
          d="
M695.371948,532.218445
	C694.498108,531.994202 694.000061,532.058716 693.525574,531.980591
	C691.771606,531.691833 688.759155,532.817383 688.831177,530.165344
	C688.888123,528.065857 691.801025,529.336548 693.411316,528.991821
	C695.305847,528.586243 697.235168,528.343079 699.569336,528.008301
	C699.989258,527.987183 700.007141,528.003174 700.017029,528.009277
	C701.315125,528.822266 703.717224,528.678101 703.321106,530.994629
	C702.902405,533.443420 700.690369,532.268860 699.227539,532.463562
	C698.091003,532.614685 696.915283,532.524109 695.371948,532.218445
z"
        />
        <path
          fill="#7D2C11"
          opacity="1.000000"
          stroke="none"
          d="
M694.990479,532.250793
	C696.617798,531.992432 698.251099,532.020447 699.882385,531.975403
	C700.820557,531.949463 702.098145,532.206787 702.390259,531.030762
	C702.740662,529.619812 701.203674,529.297852 700.227783,528.282227
	C700.477844,528.002930 700.928711,527.990540 701.718018,527.969238
	C706.401184,527.292480 710.739258,526.433899 715.513672,527.696045
	C716.090759,527.895508 716.298767,527.902222 716.819763,527.907227
	C716.190247,530.198608 714.182190,528.515808 712.404175,529.089539
	C710.711853,529.625244 709.862488,531.325134 708.205322,530.908264
	C705.570618,530.245361 708.365967,532.323669 707.188477,532.715454
	C704.654297,533.026001 702.314331,533.057800 699.542542,533.121216
	C698.014465,533.108337 696.918213,533.063965 695.412170,533.018677
	C695.000244,532.847351 694.997986,532.676941 694.990479,532.250793
z"
        />
        <path
          fill="#813920"
          opacity="1.000000"
          stroke="none"
          d="
M687.235168,516.000244
	C686.089478,515.891235 685.086548,516.136902 684.821228,515.151062
	C684.572205,514.225586 685.499756,514.105042 686.063049,514.101807
	C687.786804,514.091980 689.599243,513.519165 691.282104,514.707947
	C690.584778,516.754700 688.850708,515.681213 687.235168,516.000244
z"
        />
        <path
          fill="#46170C"
          opacity="1.000000"
          stroke="none"
          d="
M694.816956,515.066772
	C696.289307,514.927856 697.551575,514.927856 699.541809,514.927856
	C697.571777,516.553772 696.104004,515.789795 694.388550,516.003174
	C694.204346,515.733643 694.405640,515.469727 694.816956,515.066772
z"
        />
        <path
          fill="#813920"
          opacity="1.000000"
          stroke="none"
          d="
M723.489319,528.998779
	C724.808899,526.773499 728.242249,529.405396 729.944946,526.334106
	C733.528625,525.668213 736.198914,527.608521 738.969421,529.661072
	C733.967896,528.599121 728.952515,529.065308 723.489319,528.998779
z"
        />
        <path
          fill="#BB5B23"
          opacity="1.000000"
          stroke="none"
          d="
M739.700928,522.984741
	C739.776611,523.465210 739.519104,523.955994 739.167114,524.707031
	C738.484802,524.773010 737.896912,524.578796 737.136841,524.113708
	C737.765625,523.560303 738.566650,523.277771 739.700928,522.984741
z"
        />
        <path
          fill="#813920"
          opacity="1.000000"
          stroke="none"
          d="
M721.762695,529.230652
	C722.173523,529.064209 722.346252,529.056274 722.778809,529.046387
	C723.284729,529.529602 723.530884,530.014832 723.908875,530.742554
	C723.159119,530.856445 722.277527,530.727844 721.128418,530.394409
	C720.915649,529.784668 721.127930,529.510437 721.762695,529.230652
z"
        />
        <path
          fill="#813920"
          opacity="1.000000"
          stroke="none"
          d="
M725.892700,531.841492
	C725.478760,531.898621 724.906372,531.685669 724.164673,531.216431
	C724.574890,531.163879 725.154602,531.367676 725.892700,531.841492
z"
        />
        <path
          fill="#9A4A2B"
          opacity="1.000000"
          stroke="none"
          d="
M706.997437,532.997070
	C705.010193,527.914978 710.120422,530.690979 711.664551,529.107544
	C710.866821,530.088684 713.084900,531.912537 711.008789,532.920288
	C709.858459,533.683411 708.766785,533.459167 707.351257,533.221558
	C707.008118,533.004272 707.000610,533.000000 706.997437,532.997070
z"
        />
        <path
          fill="#7D2C11"
          opacity="1.000000"
          stroke="none"
          d="
M707.251221,533.266785
	C708.042236,533.021973 709.076416,533.039673 710.493896,533.097656
	C710.877319,533.137939 710.986572,533.560852 711.015015,533.777405
	C709.860413,533.839050 708.677368,533.684204 707.251221,533.266785
z"
        />
        <path
          fill="#7D2C11"
          opacity="1.000000"
          stroke="none"
          d="
M721.709717,529.034424
	C721.772583,529.345764 721.544434,529.619324 721.088501,530.041199
	C720.512878,529.945862 720.164978,529.702209 719.817017,529.458557
	C720.350891,529.304565 720.884827,529.150635 721.709717,529.034424
z"
        />
        <path
          fill="#9A4A2B"
          opacity="1.000000"
          stroke="none"
          d="
M716.221069,530.419434
	C716.170166,530.583923 716.050354,530.638245 715.930542,530.692505
	C716.004395,530.564758 716.078308,530.437012 716.221069,530.419434
z"
        />
        <path
          fill="#7D2C11"
          opacity="1.000000"
          stroke="none"
          d="
M711.709473,534.004272
	C711.687439,534.102173 711.425842,534.180786 711.077087,534.109497
	C710.989929,533.959595 711.469910,533.984985 711.709473,534.004272
z"
        />
        <path
          fill="#517581"
          opacity="1.000000"
          stroke="none"
          d="
M732.515198,398.915833
	C733.001770,398.052399 733.602051,397.913788 734.209167,398.319000
	C734.719849,398.659882 734.645752,399.179993 734.180054,399.494385
	C733.584290,399.896606 732.977173,399.820282 732.515198,398.915833
z"
        />
        <path
          fill="#BB5B23"
          opacity="1.000000"
          stroke="none"
          d="
M722.983398,520.989929
	C723.312378,522.110779 725.113464,522.015137 725.076965,523.862000
	C721.277527,523.824341 717.451355,524.826111 713.701050,523.101807
	C714.333679,521.371155 716.059265,522.536682 716.910645,521.106445
	C713.249512,521.414001 710.070312,520.503174 707.181946,518.398987
	C712.904968,515.031677 717.465088,518.835449 722.656250,520.967041
	C723.018066,521.020142 722.996521,520.998291 722.983398,520.989929
z"
        />
        <path
          fill="#9A4A2B"
          opacity="1.000000"
          stroke="none"
          d="
M715.698914,527.558228
	C711.516968,527.262329 707.201660,529.824341 702.437439,528.027893
	C706.367859,524.505676 710.921021,525.490417 715.698914,527.558228
z"
        />
        <path
          fill="#BB5B23"
          opacity="1.000000"
          stroke="none"
          d="
M717.003784,525.997070
	C717.007629,525.994080 716.584717,525.744995 716.584717,525.744995
	C716.584717,525.744995 716.912903,525.761475 716.953369,525.884766
	C716.993835,526.008118 717.000000,526.000000 717.003784,525.997070
z"
        />
        <path
          fill="#88A6B8"
          opacity="1.000000"
          stroke="none"
          d="
M491.426147,510.541473
	C491.611145,508.999603 492.932739,509.012787 494.658020,509.064423
	C494.409546,510.562653 492.868134,509.988342 491.426147,510.541473
z"
        />
        <path
          fill="#88A6B8"
          opacity="1.000000"
          stroke="none"
          d="
M554.310303,507.684875
	C554.433960,508.162384 554.340698,508.365387 554.131409,508.883118
	C551.691956,509.192139 549.757812,508.010742 547.382935,506.999817
	C546.693909,506.779358 546.350891,506.675018 545.740723,506.541748
	C545.005371,505.867310 544.537048,505.221771 544.018799,504.278137
	C546.374817,503.666687 548.036743,505.655365 550.595032,506.041992
	C552.005188,506.524017 553.049316,506.967194 554.310303,507.684875
z"
        />
        <path
          fill="#403D45"
          opacity="1.000000"
          stroke="none"
          d="
M539.278931,500.339569
	C539.479126,500.244232 539.818665,500.412384 540.343140,500.799713
	C540.158203,500.880280 539.788269,500.741638 539.278931,500.339569
z"
        />
        <path
          fill="#4E5963"
          opacity="1.000000"
          stroke="none"
          d="
M555.197266,502.429138
	C555.147583,502.583954 555.032959,502.634186 554.918274,502.684448
	C554.989624,502.564484 555.060974,502.444519 555.197266,502.429138
z"
        />
      </svg>
    );
  }
}
