import React, { Component } from "react";

export default class EnclaveImg extends Component {
  render() {
    // const theme = this.props.theme;
    return (
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="100%"
        viewBox="0 0 2158 753"
        enable-background="new 0 0 2158 753"
        xmlSpace="preserve"
      >
        <path
          fill="#E5EAEC"
          opacity="1.000000"
          stroke="none"
          d="
M2158.810059,1.857833
	C2159.000000,250.972641 2158.989502,499.945282 2159.094482,748.917847
	C2159.096191,752.928650 2158.536377,754.128235 2154.083252,754.127380
	C1437.995239,753.994385 721.907288,753.996094 5.819361,754.100220
	C1.911514,754.100769 0.877965,753.523315 0.880227,749.320312
	C1.014161,500.514191 1.000000,251.707993 1.000000,2.450905
	C1.268164,1.827670 1.506695,1.621121 1.857796,1.190177
	C720.366028,1.000000 1438.732056,1.000000 2157.549072,1.000000
	C2158.172607,1.268000 2158.379150,1.506556 2158.810059,1.857833
z"
        />
        <path
          fill="#F4F4F4"
          opacity="1.000000"
          stroke="none"
          d="
M1.500000,1.000000
	C1.884810,1.312922 1.691597,1.566354 1.210181,1.880149
	C1.000000,1.666667 1.000000,1.333333 1.500000,1.000000
z"
        />
        <path
          fill="#F4F4F4"
          opacity="1.000000"
          stroke="none"
          d="
M2159.000000,1.500000
	C2158.696045,1.872053 2158.439697,1.680320 2158.115723,1.212401
	C2158.333252,1.000000 2158.666748,1.000000 2159.000000,1.500000
z"
        />
        <path
          fill="#FFFFFF"
          opacity="1.000000"
          stroke="none"
          d="
M153.000000,733.978027
	C110.667900,733.978027 68.835464,733.900635 27.004160,734.083130
	C22.178633,734.104187 20.899565,732.728882 20.901863,727.945740
	C21.013973,494.619354 21.014656,261.292877 20.898651,27.966486
	C20.896235,23.105782 22.310398,21.895020 27.058628,21.895700
	C729.037964,21.996370 1431.017212,21.996565 2132.996582,21.893087
	C2137.816406,21.892378 2139.101074,23.234440 2139.098633,28.021479
	C2138.985107,260.846649 2139.002197,493.671844 2138.983398,726.497070
	C2138.982910,734.520020 2140.066650,733.975281 2131.444824,733.975281
	C1472.129883,733.978271 812.814941,733.978027 153.000000,733.978027
z"
        />
        <path
          fill="#F79F38"
          opacity="1.000000"
          stroke="none"
          d="
M1472.711182,104.428818
	C1474.987427,103.896255 1476.973877,103.690582 1478.960327,103.690422
	C1670.450928,103.675591 1861.941528,103.676300 2053.432129,103.676460
	C2060.989502,103.676468 2061.042480,103.678368 2061.043457,111.214264
	C2061.055176,221.866547 2061.052002,332.518829 2061.051514,443.171112
	C2061.051270,510.329437 2061.049561,577.487793 2061.047363,644.646118
	C2061.047119,651.654297 2061.043213,651.671753 2054.287842,651.671936
	C1908.639771,651.675598 1762.991821,651.675354 1617.343750,651.675232
	C1571.516113,651.675171 1525.688477,651.675049 1479.860840,651.666992
	C1477.861816,651.666626 1475.862915,651.587585 1473.418457,651.395020
	C1471.688965,649.768494 1471.773926,648.243164 1473.211670,646.384766
	C1476.117432,644.861938 1478.819092,645.074829 1481.470337,645.074097
	C1554.623047,645.054932 1627.780151,644.606140 1700.925903,645.322144
	C1724.563721,645.553528 1748.230591,646.425537 1771.855957,645.931824
	C1863.653198,644.013367 1955.454712,645.653320 2047.251221,645.056152
	C2055.010986,645.005676 2055.050781,645.006958 2055.051514,637.263184
	C2055.063721,464.129608 2055.062256,290.996033 2055.052246,117.862457
	C2055.052002,109.970459 2055.017090,109.945778 2046.362427,109.945450
	C1859.231567,109.938278 1672.100586,109.944191 1484.969727,109.921371
	C1481.327637,109.920929 1477.627808,110.380180 1473.632080,108.846962
	C1472.046021,107.529205 1471.944214,106.250023 1472.711182,104.428818
z"
        />
        <path
          fill="#F8F7F7"
          opacity="1.000000"
          stroke="none"
          d="
M263.845215,572.521790
	C257.442596,576.573425 251.858490,570.513306 245.790726,571.933350
	C243.761124,572.408325 241.419922,571.606323 239.973618,573.893066
	C239.364243,574.856445 238.466873,575.036560 237.420029,574.342529
	C231.852493,570.651367 226.242859,570.679138 220.654602,574.286072
	C219.855011,574.802124 219.214447,574.876587 218.407639,574.342224
	C212.841324,570.655823 207.236893,570.667542 201.657440,574.299988
	C200.850601,574.825256 200.200684,574.859070 199.396103,574.358887
	C193.762802,570.856812 188.135544,570.407471 182.477814,574.365356
	C182.044907,574.668152 181.077179,574.758606 180.683823,574.482971
	C174.949036,570.464172 169.250061,570.631775 163.468094,574.366821
	C163.015060,574.659424 162.057495,574.759888 161.673126,574.481689
	C155.859940,570.274170 150.105286,570.968933 144.318878,574.501587
	C143.394867,575.065674 142.432556,574.970459 141.500610,574.393555
	C135.874100,570.910583 130.243378,570.333313 124.556572,574.306885
	C124.104546,574.622742 122.949219,574.775208 122.792702,574.545654
	C120.028679,570.490234 115.455826,571.914856 111.794601,571.172852
	C97.637558,568.303711 89.176300,559.341736 88.022552,545.006226
	C87.782600,542.024780 87.941849,539.022095 84.450127,537.501282
	C83.144478,536.932556 83.158867,536.049805 84.627823,534.994690
	C88.028717,532.551758 88.166618,520.359253 84.833382,518.068115
	C83.540848,517.179688 83.118744,516.798279 84.247879,515.541077
	C87.781441,511.606567 88.226830,502.958527 85.072556,498.856720
	C84.517708,498.135193 83.846672,497.305542 84.541832,496.756500
	C89.542351,492.807251 87.471367,487.035675 86.502502,482.959900
	C85.665833,479.440247 85.197815,477.321899 86.710281,473.832489
	C88.047791,470.746704 88.205040,465.975311 86.684052,463.119446
	C84.748108,459.484314 85.863899,457.362457 86.569969,453.978577
	C87.377037,450.110626 89.445702,444.694794 84.471596,441.188049
	C84.244690,441.028076 84.241264,439.996094 84.472717,439.822723
	C89.421379,436.115387 87.271240,430.656860 86.609352,426.504120
	C86.036652,422.910858 84.760239,420.239624 86.734055,416.417511
	C88.092758,413.786499 88.001633,409.431732 86.749802,406.666077
	C85.054909,402.921600 85.842072,400.273682 86.566757,396.580261
	C87.358490,392.545105 89.504211,386.999573 84.535919,383.263702
	C84.040047,382.890839 84.142052,382.016266 84.726891,381.502441
	C89.511368,377.298889 85.974754,371.829285 86.895126,367.033630
	C87.164162,365.631775 85.705154,365.284637 84.709473,364.682739
	C83.235443,363.791687 82.979202,363.135406 84.819679,361.923431
	C88.209953,359.690796 88.049637,347.458832 84.635406,345.007050
	C83.157654,343.945862 83.382072,343.457642 84.411560,342.416840
	C87.629074,339.163940 88.223549,329.422668 85.323662,326.001251
	C84.623116,325.174683 83.710983,324.465912 84.683167,323.600433
	C89.440674,319.364990 86.000175,313.919250 86.924927,309.132751
	C87.085823,308.299988 86.536980,307.542908 85.695724,307.152954
	C83.180183,305.986755 82.019081,304.924225 85.722443,303.711609
	C87.348747,303.179108 86.965927,301.530609 86.983559,300.247925
	C87.027077,297.082672 87.026970,293.916016 86.983604,290.750763
	C86.966034,289.467896 87.347916,287.818878 85.721291,287.287811
	C81.999718,286.072815 83.390373,285.301727 85.641815,283.766174
	C87.925827,282.208435 87.643311,271.552856 85.408188,269.049957
	C84.696754,268.253326 83.681480,267.518402 84.621971,266.678741
	C89.358383,262.449860 87.187302,256.838501 86.528328,252.300354
	C86.121704,249.500046 84.223862,248.136551 86.561539,244.588272
	C88.475952,241.682434 86.961975,236.514725 86.957504,232.349716
	C86.956024,230.963791 86.181984,230.293610 84.875626,229.978073
	C83.256973,229.587128 82.868889,228.312820 84.296333,227.620132
	C88.359406,225.648468 87.569023,221.786682 87.909668,218.600739
	C88.923721,209.116531 91.887405,201.019730 100.218063,195.243210
	C103.284103,193.117203 104.665726,187.975784 109.697273,191.868423
	C121.730209,188.666046 134.010010,190.098709 146.192459,190.041718
	C174.184616,189.910751 202.177811,190.000519 230.170578,190.000595
	C238.432236,190.000610 246.693893,189.995514 254.955551,190.003586
	C256.954437,190.005539 258.964050,190.125916 261.206177,189.075806
	C264.558167,191.384521 263.886658,194.359741 262.649902,197.800644
	C259.466675,200.471985 256.028198,199.861542 252.764191,199.865173
	C211.223587,199.911469 169.682907,199.869202 128.142319,199.918335
	C121.689888,199.925964 115.225342,200.134949 108.704346,202.685760
	C106.784500,204.629623 104.212624,203.851532 102.329010,205.618896
	C101.067764,207.592361 101.628189,209.686203 100.407150,211.744980
	C96.855034,217.698792 98.183670,224.098724 97.021423,230.059525
	C96.706978,231.672180 96.902748,233.380356 96.902695,235.045349
	C96.899162,331.938110 96.875824,428.830902 96.948006,525.723572
	C96.953804,533.502014 97.311859,541.311707 99.699234,549.260498
	C100.746071,550.515808 101.141052,551.587891 101.544525,553.092896
	C102.686066,555.208252 104.712570,555.506775 106.502441,556.895752
	C112.495720,560.517578 118.693977,561.154541 125.116318,561.146912
	C167.889786,561.096008 210.663361,561.111450 253.436890,561.133850
	C256.415314,561.135437 259.446014,560.821899 262.722961,562.305542
	C265.314026,565.558960 265.074951,568.793518 263.845215,572.521790
z"
        />
        <path
          fill="#F58B2A"
          opacity="1.000000"
          stroke="none"
          d="
M1472.536377,103.937630
	C1472.995972,104.981415 1472.991089,105.956856 1472.981445,107.298073
	C1471.742554,109.669205 1469.662476,109.688904 1467.685059,109.851730
	C1465.526245,110.029495 1463.355835,109.944496 1461.190308,109.944511
	C1203.964600,109.946228 946.738831,109.947731 689.513062,109.928856
	C686.034607,109.928604 682.502625,110.344894 678.666138,108.866524
	C677.053101,107.555466 676.953125,106.272667 677.729736,104.445786
	C679.518372,103.961845 681.013367,103.845444 682.508362,103.845398
	C945.030029,103.836876 1207.551758,103.836655 1470.073364,103.837524
	C1470.739502,103.837524 1471.405640,103.858238 1472.536377,103.937630
z"
        />
        <path
          fill="#F27643"
          opacity="1.000000"
          stroke="none"
          d="
M263.628113,573.412598
	C263.470703,569.925598 263.367035,566.909729 263.181274,563.446533
	C261.313965,561.109253 261.182861,558.957275 262.361389,556.228882
	C264.030762,554.646973 265.606018,554.605591 267.689697,555.338135
	C269.562164,557.131470 269.713318,559.034119 269.562012,561.489258
	C269.759430,563.861389 269.909088,565.758362 269.595886,568.138123
	C270.309692,581.097839 269.802338,593.587341 269.910156,606.071716
	C270.005066,617.063538 269.868988,628.056885 269.960266,639.048950
	C270.005707,644.518311 270.477417,644.997986 276.030090,645.034058
	C291.019470,645.131531 306.009460,645.068909 320.999207,645.070068
	C377.627289,645.074646 434.255432,645.022888 490.883453,645.085266
	C549.973755,645.150330 609.063904,644.984436 668.154114,645.084045
	C671.307190,645.089355 674.492126,644.799988 678.043396,645.934448
	C680.153137,647.310974 680.239075,648.864441 679.259399,650.974609
	C673.547424,651.419189 668.168213,651.486816 662.320679,651.468872
	C661.475647,651.471985 661.093872,651.509644 660.423706,651.439331
	C654.847412,651.391174 649.554504,651.399719 643.874451,651.307495
	C643.487244,651.206787 643.008179,651.185181 643.008179,651.185181
	C643.008179,651.185181 642.603577,651.442627 642.364014,651.427612
	C622.302795,651.489502 602.481140,651.626221 582.659424,651.633057
	C494.181732,651.663574 405.704071,651.658325 317.226379,651.662170
	C301.230713,651.662903 285.233429,651.516968 269.240448,651.719849
	C264.760406,651.776672 263.607269,650.146484 263.635773,645.871582
	C263.795898,621.876587 263.694214,597.879822 263.628113,573.412598
z"
        />
        <path
          fill="#F58B2A"
          opacity="1.000000"
          stroke="none"
          d="
M1472.759033,647.069702
	C1472.981934,648.553528 1472.984375,649.590820 1472.979980,650.936462
	C1471.860962,651.329651 1470.748901,651.414429 1469.224976,651.406128
	C1468.459473,651.450439 1468.093140,651.508606 1467.446411,651.401184
	C1461.713501,651.376953 1456.248535,651.439270 1450.317627,651.401733
	C1449.851685,651.301880 1449.505371,651.549927 1449.322021,651.441650
	C1443.010254,651.397217 1436.881958,651.461121 1430.285156,651.425537
	C1429.459961,651.452515 1429.093018,651.509827 1428.447266,651.399109
	C1422.704102,651.377258 1417.228271,651.443481 1411.283203,651.414001
	C1410.459473,651.452026 1410.093018,651.508545 1409.446655,651.402771
	C1403.852539,651.338440 1398.526367,651.359314 1392.815430,651.280212
	C1392.222290,651.230713 1392.013916,651.281250 1391.483887,651.333252
	C1385.025879,651.398804 1378.889526,651.462830 1372.284302,651.428284
	C1371.459106,651.453552 1371.092896,651.509460 1370.448730,651.399780
	C1365.037964,651.375610 1359.894165,651.438904 1354.280640,651.408325
	C1353.457886,651.449951 1353.092529,651.507751 1352.447510,651.402466
	C1346.844482,651.349304 1341.509033,651.381592 1335.803955,651.298706
	C1335.225342,651.232422 1335.016113,651.281494 1334.484863,651.335327
	C1328.024902,651.401733 1321.886963,651.463379 1315.280151,651.426147
	C1314.456787,651.452698 1314.092529,651.509583 1313.451172,651.398804
	C1307.706299,651.376831 1302.226807,651.442749 1296.277832,651.412476
	C1295.456299,651.450378 1295.092407,651.507446 1294.450317,651.401611
	C1288.715576,651.377747 1283.246948,651.439758 1277.312012,651.401489
	C1276.845825,651.301208 1276.506104,651.548218 1276.326294,651.439819
	C1270.006226,651.395874 1263.865967,651.460266 1257.254272,651.428955
	C1256.226440,651.534058 1255.680054,651.502197 1254.863281,651.222046
	C1249.277100,651.289429 1243.970459,651.396912 1238.235229,651.410767
	C1237.455688,651.451233 1237.092651,651.508057 1236.451904,651.401917
	C1230.716431,651.377747 1225.246582,651.438904 1218.962036,651.417725
	C1212.012939,651.398621 1205.878418,651.462036 1199.274780,651.425354
	C1198.454224,651.453796 1198.091675,651.507446 1197.454834,651.397461
	C1192.041504,651.373169 1186.891479,651.437683 1181.271484,651.407837
	C1180.452881,651.449280 1180.091919,651.507080 1179.454346,651.401123
	C1173.736694,651.379883 1168.283081,651.444397 1162.364258,651.420776
	C1161.899292,651.332520 1161.500244,651.557922 1161.291016,651.464478
	C1154.991333,651.426575 1148.900757,651.482056 1142.343262,651.451416
	C1141.491333,651.467407 1141.100342,651.513000 1140.414795,651.417603
	C1134.687256,651.400391 1129.246826,651.458557 1123.338867,651.433472
	C1122.488647,651.462097 1122.098389,651.513306 1121.411133,651.422119
	C1115.839355,651.363037 1110.555786,651.376343 1104.843506,651.306274
	C1104.197876,651.269531 1103.981079,651.316284 1103.431641,651.371033
	C1097.002930,651.430237 1090.906738,651.481445 1084.343628,651.446350
	C1083.492065,651.468750 1083.099976,651.510193 1082.413208,651.417603
	C1077.018311,651.398621 1071.911255,651.455383 1066.337158,651.428650
	C1065.487793,651.460449 1065.097778,651.513550 1064.410400,651.421204
	C1058.692261,651.401855 1053.262329,651.455750 1047.367920,651.421204
	C1046.903320,651.332764 1046.500610,651.558289 1046.289795,651.464355
	C1039.670776,651.436218 1033.262573,651.501953 1026.391602,651.475708
	C1025.929077,651.383728 1025.502075,651.559143 1025.299194,651.448975
	C1019.666870,651.398254 1014.237427,651.457703 1008.340698,651.433105
	C1007.489868,651.463013 1007.098267,651.511597 1006.409302,651.421631
	C1000.813660,651.362915 995.507202,651.377197 989.812988,651.305298
	C989.206360,651.266113 988.987488,651.313110 988.432861,651.368958
	C986.377197,651.416626 984.657288,651.455444 982.520752,651.385742
	C980.680176,649.873047 980.793091,648.347351 982.140747,646.446289
	C985.023987,644.866272 987.726807,645.083130 990.376343,645.081238
	C1023.689331,645.057434 1057.002930,644.950317 1090.314819,645.145935
	C1098.951660,645.196655 1107.575806,646.543457 1116.241455,646.023438
	C1150.687134,643.956360 1185.164551,645.560486 1219.623901,645.084595
	C1229.775146,644.944397 1239.867188,646.425354 1250.050415,646.094116
	C1320.977295,643.786194 1391.926636,645.660034 1462.864014,645.096741
	C1466.136108,645.070740 1469.576172,644.473022 1472.759033,647.069702
z"
        />
        <path
          fill="#F27643"
          opacity="1.000000"
          stroke="none"
          d="
M677.558594,103.939804
	C678.022888,104.989441 678.022461,105.954971 678.024780,107.282593
	C676.815857,109.684319 674.724121,109.685417 672.752258,109.838692
	C670.260925,110.032341 667.757446,109.935410 665.259155,109.935448
	C536.331970,109.937538 407.404724,109.934547 278.477509,109.941467
	C267.008698,109.942085 267.989899,109.341499 267.939636,120.543541
	C267.832031,144.527878 268.137695,168.513107 267.709290,192.980713
	C267.735474,196.396042 268.186127,199.353317 266.725861,202.607788
	C265.398651,204.033554 264.146515,204.185165 262.346405,203.611511
	C260.656219,202.044754 260.508636,200.508743 261.337402,198.410370
	C261.629761,195.278290 261.697479,192.576965 261.677673,189.431702
	C261.662384,162.494476 261.812683,136.000900 261.725098,109.508118
	C261.711517,105.395683 262.431122,103.698273 267.122864,103.703232
	C403.779755,103.847656 540.436829,103.839439 677.558594,103.939804
z"
        />
        <path
          fill="#F4904E"
          opacity="1.000000"
          stroke="none"
          d="
M981.800781,647.067749
	C982.028259,648.562683 982.031372,649.610291 982.069336,650.967529
	C971.309265,652.343506 960.483887,651.707092 949.678284,651.717163
	C860.060425,651.801208 770.442322,651.754395 680.824341,651.743713
	C680.492310,651.743652 680.160278,651.692139 679.377441,651.508057
	C678.947205,650.287598 678.967773,649.223755 678.984741,647.837952
	C680.984192,644.361328 684.249756,645.121948 687.105774,645.109863
	C709.264954,645.015686 731.424561,645.062439 753.584045,645.059998
	C772.078064,645.057922 790.573975,645.215942 809.065186,644.988037
	C817.249084,644.887146 825.320374,646.429688 833.501404,646.044495
	C879.953247,643.857300 926.434204,645.617737 972.898865,645.087769
	C975.852539,645.054077 978.946350,644.638672 981.800781,647.067749
z"
        />
        <path
          fill="#F79F38"
          opacity="1.000000"
          stroke="none"
          d="
M1467.439453,651.454651
	C1467.623291,651.253113 1468.068115,651.191528 1468.662964,651.221436
	C1468.442261,651.406860 1468.071289,651.500732 1467.439453,651.454651
z"
        />
        <path
          fill="#F79F38"
          opacity="1.000000"
          stroke="none"
          d="
M1409.439575,651.456848
	C1409.623901,651.256042 1410.069092,651.194519 1410.664185,651.225647
	C1410.442871,651.410889 1410.071655,651.503540 1409.439575,651.456848
z"
        />
        <path
          fill="#F79F38"
          opacity="1.000000"
          stroke="none"
          d="
M1428.439941,651.451538
	C1428.626465,651.252869 1429.072998,651.194702 1429.668091,651.231262
	C1429.444458,651.414673 1429.072266,651.503357 1428.439941,651.451538
z"
        />
        <path
          fill="#F79F38"
          opacity="1.000000"
          stroke="none"
          d="
M1370.441162,651.453430
	C1370.627930,651.254639 1371.073853,651.196838 1371.667725,651.234375
	C1371.443848,651.417969 1371.072144,651.506226 1370.441162,651.453430
z"
        />
        <path
          fill="#F79F38"
          opacity="1.000000"
          stroke="none"
          d="
M1352.440430,651.457031
	C1352.623901,651.255066 1353.067749,651.193115 1353.661377,651.222778
	C1353.440918,651.408569 1353.070923,651.502808 1352.440430,651.457031
z"
        />
        <path
          fill="#F79F38"
          opacity="1.000000"
          stroke="none"
          d="
M1313.443604,651.451782
	C1313.629517,651.253235 1314.073242,651.195496 1314.664062,651.232483
	C1314.441284,651.415649 1314.071411,651.504150 1313.443604,651.451782
z"
        />
        <path
          fill="#F79F38"
          opacity="1.000000"
          stroke="none"
          d="
M1294.443359,651.454834
	C1294.626587,651.254517 1295.068726,651.193359 1295.659668,651.224243
	C1295.439575,651.408875 1295.071045,651.501465 1294.443359,651.454834
z"
        />
        <path
          fill="#F79F38"
          opacity="1.000000"
          stroke="none"
          d="
M1254.833740,651.350586
	C1255.146240,651.157959 1255.708740,651.134033 1256.526978,651.221741
	C1256.216431,651.395325 1255.649902,651.457336 1254.833740,651.350586
z"
        />
        <path
          fill="#F79F38"
          opacity="1.000000"
          stroke="none"
          d="
M1236.444824,651.456177
	C1236.627686,651.255310 1237.069092,651.194153 1237.658569,651.225037
	C1237.438843,651.410034 1237.071045,651.502930 1236.444824,651.456177
z"
        />
        <path
          fill="#F79F38"
          opacity="1.000000"
          stroke="none"
          d="
M1197.447388,651.449402
	C1197.632202,651.251404 1198.072998,651.194214 1198.659668,651.231079
	C1198.438232,651.413574 1198.070801,651.501831 1197.447388,651.449402
z"
        />
        <path
          fill="#F79F38"
          opacity="1.000000"
          stroke="none"
          d="
M1179.447266,651.455139
	C1179.629028,651.253906 1180.067749,651.192566 1180.653809,651.222290
	C1180.435669,651.407288 1180.069824,651.501160 1179.447266,651.455139
z"
        />
        <path
          fill="#F4904E"
          opacity="1.000000"
          stroke="none"
          d="
M1140.405762,651.487305
	C1140.606812,651.276306 1141.085938,651.210449 1141.720703,651.254883
	C1141.478760,651.454285 1141.081421,651.543335 1140.405762,651.487305
z"
        />
        <path
          fill="#F4904E"
          opacity="1.000000"
          stroke="none"
          d="
M1082.404297,651.486206
	C1082.604858,651.274902 1083.084473,651.207947 1083.720337,651.250488
	C1083.478882,651.450256 1083.081055,651.540466 1082.404297,651.486206
z"
        />
        <path
          fill="#F4904E"
          opacity="1.000000"
          stroke="none"
          d="
M1064.402100,651.491394
	C1064.599365,651.276245 1065.076782,651.204590 1065.711914,651.239014
	C1065.473999,651.441711 1065.078125,651.538269 1064.402100,651.491394
z"
        />
        <path
          fill="#F4904E"
          opacity="1.000000"
          stroke="none"
          d="
M1006.401001,651.491577
	C1006.599182,651.277283 1007.078186,651.205933 1007.715332,651.241821
	C1007.476196,651.444214 1007.079041,651.539368 1006.401001,651.491577
z"
        />
        <path
          fill="#F4904E"
          opacity="1.000000"
          stroke="none"
          d="
M1121.402832,651.492981
	C1121.600586,651.278687 1122.078247,651.207581 1122.713623,651.243347
	C1122.475098,651.445496 1122.078979,651.540833 1121.402832,651.492981
z"
        />
        <path
          fill="#F4904E"
          opacity="1.000000"
          stroke="none"
          d="
M660.415527,651.515259
	C660.608032,651.312378 661.075745,651.242126 661.697937,651.277588
	C661.465210,651.471558 661.078003,651.559692 660.415527,651.515259
z"
        />
        <path
          fill="#F4904E"
          opacity="1.000000"
          stroke="none"
          d="
M988.318604,651.487549
	C988.353333,651.178101 988.643555,651.088501 989.196533,651.164062
	C989.194824,651.496704 988.899719,651.622742 988.318604,651.487549
z"
        />
        <path
          fill="#F79F38"
          opacity="1.000000"
          stroke="none"
          d="
M1449.321167,651.423218
	C1449.295288,651.185547 1449.477051,651.140015 1449.767822,651.249390
	C1449.851685,651.301880 1449.503784,651.513000 1449.321167,651.423218
z"
        />
        <path
          fill="#F79F38"
          opacity="1.000000"
          stroke="none"
          d="
M1391.372803,651.444580
	C1391.413696,651.166626 1391.690552,651.088867 1392.211670,651.140808
	C1392.212769,651.451538 1391.930420,651.576233 1391.372803,651.444580
z"
        />
        <path
          fill="#F4904E"
          opacity="1.000000"
          stroke="none"
          d="
M642.356323,651.508545
	C642.386841,651.213623 642.682617,651.119019 643.249512,651.167664
	C643.187561,651.339355 642.887878,651.471985 642.356323,651.508545
z"
        />
        <path
          fill="#F79F38"
          opacity="1.000000"
          stroke="none"
          d="
M1334.374023,651.448975
	C1334.414185,651.169922 1334.691650,651.091370 1335.214844,651.143921
	C1335.215820,651.454590 1334.932739,651.579407 1334.374023,651.448975
z"
        />
        <path
          fill="#F79F38"
          opacity="1.000000"
          stroke="none"
          d="
M1276.325439,651.421265
	C1276.299683,651.185486 1276.478149,651.140625 1276.763916,651.249084
	C1276.845825,651.301208 1276.504517,651.511108 1276.325439,651.421265
z"
        />
        <path
          fill="#F4904E"
          opacity="1.000000"
          stroke="none"
          d="
M1025.299072,651.449585
	C1025.291504,651.162476 1025.510864,651.123657 1025.841797,651.303040
	C1025.929077,651.383728 1025.501953,651.560303 1025.299072,651.449585
z"
        />
        <path
          fill="#F4904E"
          opacity="1.000000"
          stroke="none"
          d="
M1046.289673,651.459717
	C1046.254150,651.180115 1046.465820,651.121399 1046.808716,651.263489
	C1046.903320,651.332764 1046.500244,651.549072 1046.289673,651.459717
z"
        />
        <path
          fill="#F79F38"
          opacity="1.000000"
          stroke="none"
          d="
M1218.325928,651.424500
	C1218.299072,651.187927 1218.476562,651.142334 1218.761475,651.250488
	C1218.843140,651.302673 1218.504517,651.513794 1218.325928,651.424500
z"
        />
        <path
          fill="#F4904E"
          opacity="1.000000"
          stroke="none"
          d="
M1161.290771,651.460327
	C1161.255005,651.182068 1161.464966,651.123474 1161.805420,651.263916
	C1161.899292,651.332520 1161.499756,651.549500 1161.290771,651.460327
z"
        />
        <path
          fill="#F4904E"
          opacity="1.000000"
          stroke="none"
          d="
M1103.318237,651.488831
	C1103.352661,651.180603 1103.640381,651.091431 1104.188477,651.167175
	C1104.186890,651.498962 1103.894409,651.624207 1103.318237,651.488831
z"
        />
        <path
          fill="#FEFEFE"
          opacity="1.000000"
          stroke="none"
          d="
M982.490601,647.484009
	C979.866089,647.437012 977.706848,647.288696 975.547791,647.291016
	C925.441650,647.345581 875.335571,647.458801 825.229431,647.472107
	C776.788574,647.485046 728.347778,647.398254 679.443970,647.435669
	C676.651062,647.468079 674.321045,647.378296 671.991028,647.378113
	C539.408813,647.368713 406.826599,647.367126 274.244385,647.363037
	C267.887146,647.362793 267.881897,647.355530 267.883881,640.895935
	C267.891296,616.910828 267.902496,592.925659 267.953766,568.473877
	C270.802094,565.485779 274.274902,566.141479 277.541229,566.138855
	C329.358734,566.097656 381.176270,566.110046 432.993805,566.115112
	C437.329987,566.115540 441.631714,566.053650 445.859924,564.801819
	C453.103027,562.657532 457.966583,558.140015 460.482819,550.991638
	C462.560822,545.088196 463.145172,539.031555 463.141266,532.810181
	C463.094360,458.499512 463.110565,384.188812 463.110748,309.878143
	C463.110809,283.219604 463.110596,256.561066 463.111328,229.902512
	C463.111389,227.403183 463.157074,224.900955 462.999237,222.406616
	C461.924591,205.422134 453.545685,197.059830 436.538696,196.005981
	C433.711517,195.830795 430.875702,195.888580 428.043152,195.888580
	C377.391937,195.888718 326.740723,195.899582 276.089539,195.864929
	C272.966156,195.862793 269.723877,196.397705 266.456085,194.305267
	C266.025940,166.346268 265.975372,138.707886 265.853516,111.069794
	C265.843353,108.760391 266.526367,107.645599 268.962708,107.866539
	C270.283844,107.986343 271.625061,107.883087 272.957214,107.883072
	C405.507050,107.881226 538.056885,107.879868 670.606750,107.875816
	C672.771179,107.875755 674.935669,107.842865 677.563843,107.734985
	C679.521851,107.713020 681.016052,107.841209 682.510315,107.841263
	C944.858459,107.850891 1207.206665,107.851425 1469.554810,107.850525
	C1470.387451,107.850517 1471.220215,107.824898 1472.514893,107.737549
	C1475.303345,107.744858 1477.630005,107.896629 1479.956543,107.896774
	C1670.116333,107.908211 1860.276001,107.903297 2050.435791,107.924911
	C2058.030029,107.925781 2056.934082,106.953224 2056.935059,114.624100
	C2056.957764,289.951080 2056.957275,465.278076 2056.936035,640.605042
	C2056.935059,648.087341 2058.037598,647.311096 2050.381104,647.313049
	C1965.717407,647.334412 1881.053833,647.327942 1796.390137,647.328247
	C1691.063232,647.328674 1585.736328,647.327942 1480.409424,647.330933
	C1478.243164,647.330994 1476.076782,647.370544 1473.445068,647.453979
	C1441.676636,647.481445 1410.373657,647.421692 1379.070801,647.416626
	C1295.484863,647.403015 1211.898804,647.408447 1128.312866,647.415161
	C1079.860596,647.419006 1031.408203,647.439758 982.490601,647.484009
z"
        />
        <path
          fill="#F4F4F4"
          opacity="1.000000"
          stroke="none"
          d="
M100.214554,550.680908
	C96.236984,549.880249 96.784790,546.375305 96.310410,543.898132
	C95.411957,539.206238 95.051468,534.347412 95.048302,529.557495
	C94.982681,430.258026 94.968513,330.958496 95.081200,231.659088
	C95.087349,226.240692 96.273972,220.822037 96.938950,215.406921
	C97.143967,213.737335 97.477875,212.098083 99.488632,211.079987
	C102.016495,212.743484 101.432808,215.236786 101.441277,217.486374
	C101.503929,234.128510 101.473572,250.770935 101.473679,267.413269
	C101.474243,358.946106 101.479889,450.478943 101.448593,542.011780
	C101.447639,544.801758 101.995415,547.688660 100.214554,550.680908
z"
        />
        <path
          fill="#E5EAEC"
          opacity="1.000000"
          stroke="none"
          d="
M262.944275,555.788452
	C263.257629,558.138794 263.266815,560.145752 263.187561,562.576050
	C215.962845,562.997925 168.826447,563.039246 121.690338,562.941528
	C115.735809,562.929199 109.642433,562.602966 105.151901,557.133179
	C105.561340,554.271179 107.708221,554.546814 109.395187,554.608948
	C120.150383,555.005554 130.907181,554.583313 141.660797,554.634888
	C151.256577,554.680786 160.869110,554.587646 170.468399,554.607971
	C190.160828,554.649658 209.854889,554.372192 229.543243,554.617676
	C239.787140,554.745300 250.033264,554.131409 260.271423,554.780090
	C261.117493,554.833740 261.910828,555.008362 262.944275,555.788452
z"
        />
        <path
          fill="#F4F4F4"
          opacity="1.000000"
          stroke="none"
          d="
M261.122955,197.984711
	C261.626617,199.349976 261.691223,200.720306 261.751648,202.503601
	C261.518372,203.160919 261.289246,203.405228 260.610107,203.871201
	C258.033905,204.644897 255.861191,204.462952 253.706589,204.463577
	C207.483688,204.476959 161.260788,204.480804 115.037895,204.453018
	C112.573601,204.451538 110.031235,204.876160 107.339615,203.300598
	C107.703773,200.148117 110.241241,199.561768 112.384735,199.206818
	C116.620369,198.505432 120.948975,198.054825 125.240112,198.047119
	C170.387924,197.965927 215.535950,197.992798 261.122955,197.984711
z"
        />
        <path
          fill="#C3CDCE"
          opacity="1.000000"
          stroke="none"
          d="
M263.619171,556.066467
	C246.250046,556.095276 229.251648,556.041565 212.253235,556.026550
	C191.424652,556.008118 170.595932,555.984741 149.767456,556.034912
	C135.066971,556.070251 120.366776,556.224426 105.341461,556.565552
	C103.479355,556.375244 101.942245,555.944946 100.240547,555.216309
	C100.401535,554.329590 100.803841,553.799377 101.717331,553.075806
	C104.723053,551.872742 107.411469,552.078308 110.064972,552.077820
	C220.654129,552.059814 331.243286,552.062561 441.832458,552.071533
	C444.160767,552.071716 446.500580,551.975830 449.264038,552.692017
	C451.240845,553.588562 451.660370,554.567383 450.061707,556.236450
	C449.414429,556.701111 449.133545,556.850159 448.400574,557.132019
	C434.497833,558.536682 421.010406,557.865051 407.541901,557.921204
	C391.064941,557.989990 374.585724,557.875183 358.108185,557.948364
	C343.302673,558.014160 328.498077,557.831665 313.691589,557.978760
	C302.213867,558.092773 290.732330,557.817749 279.252289,558.024780
	C275.600861,558.090637 271.914154,558.369873 268.116150,556.373169
	C266.486664,556.085815 265.238281,556.043457 263.619171,556.066467
z"
        />
        <path
          fill="#BCB7AB"
          opacity="1.000000"
          stroke="none"
          d="
M101.360489,553.542114
	C100.912613,554.220093 100.732117,554.478088 100.284378,554.806458
	C100.030785,553.875000 99.985626,552.832092 99.888176,551.399719
	C99.885658,550.180359 99.979065,549.350464 99.979019,548.520508
	C99.972885,436.321564 99.959923,324.122620 99.891174,211.456696
	C99.901390,208.980667 99.967720,206.971588 100.073723,204.533966
	C100.416954,204.023376 100.720512,203.941345 101.363632,204.189423
	C103.456406,208.462662 102.725716,212.585281 102.602524,217.148682
	C103.091942,219.771896 102.936485,221.944733 102.936638,224.105484
	C102.944054,330.533081 102.948502,436.960663 102.918121,543.388245
	C102.917183,546.675476 103.490013,550.082886 101.360489,553.542114
z"
        />
        <path
          fill="#C3CDCE"
          opacity="1.000000"
          stroke="none"
          d="
M101.758354,204.620392
	C101.162117,204.263885 100.954262,204.218964 100.429901,204.139740
	C101.834862,202.125793 104.155190,203.414490 106.578537,202.969803
	C158.016968,202.966858 209.039734,202.991058 260.530823,203.031677
	C261.055054,203.367416 261.110962,203.686752 260.826630,204.354279
	C256.192200,206.705826 251.625229,205.709198 247.180252,205.810745
	C242.693222,205.913239 238.187347,206.137650 233.215973,205.616058
	C230.751785,205.949585 228.756378,205.937881 226.759201,205.937424
	C188.168533,205.928375 149.577866,205.943649 110.987213,205.914551
	C108.017525,205.912308 104.977226,206.293961 101.758354,204.620392
z"
        />
        <path
          fill="#F4F4F4"
          opacity="1.000000"
          stroke="none"
          d="
M266.013550,194.426697
	C321.925232,193.989319 377.755310,194.009766 433.585327,193.968277
	C437.949524,193.965042 442.230927,194.547470 446.354034,195.716537
	C459.771240,199.520905 464.284454,208.752502 464.899048,223.657928
	C465.399811,235.803238 465.001190,247.985657 465.001190,260.151611
	C465.001221,352.479553 465.038208,444.807526 464.937622,537.135376
	C464.928680,545.360779 463.282196,553.353027 457.609985,559.883057
	C452.275269,566.024658 445.112244,567.948059 437.422974,567.958984
	C381.259796,568.038879 325.096466,568.003174 268.464203,568.005554
	C267.908661,566.288147 267.822052,564.569092 267.819336,562.425659
	C270.717957,559.533203 274.172791,560.128906 277.443024,560.126465
	C326.676727,560.089966 375.910522,560.076050 425.144196,560.129883
	C433.035706,560.138489 440.709930,559.067200 448.633209,556.430847
	C449.052734,556.189270 449.067108,556.065857 449.049408,556.006348
	C452.060638,555.679932 450.800659,552.962402 451.865417,551.051636
	C454.958954,546.527344 455.525635,541.727295 455.995941,536.920288
	C456.190308,534.934204 456.101776,532.924438 456.101776,530.924805
	C456.102966,430.951050 456.095093,330.977264 456.122559,231.003494
	C456.124054,225.641144 455.805847,220.365952 454.158386,215.215515
	C453.645874,213.613312 453.308472,211.987259 451.548645,210.539703
	C450.515564,205.417709 447.610931,204.375961 443.104980,204.386490
	C386.884491,204.517929 330.663574,204.481766 274.442749,204.450241
	C271.816010,204.448761 269.102203,204.938492 266.261658,203.278534
	C265.929352,200.236130 265.930664,197.552475 266.013550,194.426697
z"
        />
        <path
          fill="#E5EAEC"
          opacity="1.000000"
          stroke="none"
          d="
M448.583435,556.125732
	C447.521301,559.597778 444.064484,560.171936 441.153656,560.606140
	C436.920593,561.237671 432.686951,562.061462 428.318604,562.054260
	C375.164581,561.966858 322.010315,561.997375 268.379669,561.999329
	C267.838348,560.320984 267.773499,558.640625 267.721863,556.544189
	C277.138184,556.748047 286.586426,556.335571 295.971283,556.349243
	C305.008911,556.362488 314.127563,556.291687 323.199890,556.133972
	C343.680481,555.777893 364.171509,556.016968 384.658295,556.023621
	C405.810242,556.030518 426.962189,556.048889 448.583435,556.125732
z"
        />
        <path
          fill="#BCB7AB"
          opacity="1.000000"
          stroke="none"
          d="
M452.389954,551.601929
	C451.801727,553.614624 452.634369,555.888489 449.409851,555.964294
	C449.595062,555.444397 450.158417,554.942017 450.843750,554.180237
	C448.629883,551.456665 449.086823,548.337646 449.081451,545.388611
	C449.035767,520.242310 449.056915,495.095917 449.056610,469.949554
	C449.055695,388.182251 449.055725,306.414948 449.061768,224.647644
	C449.061951,222.485825 448.921509,220.316055 449.328491,217.676941
	C448.453491,206.921188 448.453491,206.916885 438.240021,206.916428
	C382.445496,206.913956 326.650940,206.929642 270.856445,206.883148
	C267.597931,206.880432 264.131683,207.571091 261.134552,204.699890
	C261.003601,204.032898 261.004028,203.835724 261.001770,203.343323
	C260.999146,203.048096 261.367035,202.946121 261.557251,202.931335
	C262.863953,202.969849 263.980438,203.023132 265.512512,202.998093
	C271.079956,202.980362 276.231842,203.091629 281.383759,203.093735
	C336.261658,203.116089 391.139740,203.182358 446.017151,203.023575
	C450.805084,203.009705 452.778198,204.333786 452.095642,209.529510
	C454.443787,213.035202 453.926117,216.629959 453.927155,220.086899
	C453.950806,299.199280 453.944733,378.311676 453.944824,457.424042
	C453.944855,485.737946 453.941620,514.051880 453.950806,542.365784
	C453.951782,545.372986 454.006653,548.374451 452.389954,551.601929
z"
        />
        <path
          fill="#979DA1"
          opacity="1.000000"
          stroke="none"
          d="
M1797.000000,294.243256
	C1805.163452,294.243408 1812.834106,294.445251 1820.487427,294.166290
	C1824.954590,294.003418 1826.310059,295.713226 1826.130249,300.010406
	C1825.844971,306.828827 1826.098633,313.668488 1826.032227,320.498230
	C1826.010254,322.760681 1826.256592,324.345428 1829.178589,324.272003
	C1835.004150,324.125641 1840.838623,324.132538 1846.664307,324.279114
	C1849.421265,324.348450 1850.013916,323.096008 1849.979248,320.655396
	C1849.877319,313.493225 1850.053467,306.326324 1849.898438,299.166077
	C1849.827637,295.886627 1850.760986,294.203583 1854.352905,294.212738
	C1880.175049,294.278595 1905.997437,294.267883 1931.819824,294.224945
	C1935.086304,294.219513 1936.110840,295.712585 1936.095337,298.838104
	C1936.006958,316.663452 1936.001709,334.489685 1936.099121,352.314941
	C1936.116821,355.557373 1934.877197,356.787231 1931.699097,356.780975
	C1905.876709,356.730225 1880.054443,356.729126 1854.232056,356.781250
	C1850.992676,356.787781 1849.831909,355.427185 1849.902222,352.245331
	C1850.049194,345.584808 1849.861694,338.917175 1849.983521,332.255615
	C1850.029907,329.713562 1849.222900,328.660278 1846.575195,328.722504
	C1840.914062,328.855560 1835.243652,328.888428 1829.584961,328.709808
	C1826.592651,328.615356 1825.960571,329.945892 1826.013306,332.595856
	C1826.142456,339.090515 1825.920532,345.593323 1826.106934,352.085205
	C1826.207153,355.576263 1824.833130,356.802795 1821.427612,356.792999
	C1796.438477,356.721191 1771.448853,356.719330 1746.459717,356.793182
	C1742.953491,356.803528 1741.872314,355.356873 1741.893921,351.973816
	C1742.006958,334.315399 1742.030640,316.655121 1741.881958,298.997223
	C1741.849243,295.094818 1743.576904,294.186890 1747.021240,294.204926
	C1763.513794,294.291260 1780.006958,294.243286 1797.000000,294.243256
z"
        />
        <path
          fill="#979DA1"
          opacity="1.000000"
          stroke="none"
          d="
M1467.000000,294.243256
	C1478.157104,294.243408 1488.815674,294.343567 1499.470581,294.190277
	C1503.334595,294.134644 1505.196533,295.058380 1505.142090,299.480865
	C1504.926880,316.947540 1504.970825,334.419128 1505.118774,351.887207
	C1505.150757,355.656494 1503.722412,356.804352 1500.133057,356.800690
	C1439.357666,356.738464 1378.582275,356.737366 1317.806885,356.802185
	C1314.153564,356.806091 1312.851685,355.529510 1312.882690,351.825043
	C1313.026611,334.674988 1313.117676,317.520264 1312.838135,300.373566
	C1312.757690,295.440399 1314.433350,294.148438 1319.139771,294.161133
	C1368.259521,294.293762 1417.379883,294.242981 1467.000000,294.243256
z"
        />
        <path
          fill="#979DA1"
          opacity="1.000000"
          stroke="none"
          d="
M633.341858,279.519501
	C637.826721,278.939484 636.655823,282.036194 636.722046,284.094635
	C636.894653,289.455048 640.611755,291.821289 645.766724,289.857422
	C647.268066,289.285431 647.177612,288.192871 647.216064,287.068207
	C647.278748,285.237518 647.252625,283.403931 647.271484,281.571594
	C647.285461,280.217834 647.843750,279.364807 649.317749,279.298920
	C651.018494,279.222870 651.746643,280.079559 651.737915,281.705902
	C651.734314,282.372131 651.748596,283.038483 651.757080,283.704742
	C651.838196,290.102356 650.402710,289.939178 658.370422,289.942566
	C660.870972,289.943604 663.179199,290.313934 661.592529,286.141388
	C661.226196,285.178192 660.438599,279.575897 664.355530,279.505859
	C668.641724,279.429260 666.329407,283.524933 666.661377,285.747314
	C666.989319,287.941803 666.562744,290.177032 669.826843,290.196198
	C670.311279,290.199036 671.181885,290.965637 671.212463,291.423035
	C671.507019,295.833191 674.753357,294.723175 677.420349,295.005859
	C679.076172,295.181396 681.956482,293.640991 681.920715,297.096741
	C681.889160,300.139191 679.274719,298.934906 677.729187,299.002197
	C672.276001,299.239594 669.856934,303.106689 671.850525,308.441406
	C672.407410,309.931610 673.463806,309.898438 674.609436,309.927521
	C676.440979,309.974030 678.274048,309.957825 680.106262,309.983704
	C681.275574,310.000244 681.934265,310.534363 681.991699,311.778503
	C682.056458,313.181122 681.558044,314.015747 680.048340,314.022034
	C678.882324,314.026886 677.708618,314.133972 676.551758,314.036499
	C673.540833,313.782806 671.581787,314.117126 671.628540,318.120728
	C671.674316,322.040405 673.555298,322.518066 676.599548,322.257111
	C677.590942,322.172119 678.597168,322.268402 679.596619,322.266022
	C681.190430,322.262238 682.087585,322.894714 682.024231,324.642334
	C681.970154,326.134033 681.172791,326.694336 679.812012,326.723267
	C679.145874,326.737427 678.479370,326.739594 677.813354,326.757355
	C671.968750,326.913147 670.339478,329.286682 671.782593,335.175812
	C672.205566,336.901917 673.217468,337.207886 674.650757,337.225464
	C676.482483,337.247925 678.315369,337.246002 680.145325,337.316650
	C681.649414,337.374664 682.065857,338.345612 682.011902,339.693665
	C681.965393,340.856293 681.468689,341.609100 680.262939,341.676208
	C678.934326,341.750092 677.590271,341.638367 676.272339,341.783630
	C674.749329,341.951538 672.472412,340.988739 672.045288,343.248230
	C671.531494,345.966034 670.866333,349.089203 672.505676,351.468842
	C673.672302,353.162079 676.253601,352.034851 678.194580,352.235596
	C679.913635,352.413391 682.183044,351.735443 681.992188,354.736877
	C681.823914,357.383728 679.728333,356.662628 678.217041,356.734680
	C671.421631,357.058502 667.021912,361.539429 666.749695,368.478760
	C666.681152,370.226715 667.233154,372.502319 664.296814,372.347626
	C661.737366,372.212860 662.230957,370.124573 662.274902,368.570618
	C662.379944,364.855438 661.725891,362.050171 657.112793,361.700104
	C653.086975,361.394592 651.086487,362.541870 651.729431,366.870636
	C651.898438,368.008759 651.741333,369.198517 651.702515,370.363495
	C651.653564,371.833679 650.757996,372.403412 649.406921,372.377899
	C647.994629,372.351257 647.293396,371.584961 647.279236,370.176880
	C647.274170,369.677338 647.266052,369.177460 647.243042,368.678497
	C646.936829,362.046844 644.762878,360.385742 638.532776,361.841217
	C636.343750,362.352600 637.088135,364.043640 636.726318,365.379517
	C636.083374,367.752930 638.712219,372.123291 634.385132,372.128662
	C630.367615,372.133667 632.572571,367.921875 632.357605,365.646851
	C632.114197,363.070862 631.676636,361.460785 628.682678,361.750305
	C625.891479,362.020203 622.267090,360.661804 621.852295,365.342773
	C621.705566,366.998138 621.757263,368.670746 621.707214,370.335144
	C621.663208,371.798035 620.798218,372.399872 619.439209,372.382843
	C618.038391,372.365326 617.300354,371.630219 617.282410,370.209686
	C617.269775,369.210419 617.112061,368.188171 617.267456,367.215851
	C617.980042,362.756866 616.107849,361.525024 611.729858,361.608215
	C607.781921,361.683228 606.193115,362.819794 606.731201,366.750549
	C606.887634,367.893005 606.732361,369.078644 606.714050,370.244385
	C606.691528,371.677643 605.914246,372.378479 604.525635,372.383972
	C603.157532,372.389435 602.321167,371.747894 602.291077,370.296295
	C602.263550,368.964172 602.243225,367.631653 602.242126,366.299286
	C602.240295,364.148895 602.631714,361.821198 599.406616,361.515228
	C598.860779,361.463409 597.986755,360.823792 597.923218,360.364563
	C597.307434,355.914276 593.951965,356.899719 591.095520,356.728394
	C589.457397,356.630157 587.060364,357.545685 587.009033,354.549713
	C586.957642,351.553680 589.330261,352.332031 590.994934,352.264801
	C597.601013,351.997986 597.738342,351.766022 597.284058,345.021942
	C597.130249,342.739502 596.408020,341.636902 594.041077,341.751526
	C592.380127,341.831940 590.710205,341.750244 589.044922,341.714081
	C587.617249,341.683075 586.973145,340.914368 586.973633,339.499298
	C586.974121,338.083954 587.619629,337.327148 589.046753,337.283783
	C589.879150,337.258484 590.712280,337.258850 591.544983,337.242645
	C596.547974,337.145599 599.481750,332.272675 596.943726,328.024292
	C595.544922,325.682770 593.000854,327.107849 590.989868,326.770477
	C589.341797,326.494049 586.954895,327.443878 587.009399,324.448334
	C587.063782,321.455902 589.503235,322.630615 591.087830,322.210297
	C593.185486,321.653931 596.365845,323.919922 597.194336,319.923859
	C598.139709,315.364044 597.103394,314.112854 592.255432,314.053741
	C591.089783,314.039490 589.923767,314.034363 588.758667,313.999207
	C587.532898,313.962250 587.027527,313.266815 587.011719,312.084534
	C586.996643,310.957733 587.305115,310.108612 588.592224,310.030823
	C589.588440,309.970581 590.614929,309.812378 591.582153,309.973236
	C596.234863,310.746979 597.456665,308.765442 597.400818,304.241669
	C597.349915,300.112427 596.259583,298.368103 592.069702,299.029877
	C591.096069,299.183655 590.074707,299.029663 589.075256,299.026245
	C587.659790,299.021423 586.962036,298.406097 586.998169,296.902283
	C587.036255,295.321930 587.957458,294.967102 589.269165,294.966766
	C590.768433,294.966370 592.267700,294.941559 593.766968,294.945679
	C595.453247,294.950317 597.235107,295.137695 597.323914,292.651062
	C597.335327,292.330688 597.445435,291.776794 597.613831,291.733276
	C603.729797,290.151611 601.938110,285.126282 602.316895,281.041748
	C602.427612,279.847992 603.211304,279.344940 604.350464,279.300995
	C605.712769,279.248444 606.624817,279.777710 606.704590,281.239655
	C606.768005,282.402863 606.897766,283.593781 606.729309,284.732666
	C606.121033,288.846497 607.724792,290.020325 611.901367,290.117584
	C616.490845,290.224457 617.714905,288.673065 617.347412,284.417145
	C617.194458,282.645966 615.913391,279.474976 619.458801,279.376770
	C623.078979,279.276520 621.508667,282.532684 621.837891,284.329712
	C622.351562,287.133453 621.032104,290.328949 626.355774,290.166443
	C631.203064,290.018494 633.007935,288.769379 632.297424,283.976318
	C632.090088,282.577606 631.831665,280.962616 633.341858,279.519501
z"
        />
        <path
          fill="#979DA1"
          opacity="1.000000"
          stroke="none"
          d="
M1067.054199,375.995758
	C1067.053223,380.492401 1066.946167,384.493927 1067.086304,388.486755
	C1067.190308,391.446655 1066.009399,392.770813 1063.026978,392.767334
	C1037.707764,392.737854 1012.388489,392.737396 987.069275,392.769226
	C984.151428,392.772888 982.901001,391.605804 982.912109,388.584106
	C982.984009,368.928589 982.971008,349.272675 982.920288,329.617035
	C982.913757,327.106689 983.484131,325.239594 986.354309,325.221283
	C994.156311,325.171478 1001.958984,325.206085 1009.761353,325.219482
	C1011.496643,325.222443 1013.217468,325.242310 1013.014526,327.785004
	C1012.830627,330.088318 1011.100464,329.725769 1009.678345,329.735565
	C1003.515381,329.778015 997.349915,329.851349 991.189636,329.715851
	C988.402954,329.654541 986.962158,330.173584 986.990479,333.487274
	C987.137085,350.628448 987.112122,367.771667 987.022827,384.913544
	C987.008667,387.641174 988.141663,388.276703 990.592102,388.270874
	C1013.579224,388.216339 1036.566650,388.209167 1059.553589,388.275818
	C1062.170044,388.283386 1062.994629,387.329895 1062.982788,384.768127
	C1062.903564,367.611359 1062.881470,350.453613 1062.999268,333.297302
	C1063.019897,330.281921 1061.754639,329.659393 1059.118530,329.720398
	C1053.624512,329.847534 1048.125244,329.785828 1042.628662,329.735992
	C1041.063721,329.721832 1039.063110,330.213409 1038.989380,327.582336
	C1038.916382,324.978119 1040.834229,325.272858 1042.463257,325.247925
	C1049.616943,325.138428 1056.770874,325.013916 1063.925049,324.990936
	C1067.522583,324.979401 1067.026489,327.790161 1067.032471,330.022217
	C1067.073120,345.180328 1067.053955,360.338654 1067.054199,375.995758
z"
        />
        <path
          fill="#979DA1"
          opacity="1.000000"
          stroke="none"
          d="
M1065.033569,298.581909
	C1064.787964,301.443970 1063.135620,301.926514 1061.124390,301.926605
	C1054.126709,301.926880 1047.129028,301.935272 1040.131348,301.922607
	C1038.631592,301.919891 1037.020630,302.084503 1036.979126,299.796387
	C1036.930298,297.103485 1038.901611,297.541504 1040.472778,297.526825
	C1045.970581,297.475372 1051.473022,297.377716 1056.965698,297.550507
	C1059.981934,297.645447 1061.018799,296.608154 1060.998169,293.545837
	C1060.883789,276.552216 1060.871338,259.556854 1061.007935,242.563568
	C1061.033691,239.343903 1059.755981,238.700958 1056.883911,238.712082
	C1034.891479,238.797287 1012.898315,238.811172 990.906128,238.700577
	C987.767700,238.684799 986.980286,239.811157 987.000244,242.773453
	C987.113647,259.597321 987.119751,276.422821 986.998474,293.246582
	C986.975403,296.446899 987.887512,297.707825 991.236145,297.565399
	C996.724304,297.332031 1002.230042,297.478424 1007.727783,297.528625
	C1009.137939,297.541473 1010.894348,297.163788 1011.017578,299.497284
	C1011.154114,302.082520 1009.361633,301.926971 1007.668030,301.927979
	C1000.836975,301.932098 994.004822,301.855377 987.175354,301.959808
	C983.850952,302.010651 982.898376,300.427673 982.910095,297.340759
	C982.984131,277.847504 983.006470,258.353455 982.893250,238.860565
	C982.872620,235.309326 984.336914,234.203857 987.658569,234.212265
	C1011.817139,234.273468 1035.976074,234.304703 1060.134277,234.185593
	C1064.235107,234.165375 1065.139893,235.946198 1065.114746,239.653351
	C1064.982544,259.142242 1065.050049,278.632446 1065.033569,298.581909
z"
        />
        <path
          fill="#979DA1"
          opacity="1.000000"
          stroke="none"
          d="
M702.243347,298.000000
	C702.242737,286.012085 702.164490,274.523254 702.294189,263.036835
	C702.327698,260.069458 701.482300,258.976776 698.361938,258.995056
	C678.217163,259.113037 658.070984,259.116791 637.926270,258.989746
	C634.835754,258.970276 633.776428,259.891205 634.002075,262.955414
	C634.233643,266.101166 634.046082,269.278107 634.033325,272.441345
	C634.028320,273.690948 633.816772,274.757782 632.210571,274.854248
	C630.366638,274.964966 629.955688,273.889282 629.958374,272.361572
	C629.966431,267.699890 630.002930,263.037628 629.941406,258.376740
	C629.906555,255.739105 631.279968,254.931946 633.689026,254.934860
	C656.664856,254.962646 679.640747,254.980530 702.616394,254.915253
	C705.673096,254.906570 706.767578,256.271423 706.764221,259.130585
	C706.737427,281.939880 706.746948,304.749207 706.743225,327.558533
	C706.742859,330.049530 705.901123,331.773560 703.024292,331.754181
	C698.529297,331.723907 694.033997,331.733795 689.538818,331.740326
	C688.009399,331.742523 686.968506,331.280304 686.970703,329.494110
	C686.972900,327.702667 688.027161,327.256714 689.550415,327.259003
	C692.547180,327.263550 695.550415,327.119812 698.538818,327.273804
	C701.378052,327.420074 702.317017,326.236450 702.279541,323.473206
	C702.166504,315.150177 702.241455,306.824585 702.243347,298.000000
z"
        />
        <path
          fill="#979DA1"
          opacity="1.000000"
          stroke="none"
          d="
M639.945801,384.910828
	C639.956726,382.610687 639.970764,380.779999 639.977600,378.949280
	C639.983215,377.438690 640.821411,376.947418 642.221008,377.009186
	C643.467529,377.064209 644.009766,377.734161 644.013733,378.891449
	C644.027954,383.051910 644.127930,387.216431 643.979858,391.371735
	C643.860352,394.724030 641.150330,394.029510 639.113403,394.033051
	C619.307007,394.067444 599.500549,394.053894 579.694092,394.053986
	C567.216858,394.054016 567.227844,394.054016 567.233459,381.751251
	C567.242188,362.610809 567.227295,343.470367 567.237305,324.329926
	C567.240845,317.499207 567.499084,317.031738 574.183411,317.361877
	C576.772217,317.489716 581.422974,315.269531 581.687866,319.245453
	C582.013000,324.125946 577.147705,321.292053 574.719971,321.617584
	C571.331177,322.072052 571.769531,324.130371 571.767273,326.238098
	C571.750549,341.883545 571.757446,357.528961 571.757080,373.174408
	C571.757019,377.168976 571.967102,381.177917 571.695190,385.153900
	C571.449890,388.739990 572.413757,390.077667 576.328796,390.037933
	C596.133240,389.836884 615.941711,389.847595 635.746460,390.031738
	C639.499207,390.066650 640.408569,388.614716 639.945801,384.910828
z"
        />
        <path
          fill="#C3CDCE"
          opacity="1.000000"
          stroke="none"
          d="
M1618.000000,380.936218
	C1604.515015,380.935699 1591.529419,380.989288 1578.545532,380.871979
	C1576.818481,380.856354 1573.577881,382.045807 1573.668701,379.397003
	C1573.751709,376.971832 1576.989624,378.126373 1578.782959,378.121155
	C1606.584717,378.040131 1634.386719,378.064545 1662.188721,378.064514
	C1663.775879,378.064514 1665.362915,378.064514 1666.988159,378.064514
	C1666.697998,375.280426 1661.512451,374.363922 1664.230103,371.986847
	C1666.334106,370.146667 1668.084839,374.064850 1669.783569,375.570190
	C1671.210938,376.834991 1674.277222,378.089417 1672.503784,380.618408
	C1670.545288,383.411591 1668.085693,386.004120 1664.886475,387.583344
	C1664.650146,387.700043 1663.951904,387.256653 1663.707642,386.906616
	C1663.138062,386.090546 1663.514648,385.350708 1664.167236,384.724304
	C1665.200073,383.732605 1666.857056,383.328979 1667.253418,380.936920
	C1650.911987,380.936920 1634.705933,380.936920 1618.000000,380.936218
z"
        />
        <path
          fill="#C3CDCE"
          opacity="1.000000"
          stroke="none"
          d="
M839.000000,378.064728
	C849.643860,378.064819 859.787659,378.064819 869.880493,378.064819
	C870.021240,377.544800 870.224548,377.220520 870.160156,376.963165
	C869.717590,375.194092 865.985046,374.157166 867.922485,372.377899
	C869.600525,370.836823 871.296753,373.740112 872.499329,375.087708
	C873.953613,376.717316 877.649658,377.335663 876.599304,380.051422
	C875.269775,383.488892 871.366699,384.965179 868.701721,387.428009
	C868.511047,387.604279 867.712097,387.410339 867.433655,387.141968
	C866.902466,386.629883 866.976929,385.899902 867.436218,385.347717
	C868.385132,384.207031 869.398193,383.119720 870.552124,381.822357
	C868.873657,380.326080 866.989380,380.972839 865.330200,380.969879
	C838.200867,380.921387 811.071533,380.935486 783.942139,380.935425
	C782.943481,380.935425 781.933960,381.027374 780.948792,380.911804
	C779.601196,380.753693 777.313782,381.561493 777.380005,379.446869
	C777.442627,377.446045 779.713196,378.097961 781.077698,378.094879
	C800.218384,378.051453 819.359192,378.064972 839.000000,378.064728
z"
        />
        <path
          fill="#C3CDCE"
          opacity="1.000000"
          stroke="none"
          d="
M1234.999512,380.943665
	C1212.031128,380.938232 1189.562500,380.937164 1167.093872,380.934509
	C1165.928955,380.934387 1164.763184,380.944275 1163.599243,380.906738
	C1162.599243,380.874542 1161.373169,380.851624 1161.296143,379.578857
	C1161.216431,378.263611 1162.429565,378.147644 1163.431641,378.105835
	C1164.760986,378.050323 1166.093872,378.065857 1167.425171,378.065796
	C1194.554565,378.064087 1221.684082,378.064514 1248.813477,378.064514
	C1250.584473,378.064514 1252.355469,378.064514 1254.141724,378.064514
	C1254.079712,377.258118 1254.125610,376.924683 1254.020874,376.648621
	C1253.427490,375.086578 1250.119873,374.158386 1251.791748,372.442871
	C1253.428711,370.763306 1254.720825,373.624908 1256.094238,374.585236
	C1257.040283,375.246765 1257.746948,376.269867 1258.722778,376.866028
	C1261.570435,378.605835 1261.319580,380.358917 1258.979492,382.298096
	C1257.318115,383.674683 1255.701294,385.104767 1254.041626,386.483521
	C1253.320312,387.082886 1252.588745,388.183044 1251.537842,387.246063
	C1250.533325,386.350311 1251.297607,385.448334 1251.949585,384.674530
	C1252.768066,383.703186 1253.607666,382.749634 1255.164307,380.947693
	C1247.957153,380.947693 1241.728271,380.947693 1234.999512,380.943665
z"
        />
        <path
          fill="#979DA1"
          opacity="1.000000"
          stroke="none"
          d="
M1022.945923,321.914429
	C1022.945862,315.614899 1022.945862,309.800232 1022.945862,303.478638
	C1019.309326,305.259857 1017.533081,308.489594 1015.018860,310.883026
	C1014.109863,311.748383 1013.437256,313.307800 1011.822144,311.999573
	C1010.283875,310.753571 1010.631348,309.421692 1011.822876,308.155365
	C1015.694031,304.040894 1019.623291,299.979706 1023.429810,295.806396
	C1024.619629,294.501953 1025.343994,294.456909 1026.557617,295.790680
	C1030.359131,299.968109 1034.292358,304.025391 1038.163452,308.139679
	C1039.346558,309.397034 1039.735229,310.723480 1038.193726,311.986298
	C1036.583008,313.305725 1035.884155,311.802063 1035.011841,310.883423
	C1032.654053,308.400665 1030.295410,305.918701 1027.059204,302.512390
	C1027.059204,310.977600 1027.128784,318.207458 1027.004150,325.434021
	C1026.977295,326.995361 1028.123901,329.597168 1025.223267,329.769104
	C1021.797058,329.972168 1023.275818,327.068665 1022.977295,325.385712
	C1022.805786,324.418823 1022.945984,323.396637 1022.945923,321.914429
z"
        />
        <path
          fill="#979DA1"
          opacity="1.000000"
          stroke="none"
          d="
M1027.189453,352.350098
	C1025.787964,359.766479 1024.471069,366.760712 1023.140991,373.752411
	C1022.875122,375.150024 1023.072144,377.035065 1020.813293,376.798920
	C1018.286560,376.534698 1018.735657,374.553955 1019.022339,372.986664
	C1020.957764,362.407074 1023.007874,351.848450 1024.934448,341.267303
	C1025.275024,339.396667 1026.104370,338.642975 1027.954468,339.019226
	C1030.066650,339.448822 1029.262817,340.978973 1029.065063,342.165649
	C1028.521240,345.428558 1027.875122,348.674377 1027.189453,352.350098
z"
        />
        <path
          fill="#46505D"
          opacity="1.000000"
          stroke="none"
          d="
M713.154541,453.031250
	C712.064148,453.356018 711.413879,453.654053 710.802063,453.589233
	C709.484802,453.449707 707.878052,452.958954 707.348511,454.505951
	C706.726013,456.324738 708.633667,456.469513 709.735962,456.922729
	C712.334778,457.991333 714.535400,459.698090 712.662781,462.480194
	C710.544189,465.627838 707.462830,464.200653 704.302917,463.746368
	C705.177490,461.617462 706.070129,462.117188 706.814331,462.167267
	C708.253296,462.264069 710.139526,462.920135 710.562195,460.958801
	C710.933044,459.238342 708.970947,459.186768 707.843689,458.680634
	C705.765808,457.747650 703.753662,456.677002 704.744812,453.929596
	C705.757751,451.121979 708.517944,450.719452 710.545227,451.714508
	C714.594116,453.701752 716.454712,452.112183 717.757751,447.379791
	C718.955017,450.353760 720.172485,452.157104 722.818726,452.843445
	C720.479126,453.803345 718.511108,454.323914 718.953735,457.501312
	C719.321960,460.144836 719.211182,462.490631 723.424438,462.703674
	C718.071472,464.899170 716.681824,464.182465 716.401611,459.893005
	C716.227234,457.223877 717.211853,454.138489 713.154541,453.031250
z"
        />
        <path
          fill="#979DA1"
          opacity="1.000000"
          stroke="none"
          d="
M1032.279053,272.054138
	C1031.982422,269.563843 1032.597168,267.586243 1031.078979,265.498535
	C1028.914062,262.521515 1034.141113,254.024689 1037.732422,254.143524
	C1038.924561,254.182968 1040.168701,254.368423 1040.640991,255.628540
	C1041.182373,257.072754 1040.208984,257.448883 1038.991211,258.011383
	C1036.314941,259.247620 1035.957886,260.874939 1039.078857,262.074341
	C1040.840576,262.751312 1041.250000,264.455750 1039.973145,264.945465
	C1035.102661,266.813385 1037.814453,270.801819 1037.024902,273.779114
	C1036.528320,275.651917 1038.691284,279.039093 1034.575439,278.953583
	C1030.970947,278.878723 1032.727905,275.812927 1032.295654,273.998535
	C1032.183472,273.527313 1032.278809,273.006622 1032.279053,272.054138
z"
        />
        <path
          fill="#979DA1"
          opacity="1.000000"
          stroke="none"
          d="
M1008.771118,277.063477
	C1006.706909,273.158844 1006.402832,269.466370 1007.901855,265.706757
	C1009.125793,262.637146 1011.553345,261.597717 1014.706848,261.917175
	C1018.022583,262.253113 1019.014221,264.609711 1019.471252,267.409241
	C1019.969971,270.464630 1019.041992,272.180573 1015.548340,271.985138
	C1014.302734,271.915466 1012.118591,271.082947 1011.987854,273.379700
	C1011.866760,275.506012 1013.657104,275.835358 1015.485107,275.969910
	C1016.697327,276.059174 1018.805237,274.282349 1019.004944,276.943237
	C1019.179565,279.269135 1016.863220,279.161011 1015.352356,279.434448
	C1012.945190,279.870087 1010.764160,279.162109 1008.771118,277.063477
z"
        />
        <path
          fill="#46505D"
          opacity="1.000000"
          stroke="none"
          d="
M1727.376343,475.243530
	C1727.145020,478.233734 1730.523926,477.048218 1731.028564,478.832886
	C1728.131226,480.441376 1726.472168,484.902313 1727.864258,487.160339
	C1728.636353,488.412628 1736.256226,489.029449 1737.838501,487.960449
	C1738.933716,487.220520 1738.888550,486.321167 1737.934692,485.592255
	C1737.299561,485.106720 1736.451172,484.892700 1735.687378,484.584106
	C1733.712769,483.786255 1731.988159,482.700867 1732.640869,480.214355
	C1733.328491,477.595062 1735.513672,477.221832 1737.792969,477.440613
	C1738.711792,477.528839 1739.590088,478.039459 1740.512939,478.367157
	C1740.230103,478.952087 1740.165649,479.384888 1739.951782,479.479431
	C1738.432495,480.150787 1735.818848,478.168701 1735.309204,480.745300
	C1734.891357,482.858337 1737.572632,482.675659 1738.887939,483.428497
	C1740.509521,484.356689 1741.519531,485.466278 1741.138794,487.394287
	C1740.761108,489.306885 1739.392944,490.104828 1737.567993,490.255920
	C1734.310669,490.525604 1731.170898,488.839905 1727.762207,490.015900
	C1726.267090,490.531647 1725.320801,488.811615 1724.938721,487.188568
	C1724.318359,484.553467 1726.524658,481.214355 1723.004395,479.298309
	C1724.900513,478.320953 1724.295532,474.942108 1727.376343,475.243530
z"
        />
        <path
          fill="#46505D"
          opacity="1.000000"
          stroke="none"
          d="
M674.921509,433.932770
	C674.916809,432.309204 674.902954,431.144379 674.909668,429.979675
	C674.919556,428.276184 674.009583,427.576965 672.394287,427.698273
	C670.904541,427.810181 669.883362,428.386902 669.927917,430.134460
	C669.978821,432.129669 669.937012,434.127716 669.909424,436.124176
	C669.897766,436.965942 669.812012,437.826080 668.718506,437.956085
	C667.717285,438.075073 667.138184,437.570770 667.128662,436.576874
	C667.098572,433.416382 666.160889,429.800049 667.391113,427.229584
	C668.504761,424.902863 672.377625,425.686310 675.109497,425.672180
	C676.019775,425.667450 677.077393,426.614594 677.817932,426.370087
	C680.242004,425.569763 683.006104,424.265747 684.580139,426.922913
	C686.306702,429.837555 685.079956,433.436951 685.250488,436.731567
	C685.288940,437.474426 684.863281,437.967163 684.083862,438.005310
	C682.966003,438.060028 682.727783,437.323242 682.724304,436.406006
	C682.718018,434.742096 682.747681,433.076935 682.692383,431.414642
	C682.639282,429.817352 683.088074,427.835358 680.551880,427.705292
	C678.074707,427.578247 677.466492,429.085510 677.605225,431.233673
	C677.680176,432.393494 677.695496,433.569702 677.590271,434.725098
	C677.480713,435.927734 678.164490,437.950562 676.274658,437.905487
	C673.981812,437.850830 675.312012,435.622437 674.921509,433.932770
z"
        />
        <path
          fill="#46505D"
          opacity="1.000000"
          stroke="none"
          d="
M573.928467,509.130554
	C573.145264,511.657288 575.607117,515.598877 572.269043,515.623413
	C569.785828,515.641785 571.483582,511.734375 571.114258,509.618988
	C570.444397,505.781891 571.199890,503.188477 576.138550,503.619934
	C579.419739,503.906586 582.719910,504.219971 586.140930,503.614777
	C588.798645,503.144623 589.614258,505.853271 589.241333,508.192108
	C588.826355,510.794525 590.710815,515.427490 588.103210,515.557556
	C585.201965,515.702271 587.199402,511.300446 586.764771,508.945068
	C586.446045,507.218018 586.579529,505.554077 584.096069,505.711090
	C581.771362,505.858093 581.773926,507.411896 581.559265,509.182800
	C581.282410,511.467743 582.681824,515.760864 580.340820,515.600403
	C577.278076,515.390503 579.461365,511.282227 578.972107,508.929993
	C578.670959,507.481964 579.083191,505.789215 576.854431,505.703339
	C574.810242,505.624542 573.712769,506.504150 573.928467,509.130554
z"
        />
        <path
          fill="#46505D"
          opacity="1.000000"
          stroke="none"
          d="
M1923.848999,481.328979
	C1919.990479,478.139862 1919.095093,479.957642 1918.836304,483.739075
	C1918.694458,485.811035 1920.314819,489.583588 1917.598755,489.658600
	C1914.752197,489.737244 1916.236938,485.972168 1916.156006,483.966949
	C1915.921997,478.172699 1918.292847,476.453888 1924.223267,477.651794
	C1925.150146,477.838959 1925.822632,478.935791 1926.947021,478.387115
	C1929.321655,477.228302 1932.215454,476.338684 1933.617554,478.996399
	C1935.253784,482.097748 1934.238525,485.841888 1934.104980,489.310669
	C1934.095337,489.558014 1933.387939,489.956512 1932.989136,489.976807
	C1931.866821,490.034058 1931.727173,489.189392 1931.722290,488.312134
	C1931.711060,486.315216 1931.701660,484.318298 1931.690918,482.321381
	C1931.682739,480.818054 1931.217651,479.689423 1929.450928,479.715698
	C1927.735718,479.741211 1926.590332,480.365845 1926.623535,482.342987
	C1926.657104,484.338531 1926.603149,486.335938 1926.565674,488.332123
	C1926.548584,489.245636 1926.216431,489.979431 1925.163818,489.990906
	C1924.002441,490.003601 1923.949707,489.108978 1923.938354,488.278870
	C1923.908936,486.115784 1923.914185,483.952240 1923.848999,481.328979
z"
        />
        <path
          fill="#46505D"
          opacity="1.000000"
          stroke="none"
          d="
M1883.057983,477.889160
	C1887.344971,477.082275 1891.259033,478.590637 1895.207764,477.628937
	C1898.420410,476.846527 1900.367554,478.170837 1900.292114,481.733521
	C1900.285034,482.064972 1900.351562,482.408813 1900.285522,482.726440
	C1899.783936,485.137268 1901.467651,489.636444 1899.134766,489.581696
	C1896.095581,489.510376 1898.206665,485.317291 1897.762573,482.971466
	C1897.441162,481.273590 1897.625610,479.578857 1895.139648,479.708069
	C1892.839600,479.827606 1892.777954,481.328674 1892.554688,483.121216
	C1892.269409,485.411255 1893.756226,489.697968 1891.414185,489.581604
	C1888.146118,489.419220 1890.466309,485.348755 1889.994385,483.039642
	C1889.654663,481.377319 1889.961182,479.611145 1887.484619,479.680115
	C1885.245972,479.742432 1884.766235,481.119781 1884.920654,483.023743
	C1884.973999,483.682617 1885.103394,484.412567 1884.892944,485.000854
	C1884.305542,486.642426 1886.382202,489.879730 1883.376709,489.753723
	C1880.874023,489.648773 1882.226685,486.484039 1882.163696,484.739716
	C1882.083008,482.506866 1881.448242,480.142365 1883.057983,477.889160
z"
        />
        <path
          fill="#4F5965"
          opacity="1.000000"
          stroke="none"
          d="
M504.953491,459.001648
	C504.952118,460.110962 505.014099,460.784210 504.935089,461.440521
	C504.811523,462.467010 505.388916,464.101898 503.477386,463.897675
	C502.157166,463.756561 502.370911,462.445129 502.363892,461.500305
	C502.334229,457.506531 502.335083,453.512299 502.363892,449.518524
	C502.370819,448.558472 502.239899,447.415100 503.589966,447.158325
	C504.729431,446.941589 505.464539,447.593536 506.028381,448.495392
	C508.100037,451.809082 510.171936,455.122650 513.055481,459.734558
	C513.055481,455.332031 513.035889,452.425873 513.066589,449.520264
	C513.076904,448.555695 512.978760,447.275238 514.332214,447.248840
	C515.717957,447.221802 515.623108,448.519836 515.631409,449.474182
	C515.666016,453.467896 515.667175,457.462158 515.637634,461.455933
	C515.630676,462.399323 515.900635,463.602936 514.628784,463.968933
	C513.305969,464.349701 512.494263,463.580627 511.823975,462.471527
	C509.903564,459.294159 507.909576,456.161346 504.953583,453.327820
	C504.953583,455.071228 504.953583,456.814606 504.953491,459.001648
z"
        />
        <path
          fill="#2F3A48"
          opacity="1.000000"
          stroke="none"
          d="
M1034.977783,423.587402
	C1036.063599,419.484833 1037.464355,421.069672 1038.714600,422.990051
	C1040.744507,426.108215 1042.668823,429.295105 1045.795898,432.101318
	C1045.795898,429.027588 1045.783936,425.953827 1045.803589,422.880219
	C1045.808960,422.032074 1045.935181,421.169495 1047.109131,421.285828
	C1048.025269,421.376648 1047.976562,422.180634 1047.981323,422.791229
	C1048.016479,427.276886 1048.012939,431.762848 1048.013672,436.248718
	C1048.013794,437.092590 1047.988892,437.889435 1046.813232,437.998535
	C1045.609619,438.110199 1045.038086,437.408356 1044.489258,436.522552
	C1042.442993,433.220490 1040.368286,429.936066 1037.981201,426.130035
	C1036.760620,429.358093 1037.529785,431.856079 1037.176514,434.250916
	C1036.991699,435.503967 1038.189941,437.780731 1035.946411,437.765594
	C1034.202026,437.753815 1035.063843,435.635529 1035.021729,434.488159
	C1034.893555,431.003632 1034.975342,427.511383 1034.977783,423.587402
z"
        />
        <path
          fill="#2F3A48"
          opacity="1.000000"
          stroke="none"
          d="
M1492.663818,447.347046
	C1496.491943,447.749115 1496.532349,451.259796 1498.309326,453.258484
	C1499.681030,454.801331 1500.030640,457.166901 1502.960815,458.617706
	C1502.960815,454.488800 1502.960815,450.858368 1502.960815,447.227966
	C1503.595581,447.229156 1504.230347,447.230347 1504.865112,447.231567
	C1504.865112,452.602509 1505.202637,458.008698 1504.647339,463.321594
	C1504.492676,464.802155 1502.299561,463.840332 1501.425903,462.427094
	C1499.446167,459.225098 1497.438721,456.040192 1494.215088,452.964813
	C1494.215088,455.926422 1494.239868,458.888367 1494.200439,461.849457
	C1494.188232,462.762421 1494.409058,464.041168 1492.856689,463.860840
	C1491.675537,463.723663 1491.987915,462.626282 1491.997559,461.893127
	C1492.059937,457.091431 1492.180054,452.290497 1492.663818,447.347046
z"
        />
        <path
          fill="#979DA1"
          opacity="1.000000"
          stroke="none"
          d="
M1035.924561,370.482666
	C1033.444702,369.467926 1032.577026,368.343231 1034.473511,366.001740
	C1036.648193,363.316925 1040.549561,360.853119 1040.297485,357.678558
	C1040.077026,354.901276 1036.634155,352.393799 1034.657593,349.735321
	C1033.815430,348.602631 1032.474243,347.554749 1034.317993,346.013123
	C1036.094482,344.527893 1036.955200,345.638458 1037.895752,346.918152
	C1037.994263,347.052185 1038.085938,347.191345 1038.185913,347.324310
	C1047.765015,360.059875 1049.118530,354.888000 1035.924561,370.482666
z"
        />
        <path
          fill="#979DA1"
          opacity="1.000000"
          stroke="none"
          d="
M1006.449158,351.457794
	C1007.919312,349.622925 1009.202820,348.095856 1010.404724,346.506989
	C1011.373108,345.226898 1012.302429,344.826416 1013.681091,346.023773
	C1014.800720,346.996185 1015.013367,348.007050 1013.921997,348.915527
	C1006.651428,354.967377 1006.855835,360.878204 1014.052612,366.827423
	C1015.213318,367.786926 1014.756409,368.831543 1013.692932,369.735168
	C1012.608398,370.656555 1011.748779,370.768036 1010.787476,369.535828
	C1008.234497,366.263062 1005.356018,363.212219 1003.089233,359.756714
	C1000.611511,355.979645 1004.944946,354.341858 1006.449158,351.457794
z"
        />
        <path
          fill="#46505D"
          opacity="1.000000"
          stroke="none"
          d="
M1908.130005,453.427795
	C1910.077271,451.912170 1910.214966,451.861938 1910.742432,453.308044
	C1911.419067,455.163727 1911.846802,457.110229 1912.393555,459.059326
	C1914.547852,458.627289 1913.706543,456.634308 1914.423706,455.514343
	C1915.229614,454.256226 1914.407959,451.628082 1916.868042,451.851227
	C1918.986084,452.043335 1918.534058,454.359344 1919.049805,455.811462
	C1919.489746,457.050507 1919.807617,458.332886 1920.500122,460.684174
	C1921.506470,457.482727 1922.226196,455.258331 1922.899780,453.020020
	C1923.182617,452.080566 1923.726196,451.579254 1924.700317,451.860596
	C1925.972046,452.227905 1925.364624,453.155609 1925.138428,453.853790
	C1924.273438,456.524597 1923.356445,459.178772 1922.438721,461.832245
	C1922.074463,462.885284 1921.963135,464.154358 1920.304688,464.044952
	C1918.598755,463.932404 1918.948853,462.417816 1918.457764,461.494934
	C1917.705444,460.080994 1918.240479,458.128815 1916.651367,457.043335
	C1914.247681,458.777252 1916.273926,464.255829 1912.579102,463.662903
	C1909.639160,463.191101 1910.036621,458.458588 1908.857300,455.660004
	C1908.600952,455.052063 1908.433594,454.406677 1908.130005,453.427795
z"
        />
        <path
          fill="#46505D"
          opacity="1.000000"
          stroke="none"
          d="
M510.743988,488.326660
	C509.797699,486.383087 510.181030,484.316101 508.740601,482.967194
	C508.502869,483.128754 508.372772,483.183258 508.289612,483.278625
	C506.585388,485.233307 507.990875,489.848785 505.011566,489.732269
	C501.704712,489.602905 502.536011,485.004181 501.088257,482.600616
	C500.844238,482.195496 500.796936,481.647766 500.743591,481.156708
	C500.621796,480.035339 498.974731,478.483337 500.890442,477.908203
	C502.612488,477.391144 502.713348,479.315369 503.076202,480.556396
	C503.520905,482.077271 504.028900,483.579651 504.628326,485.463379
	C506.417450,483.669769 506.240662,481.543365 506.869904,479.768372
	C507.213379,478.799500 507.295166,477.798431 508.585052,477.717499
	C509.905487,477.634674 510.200043,478.651886 510.480286,479.601471
	C511.085358,481.651489 511.641907,483.715851 512.295288,486.049225
	C514.037781,483.688019 514.072021,481.100006 514.951233,478.819336
	C515.332520,477.830444 516.046814,477.590637 516.897583,477.915710
	C517.722839,478.230988 517.425781,478.962769 517.218994,479.577576
	C516.165466,482.710541 515.307800,485.930908 513.973450,488.939728
	C513.183960,490.719910 511.752686,490.802887 510.743988,488.326660
z"
        />
        <path
          fill="#46505D"
          opacity="1.000000"
          stroke="none"
          d="
M1778.444824,483.592834
	C1777.209229,486.000549 1777.722534,489.976746 1774.916626,489.772217
	C1771.712280,489.538574 1773.281372,485.010620 1771.414795,483.025879
	C1770.439087,483.277527 1770.395996,484.158569 1770.045044,484.891693
	C1769.203369,486.649445 1770.162476,489.995850 1767.248657,489.853912
	C1764.763550,489.732849 1764.965698,486.565216 1764.407593,484.688324
	C1763.759277,482.508545 1761.510376,478.579102 1763.258545,478.264313
	C1766.656738,477.652466 1765.248901,482.173553 1766.588623,484.161194
	C1766.857788,484.560577 1767.012695,485.036957 1767.258057,485.558136
	C1768.867065,483.937347 1768.622681,481.741028 1769.365967,479.916748
	C1769.710938,479.070374 1769.682495,477.805084 1771.055298,477.774841
	C1772.585938,477.741119 1772.851440,478.846649 1773.138184,480.054749
	C1773.542725,481.760071 1774.119507,483.424561 1774.635986,485.150970
	C1776.237915,484.741547 1775.755493,483.444550 1776.290894,482.766541
	C1777.503174,481.231384 1776.278564,477.265320 1779.256714,478.094177
	C1781.195923,478.633942 1778.598633,481.396881 1778.444824,483.592834
z"
        />
        <path
          fill="#2F3A48"
          opacity="1.000000"
          stroke="none"
          d="
M695.874329,489.923645
	C691.820923,490.926270 688.433167,490.388947 686.338989,486.970245
	C684.292603,483.629608 684.400391,479.949493 686.271729,476.519867
	C687.892578,473.549500 690.730652,472.662506 693.921875,473.043518
	C697.205627,473.435516 699.550232,475.303680 700.168518,478.559448
	C701.003967,482.957550 700.931641,487.252777 695.874329,489.923645
z"
        />
        <path
          fill="#4F5965"
          opacity="1.000000"
          stroke="none"
          d="
M959.644043,423.519775
	C959.648560,426.780304 959.738953,429.602020 959.616577,432.414490
	C959.427429,436.762329 956.662476,438.598328 952.709290,438.541840
	C948.890930,438.487274 946.487427,436.437286 946.375122,432.388794
	C946.287903,429.242188 946.359924,426.091187 946.358582,422.942139
	C946.358154,421.776825 946.819702,420.966095 948.094910,421.110077
	C949.351501,421.252014 948.929626,422.342255 948.943237,423.069153
	C948.995972,425.885742 948.968872,428.703796 948.980957,431.521240
	C948.992310,434.165009 949.818970,436.052551 952.934937,436.044830
	C955.991028,436.037262 956.947205,434.310791 956.909241,431.575958
	C956.870117,428.758911 956.878418,425.940277 956.928406,423.123474
	C956.945251,422.173645 956.896545,420.971680 958.426147,421.198364
	C959.453003,421.350555 959.547424,422.244720 959.644043,423.519775
z"
        />
        <path
          fill="#46505D"
          opacity="1.000000"
          stroke="none"
          d="
M1379.079346,431.952759
	C1380.134155,426.088470 1384.901123,423.466827 1389.434937,425.860229
	C1389.581177,425.937469 1389.749634,425.990173 1389.869751,426.096893
	C1390.728394,426.859161 1390.438599,439.246155 1389.429932,440.713806
	C1387.245361,443.892822 1384.189575,443.647736 1381.014404,442.660767
	C1380.754761,442.580048 1380.588867,442.197601 1380.198975,441.746704
	C1381.483154,440.181824 1383.207886,441.287445 1384.627808,441.080841
	C1386.123901,440.863220 1387.316040,440.293243 1387.299561,438.743866
	C1387.276733,436.600830 1385.674316,437.893463 1384.770752,437.884644
	C1381.073486,437.848755 1379.438721,435.775055 1379.079346,431.952759
z"
        />
        <path
          fill="#46505D"
          opacity="1.000000"
          stroke="none"
          d="
M988.053040,521.279358
	C986.507324,520.937073 984.932739,521.450928 984.330261,519.599670
	C985.117676,518.350769 986.349182,519.112000 987.350220,519.074890
	C989.029053,519.012695 991.122803,519.193665 991.229309,517.013184
	C991.378235,513.963684 989.120544,516.249756 988.041016,516.019165
	C983.901367,515.134949 982.742126,512.329163 983.138367,508.654480
	C983.536255,504.963501 985.539795,503.280457 989.542053,503.521423
	C993.284058,503.746704 995.155945,504.756256 994.357727,508.889954
	C993.987549,510.806671 994.309998,512.853027 994.277527,514.839966
	C994.214539,518.690613 992.637573,521.190613 988.053040,521.279358
z"
        />
        <path
          fill="#4F5965"
          opacity="1.000000"
          stroke="none"
          d="
M1836.656616,505.129089
	C1836.656616,507.258911 1836.382324,508.973816 1836.707275,510.566467
	C1837.771362,515.782532 1834.504517,516.323792 1830.726074,516.326050
	C1826.348511,516.328613 1825.022461,513.476074 1825.087646,509.751312
	C1825.158813,505.681213 1827.024048,502.831116 1831.496826,503.574432
	C1833.795898,503.956543 1833.932129,503.322784 1833.953979,501.492249
	C1833.967651,500.345978 1833.159302,498.337067 1835.067383,498.260254
	C1837.413818,498.165802 1836.443237,500.382202 1836.628540,501.676025
	C1836.768311,502.651398 1836.656494,503.662781 1836.656616,505.129089
z"
        />
        <path
          fill="#46505D"
          opacity="1.000000"
          stroke="none"
          d="
M1034.117188,462.562744
	C1034.080933,461.613556 1034.042969,461.111176 1034.079712,460.614319
	C1034.286255,457.822754 1033.012451,454.474609 1034.743286,452.480652
	C1035.448364,451.668213 1039.303711,451.542419 1041.892334,452.253571
	C1044.449097,452.955841 1044.562134,449.995972 1045.842041,447.998322
	C1047.121948,450.166321 1048.026611,452.329346 1050.820923,452.774506
	C1048.530884,453.831024 1046.332886,454.375732 1046.993896,457.524719
	C1047.499756,459.934906 1045.954712,463.258850 1051.624146,462.482117
	C1045.232422,465.013184 1044.603882,464.489105 1044.430298,457.247894
	C1044.379028,455.105988 1043.858643,453.207123 1041.675903,453.593414
	C1039.837646,453.918701 1036.539795,453.381531 1036.904297,457.186066
	C1037.062378,458.836548 1037.047852,460.528534 1036.882935,462.178802
	C1036.662720,464.380463 1035.696411,464.816681 1034.117188,462.562744
z"
        />
        <path
          fill="#46505D"
          opacity="1.000000"
          stroke="none"
          d="
M1358.357422,435.216461
	C1354.611206,438.874298 1353.453491,439.130829 1348.708984,437.108246
	C1348.800293,436.813751 1348.816772,436.336884 1349.004028,436.256134
	C1350.760376,435.498688 1353.628418,437.628601 1354.351318,435.272705
	C1355.190308,432.537903 1351.725830,433.134979 1350.346191,432.014587
	C1349.091675,430.995758 1348.210327,429.957153 1348.591797,428.250824
	C1348.973633,426.542114 1350.215454,425.791840 1351.794556,425.550873
	C1353.439087,425.299896 1355.090210,425.229187 1356.384766,426.605927
	C1356.228394,426.945801 1356.172363,427.379700 1355.961304,427.476929
	C1354.448608,428.173523 1351.836304,426.127625 1351.315552,428.720856
	C1350.897705,430.801666 1353.503906,430.840515 1354.875244,431.395325
	C1356.504883,432.054565 1356.723267,433.996948 1359.085693,435.016846
	C1359.085693,432.192932 1359.099854,429.804596 1359.079102,427.416565
	C1359.070557,426.415375 1359.541870,425.810242 1360.510254,425.813599
	C1361.486572,425.816956 1361.929199,426.446472 1361.922729,427.436401
	C1361.905518,430.095642 1361.963745,432.756073 1361.902100,435.413940
	C1361.877686,436.458221 1362.346558,437.987152 1360.473755,437.958130
	C1358.753784,437.931549 1359.214600,436.326935 1358.357422,435.216461
z"
        />
        <path
          fill="#4F5965"
          opacity="1.000000"
          stroke="none"
          d="
M1877.953247,504.163391
	C1877.985718,506.602295 1877.951294,508.594635 1878.067871,510.578094
	C1878.210083,512.995667 1879.610229,514.058899 1882.002441,514.044617
	C1884.411377,514.030212 1885.782349,512.903564 1885.859375,510.500732
	C1885.954834,507.519531 1885.865356,504.532776 1885.926880,501.549896
	C1885.945312,500.652588 1885.598999,499.331024 1887.018066,499.215607
	C1888.761108,499.073822 1888.619507,500.528290 1888.633057,501.646851
	C1888.669312,504.630310 1888.756104,507.620636 1888.604614,510.597137
	C1888.397339,514.677490 1885.755981,516.494507 1882.019531,516.544678
	C1878.249146,516.595398 1875.627686,514.779846 1875.395020,510.696350
	C1875.234985,507.886841 1875.302612,505.060364 1875.370483,502.243927
	C1875.395996,501.182953 1874.874878,499.495758 1876.413818,499.338470
	C1878.576538,499.117462 1877.715942,501.096832 1877.936157,502.223816
	C1878.029785,502.702972 1877.952759,503.215515 1877.953247,504.163391
z"
        />
        <path
          fill="#4F5965"
          opacity="1.000000"
          stroke="none"
          d="
M1343.843262,421.206116
	C1346.911133,426.104065 1346.356689,431.277893 1344.300537,436.074158
	C1343.192749,438.658325 1340.055420,438.800781 1337.240845,438.326324
	C1334.592651,437.879852 1332.855103,436.360474 1332.617310,433.833710
	C1332.276489,430.211609 1332.416992,426.543060 1332.384888,422.894043
	C1332.377319,422.030945 1332.622559,421.136414 1333.676758,421.156067
	C1334.733032,421.175812 1334.909668,422.105286 1334.921997,422.947968
	C1334.963379,425.771667 1334.958130,428.596008 1334.975952,431.420074
	C1334.992432,434.025604 1335.690430,436.013733 1338.850220,436.043945
	C1342.276611,436.076691 1342.870483,433.998840 1342.943848,431.146790
	C1343.028564,427.858582 1342.319336,424.520844 1343.843262,421.206116
z"
        />
        <path
          fill="#4F5965"
          opacity="1.000000"
          stroke="none"
          d="
M1065.593872,516.348145
	C1065.478638,511.469238 1065.408081,506.326324 1065.349365,501.183289
	C1065.332520,499.705048 1065.339600,498.548065 1067.578857,498.973846
	C1070.229492,499.477814 1073.068237,498.619873 1076.127930,499.598267
	C1074.931763,501.994080 1073.110352,501.153290 1071.695068,501.304260
	C1070.005859,501.484406 1068.048340,500.725952 1068.025513,503.789856
	C1068.002930,506.812042 1069.877563,506.270599 1071.633423,506.353760
	C1072.578735,506.398499 1073.667358,506.065308 1074.488403,507.458801
	C1073.572998,510.486603 1066.789673,506.184753 1068.150757,511.938416
	C1069.201660,516.380981 1073.881714,512.023804 1076.013672,515.152466
	C1072.613159,516.976501 1069.024902,515.725830 1065.593872,516.348145
z"
        />
        <path
          fill="#4F5965"
          opacity="1.000000"
          stroke="none"
          d="
M617.860535,462.991882
	C615.484009,459.659515 615.355713,456.485809 617.390137,453.414856
	C619.187439,450.701782 621.982422,451.372955 624.471497,451.818268
	C625.791809,450.047638 623.570557,446.345917 626.241699,446.426605
	C629.002808,446.509979 627.306091,449.945160 627.598877,451.869873
	C627.870605,453.656433 627.657043,455.516785 627.656860,457.344666
	C627.655945,464.613617 625.625671,465.875885 617.860535,462.991882
z"
        />
        <path
          fill="#4F5965"
          opacity="1.000000"
          stroke="none"
          d="
M1482.309570,437.392334
	C1481.161011,433.874695 1483.341431,430.532867 1480.425781,427.259125
	C1478.505005,425.102478 1484.657227,419.826569 1488.279541,420.566803
	C1488.525879,421.360901 1488.219849,421.870392 1487.419800,421.928864
	C1485.992310,422.033173 1484.650024,422.264893 1484.824097,424.116180
	C1485.013428,426.128998 1486.686523,425.452423 1487.893555,425.698120
	C1489.504028,426.026001 1492.182861,424.540619 1492.482910,427.173523
	C1492.872437,430.591187 1493.201172,434.163513 1492.167847,437.559021
	C1491.963501,438.230988 1490.683228,438.287170 1490.271240,437.235504
	C1490.039917,436.644714 1490.287720,435.850250 1490.018433,435.293091
	C1488.874878,432.927155 1492.740601,428.092163 1487.245850,428.123779
	C1482.048340,428.153687 1485.695679,432.748077 1484.707275,435.077484
	C1484.203003,436.266052 1485.173584,438.415894 1482.309570,437.392334
z"
        />
        <path
          fill="#2F3A48"
          opacity="1.000000"
          stroke="none"
          d="
M1748.496826,433.893768
	C1748.113159,435.566986 1749.434814,437.855988 1747.072266,437.826416
	C1745.180542,437.802795 1746.158813,435.758972 1746.013306,434.615906
	C1745.846802,433.306152 1745.941040,431.958282 1745.979248,430.628632
	C1746.036865,428.623688 1745.304810,427.415039 1743.092407,427.603973
	C1741.136230,427.771088 1740.081909,428.682587 1740.203003,430.806976
	C1740.307007,432.630463 1740.209839,434.465210 1740.207275,436.294983
	C1740.206177,437.174133 1740.095093,437.998871 1738.943970,437.951355
	C1737.790283,437.903748 1737.793457,437.020508 1737.791626,436.182678
	C1737.781738,431.524963 1737.771240,426.867279 1737.768066,422.209564
	C1737.767578,421.384338 1737.719849,420.367401 1738.793457,420.277100
	C1740.254639,420.154175 1740.295776,421.401337 1740.184937,422.347443
	C1739.833496,425.349304 1740.528809,426.087982 1743.930908,425.716431
	C1750.061523,425.046875 1748.013916,430.218872 1748.496826,433.893768
z"
        />
        <path
          fill="#2F3A48"
          opacity="1.000000"
          stroke="none"
          d="
M1459.995605,463.959778
	C1457.747925,458.667633 1459.251221,453.215302 1458.823120,447.854462
	C1458.755493,447.007416 1459.082520,446.126007 1460.202271,446.276245
	C1460.959351,446.377777 1461.299316,447.112915 1461.170410,447.767426
	C1460.332642,452.019073 1462.511353,452.216766 1465.836548,451.610840
	C1467.592529,451.290924 1469.055298,452.325714 1469.259155,454.400665
	C1469.356445,455.390991 1469.662598,456.441895 1469.447021,457.365692
	C1468.937256,459.549500 1471.001587,463.413696 1468.007446,463.564453
	C1465.825317,463.674347 1467.322144,459.695099 1467.036377,457.575165
	C1466.764160,455.556488 1467.145264,453.401459 1464.051392,453.602051
	C1461.224609,453.785339 1461.263550,455.708801 1461.164551,457.839813
	C1461.069702,459.880280 1462.015137,462.097809 1459.995605,463.959778
z"
        />
        <path
          fill="#2F3A48"
          opacity="1.000000"
          stroke="none"
          d="
M1001.781006,429.964966
	C1001.785706,427.014557 1001.764526,424.523193 1001.809998,422.033051
	C1001.824646,421.230194 1001.865173,420.238861 1003.025024,420.277618
	C1004.136292,420.314728 1004.268188,421.276520 1004.184753,422.085144
	C1003.868225,425.152039 1004.434509,426.528137 1008.160278,425.661194
	C1011.787781,424.817139 1012.692078,427.746826 1012.428833,430.668213
	C1012.208801,433.109589 1013.891418,437.317627 1011.202271,437.547485
	C1008.814148,437.751587 1010.359985,433.527771 1010.039368,431.301422
	C1009.768555,429.421204 1010.000793,427.496674 1007.283020,427.576569
	C1004.618713,427.654877 1004.290222,429.311493 1004.125061,431.593750
	C1003.972229,433.705719 1005.398804,437.691284 1003.177124,437.584778
	C1000.268188,437.445312 1002.454041,433.557495 1001.793762,431.416901
	C1001.700195,431.113647 1001.780884,430.756653 1001.781006,429.964966
z"
        />
        <path
          fill="#2F3A48"
          opacity="1.000000"
          stroke="none"
          d="
M540.878174,477.547241
	C543.221375,477.593323 545.031555,477.899261 545.245667,480.341034
	C545.519653,483.466675 546.094238,486.669586 544.914062,489.658600
	C544.623535,490.394531 543.049438,490.083771 543.010071,488.625854
	C542.956299,486.630707 542.942932,484.632690 542.982361,482.637299
	C543.021912,480.635742 542.316528,479.416168 540.100159,479.603241
	C538.148071,479.768036 537.082458,480.671143 537.203369,482.799957
	C537.307007,484.623779 537.210327,486.458832 537.207581,488.288910
	C537.206238,489.165558 537.101685,489.993774 535.949707,489.951447
	C534.794983,489.908997 534.792175,489.027618 534.791809,488.188324
	C534.789673,483.529877 534.780579,478.871399 534.799072,474.213013
	C534.802307,473.405975 534.778259,472.362152 535.852783,472.295563
	C537.333008,472.203796 537.205444,473.472687 537.197571,474.415375
	C537.178772,476.667389 536.859192,479.131866 540.878174,477.547241
z"
        />
        <path
          fill="#46505D"
          opacity="1.000000"
          stroke="none"
          d="
M1745.945557,453.144165
	C1742.050415,453.879791 1743.085815,457.962677 1741.305664,460.398590
	C1740.411255,461.622681 1740.858154,464.038513 1738.586548,463.984131
	C1736.380981,463.931305 1736.598999,461.626617 1735.932495,460.254578
	C1734.994995,458.323914 1734.333740,456.257355 1733.584717,454.237640
	C1733.277222,453.408539 1732.292969,452.332825 1733.817871,451.848602
	C1734.950806,451.488800 1735.577637,452.403748 1735.936646,453.440796
	C1736.692139,455.622223 1737.483154,457.791321 1738.280396,460.023895
	C1740.229370,459.097076 1740.477905,457.118774 1740.579834,455.788055
	C1740.981934,450.542480 1745.097656,452.646423 1747.705444,451.930603
	C1748.661987,451.668060 1748.913208,452.776428 1748.913086,453.654907
	C1748.912720,456.477631 1748.926636,459.300446 1748.908081,462.123016
	C1748.902588,462.958069 1748.817017,463.834045 1747.725708,463.954102
	C1746.342651,464.106201 1746.096313,463.171356 1746.086792,462.090424
	C1746.062134,459.267914 1746.051880,456.445221 1745.945557,453.144165
z"
        />
        <path
          fill="#2F3A48"
          opacity="1.000000"
          stroke="none"
          d="
M1025.780518,512.516174
	C1025.771362,510.564880 1025.776733,509.070496 1025.749268,507.576752
	C1025.710693,505.483490 1024.093872,505.906281 1022.873901,505.885620
	C1021.641357,505.864746 1020.035217,505.438385 1020.037537,507.579865
	C1020.038818,508.741974 1020.206909,509.939575 1019.982971,511.057739
	C1019.658020,512.680237 1021.090515,515.630188 1018.986633,515.670776
	C1016.678162,515.715332 1018.245789,512.763245 1017.797424,511.197083
	C1017.259766,509.319031 1018.842163,507.017212 1016.274353,505.323547
	C1015.864319,505.053070 1017.455688,500.987366 1018.751343,499.110870
	C1019.993042,497.312622 1022.088318,497.605103 1023.727112,498.541687
	C1024.071655,499.626556 1023.464417,499.888397 1023.080261,499.879059
	C1021.389832,499.838135 1019.973938,500.156250 1020.111328,502.227081
	C1020.277344,504.728394 1022.321228,503.440521 1023.557007,503.779266
	C1025.012451,504.178314 1027.540771,502.734070 1027.806152,504.891571
	C1028.245117,508.458191 1027.776611,512.136719 1027.630127,515.765625
	C1027.629395,515.781311 1026.392090,515.877686 1026.348022,515.739746
	C1026.063354,514.848145 1025.949829,513.901794 1025.780518,512.516174
z"
        />
        <path
          fill="#46505D"
          opacity="1.000000"
          stroke="none"
          d="
M1368.797119,458.486145
	C1367.810303,462.209930 1366.212769,465.034912 1361.931885,464.111115
	C1359.705322,463.630646 1359.981567,465.028015 1359.913940,466.352417
	C1359.859619,467.417908 1360.301270,468.971344 1358.527588,468.940125
	C1356.367554,468.902069 1357.162476,467.065796 1357.109741,465.934265
	C1356.994629,463.461975 1357.342407,460.944550 1357.015137,458.510895
	C1356.409180,454.005859 1357.178955,451.186127 1362.679199,451.373169
	C1367.399902,451.533661 1368.501709,454.272186 1368.797119,458.486145
z"
        />
        <path
          fill="#2F3A48"
          opacity="1.000000"
          stroke="none"
          d="
M570.364258,451.936768
	C573.360657,451.240570 575.797607,451.231995 577.191711,453.996277
	C578.542419,456.674591 578.524414,459.464813 576.911194,462.030029
	C575.463806,464.331512 573.192078,463.992737 570.768188,464.026825
	C566.994446,464.079865 566.459839,462.387024 566.700317,459.222778
	C566.962769,455.769287 566.738159,452.280884 566.804443,448.809204
	C566.824219,447.770111 566.396912,446.122650 568.211975,446.298889
	C569.464661,446.420532 569.099792,447.818726 569.225220,448.752167
	C569.370850,449.834839 568.761292,451.097137 570.364258,451.936768
z"
        />
        <path
          fill="#2F3A48"
          opacity="1.000000"
          stroke="none"
          d="
M730.797729,421.986938
	C732.885315,420.331482 733.186768,420.338074 733.139771,422.484985
	C733.080505,425.194763 733.480652,426.080261 736.643921,425.697266
	C741.060791,425.162476 742.129150,428.501343 742.004761,432.212860
	C741.883362,435.833588 740.334290,438.251801 736.310852,438.173431
	C732.513916,438.099426 729.764343,437.305298 730.672180,432.295898
	C731.252747,429.092346 730.782532,425.698303 730.797729,421.986938
z"
        />
        <path
          fill="#2F3A48"
          opacity="1.000000"
          stroke="none"
          d="
M522.780823,507.702759
	C523.107910,506.149902 522.081177,503.802765 523.852539,504.075714
	C526.694824,504.513733 529.801636,502.026459 532.351013,504.793640
	C534.379578,506.995514 534.419556,509.684174 533.719238,512.314636
	C533.062744,514.780457 531.306030,516.506165 528.602905,516.142456
	C525.855347,515.772888 524.971497,516.795227 525.199951,519.338867
	C525.281738,520.250427 525.014038,521.034302 523.886597,520.947205
	C522.736267,520.858398 522.794006,519.938110 522.792297,519.115417
	C522.784485,515.448975 522.783997,511.782562 522.780823,507.702759
z"
        />
        <path
          fill="#4F5965"
          opacity="1.000000"
          stroke="none"
          d="
M1445.105469,433.879211
	C1446.763672,438.018188 1450.704468,434.321411 1453.085449,437.008179
	C1450.040161,439.113373 1446.750977,437.760620 1443.707764,437.986267
	C1442.722168,438.059357 1442.390259,437.116516 1442.389038,436.231232
	C1442.382690,431.734131 1442.388306,427.236908 1442.429443,422.740082
	C1442.435425,422.079376 1442.661499,421.313446 1443.451904,421.243195
	C1446.624023,420.961243 1449.807617,420.720459 1453.364502,421.497864
	C1451.638794,424.085938 1449.812988,423.159119 1448.287598,423.287537
	C1446.621216,423.427795 1445.044434,423.070282 1445.028931,425.714935
	C1445.014771,428.143097 1446.164917,428.399170 1448.077637,428.380432
	C1449.270752,428.368713 1450.825439,427.766266 1451.436279,429.511719
	C1450.382446,432.590424 1444.687744,428.011322 1445.105469,433.879211
z"
        />
        <path
          fill="#2F3A48"
          opacity="1.000000"
          stroke="none"
          d="
M699.984253,430.357361
	C700.136658,435.400635 698.839050,438.791962 693.240601,438.022797
	C690.501587,437.646484 691.391968,439.590179 691.226318,440.873932
	C691.105774,441.807800 691.398560,443.075897 689.867554,442.932617
	C688.390869,442.794434 688.811340,441.505371 688.799500,440.613159
	C688.755310,437.289398 688.606628,433.951477 688.857178,430.646179
	C688.976074,429.078522 687.545593,426.154694 690.013123,426.130035
	C692.853394,426.101593 696.308777,423.911926 698.734131,427.256226
	C699.301880,428.039001 699.553894,429.050659 699.984253,430.357361
z"
        />
        <path
          fill="#2F3A48"
          opacity="1.000000"
          stroke="none"
          d="
M901.799438,479.297943
	C904.950806,478.446381 908.137817,476.087128 911.218567,478.682434
	C913.342468,480.471558 913.300232,483.146179 912.893677,485.654144
	C912.558350,487.722717 911.458313,489.725983 909.270325,489.921844
	C906.303406,490.187469 903.183899,489.663849 904.040405,494.310028
	C904.066956,494.454132 903.311584,494.928986 902.921265,494.928314
	C901.748047,494.926300 901.793640,493.986725 901.792114,493.164001
	C901.783691,488.668365 901.785095,484.172699 901.799438,479.297943
z"
        />
        <path
          fill="#4F5965"
          opacity="1.000000"
          stroke="none"
          d="
M640.106018,451.848816
	C640.670898,456.507080 644.923218,452.770782 646.441711,455.404846
	C645.835266,457.264832 644.100647,456.670990 642.852844,456.612274
	C640.613464,456.506866 640.091797,457.446198 640.059143,459.536469
	C640.025269,461.714050 641.045044,461.889954 642.778870,461.954071
	C644.453674,462.015961 646.383545,461.168274 648.242859,463.280243
	C644.496643,464.494141 641.131104,464.575928 637.750122,463.402435
	C637.221436,463.218933 636.964417,448.485199 637.564087,448.104126
	C640.871155,446.002838 644.296875,447.124023 648.241272,447.571869
	C646.665283,450.118500 644.685669,449.107056 643.099365,449.300476
	C641.674988,449.474152 639.911316,448.972107 640.106018,451.848816
z"
        />
        <path
          fill="#2F3A48"
          opacity="1.000000"
          stroke="none"
          d="
M915.780884,489.694458
	C915.780884,487.418060 916.135193,485.506836 915.715027,483.784607
	C914.406067,478.418701 917.701416,477.653717 921.651550,477.558014
	C925.467163,477.465576 926.856201,479.846405 927.021240,483.278473
	C927.244995,487.932037 925.439819,490.105225 920.731506,490.022278
	C918.656799,489.985748 918.112183,490.440247 918.176758,492.367035
	C918.209351,493.341797 918.796936,495.053772 916.884460,494.906464
	C915.098572,494.768921 915.977661,493.131958 915.802368,492.132996
	C915.689453,491.489258 915.781006,490.809631 915.780884,489.694458
z"
        />
        <path
          fill="#2F3A48"
          opacity="1.000000"
          stroke="none"
          d="
M714.783813,486.298462
	C713.942932,488.219849 713.030334,489.907837 711.234253,489.948608
	C708.151855,490.018494 705.221863,489.794098 706.034058,494.340668
	C706.058167,494.475708 705.298523,494.908508 704.890137,494.929596
	C704.080627,494.971436 703.800964,494.364929 703.800598,493.636810
	C703.798401,488.815002 703.380981,483.940643 703.972046,479.192108
	C704.298706,476.568024 707.503296,478.476166 709.351318,477.735870
	C711.511230,476.870605 713.476868,478.217224 714.489319,480.525330
	C715.265625,482.294891 715.017639,484.105103 714.783813,486.298462
z"
        />
        <path
          fill="#2F3A48"
          opacity="1.000000"
          stroke="none"
          d="
M1933.181152,436.920471
	C1931.961548,437.999878 1930.801270,438.391510 1929.651367,438.153320
	C1927.230591,437.651917 1925.771484,438.009949 1926.189575,440.966034
	C1926.325684,441.928375 1926.288940,443.109955 1924.775635,442.939758
	C1923.620605,442.809845 1923.795898,441.808777 1923.793091,441.018738
	C1923.778076,436.694183 1923.779175,432.369568 1923.785156,428.044952
	C1923.786865,426.845520 1923.697510,425.726929 1925.603516,425.980438
	C1928.739380,426.397583 1932.753784,423.892609 1934.506348,428.655945
	C1935.532471,431.444855 1935.367310,434.203583 1933.181152,436.920471
z"
        />
        <path
          fill="#2F3A48"
          opacity="1.000000"
          stroke="none"
          d="
M1307.895874,421.148621
	C1308.440063,421.146545 1308.613281,421.114044 1308.770142,421.151245
	C1310.922119,421.661743 1314.000977,419.734100 1315.132080,422.039459
	C1316.344727,424.511139 1312.455811,425.392792 1311.745117,427.870972
	C1315.034058,429.611053 1318.217896,431.963074 1315.124268,436.100098
	C1312.521240,439.580994 1308.977051,438.351044 1304.535400,437.081543
	C1308.089722,434.838104 1313.014648,438.379517 1313.589966,433.362976
	C1313.999390,429.793793 1310.205444,430.704285 1308.221680,429.904419
	C1307.523438,428.543488 1308.626831,427.950256 1309.216309,427.310791
	C1311.471191,424.864471 1312.329590,423.179047 1307.699951,423.154846
	C1305.980347,423.145874 1305.982056,422.823639 1307.895874,421.148621
z"
        />
        <path
          fill="#46505D"
          opacity="1.000000"
          stroke="none"
          d="
M1518.617188,458.904724
	C1517.951782,462.143768 1520.846313,461.881622 1522.670654,462.915436
	C1517.383301,464.823181 1516.175171,464.138153 1515.931519,459.767731
	C1515.875977,458.771759 1515.767334,457.740936 1515.951050,456.777191
	C1516.359741,454.632782 1514.975952,453.391266 1513.426758,453.228546
	C1510.835571,452.956451 1512.171387,455.303772 1511.999512,456.445648
	C1511.729980,458.237061 1511.937744,460.098114 1511.911865,461.929260
	C1511.897583,462.944672 1511.859863,464.061920 1510.422729,463.978546
	C1509.024048,463.897339 1509.098511,462.751007 1509.087524,461.769379
	C1509.063232,459.605194 1509.059082,457.440430 1509.078247,455.276154
	C1509.097900,453.068665 1508.435181,450.733521 1512.417847,452.647644
	C1515.101440,453.937347 1515.868408,450.336182 1516.911743,448.630768
	C1519.002441,448.668884 1518.253784,450.554749 1519.107422,451.146912
	C1520.544556,452.143951 1526.181274,452.527191 1519.327881,454.297455
	C1517.923950,454.660126 1518.808228,457.018585 1518.617188,458.904724
z"
        />
        <path
          fill="#2F3A48"
          opacity="1.000000"
          stroke="none"
          d="
M608.462036,482.504395
	C605.109619,482.406219 605.497803,484.473907 605.531494,486.288757
	C605.571289,488.432068 607.221375,487.800659 608.435730,487.986145
	C609.988159,488.223297 611.722839,487.323792 613.668945,489.167114
	C610.094177,490.616241 606.796082,489.983643 603.575500,489.756989
	C603.011047,489.717255 602.421448,474.550507 603.167236,474.100433
	C606.307373,472.205078 609.628906,473.283325 613.421814,473.466736
	C612.333435,475.863251 610.654846,475.033447 609.417969,475.195801
	C607.726685,475.417847 605.643860,474.627106 605.491516,477.594513
	C605.328491,480.772125 607.402588,480.467499 609.425476,480.523315
	C610.340759,480.548584 611.497070,480.161865 611.924255,481.529480
	C611.302612,483.012482 609.955872,482.294586 608.462036,482.504395
z"
        />
        <path
          fill="#4F5965"
          opacity="1.000000"
          stroke="none"
          d="
M952.036865,504.809875
	C956.137451,502.455902 960.550293,504.789490 964.837280,503.595215
	C966.768433,503.057251 967.875122,505.259552 967.928406,507.310394
	C967.988953,509.641449 968.018066,511.978210 967.909241,514.305359
	C967.881653,514.895691 968.611755,516.120789 967.107483,516.101196
	C966.099487,516.088013 965.052979,516.038025 965.058350,514.578735
	C965.066284,512.412964 965.013062,510.246674 965.042053,508.081360
	C965.064270,506.423065 964.341370,505.570221 962.653320,505.729492
	C961.232849,505.863525 960.054199,506.279022 960.099426,508.091797
	C960.149231,510.089630 960.122986,512.090515 960.079834,514.088989
	C960.063049,514.866089 960.232544,515.866455 959.044800,515.977417
	C957.692505,516.103577 957.392334,515.162354 957.371277,514.084167
	C957.335571,512.252075 957.296814,510.417847 957.345581,508.586823
	C957.412231,506.083344 956.190063,505.322235 953.440002,505.919006
	C952.572571,505.798187 952.268677,505.516479 952.036865,504.809875
z"
        />
        <path
          fill="#46505D"
          opacity="1.000000"
          stroke="none"
          d="
M1804.927490,479.376465
	C1805.057983,480.786560 1804.570435,482.599670 1806.606323,480.897949
	C1808.149536,479.608032 1809.058472,477.121429 1812.041748,477.941162
	C1811.479492,480.616913 1807.373779,481.411835 1807.923950,483.794037
	C1808.418091,485.933411 1810.865845,487.621613 1812.589844,489.673157
	C1808.709106,491.080444 1808.173950,486.750183 1805.576416,485.968994
	C1804.420654,486.734375 1805.150391,488.026459 1804.849731,489.018982
	C1804.611206,489.806519 1804.023682,489.980011 1803.352295,489.978821
	C1802.573608,489.977417 1802.132202,489.484192 1802.129272,488.742798
	C1802.109497,483.760986 1802.108765,478.778992 1802.134277,473.797241
	C1802.138916,472.894135 1802.525391,472.142090 1803.588745,472.220490
	C1804.633911,472.297424 1804.874268,473.129974 1804.895996,473.991760
	C1804.937744,475.651794 1804.919922,477.313324 1804.927490,479.376465
z"
        />
        <path
          fill="#4F5965"
          opacity="1.000000"
          stroke="none"
          d="
M1032.262939,477.511841
	C1036.619141,478.904022 1037.326050,482.035217 1036.773804,485.566162
	C1036.397827,487.970154 1035.073486,489.870361 1032.322266,490.241272
	C1028.399780,490.770142 1025.823975,489.301788 1025.111450,485.276489
	C1024.263062,480.484741 1026.518066,477.925720 1032.262939,477.511841
z"
        />
        <path
          fill="#4F5965"
          opacity="1.000000"
          stroke="none"
          d="
M1940.872437,477.715149
	C1944.015381,477.147583 1946.589600,477.264832 1948.039673,480.198975
	C1949.385376,482.921478 1949.381104,485.656952 1947.680908,488.222565
	C1946.170166,490.502350 1943.790649,490.542572 1941.436035,490.208710
	C1938.981689,489.860748 1937.641235,488.075043 1937.234253,485.859802
	C1936.635132,482.598969 1937.043701,479.536133 1940.872437,477.715149
z"
        />
        <path
          fill="#4F5965"
          opacity="1.000000"
          stroke="none"
          d="
M956.029724,464.316589
	C951.726624,464.196686 950.063843,461.686249 949.978699,458.228973
	C949.888428,454.565491 951.393799,451.646179 955.597534,451.401276
	C959.131836,451.195404 961.370422,453.106415 961.794128,456.532318
	C962.290100,460.542755 961.330505,463.899445 956.029724,464.316589
z"
        />
        <path
          fill="#46505D"
          opacity="1.000000"
          stroke="none"
          d="
M1368.793945,478.324432
	C1370.759644,478.459564 1372.353394,478.754791 1374.200928,477.865417
	C1376.440430,476.787415 1378.813965,477.213043 1380.423096,479.405334
	C1381.368042,480.692810 1381.352661,482.287415 1380.845703,483.699249
	C1380.327759,485.142242 1378.823242,484.323364 1377.792603,484.633331
	C1376.312256,485.078522 1373.460693,483.405518 1373.585571,486.101562
	C1373.690430,488.368683 1376.303223,488.525238 1378.431274,488.176178
	C1379.162476,488.056244 1380.020142,487.695160 1380.878052,488.971313
	C1378.648560,491.062286 1375.976440,490.586761 1373.645752,489.819000
	C1371.397461,489.078369 1371.002808,486.815002 1370.591797,484.551666
	C1370.298096,482.934082 1372.508911,480.857300 1369.396240,479.717499
	C1368.632568,479.431458 1368.509644,479.032166 1368.793945,478.324432
z"
        />
        <path
          fill="#4F5965"
          opacity="1.000000"
          stroke="none"
          d="
M558.407837,505.544189
	C560.109314,502.789185 562.795349,502.965668 565.317688,503.856873
	C568.147278,504.856689 567.574951,507.621216 567.637512,509.877686
	C567.815002,516.279175 566.864563,517.088989 560.786987,516.313110
	C558.825134,516.062683 557.652100,515.097473 557.443604,513.233521
	C557.212280,511.165680 558.367615,509.948975 560.215942,508.996796
	C561.736206,508.213684 565.245728,510.602325 564.684937,507.215973
	C564.168945,504.100708 560.777161,506.232086 558.407837,505.544189
z"
        />
        <path
          fill="#2F3A48"
          opacity="1.000000"
          stroke="none"
          d="
M998.225098,456.835724
	C997.646729,459.496338 999.475464,463.538452 996.976501,463.555847
	C994.612000,463.572296 996.335144,459.469727 995.777222,457.267731
	C994.136658,450.792450 999.377441,451.970490 1002.521423,451.637390
	C1006.745178,451.189911 1006.535706,454.752106 1006.473267,457.516510
	C1006.424866,459.657806 1007.480530,463.651733 1005.429260,463.546600
	C1002.393677,463.391022 1004.527649,459.632660 1004.033508,457.501587
	C1003.676514,455.961914 1004.332764,453.902008 1001.995056,453.652557
	C1000.139832,453.454529 998.222900,453.718140 998.225098,456.835724
z"
        />
        <path
          fill="#4F5965"
          opacity="1.000000"
          stroke="none"
          d="
M651.441406,512.908569
	C653.066772,512.621704 651.899475,510.993073 651.997864,509.906586
	C652.538086,503.940430 655.785095,502.125183 661.706787,505.079437
	C663.581360,509.198517 662.639038,511.078674 658.212646,510.719086
	C657.138184,510.631775 655.135925,509.907837 655.153687,512.019653
	C655.169312,513.882263 656.982666,513.956909 658.459167,514.346313
	C659.677856,514.667786 662.016296,512.572083 661.951294,515.088806
	C661.891174,517.417053 659.291748,516.065552 657.821472,516.305664
	C654.817383,516.796387 653.439514,514.522705 651.441406,512.908569
z"
        />
        <path
          fill="#4F5965"
          opacity="1.000000"
          stroke="none"
          d="
M1463.603271,477.434998
	C1467.126465,477.705048 1467.664673,479.869354 1467.641724,482.458130
	C1467.618530,485.085114 1465.637695,484.416748 1464.087891,484.702667
	C1462.701782,484.958435 1460.115967,483.508728 1460.214233,486.134827
	C1460.297729,488.368256 1462.682129,488.362061 1464.573364,488.195709
	C1465.363281,488.126221 1466.153320,488.058746 1467.193848,487.968689
	C1465.496338,490.215668 1462.932861,490.992981 1460.552490,490.044678
	C1457.367798,488.775970 1456.821411,486.023254 1457.091064,483.068970
	C1457.424194,479.417847 1459.392456,477.316315 1463.603271,477.434998
z"
        />
        <path
          fill="#4F5965"
          opacity="1.000000"
          stroke="none"
          d="
M1813.037109,452.810089
	C1817.129883,450.466278 1821.513672,452.595184 1825.813965,451.632172
	C1828.338745,451.066803 1829.263672,453.970215 1828.883911,456.262756
	C1828.456787,458.841400 1830.582520,463.406281 1827.597412,463.581879
	C1824.419556,463.768890 1826.593506,459.342438 1826.111938,457.009644
	C1825.774048,455.372467 1826.174438,453.553162 1823.604980,453.714844
	C1821.367554,453.855591 1821.183472,455.260590 1821.028809,457.123688
	C1820.838379,459.416565 1822.066772,463.734039 1820.045288,463.608734
	C1816.500610,463.388947 1818.736084,459.377228 1818.442749,457.062408
	C1818.136230,454.644867 1817.688354,453.171844 1814.439941,453.919067
	C1813.575928,453.794678 1813.266968,453.518707 1813.037109,452.810089
z"
        />
        <path
          fill="#2F3A48"
          opacity="1.000000"
          stroke="none"
          d="
M1350.973389,457.112305
	C1351.077515,454.294647 1350.012817,453.309235 1347.642212,453.656708
	C1345.468628,453.975250 1345.344604,455.427979 1345.154297,457.226471
	C1344.916138,459.476990 1346.176392,463.764252 1344.095337,463.544800
	C1341.274780,463.247345 1343.353394,459.189423 1342.784790,456.839569
	C1341.211182,450.335907 1346.668457,452.143707 1349.464478,451.663330
	C1353.233276,451.015869 1353.426025,454.189117 1353.510498,456.953552
	C1353.515503,457.119049 1353.561401,457.294617 1353.521851,457.448364
	C1352.967041,459.599060 1354.962646,463.440948 1351.976318,463.561707
	C1349.839600,463.648102 1351.545044,459.659821 1350.973389,457.112305
z"
        />
        <path
          fill="#4F5965"
          opacity="1.000000"
          stroke="none"
          d="
M1426.968750,458.555542
	C1429.410034,460.080353 1429.661377,458.653229 1430.171997,456.463135
	C1431.195557,452.072571 1434.855103,450.206268 1438.286621,451.907104
	C1440.214478,452.862732 1440.623901,454.528961 1440.645630,456.455841
	C1440.665771,458.241394 1439.580688,458.455414 1438.099976,458.721222
	C1436.406128,459.025330 1433.150024,457.401825 1433.310059,460.105743
	C1433.490967,463.162781 1436.783569,462.166626 1439.026001,462.011627
	C1439.326050,461.990906 1439.639282,462.160675 1439.944580,462.248962
	C1437.878906,465.099915 1433.464722,465.136322 1431.232910,462.329926
	C1430.342285,461.209930 1430.432983,459.381378 1428.348999,459.212769
	C1427.548462,459.471161 1427.207886,459.245758 1426.968750,458.555542
z"
        />
        <path
          fill="#2F3A48"
          opacity="1.000000"
          stroke="none"
          d="
M1106.016357,421.357727
	C1108.217407,424.105042 1106.275269,423.287048 1104.832275,423.319183
	C1099.582275,423.436157 1097.777954,425.613678 1098.216553,430.844330
	C1098.749023,437.194611 1103.492188,435.976959 1107.968018,435.932770
	C1106.354248,439.348999 1103.622314,438.099609 1101.514038,438.023041
	C1098.767822,437.923279 1096.856934,436.172180 1096.107910,433.572113
	C1093.700562,425.216431 1098.590210,419.578522 1106.016357,421.357727
z"
        />
        <path
          fill="#4F5965"
          opacity="1.000000"
          stroke="none"
          d="
M1007.461487,506.647278
	C1008.299622,510.517456 1006.222595,510.752899 1003.556580,510.728577
	C1002.359131,510.717682 1000.102051,509.701019 1000.166016,512.150269
	C1000.211914,513.909912 1002.035950,514.247314 1003.588806,514.268677
	C1004.408081,514.279968 1005.229126,514.084473 1006.051086,513.999939
	C1006.926575,513.909851 1007.255554,514.418762 1007.116028,515.176025
	C1007.032532,515.629211 1006.619751,516.433350 1006.497192,516.407532
	C1003.604614,515.795837 999.891052,517.575745 998.030640,513.913147
	C996.306152,510.518158 996.212952,506.896759 999.484070,504.508484
	C1002.320007,502.437988 1005.437317,502.569000 1007.461487,506.647278
z"
        />
        <path
          fill="#2F3A48"
          opacity="1.000000"
          stroke="none"
          d="
M1288.020996,487.922699
	C1290.112427,488.529144 1291.970093,487.275635 1293.693237,488.373901
	C1290.649780,490.858185 1286.192139,490.780792 1283.691162,488.274017
	C1280.982544,485.559174 1280.633057,478.665131 1283.334595,475.781799
	C1286.216309,472.706299 1289.639404,472.157379 1294.475830,474.918213
	C1289.786743,475.571075 1284.932129,474.410278 1284.311157,479.918945
	C1283.962280,483.016144 1283.725098,486.394348 1288.020996,487.922699
z"
        />
        <path
          fill="#4F5965"
          opacity="1.000000"
          stroke="none"
          d="
M1075.341797,428.053925
	C1076.079834,428.008575 1076.471436,427.108948 1077.113403,426.676086
	C1079.925171,424.780548 1082.822266,424.653778 1085.192139,427.157166
	C1087.449219,429.541382 1087.519043,432.512756 1086.108398,435.465912
	C1084.691895,438.431305 1082.023926,438.741486 1079.342773,438.158295
	C1075.781006,437.383545 1074.918213,434.551025 1075.078491,431.304474
	C1075.126709,430.327972 1075.427246,429.363922 1075.341797,428.053925
z"
        />
        <path
          fill="#4F5965"
          opacity="1.000000"
          stroke="none"
          d="
M1837.706787,451.408142
	C1840.952881,452.954926 1843.559814,462.060242 1841.196045,463.289398
	C1839.164185,464.345886 1836.232544,464.856964 1833.630005,464.054169
	C1831.702271,463.459442 1831.191650,461.827087 1831.471924,460.067566
	C1831.907227,457.334991 1834.019653,456.951965 1836.337891,456.873230
	C1837.328125,456.839569 1838.938354,457.535645 1838.916870,455.588287
	C1838.897217,453.801056 1837.493164,453.630188 1836.098755,453.540588
	C1834.869263,453.461548 1833.607422,454.395782 1832.123535,453.299652
	C1833.376099,451.140961 1835.481812,451.574463 1837.706787,451.408142
z"
        />
        <path
          fill="#2F3A48"
          opacity="1.000000"
          stroke="none"
          d="
M1003.975098,486.781250
	C1003.974915,486.164307 1004.015869,485.985901 1003.968689,485.835083
	C1003.289307,483.664642 1005.779541,479.769043 1001.335083,479.679108
	C996.669861,479.584717 998.636108,483.575226 998.238953,486.015442
	C998.107544,486.822815 998.181763,487.668762 998.208679,488.495514
	C998.242065,489.521301 997.776672,490.068329 996.757202,489.945709
	C995.967041,489.850677 995.786987,489.232666 995.791565,488.503662
	C995.810303,485.523560 995.241577,482.400024 995.955688,479.601471
	C996.744080,476.511719 1000.185608,478.581970 1002.332825,477.671112
	C1003.936584,476.990845 1006.048889,477.872162 1006.226257,480.305237
	C1006.406372,482.775787 1006.437988,485.258881 1006.473572,487.737366
	C1006.487183,488.692932 1006.579590,489.987885 1005.168762,489.915009
	C1003.325439,489.819763 1004.224731,488.206390 1003.975098,486.781250
z"
        />
        <path
          fill="#4F5965"
          opacity="1.000000"
          stroke="none"
          d="
M1759.094971,488.911499
	C1755.123779,491.095795 1751.697021,491.261871 1749.671997,486.964020
	C1748.312256,484.078552 1748.606201,481.027161 1751.099487,478.756348
	C1753.123169,476.913025 1755.664307,476.733856 1758.011597,478.142090
	C1760.789429,479.808685 1761.282837,482.591980 1760.786743,485.530121
	C1760.603027,486.618011 1759.858032,487.611115 1759.094971,488.911499
z"
        />
        <path
          fill="#46505D"
          opacity="1.000000"
          stroke="none"
          d="
M1700.769043,426.677490
	C1702.676270,424.704773 1703.390015,420.695068 1705.782227,421.435944
	C1708.618774,422.314392 1706.565186,426.011658 1707.069946,428.415985
	C1707.377563,429.881439 1706.773682,431.258667 1708.418701,432.747803
	C1710.331665,434.479431 1707.092773,436.077515 1706.389526,437.536560
	C1705.540527,439.298218 1705.005249,436.738983 1704.526611,435.723816
	C1703.792358,434.166626 1702.247925,434.816986 1701.011597,434.601074
	C1699.635010,434.360687 1697.791138,435.439728 1696.963135,433.712006
	C1696.190430,432.099304 1697.838257,431.036865 1698.553711,429.808014
	C1699.133423,428.812408 1699.895630,427.922974 1700.769043,426.677490
z"
        />
        <path
          fill="#4F5965"
          opacity="1.000000"
          stroke="none"
          d="
M983.386230,452.437347
	C986.690796,450.880981 989.614868,450.819550 991.678650,453.705170
	C993.434387,456.159882 993.452393,458.966614 992.006836,461.689331
	C990.591675,464.354797 988.120728,464.636414 985.583313,464.219299
	C980.789368,463.431183 979.660400,458.009705 983.386230,452.437347
z"
        />
        <path
          fill="#2F3A48"
          opacity="1.000000"
          stroke="none"
          d="
M1814.225098,508.840637
	C1813.676758,511.521515 1815.409668,515.609314 1813.007812,515.604065
	C1810.565918,515.598755 1812.336914,511.527405 1811.779907,509.325867
	C1810.164185,502.938995 1815.292725,503.959747 1818.494751,503.636383
	C1822.724609,503.209167 1822.373779,506.695709 1822.495483,509.503632
	C1822.502686,509.668945 1822.536987,509.847137 1822.490723,509.998627
	C1821.895020,511.953339 1823.914429,515.498047 1820.920898,515.592651
	C1819.031738,515.652283 1820.311646,511.979584 1819.997314,510.009338
	C1819.729614,508.331696 1820.636963,506.017853 1817.991089,505.660034
	C1816.148193,505.410828 1814.220337,505.721313 1814.225098,508.840637
z"
        />
        <path
          fill="#2F3A48"
          opacity="1.000000"
          stroke="none"
          d="
M1376.495850,434.748352
	C1376.336060,436.226837 1376.921631,437.844574 1375.227051,437.906311
	C1373.361450,437.974304 1374.093384,436.282806 1374.004517,435.292816
	C1373.886597,433.978149 1373.958252,432.644775 1373.977661,431.319885
	C1374.005737,429.405945 1374.015381,427.506470 1371.305298,427.574860
	C1368.668945,427.641357 1368.026001,429.227081 1368.195801,431.564972
	C1368.315552,433.212250 1368.195068,434.876190 1368.207031,436.532501
	C1368.212280,437.263458 1367.997192,437.858429 1367.212402,437.946472
	C1366.162842,438.064270 1365.781128,437.466858 1365.789551,436.464874
	C1365.814941,433.483582 1365.208130,430.338318 1365.969849,427.565155
	C1366.777954,424.622955 1370.205688,426.487457 1372.369507,425.662354
	C1374.076416,425.011383 1376.030762,425.999146 1376.237915,428.375092
	C1376.410278,430.349182 1376.416748,432.337738 1376.495850,434.748352
z"
        />
        <path
          fill="#4F5965"
          opacity="1.000000"
          stroke="none"
          d="
M1909.441650,434.908875
	C1911.196533,433.818481 1909.565918,431.621674 1910.178345,429.884308
	C1911.093506,427.288452 1912.585205,425.464691 1915.469849,425.395386
	C1917.760864,425.340332 1919.816162,425.990295 1920.437622,428.604980
	C1921.026978,431.083923 1920.751343,432.802948 1917.447632,432.748779
	C1916.002563,432.725067 1913.063965,431.454041 1913.280518,434.260254
	C1913.468384,436.694977 1916.198364,436.347412 1918.222290,436.107178
	C1918.826172,436.035492 1919.445923,436.096161 1920.330688,436.096161
	C1916.904175,439.707733 1913.615112,439.145294 1909.441650,434.908875
z"
        />
        <path
          fill="#2F3A48"
          opacity="1.000000"
          stroke="none"
          d="
M738.242432,463.722565
	C737.859619,460.669617 737.763672,457.854553 737.801636,455.041260
	C737.823853,453.390930 737.084595,451.464203 740.528503,452.315887
	C742.562073,452.818817 745.580322,450.318512 747.402039,452.630402
	C749.568909,455.380371 748.270264,459.029572 748.453369,462.289185
	C748.503113,463.174866 748.205200,463.966736 747.110291,463.939453
	C745.922852,463.909851 746.003052,462.957245 745.989197,462.166748
	C745.957397,460.344147 745.935974,458.519745 745.982727,456.697937
	C746.033020,454.736847 745.413452,453.428162 743.179443,453.596863
	C741.288940,453.739655 740.444946,454.601532 740.116882,456.682190
	C739.734436,459.107666 741.976746,462.183563 738.242432,463.722565
z"
        />
        <path
          fill="#4F5965"
          opacity="1.000000"
          stroke="none"
          d="
M548.259766,464.310638
	C545.902649,464.093475 544.479675,463.044556 543.731262,461.225708
	C542.509888,458.257263 542.564941,455.333191 544.907654,452.932068
	C546.879395,450.911163 549.436157,450.859039 551.799438,452.060181
	C554.721863,453.545532 555.292480,456.372253 554.831055,459.304413
	C554.295959,462.705170 552.236633,464.556641 548.259766,464.310638
z"
        />
        <path
          fill="#4F5965"
          opacity="1.000000"
          stroke="none"
          d="
M1414.047363,454.558105
	C1414.105957,453.202362 1413.809692,451.984589 1415.236694,451.857300
	C1416.243286,451.767487 1416.642090,452.535187 1416.640503,453.471069
	C1416.635498,456.459991 1417.177612,459.593994 1416.451660,462.398376
	C1415.674683,465.399506 1412.337891,463.338165 1410.244873,464.181366
	C1408.439209,464.908783 1406.433838,463.914490 1406.206177,461.667206
	C1405.922974,458.871674 1406.090088,456.030121 1406.072632,453.208344
	C1406.067993,452.466309 1406.398071,451.886810 1407.174683,451.847809
	C1408.183838,451.797119 1408.588013,452.420654 1408.646484,453.391052
	C1408.755493,455.203217 1409.053955,457.013977 1409.025635,458.821411
	C1408.999146,460.520447 1408.535034,462.287048 1411.189453,462.169891
	C1413.616089,462.062775 1414.202393,460.575562 1414.058472,458.453857
	C1413.980103,457.296631 1414.045898,456.129639 1414.047363,454.558105
z"
        />
        <path
          fill="#2F3A48"
          opacity="1.000000"
          stroke="none"
          d="
M1473.471680,480.981079
	C1473.266602,483.245728 1473.359863,485.076141 1473.175415,486.878143
	C1473.063599,487.970886 1474.083618,490.020050 1471.890991,489.869629
	C1469.916016,489.734192 1471.014038,487.782745 1470.815552,486.646088
	C1470.647461,485.683197 1470.745728,484.666077 1470.788696,483.675690
	C1470.866699,481.877502 1470.502930,478.778961 1471.186035,478.522766
	C1473.449097,477.674072 1476.141357,477.452148 1478.585693,477.727753
	C1482.127441,478.127197 1481.549438,481.304657 1481.402954,483.614777
	C1481.269409,485.717438 1482.797852,489.508026 1480.301270,489.588654
	C1477.739990,489.671417 1479.082642,485.894226 1479.074585,483.915466
	C1479.056519,479.521545 1477.429565,478.509644 1473.471680,480.981079
z"
        />
        <path
          fill="#4F5965"
          opacity="1.000000"
          stroke="none"
          d="
M738.656677,508.328125
	C738.656555,509.247437 738.705139,509.749451 738.647949,510.239136
	C738.421814,512.175842 739.130310,515.802856 738.154785,515.753540
	C735.541809,515.621338 733.053894,516.759155 730.486877,516.191895
	C728.945862,515.851379 728.332275,514.746155 728.134338,513.370361
	C727.994080,512.395630 727.861694,511.339752 728.088318,510.408356
	C728.619995,508.223633 726.568359,504.292725 729.446533,504.183197
	C731.928284,504.088776 730.134705,508.043762 730.631714,510.137726
	C731.017700,511.764099 730.130920,514.108276 733.012695,514.151001
	C735.744568,514.191589 736.227722,512.399963 736.055786,510.142212
	C735.993164,509.319733 735.911194,508.460052 736.077576,507.667633
	C736.350037,506.369080 735.105896,504.006378 737.200684,503.986328
	C739.769592,503.961761 738.205688,506.536163 738.656677,508.328125
z"
        />
        <path
          fill="#4F5965"
          opacity="1.000000"
          stroke="none"
          d="
M1369.386475,458.634399
	C1368.921753,458.832001 1368.981079,458.933960 1368.947266,458.946350
	C1371.948608,459.561035 1370.611084,457.122375 1371.045776,455.905853
	C1371.531250,454.547119 1369.886353,451.845337 1372.462158,451.997711
	C1374.671021,452.128357 1373.349487,454.734650 1373.624390,456.221985
	C1373.803223,457.189056 1373.626221,458.216431 1373.688232,459.211823
	C1373.784790,460.763885 1373.950317,462.302612 1376.129028,462.166138
	C1378.128418,462.040863 1379.158325,461.095154 1379.057129,458.987610
	C1378.977539,457.326782 1379.044556,455.658783 1379.050781,453.993988
	C1379.054565,452.993011 1378.962158,451.762970 1380.388184,451.838867
	C1381.733154,451.910431 1381.651489,453.127533 1381.653320,454.133423
	C1381.655396,455.298798 1381.942017,456.558350 1381.595947,457.609467
	C1380.921143,459.659821 1383.577637,462.951263 1379.909912,463.755493
	C1373.101929,465.248352 1372.502563,464.763031 1370.642090,459.020905
	C1370.559937,458.767395 1370.123047,458.628845 1369.386475,458.634399
z"
        />
        <path
          fill="#4F5965"
          opacity="1.000000"
          stroke="none"
          d="
M1824.656616,433.026306
	C1824.302979,434.955292 1825.547729,437.628357 1823.593018,437.748413
	C1821.072632,437.903198 1818.349976,439.265961 1815.860962,437.717224
	C1814.656006,436.967499 1814.278687,435.656708 1814.463623,434.225403
	C1814.681396,432.539246 1815.856934,431.799805 1817.218628,431.009827
	C1818.711914,430.143433 1822.140747,432.619629 1821.706055,429.240662
	C1821.333374,426.342834 1818.243408,427.932281 1816.269043,427.874969
	C1815.983521,427.866669 1815.703125,427.683472 1815.075684,427.456482
	C1817.037354,424.734650 1819.680786,425.004211 1822.082031,425.788147
	C1825.479980,426.897247 1824.392944,430.084900 1824.656616,433.026306
z"
        />
        <path
          fill="#46505D"
          opacity="1.000000"
          stroke="none"
          d="
M1462.387207,424.475342
	C1462.388916,422.492310 1462.320068,420.927856 1464.610229,421.071991
	C1467.194580,421.234650 1469.858032,420.477631 1472.610474,421.730774
	C1471.761841,424.035889 1469.872925,423.189453 1468.479980,423.255371
	C1466.600220,423.344330 1464.982544,423.079529 1464.990967,425.959137
	C1464.999146,428.745697 1466.455688,428.786743 1468.430542,428.727142
	C1469.347412,428.699432 1470.443237,428.440277 1470.936890,429.498962
	C1470.927490,430.807404 1470.061768,431.016876 1469.251343,430.884155
	C1465.641479,430.292847 1464.559326,431.909149 1465.023071,435.286438
	C1465.151733,436.223755 1465.689453,437.901062 1463.852417,437.951630
	C1462.111694,437.999542 1462.421875,436.452209 1462.407959,435.367767
	C1462.362915,431.884735 1462.388062,428.400818 1462.387207,424.475342
z"
        />
        <path
          fill="#4F5965"
          opacity="1.000000"
          stroke="none"
          d="
M1959.407837,427.544250
	C1961.108521,424.790009 1963.793335,424.965881 1966.315674,425.856171
	C1969.145020,426.854797 1968.575195,429.617767 1968.637695,431.873901
	C1968.814087,438.235352 1968.442749,438.536316 1962.282104,438.321503
	C1960.136841,438.246704 1958.690674,437.382751 1958.442261,435.228119
	C1958.204102,433.162170 1959.371338,431.946503 1961.219238,430.995941
	C1962.738525,430.214355 1966.247559,432.602905 1965.684570,429.214325
	C1965.167114,426.101196 1961.776978,428.231995 1959.407837,427.544250
z"
        />
        <path
          fill="#4F5965"
          opacity="1.000000"
          stroke="none"
          d="
M654.591919,482.576904
	C654.433289,477.275208 650.502563,480.749756 648.172546,479.516235
	C649.707214,476.734497 652.229309,477.310028 654.539673,477.591309
	C657.101746,477.903259 657.454712,479.965851 657.624329,482.024200
	C657.705994,483.013824 657.735474,484.023193 657.637634,485.008636
	C657.479370,486.600891 658.356567,489.336761 656.978577,489.548889
	C654.501709,489.930145 651.848083,491.034790 649.248535,489.920319
	C647.837219,489.315277 647.402954,488.031647 647.428406,486.658630
	C647.458435,485.036591 648.247192,483.678131 649.859375,483.275391
	C651.278381,482.920868 652.801453,482.982849 654.591919,482.576904
z"
        />
        <path
          fill="#4F5965"
          opacity="1.000000"
          stroke="none"
          d="
M955.098389,483.648376
	C955.586060,483.361969 955.748108,483.305267 955.879517,483.207428
	C957.382812,482.088257 960.839294,484.224762 960.719971,481.614655
	C960.557251,478.055176 957.194946,480.098755 955.160034,479.868866
	C954.895325,479.838959 954.652771,479.612793 953.981934,479.253876
	C956.195801,476.943359 958.695129,477.043579 961.212585,477.790009
	C963.667725,478.517944 963.498535,480.771454 963.636780,482.736267
	C963.718201,483.893585 963.784851,485.079590 963.622437,486.219086
	C963.434875,487.535400 964.436646,489.991272 962.357483,489.839081
	C959.672119,489.642517 956.605713,491.783936 954.311584,489.181458
	C952.831177,487.502045 953.030396,485.503143 955.098389,483.648376
z"
        />
        <path
          fill="#46505D"
          opacity="1.000000"
          stroke="none"
          d="
M1871.455811,501.371521
	C1872.844971,507.663513 1872.132324,508.984009 1866.441772,509.526184
	C1863.854370,509.772705 1863.532471,510.908020 1863.574097,512.985229
	C1863.597412,514.149963 1864.114014,516.097656 1862.050903,515.900757
	C1860.092163,515.713928 1861.027466,513.811157 1860.967041,512.681824
	C1860.825806,510.047638 1860.920410,507.400696 1860.921997,504.758911
	C1860.925537,498.805084 1861.945923,497.974579 1867.777832,499.280273
	C1869.062988,499.567993 1870.344482,499.959106 1871.455811,501.371521
z"
        />
        <path
          fill="#4F5965"
          opacity="1.000000"
          stroke="none"
          d="
M590.399170,463.294189
	C588.292969,463.887085 586.360901,464.149658 584.420044,464.282593
	C582.334412,464.425476 580.809265,463.573273 580.476257,461.380981
	C580.161377,459.307831 581.258240,458.063232 583.049255,457.051758
	C584.612427,456.169006 588.102112,458.474701 587.717224,455.413971
	C587.262146,451.796021 583.563843,454.506989 581.210693,453.611694
	C582.611816,450.649811 585.186646,451.349854 587.458801,451.575806
	C590.011902,451.829681 590.519775,453.857391 590.602539,455.940857
	C590.694641,458.258728 590.589050,460.584473 590.399170,463.294189
z"
        />
        <path
          fill="#46505D"
          opacity="1.000000"
          stroke="none"
          d="
M1024.233398,460.546387
	C1025.498901,463.762939 1028.487183,461.355682 1030.931763,462.516449
	C1028.842407,465.324432 1026.052856,464.535919 1023.802185,463.852081
	C1021.017273,463.005920 1020.650208,460.334381 1020.686951,457.677460
	C1020.724792,454.947601 1021.518738,452.723938 1024.291504,451.756470
	C1026.293091,451.058014 1028.238159,451.247345 1029.888428,452.746490
	C1031.307373,454.035583 1031.450684,455.833252 1030.926758,457.371216
	C1030.131348,459.706268 1027.697998,458.121582 1025.854980,459.003357
	C1025.550781,459.849945 1023.763672,459.118561 1024.233398,460.546387
z"
        />
        <path
          fill="#46505D"
          opacity="1.000000"
          stroke="none"
          d="
M614.698120,505.743164
	C616.946045,503.119141 619.412598,502.822327 622.059448,504.131500
	C623.963989,505.073456 624.146423,506.898468 624.077698,508.811371
	C624.003540,510.873871 622.446838,510.305481 621.262390,510.664551
	C619.642578,511.155548 616.506653,509.161865 616.616943,512.105652
	C616.710693,514.607727 619.652222,514.496704 621.889404,514.105896
	C622.457397,514.006653 623.197327,513.740540 623.960999,515.189148
	C621.388794,517.218506 618.391418,516.921692 616.101624,515.416077
	C612.621704,513.127930 613.310913,509.483978 614.698120,505.743164
z"
        />
        <path
          fill="#4F5965"
          opacity="1.000000"
          stroke="none"
          d="
M1281.235840,451.761993
	C1285.129883,454.936981 1284.020386,459.181519 1283.322266,462.572052
	C1282.749268,465.355133 1278.908447,463.888947 1276.537964,464.175598
	C1274.568481,464.413757 1273.547485,462.847412 1273.437744,461.004425
	C1273.313965,458.927063 1274.471313,457.711334 1276.432861,456.973511
	C1277.922852,456.413086 1280.937378,458.191345 1280.759155,455.505463
	C1280.545044,452.281281 1277.488647,453.932343 1275.539673,453.849213
	C1275.137817,453.832092 1274.742798,453.651886 1273.734741,453.385986
	C1276.126953,451.158966 1278.426636,451.146606 1281.235840,451.761993
z"
        />
        <path
          fill="#4F5965"
          opacity="1.000000"
          stroke="none"
          d="
M980.466675,506.824554
	C980.588318,508.224335 980.647522,509.218842 980.652588,510.213623
	C980.684082,516.349182 980.135254,516.820068 973.982666,516.318909
	C972.081665,516.164124 970.793152,515.363647 970.488647,513.438354
	C970.160339,511.362427 971.196716,510.095947 973.000427,509.082611
	C974.581055,508.194641 978.055237,510.393707 977.725220,507.465790
	C977.311096,503.791840 973.619995,506.458588 971.516907,505.733948
	C974.204285,502.160614 977.688904,502.430267 980.466675,506.824554
z"
        />
        <path
          fill="#4F5965"
          opacity="1.000000"
          stroke="none"
          d="
M1798.749390,514.281616
	C1797.983276,510.781158 1799.469360,509.342865 1802.480347,508.867798
	C1803.685791,508.677612 1806.004883,510.016510 1805.856934,507.446075
	C1805.723999,505.136139 1803.614136,505.530792 1801.963257,505.666504
	C1801.147339,505.733582 1800.332642,505.812866 1799.397583,505.325104
	C1801.485474,503.156952 1804.063232,502.808105 1806.826172,504.071167
	C1808.464111,504.819977 1809.829468,514.718445 1808.308472,515.179016
	C1805.303101,516.089111 1801.807129,517.838440 1798.749390,514.281616
z"
        />
        <path
          fill="#4F5965"
          opacity="1.000000"
          stroke="none"
          d="
M1793.562866,452.596069
	C1794.715942,454.577637 1794.662964,456.418823 1794.695557,458.242859
	C1794.803101,464.261078 1794.110840,464.850830 1787.947510,464.316559
	C1786.034546,464.150665 1784.769287,463.314972 1784.480225,461.401215
	C1784.166504,459.323425 1785.240967,458.075531 1787.036011,457.060272
	C1788.603760,456.173462 1792.094238,458.460388 1791.718506,455.423096
	C1791.269409,451.793518 1787.567993,454.502930 1784.675903,453.411865
	C1787.644409,450.874969 1790.337524,450.877380 1793.562866,452.596069
z"
        />
        <path
          fill="#4F5965"
          opacity="1.000000"
          stroke="none"
          d="
M1022.515198,436.203674
	C1023.536438,436.160431 1024.317017,435.948029 1024.781372,436.239471
	C1025.440308,436.653046 1025.232666,437.586456 1024.578857,437.773224
	C1021.837646,438.556396 1018.839844,439.210449 1016.711914,436.788971
	C1014.276611,434.017609 1014.293335,430.649719 1016.320312,427.613647
	C1018.042480,425.034119 1020.700134,424.795502 1023.384277,425.988647
	C1025.547974,426.950470 1025.860840,429.003540 1025.565796,431.056152
	C1025.248169,433.265411 1023.339294,432.420074 1021.996582,432.670746
	C1020.672119,432.918060 1018.438293,431.861298 1018.229309,433.729584
	C1017.953674,436.192749 1020.278870,436.215759 1022.515198,436.203674
z"
        />
        <path
          fill="#4F5965"
          opacity="1.000000"
          stroke="none"
          d="
M578.056580,483.215271
	C579.790283,477.971161 582.984741,476.099030 586.355042,477.921326
	C588.439819,479.048553 588.800781,480.929047 588.585388,483.005402
	C588.385986,484.926178 586.782776,484.417816 585.552002,484.696747
	C584.026245,485.042542 581.171570,483.479462 581.254089,486.151337
	C581.329773,488.602448 584.040588,488.353027 586.065857,488.131592
	C586.690613,488.063263 587.329102,488.120972 588.376404,488.120972
	C586.841370,491.517548 584.071899,490.412872 582.074890,490.076202
	C578.662048,489.500854 578.040283,486.625641 578.056580,483.215271
z"
        />
        <path
          fill="#4F5965"
          opacity="1.000000"
          stroke="none"
          d="
M751.280396,429.654663
	C752.500854,426.466797 754.510315,425.061493 757.559753,425.444305
	C759.276306,425.659790 760.824341,426.334595 761.315247,428.192749
	C761.968872,430.666870 762.196228,432.813019 758.422485,432.745117
	C756.984009,432.719208 754.067505,431.466919 754.274902,434.252686
	C754.454834,436.669922 757.160461,436.351654 759.176636,436.114594
	C759.793701,436.042053 760.426575,436.103363 761.533325,436.103363
	C759.531860,439.471558 756.632446,438.616211 754.511780,437.919739
	C750.817993,436.706573 750.859680,433.283508 751.280396,429.654663
z"
        />
        <path
          fill="#4F5965"
          opacity="1.000000"
          stroke="none"
          d="
M1511.732422,436.138611
	C1509.886963,440.207153 1506.745239,438.581543 1504.242554,437.219971
	C1501.442871,435.696686 1501.188354,429.993195 1503.466187,427.374786
	C1505.654541,424.859253 1508.331909,424.721436 1511.042725,426.410187
	C1512.628418,427.398071 1512.699219,429.165161 1512.611084,430.862946
	C1512.529785,432.429932 1511.400269,432.453796 1510.191040,432.699982
	C1508.472778,433.049866 1505.142700,431.540802 1505.330688,434.001312
	C1505.592407,437.428833 1509.070923,436.062836 1511.732422,436.138611
z"
        />
        <path
          fill="#4F5965"
          opacity="1.000000"
          stroke="none"
          d="
M1532.773438,484.656921
	C1531.241821,484.999390 1529.324341,483.884979 1529.155396,485.849548
	C1528.993164,487.734894 1530.743408,488.057587 1532.265137,488.289642
	C1533.528320,488.482239 1534.816772,487.479645 1536.785522,488.862885
	C1533.670288,490.859863 1530.700439,491.165375 1528.217651,489.198029
	C1525.256226,486.851410 1525.331421,483.369324 1526.968140,480.177795
	C1528.376465,477.431274 1530.891113,476.848907 1533.754883,477.731079
	C1536.042480,478.435760 1536.613159,480.226624 1536.645752,482.375031
	C1536.685303,484.981628 1534.818359,484.617981 1532.773438,484.656921
z"
        />
        <path
          fill="#4F5965"
          opacity="1.000000"
          stroke="none"
          d="
M1761.231934,427.912170
	C1761.972168,430.844543 1762.022583,432.882446 1758.334717,432.733337
	C1756.939697,432.676941 1754.329346,431.613434 1754.248291,433.873169
	C1754.152466,436.542938 1756.769287,436.306183 1758.779053,436.164551
	C1759.556519,436.109802 1760.375854,435.672943 1761.836548,437.267456
	C1758.411133,438.613098 1755.342285,439.421387 1752.823853,436.840637
	C1750.097656,434.046967 1750.078735,430.290039 1752.595093,427.354950
	C1755.167114,424.354858 1758.331909,424.483063 1761.231934,427.912170
z"
        />
        <path
          fill="#4F5965"
          opacity="1.000000"
          stroke="none"
          d="
M1846.515137,436.203674
	C1847.536499,436.160400 1848.317017,435.947998 1848.781494,436.239410
	C1849.440430,436.652985 1849.232788,437.586395 1848.578979,437.773224
	C1845.837769,438.556671 1842.839844,439.210266 1840.711914,436.789001
	C1838.276489,434.017853 1838.293579,430.649994 1840.320435,427.613861
	C1842.042725,425.034241 1844.700195,424.795410 1847.384277,425.988617
	C1849.547729,426.950348 1849.860962,429.003296 1849.565674,431.056122
	C1849.247925,433.265564 1847.339111,432.419983 1845.996460,432.670776
	C1844.672119,432.918182 1842.438477,431.860992 1842.229492,433.729553
	C1841.953979,436.192566 1844.278687,436.215912 1846.515137,436.203674
z"
        />
        <path
          fill="#4F5965"
          opacity="1.000000"
          stroke="none"
          d="
M1777.405029,425.622864
	C1780.299561,426.576874 1780.843262,428.597809 1780.582153,430.886658
	C1780.287598,433.469971 1778.110229,432.319733 1776.673340,432.680206
	C1775.431396,432.991821 1773.173950,431.707825 1773.182495,434.040436
	C1773.190063,436.108459 1775.252563,436.255981 1776.967285,436.264984
	C1778.017090,436.270508 1779.135254,435.610168 1780.547363,436.706421
	C1777.764282,439.169495 1774.878296,438.879578 1772.414307,437.367828
	C1769.679932,435.690155 1769.575195,432.596741 1770.300049,429.777008
	C1771.162598,426.421326 1773.514282,424.913605 1777.405029,425.622864
z"
        />
        <path
          fill="#4F5965"
          opacity="1.000000"
          stroke="none"
          d="
M1405.115479,485.581207
	C1404.856323,481.814545 1405.486084,478.835999 1409.122314,477.567291
	C1411.161133,476.855957 1413.096924,477.393799 1414.596924,478.903107
	C1415.719971,480.033173 1415.887329,481.639160 1415.557007,483.105957
	C1415.011597,485.527496 1412.896362,484.319946 1411.444092,484.668762
	C1410.298340,484.943939 1408.339355,483.913391 1408.184692,485.779877
	C1408.003784,487.963074 1409.994019,488.158447 1411.698242,488.280701
	C1412.816040,488.360870 1413.944946,487.563293 1415.052979,488.319092
	C1411.831665,492.010834 1407.783203,491.048279 1405.115479,485.581207
z"
        />
        <path
          fill="#4F5965"
          opacity="1.000000"
          stroke="none"
          d="
M1824.610474,482.921021
	C1823.675293,485.465515 1821.738281,484.325134 1820.259644,484.671692
	C1819.141235,484.933868 1817.209473,483.902405 1817.153442,485.956604
	C1817.102295,487.831085 1818.856689,488.164917 1820.385742,488.275146
	C1821.612671,488.363586 1822.851318,488.292999 1824.863770,488.292999
	C1821.843262,491.259491 1818.899414,490.869568 1816.491943,489.408447
	C1813.567871,487.633789 1813.560913,484.393005 1814.413086,481.388092
	C1815.169678,478.720367 1817.119873,477.253296 1819.969971,477.390778
	C1823.173340,477.545319 1824.760254,479.301147 1824.610474,482.921021
z"
        />
        <path
          fill="#4F5965"
          opacity="1.000000"
          stroke="none"
          d="
M1083.175659,477.300171
	C1085.416992,477.451202 1087.396606,477.765381 1088.091675,479.715729
	C1088.942261,482.101898 1089.776123,484.728363 1085.442139,484.737335
	C1083.985229,484.740356 1081.077026,483.474609 1081.273071,486.245087
	C1081.444580,488.667480 1084.157593,488.352417 1086.177612,488.114471
	C1086.794434,488.041840 1087.426880,488.103241 1088.541016,488.103241
	C1086.520142,491.470398 1083.711548,490.456299 1081.478394,489.933624
	C1078.928711,489.336823 1078.169678,486.949524 1078.052490,484.476318
	C1077.884399,480.931488 1079.179932,478.352600 1083.175659,477.300171
z"
        />
        <path
          fill="#4F5965"
          opacity="1.000000"
          stroke="none"
          d="
M1041.104736,515.899780
	C1037.523315,513.168518 1037.275757,509.848541 1038.858887,506.405182
	C1040.515137,502.802582 1045.881592,502.256165 1047.921509,505.298187
	C1049.710938,507.966888 1048.541870,510.290192 1045.418091,510.713043
	C1043.932983,510.914001 1041.143677,509.418335 1041.264282,512.277954
	C1041.355103,514.429138 1043.832153,514.372437 1045.711914,514.177368
	C1046.502075,514.095398 1047.320679,513.702759 1048.272339,514.566040
	C1046.552734,517.632446 1043.804199,516.052124 1041.104736,515.899780
z"
        />
        <path
          fill="#4F5965"
          opacity="1.000000"
          stroke="none"
          d="
M1477.181885,451.378540
	C1479.628418,451.480194 1481.629639,451.886749 1482.298096,454.182983
	C1483.010254,456.629486 1483.220825,458.808868 1479.431274,458.744537
	C1477.988647,458.720032 1475.071777,457.470032 1475.274414,460.249512
	C1475.450928,462.669556 1478.160278,462.352020 1480.178345,462.114380
	C1480.793579,462.041962 1481.424561,462.103119 1482.539185,462.103119
	C1480.523926,465.470123 1477.716431,464.454620 1475.484619,463.935791
	C1472.935547,463.343292 1472.157715,460.960083 1472.056519,458.488129
	C1471.912964,454.978119 1473.075928,452.320892 1477.181885,451.378540
z"
        />
        <path
          fill="#4F5965"
          opacity="1.000000"
          stroke="none"
          d="
M1781.349487,452.392944
	C1781.598511,455.531219 1781.614014,458.352264 1781.658447,461.172852
	C1781.688110,463.062927 1781.589478,464.138428 1778.909424,463.703430
	C1776.674316,463.340637 1773.765259,465.606171 1772.014404,463.179169
	C1769.856689,460.187775 1771.364136,456.412506 1771.104126,452.990997
	C1771.043335,452.192322 1771.667358,451.803192 1772.379150,451.837463
	C1773.093994,451.871826 1773.582275,452.325256 1773.612793,453.107666
	C1773.677124,454.765167 1773.605713,456.439270 1773.820190,458.076385
	C1774.027588,459.660522 1772.963867,462.121521 1775.974487,462.171356
	C1779.132446,462.223602 1779.141846,459.969879 1779.058228,457.678589
	C1779.003662,456.187103 1779.008179,454.688538 1779.087769,453.198547
	C1779.160400,451.837036 1779.973511,451.589752 1781.349487,452.392944
z"
        />
        <path
          fill="#4F5965"
          opacity="1.000000"
          stroke="none"
          d="
M1147.932129,487.789490
	C1146.562012,484.654358 1146.601440,481.915314 1148.416870,479.451752
	C1150.489746,476.639038 1155.270020,476.677338 1156.987183,479.394470
	C1158.712036,482.123657 1157.520386,484.337738 1154.307007,484.700928
	C1152.872925,484.862976 1150.297607,483.602844 1150.245483,485.903870
	C1150.185303,488.557953 1152.798706,488.308167 1154.807861,488.162231
	C1155.577881,488.106293 1156.402100,487.651978 1157.066406,488.445007
	C1154.231445,491.222900 1151.921631,491.146423 1147.932129,487.789490
z"
        />
        <path
          fill="#46505D"
          opacity="1.000000"
          stroke="none"
          d="
M676.443726,477.472168
	C679.922363,477.322205 682.268982,478.458679 682.010193,481.894226
	C681.592896,487.435120 676.342468,482.816772 674.313293,485.423553
	C675.264832,489.852356 678.990417,487.654724 681.889587,488.282562
	C680.020691,491.440125 677.208435,490.406677 674.948364,489.901703
	C672.600464,489.377136 671.797546,487.205017 671.705872,484.848633
	C671.573364,481.442566 672.117126,478.479584 676.443726,477.472168
z"
        />
        <path
          fill="#46505D"
          opacity="1.000000"
          stroke="none"
          d="
M1087.343506,499.096924
	C1090.317139,499.078949 1092.850220,499.078949 1096.121582,499.078949
	C1094.341675,502.140442 1092.342041,501.173889 1090.771118,501.246490
	C1089.055664,501.325714 1087.963013,501.522888 1088.034546,503.664429
	C1088.096802,505.527039 1088.186523,506.861145 1090.629517,506.769104
	C1091.715088,506.728241 1093.826538,505.853882 1093.803101,507.823792
	C1093.780762,509.703766 1091.686890,508.944519 1090.581787,508.865143
	C1087.510132,508.644653 1088.258789,510.736389 1088.033813,512.444397
	C1087.869751,513.690186 1088.997314,515.987488 1086.702026,515.888184
	C1084.603882,515.797485 1085.274170,513.475830 1085.459717,512.234436
	C1086.110962,507.875366 1083.646484,503.166046 1087.343506,499.096924
z"
        />
        <path
          fill="#46505D"
          opacity="1.000000"
          stroke="none"
          d="
M1857.322998,477.782349
	C1860.924805,481.299896 1861.106201,484.020996 1858.115112,484.579010
	C1856.214844,484.933533 1854.157715,484.068695 1852.276611,485.258911
	C1853.271362,489.850494 1856.992188,487.654999 1859.965820,488.298950
	C1857.761108,491.386200 1854.798950,490.725647 1852.525391,489.704285
	C1848.953857,488.100098 1849.285645,484.485840 1849.993286,481.400604
	C1850.812622,477.828033 1853.564453,476.920898 1857.322998,477.782349
z"
        />
        <path
          fill="#4F5965"
          opacity="1.000000"
          stroke="none"
          d="
M1438.930908,489.934998
	C1435.347534,486.457031 1437.499634,482.300262 1437.370361,478.423767
	C1437.336182,477.399872 1447.660278,477.364563 1447.766357,478.515289
	C1448.105469,482.197205 1447.811401,485.938812 1447.688965,489.654938
	C1447.685425,489.760956 1446.846802,489.959198 1446.423828,489.905823
	C1445.620117,489.804321 1445.425659,489.117401 1445.408569,488.435455
	C1445.358154,486.438873 1445.346924,484.441345 1445.325073,482.444122
	C1445.305908,480.690369 1444.659912,479.548828 1442.672119,479.715881
	C1440.971558,479.858765 1439.993896,480.531158 1439.914307,482.488739
	C1439.814697,484.936737 1440.580688,487.468597 1438.930908,489.934998
z"
        />
        <path
          fill="#4F5965"
          opacity="1.000000"
          stroke="none"
          d="
M1788.147217,477.474182
	C1790.158569,477.292877 1792.357544,477.535553 1792.657104,478.963776
	C1793.394775,482.482910 1793.751953,486.253937 1792.523682,489.696533
	C1792.157227,490.723480 1790.471436,489.825928 1790.406006,488.340576
	C1790.318726,486.356415 1790.342407,484.367310 1790.321045,482.380310
	C1790.301880,480.604706 1789.578491,479.537720 1787.631348,479.715515
	C1785.935303,479.870361 1784.858032,480.562714 1784.944702,482.509125
	C1785.025513,484.327148 1784.960205,486.152313 1784.938599,487.974060
	C1784.926270,489.005280 1784.825684,490.071625 1783.426147,490.000580
	C1782.044312,489.930420 1782.086792,488.812805 1782.059326,487.823517
	C1782.036377,486.995941 1782.125977,486.158722 1782.034058,485.340576
	C1781.539673,480.947266 1781.442383,476.881622 1788.147217,477.474182
z"
        />
        <path
          fill="#46505D"
          opacity="1.000000"
          stroke="none"
          d="
M1892.360718,459.647278
	C1893.849243,463.597626 1897.210083,461.616699 1900.295532,462.433838
	C1897.480347,465.080719 1894.686157,464.806732 1892.304932,463.592621
	C1889.086670,461.951782 1889.356079,458.670502 1889.899536,455.665100
	C1890.368042,453.075378 1892.155273,451.591858 1894.749756,451.454620
	C1897.762695,451.295227 1899.867920,452.409790 1900.095459,455.871490
	C1900.347534,459.707367 1897.539673,458.357361 1895.679443,458.603119
	C1894.557251,458.751373 1893.341431,458.357025 1892.360718,459.647278
z"
        />
        <path
          fill="#4F5965"
          opacity="1.000000"
          stroke="none"
          d="
M564.046997,432.771332
	C564.750183,430.223297 562.501648,426.301849 565.504272,426.201080
	C567.876404,426.121460 566.162109,430.096375 566.631042,432.209564
	C566.997253,433.859558 566.197754,436.167053 569.091492,436.153137
	C571.883179,436.139740 571.892273,434.218781 572.138184,432.066528
	C572.375854,429.986664 570.690125,426.074249 573.276672,426.185303
	C576.065430,426.305023 574.067505,430.291595 574.652405,432.492615
	C576.073730,437.840546 572.364075,437.924011 568.781067,438.290009
	C564.850037,438.691559 563.812317,436.579346 564.046997,432.771332
z"
        />
        <path
          fill="#46505D"
          opacity="1.000000"
          stroke="none"
          d="
M972.713257,432.733154
	C973.210205,426.675690 976.331787,424.063141 980.470642,425.823761
	C982.670044,426.759369 983.152100,428.506348 983.087402,430.675476
	C983.014709,433.113098 981.204834,432.342682 979.868591,432.645569
	C978.342529,432.991486 975.523560,431.479797 975.570190,434.037720
	C975.611328,436.297302 978.215759,436.528259 980.356018,436.188080
	C981.088623,436.071655 981.855286,436.169556 983.000488,436.169556
	C978.212952,440.131989 974.427429,438.822998 972.713257,432.733154
z"
        />
        <path
          fill="#4F5965"
          opacity="1.000000"
          stroke="none"
          d="
M1085.046021,455.399872
	C1085.302246,453.845551 1084.306396,451.923218 1086.382080,451.883942
	C1088.027710,451.852844 1087.607910,453.522095 1087.645264,454.610565
	C1087.702026,456.266022 1087.624756,457.927551 1087.721802,459.579620
	C1087.803223,460.962616 1088.119873,462.302307 1089.980713,462.163574
	C1091.562866,462.045593 1092.984863,461.662231 1093.013184,459.655304
	C1093.041138,457.666992 1093.038940,455.678192 1093.055176,453.689667
	C1093.062012,452.848724 1093.074219,451.940582 1094.190796,451.855835
	C1095.229370,451.777008 1095.632202,452.486816 1095.634644,453.416595
	C1095.642578,456.399170 1096.280396,459.588745 1095.427368,462.306885
	C1094.554810,465.087372 1091.121826,463.693146 1088.865845,464.226715
	C1086.809570,464.713074 1085.323608,463.480286 1085.129517,461.277496
	C1084.970215,459.467743 1085.064087,457.635651 1085.046021,455.399872
z"
        />
        <path
          fill="#46505D"
          opacity="1.000000"
          stroke="none"
          d="
M771.435303,463.378906
	C763.700745,465.181946 759.967285,461.817474 761.931335,455.324341
	C762.945984,451.970032 766.981445,450.343933 769.981873,452.052338
	C771.846130,453.113831 772.151550,454.801605 772.078735,456.738037
	C771.986267,459.198395 770.109558,458.309113 768.806458,458.643829
	C767.308289,459.028656 764.469055,457.421387 764.580688,460.092010
	C764.675110,462.349884 767.282471,462.506165 769.419312,462.189331
	C770.234131,462.068542 771.446228,461.424042 771.435303,463.378906
z"
        />
        <path
          fill="#46505D"
          opacity="1.000000"
          stroke="none"
          d="
M607.041748,461.153442
	C609.206909,463.033173 611.474670,461.767731 613.605530,462.808716
	C611.187744,464.713257 608.263916,465.115295 605.755676,463.190338
	C602.671204,460.823181 602.997131,457.231598 604.544006,454.130737
	C606.039612,451.132721 608.895081,450.756287 611.832092,452.015594
	C614.033142,452.959290 614.311035,454.996704 614.026428,457.071289
	C613.707153,459.398407 611.675720,458.139526 610.490662,458.665436
	C609.207886,459.234711 606.084167,456.831512 607.041748,461.153442
z"
        />
        <path
          fill="#46505D"
          opacity="1.000000"
          stroke="none"
          d="
M1909.638916,477.485809
	C1912.725220,478.518555 1913.416748,480.621857 1913.014038,483.147888
	C1912.650269,485.429901 1910.652588,484.235687 1909.387695,484.634369
	C1908.037476,485.060028 1905.445923,483.388489 1905.525513,486.019409
	C1905.587646,488.072754 1907.868652,488.451874 1909.848633,488.275940
	C1910.757324,488.195190 1911.679199,488.262177 1913.389648,488.262177
	C1910.506470,491.125427 1907.723022,490.756622 1905.313599,489.606110
	C1902.223511,488.130585 1902.452637,484.959473 1902.831055,482.137512
	C1903.321289,478.482117 1905.872437,477.273590 1909.638916,477.485809
z"
        />
        <path
          fill="#46505D"
          opacity="1.000000"
          stroke="none"
          d="
M1985.418701,436.038727
	C1987.405396,436.167786 1988.995361,436.167786 1991.281982,436.167786
	C1988.608398,439.236572 1985.761841,438.757935 1983.400269,437.648010
	C1980.090210,436.092133 1980.456909,432.738342 1980.895264,429.759094
	C1981.277832,427.159302 1983.056885,425.640961 1985.657349,425.467346
	C1988.656250,425.267212 1990.800049,426.315277 1991.095825,429.777924
	C1991.418579,433.556213 1988.685425,432.346527 1986.776733,432.638641
	C1985.611206,432.817017 1983.916504,431.876251 1983.432739,433.649536
	C1983.171753,434.606537 1984.092773,435.423706 1985.418701,436.038727
z"
        />
        <path
          fill="#46505D"
          opacity="1.000000"
          stroke="none"
          d="
M1423.705566,436.214203
	C1422.338379,433.892365 1422.546143,431.706787 1422.919922,429.593506
	C1423.296265,427.464600 1424.689819,426.075562 1426.854736,425.588074
	C1428.741455,425.163269 1430.486206,425.415253 1431.943604,426.799774
	C1433.327148,428.114044 1433.274048,429.831909 1432.952271,431.452026
	C1432.636353,433.042297 1431.116333,432.391144 1430.065796,432.628510
	C1428.488281,432.984985 1425.591064,431.648468 1425.587402,433.841888
	C1425.582886,436.518646 1428.387451,436.489929 1430.651367,436.137817
	C1431.272705,436.041168 1431.921753,436.123108 1432.745361,436.123108
	C1430.004395,439.036346 1428.011719,439.084167 1423.705566,436.214203
z"
        />
        <path
          fill="#46505D"
          opacity="1.000000"
          stroke="none"
          d="
M594.311401,515.719482
	C591.088074,513.351624 591.365845,510.259033 591.995422,507.340607
	C592.732361,503.924957 596.980408,502.343384 599.981750,504.052307
	C601.846619,505.114075 602.152039,506.802460 602.078430,508.739227
	C601.984863,511.200073 600.106567,510.307617 598.804138,510.643982
	C597.307739,511.030426 594.467163,509.416077 594.582336,512.094421
	C594.679626,514.356873 597.292053,514.528381 599.422974,514.177246
	C600.155579,514.056519 601.011597,513.697327 601.885681,514.970764
	C599.652954,517.057068 597.091125,516.251709 594.311401,515.719482
z"
        />
        <path
          fill="#46505D"
          opacity="1.000000"
          stroke="none"
          d="
M741.753906,508.509460
	C743.627747,503.907745 746.518433,502.368347 749.687256,503.902191
	C751.838196,504.943329 752.245911,506.778656 752.055237,508.905792
	C751.838867,511.320068 749.941956,510.429016 748.626953,510.597412
	C747.194092,510.780914 745.659912,510.291504 744.266357,511.206726
	C745.263733,515.846558 748.981689,513.660828 751.886047,514.279663
	C750.020691,517.421997 747.159363,516.528625 744.973572,515.895264
	C741.621765,514.924011 741.597107,511.794769 741.753906,508.509460
z"
        />
        <path
          fill="#4F5965"
          opacity="1.000000"
          stroke="none"
          d="
M1092.043945,489.930725
	C1090.390625,486.600525 1090.637817,483.117401 1091.225098,480.005554
	C1092.009888,475.846741 1096.210327,478.424103 1098.762573,477.591919
	C1100.644165,476.978363 1102.342163,478.647186 1102.060425,481.153198
	C1102.041992,481.316345 1102.111816,481.493378 1102.077637,481.650208
	C1101.476685,484.401550 1103.506836,489.454468 1100.977417,489.517548
	C1097.529053,489.603516 1100.019653,484.995331 1099.400879,482.514587
	C1099.000122,480.908020 1098.800049,479.606171 1096.759766,479.708954
	C1094.696655,479.812927 1094.086426,480.855713 1093.863281,482.892029
	C1093.601807,485.277863 1095.493774,488.177246 1092.043945,489.930725
z"
        />
        <path
          fill="#2F3A48"
          opacity="1.000000"
          stroke="none"
          d="
M1849.684814,515.323242
	C1845.650879,511.303558 1844.873779,505.805969 1847.509399,502.106842
	C1849.759399,498.949249 1853.875000,498.339783 1859.671875,501.527557
	C1852.703125,500.772766 1848.292358,501.424347 1848.743652,508.619415
	C1849.163818,515.317688 1854.231201,513.631897 1858.986694,514.358704
	C1855.507935,516.901245 1852.790405,516.451599 1849.684814,515.323242
z"
        />
        <path
          fill="#2F3A48"
          opacity="1.000000"
          stroke="none"
          d="
M650.029236,454.325623
	C652.272461,447.243744 657.299744,445.103790 661.825439,448.915588
	C653.725403,449.737457 652.923218,450.323334 652.854431,455.467926
	C652.782227,460.864044 653.305481,461.268616 661.898865,462.462524
	C659.713806,464.371185 656.822754,464.817444 654.169678,463.558960
	C650.393555,461.767731 649.909424,458.373199 650.029236,454.325623
z"
        />
        <path
          fill="#2F3A48"
          opacity="1.000000"
          stroke="none"
          d="
M1069.054199,452.689270
	C1067.387573,457.391754 1065.759399,461.752136 1064.039551,466.076111
	C1063.085938,468.473724 1061.598511,469.995483 1058.404785,468.528595
	C1067.271606,462.866089 1057.094238,457.554352 1058.553833,451.598633
	C1062.340576,453.298553 1061.242676,457.726227 1063.584473,460.089264
	C1065.373657,458.389160 1065.443237,455.919250 1066.380249,453.846771
	C1066.861084,452.783203 1067.068726,450.883911 1069.054199,452.689270
z"
        />
        <path
          fill="#4F5965"
          opacity="1.000000"
          stroke="none"
          d="
M749.902832,488.614288
	C749.676086,489.328796 749.515747,489.791473 749.294006,489.823029
	C745.909912,490.304749 746.952209,487.595734 747.148071,486.252289
	C747.557251,483.443939 745.162781,479.710663 748.695801,478.081055
	C749.821350,477.561890 752.085144,477.415955 753.850830,477.385681
	C756.098450,477.347168 757.745911,478.101868 757.994568,480.628815
	C758.026978,480.957916 758.126038,481.302002 758.067078,481.615234
	C757.546387,484.382019 759.393372,489.442871 757.063721,489.506439
	C753.589172,489.601196 756.009888,485.088409 755.396423,482.656921
	C755.013184,481.137848 755.044678,479.647064 752.928955,479.717773
	C750.991272,479.782562 749.817078,480.508270 749.936646,482.702942
	C750.035461,484.517303 749.946472,486.341888 749.902832,488.614288
z"
        />
        <path
          fill="#2F3A48"
          opacity="1.000000"
          stroke="none"
          d="
M1962.979004,486.275787
	C1962.071411,483.904724 1961.289551,481.906555 1960.499512,479.911713
	C1960.208862,479.177856 1959.605103,478.300873 1960.764038,477.902100
	C1961.639038,477.601105 1962.386230,478.172150 1962.700073,479.027832
	C1963.549072,481.344025 1964.334961,483.683350 1965.257935,486.335297
	C1967.579590,484.507446 1967.403198,481.850891 1968.401001,479.776520
	C1968.797852,478.951294 1968.929077,477.553040 1970.266479,477.916809
	C1971.790161,478.331268 1970.907471,479.476196 1970.599854,480.293671
	C1969.088013,484.310486 1967.601807,488.339844 1965.957031,492.302704
	C1964.983765,494.647095 1963.386597,495.986908 1959.489990,494.006042
	C1965.058716,492.871887 1964.958374,490.030273 1962.979004,486.275787
z"
        />
        <path
          fill="#46505D"
          opacity="1.000000"
          stroke="none"
          d="
M626.399475,438.246765
	C623.743103,437.967896 622.876831,436.535858 622.963684,434.446228
	C623.044067,432.513489 624.356689,431.632507 626.030823,430.981567
	C627.509949,430.406403 630.532776,432.130188 630.332642,429.535950
	C630.094482,426.447205 627.045532,427.857605 625.042603,427.868256
	C624.728760,427.869934 624.414246,427.743561 623.753235,427.602264
	C625.242188,424.611420 627.805542,425.419098 630.004578,425.639038
	C632.183411,425.856934 633.003967,427.455688 633.050171,429.512817
	C633.057617,429.844604 633.027893,430.182922 633.081970,430.507111
	C633.909180,435.467987 633.080566,439.078888 626.399475,438.246765
z"
        />
        <path
          fill="#46505D"
          opacity="1.000000"
          stroke="none"
          d="
M711.399048,427.081879
	C711.795166,429.058441 712.039734,430.699219 712.060059,432.342773
	C712.134399,438.342224 711.596924,438.767822 705.463989,438.270935
	C703.564331,438.117065 702.305054,437.345673 702.001282,435.404694
	C701.669983,433.287689 702.748169,432.070526 704.567200,431.101288
	C706.155762,430.254944 709.586243,432.317688 709.298340,429.524109
	C708.922729,425.880005 705.169617,428.503571 701.522400,427.057495
	C705.542358,425.307831 708.174683,424.539398 711.399048,427.081879
z"
        />
        <path
          fill="#46505D"
          opacity="1.000000"
          stroke="none"
          d="
M536.247864,514.335083
	C535.549683,510.871063 536.926514,509.322693 539.987732,508.887360
	C541.214783,508.712860 543.474426,509.895081 543.424438,507.557465
	C543.376038,505.292999 541.223938,505.577911 539.551270,505.662109
	C538.666077,505.706696 537.697876,506.207123 536.459045,505.114166
	C539.560547,502.915527 542.847717,502.262421 544.980347,504.854614
	C547.384827,507.777252 546.895630,512.339355 545.453247,515.218689
	C544.767578,516.587280 539.351013,517.721130 536.247864,514.335083
z"
        />
        <path
          fill="#46505D"
          opacity="1.000000"
          stroke="none"
          d="
M550.952942,434.814911
	C551.353149,431.758698 553.136230,431.010101 555.557251,430.879333
	C556.632874,430.821259 558.571045,431.718323 558.461853,429.498138
	C558.381592,427.867218 556.943176,427.521851 555.488586,427.609711
	C554.354980,427.678162 553.221985,427.754944 551.524963,427.864380
	C553.514587,424.640320 556.258423,424.951050 558.473083,425.835510
	C562.975037,427.633514 560.514160,431.856781 561.003784,435.024445
	C561.159119,436.029480 561.791992,438.366974 559.648438,437.887238
	C556.719055,437.231628 552.353271,440.777832 550.952942,434.814911
z"
        />
        <path
          fill="#46505D"
          opacity="1.000000"
          stroke="none"
          d="
M725.210327,457.960022
	C725.982300,457.487793 726.436829,457.294189 726.868591,457.058777
	C728.323486,456.265472 731.361938,458.133606 731.327820,455.706818
	C731.282043,452.456299 728.214966,453.861084 726.232239,453.837830
	C725.809814,453.832886 725.389404,453.661102 724.394165,453.436859
	C726.790771,450.865387 729.304443,451.121765 731.761658,451.856201
	C734.208801,452.587677 734.044250,454.802704 734.075195,456.800812
	C734.090576,457.795593 734.177917,458.803345 734.054565,459.782776
	C733.878967,461.177185 734.658142,463.800323 733.359131,463.730530
	C730.636292,463.584229 727.722351,465.501434 725.226746,463.518372
	C723.388184,462.057404 723.493896,460.086945 725.210327,457.960022
z"
        />
        <path
          fill="#46505D"
          opacity="1.000000"
          stroke="none"
          d="
M1313.139404,459.079102
	C1312.651611,460.979797 1314.035278,463.732666 1312.110352,463.757324
	C1309.568237,463.789886 1306.883667,465.263367 1304.403198,463.726288
	C1303.187988,462.973267 1302.800781,461.672852 1302.968994,460.243530
	C1303.168335,458.550720 1304.368652,457.838928 1305.719849,457.046448
	C1307.230957,456.160248 1310.653687,458.535583 1310.264526,455.376709
	C1309.860229,452.093994 1306.449341,454.243896 1304.411621,453.788940
	C1304.272705,453.757874 1304.156128,453.626556 1303.739624,453.348785
	C1305.608032,450.638184 1308.253052,451.110962 1310.605957,451.839752
	C1314.103149,452.922913 1312.756714,456.175140 1313.139404,459.079102
z"
        />
        <path
          fill="#46505D"
          opacity="1.000000"
          stroke="none"
          d="
M1051.216309,479.835175
	C1049.197998,479.670044 1047.589478,479.670044 1045.462036,479.670044
	C1047.555786,476.630768 1050.150146,477.280823 1052.562134,477.795898
	C1054.803101,478.274414 1055.040894,480.192200 1055.071777,482.099274
	C1055.085083,482.929382 1054.940186,483.787476 1055.101318,484.585510
	C1055.922974,488.652069 1054.361206,490.359589 1050.181396,490.223755
	C1047.706055,490.143341 1045.284180,490.342010 1045.003174,487.132233
	C1044.744385,484.174500 1046.630005,483.036224 1049.342407,482.923126
	C1051.230713,482.844391 1054.006958,483.427765 1051.216309,479.835175
z"
        />
        <path
          fill="#46505D"
          opacity="1.000000"
          stroke="none"
          d="
M1132.123779,489.972839
	C1130.020874,489.118256 1128.475464,490.367828 1126.842529,490.298431
	C1124.785522,490.210999 1123.249268,489.535095 1122.987549,487.290131
	C1122.710205,484.910980 1124.005371,483.691589 1126.165894,482.951599
	C1127.610107,482.457031 1130.546631,484.290497 1130.317505,481.409821
	C1130.097168,478.640228 1127.286987,479.776001 1125.413940,479.817169
	C1124.950684,479.827332 1124.485229,479.736847 1123.614014,479.653931
	C1125.338257,476.731232 1127.838135,477.345703 1130.093262,477.656158
	C1132.588745,477.999725 1133.176025,479.947357 1133.047974,482.092377
	C1132.892212,484.702026 1133.659546,487.373260 1132.123779,489.972839
z"
        />
        <path
          fill="#46505D"
          opacity="1.000000"
          stroke="none"
          d="
M894.197510,482.891357
	C896.321411,480.964569 895.337708,479.990265 893.376892,479.683990
	C891.960449,479.462738 890.481567,479.640839 888.612366,479.640839
	C890.362427,476.824097 892.750000,477.219818 895.079712,477.628754
	C897.557556,478.063690 898.075500,479.915924 898.073975,482.073181
	C898.073120,483.232574 897.963074,484.405701 898.100708,485.548706
	C898.459839,488.532593 898.151306,490.396149 894.195801,490.054260
	C891.570923,489.827393 888.457642,491.040955 888.051880,487.203278
	C887.629822,483.211090 890.692444,482.843750 894.197510,482.891357
z"
        />
        <path
          fill="#46505D"
          opacity="1.000000"
          stroke="none"
          d="
M1938.904053,431.466766
	C1940.750488,429.918030 1944.472900,432.678711 1944.256104,429.365509
	C1944.061768,426.397278 1940.867065,427.917542 1938.876099,427.886047
	C1938.590088,427.881531 1938.307617,427.666260 1937.690308,427.410492
	C1939.616455,424.642242 1942.204224,425.256256 1944.629517,425.815582
	C1946.862915,426.330658 1947.039551,428.270050 1947.071655,430.160797
	C1947.085693,430.989471 1947.155518,431.827545 1947.062012,432.645599
	C1946.860962,434.406372 1947.986450,437.091156 1946.410767,437.731140
	C1944.302490,438.587524 1941.360474,438.714355 1939.242188,437.917114
	C1935.944092,436.675781 1936.132324,434.084839 1938.904053,431.466766
z"
        />
        <path
          fill="#46505D"
          opacity="1.000000"
          stroke="none"
          d="
M1891.045410,436.701019
	C1889.518433,438.965240 1887.417480,437.498138 1885.584351,438.110016
	C1883.421265,438.832031 1881.193237,437.969116 1880.984985,435.088806
	C1880.792236,432.421722 1882.386230,431.200256 1884.873413,430.941650
	C1885.365356,430.890472 1885.871704,430.970184 1886.361816,430.910217
	C1887.238892,430.802826 1888.548950,431.232025 1888.495972,429.691803
	C1888.448975,428.332031 1887.520264,427.793945 1886.196289,427.583130
	C1884.791870,427.359497 1883.347046,428.469482 1881.606934,427.201660
	C1883.791260,424.872131 1886.299805,425.122009 1888.756104,425.853821
	C1891.200684,426.582153 1891.053223,428.790009 1891.073364,430.787048
	C1891.091797,432.608704 1891.073608,434.430664 1891.045410,436.701019
z"
        />
        <path
          fill="#46505D"
          opacity="1.000000"
          stroke="none"
          d="
M1383.247437,488.334442
	C1382.549927,484.871246 1383.926514,483.322906 1386.986938,482.887299
	C1388.213989,482.712677 1390.473267,483.895081 1390.424194,481.559296
	C1390.376709,479.296967 1388.225098,479.578461 1386.552124,479.661987
	C1385.667114,479.706207 1384.698730,480.208649 1383.437256,479.092010
	C1386.548584,476.983337 1389.793823,476.157440 1391.968872,478.806519
	C1394.377441,481.740051 1393.935669,486.335602 1392.455444,489.163452
	C1391.698853,490.609192 1386.361694,491.733765 1383.247437,488.334442
z"
        />
        <path
          fill="#46505D"
          opacity="1.000000"
          stroke="none"
          d="
M1502.022949,487.595276
	C1501.878662,484.249146 1503.556274,483.184479 1506.264526,482.861481
	C1507.379883,482.728455 1509.757080,484.083893 1509.386841,481.302673
	C1509.122681,479.317627 1507.273315,479.591675 1505.774536,479.662201
	C1504.854126,479.705505 1503.933594,479.748291 1502.958252,479.155670
	C1505.425171,477.108887 1508.113647,476.637360 1510.932495,478.579956
	C1512.123657,479.400757 1512.640625,488.679840 1511.733765,489.629669
	C1510.561035,490.858276 1503.829224,489.710388 1502.022949,487.595276
z"
        />
        <path
          fill="#46505D"
          opacity="1.000000"
          stroke="none"
          d="
M1426.232178,477.843536
	C1429.083496,477.148956 1431.968994,476.600159 1433.322144,479.191803
	C1434.962769,482.334198 1434.615845,486.178711 1433.511108,489.318939
	C1433.191040,490.229034 1428.856689,490.708191 1426.297119,490.074982
	C1424.345215,489.592102 1423.723267,488.021301 1424.003662,486.216858
	C1424.419434,483.541107 1426.396606,482.962189 1428.776611,482.879913
	C1429.739746,482.846588 1431.438965,483.618103 1431.462402,481.766052
	C1431.487793,479.738434 1429.728760,479.929169 1428.299927,479.466125
	C1427.633423,479.250122 1423.871826,482.396851 1426.232178,477.843536
z"
        />
        <path
          fill="#4F5965"
          opacity="1.000000"
          stroke="none"
          d="
M616.053772,487.687500
	C616.047852,486.239349 616.046814,485.246918 616.046814,484.254486
	C616.046509,477.404297 616.046387,477.381653 622.810608,477.321533
	C626.393860,477.289673 627.352051,479.601593 627.054138,482.602234
	C626.809692,485.065094 628.128235,489.603485 626.089539,489.575867
	C622.451599,489.526581 624.980652,485.431213 624.401550,483.164154
	C623.999512,481.590302 624.449158,479.721649 621.933533,479.727875
	C619.556946,479.733795 618.777710,480.969177 618.932251,483.197327
	C619.046448,484.843079 618.960632,486.503998 618.934204,488.157684
	C618.920776,488.999542 618.811768,489.845917 617.731262,489.970215
	C616.366394,490.127167 616.096436,489.229187 616.053772,487.687500
z"
        />
        <path
          fill="#2F3A48"
          opacity="1.000000"
          stroke="none"
          d="
M989.288330,477.765076
	C993.681396,479.451172 993.504028,482.854431 992.966736,486.164368
	C992.598938,488.430481 991.043091,489.866516 988.580383,490.187469
	C984.679871,490.695740 982.136475,488.777344 981.790527,484.935242
	C981.321411,479.725433 983.307495,477.702881 989.288330,477.765076
z"
        />
        <path
          fill="#4F5965"
          opacity="1.000000"
          stroke="none"
          d="
M692.931030,463.935028
	C689.316223,460.494934 691.498291,456.328918 691.523621,452.495148
	C691.526123,452.118622 696.158813,451.655670 698.660095,451.458771
	C700.051025,451.349274 701.510376,452.110718 701.662109,453.541351
	C701.991394,456.646118 701.971619,459.790314 702.029114,462.919586
	C702.042786,463.661011 701.494324,464.092896 700.730408,464.022278
	C700.003418,463.955048 699.485962,463.497864 699.449829,462.772552
	C699.359497,460.959137 699.339050,459.141541 699.322876,457.325348
	C699.306030,455.432220 699.365601,453.536591 696.589783,453.711090
	C694.051697,453.870697 693.965393,455.554810 693.909668,457.532928
	C693.850403,459.633179 694.531982,461.824127 692.931030,463.935028
z"
        />
        <path
          fill="#2F3A48"
          opacity="1.000000"
          stroke="none"
          d="
M744.213013,483.976440
	C744.039734,488.681183 741.722412,490.654938 737.471863,490.158081
	C733.771851,489.725616 732.758118,487.171509 732.754761,483.808685
	C732.751282,480.363068 734.077271,477.878540 737.637207,477.533417
	C741.849731,477.125061 744.112915,479.322754 744.213013,483.976440
z"
        />
        <path
          fill="#4F5965"
          opacity="1.000000"
          stroke="none"
          d="
M1791.343262,432.871033
	C1791.050415,430.509918 1792.277588,427.678802 1788.687256,427.736237
	C1785.324707,427.789978 1786.018677,430.367737 1785.955688,432.427429
	C1785.940430,432.922882 1786.054077,433.445221 1785.934204,433.910248
	C1785.571045,435.319336 1786.991699,437.880707 1784.503906,437.884918
	C1782.026001,437.889130 1783.384277,435.340668 1783.070435,433.921783
	C1782.931030,433.291382 1783.046875,432.604523 1783.046753,431.943298
	C1783.045532,426.133942 1784.720947,424.699890 1790.635498,425.404083
	C1792.507446,425.626953 1793.749634,426.560699 1793.942871,428.473450
	C1794.108398,430.112030 1794.281982,431.808014 1794.032837,433.413574
	C1793.791504,434.968353 1795.317993,437.835144 1792.755737,437.824188
	C1790.257812,437.813538 1791.797974,434.902832 1791.343262,432.871033
z"
        />
        <path
          fill="#4F5965"
          opacity="1.000000"
          stroke="none"
          d="
M1065.791504,477.373260
	C1068.530273,479.179810 1070.366211,487.275665 1068.846313,489.407623
	C1068.618896,489.726624 1068.072998,489.985748 1067.677490,489.981659
	C1066.944946,489.974121 1066.470215,489.432617 1066.442627,488.713287
	C1066.366577,486.727295 1066.376343,484.738220 1066.328979,482.750885
	C1066.291504,481.181580 1066.147461,479.681610 1063.988403,479.729797
	C1062.065552,479.772766 1060.771240,480.433014 1060.954956,482.653381
	C1060.982178,482.982422 1061.046387,483.349182 1060.939331,483.642120
	C1060.185669,485.706024 1062.634033,489.636444 1059.277466,489.650940
	C1056.736694,489.661957 1058.585571,485.769928 1058.058350,483.691193
	C1056.300903,476.760559 1061.900757,478.224426 1065.791504,477.373260
z"
        />
        <path
          fill="#4F5965"
          opacity="1.000000"
          stroke="none"
          d="
M1887.097900,459.923767
	C1886.686523,461.601379 1888.091431,463.891418 1885.791382,463.906494
	C1883.578003,463.921051 1884.718750,461.630676 1884.326782,460.364471
	C1883.619141,458.078156 1886.321045,453.843567 1881.650635,453.870575
	C1876.983398,453.897552 1879.652954,458.127838 1878.962158,460.453064
	C1878.590576,461.703949 1879.794067,463.967224 1877.466919,463.924530
	C1875.201172,463.882935 1876.295532,461.669373 1876.072876,460.377136
	C1875.934448,459.574219 1876.046753,458.728088 1876.046631,457.901337
	C1876.045776,452.805267 1879.254028,450.157166 1884.159180,451.525787
	C1888.552979,452.751709 1886.637817,456.619476 1887.097900,459.923767
z"
        />
        <path
          fill="#4F5965"
          opacity="1.000000"
          stroke="none"
          d="
M580.946045,435.703857
	C580.905518,437.252838 580.609619,438.132935 579.254761,437.969025
	C578.174500,437.838348 578.072632,436.981781 578.066467,436.145416
	C578.045837,433.334747 578.076050,430.523621 578.046692,427.713074
	C578.025879,425.713898 578.972412,425.889160 580.592224,426.105560
	C583.484009,426.491943 587.486023,423.188995 588.822510,428.273041
	C589.515625,430.909698 589.036743,433.858307 589.053589,436.666168
	C589.057678,437.348419 588.829529,437.963684 587.979919,437.992676
	C586.984192,438.026672 586.451599,437.484741 586.419678,436.531738
	C586.358704,434.715057 586.364380,432.896149 586.335327,431.078308
	C586.307983,429.364838 586.297852,427.662811 583.841187,427.710419
	C581.423096,427.757294 580.765320,429.115021 580.935974,431.283356
	C581.039429,432.597870 580.952759,433.927338 580.946045,435.703857
z"
        />
        <path
          fill="#4F5965"
          opacity="1.000000"
          stroke="none"
          d="
M1836.090210,478.992340
	C1838.433594,478.190002 1840.729736,477.636719 1843.056763,477.427704
	C1845.086792,477.245422 1846.729370,478.198669 1846.915039,480.397919
	C1847.137207,483.030457 1847.053955,485.689789 1847.075562,488.337708
	C1847.082397,489.188599 1846.937988,490.034729 1845.810669,490.009583
	C1844.780640,489.986603 1844.416504,489.270874 1844.397217,488.356781
	C1844.355469,486.370117 1844.343384,484.382843 1844.321777,482.395752
	C1844.302612,480.629700 1843.602539,479.541626 1841.646729,479.715759
	C1839.955322,479.866394 1838.859985,480.539459 1838.945312,482.493866
	C1839.024780,484.312103 1838.960083,486.137207 1838.939209,487.959045
	C1838.927368,488.987823 1838.834106,490.058960 1837.440552,490.002930
	C1836.059082,489.947327 1836.067017,488.832703 1836.061279,487.838562
	C1836.045044,485.022766 1836.056030,482.206848 1836.090210,478.992340
z"
        />
        <path
          fill="#4F5965"
          opacity="1.000000"
          stroke="none"
          d="
M1325.967651,452.372559
	C1327.873291,455.819611 1326.813354,459.187134 1327.063110,462.441162
	C1327.131470,463.333069 1326.812378,464.115753 1325.712524,464.012329
	C1324.705200,463.917603 1324.408936,463.147614 1324.385864,462.256866
	C1324.355957,461.098633 1324.487305,459.919495 1324.313354,458.785492
	C1324.026367,456.915497 1325.495483,454.033569 1322.043335,453.768555
	C1318.358032,453.485596 1319.225464,456.358795 1318.879272,458.563019
	C1318.591553,460.395020 1320.504028,463.740997 1317.349365,463.753265
	C1314.894043,463.762817 1316.142944,460.499817 1316.102539,458.766052
	C1315.924927,451.155609 1317.321167,450.250519 1325.967651,452.372559
z"
        />
        <path
          fill="#2F3A48"
          opacity="1.000000"
          stroke="none"
          d="
M655.405884,437.564331
	C653.047180,436.014954 652.754395,433.977264 652.762207,431.734344
	C652.775146,428.031891 654.313660,425.664886 658.209839,425.550476
	C662.322815,425.429657 664.173340,427.756805 664.208984,431.709930
	C664.243225,435.506714 662.899780,438.171570 658.599670,438.193298
	C657.632935,438.198212 656.664795,437.923035 655.405884,437.564331
z"
        />
        <path
          fill="#2F3A48"
          opacity="1.000000"
          stroke="none"
          d="
M1338.997803,477.983032
	C1341.461060,479.266571 1342.161133,481.196564 1342.203857,483.468597
	C1342.273804,487.192505 1341.185669,490.023254 1336.837280,490.205536
	C1333.024902,490.365295 1330.963135,488.531158 1330.791138,484.688171
	C1330.617676,480.812408 1331.541260,477.719574 1336.214111,477.539398
	C1337.018311,477.508423 1337.833496,477.759094 1338.997803,477.983032
z"
        />
        <path
          fill="#2F3A48"
          opacity="1.000000"
          stroke="none"
          d="
M1542.707031,462.669464
	C1539.490601,464.855652 1536.427979,465.123627 1534.051270,462.293304
	C1531.967529,459.811554 1532.196289,456.680206 1533.715332,453.950775
	C1535.252930,451.188049 1537.981812,451.063965 1540.671387,451.920471
	C1544.352905,453.092926 1545.201538,457.246246 1542.707031,462.669464
z"
        />
        <path
          fill="#2F3A48"
          opacity="1.000000"
          stroke="none"
          d="
M698.589844,515.581055
	C692.110229,516.630920 689.932251,515.194397 689.774414,510.235535
	C689.652588,506.407074 690.971863,503.751373 695.206360,503.535645
	C699.156433,503.334381 701.298157,505.791779 701.222961,510.204407
	C701.187500,512.286194 700.653381,514.098206 698.589844,515.581055
z"
        />
        <path
          fill="#2F3A48"
          opacity="1.000000"
          stroke="none"
          d="
M1081.199829,453.820709
	C1082.919922,459.768677 1081.936523,463.192780 1078.348633,464.024323
	C1075.634033,464.653473 1072.813110,464.424744 1071.515381,461.552826
	C1070.180176,458.597870 1069.887939,455.206421 1072.625244,452.894104
	C1075.322998,450.615082 1078.391846,450.877899 1081.199829,453.820709
z"
        />
        <path
          fill="#979DA1"
          opacity="1.000000"
          stroke="none"
          d="
M1024.121948,262.279327
	C1024.870483,262.151215 1025.201904,262.121735 1025.532104,262.129883
	C1028.128906,262.193817 1027.830688,264.186920 1027.682129,265.731628
	C1027.264771,270.072845 1029.140259,274.703613 1026.556030,278.673920
	C1025.959717,279.590057 1022.993469,279.712036 1023.050110,277.104736
	C1023.156494,272.201843 1022.114258,267.231903 1024.121948,262.279327
z"
        />
        <path
          fill="#2F3A48"
          opacity="1.000000"
          stroke="none"
          d="
M670.706299,458.720764
	C669.668579,459.594940 668.889404,460.211853 667.788269,461.083649
	C670.549927,462.920563 673.677368,460.912231 675.823486,462.740143
	C675.400269,464.196381 674.415833,464.074768 673.721741,463.981628
	C670.720154,463.578857 666.458252,465.652527 665.023315,463.015137
	C663.805725,460.777039 668.197021,459.017975 669.682434,456.705292
	C670.978210,454.687775 673.552734,452.926636 671.760742,450.215485
	C670.018311,447.579498 667.618591,450.019409 665.337891,449.687103
	C666.985718,447.093323 669.441162,446.243774 672.274536,447.490051
	C675.264587,448.805267 675.729492,451.365326 674.199280,454.108643
	C673.325867,455.674469 672.056091,457.019226 670.706299,458.720764
z"
        />
        <path
          fill="#2F3A48"
          opacity="1.000000"
          stroke="none"
          d="
M929.527649,423.597687
	C930.778137,429.439209 925.987671,431.649506 923.321655,435.377563
	C925.585815,436.877136 928.473572,435.116730 931.852356,437.255249
	C927.543213,438.516388 924.012146,438.222351 920.700928,437.852386
	C918.607910,437.618622 919.829102,435.897980 920.662476,434.944580
	C922.195068,433.191315 923.903625,431.588013 925.385681,429.795532
	C926.640015,428.278564 928.174011,426.601654 926.931396,424.454498
	C925.518188,422.012512 923.507263,423.305389 921.650513,423.947449
	C921.236084,424.090790 920.721985,424.506897 920.349365,423.828156
	C923.121033,420.080811 925.341064,419.931885 929.527649,423.597687
z"
        />
        <path
          fill="#2F3A48"
          opacity="1.000000"
          stroke="none"
          d="
M1733.953369,423.464294
	C1731.274048,423.264801 1729.731689,423.626373 1729.969116,426.591675
	C1730.220337,429.727783 1730.040161,432.899994 1730.009399,436.056061
	C1730.001831,436.821228 1730.178711,437.842957 1728.984131,437.903717
	C1727.788208,437.964508 1727.820923,436.997070 1727.803101,436.180756
	C1727.774048,434.852356 1727.819580,433.521973 1727.774658,432.194397
	C1727.631958,427.983215 1729.847900,422.442169 1721.657104,422.304321
	C1726.286499,420.787964 1730.474731,420.992676 1734.580933,421.386658
	C1735.951904,421.518188 1735.354980,422.816376 1733.953369,423.464294
z"
        />
        <path
          fill="#46505D"
          opacity="1.000000"
          stroke="none"
          d="
M1294.262451,453.128723
	C1293.645752,453.515228 1293.136230,453.705597 1292.695679,453.602173
	C1291.344360,453.285004 1289.743652,453.027954 1289.275879,454.523163
	C1288.726562,456.278931 1290.637451,456.390106 1291.739380,456.952698
	C1295.591797,458.919647 1296.528687,461.784271 1293.719971,463.459625
	C1291.443970,464.817261 1288.893555,464.925323 1286.536743,463.113464
	C1286.898193,462.607513 1287.026001,462.215424 1287.248169,462.151581
	C1289.002441,461.647369 1291.768188,463.645569 1292.445312,461.056702
	C1293.060547,458.704285 1289.984497,459.044525 1288.603027,458.140442
	C1286.629639,456.849091 1285.580688,455.080536 1287.115723,453.221039
	C1289.152100,450.754364 1291.831665,450.552704 1294.262451,453.128723
z"
        />
        <path
          fill="#46505D"
          opacity="1.000000"
          stroke="none"
          d="
M1908.702148,434.921570
	C1909.026855,435.180389 1909.041016,435.057526 1909.035889,435.027100
	C1906.576172,438.861694 1903.361694,438.904724 1898.660278,437.231964
	C1899.994385,436.456177 1900.349609,436.083862 1900.728638,436.057709
	C1902.409668,435.941925 1904.849121,437.416992 1905.480225,435.025970
	C1906.054932,432.849579 1903.304688,433.048035 1901.997925,432.321747
	C1899.979614,431.199921 1898.584473,429.651489 1899.933716,427.562286
	C1901.914185,424.495880 1904.656006,424.879272 1907.415894,426.582275
	C1907.078735,427.816437 1906.358154,427.761261 1905.733398,427.614380
	C1904.382324,427.296600 1902.769653,427.014862 1902.291504,428.517151
	C1901.727295,430.289642 1903.628296,430.460510 1904.766968,430.944092
	C1906.511963,431.684998 1908.062256,432.558655 1908.702148,434.921570
z"
        />
        <path
          fill="#46505D"
          opacity="1.000000"
          stroke="none"
          d="
M650.702148,512.921631
	C651.026978,513.180481 651.041077,513.057556 651.036011,513.027100
	C648.586548,516.909241 645.336731,516.837280 641.270203,515.521912
	C641.872253,514.634277 641.991516,514.235657 642.214355,514.165344
	C643.969849,513.611694 646.766174,515.658569 647.443359,513.056030
	C648.058350,510.692535 644.958008,511.044586 643.572571,510.124420
	C641.588684,508.806763 640.594971,507.036621 642.150085,505.157959
	C644.218262,502.659515 646.856323,502.900574 649.524902,504.630554
	C648.512146,506.456940 647.266296,505.132874 646.282288,505.394714
	C645.545105,505.590851 644.530334,505.943787 644.276855,506.511597
	C643.718628,507.761871 644.963806,508.256439 645.823669,508.601166
	C647.858398,509.416962 649.950073,510.078949 650.702148,512.921631
z"
        />
        <path
          fill="#46505D"
          opacity="1.000000"
          stroke="none"
          d="
M766.034241,487.898376
	C767.177551,485.813995 765.811096,485.461304 764.543701,484.938354
	C762.215820,483.977905 759.272766,482.925507 760.903198,479.723999
	C762.624084,476.344696 765.609070,477.009399 768.481323,478.486603
	C767.905640,480.239014 766.837646,479.538788 765.924683,479.450043
	C764.730774,479.334015 763.441772,479.313263 763.204895,480.819336
	C763.005310,482.088440 764.262512,482.383362 765.129089,482.717010
	C767.786133,483.740143 770.677490,485.151550 768.686157,488.351593
	C766.757568,491.450867 763.527405,490.792389 760.039307,488.940796
	C762.177063,487.354675 764.091919,488.921051 766.034241,487.898376
z"
        />
        <path
          fill="#46505D"
          opacity="1.000000"
          stroke="none"
          d="
M1426.677612,458.015717
	C1427.226196,458.601257 1427.483032,458.966064 1427.857788,459.451080
	C1427.501831,464.588013 1424.617676,465.734924 1419.726685,462.850861
	C1419.897339,462.609619 1420.024170,462.217499 1420.246948,462.152039
	C1422.000366,461.636932 1424.775269,463.646027 1425.447021,461.050262
	C1426.054688,458.702271 1422.973267,459.043121 1421.589844,458.133087
	C1419.614380,456.833679 1418.602417,455.083588 1420.122681,453.194550
	C1422.177734,450.640961 1424.838623,450.942657 1427.602661,452.597778
	C1426.168701,454.914276 1422.547119,451.728577 1422.285889,454.867462
	C1422.102051,457.078827 1425.148438,456.564026 1426.677612,458.015717
z"
        />
        <path
          fill="#46505D"
          opacity="1.000000"
          stroke="none"
          d="
M668.681763,482.994629
	C666.955994,485.428131 667.812134,489.909027 664.428833,489.747040
	C661.781006,489.620270 661.724426,485.651215 660.852417,483.301117
	C660.218933,481.593933 657.471985,478.903656 660.037170,478.016083
	C662.646301,477.113312 661.822449,481.174194 663.014404,482.769409
	C663.679260,483.659302 663.897095,484.883179 664.420166,486.222412
	C666.232544,484.305573 666.407837,481.949463 667.271179,479.905945
	C667.669312,478.963806 667.820618,477.183655 669.463623,477.875916
	C671.183411,478.600586 669.675354,479.800293 669.452759,480.775574
	C669.306152,481.417999 669.033020,482.031555 668.681763,482.994629
z"
        />
        <path
          fill="#46505D"
          opacity="1.000000"
          stroke="none"
          d="
M719.069092,423.810181
	C718.843628,426.725983 722.188232,424.812653 722.471558,427.211914
	C719.699402,427.697113 718.972717,429.422546 718.894104,432.399506
	C718.813660,435.443359 720.325195,436.218018 723.101196,436.745514
	C718.189026,438.953888 716.837402,438.211578 716.350220,433.993134
	C716.091797,431.755920 717.688904,428.932465 714.562683,427.461334
	C716.340637,426.022705 716.263611,423.259247 718.405579,422.080627
	C718.620117,422.550568 718.834656,423.020538 719.069092,423.810181
z"
        />
        <path
          fill="#46505D"
          opacity="1.000000"
          stroke="none"
          d="
M1018.650391,454.404724
	C1017.489319,457.319397 1016.584778,459.986176 1015.440674,462.545868
	C1014.499573,464.651337 1012.980896,464.876251 1011.945007,462.705139
	C1010.524658,459.728088 1009.457153,456.582855 1008.232849,453.511932
	C1007.969910,452.852692 1007.874023,452.108063 1008.621094,451.851471
	C1009.465149,451.561615 1010.322998,451.930725 1010.658081,452.803040
	C1011.578064,455.197601 1012.407410,457.627014 1013.385193,460.356842
	C1015.397522,458.016846 1015.539246,455.422913 1016.541809,453.203156
	C1016.993225,452.203796 1017.542114,451.303162 1018.711731,451.912231
	C1019.697327,452.425385 1018.962341,453.357849 1018.650391,454.404724
z"
        />
        <path
          fill="#4F5965"
          opacity="1.000000"
          stroke="none"
          d="
M1865.282471,426.325958
	C1867.790039,425.241821 1869.962769,424.807922 1871.943115,426.601501
	C1871.809570,427.017792 1871.789917,427.504852 1871.609497,427.574493
	C1870.076416,428.165863 1867.507568,426.202759 1866.983887,428.780090
	C1866.560425,430.864441 1869.250366,430.654053 1870.533569,431.465973
	C1872.043823,432.421631 1873.229126,433.514587 1872.716309,435.425568
	C1872.246460,437.177307 1870.989014,438.249298 1869.108765,438.277008
	C1867.294312,438.303802 1865.314331,438.666412 1863.977905,437.176941
	C1864.464722,435.712708 1865.206787,436.045135 1865.784180,436.070282
	C1867.206421,436.132172 1869.179077,437.011749 1869.779785,435.352692
	C1870.657227,432.929443 1867.991211,433.113190 1866.725708,432.440765
	C1864.057861,431.023346 1862.907837,429.320770 1865.282471,426.325958
z"
        />
        <path
          fill="#4F5965"
          opacity="1.000000"
          stroke="none"
          d="
M598.645386,453.541748
	C597.193909,453.420685 596.108398,453.496124 595.886902,454.722412
	C595.660522,455.975800 596.806824,456.372009 597.685120,456.733490
	C600.234436,457.782471 603.296326,458.904083 601.197998,462.309540
	C599.165161,465.608765 595.985535,464.645233 592.969788,463.300110
	C593.227112,462.698761 593.279602,462.248474 593.472107,462.177368
	C595.231689,461.527679 598.107361,463.576691 598.762634,461.165039
	C599.501160,458.447235 595.977844,459.127441 594.613159,457.917480
	C593.403748,456.845062 592.580383,455.665741 593.097900,454.006989
	C593.680786,452.138550 595.279419,451.529572 596.989380,451.425964
	C598.416443,451.339447 600.294434,451.106598 600.825439,452.823486
	C601.169250,453.935181 599.828125,453.672546 598.645386,453.541748
z"
        />
        <path
          fill="#4F5965"
          opacity="1.000000"
          stroke="none"
          d="
M962.303406,427.507751
	C966.444458,424.699249 966.666382,424.698029 969.871704,427.158142
	C969.315918,428.182312 968.445251,427.592560 967.727844,427.537201
	C966.555969,427.446838 965.198914,427.210571 964.916321,428.703705
	C964.635498,430.187531 966.082031,430.440338 967.109863,430.872253
	C969.744812,431.979492 972.151184,433.304779 970.074585,436.683533
	C968.657410,438.989471 965.186707,438.890350 962.169800,436.677002
	C962.187012,435.916962 962.797913,436.064178 963.231262,436.022064
	C964.868347,435.863007 967.311646,437.451080 967.872864,434.944305
	C968.347839,432.822876 965.641479,433.001984 964.328735,432.259644
	C962.510559,431.231506 961.607361,429.942505 962.303406,427.507751
z"
        />
        <path
          fill="#46505D"
          opacity="1.000000"
          stroke="none"
          d="
M1138.765503,483.238403
	C1139.174805,484.525146 1139.263062,485.579681 1140.609375,486.021820
	C1141.417725,483.710297 1142.224731,481.413788 1143.023315,479.114441
	C1143.413574,477.990936 1144.237671,477.430084 1145.291260,477.961639
	C1146.427368,478.534760 1145.382202,479.361359 1145.237061,480.071167
	C1145.171021,480.393433 1145.111694,480.751801 1144.930542,481.010406
	C1142.942993,483.846863 1143.808960,489.782928 1139.917480,489.582672
	C1136.637573,489.413879 1136.855957,483.659760 1135.172607,480.550507
	C1134.798706,479.859833 1133.862427,478.603485 1135.075439,477.964386
	C1136.534912,477.195374 1137.045776,478.459991 1137.438599,479.625458
	C1137.807617,480.720581 1138.236450,481.795471 1138.765503,483.238403
z"
        />
        <path
          fill="#46505D"
          opacity="1.000000"
          stroke="none"
          d="
M1522.839844,483.943665
	C1521.344727,486.075195 1521.979004,489.775330 1519.319336,489.838989
	C1516.239014,489.912750 1516.591553,486.214783 1515.676270,484.118927
	C1514.833008,482.188110 1512.620728,478.647827 1514.242554,478.223999
	C1517.389648,477.401489 1516.465820,481.701355 1517.817017,483.503815
	C1518.369385,484.240845 1518.130981,485.509338 1519.557495,485.991058
	C1520.335205,483.893250 1521.201050,481.819153 1521.860596,479.681427
	C1522.257202,478.395935 1522.917114,477.312042 1524.227661,477.836792
	C1525.647827,478.405548 1524.391602,479.495544 1524.148804,480.319580
	C1523.822144,481.428223 1523.373047,482.500763 1522.839844,483.943665
z"
        />
        <path
          fill="#46505D"
          opacity="1.000000"
          stroke="none"
          d="
M1834.981079,427.348633
	C1835.406250,426.148621 1836.030518,425.605957 1836.962158,425.831909
	C1838.237549,426.141205 1837.691772,426.974945 1837.392822,427.734741
	C1836.303589,430.503326 1835.223755,433.275818 1834.170166,436.058167
	C1833.808594,437.013550 1833.468018,437.941833 1832.249023,438.037109
	C1830.978882,438.136414 1830.532959,437.174225 1830.167114,436.271301
	C1829.049927,433.514679 1827.974609,430.739807 1826.957031,427.945099
	C1826.715820,427.282349 1826.014404,426.326599 1827.203735,425.885742
	C1828.426025,425.432556 1828.945190,426.268341 1829.320923,427.313904
	C1830.139160,429.590515 1831.027832,431.841797 1832.210327,434.951416
	C1833.341064,431.893646 1834.119141,429.789093 1834.981079,427.348633
z"
        />
        <path
          fill="#46505D"
          opacity="1.000000"
          stroke="none"
          d="
M1400.107910,476.335754
	C1400.436768,478.604980 1403.271118,476.761383 1403.402710,479.349670
	C1400.047119,479.489258 1399.996582,481.752899 1399.954956,484.584991
	C1399.914673,487.317749 1401.084229,488.304260 1403.959595,488.403534
	C1399.665283,491.156006 1397.952515,490.317261 1397.335693,486.187195
	C1397.002808,483.957489 1398.916382,481.044556 1395.603149,479.584229
	C1396.593506,477.945923 1397.362793,476.095734 1398.655518,474.747101
	C1399.460571,473.907288 1399.912598,475.145355 1400.107910,476.335754
z"
        />
        <path
          fill="#46505D"
          opacity="1.000000"
          stroke="none"
          d="
M531.823486,451.950806
	C531.965271,454.455200 527.851440,454.196899 528.893066,457.782166
	C529.574890,460.128967 529.022095,462.644592 533.290588,462.458405
	C528.556519,464.958954 527.088989,464.295135 526.328491,460.543762
	C525.846680,458.167053 527.766724,455.345184 524.707886,453.234711
	C524.157837,452.855164 527.367981,450.682007 527.283691,448.404266
	C529.765625,448.707947 528.527283,452.769135 531.823486,451.950806
z"
        />
        <path
          fill="#46505D"
          opacity="1.000000"
          stroke="none"
          d="
M947.273560,464.083344
	C944.417786,464.481506 942.868958,463.620972 942.385925,460.910889
	C941.935181,458.381836 943.713074,455.444550 940.700562,453.198547
	C940.200806,452.825928 943.338257,450.664124 943.248413,447.283783
	C945.227966,450.039917 945.869080,452.502625 948.848511,452.654205
	C946.428467,453.856384 944.533997,454.700958 944.937805,457.863281
	C945.250183,460.310455 945.260681,462.173309 948.230408,462.595947
	C948.709778,462.664154 948.289185,463.710999 947.273560,464.083344
z"
        />
        <path
          fill="#46505D"
          opacity="1.000000"
          stroke="none"
          d="
M529.196411,490.148254
	C526.577209,489.458893 526.616089,487.603363 526.337585,485.747528
	C526.024536,483.661621 527.732483,480.953461 524.659851,479.617462
	C525.689392,477.863617 526.718872,476.109772 528.409424,473.229706
	C528.635803,476.822174 530.125000,478.170288 533.034851,478.282990
	C527.957153,481.460968 527.811829,484.320557 531.877808,488.283478
	C532.150024,488.548798 532.406433,489.243866 532.260315,489.453827
	C531.603943,490.396851 530.511719,490.142181 529.196411,490.148254
z"
        />
        <path
          fill="#46505D"
          opacity="1.000000"
          stroke="none"
          d="
M1450.378418,457.116180
	C1448.680420,453.543518 1449.291626,450.838074 1452.399780,448.167877
	C1452.746216,450.661407 1453.980591,452.277283 1456.959229,452.599792
	C1452.427124,453.420959 1453.400879,456.528473 1452.972534,459.253265
	C1452.474609,462.421051 1455.357178,461.676300 1456.607056,462.857117
	C1451.654663,465.375092 1450.483643,464.321594 1450.378418,457.116180
z"
        />
        <path
          fill="#46505D"
          opacity="1.000000"
          stroke="none"
          d="
M723.880066,488.254089
	C722.196167,490.885712 719.792603,490.775970 718.713989,488.084564
	C717.552612,485.186584 719.879456,481.408691 716.460571,479.064209
	C717.897339,477.849274 718.977112,476.377014 719.418579,473.869904
	C721.459106,475.774506 721.376770,479.081360 725.263550,478.446014
	C720.950684,479.554565 720.831482,482.062592 721.023132,484.961060
	C721.143433,486.780090 721.101501,488.336639 723.880066,488.254089
z"
        />
        <path
          fill="#46505D"
          opacity="1.000000"
          stroke="none"
          d="
M970.221069,487.237183
	C971.180481,488.428467 972.815186,487.465118 973.343994,488.841583
	C973.647644,489.631989 972.940735,489.986572 972.244019,490.081177
	C970.554016,490.310608 968.514099,490.523346 968.001099,488.552368
	C967.306885,485.885315 967.386597,483.025909 966.861633,480.301544
	C966.628540,479.092010 966.818237,477.834473 969.543335,473.862671
	C969.520203,476.988342 971.281250,478.051605 973.988586,478.708405
	C968.558167,479.897308 970.241943,483.743225 970.221069,487.237183
z"
        />
        <path
          fill="#46505D"
          opacity="1.000000"
          stroke="none"
          d="
M680.629883,506.111786
	C679.147034,503.221497 683.190613,502.928497 682.616150,500.265320
	C684.126770,502.199982 684.797913,504.582916 688.030029,504.451385
	C686.076111,506.100494 683.650818,505.950378 683.915649,509.071564
	C684.159058,511.940704 684.177185,514.308472 688.292175,514.927673
	C682.855408,516.747437 681.675476,516.056824 681.385986,511.726654
	C681.265137,509.919647 681.116455,508.114471 680.629883,506.111786
z"
        />
        <path
          fill="#46505D"
          opacity="1.000000"
          stroke="none"
          d="
M1971.190674,426.038727
	C1973.456787,425.562683 1972.022217,422.899536 1974.349854,422.485870
	C1974.915894,424.391296 1975.794067,426.407776 1978.941528,426.492065
	C1974.254028,427.568939 1975.264282,430.824524 1975.013794,433.704926
	C1974.769409,436.515808 1977.315674,435.817352 1978.859009,436.659515
	C1974.588257,438.973206 1972.758057,438.326660 1972.453979,434.549286
	C1972.222290,431.672089 1972.654663,428.719849 1971.190674,426.038727
z"
        />
        <path
          fill="#46505D"
          opacity="1.000000"
          stroke="none"
          d="
M1405.889893,427.665405
	C1403.147949,427.595947 1402.913940,429.049042 1403.063965,430.933044
	C1403.269897,433.518799 1401.688232,437.028809 1407.351074,436.773865
	C1403.594238,438.580078 1401.188477,438.910309 1400.411133,434.841217
	C1399.922485,432.283508 1401.694336,429.153381 1398.510376,427.272888
	C1399.590576,425.661682 1401.106201,424.358459 1401.967041,422.188507
	C1402.152222,425.198883 1405.623047,425.080658 1405.889893,427.665405
z"
        />
        <path
          fill="#46505D"
          opacity="1.000000"
          stroke="none"
          d="
M1063.274170,438.084290
	C1060.419067,438.480896 1058.869019,437.621765 1058.385864,434.911285
	C1057.935059,432.382141 1059.712646,429.445221 1056.700684,427.198730
	C1056.200317,426.825562 1059.338501,424.664276 1059.248047,421.286163
	C1061.229248,424.038269 1061.868164,426.503601 1064.848755,426.654144
	C1062.427734,427.855957 1060.534180,428.701477 1060.937378,431.863403
	C1061.249390,434.311096 1061.261230,436.173523 1064.230591,436.596161
	C1064.709473,436.664337 1064.289673,437.711517 1063.274170,438.084290
z"
        />
        <path
          fill="#46505D"
          opacity="1.000000"
          stroke="none"
          d="
M1358.339722,488.030090
	C1360.414185,488.647400 1359.717041,489.601166 1358.743164,489.898621
	C1356.060669,490.718079 1353.275391,490.661926 1351.699707,487.960205
	C1350.009033,485.061401 1349.945312,481.777161 1352.224365,479.174957
	C1354.287109,476.819824 1356.949829,477.059998 1359.687500,478.983612
	C1356.204956,479.484863 1352.876709,479.767365 1353.271606,484.448700
	C1353.530518,487.518463 1355.208740,488.375549 1358.339722,488.030090
z"
        />
        <path
          fill="#46505D"
          opacity="1.000000"
          stroke="none"
          d="
M599.846313,427.682129
	C596.334106,427.331024 594.405151,428.235474 594.264954,431.857239
	C594.077637,436.693817 597.728699,435.977478 600.719421,436.775604
	C598.165161,438.722107 595.452087,439.027283 593.264099,436.620178
	C590.757263,433.862305 590.862915,430.319580 593.030701,427.512421
	C594.835876,425.174927 597.621582,424.400299 600.334656,426.669250
	C600.456543,426.771240 600.255432,427.259521 599.846313,427.682129
z"
        />
        <path
          fill="#46505D"
          opacity="1.000000"
          stroke="none"
          d="
M979.007568,463.815186
	C975.728882,464.650574 972.839539,464.525024 971.386536,461.477722
	C970.143433,458.870667 970.057190,455.898712 971.965942,453.483124
	C973.677673,451.316956 976.136414,450.973022 978.699036,451.963715
	C979.048523,452.098785 979.240234,452.641876 979.786438,453.372437
	C977.560913,454.160919 974.794495,452.927429 973.612793,455.645569
	C972.207825,458.877411 973.844238,461.599701 977.585388,462.063232
	C978.464417,462.172119 980.368225,461.324371 979.007568,463.815186
z"
        />
        <path
          fill="#46505D"
          opacity="1.000000"
          stroke="none"
          d="
M634.045593,487.810791
	C635.796387,488.382416 637.334229,487.497009 638.659790,488.512115
	C635.845703,490.780579 633.066345,490.927917 631.204468,488.619171
	C628.866455,485.720062 628.882202,482.309326 631.033447,479.489716
	C632.994446,476.919525 635.800659,476.952087 639.599060,478.848450
	C631.823425,480.444031 630.941589,482.037781 634.045593,487.810791
z"
        />
        <path
          fill="#46505D"
          opacity="1.000000"
          stroke="none"
          d="
M942.714111,484.699890
	C943.321045,477.903870 946.439331,475.954041 952.060181,479.226227
	C948.601135,479.809265 944.957153,479.529114 945.224121,484.368408
	C945.482483,489.051819 949.098267,487.728058 951.914429,488.249573
	C947.615906,491.865814 944.451660,490.628265 942.714111,484.699890
z"
        />
        <path
          fill="#46505D"
          opacity="1.000000"
          stroke="none"
          d="
M662.407593,505.063354
	C661.958069,505.403870 662.060486,505.308868 662.043213,505.329163
	C664.990173,505.192535 665.935059,502.820984 667.046021,500.087006
	C668.816467,501.803436 669.251343,504.495911 672.783020,503.838043
	C670.918152,506.302917 667.767334,506.348358 668.437195,509.756744
	C668.932068,512.274963 668.859192,514.454346 672.785278,514.601929
	C667.576538,517.015320 666.033203,516.166626 665.959229,511.502014
	C665.914917,508.713196 666.428894,505.829773 662.407593,505.063354
z"
        />
        <path
          fill="#46505D"
          opacity="1.000000"
          stroke="none"
          d="
M1758.776245,452.324097
	C1760.116821,452.592407 1761.885742,452.527069 1762.138306,451.804535
	C1764.358398,445.453064 1766.213501,452.353333 1768.285400,451.985474
	C1769.102295,451.840454 1769.119263,453.019684 1768.352173,453.731140
	C1764.258423,457.527802 1764.438965,460.443085 1768.905518,463.184967
	C1764.244019,464.882690 1763.238770,464.117920 1762.855103,459.275604
	C1762.685547,457.135986 1764.720093,453.445709 1759.415527,453.725403
	C1758.631714,453.435028 1758.506592,453.038239 1758.776245,452.324097
z"
        />
        <path
          fill="#46505D"
          opacity="1.000000"
          stroke="none"
          d="
M1845.039795,460.886841
	C1844.375244,452.622040 1847.222656,449.979095 1854.144043,453.022095
	C1850.305908,453.843201 1846.257446,453.985779 1847.304932,459.150024
	C1848.083740,462.989441 1851.157959,461.860718 1853.761597,462.058044
	C1850.811646,465.366913 1848.693359,465.095398 1845.039795,460.886841
z"
        />
        <path
          fill="#2F3A48"
          opacity="1.000000"
          stroke="none"
          d="
M1060.042236,507.279785
	C1059.621460,511.709106 1061.122681,515.370850 1061.921997,519.291809
	C1058.588013,517.130615 1057.717407,513.675720 1057.571899,510.062073
	C1057.390625,505.558350 1057.862061,501.144379 1062.221558,498.192596
	C1061.499634,501.083527 1060.777710,503.974487 1060.042236,507.279785
z"
        />
        <path
          fill="#46505D"
          opacity="1.000000"
          stroke="none"
          d="
M995.614746,432.007996
	C995.112061,435.103027 996.586548,436.259033 998.951111,437.166534
	C996.347046,438.276215 993.986633,439.064117 992.966187,435.157684
	C992.280029,432.531006 994.459839,429.192047 990.997009,427.254272
	C992.100830,425.694977 993.521484,424.365814 994.268921,421.260834
	C995.475037,424.453156 996.678894,426.183929 1000.775146,426.380829
	C997.211548,427.911163 994.709595,428.558350 995.614746,432.007996
z"
        />
        <path
          fill="#46505D"
          opacity="1.000000"
          stroke="none"
          d="
M1387.890625,448.741760
	C1388.831299,450.115540 1388.693237,451.842987 1390.902222,451.834869
	C1392.076904,451.830536 1392.401978,452.800079 1391.264526,453.768982
	C1387.274902,457.167786 1387.515991,460.480682 1391.906006,463.189301
	C1387.623413,464.754913 1386.453857,464.108459 1385.876587,460.337006
	C1385.519653,458.006256 1387.461304,454.995758 1384.068237,453.453857
	C1385.242432,451.823425 1386.416748,450.193024 1387.890625,448.741760
z"
        />
        <path
          fill="#46505D"
          opacity="1.000000"
          stroke="none"
          d="
M1935.025879,450.879852
	C1935.353149,449.444733 1935.752808,447.671143 1937.073853,449.045410
	C1938.114868,450.128174 1938.334229,451.799683 1940.375000,452.008026
	C1941.182129,452.090454 1941.111938,453.078888 1940.260010,453.787628
	C1936.262817,457.112854 1936.521362,460.452667 1940.907104,463.209991
	C1936.404297,464.806213 1935.303345,464.102142 1934.875244,459.812286
	C1934.656616,457.622345 1936.054810,455.163574 1933.156982,453.264679
	C1931.843018,452.403687 1934.727905,452.301056 1935.025879,450.879852
z"
        />
        <path
          fill="#46505D"
          opacity="1.000000"
          stroke="none"
          d="
M556.122559,476.212769
	C556.610779,474.901459 557.968140,473.924561 558.102173,475.106232
	C558.403564,477.761566 561.045471,477.265259 562.503601,479.160400
	C558.997253,479.672821 558.461121,481.670288 558.484436,484.596100
	C558.505981,487.307648 559.917175,488.130554 562.070862,489.064392
	C559.729126,490.374176 557.443665,491.084564 556.213684,488.061279
	C555.017517,485.120697 557.272339,481.329865 554.008850,478.664734
	C553.601868,478.332306 556.309204,478.219330 556.122559,476.212769
z"
        />
        <path
          fill="#46505D"
          opacity="1.000000"
          stroke="none"
          d="
M1020.144836,477.241638
	C1021.270020,478.061035 1024.376587,477.379456 1022.208252,479.656830
	C1018.484680,483.567566 1018.460632,486.556671 1022.902039,489.123444
	C1018.545410,490.874420 1017.365479,490.139587 1016.860596,485.919830
	C1016.601074,483.750427 1018.467224,480.881714 1015.136841,479.512024
	C1016.096252,477.915222 1016.708008,475.792908 1018.110107,474.865265
	C1019.596375,473.881989 1019.397644,476.097046 1020.144836,477.241638
z"
        />
        <path
          fill="#46505D"
          opacity="1.000000"
          stroke="none"
          d="
M1327.676880,488.060974
	C1330.124756,490.178619 1327.966919,490.032166 1326.901489,490.153198
	C1324.806641,490.391327 1323.520752,489.472351 1322.936523,487.294556
	C1322.231567,484.666901 1324.708496,481.306763 1321.056274,479.302612
	C1320.575562,479.038910 1321.105713,478.041870 1321.900024,477.967957
	C1323.963013,477.776031 1322.464600,473.930176 1324.640625,474.822876
	C1326.047119,475.399841 1326.359375,478.034943 1328.730103,478.286133
	C1329.210938,478.337067 1328.686157,479.437469 1327.806519,479.950867
	C1323.791504,482.294403 1325.032349,485.163818 1327.676880,488.060974
z"
        />
        <path
          fill="#46505D"
          opacity="1.000000"
          stroke="none"
          d="
M605.921814,511.017029
	C605.522095,508.886230 606.749268,506.986115 604.444580,505.378998
	C603.650940,504.825623 606.846741,502.632965 606.882568,500.619507
	C608.781555,500.650238 608.268921,502.157379 608.874939,502.722717
	C610.260071,504.014954 614.625488,504.553040 609.102051,506.693024
	C606.981567,507.514587 609.024109,513.465271 611.901611,515.115845
	C607.438843,516.933411 606.644714,516.445557 605.921814,511.017029
z"
        />
        <path
          fill="#46505D"
          opacity="1.000000"
          stroke="none"
          d="
M709.300964,505.651611
	C708.723450,504.295868 710.086792,504.220154 710.221558,503.819214
	C712.677002,496.510834 714.521240,505.702881 716.692017,504.022003
	C716.914856,504.637604 717.033020,505.132568 716.418884,505.721527
	C712.272949,509.697235 712.448547,512.679565 716.897461,515.028687
	C712.713989,516.934021 711.418579,516.156433 710.845276,512.008057
	C710.563538,509.969696 712.414612,507.392029 709.300964,505.651611
z"
        />
        <path
          fill="#46505D"
          opacity="1.000000"
          stroke="none"
          d="
M540.405273,437.912018
	C538.678162,433.059448 539.684448,428.247009 539.417175,423.484528
	C539.362732,422.515656 539.383484,421.305725 540.707764,421.201141
	C542.189331,421.084167 542.002136,422.365967 542.058533,423.297424
	C542.118652,424.289032 542.073181,425.286865 542.079102,426.281952
	C542.095947,429.100800 541.355774,432.221466 542.325439,434.662476
	C543.549927,437.744751 547.520630,434.662689 550.578613,437.287231
	C547.027283,438.579437 543.865173,437.897736 540.405273,437.912018
z"
        />
        <path
          fill="#2F3A48"
          opacity="1.000000"
          stroke="none"
          d="
M1101.916260,513.725159
	C1100.961304,516.126892 1100.681274,518.571899 1096.953369,520.193298
	C1100.680908,512.351074 1101.000000,505.382172 1097.326904,498.217712
	C1101.818481,499.981659 1103.150391,504.819244 1101.916260,513.725159
z"
        />
        <path
          fill="#46505D"
          opacity="1.000000"
          stroke="none"
          d="
M515.165833,422.701508
	C519.314148,420.221588 521.111694,420.955505 520.661499,425.777710
	C520.401184,428.566010 520.704712,431.402740 520.573730,434.209229
	C520.512024,435.532288 521.392456,437.777649 519.302551,437.873779
	C517.013672,437.979126 518.033508,435.677063 517.974854,434.447968
	C517.818420,431.171387 517.921631,427.882446 517.921631,424.792206
	C516.415833,423.532684 514.711060,426.535004 513.661926,424.183502
	C513.562988,423.961731 514.439697,423.304688 515.165833,422.701508
z"
        />
        <path
          fill="#46505D"
          opacity="1.000000"
          stroke="none"
          d="
M1487.066162,478.047638
	C1489.412476,477.531677 1491.491577,476.748138 1493.598511,479.723877
	C1489.852295,479.583344 1486.705200,479.382812 1486.822510,484.131653
	C1486.942871,489.007355 1490.352539,487.881622 1493.192871,488.669922
	C1491.000977,490.725739 1488.571777,490.849731 1486.210938,489.107117
	C1483.110474,486.818420 1483.406860,482.259155 1487.066162,478.047638
z"
        />
        <path
          fill="#46505D"
          opacity="1.000000"
          stroke="none"
          d="
M647.310059,425.517761
	C648.880066,425.682587 650.384216,425.283447 651.123840,427.448486
	C647.905640,427.677429 644.483887,427.482422 644.817261,432.435547
	C645.131714,437.108002 648.601440,435.852875 652.028748,436.078766
	C649.503052,438.983185 647.183777,438.572510 645.212708,437.741364
	C642.629333,436.652039 641.935303,434.186981 642.083130,431.518433
	C642.257324,428.373840 643.564331,426.130463 647.310059,425.517761
z"
        />
        <path
          fill="#46505D"
          opacity="1.000000"
          stroke="none"
          d="
M1111.055664,477.440369
	C1112.294922,477.804962 1113.651123,477.518311 1113.851685,478.743500
	C1114.064209,480.041260 1112.961304,479.761841 1112.186890,479.701752
	C1108.917969,479.448120 1107.862793,481.066528 1107.870361,484.189117
	C1107.879028,487.761322 1109.838013,488.318878 1112.709229,488.045868
	C1113.150146,488.003937 1113.613403,488.196899 1114.426636,488.348236
	C1112.784058,491.197052 1110.307373,490.528259 1108.428345,489.834747
	C1105.582031,488.784210 1104.895874,486.114532 1105.088257,483.263397
	C1105.323486,479.779114 1107.195679,477.830139 1111.055664,477.440369
z"
        />
        <path
          fill="#46505D"
          opacity="1.000000"
          stroke="none"
          d="
M759.046509,462.004211
	C760.913513,463.342987 759.965515,463.907379 758.618408,464.121857
	C756.050293,464.530701 753.518982,464.417328 752.066162,461.827698
	C750.476196,458.993652 750.446228,455.959808 752.533325,453.434052
	C754.561340,450.979889 757.236450,451.002777 761.371277,452.969025
	C756.654541,453.700073 752.898193,454.029327 753.882751,459.167572
	C754.474854,462.257874 756.462463,462.130585 759.046509,462.004211
z"
        />
        <path
          fill="#46505D"
          opacity="1.000000"
          stroke="none"
          d="
M1797.142090,430.424561
	C1798.768066,425.469879 1801.786255,424.672760 1806.104248,426.786011
	C1802.661987,427.704071 1798.972046,428.010406 1799.870239,433.161041
	C1800.600220,437.346283 1803.929443,435.438202 1806.325439,436.384918
	C1805.049683,439.083923 1802.744873,438.502380 1800.936035,437.984650
	C1797.405273,436.974060 1796.943359,433.979156 1797.142090,430.424561
z"
        />
        <path
          fill="#2F3A48"
          opacity="1.000000"
          stroke="none"
          d="
M1297.020386,488.749878
	C1296.977539,484.629700 1296.839478,480.981964 1297.034424,477.352112
	C1297.108398,475.975677 1295.838867,473.325134 1298.295166,473.429047
	C1300.429077,473.519287 1299.356934,476.130737 1299.448608,477.611053
	C1299.632202,480.578766 1298.897095,483.756317 1299.719849,486.492828
	C1300.749756,489.918365 1305.075317,486.542847 1307.761963,489.361877
	C1303.734253,490.159271 1300.421021,490.732666 1297.020386,488.749878
z"
        />
        <path
          fill="#2F3A48"
          opacity="1.000000"
          stroke="none"
          d="
M1113.136475,438.019775
	C1111.738892,438.024597 1111.025635,437.728149 1111.019409,436.666870
	C1110.993286,432.197540 1110.969727,427.727875 1111.003418,423.258789
	C1111.009033,422.527740 1110.750366,421.452301 1111.923706,421.304871
	C1113.351074,421.125488 1113.458984,422.230286 1113.473145,423.232544
	C1113.510376,425.880859 1113.738403,428.557983 1113.439331,431.172058
	C1112.996094,435.047821 1114.351929,436.644897 1118.281616,436.010254
	C1119.191284,435.863342 1120.273438,435.706970 1121.270874,436.899353
	C1118.922485,438.976227 1116.095337,437.607391 1113.136475,438.019775
z"
        />
        <path
          fill="#2F3A48"
          opacity="1.000000"
          stroke="none"
          d="
M536.394043,452.014954
	C537.125488,452.711243 537.590637,452.961182 538.332825,452.553619
	C539.468384,451.929993 541.126160,450.984009 541.629395,452.706451
	C542.152039,454.495056 540.093506,453.801910 539.160156,454.081879
	C536.653931,454.833649 537.308533,456.864594 537.228821,458.590668
	C537.160156,460.078583 537.229492,461.574829 537.108032,463.056763
	C537.081360,463.382080 536.532593,463.863159 536.176453,463.910187
	C535.776733,463.963013 534.932922,463.552338 534.949036,463.433014
	C535.464844,459.599426 533.127258,455.469788 536.394043,452.014954
z"
        />
        <path
          fill="#2F3A48"
          opacity="1.000000"
          stroke="none"
          d="
M1101.244873,459.675110
	C1100.798218,461.368469 1102.181274,463.746857 1100.184937,463.805695
	C1097.598145,463.881958 1099.114990,461.341675 1098.828003,459.981689
	C1098.595459,458.879028 1098.778931,457.688141 1098.781372,456.536407
	C1098.788818,453.005157 1101.604614,450.852661 1104.868530,451.934967
	C1105.232422,452.055603 1105.680542,452.548981 1105.700928,452.892578
	C1105.719482,453.205231 1105.204834,453.839569 1104.932251,453.835754
	C1100.923584,453.779602 1101.049316,456.473846 1101.244873,459.675110
z"
        />
        <path
          fill="#2F3A48"
          opacity="1.000000"
          stroke="none"
          d="
M1951.781006,482.465729
	C1951.882568,479.211517 1955.073853,477.015076 1957.870239,477.933868
	C1958.236450,478.054199 1958.693237,478.540710 1958.713867,478.881500
	C1958.733032,479.196564 1958.229370,479.828003 1957.944824,479.834442
	C1952.615234,479.955872 1954.665405,483.996399 1954.193481,486.730072
	C1954.000244,487.849060 1955.086548,489.818207 1953.041016,489.871765
	C1950.911133,489.927551 1951.973389,487.919006 1951.822754,486.816742
	C1951.645386,485.520966 1951.780884,484.182343 1951.781006,482.465729
z"
        />
        <path
          fill="#4F5965"
          opacity="1.000000"
          stroke="none"
          d="
M1496.650757,436.949341
	C1496.292969,431.961273 1496.147461,427.327454 1496.072144,422.692444
	C1496.054321,421.588959 1495.884155,420.111908 1497.650391,420.226807
	C1498.983032,420.313507 1498.823730,421.544891 1498.951782,422.511353
	C1499.585571,427.294403 1497.887695,432.165070 1500.100708,437.018036
	C1500.741699,438.423523 1498.145264,438.097504 1496.650757,436.949341
z"
        />
        <path
          fill="#4F5965"
          opacity="1.000000"
          stroke="none"
          d="
M932.953247,481.828979
	C933.062988,484.076752 933.009521,485.901764 933.359375,487.645874
	C933.508240,488.388000 935.234680,489.036011 933.775818,489.848633
	C932.543457,490.535156 930.818481,490.187958 930.521179,488.636597
	C929.549683,483.567139 930.006653,478.419922 930.187622,473.300476
	C930.213013,472.582123 930.820190,472.124634 931.557007,472.179626
	C932.576904,472.255798 932.895081,473.035889 932.909180,473.913177
	C932.949280,476.399536 932.941895,478.886658 932.953247,481.828979
z"
        />
        <path
          fill="#4F5965"
          opacity="1.000000"
          stroke="none"
          d="
M745.053955,434.471497
	C745.053833,430.048370 745.045105,426.066071 745.075867,422.084106
	C745.082275,421.251587 745.224609,420.347412 746.279663,420.225800
	C747.657349,420.067047 747.795288,421.082550 747.946167,422.117920
	C748.665955,427.055511 746.487488,432.241638 749.294312,437.030914
	C749.720642,437.758423 748.634460,438.014313 748.026428,438.054504
	C745.812012,438.201019 745.238098,436.727356 745.053955,434.471497
z"
        />
        <path
          fill="#4F5965"
          opacity="1.000000"
          stroke="none"
          d="
M645.026978,488.315613
	C643.354553,491.550873 641.532593,490.107391 641.290527,487.816315
	C640.803528,483.206970 641.097656,478.513794 641.098999,473.855225
	C641.099243,472.953674 641.496765,472.169830 642.506836,472.175079
	C643.516602,472.180328 643.892517,472.971130 643.903931,473.870026
	C643.942017,476.865723 643.828369,479.868195 643.995972,482.855560
	C644.093140,484.589264 643.197876,486.511749 645.026978,488.315613
z"
        />
        <path
          fill="#2F3A48"
          opacity="1.000000"
          stroke="none"
          d="
M1758.658691,451.967957
	C1758.977173,452.444794 1758.965332,452.820160 1758.961426,453.477112
	C1754.058838,453.828186 1755.597778,457.531586 1755.188599,460.256470
	C1755.001465,461.502686 1756.306274,463.852051 1753.987671,463.830109
	C1751.703369,463.808502 1753.257568,461.456512 1752.788574,460.235748
	C1752.732910,460.090942 1752.788086,459.905945 1752.779785,459.740204
	C1752.574341,455.667084 1751.753906,451.161804 1758.658691,451.967957
z"
        />
        <path
          fill="#2F3A48"
          opacity="1.000000"
          stroke="none"
          d="
M607.188416,427.626160
	C604.829346,430.084045 606.268005,432.859131 605.859802,435.361115
	C605.687988,436.413727 606.213196,437.994171 604.421692,437.906403
	C602.682007,437.821198 603.185791,436.246246 603.169983,435.207275
	C603.112244,431.404144 603.060364,427.596741 603.192017,423.797363
	C603.234985,422.559509 602.248962,420.356598 604.574768,420.373108
	C606.469910,420.386505 605.644226,422.385712 605.849243,423.566010
	C605.989136,424.370880 606.075989,425.184967 606.564575,426.180725
	C607.285339,426.632141 607.441284,426.981171 607.188416,427.626160
z"
        />
        <path
          fill="#2F3A48"
          opacity="1.000000"
          stroke="none"
          d="
M1368.687256,477.970764
	C1368.997803,478.451050 1368.977661,478.824493 1368.963501,479.478333
	C1364.189697,479.897858 1365.453979,483.454071 1365.157715,486.266235
	C1365.026978,487.507996 1366.313843,489.858002 1363.976685,489.828430
	C1361.711426,489.799835 1363.253052,487.447174 1362.788452,486.220978
	C1362.733276,486.075562 1362.777710,485.891174 1362.781372,485.724915
	C1362.865112,481.846100 1361.327515,476.816742 1368.687256,477.970764
z"
        />
        <path
          fill="#2F3A48"
          opacity="1.000000"
          stroke="none"
          d="
M1947.188354,453.626251
	C1944.698486,456.238831 1946.403931,459.191742 1945.872925,461.839081
	C1945.680176,462.799805 1945.852295,463.975281 1944.448242,463.933929
	C1943.037476,463.892487 1943.164795,462.696167 1943.159790,461.739685
	C1943.138306,457.615173 1943.092407,453.489227 1943.183716,449.366699
	C1943.208618,448.236267 1942.473145,446.333221 1944.583496,446.358521
	C1946.254028,446.378510 1945.713867,448.023254 1945.849976,449.079468
	C1945.975098,450.050446 1946.076050,451.024597 1946.565186,452.181915
	C1947.280273,452.634460 1947.444946,452.979614 1947.188354,453.626251
z"
        />
        <path
          fill="#2F3A48"
          opacity="1.000000"
          stroke="none"
          d="
M568.188416,479.626160
	C565.726685,482.242523 567.332703,485.183258 566.868652,487.837799
	C566.699280,488.806763 566.851746,489.974731 565.448608,489.934113
	C564.037964,489.893280 564.164978,488.697021 564.159912,487.740509
	C564.138184,483.616638 564.091431,479.491364 564.182983,475.369476
	C564.208130,474.239319 563.487061,472.256866 565.576111,472.375824
	C567.500732,472.485443 566.638428,474.399567 566.850098,475.571167
	C566.994934,476.372986 567.079102,477.185760 567.566528,478.180084
	C568.285339,478.632141 568.441284,478.981171 568.188416,479.626160
z"
        />
        <path
          fill="#2F3A48"
          opacity="1.000000"
          stroke="none"
          d="
M1860.188354,453.626190
	C1857.726807,456.242920 1859.332520,459.183868 1858.868652,461.838776
	C1858.699341,462.808075 1858.851440,463.975830 1857.447388,463.933807
	C1856.036865,463.891602 1856.164917,462.695129 1856.160034,461.738861
	C1856.138550,457.614502 1856.092407,453.488708 1856.183716,449.366333
	C1856.208740,448.235962 1855.473389,446.332977 1857.583740,446.358490
	C1859.254028,446.378723 1858.713745,448.023407 1858.849976,449.079559
	C1858.975220,450.050537 1859.076050,451.024628 1859.565186,452.181915
	C1860.288940,452.630585 1860.436035,452.983612 1860.188354,453.626190
z"
        />
        <path
          fill="#2F3A48"
          opacity="1.000000"
          stroke="none"
          d="
M1413.188354,427.626190
	C1410.829224,430.084900 1412.268188,432.860626 1411.859619,435.363281
	C1411.687866,436.415863 1412.213379,437.997681 1410.419312,437.906586
	C1408.680420,437.818268 1409.185913,436.243378 1409.170044,435.204254
	C1409.112183,431.400116 1409.061035,427.591705 1409.191772,423.791260
	C1409.234253,422.552246 1408.253052,420.348785 1410.583862,420.370514
	C1412.487183,420.388306 1411.642212,422.387146 1411.850220,423.564514
	C1411.992188,424.369110 1412.076294,425.183960 1412.564453,426.180359
	C1413.288940,426.630585 1413.436035,426.983612 1413.188354,427.626190
z"
        />
        <path
          fill="#4F5965"
          opacity="1.000000"
          stroke="none"
          d="
M1123.260010,422.039185
	C1124.494873,420.872040 1125.907959,420.520203 1125.997437,422.552185
	C1126.201538,427.183899 1126.074463,431.830383 1126.070923,436.470886
	C1126.070312,437.383270 1125.783936,438.155609 1124.678223,437.998779
	C1123.348511,437.810150 1123.373291,436.701447 1123.363892,435.719482
	C1123.321289,431.244904 1123.307251,426.770020 1123.260010,422.039185
z"
        />
        <path
          fill="#46505D"
          opacity="1.000000"
          stroke="none"
          d="
M1958.687012,427.435425
	C1959.109375,427.280090 1959.078491,427.180634 1959.086060,427.156738
	C1954.305542,427.128387 1952.203003,429.349274 1952.851318,434.246246
	C1953.012207,435.462158 1953.804443,437.836060 1951.616943,437.859192
	C1948.958862,437.887299 1950.398193,435.373596 1950.111328,433.979309
	C1949.914551,433.022614 1949.931519,431.967255 1950.104736,431.001099
	C1950.407471,429.312195 1949.324463,426.037109 1950.903076,426.234009
	C1953.280884,426.530640 1956.493652,424.090332 1958.687012,427.435425
z"
        />
        <path
          fill="#46505D"
          opacity="1.000000"
          stroke="none"
          d="
M557.687134,505.435364
	C558.109436,505.279999 558.078613,505.180511 558.086182,505.156616
	C553.305908,505.128662 551.202515,507.348846 551.851257,512.246277
	C552.012329,513.462036 552.804688,515.835938 550.616943,515.859192
	C547.958313,515.887512 549.397766,513.373962 549.111145,511.979645
	C548.914490,511.022888 548.931519,509.967529 549.104736,509.001404
	C549.407593,507.312469 548.324158,504.037720 549.903015,504.234192
	C552.280762,504.530029 555.493530,502.090881 557.687134,505.435364
z"
        />
        <path
          fill="#46505D"
          opacity="1.000000"
          stroke="none"
          d="
M719.077393,505.850372
	C720.635010,502.428284 723.587402,505.167114 725.648132,503.944092
	C725.877686,503.807861 726.562805,505.732819 725.689270,505.763916
	C720.388184,505.952850 722.311890,509.862640 721.904968,512.616333
	C721.718811,513.876282 722.769409,516.083801 720.277588,515.907227
	C718.329773,515.769348 719.181091,513.847778 719.110840,512.694336
	C718.980408,510.554413 719.073914,508.400848 719.077393,505.850372
z"
        />
        <path
          fill="#4F5965"
          opacity="1.000000"
          stroke="none"
          d="
M1081.646973,513.560547
	C1081.572266,515.046204 1081.410522,516.038025 1080.056274,515.986023
	C1078.904175,515.941833 1078.925293,515.008118 1078.921265,514.216125
	C1078.899536,509.940857 1078.889160,505.665253 1078.925171,501.390198
	C1078.932861,500.481293 1078.724976,499.238129 1080.151611,499.184814
	C1081.517822,499.133759 1081.618042,500.273712 1081.626709,501.263885
	C1081.661499,505.210114 1081.649292,509.156769 1081.646973,513.560547
z"
        />
        <path
          fill="#4F5965"
          opacity="1.000000"
          stroke="none"
          d="
M1309.650635,489.327148
	C1309.415161,484.481476 1309.364136,480.042572 1309.368164,475.603729
	C1309.369263,474.488617 1309.274902,473.060150 1310.997314,473.219391
	C1312.398438,473.348938 1312.063110,474.654633 1312.072998,475.544067
	C1312.118408,479.654907 1312.085571,483.766510 1312.097778,487.877808
	C1312.102295,489.431763 1311.801636,490.562195 1309.650635,489.327148
z"
        />
        <path
          fill="#4F5965"
          opacity="1.000000"
          stroke="none"
          d="
M1455.902100,429.043213
	C1456.388794,426.120972 1454.933594,421.421143 1456.971924,421.606018
	C1460.579102,421.933105 1458.133423,426.173004 1458.564087,428.670776
	C1458.982178,431.094666 1458.657593,433.650482 1458.618408,436.147705
	C1458.601318,437.240356 1458.236938,438.146240 1456.890259,437.958588
	C1455.708008,437.793854 1455.939941,436.774628 1455.923218,436.015320
	C1455.875610,433.851501 1455.903687,431.686005 1455.902100,429.043213
z"
        />
        <path
          fill="#46505D"
          opacity="1.000000"
          stroke="none"
          d="
M1827.073486,484.975983
	C1827.073486,483.704193 1827.067139,482.879456 1827.074707,482.054840
	C1827.101807,479.083649 1830.663940,476.657166 1833.383423,477.805695
	C1833.745605,477.958588 1834.144409,478.496582 1834.157593,478.869324
	C1834.170654,479.233765 1833.696655,479.950073 1833.462769,479.939240
	C1829.120972,479.737946 1830.139282,482.870453 1829.868896,485.381958
	C1829.699829,486.952667 1831.076538,489.798889 1828.512329,489.815399
	C1825.872314,489.832428 1827.588745,486.940125 1827.073486,484.975983
z"
        />
        <path
          fill="#46505D"
          opacity="1.000000"
          stroke="none"
          d="
M1074.750732,427.529816
	C1075.071533,427.713684 1075.088745,427.650970 1075.101074,427.620728
	C1069.752441,427.290649 1069.564941,430.712616 1069.872437,434.676392
	C1069.959717,435.802185 1070.638794,437.755676 1068.695557,437.885712
	C1066.192749,438.053131 1067.243164,435.835907 1067.115356,434.590637
	C1066.947388,432.954987 1066.979858,431.282013 1067.104370,429.638580
	C1067.197876,428.404877 1067.177612,425.852661 1067.665649,426.157166
	C1069.873413,427.535034 1072.858154,423.795471 1074.750732,427.529816
z"
        />
        <path
          fill="#46505D"
          opacity="1.000000"
          stroke="none"
          d="
M630.917542,506.445007
	C629.247009,508.966125 630.206177,511.494324 629.932678,513.911682
	C629.818420,514.921875 629.838440,516.016357 628.462891,515.989502
	C627.075867,515.962524 627.094849,514.830750 627.087036,513.840271
	C627.067505,511.362640 627.044128,508.884125 627.091309,506.407288
	C627.108643,505.499115 627.198303,503.590057 627.919922,504.046967
	C630.081238,505.415375 632.731873,502.416565 633.802246,504.281158
	C635.297668,506.886139 632.127747,505.516388 630.917542,506.445007
z"
        />
        <path
          fill="#46505D"
          opacity="1.000000"
          stroke="none"
          d="
M1531.134644,453.314789
	C1527.783447,453.819214 1526.563110,455.405212 1526.817871,458.747803
	C1526.945801,460.426849 1528.071655,463.740875 1525.640381,463.727142
	C1522.517700,463.709503 1524.673584,460.323639 1524.079590,458.523285
	C1523.883057,457.928009 1524.065063,457.208771 1524.069824,456.546143
	C1524.105835,451.521576 1526.115356,450.521057 1531.134644,453.314789
z"
        />
        <path
          fill="#4F5965"
          opacity="1.000000"
          stroke="none"
          d="
M1413.419800,427.822876
	C1412.937988,427.511139 1412.909790,427.183624 1412.912354,426.611267
	C1418.840088,424.468445 1420.152710,425.437988 1420.036743,432.073242
	C1420.002441,434.034180 1421.148560,437.662811 1419.069092,437.669373
	C1415.883057,437.679474 1417.683594,434.101379 1417.413086,432.111786
	C1417.097778,429.794830 1417.668701,427.123230 1413.419800,427.822876
z"
        />
        <path
          fill="#4F5965"
          opacity="1.000000"
          stroke="none"
          d="
M1860.419800,453.822876
	C1859.937988,453.511139 1859.909790,453.183624 1859.912354,452.611267
	C1865.840088,450.468414 1867.153320,451.438293 1867.037231,458.073212
	C1867.002808,460.034149 1868.148438,463.662872 1866.069092,463.669373
	C1862.883057,463.679382 1864.683838,460.101349 1864.413086,458.111755
	C1864.097900,455.794830 1864.668701,453.123260 1860.419800,453.822876
z"
        />
        <path
          fill="#4F5965"
          opacity="1.000000"
          stroke="none"
          d="
M1947.419800,453.823059
	C1946.937988,453.511230 1946.909790,453.183655 1946.912354,452.611298
	C1952.839966,450.468445 1954.152832,451.438080 1954.036865,458.073120
	C1954.002563,460.034119 1955.148193,463.662781 1953.069336,463.669434
	C1949.882935,463.679688 1951.683594,460.101532 1951.412842,458.111969
	C1951.097534,455.795227 1951.668823,453.123383 1947.419800,453.823059
z"
        />
        <path
          fill="#4F5965"
          opacity="1.000000"
          stroke="none"
          d="
M568.419922,479.822876
	C567.938049,479.511139 567.909790,479.183624 567.912170,478.611328
	C573.840027,476.468414 575.152649,477.437897 575.036926,484.073059
	C575.002686,486.034027 576.148132,489.662811 574.069397,489.669373
	C570.883118,489.679504 572.683594,486.101562 572.413025,484.111969
	C572.097900,481.795074 572.668762,479.123413 568.419922,479.822876
z"
        />
        <path
          fill="#4F5965"
          opacity="1.000000"
          stroke="none"
          d="
M607.419922,427.822876
	C606.938049,427.511139 606.909790,427.183624 606.912170,426.611328
	C612.840027,424.468414 614.152588,425.437897 614.036865,432.073059
	C614.002686,434.034027 615.148132,437.662781 613.069397,437.669373
	C609.883118,437.679504 611.683594,434.101562 611.413025,432.111969
	C611.097839,429.795105 611.668762,427.123413 607.419922,427.822876
z"
        />
        <path
          fill="#2F3A48"
          opacity="1.000000"
          stroke="none"
          d="
M1119.214844,478.102600
	C1119.654053,482.160217 1118.018433,486.065460 1120.824707,489.302643
	C1117.198120,489.877472 1116.985352,489.873749 1116.844238,486.945831
	C1116.653442,482.984833 1116.762939,479.007721 1116.806763,475.038208
	C1116.818359,473.991638 1116.428589,472.335815 1118.056519,472.390778
	C1119.679443,472.445648 1119.068970,474.125610 1119.192993,475.159241
	C1119.290894,475.974670 1119.214111,476.811096 1119.214844,478.102600
z"
        />
        <path
          fill="#2F3A48"
          opacity="1.000000"
          stroke="none"
          d="
M1799.851074,463.980164
	C1796.005493,458.626648 1798.481201,452.781586 1797.985840,447.200195
	C1797.961914,446.931122 1798.577515,446.373627 1798.927368,446.345306
	C1800.109863,446.249695 1800.153442,447.264313 1800.172241,448.036346
	C1800.236694,450.688141 1800.087891,453.349579 1800.251221,455.993103
	C1800.394531,458.312500 1799.350342,460.823120 1801.708618,462.874023
	C1802.477661,463.542847 1801.051758,463.930298 1799.851074,463.980164
z"
        />
        <path
          fill="#2F3A48"
          opacity="1.000000"
          stroke="none"
          d="
M1811.214844,427.969116
	C1811.399048,431.551270 1810.332275,434.879822 1813.155518,437.538757
	C1810.289307,438.544708 1808.874512,437.697021 1808.820923,435.064178
	C1808.736938,430.929199 1808.769775,426.791046 1808.811768,422.654816
	C1808.820923,421.749573 1808.581177,420.419434 1809.950928,420.354095
	C1811.398560,420.285034 1811.152954,421.637695 1811.191406,422.542603
	C1811.261597,424.194305 1811.213623,425.851105 1811.214844,427.969116
z"
        />
        <path
          fill="#2F3A48"
          opacity="1.000000"
          stroke="none"
          d="
M1034.112427,499.474304
	C1034.825684,505.143036 1032.792480,510.629242 1035.860229,515.630493
	C1033.357056,516.551453 1031.885864,515.766479 1031.827026,513.111633
	C1031.731689,508.811737 1031.746460,504.507050 1031.834961,500.206848
	C1031.862671,498.855042 1032.213623,497.393616 1034.112427,499.474304
z"
        />
        <path
          fill="#2F3A48"
          opacity="1.000000"
          stroke="none"
          d="
M1866.498779,489.766479
	C1864.123047,490.228333 1862.863159,489.469452 1862.837158,487.334198
	C1862.784668,483.022644 1862.783447,478.709991 1862.815918,474.398224
	C1862.822876,473.470001 1862.743164,472.183350 1864.205933,472.349945
	C1865.207520,472.464020 1865.180176,473.529419 1865.188965,474.323700
	C1865.222290,477.308990 1865.379761,480.307220 1865.161133,483.276367
	C1864.986450,485.649750 1865.652832,487.640778 1866.498779,489.766479
z"
        />
        <path
          fill="#2F3A48"
          opacity="1.000000"
          stroke="none"
          d="
M1495.785156,480.995056
	C1495.791748,478.365814 1495.754883,476.215729 1495.828369,474.069458
	C1495.854370,473.310760 1495.844238,472.278503 1497.033447,472.322601
	C1498.179810,472.365112 1498.165161,473.377472 1498.175415,474.137878
	C1498.229492,478.103882 1498.844727,482.218384 1497.963501,485.976135
	C1497.512329,487.900330 1502.261353,488.925751 1498.664917,489.787598
	C1495.045166,490.655090 1496.006470,487.039581 1495.805054,484.944702
	C1495.694824,483.798340 1495.785278,482.632690 1495.785156,480.995056
z"
        />
        <path
          fill="#46505D"
          opacity="1.000000"
          stroke="none"
          d="
M1872.926025,454.607117
	C1872.923462,457.361053 1872.925781,459.692627 1872.914429,462.024170
	C1872.909302,463.089325 1872.737305,464.079437 1871.330566,463.966156
	C1870.250977,463.879211 1870.098877,463.030518 1870.093384,462.174164
	C1870.075439,459.343262 1870.094360,456.512146 1870.075439,453.681244
	C1870.067749,452.528778 1870.396362,451.671570 1871.743530,451.857330
	C1873.168945,452.053925 1872.859863,453.238739 1872.926025,454.607117
z"
        />
        <path
          fill="#46505D"
          opacity="1.000000"
          stroke="none"
          d="
M687.920288,461.663391
	C687.877686,463.199524 687.636230,464.125000 686.249329,463.946167
	C685.152344,463.804688 685.096863,462.923859 685.091309,462.091583
	C685.072449,459.267120 685.089905,456.442444 685.077759,453.617920
	C685.073914,452.718292 685.247986,451.933990 686.313660,451.839478
	C687.689148,451.717468 687.930786,452.615997 687.924561,453.732758
	C687.910583,456.224976 687.923279,458.717316 687.920288,461.663391
z"
        />
        <path
          fill="#46505D"
          opacity="1.000000"
          stroke="none"
          d="
M728.910034,477.879517
	C730.612488,481.437286 729.653015,485.032227 729.867310,488.562622
	C729.908936,489.248810 729.602417,489.822113 728.809753,489.944366
	C728.107727,490.052673 727.222534,489.838470 727.291382,489.179443
	C727.688416,485.382568 725.303650,481.270233 728.910034,477.879517
z"
        />
        <path
          fill="#2F3A48"
          opacity="1.000000"
          stroke="none"
          d="
M1812.634277,452.228027
	C1813.278076,452.843903 1813.539917,453.198273 1813.893555,453.755371
	C1812.421631,456.369385 1813.678467,459.065674 1813.218384,461.590607
	C1813.056763,462.477356 1813.490723,463.759979 1812.103760,463.875031
	C1810.636108,463.996826 1810.674194,462.686340 1810.830322,461.809906
	C1811.412231,458.542389 1809.239502,454.870667 1812.634277,452.228027
z"
        />
        <path
          fill="#2F3A48"
          opacity="1.000000"
          stroke="none"
          d="
M951.633911,504.227905
	C952.277832,504.843719 952.539795,505.198120 952.893494,505.755310
	C951.465271,508.375824 952.544373,511.057251 952.211121,513.595520
	C952.093323,514.492493 952.485718,515.764648 951.097229,515.879822
	C949.620911,516.002319 949.672241,514.690125 949.828613,513.815918
	C950.413574,510.546692 948.239990,506.872772 951.633911,504.227905
z"
        />
        <path
          fill="#2F3A48"
          opacity="1.000000"
          stroke="none"
          d="
M1859.204102,426.299561
	C1861.003784,427.463989 1862.018433,436.355347 1860.783691,437.531311
	C1860.498169,437.803436 1859.639526,437.984558 1859.535522,437.844147
	C1859.173218,437.354797 1858.836182,436.708252 1858.829468,436.117554
	C1858.793579,432.967560 1858.860840,429.816406 1859.204102,426.299561
z"
        />
        <path
          fill="#2F3A48"
          opacity="1.000000"
          stroke="none"
          d="
M1717.845215,488.731873
	C1717.793701,485.428009 1717.775879,482.601227 1717.791992,479.774628
	C1717.796509,478.978241 1717.718750,478.007782 1718.879395,477.970306
	C1719.268921,477.957703 1720.006836,478.369720 1720.020020,478.617798
	C1720.213379,482.239441 1720.663940,485.901581 1719.788452,489.461060
	C1719.549438,490.433716 1718.459351,489.971405 1717.845215,488.731873
z"
        />
        <path
          fill="#4F5965"
          opacity="1.000000"
          stroke="none"
          d="
M562.385986,456.726318
	C563.915283,457.280029 564.396362,458.191956 563.015930,458.664948
	C561.692993,459.118256 560.133545,458.931030 558.678101,458.929718
	C557.769653,458.928894 556.488464,459.104828 556.629456,457.649017
	C556.725708,456.654694 557.775696,456.693085 558.585754,456.684540
	C559.724243,456.672516 560.863098,456.685760 562.385986,456.726318
z"
        />
        <path
          fill="#2F3A48"
          opacity="1.000000"
          stroke="none"
          d="
M1052.998291,437.920776
	C1050.989258,434.436188 1052.086182,430.785034 1051.865723,427.217255
	C1051.824341,426.547638 1052.112061,425.942322 1053.000854,425.942444
	C1053.891235,425.942535 1054.175659,426.551208 1054.134155,427.219360
	C1053.912109,430.786194 1055.014648,434.437256 1052.998291,437.920776
z"
        />
        <path
          fill="#2F3A48"
          opacity="1.000000"
          stroke="none"
          d="
M724.844727,427.157715
	C726.047852,425.272949 727.002319,425.634949 727.110046,427.248749
	C727.308411,430.220886 727.205200,433.214325 727.190735,436.198700
	C727.186707,437.019379 727.132568,437.944061 725.950623,437.895691
	C724.782227,437.847931 724.818176,436.891205 724.809570,436.098999
	C724.778870,433.280060 724.798523,430.460541 724.844727,427.157715
z"
        />
        <path
          fill="#2F3A48"
          opacity="1.000000"
          stroke="none"
          d="
M522.206665,454.125000
	C522.209595,457.091278 522.225525,459.579559 522.192017,462.067200
	C522.181335,462.859589 522.228088,463.817291 521.080994,463.893707
	C519.914246,463.971405 519.810547,463.071228 519.807861,462.230804
	C519.798218,459.245880 519.809692,456.260712 519.846191,453.276001
	C519.858521,452.270691 520.479675,451.658356 521.410217,452.011810
	C521.821350,452.168030 521.945862,453.078613 522.206665,454.125000
z"
        />
        <path
          fill="#2F3A48"
          opacity="1.000000"
          stroke="none"
          d="
M519.785217,483.967834
	C520.437134,481.690765 518.254028,478.362030 521.210815,478.244324
	C523.057617,478.170807 521.835632,481.586731 522.205627,483.397156
	C522.270691,483.715637 522.304932,484.090942 522.200012,484.385315
	C521.558350,486.185089 523.779297,489.514221 520.767029,489.613159
	C518.933289,489.673401 520.175354,486.253510 519.785217,483.967834
z"
        />
        <path
          fill="#2F3A48"
          opacity="1.000000"
          stroke="none"
          d="
M943.785278,510.927460
	C944.464050,508.322235 942.252319,504.532654 945.265198,504.327423
	C947.154785,504.198730 945.739075,508.289551 946.205139,510.428741
	C946.274109,510.745178 946.276672,511.103912 946.204102,511.418671
	C945.860229,512.910583 947.088806,515.648193 945.238281,515.691284
	C942.595764,515.752869 944.295349,512.902710 943.785278,510.927460
z"
        />
        <path
          fill="#2F3A48"
          opacity="1.000000"
          stroke="none"
          d="
M939.214783,484.923096
	C939.211731,486.235046 939.232483,487.064789 939.199463,487.892395
	C939.160706,488.863068 939.236023,490.066528 937.769043,489.875458
	C936.724060,489.739349 936.809387,488.712311 936.802307,487.915955
	C936.780273,485.429718 936.762085,482.942719 936.804443,480.457031
	C936.820068,479.538666 936.493103,478.169373 937.761780,478.014862
	C939.627747,477.787598 939.117615,479.442413 939.188660,480.463226
	C939.280457,481.783020 939.213440,483.113892 939.214783,484.923096
z"
        />
        <path
          fill="#2F3A48"
          opacity="1.000000"
          stroke="none"
          d="
M1336.786255,455.087402
	C1337.005615,453.606384 1336.247681,451.957733 1338.095947,451.994904
	C1339.479858,452.022736 1339.176270,453.392395 1339.192871,454.301697
	C1339.238525,456.787781 1339.232422,459.275696 1339.193848,461.762024
	C1339.179443,462.690216 1339.393188,463.995636 1337.893311,463.866760
	C1336.488281,463.746033 1336.832397,462.445557 1336.807007,461.541779
	C1336.751099,459.553772 1336.786987,457.563110 1336.786255,455.087402
z"
        />
        <path
          fill="#2F3A48"
          opacity="1.000000"
          stroke="none"
          d="
M1928.998291,463.920776
	C1926.991455,460.436157 1928.068481,456.786224 1927.865356,453.217651
	C1927.827148,452.547455 1928.111450,451.942352 1929.000610,451.942505
	C1929.890869,451.942657 1930.175415,452.550903 1930.134033,453.219208
	C1929.912476,456.786102 1931.014648,460.437195 1928.998291,463.920776
z"
        />
        <path
          fill="#2F3A48"
          opacity="1.000000"
          stroke="none"
          d="
M976.200073,489.562744
	C975.859558,486.055054 975.807922,482.903198 975.813293,479.751465
	C975.814636,478.964661 975.782959,477.995850 976.963989,477.962006
	C978.151978,477.927948 978.352417,478.934082 978.159363,479.668823
	C977.274414,483.037872 980.252197,487.030426 976.200073,489.562744
z"
        />
        <path
          fill="#979DA1"
          opacity="1.000000"
          stroke="none"
          d="
M1023.136414,258.291443
	C1022.604919,255.555969 1023.650024,254.454300 1025.993774,254.909561
	C1027.488403,255.199890 1028.381836,256.422485 1027.695312,257.827057
	C1026.729126,259.803955 1025.086792,260.123627 1023.136414,258.291443
z"
        />
        <path
          fill="#4F5965"
          opacity="1.000000"
          stroke="none"
          d="
M1393.479492,467.264282
	C1393.351562,466.609192 1393.275269,466.233978 1393.409912,465.963867
	C1394.174561,464.429962 1393.161743,462.144073 1395.291382,461.241913
	C1395.902954,460.982849 1396.835083,461.637451 1396.639526,462.229095
	C1395.991699,464.186798 1396.631958,466.949432 1393.479492,467.264282
z"
        />
        <path
          fill="#4F5965"
          opacity="1.000000"
          stroke="none"
          d="
M1871.318359,491.229645
	C1870.994141,492.409027 1870.827148,493.403503 1869.910767,493.535217
	C1868.561523,493.729187 1868.957275,492.563782 1868.959839,491.858337
	C1868.962280,491.202972 1869.010864,490.483643 1869.282837,489.907501
	C1869.775635,488.864014 1869.457764,486.587433 1871.397949,487.362213
	C1872.902710,487.963043 1871.985107,489.651428 1871.318359,491.229645
z"
        />
        <path
          fill="#979DA1"
          opacity="1.000000"
          stroke="none"
          d="
M1289.366943,387.446106
	C1288.962158,385.200806 1289.826294,384.356842 1291.734863,384.848816
	C1292.591064,385.069550 1293.117188,385.880432 1292.853638,386.747528
	C1292.281250,388.631012 1291.093750,388.921417 1289.366943,387.446106
z"
        />
        <path
          fill="#979DA1"
          opacity="1.000000"
          stroke="none"
          d="
M1528.201172,387.944611
	C1526.066895,388.755219 1525.064331,388.062897 1525.182373,386.102325
	C1525.236450,385.206299 1526.038696,384.606934 1526.894775,384.693329
	C1528.844604,384.890076 1529.400391,385.972137 1528.201172,387.944611
z"
        />
        <path
          fill="#979DA1"
          opacity="1.000000"
          stroke="none"
          d="
M1724.361328,385.583130
	C1726.082031,384.188782 1727.308472,384.380981 1727.859131,386.308319
	C1728.104492,387.166718 1727.567261,387.989258 1726.718140,388.209656
	C1724.775024,388.714233 1723.990967,387.776337 1724.361328,385.583130
z"
        />
        <path
          fill="#979DA1"
          opacity="1.000000"
          stroke="none"
          d="
M1525.791016,263.101013
	C1527.901489,262.340546 1528.979370,262.928864 1528.806396,264.955566
	C1528.731567,265.833679 1527.948730,266.467407 1527.083740,266.381226
	C1525.091064,266.182526 1524.639038,265.038910 1525.791016,263.101013
z"
        />
        <path
          fill="#979DA1"
          opacity="1.000000"
          stroke="none"
          d="
M1946.009277,366.653809
	C1948.073608,367.429779 1948.584595,368.534424 1947.096436,369.990997
	C1946.446167,370.627258 1945.533081,370.623627 1944.888184,369.980011
	C1943.412720,368.507172 1943.941772,367.409943 1946.009277,366.653809
z"
        />
        <path
          fill="#979DA1"
          opacity="1.000000"
          stroke="none"
          d="
M1839.631470,369.450745
	C1837.899414,370.924500 1836.713257,370.627350 1836.145752,368.743744
	C1835.884644,367.876678 1836.413574,367.066437 1837.270020,366.847504
	C1839.180908,366.358978 1840.041138,367.206970 1839.631470,369.450745
z"
        />
        <path
          fill="#979DA1"
          opacity="1.000000"
          stroke="none"
          d="
M1525.070068,344.027954
	C1526.674438,344.147278 1528.420166,344.063965 1528.583618,346.064758
	C1528.654419,346.929962 1527.898682,347.694641 1527.011108,347.586426
	C1525.041748,347.346375 1525.136963,345.621246 1525.070068,344.027954
z"
        />
        <path
          fill="#979DA1"
          opacity="1.000000"
          stroke="none"
          d="
M1839.207275,339.100891
	C1840.393188,341.066406 1839.823120,342.153687 1837.895996,342.365540
	C1837.037354,342.459991 1836.257202,341.820953 1836.187988,340.942261
	C1836.029419,338.926056 1837.106812,338.342896 1839.207275,339.100891
z"
        />
        <path
          fill="#979DA1"
          opacity="1.000000"
          stroke="none"
          d="
M1837.373291,280.773071
	C1839.551758,280.807465 1840.453857,281.666748 1839.541992,283.534302
	C1839.166748,284.302795 1838.278564,284.700226 1837.458008,284.285858
	C1835.713867,283.404816 1835.529175,282.217682 1837.373291,280.773071
z"
        />
        <path
          fill="#979DA1"
          opacity="1.000000"
          stroke="none"
          d="
M1945.366211,284.296875
	C1943.604614,282.837891 1943.634766,281.633850 1945.470703,280.767548
	C1946.290283,280.380768 1947.201172,280.741608 1947.562866,281.525665
	C1948.413940,283.370514 1947.558594,284.279236 1945.366211,284.296875
z"
        />
        <path
          fill="#979DA1"
          opacity="1.000000"
          stroke="none"
          d="
M1839.866699,314.839539
	C1838.733765,316.761200 1837.531982,316.983154 1836.345337,315.357819
	C1835.812012,314.627472 1835.978516,313.697876 1836.721436,313.192688
	C1838.367188,312.073792 1839.447510,312.648407 1839.866699,314.839539
z"
        />
        <path
          fill="#979DA1"
          opacity="1.000000"
          stroke="none"
          d="
M1947.160889,303.430115
	C1947.099854,303.848450 1947.109619,304.014038 1947.126343,304.178894
	C1947.142822,304.341675 1947.166504,304.503693 1947.187134,304.666016
	C1945.617676,304.211365 1944.350464,303.396149 1944.480713,301.574219
	C1944.511841,301.139618 1945.429443,300.384338 1945.869751,300.431183
	C1947.564209,300.611542 1948.147949,301.580017 1947.160889,303.430115
z"
        />
        <path
          fill="#979DA1"
          opacity="1.000000"
          stroke="none"
          d="
M1960.130005,264.193970
	C1961.275391,262.284454 1962.493774,262.093048 1963.669189,263.694214
	C1964.202148,264.420258 1963.996094,265.347565 1963.261963,265.859680
	C1961.576782,267.034760 1960.562622,266.328918 1960.130005,264.193970
z"
        />
        <path
          fill="#979DA1"
          opacity="1.000000"
          stroke="none"
          d="
M1963.640137,385.588135
	C1964.003540,387.781128 1963.218994,388.718414 1961.276489,388.207703
	C1960.428345,387.984711 1959.893311,387.161652 1960.141235,386.303558
	C1960.697388,384.378387 1961.924805,384.190765 1963.640137,385.588135
z"
        />
        <path
          fill="#979DA1"
          opacity="1.000000"
          stroke="none"
          d="
M1725.991943,266.408447
	C1724.001099,265.611542 1723.368408,264.528381 1724.896362,263.058380
	C1725.545410,262.433838 1726.473145,262.436371 1727.117065,263.067810
	C1728.629761,264.551117 1727.988892,265.628967 1725.991943,266.408447
z"
        />
        <path
          fill="#979DA1"
          opacity="1.000000"
          stroke="none"
          d="
M1289.796143,265.940918
	C1288.601318,263.965698 1289.161011,262.886566 1291.108887,262.694611
	C1291.963623,262.610382 1292.766602,263.208710 1292.818604,264.104279
	C1292.931885,266.061737 1291.926880,266.750916 1289.796143,265.940918
z"
        />
        <path
          fill="#979DA1"
          opacity="1.000000"
          stroke="none"
          d="
M1528.495605,305.592072
	C1527.097046,307.332397 1525.957031,306.626678 1525.109131,305.520447
	C1524.389771,304.581879 1525.371582,304.040527 1526.053101,303.689392
	C1527.751099,302.814697 1528.429810,303.741150 1528.495605,305.592072
z"
        />
        <path
          fill="#979DA1"
          opacity="1.000000"
          stroke="none"
          d="
M1527.605835,290.513458
	C1528.984131,291.638153 1528.926270,292.564667 1527.737427,293.395020
	C1526.876831,293.996002 1526.085327,293.544617 1525.714844,292.847260
	C1524.840454,291.201508 1525.806030,290.549194 1527.605835,290.513458
z"
        />
        <path
          fill="#979DA1"
          opacity="1.000000"
          stroke="none"
          d="
M1726.005615,344.352203
	C1727.340576,344.988007 1728.274292,345.682709 1727.205322,346.973389
	C1726.447388,347.888489 1725.558594,347.893524 1724.802490,346.972168
	C1723.745483,345.684265 1724.660522,344.983368 1726.005615,344.352203
z"
        />
        <path
          fill="#979DA1"
          opacity="1.000000"
          stroke="none"
          d="
M1961.394287,320.486572
	C1960.016113,319.361969 1960.073730,318.435425 1961.262573,317.604980
	C1962.123047,317.003967 1962.914673,317.455505 1963.285156,318.152802
	C1964.159424,319.798553 1963.193848,320.450806 1961.394287,320.486572
z"
        />
        <path
          fill="#979DA1"
          opacity="1.000000"
          stroke="none"
          d="
M1946.606934,319.514221
	C1947.989624,320.642151 1947.917847,321.570435 1946.738159,322.403961
	C1945.872681,323.015564 1945.087036,322.542114 1944.717651,321.850525
	C1943.838257,320.203400 1944.804199,319.549896 1946.606934,319.514221
z"
        />
        <path
          fill="#979DA1"
          opacity="1.000000"
          stroke="none"
          d="
M1944.899658,328.894409
	C1946.830322,327.894867 1947.545044,328.788239 1947.566528,330.311798
	C1947.572632,330.744507 1946.754517,331.564514 1946.321289,331.559967
	C1944.792969,331.543823 1943.907593,330.824799 1944.899658,328.894409
z"
        />
        <path
          fill="#979DA1"
          opacity="1.000000"
          stroke="none"
          d="
M1289.894531,333.100342
	C1288.894897,331.169647 1289.788330,330.455017 1291.311768,330.433472
	C1291.744385,330.427368 1292.564453,331.245453 1292.559814,331.678772
	C1292.543945,333.207153 1291.824829,334.092316 1289.894531,333.100342
z"
        />
        <path
          fill="#979DA1"
          opacity="1.000000"
          stroke="none"
          d="
M1526.394043,333.486511
	C1525.015625,332.361847 1525.073853,331.435272 1526.262573,330.604919
	C1527.123047,330.003815 1527.914673,330.455505 1528.285034,331.152863
	C1529.159180,332.798523 1528.193848,333.450897 1526.394043,333.486511
z"
        />
        <path
          fill="#979DA1"
          opacity="1.000000"
          stroke="none"
          d="
M1726.005615,330.352203
	C1727.340576,330.988007 1728.274292,331.682709 1727.205322,332.973389
	C1726.447388,333.888489 1725.558594,333.893463 1724.802612,332.972107
	C1723.745483,331.684204 1724.660522,330.983368 1726.005615,330.352203
z"
        />
        <path
          fill="#979DA1"
          opacity="1.000000"
          stroke="none"
          d="
M1960.513672,331.394104
	C1961.638306,330.015564 1962.564697,330.074158 1963.395020,331.262726
	C1963.996216,332.123383 1963.544067,332.914917 1962.847046,333.285156
	C1961.201416,334.159454 1960.549194,333.193604 1960.513672,331.394104
z"
        />
        <path
          fill="#979DA1"
          opacity="1.000000"
          stroke="none"
          d="
M1945.395386,341.489258
	C1944.017822,340.364075 1944.072021,339.436768 1945.262451,338.605225
	C1946.123657,338.003571 1946.916138,338.456238 1947.285889,339.153961
	C1948.156250,340.796112 1947.200806,341.456635 1945.395386,341.489258
z"
        />
        <path
          fill="#979DA1"
          opacity="1.000000"
          stroke="none"
          d="
M1963.647949,278.005676
	C1963.011963,279.340424 1962.317383,280.274139 1961.026611,279.205292
	C1960.111328,278.447296 1960.106812,277.558594 1961.027954,276.802490
	C1962.316040,275.745087 1963.016602,276.661255 1963.647949,278.005676
z"
        />
        <path
          fill="#979DA1"
          opacity="1.000000"
          stroke="none"
          d="
M1724.894531,279.100342
	C1723.894897,277.169647 1724.788330,276.455048 1726.311768,276.433472
	C1726.744385,276.427307 1727.564087,277.245361 1727.559692,277.678711
	C1727.543579,279.207123 1726.824829,280.092316 1724.894531,279.100342
z"
        />
        <path
          fill="#979DA1"
          opacity="1.000000"
          stroke="none"
          d="
M1291.588013,347.497070
	C1289.792114,347.444489 1288.821655,346.792084 1289.712280,345.147797
	C1290.089478,344.451294 1290.883911,344.005310 1291.739746,344.610748
	C1292.926392,345.449951 1292.962036,346.380951 1291.588013,347.497070
z"
        />
        <path
          fill="#2F3A48"
          opacity="1.000000"
          stroke="none"
          d="
M771.092651,477.756653
	C771.181335,475.944061 770.396240,474.380676 771.773682,473.192322
	C773.758911,476.630707 773.591248,477.947968 771.092651,477.756653
z"
        />
        <path
          fill="#979DA1"
          opacity="1.000000"
          stroke="none"
          d="
M1960.899780,344.894348
	C1962.830444,343.894806 1963.545166,344.788177 1963.566650,346.311798
	C1963.572754,346.744568 1962.754761,347.564545 1962.321289,347.559998
	C1960.792603,347.543945 1959.907715,346.824677 1960.899780,344.894348
z"
        />
        <path
          fill="#979DA1"
          opacity="1.000000"
          stroke="none"
          d="
M1525.899780,276.894531
	C1527.830688,275.894714 1528.544678,276.788940 1528.566528,278.311951
	C1528.572632,278.744476 1527.754395,279.564545 1527.321167,279.559937
	C1525.792969,279.543732 1524.907227,278.824982 1525.899780,276.894531
z"
        />
        <path
          fill="#979DA1"
          opacity="1.000000"
          stroke="none"
          d="
M1292.486328,278.605896
	C1291.361694,279.984436 1290.435181,279.925903 1289.604980,278.737274
	C1289.003784,277.876648 1289.455933,277.085205 1290.152954,276.714874
	C1291.798584,275.840607 1292.450806,276.806396 1292.486328,278.605896
z"
        />
        <path
          fill="#979DA1"
          opacity="1.000000"
          stroke="none"
          d="
M1839.105469,348.899658
	C1840.105103,350.830353 1839.211670,351.544952 1837.688232,351.566528
	C1837.255493,351.572662 1836.435425,350.754639 1836.440063,350.321320
	C1836.456177,348.792847 1837.175171,347.907684 1839.105469,348.899658
z"
        />
        <path
          fill="#979DA1"
          opacity="1.000000"
          stroke="none"
          d="
M1836.502930,359.587830
	C1836.555786,357.792267 1837.208130,356.821472 1838.852295,357.711945
	C1839.548828,358.089233 1839.994263,358.883575 1839.388794,359.739349
	C1838.549805,360.925415 1837.619019,360.962341 1836.502930,359.587830
z"
        />
        <path
          fill="#979DA1"
          opacity="1.000000"
          stroke="none"
          d="
M1944.899780,357.894531
	C1946.830688,356.894714 1947.544678,357.788940 1947.566528,359.311951
	C1947.572754,359.744537 1946.754639,360.564514 1946.321167,360.559967
	C1944.792725,360.543915 1943.907715,359.824738 1944.899780,357.894531
z"
        />
        <path
          fill="#979DA1"
          opacity="1.000000"
          stroke="none"
          d="
M1962.587891,374.497131
	C1960.792603,374.444183 1959.821533,373.791962 1960.711914,372.147736
	C1961.089355,371.451202 1961.883911,371.005646 1962.739502,371.611084
	C1963.925171,372.450165 1963.962402,373.381134 1962.587891,374.497131
z"
        />
        <path
          fill="#979DA1"
          opacity="1.000000"
          stroke="none"
          d="
M1726.005615,371.352203
	C1727.340576,371.988007 1728.274170,372.682648 1727.205200,373.973328
	C1726.447266,374.888428 1725.558594,374.893494 1724.802490,373.972168
	C1723.745483,372.684265 1724.660522,371.983368 1726.005615,371.352203
z"
        />
        <path
          fill="#979DA1"
          opacity="1.000000"
          stroke="none"
          d="
M1526.394043,374.486511
	C1525.015625,373.361847 1525.073853,372.435303 1526.262573,371.604980
	C1527.123047,371.003876 1527.914795,371.455505 1528.285034,372.152863
	C1529.159302,373.798492 1528.193848,374.450897 1526.394043,374.486511
z"
        />
        <path
          fill="#979DA1"
          opacity="1.000000"
          stroke="none"
          d="
M1291.005615,371.352203
	C1292.340576,371.988007 1293.274170,372.682617 1292.205200,373.973328
	C1291.447266,374.888428 1290.558594,374.893494 1289.802490,373.972229
	C1288.745361,372.684326 1289.660522,371.983368 1291.005615,371.352203
z"
        />
        <path
          fill="#979DA1"
          opacity="1.000000"
          stroke="none"
          d="
M1528.105713,317.899750
	C1529.105347,319.830536 1528.211548,320.545135 1526.688110,320.566864
	C1526.255493,320.573090 1525.435547,319.754669 1525.440186,319.321259
	C1525.456299,317.792877 1526.175171,316.907257 1528.105713,317.899750
z"
        />
        <path
          fill="#979DA1"
          opacity="1.000000"
          stroke="none"
          d="
M1290.394043,320.486572
	C1289.015991,319.361877 1289.073730,318.435242 1290.262573,317.604919
	C1291.123047,317.003967 1291.914795,317.455597 1292.285156,318.152893
	C1293.159302,319.798462 1292.193970,320.451111 1290.394043,320.486572
z"
        />
        <path
          fill="#979DA1"
          opacity="1.000000"
          stroke="none"
          d="
M1944.899658,309.894348
	C1946.830566,308.894501 1947.545044,309.788116 1947.566406,311.311615
	C1947.572632,311.744415 1946.754517,312.564392 1946.321167,312.559845
	C1944.792358,312.543915 1943.907837,311.824463 1944.899658,309.894348
z"
        />
        <path
          fill="#979DA1"
          opacity="1.000000"
          stroke="none"
          d="
M1961.412109,303.502808
	C1963.207520,303.555725 1964.178833,304.208008 1963.288574,305.852325
	C1962.911499,306.548889 1962.116699,306.994324 1961.260864,306.389008
	C1960.074585,305.549957 1960.037720,304.619049 1961.412109,303.502808
z"
        />
        <path
          fill="#979DA1"
          opacity="1.000000"
          stroke="none"
          d="
M1724.894531,306.100342
	C1723.894897,304.169647 1724.788330,303.455017 1726.311768,303.433472
	C1726.744385,303.427307 1727.564331,304.245392 1727.559692,304.678711
	C1727.543701,306.207123 1726.824829,307.092316 1724.894531,306.100342
z"
        />
        <path
          fill="#979DA1"
          opacity="1.000000"
          stroke="none"
          d="
M1289.900391,303.893860
	C1291.829712,302.897797 1292.549927,303.787598 1292.574707,305.312195
	C1292.581665,305.743713 1291.760254,306.563568 1291.324585,306.559570
	C1289.794189,306.545563 1288.907349,305.827057 1289.900391,303.893860
z"
        />
        <path
          fill="#979DA1"
          opacity="1.000000"
          stroke="none"
          d="
M1836.352173,303.994385
	C1836.988037,302.659424 1837.682861,301.725891 1838.973511,302.794952
	C1839.888550,303.552917 1839.893433,304.441406 1838.972046,305.197479
	C1837.683838,306.254761 1836.983398,305.338989 1836.352173,303.994385
z"
        />
        <path
          fill="#979DA1"
          opacity="1.000000"
          stroke="none"
          d="
M1726.605957,317.513489
	C1727.984375,318.638153 1727.926147,319.564636 1726.737427,320.395020
	C1725.876831,320.996094 1725.085327,320.544434 1724.714966,319.847137
	C1723.840698,318.201477 1724.806152,317.549103 1726.605957,317.513489
z"
        />
        <path
          fill="#979DA1"
          opacity="1.000000"
          stroke="none"
          d="
M1290.994385,293.647797
	C1289.659302,293.012024 1288.726074,292.317200 1289.794800,291.026581
	C1290.552612,290.111511 1291.441284,290.106476 1292.197510,291.027863
	C1293.254639,292.315918 1292.339233,293.016663 1290.994385,293.647797
z"
        />
        <path
          fill="#979DA1"
          opacity="1.000000"
          stroke="none"
          d="
M1724.899780,290.894531
	C1726.830566,289.894806 1727.544800,290.788666 1727.566528,292.311920
	C1727.572510,292.744568 1726.754517,293.564484 1726.321167,293.559967
	C1724.792603,293.543884 1723.907715,292.824707 1724.899780,290.894531
z"
        />
        <path
          fill="#979DA1"
          opacity="1.000000"
          stroke="none"
          d="
M1944.899780,290.894470
	C1946.830566,289.894806 1947.544922,290.788696 1947.566528,292.311951
	C1947.572632,292.744598 1946.754517,293.564484 1946.321167,293.559937
	C1944.792603,293.543915 1943.907715,292.824707 1944.899780,290.894470
z"
        />
        <path
          fill="#979DA1"
          opacity="1.000000"
          stroke="none"
          d="
M1962.005615,290.352203
	C1963.340576,290.988007 1964.274170,291.682617 1963.205200,292.973389
	C1962.447388,293.888458 1961.558594,293.893463 1960.802490,292.972198
	C1959.745483,291.684326 1960.660522,290.983368 1962.005615,290.352203
z"
        />
        <path
          fill="#979DA1"
          opacity="1.000000"
          stroke="none"
          d="
M1837.412109,291.502869
	C1839.207397,291.555817 1840.178589,292.208069 1839.287964,293.852234
	C1838.910767,294.548859 1838.116211,294.994293 1837.260498,294.388855
	C1836.074829,293.549774 1836.037598,292.618866 1837.412109,291.502869
z"
        />
        <path
          fill="#BCB7AB"
          opacity="1.000000"
          stroke="none"
          d="
M1963.515869,359.613770
	C1962.453613,361.033142 1961.491577,361.058746 1960.628418,359.880890
	C1960.183594,359.273743 1960.383911,358.534210 1961.021240,358.259552
	C1962.152222,357.772308 1963.250488,357.768616 1963.515869,359.613770
z"
        />
        <path
          fill="#BCB7AB"
          opacity="1.000000"
          stroke="none"
          d="
M1905.848145,367.838043
	C1907.445435,366.807800 1908.263672,367.480682 1908.571045,368.734650
	C1908.730713,369.385620 1908.057617,369.923004 1907.371094,369.955048
	C1906.131714,370.012756 1905.017456,369.815308 1905.848145,367.838043
z"
        />
        <path
          fill="#BCB7AB"
          opacity="1.000000"
          stroke="none"
          d="
M1848.006836,367.268677
	C1849.162598,367.797882 1850.168823,368.379211 1849.273071,369.466888
	C1848.581055,370.307068 1847.435425,370.303864 1846.744263,369.467041
	C1845.848633,368.382751 1846.842651,367.794434 1848.006836,367.268677
z"
        />
        <path
          fill="#BCB7AB"
          opacity="1.000000"
          stroke="none"
          d="
M1857.340332,369.907593
	C1855.697388,368.559998 1856.557129,367.758575 1857.701294,367.334015
	C1858.526733,367.027649 1859.357422,367.644653 1859.504150,368.493408
	C1859.740845,369.864502 1858.669678,369.967010 1857.340332,369.907593
z"
        />
        <path
          fill="#BCB7AB"
          opacity="1.000000"
          stroke="none"
          d="
M1875.438232,369.022461
	C1875.875977,367.531311 1876.593750,366.742920 1877.922485,367.637756
	C1878.469849,368.006470 1878.886353,368.703827 1878.426880,369.279755
	C1877.567017,370.357727 1876.517456,370.255066 1875.438232,369.022461
z"
        />
        <path
          fill="#BCB7AB"
          opacity="1.000000"
          stroke="none"
          d="
M1886.994141,370.005432
	C1885.572998,369.661133 1884.905518,369.066559 1885.780029,367.950317
	C1886.514893,367.012268 1887.457764,366.996857 1888.200195,367.941589
	C1889.073730,369.053101 1888.412109,369.652649 1886.994141,370.005432
z"
        />
        <path
          fill="#BCB7AB"
          opacity="1.000000"
          stroke="none"
          d="
M1306.163330,263.848450
	C1307.004761,265.830963 1305.878174,266.016632 1304.643555,265.961823
	C1303.958374,265.931396 1303.282349,265.401306 1303.439209,264.744568
	C1303.742310,263.477295 1304.568481,262.826782 1306.163330,263.848450
z"
        />
        <path
          fill="#BCB7AB"
          opacity="1.000000"
          stroke="none"
          d="
M1896.381714,367.424072
	C1897.776001,367.268585 1898.682007,367.770142 1898.522705,368.902954
	C1898.346924,370.152405 1897.188599,370.226807 1896.252075,369.823181
	C1894.961304,369.266815 1895.298218,368.395111 1896.381714,367.424072
z"
        />
        <path
          fill="#BCB7AB"
          opacity="1.000000"
          stroke="none"
          d="
M1725.992920,360.731079
	C1724.837402,360.201782 1723.830933,359.620392 1724.726929,358.532867
	C1725.418945,357.692902 1726.564453,357.696259 1727.255493,358.533081
	C1728.151001,359.617249 1727.157349,360.205414 1725.992920,360.731079
z"
        />
        <path
          fill="#BCB7AB"
          opacity="1.000000"
          stroke="none"
          d="
M1525.836426,360.151703
	C1524.995361,358.169373 1526.121826,357.983246 1527.356445,358.038177
	C1528.041626,358.068695 1528.717773,358.598755 1528.560669,359.255524
	C1528.257568,360.522766 1527.431641,361.173462 1525.836426,360.151703
z"
        />
        <path
          fill="#BCB7AB"
          opacity="1.000000"
          stroke="none"
          d="
M1289.443115,358.954712
	C1290.532715,357.739899 1291.585571,357.632812 1292.438965,358.719238
	C1292.894409,359.299225 1292.467896,359.995056 1291.917725,360.357574
	C1290.586914,361.234344 1289.864502,360.462677 1289.443115,358.954712
z"
        />
        <path
          fill="#BCB7AB"
          opacity="1.000000"
          stroke="none"
          d="
M1944.442993,348.954681
	C1945.532715,347.739929 1946.585693,347.632629 1947.438965,348.719055
	C1947.894531,349.299042 1947.468018,349.994965 1946.917725,350.357483
	C1945.587158,351.233887 1944.864258,350.463104 1944.442993,348.954681
z"
        />
        <path
          fill="#BCB7AB"
          opacity="1.000000"
          stroke="none"
          d="
M1934.438232,283.022461
	C1934.875977,281.531250 1935.593872,280.743103 1936.922363,281.637878
	C1937.469849,282.006500 1937.886353,282.703857 1937.426880,283.279785
	C1936.567017,284.357758 1935.517456,284.255005 1934.438232,283.022461
z"
        />
        <path
          fill="#BCB7AB"
          opacity="1.000000"
          stroke="none"
          d="
M1925.994263,284.006287
	C1924.573486,283.661285 1923.905518,283.066803 1924.780151,281.950378
	C1925.515259,281.011993 1926.458008,280.997131 1927.200317,281.941925
	C1928.073486,283.053253 1927.412598,283.653290 1925.994263,284.006287
z"
        />
        <path
          fill="#BCB7AB"
          opacity="1.000000"
          stroke="none"
          d="
M1905.760254,283.574158
	C1905.206055,281.834930 1906.035034,281.171692 1907.352905,281.350372
	C1908.075073,281.448273 1908.893188,282.156189 1908.474854,283.125854
	C1907.986206,284.257812 1906.969727,284.089172 1905.760254,283.574158
z"
        />
        <path
          fill="#BCB7AB"
          opacity="1.000000"
          stroke="none"
          d="
M1897.006836,281.268860
	C1898.162354,281.798065 1899.168823,282.379303 1898.273071,283.466888
	C1897.581177,284.307007 1896.435791,284.303802 1895.744385,283.467041
	C1894.848755,282.382690 1895.842773,281.794647 1897.006836,281.268860
z"
        />
        <path
          fill="#BCB7AB"
          opacity="1.000000"
          stroke="none"
          d="
M1885.484009,282.386353
	C1886.546387,280.967072 1887.508545,280.941071 1888.371826,282.119019
	C1888.816528,282.726196 1888.616333,283.465973 1887.978882,283.740692
	C1886.847900,284.228027 1885.749634,284.231323 1885.484009,282.386353
z"
        />
        <path
          fill="#BCB7AB"
          opacity="1.000000"
          stroke="none"
          d="
M1878.163330,281.848450
	C1879.004761,283.831085 1877.877808,284.016754 1876.643311,283.961884
	C1875.958008,283.931396 1875.281860,283.401062 1875.438965,282.744568
	C1875.742188,281.477325 1876.568359,280.826691 1878.163330,281.848450
z"
        />
        <path
          fill="#BCB7AB"
          opacity="1.000000"
          stroke="none"
          d="
M1857.340210,283.907654
	C1855.697754,282.560028 1856.557007,281.758514 1857.701416,281.334015
	C1858.526855,281.027863 1859.357788,281.644684 1859.504272,282.493500
	C1859.740967,283.864624 1858.669800,283.967163 1857.340210,283.907654
z"
        />
        <path
          fill="#BCB7AB"
          opacity="1.000000"
          stroke="none"
          d="
M1847.994385,284.005737
	C1846.573120,283.661285 1845.905273,283.066833 1846.780029,281.950378
	C1847.514893,281.012024 1848.457642,280.997162 1849.200195,281.941681
	C1850.073730,283.053162 1849.412842,283.652893 1847.994385,284.005737
z"
        />
        <path
          fill="#BCB7AB"
          opacity="1.000000"
          stroke="none"
          d="
M1753.382324,263.424133
	C1754.779419,263.269440 1755.682129,263.773468 1755.525024,264.908539
	C1755.350342,266.170654 1754.189819,266.229309 1753.254761,265.826355
	C1751.964111,265.270203 1752.295776,264.397369 1753.382324,263.424133
z"
        />
        <path
          fill="#BCB7AB"
          opacity="1.000000"
          stroke="none"
          d="
M1863.848267,385.838226
	C1865.445312,384.808044 1866.263306,385.480682 1866.570679,386.734711
	C1866.730347,387.385834 1866.057373,387.922882 1865.370972,387.954987
	C1864.131470,388.012817 1863.017700,387.815155 1863.848267,385.838226
z"
        />
        <path
          fill="#BCB7AB"
          opacity="1.000000"
          stroke="none"
          d="
M1877.438232,387.022461
	C1877.875977,385.531250 1878.593872,384.743164 1879.922363,385.637817
	C1880.469849,386.006470 1880.886230,386.703796 1880.426758,387.279724
	C1879.566895,388.357666 1878.517456,388.255005 1877.438232,387.022461
z"
        />
        <path
          fill="#BCB7AB"
          opacity="1.000000"
          stroke="none"
          d="
M1919.994141,388.005402
	C1918.572876,387.661041 1917.905884,387.066467 1918.780273,385.950226
	C1919.515259,385.011963 1920.458008,384.997314 1921.200195,385.941681
	C1922.073975,387.053101 1921.412109,387.652527 1919.994141,388.005402
z"
        />
        <path
          fill="#BCB7AB"
          opacity="1.000000"
          stroke="none"
          d="
M1932.848145,385.838165
	C1934.445435,384.808105 1935.263428,385.480927 1935.570923,386.734833
	C1935.730469,387.385956 1935.057495,387.922852 1934.371094,387.955017
	C1933.131348,388.013000 1932.017822,387.815033 1932.848145,385.838165
z"
        />
        <path
          fill="#BCB7AB"
          opacity="1.000000"
          stroke="none"
          d="
M1947.340210,387.907471
	C1945.697388,386.559662 1946.557129,385.757996 1947.701416,385.333344
	C1948.526978,385.026947 1949.357788,385.644165 1949.504272,386.493164
	C1949.740845,387.864532 1948.669800,387.967041 1947.340210,387.907471
z"
        />
        <path
          fill="#BCB7AB"
          opacity="1.000000"
          stroke="none"
          d="
M1877.848267,263.838135
	C1879.445435,262.808044 1880.263550,263.480896 1880.571045,264.734772
	C1880.730713,265.385803 1880.057251,265.922852 1879.370972,265.954987
	C1878.131348,266.012939 1877.017700,265.815033 1877.848267,263.838135
z"
        />
        <path
          fill="#BCB7AB"
          opacity="1.000000"
          stroke="none"
          d="
M1866.208130,265.596375
	C1864.993896,266.086487 1863.968872,266.262878 1863.502563,265.112274
	C1863.109619,264.142578 1863.936035,263.439178 1864.657837,263.353302
	C1865.970703,263.197144 1866.807007,263.856934 1866.208130,265.596375
z"
        />
        <path
          fill="#BCB7AB"
          opacity="1.000000"
          stroke="none"
          d="
M1849.439941,265.030304
	C1849.874878,263.529144 1850.593994,262.740143 1851.928223,263.643555
	C1852.479858,264.017059 1852.880615,264.716064 1852.427856,265.297577
	C1851.562988,266.408325 1850.517090,266.267639 1849.439941,265.030304
z"
        />
        <path
          fill="#BCB7AB"
          opacity="1.000000"
          stroke="none"
          d="
M1836.339966,265.907776
	C1834.697632,264.560211 1835.556396,263.758606 1836.701172,263.334015
	C1837.526855,263.027802 1838.357544,263.644592 1838.503906,264.493500
	C1838.740479,265.864655 1837.669556,265.967133 1836.339966,265.907776
z"
        />
        <path
          fill="#BCB7AB"
          opacity="1.000000"
          stroke="none"
          d="
M1821.438232,265.022461
	C1821.875977,263.531250 1822.593872,262.743134 1823.922363,263.637848
	C1824.469849,264.006531 1824.886230,264.703766 1824.426758,265.279724
	C1823.566895,266.357666 1822.517456,266.255005 1821.438232,265.022461
z"
        />
        <path
          fill="#BCB7AB"
          opacity="1.000000"
          stroke="none"
          d="
M1807.484009,264.386230
	C1808.546509,262.966858 1809.508667,262.941284 1810.371704,264.119446
	C1810.816650,264.726776 1810.616333,265.466431 1809.979004,265.741028
	C1808.847656,266.228516 1807.749512,266.230988 1807.484009,264.386230
z"
        />
        <path
          fill="#BCB7AB"
          opacity="1.000000"
          stroke="none"
          d="
M1767.381714,263.424072
	C1768.776001,263.268585 1769.682007,263.770142 1769.522705,264.902954
	C1769.346924,266.152405 1768.188599,266.226807 1767.252075,265.823090
	C1765.961304,265.266754 1766.298218,264.395111 1767.381714,263.424072
z"
        />
        <path
          fill="#BCB7AB"
          opacity="1.000000"
          stroke="none"
          d="
M1936.645508,369.906372
	C1935.317749,369.972687 1934.247925,369.876373 1934.480835,368.505096
	C1934.624878,367.657196 1935.450562,367.039856 1936.277832,367.338104
	C1937.437256,367.756104 1938.271973,368.557739 1936.645508,369.906372
z"
        />
        <path
          fill="#BCB7AB"
          opacity="1.000000"
          stroke="none"
          d="
M1738.484131,264.386200
	C1739.546387,262.967072 1740.508667,262.940948 1741.371582,264.119019
	C1741.816406,264.726013 1741.616455,265.465698 1740.978760,265.740417
	C1739.847656,266.227753 1738.749512,266.231354 1738.484131,264.386200
z"
        />
        <path
          fill="#BCB7AB"
          opacity="1.000000"
          stroke="none"
          d="
M1514.530640,264.413727
	C1514.242798,266.262604 1513.138672,266.234131 1512.013062,265.742065
	C1511.368408,265.460236 1511.187134,264.713928 1511.635010,264.115631
	C1512.512207,262.943573 1513.480225,262.995392 1514.530640,264.413727
z"
        />
        <path
          fill="#BCB7AB"
          opacity="1.000000"
          stroke="none"
          d="
M1498.381592,263.424011
	C1499.776245,263.268311 1500.682129,263.770233 1500.522827,264.902863
	C1500.347168,266.152344 1499.188599,266.226837 1498.252197,265.823242
	C1496.961792,265.266998 1497.297974,264.395111 1498.381592,263.424011
z"
        />
        <path
          fill="#BCB7AB"
          opacity="1.000000"
          stroke="none"
          d="
M1486.558838,265.057281
	C1485.467041,266.327850 1484.414673,266.352173 1483.565552,265.287842
	C1483.105469,264.711090 1483.528198,264.007935 1484.081787,263.643982
	C1485.418945,262.764893 1486.140869,263.539948 1486.558838,265.057281
z"
        />
        <path
          fill="#BCB7AB"
          opacity="1.000000"
          stroke="none"
          d="
M1445.530640,264.413757
	C1445.242798,266.262451 1444.138794,266.234283 1443.013062,265.742065
	C1442.368530,265.460205 1442.187256,264.713989 1442.635010,264.115662
	C1443.512329,262.943481 1444.480225,262.995697 1445.530640,264.413757
z"
        />
        <path
          fill="#BCB7AB"
          opacity="1.000000"
          stroke="none"
          d="
M1429.331055,265.936768
	C1427.722412,264.588440 1428.531128,263.765900 1429.696655,263.334900
	C1430.526001,263.028198 1431.357910,263.644257 1431.504395,264.498230
	C1431.739502,265.869110 1430.656372,265.973602 1429.331055,265.936768
z"
        />
        <path
          fill="#BCB7AB"
          opacity="1.000000"
          stroke="none"
          d="
M1414.438110,265.022247
	C1414.876099,263.531158 1415.593994,262.742828 1416.922607,263.637909
	C1417.470337,264.006866 1417.886108,264.704468 1417.426880,265.280029
	C1416.566772,266.357452 1415.517212,266.255432 1414.438110,265.022247
z"
        />
        <path
          fill="#BCB7AB"
          opacity="1.000000"
          stroke="none"
          d="
M1403.208008,265.596344
	C1401.993774,266.086365 1400.969116,266.262817 1400.502930,265.112000
	C1400.110107,264.142303 1400.936157,263.439209 1401.658081,263.353271
	C1402.971313,263.196899 1403.806763,263.857330 1403.208008,265.596344
z"
        />
        <path
          fill="#BCB7AB"
          opacity="1.000000"
          stroke="none"
          d="
M1387.382202,263.424011
	C1388.777588,263.268890 1389.682251,263.771545 1389.523682,264.905029
	C1389.348267,266.159515 1388.188354,266.226562 1387.253052,265.823822
	C1385.961670,265.267761 1386.297852,264.395569 1387.382202,263.424011
z"
        />
        <path
          fill="#BCB7AB"
          opacity="1.000000"
          stroke="none"
          d="
M1372.759521,265.574677
	C1372.205933,263.835449 1373.034424,263.171844 1374.352417,263.350311
	C1375.074951,263.448181 1375.892700,264.156311 1375.473877,265.125641
	C1374.985352,266.256317 1373.969116,266.090424 1372.759521,265.574677
z"
        />
        <path
          fill="#BCB7AB"
          opacity="1.000000"
          stroke="none"
          d="
M1331.483398,264.391418
	C1332.549072,262.964111 1333.513550,262.940857 1334.376343,264.128326
	C1334.820068,264.739166 1334.612793,265.487366 1333.984009,265.765900
	C1332.837891,266.273651 1331.752686,266.224243 1331.483398,264.391418
z"
        />
        <path
          fill="#BCB7AB"
          opacity="1.000000"
          stroke="none"
          d="
M1319.006836,263.268860
	C1320.162598,263.798065 1321.168945,264.379608 1320.273071,265.467133
	C1319.581177,266.306976 1318.435547,266.304260 1317.744263,265.467285
	C1316.848633,264.382874 1317.842407,263.794617 1319.006836,263.268860
z"
        />
        <path
          fill="#BCB7AB"
          opacity="1.000000"
          stroke="none"
          d="
M1835.484009,386.386047
	C1836.546387,384.966858 1837.508545,384.940979 1838.371582,386.119110
	C1838.816406,386.726135 1838.616455,387.465912 1837.978760,387.740570
	C1836.847534,388.227783 1835.749268,388.231262 1835.484009,386.386047
z"
        />
        <path
          fill="#BCB7AB"
          opacity="1.000000"
          stroke="none"
          d="
M1924.484253,368.386047
	C1925.546509,366.966370 1926.508545,366.941193 1927.371704,368.118958
	C1927.816528,368.726044 1927.616089,369.465729 1926.978638,369.740356
	C1925.847534,370.227753 1924.749390,370.231201 1924.484253,368.386047
z"
        />
        <path
          fill="#BCB7AB"
          opacity="1.000000"
          stroke="none"
          d="
M1949.203857,265.603821
	C1947.987793,266.109131 1946.967041,266.303284 1946.499878,265.128326
	C1946.111938,264.152802 1946.926025,263.444366 1947.652710,263.355774
	C1948.973267,263.194702 1949.809204,263.857635 1949.203857,265.603821
z"
        />
        <path
          fill="#BCB7AB"
          opacity="1.000000"
          stroke="none"
          d="
M1933.994141,266.005402
	C1932.572876,265.661041 1931.905884,265.066467 1932.780273,263.950226
	C1933.515259,263.011963 1934.457886,262.997345 1935.200195,263.941711
	C1936.073730,265.053223 1935.412109,265.652527 1933.994141,266.005402
z"
        />
        <path
          fill="#BCB7AB"
          opacity="1.000000"
          stroke="none"
          d="
M1919.381714,263.424072
	C1920.776001,263.268585 1921.681763,263.770111 1921.522461,264.902924
	C1921.346680,266.152374 1920.188599,266.226807 1919.252075,265.823151
	C1917.961304,265.266815 1918.298218,264.395111 1919.381714,263.424072
z"
        />
        <path
          fill="#BCB7AB"
          opacity="1.000000"
          stroke="none"
          d="
M1303.438232,387.022461
	C1303.875977,385.531250 1304.593872,384.743134 1305.922363,385.637848
	C1306.469849,386.006531 1306.886353,386.703857 1306.426880,387.279846
	C1305.567017,388.357758 1304.517456,388.255005 1303.438232,387.022461
z"
        />
        <path
          fill="#BCB7AB"
          opacity="1.000000"
          stroke="none"
          d="
M1319.641235,385.437500
	C1320.724976,386.414124 1321.053833,387.285645 1319.755249,387.830688
	C1318.819702,388.223328 1317.659546,388.154694 1317.492432,386.897064
	C1317.341431,385.760834 1318.248901,385.268951 1319.641235,385.437500
z"
        />
        <path
          fill="#BCB7AB"
          opacity="1.000000"
          stroke="none"
          d="
M1333.641235,385.437469
	C1334.724609,386.414001 1335.053955,387.285522 1333.755371,387.830658
	C1332.819824,388.223419 1331.659668,388.154724 1331.492432,386.897095
	C1331.341309,385.760803 1332.249146,385.269196 1333.641235,385.437469
z"
        />
        <path
          fill="#BCB7AB"
          opacity="1.000000"
          stroke="none"
          d="
M1372.483887,386.385803
	C1373.546387,384.966248 1374.508423,384.940887 1375.371582,386.119019
	C1375.816528,386.726105 1375.616699,387.465790 1374.978882,387.740479
	C1373.847656,388.227722 1372.749268,388.231323 1372.483887,386.385803
z"
        />
        <path
          fill="#BCB7AB"
          opacity="1.000000"
          stroke="none"
          d="
M1389.557129,387.045227
	C1388.467529,388.260040 1387.414429,388.367218 1386.561035,387.281067
	C1386.105469,386.701477 1386.531738,386.005066 1387.082153,385.642456
	C1388.412842,384.765808 1389.135620,385.536957 1389.557129,387.045227
z"
        />
        <path
          fill="#BCB7AB"
          opacity="1.000000"
          stroke="none"
          d="
M1400.484131,386.386230
	C1401.546387,384.966797 1402.508545,384.941193 1403.371460,386.119141
	C1403.816284,386.726105 1403.616455,387.465668 1402.978882,387.740448
	C1401.847656,388.227722 1400.749512,388.231293 1400.484131,386.386230
z"
        />
        <path
          fill="#BCB7AB"
          opacity="1.000000"
          stroke="none"
          d="
M1414.848267,385.838104
	C1416.445435,384.808228 1417.246216,385.485565 1417.546631,386.740326
	C1417.618652,387.041595 1416.825317,387.860992 1416.364502,387.918518
	C1415.143066,388.070923 1414.017334,387.815094 1414.848267,385.838104
z"
        />
        <path
          fill="#BCB7AB"
          opacity="1.000000"
          stroke="none"
          d="
M1428.848267,385.837830
	C1430.445801,384.807007 1431.263672,385.480530 1431.571045,386.734436
	C1431.730591,387.385345 1431.057617,387.922882 1430.371094,387.954895
	C1429.131592,388.012665 1428.017212,387.815430 1428.848267,385.837830
z"
        />
        <path
          fill="#BCB7AB"
          opacity="1.000000"
          stroke="none"
          d="
M1442.484131,386.386230
	C1443.546387,384.966736 1444.508545,384.941284 1445.371582,386.119141
	C1445.816406,386.726105 1445.616699,387.465668 1444.979004,387.740479
	C1443.847778,388.227814 1442.749634,388.231079 1442.484131,386.386230
z"
        />
        <path
          fill="#BCB7AB"
          opacity="1.000000"
          stroke="none"
          d="
M1485.641357,385.437317
	C1486.725342,386.414154 1487.053467,387.285736 1485.755249,387.830719
	C1484.819580,388.223572 1483.659302,388.154449 1483.492188,386.897003
	C1483.341187,385.760925 1484.249023,385.269012 1485.641357,385.437317
z"
        />
        <path
          fill="#BCB7AB"
          opacity="1.000000"
          stroke="none"
          d="
M1498.994141,388.005402
	C1497.572876,387.661041 1496.905884,387.066467 1497.780396,385.950317
	C1498.515381,385.012054 1499.458008,384.997314 1500.200195,385.941681
	C1501.073975,387.053101 1500.412109,387.652527 1498.994141,388.005402
z"
        />
        <path
          fill="#BCB7AB"
          opacity="1.000000"
          stroke="none"
          d="
M1511.760132,387.573761
	C1511.206177,385.835022 1512.034912,385.171906 1513.352417,385.350250
	C1514.074585,385.448029 1514.892334,386.155609 1514.473999,387.124939
	C1513.985596,388.256104 1512.969482,388.088623 1511.760132,387.573761
z"
        />
        <path
          fill="#BCB7AB"
          opacity="1.000000"
          stroke="none"
          d="
M1739.340332,387.907593
	C1737.697388,386.559998 1738.557129,385.758606 1739.701294,385.334015
	C1740.526733,385.027649 1741.357422,385.644653 1741.503906,386.493439
	C1741.740723,387.864532 1740.669678,387.967010 1739.340332,387.907593
z"
        />
        <path
          fill="#BCB7AB"
          opacity="1.000000"
          stroke="none"
          d="
M1752.438232,387.022156
	C1752.876221,385.531555 1753.593506,384.741913 1754.922485,385.637299
	C1755.469971,386.006165 1755.886108,386.703735 1755.426880,387.279572
	C1754.566895,388.357605 1753.517456,388.255310 1752.438232,387.022156
z"
        />
        <path
          fill="#BCB7AB"
          opacity="1.000000"
          stroke="none"
          d="
M1767.381714,385.424072
	C1768.776001,385.268585 1769.682129,385.770142 1769.522705,386.902985
	C1769.347046,388.152405 1768.188599,388.226837 1767.252075,387.823120
	C1765.961304,387.266785 1766.298218,386.395111 1767.381714,385.424072
z"
        />
        <path
          fill="#BCB7AB"
          opacity="1.000000"
          stroke="none"
          d="
M1807.438110,387.022278
	C1807.876099,385.531372 1808.593994,384.742676 1809.922607,385.637451
	C1810.470337,386.006287 1810.885986,386.704102 1810.426514,387.279694
	C1809.566528,388.357452 1808.517212,388.255341 1807.438110,387.022278
z"
        />
        <path
          fill="#BCB7AB"
          opacity="1.000000"
          stroke="none"
          d="
M1821.438232,387.022461
	C1821.875977,385.531250 1822.593872,384.743164 1823.922363,385.637878
	C1824.469849,386.006561 1824.886230,386.703705 1824.426758,387.279663
	C1823.566772,388.357605 1822.517456,388.255005 1821.438232,387.022461
z"
        />
        <path
          fill="#BCB7AB"
          opacity="1.000000"
          stroke="none"
          d="
M1849.760010,387.573730
	C1849.205933,385.834930 1850.034790,385.171936 1851.352295,385.350220
	C1852.074585,385.447998 1852.892822,386.156067 1852.474365,387.125183
	C1851.986084,388.256226 1850.969482,388.088654 1849.760010,387.573730
z"
        />
        <path
          fill="#F4F4F4"
          opacity="1.000000"
          stroke="none"
          d="
M277.715820,189.227356
	C279.075470,190.314362 278.517761,191.027390 277.477020,191.463104
	C277.198334,191.579788 276.442200,191.240707 276.332458,190.949738
	C275.984802,190.027802 276.436981,189.402420 277.715820,189.227356
z"
        />
        <path
          fill="#F4F4F4"
          opacity="1.000000"
          stroke="none"
          d="
M335.120300,191.125214
	C333.940430,191.778610 333.268402,191.527237 333.212738,190.444748
	C333.175446,189.720108 333.715851,189.179276 334.441376,189.217392
	C335.523926,189.274216 335.774811,189.945953 335.120300,191.125214
z"
        />
        <path
          fill="#F4F4F4"
          opacity="1.000000"
          stroke="none"
          d="
M391.482544,191.236435
	C391.098206,190.070602 391.349060,189.330368 392.371674,189.215256
	C393.101715,189.133102 393.699554,189.606949 393.530090,190.368271
	C393.334198,191.248337 392.663330,191.709686 391.482544,191.236435
z"
        />
        <path
          fill="#F4F4F4"
          opacity="1.000000"
          stroke="none"
          d="
M372.232239,190.770905
	C372.293396,189.789108 372.646484,189.266968 373.378967,189.671616
	C373.643799,189.817932 373.826508,190.550705 373.698486,190.880753
	C373.417419,191.605087 372.851440,191.672562 372.232239,190.770905
z"
        />
        <path
          fill="#F4F4F4"
          opacity="1.000000"
          stroke="none"
          d="
M297.294556,189.515717
	C297.563232,190.560623 297.041656,190.883850 296.362366,190.753036
	C296.045441,190.692001 295.540253,190.100586 295.599030,189.873367
	C295.790894,189.131638 296.443237,189.115616 297.294556,189.515717
z"
        />
        <path
          fill="#F4F4F4"
          opacity="1.000000"
          stroke="none"
          d="
M354.268372,190.504974
	C353.410645,190.878296 352.758636,190.851685 352.590271,190.103287
	C352.538605,189.873734 353.059631,189.299347 353.378082,189.247162
	C354.057220,189.135895 354.571228,189.468552 354.268372,190.504974
z"
        />
        <path
          fill="#F4F4F4"
          opacity="1.000000"
          stroke="none"
          d="
M431.504700,189.725250
	C431.879944,190.583557 431.850342,191.234879 431.101746,191.401428
	C430.870636,191.452850 430.295807,190.932510 430.243683,190.614120
	C430.132172,189.933212 430.470123,189.423676 431.504700,189.725250
z"
        />
        <path
          fill="#F4F4F4"
          opacity="1.000000"
          stroke="none"
          d="
M410.524445,189.987427
	C410.998779,189.136047 411.589417,189.031784 412.169037,189.434875
	C412.635590,189.759323 412.630768,190.274612 412.151611,190.585449
	C411.561615,190.968155 410.972809,190.857269 410.524445,189.987427
z"
        />
        <path
          fill="#FFFFFF"
          opacity="1.000000"
          stroke="none"
          d="
M450.477112,216.488342
	C450.912659,218.297272 450.996552,219.794312 450.996582,221.291367
	C450.999573,331.860596 450.995911,442.429840 450.979279,553.459961
	C450.135620,553.966980 449.305511,554.053711 448.475372,554.053650
	C332.970673,554.047546 217.465988,554.035095 101.497803,553.971924
	C101.037605,441.878571 101.040909,329.836151 101.093613,217.317444
	C102.703606,215.304916 104.713654,215.126633 106.718254,215.123489
	C148.145935,215.058624 189.573654,214.969391 231.001236,215.162796
	C231.330276,215.164337 231.658859,215.259079 232.482666,215.339859
	C235.128464,215.093445 237.287918,215.048386 239.454834,215.048828
	C306.561066,215.062851 373.667297,215.049210 440.773529,215.080231
	C443.902313,215.081680 447.127777,214.589569 450.477112,216.488342
z"
        />
        <path
          fill="#F27643"
          opacity="1.000000"
          stroke="none"
          d="
M233.327606,216.335266
	C223.499466,216.723022 214.008606,216.859406 204.517654,216.866608
	C170.362045,216.892548 136.206406,216.874924 101.596893,216.857452
	C101.147484,212.947617 101.151955,209.054077 101.263199,204.734665
	C144.951874,204.346710 188.533752,204.384644 232.560486,204.569580
	C235.647049,208.344681 234.987488,212.165329 233.327606,216.335266
z"
        />
        <path
          fill="#F58B2A"
          opacity="1.000000"
          stroke="none"
          d="
M233.454041,216.726715
	C232.992294,212.937317 232.994247,209.228714 233.000763,205.118347
	C242.013428,203.811218 251.047119,204.565109 260.534149,204.264771
	C262.880768,205.262909 264.922058,204.785004 266.883728,204.785751
	C325.654236,204.808411 384.424713,204.781479 443.195221,204.811188
	C452.084229,204.815689 450.837646,203.662918 450.963959,212.405838
	C450.980774,213.570358 450.930573,214.735825 450.889648,216.350540
	C378.551178,216.802658 306.234467,216.805099 233.454041,216.726715
z"
        />
        <path
          fill="#E5EAEC"
          opacity="1.000000"
          stroke="none"
          d="
M452.461792,551.944946
	C452.087677,548.035706 452.006958,544.042175 452.007019,540.048645
	C452.008301,430.334686 452.017822,320.620758 452.078857,210.437531
	C458.084381,212.906006 456.421600,219.064453 457.940186,223.861832
	C458.276154,224.923203 457.999451,226.179504 457.999451,227.346054
	C457.999908,329.837097 458.023682,432.328186 457.940094,534.819153
	C457.935150,540.887939 457.820892,547.186218 452.461792,551.944946
z"
        />
        <path
          fill="#FFFFFF"
          opacity="1.000000"
          stroke="none"
          d="
M1882.000000,298.758240
	C1897.486450,298.758423 1912.473999,298.848480 1927.458740,298.691254
	C1930.867676,298.655487 1932.062988,299.569580 1932.020142,303.136383
	C1931.839844,318.120728 1931.858398,333.109344 1932.009644,348.094330
	C1932.042969,351.402252 1930.966797,352.320923 1927.728271,352.304169
	C1904.583008,352.184296 1881.436768,352.186676 1858.291504,352.302002
	C1855.085938,352.317963 1853.954346,351.453400 1853.988647,348.114044
	C1854.143799,332.962585 1854.130249,317.808044 1853.996094,302.656189
	C1853.968628,299.554321 1855.030762,298.644958 1858.022217,298.706207
	C1865.845215,298.866394 1873.673706,298.759125 1882.000000,298.758240
z"
        />
        <path
          fill="#FFFFFF"
          opacity="1.000000"
          stroke="none"
          d="
M1821.920898,349.742798
	C1821.767212,352.465912 1820.062378,352.222748 1818.604370,352.223816
	C1795.620728,352.240784 1772.636963,352.197662 1749.653687,352.287842
	C1746.749634,352.299225 1745.994507,351.203003 1746.011475,348.474640
	C1746.107422,333.152771 1746.108887,317.829712 1746.010620,302.507904
	C1745.992920,299.758453 1746.786865,298.701233 1749.671143,298.712372
	C1772.487915,298.800415 1795.305420,298.813110 1818.122070,298.700897
	C1821.296753,298.685303 1822.018677,299.873596 1821.996826,302.800781
	C1821.880981,318.288910 1821.939331,333.778351 1821.920898,349.742798
z"
        />
        <path
          fill="#FFFFFF"
          opacity="1.000000"
          stroke="none"
          d="
M1411.064575,304.137146
	C1410.367554,299.641266 1412.168579,298.657166 1416.023804,298.679077
	C1442.670654,298.830536 1469.319214,298.829651 1495.966064,298.679779
	C1499.807129,298.658173 1501.105469,299.612030 1501.039307,303.644867
	C1500.798706,318.297180 1500.812988,332.957825 1501.033203,347.610748
	C1501.090698,351.439789 1499.873901,352.338989 1496.210449,352.319183
	C1469.396851,352.174316 1442.581909,352.172150 1415.768433,352.321014
	C1412.072266,352.341522 1410.910034,351.384613 1410.968750,347.589508
	C1411.190186,333.269745 1411.064575,318.944611 1411.064575,304.137146
z"
        />
        <path
          fill="#FFFFFF"
          opacity="1.000000"
          stroke="none"
          d="
M1406.944580,311.015503
	C1406.944214,323.669037 1406.857422,335.827026 1407.002808,347.982239
	C1407.040649,351.139374 1406.218018,352.323486 1402.839600,352.308350
	C1375.528687,352.186096 1348.216797,352.197021 1320.905640,352.297272
	C1317.769531,352.308777 1316.977905,351.188995 1317.001221,348.227478
	C1317.120605,333.073883 1317.121216,317.918274 1317.000854,302.764709
	C1316.977173,299.793793 1317.787598,298.691742 1320.913452,298.703125
	C1348.224609,298.802612 1375.536499,298.814301 1402.847412,298.691406
	C1406.235596,298.676147 1407.242310,299.872223 1406.984253,303.028229
	C1406.781494,305.509613 1406.944336,308.020905 1406.944580,311.015503
z"
        />
        <path
          fill="#FFFFFF"
          opacity="1.000000"
          stroke="none"
          d="
M601.758301,330.000000
	C601.758850,319.014832 601.842041,308.528595 601.707703,298.045166
	C601.669861,295.089081 602.499084,293.976288 605.629211,293.996033
	C624.934570,294.117920 644.241333,294.102509 663.546936,294.008759
	C666.357849,293.995117 667.301147,294.897552 667.286865,297.721466
	C667.193665,316.194977 667.196228,334.669342 667.283325,353.142883
	C667.296997,356.038910 666.534241,357.310211 663.371460,357.292358
	C644.065979,357.183319 624.759338,357.197998 605.453613,357.278076
	C602.616333,357.289856 601.680054,356.223206 601.722107,353.466919
	C601.838928,345.812897 601.760071,338.155853 601.758301,330.000000
z"
        />
        <path
          fill="#F4F4F4"
          opacity="1.000000"
          stroke="none"
          d="
M1011.492310,267.337311
	C1012.233887,265.375458 1013.231140,264.351013 1014.912354,265.670654
	C1015.689026,266.280396 1016.127075,267.722900 1015.366638,268.304291
	C1014.095764,269.276001 1012.407593,269.893738 1011.492310,267.337311
z"
        />
        <path
          fill="#F8F7F7"
          opacity="1.000000"
          stroke="none"
          d="
M697.990845,481.959473
	C697.819519,486.047058 696.381897,488.509705 692.221436,488.125916
	C687.871765,487.724609 687.541504,484.343903 687.610535,481.156464
	C687.679565,477.968781 688.861572,475.015442 692.762756,475.096039
	C696.781189,475.178955 698.062622,477.921295 697.990845,481.959473
z"
        />
        <path
          fill="#E5EAEC"
          opacity="1.000000"
          stroke="none"
          d="
M1382.778809,428.269745
	C1384.713379,427.271576 1386.578003,427.076630 1387.416992,428.747589
	C1388.278687,430.463684 1388.110107,432.642395 1387.468628,434.493500
	C1387.080322,435.613464 1385.715820,435.873810 1384.519165,435.724091
	C1383.306152,435.572296 1382.406128,434.953705 1382.012207,433.712524
	C1381.439453,431.908112 1381.523926,430.173950 1382.778809,428.269745
z"
        />
        <path
          fill="#E5EAEC"
          opacity="1.000000"
          stroke="none"
          d="
M985.978821,507.597107
	C987.425781,505.082428 989.592590,504.785736 991.029480,506.353546
	C992.598450,508.065308 992.217102,510.715820 991.241699,512.761658
	C990.676880,513.946228 988.958984,514.144592 987.703674,513.511963
	C985.352844,512.327209 985.491943,510.156860 985.978821,507.597107
z"
        />
        <path
          fill="#FEFEFE"
          opacity="1.000000"
          stroke="none"
          d="
M1831.874756,513.965210
	C1828.042725,512.964966 1826.630493,509.884827 1828.281616,507.153229
	C1829.072144,505.845551 1830.276001,505.600647 1831.662109,505.740051
	C1833.216187,505.896423 1834.143433,506.682739 1833.868286,508.299957
	C1833.542847,510.214294 1835.069946,512.674683 1831.874756,513.965210
z"
        />
        <path
          fill="#FEFEFE"
          opacity="1.000000"
          stroke="none"
          d="
M622.874390,461.965698
	C619.042542,460.964508 617.630920,457.884857 619.281616,455.153351
	C620.072021,453.845490 621.275818,453.600800 622.661865,453.740265
	C624.216064,453.896637 625.143188,454.682617 624.867126,456.300140
	C624.540588,458.214142 626.069946,460.674744 622.874390,461.965698
z"
        />
        <path
          fill="#F8F7F7"
          opacity="1.000000"
          stroke="none"
          d="
M1365.580078,460.402557
	C1363.789551,462.602234 1361.529541,462.841309 1360.402954,461.032013
	C1359.264893,459.204285 1359.042114,456.205750 1360.534058,454.523315
	C1362.102173,452.754913 1364.627197,453.041077 1365.645142,455.652313
	C1366.143677,456.931458 1365.758545,458.555084 1365.580078,460.402557
z"
        />
        <path
          fill="#E5EAEC"
          opacity="1.000000"
          stroke="none"
          d="
M574.175049,453.946960
	C574.800720,454.717926 575.271240,455.281982 575.352356,455.897369
	C575.695923,458.505371 576.449646,461.458954 572.609131,462.130005
	C570.077637,462.572296 569.117432,461.229279 569.096985,458.684326
	C569.070007,455.327454 569.175049,452.445465 574.175049,453.946960
z"
        />
        <path
          fill="#E5EAEC"
          opacity="1.000000"
          stroke="none"
          d="
M739.551514,430.557129
	C739.555725,433.061157 739.896423,435.483582 737.025879,436.084930
	C735.593689,436.384979 733.583557,436.477875 733.407166,434.571075
	C733.199280,432.325165 731.817505,429.427246 734.591919,427.890228
	C736.814392,426.658936 738.782043,427.410736 739.551514,430.557129
z"
        />
        <path
          fill="#E5EAEC"
          opacity="1.000000"
          stroke="none"
          d="
M526.484131,505.841492
	C528.566284,505.203461 530.186768,505.202271 531.070129,507.079468
	C531.962524,508.976044 531.909302,510.939758 530.963013,512.823059
	C530.233948,514.274109 528.840698,514.257263 527.511292,514.156494
	C525.967102,514.039307 525.003418,513.168518 525.188599,511.566437
	C525.404663,509.697266 524.283875,507.584229 526.484131,505.841492
z"
        />
        <path
          fill="#E5EAEC"
          opacity="1.000000"
          stroke="none"
          d="
M697.552246,430.561707
	C697.555969,433.067810 697.891174,435.489960 695.020325,436.085205
	C693.590149,436.381683 691.579712,436.476440 691.405579,434.564331
	C691.201111,432.319122 689.817932,429.423065 692.595825,427.888855
	C694.821411,426.659668 696.784119,427.416595 697.552246,430.561707
z"
        />
        <path
          fill="#E5EAEC"
          opacity="1.000000"
          stroke="none"
          d="
M904.651489,487.297302
	C904.317017,486.314697 904.143433,485.661346 904.165100,485.014526
	C904.248230,482.539886 903.213745,479.189301 907.540588,479.487488
	C910.771729,479.710144 910.790771,482.359955 910.564209,484.694733
	C910.204956,488.395721 907.921875,488.829865 904.651489,487.297302
z"
        />
        <path
          fill="#E5EAEC"
          opacity="1.000000"
          stroke="none"
          d="
M920.903015,488.186768
	C916.232117,486.362579 918.195007,483.029266 918.869019,480.315491
	C919.119446,479.307343 921.116577,479.133789 922.462585,479.636475
	C924.608398,480.437714 924.666260,482.341339 924.615601,484.159943
	C924.556641,486.277283 923.994629,488.140350 920.903015,488.186768
z"
        />
        <path
          fill="#E5EAEC"
          opacity="1.000000"
          stroke="none"
          d="
M711.172974,479.945953
	C711.800171,480.717346 712.270752,481.281067 712.352173,481.896271
	C712.697327,484.504272 713.446228,487.456696 709.610901,488.128448
	C707.085815,488.570740 706.114624,487.236542 706.096436,484.685913
	C706.072388,481.332642 706.172058,478.448547 711.172974,479.945953
z"
        />
        <path
          fill="#E5EAEC"
          opacity="1.000000"
          stroke="none"
          d="
M1932.647705,432.083557
	C1932.439209,435.038727 1931.245483,436.544983 1928.500000,436.158691
	C1925.178467,435.691376 1926.396973,433.017853 1926.148682,431.073700
	C1925.869629,428.890472 1926.706055,427.528564 1929.021118,427.439484
	C1931.971069,427.326019 1932.486572,429.387360 1932.647705,432.083557
z"
        />
        <path
          fill="#FEFEFE"
          opacity="1.000000"
          stroke="none"
          d="
M1027.949341,482.247192
	C1029.023926,479.311249 1031.034302,479.036072 1032.841675,480.350037
	C1034.863647,481.820129 1034.160889,484.285706 1033.749512,486.359406
	C1033.438232,487.928345 1031.979736,488.405609 1030.616455,488.143219
	C1027.475586,487.538818 1027.683594,485.062195 1027.949341,482.247192
z"
        />
        <path
          fill="#FEFEFE"
          opacity="1.000000"
          stroke="none"
          d="
M1945.749268,481.417999
	C1945.935059,482.321533 1946.036255,482.817383 1946.027710,483.311310
	C1945.993652,485.268555 1946.426514,487.503723 1943.683350,488.098267
	C1942.270752,488.404388 1940.922607,488.064209 1940.330200,486.645172
	C1939.467285,484.577545 1939.152466,482.283112 1940.831421,480.563690
	C1942.338135,479.020599 1944.148682,479.420044 1945.749268,481.417999
z"
        />
        <path
          fill="#FEFEFE"
          opacity="1.000000"
          stroke="none"
          d="
M952.949341,456.247162
	C954.023987,453.311401 956.034424,453.036011 957.841675,454.350098
	C959.863525,455.820099 959.161011,458.285706 958.749451,460.359406
	C958.438049,461.928223 956.979614,462.405640 955.616394,462.143219
	C952.475647,461.538666 952.683533,459.062164 952.949341,456.247162
z"
        />
        <path
          fill="#E5EAEC"
          opacity="1.000000"
          stroke="none"
          d="
M1376.032471,482.782104
	C1374.818726,482.584656 1373.357544,483.277191 1373.347412,481.878662
	C1373.335083,480.162415 1374.752441,479.368225 1376.340942,479.328339
	C1377.591309,479.296875 1378.559814,480.115997 1378.733276,481.348969
	C1379.011719,483.328430 1377.364380,482.572815 1376.032471,482.782104
z"
        />
        <path
          fill="#E5EAEC"
          opacity="1.000000"
          stroke="none"
          d="
M562.909302,514.222046
	C560.679443,514.347046 559.449951,513.485168 560.367371,511.719574
	C561.090088,510.328796 563.042786,510.079163 564.229309,511.002533
	C565.896729,512.300049 564.971985,513.442444 562.909302,514.222046
z"
        />
        <path
          fill="#E5EAEC"
          opacity="1.000000"
          stroke="none"
          d="
M655.093323,508.046356
	C655.563538,505.450470 656.984253,504.861084 659.034119,505.745422
	C659.857056,506.100403 660.468750,507.101685 660.053528,507.718750
	C658.799744,509.582062 656.923462,508.684204 655.093323,508.046356
z"
        />
        <path
          fill="#E5EAEC"
          opacity="1.000000"
          stroke="none"
          d="
M1464.389526,479.983459
	C1465.965820,483.603302 1463.307129,482.584839 1461.808838,482.720001
	C1460.812378,482.809875 1459.581055,482.582886 1460.109619,481.176117
	C1460.802002,479.333740 1462.275513,478.894836 1464.389526,479.983459
z"
        />
        <path
          fill="#E5EAEC"
          opacity="1.000000"
          stroke="none"
          d="
M1435.236084,453.388428
	C1437.220215,453.588959 1438.861084,454.424774 1437.798340,456.090759
	C1436.901367,457.496979 1434.403564,457.274841 1433.380371,456.301514
	C1432.113892,455.096527 1433.635986,454.080048 1435.236084,453.388428
z"
        />
        <path
          fill="#E5EAEC"
          opacity="1.000000"
          stroke="none"
          d="
M1003.732910,505.567505
	C1005.114502,506.736725 1006.095947,507.983276 1004.033813,508.597748
	C1002.844666,508.952087 1000.596924,509.570740 1000.179749,507.878357
	C999.656982,505.757812 1001.616455,505.357635 1003.732910,505.567505
z"
        />
        <path
          fill="#FEFEFE"
          opacity="1.000000"
          stroke="none"
          d="
M1083.319824,428.668640
	C1083.748657,429.645020 1084.007568,430.291016 1084.006348,430.936554
	C1084.002075,433.205475 1084.578125,436.062134 1081.077148,436.124664
	C1077.758545,436.183929 1077.611938,433.524628 1077.820679,431.143982
	C1078.116333,427.772186 1080.039307,426.946960 1083.319824,428.668640
z"
        />
        <path
          fill="#E5EAEC"
          opacity="1.000000"
          stroke="none"
          d="
M1837.862549,458.784607
	C1840.083252,460.989105 1838.531982,461.697052 1836.866577,462.209381
	C1835.717529,462.562866 1834.385010,462.413940 1834.206421,461.009949
	C1833.893799,458.551422 1835.842896,458.731659 1837.862549,458.784607
z"
        />
        <path
          fill="#FEFEFE"
          opacity="1.000000"
          stroke="none"
          d="
M1753.344727,487.759705
	C1752.654541,486.984802 1752.160767,486.444031 1752.058105,485.837128
	C1751.641113,483.371063 1750.994629,480.594208 1754.435791,479.811859
	C1756.561279,479.328674 1757.955200,480.518311 1758.015259,482.822968
	C1758.091675,485.765808 1758.310669,488.891693 1753.344727,487.759705
z"
        />
        <path
          fill="#C3CDCE"
          opacity="1.000000"
          stroke="none"
          d="
M1700.341309,430.622620
	C1701.680542,428.733673 1702.816895,427.158203 1704.836548,424.357910
	C1704.631958,428.119781 1706.004028,430.666168 1703.629761,432.353149
	C1702.752930,432.976105 1700.339478,433.755585 1700.341309,430.622620
z"
        />
        <path
          fill="#FEFEFE"
          opacity="1.000000"
          stroke="none"
          d="
M986.107117,453.801117
	C989.709351,454.845551 991.211731,458.340607 989.604858,460.852966
	C988.895508,461.961914 987.797119,462.296021 986.586243,462.165955
	C985.342957,462.032410 984.375916,461.383026 984.093689,460.086212
	C983.590454,457.773376 983.188416,455.454285 986.107117,453.801117
z"
        />
        <path
          fill="#E5EAEC"
          opacity="1.000000"
          stroke="none"
          d="
M1916.002197,427.347168
	C1917.656738,428.021820 1918.983276,429.150269 1917.684814,430.295868
	C1916.603882,431.249725 1914.114502,431.510742 1913.275513,430.099792
	C1912.234741,428.349091 1914.178467,427.736328 1916.002197,427.347168
z"
        />
        <path
          fill="#FEFEFE"
          opacity="1.000000"
          stroke="none"
          d="
M550.629639,461.773621
	C546.504272,461.631012 544.599243,458.511841 546.183960,455.371216
	C546.782715,454.184570 547.723694,453.677124 548.984070,453.726227
	C550.426575,453.782410 551.676025,454.298737 551.863892,455.897461
	C552.092346,457.840057 552.561462,459.878235 550.629639,461.773621
z"
        />
        <path
          fill="#E5EAEC"
          opacity="1.000000"
          stroke="none"
          d="
M1821.614990,433.208191
	C1822.209595,436.129669 1820.401978,436.385498 1818.538696,436.247192
	C1817.484619,436.169037 1816.760254,435.264130 1817.207397,434.160889
	C1818.009521,432.181824 1819.665039,432.498535 1821.614990,433.208191
z"
        />
        <path
          fill="#E5EAEC"
          opacity="1.000000"
          stroke="none"
          d="
M1965.614990,433.208191
	C1966.209595,436.129669 1964.401978,436.385498 1962.538696,436.247192
	C1961.484619,436.169037 1960.760498,435.264221 1961.207642,434.160980
	C1962.009766,432.181915 1963.665039,432.498535 1965.614990,433.208191
z"
        />
        <path
          fill="#E5EAEC"
          opacity="1.000000"
          stroke="none"
          d="
M654.990234,485.924103
	C654.197632,488.491974 652.613708,488.749084 650.764771,487.901367
	C649.896240,487.503174 649.824646,486.365967 650.297852,485.808594
	C651.649414,484.216461 653.297424,484.168304 654.990234,485.924103
z"
        />
        <path
          fill="#E5EAEC"
          opacity="1.000000"
          stroke="none"
          d="
M960.451416,487.500763
	C958.620483,488.291870 956.899658,489.178040 956.188293,486.879822
	C955.754333,485.478058 956.911133,484.844727 958.127319,484.722107
	C959.872925,484.546082 961.564819,484.600006 960.451416,487.500763
z"
        />
        <path
          fill="#E5EAEC"
          opacity="1.000000"
          stroke="none"
          d="
M1863.476807,504.059418
	C1863.388550,500.767395 1865.342285,501.052856 1867.196899,501.387451
	C1869.150635,501.739899 1869.967773,503.047363 1869.738525,504.984863
	C1869.522827,506.807892 1868.286499,507.360809 1866.627197,507.526062
	C1864.267090,507.761078 1863.190186,506.831360 1863.476807,504.059418
z"
        />
        <path
          fill="#E5EAEC"
          opacity="1.000000"
          stroke="none"
          d="
M584.357422,459.001526
	C586.236511,458.647980 588.060425,458.195740 587.912292,460.527222
	C587.808105,462.167175 586.093933,462.506897 584.816284,462.273010
	C582.795532,461.903107 582.436096,460.692993 584.357422,459.001526
z"
        />
        <path
          fill="#E5EAEC"
          opacity="1.000000"
          stroke="none"
          d="
M1023.345215,456.180908
	C1023.426880,454.758636 1024.086914,454.095398 1025.003662,453.629303
	C1026.168945,453.036957 1027.250732,453.175110 1028.072754,454.174683
	C1028.448608,454.631744 1028.863770,455.399017 1028.727661,455.872009
	C1028.274658,457.445709 1026.849976,456.619812 1025.846069,456.746490
	C1025.063843,456.845184 1024.241211,456.624634 1023.345215,456.180908
z"
        />
        <path
          fill="#FEFEFE"
          opacity="1.000000"
          stroke="none"
          d="
M1024.390625,460.313782
	C1022.563110,459.055023 1023.261292,458.624359 1025.282959,458.961670
	C1025.300903,459.408417 1025.057617,459.684326 1024.390625,460.313782
z"
        />
        <path
          fill="#E5EAEC"
          opacity="1.000000"
          stroke="none"
          d="
M621.727173,508.122986
	C619.877258,508.937805 618.141418,508.944214 616.383118,508.488159
	C618.273254,504.322266 619.125610,504.217438 621.727173,508.122986
z"
        />
        <path
          fill="#E5EAEC"
          opacity="1.000000"
          stroke="none"
          d="
M1279.025391,458.633484
	C1281.479736,459.298279 1281.647705,460.563416 1280.109863,461.725403
	C1278.947266,462.603851 1277.047729,462.975739 1276.298462,461.370880
	C1275.476074,459.609253 1276.967896,458.846039 1279.025391,458.633484
z"
        />
        <path
          fill="#E5EAEC"
          opacity="1.000000"
          stroke="none"
          d="
M973.146606,512.963989
	C973.836792,510.145996 975.596252,510.381042 977.340515,511.023346
	C978.266296,511.364227 978.285034,512.481201 977.768250,513.059204
	C976.432983,514.552551 974.843628,514.985779 973.146606,512.963989
z"
        />
        <path
          fill="#E5EAEC"
          opacity="1.000000"
          stroke="none"
          d="
M1802.325439,510.926636
	C1804.137329,510.663940 1806.013062,510.151642 1805.949219,512.357605
	C1805.895752,514.199585 1804.027710,514.575745 1802.640991,514.255249
	C1800.763794,513.821350 1800.498169,512.526672 1802.325439,510.926636
z"
        />
        <path
          fill="#E5EAEC"
          opacity="1.000000"
          stroke="none"
          d="
M1787.184570,460.101685
	C1788.808960,458.419556 1790.456055,457.934753 1791.901611,459.631287
	C1792.379761,460.192444 1792.008179,461.328064 1791.200195,461.689270
	C1789.582275,462.412598 1787.766968,463.346985 1787.184570,460.101685
z"
        />
        <path
          fill="#E5EAEC"
          opacity="1.000000"
          stroke="none"
          d="
M1021.002014,427.347168
	C1022.656860,428.021667 1023.983032,429.150421 1022.684998,430.295929
	C1021.603943,431.249817 1019.114136,431.510773 1018.275391,430.099976
	C1017.234619,428.349518 1019.178223,427.736359 1021.002014,427.347168
z"
        />
        <path
          fill="#E5EAEC"
          opacity="1.000000"
          stroke="none"
          d="
M585.443481,482.551636
	C583.710205,482.309418 581.546326,483.967346 581.074768,481.449127
	C580.833313,480.159943 582.231689,479.441681 583.387878,479.416748
	C585.281433,479.375854 586.614990,480.106934 585.443481,482.551636
z"
        />
        <path
          fill="#E5EAEC"
          opacity="1.000000"
          stroke="none"
          d="
M757.694702,430.721619
	C756.195374,430.276642 753.900940,431.859619 754.130859,429.184326
	C754.259338,427.689514 755.813293,427.074524 757.147583,427.423767
	C758.951172,427.895905 760.018433,428.964569 757.694702,430.721619
z"
        />
        <path
          fill="#E5EAEC"
          opacity="1.000000"
          stroke="none"
          d="
M1505.033203,429.300262
	C1506.382690,427.139984 1507.925903,426.703400 1509.630493,428.230286
	C1509.994385,428.556183 1510.152710,429.826141 1509.952393,429.954712
	C1508.274658,431.031586 1506.520752,431.504852 1505.033203,429.300262
z"
        />
        <path
          fill="#E5EAEC"
          opacity="1.000000"
          stroke="none"
          d="
M1529.832764,480.039124
	C1531.916016,478.941132 1533.500366,479.082947 1534.014404,481.173035
	C1534.466919,483.013580 1532.799194,482.732910 1531.753418,482.725708
	C1530.198975,482.714996 1527.950317,483.148560 1529.832764,480.039124
z"
        />
        <path
          fill="#E5EAEC"
          opacity="1.000000"
          stroke="none"
          d="
M1759.093506,429.302246
	C1758.846069,430.676880 1758.026611,430.817322 1757.205811,430.727295
	C1756.148315,430.611298 1754.841187,431.295441 1754.122803,430.006317
	C1753.940918,429.679932 1754.061401,428.989136 1754.304443,428.657288
	C1755.146729,427.507507 1756.312134,426.941559 1757.697754,427.590485
	C1758.233521,427.841400 1758.595947,428.462402 1759.093506,429.302246
z"
        />
        <path
          fill="#E5EAEC"
          opacity="1.000000"
          stroke="none"
          d="
M1845.733032,427.567566
	C1847.114868,428.736847 1848.095581,429.983398 1846.033691,430.597687
	C1844.844482,430.951965 1842.596802,431.570740 1842.179688,429.878357
	C1841.656982,427.757874 1843.616455,427.357605 1845.733032,427.567566
z"
        />
        <path
          fill="#E5EAEC"
          opacity="1.000000"
          stroke="none"
          d="
M1777.870850,428.571533
	C1777.634521,432.460846 1775.115234,430.279968 1773.560425,430.461426
	C1772.835571,430.545990 1772.829956,429.389069 1773.161743,428.898956
	C1774.386475,427.090271 1775.936646,426.763336 1777.870850,428.571533
z"
        />
        <path
          fill="#E5EAEC"
          opacity="1.000000"
          stroke="none"
          d="
M1408.308105,480.583405
	C1410.201172,479.130615 1411.836060,478.688934 1412.999146,480.903137
	C1413.290161,481.457062 1413.195068,482.539398 1412.481079,482.462708
	C1410.951294,482.298370 1408.424438,484.531219 1408.308105,480.583405
z"
        />
        <path
          fill="#E5EAEC"
          opacity="1.000000"
          stroke="none"
          d="
M1818.505371,479.650146
	C1820.760010,479.172302 1822.505249,479.736664 1821.917114,481.870911
	C1821.453369,483.553955 1819.197388,482.994324 1818.030518,482.595551
	C1816.019531,481.908264 1817.075562,480.726837 1818.505371,479.650146
z"
        />
        <path
          fill="#E5EAEC"
          opacity="1.000000"
          stroke="none"
          d="
M1081.093994,482.048889
	C1081.557739,479.433502 1083.009521,478.905426 1085.038208,479.755890
	C1085.864502,480.102325 1086.469238,481.108307 1086.052002,481.724548
	C1084.796387,483.579132 1082.922729,482.683655 1081.093994,482.048889
z"
        />
        <path
          fill="#E5EAEC"
          opacity="1.000000"
          stroke="none"
          d="
M1041.589600,508.552429
	C1040.661133,505.737488 1042.419312,505.318756 1044.347656,505.441528
	C1045.313110,505.502991 1046.351807,506.411194 1046.064209,507.470642
	C1045.418457,509.849518 1043.364868,508.371796 1041.589600,508.552429
z"
        />
        <path
          fill="#E5EAEC"
          opacity="1.000000"
          stroke="none"
          d="
M1480.093506,455.302216
	C1479.846069,456.676880 1479.026611,456.817352 1478.205811,456.727325
	C1477.148315,456.611328 1475.841064,457.295532 1475.122559,456.006470
	C1474.940552,455.680023 1475.061279,454.989105 1475.304443,454.657288
	C1476.146729,453.507538 1477.312134,452.941498 1478.697754,453.590454
	C1479.233521,453.841339 1479.595947,454.462372 1480.093506,455.302216
z"
        />
        <path
          fill="#E5EAEC"
          opacity="1.000000"
          stroke="none"
          d="
M1155.093506,481.302246
	C1154.846069,482.676880 1154.026611,482.817352 1153.205811,482.727325
	C1152.148315,482.611328 1150.841064,483.295532 1150.122559,482.006439
	C1149.940674,481.679993 1150.061401,480.989136 1150.304443,480.657318
	C1151.146851,479.507568 1152.312134,478.941528 1153.697754,479.590454
	C1154.233521,479.841370 1154.595947,480.462402 1155.093506,481.302246
z"
        />
        <path
          fill="#E5EAEC"
          opacity="1.000000"
          stroke="none"
          d="
M676.282837,482.779968
	C674.813538,482.792664 673.783569,482.572144 674.451111,481.175293
	C675.140442,479.732880 676.413208,478.943329 678.060547,479.458221
	C679.015686,479.756714 679.899963,480.555145 679.766479,481.598663
	C679.498840,483.689789 677.712402,482.404205 676.282837,482.779968
z"
        />
        <path
          fill="#E5EAEC"
          opacity="1.000000"
          stroke="none"
          d="
M1853.705811,479.795044
	C1855.709229,478.823395 1856.880127,479.513611 1857.653076,480.982819
	C1857.924927,481.499603 1858.061401,482.483917 1857.237671,482.559814
	C1855.652344,482.705933 1853.898926,482.905151 1852.493286,482.362183
	C1851.750977,482.075409 1852.632446,480.659088 1853.705811,479.795044
z"
        />
        <path
          fill="#E5EAEC"
          opacity="1.000000"
          stroke="none"
          d="
M1895.032471,456.782104
	C1893.818726,456.584656 1892.357544,457.277191 1892.347412,455.878662
	C1892.335083,454.162415 1893.752441,453.368225 1895.340942,453.328308
	C1896.591309,453.296875 1897.559692,454.115997 1897.733032,455.348999
	C1898.011597,457.328461 1896.364380,456.572815 1895.032471,456.782104
z"
        />
        <path
          fill="#E5EAEC"
          opacity="1.000000"
          stroke="none"
          d="
M978.080322,427.303467
	C979.827393,427.635803 981.370544,428.675201 980.650391,429.852631
	C979.493164,431.744843 977.239197,430.525543 975.453735,430.679840
	C974.993896,428.581909 976.315857,427.931335 978.080322,427.303467
z"
        />
        <path
          fill="#E5EAEC"
          opacity="1.000000"
          stroke="none"
          d="
M767.032410,456.782104
	C765.818726,456.584656 764.357727,457.277222 764.347656,455.878632
	C764.335388,454.162384 765.752380,453.368225 767.341003,453.328308
	C768.591553,453.296875 769.559753,454.116028 769.733215,455.349030
	C770.011658,457.328644 768.364319,456.572693 767.032410,456.782104
z"
        />
        <path
          fill="#E5EAEC"
          opacity="1.000000"
          stroke="none"
          d="
M606.344116,455.455566
	C607.184021,454.513550 607.852661,453.653534 608.702820,453.398285
	C610.159241,452.961060 611.419434,453.796936 611.683655,455.195038
	C612.131714,457.565552 610.101501,456.706970 609.074768,456.713867
	C608.133057,456.720215 606.716919,457.520111 606.344116,455.455566
z"
        />
        <path
          fill="#E5EAEC"
          opacity="1.000000"
          stroke="none"
          d="
M1905.345459,482.181824
	C1905.425903,480.760345 1906.083984,480.095764 1907.001099,479.631531
	C1908.166626,479.041565 1909.248047,479.187775 1910.069824,480.181702
	C1910.446411,480.637177 1910.862793,481.405151 1910.726318,481.876190
	C1910.270996,483.446075 1908.848267,482.619324 1907.844971,482.746490
	C1907.063354,482.845612 1906.241211,482.625092 1905.345459,482.181824
z"
        />
        <path
          fill="#E5EAEC"
          opacity="1.000000"
          stroke="none"
          d="
M1988.060547,428.115326
	C1989.792114,431.945007 1986.860352,430.552490 1985.537231,430.727081
	C1984.750732,430.830872 1983.064819,431.170166 1983.367432,429.781738
	C1983.859985,427.521484 1985.502930,426.616394 1988.060547,428.115326
z"
        />
        <path
          fill="#E5EAEC"
          opacity="1.000000"
          stroke="none"
          d="
M1430.060547,428.115326
	C1431.792114,431.945007 1428.860352,430.552490 1427.537231,430.727051
	C1426.750732,430.830841 1425.064941,431.170197 1425.367554,429.781769
	C1425.860107,427.521515 1427.502930,426.616394 1430.060547,428.115326
z"
        />
        <path
          fill="#E5EAEC"
          opacity="1.000000"
          stroke="none"
          d="
M596.378418,505.469086
	C598.466370,505.176483 599.551147,505.985077 599.795593,507.698334
	C599.905334,508.466797 599.238342,508.813904 598.520142,508.738708
	C597.136108,508.593842 595.344482,509.513794 594.465576,508.191833
	C593.724426,507.077057 595.028015,506.201202 596.378418,505.469086
z"
        />
        <path
          fill="#E5EAEC"
          opacity="1.000000"
          stroke="none"
          d="
M749.777954,507.393372
	C749.721008,508.561859 749.158508,508.814423 748.466614,508.746460
	C747.093262,508.611450 745.468323,509.401367 744.417786,508.156189
	C743.861450,507.496826 744.614441,506.601013 745.267822,506.106812
	C747.066162,504.746704 748.577759,505.064972 749.777954,507.393372
z"
        />
        <path
          fill="#E5EAEC"
          opacity="1.000000"
          stroke="none"
          d="
M625.794800,435.273560
	C625.936829,432.157806 627.762024,432.347809 629.645691,432.839020
	C630.580200,433.082733 631.048340,433.962830 630.538879,434.745453
	C629.378845,436.527740 627.772156,436.914276 625.794800,435.273560
z"
        />
        <path
          fill="#E5EAEC"
          opacity="1.000000"
          stroke="none"
          d="
M706.722839,436.332764
	C704.184570,435.791290 704.116882,434.407745 705.451782,433.157349
	C706.640259,432.043976 708.604858,432.082886 709.371399,433.471924
	C710.421326,435.374695 708.628235,435.927063 706.722839,436.332764
z"
        />
        <path
          fill="#E5EAEC"
          opacity="1.000000"
          stroke="none"
          d="
M539.225891,513.911987
	C538.196167,510.976990 539.703674,510.433685 541.874207,510.636932
	C542.688965,510.713226 543.747864,511.017975 543.636169,511.983551
	C543.342468,514.522766 541.420105,514.396851 539.225891,513.911987
z"
        />
        <path
          fill="#E5EAEC"
          opacity="1.000000"
          stroke="none"
          d="
M555.722839,436.332764
	C553.184570,435.791290 553.116882,434.407745 554.451782,433.157349
	C555.640259,432.043976 557.604858,432.082886 558.371399,433.471954
	C559.421326,435.374695 557.628235,435.927063 555.722839,436.332764
z"
        />
        <path
          fill="#E5EAEC"
          opacity="1.000000"
          stroke="none"
          d="
M731.537109,459.546844
	C731.348633,462.457092 729.496094,462.502075 727.622131,462.139587
	C726.704102,461.962006 726.407593,460.888245 726.769348,460.113556
	C727.841064,457.818939 729.614685,458.398438 731.537109,459.546844
z"
        />
        <path
          fill="#E5EAEC"
          opacity="1.000000"
          stroke="none"
          d="
M1310.029419,459.012024
	C1311.256470,462.039764 1309.149048,462.167816 1307.364014,462.311829
	C1306.239624,462.402588 1305.450562,461.574371 1305.735596,460.379517
	C1306.285278,458.075073 1308.017578,458.446991 1310.029419,459.012024
z"
        />
        <path
          fill="#E5EAEC"
          opacity="1.000000"
          stroke="none"
          d="
M1048.225830,487.911926
	C1047.196289,484.977112 1048.703613,484.433563 1050.874268,484.636902
	C1051.688965,484.713257 1052.747681,485.018188 1052.635986,485.983551
	C1052.342407,488.522644 1050.420288,488.396912 1048.225830,487.911926
z"
        />
        <path
          fill="#E5EAEC"
          opacity="1.000000"
          stroke="none"
          d="
M1130.029419,485.012024
	C1131.256470,488.039764 1129.149048,488.167816 1127.364014,488.311829
	C1126.239624,488.402557 1125.450439,487.574341 1125.735474,486.379517
	C1126.285156,484.075043 1128.017578,484.446991 1130.029419,485.012024
z"
        />
        <path
          fill="#E5EAEC"
          opacity="1.000000"
          stroke="none"
          d="
M892.722839,488.332764
	C890.184570,487.791290 890.116882,486.407715 891.451721,485.157318
	C892.640259,484.043976 894.604858,484.082886 895.371338,485.471954
	C896.421326,487.374725 894.628235,487.927063 892.722839,488.332764
z"
        />
        <path
          fill="#E5EAEC"
          opacity="1.000000"
          stroke="none"
          d="
M1944.447021,435.000275
	C1942.821777,436.280243 1941.270264,437.172668 1939.912964,435.461578
	C1939.277466,434.660431 1939.560547,433.372803 1940.664185,432.978882
	C1942.381714,432.365845 1944.280396,431.847870 1944.447021,435.000275
z"
        />
        <path
          fill="#E5EAEC"
          opacity="1.000000"
          stroke="none"
          d="
M1885.722900,436.332764
	C1883.184692,435.791351 1883.116943,434.407806 1884.451660,433.157410
	C1885.640137,432.043915 1887.604492,432.083099 1888.370972,433.471985
	C1889.421143,435.374603 1887.628418,435.927002 1885.722900,436.332764
z"
        />
        <path
          fill="#E5EAEC"
          opacity="1.000000"
          stroke="none"
          d="
M1385.697510,486.490051
	C1386.897583,483.868256 1388.679810,484.257782 1390.317993,485.259460
	C1391.036499,485.698822 1390.868164,486.902924 1390.104370,487.396301
	C1388.559937,488.393768 1386.873413,489.356812 1385.697510,486.490051
z"
        />
        <path
          fill="#E5EAEC"
          opacity="1.000000"
          stroke="none"
          d="
M1506.062134,484.748047
	C1507.817993,484.701599 1509.867676,484.200836 1509.560181,486.504395
	C1509.322876,488.282135 1507.423340,488.669250 1506.003662,488.197784
	C1503.946899,487.514679 1504.298096,486.147369 1506.062134,484.748047
z"
        />
        <path
          fill="#E5EAEC"
          opacity="1.000000"
          stroke="none"
          d="
M1431.537109,485.546875
	C1431.348511,488.457397 1429.495850,488.501923 1427.622070,488.139526
	C1426.703979,487.961975 1426.407593,486.888031 1426.769409,486.113495
	C1427.841064,483.819061 1429.614746,484.398438 1431.537109,485.546875
z"
        />
        <path
          fill="#E5EAEC"
          opacity="1.000000"
          stroke="none"
          d="
M984.035645,483.110291
	C984.629822,480.157776 986.072449,478.793762 988.677612,479.759735
	C991.268555,480.720428 990.930420,483.147858 990.781311,485.248871
	C990.650269,487.094940 989.495239,488.469208 987.608704,488.408813
	C984.578552,488.311798 983.998901,486.057159 984.035645,483.110291
z"
        />
        <path
          fill="#E5EAEC"
          opacity="1.000000"
          stroke="none"
          d="
M741.833496,483.134277
	C741.867371,486.281860 741.340759,488.840149 737.859070,488.262665
	C734.871887,487.767181 734.868652,485.142853 735.095520,482.758331
	C735.245911,481.178253 736.003906,479.822021 737.686096,479.541870
	C740.077454,479.143646 741.396790,480.408661 741.833496,483.134277
z"
        />
        <path
          fill="#E5EAEC"
          opacity="1.000000"
          stroke="none"
          d="
M659.702148,436.157135
	C655.082397,436.599670 654.886047,433.754608 655.107544,430.754456
	C655.239807,428.962158 656.152954,427.568604 658.158081,427.453156
	C660.379700,427.325256 661.543762,428.631104 661.768188,430.692505
	C661.982178,432.658844 662.146484,434.710938 659.702148,436.157135
z"
        />
        <path
          fill="#E5EAEC"
          opacity="1.000000"
          stroke="none"
          d="
M1337.560791,479.649628
	C1338.441528,480.380554 1339.258911,480.909943 1339.428345,481.598816
	C1340.071167,484.209900 1340.715210,487.117981 1337.274292,488.239075
	C1334.914551,489.007874 1333.404297,487.469513 1333.148315,485.186584
	C1332.817139,482.232208 1332.884521,479.279877 1337.560791,479.649628
z"
        />
        <path
          fill="#E5EAEC"
          opacity="1.000000"
          stroke="none"
          d="
M1535.622070,455.075226
	C1537.729492,452.766876 1539.743408,452.790405 1541.149780,455.058319
	C1542.442627,457.143097 1542.519897,459.801666 1540.674194,461.519897
	C1538.790894,463.273163 1536.613525,462.770111 1535.429932,460.263092
	C1534.694214,458.704742 1534.867920,457.026306 1535.622070,455.075226
z"
        />
        <path
          fill="#E5EAEC"
          opacity="1.000000"
          stroke="none"
          d="
M693.631592,505.940491
	C695.711731,505.142426 697.291748,505.278137 698.216675,507.074768
	C699.177490,508.941040 699.153320,510.895508 698.258362,512.817749
	C697.690247,514.037781 696.559937,514.458191 695.402161,514.424500
	C694.002930,514.383850 692.842285,513.649780 692.389038,512.220520
	C691.703918,510.059967 691.728088,507.968353 693.631592,505.940491
z"
        />
        <path
          fill="#E5EAEC"
          opacity="1.000000"
          stroke="none"
          d="
M1076.907471,462.397400
	C1073.217651,461.948669 1072.824707,459.585968 1073.067627,456.913177
	C1073.229614,455.130402 1074.021729,453.676575 1075.998779,453.470154
	C1077.606567,453.302277 1079.043945,453.857666 1079.454102,455.594299
	C1080.071167,458.205566 1080.644775,460.904755 1076.907471,462.397400
z"
        />
        <path
          fill="#F79F38"
          opacity="1.000000"
          stroke="none"
          d="
M260.705750,298.380554
	C261.108551,294.000946 261.395721,290.009308 261.279419,286.029480
	C261.180450,282.643372 262.337036,281.195740 265.879730,281.277466
	C273.374237,281.450348 280.877197,281.422546 288.373260,281.286926
	C291.668976,281.227325 292.963348,282.625671 292.675171,285.777740
	C292.644958,286.108185 292.643982,286.446716 292.676849,286.776733
	C293.033722,290.357025 291.009308,295.908081 293.576324,297.136139
	C298.351746,299.420746 304.313416,297.827179 309.780609,297.852051
	C310.796692,297.856689 311.187103,297.103577 311.253601,296.198883
	C311.314545,295.369171 311.306091,294.534241 311.323700,293.701508
	C311.584991,281.333832 311.586151,281.226105 324.197510,281.380890
	C328.965546,281.439423 335.036774,279.264252 338.154663,282.509521
	C341.016205,285.487946 338.895142,291.423187 339.010376,296.050293
	C339.163818,302.211914 338.875061,308.386963 339.114380,314.543091
	C339.252075,318.084808 337.982513,319.131500 334.518341,319.122864
	C325.911072,319.101379 325.910645,319.254578 325.910645,327.667694
	C325.910614,357.661713 325.821472,387.656281 326.003479,417.649200
	C326.031860,422.328705 324.869141,424.081116 319.898621,424.011658
	C300.572968,423.741486 281.241699,423.873199 261.450562,423.769714
	C259.632629,422.340607 259.715332,420.816864 260.736877,418.852234
	C263.432861,416.915131 266.152283,417.179016 268.792450,417.170746
	C282.771576,417.127075 296.750885,417.165833 310.730133,417.146210
	C319.129608,417.134399 319.145081,417.117035 319.147766,408.504761
	C319.156342,381.211945 319.149872,353.919128 319.152374,326.626312
	C319.152557,324.629486 319.095612,322.629608 319.242554,320.637085
	C319.660614,314.968048 321.539001,312.991516 327.148193,312.256256
	C333.028687,311.485474 333.061768,311.485870 333.131470,305.571808
	C333.182434,301.245697 333.086639,296.916931 333.137421,292.592224
	C333.177673,289.165863 331.623199,287.702972 328.299194,287.895142
	C327.968384,287.914307 327.634216,287.873291 327.301422,287.868896
	C316.265167,287.723480 318.060760,288.371857 317.787140,296.437012
	C317.567505,302.911713 315.831512,304.594818 309.264954,304.791901
	C304.275635,304.941681 299.276794,304.985138 294.289886,304.765564
	C288.369232,304.504822 286.428986,302.726654 286.265472,296.904114
	C286.014984,287.985565 287.179169,287.821655 276.620331,287.849182
	C275.788239,287.851349 274.955963,287.841522 274.124084,287.857849
	C268.213959,287.973724 268.048676,288.134888 267.855225,294.105438
	C267.795959,295.934021 267.925903,297.790863 267.455383,299.570343
	C266.561890,302.949493 264.842621,305.018036 260.679504,302.849670
	C259.749329,301.242371 259.778168,300.004364 260.705750,298.380554
z"
        />
        <path
          fill="#F27643"
          opacity="1.000000"
          stroke="none"
          d="
M260.528320,297.983276
	C261.000732,299.061462 261.005127,300.135437 261.003845,301.612152
	C259.692780,303.624908 257.813629,303.665558 255.996002,303.780212
	C253.504349,303.937347 251.005508,303.863220 248.509735,303.859314
	C238.945755,303.844360 235.691086,304.448456 236.088638,292.508575
	C236.205124,289.010223 234.433548,287.997528 231.189880,287.849365
	C220.954636,287.381683 220.867493,287.369324 220.866470,297.614441
	C220.866135,300.942078 220.874695,304.269714 220.911972,307.597626
	C220.942795,310.349396 221.928711,311.758026 224.922012,312.141815
	C232.884628,313.162750 233.812683,314.260406 233.833496,322.241882
	C233.905533,349.864380 233.854919,377.487091 233.863083,405.109711
	C233.863678,407.106476 233.828659,409.103973 233.892059,411.099854
	C234.067261,416.615570 234.400497,416.973663 239.987961,417.104218
	C244.645767,417.213104 249.306656,417.080994 253.965012,417.165527
	C256.278992,417.207520 258.662170,417.059692 260.813599,419.089722
	C260.990814,420.675873 260.992767,421.833191 260.991638,423.337341
	C251.163086,423.749939 241.336472,423.754486 231.512772,423.925110
	C229.095413,423.967072 228.174347,422.944031 228.137314,420.737946
	C228.114929,419.405426 228.117096,418.072449 228.117050,416.739655
	C228.116013,385.917725 228.043961,355.095398 228.205444,324.274292
	C228.226212,320.312134 227.535004,318.766907 223.137558,318.911469
	C213.922073,319.214355 215.025101,320.148376 214.978500,310.697876
	C214.937424,302.367798 215.094910,294.034607 214.908066,285.708557
	C214.835007,282.452606 215.914658,281.236206 219.180008,281.320160
	C225.506836,281.482941 231.842880,281.466644 238.170883,281.329712
	C241.309174,281.261810 242.604187,282.321045 242.716034,285.626526
	C242.852676,289.665161 240.673935,295.068420 243.872070,297.375885
	C246.529709,299.293335 251.574463,297.909271 255.562042,297.972717
	C257.061127,297.996582 258.560883,297.978210 260.528320,297.983276
z"
        />
        <path
          fill="#2F3A48"
          opacity="1.000000"
          stroke="none"
          d="
M198.242172,473.850647
	C198.775879,470.652649 199.302948,467.873077 199.689194,465.074097
	C199.939346,463.261353 201.068909,463.017822 202.535446,463.172211
	C204.451538,463.373901 203.868378,464.697205 203.638031,465.776062
	C202.492813,471.139954 201.186859,476.473419 200.205673,481.866394
	C199.823914,483.964600 198.680923,483.982605 197.082077,484.059296
	C195.280182,484.145660 194.885574,483.237457 194.678116,481.708130
	C194.285583,478.814117 193.702911,475.945892 192.197128,473.046417
	C191.787308,475.693207 191.282776,478.329956 190.999893,480.990265
	C190.800903,482.861664 190.414978,484.077667 188.092682,484.087982
	C186.075653,484.096954 185.500885,483.030304 185.169556,481.460388
	C184.105576,476.418610 182.996872,471.384827 182.048218,466.321228
	C181.847839,465.251648 180.801697,463.559357 183.063217,463.173096
	C185.267334,462.796600 185.890976,463.988770 186.129715,465.983917
	C186.518295,469.231232 187.194931,472.444061 188.700653,475.692719
	C189.179352,472.609467 189.755219,469.537079 190.100830,466.438995
	C190.312820,464.538666 191.179413,463.963684 193.014359,463.926544
	C194.829559,463.889832 195.244598,464.806580 195.465469,466.285797
	C195.976776,469.709869 196.573593,473.121124 197.358200,477.889587
	C197.835770,475.769989 198.005783,475.015442 198.242172,473.850647
z"
        />
        <path
          fill="#2F3A48"
          opacity="1.000000"
          stroke="none"
          d="
M236.630280,472.268005
	C237.269180,473.590118 237.728622,474.573914 238.188049,475.557678
	C238.450638,475.484497 238.713226,475.411285 238.975815,475.338074
	C238.975815,472.433350 238.874680,469.523407 239.023087,466.626282
	C239.086884,465.381012 238.091415,463.353149 240.557205,463.204407
	C243.020859,463.055756 242.454590,465.023743 242.471130,466.383911
	C242.529785,471.209961 242.523575,476.037415 242.475525,480.863678
	C242.461914,482.230682 243.026489,483.987000 240.554001,484.052277
	C238.682526,484.101654 237.499420,483.686737 236.774826,481.707458
	C235.597092,478.490387 234.053528,475.407257 231.491547,472.342072
	C231.491547,475.320709 231.508545,478.299469 231.483704,481.277893
	C231.472839,482.581390 231.713379,484.055481 229.605011,484.016357
	C227.350052,483.974457 228.017166,482.303070 228.002808,481.153381
	C227.940460,476.161011 227.929276,471.166595 228.011841,466.174774
	C228.030609,465.039490 227.303909,463.291077 229.515503,463.171265
	C231.182022,463.080963 232.794693,462.909332 233.549332,465.050354
	C234.375168,467.393250 235.472061,469.640656 236.630280,472.268005
z"
        />
        <path
          fill="#2F3A48"
          opacity="1.000000"
          stroke="none"
          d="
M176.968872,467.940918
	C178.234650,472.641449 179.466354,476.937744 180.588196,481.262512
	C180.826263,482.180267 181.932495,483.666779 179.925400,483.933807
	C178.575974,484.113312 176.779465,484.636627 176.309784,482.446869
	C175.935303,480.700958 176.013092,478.912170 173.238327,478.936127
	C170.690659,478.958130 170.183670,480.205933 169.993912,482.242401
	C169.781128,484.525940 167.963730,484.170898 166.588562,483.922028
	C164.080582,483.468262 165.875061,481.739655 166.085938,480.861877
	C167.167755,476.358917 168.487610,471.911499 169.789520,467.463745
	C170.348572,465.553833 170.003021,463.083252 173.337631,463.075500
	C176.765060,463.067505 175.851761,465.966522 176.968872,467.940918
z"
        />
        <path
          fill="#2F3A48"
          opacity="1.000000"
          stroke="none"
          d="
M301.895599,463.146545
	C303.669281,463.148834 305.000366,463.192017 306.327850,463.141602
	C307.575684,463.094238 308.178070,463.639771 308.161560,464.873993
	C308.143951,466.189270 307.280914,466.328247 306.193329,466.532440
	C304.381805,466.872498 301.543823,465.172577 301.228851,468.834900
	C300.919159,472.435974 303.545044,471.696075 305.571075,471.794891
	C306.640839,471.847015 306.939392,472.414124 307.005524,473.367279
	C307.101196,474.746948 306.303589,475.033691 305.183594,475.016541
	C303.020782,474.983429 300.898254,474.606415 301.148590,478.139404
	C301.412384,481.863007 303.986542,480.542328 305.861877,480.753784
	C307.063721,480.889313 308.202484,480.769318 308.202942,482.507904
	C308.203461,484.449188 306.806366,483.928619 305.747528,484.021423
	C296.975800,484.790344 296.975647,484.788361 296.975616,476.277832
	C296.975616,473.286407 297.234894,470.266541 296.910492,467.310730
	C296.498352,463.555542 298.190918,462.628082 301.895599,463.146545
z"
        />
        <path
          fill="#2F3A48"
          opacity="1.000000"
          stroke="none"
          d="
M214.389496,462.978638
	C216.231033,463.331635 217.317551,464.030212 216.960739,465.712708
	C216.520615,467.788269 215.141266,466.258118 214.218307,466.258087
	C212.560867,466.258057 210.706650,465.856079 210.147568,468.017395
	C209.601776,470.127380 211.401062,470.823975 212.794296,471.674316
	C213.641418,472.191345 214.584030,472.577332 215.359787,473.181366
	C218.482986,475.613159 218.983704,478.638458 216.864777,481.906067
	C215.210648,484.456787 209.114548,485.396545 206.850601,483.654419
	C206.121567,483.093414 205.864838,482.356201 206.051239,481.472595
	C206.400314,479.817871 207.516907,480.772675 208.363556,480.805328
	C210.266052,480.878662 212.645248,481.834503 213.451813,479.197327
	C214.241241,476.616119 211.886765,475.844604 210.181412,474.974640
	C206.839447,473.269714 204.927719,470.776306 206.018600,466.970245
	C207.172836,462.943146 210.457291,462.526611 214.389496,462.978638
z"
        />
        <path
          fill="#2F3A48"
          opacity="1.000000"
          stroke="none"
          d="
M321.420776,468.539520
	C325.211151,473.176605 322.922485,478.266174 323.297119,483.055450
	C323.378479,484.095642 322.151306,483.975159 321.345795,484.009430
	C320.127899,484.061218 319.521606,483.505432 319.526398,482.258270
	C319.535370,479.930145 319.565826,477.599884 319.481018,475.274384
	C319.430084,473.877472 319.928497,471.863159 317.790619,471.748474
	C315.441040,471.622375 316.093994,473.701782 316.045441,475.059631
	C315.968170,477.218964 315.995605,479.383545 316.032349,481.545013
	C316.056671,482.976959 316.041046,484.107513 314.010559,484.072998
	C312.224670,484.042633 311.657898,483.362000 311.770905,481.705902
	C311.850006,480.547211 311.783264,479.378571 311.782806,478.214386
	C311.779236,469.306885 311.779327,469.306885 321.420776,468.539520
z"
        />
        <path
          fill="#2F3A48"
          opacity="1.000000"
          stroke="none"
          d="
M285.887573,481.525757
	C283.735107,484.570038 280.993744,485.075470 278.205414,483.772797
	C274.593048,482.085175 274.553925,478.549683 274.707825,475.172974
	C274.843597,472.194611 275.728058,469.561615 278.981964,468.589264
	C282.382233,467.573120 284.877808,468.795715 286.239288,471.998291
	C287.529633,475.033508 287.406128,478.142426 285.887573,481.525757
z"
        />
        <path
          fill="#2F3A48"
          opacity="1.000000"
          stroke="none"
          d="
M350.737183,484.202942
	C347.470306,483.087555 347.539307,480.694397 347.947968,478.363129
	C348.342255,476.114014 350.022186,475.173065 352.230743,474.971985
	C353.335144,474.871490 355.234222,475.697540 354.850220,473.312286
	C354.602753,471.775116 353.601166,471.236023 351.989258,471.320404
	C350.861633,471.379456 348.830780,473.290344 348.689850,470.479218
	C348.586548,468.418427 350.783600,468.639709 352.089874,468.389771
	C356.049438,467.632019 358.529053,469.133911 358.823090,473.416260
	C358.857056,473.910797 358.863007,474.409058 358.849762,474.904724
	C358.775146,477.701447 359.590607,481.029388 358.345154,483.138367
	C357.201233,485.075378 353.547394,483.547089 350.737183,484.202942
z"
        />
        <path
          fill="#2F3A48"
          opacity="1.000000"
          stroke="none"
          d="
M375.543854,480.072357
	C374.759430,476.864685 374.647217,474.025879 376.062836,471.411804
	C377.609924,468.554932 380.071533,467.459442 383.185028,468.680298
	C386.582428,470.012482 386.459320,473.050995 386.101257,475.881561
	C385.807678,478.202545 383.698639,476.824127 382.442444,477.225037
	C381.258484,477.602875 379.038727,476.163055 379.155243,478.834503
	C379.237366,480.717865 380.802948,480.930206 382.394470,481.210022
	C383.577820,481.418030 385.823334,479.652832 385.850677,482.249451
	C385.875427,484.602936 383.435150,484.160156 381.938904,484.279968
	C379.039703,484.512085 376.718719,483.443451 375.543854,480.072357
z"
        />
        <path
          fill="#2F3A48"
          opacity="1.000000"
          stroke="none"
          d="
M368.475708,470.707520
	C369.104889,468.161011 370.915314,468.500275 372.160583,469.000671
	C373.785919,469.653809 372.442291,470.994934 372.179688,471.955200
	C371.260803,475.315552 370.148804,478.623322 369.239349,481.986023
	C368.891083,483.273590 368.412903,484.001373 366.949188,484.048584
	C365.368896,484.099579 364.488678,483.550140 364.055725,481.998169
	C363.074982,478.482513 361.934357,475.010895 360.985321,471.487274
	C360.746277,470.599731 359.567261,469.102753 361.712524,468.858215
	C362.980225,468.713715 364.472992,468.433350 364.863617,470.350830
	C365.292480,472.456207 365.763214,474.553009 366.313202,477.106171
	C368.163544,475.181854 367.836731,472.967255 368.475708,470.707520
z"
        />
        <path
          fill="#2F3A48"
          opacity="1.000000"
          stroke="none"
          d="
M343.495117,465.585205
	C344.284698,471.380463 341.755890,477.064270 345.129089,482.202332
	C345.498505,482.765015 345.208252,483.845978 344.080048,484.037811
	C342.546600,484.298615 341.080963,484.282532 340.047455,482.893890
	C338.984711,481.465942 339.143066,479.774994 339.144928,478.125763
	C339.149750,473.833130 339.115143,469.540131 339.163666,465.248047
	C339.180695,463.741608 338.484985,461.802063 341.256927,461.761261
	C343.976257,461.721252 343.447784,463.563995 343.495117,465.585205
z"
        />
        <path
          fill="#2F3A48"
          opacity="1.000000"
          stroke="none"
          d="
M259.024597,476.799561
	C259.131042,478.336121 258.718536,479.579712 259.694397,480.501129
	C260.508301,481.269501 262.617401,480.589355 262.162048,482.801361
	C261.878021,484.180939 260.805695,484.044739 259.868469,484.150818
	C258.115753,484.349152 256.570465,483.987396 255.560516,482.351227
	C254.712708,480.977753 255.177994,479.436401 254.948471,477.989685
	C254.586426,475.707733 255.473831,473.490601 253.683685,471.038422
	C252.481384,469.391418 255.285446,466.724976 256.647430,464.747253
	C257.038300,464.179718 258.674103,464.201630 258.911255,465.597321
	C259.178192,467.168213 258.743500,468.788239 261.340820,469.034851
	C262.793579,469.172760 262.381073,471.554413 261.458923,471.620209
	C257.644714,471.892456 259.455170,474.638733 259.024597,476.799561
z"
        />
        <path
          fill="#2F3A48"
          opacity="1.000000"
          stroke="none"
          d="
M331.440308,468.614136
	C332.939819,468.638428 334.098816,468.652344 335.218231,468.869751
	C335.920135,469.006042 336.187866,469.660400 336.165833,470.396912
	C336.136292,471.385681 335.787415,471.856628 334.671936,471.717682
	C330.868805,471.243958 330.841309,473.865814 330.855042,476.488098
	C330.867859,478.936615 330.947296,481.346802 334.447540,481.001770
	C335.679962,480.880310 336.321228,481.241211 336.135834,482.612823
	C335.952362,483.969940 334.899231,484.002716 333.935455,484.125153
	C331.505707,484.433777 329.161835,484.153198 327.877228,481.790588
	C325.932220,478.213562 326.033844,474.522095 327.963226,470.929596
	C328.620239,469.706238 329.711853,468.975342 331.440308,468.614136
z"
        />
        <path
          fill="#2F3A48"
          opacity="1.000000"
          stroke="none"
          d="
M264.787109,473.175964
	C265.125366,471.408783 264.208252,468.651031 265.916656,469.109131
	C268.398254,469.774536 270.661072,468.030304 272.787994,469.179657
	C273.545502,469.588989 273.490204,471.642120 272.688965,471.718475
	C266.740204,472.285400 269.698486,476.785187 269.060760,479.634216
	C268.812836,480.741821 269.152557,481.981445 268.901733,483.087769
	C268.602661,484.407135 267.337585,483.946320 266.415344,483.998932
	C265.060883,484.076202 264.799500,483.181244 264.798218,482.087341
	C264.794983,479.274261 264.790833,476.461212 264.787109,473.175964
z"
        />
        <path
          fill="#2F3A48"
          opacity="1.000000"
          stroke="none"
          d="
M246.787033,475.996124
	C247.715347,473.330536 244.556015,468.949951 249.096588,469.043671
	C253.110001,469.126495 250.438416,473.188782 250.927322,475.389130
	C251.381210,477.431854 250.948410,479.658600 251.043030,481.798248
	C251.119232,483.521332 250.426086,484.097412 248.736740,484.051300
	C247.195892,484.009216 246.757095,483.297852 246.786194,481.924072
	C246.824600,480.110901 246.790161,478.296143 246.787033,475.996124
z"
        />
        <path
          fill="#46505D"
          opacity="1.000000"
          stroke="none"
          d="
M246.560883,464.528198
	C246.595551,461.602417 248.230255,461.250671 250.228561,462.000946
	C251.577271,462.507324 251.645233,463.993500 250.982056,464.934570
	C249.648331,466.827179 248.056854,466.761841 246.560883,464.528198
z"
        />
        <path
          fill="#FFFFFF"
          opacity="1.000000"
          stroke="none"
          d="
M261.453583,419.455750
	C253.329956,419.469055 245.660339,419.158264 238.016281,419.471313
	C233.654007,419.649963 232.337128,418.402679 232.364166,413.943787
	C232.551041,383.135620 232.464081,352.325775 232.460983,321.516449
	C232.460297,314.729675 232.452301,314.769348 225.629288,314.707825
	C218.090302,314.639832 219.111816,315.605042 219.042007,308.036987
	C218.983612,301.709137 219.108322,295.378906 218.992462,289.052643
	C218.949966,286.732086 219.646179,285.593658 222.084259,285.876587
	C222.741333,285.952820 223.415283,285.875946 224.081299,285.884186
	C228.407333,285.937714 233.732681,284.462677 236.793915,286.458679
	C240.239075,288.705048 237.796478,294.416016 238.038925,298.596375
	C238.208878,301.526703 239.456131,302.267548 242.084198,302.218140
	C248.077515,302.105438 254.074005,302.162750 260.533691,302.082916
	C264.869934,303.125916 265.945099,301.442505 265.970856,297.707092
	C266.052155,285.903992 266.161682,285.904755 277.874237,285.907318
	C288.080078,285.909576 288.347900,285.919373 287.980530,295.951813
	C287.797852,300.940521 289.098175,302.847107 294.324585,302.429077
	C300.118683,301.965637 305.980621,302.221039 311.808289,302.360809
	C314.773621,302.431915 315.966827,301.412567 315.977081,298.331726
	C315.990204,294.384949 314.151276,289.126923 316.719940,286.809204
	C319.657684,284.158417 324.997406,286.365082 329.246124,285.866302
	C333.643921,285.350006 335.416229,286.650879 335.065186,291.444855
	C334.604095,297.742004 334.820496,304.102173 335.007141,310.427521
	C335.099213,313.547852 334.312347,314.765717 330.883270,314.766541
	C321.327728,314.768829 321.327759,314.941193 321.327728,324.377960
	C321.327667,354.199615 321.236542,384.021881 321.427795,413.842316
	C321.455780,418.210999 320.359100,419.495880 315.894470,419.435913
	C297.905457,419.194397 279.910858,419.370941 261.453583,419.455750
z"
        />
        <path
          fill="#E5EAEC"
          opacity="1.000000"
          stroke="none"
          d="
M174.298111,472.809601
	C174.708176,474.543671 175.558792,476.182495 172.887878,476.013184
	C170.913773,475.888031 171.578400,474.595612 171.833069,473.582397
	C172.167038,472.253632 171.696075,470.562103 173.597473,469.661407
	C173.815582,470.653351 174.013794,471.554810 174.298111,472.809601
z"
        />
        <path
          fill="#F4F4F4"
          opacity="1.000000"
          stroke="none"
          d="
M282.975952,477.003906
	C282.602203,478.924591 282.870422,481.247253 280.571503,480.950531
	C278.473572,480.679718 278.981476,478.442596 278.832703,476.882446
	C278.770752,476.233215 278.763458,475.564636 278.843994,474.918884
	C279.019562,473.511383 278.828949,471.628326 280.911316,471.673981
	C282.571777,471.710419 282.670685,473.339325 282.856659,474.632385
	C282.949554,475.278229 282.950836,475.937225 282.975952,477.003906
z"
        />
        <path
          fill="#C3CDCE"
          opacity="1.000000"
          stroke="none"
          d="
M352.567780,481.429993
	C351.008636,479.896698 351.088257,478.589111 352.682434,477.574799
	C353.401886,477.117035 354.501892,477.027313 354.825073,477.950806
	C355.436798,479.699066 355.260590,481.290894 352.567780,481.429993
z"
        />
        <path
          fill="#E5EAEC"
          opacity="1.000000"
          stroke="none"
          d="
M379.236694,472.464386
	C380.466248,470.839233 381.553131,470.558716 382.481201,472.211731
	C383.006042,473.146606 383.084198,474.315979 381.869049,474.644440
	C380.495880,475.015625 378.673615,475.434082 379.236694,472.464386
z"
        />
        <path
          fill="#F58F36"
          opacity="1.000000"
          stroke="none"
          d="
M268.924561,384.800751
	C270.977600,375.884979 270.974091,376.026855 262.272949,375.811554
	C258.906738,375.728241 254.193253,377.684723 252.459427,374.427673
	C250.549377,370.839600 255.005997,368.079712 256.987366,365.229095
	C265.247955,353.344757 273.745056,341.624817 282.157227,329.845856
	C282.737152,329.033875 283.323212,328.225922 283.921906,327.427704
	C284.914886,326.103821 285.944000,324.419647 287.867798,325.440247
	C289.448273,326.278687 289.042328,328.029816 288.714966,329.508118
	C287.349701,335.673157 285.968323,341.834717 284.576660,347.993866
	C283.059235,354.709686 283.051453,354.707581 290.122131,354.713165
	C292.785645,354.715271 295.452637,354.633942 298.111908,354.741821
	C302.435638,354.917297 303.263977,356.381989 300.861115,359.940979
	C297.603638,364.765717 294.131104,369.445343 290.745697,374.183594
	C283.975098,383.659821 277.214661,393.143433 270.413483,402.597687
	C269.402008,404.003723 268.473663,406.314758 266.306885,405.244843
	C264.361786,404.284424 265.003571,402.119110 265.434692,400.285950
	C266.614319,395.270050 267.725525,390.238037 268.924561,384.800751
z"
        />
        <path
          fill="#FEFEFE"
          opacity="1.000000"
          stroke="none"
          d="
M281.409119,340.210266
	C280.623383,344.677307 279.781647,348.911224 278.942841,353.145752
	C277.831665,358.755554 278.033234,358.992981 283.948486,359.026947
	C287.444214,359.047028 290.939911,359.059906 294.435608,359.082367
	C294.598785,359.083435 294.761597,359.146393 295.802246,359.364319
	C287.983978,370.331787 280.371002,381.011322 272.757996,391.690857
	C272.517670,391.578613 272.277374,391.466400 272.037048,391.354187
	C273.108734,386.370911 273.951996,381.323761 275.322754,376.424164
	C276.466278,372.336884 274.759216,371.279938 271.166016,371.354645
	C268.170166,371.416962 265.170959,371.405487 262.175232,371.338623
	C261.225128,371.317444 259.847168,371.336700 259.425140,370.770691
	C258.548798,369.595398 259.916595,368.698822 260.549042,367.810455
	C267.111053,358.593292 273.703735,349.397980 280.587006,340.038116
	C280.886139,339.879669 281.354370,339.977448 281.409119,340.210266
z"
        />
        <path
          fill="#FEFEFE"
          opacity="1.000000"
          stroke="none"
          d="
M281.027679,340.023499
	C281.131073,339.644073 281.376007,339.408478 281.620941,339.172913
	C281.674255,339.443817 281.727570,339.714752 281.622375,340.214386
	C281.463837,340.443115 281.169220,340.167297 281.027679,340.023499
z"
        />
      </svg>
    );
  }
}
